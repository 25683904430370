import React from 'react';
import testImage from '../../../../images/industries/pdf.svg';

import './ehs-posts.styles.scss';

function EhsPosts({ posts, loading }) {
  if (loading) {
    return <p>loading...</p>;
  }

  return (
    <div className="ehs-posts-container">
      {posts.map((post, i) => (
        <div key={i} className="widgetBox-wrapper">
          <div className="widget-wrapper">
            <img src={testImage} alt="" />
            <div className="content">
              <div className="first-section">
                <a href="#" className="inside-title-link">
                  {post.userId} USERID
                </a>
                <p className="inner-content">{post.body} BODY</p>
              </div>

              <p className="label">{post.title} TITLE</p>
              <p className="date">{post.id} ID</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default EhsPosts;
