import React from 'react';
import Accordion from './accordion/accordion.component';

import './faqs.styles.scss';

class FAQs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeBlock: null,
    };
  }

  toggle = (index) => () => {
    this.setState({
      activeBlock: index === this.state.activeBlock ? null : index,
    });
  };

  toggleExpand =
    (expand = false) =>
    () => {
      this.setState({
        block1: expand,
        block2: expand,
        block3: expand,
      });
    };

  // RK--Checked for undefined values in accordionList
  render() {
    const { accordionList } = this.props;
    const { activeBlock } = this.state;
    return (
      <div className="faq-container">
        <dl className="faq-accordion">
          {accordionList != undefined && accordionList != null
            ? accordionList.map((item, index) => (
                <Accordion
                  title={item.Title}
                  description={item.Content}
                  onClick={this.toggle(index)}
                  expand={activeBlock === index}
                />
              ))
            : null}
        </dl>
      </div>
    );
  }
}

export default FAQs;
