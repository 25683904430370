import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { link, title, title_1, title_2, title_3 } from './request-a-quote.data';
import TextInput from '../../form-inputs/text-input/text-input.component';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './request-a-quote.validationRules';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import { submitRequestServiceQuoteResponse } from '../../../../js/api/specialty-pages/_request-a-quote.data';
import './request-a-quote.styles.scss';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../../js/api/_config';

// RP #Task  - Request Modeling Data
function RequestAQuote({ data, status }) {
  const [dropDownContent, setDropDownContent] = useState(data);
  const [noContent] = useState(204);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const [ProjectType] = useState(
    status != noContent && dropDownContent.ProjectInformation
      ? dropDownContent.ProjectInformation
      : null
  );
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitRequestServiceQuoteResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      ProjectType:
        values.projectType != null ? values.projectType.trim() : 'None',
      DescribeProject:
        values.describeProject != null ? values.describeProject.trim() : 'None',
      CommenceDate:
        values.commenceDate != null ? values.commenceDate.trim() : 'None',
      ResultDate: values.resultDate != null ? values.resultDate.trim() : 'None',
      Company: values.companyName.trim(),
      Address: values.address.trim(),
      AddressTwo: values.address2.trim(),
      City: values.city.trim(),
      State: values.stateName != null ? values.stateName.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      Country: values.Country != null ? values.Country.trim() : 'None',
      Industry:
        values.industryName != null ? values.industryName.trim() : 'None',
      HearAboutUs:
        values.hearaboutus != null ? values.hearaboutus.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.request_service_quote.UniqueId,
      Catalog: ListOfFormUniqueData.request_service_quote.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
  }

  return (
    <div className="remote-modeling-form">
      <p className="title">{title}</p>
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="form-title">{title_2}</p>
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.firstName && <p className="help">{errors.firstName}</p>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <p className="help">{errors.lastName}</p>}

            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
              maxlength={200}
            />
            {errors.jobTitle && <p className="help">{errors.jobTitle}</p>}
            <TextInput
              placeholder="Business Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={200}
            />
            {errors.email && <p className="help">{errors.email}</p>}
            <TextInput
              placeholder="Business Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            {errors.phone && <p className="help">{errors.phone}</p>}
            <p className="form-title">{title_1}</p>
            <SelectList
              className="mb-2"
              defaultText="What type of project can we help you with?"
              name="projectType"
              onChange={handleChange}
              value={values.projectType}
            >
              {status != noContent &&
                ProjectType.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                )).sort((a, b) => a - b)}
            </SelectList>
            <textarea
              name="describeProject"
              placeholder="Describe the project"
              required
              onChange={handleChange}
              value={values.describeProject}
            />
            <TextInput
              placeholder="How soon will the project commence?"
              name="commenceDate"
              onChange={handleChange}
              value={values.commenceDate}
              maxlength={100}
            />
            <TextInput
              placeholder="How soon do you need the results by?"
              name="resultDate"
              onChange={handleChange}
              value={values.resultDate}
              maxlength={100}
            />
          </Col>
          <Col xs={12} sm={6} className="right">
            <p className="form-title">{title_3}</p>
            <TextInput
              placeholder="Company Name"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              required
              maxlength={100}
            />
            {errors.companyName && <p className="help">{errors.companyName}</p>}
            <TextInput
              placeholder="Business Address 1"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />
            {errors.address && <p className="help">{errors.address}</p>}
            <TextInput
              placeholder="Business Address 2"
              required
              type="text"
              name="address2"
              onChange={handleChange}
              value={values.address2 || ''}
              autoComplete="off"
              className={`${errors.address2 && 'error'}`}
              maxlength={300}
            />
            {errors.address2 && <p className="help">{errors.address2}</p>}

            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={200}
            />
            {errors.city && <p className="help">{errors.city}</p>}

            <SelectList
              defaultText="— Country —"
              name="Country"
              onChange={handleChange}
              value={values.Country}
            >
              {Country &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            <SelectList
              defaultText="— State/Province —"
              name="stateName"
              onChange={handleChange}
              onClick={stateclick}
              value={values.stateName}
            >
              {State &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />
            {errors.postalCode && <p className="help">{errors.postalCode}</p>}

            <SelectList
              defaultText="— Select an Industry —"
              name="industryName"
              value={values.industryName}
              onChange={handleChange}
            >
              {status != noContent &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            <SelectList
              defaultText="— How did you hear about us? —"
              name="hearaboutus"
              value={values.hearaboutus}
              onChange={handleChange}
            >
              {status != noContent &&
                HowDidYouHearAboutUs.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            <textarea
              name="comments"
              placeholder="comments"
              required
              onChange={handleChange}
              value={values.comments}
            />
          </Col>
          <div className="recaptcha">
            <ReCaptcha />
          </div>
          <div className="link">
            <CustomButton className="heroLink">{link}</CustomButton>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default RequestAQuote;
