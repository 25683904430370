import moment from 'moment';
import {
  replacespecialcharacter,
  replacewashintondc,
} from '../../../js/helpers/_sort-ctas';
import { TrainingAzureSearchServices } from './training-azure-search.services';
import { TrainingNewAzureSearchTypesConstants } from './training-azure-search.types';

export const generalSearchDateConstant = '2001-01-01T10:57:02.444Z';

export const staticTrainingAzureSearchData = {
  count: true,
  skip: 0,
  top: 10,
  searchMode: 'any',
  queryType: 'simple',
  orderby: 'DatePublished asc', // RP - ADVANCED SEARCH FILTERS
  filter: '',
  facets: [
    // "DisplayName,count:5,sort:count",
    `DatePublished,values:${moment().toDate().toISOString()}|${moment()
      .add(1, 'years')
      .toDate()
      .toISOString()}`,
    // "City,count:5,sort:count",
    // "State,count:5,sort:count",
    'ByFormat,count:50,sort:count',
    'ByCategory,count:50,sort:count',
    'ByIndustry,count:50,sort:count',
    // "ByLocation,count:50,sort:count",
    'State,count:50,sort:count',
    'BusinessUnit,count:50,sort:count',
    'Amount,values:0 | 5000',
    'RegulatoryAgency,count:50,sort:count',
  ],
  search: '*',
};

export const getTrainingAzureSearchData = (requestBody) => {
  // let newFilterString = requestBody.filter.length > 0
  //     ? requestBody.filter + " and RegistrationEndDate ge " + moment().toDate().toISOString()
  //     : "RegistrationEndDate ge " + moment().toDate().toISOString();

  // RP - SD39 : Training page- Advanced search Filter
  requestBody.orderby =
    requestBody.orderby === 'None' ||
    requestBody.orderby === '' ||
    requestBody.orderby === 'relevance' ||
    requestBody.orderby === 'Relevance'
      ? 'search.score() desc'
      : requestBody.orderby;

  const min = moment(moment().toDate()).toISOString().split('T')[0];
  const max = moment(moment().add(1, 'years').toDate())
    .toISOString()
    .split('T')[0];

  const currentData = requestBody.filter.toString().split(' and');

  const ddata = [];
  currentData.map((element, index) => {
    if (
      element.includes('DatePublished ge') ||
      element.includes('DatePublished le')
    ) {
      ddata.push(element);
    }
  });

  let preparedDateFilter =
    currentData &&
    currentData.map((element, index) => {
      if (element.includes('DatePublished ge')) {
        if (
          element.split('T')[0].trim() === `DatePublished ge ${min}` &&
          ddata &&
          ddata[1].split('T')[0].trim() === `DatePublished le ${max}`
        ) {
          return '';
        }
        return element;
      }
      if (element.includes('DatePublished le')) {
        if (
          ddata &&
          ddata[0].split('T')[0].trim() === `DatePublished ge ${min}` &&
          element.split('T')[0].trim() === `DatePublished le ${max}`
        ) {
          return '';
        }
        return element;
      }
      return element;
    });

  preparedDateFilter =
    preparedDateFilter && preparedDateFilter.filter((x) => x !== '');
  const strPreparedDateFilter =
    preparedDateFilter &&
    preparedDateFilter.toString().split(',').join(' and ');

  const newSearchFilterString =
    requestBody.search !== '*' &&
    strPreparedDateFilter &&
    strPreparedDateFilter.length > 0
      ? `${strPreparedDateFilter} and (search.ismatchscoring('"${replacespecialcharacter(
          requestBody.search
        )}"~3', 'DisplayName', 'full', 'any') or search.ismatch('${replacespecialcharacter(
          requestBody.search
        )}', 'ShortDescription', 'full', 'all') or search.ismatch('${replacespecialcharacter(
          requestBody.search
        )}', 'LongDescription', 'full', 'all'))`
      : requestBody.search !== '*' && strPreparedDateFilter.length === 0
      ? ` (search.ismatchscoring('"${replacespecialcharacter(
          requestBody.search
        )}"~3', 'DisplayName', 'full', 'any') or search.ismatch('${replacespecialcharacter(
          requestBody.search
        )}', 'ShortDescription', 'full', 'all') or search.ismatch('${replacespecialcharacter(
          requestBody.search
        )}', 'LongDescription', 'full', 'all'))`
      : strPreparedDateFilter && strPreparedDateFilter.length > 0
      ? strPreparedDateFilter
      : '';

  const trainingRequestBody = {
    ...requestBody,
    top: 5000,
    skip: 0,
    filter: replacewashintondc(
      newSearchFilterString.replace(
        'ESG and  Sustainability',
        'ESG and Sustainability'
      )
    ),
    search: '*',
    // "orderby":"search.score() desc"
  };
  return (dispatch) => {
    TrainingAzureSearchServices.searchForGeneralData(trainingRequestBody).then(
      (response) => {
        dispatch({
          type: TrainingNewAzureSearchTypesConstants.TRAINING_NEW_LAST_SEARCH,
          payload: requestBody,
        });

        dispatch({
          type: TrainingNewAzureSearchTypesConstants.TRAINING_NEW_AZURE_SEARCH,
          payload: response,
          lastQuery: requestBody,
        });
      }
    );
  };
};
