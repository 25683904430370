import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './subscribe.validationRules';
import CustomButton from '../../custom-button/custom-button.component';
import TextInput from '../../form-inputs/text-input/text-input.component';
import SelectList from '../../form-inputs/select-list/select-list.component';
import { link, title } from './subscribe.data';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import { submitSubscribeResponse } from '../../../../js/api/subscribe/_subscribe.data';
import './subscribe.styles.scss';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';

function SubscribeForm({ data, mainData, status }) {
  // #RP - Task 62  - Subscribe
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country] = useState(
    status != noContent && dropDownContent.Country
      ? dropDownContent.Country
      : null
  );
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitSubscribeResponse(
        submissionObj,
        token
      );
      history.push('/subscribe-thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.title.trim(),
      Company: values.companyName.trim(),
      Industry: values.industry != null ? values.industry.trim() : 'None',
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : 'None',
      PostalCode: values.postalCode != null ? values.postalCode.trim() : 'None',
      Country: values.country != null ? values.country.trim() : 'None',
      PhoneNumber: values.phone != null ? values.phone.trim() : '0',
      EmailAddress: values.email.trim(),
      Information:
        values.information != null ? values.information.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.Subscribe.UniqueId,
      Catalog: ListOfFormUniqueData.Subscribe.Catalog,
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  return (
    <div className="subscribe-inner-container">
      <p className="form-title">{title}</p>
      <form
        className="subscribe-form"
        id="Subscribe"
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="left">
          {errors.firstName && <span className="help">{errors.firstName}</span>}
          <TextInput
            placeholder="First Name"
            required
            type="text"
            name="firstName"
            onChange={handleChange}
            value={values.firstName || ''}
            autoComplete="off"
            className={`${errors.firstName && 'error'}`}
            maxlength={100}
          />
          {errors.lastName && <span className="help">{errors.lastName}</span>}
          <TextInput
            placeholder="Last Name"
            required
            type="text"
            name="lastName"
            onChange={handleChange}
            value={values.lastName || ''}
            autoComplete="off"
            className={`${errors.lastName && 'error'}`}
            maxlength={100}
          />
          {errors.title && <span className="help">{errors.title}</span>}
          <TextInput
            placeholder="Title"
            required
            type="text"
            name="title"
            onChange={handleChange}
            value={values.title || ''}
            autoComplete="off"
            className={`${errors.title && 'error'}`}
            maxlength={200}
          />
          {errors.companyName && (
            <span className="help">{errors.companyName}</span>
          )}
          <TextInput
            placeholder="Company Name"
            required
            type="text"
            name="companyName"
            onChange={handleChange}
            value={values.companyName || ''}
            autoComplete="off"
            className={`${errors.companyName && 'error'}`}
            maxlength={200}
          />

          <SelectList
            defaultText="— Select an Industry —"
            name="industry"
            onChange={handleChange}
            value={values.industry}
          >
            {status != noContent &&
              Industry.map(({ Name }) => (
                <option key={Name} value={Name}>
                  {Name}
                </option>
              ))}
          </SelectList>
        </div>
        <div className="right subscribe-right">
          <Row>
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />

            <Col xs={8} className="pr-2">
              {errors.city && <span className="help">{errors.city}</span>}
              <TextInput
                placeholder="City"
                required
                type="text"
                name="city"
                onChange={handleChange}
                value={values.city || ''}
                autoComplete="off"
                className={`${errors.city && 'error'}`}
                maxlength={100}
              />
            </Col>
            <Col xs={4}>
              <SelectList
                defaultText="— State / Province —"
                name="state"
                onChange={handleChange}
                value={values.state}
              >
                {status != noContent &&
                  State.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </Col>

            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />

            <SelectList
              defaultText="— Country —"
              name="country"
              onChange={handleChange}
              value={values.country}
            >
              {status != noContent &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="tel"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Company Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={100}
            />

            <textarea
              name="information"
              placeholder="Please indicate which states you are interested in, if applicable. This will allow us to send you state-specific eNews."
              onChange={handleChange}
              value={values.information}
              maxLength={200}
            />
          </Row>
          <div className="recaptcha">
            <ReCaptcha />
          </div>

          <div className="link">
            <CustomButton className="heroLink">{link}</CustomButton>
          </div>
        </div>
      </form>
    </div>
  );
  return null;
}

export default SubscribeForm;
