import React from 'react';
import './SafeBridgeRootComponent.scss';

function TrainingAzureSearchPostElement({ element }) {
  const onClickSearchPost = () => {
    const splitElements = element.Link.split('/');
    const lastElement = splitElements[splitElements.length - 1];
    window.open(
      `/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/${lastElement
        .replace(/\s+|\+/g, '-')
        .replace(/\//g, '-')}`,
      '_blank'
    );
  };

  return (
    // KT add class safe-bridge-post-changed
    <div className="row safe-bridge-post safe-bridge-post-changed">
      <div className="col-md-3 col-sm-3 safe-bridge-sub-data-column safe-bridge-sub-data-column-changed p-3">
        {/* KT add class safe-bridge-sub-data-column-changed col-sm-2  10-11-2021 */}
        <div className="safe-bridge-post-caption safe-bridge-post-caption-changed">
          {/* KT add class safe-bridge-post-caption-changed  10-11-2021 */}
          <span>Name Of Compount</span>
          <br />
        </div>
        <p className="safe-bridge-post-caption-content-changed">
          {element.NameOfCompound}
        </p>
      </div>

      <div className="col-md-2 col-sm-2 p-3 safe-bridge-sub-data-column safe-bridge-sub-data-column-changed light-background">
        {/* KT add class safe-bridge-sub-data-column-changed col-sm-2  10-11-2021 */}
        <div className="safe-bridge-post-caption safe-bridge-post-caption-changed">
          {/* KT add class safe-bridge-post-caption-changed  10-11-2021 */}
          <span>CAS Number</span>
          <br />
        </div>
        <p className="safe-bridge-post-caption-content-changed">
          {element.CASNumber}
        </p>
      </div>

      <div className="col-md-2 col-sm-2 safe-bridge-sub-data-column safe-bridge-sub-data-column-changed p-3">
        {/* KT add class safe-bridge-sub-data-column-changed col-sm-2  10-11-2021 */}
        <div className="safe-bridge-post-caption safe-bridge-post-caption-changed">
          {/* KT add class safe-bridge-post-caption-changed  10-11-2021 */}
          <span>Analytical Method</span>
          <br />
        </div>
        <p className="safe-bridge-post-caption-content-changed">
          {element.AnalyticalMethod}
        </p>
      </div>

      <div className="col-md-2  col-sm-2 p-3 safe-bridge-sub-data-column safe-bridge-sub-data-column-changed light-background">
        {/* KT add class safe-bridge-sub-data-column-changed col-sm-2  10-11-2021 */}
        <div className="safe-bridge-post-caption safe-bridge-post-caption-changed">
          {/* KT add class safe-bridge-post-caption-changed  10-11-2021 */}
          <span>Method Report Number</span>
          <br />
        </div>
        <p className="safe-bridge-post-caption-content-changed">
          {element.MethodReportNumber}
        </p>
      </div>

      <div className="col-md-2  col-sm-2 p-3 safe-bridge-sub-data-column safe-bridge-sub-data-column-changed">
        {/* KT add class safe-bridge-sub-data-column-changed col-sm-2  10-11-2021 */}
        <div className="safe-bridge-post-caption safe-bridge-post-caption-changed">
          {/* KT add class safe-bridge-post-caption-changed  10-11-2021 */}
          <span>Quantification Limit</span>
        </div>
        <p className="safe-bridge-post-caption-content-changed">
          {element.QuantificationLimit}
        </p>
      </div>
      {/* KT add class safe-bridge-button-changed col-sm-1  10-11-2021 */}
      <div className="col-md-1  col-sm-1 p-3 safe-bridge-button-changed">
        <button
          onClick={onClickSearchPost}
          className="safe-bridge-read-more-button-style"
        >
          <i
            className="fa fa-external-link"
            style={{ fontSize: '20px' }}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
}

function SafeBridgeSearchPostList({ valueList, activePage }) {
  return (
    <div className="training-posts-container">
      {valueList && valueList.length > 0 && (
        <div className="safe-bridge-top-heading-row safe-bridge-top-heading-row-changed">
          {/* KT add class safe-bridge-top-heading-row-changed 10-12-2021 */}
          <div className="col-md-3 col-sm-3">
            {/* KT add class col-sm-3 10-12-2021 */}
            <span className="safe-bridge-top-heading-changed">
              Name of Compound
            </span>
          </div>

          <div className="col-md-2 col-sm-2">
            {/* KT add class col-sm-2 10-12-2021 */}
            <span className="safe-bridge-top-heading-changed">CAS Number</span>
          </div>

          <div className="col-md-2 col-sm-2">
            {/* KT add class col-sm-2 10-12-2021 */}
            <span className="safe-bridge-top-heading-changed">
              Analytical Method
            </span>
          </div>

          <div className="col-md-2 col-sm-2">
            {/* KT add class col-sm-2 10-12-2021 */}
            <span className="safe-bridge-top-heading-changed">
              Method Report No
            </span>
          </div>

          <div className="col-md-2 col-sm-2">
            {/* KT add class col-sm-2 10-12-2021 */}
            <span className="safe-bridge-top-heading-changed">
              Quantification Limit
            </span>
          </div>
        </div>
      )}
      {valueList &&
        valueList
          .slice(activePage * 10 - 10, activePage * 10)
          .map((element) => (
            <TrainingAzureSearchPostElement element={element} />
          ))}
    </div>
  );
}

export default SafeBridgeSearchPostList;
