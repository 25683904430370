import React from 'react';
import { title, content_1, content_2 } from './website-problem.data';

import './website-problem-styles.scss';

// #RP - Task 71 - Website Problem
function WebsiteProblemWidget({ mainData, status }) {
  const noContent = 204;
  const sideTitle =
    status != noContent && mainData.SideTitle ? mainData.SideTitle : '';
  const sideContent =
    status != noContent && mainData.SideContent ? mainData.SideContent : '';

  return (
    <div className="website-problem-widget-container">
      <p className="title" dangerouslySetInnerHTML={{ __html: sideTitle }} />
      <p
        className="content-1"
        dangerouslySetInnerHTML={{ __html: sideContent }}
      />
    </div>
  );
}

export default WebsiteProblemWidget;
