import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { title } from './ehs-article-listing.data';
import EhsPosts from './ehs-posts/ehs-posts.component';
import Pagination from '../../resources-and-media/pagination/pagination.component';
import { objData } from '../../commons/filtered-content-well/filtered-content.data';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import './ehs-article-listing.styles.scss';

function EhsArticleListing() {
  const [data, setData] = useState(objData);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  function filterContent(activeSection) {
    const newData = data.map((group) => {
      if (activeSection === group.section) {
        group.active = true;
      } else {
        group.active = false;
      }
      return group;
    });

    setData(newData);
  }

  // this effect is just for testing purposes
  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get('https://jsonplaceholder.typicode.com/posts');

      // https://trinitystage.azurewebsites.net/restapi/customnewsfilter/
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="ehs-listing-wrapper">
      <div className="top-container">
        <p className="title">{title}</p>
        <SelectList
          className=""
          onChange={(e) => filterContent(e.target.value)}
          defaultText="Sort By"
        >
          {data.map(({ section, active }) => (
            <option
              className={`filter
                    ${active ? 'active' : ''}
                `}
              value={section}
            >
              {section}
            </option>
          ))}
        </SelectList>
      </div>
      <EhsPosts posts={currentPosts} loading={loading} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
    </div>
  );
}

export default EhsArticleListing;
