import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import CategoryListing from './software-category.page';
import './software-secondary.styles.scss';
import { sortingbySortorderInServiceBrand } from '../../../js/helpers/_sort-ctas';

function SoftwareSecondary({ page: { MainContent, ModelReadyDataContent } }) {
  // RP #Task 145 Model Ready Data
  ModelReadyDataContent = sortingbySortorderInServiceBrand(
    ModelReadyDataContent
  );

  if (MainContent && ModelReadyDataContent) {
    return (
      <div className="software-secondary software">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.MobileImage_src}
          alt={`${MainContent.Title} image`}
          template="featured"
          title={MainContent.Title}
          description={MainContent.HeaderContent}
          colorCategory="soft"
          altLayout
          specialityHeroCta
          specialityHeroCtaData={MainContent}
        />

        <div className="trinity-container sm-up-module-spacer">
          <Row>
            <Col className="content-container">
              <p className="sstitle">{MainContent.SubTitle}</p>
              {/* <ContentWell
              content={MainContent.Content}/> */}
              {ModelReadyDataContent && (
                <CategoryListing data={ModelReadyDataContent} />
              )}
            </Col>
            <Col className="d-lg-none inner-hero" />
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareSecondary;
