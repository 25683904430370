import React, { useState } from 'react';
import './forgot-password.styles.scss';
import { Link, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import TextInput from '../../../../commons/form-inputs/text-input/text-input.component';
import CustomButton from '../../../../commons/custom-button/custom-button.component';
import { validateEmailSyntax } from '../../../../../js/helpers/_validate-email-syntax';
import {
  userEmailExist,
  userForgotPasswordRequest,
} from '../../../../../js/api/_user-email-exist';

function ForgotPasswordForm() {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const verifiedUserEmail = useSelector(
    ({ user }) => user.verified_email,
    shallowEqual
  );
  const isUserFirstTimeLogin = useSelector(
    ({ user }) => user.isuser_login_firsttime,
    shallowEqual
  );
  const [email, setEmail] = useState(verifiedUserEmail || '');
  const emailOnChange = (e) => setEmail(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage('');
    const submissionEmail = email;

    const validEmailSyntax = validateEmailSyntax(submissionEmail);
    if (!validEmailSyntax) {
      setSubmitting(false);
      return setErrorMessage('Email must be in a valid format');
    }

    const emailExists = await userEmailExist(submissionEmail);

    if (emailExists) {
      const reponse = await handleForgotPasswordRequest(
        `${window.location.origin}/reset-password`
      );
      if (reponse === 1) {
        setSubmitting(false);
        history.push('password-help');
      }
      setSubmitting(false);
    } else {
      setSubmitting(false);
      setErrorMessage("That email doesn't exist");
    }
  };

  const handleForgotPasswordRequest = async (url) => {
    const data = {
      emailaddress: email,
      requesturl: url,
    };
    return await userForgotPasswordRequest(data);
  };

  return (
    <div className="forgot-pswd-form module-spacer">
      {errorMessage && <p className="text error p-0">*{errorMessage}</p>}
      <div className="form-container background action-item">
        <h2>
          {isUserFirstTimeLogin
            ? 'Please Reset Your Password'
            : 'Password Help?'}
        </h2>
        <p className="blurb">
          {isUserFirstTimeLogin
            ? 'Our website has changed. Please enter your email address to reset password.'
            : 'Enter the email address associated with your Trinity account. Our website has changed. Please enter your email address to reset password.'}
        </p>

        <TextInput
          onChange={(e) => emailOnChange(e)}
          placeholder=""
          label="Email"
          type="email"
          value={email}
        />

        <Link to="/reset-password">
          <CustomButton
            onClick={handleSubmit}
            type="submit"
            loading={submitting}
          >
            Continue
          </CustomButton>
        </Link>
      </div>
      <p>
        Has your email changed? If you no longer use the email address
        associated with your Trinity account, you may contact{' '}
        <a href="mailto:password-help@trinityconsultants.com">Password Help</a>{' '}
        for help restoring access to your account.
      </p>
    </div>
  );
}

export default ForgotPasswordForm;
