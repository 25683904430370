import React, { useState, useEffect } from 'react';
import './filitered-content-well-pagination.styles.scss';
import axios from 'axios';

import Pagination from '../../../resources-and-media/pagination/pagination.component';
import FiliteredContentWellPagination from './list-content-well/list-content-well.component';

function FilteredContentWellPagination({ title }) {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      const res = await axios.get('https://jsonplaceholder.typicode.com/posts');

      // https://trinitystage.azurewebsites.net/restapi/customnewsfilter/
      setPosts(res.data);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  // Get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="filtered-content-well">
      {title && <h2>{title}</h2>}
      <FiliteredContentWellPagination posts={currentPosts} loading={loading} />
      <Pagination
        postsPerPage={postsPerPage}
        totalPosts={posts.length}
        paginate={paginate}
      />
    </div>
  );
}

export default FilteredContentWellPagination;
