import React from 'react';
import './speciality-hero-cta.styles.scss';

import CustomButton from '../../custom-button/custom-button.component';

function SpecialityHeroCta({ data }) {
  const handleOnClick = (url) => {
    if (url) {
      const originurl = window.location.origin;
      const apiurl = `${originurl}/${url}`;
      window.open(apiurl);
    }
  };

  return (
    <div className="speciality-hero-cta">
      <h2 className="title">
        {data.HeaderRightSideTitle && data.HeaderRightSideTitle != undefined
          ? data.HeaderRightSideTitle
          : ''}
      </h2>
      <p className="content the-content">
        {data.HeaderRightSideContent && data.HeaderRightSideContent != undefined
          ? data.HeaderRightSideContent
          : ''}
      </p>
      <CustomButton
        category=""
        className="custom-button"
        arrow
        onClick={() => handleOnClick(data.HeaderRightSideButtonLink)}
      >
        {data.HeaderRightSideButtonText &&
        data.HeaderRightSideButtonText != undefined
          ? data.HeaderRightSideButtonText
          : ''}
      </CustomButton>
    </div>
  );
}

export default SpecialityHeroCta;
