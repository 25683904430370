export const locationsData = [
  {
    state: 'Birmingham',
    address_1: '1 Perimeter Park S',
    address_2: 'Suite 100N ',
    city_zip: 'Birmingham, AL 35243',
    phone: 'P: (205) 970-6035',
    latitude: 39.394338218063,
    longitude: -102.665372321828,
  },
  {
    state: 'Phoenix',
    address_1: '1 Perimeter Park S',
    address_2: 'Suite 100N ',
    city_zip: 'Birmingham, AL 35243',
    phone: 'P: (205) 970-6035',
    latitude: 38.384328322274,
    longitude: -98.487759176401,
  },
  {
    state: 'Little Rock',
    address_1: '1 Perimeter Park S',
    address_2: 'Suite 100N ',
    city_zip: 'Birmingham, AL 35243',
    phone: 'P: (205) 970-6035',
    latitude: 31.395308317072,
    longitude: -99.555269414963,
  },
  {
    state: 'Victoria',
    address_1: '1 Perimeter Park S',
    address_2: 'Suite 100N ',
    city_zip: 'Birmingham, AL 35243',
    phone: 'P: (205) 970-6035',
    latitude: 36.394308805849,
    longitude: -123.708762645341,
  },
  {
    state: 'Bakersfield',
    address_1: '1 Perimeter Park S',
    address_2: 'Suite 100N ',
    city_zip: 'Birmingham, AL 35243',
    phone: 'P: (205) 970-6035',
    latitude: 47.49855629475769,
    longitude: -122.14184416996333,
  },
];
