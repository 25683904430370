// setting default content to fill in placeholder data for fields not filled out
function SoftwareSecondaryModel({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = '',
    hero_description = '',
    hero_specialty_cta: {
      title = '',
      content = '',
      link: { src, text },
    },
  },
  content_area = '<p></p>',
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_specialty_cta: {
        title,
        content,
        link: {
          src,
          text,
        },
      },
    },
    content_area,
  };
}

export default SoftwareSecondaryModel;
