import { ENDPOINTS } from './_config';

export const getNavigationItems = async () => {
  try {
    const request = await fetch(ENDPOINTS.NAVIGATION);
    const response = await request.json();

    localStorage.setItem('navigation', JSON.stringify(response));
    localStorage.setItem('navigation-time-stamp', Date.now());

    return response;
  } catch (e) {
    return null;
  }
};
