import React from 'react';
import './partner-layout.styles.scss';
import PartnerItem from '../partner-item/partner-item.component';

// JP #Task 41
function PartnerLayout({ product }) {
  const content =
    'Save 25% when you register for two or more partner courses held on consecutive dates.';

  const subContent = 'EPA Proposes Project Emissions Accounting Rule';
  return (
    <div className="partner-layout">
      {product && product.RelatedProduct.length > 0 ? (
        <PartnerItem
          title="Partner Courses"
          content={content}
          location="Boston, MA"
          date="January 3, 2020"
          subContent={subContent}
          product={product}
        />
      ) : null}
    </div>
  );
}

export default PartnerLayout;
