// setting default content to fill in placeholder data for fields not filled out
function StaffingLpModel({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = 'Aquatic Environmental Monitoring',
    hero_description = '',
    hero_ctas = [],
    hero_ctas_title = '',
  },
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_ctas,
      hero_ctas_title,
    },
  };
}

export default StaffingLpModel;
