import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentWell from '../../../../components/about/careers/careers-ava/content-well-link/content-well-link.component';
import { defaultContent } from '../../../../components/about/careers/careers-ava/content-well-link/content-well.data';
import AdvancedSearch from '../../../../components/commons/sidebar-with-filters/advanced-search/advanced-search.component';
import CareersListingWithFilters from '../../../../components/about/careers/careers-ava/career-listing-with-filters/career-lisiting-with-filters.component';
import Hero from '../../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../../images/training/training_lp_hero.png';
import Loader from '../../../../components/commons/helpers/loader/loader.component';

// #RP - Task 124 Career
function AvaPositions({ page: { MainContent, CareerContent } }) {
  if (MainContent && CareerContent) {
    return (
      <div className="ava-container">
        {MainContent && (
          <Hero
            src={MainContent.HeaderImage_src}
            mobileImgSrc={MainContent.HeaderMobileImage_src}
            alt={MainContent.HeaderMobileImage_alt}
            template="standard"
            title={MainContent.Title}
            colorCategory={
              MainContent &&
              Object.keys(MainContent).length > 0 &&
              MainContent.url.split('/')[1] === 'staffinges'
                ? 'staffing'
                : 'policies'
            }
          />
        )}
        <div className="trinity-container">
          <div className="top-container">
            <Col md={12} sm={12} lg={12}>
              {MainContent && <ContentWell content={MainContent.Content} />}
            </Col>
            <hr />
          </div>

          <Col md={12} sm={12} lg={12}>
            {CareerContent && (
              <CareersListingWithFilters cdata={CareerContent} />
            )}
          </Col>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default AvaPositions;
