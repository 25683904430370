import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class FeedbackServices {
  static getFeedback(feedBackID) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.FEEDBACK.GET_FEEDBACK_DATA + feedBackID,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static submitFeedBackData(data, feedBackID) {
    return axios({
      method: 'POST',
      url: `${ENDPOINTS.FEEDBACK.GET_FEEDBACK_DATA}/complete`,
      data,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }
}
