export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (values.email && values.email.trim() === '') {
    values.email = '';
    errors.email = 'Email address is required';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (values.firstName && values.firstName.trim() === '') {
    values.firstName = '';
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (values.lastName && values.lastName.trim() === '') {
    values.lastName = '';
    errors.lastName = 'Last Name is required';
  }
  if (!values.jobTitle) {
    errors.jobTitle = 'Job Title is required';
  }
  if (values.jobTitle && values.jobTitle.trim() === '') {
    values.jobTitle = '';
    errors.jobTitle = 'Job Title is required';
  }
  // if (!values.phone) {
  //   errors.phone = "Phone number field - ex: 1234567890";
  // }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (values.address && values.address.trim() === '') {
    values.address = '';
    errors.address = 'Address is required';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (values.city && values.city.trim() === '') {
    values.city = '';
    errors.city = 'City is required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }
  if (values.postalCode && values.postalCode.trim() === '') {
    values.postalCode = '';
    errors.postalCode = 'Postal Code is required';
  }
  if (!values.companyName) {
    errors.companyName = 'Company Name is required';
  }
  if (values.companyName && values.companyName.trim() === '') {
    values.companyName = '';
    errors.companyName = 'Company Name is required';
  }
  if (!values.State) {
    errors.State = 'State is required';
  }
  if (values.State && values.State.trim() === '') {
    values.State = '';
    errors.State = 'State is required';
  }
  if (!values.Country) {
    errors.Country = 'Country is required';
  }
  if (values.Country && values.Country.trim() === '') {
    values.Country = '';
    errors.Country = 'Country is required';
  }
  if (!values.recaptchaValue) {
    errors.recaptchaValue = 'recaptcha is required';
  }

  return errors;
}
