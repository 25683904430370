import React from 'react';
import './technology-cta.style.scss';

function TechnologyCta({
  linearColor,
  bgImage,
  content,
  linkBox = false,
  isFromTechnology = false,
}) {
  const backgroundImage = {
    backgroundImage: bgImage
      ? `linear-gradient(${linearColor}),url('${bgImage}')`
      : '',
    backgroundSize: bgImage ? 'cover' : '',
  };

  const technologyImage = {
    backgroundImage: bgImage ? `url('${bgImage}')` : '',
    backgroundSize: bgImage ? 'cover' : '',
  };

  return (
    <div
      style={isFromTechnology ? technologyImage : backgroundImage}
      className={`technology-cta xs-only-trinity-container module-spacer ${
        linkBox ? 'link-listing' : ''
      }`}
    >
      <div dangerouslySetInnerHTML={{ __html: content }} className="content" />
    </div>
  );
}

export default TechnologyCta;
