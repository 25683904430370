import React from 'react';
import './empty-table.styles.scss';

function EmptyTableComponent(props) {
  return (
    <div className="trinity-container empty-table">
      <div>
        <h2 className="first-line">{props.message}</h2>
      </div>
    </div>
  );
}

export default EmptyTableComponent;
