import React, { useState, useRef, createRef } from 'react';
import './video-carousel.styles.scss';

import { AzureMP } from 'react-azure-mp';
import { Player } from 'video-react';
import ReactPlayer from 'react-player';
import SectionTitle from '../section-title/section-title.component';

import Carousel from '../carousel/carousel.component';
import VideoModal from '../../commons/video-modal/video-modal.component';

function VideoCarousel({ videos, heading, infinite }) {
  const [videoVisible, setVideoVisible] = useState(false);
  // const videoRef = useRef([]);

  const toggleVisibility = (visible) => {
    setVideoVisible(visible);
  };
  /* if (videoRef.current.length !== Object.entries(videos).length) {
        videoRef.current = Array(Object.entries(videos).length).fill(null).map((_, i) => videoRef.current[i] || createRef());
    }
*/

  // this needs to be thumbnail images that open a video modal
  return (
    <div className="video-carousel">
      <h2>{heading}</h2>
      <div className="carousel-container">
        <Carousel infinite={infinite}>
          {videos &&
            Object.entries(videos).map((video, i) => (
              <div
                key={video.src + i}
                style={{ marginTop: 5 }}
                className="slick-item-container"
              >
                <div onClick={() => toggleVisibility(true)} className="thumb">
                  {/* <video controls>
                    <source src={video.src} type="video/mp4" />
                    <source src={video.src} type="video/ogg" />
                  </video> */}
                  {/* leave this for now, probably change later */}
                  {/* <div className="parent_item"> */}
                  <video
                    controls
                    controlsList="nodownload"
                    disablePictureInPicture
                    height={200}
                    width={300}
                    // ref={videoRef.current[i]}
                    className="embed-responsive-item"
                    src={video[1].substring(9)}
                    allowFullScreen
                    autoPlay={false}
                    frameBorder="0"
                  />
                  {/* <div onClick={() => {
                                        video[1].current[i].play()
                                    }} className="center_item"></div>
                                </div> */}
                  <p className="image-title">
                    {video[0].length > 40 ? video[0] : video[0]}
                  </p>
                </div>
              </div>
            ))}
        </Carousel>
        <p className="title" />
      </div>

      {/* <VideoModal visible={videoVisible} toggleVisibility={toggleVisibility}/> */}
    </div>
  );
}

VideoCarousel.defaultProps = {
  infinite: true,
};

export default VideoCarousel;
