// setting default content to fill in placeholder data for fields not filled out
function ConsultingDataTableModel({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = 'Aquatic Environmental Monitoring',
  },
  widgets = [],
  content_area = '<p>this is some mooooooo content</p>',
  table_data = [],
}) {
  return {
    widgets,
    hero: {
      hero_src,
      hero_alt,
      hero_title,
    },
    content_area,
    table_data,
  };
}

export default ConsultingDataTableModel;
