export default function validate(values) {
  const errors = {};
  if (!values.Email) {
    errors.Email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.Email)) {
    errors.Email = 'Email address is invalid';
  }
  if (!values.FirstName) {
    errors.FirstName = 'First Name is required';
  }
  if (!values.LastName) {
    errors.LastName = 'Last Name is required';
  }
  if (!values.Title) {
    errors.Title = 'Title is required';
  }
  if (!values.PhoneNumber) {
    errors.PhoneNumber = 'Phone number field - ex: 1234567890';
  }
  if (!values.Address) {
    errors.Address = 'Address is required';
  }
  if (!values.City) {
    errors.City = 'City is required';
  }
  if (!values.PostalCode) {
    errors.PostalCode = 'Postal Code is required';
  }
  if (!values.Company) {
    errors.Company = 'Company is required';
  }
  if (
    !values.StateID ||
    values?.StateID == -1 ||
    values?.StateID === '— State —'
  ) {
    errors.StateID = 'State is required';
  }
  if (
    !values.CountryID ||
    values?.CountryID === -1 ||
    values?.CountryID === '— Country —'
  ) {
    errors.CountryID = 'Country is required';
  }

  /* if (!values.NewPassword) {
        errors.NewPassword = "New password is required";
    }
    if (!values.SetConfirmPassword) {
        errors.SetConfirmPassword = "Confirm password is required";
    }
    if(values.NewPassword !== values.SetConfirmPassword){
        errors.NewPassword = "New password and confirm password should match";
        errors.SetConfirmPassword = "New password and confirm password should match";
    } */

  return errors;
}
