import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getAttendees } from '../../../../js/api/shopping-cart/_attendees-cart';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';
import { updateAllowCartStepsAction } from '../../../../redux/shopping-cart/shopping-cart.action';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import './training-policies-sidebar.styles.scss';

function TrainingPolices({
  buttonText,
  // mainContent,
  totalAttendeesRequired,
  products,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [totalexistattendeesrequired, setTotalexistattendeesrequired] =
    useState([]);
  // const [count]

  const handleReviewOrder = async () => {
    try {
      let checkVariable = false;
      products.forEach((product) => {
        const attendee = totalexistattendeesrequired.filter(
          (e) => e.variantsku === product.variantsku
        )[0];
        if (attendee === undefined) {
          checkVariable = true;
        }
        if (attendee && attendee.quantity !== product.quantity) {
          checkVariable = true;
        }
      });

      if (checkVariable) {
        handleNotify(
          'Fill out all the required attendees before reviewing order.',
          ToasterTypes.Warning
        );
      } else {
        setSubmitting(true);
        dispatch(updateAllowCartStepsAction(3));
        history.push(`${location.pathname}/review-order`);
        setSubmitting(false);
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in review order');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in review order',
        },
      });
    }
  };
  const cartitemimpersonate = useSelector(({ cart }) => cart.impersonateMember);
  const attendeescountupdate = useSelector(
    ({ cart }) => cart.attendeescount_update,
    shallowEqual
  );

  useEffect(() => {
    try {
      let getcartitemResponse;

      async function getCartAttendees() {
        let memberId;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
        getcartitemResponse = await getAttendees(memberId);
        if (getcartitemResponse) {
          const attendees =
            getcartitemResponse.Attendees &&
            [].concat.apply(
              [],
              getcartitemResponse.Attendees.map((e) => e.attendees)
            );
          let attendeesItems = [];
          getcartitemResponse &&
            getcartitemResponse.Attendees.forEach((e) => {
              attendeesItems = attendeesItems.concat([
                {
                  variantsku: e.VariantSku,
                  quantity: e.attendees.length,
                },
              ]);
            });
          if (attendees && attendees.length > 0) {
            setTotalexistattendeesrequired(attendeesItems);
          } else {
            setTotalexistattendeesrequired(attendeesItems);
          }
        }
      }
      getCartAttendees();
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in sidebar binding data');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in sidebar binding data',
        },
      });
    }
  }, [attendeescountupdate]);

  return (
    <div className="cart-sidebar">
      <div className="top-container">
        <Row>
          <Col xs={12} lg={12} md={12}>
            <CustomButton loading={submitting} onClick={handleReviewOrder}>
              {buttonText}
            </CustomButton>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <p className="order-blurb">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    "By placing your order, you agree to Trinity's<br />privacy notice, and conditions of use.",
                }}
              />
            </p>
          </Col>
        </Row>
      </div>

      <hr />
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<p><strong>Training Registration Policies</strong></p><div style="text-align:left;"><li><strong>Course fees are payable upon registration.</strong></li><li><strong><span style="background-color:transparent;color:inherit;font-family:inherit;font-size:inherit;text-align:inherit;text-transform:inherit;white-space:inherit;word-spacing:normal;caret-color:auto;">Only one discount can be applied to your purchase. </span></strong></li><li><strong>The course fee is per attendee. Recording our courses is strictly prohibited.</strong></li><li><strong>Recordings of paid, virtual courses will not be provided.&nbsp;</strong></li><li><strong>Cancellations received more than 72 hours prior to a course are eligible for a full refund or transfer to another course. Cancellations received less than 72 hours prior to a course may be transferred to another course; however, no refund will be\n        provided. If Trinity Consultants&nbsp;cancels a course, full refunds will be provided.</strong></li><li><strong>Trinity Consultants assumes no responsibility for participants&rsquo; travel expenses including, in the case of course cancellation, reimbursement for non-refundable reservations.</strong></li><li><strong>These courses are intended primarily for environmental professionals from industry and government agencies. Trinity Consultants&nbsp;reserves the right to decline registrations at its discretion to persons for whom its courses may not be intended.</strong></li></div>',
        }}
      />
    </div>
  );
}

export default TrainingPolices;
