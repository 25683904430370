import React, { useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';

import './consulting-individual-category-detail.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  consultingproductdata,
  newsdata,
  resourcesdata,
  trainingcourseservicedata,
} from '../../../redux/widget-box/widget-box.action';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { ContactUsWidget } from '../../../components/commons/widgets/Industry-expert/industry-expert.component';

function ConsultingTertiary({
  page: { MainContent, CategoryContent, AuthorContent, ResourceContent },
  section,
}) {
  // const data = widgetData;

  const resourceResponse = {
    type: 'RELATED_RESOURCES_DATA',
    payload: ResourceContent,
    status: true,
  };

  const dispatch = useDispatch();
  const news = useSelector(({ widget }) => widget.news);
  const resourceRedux = useSelector(({ widget }) => widget.resources);
  const resources =
    ResourceContent != undefined &&
    ResourceContent != null &&
    ResourceContent.length > 0
      ? resourceResponse
      : resourceRedux;
  const training = useSelector(({ widget }) => widget.trainingcourseservice);
  const product = useSelector(({ widget }) => widget.consultingproduct);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(await newsdata(window.location.pathname, 'consulting'));
      if (
        ResourceContent == undefined ||
        ResourceContent == null ||
        ResourceContent.length <= 0
      )
        dispatch(await resourcesdata(window.location.pathname, 'consulting'));
      // dispatch(await trainingcourseproductdata(window.location.pathname, "consulting"));
      dispatch(
        await trainingcourseservicedata(window.location.pathname, 'consulting')
      );
      dispatch(
        await consultingproductdata(window.location.pathname, 'consulting')
      );
    };
    fetchData();
  }, []);

  if (
    MainContent &&
    news !== undefined &&
    news.status &&
    resources !== undefined &&
    resources.status &&
    training !== undefined &&
    training.status &&
    product !== undefined &&
    product.status
  ) {
    return (
      <div className={`consulting consulting-tertiary ${section || ''}`}>
        {/* this templatet doesn't use a hero description */}
        {/* so best to not pass the prop down */}
        <div className="industries industries-lp">
          <div className="hero_indus">
            <Hero
              src={MainContent.HeaderImage_src}
              mobileImgSrc={MainContent.HeaderMobileImage_src}
              alt={MainContent.HeaderImage_alt}
              title={MainContent.Title}
              template="standard"
              transparent
              altMobileTitle
              colorCategory={section}
            />
          </div>
          <div className="trinity-container">
            <ContactCta
              Title="Contact us to learn how we can help your business."
              subTitle="Contact Trinity Consulting"
              subTitleLink="#"
            />
            <ContentWithSidebar
              style={{ display: 'flex' }}
              left={
                <>
                  <ContentWell content={MainContent.Content} />
                  {/*
                <FullWidthCtaWithBackground
                  title={'this is a title'}
                  content={'this is the content'}
                  link={{href:'daskl', text:'link text ›'}}
                /> */}
                </>
              }
              right={
                <>
                  <Col className="pr-sm-1 pr-md-0" sm={6} md={12}>
                    {AuthorContent && (
                      <ContactUsWidget
                        title="Contact Us"
                        data={AuthorContent}
                      />
                    )}

                    {/* <SingleWidget
                    singleLinkText="Download the Course Agenda:"
                    singleLinkContent="Air Compliance Auditing for Industrial Facilities"
                    singleLinkSub="Download Adobe Acrobat Reader"
                    src={pdfImageWidget}
                  /> */}
                  </Col>
                  <Row style={{ flex: 1 }}>
                    <Col sm={12} md={12} lg={12}>
                      <WidgetBox
                        isFromConsulting
                        relatedTrainingCourseContent={training}
                        relatedResourcesContent={resources}
                        relatedNewsContent={news}
                        relatedProductContent={product}
                      />
                    </Col>
                  </Row>
                </>
              }
            />
            {/* <Modal /> */}
          </div>
        </div>
      </div>
    );
  }

  return <Loader />;
}

export default ConsultingTertiary;
