// Just for testing data

export const title = 'Sign up for job alerts';

export const first_content =
  "Sign up to receive text messages when new staffing opportunities are posted to our Engineering & EHS Recruitment team's webpage.";

export const second_content =
  'Message & data rates may apply in the US. Once you have found a new position, simply text back “OPT OUT” and we will remove you from the list.';

export const link = 'Submit';
