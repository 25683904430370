import React from 'react';
import './image-with-overlay-text-cta.styles.scss';
import tempImage from '../../../images/staffing/widgetImg3.png';

// RP #Task 92 Policies

function ImageWithTextOverlay({
  title,
  image,
  category,
  content,
  content_link,
  inner_content,
  ispolicies = false,
}) {
  return (
    <div className="container">
      <div
        className="image-with-overlay-text background cover"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div
          className={`text-container transparent background sm-up-lighter ${category}`}
        >
          {ispolicies && (
            <a
              href={
                content_link.includes('Recruiting')
                  ? 'https://www.trinity-careers.com'
                  : content_link
              }
            >
              <h3
                className="image-widget-title"
                dangerouslySetInnerHTML={{ __html: `${title} >` }}
              />
            </a>
          )}
          {!ispolicies && (
            <h3
              className="image-widget-title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {ispolicies && (
            <p
              className="inner-content"
              dangerouslySetInnerHTML={{
                __html: inner_content.replace('&n...', ''),
              }}
            />
          )}
          {!ispolicies && (
            <p
              className="inner-content"
              dangerouslySetInnerHTML={{ __html: inner_content }}
            />
          )}
        </div>
      </div>
      <div className="content-container">
        <p className="content" dangerouslySetInnerHTML={{ __html: content }} />
        {!ispolicies && <a href={content_link}>Learn more...</a>}
      </div>
    </div>
  );
}

export default ImageWithTextOverlay;
