import React, { useState } from 'react';
import './product-thumb-carousel.styles.scss';

function ProductThumbCarousel({ images }) {
  const [mainImg, setMainImg] = useState(images[0]);

  if (mainImg) {
    return (
      <div className="product-thumb-carousel">
        <div className="main-image">
          {mainImg.src && <img src={mainImg.src} alt={mainImg.alt} />}
        </div>
        <div className="thumbnail-images">
          {images.map((img, i) => (
            <div key={i} className="thumb">
              <img
                onClick={() => setMainImg(img)}
                src={img.src}
                alt={img.alt}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
}

export default ProductThumbCarousel;
