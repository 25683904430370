import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class ResourceService {
  static async getPageWiseResource(resourcerequest) {
    return axios({
      method: 'POST',
      url:
        ENDPOINTS.PROXY + ENDPOINTS.RESOURCE_FILTER.GET_PAGE_WISE_RESOURCE_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: resourcerequest.PageNumber,
        KeywordorPhrase: resourcerequest.KeywordorPhrase,
        ResourceType: resourcerequest.ResourceType,
        Industry: resourcerequest.Industry,
        Services: resourcerequest.Services,
        Location: resourcerequest.Location,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getNewPageWiseResource(resourcerequest) {
    return axios({
      method: 'POST',
      url:
        ENDPOINTS.PROXY +
        ENDPOINTS.RESOURCE_FILTER.GET_NEW_PAGE_WISE_RESOURCE_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: resourcerequest.PageNumber,
        KeywordorPhrase: resourcerequest.KeywordorPhrase,
        ResourceType: resourcerequest.ResourceType,
        Industry: resourcerequest.Industry,
        Services: resourcerequest.Services,
        Location: resourcerequest.Location,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getResourceFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RESOURCE_FILTER.RESOURCE_FILTER_DATA,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getNewResourceFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RESOURCE_FILTER.NEW_RESOURCE_FILTER_DATA,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTorontoContentData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RESOURCE_FILTER.GET_DOCUMENT_BY_FOLDER,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}
