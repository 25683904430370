import { ENDPOINTS } from '../_config';

export const getUserStates = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_USER_STATES);
    const response = await request.json();

    return response.States;
  } catch (e) {
    return null;
  }
};
