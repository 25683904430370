import React, { useState } from 'react';
import './ehs-landing.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import FilteredContentWell from '../../../components/commons/filtered-content-well/filtered-content-well.component';
import FiliteredContentWellPagination from '../../../components/commons/filtered-content-well/filitered-content-well-pagination/filitered-content-well-pagiantion.component';
import VideoCarousel from '../../../components/software/video-carousel/video-carousel.component';
import { images } from '../../software/software-product-detail/software-product.data';
import EhsArticleListing from '../../../components/consulting/ehs-article-lisiting/ehs-article-listing.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import widgetData from '../../../components/commons/widgets/widgetBox-component/widgetBox-data';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import { defaultContent } from '../../../components/commons/content-well/content-well.data';

function getHeroCtaContent(categoryContent) {
  return categoryContent.map((section) => section.Category);
}

function EhsLanding({ page: { modules, MainContent, CategoryContent } }) {
  const [heroCtas, setHeroCtas] = useState(
    CategoryContent ? getHeroCtaContent(CategoryContent) : null
  );
  const data = widgetData.slice(0, 1);
  return (
    <div className="ehs-landing">
      <Hero
        src="sdaklf"
        mobileImgSrc=""
        alt=""
        title={modules.hero.hero_title}
        description={modules.hero.hero_description}
        template="featured"
        colorCategory="environmental-health-safety"
      />

      <div className="trinity-container ehs-container">
        <Row>
          <Col sm={6} md={6} lg={7}>
            <ContentWell contentWell={defaultContent} />
          </Col>
          {data.map((widget, k) => (
            <Col key={widget.title + k} className="widget">
              <WidgetBox
                widgetTitle={widget.widgetTitle}
                widgetContent={widget.widgetContent}
                widgetSubContent={widget.widgetSubContent}
                singleLink={widget.singleLink}
                widgetLink={widget.widgetLink}
                widgetImage={widget.widgetImage}
              />
            </Col>
          ))}
        </Row>

        <hr className="section-seperator" />

        <div className="services-product-info">
          <FilteredContentWell title="EHS SERVICES & PRODUCT INFORMATION" />
        </div>
        <hr className="section-seperator" />

        <div className="ehs-videos">
          <VideoCarousel videos={images} />
        </div>
        <hr className="section-seperator" />

        <div className="ehs-news">
          <FiliteredContentWellPagination title="EHS NEWS" />
        </div>
        <hr className="section-seperator" />

        <div className="ehs-resources">
          <EhsArticleListing />
        </div>
      </div>
    </div>
  );
}

export default EhsLanding;
