import axios from 'axios';
import {
  ENDPOINTS,
  SITE_SEARCH_API_KEY,
  SITE_SEARCH_AUTHORITY,
} from '../../js/api/_config';

export const getEHSPublicationDataFromAPI = async () => {
  try {
    const request = await fetch(ENDPOINTS.EHS.GET_EHS_PUBLICATION);
    if (request.status === 204) {
      return request;
    }
    return await request.json();
  } catch (e) {
    return null;
  }
};

export const searchForEHSPublication = async (requestBody) =>
  axios({
    method: 'POST',
    url: ENDPOINTS.EHS.EHS_PUBLICATION_SEARCH,
    headers: {
      'api-key': SITE_SEARCH_API_KEY,
      'content-type': 'application/json',
      authority: SITE_SEARCH_AUTHORITY,
    },
    data: requestBody,
  })
    .then((e) => e.data)
    .catch((e) => (e.response !== undefined ? '' : ''));
