import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Multiselect } from 'multiselect-react-dropdown';
import './search-by-filter.styles.scss';
import OfficeDetails, {
  USOfficeDetails,
} from '../office-details/office-details.component';

// RP #Task 94 Location
function SearchByFilter({
  allCountry,
  allState,
  allLocation,
  countryData,
  stateData,
  locationData,
  selectedRegion,
  selectedCountry,
  selectedState,
}) {
  // filter data
  const [filterCountryData, setFilterCountryData] = useState([]);
  const [filterStateData, setFilterStateData] = useState([]);
  const [filterLocationData, setFilterLocationData] = useState([]);
  const [filterSelectedState, setFilterSelectedState] = useState([]);
  const [filterSelectedCountry, setFilterSelectedCountry] = useState([]);

  useEffect(() => {
    setFilterCountryData(countryData);
    setFilterStateData(stateData);
    setFilterLocationData(locationData);
    setFilterSelectedState(selectedState);
    setFilterSelectedCountry(selectedCountry);
  }, [countryData, stateData, locationData, selectedState, selectedCountry]);

  // Country
  const onCountrySelect = (selectedList, selectedItem) => {
    setFilterSelectedCountry(selectedList);
    filterCountryContent(selectedList);
  };

  const onCountryRemove = (selectedList, removedItem) => {
    setFilterSelectedCountry(selectedList);
    filterCountryContent(selectedList);
  };

  // State
  const onStateSelect = (selectedList, selectedItem) => {
    setFilterSelectedState(selectedList);
    filterStateContent(selectedList);
  };

  const onStateRemove = (selectedList, removedItem) => {
    setFilterSelectedState(selectedList);
    filterStateContent(selectedList);
  };

  // filter country
  const filterCountryContent = (filterData) => {
    const newStateData = [];
    const newLocationData = [];

    if (filterData.length > 0 && filterData !== []) setFilterSelectedState([]);

    if (filterData === [] || filterData.length === 0) {
      allState.forEach((group) => {
        if (
          selectedRegion === group.RegionName ||
          selectedRegion === 'All Regions'
        ) {
          newStateData.push({
            StateName: group.StateName,
            SortOrder: group.SortOrder,
            StateUrl: group.StateUrl,
            RegionName: group.RegionName,
            CountryName: group.CountryName,
            Active: true,
            VisitStateLink: group.VisitStateLink,
            isShowStateLink: group.isShowStateLink,
          });
        }
      });

      if (
        filterSelectedState === null ||
        filterSelectedState === [] ||
        filterSelectedState.length === 0
      ) {
        allLocation.forEach((group) => {
          if (
            selectedRegion === group.RegionName ||
            selectedRegion === 'All Regions'
          ) {
            newLocationData.push({
              Title: group.Title,
              ServiceName: group.ServiceName,
              Address1: group.Address1,
              Address2: group.Address2,
              City: group.City,
              Zip: group.Zip,
              PhoneNumber: group.PhoneNumber,
              FaxNumber: group.FaxNumber,
              DirectionLink: group.DirectionLink,
              VisitOfficeLink: group.VisitOfficeLink,
              VisitStateLink: group.VisitStateLink,
              Content: group.Content,
              Latitude: group.Latitude,
              Longitude: group.Longitude,
              HeaderImage: group.HeaderImage,
              MetaTitle: group.MetaTitle,
              MetaDescription: group.MetaDescription,
              Url: group.Url,
              RegionName: group.RegionName,
              CountryName: group.CountryName,
              StateName: group.StateName,
              Active: true,
              OfficeCountryName: group.OfficeCountryName,
              OfficeStateName: group.OfficeStateName,
              isShowStateLink: group.isShowStateLink,
            });
          }
        });
      } else {
        filterSelectedState.forEach((state) => {
          allLocation.forEach((group) => {
            if (state.StateName === group.StateName) {
              newLocationData.push({
                Title: group.Title,
                ServiceName: group.ServiceName,
                Address1: group.Address1,
                Address2: group.Address2,
                City: group.City,
                Zip: group.Zip,
                PhoneNumber: group.PhoneNumber,
                FaxNumber: group.FaxNumber,
                DirectionLink: group.DirectionLink,
                VisitOfficeLink: group.VisitOfficeLink,
                VisitStateLink: group.VisitStateLink,
                Content: group.Content,
                Latitude: group.Latitude,
                Longitude: group.Longitude,
                HeaderImage: group.HeaderImage,
                MetaTitle: group.MetaTitle,
                MetaDescription: group.MetaDescription,
                Url: group.Url,
                RegionName: group.RegionName,
                CountryName: group.CountryName,
                StateName: group.StateName,
                Active: true,
                OfficeCountryName: group.OfficeCountryName,
                OfficeStateName: group.OfficeStateName,
                isShowStateLink: group.isShowStateLink,
              });
            }
          });
        });
      }
      setFilterStateData(newStateData);
      setFilterLocationData(newLocationData);
    } else {
      filterData.forEach((country) => {
        allState.forEach((group) => {
          if (country.CountryName === group.CountryName) {
            newStateData.push({
              StateName: group.StateName,
              SortOrder: group.SortOrder,
              StateUrl: group.StateUrl,
              RegionName: group.RegionName,
              CountryName: group.CountryName,
              Active: true,
              VisitStateLink: group.VisitStateLink,
              isShowStateLink: group.isShowStateLink,
            });
          }
        });

        allLocation.forEach((group) => {
          if (country.CountryName === group.CountryName) {
            newLocationData.push({
              Title: group.Title,
              ServiceName: group.ServiceName,
              Address1: group.Address1,
              Address2: group.Address2,
              City: group.City,
              Zip: group.Zip,
              PhoneNumber: group.PhoneNumber,
              FaxNumber: group.FaxNumber,
              DirectionLink: group.DirectionLink,
              VisitOfficeLink: group.VisitOfficeLink,
              VisitStateLink: group.VisitStateLink,
              Content: group.Content,
              Latitude: group.Latitude,
              Longitude: group.Longitude,
              HeaderImage: group.HeaderImage,
              MetaTitle: group.MetaTitle,
              MetaDescription: group.MetaDescription,
              Url: group.Url,
              RegionName: group.RegionName,
              CountryName: group.CountryName,
              StateName: group.StateName,
              Active: true,
              OfficeCountryName: group.OfficeCountryName,
              OfficeStateName: group.OfficeStateName,
              isShowStateLink: group.isShowStateLink,
            });
          }
        });
      });
      setFilterStateData(newStateData);
      setFilterLocationData(newLocationData);
    }
  };

  // filter state
  const filterStateContent = (filterData) => {
    const newLocationData = [];

    if (filterData === [] || filterData.length === 0) {
      if (
        filterSelectedCountry === [] ||
        filterSelectedCountry === null ||
        filterSelectedCountry.length === 0
      ) {
        allLocation.forEach((group) => {
          if (
            selectedRegion === group.RegionName ||
            selectedRegion === 'All Regions'
          ) {
            newLocationData.push({
              Title: group.Title,
              ServiceName: group.ServiceName,
              Address1: group.Address1,
              Address2: group.Address2,
              City: group.City,
              Zip: group.Zip,
              PhoneNumber: group.PhoneNumber,
              FaxNumber: group.FaxNumber,
              DirectionLink: group.DirectionLink,
              VisitOfficeLink: group.VisitOfficeLink,
              Content: group.Content,
              Latitude: group.Latitude,
              Longitude: group.Longitude,
              HeaderImage: group.HeaderImage,
              MetaTitle: group.MetaTitle,
              MetaDescription: group.MetaDescription,
              Url: group.Url,
              RegionName: group.RegionName,
              CountryName: group.CountryName,
              StateName: group.StateName,
              Active: true,
              OfficeCountryName: group.OfficeCountryName,
              OfficeStateName: group.OfficeStateName,
              isShowStateLink: group.isShowStateLink,
            });
          }
        });
      } else {
        filterSelectedCountry.forEach((country) => {
          allLocation.forEach((group) => {
            if (country.CountryName === group.CountryName) {
              newLocationData.push({
                Title: group.Title,
                ServiceName: group.ServiceName,
                Address1: group.Address1,
                Address2: group.Address2,
                City: group.City,
                Zip: group.Zip,
                PhoneNumber: group.PhoneNumber,
                FaxNumber: group.FaxNumber,
                DirectionLink: group.DirectionLink,
                VisitOfficeLink: group.VisitOfficeLink,
                VisitStateLink: group.VisitStateLink,
                Content: group.Content,
                Latitude: group.Latitude,
                Longitude: group.Longitude,
                HeaderImage: group.HeaderImage,
                MetaTitle: group.MetaTitle,
                MetaDescription: group.MetaDescription,
                Url: group.Url,
                RegionName: group.RegionName,
                CountryName: group.CountryName,
                StateName: group.StateName,
                Active: true,
                OfficeCountryName: group.OfficeCountryName,
                OfficeStateName: group.OfficeStateName,
                isShowStateLink: group.isShowStateLink,
              });
            }
          });
        });
      }
      setFilterLocationData(newLocationData);
    } else {
      filterData.forEach((state) => {
        allLocation.forEach((group) => {
          if (state.StateName === group.StateName) {
            newLocationData.push({
              Title: group.Title,
              ServiceName: group.ServiceName,
              Address1: group.Address1,
              Address2: group.Address2,
              City: group.City,
              Zip: group.Zip,
              PhoneNumber: group.PhoneNumber,
              FaxNumber: group.FaxNumber,
              DirectionLink: group.DirectionLink,
              VisitOfficeLink: group.VisitOfficeLink,
              Content: group.Content,
              Latitude: group.Latitude,
              Longitude: group.Longitude,
              HeaderImage: group.HeaderImage,
              MetaTitle: group.MetaTitle,
              MetaDescription: group.MetaDescription,
              Url: group.Url,
              RegionName: group.RegionName,
              CountryName: group.CountryName,
              StateName: group.StateName,
              Active: true,
              OfficeCountryName: group.OfficeCountryName,
              OfficeStateName: group.OfficeStateName,
              isShowStateLink: group.isShowStateLink,
            });
          }
        });
      });
      setFilterLocationData(newLocationData);
    }
  };

  // RP - SD 161 - Locations page : Title
  if (filterCountryData && filterStateData && filterLocationData) {
    return (
      <>
        {/* KT add class filter-bar-container-changed 10-29-2021 */}
        <Row className="filter-bar-container filter-bar-container-changed">
          <Col xs={12} sm={4}>
            {' '}
            {/* KT change column sm-3 to sm-4 10-29-2021 */}
            <label className="search-title">
              Search By: Country/State/Province
            </label>
          </Col>
          <Col xs={12} sm={8} className="state">
            {' '}
            {/* KT change column sm-3 to sm-4 10-29-2021 */}
            <Row className="states-countries-container">
              <Col xs={12} sm={5}>
                <div className="countries-container">
                  <p className="label">Country</p>
                  <form autoComplete="off">
                    <Multiselect
                      options={
                        filterCountryData &&
                        filterCountryData.sort((a, b) =>
                          a.CountryName > b.CountryName ? -1 : 1
                        )
                      }
                      displayValue="CountryName"
                      showCheckbox
                      closeIcon="cancel"
                      id="country-search"
                      selectedValues={filterSelectedCountry}
                      onSelect={onCountrySelect}
                      onRemove={onCountryRemove}
                    />
                  </form>
                </div>
              </Col>
              <Col xs={12} sm={5}>
                <div className="states-container">
                  <p className="label">State</p>
                  <form autoComplete="off">
                    <Multiselect
                      options={filterStateData}
                      displayValue="StateName"
                      showCheckbox
                      closeIcon="cancel"
                      id="state-search"
                      selectedValues={filterSelectedState}
                      onSelect={onStateSelect}
                      onRemove={onStateRemove}
                    />
                  </form>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4} className="search-wrapper" />
        </Row>
        {/* <CountryDetails
          country={"United States"}
          // selectedCountry={selectedCountry}
          // region={"North America"}
          selectedRegion={selectedRegion}
        /> */}
        <USOfficeDetails
          locationData={filterLocationData}
          stateData={filterStateData}
        />
        <OfficeDetails
          locationData={filterLocationData}
          stateData={filterStateData}
        />
      </>
    );
  }
  return null;
}

export default SearchByFilter;
