import React, { Component } from 'react';
import { locationsData } from '../google-map/locations-map.data';

import './sidebar.styles.scss';

class SidebarLocations extends Component {
  onStateClick = (props) => {};

  render() {
    const data = locationsData;
    return (
      <div className="sidebar-container">
        {data.map((location, i) => (
          <div className="inner-wrapper">
            <div className="each-location" key={i}>
              <p className="state">
                <a href="#" onClick={this.onStateClick}>
                  {location.state}
                </a>
              </p>
              <p className="address">{location.address_1}</p>
              <p className="address">{location.address_2}</p>
              <p className="city-zip">{location.city_zip}</p>
              <p className="phone">{location.phone}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default SidebarLocations;
