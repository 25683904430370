export const SoftwareResourceActionTypes = {
  GET_PAGEWISE_SOFTWARE_RESOURCE: 'GET_PAGEWISE_SOFTWARE_RESOURCE',
  TOTAL_ITEM_COUNT: 'TOTAL_ITEM_COUNT',
  SOFTWARE_RESOURCE_LOADING: 'SOFTWARE_RESOURCE_LOADING',
  ACTIVE_PAGE_NUMBER: 'ACTIVE_PAGE_NUMBER',
  FILTER_LOADING: 'FILTER_LOADING',
  KEYWORD_FILTER: 'KEYWORD_FILTER',
  MODELING_CATEGORY_FILTER: 'MODELING_CATEGORY_FILTER',
  PRODUCT_FILTER: 'PRODUCT_FILTER',
  SERVICE_FILTER: 'SERVICE_FILTER',
  INDUSTRY_FILTER: 'INDUSTRY_FILTER',
  LOCATION_FILTER: 'LOCATION_FILTER',
  SOFTWARE_RESOURCE_TYPE: 'SOFTWARE_RESOURCE_TYPE',
};
