import moment from 'moment';
import { TrainingNewAzureSearchTypesConstants } from './training-azure-search.types';
import { compareValues, groupBy } from '../../../utilities/CommonUtilities';

const TRAINING_AZURE_SEARCH_DATA = {
  lastSearchData: {},
  currentSearchData: {},
  freeCourseCount: 0,
};

function shiftArrayToRight(arr, places) {
  for (let i = 0; i < places; i++) {
    arr.unshift(arr.pop());
  }
  return arr;
}

function removeDuplicates(arr, equals) {
  const originalArr = arr.slice(0);
  let i;
  let len;
  let val;
  arr.length = 0;

  for (i = 0, len = originalArr.length; i < len; ++i) {
    val = originalArr[i];
    if (!arr.some((item) => equals(item, val))) {
      arr.push(val);
    }
  }

  return arr;
}

function equalTraining(leftOne, rightOne) {
  return leftOne.ProductVariantSKU === rightOne.ProductVariantSKU;
}

export const trainingAzureSearchData = (
  state = TRAINING_AZURE_SEARCH_DATA,
  action
) => {
  switch (action.type) {
    case TrainingNewAzureSearchTypesConstants.TRAINING_NEW_AZURE_SEARCH:
      if (action.payload) {
        const payloadData = action.payload;

        let totalDocumentCount = action.payload['@odata.count'];
        const searchFacetsObject = payloadData['@search.facets'];

        let preparedSearchFacets = [];
        let preparedObject = {};
        let datePublished = {};

        if (searchFacetsObject) {
          datePublished = {
            startDate: moment('1975-03-04T10:57:02.444Z'),
            endDate: moment().toISOString(),
          };

          const changedFacets = shiftArrayToRight(
            Object.keys(searchFacetsObject),
            1
          );

          preparedSearchFacets = changedFacets
            .filter((e) => e !== 'DatePublished')
            .map((e) => {
              const value = searchFacetsObject[e].filter(
                (element) => element.value && element.value.length !== 0
              );
              return {
                key: e,
                value: value.sort(compareValues('value', '')),
              };
            });
        }

        // RP - ADVANCED SEARCH FILTERS
        // payloadData.value = payloadData.value ? payloadData.value.sort((a, b) => {
        //     if (a && a.DatePublished && b && b.DatePublished)
        //         return a.DatePublished > b.DatePublished ? 1 : -1
        //     return 0
        // }) : [];

        const newArray = removeDuplicates(payloadData.value, equalTraining);

        let preparedGroupedList = Object.values(
          groupBy(removeDuplicates(payloadData.value, equalTraining), 'SKU')
        );

        if (
          action.lastQuery?.orderby !== undefined &&
          action.lastQuery?.orderby === 'DisplayName'
        ) {
          preparedGroupedList = preparedGroupedList.sort((a, b) => {
            const key = 'DisplayName';
            const varA =
              typeof a[0][key] === 'string'
                ? a[0][key].toUpperCase()
                : a[0][key];
            const varB =
              typeof b[0][key] === 'string'
                ? b[0][key].toUpperCase()
                : b[0][key];

            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return comparison;
          });
        }
        if (action.lastQuery?.orderby === undefined) {
          preparedGroupedList = preparedGroupedList.sort((a, b) => {
            const key = 'DisplayName';
            const varA =
              typeof a[0][key] === 'string'
                ? a[0][key].toUpperCase()
                : a[0][key];
            const varB =
              typeof b[0][key] === 'string'
                ? b[0][key].toUpperCase()
                : b[0][key];

            let comparison = 0;
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
            return comparison;
          });
        }
        if (
          action.lastQuery?.orderby !== undefined &&
          (action.lastQuery?.orderby === 'None' ||
            action.lastQuery?.orderby === 'relevance')
        ) {
          preparedGroupedList = preparedGroupedList.sort((a, b) => {
            const key = '@search.score';
            const va = parseFloat(a[0][key]);
            const vb = parseFloat(b[0][key]);

            if (va.length === vb.length) {
              return va > vb ? 1 : -1; // same length condition
            }
            return va.length > vb.length ? 1 : -1; // comparing length of string
          });
        }
        if (
          action.lastQuery?.orderby !== undefined &&
          action.lastQuery?.orderby === 'DatePublished asc'
        ) {
          let lstFormatData = [];
          const lstOnDemand = payloadData.value.filter(
            (x) => x.SessionTypeName === 'OnDemand'
          );
          const lstNotOnDemand = payloadData.value.filter(
            (x) => x.SessionTypeName !== 'OnDemand'
          );
          lstFormatData = lstFormatData.concat(lstNotOnDemand);

          lstFormatData = lstFormatData
            ? lstFormatData.sort((a, b) => {
                if (a && a.DatePublished && b && b.DatePublished)
                  return a.DatePublished > b.DatePublished ? 1 : -1;
                return 0;
              })
            : [];

          lstFormatData = lstFormatData.concat(lstOnDemand);
          preparedGroupedList = Object.values(
            groupBy(removeDuplicates(lstFormatData, equalTraining), 'SKU')
          );
        }

        totalDocumentCount = preparedGroupedList.length;

        preparedObject = {
          ...preparedObject,
          ...action.payload,
          totalDocumentCount,
          preparedSearchFacets,
          datePublished,
          preparedGroupedList,
        };

        const freecount =
          payloadData &&
          payloadData.value &&
          payloadData.value.filter((x) => x.Amount === 0);

        return {
          ...state,
          currentSearchData: preparedObject,
          freeCourseCount: freecount.length,
        };
      }
      return {
        ...state,
      };

    case TrainingNewAzureSearchTypesConstants.TRAINING_NEW_LAST_SEARCH:
      if (action.payload) {
        return {
          ...state,
          lastSearchData: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
