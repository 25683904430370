import React, { useState } from 'react';

import LoginForm from '../../../components/ecommerce/forms/login-form/login-form.component';
import EmailLoginForm from '../../../components/ecommerce/forms/email-form/email-login-form.component';
import AdminLoginForm from '../../../components/ecommerce/forms/admin-form/admin-login-form.component';

function AdminLogin() {
  return (
    <div className="login">
      <AdminLoginForm />
    </div>
  );
}

export default AdminLogin;
