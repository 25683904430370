import React, { Suspense } from 'react';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './policies.styles.scss';

function NewPoliciesIndividualLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-policies-individual-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-policies-individual-content-component')
  );

  return (
    <div className={`policy policies-individual-category ${section || ''}`}>
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          section={section}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} section={section} />
      </Suspense>
    </div>
  );
}

export default NewPoliciesIndividualLandingPage;
