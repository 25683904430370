import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

// #RP - Task 152 - Related Data
export class RelatedDataServices {
  // training
  static async getRelatedTrainingCourseServiceData(courseName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_TRAINING_COURSE_SERVICE_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        serviceName: courseName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedTrainingCourseIndustriesData(courseName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_TRAINING_COURSE_INDUSTRIES_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        industryName: courseName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedTrainingCourseProductData(courseName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_TRAINING_COURSE_PRODUCT_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        softwareName: courseName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedTrainingCourseLocationData(courseName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_TRAINING_COURSE_LOCATION_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        stateName: courseName,
        pageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  // RP - SD : 93 -- Related Resources Same title category changes
  static async getRelatedResourcesData(resourceName, pagename, category) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_RESOURCES_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        Resourcesname: resourceName,
        PageName: pagename,
        Category:
          category &&
          category !== undefined &&
          category !== 'Telerik.OpenAccess.TrackedList`1[System.Guid]' &&
          category,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedNewsData(newsName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_NEWS_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        Newsname: newsName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedProductConsultingData(productName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_PRODUCT_CONSULTING_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        Productname: productName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getRelatedProductIndustriesData(productName, pagename) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.RELATED_ITEM.GET_PRODUCT_INDUSTRIES_DATA,
      headers: {
        Accept: 'application/json',
      },
      params: {
        Productname: productName,
        PageName: pagename,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}

export const getAuthToken = async () => {
  const tokenJson = {
    client_id: 'trinityfrontend',
    client_secret: '2658e63b2342470d94177c546919bafg',
    grant_type: 'password',
    scope: 'openid offline_access',
    username: 'test@gmail.com',
    password: 'test@123',
    membershipProvider: 'CustomMembershipProvider',
  };

  let formBody = [];
  for (const property in tokenJson) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(tokenJson[property]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  }
  formBody = formBody.join('&');

  const location = ENDPOINTS.PROXY + ENDPOINTS.AUTHENTICATION;
  const settings = {
    method: 'POST',
    headers: {
      Origin: 'trinity-fe.imag-stage.com',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: formBody,
  };
  try {
    const fetchResponse = await fetch(location, settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
};
