import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentWell from '../../../../components/commons/content-well/content-well.component';
import { defaultContent } from '../../../../components/commons/content-well/content-well.data';
import ResumeBlock from '../../../../components/about/careers/careers-indiv/resume-info/resume.component';
import heroImageSrcStaffing from '../../../../images/staffing-individual/ehs-primary-background.png';
import heroImageSrcCareer from '../../../../images/career-individual/ehs-primary-background.png';
import Hero from '../../../../components/commons/hero/hero.component';
import { getApplyForJobPageData } from '../../../../js/api/applyforjob/_ind-positions.data';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './ind-positions.styles.scss';

function IndivPositions(
  { page: { MainContent, HiringManagerContent, RecruitingManagerContent } },
  name
) {
  // #RP - Task 70 - Apply For Job
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getApplyForJobPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(
          MainContent &&
            MainContent != null &&
            MainContent != undefined &&
            MainContent != ''
            ? MainContent
            : getResponse.MainContent
        );
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.State != undefined)
  ) {
    return (
      <div className="individual-container">
        <Hero
          src={
            MainContent && MainContent != null
              ? MainContent &&
                Object.keys(MainContent).length > 0 &&
                MainContent.url.split('/')[1] === 'staffinges'
                ? heroImageSrcStaffing
                : heroImageSrcCareer
              : status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            MainContent && MainContent != null
              ? MainContent &&
                Object.keys(MainContent).length > 0 &&
                MainContent.url.split('/')[1] === 'staffinges'
                ? heroImageSrcStaffing
                : heroImageSrcCareer
              : status != noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory={
            MainContent &&
            Object.keys(MainContent).length > 0 &&
            MainContent.url.split('/')[1] === 'staffinges'
              ? 'staffing'
              : 'policies'
          }
          description={MainContent.HeaderContent}
        />
        <div className="trinity-container">
          <Row>
            <Col className="top-container">
              {responseMainContent && (
                <ContentWell content={responseMainContent.Content} />
              )}
            </Col>
            <Col sm={6} md={4} className="resume-container">
              <ResumeBlock
                data={responseDropDownContent}
                mainData={responseMainContent}
                status={status}
                HiringManagerContent={HiringManagerContent}
                RecruitingManagerContent={RecruitingManagerContent}
                name={name}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default IndivPositions;
