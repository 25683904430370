import React, { useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import BoxCta from '../../../components/commons/cta/box-cta/box-cta.component';
// import heroImageSrc from "../../../../images/consulting-ehs/ehs-secondary-b-hero.png";
import { sortCategoryContent } from '../../../js/helpers/_sort-ctas';

import './consulting-individual-category.styles.scss';
import {
  consultingproductdata,
  newsdata,
  resourcesdata,
  trainingcourseservicedata,
} from '../../../redux/widget-box/widget-box.action';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function ConsultingSecondaryB({
  page: { MainContent, CategoryContent, ResourceContent },
  section,
}) {
  // const data = widgetData;

  const resourceResponse = {
    type: 'RELATED_RESOURCES_DATA',
    payload: ResourceContent,
    status: true,
  };

  const dispatch = useDispatch();
  const news = useSelector(({ widget }) => widget.news);
  const resourceRedux = useSelector(({ widget }) => widget.resources);
  const resources =
    ResourceContent != undefined &&
    ResourceContent != null &&
    ResourceContent.length > 0
      ? resourceResponse
      : resourceRedux;
  const training = useSelector(({ widget }) => widget.trainingcourseservice);
  const product = useSelector(({ widget }) => widget.consultingproduct);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(await newsdata(window.location.pathname, 'consulting'));
      if (
        ResourceContent == undefined ||
        ResourceContent == null ||
        ResourceContent.length <= 0
      )
        dispatch(await resourcesdata(window.location.pathname, 'consulting'));
      // dispatch(await trainingcourseproductdata(window.location.pathname,"consulting"));
      dispatch(
        await trainingcourseservicedata(window.location.pathname, 'consulting')
      );
      dispatch(
        await consultingproductdata(window.location.pathname, 'consulting')
      );
    };
    fetchData();
  }, []);

  if (
    MainContent &&
    news != undefined &&
    news.status &&
    resources != undefined &&
    resources.status &&
    training != undefined &&
    training.status &&
    product != undefined &&
    product.status
  ) {
    return (
      <div className={`consulting consulting-secondary-b ${section || ''}`}>
        <div className="industries industries-lp">
          <div className="hero_indus">
            <Hero
              src={MainContent.HeaderImage_src}
              mobileImgSrc={MainContent.HeaderMobileImage_src}
              alt={MainContent.HeaderImage_alt}
              title={MainContent.Title}
              description={MainContent.Content}
              template="featured" // RK--changed the standrad to featured for testing the Consulting 3rd Level
              transparent
              altLayout
              downArrow={false}
              colorCategory={section}
            />
          </div>
          <div
            style={{ marginTop: '4rem' }}
            className="sm-up-trinity-container"
          >
            <ContactCta
              Title="Contact us to learn how we can help your business."
              subTitle="Contact Trinity Consulting"
              subTitleLink="#"
            />

            <ContentWithSidebar
              left={
                <Row className="no-gutter-around mb-3 mb-sm-4 mb-md-0">
                  {CategoryContent &&
                    sortCategoryContent(CategoryContent).map(
                      ({ Category }, i) => (
                        <Col
                          sm={6}
                          lg={4}
                          className="pr-sm-1 plr-lg-small pl-sm-1 pb-2"
                        >
                          <BoxCta
                            title={Category.Title}
                            content={Category.IntroText}
                            link={Category.url}
                            categories={[
                              {
                                title: 'enevironment',
                                id: section,
                              },
                            ]}
                            key={i}
                            className={i % 2 === 0 ? 'darker' : ''}
                          />
                        </Col>
                      )
                    )}
                </Row>
              }
              right={
                <Row>
                  <Col sm={6} md={12} lg={12}>
                    <WidgetBox
                      isFromConsulting
                      relatedTrainingCourseContent={training}
                      relatedResourcesContent={resources}
                      relatedNewsContent={news}
                      relatedProductContent={product}
                    />
                  </Col>
                </Row>
              }
            />
          </div>
        </div>
      </div>
    );
  }

  return <Loader />;
}

export default ConsultingSecondaryB;
