import { EHSActionTypes } from './ehs.types';
import { staticEHSSearchData } from './ehs.action';
import { compareValues } from '../../utilities/CommonUtilities';

const EHS_INITIAL_STATE = {
  allEHSList: '',
  filteredEHSList: '',
  ehsPublicationData: {},

  ehsSearchPublicationData: {},
  featuredPublicationData: [],
  lastEHSSearch: staticEHSSearchData,
};

export const ehsQuarterly = (state = EHS_INITIAL_STATE, action) => {
  switch (action.type) {
    case EHSActionTypes.GET_ALL_EHS_DATA:
      if (action.payload) {
        return {
          ...state,
          allEHSList: action.payload,
          filteredEHSList: action.payload,
        };
      }
      return {
        ...state,
      };

    case EHSActionTypes.EHS_PUBLICATION_DATA:
      if (action.payload) {
        return {
          ...state,
          ehsPublicationData: action.payload,
        };
      }
      return {
        ...state,
      };

    case EHSActionTypes.EHS_SEARCH_DATA:
      console.log(state);

      if (action.payload) {
        const payLoadData = action.payload;
        const searchFacetsObject = payLoadData['@search.facets'];
        let preparedSearchFacets = [];

        if (searchFacetsObject) {
          preparedSearchFacets = Object.keys(searchFacetsObject).map((e) => {
            const value = searchFacetsObject[e].filter(
              (element) => element.value.length !== 0
            );
            return value.map((e) => ({
              cat: e.count,
              key: e.value,
            }));
          });
        }

        const valuesArray = action.payload.value
          .map((e) => Object.assign(e, { SortOrder: parseInt(e.SortOrder) }))
          .sort(compareValues('SortOrder'));

        const preparedObject = Object.assign(
          action.payload,
          { preparedSearchFacets },
          { value: valuesArray }
        );

        // TODO Need to change at release time
        const featuredValues =
          state.featuredPublicationData.length > 0
            ? state.featuredPublicationData
            : valuesArray
                .filter((element) => element.IsFeaturePublication === 'True')
                .sort(compareValues('FeatureSort'));

        return {
          ...state,
          ehsSearchPublicationData: preparedObject,
          featuredPublicationData: featuredValues,
        };
      }
      return {
        ...state,
      };

    case EHSActionTypes.EHS_SEARCH_LAST_QUERY:
      if (action.payload) {
        return {
          ...state,
          lastEHSSearch: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
