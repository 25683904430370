import React from 'react';
// import { requirementsData } from "./system-requirements.data";
import './system-requirements.styles.scss';

function RequirementsContentWell({
  title,
  innerTitle1,
  innerTitle2,
  innerContent,
}) {
  return (
    <div className="systems-container">
      <h2>{title}</h2>
      <div className="inner-wrapper">
        <p className="inner-title">{innerTitle1}</p>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: innerContent }}
        />
        {/* <p className="content">

            <div dangerouslySetInnerHTML={{ __html: innerContent }}></div>

        </p> */}

        {/* {requirementsData.map((item) => (
          <>
            <p className="content">{item.content}</p>
          </>
        ))} */}
        {/* <p className="inner-title ">{innerTitle2}</p>
        <p className="content">
          32-bit or 64-bit versions of Windows 10, Windows 8, Windows 7, Windows
          Vista, Windows Server 2012 or Windows Server 2008 Microsoft .NET
          Framework 4.0 or later
        </p> */}
      </div>
    </div>
  );
}

export default RequirementsContentWell;
