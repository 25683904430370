import React, { useEffect } from 'react';
import './technology-lp.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
// import TechnologyCta from "../../../components/technology/technology-ctabackup/technology-cta.component";
// import TechnologyCtaLink from "../../../components/technology/technology-ctabackup/technology-cta-link.component";
import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import TechnologyCta from '../../../components/technology/technology-cta/technology-cta.component';
// import heroImageSrc from "../../../images/technology/tech_services_hero.png";
// import Tech1 from "../../../images/technology/tech-1.svg";
// import Tech2 from "../../../images/technology/tech-2.svg";
// import { bkCtaDesc2 } from "./technology-lp.data";
import {
  newsdata,
  resourcesdata,
} from '../../../redux/widget-box/widget-box.action';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function TechnologyLp({ page: { MainContent, CategoryContent } }) {
  /*  const data = widgetData.slice(0, 2);

      const [ctas, setCtas] = useState(
        CategoryContent ? setCategoriesToCtas(CategoryContent) : null
      ); */

  const dispatch = useDispatch();

  const defaultResponse = {
    type: '',
    payload: [],
    status: true,
  };

  const news = useSelector(({ widget }) => widget.news);
  const resources = useSelector(({ widget }) => widget.resources);

  const training = defaultResponse;
  const product = defaultResponse;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(await newsdata(window.location.pathname, 'technology'));
      dispatch(await resourcesdata(window.location.pathname, 'technology'));
    };
    fetchData();
  }, []);

  if (
    MainContent &&
    news != undefined &&
    news.status &&
    resources != undefined &&
    resources.status &&
    training != undefined &&
    training.status &&
    product != undefined &&
    product.status
  ) {
    return (
      <div className="technology-services-lp technology">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          colorCategory="technology"
          downArrow
        />
        <div className="sm-up-trinity-container">
          <ContactCta
            Title="Contact us to learn how we can help your business."
            subTitle="Contact Trinity Consulting"
            subTitleLink="#"
          />

          <TechnologyCta
            linkBox
            isFromTechnology
            bgImage={MainContent.EHSImage_src}
            content={MainContent.EHSIntro}
            linearColor="rgba(193, 76, 43, 0.74), rgba(193, 76, 43, 0.74)"
          />

          <TechnologyCta
            bgImage={MainContent.BreezeImage_src}
            isFromTechnology
            linearColor="rgba(49, 56, 48, 0.74), rgba(49, 56, 48, 0.74)"
            content={MainContent.BreezeIntro}
          />
          <Row>
            <Col sm={6} md={6} lg={6} className="widget-box">
              <WidgetBox
                relatedTrainingCourseContent={training}
                relatedResourcesContent={resources}
                relatedNewsContent={news}
                relatedProductContent={product}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default TechnologyLp;
