import React, { useCallback, useEffect, useState } from 'react';
import './filtered-cta-grid.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FilterControls from './filter-controls/filter-controls.component.jsx';
import BoxCta from '../cta/box-cta/box-cta.component';

import { setCategoriesToCtas } from '../../../js/helpers/general.functions.js';
import { colorClassMatcher } from '../../../js/helpers/_color-class-matcher';
import { sortCtas } from '../../../js/helpers/_sort-ctas';

function getCategories(ctas) {
  if (!ctas) return null;

  if (ctas[0].categories) {
    return ctas.reduce((allCategories, { categories }) => {
      categories.forEach((category) => {
        allCategories[category.title] = category.id;
      });

      return allCategories;
    }, {});
  }
  return ctas
    .filter((x) => x.SubContent.length > 0)
    .reduce((allCategories, cta) => {
      allCategories[cta.Category.Title] = colorClassMatcher(
        cta.Category.Section
      );
      return allCategories;
    }, {});
}

function swap(arr) {
  [arr[1], arr[2]] = [arr[2], arr[1]];
  return arr;
}

function FilteredCtaGrid({
  bgImage,
  ctas,
  onlySmallCtas,
  activeStarterSection,
  optionTitle,
  isFromSoftware,
  isFromConsulting,
  isFromTraining,
}) {
  const [allCtas, setAllCtas] = useState([]);
  const [activeContent, setActiveContent] = useState(null);
  const [categories] = useState(
    ctas.length > 0
      ? getCategories(isFromConsulting ? swap(ctas.reverse()) : ctas)
      : null
  );

  // stopping function from rerendering select list (this is passed as prop)
  const filterContent = useCallback(
    (e) => {
      let activeCategory = e;
      if (e.target) activeCategory = e.target.getAttribute('data-category');

      if (activeCategory === null) activeCategory = e.target.value;
      if (activeCategory === 'all') return setActiveContent(allCtas);

      const activeCtas = allCtas.filter(
        (cta) => cta.categories[0].id === activeCategory
      );

      // defaulting to all ctas if no matches
      setActiveContent(activeCtas.length ? activeCtas : allCtas);
    },
    [allCtas]
  );

  useState(() => {
    if (ctas.length > 0) {
      let ctasUpdated = setCategoriesToCtas(ctas);
      ctasUpdated = sortCtas(ctasUpdated);

      setAllCtas(ctasUpdated);
    }
  }, []);

  useEffect(() => {
    if (allCtas.length) {
      if (activeStarterSection) {
        filterContent(activeStarterSection);
      } else {
        setActiveContent(allCtas);
      }
    }
  }, [allCtas]);

  const backgroundImage = {
    backgroundImage: bgImage ? `url('${bgImage}')` : '',
    backgroundSize: bgImage ? 'cover' : '',
  };

  return (
    <div
      id="filiteredGrid"
      className={`filtered-cta-grid 
        ${bgImage ? 'bg-img' : 'module-spacer'}
        ${onlySmallCtas ? 'only-small-ctas' : 'standard'}
      `}
    >
      <div style={backgroundImage} aria-hidden="true" className="bg-image" />
      <FilterControls
        filter={filterContent}
        categories={categories}
        title={optionTitle}
      />

      <Row className="filter-row">
        {activeContent?.map((cta, i) => (
          <Col
            key={`${cta.title}${i + Math.random() * 10}`}
            className={
              isFromTraining
                ? 'grid-box-width pr-sm-1 pb-1 pb-sm-1'
                : 'grid-box-width pr-sm-1 pb-1 pb-sm-1'
            }
          >
            {cta.link ? (
              <BoxCta
                title={cta?.title}
                smallBoxCta={!!onlySmallCtas}
                content={cta.content ? cta?.content : null}
                body={bgImage ? null : cta?.description}
                link={bgImage ? null : cta?.link?.href}
                categories={cta?.categories}
              />
            ) : (
              <BoxCta
                title={cta.Title}
                isFromSoftware={isFromSoftware}
                smallBoxCta={!!onlySmallCtas}
                content={onlySmallCtas ? null : cta?.IntroText}
                body={bgImage ? null : cta?.description}
                link={cta?.url}
                categories={cta?.categories}
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default FilteredCtaGrid;
