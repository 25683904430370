import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={5}
    defaultCenter={{
      lat: parseFloat(props.stateLatitude),
      lng: parseFloat(props.stateLongitude),
    }}
  >
    {props.markers.map((marker) => {
      const onClick = props.onClick.bind(this, marker);
      return (
        <Marker
          key={marker.id}
          onClick={onClick}
          position={{
            lat: parseFloat(marker.Latitude),
            lng: parseFloat(marker.Longitude),
          }}
        >
          {props.selectedMarker === marker && (
            <InfoWindow>
              <div>
                <a href={marker.Url}>{marker.City}</a>
              </div>
            </InfoWindow>
          )}
        </Marker>
      );
    })}
  </GoogleMap>
));

export default class ShelterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelters: this.props.data,
      selectedMarker: false,
      stateLatitude: this.props.Latitude,
      stateLongitude: this.props.Longitude,
    };
  }

  handleClick = (marker, event) => {
    this.setState({ selectedMarker: marker });
  };

  render() {
    return (
      <MapWithAMarker
        selectedMarker={this.state.selectedMarker}
        markers={this.state.shelters}
        stateLatitude={this.props.Latitude}
        stateLongitude={this.props.Longitude}
        onClick={this.handleClick}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBM2AvVtQ27kN9h3guGJwgCGIqiVE-laNk&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    );
  }
}
