// *******************************************************
// ********************************************************
// **
// **  README
// **
// **  1. This file should be wrapped around every page route
// **  2. Adds all page <head></head> data (seo, meta tags, etc..)
// **  3. Passes down page data from api
// **
// ********************************************************
// ********************************************************

import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import ModuleController from './module-controller.component';
import Loader from '../components/commons/helpers/loader/loader.component';

import { getPageDataByApiUrl } from '../js/api/get-pages.data';
import {
  getPageWiseNews,
  getUserPageWiseNews,
} from '../redux/news/news.action';
import { BASE_URL, WEBSITE_URL } from '../js/api/_config';
import { metaStaticInformation } from '../MetaInformation';

function PageWrapper({ page, template, section }) {
  // commenting out seo stuff while connecting to the api
  // => so i can continue using placeholder data && api data
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [dataError, setDataError] = useState(false);

  useEffect(() => {
    let unmounted = false;
    let responseError = false;

    async function renderData() {
      let response;

      if (!page.apiurl) {
        // checking for placeholder data
        response = page;
      } else {
        // console.log(page.apiurl);
        // getting api data
        try {
          if (page.apiurl === '/restapi/customcontent/news') {
            dispatch(getUserPageWiseNews(1));
          } else if (
            page.apiurl ===
            '/restapi/customcontent/training/training-by-industry'
          ) {
            page.apiurl = '/restapi/customcontent/training';
            response = await getPageDataByApiUrl(page.apiurl);
            response = {
              ...response,
              MainContent: {
                ...response.MainContent,
                Title: 'Training By Industry',
              },
            };
          } else if (
            page.apiurl ===
            '/restapi/customcontent/training/training-by-category'
          ) {
            response = await getPageDataByApiUrl(page.apiurl);
            response = {
              ...response,
              MainContent: {
                ...response.MainContent,
                HeaderImage_alt: '',
                HeaderImage_src: `${BASE_URL}/images/default-source/siteassets/training/training/default-training_l2desktop.jpg`,
                HeaderMobileImage_src: `${BASE_URL}/images/default-source/siteassets/training/training/default-training_mobile.jpg`,
                HeaderImage_title: 'training_lp_hero.1a67d2e9',
              },
            };
          } else {
            response = await getPageDataByApiUrl(page.apiurl);
          }
        } catch (e) {
          responseError = true;
        }
      }

      if (!unmounted && !responseError) {
        setPageData(response);
        setDataLoaded(true);
      } else if (!unmounted && responseError) {
        setDataError(true);
      }
    }

    if (!unmounted) {
      setDataLoaded(false);
      renderData();
    }

    return () => (unmounted = true);
  }, [page, template]);

  const getTitleFromPageData = (pageData) => {
    if (pageData === null || pageData === undefined) {
      return 'Trinity Consultants';
    }
    if (pageData.MainContent) {
      if (
        pageData.MainContent.MetaTitle &&
        pageData.MainContent.MetaTitle.length > 0
      ) {
        return `${pageData.MainContent.MetaTitle} | Trinity Consultants`;
      }
      return `${pageData.MainContent.Title} | Trinity Consultants`;
    }
    return 'Trinity Consultants';
  };

  const getDescriptionFromPageData = (pageData) => {
    if (pageData === null || pageData === undefined) {
      return '';
    }
    if (pageData.MainContent) {
      if (
        pageData.MainContent.MetaDescription &&
        pageData.MainContent.MetaDescription.length > 0
      ) {
        return pageData.MainContent.MetaDescription;
      }
      return '';
    }
    return '';
  };

  const getCanonicalURLFromPage = (pageData) => {
    if (pageData === null || pageData === undefined) {
      return WEBSITE_URL + window.location.pathname;
    }
    if (pageData.MainContent) {
      return WEBSITE_URL + pageData.MainContent.url;
      // if (pageData.MainContent.MetaCanonical && pageData.MainContent.MetaCanonical.length > 0) {
      //     return WEBSITE_URL + pageData.MainContent.url;
      // }
      // else {
      //     return WEBSITE_URL + pageData.MainContent.url;
      // }
    }
    return WEBSITE_URL + window.location.pathname;
  };

  // This will be on server side.
  /*    const meta = {
            title: getTitleFromPageData(pageData),
            description: getDescriptionFromPageData(pageData),
            canonical: getCanonicalURLFromPage(pageData),
            meta: {
                charset: "utf-8",
                name: {
                    keywords: "",
                },
            }
        }; */

  /* const getMetaInformation = () => {
        let data = {
            metaTitle: "Trinity Consultants",
            metaDescription: "",
            canonicalURL: ""
        }
        let getFilteredData = metaStaticInformation.filter(e => e.apiurl === page.apiurl)
        if (getFilteredData.length > 0) {
            let currentElement = getFilteredData[0];
            data = {
                metaTitle: currentElement.metatitle,
                metaDescription: currentElement.metadescription,
                canonicalURL: currentElement.url
            }
        }

        return data;
    };

    let metaData = getMetaInformation(); */

  if (
    (dataLoaded && pageData && page.name !== 'Consulting') ||
    (dataLoaded &&
      pageData &&
      pageData.MainContent &&
      pageData.MainContent.Title === 'Consulting')
  ) {
    // && pageData.MainContent.Title.toString().toLowerCase().replace(" ", "").replace("-", "") === page.name.toString().toLowerCase().replace(" ", "").replace("-", "")
    return (
      // <DocumentMeta {...meta}>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={getDescriptionFromPageData(pageData)}
          />
          <title>{getTitleFromPageData(pageData)}</title>
          <link rel="canonical" href={getCanonicalURLFromPage(pageData)} />
        </Helmet>

        <ModuleController
          section={section}
          page={pageData}
          template={template}
        />
      </>
      // </DocumentMeta>
    );
  }

  if (dataError) return <Redirect to="/404" />;
  return <Loader />;
}

export default PageWrapper;
