import { SoftwareResourceActionTypes } from './software-resource.type';

const RESOURCE_INITIAL_STATE = {
  softwareresourceList: [],
  totalItemsCount: '',
  softwareresourceLoading: false,
  filterList: '',
  keywordFilter: [],
  modelingcategoryFilter: [],
  productFilter: [],
  serviceFilter: [],
  industryFilter: [],
  locationFilter: [],
  softwareresourcetype: [],
  pagenumber: 1,
};

const softwareResourceReducer = (state = RESOURCE_INITIAL_STATE, action) => {
  switch (action.type) {
    case SoftwareResourceActionTypes.GET_PAGEWISE_SOFTWARE_RESOURCE:
      if (action.payload) {
        return {
          ...state,
          softwareresourceList: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.TOTAL_ITEM_COUNT:
      if (action.payload) {
        return {
          ...state,
          totalItemsCount: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING:
      return {
        ...state,
        softwareresourceLoading: action.payload,
      };
    case SoftwareResourceActionTypes.FILTER_LOADING:
      if (action.payload) {
        return {
          ...state,
          filterList: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.KEYWORD_FILTER:
      if (action.payload) {
        return {
          ...state,
          keywordFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.MODELING_CATEGORY_FILTER:
      if (action.payload) {
        return {
          ...state,
          modelingcategoryFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.PRODUCT_FILTER:
      if (action.payload) {
        return {
          ...state,
          productFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.SERVICE_FILTER:
      if (action.payload) {
        return {
          ...state,
          serviceFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.INDUSTRY_FILTER:
      if (action.payload) {
        return {
          ...state,
          industryFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.LOCATION_FILTER:
      if (action.payload) {
        return {
          ...state,
          locationFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.SOFTWARE_RESOURCE_TYPE:
      if (action.payload) {
        return {
          ...state,
          softwareresourcetype: action.payload,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourceActionTypes.ACTIVE_PAGE_NUMBER:
      return {
        ...state,
        pagenumber: action.payload,
      };
    default:
      return state;
  }
};

export default softwareResourceReducer;
