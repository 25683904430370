import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getUserIsLoggedin } from '../../../js/api/auth/_login';
import {
  addCartItem,
  getCartItem,
} from '../../../js/api/shopping-cart/_shopping-cart';
import {
  addSoftwareItemToCart,
  cartCountUpdate,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { CartActionTypes } from '../../../redux/shopping-cart/shopping-cart.types';
import { UserActionTypes } from '../../../redux/user/user.types';
import CustomButton from '../../commons/custom-button/custom-button.component';
import './product-list-item.styles.scss';

function ProductListItem({
  title,
  retailPrice,
  salePrice,
  maxQty,
  productId,
  fromCarousel,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.tokens.access_token);
  const loggedInUserData = useSelector(({ user }) => user.loggedin_user);
  const [productQty, setproductQty] = useState(1);
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const history = useHistory();
  const location = useLocation();

  const handleQtyChange = (event) => {
    const qty = event.target.value;
    setproductQty(qty);
  };

  const handleAddToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, productQty);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
      }
    } else {
      const data = {
        productid: event.target.id,
        quantity: productQty,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter(location.pathname);
      history.push('/login');
    }
  };

  const handleBuyNowToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, productQty);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
        history.push('/shopping-cart');
      }
    } else {
      const data = {
        productid: event.target.id,
        quantity: productQty,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter('/shopping-cart');
      history.push('/login');
    }
  };
  const fetchUserIsLoggedin = async () => {
    const getResponse = await getUserIsLoggedin();
    return getResponse;
  };
  const addItemToCart = async (itemid, itemqty) => {
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      productid: itemid,
      quantity: itemqty,
      memberid: memberId,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };
  const getCartItems = async () => {
    const getResponse = await getCartItem(null);
    return getResponse;
  };

  const addRouter = useCallback(
    (redirect_router) =>
      dispatch({
        type: UserActionTypes.REDIRECT_ROUTER,
        payload: {
          redirect_router,
        },
      }),
    [dispatch]
  );

  const addCartItems = useCallback(
    (cart_items) =>
      dispatch({
        type: CartActionTypes.GET_CART_ITEM,
        payload: {
          cart_items,
        },
      }),
    [dispatch]
  );

  const updateCartCount = useCallback(
    (countUpdate) =>
      dispatch({
        type: CartActionTypes.CART_COUNT_UPDATE,
        payload: {
          countUpdate,
        },
      }),
    [dispatch]
  );

  maxQty = new Array(maxQty).fill('');

  return (
    <div className="product-list-item" {...otherProps}>
      <div className="title-price-container">
        <h3 className={`title ${fromCarousel && 'carousel-style'}`}>{title}</h3>
        <div className="prices-container">
          {retailPrice && (
            <p className="retail">
              <span className="retail-price line-through"> ${retailPrice}</span>
            </p>
          )}
          {salePrice && (
            <p className="sales">
              <span className="sale-price"> ${salePrice}</span>
            </p>
          )}
        </div>
      </div>
      <div className="quantity">
        <label htmlFor="quantity-select">Qty:</label>
        <select
          name=""
          id={`quantity-select-${productId}`}
          onChange={handleQtyChange}
        >
          {maxQty.map((x, i) => (
            <option key={i} value={`${i + 1}`}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="buttons">
        <CustomButton
          rounded
          arrow
          altColors
          id={productId}
          onClick={handleAddToShoppingCart}
        >
          Add to Cart &gt;
        </CustomButton>
        <CustomButton
          rounded
          arrow
          id={productId}
          onClick={handleBuyNowToShoppingCart}
        >
          Buy Now &gt;
        </CustomButton>
      </div>
    </div>
  );
}

export default ProductListItem;
