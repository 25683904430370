import * as Sentry from '@sentry/react';
import { ENDPOINTS } from '../_config';
import { handleNotify } from '../../helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../helpers/toaster/toaster-types';

export const addCartItem = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.ADD_ITEM;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
      // "X-Requested-With":"XMLHttpRequest"--removed XMLHTTPRequest
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      handleNotify('Item has been added to cart!', ToasterTypes.Success);
      return await fetchResponse.json();
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while adding item to cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    handleNotify('Error while adding item to cart', ToasterTypes.Error);
    return null;
  }
};

export const getCartItem = async (item) => {
  let cartContentResponse;
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  // const location = ENDPOINTS.PROXY + ENDPOINTS.CART.GET_CART_CONTENTS/${item};
  // await fetch(`${ENDPOINTS.GET_CATEGORIES}/${Id}`);
  // const settings = {
  //   method: "GET",
  //   headers: {
  //     'Authorization': auth,
  //   },
  // };
  const request = await fetch(
    `${ENDPOINTS.PROXY + ENDPOINTS.CART.GET_CART_CONTENTS}/${item}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: auth,
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      cartContentResponse = data;
    })
    .catch((error) => {
      console.error('getCartItem--Error:', error);
    });
  // RK Temporarily commented to fix issue in TrinitySandbox to get cart items.
  // try {
  //   const fetchResponse = await fetch(location, settings);
  //   if (fetchResponse.status === 200) {
  //     const response = await fetchResponse.json();
  //     return response;
  //   }
  //   return null;
  // } catch (e) {
  //   console.log("error getting softwares", e);
  //   return null;
  // }
  return cartContentResponse;
};

export const getCustomers = async () => {
  const customers = [];
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.GET_CUSTOMERS;

  const request = await fetch(location, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth,
    },
  });

  if (request.status === 200) {
    const response = await request.json();
    return response;
  }
  return customers;
};
export const removeCartItem = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.REMOVE_ITEM;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while removing item from cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while removing item from cart', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in handle delete method');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in handle delete method',
      },
    });
    return null;
  }
};

export const updateCartItemsPost = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.CHANGE_ITEM_QUANTITY;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      // handleNotify('Qty has been update for cart.', ToasterTypes.Success);
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in Update cart');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in Update cart',
      },
    });
    return null;
  }
};
export const removeDiscountItem = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.REMOVE_DISCOUNT;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    // handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    // handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  }
};

export const applyDiscountOnCartItems = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.APPLY_DISCOUNT;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      const response = await fetchResponse.json();

      return response;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('DiscountCode is not valid', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('DiscountCode is not valid', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in Apply Discount Codes');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in Apply Discount Codes',
      },
    });
    return null;
  }
};
