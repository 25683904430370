import React from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import './hero.styles.scss';

function BreadCrumbComponent({ breadCrumbList }) {
  return (
    <div className="trinity-container breadcrumb-top-line-outer">
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadCrumbList &&
          breadCrumbList.map((element, index) => (
            <Link
              color="inherit"
              style={
                element.active === true
                  ? menuItemStyle.activeMenuItem
                  : menuItemStyle.inActiveMenuItem
              }
              href={element.link}
            >
              {element.key.replace(/\b(\w)/g, (s) => s.toUpperCase())}
              {/* {element.key.indexOf(">") === -1 && element.active === true ? " >" : ""} */}
            </Link>
          ))}
      </Breadcrumbs>
    </div>
  );
}

const menuItemStyle = {
  activeMenuItem: {
    color: '#000000',
    opacity: 0.5,
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 500,
  },
  inActiveMenuItem: {
    color: '#000000',
    textDecoration: 'none',
    fontSize: '16px',
    fontWeight: 500,
  },
};
export default BreadCrumbComponent;
