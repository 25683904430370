import React from 'react';
import './full-width-text-with-button.styles.scss';
import ContentWell from '../../commons/content-well/content-well.component.jsx';
import CustomButton from '../../commons/custom-button/custom-button.component';

function FullWidthTextWithButton({ content, link }) {
  // had to make this only a content well because
  // the api is setup only with a wysiwyg

  const handleApplyClick = (url) => {
    if (url) window.open(url);
  };

  return (
    <div className="full-width-text-with-button sm-up-trinity-container sm-up-module-spacer">
      <div className="xs-only-trinity-container content-container">
        {/* <h2 className="title">{title}</h2> */}
        <ContentWell content={content} />
      </div>
      <div className="btn-container">
        <CustomButton
          arrow
          className="xs-full-width-btn"
          onClick={() => handleApplyClick('/staffinges/submit-job-request')}
        >
          {link.text}
        </CustomButton>
      </div>
    </div>
  );
}

export default FullWidthTextWithButton;
