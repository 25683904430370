import React, { useEffect } from 'react';
import './training-secondary.styles.scss';
import { useDispatch } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';

function TrainingSecondarySoftware() {
  const dispatch = useDispatch();
  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const format = { Name: 'Software Courses', ID: '187' };
  const pformat = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: [],
      TrainingCategory: ['Software Courses'],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: [],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(selectTrainingActionFilter(format, pformat, 'Training_Category'));
    submit();
  }, []);

  return (
    <div className="training training-secondary">
      <Hero
        src={heroImageSrc}
        mobileImgSrc={heroImageSrc}
        title="BREEZE Software Training"
        template="standard"
        link={heroLink}
        colorCategory="train"
      />
      <div className="trinity-container">
        <TrainingAdvanceSearchFilterDataListing />
      </div>
    </div>
  );
}

export default TrainingSecondarySoftware;
