import React from 'react';
import './content-well.styles.scss';

// #RP - Task 59 - Submit Job Request
function ContentWell({ content }) {
  return (
    <div className="content-well the-content mb-4">
      <p
        className="sub-title"
        dangerouslySetInnerHTML={{ __html: content.SubTitle }}
      />
      <p
        className="content"
        dangerouslySetInnerHTML={{ __html: content.SubContent }}
      />
    </div>
  );
}

export default ContentWell;
