function HomepageModal({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = '',
    hero_description = '',
    hero_ctas = [],
  },
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_ctas,
    },
  };
}

export default HomepageModal;
