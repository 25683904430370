import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import EditProfileForm from '../../../components/ecommerce/forms/edit-profile/edit-profile-form.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './edit-profile.styles.scss';
import { getAccountPageData } from '../../../js/api/create-account/_create-account.data';

function EditProfile() {
  const [responseMainContent, setResponseMainContent] = useState({});

  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getAccountPageData();
      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.CustomDropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country !== undefined)
  ) {
    return (
      <Row className="edit-profile-page trinity-container">
        <Col xs={12} className="text-content-container">
          <div className="text-content background transparent">
            <div className="inner-wrapper  trinity-container">
              <h1 className="m-0">Edit Profile</h1>
            </div>
          </div>
        </Col>
        <EditProfileForm
          data={responseDropDownContent}
          mainData={responseMainContent}
          status={status}
        />
      </Row>
    );
  }
  return <Loader />;
}
export default EditProfile;
