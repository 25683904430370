import React, { useEffect, useState } from 'react';
import './software-product-detail.styles.scss';
import { Col, Row } from 'react-bootstrap';
import ProductDisplay from '../../../components/software/product-display/product-display.component';
import ProductListSidebar from '../../../components/software/product-list-sidebar/product-list-sidebar.component';
import ProductCarousel from '../../../components/software/filtered-product-carousel/filtered-product-carousel.component';
import WidgetBox from '../../../components/software/widgets/widgetBox-component/WidgetBox.component';
import FilteredContentWell from '../../../components/software/filtered-content-well/filtered-content-well.component';
import IconList from '../../../components/software/icon-list/icon-list.component';
import RequirementsContentWell from '../../../components/software/system-requirements-well/system-requirements.component';
import VideoCarousel from '../../../components/software/video-carousel/video-carousel.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import FilteredSoftwareProduct from '../../../components/software/filtered-image-content-well/filtered-software-product';
import {
  BREEZE_CONTACT_NUMBER,
  DATA_TEAM,
  SALES_TEAM,
  SUPPORT_TEAM,
} from '../../../js/api/_config';

function SoftwareProductDetail({
  page: { MainContent, CategoryContent, SoftwareContent },
}) {
  const [sec1MainContent, setsec1MainContent] = useState(null);
  const [sec1RelatedProduct, setsec1RelatedProduct] = useState(null);
  const [sec1ProductImageContent, setsec1ProductImageContent] = useState([]);
  const [secSoftwareModuleContent, setsecSoftwareModuleContent] = useState([]);
  const [secVideoUrlContent, setSecVideoUrlContent] = useState([]);
  const [secDownloadProductTechSheetURL, setSecDownloadProductTechSheetURL] =
    useState();
  const [secSoftwareResourceContent, setsecSoftwareResourceContent] = useState(
    []
  );
  const [secSoftwareRequirementContent, setsecSoftwareRequirementContent] =
    useState(null);
  const [
    secSoftwareRelatedProductandServiceContent,
    setsecSoftwareRelatedProductandServiceContent,
  ] = useState([]);
  const [
    secSoftwareRelatedProgramContent,
    setsecSoftwareRelatedProgramContent,
  ] = useState([]);
  const [
    secRelatedTrainingCoursesContent,
    setsecRelatedTrainingCoursesContent,
  ] = useState([]);
  const [secSoftwareProductTourContent, setsecSoftwareProductTourContent] =
    useState([]);
  const [secProductList, setsecProductList] = useState(null);
  const [secRelatedProductCategory, setsecRelatedProductCategory] =
    useState(null);
  const [secBundleProductList, setsecBundleProductList] = useState([]);

  /* NL: TASK 53 # Software Product Page */
  useEffect(() => {
    if (SoftwareContent) {
      const response = SoftwareContent;
      if (response.ProductImageContent) {
        let allproductimages = [];
        allproductimages = response.ProductImageContent;
        setsec1ProductImageContent(allproductimages);
      }
      if (response.SoftwareModuleContent) {
        let allsoftwaremoules = [];
        allsoftwaremoules = response.SoftwareModuleContent;
        setsecSoftwareModuleContent(allsoftwaremoules);
      }
      if (response.ProductTutorialLinks) {
        let allVideoContent = [];
        allVideoContent = response.ProductTutorialLinks;
        setSecVideoUrlContent(allVideoContent);
      }
      if (response.SoftwareResourceContent) {
        let allsoftwareresources = [];
        allsoftwareresources = response.SoftwareResourceContent;
        setsecSoftwareResourceContent(allsoftwareresources);
      }
      if (response.SoftwareRequirementContent) {
        setsecSoftwareRequirementContent(response.SoftwareRequirementContent);
      }
      if (response.SoftwareRelatedProductandServiceContent) {
        let allsfproductandservices = [];
        allsfproductandservices =
          response.SoftwareRelatedProductandServiceContent;
        setsecSoftwareRelatedProductandServiceContent(allsfproductandservices);
      }
      if (response.SoftwareRelatedProgramContent) {
        let allsfrelatedprograms = [];
        allsfrelatedprograms = response.SoftwareRelatedProgramContent;
        setsecSoftwareRelatedProgramContent(allsfrelatedprograms);
      }
      if (response.SoftwareProductTourContent) {
        let allsfproducttours = [];
        allsfproducttours = response.SoftwareProductTourContent;
        setsecSoftwareProductTourContent(allsfproducttours);
      }
      if (response.SoftwareRelatedCategory) {
        setsecRelatedProductCategory(response.SoftwareRelatedCategory);
      }
      if (response.ProductList.ProductSimpleProperty) {
        setsecProductList(response.ProductList.ProductSimpleProperty);
        if (response.ProductList.ProductSimpleProperty) {
          const itemvariants =
            response.ProductList.ProductSimpleProperty.Variants;
          const items = [];
          itemvariants.forEach((element) => {
            if (
              element.IsBundle === 'True' &&
              element.IsExtension === 'False' &&
              element.IsModule === 'False'
            ) {
              items.push({
                title: element.Title,
                maxQty: 10,
                retailPrice: element.ProductPrice_BundleRetailPrice,
                salePrice: element.ProductPrice_Price,
                id: element.Id,
                categories: [
                  {
                    title: element.BundleCategory,
                    id: element.BundleCategory.toLowerCase(),
                  },
                ],
              });
            }
          });
          setsecBundleProductList(items);
        }
      }
      if (response.ProductRelatedTrainingData) {
        const relatedprod = response.ProductRelatedTrainingData;
        setsecRelatedTrainingCoursesContent(relatedprod);
      }
      if (response.DownloadProductTechSheetURL) {
        const techsheet = response.DownloadProductTechSheetURL;
        setSecDownloadProductTechSheetURL(techsheet);
      }
      if (response.RelatedProduct) {
        setsec1RelatedProduct(response.RelatedProduct.ProductSimpleProperty);
      } else {
        setsec1RelatedProduct({});
      }
    }
    if (MainContent) {
      setsec1MainContent(MainContent);
    }
  }, []);

  const getBottomEmailBar = () => (
    <div className="xs-only-trinity-container">
      {/* <hr/>
                <div>
                    Email:
                    {" "} <a style={{color: "#000"}} href={"mailto:" + SALES_TEAM}>Sales Team</a>
                    {" > "} <a style={{color: "#000"}} href={"mailto:" + DATA_TEAM}>Data Team</a>
                    {" > "} <a style={{color: "#000"}} href={"mailto:" + SUPPORT_TEAM}>Support Team</a>
                </div>
                <div>
                    Phone: {" "}
                    <a style={{color: "#000"}} href={"phone:" + BREEZE_CONTACT_NUMBER}>{BREEZE_CONTACT_NUMBER}</a>
                </div> */}
      <Row style={{ justifyContent: 'center' }}>
        <Col xs={12} lg={12} md={12}>
          <div className="contact-bottom-classic-block">
            <ul>
              <li>
                <div style={{ color: '#000' }}>Email</div>
                <ul>
                  <li>
                    BREEZE Sales Team:{' '}
                    <a style={{ color: '#000' }} href={`mailto:${SALES_TEAM}`}>
                      {SALES_TEAM}
                    </a>
                  </li>
                  <li>
                    BREEZE Data Team:{' '}
                    <a style={{ color: '#000' }} href={`mailto:${DATA_TEAM}`}>
                      {DATA_TEAM}
                    </a>
                  </li>
                  <li>
                    BREEZE Support Team:{' '}
                    <a
                      style={{ color: '#000' }}
                      href={`mailto:${SUPPORT_TEAM}`}
                    >
                      {SUPPORT_TEAM}
                    </a>
                  </li>
                </ul>
              </li>

              <li>
                <div style={{ color: '#000' }}>
                  Phone:{' '}
                  <a
                    style={{ color: '#000' }}
                    href={`phone:${BREEZE_CONTACT_NUMBER}`}
                  >
                    {BREEZE_CONTACT_NUMBER}
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </Col>
      </Row>

      {/* <Row style={{justifyContent: "center"}}>
                    <Col xs={12} lg={4} md={4}>
                        <div className={"contact-bottom-classic-block-phone"}>
                            <Col xs={12} lg={12} md={12}>
                                <div>Phone</div>
                                <div>

                                </div>
                            </Col>
                        </div>
                    </Col>

                    <Col xs={12} lg={4} md={4}/>
                    <Col xs={12} lg={4} md={4}/>
                </Row> */}
    </div>
  );

  if (
    sec1MainContent &&
    secRelatedTrainingCoursesContent &&
    secRelatedProductCategory
  ) {
    return (
      <div className="software-product-detail module-spacer software">
        <div className="sm-up-trinity-container">
          <div className="main-product-container">
            <div className="main-content">
              <ProductDisplay
                sec1MainContent={sec1MainContent}
                sec1ProductImageContent={sec1ProductImageContent}
                sec1RelatedProduct={sec1RelatedProduct}
                techsheetURL={secDownloadProductTechSheetURL}
              />

              {secBundleProductList && secBundleProductList.length > 0 && (
                <>
                  <hr className="section-seperator" />
                  <ProductCarousel
                    title="BUNDLE PACKAGING & PRICING"
                    products={secBundleProductList}
                  />
                </>
              )}

              <hr className="section-seperator" />

              <div className="xs-only-trinity-container">
                <IconList relatedprograms={secSoftwareRelatedProgramContent} />
              </div>

              {secSoftwareModuleContent &&
                secSoftwareModuleContent.length > 0 && (
                  <>
                    <hr className="section-seperator" />
                    <div className="xs-only-trinity-container">
                      <FilteredContentWell
                        moduledata={secSoftwareModuleContent}
                        title="FEATURES & PRODUCT INFORMATION"
                      />
                    </div>
                  </>
                )}
              {secSoftwareProductTourContent &&
                secSoftwareProductTourContent.length > 0 && (
                  <>
                    <hr className="section-seperator" />

                    <div className="xs-only-trinity-container">
                      <FilteredSoftwareProduct
                        moduledata={secSoftwareProductTourContent}
                        heading="PRODUCT TOUR"
                      />
                    </div>
                  </>
                )}
              {secSoftwareResourceContent &&
                secSoftwareResourceContent.length > 0 && (
                  <>
                    <hr className="section-seperator" />

                    <div className="xs-only-trinity-container">
                      <FilteredSoftwareProduct
                        heading="FREQUENTLY ASKED QUESTIONS"
                        moduledata={secSoftwareResourceContent}
                      />
                    </div>
                  </>
                )}
              {secVideoUrlContent &&
                Object.entries(secVideoUrlContent).length > 0 && (
                  <>
                    <hr className="section-seperator" />

                    <div className="xs-only-trinity-container">
                      <VideoCarousel
                        heading="TUTORIALS"
                        videos={secVideoUrlContent}
                        infinite={false}
                      />
                    </div>
                  </>
                )}

              {/* //TODO This has been removed as directed by vishal & business */}
              {secSoftwareRelatedProgramContent &&
                secSoftwareRelatedProgramContent.length > 0 && <></>}
              {secSoftwareRequirementContent &&
                Object.keys(secSoftwareRequirementContent).length > 0 && (
                  <>
                    <hr className="section-seperator" />

                    <div className="xs-only-trinity-container">
                      <RequirementsContentWell
                        title={secSoftwareRequirementContent.Title}
                        innerContent={secSoftwareRequirementContent.Content}
                      />
                    </div>
                  </>
                )}

              {secRelatedTrainingCoursesContent &&
                secRelatedTrainingCoursesContent.length > 0 && (
                  <>
                    <hr className="section-seperator" />

                    <WidgetBox
                      widgettitle="TRAINING COURSES"
                      widgetdata={secRelatedTrainingCoursesContent}
                    />
                  </>
                )}
              <hr className="section-seperator" />
              <h2>CONTACT US TO LEARN MORE</h2>
              {getBottomEmailBar()}
            </div>
            <aside>
              <ProductListSidebar
                isFromSoftwareProduct
                productlist={secProductList}
              />
            </aside>
          </div>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareProductDetail;
