import React, { useEffect } from 'react';
import './news-media.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import NewsListingWithFilters from '../../../components/resources-and-media/news-listing-with-filters/news-listing-with-filters.component';
import {
  getNewsPageData,
  getPageWiseNews,
  getUserPageWiseNews,
  loadFilter,
  loadNews,
} from '../../../redux/news/news.action';
import Loader from '../../../components/commons/helpers/loader/loader.component';

// RP #Task 106 News Landing
function NewsMedia() {
  const dispatch = useDispatch();
  const selectlocation = [];
  const newsFilter = useSelector(({ news }) => news.filterList);
  const newsPageData = useSelector(({ news }) => news.newsPageData);
  const plocation = useSelector(({ news }) => news.locationFilter);
  plocation.map((item) => {
    selectlocation.push(item.Id);
  });

  const newsData = {
    PageNumber: 1,
    Tags: '',
    NewsCategory: '',
    IndustryCategory: '',
    Location:
      selectlocation != undefined && selectlocation != null
        ? selectlocation
        : '',
    PublishedDate: '',
  };

  useEffect(() => {
    dispatch(loadNews(true));
    dispatch(getUserPageWiseNews(newsData));
    dispatch(getNewsPageData());
    dispatch(loadFilter());
  }, []);

  if (newsFilter && newsFilter.responseStatus) {
    return (
      <div className="news news-lp">
        <Hero
          src={newsPageData.HeaderImage_src}
          mobileImgSrc={newsPageData.HeaderMobileImage_src}
          alt={newsPageData.HeaderImage_alt}
          template="standard"
          title="News"
        />
        <div className="sm-up-trinity-container">
          <NewsListingWithFilters />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default NewsMedia;
