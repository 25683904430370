import { HomePageActionTypes } from './new-home.types';

const homePageDefaultState = {
  pageData: '',
  trainingData: [],
};

const homePageReducer = (state = homePageDefaultState, action) => {
  switch (action.type) {
    case HomePageActionTypes.GET_HOME_PAGE_DATA:
      if (action.payload) {
        return {
          ...state,
          pageData: action.payload,
        };
      }
      return {
        ...state,
      };

    case HomePageActionTypes.GET_HOME_TRAINING_DATA:
      if (action.payload) {
        return {
          ...state,
          trainingData: action.payload,
        };
      }
      return {
        ...state,
      };

    /* case HomePageActionTypes.CLASSROOM_HOME_PAGE_DATA:
            if (action.payload) {
                return {
                    ...state,
                    classRoom: action.payload && action.payload.Product,
                };
            } else {
                return {
                    ...state
                }
            }

        case HomePageActionTypes.WEBINAR_HOME_PAGE_DATA:
            if (action.payload) {
                return {
                    ...state,
                    webinar: action.payload && action.payload.Product,
                };
            } else {
                return {
                    ...state
                }
            }

        case HomePageActionTypes.SEMINAR_HOME_PAGE_DATA:
            if (action.payload) {
                return {
                    ...state,
                    seminar: action.payload && action.payload.Product,
                };
            } else {
                return {
                    ...state
                }
            } */

    default:
      return state;
  }
};

export default homePageReducer;
