import React, { useCallback, useState } from 'react';
import './admin-login-form.scss';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import CustomButton from '../../../commons/custom-button/custom-button.component';

import { authenticateUser } from '../../../../js/api/auth/_auth';
import { UserActionTypes } from '../../../../redux/user/user.types';
import { getUserProfileRes } from '../../../../js/api/auth/_user-profile';
import {
  addIsBackendUser,
  addLoggedinUser,
} from '../../../../redux/user/user.action';
import { validateEmailSyntax } from '../../../../js/helpers/_validate-email-syntax';
import { adminuserEmailExist } from '../../../../js/api/_user-email-exist';

function AdminLoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const verifiedUserEmail = useSelector(({ user }) => user.verified_email);
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState(!!verifiedUserEmail);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [username, setUsername] = useState(verifiedUserEmail || '');
  const [password, setPassword] = useState('');

  const usernameOnChange = (e) => setUsername(e.target.value);
  const passwordOnChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage('');
    const validEmailSyntax = validateEmailSyntax(username);
    if (!validEmailSyntax) {
      setSubmitting(false);
      return setErrorMessage('Email must be in a valid format');
    }

    const emailExists = await adminuserEmailExist(username);
    if (!emailExists.Exists) {
      setSubmitting(false);
      setErrorMessage("That email doesn't exist");
      return;
    }
    if (!emailExists.IsBackendUser) {
      setSubmitting(false);
      setErrorMessage('User is not the admin user');
      return;
    }

    if (emailExists.Exists && emailExists.IsBackendUser) {
      const { access_token, refresh_token } = await authenticateUser({
        username,
        password,
      });

      if (access_token) {
        addUser(access_token, refresh_token);
        const response = await getUserProfile();
        if (response) {
          if (response.User) {
            dispatch(addLoggedinUser(response.User));
            dispatch(addIsBackendUser(emailExists.IsBackendUser));
          }
        }
        history.push('/admin/landingPage');
      } else {
        setErrorMessage('The username or password is incorrect');
        setSubmitting(false);
      }
    }
  };

  const getUserProfile = async () => {
    const getResponse = await getUserProfileRes();
    return getResponse;
  };

  const addUser = useCallback(
    (access_token, refresh_token) =>
      dispatch({
        type: UserActionTypes.ADD_USER,
        payload: {
          access_token,
          refresh_token,
        },
      }),
    [dispatch]
  );

  return (
    <div className="login-form module-spacer">
      {errorMessage && <p className="text error p-0">*{errorMessage}</p>}
      <div className="form-container2 background action-item">
        <h2>Admin Sign In</h2>

        <TextInput
          onChange={(e) => usernameOnChange(e)}
          placeholder=""
          label="Username"
          value={username}
        />
        <TextInput
          onChange={(e) => passwordOnChange(e)}
          placeholder=""
          label="Password"
          type="password"
          value={password}
        />
        <Link
          to="/forgot-password"
          className="forgot-password mb-3"
          onClick={() => {}}
        >
          Forgot your password?
        </Link>
        {/* <div className="signed-in-box">
                    <input type="checkbox" name="" id="keepSignedIn" className="mr-2" />
                    <label htmlFor="keepSignedIn">Keep me signed in.</label>
                </div> */}

        <CustomButton loading={submitting} onClick={handleSubmit}>
          Sign In
        </CustomButton>
      </div>
      <p>Need Help? Please submit an IT Service Ticket</p>
    </div>
  );
}

export default AdminLoginForm;
