import React from 'react';
import '../TrinitySiteSearch.scss';
import { BASE_URL, WEBSITE_URL } from '../../../js/api/_config';
import softwareIcon from '../images/software-icon-350x300.png';

function SoftwareListingResourceCard(props) {
  return (
    <div
      className="context-card"
      onClick={() =>
        window.open(
          props.elementData.Url.toLowerCase().includes(
            'media.trinityconsultants.com'
          )
            ? props.elementData.Url
            : WEBSITE_URL + escape(props.elementData.Url)
        )
      }
    >
      <div className="image-container">
        <img
          alt={`TrinityBulletField${Math.random()}`}
          className={
            props.elementData.Image
              ? 'image-present-class'
              : 'image-not-present-class'
          }
          src={
            props.elementData.Image
              ? BASE_URL + props.elementData.Image
              : softwareIcon
          }
        />
      </div>
      <div className="side-data">
        <div className="title-tag-container">
          <span className="context-trinitysearch-title">
            {props.elementData.Title}
          </span>
          {props.elementData.Category && (
            <div className="tag-beside-title">{props.elementData.Category}</div>
          )}
        </div>
        <div className="context-text">
          {props.elementData.Version && (
            <span>
              <strong>Version: </strong> {props.elementData.Version}
            </span>
          )}
          {/* <span>
                        <ContentWell content={props.elementData.Content} />
                    </span> */}
        </div>
      </div>
    </div>
  );
}

export default SoftwareListingResourceCard;
