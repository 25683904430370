import React from 'react';
import './product-filter-button.styles.scss';

function ProductFilterButton({ children, colorCategory, ...otherProps }) {
  return (
    <button
      {...otherProps}
      className={`trak background filter-button ${
        colorCategory && colorCategory.split(' ').join('_')
      }`}
    >
      {children}
    </button>
  );
}

export default ProductFilterButton;
