import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../../js/custom-hooks/useForm';
import validate from '../../../../../js/custom-hooks/formValidationRules';
import TextInput from '../../../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../../../commons/form-inputs/select-list/select-list.component';
import ReCaptcha from '../../../../commons/recaptcha/recaptcha.component';
import CustomButton from '../../../../commons/custom-button/custom-button.component';
import {
  companyTitle,
  InformationTitle,
  link,
  register_content,
  register_title,
} from './agency-form.data';

import './agency-form.styles.scss';

function RegulatoryAgencyProgramForm() {
  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  function submitSuccess() {
    history.push('/thank-you-page');
  }
  return (
    <div className="agency-form-container">
      <form onSubmit={handleSubmit} noValidate>
        <p className="title">{register_title}</p>
        <p className="content">{register_content}</p>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="title">{InformationTitle}</p>
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
            />
            {errors.jobTitle && <span className="help">{errors.jobTitle}</span>}
            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
            />
            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Business Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Business "
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
            />
          </Col>
          <Col xs={12} sm={6} className="right">
            <p className="title">{companyTitle}</p>
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company Name"
              autoComplete="off"
              className={` ${errors.companyName && 'error'}`}
              type="number"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              required
            />
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address 1"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
            />
            <TextInput placeholder="Business Address 2" />
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
            />
            <SelectList defaultText="— Select a State —" className="mb-2" />

            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
            />

            <SelectList defaultText="— Select a Country —" className="mb-2" />
            <SelectList
              defaultText="— How did you hear about us? —"
              className="mb-2"
            />
          </Col>
        </Row>
        <div className="recaptcha">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default RegulatoryAgencyProgramForm;
