import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewPrivacyPolicyLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-privacy-policy-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-privacy-policy-content-component')
  );

  return (
    <div className="privacy-policy-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          section={section}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} section={section} />
      </Suspense>
    </div>
  );
}

export default NewPrivacyPolicyLandingPage;
