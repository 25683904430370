import React from 'react';
import './career-lisiting-with-filters.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CareerListing from '../career-lisiting/career-lisiting.component';

// #RP - Task 124 Career
function ResourceListingWithFilter({ contentData, show }) {
  // RP - SD19: Adding Advanced Search Filters
  return (
    <div className="career-outer-container module-spacer-bottom sm-up-module-spacer-top">
      <Row className="no-gutter-around">
        <Col>
          <CareerListing avadata={contentData} show={show} />
        </Col>
      </Row>
    </div>
  );
}

export default ResourceListingWithFilter;
