import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './website-problem.styles.scss';

// #RP - Task 71 - Website Problem
function WebsiteProblemContent({ mainData, status }) {
  const noContent = 204;
  const subTitle =
    status !== noContent && mainData.SubTitle ? mainData.SubTitle : '';
  const subContent =
    status !== noContent && mainData.SubContent ? mainData.SubContent : '';
  const leftContent =
    status !== noContent && mainData.LeftContent ? mainData.LeftContent : '';
  const rightContent =
    status !== noContent && mainData.RightContent ? mainData.RightContent : '';

  return (
    <div className="website-problem-container">
      <p className="title" dangerouslySetInnerHTML={{ __html: subTitle }} />
      <p className="content" dangerouslySetInnerHTML={{ __html: subContent }} />
      <div className="problem-points">
        <Row>
          <Col xs={12} sm={6}>
            <p
              className="point-content"
              dangerouslySetInnerHTML={{ __html: leftContent }}
            />
          </Col>
          <Col xs={12} sm={6}>
            <p
              className="point-content"
              dangerouslySetInnerHTML={{ __html: rightContent }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default WebsiteProblemContent;
