import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class HomePageServices {
  static getHomePageData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEW_HOME_PAGE.LANDING_PAGE_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getHomeTrainingData() {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.NEW_HOME_PAGE.HOME_PAGE_DATA}upcomingtraining`,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getCommonCoursesData(data) {
    return axios({
      method: 'POST',
      data,
      url: ENDPOINTS.TRAINING_FILTER.GET_PAGE_WISE_TRAINING_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getNewSliderData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEW_HOME_PAGE.NEW_SLIDER_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getCaseStudiesData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEW_HOME_PAGE.CASE_STUDIES_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getBlogSeriesData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEW_HOME_PAGE.BLOG_SERIES_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }
}
