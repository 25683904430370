import React, { Suspense } from 'react';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import './indivi-state.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NewIndividualStateLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-individual-state-hero-component')
  );
  const NewIndividualStateNewsComponent = React.lazy(() =>
    import('./new-individual-state-news-component')
  );
  const NewIndividualStateMapComponent = React.lazy(() =>
    import('./new-individual-state-map-component')
  );
  const NewIndividualStateLeadershipComponent = React.lazy(() =>
    import('./new-individual-state-leadership-component')
  );
  const NewIndividualStateLocallinkComponent = React.lazy(() =>
    import('./new-individual-state-locallink-component')
  );
  const NewIndividualStateWidgetboxComponent = React.lazy(() =>
    import('./new-individual-state-widgetbox-component')
  );

  return (
    <div className="individual-location-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Col className="trinity-container">
          <ContentWithSidebar
            isCustom
            left={
              <div>
                <h2>{MainContent.Title}</h2>
                <ContentWell
                  content={MainContent.Content ? MainContent.Content : ''}
                />
                <NewIndividualStateNewsComponent MainContent={MainContent} />
              </div>
            }
            right={
              <Row>
                <Col className="left">
                  <NewIndividualStateMapComponent MainContent={MainContent} />
                  <br />
                  <NewIndividualStateLeadershipComponent
                    MainContent={MainContent}
                  />
                  <NewIndividualStateWidgetboxComponent
                    MainContent={MainContent}
                  />
                  <NewIndividualStateLocallinkComponent
                    MainContent={MainContent}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Suspense>
    </div>
  );
}

export default NewIndividualStateLandingPage;
