import React from 'react';
import './image-with-overlay-text-cta.styles.scss';

import tempImage from '../../../../images/staffing/widgetImg2.png';

function ImageWithTextOverlay({ title, image, category, href }) {
  return (
    <div
      className="image-with-overlay-text background cover"
      style={{ backgroundImage: `url(${image ? image.src : tempImage})` }}
    >
      <div
        className={`text-container transparent background sm-up-lighter ${category}`}
      >
        <a href={href.toLowerCase().includes("staffinges/available-positions")
                  ? 'https://trinitystaffing.ihire.com/'
                  : href} 
                  target={href.toLowerCase().includes("staffinges/available-positions")
                    ? '_blank'
                    : '_self'}
                  className="image-widget-title">
          {title} <span className="">{'>'}</span>
        </a>
      </div>
    </div>
  );
}

export default ImageWithTextOverlay;
