// setting default content to fill in placeholder data for fields not filled out
function AboutLpModal({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = '',
    hero_description = '',
    hero_ctas = [],
  },
  ctas = [],
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_ctas,
    },
    ctas,
  };
}

export default AboutLpModal;
