export const title =
  'Please complete the form below so that we can setup your trial and provide you with access to the software. ';
export const blurb =
  'We are happy to provide first-time users of BREEZE Software with a limited-time trial (up to a week) so that you can better familiarize yourself with the software features and capabilities before making your purchase.';
export const blurb_2 =
  '**Note that your trial will start on the date you select below and will be valid for up to a week from your start date.**';

export const informationBlurb = 'Your Information';
export const companyBlurb = 'Company Information';
export const licenseBlurb = 'License Information';
export const backLink = '< Go Back';
export const link = 'Request Trial';
