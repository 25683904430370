import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UserActionTypes } from '../../../../redux/user/user.types';
import './impersonation-banner.styles.scss';
// import { getUserIsLoggedin } from "../../../js/api/auth/_login";
import {
  addLoggedinUser,
  addUserToken,
  getImpersonateDetails,
} from '../../../../redux/user/user.action';
import { removeUser } from '../../../../redux/user/user.action';
import {
  resetCartItem,
  cartCountUpdate,
  UpdateImpersonateMember,
  addCartBasketId,
  addCartItem,
} from '../../../../redux/shopping-cart/shopping-cart.action';

import { CartActionTypes } from '../../../../redux/shopping-cart/shopping-cart.types';

function ImpersonationBanner() {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitemimpersonate = useSelector(({ cart }) => cart.impersonateMember);
  // if(impersonateToken!=undefined){
  // const {id:{match:{params:{tokenId}}}}=impersonateToken;
  // }
  // const {id} = impersonateToken.match;

  // const dispatch = useDispatch();
  // const user = useSelector(({ user }) => user.tokens.access_token);

  // useEffect(() => {
  //   //setNavItems();
  //   async function checkUserLoggedIn() {
  //     const userIsLoggedIn =null;// await getUserIsLoggedin(null);

  //     if (!userIsLoggedIn) {
  //       const tokens = {
  //         access_token: null,
  //         refresh_token: null,
  //       };

  //        //dispatch(getImpersonateDetails(impersonateToken==undefined?null:impersonateToken));
  //       // dispatch(addLoggedinUser(null));
  //     }
  //     if (user && userIsLoggedIn) {
  //      // getCartItemsCount();
  //      // dispatch(getClientData());
  //     }
  //   }
  //   // dispatch(removeUser(null));
  //   // dispatch(resetCartItem(null));
  //    //checkUserLoggedIn();
  // }, []);

  const handleRevert = async (event) => {
    const impersonate = {
      name: 'None',
      memberId: null,
      userprofile: null,
    };
    dispatch(UpdateImpersonateMember(impersonate));
    dispatch({
      type: CartActionTypes.UPDATE_IMPERSONATE,
      payload: impersonate,
    });
    dispatch(cartCountUpdate(1));
    history.push('/admin/impersonate');
  };

  return (
    <div className="impersonation-container">
      <p className="text">
        You are viewing as{' '}
        <span>
          {cartitemimpersonate != null ? cartitemimpersonate.name : ''}
        </span>{' '}
        -{' '}
        <a href="javascript:void();" onClick={handleRevert}>
          Revert Identity
        </a>
      </p>
    </div>
  );
}

export default ImpersonationBanner;
