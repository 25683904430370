import { MinnowServices } from './minnow.services';
import { MinnowActionTypes } from './minnow.types';

export const getMinnowData = () => (dispatch, getState) => {
  MinnowServices.getMinnowDAta().then((response) => {
    if (response) {
      dispatch({
        type: MinnowActionTypes.GET_MINNOW_DATA,
        payload: response,
      });
    }
  });
};

export const serviceBrandTrainingList =
  (parameterURL) => (dispatch, getState) => {
    MinnowServices.getServiceBrandTrainingList(parameterURL).then(
      (response) => {
        if (response) {
          dispatch({
            type: MinnowActionTypes.SERVICE_BRAND_TRAINING,
            payload: response,
            status: true,
          });
        }
      }
    );
  };

export const serviceBrandNewsList =
  (parameterURL, parameter) => (dispatch, getState) => {
    MinnowServices.getServiceBrandNewsList(parameterURL, parameter).then(
      (response) => {
        if (response) {
          dispatch({
            type: MinnowActionTypes.SERVICE_BRAND_NEWS,
            payload: response,
            status: true,
          });
        }
      }
    );
  };

export const serviceBrandEventList = (parameterURL) => (dispatch, getState) => {
  MinnowServices.getServiceBrandEventList(parameterURL).then((response) => {
    if (response) {
      dispatch({
        type: MinnowActionTypes.SERVICE_BRAND_EVENTS,
        payload: response,
        status: true,
      });
    }
  });
};
