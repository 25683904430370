import React from 'react';
import './general-content-box-cta-styles.scss';

import { Link } from 'react-router-dom';

function BoxCta({
  title,
  className,
  smallBoxCta,
  subTitle,
  content,
  link,
  color,
  categories,
  transparent,
  test,
}) {
  return (
    <Link
      className={`
                ${className || ''}
                box-cta background xs-only-trinity-container
                ${transparent ? 'transparent' : ''} 
                ${categories ? categories[0].id : ''}
                ${smallBoxCta ? 'small-box-cta' : ''}
               ${color}
            `}
      to={link}
    >
      {title && (
        <div className="title">
          <div dangerouslySetInnerHTML={{ __html: title }} />{' '}
          {/* NL # TASK 40 */}
          {smallBoxCta && <span className="arrow d-none d-sm-inline">›</span>}
        </div>
      )}
      {subTitle && <div className="sub-title">{subTitle}</div>}
      {content && (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {link ? (
        <div className="bottom-link">
          <span className="link-text">View More</span>
          <span className="arrow">›</span>
        </div>
      ) : (
        <span className="arrow d-sm-none">›</span>
      )}
    </Link>
  );
}

export default BoxCta;
