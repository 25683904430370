import React, { useEffect } from 'react';
import './training-secondary.styles.scss';
import { useDispatch } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';
import headerDesktopImage from '../../../images/training/default-training_l2desktop.jpg';
import headerMobileImage from '../../../images/training/default-training_mobile.jpg';

const INITIAL_STATE = {
  KeywordPhrase: '',
  ResouceType: '',
  Industry: '',
  Location: '',
  Softwares: '',
};

function TrainingAllCourse({
  page: { Product, MainContent, TrainingCategory },
}) {
  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const dispatch = useDispatch();

  const date = { Name: 'Next 12 Months', ID: '6' };
  const pdate = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: [],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: ['Next 12 Months'],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(selectTrainingActionFilter(date, pdate, 'Training_Date'));
    submit();
  }, []);

  return (
    <div className="training training-secondary">
      <Hero
        src={headerDesktopImage}
        alt="Training All Courses image"
        mobileImgSrc={headerMobileImage}
        title="All Training Courses"
        template="standard" // Changed from Landing to Standard
        link={heroLink}
        colorCategory="train"
      />
      <div className="trinity-container">
        <TrainingAdvanceSearchFilterDataListing Product={Product} />
      </div>
    </div>
  );
}

export default TrainingAllCourse;
