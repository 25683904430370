import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewConsultingIndividualCategoryLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-consulting-individual-category-hero-component')
  );

  const ContentComponent = React.lazy(() =>
    import('./new-consulting-individual-category-content')
  );

  return (
    <div className={`consulting consulting-secondary-b ${section || ''}`}>
      <div className="industries industries-lp">
        <Suspense fallback={<Loader />}>
          <HeroComponent
            MainContent={MainContent}
            section={section}
            breadCrumbList={breadCrumbList}
          />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <ContentComponent MainContent={MainContent} section={section} />
        </Suspense>
      </div>
    </div>
  );
}

export default NewConsultingIndividualCategoryLandingPage;
