import React from 'react';
import './contact-cta.styles.scss';

import Row from 'react-bootstrap/Row';
import { ReactComponent as MessageIcon } from '../../../../images/general/message-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../../images/general/phone-icon.svg';

function ContactCta({
  data,
  CenterPhoneNumber,
  LeftSideContent,
  LeftSideLink,
  RightSideContent,
  CenterContent,
  RightSidePhoneNumber,
  LeftSideLinkText,
}) {
  if (data) {
    return (
      <Row className="contact-cta module-spacer">
        <div className="contact-us within-united-states contact-section">
          <div className="icon left d-none d-sm-flex">
            <MessageIcon className="icon" />
          </div>
          <div style={{ marginLeft: '1rem' }} className="content">
            <a
              href={
                data.LeftSideLink != undefined
                  ? data.LeftSideLink
                  : data.LeftLink
              }
            >
              {LeftSideContent}
              <span className="phone">{LeftSideLinkText}</span>
            </a>
          </div>
        </div>
        {/* <div className="phone-numbers-container"> */}
        <div className="within-united-states contact-section">
          <div className="icon left d-none d-sm-flex">
            <PhoneIcon className="icon" />
          </div>
          <div className="content">
            <a href={`tel:${CenterPhoneNumber}`}>
              {CenterContent}
              <span className="phone">{CenterPhoneNumber}</span>
            </a>
          </div>
        </div>
        <div className="outside-united-states contact-section">
          <div className="icon left d-none d-sm-flex">
            <PhoneIcon className="icon" />
          </div>
          <div className="content d-flex align-items-center">
            <a href={`tel:${RightSidePhoneNumber}`}>
              {RightSideContent}
              <span className="phone">{RightSidePhoneNumber}</span>
            </a>
          </div>
        </div>
        {/* </div> */}
      </Row>
    );
  }
  return null;
}

export default ContactCta;
