import { ENDPOINTS } from '../_config';

export const getLocations = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_LOCATIONS);
    const response = await request.json();

    return response;
  } catch (e) {
    return null;
  }
};
