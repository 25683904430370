import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PhoneIcon from '../../../../images/general/phone-icon.svg';
import './single-widget.styles.scss';

function SingleWidget({
  singleLinkText,
  singleLinkContent,
  link,
  singleLinkSub,
  src,
  alt,
  content,
}) {
  return (
    <div className="single-widget-container">
      <div className="single-content">
        <a href="#">
          <img src={src} alt={alt} className="single-icon" />
        </a>
        <div className="single-text">
          <div className="single-content-text">
            {singleLinkText && (
              <a target="_blank" href={link} rel="noreferrer">
                {singleLinkText}
              </a>
            )}
            {singleLinkContent && <p>{singleLinkContent}</p>}
            {content && (
              <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="content"
              />
            )}
          </div>
          <div className="single-sub-content">
            {singleLinkSub && (
              <a href="#">
                <p>{singleLinkSub}</p>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleWidget;
