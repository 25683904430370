import React from 'react';
import './widget-box.styles.scss';
import { trinityTrainingDateFormatter } from '../../../../utilities/CommonUtilities';

function CommonTrainingWidgetBox({
  widgetImage,
  widgetContent,
  widgetContentLink,
  widgetDate,
  widgetSubContent,
  singleLinkContent,
  singleLink,
  alt,
  city,
  state,
  startDate,
  lstRelatedTrainingData,
  isFromConsulting,
  isFromIndustry,
  isLocation,
}) {
  return (
    <div>
      <div className="widget-content">
        {widgetImage && (
          <img src={widgetImage} alt={alt} className="widget-icon" />
        )}
        <div className="widget-text">
          {widgetContent && (
            <a
              href={widgetContentLink}
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              {widgetContent}
            </a>
          )}
          {widgetDate && (
            <p className="date">{trinityTrainingDateFormatter(widgetDate)}</p>
          )}
          {widgetSubContent && (
            <span className="sub-content">{widgetSubContent}</span>
          )}
          {singleLinkContent && (
            <a
              href={singleLink}
              target="_blank"
              className="widget-single-link"
              rel="noreferrer"
            >
              {singleLinkContent}
            </a>
          )}
          <br />

          {(isFromConsulting || isFromIndustry || isLocation) && (
            <ul style={{ paddingInlineStart: '1.2rem' }}>
              {lstRelatedTrainingData !== undefined &&
                lstRelatedTrainingData !== null &&
                lstRelatedTrainingData.length > 0 &&
                lstRelatedTrainingData.map((item, index) => (
                  <li>
                    <a className="widget-time" href={item.TrainingUrl}>
                      {item.SessionTypeTest !== undefined &&
                        item.SessionTypeTest}
                      {item.strStartDate !== undefined &&
                      item.SessionTypeTest.toUpperCase() != 'ONDEMAND'
                        ? ` - ${trinityTrainingDateFormatter(
                            item.strStartDate
                          )}`
                        : ''}
                      <br />
                      <p style={{ fontWeight: 'lighter' }}>
                        {item.City !== undefined &&
                          item.City.length > 0 &&
                          item.City}
                        {item.State !== undefined &&
                          item.State.length > 0 &&
                          `, ${item.State}`}
                      </p>
                    </a>
                  </li>
                ))}
            </ul>
          )}
          {/* {
                            !isFromIndustry && !isFromConsulting && lstRelatedTrainingData !== undefined && lstRelatedTrainingData !== null &&
                            lstRelatedTrainingData.length > 0 && lstRelatedTrainingData.map((item, index) => {
                                return (
                                    <a className="widget-time" href={item.TrainingUrl}>
                                        {item.SessionTypeTest !== undefined && item.SessionTypeTest}
                                        {" - "}
                                        {item.strStartDate !== undefined && item.strStartDate}
                                        <br/>
                                        {item.City !== undefined && item.City}
                                        {", "}
                                        {item.State !== undefined && item.State}
                                    </a>
                                )
                            })
                        } */}
          {/* <p className="widget-time"> {startDate && startDate} {city && " - " + city + (state !== "" ? ', ' + state : "")}</p> */}
        </div>
      </div>
    </div>
  );
}

export default CommonTrainingWidgetBox;
