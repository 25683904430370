import React from 'react';
import './content-with-sidebar.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ContentWithSidebar({ left, right, isCustom, style }) {
  return (
    <Row
      className={
        isCustom ? 'content-with-sidebar-custom' : 'content-with-sidebar'
      }
      style={style}
    >
      <Col md={6} lg={7} className="left">
        {left}
      </Col>
      <Col md={6} lg={5} className="right mt-md-0 sidebar-right">
        {right}
      </Col>
    </Row>
  );
}

export default ContentWithSidebar;
