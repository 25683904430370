import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../components/commons/custom-button/custom-button.component';
import Loader from '../../components/commons/helpers/loader/loader.component';
import Hero from '../../components/commons/hero/hero.component';
import { getUserProfileRes } from '../../js/api/auth/_user-profile';
import { getAllImpersonateUsers } from '../../redux/impersonate-users/impersonate.action';
import {
  cartCountUpdate,
  UpdateImpersonateMember,
} from '../../redux/shopping-cart/shopping-cart.action';
import './impersonate-user-list-table.styles.scss';
import {
  DataGrid,
  StripedDataGrid,
  GridRowsProp,
  GridColDef,
  GridToolbar,
} from '@mui/x-data-grid';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Col, Row } from 'react-bootstrap';
// import search from '../ehs-quarterly/images/search.svg';
import search from '../../components/ehs-quarterly/images/search.svg';

const dataGridColumns: GridColDef[] = [
  {
    field: 'UserName',
    headerName: 'User Name',
    flex: 1.5,
    renderHeader: (params) => (
      <span className="datagrid-header-column">User Name</span>
    ),
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    flex: 1,
    renderHeader: (params) => (
      <span className="datagrid-header-column">First Name</span>
    ),
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    flex: 1,
    renderHeader: (params) => (
      <span className="datagrid-header-column">Last Name</span>
    ),
  },
  {
    field: 'company',
    headerName: 'Company',
    flex: 1,
    renderHeader: (params) => (
      <span className="datagrid-header-column">Company</span>
    ),
  },
  {
    field: 'actions',
    headerName: '',
    align: 'right',
    // headerAlign: 'center',
    sortable: false,
    flex: 1,
    disableClickEventBubbling: true,
    // renderHeader: (params) => (
    //   <span className="datagrid-header-column">Actions</span>
    // ),
    renderCell: (params) => {
      return <CreateCustomInsertButton row={params.row} />;
    },
  },
];

export function CreateCustomInsertButton({ row }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const getUserProfile = async (memberid) => await getUserProfileRes(memberid);
  const handleImpersonateClick = async (event) => {
    const fname = event.target.attributes.getNamedItem('fname').value;
    const lname = event.target.attributes.getNamedItem('lname').value;
    const memberid = event.target.id;
    const impersonate = {
      name: `${fname || ''} ${lname || ''}`,
      memberId: memberid,
    };
    const getcartitemResponse = await getUserProfile(memberid);
    if (getcartitemResponse) {
      if (getcartitemResponse.User) {
        impersonate.userprofile = getcartitemResponse.User;
      }
    }
    dispatch(UpdateImpersonateMember(impersonate));
    dispatch(cartCountUpdate(1));

    history.push('/shopping-cart');
  };
  return (
    <CustomButton
      id={row.memberid}
      fname={row.firstname}
      lname={row.lastname}
      onClick={handleImpersonateClick}
    >
      Impersonate
    </CustomButton>
  );
}

function ImpersonateUserListTableComponent() {
  const dispatch = useDispatch();
  const [searchBoxText, setSearchBoxText] = React.useState('*');

  const impersonateUserList = useSelector(
    ({ impersonateUser }) => impersonateUser.userList
  );

  const totalCount = useSelector(
    ({ impersonateUser }) => impersonateUser.count
  );

  // const [impUserList, setImpUserList] = useState([]);

  const [pageState, setPageState] = useState({
    page: 0,
    pageSize: 50,
  });

  useEffect(() => {
    dispatch(getAllImpersonateUsers(pageState?.page, pageState?.pageSize));
  }, []);

  useEffect(() => {
    dispatch(getAllImpersonateUsers(pageState?.page, pageState?.pageSize));
  }, [pageState]);

  const onEnterSearch = () => {
    if (searchBoxText.toString().trim().length === 0) {
      dispatch(getAllImpersonateUsers(pageState?.page, pageState?.pageSize));
    } else {
      dispatch(
        getAllImpersonateUsers(
          pageState?.page,
          pageState?.pageSize,
          searchBoxText
        )
      );
    }
  };

  const getSearchBar = () => (
    <div className="search-box">
      <input
        type="text"
        placeholder="Search Here"
        className="search-input"
        value={searchBoxText}
        onKeyDown={(e) => e.key === 'Enter' && onEnterSearch()}
        onChange={(e) => setSearchBoxText(e.target.value)}
      />
      <img
        style={{
          width: '20px',
          height: '20px',
          marginRight: '10px',
          cursor: 'pointer',
        }}
        // onClick={onSearchInTrinitySite}
        src={search}
        alt={Math.random().toString()}
      />
    </div>
  );

  if (!impersonateUserList) {
    return <Loader />;
  }

  return (
    <div className="user-list-table">
      <Hero
        title="Impersonate"
        template="standard"
        altMobileTitle
        colorCategory="gen"
      />

      <div
        className="trinity-container"
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
      >
        <div className="search-filter-container">{getSearchBar()}</div>
        <DataGrid
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
            },
          }}
          slots={{ toolbar: GridToolbar, exportIcon: ImportExportIcon }}
          getRowId={(row: any) => row.memberid}
          autoHeight
          getRowClassName={(params) => {
            return (
              params.indexRelativeToCurrentPage % 2 === 0 && 'datagrid-dark-row'
            );
          }}
          rows={impersonateUserList}
          rowCount={totalCount}
          disableColumnFilter
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: pageState?.pageSize,
                page: pageState?.page,
              },
            },
          }}
          pagination
          paginationMode="server"
          disableRowSelectionOnClick
          onPaginationModelChange={(model) => {
            setPageState(model);
          }}
          columns={dataGridColumns}
        />
      </div>
    </div>
  );
}

export default ImpersonateUserListTableComponent;
