import React, { useState } from 'react';
import './product-filter-controls.styles.scss';

import ProductFilterButton from '../product-filter-button/product-filter-button.component';

function ProductFilterControls({ filter, categories }) {
  const [categoriesArray] = useState(
    categories ? Object.entries(categories) : null
  );

  if (categoriesArray) {
    return (
      <div className="product-filter-controls">
        <div className="filter-buttons d-flex flex-wrap">
          <ProductFilterButton
            data-category="all"
            onClick={(e) => filter(e)}
            colorCategory="all"
          >
            All
          </ProductFilterButton>
          {categoriesArray.map(([category, categoryId], index) => (
            <ProductFilterButton
              data-category={categoryId}
              key={index}
              onClick={(e) => filter(e)}
              colorCategory={categoryId}
            >
              {category}
            </ProductFilterButton>
          ))}
        </div>
      </div>
    );
  }
  return null;
}

export default ProductFilterControls;
