import { CourseServices } from './course.services';
import { CourseActionTypes } from './course.types';

export const getTrainingDataList =
  (countryName, stateName, isBoth) => (dispatch, getState) => {
    CourseServices.getTrainingDataList(countryName, stateName, isBoth).then(
      (response) => {
        dispatch({
          type: CourseActionTypes.GET_TRAINING_DATA_BY_LOCATION,
          payload:
            response != null && response.LocationSpecific
              ? response.LocationSpecific.locationSpecificData
              : [],
        });

        dispatch({
          type: CourseActionTypes.STATE_WISE_META_INFORMATION,
          payload:
            response != null && response.LocationSpecific
              ? response.LocationSpecific.metaDetails
              : '',
        });
      }
    );
  };

export const getCountryAndStateData = () => (dispatch) => {
  CourseServices.getCountryStateData().then((response) => {
    dispatch({
      type: CourseActionTypes.GET_COUNTRY_STATE_WISE_DATA,
      payload:
        response != null && response.LocationSpecific
          ? response.LocationSpecific
          : [],
    });
  });
};
