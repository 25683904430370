import 'font-awesome/css/font-awesome.min.css';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { isMobile, isTablet } from 'react-device-detect';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as TrinityLogo } from '../../../../images/2020-new-trinity-logo.svg';
import { ReactComponent as CartIcon } from '../../../../images/cart-icon.svg';
import { CAREER_WEBSITE_URL } from '../../../../js/api/_config';
import { getNavigationItems } from '../../../../js/api/_navigation';
import { getUserIsLoggedin } from '../../../../js/api/auth/_login';
import { getCartItem } from '../../../../js/api/shopping-cart/_shopping-cart';
import { useOnClickOutside } from '../../../../js/custom-hooks/useOutsideClick';
import { timeStampMinutesDifference } from '../../../../js/helpers/_time-stamp-minutes-difference';
import {
  UpdateImpersonateMember,
  addCartItem,
  cartCountUpdate,
  resetCartItem,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import { CartActionTypes } from '../../../../redux/shopping-cart/shopping-cart.types';
import {
  addIsBackendUser,
  addLoggedinUser,
  addUserToken,
  removeUser,
} from '../../../../redux/user/user.action';
import { compareValues } from '../../../../utilities/CommonUtilities';
import { ReactComponent as SearchIcon } from '../../../ehs-quarterly/images/search.svg';
import './TrinityHeader.scss';
// import search from "../../../ehs-quarterly/images/search.svg";

const TrinityHeader = () => {
  const [showSignInMenu, setShowSignInMenu] = useState(false);
  const signInContainerRef = useRef();
  useOnClickOutside(signInContainerRef, () => setShowSignInMenu(false));

  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user?.tokens?.access_token);

  const [navVisible, setNavVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [mailVisible, setMailVisible] = useState(false);
  const [locationVisible, setLocationVisible] = useState(false);
  const [navigationItems, setNavigationItems] = useState(null);
  const [cartCount, setcartCount] = useState(0);
  const [cookies, setCookie] = useCookies(['name']);
  //const [cartItems, setcartItems] = useState(null);

  const cartVisiblity = useSelector(({ cart }) => cart.visible);
  //const cartitems = useSelector(({ cart }) => cart.cart_items, shallowEqual);
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitemimpersonate = useSelector(({ cart }) => cart.impersonateMember);
  const profileuser = useSelector(({ user }) => user.loggedin_user);
  const improfileuser = useSelector(({ cart }) =>
    cart.impersonateMember ? cart.impersonateMember.userprofile : null
  );
  const isAdminUser = useSelector(({ user }) => user.isbackend_user);
  const animationDelay = 500;
  const userEmailData = useSelector(({ user }) => user.verified_email);

  const toggleCartView = useCallback(
    () => dispatch({ type: CartActionTypes.TOGGLE_CART_VIEW }),
    [dispatch]
  );

  useEffect(() => {
    setNavItems();

    getUserIsLoggedin().then((response) => {
      if (response) {
        checkUserLoggedIn();
      } else {
        const tokens = {
          access_token: null,
          refresh_token: null,
        };
        dispatch(addUserToken(tokens));
        dispatch(addLoggedinUser(null));
        dispatch(cartCountUpdate(null));
        dispatch(addIsBackendUser(false));
        const impersonate = {
          name: 'None',
          memberId: null,
          userprofile: null,
        };
        dispatch(UpdateImpersonateMember(impersonate));
      }
    });

    async function checkUserLoggedIn() {
      if (user) {
        getCartItemsCount();
        let memberId;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
      }
    }

    checkUserLoggedIn();
  }, [user]);

  const history = useHistory();

  useEffect(() => {
    async function checkUserLoggedIn() {
      const userIsLoggedIn = await getUserIsLoggedin(null);
      if (user && userIsLoggedIn) {
        getCartItemsCount();
        let memberId;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
      }
    }

    checkUserLoggedIn();
  }, [cartitemupdate]);

  const getCartItems = async () => {
    let memberId = '';
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    return await getCartItem(memberId);
  };

  /*NL: TASK 61 # Shopping Cart*/
  const getCartItemsCount = async () => {
    let cartcount = 0;

    const getcartitemResponse = await getCartItems();

    if (getcartitemResponse) {
      const ordercart = getcartitemResponse.Cart;
      if (ordercart && !ordercart.OrderLines) {
        dispatch(addCartItem(ordercart));
        setcartCount(cartcount);
        return cartcount;
      }
      if (ordercart) {
        dispatch(addCartItem(ordercart));
        let tomorrow = new Date();
        let today = new Date();
        tomorrow.setDate(today.getDate() + 30);

        setCookie('basketid', ordercart.BasketID, {
          path: '/',
          expires: tomorrow,
        });
        //setCookie('basketid', ordercart.BasketID, { path: '/', expires: tomorrow, domain: 'localhost', secure: '', httpOnly: true, sameSite: 'lax' });
        //setCookie('basketid', ordercart.BasketID, { path: '/', httpOnly: true, sameSite: 'lax', expires: tomorrow });
      } else {
        dispatch(addCartItem(ordercart));
      }

      if (ordercart) {
        if (ordercart.ItemsCount > 0) {
          ordercart.OrderLines.forEach((a) => (cartcount += a.Quantity));
        }
      }
    }
    setcartCount(cartcount);
    return cartcount;
  };

  const setNavItems = async () => {
    const cachedNavigationItems = JSON.parse(
      localStorage.getItem('navigation')
    );

    let navigationTimeStamp = +localStorage.getItem('navigation-time-stamp');
    let currentTimeStamp = Date.now();

    let minutesDifference = timeStampMinutesDifference(
      currentTimeStamp,
      navigationTimeStamp
    );

    if (minutesDifference > 30 || !cachedNavigationItems) {
      const navigationList = await getNavigationItems();
      setNavigationItems(navigationList);
    } else {
      setNavigationItems(cachedNavigationItems);
      getNavigationItems();
    }
  };

  const hamClick = (e) => {
    e.preventDefault();
    const animationContainer = document.getElementsByClassName(
      'trinity-navigation-animation-container'
    );
    const navigation = document.getElementsByClassName('navigation');
    const overlay = document.getElementsByClassName('trinity-header-overlay');

    if (
      animationContainer &&
      navigation &&
      overlay &&
      animationContainer[0] &&
      navigation[0] &&
      overlay[0]
    ) {
      if (animationContainer[0].classList.contains('visible')) {
        animationContainer &&
          animationContainer[0].classList &&
          animationContainer[0].classList.remove('visible');
        navigation[0].classList.remove('visible', 'show-submenu-1');
        overlay[0].classList.remove('visible');
      } else {
        animationContainer &&
          animationContainer[0].classList &&
          animationContainer[0].classList.add('visible');
        navigation[0].classList.add('visible', 'show-submenu-1');
        overlay[0] && overlay[0].classList.add('visible');
      }
    }
  };

  const hideMobileMenu = (e) => {
    e.preventDefault();
    const animationContainer = document.getElementsByClassName(
      'trinity-navigation-animation-container'
    );
    const navigation = document.getElementsByClassName('navigation');
    const overlay = document.getElementsByClassName('trinity-header-overlay');

    if (
      animationContainer &&
      navigation &&
      overlay &&
      animationContainer[0] &&
      navigation[0] &&
      overlay[0]
    ) {
      animationContainer &&
        animationContainer[0].classList &&
        animationContainer[0].classList.remove('visible');
      navigation[0].classList.remove('visible', 'show-submenu-1');
      overlay[0].classList.remove('visible');
    }
  };

  const headerSpanReference = useRef();

  useOnClickOutside(headerSpanReference, (e) => {
    hideMobileMenu(e);
  });

  const submenu = (e) => {
    e.preventDefault();
    const menu = e.currentTarget.closest('.menu');
    menu.classList.add('trinity-navigation-active');
  };
  const backMenu = (e) => {
    e.preventDefault();
    const menu = e.currentTarget.closest('.menu');
    menu.classList.remove('trinity-navigation-active');
  };

  const logOutClick = () => {
    dispatch(removeUser(null));
    dispatch(resetCartItem(null));
    setTimeout(() => {
      history.push('/login');
    }, 1000);
  };

  const renderSubItem = (menuItem) => {
    return menuItem
      .map((e) => {
        return Object.assign({}, e, { SortOrder: parseInt(e.SortOrder) });
      })
      .sort(compareValues('SortOrder', 'asc'))
      .map((item) => {
        return (
          <li>
            <a
              className={'menu-name sub-menu-item'}
              style={{ textDecoration: 'none' }}
              target={item?.externalLink ? '_blank' : '_self'}
              href={item.path}
            >
              {item.name}
            </a>
          </li>
        );
      });
  };

  const renderMenuItem = (menuItem) => {
    // console.log(menuItem);
    /*return menuItem
            .map(e => {
                return Object.assign({}, e, {SortOrder: parseInt(e.SortOrder)});
            })
            .sort(compareValues('SortOrder', 'asc'))
            .sort(compareValues('position', 'asc'))
            .map(item => (

            ));*/

    return (
      <div className={'menu-column'}>
        <div className="menu-subtitle">
          {menuItem.path.toString().split('/').join('').length > 1 ? (
            <a
              className={'menu-name sub-menu-item'}
              style={{ textDecoration: 'none' }}
              target={menuItem?.externalLink ? '_blank' : '_self'}
              href={menuItem.path}
            >
              {`${menuItem.name} `}{' '}
              <i className="fa fa-chevron-right arrow" aria-hidden="true" />
            </a>
          ) : (
            <span
              className={'menu-name sub-menu-item'}
              style={{ textDecoration: 'none', cursor: 'arrow' }}
            >
              {`${menuItem.name} `}
            </span>
          )}
        </div>
        <ul className="menu-name">{renderSubItem(menuItem.children)}</ul>
      </div>
    );
  };

  const renderMobileItem = (menuItem) => {
    return menuItem
      .map((e) => {
        return Object.assign({}, e, { SortOrder: parseInt(e.SortOrder) });
      })
      .sort(compareValues('SortOrder', 'asc'))
      .sort(compareValues('position', 'asc'))
      .map((item) => (
        <ul className="sub-menu">
          <li className="menu">
            <div className="trinity-navigation-content-wrapper">
              <a
                className={'trinity-navigation-link'}
                onClick={(w) => onNavClick(w, item)}
                href={item.path}
              >
                {`${item.name} `}
              </a>
              {item.children && item.children.length > 0 && (
                <>
                  <span className="drilldown mobile" onClick={submenu}>
                    ›
                  </span>
                  <div className="sub-menu-wrapper">
                    {renderMobileItem(item.children)}
                    <ul className="trinity-navigation-breadcrumb">
                      <div className="link-wrapper">
                        <span
                          className="drilldown drilldown-back"
                          onClick={backMenu}
                        >
                          ‹
                        </span>
                        <a className="mobile-link" href={item?.path}>
                          {item.name}
                        </a>
                      </div>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </li>
        </ul>
      ));
  };

  const onNavClick = (e, item) => {
    if (item.path === '/careers') {
      window.open(CAREER_WEBSITE_URL, '_blank');
      return;
    }

    if (isMobile || isTablet) {
      hamClick(e);
    }

    if (item?.externalLink) {
      window.open(item?.path, '_blank');
    } else {
      history.push(item.path);
      window.location.reload();
    }

    hideMobileMenu(e);
  };

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const renderMenu = (navigationItems) => {
    return navigationItems
      .map((e) => {
        return Object.assign({}, e, { SortOrder: parseInt(e.SortOrder) });
      })
      .sort(compareValues('SortOrder', 'asc'))
      .filter(
        (e) =>
          e.name !== 'Subscribe' &&
          e.name !== 'Contact Us' &&
          e.name !== 'Locations'
      )
      .map((item) => {
        let testObject = groupBy(item.children, 'column');

        return (
          <li className="menu">
            <div
              id={item.name.toLowerCase()}
              className="trinity-navigation-content-wrapper"
            >
              <NavLink
                activeClassName="navbar__link--active"
                className="trinity-navigation-link"
                style={{ cursor: 'pointer' }}
                onClick={(w) => onNavClick(w, item)}
                to={
                  item.path.toString().toLowerCase() !== '/careers' && item.path
                }
              >
                {item.name}
              </NavLink>
              {item?.children?.length > 0 && (
                <span className="drilldown mobile" onClick={submenu}>
                  ›
                </span>
              )}
              {item.children.length > 0 && (
                <div
                  id={`${item.name.toLowerCase()}-menu`}
                  className="menu-dropdown-container"
                >
                  <div
                    className="menu-dropdown-left"
                    style={{ backgroundImage: `url(${item.MenuImage_src})` }}
                  >
                    <div className="menu-dropdown-left-cover">
                      <div>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.MenuContent,
                            }}
                          />
                          {item.ButtonText.length > 0 && (
                            <button
                              className="trinity-menu-custom-button"
                              onClick={() =>
                                window.open(item.ButtonLink, '_blank')
                              }
                            >
                              {item.ButtonText}
                            </button>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="menu-dropdown-right">
                    <div className="menu-data">
                      <Row>
                        {Object.values(testObject).map((element) => {
                          return (
                            <Col md={3} lg={3}>
                              {element
                                .sort(compareValues('row', 'asc'))
                                .map((innerElement) => {
                                  return renderMenuItem(innerElement);
                                })}
                            </Col>
                          );
                        })}
                        {/*<Col md={4} lg={4}>
                                                <div className="menu-dropdown">Google</div>
                                            </Col>

                                            <Col md={4} lg={4}>
                                                <div className="menu-dropdown">
                                                    {renderMenuItem(item.children)}
                                                </div>
                                            </Col>

                                            <Col md={4} lg={4}>
                                                <div className="menu-dropdown">Google</div>
                                            </Col>*/}
                      </Row>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="sub-menu-wrapper">
              {renderMobileItem(item.children)}
              <ul className="trinity-navigation-breadcrumb">
                <div className="link-wrapper">
                  <span className="drilldown drilldown-back" onClick={backMenu}>
                    ‹
                  </span>
                  <a className="mobile-link" href={item.path}>
                    {item.name}
                  </a>
                </div>
              </ul>
            </div>
          </li>
        );
      });
  };

  if (!navigationItems) {
    return null;
  }

  function checkForIE() {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf('MSIE ');

    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      return true;
    }

    return false;
  }

  return (
    <header
      className="md-up-trinity-container"
      style={{ height: checkForIE() && '169px' }}
    >
      <div className="header-icons">
        <div className="icons-container xs-sm-trinity-container">
          <a
            className="logo-container"
            href="https://www.trinityconsultants.com"
          >
            <TrinityLogo className="logo" />
          </a>

          {!user ? (
            <a className="login" href="/login">
              <svg
                width="28.867"
                height="32.901"
                viewBox="0 0 28.867 32.901"
                className="d-md-none mobile-login"
              >
                <g
                  id="Group_35"
                  data-name="Group 35"
                  transform="translate(-370.66 -301)"
                >
                  <g
                    id="Group_34"
                    data-name="Group 34"
                    transform="translate(370.66 301)"
                  >
                    <path
                      id="Path_4733"
                      data-name="Path 4733"
                      d="M468.5,301A10.5,10.5,0,1,0,479,311.5,10.5,10.5,0,0,0,468.5,301Zm0,18.467a7.963,7.963,0,1,1,7.963-7.963A7.972,7.972,0,0,1,468.5,319.467Z"
                      transform="translate(-454.266 -301)"
                      fill="#0732A0"
                    ></path>
                    <path
                      id="Path_4734"
                      data-name="Path 4734"
                      d="M376.685,739.5a11.891,11.891,0,0,1,20.3,8.408h2.542a14.433,14.433,0,0,0-28.867,0H373.2A11.813,11.813,0,0,1,376.685,739.5Z"
                      transform="translate(-370.66 -715.006)"
                      fill="#0732A0"
                    ></path>
                  </g>
                </g>
              </svg>
            </a>
          ) : (
            <i
              className="fa fa-sign-out d-md-none"
              onClick={logOutClick}
              style={{ color: '#0732A0', fontSize: '32px' }}
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <span
        aria-hidden="true"
        onClick={hideMobileMenu}
        className="trinity-header-overlay"
      />
      <trinity-navigation>
        <div className="trinity-navigation-container">
          <ul className="utility-navigation">
            <li>
              <NavLink
                activeClassName="navbar__link--active"
                className="trinity-navigation-link"
                style={{ cursor: 'pointer' }}
                to={'/locations'}
              >
                Locations
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="navbar__link--active"
                className="trinity-navigation-link"
                style={{ cursor: 'pointer' }}
                to={'/subscribe'}
              >
                Subscribe
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="navbar__link--active"
                className="trinity-navigation-link"
                style={{ cursor: 'pointer' }}
                to={'/contactus'}
              >
                Contact Us
              </NavLink>
            </li>
            <li>
              <a className="tel" href="tel:+8668300796">
                Call Us: 866.830.0796
              </a>
            </li>

            {!user ? (
              <li style={{ marginTop: '3px' }}>
                <a className="cart" href="/login">
                  <CartIcon className="trinity-navigation-cart" />
                  <span className="trinity-navigation-cart-count">{0}</span>
                </a>
              </li>
            ) : (
              <li style={{ marginTop: '3px' }}>
                <a className="cart" href="/shopping-cart">
                  <CartIcon className="trinity-navigation-cart" />
                  <span className="trinity-navigation-cart-count">
                    {cartCount}
                  </span>
                </a>
              </li>
            )}

            <li style={{ marginTop: '5px' }}>
              <a className="cart" href="/search">
                <SearchIcon
                  className="trinity-navigation-cart"
                  alt={Math.random().toString()}
                />
              </a>
            </li>
            <li>
              <a className="login">
                <span className="account-container-in">
                  <p
                    className="sign-in"
                    onClick={() => setShowSignInMenu(true)}
                  >
                    {!user
                      ? 'Sign In'
                      : improfileuser
                      ? 'Hello, ' + improfileuser.FirstName
                      : profileuser
                      ? 'Hello, ' + profileuser.FirstName
                      : ''}
                    <i className="fa fa-angle-down" />
                  </p>
                  <div
                    ref={signInContainerRef}
                    className={`dropdown-account ${
                      showSignInMenu
                        ? 'account-dropdown-visible'
                        : 'account-dropdown-hidden'
                    }`}
                  >
                    {!user ? (
                      <div className="outline-container">
                        <a href="/login">
                          <button className="trinity-menu-custom-button">
                            Sign In
                          </button>
                        </a>
                        <a href="/create-account">
                          <button className="trinity-menu-custom-button">
                            Create New Account
                          </button>
                        </a>
                      </div>
                    ) : (
                      <div className="outline-container">
                        <a href="/my-account">
                          <button className="trinity-menu-custom-button">
                            My Account
                          </button>
                        </a>
                        {isAdminUser && (
                          <a href="/admin/landingPage">
                            <button className="trinity-menu-custom-button">
                              Admin Home
                            </button>
                          </a>
                        )}
                        <a href="#">
                          <button
                            onClick={logOutClick}
                            className="trinity-menu-custom-button"
                          >
                            Logout
                          </button>
                        </a>
                      </div>
                    )}
                  </div>
                </span>
              </a>
            </li>
          </ul>
          <div
            id="trinity-navigation-animation-container"
            className="trinity-navigation-animation-container"
          >
            <ul id="navigation" className="navigation">
              {renderMenu(navigationItems || [])}
            </ul>
          </div>
          <div className="trinity-navigation-icons-container d-md-none">
            <div className="trinity-navigation-icons trinity-container">
              <a href="/contactus">
                <svg width="30" height="30.219" viewBox="0 0 39.359 30.219">
                  <g
                    id="Group_4470"
                    data-name="Group 4470"
                    transform="translate(-2.959 -62.383)"
                  >
                    <g
                      id="Group_4469"
                      data-name="Group 4469"
                      transform="translate(3.459 62.883)"
                    >
                      <path
                        id="Path_6081"
                        data-name="Path 6081"
                        d="M34.988,61H3.371A3.377,3.377,0,0,0,0,64.371V86.848a3.377,3.377,0,0,0,3.371,3.371H34.988a3.377,3.377,0,0,0,3.371-3.371V64.371A3.377,3.377,0,0,0,34.988,61Zm-.466,2.248L19.251,78.519,3.848,63.248ZM2.248,86.382V64.826L13.072,75.558Zm1.589,1.589L14.668,77.14l3.8,3.763a1.124,1.124,0,0,0,1.586,0l3.7-3.7L34.522,87.971Zm32.275-1.589L25.339,75.61,36.112,64.837Z"
                        transform="translate(0 -61)"
                        fill="#707070"
                        stroke="#707070"
                        stroke-width="1"
                      ></path>
                    </g>
                  </g>
                </svg>
              </a>
              <a href="/search">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={30}
                  height={30}
                  viewBox="0 0 56.966 56.966"
                >
                  <path
                    xmlns="http://www.w3.org/2000/svg"
                    d="M55.146 51.887L41.588 37.786A22.926 22.926 0 0046.984 23c0-12.682-10.318-23-23-23s-23 10.318-23 23 10.318 23 23 23c4.761 0 9.298-1.436 13.177-4.162l13.661 14.208c.571.593 1.339.92 2.162.92.779 0 1.518-.297 2.079-.837a3.004 3.004 0 00.083-4.242zM23.984 6c9.374 0 17 7.626 17 17s-7.626 17-17 17-17-7.626-17-17 7.626-17 17-17z"
                    fill="#8f8d8d"
                    data-original="#000000"
                  />
                </svg>
              </a>
              <a href="/locations">
                <svg width="30" height="30" viewBox="0 0 28.266 39.779">
                  <g
                    id="Group_47"
                    data-name="Group 47"
                    transform="translate(0.5 0.5)"
                  >
                    <g
                      id="Group_42"
                      data-name="Group 42"
                      transform="translate(0 0)"
                    >
                      <g id="Group_41" data-name="Group 41">
                        <path
                          id="Path_4737"
                          data-name="Path 4737"
                          d="M390.633,428.089a13.635,13.635,0,0,0-11.6,20.8l10.822,17.44a1.136,1.136,0,0,0,.965.537h.009a1.138,1.138,0,0,0,.966-.552l10.546-17.609a13.636,13.636,0,0,0-11.71-20.618Zm9.76,19.452-9.589,16.009-9.839-15.855a11.369,11.369,0,1,1,19.428-.154Z"
                          transform="translate(-377 -428.089)"
                          fill="#707070"
                          stroke="#707070"
                          stroke-miterlimit="10"
                          stroke-width="1"
                        ></path>
                      </g>
                    </g>
                    <g
                      id="Group_44"
                      data-name="Group 44"
                      transform="translate(6.817 6.817)"
                    >
                      <g
                        id="Group_43"
                        data-name="Group 43"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_4738"
                          data-name="Path 4738"
                          d="M398.567,442.839a6.817,6.817,0,1,0,6.817,6.817A6.824,6.824,0,0,0,398.567,442.839Zm0,11.376a4.56,4.56,0,1,1,4.552-4.559A4.563,4.563,0,0,1,398.567,454.215Z"
                          transform="translate(-391.75 -442.839)"
                          fill="#707070"
                          stroke="#707070"
                          stroke-miterlimit="10"
                          stroke-width="1"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </a>
              <a
                href={
                  !user
                    ? `${window?.location?.origin}/login`
                    : `${window?.location?.origin}/shopping-cart`
                }
              >
                <svg width="30" height="30" viewBox="0 0 46.13 40.599">
                  <g
                    id="Group_46"
                    data-name="Group 46"
                    transform="translate(0.25 0.25)"
                  >
                    <path
                      id="Path_4739"
                      data-name="Path 4739"
                      d="M569.2,799.162h24.241a1.337,1.337,0,0,0,1.285-.969l5.346-18.713a1.337,1.337,0,0,0-1.285-1.7h-32.67l-.955-4.3a1.337,1.337,0,0,0-1.305-1.047h-8.02a1.337,1.337,0,0,0,0,2.673h6.948l4.826,21.717a4.009,4.009,0,0,0,1.593,7.689h24.237a1.337,1.337,0,0,0,0-2.673H569.208a1.336,1.336,0,0,1,0-2.673Zm27.815-18.713-4.583,16.039H570.28l-3.564-16.039Z"
                      transform="translate(-554.505 -772.429)"
                      fill="#707070"
                      stroke="#707070"
                      stroke-miterlimit="10"
                      stroke-width="0.5"
                    ></path>
                    <path
                      id="Path_4740"
                      data-name="Path 4740"
                      d="M612.028,914.5a4.01,4.01,0,1,0,4.01-4.01A4.015,4.015,0,0,0,612.028,914.5Zm4.01-1.336A1.337,1.337,0,1,1,614.7,914.5,1.338,1.338,0,0,1,616.038,913.159Z"
                      transform="translate(-598.662 -878.407)"
                      fill="#707070"
                      stroke="#707070"
                      stroke-miterlimit="10"
                      stroke-width="0.5"
                    ></path>
                    <path
                      id="Path_4741"
                      data-name="Path 4741"
                      d="M693.328,914.5a4.01,4.01,0,1,0,4.01-4.01A4.015,4.015,0,0,0,693.328,914.5Zm4.01-1.336A1.337,1.337,0,1,1,696,914.5,1.338,1.338,0,0,1,697.338,913.159Z"
                      transform="translate(-661.071 -878.407)"
                      fill="#707070"
                      stroke="#707070"
                      stroke-miterlimit="10"
                      stroke-width="0.5"
                    ></path>
                  </g>
                </svg>
              </a>

              <a href="" onClick={hamClick}>
                <svg
                  data-name="Group 50"
                  width="30"
                  height="30"
                  viewBox="0 0 34.052 26.077"
                  className="hamburger"
                >
                  <g
                    id="Group_49"
                    data-name="Group 49"
                    transform="translate(0 0)"
                  >
                    <g id="Group_48" data-name="Group 48">
                      <rect
                        id="Rectangle_3688"
                        data-name="Rectangle 3688"
                        width="34.052"
                        height="4.822"
                        transform="translate(0 21.254)"
                        fill="#707070"
                      ></rect>
                      <rect
                        id="Rectangle_3689"
                        data-name="Rectangle 3689"
                        width="34.052"
                        height="4.822"
                        transform="translate(0 10.627)"
                        fill="#707070"
                      ></rect>
                      <rect
                        id="Rectangle_3690"
                        data-name="Rectangle 3690"
                        width="34.052"
                        height="4.822"
                        fill="#707070"
                      ></rect>
                    </g>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </trinity-navigation>
    </header>
  );
};

export default TrinityHeader;
