import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import AgencyForm from './agency-form/agency-form.component';
import {
  content_1,
  content_2,
  regulatoryData_content_1,
  regulatoryData,
  regulatoryData_content_2,
  expectations_title,
  expectations_content,
  expectationsData,
} from './regulatory-agency-program.data';

import './regulatory-agency-program.styles.scss';

function RegulatoryAgencyProgramContent() {
  return (
    <>
      <div className="agency-content-container">
        <div className="top-content">
          <Row>
            <Col xs={12} sm={6}>
              <div className="left-content">
                <p className="content">{content_1}</p>
                <p className="content">{content_2}</p>
                <ul>
                  {regulatoryData.map((item) => (
                    <li>
                      <a href="#">{item.name}</a>
                    </li>
                  ))}
                </ul>
                <p className="content">{regulatoryData_content_1}</p>
                <p className="content">{regulatoryData_content_2}</p>
              </div>
            </Col>
            <Col xs={12} sm={6}>
              <div className="right-content">
                <p className="content-title">{expectations_title}</p>
                <p className="content">{expectations_content}</p>
                <ol>
                  {expectationsData.map((item) => (
                    <li>{item.name}</li>
                  ))}
                </ol>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <AgencyForm />
    </>
  );
}

export default RegulatoryAgencyProgramContent;
