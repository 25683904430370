import React, { useEffect, useState } from 'react';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './support-request.validationRules';
import { checkbox_title, link } from './support-request.data';
import TextInput from '../../form-inputs/text-input/text-input.component';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import { submitSoftwareSupportResponse } from '../../../../js/api/softwareForm/_softwareSupportRequest.data';
import './support-request.styles.scss';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '../../../../js/api/_config';

function SoftwareRequestForm({ data, mainData, status }) {
  // #RP - Task 60 - Software support request
  const [dropDownContent, setDropDownContent] = useState(data);
  const [noContent] = useState(204);
  const [showInput, setShowInput] = useState(false);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Product] = useState(
    status != noContent && dropDownContent.Product
      ? dropDownContent.Product
      : null
  );
  const SubTitle = mainData.SubTitle != null ? mainData.SubTitle : '';
  const SubContent = mainData.SubContent != null ? mainData.SubContent : '';
  const [Country, setCountry] = useState();
  const [State, setState] = useState();

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitSoftwareSupportResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      Product: values.product != null ? values.product.trim() : 'None',
      RegistrationNumber:
        values.registrationNumber != null
          ? values.registrationNumber.trim()
          : 'None',
      CustomerID: values.customerID != null ? values.customerID.trim() : 'None',
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Company: values.companyName.trim(),
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : 'None',
      Country: values.Country,
      PostalCode: values.postalCode.trim(),
      PhoneNumber: values.phone.trim(),
      EmailAddress: values.email.trim(),
      HowCanWeHelpYou:
        values.howcanwehelpyou != null ? values.howcanwehelpyou.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.generate_software_key.UniqueId,
      Catalog: ListOfFormUniqueData.generate_software_key.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    // KT add class support-request-form-wrapper-changed 11-22-2021
    <div className="support-request-form-wrapper support-request-form-wrapper-changed ">
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <p
          className="form-title"
          dangerouslySetInnerHTML={{ __html: SubTitle }}
        />
        <p
          className="form-blurb"
          dangerouslySetInnerHTML={{ __html: SubContent }}
        />
        <SelectList
          className="mb-2"
          defaultText="— Select a product —"
          name="product"
          onChange={handleChange}
          value={values.product}
        >
          {status != noContent &&
            Product.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>

        <TextInput
          placeholder="Product Registration Number"
          required
          type="number"
          name="registrationNumber"
          onChange={handleChange}
          value={values.registrationNumber || ''}
          autoComplete="off"
          className={`${errors.registrationNumber && 'error'}`}
          maxlength={200}
        />
        {errors.registrationNumber && (
          <span className="help">{errors.registrationNumber}</span>
        )}
        <div className="checkbox-container">
          <p className="inner-title">{checkbox_title}</p>
          <div className="first-input">
            <input
              type="radio"
              name="siteID"
              id="1"
              value="value1"
              className="radiocheck"
              onClick={() => setShowInput(true)}
            />
            <label htmlFor="yes">Yes</label>
          </div>
          <div className="second-input">
            <input
              type="radio"
              name="siteID"
              id="2"
              value="value1"
              className="radiocheck"
              onClick={() => setShowInput(false)}
            />
            <label htmlFor="no">No</label>
          </div>
        </div>

        {showInput && (
          <TextInput
            placeholder="Customer ID"
            type="text"
            name="customerID"
            onChange={handleChange}
            value={values.customerID || ''}
            autoComplete="off"
            maxlength={100}
          />
        )}

        <TextInput
          placeholder="First Name"
          required
          type="text"
          name="firstName"
          onChange={handleChange}
          value={values.firstName || ''}
          autoComplete="off"
          className={`${errors.firstName && 'error'}`}
          maxlength={100}
        />
        {errors.firstName && <span className="help">{errors.firstName}</span>}

        <TextInput
          placeholder="Last Name"
          required
          type="text"
          name="lastName"
          onChange={handleChange}
          value={values.lastName || ''}
          autoComplete="off"
          className={`${errors.lastName && 'error'}`}
          maxlength={100}
        />
        {errors.lastName && <span className="help">{errors.lastName}</span>}

        <TextInput
          placeholder="Company Name"
          required
          type="text"
          name="companyName"
          onChange={handleChange}
          value={values.companyName || ''}
          autoComplete="off"
          className={`${errors.companyName && 'error'}`}
          maxlength={200}
        />
        {errors.companyName && (
          <span className="help">{errors.companyName}</span>
        )}

        <TextInput
          placeholder="City"
          required
          type="text"
          name="city"
          onChange={handleChange}
          value={values.city || ''}
          autoComplete="off"
          className={`${errors.city && 'error'}`}
          maxlength={200}
        />
        {errors.city && <span className="help">{errors.city}</span>}
        <SelectList
          defaultText="— Country —"
          name="Country"
          onChange={handleChange}
          value={values.Country}
        >
          {Country &&
            Country.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>

        <SelectList
          defaultText="— State/Province —"
          name="stateName"
          onChange={handleChange}
          onClick={stateclick}
          value={values.stateName}
        >
          {State &&
            State.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>

        <TextInput
          placeholder="Postal Code"
          required
          name="postalCode"
          onChange={handleChange}
          value={values.postalCode || ''}
          autoComplete="off"
          className={`${errors.postalCode && 'error'}`}
          maxlength={100}
        />
        {errors.postalCode && <span className="help">{errors.postalCode}</span>}

        <TextInput
          placeholder="Business Phone"
          required
          type="number"
          name="phone"
          onChange={handleChange}
          value={values.phone || ''}
          autoComplete="off"
          className={`${errors.phone && 'error'}`}
          maxlength={100}
        />
        {errors.phone && <span className="help">{errors.phone}</span>}

        <TextInput
          placeholder="Email"
          type="email"
          name="email"
          required
          onChange={handleChange}
          value={values.email || ''}
          autoComplete="off"
          className={`${errors.email && 'error'}`}
          maxlength={100}
        />
        {errors.email && <span className="help">{errors.email}</span>}
        <textarea
          className="text-area-height"
          placeholder="How can we help you?"
          onChange={handleChange}
          name="howcanwehelpyou"
          value={values.howcanwehelpyou}
          maxLength={500}
        />
        <textarea
          className="text-area-height"
          placeholder="Comments"
          onChange={handleChange}
          name="comments"
          value={values.comments}
          maxLength={500}
        />
        <div className="recaptcha">
          <div
            style={
              errors &&
              errors.recaptchaValue && {
                border: '1px solid red',
                width: 'fit-content',
              }
            }
          >
            <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
          </div>
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default SoftwareRequestForm;
