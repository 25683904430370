import { ENDPOINTS } from '../_config';

// #RP - Task  - Operational Leader
export const getOperationalLeaderPageData = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_OPERATIONAL_LEADERS);
    if (request.status === 204) {
      // 204 => No content in backend
      return request;
    }
    return await request.json();
  } catch (e) {
    return null;
  }
};
