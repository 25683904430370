import React, { useEffect } from 'react';
import './widget-box.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import CommonWidgetBox from './common-widgetBox.component';
import {
  trainingcoursedata,
  resourcesdata,
  newsdata,
  productdata,
} from '../../../../redux/widget-box/widget-box.action';
import CommonTrainingWidgetBox from './common-training-widgetBox.component';

function TrainingConnectWidgetBox({
  relatedTrainingCourse,
  isFromConsulting,
  isFromIndustry,
  isLocation,
}) {
  return (
    <div>
      {relatedTrainingCourse && relatedTrainingCourse.length > 0 && (
        <div className="widgetBox-container">
          <h5 className="widget-title">Related Training Courses</h5>
          {relatedTrainingCourse &&
            relatedTrainingCourse.map((widget) => (
              <CommonTrainingWidgetBox
                isFromConsulting={isFromConsulting}
                isFromIndustry={isFromIndustry}
                isLocation={isLocation}
                widgetImage={widget.Icon}
                widgetContent={widget.CourseName}
                widgetContentLink={widget.TrainingUrl}
                widgetDate=""
                widgetSubContent=""
                singleLinkContent=""
                singleLink=""
                // city = {widget.City}
                // state = {widget.State}
                startDate={widget.strStartDate}
                lstRelatedTrainingData={widget.lstRelatedTrainingData}
              />
            ))}
          <div className="widget-link">
            <a
              href="/training"
              target="_blank"
              className="single-link"
              rel="noreferrer"
            >
              All Training Courses ›
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrainingConnectWidgetBox;
