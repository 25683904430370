import React from 'react';

import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import ImageWithOverlayTextListing from '../../../components/commons/image-with-overlay-text-listing/image-with-overlay-text-listing.component';

import FullWidthTextWithButton from '../../../components/staffing/full-width-text-with-button/full-width-text-with-button.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './staffing-lp-page.scss';
import { sortingbySortorder } from '../../../js/helpers/_sort-ctas';

function StaffingLp({ page: { MainContent, CategoryContent } }) {
  CategoryContent = sortingbySortorder(CategoryContent);

  if (MainContent) {
    return (
      <div className="staffing staffing-lp">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          colorCategory="staffing"
          downArrow
          heroCtas
          heroCtasCustomMarkup={[
            {
              markup: MainContent.EmployerIntro,
              colorCategory: 'gen',
              link: MainContent.EmployerIntroLink,
            },
            {
              markup: MainContent.JobSeekerIntro,
              colorCategory: 'job',
              link: MainContent.JobSeekerIntroLink,
            },
          ]}
          altLayout
          leanMoreClasses="learnMoreText"
        />

        {/* had to remove title => api is only a wysiwyg */}
        {CategoryContent && (
          <FullWidthTextWithButton
            content={MainContent.EmployerContent}
            link={{
              text: 'Submit a job request',
            }}
          />
        )}

        {CategoryContent && (
          <div className="sm-up-trinity-container">
            <ImageWithOverlayTextListing
              ctas={CategoryContent.slice(0, 3)}
              colorCategory="job-2"
            />
          </div>
        )}

        <div
          className={`trinity-container ${
            !CategoryContent ? 'module-spacer' : ''
          }`}
        >
          {MainContent.JobSeekerContent && (
            <ContentWell content={MainContent.JobSeekerContent} />
          )}
        </div>

        <div className="sm-up-trinity-container">
          {CategoryContent && (
            <ImageWithOverlayTextListing
              ctas={CategoryContent.slice(3)}
              colorCategory="job-2"
            />
          )}
        </div>
      </div>
    );
  }

  return <Loader />;
}

export default StaffingLp;
