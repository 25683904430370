import React, { useState } from 'react';
import './hero-widget.styles.scss';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import useInputCollection from '../../../../js/custom-hooks/useInputCollection';

const INITIAL_STATE = {
  name: '',
  email: '',
};

const allRequiredFields = ['name', 'email'];

const validateRequiredFields = (fields, requiredFields) => {
  let errorMessage;

  requiredFields.forEach((requiredField) => {
    if (fields[requiredField] === '') {
      errorMessage = 'All required fields must be filled out.';
    }
  });

  return !errorMessage ? true : errorMessage;
};

function HeroWidget({ title, link }) {
  const { state, updateInput } = useInputCollection(INITIAL_STATE);

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMesssage] = useState(null);

  const history = useHistory();

  const handleSubmit = async (e) => {
    // e.preventDefault();
    history.replace('/subscribe');
    window.location.reload();

    /* setSubmitting(true);

    const validFormResponse = validateRequiredFields(state, allRequiredFields);
    if (validFormResponse !== true) {
      setErrorMesssage(validFormResponse);
      setSubmitting(false);
      return null;
    }

    const submissionObj = {
      email: state.email ? state.email.trim() : "",
      name: state.name ? state.name.trim() : "",
    };

    const response = await registerUser(submissionObj);

    if (response.error) {
      setErrorMesssage(response.message);
      setSubmitting(false);
      return null;
    } */
  };

  if (state) {
    return (
      <div className="hero-widget-container">
        {/* {errorMessage && <p className="text error">{errorMessage}</p>} */}
        <h3 className="title">{title}</h3>
        <div className="inputs">
          <TextInput
            placeholder="Name"
            name="name"
            onChange={updateInput}
            value={state.name}
          />
          <TextInput
            type="email"
            placeholder="Email"
            name="email"
            onChange={updateInput}
            value={state.email}
          />
        </div>
        <div className="link">
          <CustomButton
            className="heroLink"
            loading={submitting}
            onClick={handleSubmit}
          >
            {link}
          </CustomButton>
        </div>
      </div>
    );
  }
  return null;
}

export default HeroWidget;
