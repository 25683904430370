import React, { Suspense } from 'react';
import './indivi-bio.styles.scss';
import Loader from '../../../../components/commons/helpers/loader/loader.component';

function NewIndiviBioLandingPage({ page: { DropDownContent } }) {
  const HeroComponent = React.lazy(() =>
    import('./new-indivi-bio-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-indivi-bio-content-component')
  );

  return (
    <div>
      <div className="indiv-container">
        <Suspense fallback={<Loader />}>
          <HeroComponent DropDownContent={DropDownContent} />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <ContentComponent DropDownContent={DropDownContent} />
        </Suspense>
      </div>
    </div>
  );
}

export default NewIndiviBioLandingPage;
