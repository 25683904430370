import 'font-awesome/css/font-awesome.min.css';
import React, { useEffect, useState } from 'react';
import './css/global.styles.scss';

import cookie from 'react-cookies';
import Notifications from 'react-notify-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import CookieBanner from './components/commons/cookie-banner/cookie-banner.component';
import Footer from './components/commons/footer/footer.component';
import ImpersonationBanner from './components/commons/navigation/impersonation-banner/impersonation-banner.component.jsx';
import TrinityHeader from './components/new-header/Components/TrinityHeader/TrinityHeader';
import NewHomePage from './components/new-home-page/components/demos/NewHomePage';
import { authRequest } from './js/api/auth/_auth-request';
import { getUserIsLoggedin } from './js/api/auth/_login';
import { GA4_MEASUREMENT_ID, TOKEN_REFRESH_TIME } from './js/api/_config';
import PageRouting from './pages/page-routing.router.jsx';
import { getHomePageData } from './redux/new-home-page/new-home.action';
import ReactGA from 'react-ga4';
import InitializeGoogleAnalytics, {
  TrackGoogleAnalyticsEvent,
} from './TrackGoogleAnalytics';

function App() {
  const user = useSelector(({ user }) => user.tokens.access_token);
  const dispatch = useDispatch();

  const [intervalId, setIntervalId] = useState(0);

  const getCookie = cookie.load('trinityCookie');
  const improfileuser = useSelector(({ cart }) =>
    cart.impersonateMember ? cart.impersonateMember.userprofile : null
  );

  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  console.log('getCookie', getCookie);

  useEffect(() => {
    dispatch(getHomePageData());

    if (user) {
      getUserIsLoggedin().then((response) => {
        if (response) {
          const handleToken = setInterval(() => {
            authRequest().then((response) => {});
          }, TOKEN_REFRESH_TIME);
          setIntervalId(handleToken);
        }
      });
    } else {
      clearInterval(intervalId);
    }
  }, [user]);

  return (
    <div className="App">
      {improfileuser ? <ImpersonationBanner /> : ''}
      <TrinityHeader />
      <main>
        <Route exact path="/" render={() => <NewHomePage />} />
        <PageRouting />
      </main>
      <Notifications />
      <Footer />
      {!getCookie && <CookieBanner />}
    </div>
  );
}

export default App;
