import React, { useState } from 'react';
import './filtered-content-well.styles.scss';

// import SectionTitle from "../../software/section-title/section-title.component";
// import { objData } from "./filtered-content.data";
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import { BASE_URL } from '../../../js/api/_config';

function FilteredContentWell({ title, moduledata }) {
  const objData = [];
  moduledata =
    moduledata != undefined && moduledata
      ? moduledata.sort((a, b) => a.SortOrder - b.SortOrder)
      : null;

  if (moduledata) {
    moduledata.forEach((element, index) => {
      const isActive = index === 0;
      objData.push({
        section: element.Title,
        active: isActive,
        content: element.Content,
        brochure: element.Browcher,
        sortOrder: element.SortOrder,
      });
    });
  }
  const [data, setData] = useState(objData);

  function filterContent(activeSection) {
    const newData = data.map((group) => {
      group.active = activeSection === group.section;
      return group;
    });

    setData(newData);
  }

  if (data) {
    return (
      <div className="bottom-filtered-content-well">
        {title && <h2>{title}</h2>}
        <SelectList
          className="d-md-none"
          onChange={(e) => filterContent(e.target.value)}
          defaultText="— Topics —"
          label="To learn more about EXDAM features, select topics below"
        >
          {data.map(({ section, active }, index) => (
            <option
              key={index}
              className={`filter
                    ${active ? 'active' : ''}
                `}
              value={section}
            >
              {section}
            </option>
          ))}
        </SelectList>

        <hr className="mobile-seperator d-md-none" />

        <div className="content">
          <div className="title-filters">
            {data.map(({ section, active }, index) => (
              <h3
                key={index}
                onClick={() => filterContent(section)}
                className={`filter
                    ${active ? 'active' : ''}
                `}
              >
                {section}
              </h3>
            ))}
          </div>
          {data.map((group, index) => {
            if (group.active) {
              return (
                <>
                  <div
                    key={index}
                    className="content-well"
                    dangerouslySetInnerHTML={{
                      __html: group.content.replace(/<\/?p[^>]*>/g, ''),
                    }}
                  />
                  {group.brochure && group.brochure.length > 0 && (
                    <CustomButton
                      onClick={() => window.open(group.brochure, '_blank')}
                      title="Download Brochure"
                    />
                  )}
                </>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}

export default FilteredContentWell;
