import React, { Suspense, useEffect } from 'react';
import Loader from '../../commons/helpers/loader/loader.component';
import './ehs-individual-article.styles.scss';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import EHSEmptyPageComponent from '../EmptyEHSComponent';

function EhsIndividualArticleLandingPage({ newsId }) {
  const [mainContent, setMainContent] = React.useState(null);
  const [strPublishedDate, setStrPublishedDate] = React.useState('');
  const [document, setDocument] = React.useState('');

  const ContentComponent = React.lazy(() =>
    import('./ehs-individual-article-content-component')
  );

  const getCustomEhsSubPublicationDataFromAPIs = async () =>
    await getPageDataByApiUrl(`/restapi/customehssubpublication/${newsId}`);

  useEffect(() => {
    getCustomEhsSubPublicationDataFromAPIs()
      .then((e) => {
        setMainContent(e?.MainContent);
        setStrPublishedDate(e?.strPublishedDate);
        setDocument(e?.Document);
      })
      .catch(() => {
        setMainContent({});
        setStrPublishedDate('');
      });
  }, []);
  // useEffect(async () => {
  //     console.log("error");
  //     try{
  //     let response = await getPageDataByApiUrl("/restapi/customehssubpublication/" + newsId);

  //     if (response && Object.keys(response).length > 0) {
  //         setMainContent(response?.MainContent)
  //         setStrPublishedDate(response?.strPublishedDate)
  //         setDocument(response?.Document)
  //     } else {
  //         setMainContent({})
  //         setStrPublishedDate("")
  //     }
  // }
  // catch(error){ console.error(error) }
  // }, [window.location])

  if (!mainContent) {
    return <Loader />;
  }

  if (Object.keys(mainContent).length > 0) {
    return (
      <div className="indiv-container indiviual">
        <div className="trinity-container module-spacer">
          <Suspense fallback={<Loader />}>
            <ContentComponent
              MainContent={mainContent}
              strPublishedDate={strPublishedDate}
              document={document}
            />
          </Suspense>
        </div>
      </div>
    );
  }
  return <EHSEmptyPageComponent message="No Data Available" />;
}

export default EhsIndividualArticleLandingPage;
