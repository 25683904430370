export const title = 'Local Links';

export const linkData = [
  {
    link: 'Iowa Department of Natural Resources',
  },
  {
    link: 'EPA Region 7',
  },
  {
    link: 'Emission Factors',
  },
  {
    link: 'EPA Air Toxics Website',
  },
  {
    link: 'Polk County',
  },
  {
    link: 'Linn County',
  },
];
