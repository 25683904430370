import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../components/commons/custom-button/custom-button.component';
import { ENDPOINTS } from '../../../js/api/_config';
import './not-found.scss';

function NotFoundPage() {
  const [display, setDisplay] = React.useState(false);
  let history = useHistory();

  const meta = {
    title: '404 - Page Not Found',
    description: 'Page not found',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  const onClickBackToLogin = () => {
    history.push('/');
  };

  useEffect(() => {
    fetch(ENDPOINTS.URL_404.NOT_FOUND_URL)
      .then((e) => {})
      .catch((e) => {});
    setTimeout(() => {
      setDisplay(true);
    }, 1000);
  }, []);

  if (display) {
    return (
      <DocumentMeta {...meta}>
        <div className="error-display">
          <div className="trinity-container">
            <div className={'outer-container'}>
              <FontAwesomeIcon icon={faFrown} className={'smile-icon'} />
              <hr />
              <span className={'outer-font'}>404</span>
              <h2 className={'light-font-large'}>
                Oops !! Looks like page is gone
              </h2>
              <span className={'light-font'}>
                This link is broken or page is removed.
              </span>
              <br />
              <span className={'light-font'}>
                Please click on the below button to continue.
              </span>
              <br />
              <CustomButton
                title={'Go Back To Home'}
                onClick={onClickBackToLogin}
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </div>
          </div>
        </div>
      </DocumentMeta>
    );
  } else {
    return null;
  }
}

export default NotFoundPage;
