import React, { Component } from 'react';
import Parser from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomButton from '../../../commons/custom-button/custom-button.component';

import './widget-box.styles.scss';

import { Link } from 'react-router-dom';
import moment from 'moment';
import removeStyleFromHTMLString, {
  trinityTrainingDateFormatter,
} from '../../../../utilities/CommonUtilities';

// JP #Task 41
class WidgetBox extends Component {
  state = {
    title: 'Air Compliance Auditing for Industrial Facilities ›',
    titleContent:
      'This 1-day course is for industrial corporate and facility environmental personnel responsible for air permitting and compliance audits. The course covers general auditing concepts and practices, a brief overview of air regulations and permitting requirements, how to prepare and complete an air compliance audit, and documenting and handling audit findings. The course includes a review of example audit work documents, dissection of fshan air permit for auditing purposes, and group exercises.',
    typeText: 'Type:',
    typeInfo: 'Classroom',
    upcomingText: 'Upcoming Times & Locations:',
    time: 'Oct 24, 2020 - 8:00am - 5:00pm',
    location: 'Boston, MA',
    buttonText: 'Learn More',
    filterCategory: null,
  };

  totalCourseDays = (startDate, endDate) => {
    if (
      startDate !== null &&
      endDate !== null &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      const startDateTime = new Date(startDate);
      const endDateTime = new Date(endDate);
      const diffDates = Math.abs(endDateTime - startDateTime);
      const mins = Math.floor(diffDates / 60000);
      const hours = Math.floor(mins / 60);

      return `${hours} hr:${mins} min`; // isNaN(Difference_In_Days)?0:Math.ceil(Difference_In_Days);
    }
    return 0;
  };

  render() {
    // let history = useHistory();

    this.state.filterCategory = this.props.filterCategory;

    const { buttonText, upcomingText } = this.state;

    const handleClick = (product) => {
      localStorage.setItem('prodVariant', JSON.stringify(product));
      // history.push("/training" + "/" + product.ID + "/" + product.Name.replace(/\s+|\+/g, '-').replace(/\//g, '-'));
    };

    function WidgetBoxWraper({ product }) {
      return (
        <div>
          {product.Variants !== undefined && product.Variants.length > 0 ? (
            <div className="widgetBox-wrapper">
              <div className="content-wrapper">
                <div className="first-content">
                  <p className="title">{product.Title} </p>
                  <p className="content-well">
                    {product.LongDescription &&
                      Parser(
                        removeStyleFromHTMLString(product.LongDescription)
                      )}
                  </p>
                  {/* <p dangerouslySetInnerHTML={{ __html: product.ShortDescription }} className={"more-content"} /> */}
                </div>
                {/* <div className="widget-wrapper">
            <div className="left-content">
              <div className="type-wrapper">
                <p className="type">{typeText}</p>
                <p className="type-info">{product.Type}</p>
              </div>
            </div>
          </div> */}
                <hr />
                {product.Variants
                  ? product.Variants.sort((a, b) =>
                      a.SessionTypeName.localeCompare(b.SessionTypeName)
                    )
                      .sort((a, b) => {
                        if (a && a.StartDate && b && b.StartDate) {
                          return new Date(a.StartDate).getTime() >
                            new Date(b.StartDate).getTime()
                            ? 1
                            : -1;
                        }
                        return 0;
                      })
                      .map((variant, i) => (
                        <div className="widget-wrapper" key={i}>
                          {i === 0 && (
                            <p className="upcoming">{upcomingText}</p>
                          )}
                          <Row className="inner-widget-wrapper">
                            <Col xs={12} sm={3} md={2}>
                              <p className="location">
                                {variant.SessionTypeName}
                              </p>
                            </Col>
                            <Col xs={12} sm={3} md={4} className="px-1">
                              {variant.SessionTypeName !== 'OnDemand' &&
                                variant.SessionTypeName !== 'eLearning' && (
                                  <p className="time">
                                    {trinityTrainingDateFormatter(
                                      variant.StartDate
                                    )}{' '}
                                    {variant.EndDate &&
                                      !moment(variant.StartDate).isSame(
                                        variant.EndDate,
                                        'day'
                                      ) &&
                                      `- ${trinityTrainingDateFormatter(
                                        variant.EndDate
                                      )}`}
                                  </p>
                                )}
                            </Col>

                            {/* <Col xs={12} sm={2}>
                    {(variant.SessionTypeName.toLowerCase()=="ondemand" || variant.SessionTypeName.toLowerCase()=="elearning")?(<p>{variant.SessionLength}</p>):(<p className="day-course">{this.totalCourseDays(variant.StartDate,variant.EndDate)}</p>)}
                    </Col> */}
                            <Col xs={12} sm={3} className="px-1">
                              {variant.SessionTypeName.toLowerCase() ===
                                'webinar' ||
                              variant.SessionTypeName.toLowerCase() ===
                                'ondemand' ||
                              variant.SessionTypeName.toLowerCase() ===
                                'elearning' ? (
                                <p className="location">Online</p>
                              ) : (
                                <p className="location">
                                  {variant.City}
                                  {variant.State && `, ${variant.State}`}
                                </p>
                              )}
                            </Col>
                            <Col xs={12} sm={3}>
                              <div className="buttons">
                                <Link
                                  target="_blank"
                                  to={`/training/${
                                    product.ID
                                  }/${product.Title.replace(
                                    /\s+|\+/g,
                                    '-'
                                  ).replace(/\//g, '-')}`}
                                >
                                  <CustomButton
                                    onClick={() => handleClick(product)}
                                  >
                                    {buttonText}
                                  </CustomButton>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <hr className="line-component" />
                        </div>
                      ))
                  : null}
              </div>
              <hr className="widget-hr" />
            </div>
          ) : null}
        </div>
      );
    }

    function WidgetBoxCustom({ product }) {
      return (
        <div>
          <div className="widgetBox-wrapper">
            <div className="content-wrapper">
              <div className="first-content">
                <p className="title">{product.CourseName} </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.CourseDescription,
                  }}
                  className="more-content"
                />
              </div>
              <hr />

              {product.locationSpecificVariants
                ? product.locationSpecificVariants
                    .sort((a, b) => a.SessionType.localeCompare(b.SessionType))
                    .map((variant, i) => (
                      <div className="widget-wrapper" key={i}>
                        {i === 0 && <p className="upcoming">{upcomingText}</p>}

                        <Row className="inner-widget-wrapper">
                          <Col xs={12} sm={1}>
                            <p className="location">{variant.SessionType}</p>
                          </Col>
                          <Col xs={12} sm={4}>
                            <p className="time">
                              {trinityTrainingDateFormatter(variant.StartDate)}{' '}
                              {variant.EndDate
                                ? `- ${trinityTrainingDateFormatter(
                                    variant.EndDate
                                  )}`
                                : null}
                            </p>
                          </Col>
                          <Col xs={12} sm={3}>
                            {variant.SessionType.toLowerCase() === 'webinar' ||
                            variant.SessionType.toLowerCase() === 'ondemand' ||
                            variant.SessionType.toLowerCase() ===
                              'elearning' ? (
                              <p className="location">Online</p>
                            ) : (
                              <p className="location">
                                {variant.City}
                                {variant.State && `, ${variant.State}`}
                              </p>
                            )}
                          </Col>
                          <Col xs={12} sm={3}>
                            <div className="buttons">
                              <Link
                                target="_blank"
                                to={`/${variant.CourseUrl}`}
                              >
                                <CustomButton onClick={() => {}}>
                                  {buttonText}
                                </CustomButton>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ))
                : null}
            </div>
            <hr className="widget-hr" />
          </div>
        </div>
      );
    }

    return this.state.filterCategory && this.state.filterCategory.length > 0
      ? this.state.filterCategory.map((product, idx) =>
          this.props.isCustom ? (
            <WidgetBoxCustom key={idx} product={product} />
          ) : (
            <WidgetBoxWraper key={idx} product={product} />
          )
        )
      : null;
  }
}

export default WidgetBox;
