import React from 'react';
import { AzureMP } from 'react-azure-mp';
import { useHistory, useLocation } from 'react-router-dom';
import './azure-media-player.scss';
import CustomButton from '../custom-button/custom-button.component';

export function AzureMediaPlayer() {
  const history = useHistory();
  const location = useLocation();
  const url = location.state;

  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <div
        className={
          'd-flex ' +
          'justify-content-center ' +
          'align-items-center ' +
          'azure-media-player'
        }
      >
        <AzureMP adaptRatio={[4, 3]} skin="amp-flush" src={[{ src: url }]} />
      </div>
      <div className="d-flex justify-content-center go-back-button">
        <CustomButton className="upload-link" onClick={goBack}>
          Go Back
        </CustomButton>
      </div>
    </>
  );
}
