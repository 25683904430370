import React from 'react';
import { notify } from 'react-notify-toast';
import { ToasterTypes } from './toaster-types';

const notificationStyle = {
  notification: {
    fontSize: '20px',
    color: '#FFF',
  },
};

export const handleNotify = (messagetext, action) => {
  switch (action) {
    case ToasterTypes.Success:
      notify.show(
        <span style={notificationStyle.notification}>{messagetext}</span>,
        action
      );
      break;
    case ToasterTypes.Error:
      notify.show(
        <span style={notificationStyle.notification}>{messagetext}</span>,
        action
      );
      break;
    case ToasterTypes.Warning:
      notify.show(
        <span style={notificationStyle.notification}>{messagetext}</span>,
        action
      );
      break;

    default:
      break;
  }
};
