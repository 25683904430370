import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class TrainingServices {
  static async getTrainingFormatFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_FORMAT_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTrainingCategoryFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_CATEGORY_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTrainingIndustryFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_INDUSTRY_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTrainingLocationFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_LOCATION_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTrainingRegulatoryAgencyFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_REGULATORYAGENCY_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getTrainingDateFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_FILTER.TRAINING_DATE_FILTER_DATA,
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getPageWiseTraining(trainingrequest) {
    return axios({
      method: 'POST',
      url:
        ENDPOINTS.PROXY + ENDPOINTS.TRAINING_FILTER.GET_PAGE_WISE_TRAINING_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: trainingrequest.PageNumber,
        TrainingFormat: trainingrequest.TrainingFormat,
        TrainingCategory: trainingrequest.TrainingCategory,
        TrainingIndustry: trainingrequest.TrainingIndustry,
        TrainingDate: trainingrequest.TrainingDate,
        TrainingLocation: trainingrequest.TrainingLocation,
        TrainingKeywords: trainingrequest.TrainingKeywords,
        IgnoreDefaultSearch: trainingrequest.IgnoreDefaultSearch,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}
