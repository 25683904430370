import React from 'react';
import ContentWell from '../../../../commons/content-well/content-well.component';
import { defaultContent } from '../../../../commons/content-well/content-well.data';
import { BioData } from './bio-profile.data';
import './bio-profile.styles.scss';

function BioProfile({ selectedAuthor, individualcontent }) {
  // #RP - Task - Bind data to Individual Bio Form in Front-End.
  return (
    <div className="bio-profile-container">
      {selectedAuthor && (
        <div className="bio-top">
          <div className="image">
            <img
              style={{ height: 'intrinsic' }}
              src={selectedAuthor.ImageUrl}
            />
          </div>
          <div className="bio-sub-text">
            <p className="info">Contact Information: </p>
            {/* <p className="address-1">address 1</p>
            <p className="address-2">address 2</p>
            <p className="city">Dallas, TX 75251</p> */}
            <a href={`tel:${selectedAuthor.Phone}`} className="phone">
              {selectedAuthor.Phone}
            </a>
            <a className="email" href={`mailto:${selectedAuthor.Email}`}>
              {selectedAuthor.Email}
            </a>
          </div>
        </div>
      )}
      {selectedAuthor && individualcontent == undefined && (
        <ContentWell content={selectedAuthor.Content} />
      )}
      {individualcontent != undefined &&
        individualcontent != null &&
        individualcontent != '' && <ContentWell content={individualcontent} />}
    </div>
  );
}

export default BioProfile;
