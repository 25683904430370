import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import WidgetBox from '../widgetBox/widgetBox-with-button/widget-box.component';
import TrainingAdvanceSearchFilter from '../training-advance-search-filter/training-advance-search-filter.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
} from '../../../redux/training/training.action';
import './training-advance-search-filter-data-listing.styles.scss';
import Loader from '../../commons/helpers/loader/loader.component';

function TrainingAdvanceSearchFilterDataListing({ Product, isCustom }) {
  const dispatch = useDispatch();

  const trainingList = useSelector(({ training }) => training.TrainingList);
  const data =
    Product == undefined ||
    Product == null ||
    Product == [] ||
    Product.length == 0
      ? trainingList.Product
      : Product;
  const totalItemsCount = useSelector(
    ({ training }) => training.TotalItemsCount
  );
  const activePage = useSelector(({ training }) => training.PageNumber);
  const perPageCount = useSelector(
    ({ training }) => training.TrainingList.TrainingRecordsPerPage
  );

  const pformat = useSelector(
    ({ training }) => training.TrainingFormatFilterSelected
  );
  const pcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterSelected
  );
  const pindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterSelected
  );
  const plocation = useSelector(
    ({ training }) => training.TrainingLocationFilterSelected
  );
  const pdate = useSelector(
    ({ training }) => training.TrainingDateFilterSelected
  );
  const pkeyword = useSelector(
    ({ training }) => training.TrainingKeywordFilterSelected
  );
  const trainingLoading = useSelector(
    ({ training }) => training.TrainingLoading
  );
  const IgnoreDefaultSearch = useSelector(
    ({ training }) => training.IgnoreDefaultSearch
  );

  const handlePageChange = (pageNumber) => {
    if (pageNumber != activePage) {
      const selectformat = [];
      const selectcategory = [];
      const selectindustry = [];
      const selectlocation = [];
      const selectdate = [];
      const selectkeyword = [];

      pformat.map((item) => {
        selectformat.push(item.Name);
      });
      pcategory.map((item) => {
        selectcategory.push(item.Name);
      });
      pindustry.map((item) => {
        selectindustry.push(item.Name);
      });
      plocation.map((item) => {
        selectlocation.push(item.Name);
      });
      pdate.map((item) => {
        selectdate.push(item.Name);
      });
      pkeyword.map((item) => {
        selectkeyword.push(item.Name);
      });

      const submissionObj = {
        PageNumber: pageNumber,
        TrainingFormat: selectformat,
        TrainingCategory: selectcategory,
        TrainingIndustry: selectindustry,
        TrainingLocation: selectlocation,
        TrainingDate: selectdate,
        TrainingKeywords: selectkeyword,
        IgnoreDefaultSearch,
      };

      dispatch(loadTraining(true));
      dispatch(getPageWiseTraining(submissionObj));
      dispatch(activePageNumber(pageNumber));
    }
  };

  return (
    <div className="module-spacer">
      <ContentWithSidebar
        left={
          <Row className="no-gutter-around xs-only-trinity-container">
            <Col>
              <div className="wrapper xs-only-trinity-container">
                {!trainingLoading &&
                  data &&
                  data != undefined &&
                  data != null &&
                  data.length > 0 &&
                  data[0].Variants != null &&
                  data[0].Variants.length > 0 && (
                    <div>
                      {/* <h3 style={{marginBottom: "2rem"}}>Total <span style={{color: "#41AFF7"}}>{totalItemsCount}</span> training found</h3>                                                                                                 */}
                      {/* <br/> */}
                      <WidgetBox isCustom={isCustom} filterCategory={data} />
                      <br />
                      {totalItemsCount > perPageCount && (
                        <div className="floatright">
                          <Pagination
                            prevPageText="Previous"
                            nextPageText="Next"
                            hideDisabled
                            activePage={activePage}
                            itemsCountPerPage={perPageCount}
                            totalItemsCount={totalItemsCount}
                            onChange={handlePageChange}
                          />
                        </div>
                      )}
                    </div>
                  )}
                {(trainingLoading || data == undefined) && <Loader />}
                {!trainingLoading &&
                  (data == null ||
                    data.length == 0 ||
                    data == '' ||
                    data[0].Variants == null ||
                    data[0].Variants.length == 0) && <p>No Records found</p>}
              </div>
            </Col>
          </Row>
        }
        right={<TrainingAdvanceSearchFilter />}
      />
    </div>
  );
}

export default TrainingAdvanceSearchFilterDataListing;
