import { UserActionTypes } from './user.types';

const INITIAL_STATE = {
  tokens: {
    access_token: null,
    refresh_token: null,
  },
  verified_email: null,
  redirect_router: null,
  loggedin_user: {},
  isbackend_user: false,
  isuser_login_firsttime: false,
  subscribe_user_data: {},
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.ADD_USER:
      return {
        ...state,
        tokens: action.payload,
      };
    case UserActionTypes.REMOVE_USER:
      return INITIAL_STATE;
    case UserActionTypes.VERIFIED_EMAIL:
      return {
        ...state,
        verified_email: action.payload,
      };
    case UserActionTypes.REDIRECT_ROUTER:
      return {
        ...state,
        redirect_router: action.payload,
      };
    case UserActionTypes.LOGGEDIN_USER:
      return {
        ...state,
        loggedin_user: action.payload,
      };
    case UserActionTypes.GET_IMPERSONATE_DETAILS:
      return {
        ...state,
        tokens: action.payload,
      };
    case UserActionTypes.ISBACKEND_USER:
      return {
        ...state,
        isbackend_user: action.payload,
      };
    case UserActionTypes.ISUSER_LOGIN_FIRSTTIME:
      return {
        ...state,
        isuser_login_firsttime: action.payload,
      };
    case UserActionTypes.SUBSCRIBE_USER_DATA:
      return {
        ...state,
        subscribe_user_data: action.payload,
      };
    default:
      return state; // this is not initial state otherwise it's load default state
  }
};

export default userReducer;
