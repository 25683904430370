import SoftwareSecondaryModel from './software-models/software-secondary.model';
import SoftwareLpModel from './software-models/software-lp.model';

const softwarePages = [
  // {
  //   name: "Software",
  //   path: "/software",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "software-landing",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Software",
  //       hero_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit. Malesuada fames ac turpis egestas integer. Tellus mauris a diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Ac odio tempor orci dapibus. Risus in hendrerit gravida rutrum. Ligula ullamcorper malesuada proin libero nunc consequat interdum. Porttitor leo a`,
  //     },
  //   }),
  // },
  // {
  //   name: "Software Product",
  //   path: "/software-product",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "SoftwareProductDetail",
  // },
  // {
  //   name: "Software Product Individual Tour",
  //   path: "/software-product-tour",
  //   template: "SoftwareProductIndivTour",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "ExDAM - Structure Development",
  //       hero_description: `Evaluate damage to structures and injury to people caused by explosions`,
  //     },
  //   }),
  // },
  {
    name: 'Software Secondary',
    path: '/software-secondary',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'SoftwareSecondary',
    modules: SoftwareSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'Model-Ready Data',
        hero_description: `<p>The BREEZE Data Team is staffed by professional meteorologists and environmental engineers who provide expertise in obtaining meteorological and terrain data for your modeling needs. Our team is knowledgeable about sources for data worldwide, potential problems with external data sets and the appropriate ways to address them, and the proper preprocessing techniques for model input.</p>

                <p>Acquiring data, verifying quality, and processing it into a model-ready state can be a costly, time-consuming process. Our long history of data processing enables us to handle both your routine and non-routine meteorological data processing needs with expertise and efficiency. Don’t compromise your modeling with delayed data availability or improper data handling. Let the BREEZE meteorologists and engineers process data for you quickly and properly.</p>
                
                <p>
                Why choose BREEZE for your Data needs?</br>
                - Quality-assured data processed by professional meteorologists and engineers</br>
                - Missing observations filled using U.S. EPA-accepted practices</br>
                - Data requests processed within 1-2 business days
                <p>`,
        hero_specialty_cta: {
          title: 'REQUEST A QUOTE',
          content:
            'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
          link: {
            src: '',
            text: 'Request a quote',
          },
        },
      },
      content_area: `
                <h1>Meteorological and Terrain Data for Environmental Modeling</h1>

                <p>
                   <a href="#">Data for AERMOD and AERMET</a></br>
                    BREEZE meteorologists can provide you with AERMET- and AERMOD-ready meteorological and terrain datasets that are checked and quality assured according to U.S. EPA standards, adapting the EPA methodology as needed in cases where a local regulatory agency prefers to deviate from EPA guidance, or for international locations where U.S.-specific guidance does not apply. Our staff will help you identify the most appropriate station for your project and, if necessary, fill data according…
                </p>
                <p>
                <a href="#">Data for CALPUFF and CALMET</a></br>
                    Preparing meteorological data for CALPUFF modeling is time-intensive due to the several processing steps and large data files involved. Our unique combination of computing infrastructure and professional meteorologists allow BREEZE to process your data in a timely manner.
                </p>
                <p>
                <a href="#">Data for ISC and CAL3QHCR</a></br>
                    BREEZE processes this data in accordance with U.S. EPA guidance, adapting the EPA methodology as needed in cases where a local regulatory agency prefers to deviate from EPA guidance, or for international locations where U.S.-specific guidance does not apply. In such cases, the Data Team’s Certified Consulting Meteorologists (CCM) make expert decisions on the most credible scientific approach to use.
                </p>
                <p>
                <a href="#">Data for ADMS</a></br>
                    The ADMS air dispersion model uses an .ADM meteorological input data file format. The BREEZE Data Team can provide meteorological data in this format for locations around the globe. Typically, the main input for this type of data is a meteorological surface observing station (such as those found at most airports). However, for remote locations, over-water locations, etc., the BREEZE Team is able to use mesoscale model simulations (WRF or MM5) to produce accurate, site-specific data in areas…
                </p>
                <p>
                <a href="#">Custom Datasets</a></br>
                    Under the supervision of an AMS Certified Consulting Meteorologist, the BREEZE Data Team processes and analyzes meteorological data for an array of needs that fall outside of typical air dispersion model inputs. From converting on-site meteorological tower data into custom data formats needed for specialized models to facility design studies that require determination of mean and extreme climatological conditions, the BREEZE Data Team is equipped to find efficient, innovative solutions to meet…
                </p>
                <p>
                <a href="#">Certified Consulting Meteorologists</a></br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget….
                </p>
                <p>
                <a href="#">Model Ready Data FAQs</a></br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget….
                </p>
                <p>
                <a href="#">Pricing</a></br>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.
                </p>
            `,
    }),
  },
  // {
  //   name: "Software Support",
  //   path: "/software/software-support",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "SoftwareSupport",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Support",
  //     },
  //   }),
  // },
  {
    name: 'Support Request Form',
    path: '/support-request',
    template: 'SupportRequestForm',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Software Support Request',
      },
    }),
  },
  {
    name: 'Software Demo Form',
    path: '/software-demo',
    template: 'software-demo',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Request a Software Demo',
        hero_description: 'Schedule a Complimentary, Live Online Demonstration',
      },
    }),
  },
  // {
  //   name: "Software Request A Trial Form",
  //   path: "/request-a-trial-license",
  //   template: "software-request-a-trial",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Request a Trial License",
  //       hero_description:
  //         "Submit the form below to request a free software trial",
  //     },
  //   }),
  // },
  {
    name: 'Software Request A Trial Form',
    path: '/request-a-trial-license',
    template: 'new-software-request-a-trial',
    apiurl: '/restapi/customrequesttrialcontent/requesttrial',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Request a Trial License',
        hero_description:
          'Submit the form below to request a free software trial',
      },
    }),
  },
  // {
  //   name: "Software Request Key Form",
  //   path: "/software-request-key",
  //   template: "software-request-key",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Request BRMS Access",
  //     },
  //   }),
  // },
  {
    name: 'Software Request Key Form',
    path: '/software-request-key',
    apiurl: '/restapi/customsoftwarerequestkeycontent/softwarerequestkey',
    template: 'new-software-request-key',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Request BRMS Access',
      },
    }),
  },
  // {
  //   name: "Request A Key",
  //   path: "/request-key",
  //   template: "request-a-key",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Request A Key",
  //     },
  //   }),
  // },
  {
    name: 'Request A Key',
    path: '/request-key',
    apiurl:
      '/restapi/customsoftwaresupportrequestcontent/softwaresupportrequest',
    template: 'new-request-a-key',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Request A Key',
      },
    }),
  },
  // {
  //   name: "Request Software Quote",
  //   path: "/request-software-quote",
  //   template: "request-software-quote",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Request A Quote",
  //       hero_description: "Request A Demo",
  //     },
  //   }),
  // },
  {
    name: 'Request Software Quote',
    path: '/request-software-quote',
    apiurl: '/restapi/customrequestsoftwarequotecontent/requestsoftwarequote',
    template: 'new-request-software-quote',
    modules: SoftwareLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Request A Quote',
        hero_description: 'Request A Demo',
      },
    }),
  },
  // {
  //   name: "Software Resources",
  //   path: "/software-resources",
  //   template: "software-resources",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Software Resources",
  //     },
  //   }),
  // },
  // {
  //   name: "Release Notes",
  //   path: "/release-notes",
  //   template: "release-notes",
  //   modules: SoftwareLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "3D Analyst Release 4.0",
  //       hero_description: "Prompt and quality support from technical experts",
  //     },
  //   }),
  // },
];

export default JSON.stringify(softwarePages);
