import React, { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewResourcesLandingPage({ page: { MainContent, breadCrumbList } }) {
  const dispatch = useDispatch();

  const HeroComponent = React.lazy(() =>
    import('./new-resources-hero-component')
  );
  const ResourceSearchRootComponent = React.lazy(() =>
    import('./resource-search/ResourceSearchRootComponent')
  );

  return (
    <div className="resources resources-lp">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
        <ResourceSearchRootComponent />
      </Suspense>
    </div>
  );
}

export default NewResourcesLandingPage;
