import React, { Suspense, useEffect } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './new-subscribe-second-style.scss';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';

function NewSubscribeSecondLanding() {
  const [mainContent, setMainContent] = React.useState({});

  const HeroComponent = React.lazy(() => import('./new-subscribe-second-hero'));
  const ContentComponent = React.lazy(() =>
    import('./new-subscribe-second-level-content')
  );

  useEffect(async () => {
    const response = await getPageDataByApiUrl(
      '/restapi/customsubscribecontent/subscribe'
    );
    if (response) {
      setMainContent(response?.MainContent);
    }
  }, []);

  if (Object.keys(mainContent).length > 0) {
    return (
      <div>
        <div>
          <div className="subscribe-container">
            <Suspense fallback={<Loader />}>
              <HeroComponent MainContent={mainContent} />
            </Suspense>
          </div>
          <div className="indiv-container indiviual">
            <div className="trinity-container module-spacer">
              <Suspense fallback={<Loader />}>
                <ContentComponent MainContent={mainContent} />
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default NewSubscribeSecondLanding;
