import React from 'react';
import './icon-list-item.styles.scss';

import { ReactComponent as EducationIcon } from '../../../../images/software/education-icon.svg';
import CustomButton from '../../../commons/custom-button/custom-button.component';

function IconListItem({ title, description, iconurl, buttontext, buttonlink }) {
  return (
    <a className="icon-list-item xs-only-trinity-container">
      {/* <EducationIcon /> */}
      {iconurl ? (
        <img width="65.09" height="43.177" src={iconurl} />
      ) : (
        <EducationIcon />
      )}
      <h3 className="title">{title}</h3>
      <p>{description}</p>

      <CustomButton
        rounded
        altColors
        onClick={() => {
          window.location.replace(buttonlink);
        }}
      >
        {buttontext}
      </CustomButton>
    </a>
  );
}

export default IconListItem;
