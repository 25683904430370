import IndustriesModel from './industries-models/industries.model';
import calender from '../../images/industries/calender.svg';
import pdf from '../../images/industries/pdf.svg';
import video from '../../images/industries/video.svg';
import factory from '../../images/industries/factory.svg';

const industriesPages = [
  // {
  //   modules: IndustriesModel({
  //     widgets: [
  //       {
  //         widgetTitle: "Upcoming Training Courses",
  //         widgetContent:
  //           "Accidental Release Modeling Workshop with BREEZE Incident Analyst",
  //         widgetSubContent: "Dallas, TX",
  //         widgetLink: "All Training Courses ›",
  //         widgetImage: calender
  //       },
  //       {
  //         widgetTitle: "Related Resources",
  //         widgetContent: "Air Dispersion Modeling Services Sheet",
  //         singleLink: "Download Adobe Acrobat Reader",
  //         widgetLink: "All Resources ›",
  //         widgetImage: video
  //       },
  //       {
  //         widgetTitle: "Related News",
  //         widgetContent: "News article headline goes here",
  //         singleLink: "Download Adobe Acrobat Reader",
  //         widgetLink: "All News ›",
  //         widgetImage: pdf
  //       },
  //       {
  //         widgetTitle: "Related Products",
  //         widgetContent: "AERSCREEN",
  //         widgetSubContent: "A screening model",
  //         widgetLink: "All Products ›",
  //         widgetImage: factory
  //       }
  //     ]
  //   })
  // }
];

export default JSON.stringify(industriesPages);
