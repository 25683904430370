import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../../components/commons/hero/hero.component';
import ImageWithOverlayTextListing from '../../../../components/about/image-with-overlay-text-listing/career-image-with-overlay-text-listing.component';
import CareersListing from '../../../../components/about/careers/career-lisiting-without-pagination/career-lisiting.component';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './careers-lp.styles.scss';

// #RP - Task 124 Career
function CareersLP({ page: { MainContent, CareerContent } }) {
  if (MainContent && CareerContent) {
    return (
      <div className="careers-lp careers-lp-container">
        {MainContent && (
          <Hero
            src={MainContent.HeaderImage_src ? MainContent.HeaderImage_src : ''}
            mobileImgSrc={
              MainContent.HeaderMobileImage_src
                ? MainContent.HeaderMobileImage_src
                : ''
            }
            alt={MainContent.HeaderImage_alt ? MainContent.HeaderImage_alt : ''}
            title={
              MainContent.Title
                ? MainContent.Title
                : 'Please contact admin for page content'
            }
            description={MainContent ? MainContent.HeaderContent : ''}
            template="landing"
            colorCategory="policies"
          />
        )}
        <div className="trinity-container">
          {CareerContent && (
            <ImageWithOverlayTextListing
              ctas={CareerContent.CompanyCareerPositionsContent}
              colorCategory="policies"
            />
          )}
          <Row>
            {CareerContent && (
              <Col>
                <CareersListing
                  data={
                    CareerContent.AvailablePositionsContent
                      .IndividualAvailablePositionsContent
                  }
                  avaData={CareerContent.AvailablePositionsContent}
                  subTitle={MainContent.SubTitle}
                />
              </Col>
            )}
            {/* <Col sm={5} md={4}>
            <JobAlertWidget />
          </Col> */}
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default CareersLP;
