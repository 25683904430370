import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { components } from 'react-select';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import Creatable from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import AdminNewsActiveSearchFilter from './admin-news-search-active-filter.component';
import SelectList from '../../../components/commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../../components/commons/custom-button/custom-button.component';
import {
  activePageNumber,
  getAdminPageWiseNews,
  getPageWiseNews,
  loadNews,
  selectNewsActionFilter,
} from '../../../redux/news/news.action';

import './admin-news-advance-filter.styles.scss';
import 'react-datepicker/dist/react-datepicker.css';

library.add(faCaretDown);

function AdminNewsAdvanceFilters({ dataSource, publishedDateSource }) {
  const dispatch = useDispatch();
  const ptags = useSelector(({ news }) => news.tagsFilter);
  const pnews = useSelector(({ news }) => news.newsFilter);
  const pindustry = useSelector(({ news }) => news.industryFilter);
  const pdate = useSelector(({ news }) => news.publishedDateFilter);
  const plocation = useSelector(({ news }) => news.locationFilter);
  const newsLoading = useSelector(({ news }) => news.newsLoading);

  const [values, setValues] = useState([]);
  const [tags, setTags] = useState([]);
  const [newsCategory, setNewsCategory] = useState([]);
  const [industryCategory, setIndustryCategory] = useState([]);
  const [location, setLocation] = useState([]);
  const [publishedDate, setPublishedDate] = useState([]);
  const [filterInputText, setFilterInputText] = useState('');
  const [selectValue, setSelectValue] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setValues(dataSource.AllTags);
  }, [dataSource.AllTags]);

  const handleChange = (event) => {
    event.persist();
    if (
      event.target.name === 'Tags' &&
      event.target.name != '— Keyword OR Phrase —'
    ) {
      setTags((tags) => ({
        ...tags,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (
      event.target.name === 'NewsCategory' &&
      event.target.name != '— News Category —'
    ) {
      setNewsCategory((newsCategory) => ({
        ...newsCategory,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'IndustryCategory') {
      setIndustryCategory((industryCategory) => ({
        ...industryCategory,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Location') {
      setLocation((location) => ({
        ...location,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'PublishedDate') {
      setPublishedDate((publishedDate) => ({
        ...publishedDate,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSuccess();
  };

  function submitSuccess() {
    if (
      tags != null &&
      tags.Id != undefined &&
      tags.Id != '— Keyword OR Phrase —' &&
      tags.Name != '— Keyword OR Phrase —' &&
      tags.Id != 0 &&
      tags.Id != '0'
    )
      dispatch(selectNewsActionFilter(tags, ptags, 'Tags'));
    if (
      newsCategory != null &&
      newsCategory.Id != undefined &&
      newsCategory.Id != '— News Category —' &&
      newsCategory.Name != '— News Category —' &&
      newsCategory.Id != 0 &&
      newsCategory.Id != '0'
    )
      dispatch(selectNewsActionFilter(newsCategory, pnews, 'News'));
    if (
      industryCategory != null &&
      industryCategory.Id != undefined &&
      industryCategory.Id != '— Industry Category —' &&
      industryCategory.Name != '— Industry Category —' &&
      industryCategory.Id != 0 &&
      industryCategory.Id != '0'
    )
      dispatch(selectNewsActionFilter(industryCategory, pindustry, 'Industry'));
    if (
      location != null &&
      location.Id != undefined &&
      location.Id != '— Location —' &&
      location.Name != '— Location —' &&
      location.Id != 0 &&
      location.Id != '0'
    )
      dispatch(selectNewsActionFilter(location, plocation, 'Location'));
    if (
      publishedDate != null &&
      publishedDate.Id != undefined &&
      publishedDate.Id != '— Published Date —' &&
      publishedDate.Name != '— Published Date —' &&
      publishedDate.Id != 0 &&
      publishedDate.Id != '0'
    )
      dispatch(selectNewsActionFilter(publishedDate, pdate, 'PublishedDate'));
    if (startDate != null && endDate != null) {
      dispatch(
        selectNewsActionFilter(publishedDate, startDate, 'PublishedFromDate')
      );
      dispatch(
        selectNewsActionFilter(publishedDate, endDate, 'PublishedToDate')
      );
    }

    const selecttags = [];
    const selectnews = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectdate = [];

    ptags.map((item) => {
      selecttags.push(item.Name);
    });
    pnews.map((item) => {
      selectnews.push(item.Id);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pdate.map((item) => {
      selectdate.push(item.Id);
    });

    const submissionObj = {
      PageNumber: 1,
      Tags: selecttags,
      NewsCategory: selectnews,
      IndustryCategory: selectindustry,
      Location: selectlocation,
      PublishedDate: selectdate,
      PublishedFromDate:
        startDate && endDate && new Date(startDate).toISOString(),
      PublishedToDate: startDate && endDate && new Date(endDate).toISOString(),
    };

    dispatch(loadNews(true));
    dispatch(getAdminPageWiseNews(submissionObj));
    dispatch(activePageNumber(1));

    setTags({ Name: '— Keyword OR Phrase —', Id: null });
    setNewsCategory({ Name: '— News Category —', Id: null });
    setIndustryCategory({ Name: '— Industry Category —', Id: null });
    setLocation({ Name: '— Location —', Id: null });
    setPublishedDate({ Name: '— Published Date —', Id: null });
    setStartDate(null);
    setEndDate(null);
  }

  function CaretDownIcon() {
    return <FontAwesomeIcon icon="caret-down" />;
  }

  function DropdownIndicator(props) {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon classname="arrow" />
      </components.DropdownIndicator>
    );
  }

  const getPreparedTagData = (tagList) =>
    tagList.map((e) => ({
      label: e.Name,
      value: e.Id,
    }));

  const handleTagChange = (event) => {
    if (event && event.label && event.value) {
      setSelectValue({
        label: event.label,
        value: event.value,
      });
      setTags((tags) => ({
        ...tags,
        Name: event.label,
        Id: event.value,
      }));
    } else {
      setSelectValue({
        label: event,
        value: event,
      });
      setTags((tags) => ({
        ...tags,
        Name: event,
        Id: event,
      }));
    }
  };

  const handleTagCreate = (value) => {
    setValues((values) => {
      if (!values) {
        return values;
      }
      const index = values.findIndex(
        (e) => e.Name === value || e.Id === value.trim().toLowerCase()
      );
      if (index === -1) {
        return [
          ...values,
          {
            Name: value,
            Id: value.trim().toLowerCase(),
          },
        ];
      }
      return values;
    });
    setTimeout(() => {
      handleTagChange(value.trim().toLowerCase());
    }, 200);
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      padding: 5,
      borderRadius: 15,
      borderWidth: '1px',
      borderColor: '#707070',
    }),
  };

  const handleSelectEnter = (e) => {
    if (filterInputText && e.key === 'Enter') handleTagCreate(filterInputText);
  };

  return (
    <div className="sidebar-with-filters module-spacer-bottom">
      <div className="advanced-search">
        <h2>ADVANCED SEARCH</h2>

        <AdminNewsActiveSearchFilter />

        {values && (
          <Creatable
            className="mb-2"
            placeholder="- Keyword OR Phrase -"
            // formatCreateLabel={(inputText) => `Search "${inputText}"`}
            value={selectValue}
            styles={selectStyles}
            onChange={handleTagChange}
            components={{ DropdownIndicator }}
            options={getPreparedTagData(values)}
            onCreateOption={handleTagCreate}
            // onKeyDown={handleSelectEnter}
            onInputChange={(e) => setFilterInputText(e)}
          />
          /* <SelectList
                          onChange={handleChange}
                          name={"Tags"}
                          value={tags.Id ? tags.Id : "— Tags —"}
                          defaultText="— Tags —"
                          className="mb-2"
                        >
                          {dataSource.AllTags.map((tags) => (
                            <option
                              className={`filter`}
                              value={tags.Id}
                            >
                              {tags.Name}
                            </option>
                          ))}
                        </SelectList> */
        )}

        {dataSource && dataSource.NewsCategory && (
          <SelectList
            onChange={handleChange}
            name="NewsCategory"
            value={newsCategory.Id ? newsCategory.Id : '— News Category —'}
            defaultText="— News Category —"
            className="mb-2"
          >
            {dataSource.NewsCategory.map((news) => (
              <option className="filter" value={news.Id}>
                {news.Name}
              </option>
            ))}
          </SelectList>
        )}

        {/* These need to come from api */}
        {dataSource && dataSource.IndustryCategory && (
          <SelectList
            onChange={handleChange}
            name="IndustryCategory"
            value={
              industryCategory.Id
                ? industryCategory.Id
                : '— Industry Category —'
            }
            defaultText="— Industry Category —"
            className="mb-2"
          >
            {dataSource.IndustryCategory.map((industry) => (
              <option className="filter" value={industry.Id}>
                {industry.Name}
              </option>
            ))}
          </SelectList>
        )}

        {/* {publishedDateSource && (
                        <SelectList
                            onChange={handleChange}
                            name={"PublishedDate"}
                            value={publishedDate.Id ? publishedDate.Id : "— Published Date —"}
                            defaultText="— Published Date —"
                            className="mb-2"
                        >
                            {publishedDateSource.map((date) => (
                                <option
                                    className={`filter`}
                                    value={date.Id}
                                >
                                    {date.Name}
                                </option>
                            ))}
                        </SelectList>
                    )} */}

        {dataSource &&
          dataSource.AllLocation &&
          dataSource.AllLocation != undefined && (
            <SelectList
              onChange={handleChange}
              name="Location"
              value={location.Id ? location.Id : '— Location —'}
              defaultText="— Location —"
              className="mb-2"
            >
              {dataSource.AllLocation.map((location) => (
                <option className="filter" value={location.Id}>
                  {location.Name}
                </option>
              ))}
            </SelectList>
          )}
        <div className="range-date-picker">
          <DatePicker
            placeholderText="— From Published Date —"
            className="range-date mb-2"
            selected={startDate}
            dateFormat="MM/dd/yyyy"
            onChange={(date) => {
              setStartDate(date);
              if (endDate !== null) {
                setEndDate(null);
              }
            }}
          />

          <DatePicker
            placeholderText="— To Published Date —"
            className="range-date mb-2"
            selected={endDate}
            dateFormat="MM/dd/yyyy"
            disabled={!startDate}
            minDate={startDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>

        <CustomButton
          onClick={handleSubmit}
          loading={newsLoading}
          btndisabled={newsLoading}
          className="ml-auto mr-auto d-block button-spacer"
        >
          Search
        </CustomButton>
      </div>
    </div>
  );
}

export default AdminNewsAdvanceFilters;
