export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.jobTitle) {
    errors.jobTitle = 'Job Title is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone number field - ex: 1234567890';
  }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  // if (!values.address2) {
  //   errors.address2 = "Address is required";
  // }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }
  if (!values.companyName) {
    errors.companyName = 'Company Name is required';
  }
  return errors;
}
