import { ENDPOINTS } from './_config';

// JP #Task 41
// I believe this accepts an id with the section in which to get the products
export const getCategory = async (section) => {
  try {
    // TODO make this endpoint a method that accepts an id
    const request = await fetch(ENDPOINTS.GETPRODUCTS);
    const response = await request.json();

    return response;
  } catch (e) {
    return null;
  }
};

export const getTrainingContent = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_TRAINING_CONTENT);
    const response = await request.json();
    return response;
  } catch (error) {
    return null;
  }
};

export const searchCategoryCriteria = async (submissionObj) => {
  try {
    const request = await fetch(ENDPOINTS.PROXY + ENDPOINTS.GET_SEARCH, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

      mode: 'no-cors',
      body: JSON.stringify(submissionObj),
    });
    const response = await request.json();
    const categories = response
      ? response.categoryProducts.filter(
          (cat) =>
            cat.Name !== 'ZZ NOT FOUND' && cat.Name !== 'Location Specific'
        )
      : null;
    return categories;
  } catch (e) {
    return null;
  }
};

export const getTrainingSecondaryContent = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_TRAINING_SECONDARY_CONTENT);
    const response = await request.json();
    return response;
  } catch (error) {
    return null;
  }
};

export const getCategories = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_CATEGORIES);
    const response = await request.json();

    const categories = response.Categories.filter(
      (el) => el.Name.toLowerCase() === 'training courses'
    ).map((element) => {
      if (element) {
        return element.Categories.filter((category) => {
          if (category) {
            return { id: category.ID, Title: category.Name };
          }
          return category;
        });
      }
      return element;
    });

    return categories && categories.length > 0 ? categories[0] : null;
  } catch (e) {
    return null;
  }
};

export const getCategoryProducts = async (Id) => {
  try {
    let request = null;
    if (Id) {
      request = await fetch(`${ENDPOINTS.GET_CATEGORYPRODUCTS}\\${Id}`);
    } else {
      request = await fetch(ENDPOINTS.GET_CATEGORYPRODUCTS);
    }
    const response = await request.json();
    const categories = response
      ? response.categoryProducts.filter(
          (cat) =>
            cat.Name !== 'ZZ NOT FOUND' && cat.Name !== 'Location Specific'
        )
      : null;

    return categories;
  } catch (e) {
    return null;
  }
};

export const getProductsByCategory = async (Id) => {
  try {
    const request = await fetch(`${ENDPOINTS.GET_CATEGORIES}/${Id}`);
    const response = await request.json();
    return response;
  } catch (e) {
    return null;
  }
};
// RK Task#100 training category by name
export const getProductsByCategoryName = async (name) => {
  try {
    const request = await fetch(`${ENDPOINTS.GET_CATEGORIES_NAME}/${name}`);
    const response = await request.json();
    return response;
  } catch (e) {
    return null;
  }
};
export const getSingleProduct = async (Id) => {
  // RK--Updated fetch to fix JSON token error in TrinitySandbox as Temporary
  // try {
  //   const request = await fetch(`${ENDPOINTS.GET_SINGLE_PRODUCT}${Id}`);
  //   const response = await request.json();
  //   return response ? response.Product : null;
  // } catch (e) {
  //   return null;
  // }
  let singleProductResponseData;
  const request = await fetch(`${ENDPOINTS.GET_SINGLE_TRAINING_PRODUCT}${Id}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      singleProductResponseData = data.Product;
    })
    .catch((error) => {});
  return singleProductResponseData;
};

export const getGlobalDropdown = async () => {
  try {
    const request = await fetch(`${ENDPOINTS.GET_ContactUs}`);
    const response = await request.json();
    return response ? response.DropDownContent : null;
  } catch (e) {
    return null;
  }
};
