import axios from 'axios';
import { ENDPOINTS } from '../_config';

// #RP - Task 71 - Website Problem
export const getWebsiteProblemPageData = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_WEBSITE_PROBLEM);
    if (request.status === 204) {
      // 204 => No content in backend
      return request;
    }
    return await request.json();
  } catch (e) {
    return null;
  }
};

export const submitWebsiteProblemPageData = async (fields) => {
  const location = ENDPOINTS.PROXY + ENDPOINTS.SUBMIT_FORM_AZURE_QUEUE;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      // Authorization: 'Basic '+btoa('TrinityQueueMessage:221AEC45-5074-403B-A6E6-8933841F963D'),
      // 'Authorization': "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVHJpbml0eVF1ZXVlTWVzc2FnZSIsIm5iZiI6IjE1OTMxMDY5OTAiLCJleHAiOiIxNTkzMTkzMzkwIn0.BXioW1uoj1mcbX7DTxb2kNsrE-eZ85vZEAKDmphMfZc",
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    return await fetchResponse.json();
  } catch (e) {
    return e;
  }
};

export const submitReportWebsiteProblemResponse = async (fields, token) => {
  const location =
    ENDPOINTS.PROXY + ENDPOINTS.SUBMIT_AZURE_QUEUE.REPORT_WEBSITE_PROBLEM;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    return await fetchResponse.json();
  } catch (e) {
    return e;
  }
};

export const submitFileDataWebsiteProblem = async (formData, token) => {
  const location = ENDPOINTS.SUBMIT_AZURE_QUEUE;
  const config = {
    headers: {
      Authorization: `bearer ${token}`,
      'content-type': 'multipart/form-data',
    },
  };
  try {
    const response = await axios.post(location, formData, config);
    return await response.json();
  } catch (e) {
    return e;
  }
};
