import React, { Component } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FilteredMap from './filitered-map/filitered-map.component';

import './map.styles.css';

class Map extends Component {
  render() {
    return (
      <div className="inner-map-container">
        <FilteredMap />
      </div>
    );
  }
}

export default Map;
