import React from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import CustomTrainingOuter from '../../../components/training/forms/custom-training/custom-training.component';

import './custom-training.styles.scss';

function CustomTraining({ page: { modules } }) {
  return (
    <div className="custom-training-container">
      <Hero
        src={heroImageSrc}
        alt={`${modules.hero.hero_title} image`}
        title={modules.hero.hero_title}
        template="standard"
        description={modules.hero.hero_description}
        colorCategory="train"
      />
      <div className="trinity-container">
        <CustomTrainingOuter />
      </div>
    </div>
  );
}

export default CustomTraining;
