import React from 'react';
import './TrainingAzureSearchRootComponent.scss';
import CustomButton from '../../../../components/commons/custom-button/custom-button.component';
import {
  trinityTrainingDateFormatter,
  trinitySearchTrainingDateFormatter,
} from '../../../../utilities/CommonUtilities';
import { TrainingSearchSiteType } from '../../../../components/commons/enum/enum';
import { SortingByDatePublished } from '../../../../js/helpers/_sort-ctas';

function TrainingAzureSearchPostElement({ element }) {
  const processName = (individualObject) => {
    if (
      individualObject.City?.length > 0 &&
      individualObject.State?.length > 0
    ) {
      return `${individualObject.City}, ${individualObject.State}`;
    }
    if (individualObject.City?.length > 0) {
      return individualObject.City;
    }
    if (
      individualObject.State?.length > 0 &&
      individualObject.State === 'ZZ NOT FOUND'
    ) {
      return '';
    }
    if (individualObject.State?.length > 0) {
      return individualObject.State;
    }
    return '';
  };

  const onClickLearnMore = () => {
    window.open(
      `/training/${element[0].SKU}/${element[0].DisplayName.replace(
        /\s+|\+/g,
        '-'
      ).replace(/\//g, '-')}`,
      '_blank'
    );
  };

  const sitedata =
    element[0] &&
    element[0].SiteId != undefined &&
    element[0].SiteId != null &&
    element[0].SiteId != ''
      ? element[0].SiteId.split(',')
      : null;

  const isSafebridge = !!(
    sitedata !== null &&
    sitedata.find((x) => parseInt(x) === 3) != undefined &&
    Object.keys(sitedata.find((x) => parseInt(x) === 3)).length > 0
  );

  return (
    <div className="widgetBox-wrapper">
      <div className="widget-wrapper">
        <div>
          {/* <div className="tags-container">
                        <ul>
                            <>
                                {sitedata.map((item, index) => {
                                    return (
                                        <li className="list-item" key={index}>
                                            {parseInt(item) == 0 && "TrinityConsultants"}
                                            {parseInt(item) == TrainingSearchSiteType.TrinityConsultants && "TrinityConsultants"}
                                            {parseInt(item) == TrainingSearchSiteType.Breeze && "Breeze"}
                                            {parseInt(item) == TrainingSearchSiteType.SafeBridge && "SafeBridge"}
                                        </li>
                                    )
                                })}
                            </>
                        </ul>
                    </div> */}
        </div>

        <div className="class-dec">
          <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* KT change tag h1 to P and add new class for styling */}
              <h1 className="training-post-heading">
                {element[0]?.DisplayName}
              </h1>
              {/* <p className={"post-heading-sub-title"}>Upcoming time & location</p> */}
              {isSafebridge === true && (
                <p className="post-heading-sub-title">
                  Business Unit : SafeBridge
                </p>
              )}
            </div>
          </div>

          <div>
            <div>
              <CustomButton onClick={onClickLearnMore} title="Learn More" />
            </div>
          </div>
        </div>
        <div className="bottom-row-card-container">
          {element &&
            SortingByDatePublished(element).map((individualObject, index) => (
              <div key={index} className="classroom classroom-changed">
                <h1 className="class-name">
                  {individualObject.ByFormat &&
                  individualObject.ByFormat === 'Complimentary'
                    ? individualObject.SessionTypeName
                    : individualObject.ByFormat === 'CourseSubscription'
                    ? 'Course Subscription'
                    : individualObject.ByFormat}
                </h1>
                {individualObject.ByFormat &&
                individualObject.ByFormat !== 'OnDemand' ? (
                  <>
                    {individualObject.ByFormat &&
                    individualObject.ByFormat === 'Webinar' &&
                    individualObject.City &&
                    individualObject.City === '' &&
                    individualObject.State &&
                    individualObject.State === '' ? (
                      <p className="class-date">
                        {individualObject.EndDate &&
                        individualObject.DatePublished &&
                        trinitySearchTrainingDateFormatter(
                          individualObject.EndDate
                        ) ===
                          trinitySearchTrainingDateFormatter(
                            individualObject.DatePublished
                          )
                          ? individualObject.DatePublished &&
                            trinitySearchTrainingDateFormatter(
                              individualObject.DatePublished
                            )
                          : individualObject.DatePublished &&
                            `${trinitySearchTrainingDateFormatter(
                              individualObject.DatePublished
                            )}-${individualObject.EndDate}` &&
                            trinitySearchTrainingDateFormatter(
                              individualObject.EndDate
                            )}
                        <br />
                        Online
                      </p>
                    ) : (
                      <p className="class-date">
                        {individualObject.EndDate &&
                        individualObject.DatePublished &&
                        trinitySearchTrainingDateFormatter(
                          individualObject.EndDate
                        ) ===
                          trinitySearchTrainingDateFormatter(
                            individualObject.DatePublished
                          )
                          ? individualObject.ByFormat &&
                            individualObject.DatePublished &&
                            individualObject.ByFormat === 'Complimentary' &&
                            individualObject.DatePublished.startsWith('999')
                            ? 'Online'
                            : individualObject.DatePublished &&
                              trinitySearchTrainingDateFormatter(
                                individualObject.DatePublished
                              )
                          : individualObject.DatePublished &&
                            `${trinitySearchTrainingDateFormatter(
                              individualObject.DatePublished
                            )}-${individualObject.DatePublished}` &&
                            trinitySearchTrainingDateFormatter(
                              individualObject.DatePublished
                            )}
                      </p>
                    )}
                    <p className="class-date">
                      {processName(individualObject)}
                    </p>
                  </>
                ) : (
                  <p className="class-date">Online</p>
                )}
                {/* KT add div price-changed 10-25-2021 */}
                <div className="price-changed">
                  <h3>
                    {individualObject &&
                      individualObject.Amount &&
                      individualObject.Amount !== undefined &&
                      individualObject.Amount}
                  </h3>
                </div>
              </div>
            ))}
        </div>
      </div>
      <hr />
    </div>
  );
}

function TrainingAzureSearchPostList({ valueList, activePage }) {
  return (
    <div className="training-posts-container">
      {valueList &&
        valueList
          .slice(activePage * 10 - 10, activePage * 10)
          .map((element) => (
            <TrainingAzureSearchPostElement element={element} />
          ))}
    </div>
  );
}

export default TrainingAzureSearchPostList;
