import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/software/software_lp_hero.png';
import SoftwareRequestForm from '../../../components/commons/forms/software-support-request/support-request.component';
import { getSoftwareSupportRequestPageData } from '../../../js/api/softwareForm/_softwareSupportRequest.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function SofwareSupportRequestForm({ page: { modules } }) {
  // #RP - Task 60 - Software support request web form
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getSoftwareSupportRequestPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.State != undefined)
  ) {
    return (
      <div className="support-request-form-container software">
        <Hero
          src={
            status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status != noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status != noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
        />

        <div className="sm-up-trinity-container">
          <SoftwareRequestForm
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SofwareSupportRequestForm;
