import React from 'react';

import './image-content.styles.scss';

function ImageContent({ src }) {
  return (
    <div className="content-wrapper">
      <div className="left-content">
        <h3 className="heading">Spotlight</h3>
        <img src={src} alt="" />
      </div>
      <div className="mobile-wrapper">
        <div className="middle-content">
          <p className="first">
            NEWS! Trinity acquires PSM specialty firm, Provenance Consulting
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
            euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo.
          </p>
          <a href="#">Read the full article &gt;</a>
        </div>
        <div className="right-content">
          <a href="#">
            Don’t miss important regulatory updates. Subscribe today. ›
          </a>
          <a href="#">
            Company News! U.K. based ecology consulting company ECOSA joins
            Trinity. ›
          </a>
          <a href="#">
            Have a question about environmental requirements? Contact Us! ›
          </a>
        </div>
      </div>
    </div>
  );
}

export default ImageContent;
