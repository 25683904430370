import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../js/custom-hooks/useForm';
import validate from './request-key.validationRules';
import { link, title_1, title_2 } from './request-key.data';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../commons/recaptcha/recaptcha.component';
import './request-key.styles.scss';
import { submitSoftwareRequestKeyResponse } from '../../../js/api/softwareForm/_softwareRequestKey.data';
import { createToken } from '../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../commons/helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../js/api/_config';

function RequestKeyForm({ data, mainData, status }) {
  // RP #Task 52 - Request key
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setMainContent] = useState(mainData);
  const [noContent] = useState(204);

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );

  const mainContent1 = mainData != null ? mainData.LeftContent : '';
  const mainContent2 = mainData != null ? mainData.RightContent : '';

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitSoftwareRequestKeyResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle != null ? values.jobTitle.trim() : 'None',
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Company: values.companyName.trim(),
      Address: values.address.trim(),
      AddressTwo: values.address2 != null ? values.address2.trim() : 'None',
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      Country: values.country != null ? values.country.trim() : 'None',
      Industry: values.industry != null ? values.industry.trim() : 'None',
      Hearaboutus:
        values.hearaboutus != null ? values.hearaboutus.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.request_key.UniqueId,
      Catalog: ListOfFormUniqueData.request_key.Catalog,
    };

    submitData(submissionObj);
  }

  return (
    <div className="request-key-form">
      <Row>
        <Col xs={12} sm={6}>
          <p
            className="title"
            dangerouslySetInnerHTML={{ __html: mainContent1 }}
          />
        </Col>
        <Col xs={12} sm={6}>
          <p
            className="title"
            dangerouslySetInnerHTML={{ __html: mainContent2 }}
          />
        </Col>
      </Row>
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="form-title">{title_1}</p>
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />

            {errors.jobTitle && <span className="help">{errors.jobTitle}</span>}
            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
              maxlength={200}
            />
            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Business Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={200}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Business Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={200}
            />
          </Col>
          <Col xs={12} sm={6} className="right">
            <p className="form-title">{title_2}</p>
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company Name"
              required
              type="text"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              autoComplete="off"
              className={`${errors.companyName && 'error'}`}
              maxlength={100}
            />
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address 1"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />

            <TextInput
              placeholder="Business Address 2"
              type="text"
              name="address2"
              onChange={handleChange}
              value={values.address2 || ''}
              autoComplete="off"
              maxlength={300}
            />
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={100}
            />

            <SelectList
              defaultText="— State/Province —"
              name="State"
              onChange={handleChange}
              value={values.stateName}
              onClick={stateclick}
            >
              {State &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />

            <SelectList
              defaultText="— Country —"
              onChange={handleChange}
              name="Country"
              value={values.countryName}
            >
              {Country &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            <SelectList
              defaultText="— Industry —"
              name="industry"
              required
              onChange={handleChange}
              value={values.IndustryID}
            >
              {status != noContent &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            <SelectList
              defaultText="— How did you hear about us? —"
              name="hearaboutus"
              onChange={handleChange}
              value={values.hearaboutusID}
            >
              {status != noContent &&
                HowDidYouHearAboutUs.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
          </Col>
          <div className="recaptcha">
            <ReCaptcha />
          </div>
          <div className="link">
            <CustomButton className="heroLink">{link}</CustomButton>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default RequestKeyForm;
