import React from 'react';
import './subscribe-thankyou.scss';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../../custom-button/custom-button.component';

function SubscribeThankyouPage() {
  const history = useHistory();

  const onClickBackToLogin = () => {
    history.push('/');
  };

  return (
    <div className="subsribe-thankyou-page">
      <div className="trinity-container">
        <div className="outer-container">
          <FontAwesomeIcon icon={faSmile} className="smile-icon" />
          <hr />
          <span className="outer-font">Thank you for subscribing.</span>
          <h2 className="light-font">
            To find an office near you, please view our{' '}
            <a href="/locations">Locations</a>.
          </h2>
          <br />
          <h2 className="light-font">
            For immediate assistance, please call{' '}
            <a href="tel:9726618100">972.661.8100</a>
          </h2>
          <br />
          <span className="light-font">
            Please click on the below button to continue.
          </span>
          <CustomButton
            title="Go Back To Home"
            onClick={onClickBackToLogin}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
        </div>
      </div>
    </div>
  );
}

export default SubscribeThankyouPage;
