export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }
  if (!values.state) {
    errors.state = 'State is required';
  }
  if (!values.recaptchaValue) {
    errors.recaptchaValue = 'recaptcha is required';
  }
  // if (!values.title) {
  //   errors.title = "Title is required";
  // }
  // if (!values.phone) {
  //   errors.phone = "Phone number field - ex: 1234567890";
  // }
  // if (values.phone) {
  //   var phoneno = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9]{4})$/;
  //   if(!(values.phone.match(phoneno))){
  //     errors.phone = "Phone number field - ex: 1234567890";
  //   }
  // }
  // if (!values.address) {
  //   errors.address = "Address is required";
  // }
  // if (!values.city) {
  //   errors.city = "City is required";
  // }
  // if (!values.postalCode) {
  //   errors.postalCode = "Postal Code is required";
  // }
  // if (!values.companyName) {
  //   errors.companyName = "Company Name is required";
  // }

  return errors;
}
