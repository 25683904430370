import React, { useEffect, useState } from 'react';
import './industry-individual-service.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import widgetData from '../../../components/commons/widgets/widgetBox-component/widgetBox-data';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  getRelatedTrainingCourseData,
  getRelatedResourcesData,
  getRelatedNewsData,
  getRelatedProductData,
} from '../../../redux/widget-box/widget-box.services';
import { authRefresh } from '../../../js/api/auth/_auth-refresh';
import IndustryExpertWidget from '../../../components/commons/widgets/Industry-expert/industry-expert.component';
import SingleWidget from '../../../components/commons/widgets/single-widget/single-widget.component';
import pdfImageWidget from '../../../images/consulting-ehs/pdf.svg';
import {
  newsdata,
  resourcesdata,
  trainingcourseindustriesdata,
  industriesproductdata,
} from '../../../redux/widget-box/widget-box.action';

function IndustrialSecondaryA({
  page: { MainContent, AuthorContent, DownloadTechSheet, ResourceContent },
  section,
}) {
  // const data = widgetData;

  const resourceResponse = {
    type: 'RELATED_RESOURCES_DATA',
    payload: ResourceContent,
    status: true,
  };

  const dispatch = useDispatch();
  const news = useSelector(({ widget }) => widget.news);
  const resourceRedux = useSelector(({ widget }) => widget.resources);
  const resources =
    ResourceContent != undefined &&
    ResourceContent != null &&
    ResourceContent.length > 0
      ? resourceResponse
      : resourceRedux;
  const training = useSelector(({ widget }) => widget.trainingcourseindustries);
  const product = useSelector(({ widget }) => widget.industriesproduct);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(await newsdata(window.location.pathname, 'industries'));
      if (
        ResourceContent == undefined ||
        ResourceContent == null ||
        ResourceContent.length <= 0
      )
        dispatch(await resourcesdata(window.location.pathname, 'industries'));
      dispatch(
        await trainingcourseindustriesdata(
          window.location.pathname,
          'industries'
        )
      );
      dispatch(
        await industriesproductdata(window.location.pathname, 'industries')
      );
    };
    fetchData();
  }, []);

  if (
    MainContent &&
    news != undefined &&
    news.status &&
    resources != undefined &&
    resources.status &&
    training != undefined &&
    training.status &&
    product != undefined &&
    product.status
  ) {
    return (
      <div className="industrialSecondary-a">
        <div className="hero_indus">
          <Hero
            src={MainContent.HeaderImage_src}
            mobileImgSrc={MainContent.HeaderMobileImage_src}
            alt={MainContent.HeaderImage_alt}
            title={MainContent.Title}
            description={`<h3>${MainContent.IntroText}</h3>`}
            template="featured" // RK--changed the standrad to featured for testing the Industry 3rd Level
            downArrow={false} /// /changed the true to false for testing the Industry 3rd Level
            transparent
            colorCategory="secondary-a"
            altLayout
          />
        </div>
        <div className="sm-up-trinity-container trinity-container module-spacer">
          <ContentWithSidebar
            left={<ContentWell content={MainContent.Content} />}
            right={
              <Row>
                <Col sm={6} md={12} lg={12}>
                  {DownloadTechSheet && (
                    <SingleWidget
                      singleLinkText="Download the Tech Sheet:"
                      singleLinkContent={DownloadTechSheet.Title}
                      link={DownloadTechSheet.Url}
                      singleLinkSub=""
                      src={pdfImageWidget}
                    />
                  )}

                  {AuthorContent && (
                    <IndustryExpertWidget
                      title="Industry Experts"
                      data={AuthorContent}
                    />
                  )}
                  <WidgetBox
                    isFromIndustry
                    relatedTrainingCourseContent={training}
                    relatedResourcesContent={resources}
                    relatedNewsContent={news}
                    relatedProductContent={product}
                  />
                </Col>
              </Row>
            }
          />
        </div>
      </div>
    );
  }

  return null;
}

export default IndustrialSecondaryA;
