import React, { Suspense } from 'react';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './policies.styles.scss';

function NewPoliciesLandingPage({ page: { MainContent, breadCrumbList } }) {
  const HeroComponent = React.lazy(() =>
    import('./new-policies-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-policies-content-component')
  );

  return (
    <div className="policies policies-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewPoliciesLandingPage;
