import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { logos } from './logos.data';
import BreezeLogo from '../../../images/homepage/breeze.svg';
import AdventLogo from '../../../images/homepage/advent.svg';
import SafebridgeLogo from '../../../images/homepage/safebridge.svg';
import TrispyrianLogo from '../../../images/homepage/trispyrian.svg';

import './logo-container.styles.scss';

function Image({ logo }) {
  const [defaultImg, setImg] = useState(BreezeLogo);

  const onMouseLeave = (e) => {
    setImg(BreezeLogo);
  };

  const onMouseEnter = (e) => {
    setImg(SafebridgeLogo);
  };

  return (
    <img
      key={logo.image}
      src={defaultImg}
      alt=""
      className="logo"
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    />
  );
}

function Logos() {
  return (
    <div className="images">
      <div className="logos-container">
        <h3 className="title">Trinity title goes here</h3>
        <div className="logos">
          {logos.map((logo) => (
            <Link to="/">
              <Image logo={logo} />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Logos;
