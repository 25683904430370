import React from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/about/policiesHero.svg';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import { defaultContent } from '../../../components/commons/content-well/content-well.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './mission.styles.scss';

function Mission({ page: { MainContent, AboutUsCategoryContent } }) {
  if (MainContent) {
    return (
      <div className="mission-container">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="standard"
          colorCategory="policies"
        />
        <div className="trinity-container">
          <ContentWell
            content={
              AboutUsCategoryContent != undefined &&
              AboutUsCategoryContent.IntroText != undefined &&
              AboutUsCategoryContent.IntroText != null &&
              AboutUsCategoryContent.IntroText != ''
                ? MainContent.Content
                : MainContent.Content
            }
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default Mission;
