import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const removeListener = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      removeListener();
    };
  }, []);

  return <>{children}</>;
}

export default withRouter(ScrollToTop);
