export const ClientActionTypes = {
  GET_SOFTWARE_DATA_DOWNLOADS: 'GET_SOFTWARE_DATA_DOWNLOADS',
  GET_ORDER_HISTORY: 'GET_ORDER_HISTORY',
  GET_MODEL_JOB_STATUS: 'GET_MODEL_JOB_STATUS',
  TRAINING_CERTIFICATES: 'TRAINING_CERTIFICATES',
  WEBINAR: 'WEBINAR',
  ELEARNING_LIST: 'ELEARNING_LIST',
  BRMS_KEY: 'BRMS_KEY',
  BRMS_USER_ID: 'BRMS_USER_ID',
  GET_QUEUED_DATA: 'GET_QUEUED_DATA',
  GET_COMPLETED_DATA: 'GET_COMPLETED_DATA',
  OLD_ORDER_HISTORY: 'OLD_ORDER_HISTORY',
  OLD_ON_DEMAND: 'OLD_ON_DEMAND',
  OLD_LEARNING: 'OLD_LEARNING',
};
