import React from 'react';
import ResetSuccessful from '../../../components/ecommerce/forms/password-forms/reset-successful/reset-successful.component';

function ResetSuccesful() {
  return (
    <div className="pswd-reset-success">
      <ResetSuccessful />
    </div>
  );
}

export default ResetSuccesful;
