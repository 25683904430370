import React from 'react';
import Col from 'react-bootstrap/Col';
import { useHistory, useLocation } from 'react-router-dom';
import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function ComSoftware({ page: { MainContent } }) {
  const history = useHistory();
  const path = useLocation();

  React.useEffect(() => {
    if (path.pathname === '/software/support/release-notes') {
      history.push({
        pathname: '/software/support',
        state: {
          isFromMegaMenu: true,
        },
      });
    }
  });

  if (MainContent) {
    if (path.pathname !== '/software/support/release-notes') {
      return (
        <div className="general-container">
          <Hero
            src={MainContent.HeaderImage_src}
            mobileImgSrc={MainContent.HeaderMobileImage_src}
            alt={MainContent.HeaderImage_alt}
            title={MainContent.Title}
            colorCategory="soft"
            template="featured"
            description={MainContent.HeaderContent}
          />
          <div className="trinity-container module-spacer">
            <Col xs={12} lg={10}>
              <ContentWell content={MainContent.Content} />
            </Col>
          </div>
        </div>
      );
    }
  }
  return <Loader />;
}

export default ComSoftware;
