import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { widgetData } from './resource-listing.data';
import ResourcePosts from './resource-posts/resource-landing-posts.component';
// import Pagination from "../pagination/pagination.component";
import './resource-listing.styles.scss';
import Pagination from 'react-js-pagination';
import Loader from '../../commons/helpers/loader/loader.component';

// RP #Task 119 Resource Landing
function ResourceLanding({
  resourceContent,
  resourceLoading,
  postsPerPage,
  totalItemsCount,
  activePage,
  handlePageChange,
}) {
  if (resourceLoading) {
    return <Loader />;
  }

  if (resourceContent && resourceContent.length > 0) {
    return (
      <div className="outerwrapper">
        <ResourcePosts posts={resourceContent} />
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          activePage={activePage}
          itemsCountPerPage={postsPerPage}
          totalItemsCount={totalItemsCount}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
        />
      </div>
    );
  }
  return (
    <div className="outerwrapper">
      <p>No Records found</p>
    </div>
  );
}

export default ResourceLanding;
