export const CUSTOM_SITE_MAP = [
  {
    "name": "Homepage",
    "path": "/",
    "template": "homepage",
    "modules": {
      "hero": {
        "hero_src": "",
        "hero_alt": "",
        "hero_title": "HELPING BUSINESSES ACHIEVE THEIR ENVIRONMENTAL OBJECTIVES",
        "hero_description": "",
        "hero_ctas": []
      }
    }
  },
  {
    "name": "Software",
    "apiurl": "/restapi/customsoftwarecontent/software",
    "url": "/software/products",
    "template": "new-software-landing",
    "section": "software"
  },
  {
    "name": "EHS Quarterly",
    "apiurl": "/restapi/customehspublication/publications",
    "url": "/about-us/ehs-quarterly",
    "template": "new-trinity-ehs-publication",
    "section": "about-us"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/emily-stewart",
    "url": "/about-us/individual-bio/emily-stewart",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/patrick-nonhof",
    "url": "/about-us/individual-bio/patrick-nonhof",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/john-mcfarland",
    "url": "/about-us/individual-bio/john-mcfarland",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/patrick-fisher",
    "url": "/about-us/individual-bio/patrick-fisher",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/emily-kolb",
    "url": "/about-us/individual-bio/emily-kolb",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/matt-berlew",
    "url": "/about-us/individual-bio/matt-berlew",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/raghu-soule",
    "url": "/about-us/individual-bio/raghu-soule",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-sherer-pe",
    "url": "/about-us/individual-bio/mike-sherer-pe",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jose-orsini",
    "url": "/about-us/individual-bio/jose-orsini",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jeff-adkins",
    "url": "/about-us/individual-bio/jeff-adkins",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/courtny-edge",
    "url": "/about-us/individual-bio/courtny-edge",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-phan",
    "url": "/about-us/individual-bio/tony-phan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/james-topp",
    "url": "/about-us/individual-bio/james-topp",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-olson",
    "url": "/about-us/individual-bio/mike-olson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/associate-consultant",
    "url": "/about-us/individual-bio/associate-consultant",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/hung-ming-sue-sung",
    "url": "/about-us/individual-bio/hung-ming-sue-sung",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/priscilla-khuu",
    "url": "/about-us/individual-bio/priscilla-khuu",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/chloe-s-reece",
    "url": "/about-us/individual-bio/chloe-s-reece",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jim-perry",
    "url": "/about-us/individual-bio/jim-perry",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/aj-hansborough",
    "url": "/about-us/individual-bio/aj-hansborough",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/robert-sussman",
    "url": "/about-us/individual-bio/robert-sussman",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brian-anderson",
    "url": "/about-us/individual-bio/brian-anderson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/hillary-killorn",
    "url": "/about-us/individual-bio/hillary-killorn",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dale-overcash",
    "url": "/about-us/individual-bio/dale-overcash",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/bernie-lindquist",
    "url": "/about-us/individual-bio/bernie-lindquist",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/virgil-sufariu",
    "url": "/about-us/individual-bio/virgil-sufariu",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ramya-lakshmanan",
    "url": "/about-us/individual-bio/ramya-lakshmanan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/cheryl-wiramanaden",
    "url": "/about-us/individual-bio/cheryl-wiramanaden",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kurt-last",
    "url": "/about-us/individual-bio/kurt-last",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ian-donaldson",
    "url": "/about-us/individual-bio/ian-donaldson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-zimmer",
    "url": "/about-us/individual-bio/mike-zimmer",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/felicity-melville",
    "url": "/about-us/individual-bio/felicity-melville",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kim-connors",
    "url": "/about-us/individual-bio/kim-connors",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/misty-sinclair",
    "url": "/about-us/individual-bio/misty-sinclair",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/justin-adams",
    "url": "/about-us/individual-bio/justin-adams",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kathryn-connolly",
    "url": "/about-us/individual-bio/kathryn-connolly",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/sarah-gray",
    "url": "/about-us/individual-bio/sarah-gray",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/charles-brower",
    "url": "/about-us/individual-bio/charles-brower",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jason-schmitz",
    "url": "/about-us/individual-bio/jason-schmitz",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/weiping-dai",
    "url": "/about-us/individual-bio/weiping-dai",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/chris-shoop",
    "url": "/about-us/individual-bio/chris-shoop",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/robert-liles",
    "url": "/about-us/individual-bio/robert-liles",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/christina-ridge",
    "url": "/about-us/individual-bio/christina-ridge",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/gena-driscoll",
    "url": "/about-us/individual-bio/gena-driscoll",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/managing-consultant-climate-change-services",
    "url": "/about-us/individual-bio/managing-consultant-climate-change-services",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/john-iwanski",
    "url": "/about-us/individual-bio/john-iwanski",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/christine-heath",
    "url": "/about-us/individual-bio/christine-heath",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/Charles-C-Lee",
    "url": "/about-us/individual-bio/Charles-C-Lee",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/adam-erenstein",
    "url": "/about-us/individual-bio/adam-erenstein",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-burr",
    "url": "/about-us/individual-bio/mike-burr",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/elaine-strauss",
    "url": "/about-us/individual-bio/elaine-strauss",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jon-snoeberger",
    "url": "/about-us/individual-bio/jon-snoeberger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/simon-colenutt",
    "url": "/about-us/individual-bio/simon-colenutt",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/chris-price",
    "url": "/about-us/individual-bio/chris-price",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/john-schnarre",
    "url": "/about-us/individual-bio/john-schnarre",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/terry-donnelly",
    "url": "/about-us/individual-bio/terry-donnelly",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/shreyas-erapalli",
    "url": "/about-us/individual-bio/shreyas-erapalli",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/joe-kupper",
    "url": "/about-us/individual-bio/joe-kupper",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-martin",
    "url": "/about-us/individual-bio/tony-martin",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/amanda-smith",
    "url": "/about-us/individual-bio/amanda-smith",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/regina-dilaavore",
    "url": "/about-us/individual-bio/regina-dilaavore",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lauren-mercer",
    "url": "/about-us/individual-bio/lauren-mercer",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/andrey-nikiforov",
    "url": "/about-us/individual-bio/andrey-nikiforov",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/managing-consultant-pmp",
    "url": "/about-us/individual-bio/managing-consultant-pmp",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ecosa-team",
    "url": "/about-us/individual-bio/ecosa-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kristine-davies",
    "url": "/about-us/individual-bio/kristine-davies",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/erica-dahl",
    "url": "/about-us/individual-bio/erica-dahl",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/chuck-buttry",
    "url": "/about-us/individual-bio/chuck-buttry",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/susan-barnes",
    "url": "/about-us/individual-bio/susan-barnes",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/shishir-mohan",
    "url": "/about-us/individual-bio/shishir-mohan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/caitlin-king",
    "url": "/about-us/individual-bio/caitlin-king",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/sean-cronin",
    "url": "/about-us/individual-bio/sean-cronin",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/nimish-katwala",
    "url": "/about-us/individual-bio/nimish-katwala",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/roop-kumar",
    "url": "/about-us/individual-bio/roop-kumar",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ryan-conley",
    "url": "/about-us/individual-bio/ryan-conley",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/justin-sullivan",
    "url": "/about-us/individual-bio/justin-sullivan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kasi-dubbs",
    "url": "/about-us/individual-bio/kasi-dubbs",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dana-norvell-pe",
    "url": "/about-us/individual-bio/dana-norvell-pe",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/josh-haar",
    "url": "/about-us/individual-bio/josh-haar",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/nav-marocha",
    "url": "/about-us/individual-bio/nav-marocha",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tiffany-stefanescu",
    "url": "/about-us/individual-bio/tiffany-stefanescu",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jenny-brancheau",
    "url": "/about-us/individual-bio/jenny-brancheau",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/george-iwaszek",
    "url": "/about-us/individual-bio/george-iwaszek",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/laura-redmon",
    "url": "/about-us/individual-bio/laura-redmon",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/disha-gadre",
    "url": "/about-us/individual-bio/disha-gadre",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/latha-kambham",
    "url": "/about-us/individual-bio/latha-kambham",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-liebert",
    "url": "/about-us/individual-bio/mike-liebert",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ashley-jones",
    "url": "/about-us/individual-bio/ashley-jones",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/edward-gardetto",
    "url": "/about-us/individual-bio/edward-gardetto",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/valerie-rosenkrantz",
    "url": "/about-us/individual-bio/valerie-rosenkrantz",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lori-bocchino",
    "url": "/about-us/individual-bio/lori-bocchino",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/meghan-foley",
    "url": "/about-us/individual-bio/meghan-foley",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/arron-heinerikson",
    "url": "/about-us/individual-bio/arron-heinerikson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kalpesh-baviskar",
    "url": "/about-us/individual-bio/kalpesh-baviskar",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ellen-hewitt",
    "url": "/about-us/individual-bio/ellen-hewitt",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/sundar-sadashivam",
    "url": "/about-us/individual-bio/sundar-sadashivam",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/qiguo-jing",
    "url": "/about-us/individual-bio/qiguo-jing",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/Subbarao-Kattamuri",
    "url": "/about-us/individual-bio/Subbarao-Kattamuri",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lisa-schmidt",
    "url": "/about-us/individual-bio/lisa-schmidt",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-meister",
    "url": "/about-us/individual-bio/mike-meister",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/paul-smith",
    "url": "/about-us/individual-bio/paul-smith",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kirk-lowery",
    "url": "/about-us/individual-bio/kirk-lowery",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/austin-alexander",
    "url": "/about-us/individual-bio/austin-alexander",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/yana-chervona",
    "url": "/about-us/individual-bio/yana-chervona",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/emily-lower",
    "url": "/about-us/individual-bio/emily-lower",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-colombari",
    "url": "/about-us/individual-bio/tony-colombari",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/wes-younger",
    "url": "/about-us/individual-bio/wes-younger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/patrick-mckean",
    "url": "/about-us/individual-bio/patrick-mckean",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/deborah-walden-hersh",
    "url": "/about-us/individual-bio/deborah-walden-hersh",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/allan-ader",
    "url": "/about-us/individual-bio/allan-ader",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/martin-axon",
    "url": "/about-us/individual-bio/martin-axon",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kim-teofilak",
    "url": "/about-us/individual-bio/kim-teofilak",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jeremiah-redman",
    "url": "/about-us/individual-bio/jeremiah-redman",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/taylor-wilson",
    "url": "/about-us/individual-bio/taylor-wilson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/natalie-vanliew",
    "url": "/about-us/individual-bio/natalie-vanliew",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/angie-wanger",
    "url": "/about-us/individual-bio/angie-wanger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/david-eb-strohm",
    "url": "/about-us/individual-bio/david-eb-strohm",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jay-hofmann",
    "url": "/about-us/individual-bio/jay-hofmann",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/monty-west",
    "url": "/about-us/individual-bio/monty-west",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/bill-bruscino",
    "url": "/about-us/individual-bio/bill-bruscino",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/steve-tasch",
    "url": "/about-us/individual-bio/steve-tasch",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/vineet-masuraha",
    "url": "/about-us/individual-bio/vineet-masuraha",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jenna-kube",
    "url": "/about-us/individual-bio/jenna-kube",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/melissa-ryan",
    "url": "/about-us/individual-bio/melissa-ryan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/katherine-meiser",
    "url": "/about-us/individual-bio/katherine-meiser",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rhoni-whyard",
    "url": "/about-us/individual-bio/rhoni-whyard",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/christa-russell",
    "url": "/about-us/individual-bio/christa-russell",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/katie-brubaker",
    "url": "/about-us/individual-bio/katie-brubaker",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/janet-martin",
    "url": "/about-us/individual-bio/janet-martin",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/steve-walters",
    "url": "/about-us/individual-bio/steve-walters",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/matthew-tarnoff",
    "url": "/about-us/individual-bio/matthew-tarnoff",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/simone-wallace",
    "url": "/about-us/individual-bio/simone-wallace",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-gunsinger",
    "url": "/about-us/individual-bio/mike-gunsinger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/anna-aumann",
    "url": "/about-us/individual-bio/anna-aumann",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ryan-graff",
    "url": "/about-us/individual-bio/ryan-graff",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rich-jenks",
    "url": "/about-us/individual-bio/rich-jenks",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brian-mensinger",
    "url": "/about-us/individual-bio/brian-mensinger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/stephen-smyth",
    "url": "/about-us/individual-bio/stephen-smyth",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jim-lyons",
    "url": "/about-us/individual-bio/jim-lyons",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/safebridge-team",
    "url": "/about-us/individual-bio/safebridge-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tiffany-wang",
    "url": "/about-us/individual-bio/tiffany-wang",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/paul-lepage",
    "url": "/about-us/individual-bio/paul-lepage",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/andrew-martin",
    "url": "/about-us/individual-bio/andrew-martin",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/doug-abeln",
    "url": "/about-us/individual-bio/doug-abeln",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/trs-team",
    "url": "/about-us/individual-bio/trs-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dan-risch",
    "url": "/about-us/individual-bio/dan-risch",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/deanna-duram",
    "url": "/about-us/individual-bio/deanna-duram",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lindsay-giovannetti",
    "url": "/about-us/individual-bio/lindsay-giovannetti",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/mike-remsberg",
    "url": "/about-us/individual-bio/mike-remsberg",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/anna-henolson",
    "url": "/about-us/individual-bio/anna-henolson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dave-larsen",
    "url": "/about-us/individual-bio/dave-larsen",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/divya-agarwal",
    "url": "/about-us/individual-bio/divya-agarwal",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-schroeder",
    "url": "/about-us/individual-bio/tony-schroeder",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/farshid-kiani",
    "url": "/about-us/individual-bio/farshid-kiani",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/herbert-els",
    "url": "/about-us/individual-bio/herbert-els",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/aaron-day",
    "url": "/about-us/individual-bio/aaron-day",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/christi-wilson",
    "url": "/about-us/individual-bio/christi-wilson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/inaas-darrat",
    "url": "/about-us/individual-bio/inaas-darrat",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/katie-jeziorski",
    "url": "/about-us/individual-bio/katie-jeziorski",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rahul-pendse",
    "url": "/about-us/individual-bio/rahul-pendse",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jacob-terry",
    "url": "/about-us/individual-bio/jacob-terry",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/bob-schreiber",
    "url": "/about-us/individual-bio/bob-schreiber",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/minnow-team",
    "url": "/about-us/individual-bio/minnow-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jesse-gonzalez",
    "url": "/about-us/individual-bio/jesse-gonzalez",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/russell-bailey",
    "url": "/about-us/individual-bio/russell-bailey",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/bethany-caspersz",
    "url": "/about-us/individual-bio/bethany-caspersz",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/arkady-mayblyum",
    "url": "/about-us/individual-bio/arkady-mayblyum",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/peter-babigian",
    "url": "/about-us/individual-bio/peter-babigian",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/leonie-andersen",
    "url": "/about-us/individual-bio/leonie-andersen",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/albert-dyrness",
    "url": "/about-us/individual-bio/albert-dyrness",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brad-james",
    "url": "/about-us/individual-bio/brad-james",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brian-noel",
    "url": "/about-us/individual-bio/brian-noel",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/bruce-douglas",
    "url": "/about-us/individual-bio/bruce-douglas",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/eddie-al-rayes",
    "url": "/about-us/individual-bio/eddie-al-rayes",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/advent-team",
    "url": "/about-us/individual-bio/advent-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/melissa-hatfield-atkinson",
    "url": "/about-us/individual-bio/melissa-hatfield-atkinson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/baron-huntwork",
    "url": "/about-us/individual-bio/baron-huntwork",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/pierre-stecko",
    "url": "/about-us/individual-bio/pierre-stecko",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jennifer-markwardt",
    "url": "/about-us/individual-bio/jennifer-markwardt",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/alex-marcucci",
    "url": "/about-us/individual-bio/alex-marcucci",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jason-swofford",
    "url": "/about-us/individual-bio/jason-swofford",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/john-hecht",
    "url": "/about-us/individual-bio/john-hecht",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/robert-a-large",
    "url": "/about-us/individual-bio/robert-a-large",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brett-zogas",
    "url": "/about-us/individual-bio/brett-zogas",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kim-ayotte",
    "url": "/about-us/individual-bio/kim-ayotte",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/aztec-team",
    "url": "/about-us/individual-bio/aztec-team",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/david-dempsey",
    "url": "/about-us/individual-bio/david-dempsey",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/maya-ravishankar",
    "url": "/about-us/individual-bio/maya-ravishankar",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/casey-lenhart",
    "url": "/about-us/individual-bio/casey-lenhart",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/janet-gould",
    "url": "/about-us/individual-bio/janet-gould",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/alison-ritterbusch",
    "url": "/about-us/individual-bio/alison-ritterbusch",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/wendy-merz",
    "url": "/about-us/individual-bio/wendy-merz",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/built-environment-inquiries",
    "url": "/about-us/individual-bio/built-environment-inquiries",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/brian-burdorf",
    "url": "/about-us/individual-bio/brian-burdorf",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jeremy-jewell",
    "url": "/about-us/individual-bio/jeremy-jewell",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tom-muscenti",
    "url": "/about-us/individual-bio/tom-muscenti",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-jabon",
    "url": "/about-us/individual-bio/tony-jabon",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ginger-spencer",
    "url": "/about-us/individual-bio/ginger-spencer",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rich-pandullo",
    "url": "/about-us/individual-bio/rich-pandullo",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/john-colebrook",
    "url": "/about-us/individual-bio/john-colebrook",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rancie-bernal",
    "url": "/about-us/individual-bio/rancie-bernal",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/george-shewe",
    "url": "/about-us/individual-bio/george-shewe",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/hui-cheng",
    "url": "/about-us/individual-bio/hui-cheng",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/cems-specialist",
    "url": "/about-us/individual-bio/cems-specialist",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rob-ferry",
    "url": "/about-us/individual-bio/rob-ferry",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/regional-director",
    "url": "/about-us/individual-bio/regional-director",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/steve-zervas",
    "url": "/about-us/individual-bio/steve-zervas",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/david-meisters",
    "url": "/about-us/individual-bio/david-meisters",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jeremias-szust",
    "url": "/about-us/individual-bio/jeremias-szust",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kyle-dunn",
    "url": "/about-us/individual-bio/kyle-dunn",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/melissa-hillman",
    "url": "/about-us/individual-bio/melissa-hillman",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/alan-chuang",
    "url": "/about-us/individual-bio/alan-chuang",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/branden-barry",
    "url": "/about-us/individual-bio/branden-barry",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/sharon-limage",
    "url": "/about-us/individual-bio/sharon-limage",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lauren-hendrickson",
    "url": "/about-us/individual-bio/lauren-hendrickson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/shari-weech",
    "url": "/about-us/individual-bio/shari-weech",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/william-erickson",
    "url": "/about-us/individual-bio/william-erickson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/steve-reale",
    "url": "/about-us/individual-bio/steve-reale",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kati-petersburg",
    "url": "/about-us/individual-bio/kati-petersburg",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/christine-chambers",
    "url": "/about-us/individual-bio/christine-chambers",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dan-smith",
    "url": "/about-us/individual-bio/dan-smith",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/paul-greywall",
    "url": "/about-us/individual-bio/paul-greywall",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/randal-baxter",
    "url": "/about-us/individual-bio/randal-baxter",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/kent-rader",
    "url": "/about-us/individual-bio/kent-rader",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/carla-adduci",
    "url": "/about-us/individual-bio/carla-adduci",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/suzy-sabanathan",
    "url": "/about-us/individual-bio/suzy-sabanathan",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/david-wall",
    "url": "/about-us/individual-bio/david-wall",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/tony-powell",
    "url": "/about-us/individual-bio/tony-powell",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/scott-adamson",
    "url": "/about-us/individual-bio/scott-adamson",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/jim-eggenschwiler",
    "url": "/about-us/individual-bio/jim-eggenschwiler",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rhonda-grigg",
    "url": "/about-us/individual-bio/rhonda-grigg",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/sharon-killian",
    "url": "/about-us/individual-bio/sharon-killian",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/michael-ballenger",
    "url": "/about-us/individual-bio/michael-ballenger",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/dasen-kendrick",
    "url": "/about-us/individual-bio/dasen-kendrick",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/nicole-saniti",
    "url": "/about-us/individual-bio/nicole-saniti",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/shannon-lynn",
    "url": "/about-us/individual-bio/shannon-lynn",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/matt-page",
    "url": "/about-us/individual-bio/matt-page",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/gillian-adams",
    "url": "/about-us/individual-bio/gillian-adams",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/rahul-kekatpure",
    "url": "/about-us/individual-bio/rahul-kekatpure",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/colt-sommers",
    "url": "/about-us/individual-bio/colt-sommers",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/maya-rao",
    "url": "/about-us/individual-bio/maya-rao",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/lynne-santos",
    "url": "/about-us/individual-bio/lynne-santos",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/ian-smith",
    "url": "/about-us/individual-bio/ian-smith",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/consultant",
    "url": "/about-us/individual-bio/consultant",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/marcus-firstman",
    "url": "/about-us/individual-bio/marcus-firstman",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/individual-bio/chris-peltier",
    "url": "/about-us/individual-bio/chris-peltier",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Manager of Consulting Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/emily-stewart",
    "url": "/contact-us/emily-stewart",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Managing Director, Provenance PSM",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/patrick-nonhof",
    "url": "/contact-us/patrick-nonhof",
    "template": "new-individual-bio",
    "section": "managing-director,-provenance-psm"
  },
  {
    "name": "Vice President",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-mcfarland",
    "url": "/contact-us/john-mcfarland",
    "template": "new-individual-bio",
    "section": "vice-president"
  },
  {
    "name": "PHA Facilitator",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/patrick-fisher",
    "url": "/contact-us/patrick-fisher",
    "template": "new-individual-bio",
    "section": "pha-facilitator"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/emily-kolb",
    "url": "/contact-us/emily-kolb",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Director, Mergers & Acquisitions",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/matt-berlew",
    "url": "/contact-us/matt-berlew",
    "template": "new-individual-bio",
    "section": "director,-mergers-acquisitions"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/raghu-soule",
    "url": "/contact-us/raghu-soule",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-sherer-pe",
    "url": "/contact-us/mike-sherer-pe",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Director, Sustainability & Assurance",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jose-orsini",
    "url": "/contact-us/jose-orsini",
    "template": "new-individual-bio",
    "section": "director,-sustainability-assurance"
  },
  {
    "name": "Director, Sustainability & Assurance",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jose-orsini",
    "url": "/managing-consultant",
    "template": "new-individual-bio",
    "section": "director,-sustainability-assurance"
  },
  {
    "name": "Director, Sustainability & Assurance",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jose-orsini",
    "url": "/contact-us/managing-consultant",
    "template": "new-individual-bio",
    "section": "director,-sustainability-assurance"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jeff-adkins",
    "url": "/contact-us/jeff-adkins",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/courtny-edge",
    "url": "/contact-us/courtny-edge",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-phan",
    "url": "/contact-us/tony-phan",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Account Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/james-topp",
    "url": "/contact-us/james-topp",
    "template": "new-individual-bio",
    "section": "account-director"
  },
  {
    "name": "Principal Toxicologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-olson",
    "url": "/contact-us/mike-olson",
    "template": "new-individual-bio",
    "section": "principal-toxicologist"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/associate-consultant",
    "url": "/contact-us/associate-consultant",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/associate-consultant",
    "url": "/associate-consultant",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Director, EHS Global Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/hung-ming-sue-sung",
    "url": "/contact-us/hung-ming-sue-sung",
    "template": "new-individual-bio",
    "section": "director,-ehs-global-services"
  },
  {
    "name": "Director, EHS Global Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/hung-ming-sue-sung",
    "url": "/Hung-Ming-sue-Sung",
    "template": "new-individual-bio",
    "section": "director,-ehs-global-services"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/priscilla-khuu",
    "url": "/contact-us/priscilla-khuu",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/chloe-s-reece",
    "url": "/contact-us/chloe-s-reece",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jim-perry",
    "url": "/contact-us/jim-perry",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/aj-hansborough",
    "url": "/contact-us/aj-hansborough",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/robert-sussman",
    "url": "/contact-us/robert-sussman",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brian-anderson",
    "url": "/contact-us/brian-anderson",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/hillary-killorn",
    "url": "/contact-us/hillary-killorn",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dale-overcash",
    "url": "/contact-us/dale-overcash",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Senior Toxicologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/bernie-lindquist",
    "url": "/contact-us/bernie-lindquist",
    "template": "new-individual-bio",
    "section": "senior-toxicologist"
  },
  {
    "name": "Associate Director - Automation Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/virgil-sufariu",
    "url": "/contact-us/virgil-sufariu",
    "template": "new-individual-bio",
    "section": "associate-director--automation-services"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ramya-lakshmanan",
    "url": "/contact-us/ramya-lakshmanan",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Senior Environmental Chemist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/cheryl-wiramanaden",
    "url": "/contact-us/cheryl-wiramanaden",
    "template": "new-individual-bio",
    "section": "senior-environmental-chemist"
  },
  {
    "name": "Senior Environmental Chemist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/cheryl-wiramanaden",
    "url": "/contact-us/cheryl-wiramanaden-m.chem.-ph.d.-p.chem",
    "template": "new-individual-bio",
    "section": "senior-environmental-chemist"
  },
  {
    "name": "Regional Director - Minneapolis, Minnesota",
    "apiurl": "/restapi/customaboutuslvl2content/regional-director--minneapolis-minnesota",
    "url": "/regional-director--minneapolis-minnesota",
    "template": "new-individual-bio",
    "section": "regional-director--minneapolis,-minnesota"
  },
  {
    "name": "Executive VP, WorkingBuildings",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kurt-last",
    "url": "/contact-us/kurt-last",
    "template": "new-individual-bio",
    "section": "executive-vp,-workingbuildings"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ian-donaldson",
    "url": "/contact-us/ian-donaldson",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-zimmer",
    "url": "/contact-us/mike-zimmer",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-zimmer",
    "url": "/contact-us/mike-zimmer-pe",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/felicity-melville",
    "url": "/contact-us/felicity-melville",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Principal",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kim-connors",
    "url": "/contact-us/kim-connors",
    "template": "new-individual-bio",
    "section": "managing-principal"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/misty-sinclair",
    "url": "/contact-us/misty-sinclair",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/justin-adams",
    "url": "/contact-us/justin-adams",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kathryn-connolly",
    "url": "/contact-us/kathryn-connolly",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/sarah-gray",
    "url": "/contact-us/sarah-gray",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Chief Marketing Officer",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/charles-brower",
    "url": "/contact-us/charles-brower",
    "template": "new-individual-bio",
    "section": "chief-marketing-officer"
  },
  {
    "name": "Director, EHS Digital Solutions",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jason-schmitz",
    "url": "/contact-us/jason-schmitz",
    "template": "new-individual-bio",
    "section": "director,-ehs-digital-solutions"
  },
  {
    "name": "Director, BREEZE Software & China Operations",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/weiping-dai",
    "url": "/contact-us/weiping-dai",
    "template": "new-individual-bio",
    "section": "director,-breeze-software-china-operations"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/chris-shoop",
    "url": "/contact-us/chris-shoop",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/robert-liles",
    "url": "/contact-us/robert-liles",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Vice President",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/christina-ridge",
    "url": "/contact-us/christina-ridge",
    "template": "new-individual-bio",
    "section": "vice-president"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/gena-driscoll",
    "url": "/contact-us/gena-driscoll",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant, Climate Change Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/managing-consultant-climate-change-services",
    "url": "/contact-us/managing-consultant-climate-change-services",
    "template": "new-individual-bio",
    "section": "managing-consultant,-climate-change-services"
  },
  {
    "name": "Managing Consultant, Climate Change Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/managing-consultant-climate-change-services",
    "url": "/managing-consultant-climate-change-services",
    "template": "new-individual-bio",
    "section": "managing-consultant,-climate-change-services"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-iwanski",
    "url": "/contact-us/john-iwanski",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-iwanski",
    "url": "/managing-director---north-region",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-iwanski",
    "url": "/contact-us/managing-director---north-region",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/christine-heath",
    "url": "/contact-us/christine-heath",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/charles-c-lee",
    "url": "/contact-us/charles-c-lee",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/adam-erenstein",
    "url": "/contact-us/adam-erenstein",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-burr",
    "url": "/contact-us/mike-burr",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant, Cleanrooms and Sterile Compounding",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/elaine-strauss",
    "url": "/contact-us/elaine-strauss",
    "template": "new-individual-bio",
    "section": "senior-consultant,-cleanrooms-and-sterile-compounding"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jon-snoeberger",
    "url": "/contact-us/jon-snoeberger",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Managing Principal Ecologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/simon-colenutt",
    "url": "/contact-us/simon-colenutt",
    "template": "new-individual-bio",
    "section": "managing-principal-ecologist"
  },
  {
    "name": "Director, Human Resources",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/chris-price",
    "url": "/contact-us/chris-price",
    "template": "new-individual-bio",
    "section": "director,-human-resources"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-schnarre",
    "url": "/contact-us/john-schnarre",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Director, AWN Consulting",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/terry-donnelly",
    "url": "/contact-us/terry-donnelly",
    "template": "new-individual-bio",
    "section": "managing-director,-awn-consulting"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shreyas-erapalli",
    "url": "/contact-us/shreyas-erapalli",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Engineer",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/joe-kupper",
    "url": "/contact-us/joe-kupper",
    "template": "new-individual-bio",
    "section": "senior-engineer"
  },
  {
    "name": "Commissioning Authority 6",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-martin",
    "url": "/contact-us/tony-martin",
    "template": "new-individual-bio",
    "section": "commissioning-authority-6"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/amanda-smith",
    "url": "/contact-us/amanda-smith",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/regina-dilaavore",
    "url": "/contact-us/regina-dilaavore",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Account Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lauren-mercer",
    "url": "/contact-us/lauren-mercer",
    "template": "new-individual-bio",
    "section": "account-director"
  },
  {
    "name": "President, Principal",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/andrey-nikiforov",
    "url": "/contact-us/andrey-nikiforov",
    "template": "new-individual-bio",
    "section": "president,-principal"
  },
  {
    "name": "Managing Consultant, PMP",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/managing-consultant-pmp",
    "url": "/contact-us/managing-consultant-pmp",
    "template": "new-individual-bio",
    "section": "managing-consultant,-pmp"
  },
  {
    "name": "Managing Consultant, PMP",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/managing-consultant-pmp",
    "url": "/managing-consultant-pmp",
    "template": "new-individual-bio",
    "section": "managing-consultant,-pmp"
  },
  {
    "name": "ECOSA Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ecosa-team",
    "url": "/contact-us/ecosa-team",
    "template": "new-individual-bio",
    "section": "ecosa-team"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kristine-davies",
    "url": "/contact-us/kristine-davies",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Toxicologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/erica-dahl",
    "url": "/contact-us/erica-dahl",
    "template": "new-individual-bio",
    "section": "managing-toxicologist"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/chuck-buttry",
    "url": "/contact-us/chuck-buttry",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/susan-barnes",
    "url": "/contact-us/susan-barnes",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Dir., Environmental Consulting",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shishir-mohan",
    "url": "/contact-us/shishir-mohan",
    "template": "new-individual-bio",
    "section": "managing-dir.,-environmental-consulting"
  },
  {
    "name": "Senior Supervisor",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/caitlin-king",
    "url": "/contact-us/caitlin-king",
    "template": "new-individual-bio",
    "section": "senior-supervisor"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/sean-cronin",
    "url": "/contact-us/sean-cronin",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Director, Integration & Corporate Strategy",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/nimish-katwala",
    "url": "/contact-us/nimish-katwala",
    "template": "new-individual-bio",
    "section": "director,-integration-corporate-strategy"
  },
  {
    "name": "G.M., Automation Design Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/roop-kumar",
    "url": "/contact-us/roop-kumar",
    "template": "new-individual-bio",
    "section": "g.m.,-automation-design-services"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ryan-conley",
    "url": "/contact-us/ryan-conley",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/justin-sullivan",
    "url": "/contact-us/justin-sullivan",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kasi-dubbs",
    "url": "/contact-us/kasi-dubbs",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dana-norvell-pe",
    "url": "/contact-us/dana-norvell-pe",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/josh-haar",
    "url": "/contact-us/josh-haar",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Chief Accounting Officer",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/nav-marocha",
    "url": "/contact-us/nav-marocha",
    "template": "new-individual-bio",
    "section": "chief-accounting-officer"
  },
  {
    "name": "Senior Product Specialist / Meteorologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tiffany-stefanescu",
    "url": "/contact-us/tiffany-stefanescu",
    "template": "new-individual-bio",
    "section": "senior-product-specialist--meteorologist"
  },
  {
    "name": "Sales & Marketing Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jenny-brancheau",
    "url": "/contact-us/jenny-brancheau",
    "template": "new-individual-bio",
    "section": "sales-marketing-manager"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/george-iwaszek",
    "url": "/contact-us/george-iwaszek",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/george-iwaszek",
    "url": "/contact-us/manager-of-consulting-services",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Director, Marketing & Education",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/laura-redmon",
    "url": "/contact-us/laura-redmon",
    "template": "new-individual-bio",
    "section": "director,-marketing-education"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/disha-gadre",
    "url": "/contact-us/disha-gadre",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/latha-kambham",
    "url": "/contact-us/latha-kambham",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-liebert",
    "url": "/contact-us/mike-liebert",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ashley-jones",
    "url": "/contact-us/ashley-jones",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/edward-gardetto",
    "url": "/contact-us/edward-gardetto",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "﻿ Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/valerie-rosenkrantz",
    "url": "/contact-us/valerie-rosenkrantz",
    "template": "new-individual-bio",
    "section": "﻿-managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lori-bocchino",
    "url": "/contact-us/lori-bocchino",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/meghan-foley",
    "url": "/contact-us/meghan-foley",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/arron-heinerikson",
    "url": "/contact-us/arron-heinerikson",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/arron-heinerikson",
    "url": "/contact-us/aaron-heinerikson",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Automation Manager - Delta V, India",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kalpesh-baviskar",
    "url": "/contact-us/kalpesh-baviskar",
    "template": "new-individual-bio",
    "section": "automation-manager--delta-v,-india"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ellen-hewitt",
    "url": "/contact-us/ellen-hewitt",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/sundar-sadashivam",
    "url": "/contact-us/sundar-sadashivam",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Environmental Software Specialist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/qiguo-jing",
    "url": "/contact-us/qiguo-jing",
    "template": "new-individual-bio",
    "section": "senior-environmental-software-specialist"
  },
  {
    "name": "Country Head",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/Subbarao-Kattamuri",
    "url": "/contact-us/Subbarao-Kattamuri",
    "template": "new-individual-bio",
    "section": "country-head"
  },
  {
    "name": "Country Head",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/Subbarao-Kattamuri",
    "url": "/contact-us/subbarao-v-kattamuri",
    "template": "new-individual-bio",
    "section": "country-head"
  },
  {
    "name": "Principal Consultant - Tulsa, OK",
    "apiurl": "/restapi/customaboutuslvl2content/principal-consultant--tulsa-ok",
    "url": "/principal-consultant--tulsa-ok",
    "template": "new-individual-bio",
    "section": "principal-consultant--tulsa,-ok"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lisa-schmidt",
    "url": "/contact-us/lisa-schmidt",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-meister",
    "url": "/contact-us/mike-meister",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Director, Quality Management",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/paul-smith",
    "url": "/contact-us/paul-smith",
    "template": "new-individual-bio",
    "section": "director,-quality-management"
  },
  {
    "name": "Managing Dir., Environmental Consulting",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kirk-lowery",
    "url": "/contact-us/kirk-lowery",
    "template": "new-individual-bio",
    "section": "managing-dir.,-environmental-consulting"
  },
  {
    "name": "Associate Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/austin-alexander",
    "url": "/contact-us/austin-alexander",
    "template": "new-individual-bio",
    "section": "associate-consultant"
  },
  {
    "name": "Senior Toxicologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/yana-chervona",
    "url": "/contact-us/yana-chervona",
    "template": "new-individual-bio",
    "section": "senior-toxicologist"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/emily-lower",
    "url": "/contact-us/emily-lower",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-colombari",
    "url": "/contact-us/tony-colombari",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/wes-younger",
    "url": "/contact-us/wes-younger",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/patrick-mckean",
    "url": "/contact-us/patrick-mckean",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/deborah-walden--hersh",
    "url": "/contact-us/deborah-walden--hersh",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/deborah-walden--hersh",
    "url": "/contact-us/deborah-walden-hersh",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/allan-ader",
    "url": "/contact-us/allan-ader",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Senior Principal Occupational Hygienist, European Operations",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/martin-axon",
    "url": "/contact-us/martin-axon",
    "template": "new-individual-bio",
    "section": "senior-principal-occupational-hygienist,-european-operations"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kim-teofilak",
    "url": "/contact-us/kim-teofilak",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jeremiah-redman",
    "url": "/contact-us/jeremiah-redman",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/taylor-wilson",
    "url": "/contact-us/taylor-wilson",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/natalie-vanliew",
    "url": "/contact-us/natalie-vanliew",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/angie-wanger",
    "url": "/contact-us/angie-wanger",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/david-eb-strohm",
    "url": "/contact-us/david-eb-strohm",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/david-eb-strohm",
    "url": "/about-us/individual-bio/david-eb-strohm",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Divisional President, Environmental Consulting",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jay-hofmann",
    "url": "/contact-us/jay-hofmann",
    "template": "new-individual-bio",
    "section": "divisional-president,-environmental-consulting"
  },
  {
    "name": "Managing Director, Human Resources",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/monty-west",
    "url": "/contact-us/monty-west",
    "template": "new-individual-bio",
    "section": "managing-director,-human-resources"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/bill-bruscino",
    "url": "/contact-us/bill-bruscino",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/steve-tasch",
    "url": "/contact-us/steve-tasch",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Dir., Environmental Consulting",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/vineet-masuraha",
    "url": "/contact-us/vineet-masuraha",
    "template": "new-individual-bio",
    "section": "managing-dir.,-environmental-consulting"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jenna-kube",
    "url": "/contact-us/jenna-kube",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/melissa-ryan",
    "url": "/contact-us/melissa-ryan",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/katherine-meiser",
    "url": "/contact-us/katherine-meiser",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Executive Director, Ecofish Research",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rhoni-whyard",
    "url": "/contact-us/rhoni-whyard",
    "template": "new-individual-bio",
    "section": "executive-director,-ecofish-research"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/christa-russell",
    "url": "/contact-us/christa-russell",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/katie-brubaker",
    "url": "/contact-us/katie-brubaker",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Vice President",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/janet-martin",
    "url": "/contact-us/janet-martin",
    "template": "new-individual-bio",
    "section": "vice-president"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/steve-walters",
    "url": "/contact-us/steve-walters",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/matthew-tarnoff",
    "url": "/contact-us/matthew-tarnoff",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/simone-wallace",
    "url": "/contact-us/simone-wallace",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Senior Geoscientist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-gunsinger",
    "url": "/contact-us/mike-gunsinger",
    "template": "new-individual-bio",
    "section": "senior-geoscientist"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/anna-aumann",
    "url": "/contact-us/anna-aumann",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Industrial Hygienist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ryan-graff",
    "url": "/contact-us/ryan-graff",
    "template": "new-individual-bio",
    "section": "principal-industrial-hygienist"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rich-jenks",
    "url": "/contact-us/rich-jenks",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rich-jenks",
    "url": "/contact-us/rich-jenks-",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brian-mensinger",
    "url": "/contact-us/brian-mensinger",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Director, Acoustics",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/stephen-smyth",
    "url": "/contact-us/stephen-smyth",
    "template": "new-individual-bio",
    "section": "director,-acoustics"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jim-lyons",
    "url": "/contact-us/jim-lyons",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "SafeBridge Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/safebridge-team",
    "url": "/contact-us/safebridge-team",
    "template": "new-individual-bio",
    "section": "safebridge-team"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tiffany-wang",
    "url": "/contact-us/tiffany-wang",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Senior Aquatic Scientist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/paul-lepage",
    "url": "/contact-us/paul-lepage",
    "template": "new-individual-bio",
    "section": "senior-aquatic-scientist"
  },
  {
    "name": "Manager Consulting Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/andrew-martin",
    "url": "/contact-us/andrew-martin",
    "template": "new-individual-bio",
    "section": "manager-consulting-services"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/doug-abeln",
    "url": "/contact-us/doug-abeln",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "TRS Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/trs-team",
    "url": "/contact-us/trs-team",
    "template": "new-individual-bio",
    "section": "trs-team"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dan-risch",
    "url": "/contact-us/dan-risch",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/deanna-duram",
    "url": "/contact-us/deanna-duram",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lindsay-giovannetti",
    "url": "/contact-us/lindsay-giovannetti",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Divisional President, Water & Ecology",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/mike-remsberg",
    "url": "/contact-us/mike-remsberg",
    "template": "new-individual-bio",
    "section": "divisional-president,-water-ecology"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/anna-henolson",
    "url": "/contact-us/anna-henolson",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Chief Financial Officer",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dave-larsen",
    "url": "/contact-us/dave-larsen",
    "template": "new-individual-bio",
    "section": "chief-financial-officer"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/divya-agarwal",
    "url": "/contact-us/divya-agarwal",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-schroeder",
    "url": "/contact-us/tony-schroeder",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/farshid-kiani",
    "url": "/contact-us/farshid-kiani",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Divisional President, Built Environment",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/herbert-els",
    "url": "/contact-us/herbert-els",
    "template": "new-individual-bio",
    "section": "divisional-president,-built-environment"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/aaron-day",
    "url": "/contact-us/aaron-day",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/christi-wilson",
    "url": "/contact-us/christi-wilson",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Director, Chemical Sector Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/inaas-darrat",
    "url": "/contact-us/inaas-darrat",
    "template": "new-individual-bio",
    "section": "director,-chemical-sector-services"
  },
  {
    "name": "Director, Chemical Sector Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/inaas-darrat",
    "url": "/contact-us/director-chemical-sector-services",
    "template": "new-individual-bio",
    "section": "director,-chemical-sector-services"
  },
  {
    "name": "Director, Chemical Sector Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/inaas-darrat",
    "url": "/director-chemical-sector-services",
    "template": "new-individual-bio",
    "section": "director,-chemical-sector-services"
  },
  {
    "name": "Business Development Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/katie-jeziorski",
    "url": "/contact-us/katie-jeziorski",
    "template": "new-individual-bio",
    "section": "business-development-manager"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rahul-pendse",
    "url": "/contact-us/rahul-pendse",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Director, Product Development",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jacob-terry",
    "url": "/contact-us/jacob-terry",
    "template": "new-individual-bio",
    "section": "director,-product-development"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/bob-schreiber",
    "url": "/contact-us/bob-schreiber",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Minnow Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/minnow-team",
    "url": "/contact-us/minnow-team",
    "template": "new-individual-bio",
    "section": "minnow-team"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jesse-gonzalez",
    "url": "/contact-us/jesse-gonzalez",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/russell-bailey",
    "url": "/contact-us/russell-bailey",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/russell-bailey",
    "url": "/contact-us/russell-bailey-",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/bethany-caspersz",
    "url": "/contact-us/bethany-caspersz",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Manager - Foxboro",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/arkady-mayblyum",
    "url": "/contact-us/arkady-mayblyum",
    "template": "new-individual-bio",
    "section": "manager--foxboro"
  },
  {
    "name": "Principal, Technology Design",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/peter-babigian",
    "url": "/contact-us/peter-babigian",
    "template": "new-individual-bio",
    "section": "principal,-technology-design"
  },
  {
    "name": "Senior Environmental Specialist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/leonie-andersen",
    "url": "/contact-us/leonie-andersen",
    "template": "new-individual-bio",
    "section": "senior-environmental-specialist"
  },
  {
    "name": "Divisional Managing Dir., Life Sciences",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/albert-dyrness",
    "url": "/contact-us/albert-dyrness",
    "template": "new-individual-bio",
    "section": "divisional-managing-dir.,-life-sciences"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brad-james",
    "url": "/contact-us/brad-james",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Manager of Consulting Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brian-noel",
    "url": "/contact-us/brian-noel",
    "template": "new-individual-bio",
    "section": "manager-of-consulting-services"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/bruce-douglas",
    "url": "/contact-us/bruce-douglas",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-al--rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie--al-rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/about/leadership/eddie-al-rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-al-rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-al-rayes-",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-al_-rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/about/leadership/eddie--al--rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-al---rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie-alrayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/eddie-al--rayes",
    "url": "/contact-us/eddie--al--rayes",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Advent Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/advent-team",
    "url": "/contact-us/advent-team",
    "template": "new-individual-bio",
    "section": "advent-team"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/melissa-hatfield-atkinson",
    "url": "/contact-us/melissa-hatfield-atkinson",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/baron-huntwork",
    "url": "/contact-us/baron-huntwork",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Managing Principal",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/pierre-stecko",
    "url": "/contact-us/pierre-stecko",
    "template": "new-individual-bio",
    "section": "managing-principal"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jennifer-markwardt",
    "url": "/contact-us/jennifer-markwardt",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/alex-marcucci",
    "url": "/contact-us/alex-marcucci",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jason-swofford",
    "url": "/contact-us/jason-swofford",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-hecht",
    "url": "/contact-us/john-hecht",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/robert-a-large",
    "url": "/contact-us/robert-a-large",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brett-zogas",
    "url": "/contact-us/brett-zogas",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kim-ayotte",
    "url": "/contact-us/kim-ayotte",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Aztec Team",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/aztec-team",
    "url": "/contact-us/aztec-team",
    "template": "new-individual-bio",
    "section": "aztec-team"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/david-dempsey",
    "url": "/contact-us/david-dempsey",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Consultant/Senior BD Coordinator",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/maya-ravishankar",
    "url": "/contact-us/maya-ravishankar",
    "template": "new-individual-bio",
    "section": "consultantsenior-bd-coordinator"
  },
  {
    "name": "Director, Monitoring Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/casey-lenhart",
    "url": "/contact-us/casey-lenhart",
    "template": "new-individual-bio",
    "section": "director,-monitoring-services"
  },
  {
    "name": "Principal Toxicologist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/janet-gould",
    "url": "/contact-us/janet-gould",
    "template": "new-individual-bio",
    "section": "principal-toxicologist"
  },
  {
    "name": "Director of M&A Strategy and Integration",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/alison-ritterbusch",
    "url": "/contact-us/alison-ritterbusch",
    "template": "new-individual-bio",
    "section": "director-of-ma-strategy-and-integration"
  },
  {
    "name": "Director, Sustainability Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/wendy-merz",
    "url": "/contact-us/wendy-merz",
    "template": "new-individual-bio",
    "section": "director,-sustainability-services"
  },
  {
    "name": "Inquiries",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/built-environment-inquiries",
    "url": "/contact-us/built-environment-inquiries",
    "template": "new-individual-bio",
    "section": "inquiries"
  },
  {
    "name": "Director, Operations",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/brian-burdorf",
    "url": "/contact-us/brian-burdorf",
    "template": "new-individual-bio",
    "section": "director,-operations"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jeremy-jewell",
    "url": "/contact-us/jeremy-jewell",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tom-muscenti",
    "url": "/contact-us/tom-muscenti",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-jabon",
    "url": "/contact-us/tony-jabon",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-jabon",
    "url": "/contact-us/tony-jabon-pe",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Director Marketing, Built Environment",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ginger-spencer",
    "url": "/contact-us/ginger-spencer",
    "template": "new-individual-bio",
    "section": "director-marketing,-built-environment"
  },
  {
    "name": "Director, EHS Management",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rich-pandullo",
    "url": "/contact-us/rich-pandullo",
    "template": "new-individual-bio",
    "section": "director,-ehs-management"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/john-colebrook",
    "url": "/contact-us/john-colebrook",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Director, Human Resources",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rancie-bernal",
    "url": "/contact-us/rancie-bernal",
    "template": "new-individual-bio",
    "section": "managing-director,-human-resources"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/george-shewe",
    "url": "/contact-us/george-shewe",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/hui-cheng",
    "url": "/contact-us/hui-cheng",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "CEMS Specialist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/cems-specialist",
    "url": "/contact-us/cems-specialist",
    "template": "new-individual-bio",
    "section": "cems-specialist"
  },
  {
    "name": "CEMS Specialist",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/cems-specialist",
    "url": "/cems-specialist",
    "template": "new-individual-bio",
    "section": "cems-specialist"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rob-ferry",
    "url": "/contact-us/rob-ferry",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/regional-director",
    "url": "/contact-us/regional-director",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Regional Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/regional-director",
    "url": "/regional-director",
    "template": "new-individual-bio",
    "section": "regional-director"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/steve-zervas",
    "url": "/contact-us/steve-zervas",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/david-meisters",
    "url": "/contact-us/david-meisters",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jeremias-szust",
    "url": "/contact-us/jeremias-szust",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kyle-dunn",
    "url": "/contact-us/kyle-dunn",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/melissa-hillman",
    "url": "/contact-us/melissa-hillman",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Director, Information Technology",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/alan-chuang",
    "url": "/contact-us/alan-chuang",
    "template": "new-individual-bio",
    "section": "director,-information-technology"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/branden-barry",
    "url": "/contact-us/branden-barry",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Regional Business Development Supervisor",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/sharon-limage",
    "url": "/contact-us/sharon-limage",
    "template": "new-individual-bio",
    "section": "regional-business-development-supervisor"
  },
  {
    "name": "PSM Consultant, Relief Systems Technical Expert",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lauren-hendrickson",
    "url": "/contact-us/lauren-hendrickson",
    "template": "new-individual-bio",
    "section": "psm-consultant,-relief-systems-technical-expert"
  },
  {
    "name": "Senior Scientist/Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shari-weech",
    "url": "/contact-us/shari-weech",
    "template": "new-individual-bio",
    "section": "senior-scientistprincipal-consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/william-erickson",
    "url": "/contact-us/william-erickson",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/steve-reale",
    "url": "/contact-us/steve-reale",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kati-petersburg",
    "url": "/contact-us/kati-petersburg",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/christine-chambers",
    "url": "/contact-us/christine-chambers",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dan-smith",
    "url": "/contact-us/dan-smith",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "CEO Trinity Consultants",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/paul-greywall",
    "url": "/contact-us/paul-greywall",
    "template": "new-individual-bio",
    "section": "ceo-trinity-consultants"
  },
  {
    "name": "Air Quality Specialist - Project Manager, Monitoring Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/randal-baxter",
    "url": "/contact-us/randal-baxter",
    "template": "new-individual-bio",
    "section": "air-quality-specialist--project-manager,-monitoring-services"
  },
  {
    "name": "Manager Analytical Services",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/kent-rader",
    "url": "/contact-us/kent-rader",
    "template": "new-individual-bio",
    "section": "manager-analytical-services"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/carla-adduci",
    "url": "/contact-us/carla-adduci",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/suzy-sabanathan",
    "url": "/contact-us/suzy-sabanathan",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/david-wall",
    "url": "/contact-us/david-wall",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Managing Director",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/tony-powell",
    "url": "/contact-us/tony-powell",
    "template": "new-individual-bio",
    "section": "managing-director"
  },
  {
    "name": "Air Quality - Applied Services Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/scott-adamson",
    "url": "/contact-us/scott-adamson",
    "template": "new-individual-bio",
    "section": "air-quality--applied-services-manager"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/jim-eggenschwiler",
    "url": "/contact-us/jim-eggenschwiler",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rhonda-grigg",
    "url": "/contact-us/rhonda-grigg",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/sharon-killian",
    "url": "/contact-us/sharon-killian",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/michael-ballenger",
    "url": "/contact-us/michael-ballenger",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/michael-ballenger",
    "url": "/contact-us/michael-ballenger-",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/dasen-kendrick",
    "url": "/contact-us/dasen-kendrick",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Regional Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/nicole-saniti",
    "url": "/contact-us/nicole-saniti",
    "template": "new-individual-bio",
    "section": "regional-manager"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shannon-lynn",
    "url": "/contact-us/shannon-lynn",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shannon-lynn",
    "url": "/contact-us/shannon-lynn-pe",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shannon-lynn",
    "url": "/principal-consultant--little-rock-ar",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/shannon-lynn",
    "url": "/contact-us/principal-consultant--little-rock-ar",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/matt-page",
    "url": "/contact-us/matt-page",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Principal Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/gillian-adams",
    "url": "/contact-us/gillian-adams",
    "template": "new-individual-bio",
    "section": "principal-consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/rahul-kekatpure",
    "url": "/contact-us/rahul-kekatpure",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Senior Project Manager",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/colt-sommers",
    "url": "/contact-us/colt-sommers",
    "template": "new-individual-bio",
    "section": "senior-project-manager"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/maya-rao",
    "url": "/contact-us/maya-rao",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Managing Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/lynne-santos",
    "url": "/contact-us/lynne-santos",
    "template": "new-individual-bio",
    "section": "managing-consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/ian-smith",
    "url": "/contact-us/ian-smith",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/consultant",
    "url": "/contact-us/consultant",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/consultant",
    "url": "/consultant",
    "template": "new-individual-bio",
    "section": "consultant"
  },
  {
    "name": "Senior Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/marcus-firstman",
    "url": "/contact-us/marcus-firstman",
    "template": "new-individual-bio",
    "section": "senior-consultant"
  },
  {
    "name": "Built Environment Consultant",
    "apiurl": "/restapi/customaboutuslvl2content/contact-us/chris-peltier",
    "url": "/contact-us/chris-peltier",
    "template": "new-individual-bio",
    "section": "built-environment-consultant"
  },
  {
    "name": "Subscribe",
    "apiurl": "/restapi/customcontent/subscribe",
    "url": "/subscribe",
    "template": "general_content",
    "section": "subscribe"
  },
  {
    "name": "Tanks/ Terminals",
    "apiurl": "/restapi/customcontent/software/tanks",
    "url": "/software/tanks",
    "template": "software-categories",
    "section": "tanks-terminals"
  },
  {
    "name": "Tanks/ Terminals",
    "apiurl": "/restapi/customcontent/software/tanks",
    "url": "/software/tank-emissions",
    "template": "software-categories",
    "section": "tanks-terminals"
  },
  {
    "name": "Explosion",
    "apiurl": "/restapi/customcontent/software/explosion",
    "url": "/software/explosion",
    "template": "software-categories",
    "section": "explosion"
  },
  {
    "name": "Explosion",
    "apiurl": "/restapi/customcontent/software/explosion",
    "url": "/software/products/explosion",
    "template": "software-categories",
    "section": "explosion"
  },
  {
    "name": "Data Analysis",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization",
    "url": "/software/air-dispersion---data-visualization",
    "template": "software-categories",
    "section": "data-analysis"
  },
  {
    "name": "Hazard",
    "apiurl": "/restapi/customcontent/software/hazard",
    "url": "/software/hazard",
    "template": "software-categories",
    "section": "hazard"
  },
  {
    "name": "Hazard",
    "apiurl": "/restapi/customcontent/software/hazard",
    "url": "/software/products/hazard",
    "template": "software-categories",
    "section": "hazard"
  },
  {
    "name": "Air Dispersion",
    "apiurl": "/restapi/customcontent/software/dispersion",
    "url": "/software/dispersion",
    "template": "software-categories",
    "section": "air-dispersion"
  },
  {
    "name": "Air Dispersion",
    "apiurl": "/restapi/customcontent/software/dispersion",
    "url": "/software/products/air-dispersion/",
    "template": "software-categories",
    "section": "air-dispersion"
  },
  {
    "name": "Health Risk",
    "apiurl": "/restapi/customcontent/software/health-risk",
    "url": "/software/health-risk",
    "template": "software-categories",
    "section": "health-risk"
  },
  {
    "name": "Health Risk",
    "apiurl": "/restapi/customcontent/software/health-risk",
    "url": "/software/products/health-risk",
    "template": "software-categories",
    "section": "health-risk"
  },
  {
    "name": "5.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/tankesp/5.0",
    "url": "/software/support/release-notes/tankesp/5.0",
    "template": "new-releasenotes-categroy",
    "section": "tankesp"
  },
  {
    "name": "2.4.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/2.4.1",
    "url": "/software/support/release-notes/3d-analyst/2.4.1",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "5.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/5.0",
    "url": "/software/support/release-notes/aerscreen/5.0",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "1.7",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/downwash-analyst/1.7",
    "url": "/software/support/release-notes/downwash-analyst/1.7",
    "template": "new-releasenotes-categroy",
    "section": "downwash-analyst"
  },
  {
    "name": "3.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/3.0",
    "url": "/software/support/release-notes/aerscreen/3.0",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "9.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/9.2",
    "url": "/software/support/release-notes/aermod/9.2",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "5.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/tankesp/5.3",
    "url": "/software/support/release-notes/tankesp/5.3",
    "template": "new-releasenotes-categroy",
    "section": "tankesp"
  },
  {
    "name": "4.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/4.0",
    "url": "/software/support/release-notes/incident-analyst/4.0",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "7.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.4",
    "url": "/software/support/release-notes/aermod/7.4",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "3.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/3.0",
    "url": "/software/support/release-notes/incident-analyst/3.0",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "5.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/tankesp/5.1",
    "url": "/software/support/release-notes/tankesp/5.1",
    "template": "new-releasenotes-categroy",
    "section": "tankesp"
  },
  {
    "name": "7.5",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.5",
    "url": "/software/support/release-notes/aermet/7.5",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "2.3.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/2.3.2",
    "url": "/software/support/release-notes/3d-analyst/2.3.2",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "7.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.4",
    "url": "/software/support/release-notes/aermet/7.4",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "2.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/2.0",
    "url": "/software/support/release-notes/calpuff/2.0",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "1.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/1.4",
    "url": "/software/support/release-notes/incident-analyst/1.4",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "9.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/9.0",
    "url": "/software/support/release-notes/aermod/9.0",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.5",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.5",
    "url": "/software/support/release-notes/aermod/7.5",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "5.1.9",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/roads/5.1.9",
    "url": "/software/support/release-notes/roads/5.1.9",
    "template": "new-releasenotes-categroy",
    "section": "roads"
  },
  {
    "name": "1.8",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/1.8",
    "url": "/software/support/release-notes/aerscreen/1.8",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "7.12",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.12",
    "url": "/software/support/release-notes/aermod/7.12",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "1.9",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/1.9",
    "url": "/software/support/release-notes/aerscreen/1.9",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "7.12.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.12.1",
    "url": "/software/support/release-notes/aermod/7.12.1",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "10.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/10.0",
    "url": "/software/support/release-notes/aermod/10.0",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "8.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/8.0",
    "url": "/software/support/release-notes/aermod/8.0",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "5.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/vasdip/5.0",
    "url": "/software/support/release-notes/vasdip/5.0",
    "template": "new-releasenotes-categroy",
    "section": "vasdip"
  },
  {
    "name": "2.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/2.2",
    "url": "/software/support/release-notes/calpuff/2.2",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "10.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/10.0",
    "url": "/software/support/release-notes/aermet/10.0",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "7.8",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.8",
    "url": "/software/support/release-notes/aermod/7.8",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "8.5.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/exdam/8.5.1",
    "url": "/software/support/release-notes/exdam/8.5.1",
    "template": "new-releasenotes-categroy",
    "section": "exdam"
  },
  {
    "name": "1.7",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/1.7",
    "url": "/software/support/release-notes/aerscreen/1.7",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "2.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/2.0",
    "url": "/software/support/release-notes/incident-analyst/2.0",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "7.7",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.7",
    "url": "/software/support/release-notes/aermet/7.7",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "2.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/2.3",
    "url": "/software/support/release-notes/3d-analyst/2.3",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "11.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/11.0",
    "url": "/software/support/release-notes/aermod/11.0",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "8.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/8.0",
    "url": "/software/support/release-notes/aermet/8.0",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "7.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.2",
    "url": "/software/support/release-notes/aermod/7.2",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "2.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/2.2",
    "url": "/software/support/release-notes/3d-analyst/2.2",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "2.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/2.4",
    "url": "/software/support/release-notes/3d-analyst/2.4",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "4.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/4.0",
    "url": "/software/support/release-notes/3d-analyst/4.0",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "7.9",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.9",
    "url": "/software/support/release-notes/aermet/7.9",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "1.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/1.2",
    "url": "/software/support/release-notes/incident-analyst/1.2",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "2.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/2.3",
    "url": "/software/support/release-notes/calpuff/2.3",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "5.0.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/tankesp/5.0.1",
    "url": "/software/support/release-notes/tankesp/5.0.1",
    "template": "new-releasenotes-categroy",
    "section": "tankesp"
  },
  {
    "name": "8.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/8.1",
    "url": "/software/support/release-notes/aermod/8.1",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.6",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.6",
    "url": "/software/support/release-notes/aermod/7.6",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.5.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.5.2",
    "url": "/software/support/release-notes/aermet/7.5.2",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "5.1.8",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/roads/5.1.8",
    "url": "/software/support/release-notes/roads/5.1.8",
    "template": "new-releasenotes-categroy",
    "section": "roads"
  },
  {
    "name": "9.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/exdam/9.0",
    "url": "/software/support/release-notes/exdam/9.0",
    "template": "new-releasenotes-categroy",
    "section": "exdam"
  },
  {
    "name": "7.2.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/metview/7.2.3",
    "url": "/software/support/release-notes/metview/7.2.3",
    "template": "new-releasenotes-categroy",
    "section": "metview"
  },
  {
    "name": "7.5.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.5.1",
    "url": "/software/support/release-notes/aermet/7.5.1",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "3.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/3.0",
    "url": "/software/support/release-notes/calpuff/3.0",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "2.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/2.0",
    "url": "/software/support/release-notes/aerscreen/2.0",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "2.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/2.1",
    "url": "/software/support/release-notes/calpuff/2.1",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "4.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aerscreen/4.0",
    "url": "/software/support/release-notes/aerscreen/4.0",
    "template": "new-releasenotes-categroy",
    "section": "aerscreen"
  },
  {
    "name": "3.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/3d-analyst/3.0",
    "url": "/software/support/release-notes/3d-analyst/3.0",
    "template": "new-releasenotes-categroy",
    "section": "3d-analyst"
  },
  {
    "name": "7.6",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.6",
    "url": "/software/support/release-notes/aermet/7.6",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "7.7",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.7",
    "url": "/software/support/release-notes/aermod/7.7",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.2.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/metview/7.2.4",
    "url": "/software/support/release-notes/metview/7.2.4",
    "template": "new-releasenotes-categroy",
    "section": "metview"
  },
  {
    "name": "2.4",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/calpuff/2.4",
    "url": "/software/support/release-notes/calpuff/2.4",
    "template": "new-releasenotes-categroy",
    "section": "calpuff"
  },
  {
    "name": "9.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/9.1",
    "url": "/software/support/release-notes/aermod/9.1",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "5.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/tankesp/5.2",
    "url": "/software/support/release-notes/tankesp/5.2",
    "template": "new-releasenotes-categroy",
    "section": "tankesp"
  },
  {
    "name": "7.9.2",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.9.2",
    "url": "/software/support/release-notes/aermod/7.9.2",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.3",
    "url": "/software/support/release-notes/aermod/7.3",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "1.3",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/incident-analyst/1.3",
    "url": "/software/support/release-notes/incident-analyst/1.3",
    "template": "new-releasenotes-categroy",
    "section": "incident-analyst"
  },
  {
    "name": "7.8",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/7.8",
    "url": "/software/support/release-notes/aermet/7.8",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "8.5",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/exdam/8.5",
    "url": "/software/support/release-notes/exdam/8.5",
    "template": "new-releasenotes-categroy",
    "section": "exdam"
  },
  {
    "name": "8.7",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/exdam/8.7",
    "url": "/software/support/release-notes/exdam/8.7",
    "template": "new-releasenotes-categroy",
    "section": "exdam"
  },
  {
    "name": "7.9",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.9",
    "url": "/software/support/release-notes/aermod/7.9",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "8.6",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/exdam/8.6",
    "url": "/software/support/release-notes/exdam/8.6",
    "template": "new-releasenotes-categroy",
    "section": "exdam"
  },
  {
    "name": "7.10",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.10",
    "url": "/software/support/release-notes/aermod/7.10",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "7.11",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.11",
    "url": "/software/support/release-notes/aermod/7.11",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "5.1.10",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/roads/5.1.10",
    "url": "/software/support/release-notes/roads/5.1.10",
    "template": "new-releasenotes-categroy",
    "section": "roads"
  },
  {
    "name": "9.0",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermet/9.0",
    "url": "/software/support/release-notes/aermet/9.0",
    "template": "new-releasenotes-categroy",
    "section": "aermet"
  },
  {
    "name": "7.10.1",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl3/software/support/release-notes/aermod/7.10.1",
    "url": "/software/support/release-notes/aermod/7.10.1",
    "template": "new-releasenotes-categroy",
    "section": "aermod"
  },
  {
    "name": "Burlington, Iowa",
    "apiurl": "/restapi/customcontent/contact-us/burlington-iowa",
    "url": "/contact-us/burlington-iowa",
    "template": "general_content",
    "section": "burlington,-iowa"
  },
  {
    "name": "Denver, Colorado",
    "apiurl": "/restapi/customcontent/contact-us/denver-colorado",
    "url": "/contact-us/denver-colorado",
    "template": "general_content",
    "section": "denver,-colorado"
  },
  {
    "name": "Allentown, PA",
    "apiurl": "/restapi/customcontent/contact-us/allentown-pa",
    "url": "/contact-us/allentown-pa",
    "template": "general_content",
    "section": "allentown,-pa"
  },
  {
    "name": "Phoenix, Arizona",
    "apiurl": "/restapi/customcontent/contact-us/phoenix",
    "url": "/contact-us/phoenix",
    "template": "general_content",
    "section": "phoenix,-arizona"
  },
  {
    "name": "Boston, Massachusetts",
    "apiurl": "/restapi/customcontent/contact-us/boston-massachusetts",
    "url": "/contact-us/boston-massachusetts",
    "template": "general_content",
    "section": "boston,-massachusetts"
  },
  {
    "name": "Oakland, California",
    "apiurl": "/restapi/customcontent/contact-us/oakland-california",
    "url": "/contact-us/oakland-california",
    "template": "general_content",
    "section": "oakland,-california"
  },
  {
    "name": "Cleveland, Ohio",
    "apiurl": "/restapi/customcontent/contact-us/cleveland-ohio",
    "url": "/contact-us/cleveland-ohio",
    "template": "general_content",
    "section": "cleveland,-ohio"
  },
  {
    "name": "Drums, PA",
    "apiurl": "/restapi/customcontent/contact-us/drums-pa",
    "url": "/contact-us/drums-pa",
    "template": "general_content",
    "section": "drums,-pa"
  },
  {
    "name": "Exton, Pennsylvania",
    "apiurl": "/restapi/customcontent/contact-us/exton-pennsylvania",
    "url": "/contact-us/exton-pennsylvania",
    "template": "general_content",
    "section": "exton,-pennsylvania"
  },
  {
    "name": "Princeton, New Jersey",
    "apiurl": "/restapi/customcontent/contact-us/princeton-new-jersey",
    "url": "/contact-us/princeton-new-jersey",
    "template": "general_content",
    "section": "princeton,-new-jersey"
  },
  {
    "name": "Birmingham, Alabama",
    "apiurl": "/restapi/customcontent/contact-us/birmingham-alabama",
    "url": "/contact-us/birmingham-alabama",
    "template": "general_content",
    "section": "birmingham,-alabama"
  },
  {
    "name": "Baton Rouge, Louisiana",
    "apiurl": "/restapi/customcontent/contact-us/baton-rouge-louisiana",
    "url": "/contact-us/baton-rouge-louisiana",
    "template": "general_content",
    "section": "baton-rouge,-louisiana"
  },
  {
    "name": "Dublin, Ohio",
    "apiurl": "/restapi/customcontent/contact-us/dublin-ohio",
    "url": "/contact-us/dublin-ohio",
    "template": "general_content",
    "section": "dublin,-ohio"
  },
  {
    "name": "Raleigh, North Carolina",
    "apiurl": "/restapi/customcontent/contact-us/raleigh-north-carolina",
    "url": "/contact-us/raleigh-north-carolina",
    "template": "general_content",
    "section": "raleigh,-north-carolina"
  },
  {
    "name": "Columbus, OH",
    "apiurl": "/restapi/customcontent/contact-us/ohio-onsite",
    "url": "/contact-us/ohio-onsite",
    "template": "general_content",
    "section": "columbus,-oh"
  },
  {
    "name": "Detroit, Michigan",
    "apiurl": "/restapi/customcontent/contact-us/detroit-michigan",
    "url": "/contact-us/detroit-michigan",
    "template": "general_content",
    "section": "detroit,-michigan"
  },
  {
    "name": "Covington, Kentucky",
    "apiurl": "/restapi/customcontent/contact-us/covington-kentucky",
    "url": "/contact-us/covington-kentucky",
    "template": "general_content",
    "section": "covington,-kentucky"
  },
  {
    "name": "Richmond, VA",
    "apiurl": "/restapi/customcontent/contact-us/richmond-virgina",
    "url": "/contact-us/richmond-virgina",
    "template": "general_content",
    "section": "richmond,-va"
  },
  {
    "name": "Evansville, Indiana",
    "apiurl": "/restapi/customcontent/contact-us/evansville-in",
    "url": "/contact-us/evansville-in",
    "template": "general_content",
    "section": "evansville,-indiana"
  },
  {
    "name": "Maryville, MI",
    "apiurl": "/restapi/customcontent/contact-us/maryville-mi",
    "url": "/contact-us/maryville-mi",
    "template": "general_content",
    "section": "maryville,-mi"
  },
  {
    "name": "West Des Moines, Iowa",
    "apiurl": "/restapi/customcontent/contact-us/west-des-moines-iowa",
    "url": "/contact-us/west-des-moines-iowa",
    "template": "general_content",
    "section": "west-des-moines,-iowa"
  },
  {
    "name": "New York City, New York",
    "apiurl": "/restapi/customcontent/contact-us/new-york-city-new-york",
    "url": "/contact-us/new-york-city-new-york",
    "template": "general_content",
    "section": "new-york-city,-new-york"
  },
  {
    "name": "Charlotte, NC",
    "apiurl": "/restapi/customcontent/contact-us/charlotte-nc",
    "url": "/contact-us/charlotte-nc",
    "template": "general_content",
    "section": "charlotte,-nc"
  },
  {
    "name": "Castleton-On-Hudson",
    "apiurl": "/restapi/customcontent/contact-us/castleton-on-hudson",
    "url": "/contact-us/castleton-on-hudson",
    "template": "general_content",
    "section": "castleton-on-hudson"
  },
  {
    "name": "Joplin, MO",
    "apiurl": "/restapi/customcontent/contact-us/joplin-mo",
    "url": "/contact-us/joplin-mo",
    "template": "general_content",
    "section": "joplin,-mo"
  },
  {
    "name": "Corpus Christi, TX",
    "apiurl": "/restapi/customcontent/contact-us/corpus-christi-tx",
    "url": "/contact-us/corpus-christi-tx",
    "template": "general_content",
    "section": "corpus-christi,-tx"
  },
  {
    "name": "Mt. Vernon, IN",
    "apiurl": "/restapi/customcontent/contact-us/mt.-vernon-in",
    "url": "/contact-us/mt.-vernon-in",
    "template": "general_content",
    "section": "mt.-vernon,-in"
  },
  {
    "name": "Anaheim, CA",
    "apiurl": "/restapi/customcontent/contact-us/anaheim-ca",
    "url": "/contact-us/anaheim-ca",
    "template": "general_content",
    "section": "anaheim,-ca"
  },
  {
    "name": "Austin, Texas",
    "apiurl": "/restapi/customcontent/contact-us/austin-texas",
    "url": "/contact-us/austin-texas",
    "template": "general_content",
    "section": "austin,-texas"
  },
  {
    "name": "San Ramon",
    "apiurl": "/restapi/customcontent/contact-us/san-ramon",
    "url": "/contact-us/san-ramon",
    "template": "general_content",
    "section": "san-ramon"
  },
  {
    "name": "Hybrid",
    "apiurl": "/restapi/customcontent/contact-us/hybrid",
    "url": "/contact-us/hybrid",
    "template": "general_content",
    "section": "hybrid"
  },
  {
    "name": "Upstate, New York",
    "apiurl": "/restapi/customcontent/contact-us/upstate-new-york",
    "url": "/contact-us/upstate-new-york",
    "template": "general_content",
    "section": "upstate,-new-york"
  },
  {
    "name": "Ontario",
    "apiurl": "/restapi/customcontent/contact-us/ontario",
    "url": "/contact-us/ontario",
    "template": "general_content",
    "section": "ontario"
  },
  {
    "name": "Dallas",
    "apiurl": "/restapi/customcontent/contact-us/dallas",
    "url": "/contact-us/dallas",
    "template": "general_content",
    "section": "dallas"
  },
  {
    "name": "Remote",
    "apiurl": "/restapi/customcontent/contact-us/remote",
    "url": "/contact-us/remote",
    "template": "general_content",
    "section": "remote"
  },
  {
    "name": "Keystone, SD",
    "apiurl": "/restapi/customcontent/contact-us/keystone-sd",
    "url": "/contact-us/keystone-sd",
    "template": "general_content",
    "section": "keystone,-sd"
  },
  {
    "name": "South Carolina",
    "apiurl": "/restapi/customcontent/contact-us/south-carolina",
    "url": "/contact-us/south-carolina",
    "template": "general_content",
    "section": "south-carolina"
  },
  {
    "name": "Ohio",
    "apiurl": "/restapi/customcontent/contact-us/ohio",
    "url": "/contact-us/ohio",
    "template": "general_content",
    "section": "ohio"
  },
  {
    "name": "Durango, CO",
    "apiurl": "/restapi/customcontent/contact-us/durango-co",
    "url": "/contact-us/durango-co",
    "template": "general_content",
    "section": "durango,-co"
  },
  {
    "name": "New York",
    "apiurl": "/restapi/customcontent/contact-us/new-york",
    "url": "/contact-us/new-york",
    "template": "general_content",
    "section": "new-york"
  },
  {
    "name": "Dallas, TX",
    "apiurl": "/restapi/customcontent/contact-us/dallas-tx",
    "url": "/contact-us/dallas-tx",
    "template": "general_content",
    "section": "dallas,-tx"
  },
  {
    "name": "Wixom, Michigan",
    "apiurl": "/restapi/customcontent/contact-us/wixom-michigan",
    "url": "/contact-us/wixom-michigan",
    "template": "general_content",
    "section": "wixom,-michigan"
  },
  {
    "name": "Odessa, TX",
    "apiurl": "/restapi/customcontent/contact-us/odessa-tx",
    "url": "/contact-us/odessa-tx",
    "template": "general_content",
    "section": "odessa,-tx"
  },
  {
    "name": "Kansas City, Kansas",
    "apiurl": "/restapi/customcontent/contact-us/kansas-city-kansas",
    "url": "/contact-us/kansas-city-kansas",
    "template": "general_content",
    "section": "kansas-city,-kansas"
  },
  {
    "name": "Chicago, Illinois",
    "apiurl": "/restapi/customcontent/contact-us/chicago-illinois",
    "url": "/contact-us/chicago-illinois",
    "template": "general_content",
    "section": "chicago,-illinois"
  },
  {
    "name": "Belvidere, NJ",
    "apiurl": "/restapi/customcontent/contact-us/belvidere-nj",
    "url": "/contact-us/belvidere-nj",
    "template": "general_content",
    "section": "belvidere,-nj"
  },
  {
    "name": "Mountain View, California",
    "apiurl": "/restapi/customcontent/contact-us/mountain-view-california",
    "url": "/contact-us/mountain-view-california",
    "template": "general_content",
    "section": "mountain-view,-california"
  },
  {
    "name": "Toronto, Ontario",
    "apiurl": "/restapi/customcontent/contact-us/toronto-ontario",
    "url": "/contact-us/toronto-ontario",
    "template": "general_content",
    "section": "toronto,-ontario"
  },
  {
    "name": "Beachwood",
    "apiurl": "/restapi/customcontent/contact-us/beachwood",
    "url": "/contact-us/beachwood",
    "template": "general_content",
    "section": "beachwood"
  },
  {
    "name": "Multiple Locations",
    "apiurl": "/restapi/customcontent/contact-us/multiple-locations",
    "url": "/contact-us/multiple-locations",
    "template": "general_content",
    "section": "multiple-locations"
  },
  {
    "name": "Queensland, Australia",
    "apiurl": "/restapi/customcontent/contact-us/queensland-australia",
    "url": "/contact-us/queensland-australia",
    "template": "general_content",
    "section": "queensland,-australia"
  },
  {
    "name": "Sedalia, MO",
    "apiurl": "/restapi/customcontent/contact-us/sedalia-mo",
    "url": "/contact-us/sedalia-mo",
    "template": "general_content",
    "section": "sedalia,-mo"
  },
  {
    "name": "Sacramento, California",
    "apiurl": "/restapi/customcontent/contact-us/sacramento-california",
    "url": "/contact-us/sacramento-california",
    "template": "general_content",
    "section": "sacramento,-california"
  },
  {
    "name": "Greater Philadelphia",
    "apiurl": "/restapi/customcontent/contact-us/greater-philadelphia",
    "url": "/contact-us/greater-philadelphia",
    "template": "general_content",
    "section": "greater-philadelphia"
  },
  {
    "name": "Atlanta, Georgia",
    "apiurl": "/restapi/customcontent/contact-us/atlanta-georgia",
    "url": "/contact-us/atlanta-georgia",
    "template": "general_content",
    "section": "atlanta,-georgia"
  },
  {
    "name": "Bakersfield, California",
    "apiurl": "/restapi/customcontent/contact-us/bakersfield-california",
    "url": "/contact-us/bakersfield-california",
    "template": "general_content",
    "section": "bakersfield,-california"
  },
  {
    "name": "Reno, Nevada",
    "apiurl": "/restapi/customcontent/contact-us/reno-nevada",
    "url": "/contact-us/reno-nevada",
    "template": "general_content",
    "section": "reno,-nevada"
  },
  {
    "name": "United States",
    "apiurl": "/restapi/customcontent/contact-us/united-states",
    "url": "/contact-us/united-states",
    "template": "general_content",
    "section": "united-states"
  },
  {
    "name": "Canada",
    "apiurl": "/restapi/customcontent/contact-us/canada",
    "url": "/contact-us/canada",
    "template": "general_content",
    "section": "canada"
  },
  {
    "name": "Albany, New York",
    "apiurl": "/restapi/customcontent/contact-us/albany-new-york",
    "url": "/contact-us/albany-new-york",
    "template": "general_content",
    "section": "albany,-new-york"
  },
  {
    "name": "Albuquerque, New Mexico",
    "apiurl": "/restapi/customcontent/contact-us/albuquerque-new-mexico",
    "url": "/contact-us/albuquerque-new-mexico",
    "template": "general_content",
    "section": "albuquerque,-new-mexico"
  },
  {
    "name": "Portland, OR",
    "apiurl": "/restapi/customcontent/contact-us/portland-or",
    "url": "/contact-us/portland-or",
    "template": "general_content",
    "section": "portland,-or"
  },
  {
    "name": "Milwaukee, WI",
    "apiurl": "/restapi/customcontent/contact-us/milwaukee-wi",
    "url": "/contact-us/milwaukee-wi",
    "template": "general_content",
    "section": "milwaukee,-wi"
  },
  {
    "name": "Multiple Locations - North Carolina",
    "apiurl": "/restapi/customcontent/contact-us/multiple-locations---north-carolina",
    "url": "/contact-us/multiple-locations---north-carolina",
    "template": "general_content",
    "section": "multiple-locations--north-carolina"
  },
  {
    "name": "Maryland",
    "apiurl": "/restapi/customcontent/contact-us/maryland",
    "url": "/contact-us/maryland",
    "template": "general_content",
    "section": "maryland"
  },
  {
    "name": "Robinson, IL",
    "apiurl": "/restapi/customcontent/contact-us/robinson-il",
    "url": "/contact-us/robinson-il",
    "template": "general_content",
    "section": "robinson,-il"
  },
  {
    "name": "New Jersey",
    "apiurl": "/restapi/customcontent/contact-us/new-jersey",
    "url": "/contact-us/new-jersey",
    "template": "general_content",
    "section": "new-jersey"
  },
  {
    "name": "Irvine, California",
    "apiurl": "/restapi/customcontent/contact-us/irvine-california",
    "url": "/contact-us/irvine-california",
    "template": "general_content",
    "section": "irvine,-california"
  },
  {
    "name": "Missouri",
    "apiurl": "/restapi/customcontent/contact-us/missouri",
    "url": "/contact-us/missouri",
    "template": "general_content",
    "section": "missouri"
  },
  {
    "name": "Salt Lake City, Utah",
    "apiurl": "/restapi/customcontent/contact-us/salt-lake-city-utah",
    "url": "/contact-us/salt-lake-city-utah",
    "template": "general_content",
    "section": "salt-lake-city,-utah"
  },
  {
    "name": "Foxboro",
    "apiurl": "/restapi/customcontent/contact-us/foxboro",
    "url": "/contact-us/foxboro",
    "template": "general_content",
    "section": "foxboro"
  },
  {
    "name": "Salt Lake City",
    "apiurl": "/restapi/customcontent/contact-us/salt-lake-city",
    "url": "/contact-us/salt-lake-city",
    "template": "general_content",
    "section": "salt-lake-city"
  },
  {
    "name": "Orlando, Florida",
    "apiurl": "/restapi/customcontent/contact-us/orlando-florida",
    "url": "/contact-us/orlando-florida",
    "template": "general_content",
    "section": "orlando,-florida"
  },
  {
    "name": "Little Rock, AR",
    "apiurl": "/restapi/customcontent/contact-us/little-rock-ar",
    "url": "/contact-us/little-rock-ar",
    "template": "general_content",
    "section": "little-rock,-ar"
  },
  {
    "name": "Columbus, Ohio",
    "apiurl": "/restapi/customcontent/contact-us/columbus-ohio",
    "url": "/contact-us/columbus-ohio",
    "template": "general_content",
    "section": "columbus,-ohio"
  },
  {
    "name": "Washington, DC",
    "apiurl": "/restapi/customcontent/contact-us/washington-dc",
    "url": "/contact-us/washington-dc",
    "template": "general_content",
    "section": "washington,-dc"
  },
  {
    "name": "Southborough",
    "apiurl": "/restapi/customcontent/contact-us/southborough",
    "url": "/contact-us/southborough",
    "template": "general_content",
    "section": "southborough"
  },
  {
    "name": "Georgetown, Ontario",
    "apiurl": "/restapi/customcontent/contact-us/georgetown-ontario",
    "url": "/contact-us/georgetown-ontario",
    "template": "general_content",
    "section": "georgetown,-ontario"
  },
  {
    "name": "Oklahoma City, OK",
    "apiurl": "/restapi/customcontent/contact-us/oklahoma-city-ok",
    "url": "/contact-us/oklahoma-city-ok",
    "template": "general_content",
    "section": "oklahoma-city,-ok"
  },
  {
    "name": "Frederick, Maryland",
    "apiurl": "/restapi/customcontent/contact-us/frederick-maryland",
    "url": "/contact-us/frederick-maryland",
    "template": "general_content",
    "section": "frederick,-maryland"
  },
  {
    "name": "Northern Wasatch Front Ozone Nonattainment Area Potential Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/northern-wasatch-front-ozone-nonattainment-area-potential-reclassification",
    "url": "/news/northern-wasatch-front-ozone-nonattainment-area-potential-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Northern Wasatch Front Ozone Nonattainment Area Potential Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/northern-wasatch-front-ozone-nonattainment-area-potential-reclassification",
    "url": "/news/states/utah/northern-wasatch-front-ozone-nonattainment-area-potential-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "State Oil Production at Highest Level in 25 Years-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/State-Oil-Production-at-Highest-Level-in-25-Years-Wyoming",
    "url": "/news/State-Oil-Production-at-Highest-Level-in-25-Years-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adopts Rule Change to Chapter 116 for Project Emissions Accounting (PEA) under New Source Review (NSR) Preconstruction Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adopts-rule-change-to-chapter-116-for-pea-under-nsr-preconstruction-permitting-program",
    "url": "/news/tceq-adopts-rule-change-to-chapter-116-for-pea-under-nsr-preconstruction-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adopts Rule Change to Chapter 116 for Project Emissions Accounting (PEA) under New Source Review (NSR) Preconstruction Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adopts-rule-change-to-chapter-116-for-pea-under-nsr-preconstruction-permitting-program",
    "url": "/news/tceq-adopts-rule-change-to-chapter-116-for-project-emissions-accounting-pea-under-new-source-review-nsr-preconstruction-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adopts Rule Change to Chapter 116 for Project Emissions Accounting (PEA) under New Source Review (NSR) Preconstruction Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adopts-rule-change-to-chapter-116-for-pea-under-nsr-preconstruction-permitting-program",
    "url": "/tceq-adopts-rule-change-to-chapter-116-for-project-emissions-accounting-pea-under-new-source-review-nsr-preconstruction-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Will You Be Targeted for the Regional Haze Second Planning Period?",
    "apiurl": "/restapi/customnewscontentlvl2/news/will-you-be-targeted-for-the-regional-haze-second-planning-period",
    "url": "/news/will-you-be-targeted-for-the-regional-haze-second-planning-period",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Will You Be Targeted for the Regional Haze Second Planning Period?",
    "apiurl": "/restapi/customnewscontentlvl2/news/will-you-be-targeted-for-the-regional-haze-second-planning-period",
    "url": "/News/Federal/Will-You-Be-Targeted-for-the-Regional-Haze-Second-Planning-Period-/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Will You Be Targeted for the Regional Haze Second Planning Period?",
    "apiurl": "/restapi/customnewscontentlvl2/news/will-you-be-targeted-for-the-regional-haze-second-planning-period",
    "url": "/news/Will-You-Be-Targeted-for-the-Regional-Haze-Second-Planning-Period-/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Water Quality Standard for Manganese and Implementation",
    "apiurl": "/restapi/customnewscontentlvl2/news/water-quality-standard-for-manganese-and-implementation",
    "url": "/news/water-quality-standard-for-manganese-and-implementation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Water Quality Standard for Manganese and Implementation",
    "apiurl": "/restapi/customnewscontentlvl2/news/water-quality-standard-for-manganese-and-implementation",
    "url": "/news/state/pennsylvania/water-quality-standard-for-manganese-and-implementation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ Proposed Emission Rule Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/udaq-proposed-emission-rule-amendments",
    "url": "/news/udaq-proposed-emission-rule-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Proposes Water Quality Updates-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Proposes-Water-Quality-Updates-Louisiana",
    "url": "/news/LDEQ-Proposes-Water-Quality-Updates-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Wyoming Nonpoint Source Program Report Released-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Wyoming-Nonpoint-Source-Program-Report-Released-Wyoming",
    "url": "/news/Wyoming-Nonpoint-Source-Program-Report-Released-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Introduces CoolTracker365",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-introduces-cooltracker365",
    "url": "/news/trinity-consultants-introduces-cooltracker365",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Georgia EPD Removes Stringent Atlanta Ozone Non-Attainment Permitting and Emissions Statement Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/georgia-epd-removes-stringent-atlanta-ozone-non-attainment-permitting-and-emissions-statement-requirements",
    "url": "/news/georgia-epd-removes-stringent-atlanta-ozone-non-attainment-permitting-and-emissions-statement-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WA State Supreme Court Rules Ecology May Not Apply Air Emission Standards to ‘Indirect Emitters’-Washington",
    "apiurl": "/restapi/customnewscontentlvl2/news/WA-State-Supreme-Court-Rules-Ecology-May-Not-Apply-Air-Emission-Standards-to-Indirect-Emitters--Washington",
    "url": "/news/WA-State-Supreme-Court-Rules-Ecology-May-Not-Apply-Air-Emission-Standards-to-Indirect-Emitters--Washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes Amendment for Opacity Limits for Fuel-Burning Equipment",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-proposes-amendment-for-opacity-limits-for-fuel-burning-equipment",
    "url": "/news/doee-proposes-amendment-for-opacity-limits-for-fuel-burning-equipment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tips to Tackle Your 2022 Florida Environmental Reporting Obligations",
    "apiurl": "/restapi/customnewscontentlvl2/news/tips-to-tackle-your-2022-florida-environmental-reporting-obligations",
    "url": "/news/tips-to-tackle-your-2022-florida-environmental-reporting-obligations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nevada BAPC Renews General Air Quality Operating Permit for Temporary Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/nevada-bapc-renews-general-air-quality-operating-permit-for-temporary-sources",
    "url": "/news/nevada-bapc-renews-general-air-quality-operating-permit-for-temporary-sources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Smart Strategies to Wastewater Sampling",
    "apiurl": "/restapi/customnewscontentlvl2/news/smart-strategies-to-wastewater-sampling",
    "url": "/news/smart-strategies-to-wastewater-sampling",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Ontario Excess Soil Management Regulations, and Amendments to Brownfields Regulation 153/04",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-ontario-excess-soil-management-regulations-and-amendments-to-brownfields-regulation-153-04",
    "url": "/news/new-ontario-excess-soil-management-regulations-and-amendments-to-brownfields-regulation-153-04",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Ontario Excess Soil Management Regulations, and Amendments to Brownfields Regulation 153/04",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-ontario-excess-soil-management-regulations-and-amendments-to-brownfields-regulation-153-04",
    "url": "/states/ontario/new-ontario-excess-soil-management-regulations-and-amendments-to-brownfields-regulation-153-04",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Rulemaking Prohibiting Hydrofluorocarbons (HFC) for use in Specific End Uses",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-rulemaking-prohibiting-hydrofluorocarbons-hfc-for-use-in-specific-end-uses",
    "url": "/news/proposed-rulemaking-prohibiting-hydrofluorocarbons-hfc-for-use-in-specific-end-uses",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Industrial Stakeholder Group (ISG) Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/industrial-stakeholder-group-(isg)-update",
    "url": "/news/industrial-stakeholder-group-(isg)-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues Aggregate Processing Operations Workbook",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-aggregate-processing-operations-workbook",
    "url": "/news/tceq-issues-aggregate-processing-operations-workbook",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ Releases New Visible Emissions Check Guide",
    "apiurl": "/restapi/customnewscontentlvl2/news/clark-county-daq-releases-new-visible-emissions-check-guide",
    "url": "/news/clark-county-daq-releases-new-visible-emissions-check-guide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Regulation 7 Changes to Address Ozone Nonattainment Redesignation-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Regulation-7-Changes-to-Address-Ozone-Nonattainment-Redesignation-Colorado",
    "url": "/news/Proposed-Regulation-7-Changes-to-Address-Ozone-Nonattainment-Redesignation-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Reporting Reminders-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-Reporting-Reminders-Kansas",
    "url": "/news/2020-Reporting-Reminders-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Adopted Title V Fee Increase -Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-DEQ-Adopted-Title-V-Fee-Increase--Oregon",
    "url": "/news/Oregon-DEQ-Adopted-Title-V-Fee-Increase--Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "North Dakota High Efficiency Program for Control Devices",
    "apiurl": "/restapi/customnewscontentlvl2/news/north-dakota-high-efficiency-program-for-control-devices",
    "url": "/news/north-dakota-high-efficiency-program-for-control-devices",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Withdraws Proposed Revisions to the Risk Evaluation/Corrective Action Program (RECAP)-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Withdraws-Proposed-Revisions-to-the-Risk-Evaluation-Corrective-Action-Program-(RECAP)-Louisiana",
    "url": "/news/LDEQ-Withdraws-Proposed-Revisions-to-the-Risk-Evaluation-Corrective-Action-Program-(RECAP)-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Launches Document Viewer for Air Quality Records-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-Launches-Document-Viewer-for-Air-Quality-Records-Nevada",
    "url": "/news/NDEP-Launches-Document-Viewer-for-Air-Quality-Records-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Initiates Implementation of NJ Environmental Justice Law with Administrative Order",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-initiates-implementation-of-nj-environmental-justice-law-with-administrative-order",
    "url": "/news/njdep-initiates-implementation-of-nj-environmental-justice-law-with-administrative-order",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Jersey Passes Major Environmental Justice Legislation",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-jersey-passes-major-environmental-justice-legislation",
    "url": "/news/new-jersey-passes-major-environmental-justice-legislation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Jersey Passes Major Environmental Justice Legislation",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-jersey-passes-major-environmental-justice-legislation",
    "url": "/news/state/new-jersey/new-jersey-passes-major-environmental-justice-legislation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Client Satisfaction Survey Summary",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-client-satisfaction-survey-summary",
    "url": "/news/2020-client-satisfaction-survey-summary",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA’s TANKS Program No Longer Accepted by Clark County DAQ",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-s-tanks-program-no-longer-accepted-by-clark-county-daq",
    "url": "/news/epa-s-tanks-program-no-longer-accepted-by-clark-county-daq",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Donation Benefits Victims of Racial Injustice and COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/donation-benefits-victims-of-racial-injustice-and-covid-19",
    "url": "/news/donation-benefits-victims-of-racial-injustice-and-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reminder for Bulk Material Sites and Sources of Particulate Matter Regulation 6, Rule 1 Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/reminder-for-bulk-material-sites-and-sources-of-particulate-matter-regulation-6-rule-1-amendments",
    "url": "/news/reminder-for-bulk-material-sites-and-sources-of-particulate-matter-regulation-6-rule-1-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reminder for Bulk Material Sites and Sources of Particulate Matter Regulation 6, Rule 1 Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/reminder-for-bulk-material-sites-and-sources-of-particulate-matter-regulation-6-rule-1-amendments",
    "url": "/states/reminder-for-bulk-material-sites-and-sources-of-particulate-matter-regulation-6-rule-1-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Handling Odor Complaints in Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/handling-odor-complaints-in-texas",
    "url": "/news/handling-odor-complaints-in-texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "How to Comply When Idling a Unit",
    "apiurl": "/restapi/customnewscontentlvl2/news/how-to-comply-when-idling-a-unit",
    "url": "/news/how-to-comply-when-idling-a-unit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed California Regional Permit Expands Stormwater Regulations for LA and Ventura County Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-california-regional-permit-expands-stormwater-regulations-for-la-and-ventura-county-facilities",
    "url": "/news/proposed-california-regional-permit-expands-stormwater-regulations-for-la-and-ventura-county-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Partners with its Clients to Support Direct Relief",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-partners-with-its-clients-to-support-direct-relief",
    "url": "/news/trinity-partners-with-its-clients-to-support-direct-relief",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Partners with its Clients to Support Direct Relief",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-partners-with-its-clients-to-support-direct-relief",
    "url": "/www.trinityconsultants.com/news/trinity-partners-with-its-clients-to-support-direct-relief",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulations Respecting Reduction in the Release of Methane and Certain Volatile Organic Compounds (Upstream Oil and Gas Sector)-Alberta",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-Alberta",
    "url": "/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-Alberta",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Changes to NOx RACT Regulations in the District",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-changes-to-nox-ract-regulations-in-the-district",
    "url": "/news/doee-finalizes-changes-to-nox-ract-regulations-in-the-district",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes More Stringent TRI Requirements for PFAS and Chemicals of Special Concern",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-more-stringent-tri-requirements-for-pfas-and-chemicals-of-special-concern",
    "url": "/news/epa-proposes-more-stringent-tri-requirements-for-pfas-and-chemicals-of-special-concern",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virtual Inspections in Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/virtual-inspections-",
    "url": "/news/virtual-inspections-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Approval of Illinois' Ozone NNSR SIP-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Approval-of-Illinois'-Ozone-NNSR-SIP-Illinois",
    "url": "/news/Approval-of-Illinois'-Ozone-NNSR-SIP-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Extends Submission Deadline for Upcoming Toxic Air Contaminant Emissions Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-extends-submission-deadline-for-upcoming-toxic-air-contaminant-emissions-reporting",
    "url": "/news/oregon-deq-extends-submission-deadline-for-upcoming-toxic-air-contaminant-emissions-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Informally Notifies Facilities of Upcoming Cleaner Air Oregon Triennial Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-informally-notifies-facilities-of-upcoming-cleaner-air-oregon-triennial-reporting-requirements-",
    "url": "/news/oregon-deq-informally-notifies-facilities-of-upcoming-cleaner-air-oregon-triennial-reporting-requirements-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes to Renew and Revise Source Category Permits for Engines Subject to NSPS JJJJ and NSPS IIII-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Proposes-to-Renew-and-Revise-Source-Category-Permits-for-Engines-Subject-to-NSPS-JJJJ-and-NSPS-IIII-Washington-DC",
    "url": "/news/DOEE-Proposes-to-Renew-and-Revise-Source-Category-Permits-for-Engines-Subject-to-NSPS-JJJJ-and-NSPS-IIII-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Quarterly Industrial Stakeholder - Air Permitting Group Meeting Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-quarterly-industrial-stakeholder---air-permitting-group-meeting-updates",
    "url": "/news/njdep-quarterly-industrial-stakeholder---air-permitting-group-meeting-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2019 Texas Environmental Regulatory Deadlines-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/2019-Texas-Environmental-Regulatory-Deadlines-Texas",
    "url": "/news/2019-Texas-Environmental-Regulatory-Deadlines-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Rescission of Clean Data Determination and Attainment Plan Revision for Yuma Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/rescission-of-clean-data-determination-and-attainment-plan-revision-for-yuma-industry",
    "url": "/news/rescission-of-clean-data-determination-and-attainment-plan-revision-for-yuma-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Montana Registration Program for Aggregates, Ready-Mix, and Asphalt Industry-Montana",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Montana-Registration-Program-for-Aggregates-Ready-Mix-and-Asphalt-Industry-Montana",
    "url": "/news/New-Montana-Registration-Program-for-Aggregates-Ready-Mix-and-Asphalt-Industry-Montana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What’s new in the 2019 Stormwater Multi-Sector General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-s-new-in-the-2019-stormwater-multi-sector-general-permit",
    "url": "/news/what-s-new-in-the-2019-stormwater-multi-sector-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What’s new in the 2019 Stormwater Multi-Sector General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-s-new-in-the-2019-stormwater-multi-sector-general-permit",
    "url": "/states/arizona/what-s-new-in-the-2019-stormwater-multi-sector-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Updates to the GHG Mandatory Reporting Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-updates-to-the-ghg-mandatory-reporting-rule",
    "url": "/news/epa-proposes-updates-to-the-ghg-mandatory-reporting-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washoe County AQMD's New Confidentiality Request Form",
    "apiurl": "/restapi/customnewscontentlvl2/news/washoe-county-aqmds-new-confidentiality-request-form",
    "url": "/news/washoe-county-aqmds-new-confidentiality-request-form",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reporting Reminders (April 2019 Update)-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reporting-Reminders-(April-2019-Update)-Iowa",
    "url": "/news/Reporting-Reminders-(April-2019-Update)-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Toxic Release Inventory (TRI) Basics and Landmines",
    "apiurl": "/restapi/customnewscontentlvl2/news/toxic-release-inventory-(tri)-basics-and-landmines",
    "url": "/news/toxic-release-inventory-(tri)-basics-and-landmines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Toxic Release Inventory (TRI) Basics and Landmines",
    "apiurl": "/restapi/customnewscontentlvl2/news/toxic-release-inventory-(tri)-basics-and-landmines",
    "url": "/News/Federal/Toxic-Release-Inventory-TRI-Basics-and-Landmines/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are You Prepared for the RY2020 New PFAS TRI Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "url": "/news/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are You Prepared for the RY2020 New PFAS TRI Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "url": "/news/federal/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are You Prepared for the RY2020 New PFAS TRI Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "url": "/federal/are-you-prepared-for-the-ry2020-new-pfas-tri-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDPHE Formal Application Rejection Process to Begin March 1, 2019-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/CDPHE-Formal-Application-Rejection-Process-to-Begin-March-1-2019-Colorado",
    "url": "/news/CDPHE-Formal-Application-Rejection-Process-to-Begin-March-1-2019-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated Name for the Online Permit System-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Updated-Name-for-the-Online-Permit-System-Iowa",
    "url": "/news/Updated-Name-for-the-Online-Permit-System-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA Proposes Significant Changes to VOC and NOx RACT Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-proposes-significant-changes-to-voc-and-nox-ract-rules",
    "url": "/news/ohio-epa-proposes-significant-changes-to-voc-and-nox-ract-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Building Envelope Studies for Odor Evaluation",
    "apiurl": "/restapi/customnewscontentlvl2/news/building-envelope-studies-for-odor-evaluation",
    "url": "/news/building-envelope-studies-for-odor-evaluation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice Focus May Begin to Impact Ohio Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-focus-may-begin-to-impact-ohio-industry",
    "url": "/news/environmental-justice-focus-may-begin-to-impact-ohio-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice Focus May Begin to Impact Ohio Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-focus-may-begin-to-impact-ohio-industry",
    "url": "/www.trinityconsultants.com/news/environmental-justice-focus-may-begin-to-impact-ohio-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Expands Funding and Scope for Heavy Duty Engine Replacement Projects",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-expands-funding-and-scope-for-heavy-duty-engine-replacement-projects",
    "url": "/news/baaqmd-expands-funding-and-scope-for-heavy-duty-engine-replacement-projects",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MPCA Winter Air Modeling Seminar",
    "apiurl": "/restapi/customnewscontentlvl2/news/mpca-hosts-annual-winter-modeling-seminar",
    "url": "/news/mpca-hosts-annual-winter-modeling-seminar",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Don’t Miss Your (RMP) Anniversary - For Your Risk Management Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/don-t-miss-your-(rmp)-anniversary---for-your-risk-management-plan",
    "url": "/news/don-t-miss-your-(rmp)-anniversary---for-your-risk-management-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Don’t Miss Your (RMP) Anniversary - For Your Risk Management Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/don-t-miss-your-(rmp)-anniversary---for-your-risk-management-plan",
    "url": "/federal/don-t-miss-your-rmp-anniversary----for-your-risk-management-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Comment Period Open for Proposed Revisions to Air Regulations-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Comment-Period-Open-for-Proposed-Revisions-to-Air-Regulations-Missouri",
    "url": "/news/Comment-Period-Open-for-Proposed-Revisions-to-Air-Regulations-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - Dec 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news---dec-2020",
    "url": "/news/good-news---dec-2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - Dec 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news---dec-2020",
    "url": "/news/good-news-dec-2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Shanghai Potent Compound Boot Camp Brochure",
    "apiurl": "/restapi/customnewscontentlvl2/news/Shanghai-Potent-Compound-Boot-Camp-Brochure",
    "url": "/news/Shanghai-Potent-Compound-Boot-Camp-Brochure",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WBCSD/GCCA Biodiversity Guidelines for Net Impact Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment",
    "url": "/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WBCSD/GCCA Biodiversity Guidelines for Net Impact Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment",
    "url": "/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment-nia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WBCSD/GCCA Biodiversity Guidelines for Net Impact Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment",
    "url": "/www.trinityconsultants.com/news/wbcsd-gcca-biodiversity-guidelines-for-net-impact-assessment-nia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing OSWRO Overlaps",
    "apiurl": "/restapi/customnewscontentlvl2/news/managing-oswro-overlaps",
    "url": "/news/managing-oswro-overlaps",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional Risk Management Program Rule Changes May Be on the Horizon",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-risk-management-program-rule-changes-may-be-on-the-horizon",
    "url": "/news/additional-risk-management-program-rule-changes-may-be-on-the-horizon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional Risk Management Program Rule Changes May Be on the Horizon",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-risk-management-program-rule-changes-may-be-on-the-horizon",
    "url": "/www.trinityconsultants.com/news/additional-risk-management-program-rule-changes-may-be-on-the-horizon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Silently Strikes SSM Exemption Language",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-silently-strikes-ssm-exemption-language",
    "url": "/news/epa-silently-strikes-ssm-exemption-language",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adds Additional Permit Application Types to ePermits System",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adds-additional-permit-application-types-to-epermits-system",
    "url": "/news/tceq-adds-additional-permit-application-types-to-epermits-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adds Additional Permit Application Types to ePermits System",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adds-additional-permit-application-types-to-epermits-system",
    "url": "/news/state/texas/tceq-adds-additional-permit-application-types-to-epermits-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Texas Areas’ Potential Ozone Nonattainment Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/texas-areas-potential-ozone-nonattainment-reclassification",
    "url": "/news/texas-areas-potential-ozone-nonattainment-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Governor Sisolak Launches the New State of Nevada Climate Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/governor-sisolak-launches-the-new-state-of-nevada-climate-initiative",
    "url": "/news/governor-sisolak-launches-the-new-state-of-nevada-climate-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Governor Sisolak Launches the New State of Nevada Climate Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/governor-sisolak-launches-the-new-state-of-nevada-climate-initiative",
    "url": "/news/state/nevada/governor-sisolak-launches-the-new-state-of-nevada-climate-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes Regulatory Permit for Turbines",
    "apiurl": "/restapi/customnewscontentlvl2/news/ldeq-finalizes-regulatory-permit-for-turbines",
    "url": "/news/ldeq-finalizes-regulatory-permit-for-turbines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes in Ontario Impact Air Permitting & Dispersion Modelling",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-in-ontario-impact-air-permitting-dispersion-modelling",
    "url": "/news/changes-in-ontario-impact-air-permitting-dispersion-modelling",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma Triennial Emissions Inventory",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-triennial-emissions-inventory",
    "url": "/news/oklahoma-triennial-emissions-inventory",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Navigating the Ozone Precursor Rule: A Guide to Alternative Compliance Plans",
    "apiurl": "/restapi/customnewscontentlvl2/news/navigating-the-ozone-precursor-rule-a-guide-to-alternative-compliance-plans",
    "url": "/news/navigating-the-ozone-precursor-rule-a-guide-to-alternative-compliance-plans",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Proposed Regulatory Changes - Right-to-Know Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-proposed-regulatory-changes-right-to-know-reporting",
    "url": "/news/kdhe-proposed-regulatory-changes-right-to-know-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "HFC Refrigerant Rule in Effect in New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/hfc-refrigerant-rule-in-effect-in-new-york",
    "url": "/news/hfc-refrigerant-rule-in-effect-in-new-york",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Issues Guidance Document for Baghouse Testing Determination System",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndep-issues-guidance-document-for-baghouse-testing-determination-system",
    "url": "/news/ndep-issues-guidance-document-for-baghouse-testing-determination-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated New York Guidelines for Implementing Part 212 Includes Changes to Modeling Guideline Concentrations",
    "apiurl": "/restapi/customnewscontentlvl2/news/updated-new-york-guidelines-for-implementing-part-212-includes-changes-to-modeling-guideline-concentrations",
    "url": "/news/updated-new-york-guidelines-for-implementing-part-212-includes-changes-to-modeling-guideline-concentrations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed SIP Approval for Limitations on Premises-Wide Actual Emissions Below Title V Source Threshold – Connecticut",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-sip-approval-for-limitations-on-premises-wide-actual-emissions-below-title-v-source-threshold-connecticut",
    "url": "/news/proposed-sip-approval-for-limitations-on-premises-wide-actual-emissions-below-title-v-source-threshold-connecticut",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Winery WDR Fee Stakeholder Meeting",
    "apiurl": "/restapi/customnewscontentlvl2/news/winery-wdr-fee-stakeholder-meeting",
    "url": "/news/winery-wdr-fee-stakeholder-meeting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Winery WDR Fee Stakeholder Meeting",
    "apiurl": "/restapi/customnewscontentlvl2/news/winery-wdr-fee-stakeholder-meeting",
    "url": "/www.trinityconsultants.com/news/winery-wdr-fee-stakeholder-meeting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Greenhouse Gas Reduction Requirements Impacting Colorado Manufacturers",
    "apiurl": "/restapi/customnewscontentlvl2/news/greenhouse-gas-reduction-requirements-impacting-colorado-manufacturers",
    "url": "/news/greenhouse-gas-reduction-requirements-impacting-colorado-manufacturers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Enablon",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---enablon",
    "url": "/news/partner-spotlight---enablon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Enablon",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---enablon",
    "url": "/technology/partner-spotlight---enablon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Risk Assessments Now Needed for Nitrosamines in APIs and Drug Products",
    "apiurl": "/restapi/customnewscontentlvl2/news/Risk-Assessments-Now-Needed-for-Nitrosamines-in-APIs-and-Drug-Products",
    "url": "/news/Risk-Assessments-Now-Needed-for-Nitrosamines-in-APIs-and-Drug-Products",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Renewed General Permit for Stormwater Discharges Associated with Construction Activities in Maryland",
    "apiurl": "/restapi/customnewscontentlvl2/news/renewed-general-permit-for-stormwater-discharges-associated-with-construction-activities-in-maryland",
    "url": "/news/renewed-general-permit-for-stormwater-discharges-associated-with-construction-activities-in-maryland",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Proposes to Renew the Air Quality Dust Action General Permit for Maricopa County",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-proposes-to-renew-the-air-quality-dust-action-general-permit-for-maricopa-county",
    "url": "/news/adeq-proposes-to-renew-the-air-quality-dust-action-general-permit-for-maricopa-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Reclassify Fairfield, Middlesex, and New Haven Counties from Serious to Severe",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-reclassify-fairfield-middlesex-and-new-haven-counties-from-serious-to-severe",
    "url": "/news/epa-proposes-to-reclassify-fairfield-middlesex-and-new-haven-counties-from-serious-to-severe",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Missouri Hazardous Waste Program Proposing Stakeholder Fee Increase-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Missouri-Hazardous-Waste-Program-Proposing-Stakeholder-Fee-Increase-Missouri",
    "url": "/news/Missouri-Hazardous-Waste-Program-Proposing-Stakeholder-Fee-Increase-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PA DEP Publishes Draft RACT Regulations to Control VOC Emissions from Oil and Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/pa-dep-publishes-draft-ract-regulations-to-control-voc-emissions-from-oil-and-gas-industry",
    "url": "/news/pa-dep-publishes-draft-ract-regulations-to-control-voc-emissions-from-oil-and-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Training Reminder-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/Training-Reminder-Nebraska",
    "url": "/news/Training-Reminder-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Release of NMED TSP Repeal Guidance Document-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/Release-of-NMED-TSP-Repeal-Guidance-Document-New-Mexico",
    "url": "/news/Release-of-NMED-TSP-Repeal-Guidance-Document-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Dispersion Modeling Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-dispersion-modeling-updates",
    "url": "/news/air-dispersion-modeling-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Georgia Environmental Reporting Spring 2022",
    "apiurl": "/restapi/customnewscontentlvl2/news/GA-environmental-reporting-spring-2022",
    "url": "/news/GA-environmental-reporting-spring-2022",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2019 TUR Plan Summary and Report Updated Deadline",
    "apiurl": "/restapi/customnewscontentlvl2/news/massdep-2019-tur-plan-summary-and-report-updated-deadline",
    "url": "/news/massdep-2019-tur-plan-summary-and-report-updated-deadline",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Quality Client Contact and EASY-Air Update (CC) Meetings - November 21, 2019-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Air-Quality-Client-Contact-and-EASY-Air-Update-(CC)-Meetings---November-21-2019-Iowa",
    "url": "/news/Air-Quality-Client-Contact-and-EASY-Air-Update-(CC)-Meetings---November-21-2019-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virtual Public Workshop for BAAQMD Regulation 2: Permits Amendment Concepts",
    "apiurl": "/restapi/customnewscontentlvl2/news/virtual-public-workshop-for-baaqmd-regulation-2-permits-amendment-concepts",
    "url": "/news/virtual-public-workshop-for-baaqmd-regulation-2-permits-amendment-concepts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virtual Public Workshop for BAAQMD Regulation 2: Permits Amendment Concepts",
    "apiurl": "/restapi/customnewscontentlvl2/news/virtual-public-workshop-for-baaqmd-regulation-2-permits-amendment-concepts",
    "url": "/www.trinityconsultants.com/news/virtual-public-workshop-for-baaqmd-regulation-2-permits-amendment-concepts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Buy Clean California Act: Global Warming Potential Limits Established",
    "apiurl": "/restapi/customnewscontentlvl2/news/buy-clean-california-act-global-warming-potential-limits-established",
    "url": "/news/buy-clean-california-act-global-warming-potential-limits-established",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CEDRI Available to Submit Title V Air Permit Applications and Reports",
    "apiurl": "/restapi/customnewscontentlvl2/news/cedri-available-to-submit-title-v-air-permit-applications-and-reports",
    "url": "/news/cedri-available-to-submit-title-v-air-permit-applications-and-reports",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CEDRI Available to Submit Title V Air Permit Applications and Reports",
    "apiurl": "/restapi/customnewscontentlvl2/news/cedri-available-to-submit-title-v-air-permit-applications-and-reports",
    "url": "/news/state/kentucky/cedri-available-to-submit-title-v-air-permit-applications-and-reports",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reclassification of Northern Wasatch Front to serious ozone nonattainment",
    "apiurl": "/restapi/customnewscontentlvl2/news/reclassification-of-northern-wasatch-front-to-serious-ozone-nonattainment",
    "url": "/news/reclassification-of-northern-wasatch-front-to-serious-ozone-nonattainment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Authority: TCEQ to Administer O&G Water Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-authority-tceq-to-administer-o-g-water-permitting",
    "url": "/news/changes-to-authority-tceq-to-administer-o-g-water-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Rules to Redesignate Oakridge, Oregon Nonattainment Areas",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-rules-to-redesignate-oakridge-oregon-nonattainment-areas",
    "url": "/news/epa-finalizes-rules-to-redesignate-oakridge-oregon-nonattainment-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2020: Corporate Initiatives",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2020-corporate-initiatives",
    "url": "/news/best-of-2020-corporate-initiatives",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reporting Deadlines and Information for 2019 Reporting Season-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reporting-Deadlines-and-Information-for-2019-Reporting-Season-Texas",
    "url": "/news/Reporting-Deadlines-and-Information-for-2019-Reporting-Season-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Canadian Fuel Regulations - Regulatory Reporting and Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/canadian-fuel-regulations---regulatory-reporting-and-guidance-",
    "url": "/news/canadian-fuel-regulations---regulatory-reporting-and-guidance-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Attainment Redesignation Approved by EPA for Lemont and Pekin Areas",
    "apiurl": "/restapi/customnewscontentlvl2/news/attainment-redesignation-approved-by-epa-for-lemont-and-pekin-areas",
    "url": "/news/attainment-redesignation-approved-by-epa-for-lemont-and-pekin-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Wyoming Air Quality Advisory Board Meeting Jul 15: SO2 Annual Report-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Wyoming-Air-Quality-Advisory-Board-Meeting-Jul-15-SO2-Annual-Report-Wyoming",
    "url": "/news/Wyoming-Air-Quality-Advisory-Board-Meeting-Jul-15-SO2-Annual-Report-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bexar County RY2017 EI Revision Notice-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Bexar-County-RY2017-EI-Revision-Notice-Texas",
    "url": "/news/Bexar-County-RY2017-EI-Revision-Notice-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Georgia Industrial Stormwater General Permit Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/georgia-industrial-stormwater-general-permit-update",
    "url": "/news/georgia-industrial-stormwater-general-permit-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Newly Amended WOTUS Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-newly-amended-wotus-rule",
    "url": "/news/epa-releases-newly-amended-wotus-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 6 - Into the Deep with Water Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-6-into-the-deep-with-water-regulations",
    "url": "/news/ehs-quarterly-issue-6-into-the-deep-with-water-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 6 - Into the Deep with Water Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-6-into-the-deep-with-water-regulations",
    "url": "/news/ehs-quarterly-issue-6---into-the-deep-with-water-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional CEDRI Updates for Certain NSPS & NESHAP Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-cedri-updates-for-certain-nsps-neshap-reporting-requirements-",
    "url": "/news/additional-cedri-updates-for-certain-nsps-neshap-reporting-requirements-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional CEDRI Updates for Certain NSPS & NESHAP Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-cedri-updates-for-certain-nsps-neshap-reporting-requirements-",
    "url": "/federal/additional-cedri-updates-for-certain-nsps-and-neshap-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CSAPR Update May Reduce Ozone Season Emissions Budgets for EGUs",
    "apiurl": "/restapi/customnewscontentlvl2/news/csapr-update-may-reduce-ozone-season-emissions-budgets-for-egus",
    "url": "/news/csapr-update-may-reduce-ozone-season-emissions-budgets-for-egus",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Benefits of Comprehensive EHS Risk Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/benefits-of-comprehensive-ehs-risk-management",
    "url": "/news/benefits-of-comprehensive-ehs-risk-management",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Benefits of Comprehensive EHS Risk Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/benefits-of-comprehensive-ehs-risk-management",
    "url": "/news/ehs-management/benefits-of-comprehensive-ehs-risk-management",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP Proposes Amendments to 310 CMR 7.00 Air Pollution Control-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-Proposes-Amendments-to-310-CMR-7-00-Air-Pollution-Control-Massachusetts",
    "url": "/news/MassDEP-Proposes-Amendments-to-310-CMR-7-00-Air-Pollution-Control-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Modeling Tip: How to Add a New Chemical Mixture in BREEZE Incident Analyst",
    "apiurl": "/restapi/customnewscontentlvl2/news/Modeling-Tip-How-to-Add-a-New-Chemical-Mixture-in-BREEZE-Incident-Analyst",
    "url": "/news/Modeling-Tip-How-to-Add-a-New-Chemical-Mixture-in-BREEZE-Incident-Analyst",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Stricter Emission Limits for Metal Melting and Heating Furnaces in Southern California",
    "apiurl": "/restapi/customnewscontentlvl2/news/stricter-emission-limits-for-metal-melting-and-heating-furnaces-in-southern-california",
    "url": "/news/stricter-emission-limits-for-metal-melting-and-heating-furnaces-in-southern-california",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Stricter Emission Limits for Metal Melting and Heating Furnaces in Southern California",
    "apiurl": "/restapi/customnewscontentlvl2/news/stricter-emission-limits-for-metal-melting-and-heating-furnaces-in-southern-california",
    "url": "/news/scaqmd-plans-to-set-stricter-emission-limits-for-metal-melting-and-heating-furnaces-in-southern-california",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia DEQ COVID-19 Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/virginia-deq-covid-19-updates-",
    "url": "/news/virginia-deq-covid-19-updates-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia DEQ COVID-19 Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/virginia-deq-covid-19-updates-",
    "url": "/states/virginia/virginia-deq-covid-19-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes New Air Quality Rules for the Oil & Natural Gas Industry-West Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Proposes-New-Air-Quality-Rules-for-the-Oil-Natural-Gas-Industry-West-Virginia",
    "url": "/news/EPA-Proposes-New-Air-Quality-Rules-for-the-Oil-Natural-Gas-Industry-West-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tanks and Loading Increases RAP",
    "apiurl": "/restapi/customnewscontentlvl2/news/tanks-and-loading-increases-rap",
    "url": "/news/tanks-and-loading-increases-rap",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Compliance Dates for the Ozone Precursor Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-compliance-dates-for-the-ozone-precursor-rule",
    "url": "/news/upcoming-compliance-dates-for-the-ozone-precursor-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Air Quality Updates in Pinal County",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-air-quality-updates-in-pinal-county",
    "url": "/news/upcoming-air-quality-updates-in-pinal-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ Launches \"List Server\" Feature-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Clark-County-DAQ-Launches-List-Server-Feature-Nevada",
    "url": "/news/Clark-County-DAQ-Launches-List-Server-Feature-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "10 Good Things About Option D Permits in Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/10-good-things-about-option-d-permits-in-minnesota",
    "url": "/news/10-good-things-about-option-d-permits-in-minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New September 2021 Proposed Regulation 7 Revisions Targeting Oil and Gas Emissions in Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-september-2021-proposed-regulation-7-revisions-targeting-oil-and-gas-emissions-in-colorado",
    "url": "/news/new-september-2021-proposed-regulation-7-revisions-targeting-oil-and-gas-emissions-in-colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Delaware's Regulations Governing Hazardous Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/delawares-regulations-governing-hazardous-waste",
    "url": "/news/delawares-regulations-governing-hazardous-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Delaware's Regulations Governing Hazardous Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/delawares-regulations-governing-hazardous-waste",
    "url": "/news/state/delaware/delawares-regulations-governing-hazardous-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MPCA Intends to Adopt Proposed Exempt Source and Conditionally Insignificant Activities Rulemaking Updates without Public Hearing-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/MPCA-Intends-to-Adopt-Proposed-Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-without-Public-Hearing-Minnesota",
    "url": "/news/MPCA-Intends-to-Adopt-Proposed-Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-without-Public-Hearing-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Effective Tools for Air Emissions Quantification for Chemical Manufacturing Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/effective-tools-for-air-emissions-quantification-for-chemical-manufacturing-facilities",
    "url": "/news/effective-tools-for-air-emissions-quantification-for-chemical-manufacturing-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Recently Proposed OAC Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/recently-proposed-oac-changes",
    "url": "/news/recently-proposed-oac-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Department of Environmental Quality – Proposed Water Quality Permit Fees Change",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-department-of-environmental-quality-proposed-water-quality-permit-fees-change",
    "url": "/news/oregon-department-of-environmental-quality-proposed-water-quality-permit-fees-change",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDPHE Finalizes Upstream GHG Intensity Verification Process with Regulation 7 Revisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/cdphe-finalizes-upstream-ghg-intensity-verification-process-with-regulation-7-revisions",
    "url": "/news/cdphe-finalizes-upstream-ghg-intensity-verification-process-with-regulation-7-revisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR EASY Air is Live-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-EASY-Air-is-Live-Iowa",
    "url": "/news/IDNR-EASY-Air-is-Live-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to Env-A 1200 Volatile Organic Compounds (VOCs) Reasonably Available Control Technology (RACT)-New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Amendments-to-Env-A-1200-Volatile-Organic-Compounds-(VOCs)-Reasonably-Available-Control-Technology-(RACT)-New-Hampshire",
    "url": "/news/Proposed-Amendments-to-Env-A-1200-Volatile-Organic-Compounds-(VOCs)-Reasonably-Available-Control-Technology-(RACT)-New-Hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised New Effluent Limits for Steam Electric Plants",
    "apiurl": "/restapi/customnewscontentlvl2/news/revised-new-effluent-limits-for-steam-electric-plants",
    "url": "/news/revised-new-effluent-limits-for-steam-electric-plants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised New Effluent Limits for Steam Electric Plants",
    "apiurl": "/restapi/customnewscontentlvl2/news/revised-new-effluent-limits-for-steam-electric-plants",
    "url": "/news/federal/revised-new-effluent-limits-for-steam-electric-plants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Project Spotlight: KPUD Renewable Natural Gas Project",
    "apiurl": "/restapi/customnewscontentlvl2/news/project-spotlight-kpud-renewable-natural-gas-project",
    "url": "/news/project-spotlight-kpud-renewable-natural-gas-project",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Releases Clean Air Oregon Prioritization Results-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-DEQ-Releases-Clean-Air-Oregon-Prioritization-Results-Oregon",
    "url": "/news/Oregon-DEQ-Releases-Clean-Air-Oregon-Prioritization-Results-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Kentucky Division of Water Issues New Multi-Sector General Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/kentucky-division-of-water-issues-new-multi-sector-general-stormwater-permit",
    "url": "/news/kentucky-division-of-water-issues-new-multi-sector-general-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Requires Ethylene Oxide Facilities to Report under TRI Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-requires-ethylene-oxide-facilities-to-report-under-tri-program",
    "url": "/news/epa-requires-ethylene-oxide-facilities-to-report-under-tri-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Common Arizona Air Quality Compliance Violations for Rock Producing Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/common-arizona-air-quality-compliance-violations-for-rock-producing-facilities",
    "url": "/news/common-arizona-air-quality-compliance-violations-for-rock-producing-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP Issues Draft Cumulative Impact Analysis Framework",
    "apiurl": "/restapi/customnewscontentlvl2/news/massdep-issues-draft-cumulative-impact-analysis-framework",
    "url": "/news/massdep-issues-draft-cumulative-impact-analysis-framework",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Mexico Environment Department Once Again Makes Updates to GCP-O&G Registration Forms-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Mexico-Environment-Department-Once-Again-Makes-Updates-to-GCP-O-G-Registration-Forms-New-Mexico",
    "url": "/news/New-Mexico-Environment-Department-Once-Again-Makes-Updates-to-GCP-O-G-Registration-Forms-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing a standout LDAR program: A Guide for the Proactive",
    "apiurl": "/restapi/customnewscontentlvl2/news/managing-a-standout-ldar-program-a-guide-for-the-proactive",
    "url": "/news/managing-a-standout-ldar-program-a-guide-for-the-proactive",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Amendment Concepts for BAAQMD Regulation 2: Permits",
    "apiurl": "/restapi/customnewscontentlvl2/news/amendment-concepts-for-baaqmd-regulation-2-permits",
    "url": "/news/amendment-concepts-for-baaqmd-regulation-2-permits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Amendment Concepts for BAAQMD Regulation 2: Permits",
    "apiurl": "/restapi/customnewscontentlvl2/news/amendment-concepts-for-baaqmd-regulation-2-permits",
    "url": "/www.trinityconsultants.com/news/amendment-concepts-for-baaqmd-regulation-2-permits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADVENT Engineering Services Joins Trinity Consultants",
    "apiurl": "/restapi/customnewscontentlvl2/news/ADVENT-Engineering-Services-Joins-Trinity-Consultants-",
    "url": "/news/ADVENT-Engineering-Services-Joins-Trinity-Consultants-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Substantive Changes to Water Quality Trading Proposed Rule-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/Substantive-Changes-to-Water-Quality-Trading-Proposed-Rule-Louisiana",
    "url": "/news/Substantive-Changes-to-Water-Quality-Trading-Proposed-Rule-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA’s Clean Air Scientific Advisory Committee Recommends Tightening of PM2.5 NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-s-clean-air-scientific-advisory-committee-recommends-tightening-of-pm2.5-naaqs",
    "url": "/news/epa-s-clean-air-scientific-advisory-committee-recommends-tightening-of-pm2.5-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Chemical Reporting and Compliance Basics: Many Changes to Manage",
    "apiurl": "/restapi/customnewscontentlvl2/news/chemical-reporting-and-compliance-basics-many-changes-to-manage",
    "url": "/news/chemical-reporting-and-compliance-basics-many-changes-to-manage",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Chemical Reporting and Compliance Basics: Many Changes to Manage",
    "apiurl": "/restapi/customnewscontentlvl2/news/chemical-reporting-and-compliance-basics-many-changes-to-manage",
    "url": "/news/federal/chemical-reporting-and-compliance-basics-many-changes-to-manage",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Ethylene Oxide Ambient Monitoring-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Ethylene-Oxide-Ambient-Monitoring-Illinois",
    "url": "/news/Proposed-Ethylene-Oxide-Ambient-Monitoring-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves Massachusetts SIP Revision-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Approves-Massachusetts-SIP-Revision-Massachusetts",
    "url": "/news/EPA-Approves-Massachusetts-SIP-Revision-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PA EQB Authorizes Cap-and-Trade Petition For Evaluation-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/PA-EQB-Authorizes-Cap-and-Trade-Petition-For-Evaluation-Pennsylvania",
    "url": "/news/PA-EQB-Authorizes-Cap-and-Trade-Petition-For-Evaluation-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDAQMD Proposes Amendments to NSR & PSD Air Permitting Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdaqmd-proposes-amendments-to-nsr-psd-air-permitting-rules",
    "url": "/news/mdaqmd-proposes-amendments-to-nsr-psd-air-permitting-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDAQMD Proposes Amendments to NSR & PSD Air Permitting Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdaqmd-proposes-amendments-to-nsr-psd-air-permitting-rules",
    "url": "/news/mdaqmd-proposes-amendments-to-nsr-psd-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Carbon Capture in Wyoming-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Carbon-Capture-in-Wyoming-Wyoming",
    "url": "/news/Carbon-Capture-in-Wyoming-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Multi-Sector General Permit Delayed, But Changes are Coming",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-multi-sector-general-permit-delayed-but-changes-are-coming",
    "url": "/news/epa-multi-sector-general-permit-delayed-but-changes-are-coming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Multi-Sector General Permit Delayed, But Changes are Coming",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-multi-sector-general-permit-delayed-but-changes-are-coming",
    "url": "/news/state/oklahoma/epa-multi-sector-general-permit-delayed-but-changes-are-coming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Training Reminder-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Training-Reminder-Kansas",
    "url": "/news/Training-Reminder-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 7 - Piecing Together the Environmental Reporting Puzzle",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-7-piecing-together-the-environmental-reporting-puzzle",
    "url": "/news/ehs-quarterly-issue-7-piecing-together-the-environmental-reporting-puzzle",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Launches Website for Cleaner Air Oregon Submissions-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/DEQ-Launches-Website-for-Cleaner-Air-Oregon-Submissions-Oregon",
    "url": "/news/DEQ-Launches-Website-for-Cleaner-Air-Oregon-Submissions-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA is Sued! Industry Battles the Ethylene Oxide Rule in the MON",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-is-sued!-industry-battles-the-ethylene-oxide-rule-in-the-mon",
    "url": "/news/epa-is-sued!-industry-battles-the-ethylene-oxide-rule-in-the-mon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Retain Existing NAAQS for Ozone",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-retain-existing-naaqs-for-ozone",
    "url": "/news/epa-proposes-to-retain-existing-naaqs-for-ozone",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Retain Existing NAAQS for Ozone",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-retain-existing-naaqs-for-ozone",
    "url": "/states/arizona/epa-proposes-to-retain-existing-naaqs-for-ozone",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "November 2019 Triangle Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/November-2019-Triangle-Newsletter",
    "url": "/news/November-2019-Triangle-Newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Presses \"Pause\" on Good Neighbor FIP for Six States",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-presses-pause-on-good-neighbor-fip-for-six-states",
    "url": "/news/epa-presses-pause-on-good-neighbor-fip-for-six-states",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Partners with New Majority Owner Oak Hill Capital",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-partners-with-new-majority-owner-oak-hill-capital",
    "url": "/news/trinity-consultants-partners-with-new-majority-owner-oak-hill-capital",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice in California - What is EJ and How Might it Impact You?",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-in-california-what-is-ej-and-how-might-it-impact-you",
    "url": "/news/environmental-justice-in-california-what-is-ej-and-how-might-it-impact-you",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice in California - What is EJ and How Might it Impact You?",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-in-california-what-is-ej-and-how-might-it-impact-you",
    "url": "/news/environmental-justice-in-california---what-is-ej-and-how-might-it-impact-you",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado’s Greenhouse Gas Inventory and More to Come-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Colorado-s-Greenhouse-Gas-Inventory-and-More-to-Come-Colorado",
    "url": "/news/Colorado-s-Greenhouse-Gas-Inventory-and-More-to-Come-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado’s Greenhouse Gas Inventory and More to Come-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Colorado-s-Greenhouse-Gas-Inventory-and-More-to-Come-Colorado",
    "url": "/states/Colorado/Colorado-s-Greenhouse-Gas-Inventory-and-More-to-Come-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Next Step for ACE Expected Next Month",
    "apiurl": "/restapi/customnewscontentlvl2/news/next-step-for-ace-expected-next-month",
    "url": "/news/next-step-for-ace-expected-next-month",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Next Step for ACE Expected Next Month",
    "apiurl": "/restapi/customnewscontentlvl2/news/next-step-for-ace-expected-next-month",
    "url": "/news/state/oklahoma/next-step-for-ace-expected-next-month",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Senate Bill 181: Potential Changes for Oil and Gas Operations in Colorado-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Senate-Bill-181-Potential-Changes-for-Oil-and-Gas-Operations-in-Colorado-Colorado",
    "url": "/news/Senate-Bill-181-Potential-Changes-for-Oil-and-Gas-Operations-in-Colorado-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Crystalline Silica 24-hour ReV DSD",
    "apiurl": "/restapi/customnewscontentlvl2/news/crystalline-silica-24-hour-rev-dsd",
    "url": "/news/crystalline-silica-24-hour-rev-dsd",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York to Lower Ozone Season NOx RACT Limits for SCCTs-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-York-to-Lower-Ozone-Season-NOx-RACT-Limits-for-SCCTs-New-Jersey",
    "url": "/news/New-York-to-Lower-Ozone-Season-NOx-RACT-Limits-for-SCCTs-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PSM Experts Present at 2021 AIChE Global Congress on Process Safety",
    "apiurl": "/restapi/customnewscontentlvl2/news/psm-experts-present-at-2021-aiche-global-congress-on-process-safety",
    "url": "/news/psm-experts-present-at-2021-aiche-global-congress-on-process-safety",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Understanding Ontario’s Energy and Water Reporting and Benchmarking (EWRB) Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/understanding-ontario-s-energy-and-water-reporting-and-benchmarking-(ewrb)-initiative",
    "url": "/news/understanding-ontario-s-energy-and-water-reporting-and-benchmarking-(ewrb)-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes New Regulations for Screen Printing Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-proposes-new-regulations-for-screen-printing-operations",
    "url": "/news/doee-proposes-new-regulations-for-screen-printing-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Provides COVID-19 Air Quality Regulatory Compliance Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-provides-covid-19-air-quality-regulatory-compliance-guidance",
    "url": "/news/njdep-provides-covid-19-air-quality-regulatory-compliance-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Is Your Facility Ready for an MOL Inspection?",
    "apiurl": "/restapi/customnewscontentlvl2/news/is-your-facility-ready-for-an-mol-inspection",
    "url": "/news/is-your-facility-ready-for-an-mol-inspection",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to Env-A 1000 Prevention, Abatement, and Control of Open Source Air Pollution-New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Amendments-to-Env-A-1000-Prevention-Abatement-and-Control-of-Open-Source-Air-Pollution-New-Hampshire",
    "url": "/news/Proposed-Amendments-to-Env-A-1000-Prevention-Abatement-and-Control-of-Open-Source-Air-Pollution-New-Hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Amendments to Air Permit Application Forms-New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/Amendments-to-Air-Permit-Application-Forms-New-Hampshire",
    "url": "/news/Amendments-to-Air-Permit-Application-Forms-New-Hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP New Biennial Hazardous Waste Reporting System-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-New-Biennial-Hazardous-Waste-Reporting-System-Nevada",
    "url": "/news/NDEP-New-Biennial-Hazardous-Waste-Reporting-System-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Updates Concrete Batch Plant Standard Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-updates-concrete-batch-plant-standard-permit",
    "url": "/news/tceq-updates-concrete-batch-plant-standard-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SIP Approval for Fuel Sulfur Limitations in New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/sip-approval-for-fuel-sulfur-limitations-in-new-hampshire",
    "url": "/news/sip-approval-for-fuel-sulfur-limitations-in-new-hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The SAFE Vehicles Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-safe-vehicles-rule",
    "url": "/news/the-safe-vehicles-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Developing PFAS Regulations in the Food and Beverage Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/developing-pfas-regulations-in-the-food-and-beverage-industry",
    "url": "/news/developing-pfas-regulations-in-the-food-and-beverage-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Developing PFAS Regulations in the Food and Beverage Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/developing-pfas-regulations-in-the-food-and-beverage-industry",
    "url": "/www.trinityconsultants.com/news/developing-pfas-regulations-in-the-food-and-beverage-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues the Final 2021 MSGP",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-the-final-2021-mspg",
    "url": "/news/epa-issues-the-final-2021-mspg",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ODEQ CAO Website Resources-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/ODEQ-CAO-Website-Resources-Oregon",
    "url": "/news/ODEQ-CAO-Website-Resources-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREEZE TankESP 5.1 Software Includes Recently Updated AP-42 Tank Emission Factors",
    "apiurl": "/restapi/customnewscontentlvl2/news/BREEZE-TankESP-5-1-Software-Includes-Recently-Updated-AP-42-Tank-Emission-Factors",
    "url": "/news/BREEZE-TankESP-5-1-Software-Includes-Recently-Updated-AP-42-Tank-Emission-Factors",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Migration Corridor Protection to be addressed as part of Wyoming Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/migration-corridor-protection-to-be-addressed-as-part-of-wyoming-permitting",
    "url": "/news/migration-corridor-protection-to-be-addressed-as-part-of-wyoming-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Migration Corridor Protection to be addressed as part of Wyoming Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/migration-corridor-protection-to-be-addressed-as-part-of-wyoming-permitting",
    "url": "/states/migration-corridor-protection-to-be-addressed-as-part-of-wyoming-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated Industrial General Stormwater Permit Effective January 1, 2020-Washington",
    "apiurl": "/restapi/customnewscontentlvl2/news/Updated-Industrial-General-Stormwater-Permit-Effective-January-1-2020-Washington",
    "url": "/news/Updated-Industrial-General-Stormwater-Permit-Effective-January-1-2020-Washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Adopts Final Guidance on Evaluating CLCPA for Air Permit Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-adopts-final-guidance-on-evaluating-clcpa-for-air-permit-applications",
    "url": "/news/new-york-adopts-final-guidance-on-evaluating-clcpa-for-air-permit-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "How to Minimize Your Footprint and Maximize Your Handprint",
    "apiurl": "/restapi/customnewscontentlvl2/news/how-to-minimize-your-footprint-and-maximize-your-handprint",
    "url": "/news/how-to-minimize-your-footprint-and-maximize-your-handprint",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes New Air Quality Regulations for Permitting Demand Response Engines-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Proposes-New-Air-Quality-Regulations-for-Permitting-Demand-Response-Engines-Washington-DC",
    "url": "/news/DOEE-Proposes-New-Air-Quality-Regulations-for-Permitting-Demand-Response-Engines-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA's New Owner Audit Policy and New Owner Clean Air Act Audit Program for Oil and Natural Gas Sectors",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-new-owner-audit-policy-and-new-owner-clean-air-act-audit-program-for-oil-and-natural-gas-sectors",
    "url": "/news/epa-new-owner-audit-policy-and-new-owner-clean-air-act-audit-program-for-oil-and-natural-gas-sectors",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York State DEC Requires Electronic Submittal of Annual Emission Statements",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-state-dec-requires-electronic-submittal-of-annual-emission-statements",
    "url": "/news/new-york-state-dec-requires-electronic-submittal-of-annual-emission-statements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates UST Compliance Forms",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-ust-compliance-forms",
    "url": "/news/kdhe-updates-ust-compliance-forms",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates UST Compliance Forms",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-ust-compliance-forms",
    "url": "/news/state/kansas/kdhe-updates-ust-compliance-forms",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to Ohio EPA’s PTI and PTIO Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-revisions-to-ohio-epas-pti-and-ptio-rules",
    "url": "/news/proposed-revisions-to-ohio-epas-pti-and-ptio-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to Ohio EPA’s PTI and PTIO Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-revisions-to-ohio-epas-pti-and-ptio-rules",
    "url": "/news/proposed-revisions-to-ohio-epa-s-pti-and-ptio-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Permit by Rule Registration",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-permit-by-rule-registration",
    "url": "/news/changes-to-permit-by-rule-registration",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "January 2019 AWMA Midwest Section Meeting – EPA Region 7 Key Takeaways-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/January-2019-AWMA-Midwest-Section-Meeting-EPA-Region-7-Key-Takeaways-Kansas",
    "url": "/news/January-2019-AWMA-Midwest-Section-Meeting-EPA-Region-7-Key-Takeaways-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma DEQ AQD Retires E-Permitting System",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-deq-aqd-retires-e-permitting-system",
    "url": "/news/oklahoma-deq-aqd-retires-e-permitting-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Boiler MACT Affected Sources Five-Year Tune-Up-South Carolina",
    "apiurl": "/restapi/customnewscontentlvl2/news/Boiler-MACT-Affected-Sources-Five-Year-Tune-Up-South-Carolina",
    "url": "/news/Boiler-MACT-Affected-Sources-Five-Year-Tune-Up-South-Carolina",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Gensuite",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---gensuite",
    "url": "/news/partner-spotlight---gensuite",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Gensuite",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---gensuite",
    "url": "/news/technology/partner-spotlight---gensuite",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Gensuite",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---gensuite",
    "url": "/news/partner-spotlight-gensuite",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ Updates RACT Requirements for Major Stationary Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/clark-county-daq-updates-ract-requirements-for-major-stationary-sources",
    "url": "/news/clark-county-daq-updates-ract-requirements-for-major-stationary-sources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Crafting Your Climate Strategy: How to Assess and Mitigate EJ Exposure",
    "apiurl": "/restapi/customnewscontentlvl2/news/crafting-your-climate-strategy-how-to-assess-and-mitigate-ej-exposure",
    "url": "/news/crafting-your-climate-strategy-how-to-assess-and-mitigate-ej-exposure",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WDNR Streamlines Air Rules for Litho Printers and Launches Online Industry Portal-Wisconsin",
    "apiurl": "/restapi/customnewscontentlvl2/news/WDNR-Streamlines-Air-Rules-for-Litho-Printers-and-Launches-Online-Industry-Portal-Wisconsin",
    "url": "/news/WDNR-Streamlines-Air-Rules-for-Litho-Printers-and-Launches-Online-Industry-Portal-Wisconsin",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes a Revision to the Water Quality Regulations-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Finalizes-a-Revision-to-the-Water-Quality-Regulations-Louisiana",
    "url": "/news/LDEQ-Finalizes-a-Revision-to-the-Water-Quality-Regulations-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Requesting Comments on Draft Recommended Procedures for Cleaner Air Oregon-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/DEQ-Requesting-Comments-on-Draft-Recommended-Procedures-for-Cleaner-Air-Oregon-Oregon",
    "url": "/news/DEQ-Requesting-Comments-on-Draft-Recommended-Procedures-for-Cleaner-Air-Oregon-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Preliminary Annual Offset Equivalency Demonstration Results",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-preliminary-annual-offset-equivalency-demonstration-results",
    "url": "/news/sjvapcd-preliminary-annual-offset-equivalency-demonstration-results",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Preliminary Annual Offset Equivalency Demonstration Results",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-preliminary-annual-offset-equivalency-demonstration-results",
    "url": "/news/states/california/sjvapcd-preliminary-annual-offset-equivalency-demonstration-results",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reminder of the Month - Boiler MACT Compliance Certification Due January 31st-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reminder-of-the-Month---Boiler-MACT-Compliance-Certification-Due-January-31st-Nebraska",
    "url": "/news/Reminder-of-the-Month---Boiler-MACT-Compliance-Certification-Due-January-31st-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR Draft 2018 Impaired Waters List-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-Draft-2018-Impaired-Waters-List-Iowa",
    "url": "/news/IDNR-Draft-2018-Impaired-Waters-List-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "More than $3400 Raised for Children’s Hospital of Philadelphia by Team Trinity",
    "apiurl": "/restapi/customnewscontentlvl2/news/more-than-3400-raised-for-children-s-hospital-of-philadelphia-by-team-trinity",
    "url": "/news/more-than-3400-raised-for-children-s-hospital-of-philadelphia-by-team-trinity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "More than $3400 Raised for Children’s Hospital of Philadelphia by Team Trinity",
    "apiurl": "/restapi/customnewscontentlvl2/news/more-than-3400-raised-for-children-s-hospital-of-philadelphia-by-team-trinity",
    "url": "/company/more-than-3400-raised-for-children-s-hospital-of-philadelphia-by-team-trinity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Canada’s Air Requirements for “Onshore Upstream Oil and Gas” Sources is Effective January 2020-Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/Canada-s-Air-Requirements-for-Onshore-Upstream-Oil-and-Gas-Sources-is-Effective-January-2020-Ontario",
    "url": "/news/Canada-s-Air-Requirements-for-Onshore-Upstream-Oil-and-Gas-Sources-is-Effective-January-2020-Ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Finalizes Rule Establishing Cap and Trade Program-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/DEQ-Finalizes-Rule-Establishing-Cap-and-Trade-Program-Virginia",
    "url": "/news/DEQ-Finalizes-Rule-Establishing-Cap-and-Trade-Program-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Approve Part of Illinois Infrastructure SIP for 2012 PM2.5 NAAQS-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Proposes-to-Approve-Part-of-Illinois-Infrastructure-SIP-for-2012-PM2-5-NAAQS-Illinois",
    "url": "/news/EPA-Proposes-to-Approve-Part-of-Illinois-Infrastructure-SIP-for-2012-PM2-5-NAAQS-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Amendments to CO2 Trading Program and Repeal of CO2 Offset Projects under RGGI-New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/Amendments-to-CO2-Trading-Program-and-Repeal-of-CO2-Offset-Projects-under-RGGI-New-Hampshire",
    "url": "/news/Amendments-to-CO2-Trading-Program-and-Repeal-of-CO2-Offset-Projects-under-RGGI-New-Hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What You Need to Know About NYSDEC’s Recently Adopted Suite of Air Rule Revisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-you-need-to-know-about-nysdecs-recently-adopted-suite-of-air-rule-revisions",
    "url": "/news/what-you-need-to-know-about-nysdecs-recently-adopted-suite-of-air-rule-revisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP’s New Health RSW for Air Permit Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-s-new-health-rsw-for-air-permit-applications",
    "url": "/news/njdep-s-new-health-rsw-for-air-permit-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes AQ382: Incorporating Test Results-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Finalizes-AQ382-Incorporating-Test-Results-Louisiana",
    "url": "/news/LDEQ-Finalizes-AQ382-Incorporating-Test-Results-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Adoption of New Regulations as a Replacement of Current GPLPE-Connecticut",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Adoption-of-New-Regulations-as-a-Replacement-of-Current-GPLPE-Connecticut",
    "url": "/news/Proposed-Adoption-of-New-Regulations-as-a-Replacement-of-Current-GPLPE-Connecticut",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Hearing to Adopt Amendments to Regulation 2: Permits",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-hearing-to-adopt-amendments-to-regulation-2-permits",
    "url": "/news/baaqmd-hearing-to-adopt-amendments-to-regulation-2-permits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to the Refrigerant Management Programs Extension to Substitutes",
    "apiurl": "/restapi/customnewscontentlvl2/news/revisions-to-the-refrigerant-management-programs-extension-to-substitutes",
    "url": "/news/revisions-to-the-refrigerant-management-programs-extension-to-substitutes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to the Refrigerant Management Programs Extension to Substitutes",
    "apiurl": "/restapi/customnewscontentlvl2/news/revisions-to-the-refrigerant-management-programs-extension-to-substitutes",
    "url": "/federal/revisions-to-the-refrigerant-management-program-s-extension-to-substitutes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to the Refrigerant Management Programs Extension to Substitutes",
    "apiurl": "/restapi/customnewscontentlvl2/news/revisions-to-the-refrigerant-management-programs-extension-to-substitutes",
    "url": "/news/federal/revisions-to-the-refrigerant-management-program-s-extension-to-substitutes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Proposed MON/EMACT/OLD/Refinery MACT Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-proposed-mon-emact-old-refinery-mact-updated",
    "url": "/news/epa-issues-proposed-mon-emact-old-refinery-mact-updated",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires Engineering Consulting Firm Aztec Technologies",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-engineering-consulting-firm-aztec-technologies",
    "url": "/news/trinity-consultants-acquires-engineering-consulting-firm-aztec-technologies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New WOTUS Supreme Court Decision: Goodbye Significant Nexus, Hello Continuous Surface Connection",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-wotus-supreme-court-decision-goodbye-significant-nexus-hello-continuous-surface-connection",
    "url": "/news/new-wotus-supreme-court-decision-goodbye-significant-nexus-hello-continuous-surface-connection",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Proposed 2022 Construction General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-proposed-2022-construction-general-permit",
    "url": "/news/epa-issues-proposed-2022-construction-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Proposed 2022 Construction General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-proposed-2022-construction-general-permit",
    "url": "/www.trinityconsultants.com/news/epa-issues-proposed-2022-construction-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft 2021 Ambient Air Quality Monitoring Network Plan Published by NDEE",
    "apiurl": "/restapi/customnewscontentlvl2/news/2021-draft-ambient-air-quality-monitoring-network-plan-published-by-ndee",
    "url": "/news/2021-draft-ambient-air-quality-monitoring-network-plan-published-by-ndee",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft 2021 Ambient Air Quality Monitoring Network Plan Published by NDEE",
    "apiurl": "/restapi/customnewscontentlvl2/news/2021-draft-ambient-air-quality-monitoring-network-plan-published-by-ndee",
    "url": "/www.trinityconsultants.com/news/2021-draft-ambient-air-quality-monitoring-network-plan-published-by-ndee",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Proposes Several Rule Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-proposes-several-rule-changes",
    "url": "/news/new-york-proposes-several-rule-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Simplified Permitting Proposed for Stormwater Management Works in Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/simplified-permitting-proposed-for-stormwater-management-works-in-ontario",
    "url": "/news/simplified-permitting-proposed-for-stormwater-management-works-in-ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Air Toxics Emission Inventory Data Published",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-air-toxics-emission-inventory-data-published",
    "url": "/news/2020-air-toxics-emission-inventory-data-published",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Executive Order Signed to Address Environmental Justice in Virginia-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Executive-Order-Signed-to-Address-Environmental-Justice-in-Virginia-Virginia",
    "url": "/news/Executive-Order-Signed-to-Address-Environmental-Justice-in-Virginia-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EJ Considerations for the Development of PFAS National Primary Drinking Water Regulation (NPDWR)",
    "apiurl": "/restapi/customnewscontentlvl2/news/ej-considerations-for-the-development-of-pfas-national-primary-drinking-water-regulation-(npdwr)",
    "url": "/news/ej-considerations-for-the-development-of-pfas-national-primary-drinking-water-regulation-(npdwr)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best Practices in PSM Incident Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-practices-in-psm-incident-management",
    "url": "/news/best-practices-in-psm-incident-management",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best Practices in PSM Incident Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-practices-in-psm-incident-management",
    "url": "/News/Federal/Best-Practices-in-PSM-Incident-Management/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires Texas-Based DiSorbo Consulting",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-texas-based-disorbo-consulting",
    "url": "/news/trinity-acquires-texas-based-disorbo-consulting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest News on Emissions Events from Oil and Gas Sites in the Permian Basin",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-news-on-emissions-events-from-oil-and-gas-sites-in-the-permian-basin",
    "url": "/news/latest-news-on-emissions-events-from-oil-and-gas-sites-in-the-permian-basin",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest News on Emissions Events from Oil and Gas Sites in the Permian Basin",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-news-on-emissions-events-from-oil-and-gas-sites-in-the-permian-basin",
    "url": "/www.trinityconsultants.com/latest-news-on-emissions-events-from-oil-and-gas-sites-in-the-permian-basin",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Auditing Best Practices Part 4: Writing Effective Audit Findings",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-auditing-best-practices-part-4-writing-effective-audit-findings",
    "url": "/news/ehs-auditing-best-practices-part-4-writing-effective-audit-findings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Draft Issued - Georgia Industrial Stormwater General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-draft-issued---georgia-industrial-stormwater-general-permit",
    "url": "/news/final-draft-issued---georgia-industrial-stormwater-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires Soundview Consultants",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-soundview-consultants",
    "url": "/news/trinity-consultants-acquires-soundview-consultants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York State’s GHG Focus - Impact to NY Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-states-ghg-focus-impact-to-ny-industry",
    "url": "/news/new-york-states-ghg-focus-impact-to-ny-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York State’s GHG Focus - Impact to NY Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-states-ghg-focus-impact-to-ny-industry",
    "url": "/news/new-york-state-s-ghg-focus---impact-to-ny-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Triennial Emission Inventories",
    "apiurl": "/restapi/customnewscontentlvl2/news/triennial-emission-inventories",
    "url": "/news/triennial-emission-inventories",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York State DEC Proposes Updates to Part 212 Guidelines DAR-1",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-state-dec-proposes-updates-to-part-212-guidelines-dar-1",
    "url": "/news/new-york-state-dec-proposes-updates-to-part-212-guidelines-dar-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Texas RY2018 Tier II Reporting Updates-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Texas-RY2018-Tier-II-Reporting-Updates-Texas",
    "url": "/news/Texas-RY2018-Tier-II-Reporting-Updates-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge May 2021 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/safebridge-may-2021-newsletter",
    "url": "/news/safebridge-may-2021-newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Adopts New AB 617 Title V Facility Owner Fee and Suspends Other Proposed Fee Increases",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-adopts-new-ab-617-title-v-facility-owner-fee-and-suspends-other-proposed-fee-increases",
    "url": "/news/baaqmd-adopts-new-ab-617-title-v-facility-owner-fee-and-suspends-other-proposed-fee-increases",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Adopts New AB 617 Title V Facility Owner Fee and Suspends Other Proposed Fee Increases",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-adopts-new-ab-617-title-v-facility-owner-fee-and-suspends-other-proposed-fee-increases",
    "url": "/states/baaqmd-adopts-new-ab-617-title-v-facility-owner-fee-and-suspends-other-proposed-fee-increases",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Supreme Court Rules §111(d) Does Not Expressly Authorize EPA to Regulate GHG",
    "apiurl": "/restapi/customnewscontentlvl2/news/supreme-court-rules-111(d)-does-not-expressly-authorize-epa-to-regulate-greenhouse-gases-from-the-us-electrical-generating-grid-by-generation-shifting-now-what",
    "url": "/news/supreme-court-rules-111(d)-does-not-expressly-authorize-epa-to-regulate-greenhouse-gases-from-the-us-electrical-generating-grid-by-generation-shifting-now-what",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREEZE ExDAM Identified as an Example for Advanced Situational Awareness",
    "apiurl": "/restapi/customnewscontentlvl2/news/BREEZE-ExDAM-Identified-as-an-Example-for-Advanced-Situational-Awareness",
    "url": "/news/BREEZE-ExDAM-Identified-as-an-Example-for-Advanced-Situational-Awareness",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Finalized Regulation 84 Now Includes Oil and Gas Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/finalized-regulation-84-now-includes-oil-and-gas-operations",
    "url": "/news/finalized-regulation-84-now-includes-oil-and-gas-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Finalized Regulation 84 Now Includes Oil and Gas Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/finalized-regulation-84-now-includes-oil-and-gas-operations",
    "url": "/news/state/colorado/finalized-regulation-84-now-includes-oil-and-gas-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Auditing Best Practices Part 1: Audit Team Selection",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-auditing-best-practices-part-1-audit-team-selection",
    "url": "/news/ehs-auditing-best-practices-part-1-audit-team-selection",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regional Haze: Utah Finishes 1st Planning Period SIP and Prepares for 2nd Planning Period-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regional-Haze-Utah-Finishes-1st-Planning-Period-SIP-and-Prepares-for-2nd-Planning-Period-Utah",
    "url": "/news/Regional-Haze-Utah-Finishes-1st-Planning-Period-SIP-and-Prepares-for-2nd-Planning-Period-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BOEM Announces the Revised Offshore Air Quality Regulations for Gulf of Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/boem-announces-the-revised-offshore-air-quality-regulations-for-gulf-of-mexico",
    "url": "/news/boem-announces-the-revised-offshore-air-quality-regulations-for-gulf-of-mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BOEM Announces the Revised Offshore Air Quality Regulations for Gulf of Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/boem-announces-the-revised-offshore-air-quality-regulations-for-gulf-of-mexico",
    "url": "/federal/boem-announces-the-revised-offshore-air-quality-regulations-for-gulf-of-mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Will New Controls Be Required for Your Facility in a Serious Ozone Nonattainment Area?-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Will-New-Controls-Be-Required-for-Your-Facility-in-a-Serious-Ozone-Nonattainment-Area--Colorado",
    "url": "/news/Will-New-Controls-Be-Required-for-Your-Facility-in-a-Serious-Ozone-Nonattainment-Area--Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Philadelphia AMS Revises Air Toxics Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/philadelphia-ams-revises-air-toxics-regulations",
    "url": "/news/philadelphia-ams-revises-air-toxics-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Reporting Requirements for Illinois Facilities - January 2019-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Upcoming-Reporting-Requirements-for-Illinois-Facilities---January-2019-Illinois",
    "url": "/news/Upcoming-Reporting-Requirements-for-Illinois-Facilities---January-2019-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Implications of COVID-19 on Method 9 Evaluation Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/implications-of-covid-19-on-method-9-evaluation-requirements",
    "url": "/news/implications-of-covid-19-on-method-9-evaluation-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Air Quality Permit Fees Effective January 16th",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-air-quality-permit-fees-effective-january-16th",
    "url": "/news/padep-air-quality-permit-fees-effective-january-16th",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Quarterly Industrial Stakeholder / Air Permitting Group Meeting Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-quarterly-industrial-stakeholder-air-permitting-group-meeting-updates-2022",
    "url": "/news/njdep-quarterly-industrial-stakeholder-air-permitting-group-meeting-updates-2022",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exploring the Data: Common Tools for EJ Assessments",
    "apiurl": "/restapi/customnewscontentlvl2/news/exploring-the-data-common-tools-for-ej-assessments",
    "url": "/news/exploring-the-data-common-tools-for-ej-assessments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "January 2020 SafeBridge Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/January-2020-Newsletter",
    "url": "/news/January-2020-Newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to MBARD Rule 426 Architectural Coatings",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-revisions-to-mbard-rule-426-architectural-coatings",
    "url": "/news/proposed-revisions-to-mbard-rule-426-architectural-coatings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to MBARD Rule 426 Architectural Coatings",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-revisions-to-mbard-rule-426-architectural-coatings",
    "url": "/news/state/california/proposed-revisions-to-mbard-rule-426-architectural-coatings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Calculating Tank Emissions with TankESP",
    "apiurl": "/restapi/customnewscontentlvl2/news/calculating-tank-emissions-with-tankesp",
    "url": "/news/calculating-tank-emissions-with-tankesp",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Calculating Tank Emissions with TankESP",
    "apiurl": "/restapi/customnewscontentlvl2/news/calculating-tank-emissions-with-tankesp",
    "url": "/News/Technology/Calculating-Tank-Emissions-with-TankESP/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Navigating Net-Zero Commitments",
    "apiurl": "/restapi/customnewscontentlvl2/news/navigating-net-zero-commitments",
    "url": "/news/navigating-net-zero-commitments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Employee Spotlight: Gena Driscoll",
    "apiurl": "/restapi/customnewscontentlvl2/news/employee-spotlight-gena-driscoll",
    "url": "/news/employee-spotlight-gena-driscoll",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires Sespe Consulting",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-sespe-consulting",
    "url": "/news/trinity-acquires-sespe-consulting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RGGI Re-established in New Jersey-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/RGGI-Re-established-in-New-Jersey-New-Jersey",
    "url": "/news/RGGI-Re-established-in-New-Jersey-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed regulation Part 20 – Regulation and Licensing of TENORM",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-regulation-part-20-regulation-and-licensing-of-tenorm-",
    "url": "/news/proposed-regulation-part-20-regulation-and-licensing-of-tenorm-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed regulation Part 20 – Regulation and Licensing of TENORM",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-regulation-part-20-regulation-and-licensing-of-tenorm-",
    "url": "/states/proposed-regulation-part-20-regulation-and-licensing-of-tenorm-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Amendments to PCWP MACT, Including New Emissions Limits and Work Practice Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-amendments-to-pcwp-mact-including-new-emissions-limits-and-work-practice-standards",
    "url": "/news/epa-proposes-amendments-to-pcwp-mact-including-new-emissions-limits-and-work-practice-standards",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Quarterly Industrial Stakeholder / Air Permitting Group Meeting Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-quarterly-industrial-stakeholder-air-permitting-group-meeting-updates",
    "url": "/news/njdep-quarterly-industrial-stakeholder-air-permitting-group-meeting-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tennessee Environmental Reporting Spring 2022",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-reporting-spring-2022",
    "url": "/news/environmental-reporting-spring-2022",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Intelex",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---intelex",
    "url": "/news/partner-spotlight---intelex",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Intelex",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---intelex",
    "url": "/news/technology/partner-spotlight---intelex",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Fee Increases on the Horizon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Fee-Increases-on-the-Horizon-Illinois",
    "url": "/news/Fee-Increases-on-the-Horizon-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Department of Ecology - Proposed HFC Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-department-of-ecology-proposed-hfc-amendments",
    "url": "/news/washington-department-of-ecology-proposed-hfc-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Department of Ecology - Proposed HFC Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-department-of-ecology-proposed-hfc-amendments",
    "url": "/news/washington-department-of-ecology---proposed-hfc-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants and GSCC Develop Global Standard to Measure and Report Carbon Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/gscc-press-release-global-standard-report",
    "url": "/news/gscc-press-release-global-standard-report",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nevada Agency Responses to EPA COVID-19 Temporary Policy",
    "apiurl": "/restapi/customnewscontentlvl2/news/nevada-agency-responses-to-epa-covid-19-temporary-policy",
    "url": "/news/nevada-agency-responses-to-epa-covid-19-temporary-policy",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TRI Back Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/tri-back-reporting",
    "url": "/news/tri-back-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TRI Back Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/tri-back-reporting",
    "url": "/news/federal/tri-back-reporting/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Interim Final Environmental Justice Policy Issued in Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/interim-final-environmental-justice-policy-issued-in-pennsylvania",
    "url": "/news/interim-final-environmental-justice-policy-issued-in-pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Cority",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---cority",
    "url": "/news/partner-spotlight---cority",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight - Cority",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight---cority",
    "url": "/news/partner-spotlight-cority",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado’s Proposed GHG Pollution Reduction Roadmap",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-s-proposed-ghg-pollution-reduction-roadmap",
    "url": "/news/colorado-s-proposed-ghg-pollution-reduction-roadmap",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado’s Proposed GHG Pollution Reduction Roadmap",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-s-proposed-ghg-pollution-reduction-roadmap",
    "url": "/states/colorado/colorado-s-proposed-ghg-pollution-reduction-roadmap",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What the 2015 MSGP Expiration Means and How to Stay in Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-the-2015-msgp-expiration-means-and-how-to-stay-in-compliance",
    "url": "/news/what-the-2015-msgp-expiration-means-and-how-to-stay-in-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What the 2015 MSGP Expiration Means and How to Stay in Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-the-2015-msgp-expiration-means-and-how-to-stay-in-compliance",
    "url": "/federal/what-the-2015-msgp-expiration-means-and-how-to-stay-in-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Amendments to Boiler MACT Including Changes to Emission Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "url": "/news/epa-proposes-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Amendments to Boiler MACT Including Changes to Emission Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "url": "/federal/epa-proposes-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Northern Weld County Colorado Facilities Should Prepare for Stricter Air Quality Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/northern-weld-county-colorado-facilities-should-prepare-for-stricter-air-quality-regulations",
    "url": "/news/northern-weld-county-colorado-facilities-should-prepare-for-stricter-air-quality-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts EJ Update on Impacts for Permitting Projects",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-ej-update-on-impacts-for-permitting-projects",
    "url": "/news/massachusetts-ej-update-on-impacts-for-permitting-projects",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County Proposes Rulemaking for Emission Offsets Generated By Voluntary Mobile Source Emission Reduction Credits (MERCs)",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-proposes-rulemaking-for-emission-offsets-generated-by-voluntary-mobile-source-emission-reduction-credits-(mercs)",
    "url": "/news/maricopa-county-proposes-rulemaking-for-emission-offsets-generated-by-voluntary-mobile-source-emission-reduction-credits-(mercs)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Meetings Scheduled to Discuss SCAQMD/CARB Emission Reduction Plans",
    "apiurl": "/restapi/customnewscontentlvl2/news/meetings-scheduled-to-discuss-scaqmd-carb-emission-reduction-plans",
    "url": "/news/meetings-scheduled-to-discuss-scaqmd-carb-emission-reduction-plans",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Meetings Scheduled to Discuss SCAQMD/CARB Emission Reduction Plans",
    "apiurl": "/restapi/customnewscontentlvl2/news/meetings-scheduled-to-discuss-scaqmd-carb-emission-reduction-plans",
    "url": "/news/state/california/meetings-scheduled-to-discuss-scaqmd-carb-emission-reduction-plans",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDDEQ Outlines Compliance Requirements for Condensate Truck Loadout Emissions-North Dakota",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDDEQ-Outlines-Compliance-Requirements-for-Condensate-Truck-Loadout-Emissions-North-Dakota",
    "url": "/news/NDDEQ-Outlines-Compliance-Requirements-for-Condensate-Truck-Loadout-Emissions-North-Dakota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "U.S. EPA Finalizes El Paso County Ozone Nonattainment Designation As Marginal Nonattainment",
    "apiurl": "/restapi/customnewscontentlvl2/news/u.s.-epa-finalizes-el-paso-county-ozone-nonattainment-designation-as-marginal-nonattainment",
    "url": "/news/u.s.-epa-finalizes-el-paso-county-ozone-nonattainment-designation-as-marginal-nonattainment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New NY Policy DEP-23-1 Will Require Disadvantaged Communities Impact Analysis for All Major Environmental Permits",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-ny-policy-dep-23-1-will-require-disadvantaged-communities-impact-analysis-for-all-major-environmental-permits",
    "url": "/news/new-ny-policy-dep-23-1-will-require-disadvantaged-communities-impact-analysis-for-all-major-environmental-permits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ’s 2019 Proposed Rule Summary for Stationary Sources-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/UDAQ-s-2019-Proposed-Rule-Summary-for-Stationary-Sources-Utah",
    "url": "/news/UDAQ-s-2019-Proposed-Rule-Summary-for-Stationary-Sources-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Online Dust Control Permitting Portal for Sources in Clark County",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-online-dust-control-permitting-portal-for-sources-in-clark-county",
    "url": "/news/new-online-dust-control-permitting-portal-for-sources-in-clark-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Online Dust Control Permitting Portal for Sources in Clark County",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-online-dust-control-permitting-portal-for-sources-in-clark-county",
    "url": "/www.trinityconsultants.com/news/new-online-dust-control-permitting-portal-for-sources-in-clark-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado Rulemaking for Class B Firefighting Foam with PFAS",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-rulemaking-for-class-b-firefighting-foam-with-pfas",
    "url": "/news/colorado-rulemaking-for-class-b-firefighting-foam-with-pfas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Quarterly Industrial Stakeholders/Air Permitting Group Meeting Updates-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/NJDEP-Quarterly-Industrial-Stakeholders-Air-Permitting-Group-Meeting-Updates-New-Jersey",
    "url": "/news/NJDEP-Quarterly-Industrial-Stakeholders-Air-Permitting-Group-Meeting-Updates-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants’ Digital Solutions Team Expands its Footprint in the UK & Ireland",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-digital-solutions-team-expands-its-footprint-in-the-uk-ireland",
    "url": "/news/trinity-consultants-digital-solutions-team-expands-its-footprint-in-the-uk-ireland",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Lifts Stay on Turbine NESHAP: Risks for Post-2003 Turbines at Major HAP Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-lifts-stay-on-turbine-neshap-risks-for-post-2003-turbines-at-major-hap-sources",
    "url": "/news/epa-lifts-stay-on-turbine-neshap-risks-for-post-2003-turbines-at-major-hap-sources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Lifts Stay on Turbine NESHAP: Risks for Post-2003 Turbines at Major HAP Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-lifts-stay-on-turbine-neshap-risks-for-post-2003-turbines-at-major-hap-sources",
    "url": "/News/Federal/EPA-Lifts-Stay-on-Turbine-NESHAP--Risks-for-Post-2003-Turbines-at-Major-HAP-Sources/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Montana DEQ COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/montana-deq-covid-19-response",
    "url": "/news/montana-deq-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ: Alternative Language Requirements Extend to Public Meetings",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-alternative-language-requirements-extend-to-public-meetings",
    "url": "/news/tceq-alternative-language-requirements-extend-to-public-meetings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Professionals Leading the Way on COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-professionals-leading-the-way-on-covid-19-",
    "url": "/news/ehs-professionals-leading-the-way-on-covid-19-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Professionals Leading the Way on COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-professionals-leading-the-way-on-covid-19-",
    "url": "/news/federal/Technology/EHS-Professionals-Leading-the-Way-on-COVID-19/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Professionals Leading the Way on COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-professionals-leading-the-way-on-covid-19-",
    "url": "/Technology/EHS-Professionals-Leading-the-Way-on-COVID-19/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Proposes Long-Anticipated Environmental Justice Regulation",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-proposes-long-anticipated-environmental-justice-regulation",
    "url": "/news/njdep-proposes-long-anticipated-environmental-justice-regulation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Does Your SPCC Plan Make the Grade? Lessons Learned from EPA Inspections-South Carolina",
    "apiurl": "/restapi/customnewscontentlvl2/news/Does-Your-SPCC-Plan-Make-the-Grade-Lessons-Learned-from-EPA-Inspections-South-Carolina",
    "url": "/news/Does-Your-SPCC-Plan-Make-the-Grade-Lessons-Learned-from-EPA-Inspections-South-Carolina",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Renews PAG-03 for Stormwater Discharges Associated with Industrial Activities in Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-renews-pag-03-for-stormwater-discharges-associated-with-industrial-activities-in-pennsylvania",
    "url": "/news/padep-renews-pag-03-for-stormwater-discharges-associated-with-industrial-activities-in-pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Look, Expanded Expertise, Continued Commitment to Clients",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-look-expanded-expertise-continued-commitment-to-clients",
    "url": "/news/new-look-expanded-expertise-continued-commitment-to-clients",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Look, Expanded Expertise, Continued Commitment to Clients",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-look-expanded-expertise-continued-commitment-to-clients",
    "url": "/news/company/new-look-expanded-expertise-continued-commitment-to-clients",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Option for Tank Rule NSPS Subpart Kb",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-option-for-tank-rule-nsps-subpart-kb",
    "url": "/news/new-option-for-tank-rule-nsps-subpart-kb",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Recent PFAS Related Activity in Canada",
    "apiurl": "/restapi/customnewscontentlvl2/news/recent-pfas-related-activity-in-canada",
    "url": "/news/recent-pfas-related-activity-in-canada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Receives Outstanding Client Satisfaction Scores",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-receives-outstanding-client-satisfaction-scores",
    "url": "/news/trinity-receives-outstanding-client-satisfaction-scores",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Updates on Tier II Chemical Reporting Process for Reporting Year 2019-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/TCEQ-Updates-on-Tier-II-Chemical-Reporting-Process-for-Reporting-Year-2019-Texas",
    "url": "/news/TCEQ-Updates-on-Tier-II-Chemical-Reporting-Process-for-Reporting-Year-2019-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "How Changes to Federal Regulations are Incorporated into Your Minnesota Air Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-federal-regulations-minnesota-air-permit",
    "url": "/news/changes-to-federal-regulations-minnesota-air-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WOTUS – EPA and Corps of Engineers Issue the Latest Final Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/wotus-epa-and-corps-of-engineers-issue-the-latest-final-rule",
    "url": "/news/wotus-epa-and-corps-of-engineers-issue-the-latest-final-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PFAS Update: EPA Proposes Restrictive Drinking Water Standards for PFAS",
    "apiurl": "/restapi/customnewscontentlvl2/news/pfas-update-epa-proposes-restrictive-drinking-water-standards-for-pfas",
    "url": "/news/pfas-update-epa-proposes-restrictive-drinking-water-standards-for-pfas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TankESPTip - How to locate the backend file and load it into BREEZE TankESP Version 5.0",
    "apiurl": "/restapi/customnewscontentlvl2/news/TankESPTip---How-to-locate-the-backend-file-and-load-it-into-BREEZE-TankESP-Version-5-0",
    "url": "/news/TankESPTip---How-to-locate-the-backend-file-and-load-it-into-BREEZE-TankESP-Version-5-0",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part I: The Impact of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-impact-of-pha-inconsistencies",
    "url": "/news/the-impact-of-pha-inconsistencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part I: The Impact of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-impact-of-pha-inconsistencies",
    "url": "/news/federal/the-impact-of-pha-inconsistencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part I: The Impact of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-impact-of-pha-inconsistencies",
    "url": "/federal/the-impact-of-pha-inconsistencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Federal Regulation for Storage Tanks and Loading Operations in Canada",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-federal-regulation-for-storage-tanks-and-loading-operations-in-canada",
    "url": "/news/new-federal-regulation-for-storage-tanks-and-loading-operations-in-canada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Federal Regulation for Storage Tanks and Loading Operations in Canada",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-federal-regulation-for-storage-tanks-and-loading-operations-in-canada",
    "url": "/www.trinityconsultants.com/news/new-federal-regulation-for-storage-tanks-and-loading-operations-in-canada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "State-Focused Environmental Reporting Resources for RY2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/state-focused-environmental-reporting-resources-for-ry2020",
    "url": "/news/state-focused-environmental-reporting-resources-for-ry2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "State-Focused Environmental Reporting Resources for RY2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/state-focused-environmental-reporting-resources-for-ry2020",
    "url": "/news/state-focused-environmental-reporting-webinars-for-ry2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "West Pinal County PM10 Nonattainment Area Moves from Moderate to Serious",
    "apiurl": "/restapi/customnewscontentlvl2/news/west-pinal-county-pm10-nonattainment-area-moves-from-moderate-to-serious",
    "url": "/news/west-pinal-county-pm10-nonattainment-area-moves-from-moderate-to-serious",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "West Pinal County PM10 Nonattainment Area Moves from Moderate to Serious",
    "apiurl": "/restapi/customnewscontentlvl2/news/west-pinal-county-pm10-nonattainment-area-moves-from-moderate-to-serious",
    "url": "/www.trinityconsultants.com/news/west-pinal-county-pm10-nonattainment-area-moves-from-moderate-to-serious",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ Proposes Maintenance Plans for PM2.5 Nonattainment Areas-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/UDAQ-Proposes-Maintenance-Plans-for-PM2-5-Nonattainment-Areas-Utah",
    "url": "/news/UDAQ-Proposes-Maintenance-Plans-for-PM2-5-Nonattainment-Areas-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California’s Low Carbon Fuel Standard Has Far-Reaching Impacts",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-s-low-carbon-fuel-standard-has-far-reaching-impacts",
    "url": "/news/california-s-low-carbon-fuel-standard-has-far-reaching-impacts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised Air Permitting Guidance Finalized for Oil and Gas Production-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Revised-Air-Permitting-Guidance-Finalized-for-Oil-and-Gas-Production-Wyoming",
    "url": "/news/Revised-Air-Permitting-Guidance-Finalized-for-Oil-and-Gas-Production-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR Fee Advisory Group Meeting-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-Fee-Advisory-Group-Meeting-Iowa",
    "url": "/news/IDNR-Fee-Advisory-Group-Meeting-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Updates to Colorado Regulation 26: Statewide Requirements for Diesel and Natural Gas-Fired Engines",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-updates-to-colorado-regulation-26-statewide-requirements-for-diesel-and-natural-gas-fired-engines",
    "url": "/news/proposed-updates-to-colorado-regulation-26-statewide-requirements-for-diesel-and-natural-gas-fired-engines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes Proposed to Statewide Air Emission Inventory Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-proposed-to-statewide-air-emission-inventory-requirements",
    "url": "/news/changes-proposed-to-statewide-air-emission-inventory-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes Proposed to Statewide Air Emission Inventory Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-proposed-to-statewide-air-emission-inventory-requirements",
    "url": "/news/ab-2588-eicg-ab-617-ctr-proposed-15-day-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Leveraging Technology for Compliance: NMED's Guidance on Data Management for Oil and Gas Facilities Subject to the Ozone Precursor Rule (20.2.50 NMAC)",
    "apiurl": "/restapi/customnewscontentlvl2/news/leveraging-technology-for-compliance-nmed's-guidance-on-data-management-for-oil-and-gas-facilities",
    "url": "/news/leveraging-technology-for-compliance-nmed's-guidance-on-data-management-for-oil-and-gas-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes First-Ever GHG Emissions Standards for Newly Manufactured Aircraft",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-first-ever-ghg-emissions-standards-for-newly-manufactured-aircraft",
    "url": "/news/epa-proposes-first-ever-ghg-emissions-standards-for-newly-manufactured-aircraft",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes First-Ever GHG Emissions Standards for Newly Manufactured Aircraft",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-first-ever-ghg-emissions-standards-for-newly-manufactured-aircraft",
    "url": "/news/federal/epa-proposes-first-ever-ghg-emissions-standards-for-newly-manufactured-aircraft",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Takeaways from the Biden Executive Order on Climate-Related Financial Risk",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-takeaways-from-the-biden-executive-order-on-climate-related-financial-risk",
    "url": "/news/ehs-takeaways-from-the-biden-executive-order-on-climate-related-financial-risk",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah Proposes New Controls on HMA Plants and Boilers",
    "apiurl": "/restapi/customnewscontentlvl2/news/utah-proposes-new-controls-on-hma-plants-and-boilers",
    "url": "/news/utah-proposes-new-controls-on-hma-plants-and-boilers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario MECP to Regulate Odor Mixtures",
    "apiurl": "/restapi/customnewscontentlvl2/news/ontario-mecp-to-regulate-odour-mixtures",
    "url": "/news/ontario-mecp-to-regulate-odour-mixtures",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Jersey County Second Maintenance Plan for the 1997 Ozone National Ambient Air Quality Standard-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Jersey-County-Second-Maintenance-Plan-for-the-1997-Ozone-National-Ambient-Air-Quality-Standard-Illinois",
    "url": "/news/Jersey-County-Second-Maintenance-Plan-for-the-1997-Ozone-National-Ambient-Air-Quality-Standard-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Draft of Revised Ozone NAAQS Policy Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-draft-revised-ozone-naaqs-policy-assessment",
    "url": "/news/epa-issues-draft-revised-ozone-naaqs-policy-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Updating Toxics Release Inventory Reporting to Advance Environmental Justice",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-updating-toxics-release-inventory-reporting-to-advance-environmental-justice",
    "url": "/news/epa-updating-toxics-release-inventory-reporting-to-advance-environmental-justice",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Submitting Proposed Amendments to District Rule 4601",
    "apiurl": "/restapi/customnewscontentlvl2/news/submitting-proposed-amendments-to-district-rule-4601-",
    "url": "/news/submitting-proposed-amendments-to-district-rule-4601-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Adopts Non-Rule Standard Permit for Marine Loading Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-adopts-non-rule-standard-permit-for-marine-loading-operations",
    "url": "/news/tceq-adopts-non-rule-standard-permit-for-marine-loading-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP Proposes Substantial Rule Changes for Control Requirements at Petroleum Storage Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-dep-proposes-substantial-rule-changes-for-control-requirements-at-petroleum-storage-facilities",
    "url": "/news/maine-dep-proposes-substantial-rule-changes-for-control-requirements-at-petroleum-storage-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MCAQD Updates to Rule 331: Solvent Cleaning",
    "apiurl": "/restapi/customnewscontentlvl2/news/mcaqd-updates-to-rule-331-solvent-cleaning",
    "url": "/news/mcaqd-updates-to-rule-331-solvent-cleaning",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois EPA to Begin Testing all Illinois Community Water Supplies for PFAS Substances",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-epa-to-begin-testing-all-illinois-community-water-supplies-for-pfas-substances",
    "url": "/news/illinois-epa-to-begin-testing-all-illinois-community-water-supplies-for-pfas-substances",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois EPA to Begin Testing all Illinois Community Water Supplies for PFAS Substances",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-epa-to-begin-testing-all-illinois-community-water-supplies-for-pfas-substances",
    "url": "/states/illinois/illinois-epa-to-begin-testing-all-illinois-community-water-supplies-for-pfas-substances",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "San Diego APCD Proposes Amendments to Architectural Coatings Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/san-diego-apcd-proposes-amendments-to-architectural-coatings-rule",
    "url": "/news/san-diego-apcd-proposes-amendments-to-architectural-coatings-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "San Diego APCD Proposes Amendments to Architectural Coatings Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/san-diego-apcd-proposes-amendments-to-architectural-coatings-rule",
    "url": "/news/states/california/san-diego-apcd-proposes-amendments-to-architectural-coatings-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Common Findings in Health and Safety Compliance Audits",
    "apiurl": "/restapi/customnewscontentlvl2/news/common-findings-in-health-and-safety-compliance-audits-",
    "url": "/news/common-findings-in-health-and-safety-compliance-audits-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Common Findings in Health and Safety Compliance Audits",
    "apiurl": "/restapi/customnewscontentlvl2/news/common-findings-in-health-and-safety-compliance-audits-",
    "url": "/new/ehs-management/common-findings-in-health-and-safety-compliance-audits-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Common Findings in Health and Safety Compliance Audits",
    "apiurl": "/restapi/customnewscontentlvl2/news/common-findings-in-health-and-safety-compliance-audits-",
    "url": "/news/federal/common-findings-in-health-and-safety-compliance-audits-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Publishes Proposed Texas SIP Revision in the Federal Register to Allow Construction Prior to Permit Amendment Issuance",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-publishes-proposed-texas-sip-revision-in-the-federal-register-to-allow-construction-prior-to-permit-amendment-issuance",
    "url": "/news/epa-publishes-proposed-texas-sip-revision-in-the-federal-register-to-allow-construction-prior-to-permit-amendment-issuance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tier 5 Workshop: Potential Amendments to the Off-Road New Diesel Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/tier-5-workshop-potential-amendments-to-the-off-road-new-diesel-regulations",
    "url": "/news/tier-5-workshop-potential-amendments-to-the-off-road-new-diesel-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Mobile Source GHG Regulations: Past and Present",
    "apiurl": "/restapi/customnewscontentlvl2/news/mobile-source-ghg-regulations-past-and-present",
    "url": "/news/mobile-source-ghg-regulations-past-and-present",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Mobile Source GHG Regulations: Past and Present",
    "apiurl": "/restapi/customnewscontentlvl2/news/mobile-source-ghg-regulations-past-and-present",
    "url": "/www.trinityconsultants.com/news/mobile-source-ghg-regulations-past-and-present",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Environmental Quality Commission Adopts Amendments to Greenhouse Gas Reporting and Clean Fuels Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-environmental-quality-commission-adopts-amendments-to-greenhouse-gas-reporting-and-clean-fuels-program-",
    "url": "/news/oregon-environmental-quality-commission-adopts-amendments-to-greenhouse-gas-reporting-and-clean-fuels-program-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Toxics Release Inventory (TRI) Tips and Tricks",
    "apiurl": "/restapi/customnewscontentlvl2/news/toxics-release-inventory-(tri)-tips-and-tricks",
    "url": "/news/toxics-release-inventory-(tri)-tips-and-tricks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final RMP Reconsideration Rule Now in Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rmp-reconsideration-rule-now-in-effect",
    "url": "/news/final-rmp-reconsideration-rule-now-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final RMP Reconsideration Rule Now in Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rmp-reconsideration-rule-now-in-effect",
    "url": "/federal/final-rmp-reconsideration-rule-now-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final RMP Reconsideration Rule Now in Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rmp-reconsideration-rule-now-in-effect",
    "url": "/news/federal/final-rmp-reconsideration-rule-now-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RACT III is on the Horizon in Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/ract-iii-is-on-the-horizon-in-pennsylvania",
    "url": "/news/ract-iii-is-on-the-horizon-in-pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA TRI Inquiries-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-TRI-Inquiries-Nebraska",
    "url": "/news/EPA-TRI-Inquiries-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emergency Response and Contingency Plans - Individual or Integrated",
    "apiurl": "/restapi/customnewscontentlvl2/news/emergency-response-and-contingency-plans---individual-or-integrated",
    "url": "/news/emergency-response-and-contingency-plans---individual-or-integrated",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emergency Response and Contingency Plans - Individual or Integrated",
    "apiurl": "/restapi/customnewscontentlvl2/news/emergency-response-and-contingency-plans---individual-or-integrated",
    "url": "/news/state/kentucky/emergency-response-and-contingency-plans---individual-or-integrated",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Hearing for Revisions to MCAQD Rules 350, 351, 352 and 353",
    "apiurl": "/restapi/customnewscontentlvl2/news/public-hearing-for-revisions-to-mcaqd-rules-350-351-352-and-353",
    "url": "/news/public-hearing-for-revisions-to-mcaqd-rules-350-351-352-and-353",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Hearing for Revisions to MCAQD Rules 350, 351, 352 and 353",
    "apiurl": "/restapi/customnewscontentlvl2/news/public-hearing-for-revisions-to-mcaqd-rules-350-351-352-and-353",
    "url": "/news/states/arizona/public-hearing-for-revisions-to-mcaqd-rules-350-351-352-and-353",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Compliance Still Required with California Method 9 Evaluations",
    "apiurl": "/restapi/customnewscontentlvl2/news/compliance-still-required-with-california-method-9-evaluations",
    "url": "/news/compliance-still-required-with-california-method-9-evaluations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Final Rule to Approve Part of Illinois Infrastructure SIP for 2012 PM2.5 NAAQS-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Issues-Final-Rule-to-Approve-Part-of-Illinois-Infrastructure-SIP-for-2012-PM2-5-NAAQS-Illinois",
    "url": "/news/EPA-Issues-Final-Rule-to-Approve-Part-of-Illinois-Infrastructure-SIP-for-2012-PM2-5-NAAQS-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Reporting Requirements-CARB AB617 Criteria Pollutant and Toxics Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-reporting-requirements-carb-ab617-criteria-pollutant-and-toxics-emission",
    "url": "/news/upcoming-reporting-requirements-carb-ab617-criteria-pollutant-and-toxics-emission",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Reporting Requirements-CARB AB617 Criteria Pollutant and Toxics Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-reporting-requirements-carb-ab617-criteria-pollutant-and-toxics-emission",
    "url": "/news/upcoming-reporting-requirements-for-california-air-resources-board-s-ab617-criteria-pollutant-and-toxics-emissions-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Regulation 3 Fee Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-regulation-3-fee-amendments",
    "url": "/news/baaqmd-accepting-comments-for-regulation-3-fee-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The SSM Saga Continues",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-ssm-saga-continues",
    "url": "/news/the-ssm-saga-continues",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 5 - EPA Sharpens Focus through Environmental Justice Lens",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-5---epa-sharpens-focus-through-environmental-justice-lens",
    "url": "/news/ehs-quarterly-issue-5---epa-sharpens-focus-through-environmental-justice-lens",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 5 - EPA Sharpens Focus through Environmental Justice Lens",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-5---epa-sharpens-focus-through-environmental-justice-lens",
    "url": "/news/ehs-quarterly-issue-5-epa-sharpens-focus-through-environmental-justice-lens",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Adopts Amendments to Regulation 2: Permits",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-adopts-amendments-to-regulation-2-permits",
    "url": "/news/baaqmd-adopts-amendments-to-regulation-2-permits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity’s Atlanta Office Permits Cinnamon Toast Crunch Expansion",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinitys-atlanta-office-permits-cinnamon-toast-crunch-expansion",
    "url": "/news/trinitys-atlanta-office-permits-cinnamon-toast-crunch-expansion",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity’s Atlanta Office Permits Cinnamon Toast Crunch Expansion",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinitys-atlanta-office-permits-cinnamon-toast-crunch-expansion",
    "url": "/news/trinity-s-atlanta-office-permits-cinnamon-toast-crunch-expansion",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to Maricopa County Air Quality Department Rule 336 (Surface Coating Operations)",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-maricopa-county-air-quality-department-rule-336-surface-coating-operations",
    "url": "/news/updates-to-maricopa-county-air-quality-department-rule-336-surface-coating-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Applications for High Mileage Fleet Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-applications-for-high-mileage-fleet-program",
    "url": "/news/baaqmd-accepting-applications-for-high-mileage-fleet-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Why You Can’t Trust Your TRI Template – Especially in the Year of COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/why-you-can-t-trust-your-tri-template-especially-in-the-year-of-covid-19",
    "url": "/news/why-you-can-t-trust-your-tri-template-especially-in-the-year-of-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed HFC Phasedown under the AIM Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-hfc-phasedown-under-the-aim-act",
    "url": "/news/proposed-hfc-phasedown-under-the-aim-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New TCEQ NSR Application and PBR Assessment Workbooks",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-tceq-nsr-application-and-pbr-assessment-workbooks",
    "url": "/news/new-tceq-nsr-application-and-pbr-assessment-workbooks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD nominates Richmond-San Pablo for Year 3 CARB Community Air Protection Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-nominates-richmond-san-pablo-for-year-3-carb-community-air-protection-program",
    "url": "/news/baaqmd-nominates-richmond-san-pablo-for-year-3-carb-community-air-protection-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD nominates Richmond-San Pablo for Year 3 CARB Community Air Protection Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-nominates-richmond-san-pablo-for-year-3-carb-community-air-protection-program",
    "url": "/states/california/baaqmd-nominates-richmond-san-pablo-for-year-3-carb-community-air-protection-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Hydrofluorocarbon Regulation Outlook",
    "apiurl": "/restapi/customnewscontentlvl2/news/hydrofluorocarbon-regulation-outlook",
    "url": "/news/hydrofluorocarbon-regulation-outlook",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Hydrofluorocarbon Regulation Outlook",
    "apiurl": "/restapi/customnewscontentlvl2/news/hydrofluorocarbon-regulation-outlook",
    "url": "/www.trinityconsultants.com/news/hydrofluorocarbon-regulation-outlook",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to Permit Fees",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-permit-fees",
    "url": "/news/proposed-amendments-to-permit-fees",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Issues Multiple Potpourris for Various Air Regulations-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Issues-Multiple-Potpourris-for-Various-Air-Regulations-Louisiana",
    "url": "/news/LDEQ-Issues-Multiple-Potpourris-for-Various-Air-Regulations-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CAO Hazard Index Proposed Rule Available for Public Comment-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/CAO-Hazard-Index-Proposed-Rule-Available-for-Public-Comment-Oregon",
    "url": "/news/CAO-Hazard-Index-Proposed-Rule-Available-for-Public-Comment-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Files a Notice of Preliminary Rulemaking (NPRM) for New Surface Water Protection Program (SWPP)",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-files-a-notice-of-preliminary-rulemaking-(nprm)-for-new-surface-water-protection-program-(swpp)",
    "url": "/news/adeq-files-a-notice-of-preliminary-rulemaking-(nprm)-for-new-surface-water-protection-program-(swpp)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah DEQ Updating Its ISIP to Include Use of AERMOD-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Utah-DEQ-Updating-Its-ISIP-to-Include-Use-of-AERMOD-Utah",
    "url": "/news/Utah-DEQ-Updating-Its-ISIP-to-Include-Use-of-AERMOD-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois Environmental Regulatory Group Air Seminar-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Illinois-Environmental-Regulatory-Group-Air-Seminar-Illinois",
    "url": "/news/Illinois-Environmental-Regulatory-Group-Air-Seminar-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Proposed Rulemaking for Climate Protection Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-proposed-rulemaking-for-climate-protection-program",
    "url": "/news/oregon-proposed-rulemaking-for-climate-protection-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reclassification of Connecticut for the 2008 Ozone National Ambient Air Quality Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reclassification-of-Connecticut-for-the-2008-Ozone-National-Ambient-Air-Quality-Standards-Connecticut",
    "url": "/news/Reclassification-of-Connecticut-for-the-2008-Ozone-National-Ambient-Air-Quality-Standards-Connecticut",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Guidance Document Updates and New District Office Map-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/KDHE-Guidance-Document-Updates-and-New-District-Office-Map-Kansas",
    "url": "/news/KDHE-Guidance-Document-Updates-and-New-District-Office-Map-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Seeking Comment on National Compliance & Enforcement Initiatives (NCEI) for FY2024-2027",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-seeking-comment-on-national-compliance-enforcement-initiatives-(ncei)-for-fy2024-2027",
    "url": "/news/epa-seeking-comment-on-national-compliance-enforcement-initiatives-(ncei)-for-fy2024-2027",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity and Its Clients Support COVID Relief Efforts",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-and-its-clients-support-covid-relief-efforts",
    "url": "/news/trinity-and-its-clients-support-covid-relief-efforts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Publishes Draft 2020 303(d) List of Impaired Waters",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-publishes-draft-2020-303(d)-list-of-impaired-waters",
    "url": "/news/kdhe-publishes-draft-2020-303(d)-list-of-impaired-waters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Publishes Draft 2020 303(d) List of Impaired Waters",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-publishes-draft-2020-303(d)-list-of-impaired-waters",
    "url": "/states/kansas/kdhe-publishes-draft-2020-303d-list-of-impaired-waters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sneak Peek into NY Climate Action Council’s Proposed Scoping Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/sneak-peek-into-ny-climate-action-council-s-proposed-scoping-plan",
    "url": "/news/sneak-peek-into-ny-climate-action-council-s-proposed-scoping-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bay Area AQMD Proposes Risk Management Methodology for Fine Particulate Matter (PM2.5)",
    "apiurl": "/restapi/customnewscontentlvl2/news/bay-area-aqmd-proposes-risk-management-methodology-for-fine-particulate-matter-(pm2.5)",
    "url": "/news/bay-area-aqmd-proposes-risk-management-methodology-for-fine-particulate-matter-(pm2.5)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Is Your Upstream E&P Facility in Compliance with SPCC Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/is-your-upstream-e-p-facility-in-compliance-with-spcc-requirements",
    "url": "/news/is-your-upstream-e-p-facility-in-compliance-with-spcc-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Is Your Upstream E&P Facility in Compliance with SPCC Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/is-your-upstream-e-p-facility-in-compliance-with-spcc-requirements",
    "url": "/federal/is-your-upstream-e-p-facility-in-compliance-with-spcc-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "US EPA Proposes to Approve Illinois’ Sunsetting of ERMS Program-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/US-EPA-Proposes-to-Approve-Illinois-Sunsetting-of-ERMS-Program-Illinois",
    "url": "/news/US-EPA-Proposes-to-Approve-Illinois-Sunsetting-of-ERMS-Program-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bexar County Ozone Nonattainment Area Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/bexar-county-ozone-nonattainment-area-updates",
    "url": "/news/bexar-county-ozone-nonattainment-area-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Kentucky Office Announcement – Visible Emissions Certified-Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/Kentucky-Office-Announcement-Visible-Emissions-Certified-Kentucky",
    "url": "/news/Kentucky-Office-Announcement-Visible-Emissions-Certified-Kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Texas Areas’ Proposed Ozone Nonattainment Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/texas-areas-proposed-ozone-nonattainment-reclassification",
    "url": "/news/texas-areas-proposed-ozone-nonattainment-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Executive Order Requires EPA to Establish Website for Guidance Documents Currently in Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/executive-order-requires-epa-to-establish-website-for-guidance-documents-currently-in-effect",
    "url": "/news/executive-order-requires-epa-to-establish-website-for-guidance-documents-currently-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Executive Order Requires EPA to Establish Website for Guidance Documents Currently in Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/executive-order-requires-epa-to-establish-website-for-guidance-documents-currently-in-effect",
    "url": "/federal/executive-order-requires-epa-to-establish-website-for-guidance-documents-currently-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Requirements Proposed in EPAs Draft 2020 MSGP",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-requirements-proposed-in-epas-draft-2020-msgp",
    "url": "/news/new-requirements-proposed-in-epas-draft-2020-msgp",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Requirements Proposed in EPAs Draft 2020 MSGP",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-requirements-proposed-in-epas-draft-2020-msgp",
    "url": "/federal/new-requirements-proposed-in-epa-s-draft-2020-msgp",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Wyoming’s 5-Year Ambient Air Monitoring Network Assessment Available for Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/wyoming-s-5-year-ambient-air-monitoring-network-assessment-available-for-comment",
    "url": "/news/wyoming-s-5-year-ambient-air-monitoring-network-assessment-available-for-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Changes to Georgia’s NPDES Industrial Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-changes-to-georgia-s-npdes-industrial-stormwater-permit",
    "url": "/news/upcoming-changes-to-georgia-s-npdes-industrial-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Plan Approval: Revisions to VOC Definitions and Ambient Air Quality Standards-Georgia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Air-Plan-Approval-Revisions-to-VOC-Definitions-and-Ambient-Air-Quality-Standards-Georgia",
    "url": "/news/Air-Plan-Approval-Revisions-to-VOC-Definitions-and-Ambient-Air-Quality-Standards-Georgia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WDNR Debuts New Way to Track Air Permit Progress-Wisconsin",
    "apiurl": "/restapi/customnewscontentlvl2/news/WDNR-Debuts-New-Way-to-Track-Air-Permit-Progress-Wisconsin",
    "url": "/news/WDNR-Debuts-New-Way-to-Track-Air-Permit-Progress-Wisconsin",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Updates to Missouri Air Regulations-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Updates-to-Missouri-Air-Regulations-Missouri",
    "url": "/news/Proposed-Updates-to-Missouri-Air-Regulations-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Proposed Issuance, with Change, to the NPDES General Permit NER920000",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndee-proposed-issuance-with-change-to-the-npdes-general-permit-ner920000",
    "url": "/news/ndee-proposed-issuance-with-change-to-the-npdes-general-permit-ner920000",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Proposed Issuance, with Change, to the NPDES General Permit NER920000",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndee-proposed-issuance-with-change-to-the-npdes-general-permit-ner920000",
    "url": "/news/draft-2021-ambient-air-quality-monitoring-network-plan-published-by-ndee",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Rule 4354, Glass Melting Furnaces, Public Scoping Meeting",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-rule-4354-glass-melting-furnaces-public-scoping-meeting",
    "url": "/news/sjvapcd-rule-4354-glass-melting-furnaces-public-scoping-meeting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft Emergency Engine to Demand Response Engine FAQs-Connecticut",
    "apiurl": "/restapi/customnewscontentlvl2/news/Draft-Emergency-Engine-to-Demand-Response-Engine-FAQs-Connecticut",
    "url": "/news/Draft-Emergency-Engine-to-Demand-Response-Engine-FAQs-Connecticut",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Finalizes CO2 Budget Trading Program as Virginia Joins RGGI",
    "apiurl": "/restapi/customnewscontentlvl2/news/deq-finalizes-co2-budget-trading-program-as-virginia-joins-rggi",
    "url": "/news/deq-finalizes-co2-budget-trading-program-as-virginia-joins-rggi",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OMB Guidance for the EPA Regarding COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/omb-guidance-for-the-epa-regarding-covid-19",
    "url": "/news/omb-guidance-for-the-epa-regarding-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OMB Guidance for the EPA Regarding COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/omb-guidance-for-the-epa-regarding-covid-19",
    "url": "/federal/omb-guidance-for-the-epa-regarding-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Is your Facility Prepared to Submit its CY2022 CMP?",
    "apiurl": "/restapi/customnewscontentlvl2/news/is-your-facility-prepared-to-submit-its-cy2022-cmp",
    "url": "/news/is-your-facility-prepared-to-submit-its-cy2022-cmp",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Publishes On-Site Inspection Procedures",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-publishes-on-site-inspection-procedures",
    "url": "/news/epa-publishes-on-site-inspection-procedures",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Publishes On-Site Inspection Procedures",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-publishes-on-site-inspection-procedures",
    "url": "/federal/epa-publishes-on-site-inspection-procedures",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Risk Management Program Safer Communities by Chemical Accident Prevention Proposed Rule Highlights",
    "apiurl": "/restapi/customnewscontentlvl2/news/risk-management-program-safer-communities-by-chemical-accident-prevention-proposed-rule-highlights",
    "url": "/news/risk-management-program-safer-communities-by-chemical-accident-prevention-proposed-rule-highlights",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "San Diego APCD Restricts Stationary Engine Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/san-diego-apcd-restricts-stationary-engine-emissions",
    "url": "/news/san-diego-apcd-restricts-stationary-engine-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Adds 12 New Chemicals for TRI Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-adds-12-new-chemicals-for-tri-reporting",
    "url": "/news/epa-adds-12-new-chemicals-for-tri-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ's New Non-Rule Air Quality Standard Permit for Marine Loading Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceqs-new-non-rule-air-quality-standard-permit-for-marine-loading-operations",
    "url": "/news/tceqs-new-non-rule-air-quality-standard-permit-for-marine-loading-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sustainability Reporting Guidance for Oil & Gas Industry – 4th Edition released by IPIECA, API, and IOGP",
    "apiurl": "/restapi/customnewscontentlvl2/news/sustainability-reporting-guidance-for-oil-gas-industry-4th-edition-released-by-ipieca-api-and-iogp",
    "url": "/news/sustainability-reporting-guidance-for-oil-gas-industry-4th-edition-released-by-ipieca-api-and-iogp",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sustainability Reporting Guidance for Oil & Gas Industry – 4th Edition released by IPIECA, API, and IOGP",
    "apiurl": "/restapi/customnewscontentlvl2/news/sustainability-reporting-guidance-for-oil-gas-industry-4th-edition-released-by-ipieca-api-and-iogp",
    "url": "/federal/sustainability-reporting-guidance-for-oil---gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Action Days Happening Early in the Upper Green-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ozone-Action-Days-Happening-Early-in-the-Upper-Green-Wyoming",
    "url": "/news/Ozone-Action-Days-Happening-Early-in-the-Upper-Green-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Answering the Frequently Asked Question: Is the New Monthly Reporting in CAERS Required?",
    "apiurl": "/restapi/customnewscontentlvl2/news/answering-the-frequently-asked-question-is-the-new-monthly-reporting-in-caers-required",
    "url": "/news/answering-the-frequently-asked-question-is-the-new-monthly-reporting-in-caers-required",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The SO2 Data Requirements Rule: What Next?",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-so2-data-requirements-rule-what-next",
    "url": "/news/the-so2-data-requirements-rule-what-next",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The SO2 Data Requirements Rule: What Next?",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-so2-data-requirements-rule-what-next",
    "url": "/News/Federal/The-SO2-Data-Requirements-Rule--What-Next-/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Testing of Bakery Ovens:  Overlooking Small Details Can Result in Compliance Issues",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-testing-of-bakery-ovens-overlooking-small-details-can-result-in-compliance-issues",
    "url": "/news/emissions-testing-of-bakery-ovens-overlooking-small-details-can-result-in-compliance-issues",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Finalizes Amendments to the Air Quality Permit Exemptions List",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-finalizes-amendments-to-the-air-quality-permit-exemptions-list",
    "url": "/news/padep-finalizes-amendments-to-the-air-quality-permit-exemptions-list",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2024 Arizona Water Quality Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/2024-arizona-water-quality-assessment",
    "url": "/news/2024-arizona-water-quality-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Greenhouse Gas Regulation Amendments in Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-greenhouse-gas-regulation-amendments-in-massachusetts",
    "url": "/news/proposed-greenhouse-gas-regulation-amendments-in-massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Supplemental Proposal for Oil and Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-supplemental-proposal-for-oil-and-gas-industry",
    "url": "/news/epa-releases-supplemental-proposal-for-oil-and-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Proposes Rule Amendments for Permit and Reporting Requirements for Fumigants and other Hazardous Air Pollutants",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-proposes-rule-for-permit-and-reporting-fumigants-and-other-haps",
    "url": "/news/njdep-proposes-rule-for-permit-and-reporting-fumigants-and-other-haps",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Enhancing Environmental Accountability: Recent Updates in MACT Requirements for Fenceline Monitoring",
    "apiurl": "/restapi/customnewscontentlvl2/news/enhancing-environmental-accountability-recent-updates-in-mact-requirements-for-fenceline-monitoring",
    "url": "/news/enhancing-environmental-accountability-recent-updates-in-mact-requirements-for-fenceline-monitoring",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to OLD MACT [40 CFR 63, subpart EEEE]",
    "apiurl": "/restapi/customnewscontentlvl2/news/revisions-to-old-mact-40-cfr-63-subpart-eeee-",
    "url": "/news/revisions-to-old-mact-40-cfr-63-subpart-eeee-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to OLD MACT [40 CFR 63, subpart EEEE]",
    "apiurl": "/restapi/customnewscontentlvl2/news/revisions-to-old-mact-40-cfr-63-subpart-eeee-",
    "url": "/federal/revisions-to-old-mact",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 11 - Road Map to a Stress-Free Reporting Season",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-11---road-map-to-a-stress-free-reporting-season",
    "url": "/news/ehs-quarterly-issue-11---road-map-to-a-stress-free-reporting-season",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nonroad Engine Classification in Arizona and Associated Recordkeeping",
    "apiurl": "/restapi/customnewscontentlvl2/news/nonroad-engine-classification-in-arizona-and-associated-recordkeeping",
    "url": "/news/nonroad-engine-classification-in-arizona-and-associated-recordkeeping",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Evaluating “Building Electrification” for Emission Reductions",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-evaluating-building-electrification-for-emission-reductions",
    "url": "/news/scaqmd-evaluating-building-electrification-for-emission-reductions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Changes to Title V Air Permits Guidance Manual from 2014 US Supreme Court Ruling-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Changes-to-Title-V-Air-Permits-Guidance-Manual-from-2014-US-Supreme-Court-Ruling-Virginia",
    "url": "/news/Proposed-Changes-to-Title-V-Air-Permits-Guidance-Manual-from-2014-US-Supreme-Court-Ruling-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CFATS Compliance – Lessons Learned from an Authorization Visit",
    "apiurl": "/restapi/customnewscontentlvl2/news/cfats-compliance-lessons-learned-from-an-authorization-visit",
    "url": "/news/cfats-compliance-lessons-learned-from-an-authorization-visit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CFATS Compliance – Lessons Learned from an Authorization Visit",
    "apiurl": "/restapi/customnewscontentlvl2/news/cfats-compliance-lessons-learned-from-an-authorization-visit",
    "url": "/federal/lessons-learned-from-an-authorization-visit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Proposed General ACDP Attachment for Emergency Generators in Lane County",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-proposed-general-acdp-attachment-for-emergency-generators-in-lane-county",
    "url": "/news/new-proposed-general-acdp-attachment-for-emergency-generators-in-lane-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Enforcement Discretion for Oil and Gas Sites in the Permian Basin: The Audit Act and Permian Basin Find It and Fix It Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/enforcement-discretion-for-oil-and-gas-sites-in-the-permian-basin-the-audit-act-and-permian-basin-find-it-and-fix-it-initiative",
    "url": "/news/enforcement-discretion-for-oil-and-gas-sites-in-the-permian-basin-the-audit-act-and-permian-basin-find-it-and-fix-it-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Enforcement Discretion for Oil and Gas Sites in the Permian Basin: The Audit Act and Permian Basin Find It and Fix It Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/enforcement-discretion-for-oil-and-gas-sites-in-the-permian-basin-the-audit-act-and-permian-basin-find-it-and-fix-it-initiative",
    "url": "/www.trinityconsultants.com/enforcement-discretion-for-oil-and-gas-sites-in-the-permian-basin-the-audit-act-and-permian-basin-find-it-and-fix-it-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "USEPA NPDES Compliance Initiative - Are You Submitting Your DMRs?-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/USEPA-NPDES-Compliance-Initiative---Are-You-Submitting-Your-DMRs--Missouri",
    "url": "/news/USEPA-NPDES-Compliance-Initiative---Are-You-Submitting-Your-DMRs--Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Why It’s Vital to Consider the Built Environment at the Start of Your USP <797>/<800> Compliance Journey",
    "apiurl": "/restapi/customnewscontentlvl2/news/why-its-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "url": "/news/why-its-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Why It’s Vital to Consider the Built Environment at the Start of Your USP <797>/<800> Compliance Journey",
    "apiurl": "/restapi/customnewscontentlvl2/news/why-its-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "url": "/news/why-it-s-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Unfinished Business: What Happens to EPA with a Change in US Presidents?",
    "apiurl": "/restapi/customnewscontentlvl2/news/unfinished-business-what-happens-to-epa-with-a-change-in-us-presidents",
    "url": "/news/unfinished-business-what-happens-to-epa-with-a-change-in-us-presidents",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Delegates Authority to Washoe County Health District for PSD Regulation Implementation",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-delegates-authority-to-washoe-county-health-district-for-psd-regulation-implementation",
    "url": "/news/epa-delegates-authority-to-washoe-county-health-district-for-psd-regulation-implementation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are you an E&P operator, using open flares? The Guidance is Changing!-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Are-you-an-E-P-operator-using-open-flares-The-Guidance-is-Changing!-Colorado",
    "url": "/news/Are-you-an-E-P-operator-using-open-flares-The-Guidance-is-Changing!-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Notice of Proposed Rulemaking for Maricopa County Air Quality Department Rule 324 (Stationary RICE)",
    "apiurl": "/restapi/customnewscontentlvl2/news/notice-of-proposed-rulemaking-for-maricopa-county-air-quality-department-rule-324-stationary-rice",
    "url": "/news/notice-of-proposed-rulemaking-for-maricopa-county-air-quality-department-rule-324-stationary-rice",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "HFC Management Rule Proposed by EPA",
    "apiurl": "/restapi/customnewscontentlvl2/news/hfc-management-rule-proposed-by-epa",
    "url": "/news/hfc-management-rule-proposed-by-epa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Proposed Rule for GHG Control from Landfills",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-proposed-rule-for-ghg-control-from-landfills",
    "url": "/news/oregon-deq-proposed-rule-for-ghg-control-from-landfills",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Adjustments at Arkansas DEQ",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-adjustments-at-arkansas-deq",
    "url": "/news/covid-19-adjustments-at-arkansas-deq",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Adjustments at Arkansas DEQ",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-adjustments-at-arkansas-deq",
    "url": "/state/covid-19-adjustments-at-arkansas-deq",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Annual Operating Fee Change for Title V Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/annual-operating-fee-change-for-title-v-facilities",
    "url": "/news/annual-operating-fee-change-for-title-v-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes a Clean Data Determination for the Salt Lake PM2.5 Serious Non-Attainment Area-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Proposes-a-Clean-Data-Determination-for-the-Salt-Lake-PM2-5-Serious-Non-Attainment-Area-Utah",
    "url": "/news/EPA-Proposes-a-Clean-Data-Determination-for-the-Salt-Lake-PM2-5-Serious-Non-Attainment-Area-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Prepare for Changes and Upcoming Due Dates Associated with Wyoming’s Recently Issued Final Construction General Permits for Stormwater",
    "apiurl": "/restapi/customnewscontentlvl2/news/prepare-for-changes-and-upcoming-due-dates-associated-with-wyoming-s-recently-issued-final-construction-general-permits-for-stormwater",
    "url": "/news/prepare-for-changes-and-upcoming-due-dates-associated-with-wyoming-s-recently-issued-final-construction-general-permits-for-stormwater",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Prepare for Changes and Upcoming Due Dates Associated with Wyoming’s Recently Issued Final Construction General Permits for Stormwater",
    "apiurl": "/restapi/customnewscontentlvl2/news/prepare-for-changes-and-upcoming-due-dates-associated-with-wyoming-s-recently-issued-final-construction-general-permits-for-stormwater",
    "url": "/states/wyoming/prepare-for-changes-and-upcoming-due-dates-associated-with-wyoming-s-recently-issued-final-construction-general-permits-for-stormwater",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2021: Industry Trade Shows & Presentations",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2021-industry-trade-shows-presentations",
    "url": "/news/best-of-2021-industry-trade-shows-presentations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Reclassifies Denver Ozone Nonattainment Area to Serious-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Reclassifies-Denver-Ozone-Nonattainment-Area-to-Serious-Colorado",
    "url": "/news/EPA-Reclassifies-Denver-Ozone-Nonattainment-Area-to-Serious-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Revised Source Category Permits for Engines Subject to NSPS JJJJ and NSPS IIII -Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Finalizes-Revised-Source-Category-Permits-for-Engines-Subject-to-NSPS-JJJJ-and-NSPS-IIII--Washington-DC",
    "url": "/news/DOEE-Finalizes-Revised-Source-Category-Permits-for-Engines-Subject-to-NSPS-JJJJ-and-NSPS-IIII--Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Redesignation Demonstration and Maintenance Plan for the Lemont and Pekin Sulfur Dioxide Nonattainment Areas-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Redesignation-Demonstration-and-Maintenance-Plan-for-the-Lemont-and-Pekin-Sulfur-Dioxide-Nonattainment-Areas-Illinois",
    "url": "/news/Redesignation-Demonstration-and-Maintenance-Plan-for-the-Lemont-and-Pekin-Sulfur-Dioxide-Nonattainment-Areas-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trends in Environmental Compliance Auditing",
    "apiurl": "/restapi/customnewscontentlvl2/news/trends-in-environmental-compliance-auditing",
    "url": "/news/trends-in-environmental-compliance-auditing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Implications for EPA’s Enforcement and Compliance Assurance Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-implications-for-epa-s-enforcement-and-compliance-assurance-program",
    "url": "/news/covid-19-implications-for-epa-s-enforcement-and-compliance-assurance-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Implications for EPA’s Enforcement and Compliance Assurance Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-implications-for-epa-s-enforcement-and-compliance-assurance-program",
    "url": "/federal/covid-19-implications-for-epas-enforcement-and-compliance-assurance-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Las Vegas, NV Ozone Nonattainment Area Facing Redesignation to Serious Nonattainment",
    "apiurl": "/restapi/customnewscontentlvl2/news/las-vegas-nv-ozone-nonattainment-area-facing-redesignation-to-serious-nonattainment",
    "url": "/news/las-vegas-nv-ozone-nonattainment-area-facing-redesignation-to-serious-nonattainment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OPR to Implement Statewide VMT Requirements under CEQA",
    "apiurl": "/restapi/customnewscontentlvl2/news/opr-to-implement-statewide-vmt-requirements-under-ceqa",
    "url": "/news/opr-to-implement-statewide-vmt-requirements-under-ceqa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2021: Employee Highlights",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2021-employee-highlights",
    "url": "/news/best-of-2021-employee-highlights",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reporting Reminders (March Update)-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reporting-Reminders-(March-Update)-Iowa",
    "url": "/news/Reporting-Reminders-(March-Update)-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHMSA FAQs Shed Light on Regulations for Midstream Processing Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/phmsa-faqs-shed-light-on-regulations-for-midstream-processing-facilities",
    "url": "/news/phmsa-faqs-shed-light-on-regulations-for-midstream-processing-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Expediting Permit Applications Critical to COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-expediting-permit-applications-critical-to-covid-19-response",
    "url": "/news/baaqmd-expediting-permit-applications-critical-to-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario MECP Updates Permitting Process for Use of Alternative Low Carbon Fuels",
    "apiurl": "/restapi/customnewscontentlvl2/news/ontario-mecp-updates-permitting-process-for-use-of-alternative-low-carbon-fuels",
    "url": "/news/ontario-mecp-updates-permitting-process-for-use-of-alternative-low-carbon-fuels",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bay Area AQMD Adopts Zero NOx Standards for Building Appliance Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-adopts-zero-nox-appliance-standards",
    "url": "/news/baaqmd-adopts-zero-nox-appliance-standards",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to TCEQ Public Notice Process",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-tceq-public-notice-process",
    "url": "/news/updates-to-tceq-public-notice-process",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Auditing Best Practices Part 3: Ensuring Confidentiality",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-auditing-best-practices-part-3-ensuring-confidentiality",
    "url": "/news/ehs-auditing-best-practices-part-3-ensuring-confidentiality",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Climate Change Related Reporting & Disclosures",
    "apiurl": "/restapi/customnewscontentlvl2/news/climate-change-related-reporting-disclosures",
    "url": "/news/climate-change-related-reporting-disclosures",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Climate Change Related Reporting & Disclosures",
    "apiurl": "/restapi/customnewscontentlvl2/news/climate-change-related-reporting-disclosures",
    "url": "/www.trinityconsultants.com/news/climate-change-related-reporting-disclosures",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tip of the Month - Spill Plan Compliance-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Tip-of-the-Month---Spill-Plan-Compliance-Kansas",
    "url": "/news/Tip-of-the-Month---Spill-Plan-Compliance-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NMED Newly Released Oil and Gas Sector Ozone Precursor Rule Expected to Impose Stringent Standards to Significantly Reduce Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/nmed-newly-released-oil-and-gas-sector-ozone-precursor-rule-expected-to-impose-stringent-standards-to-significantly-reduce-emissions",
    "url": "/news/nmed-newly-released-oil-and-gas-sector-ozone-precursor-rule-expected-to-impose-stringent-standards-to-significantly-reduce-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upstream and Midstream Operators Required to Attain a 98% Gas Capture by 2026 Under Proposed OCD Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/upstream-and-midstream-operators-required-to-attain-a-98-gas-capture-by-2026-under-proposed-ocd-rule",
    "url": "/news/upstream-and-midstream-operators-required-to-attain-a-98-gas-capture-by-2026-under-proposed-ocd-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upstream and Midstream Operators Required to Attain a 98% Gas Capture by 2026 Under Proposed OCD Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/upstream-and-midstream-operators-required-to-attain-a-98-gas-capture-by-2026-under-proposed-ocd-rule",
    "url": "/states/new-mexico/upstream-and-midstream-operators-required-to-attain-a-98--gas-capture-by-2026-under-proposed-ocd-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Manifest Requirements for Nonhazardous Special Waste-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Changes-to-Manifest-Requirements-for-Nonhazardous-Special-Waste-Illinois",
    "url": "/news/Changes-to-Manifest-Requirements-for-Nonhazardous-Special-Waste-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario MECP Updates Guideline A-5 for Stationary Combustion Turbines",
    "apiurl": "/restapi/customnewscontentlvl2/news/ontario-mecp-updates-guideline-a-5-for-stationary-combustion-turbines",
    "url": "/news/ontario-mecp-updates-guideline-a-5-for-stationary-combustion-turbines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario MECP Updates Guideline A-5 for Stationary Combustion Turbines",
    "apiurl": "/restapi/customnewscontentlvl2/news/ontario-mecp-updates-guideline-a-5-for-stationary-combustion-turbines",
    "url": "/www.trinityconsultants.com/news/ontario-mecp-updates-guideline-a-5-for-stationary-combustion-turbines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD’s Self-Reporting Pilot Program for Emergency Engine Operators",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-s-self-reporting-pilot-program-for-emergency-engine-operators",
    "url": "/news/baaqmd-s-self-reporting-pilot-program-for-emergency-engine-operators",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD’s Self-Reporting Pilot Program for Emergency Engine Operators",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-s-self-reporting-pilot-program-for-emergency-engine-operators",
    "url": "/www.trinityconsultants.com/news/baaqmd-s-self-reporting-pilot-program-for-emergency-engine-operators",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are Northern Weld (CO) and El Paso (TX) Counties Nonattainment Areas? DC Court of Appeals Decision",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-northern-weld-(co)-and-el-paso-(tx)-counties-nonattainment-areas-dc-court-of-appeals-decision",
    "url": "/news/are-northern-weld-(co)-and-el-paso-(tx)-counties-nonattainment-areas-dc-court-of-appeals-decision",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Governor Baker Climate Bill Includes Environmental Justice Component",
    "apiurl": "/restapi/customnewscontentlvl2/news/governor-baker-climate-bill-includes-environmental-justice-component",
    "url": "/news/governor-baker-climate-bill-includes-environmental-justice-component",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Governor Baker Climate Bill Includes Environmental Justice Component",
    "apiurl": "/restapi/customnewscontentlvl2/news/governor-baker-climate-bill-includes-environmental-justice-component",
    "url": "/www.trinityconsultants.com/news/governor-baker-climate-bill-includes-environmental-justice-component",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BLM Wyoming Releases Greenhouse Gas Toolkit, Requires Submission with All APDs-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/BLM-Wyoming-Releases-Greenhouse-Gas-Toolkit-Requires-Submission-with-All-APDs-Wyoming",
    "url": "/news/BLM-Wyoming-Releases-Greenhouse-Gas-Toolkit-Requires-Submission-with-All-APDs-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Proposes Amendments to Rule 4905 regarding Natural Gas-Fired, Fan-Type Central Furnaces",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-proposes-amendments-to-rule-4905-regarding-natural-gas-fired-fan-type-central-furnaces",
    "url": "/news/sjvapcd-proposes-amendments-to-rule-4905-regarding-natural-gas-fired-fan-type-central-furnaces",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Proposes Amendments to Rule 4905 regarding Natural Gas-Fired, Fan-Type Central Furnaces",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-proposes-amendments-to-rule-4905-regarding-natural-gas-fired-fan-type-central-furnaces",
    "url": "/news/states/california/sjvapcd-proposes-amendments-to-rule-4905-regarding-natural-gas-fired-fan-type-central-furnaces",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge Sep 2021 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/safebridge-sep-2021-newsletter",
    "url": "/news/safebridge-sep-2021-newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Proposes Revisions to Hazardous Waste Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-proposes-revisions-to-hazardous-waste-regulations",
    "url": "/news/mde-proposes-revisions-to-hazardous-waste-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to the Massachusetts Contingency Plan-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Amendments-to-the-Massachusetts-Contingency-Plan-Massachusetts",
    "url": "/news/Proposed-Amendments-to-the-Massachusetts-Contingency-Plan-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated Air Dispersion Modeling Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/updated-air-dispersion-modeling-guidance",
    "url": "/news/updated-air-dispersion-modeling-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated Air Dispersion Modeling Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/updated-air-dispersion-modeling-guidance",
    "url": "/states/updated-air-dispersion-modeling-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah Physicians for a Healthy Environment Bring Forward their Air Quality Concerns - Utah's Inland Port-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Utah-Physicians-for-a-Healthy-Environment-Bring-Forward-their-Air-Quality-Concerns---Utah's-Inland-Port-Utah",
    "url": "/news/Utah-Physicians-for-a-Healthy-Environment-Bring-Forward-their-Air-Quality-Concerns---Utah's-Inland-Port-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Iowa COVID-19 Environmental Compliance Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/iowa-covid-19-environmental-compliance-updates",
    "url": "/news/iowa-covid-19-environmental-compliance-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Wynnewood 2020 Decision Confirms Major Changes to PSM Applicability of Utilities and non-HHC Equipment",
    "apiurl": "/restapi/customnewscontentlvl2/news/wynnewood-2020-decision-confirms-major-changes-to-psm-applicability-of-utilities-and-non-hhc-equipment",
    "url": "/news/wynnewood-2020-decision-confirms-major-changes-to-psm-applicability-of-utilities-and-non-hhc-equipment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Toxics Emission Inventory Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-toxics-emission-inventory-update",
    "url": "/news/air-toxics-emission-inventory-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Did You File Your First TRI Form R in 2023? This Trigger TCEQ Pollution Prevention Plans!",
    "apiurl": "/restapi/customnewscontentlvl2/news/did-you-file-your-first-tri-form-r-in-2023-this-trigger-tceq-pollution-prevention-plans!",
    "url": "/news/did-you-file-your-first-tri-form-r-in-2023-this-trigger-tceq-pollution-prevention-plans!",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Proposed NSPS Rules for the Oil and Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-proposed-nsps-rules-for-the-oil-and-gas-industry",
    "url": "/news/epa-releases-proposed-nsps-rules-for-the-oil-and-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Hosting Public Workshop for Proposed Amendments to Regulation 6 Rule 5 on Feb 4",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-hosting-public-workshop-for-proposed-amendments-to-regulation-6-rule-5-on-feb-4",
    "url": "/news/baaqmd-hosting-public-workshop-for-proposed-amendments-to-regulation-6-rule-5-on-feb-4",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues Final Approval of Florida’s SSM SIP Revisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-final-approval-of-florida-ssm-sip-revisions",
    "url": "/news/epa-issues-final-approval-of-florida-ssm-sip-revisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "South Coast AQMD Warehouse ISR Rule 2305 Annual Reporting (AWR) Requirements - Due January 31, 2023",
    "apiurl": "/restapi/customnewscontentlvl2/news/south-coast-aqmd-warehouse-isr-rule-2305-annual-reporting-(awr)-requirements",
    "url": "/news/south-coast-aqmd-warehouse-isr-rule-2305-annual-reporting-(awr)-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Using Optical Gas Imaging in Gas Leak Detection: Proposed Updates to Appendix K",
    "apiurl": "/restapi/customnewscontentlvl2/news/using-optical-gas-imaging-in-gas-leak-detection-proposed-updates-to-appendix-k",
    "url": "/news/using-optical-gas-imaging-in-gas-leak-detection-proposed-updates-to-appendix-k",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PIPES Act of 2020, Section 114",
    "apiurl": "/restapi/customnewscontentlvl2/news/pipes-act-of-2020-section-114",
    "url": "/news/pipes-act-of-2020-section-114",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Texas Adopts Federal RCRA Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/texas-adopts-federal-rcra-updates",
    "url": "/news/texas-adopts-federal-rcra-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues a New Readily Available Permit for Marine Loading",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-a-new-readily-available-permit-for-marine-loading",
    "url": "/news/tceq-issues-a-new-readily-available-permit-for-marine-loading",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues a New Readily Available Permit for Marine Loading",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-a-new-readily-available-permit-for-marine-loading",
    "url": "/state/tceq-issues-a-new-readily-available-permit-for-marine-loading",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulatory Update on PFAS: CERCLA Hazardous Substances",
    "apiurl": "/restapi/customnewscontentlvl2/news/regulatory-update-on-pfas-cercla-hazardous-substances",
    "url": "/news/regulatory-update-on-pfas-cercla-hazardous-substances",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 12 - From Air Quality to PFAS: EPA's Scrutiny Deepens",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-12---from-air-quality-to-pfas-epa-scrutiny-deepens",
    "url": "/news/ehs-quarterly-issue-12---from-air-quality-to-pfas-epa-scrutiny-deepens",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 12 - From Air Quality to PFAS: EPA's Scrutiny Deepens",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-12---from-air-quality-to-pfas-epa-scrutiny-deepens",
    "url": "/news/ehs-quarterly-issue-12---from-air-quality-to-pfas-epa's-scrutiny-deepens",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest NATA Released – Its Role and Implications",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-nata-released-its-role-and-implications",
    "url": "/news/latest-nata-released-its-role-and-implications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest NATA Released – Its Role and Implications",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-nata-released-its-role-and-implications",
    "url": "/News/Federal/Latest-NATA-Released---Its-Role-and-Implications/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New NPDES Stormwater Discharge General Permit No. 1200-Z",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-npdes-stormwater-discharge-general-permit-no-1200-z",
    "url": "/news/new-npdes-stormwater-discharge-general-permit-no-1200-z",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New NPDES Stormwater Discharge General Permit No. 1200-Z",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-npdes-stormwater-discharge-general-permit-no-1200-z",
    "url": "/news/new-npdes-stormwater-discharge-general-permit-no.-1200-z",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Proposes Draft Guidance on Evaluating CLCPA for Air Permit Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-proposes-draft-guidance-on-evaluating-clcpa-for-air-permit-applications",
    "url": "/news/new-york-proposes-draft-guidance-on-evaluating-clcpa-for-air-permit-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Acoustics Leader Cerami & Associates Joins Trinity",
    "apiurl": "/restapi/customnewscontentlvl2/news/acoustics-leader-cerami-associates-joins-trinity",
    "url": "/news/acoustics-leader-cerami-associates-joins-trinity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Methane and Waste Prevention Rule on BLM Leases May Be Reinstated in Full",
    "apiurl": "/restapi/customnewscontentlvl2/news/methane-and-waste-prevention-rule-on-blm-leases-may-be-reinstated-in-full",
    "url": "/news/methane-and-waste-prevention-rule-on-blm-leases-may-be-reinstated-in-full",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Methane and Waste Prevention Rule on BLM Leases May Be Reinstated in Full",
    "apiurl": "/restapi/customnewscontentlvl2/news/methane-and-waste-prevention-rule-on-blm-leases-may-be-reinstated-in-full",
    "url": "/federal/methane-and-waste-prevention-rule-on-blm-leases-may-be-reinstated-in-full",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Regional Haze SIP Revision Proposal",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-regional-haze-sip-revision-proposal",
    "url": "/news/massachusetts-regional-haze-sip-revision-proposal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Regional Haze SIP Revision Proposal",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-regional-haze-sip-revision-proposal",
    "url": "/www.trinityconsultants.com/news/massachusetts-regional-haze-sip-revision-proposal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA AERR Proposed Revisions Webinar August 2023",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-aerr-proposed-revisions-webinar-august-2023",
    "url": "/news/epa-aerr-proposed-revisions-webinar-august-2023",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Reporting Reminders-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-Reporting-Reminders-Nebraska",
    "url": "/news/2020-Reporting-Reminders-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Friendly Emissions Inventory Reminder!-Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/Friendly-Emissions-Inventory-Reminder!-Kentucky",
    "url": "/news/Friendly-Emissions-Inventory-Reminder!-Kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ Implemented Adjusted Fee Schedules-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Clark-County-DAQ-Implemented-Adjusted-Fee-Schedules-Nevada",
    "url": "/news/Clark-County-DAQ-Implemented-Adjusted-Fee-Schedules-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SEC Proposed Rule Seeks Transparency and Standardization of Public Disclosures on Climate-Related Risks",
    "apiurl": "/restapi/customnewscontentlvl2/news/sec-proposed-rule-seeks-transparency-and-standardization-of-public-disclosures-on-climate-related-risks",
    "url": "/news/sec-proposed-rule-seeks-transparency-and-standardization-of-public-disclosures-on-climate-related-risks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes Water Quality Updates Affecting LPDES Permits-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Finalizes-Water-Quality-Updates-Affecting-LPDES-Permits-Louisiana",
    "url": "/news/LDEQ-Finalizes-Water-Quality-Updates-Affecting-LPDES-Permits-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Tightened PM2.5 National Ambient Air Quality Standards (NAAQS)",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-tightened-pm2.5-national-ambient-air-quality-standards-(naaqs)",
    "url": "/news/epa-proposes-tightened-pm2.5-national-ambient-air-quality-standards-(naaqs)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Department of Environmental Quality – Aquatic Life Criteria Proposed Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-department-of-environmental-quality-aquatic-life-criteria-proposed-updates",
    "url": "/news/oregon-department-of-environmental-quality-aquatic-life-criteria-proposed-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "South Carolina Publishes Draft Industrial Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/south-carolina-publishes-draft-industrial-stormwater-permit",
    "url": "/news/south-carolina-publishes-draft-industrial-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Potential Impacts of Hurricane Laura Emergency Order on Pending Permit Issuance in \"Emergency Areas\"",
    "apiurl": "/restapi/customnewscontentlvl2/news/potential-impacts-of-hurricane-laura-emergency-order-on-pending-permit-issuance-in-emergency-areas",
    "url": "/news/potential-impacts-of-hurricane-laura-emergency-order-on-pending-permit-issuance-in-emergency-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "USEPA Eyeing Major “RCRA Empty” Container Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/usepa-eyeing-major-rcra-empty-container-changes",
    "url": "/news/usepa-eyeing-major-rcra-empty-container-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NMED Announces Changes to New Mexico Air Dispersion Modeling Waiver",
    "apiurl": "/restapi/customnewscontentlvl2/news/nmed-announces-changes-to-new-mexico-air-dispersion-modeling-waiver",
    "url": "/news/nmed-announces-changes-to-new-mexico-air-dispersion-modeling-waiver",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DID YOU KNOW? Your ''Lifetime'' New York Minor Air Facility Registration May Actually Expire!-New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/DID-YOU-KNOW-Your-''Lifetime''-New-York-Minor-Air-Facility-Registration-May-Actually-Expire!-New-York",
    "url": "/news/DID-YOU-KNOW-Your-''Lifetime''-New-York-Minor-Air-Facility-Registration-May-Actually-Expire!-New-York",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Issues 2021 MSGP for Industrial Stormwater Discharges for Idaho Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-issues-2021-msgp-for-industrial-stormwater-discharges-for-idaho-facilities",
    "url": "/news/epa-issues-2021-msgp-for-industrial-stormwater-discharges-for-idaho-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes to Revise and Renew Source Category Permits for Emergency Engines Subject to RICE MACT",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-proposes-to-revise-and-renew-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "url": "/news/doee-proposes-to-revise-and-renew-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires Water Consultancy Vision Environment Australia",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-water-consultancy-vision-environment-australia",
    "url": "/news/trinity-acquires-water-consultancy-vision-environment-australia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires Water Consultancy Vision Environment Australia",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-water-consultancy-vision-environment-australia",
    "url": "/company/trinity-acquires-water-consultancy-vision-environment-australia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Impacts on Idaho Department of Environmental Quality Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-impacts-on-idaho-department-of-environmental-quality-operations",
    "url": "/news/covid-19-impacts-on-idaho-department-of-environmental-quality-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19 Impacts on Idaho Department of Environmental Quality Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-impacts-on-idaho-department-of-environmental-quality-operations",
    "url": "/states/idaho/covid-19-impacts-on-idaho-department-of-environmental-quality-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Recent Good News at Trinity",
    "apiurl": "/restapi/customnewscontentlvl2/news/recent-good-news-at-trinity",
    "url": "/news/recent-good-news-at-trinity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Deep Dive into 401 KAR 63:020, Potentially Hazardous Matter or Toxic Substances-Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/Deep-Dive-into-401-KAR-63-020-Potentially-Hazardous-Matter-or-Toxic-Substances-Kentucky",
    "url": "/news/Deep-Dive-into-401-KAR-63-020-Potentially-Hazardous-Matter-or-Toxic-Substances-Kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 4 - Forging a New Path",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-4",
    "url": "/news/ehs-quarterly-issue-4",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ to Develop Regulations to Limit Methane from Natural Gas Infrastructure",
    "apiurl": "/restapi/customnewscontentlvl2/news/deq-to-develop-regulations-to-limit-methane-from-natural-gas-infrastructure",
    "url": "/news/deq-to-develop-regulations-to-limit-methane-from-natural-gas-infrastructure",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Potential Amendments to Off-Road Diesel Engine Emission Standards: Tier 5 and CO2 Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/potential-amendments-to-off-road-diesel-engine-emission-standards-tier-5-and-co2-standards",
    "url": "/news/potential-amendments-to-off-road-diesel-engine-emission-standards-tier-5-and-co2-standards",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Cleaner Air Oregon Procedures Continue to Evolve-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Cleaner-Air-Oregon-Procedures-Continue-to-Evolve-Oregon",
    "url": "/news/Cleaner-Air-Oregon-Procedures-Continue-to-Evolve-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Guidance on AST Emergency Containment Structure Verification-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/PADEP-Guidance-on-AST-Emergency-Containment-Structure-Verification-Pennsylvania",
    "url": "/news/PADEP-Guidance-on-AST-Emergency-Containment-Structure-Verification-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BOEM Issues Several NTLs Implementing the New Offshore Air Quality Regulations for the Gulf of Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/boem-issues-several-ntls-implementing-the-new-offshore-air-quality-regulations-for-the-gulf-of-mexico",
    "url": "/news/boem-issues-several-ntls-implementing-the-new-offshore-air-quality-regulations-for-the-gulf-of-mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BOEM Issues Several NTLs Implementing the New Offshore Air Quality Regulations for the Gulf of Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/boem-issues-several-ntls-implementing-the-new-offshore-air-quality-regulations-for-the-gulf-of-mexico",
    "url": "/news/federal/boem-issues-several-ntls-implementing-the-new-offshore-air-quality-regulations-for-the-gulf-of-mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Air Quality Rules Comment Period Deadline Extended-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Air-Quality-Rules-Comment-Period-Deadline-Extended-Iowa",
    "url": "/news/Proposed-Air-Quality-Rules-Comment-Period-Deadline-Extended-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Proposed Regulation 23 Regional Haze Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-proposed-regulation-23-regional-haze-limits",
    "url": "/news/new-proposed-regulation-23-regional-haze-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Proposed Regulation 23 Regional Haze Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-proposed-regulation-23-regional-haze-limits",
    "url": "/news/states/colorado/new-proposed-regulation-23-regional-haze-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD to Host Online Workshop Series Regarding Alternatives to Diesel Equipment on August 5 and August 19, 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-to-host-online-workshop-series-regarding-alternatives-to-diesel-equipment-on-august-5-and-august-19-2020",
    "url": "/news/baaqmd-to-host-online-workshop-series-regarding-alternatives-to-diesel-equipment-on-august-5-and-august-19-2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exempt Source and Conditionally Insignificant Activities Rulemaking Updates-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-Minnesota",
    "url": "/news/Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Reporting Requirements for Illinois Facilities - March 2019-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Upcoming-Reporting-Requirements-for-Illinois-Facilities---March-2019-Illinois",
    "url": "/news/Upcoming-Reporting-Requirements-for-Illinois-Facilities---March-2019-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington’s Clean Fuel Standard Set to Take Effect in 2023",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-s-clean-fuel-standard-set-to-take-effect-in-2023",
    "url": "/news/washington-s-clean-fuel-standard-set-to-take-effect-in-2023",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia Air Pollution Control Board Holds Meeting on Environmental Justice-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Virginia-Air-Pollution-Control-Board-Holds-Meeting-on-Environmental-Justice-Virginia",
    "url": "/news/Virginia-Air-Pollution-Control-Board-Holds-Meeting-on-Environmental-Justice-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Differences Between AERMOD and CALPUFF",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-differences-between-aermod-and-calpuff",
    "url": "/news/the-differences-between-aermod-and-calpuff",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Differences Between AERMOD and CALPUFF",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-differences-between-aermod-and-calpuff",
    "url": "/News/Technology/The-Differences-Between-AERMOD-and-CALPUFF/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Annual Toxic Release Inventory (TRI) Reports due July 1st-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/Annual-Toxic-Release-Inventory-(TRI)-Reports-due-July-1st-Nebraska",
    "url": "/news/Annual-Toxic-Release-Inventory-(TRI)-Reports-due-July-1st-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Guidance Documents for the Natural Gas Transmission and Storage Segment Program (September 2020)",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-guidance-documents-for-the-natural-gas-transmission-and-storage-segment-program-(september-2020)",
    "url": "/news/new-guidance-documents-for-the-natural-gas-transmission-and-storage-segment-program-(september-2020)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Guidance Documents for the Natural Gas Transmission and Storage Segment Program (September 2020)",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-guidance-documents-for-the-natural-gas-transmission-and-storage-segment-program-(september-2020)",
    "url": "/states/new-guidance-documents-for-the-natural-gas-transmission-and-storage-segment-program-september-2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Withdrawal of BPA One-Hour Ozone Redesignation and SIP Revision",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-withdrawal-of-bpa-one-hour-ozone-redesignation-and-sip-revision",
    "url": "/news/proposed-withdrawal-of-bpa-one-hour-ozone-redesignation-and-sip-revision",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Withdrawal of BPA One-Hour Ozone Redesignation and SIP Revision",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-withdrawal-of-bpa-one-hour-ozone-redesignation-and-sip-revision",
    "url": "/news/state/texas/proposed-withdrawal-of-bpa-one-hour-ozone-redesignation-and-sip-revision",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ’s Storage Tank Emissions Calculation Tool",
    "apiurl": "/restapi/customnewscontentlvl2/news/deq-s-storage-tank-emissions-calculation-tool-",
    "url": "/news/deq-s-storage-tank-emissions-calculation-tool-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ’s Storage Tank Emissions Calculation Tool",
    "apiurl": "/restapi/customnewscontentlvl2/news/deq-s-storage-tank-emissions-calculation-tool-",
    "url": "/news/state/oklahoma/deq-s-storage-tank-emissions-calculation-tool-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OMB Requests Comments on Social Cost of Greenhouse Gases",
    "apiurl": "/restapi/customnewscontentlvl2/news/omb-requests-comments-on-social-cost-of-greenhouse-gases",
    "url": "/news/omb-requests-comments-on-social-cost-of-greenhouse-gases",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Publishes Rule 4311 Draft Rule Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-publishes-rule-4311-draft-rule-amendments",
    "url": "/news/sjvapcd-publishes-rule-4311-draft-rule-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Publishes Rule 4311 Draft Rule Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-publishes-rule-4311-draft-rule-amendments",
    "url": "/states/california/sjvapcd-publishes-rule-4311-draft-rule-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants’ Names James Perry Managing Director of Cerami & Associates",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-names-james-perry-managing-director-of-cerami-associates",
    "url": "/news/trinity-consultants-names-james-perry-managing-director-of-cerami-associates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EQB Approves Final Rulemaking for Air Quality Program Fees",
    "apiurl": "/restapi/customnewscontentlvl2/news/eqb-approves-final-rulemaking-for-air-quality-program-fees",
    "url": "/news/eqb-approves-final-rulemaking-for-air-quality-program-fees",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EQB Approves Final Rulemaking for Air Quality Program Fees",
    "apiurl": "/restapi/customnewscontentlvl2/news/eqb-approves-final-rulemaking-for-air-quality-program-fees",
    "url": "/news/state/pennsylvania/eqb-approves-final-rulemaking-for-air-quality-program-fees",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Proposes Amendments to MACT Determinations for Non-HON Sources-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Proposes-Amendments-to-MACT-Determinations-for-Non-HON-Sources-Louisiana",
    "url": "/news/LDEQ-Proposes-Amendments-to-MACT-Determinations-for-Non-HON-Sources-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Salt Lake Area PM2.5 Serious Non-attainment State Implementation Plan (SIP) Approved by Air Quality Board-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Salt-Lake-Area-PM2-5-Serious-Non-attainment-State-Implementation-Plan-(SIP)-Approved-by-Air-Quality-Board-Utah",
    "url": "/news/Salt-Lake-Area-PM2-5-Serious-Non-attainment-State-Implementation-Plan-(SIP)-Approved-by-Air-Quality-Board-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Carbon Black MACT RTR – More Process Vents and Testing",
    "apiurl": "/restapi/customnewscontentlvl2/news/carbon-black-mact-rtr-more-process-vents-and-testing",
    "url": "/news/carbon-black-mact-rtr-more-process-vents-and-testing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Team Trinity Supports Childhood Cancer Research: Couch to 5K Across the US",
    "apiurl": "/restapi/customnewscontentlvl2/news/team-trinity-supports-childhood-cancer-research-couch-to-5k-across-the-us",
    "url": "/news/team-trinity-supports-childhood-cancer-research-couch-to-5k-across-the-us",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Shoreline Response Programme Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/shoreline-response-programme-guidance-",
    "url": "/news/shoreline-response-programme-guidance-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Shoreline Response Programme Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/shoreline-response-programme-guidance-",
    "url": "/ehs-management/shoreline-response-programme-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON RTR Impacts on Facility Plans",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-rtr-impacts-on-facility-plans",
    "url": "/news/mon-rtr-impacts-on-facility-plans",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Gear Up for 2023 Compliance in Washington State",
    "apiurl": "/restapi/customnewscontentlvl2/news/gear-up-for-2023-compliance-in-washington-state",
    "url": "/news/gear-up-for-2023-compliance-in-washington-state",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Title V Permits by Rule Supplemental Table",
    "apiurl": "/restapi/customnewscontentlvl2/news/title-v-permits-by-rule-supplemental-table",
    "url": "/news/title-v-permits-by-rule-supplemental-table",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Title V Permits by Rule Supplemental Table",
    "apiurl": "/restapi/customnewscontentlvl2/news/title-v-permits-by-rule-supplemental-table",
    "url": "/state/title-v-permits-by-rule-supplemental-table",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming New Mexico Minor Source Emission Inventory-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/Upcoming-New-Mexico-Minor-Source-Emission-Inventory-New-Mexico",
    "url": "/news/Upcoming-New-Mexico-Minor-Source-Emission-Inventory-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Applications for Funding Zero-Emission Projects",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-applications-for-funding-zero-emission-projects",
    "url": "/news/baaqmd-accepting-applications-for-funding-zero-emission-projects",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Fenceline Air Monitoring Requirements Continue to Morph and Expand",
    "apiurl": "/restapi/customnewscontentlvl2/news/fenceline-air-monitoring-requirements-continue-to-morph-and-expand",
    "url": "/news/fenceline-air-monitoring-requirements-continue-to-morph-and-expand",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Permitting Matrix Updated",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndee-permitting-matrix-updated",
    "url": "/news/ndee-permitting-matrix-updated",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Environmental Quality Commission Adopts Amendments to Hazard Index Benchmarks",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-environmental-quality-commission-adopts-amendments-to-hazard-index-benchmarks-",
    "url": "/news/oregon-environmental-quality-commission-adopts-amendments-to-hazard-index-benchmarks-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID19 Update for Training Registrants",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid19-update-for-training-registrants",
    "url": "/news/covid19-update-for-training-registrants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Software Updates x3: U.S. EPA 19191 Executables Now Available in BREEZE AERMOD, AERSCREEN and AERMET",
    "apiurl": "/restapi/customnewscontentlvl2/news/Software-Updates-x3-U-S-EPA-19191-Executables-Now-Available-in-BREEZE-AERMOD-AERSCREEN-and-AERMET",
    "url": "/news/Software-Updates-x3-U-S-EPA-19191-Executables-Now-Available-in-BREEZE-AERMOD-AERSCREEN-and-AERMET",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bill Signed Requiring Effort to Sell Coal-Fired Power Plants-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Bill-Signed-Requiring-Effort-to-Sell-Coal-Fired-Power-Plants-Wyoming",
    "url": "/news/Bill-Signed-Requiring-Effort-to-Sell-Coal-Fired-Power-Plants-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Bay Area AQMD Public Hearing for Proposed Amendments to Regulation 8 Rule 8: Wastewater Collection and Separation Systems",
    "apiurl": "/restapi/customnewscontentlvl2/news/bay-area-aqmd-public-hearing-for-proposed-amendments-to-regulation-8-rule-8-wastewater-collection-and-separation-systems",
    "url": "/news/bay-area-aqmd-public-hearing-for-proposed-amendments-to-regulation-8-rule-8-wastewater-collection-and-separation-systems",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Denver to be Classified as a Severe Nonattainment Area for Ozone",
    "apiurl": "/restapi/customnewscontentlvl2/news/denver-to-be-classified-as-a-severe-nonattainment-area-for-ozone",
    "url": "/news/denver-to-be-classified-as-a-severe-nonattainment-area-for-ozone",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois EPA Adopts New General NPDES Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-epa-adopts-new-general-npdes-stormwater-permit",
    "url": "/news/illinois-epa-adopts-new-general-npdes-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Proposed Warehouse Indirect Source Review for NOx and PM10 Reductions (Rule 2305)",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-proposed-warehouse-indirect-source-review-for-nox-and-pm10-reductions-rule-2305",
    "url": "/news/scaqmd-proposed-warehouse-indirect-source-review-for-nox-and-pm10-reductions-rule-2305",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Proposed Warehouse Indirect Source Review for NOx and PM10 Reductions (Rule 2305)",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-proposed-warehouse-indirect-source-review-for-nox-and-pm10-reductions-rule-2305",
    "url": "/www.trinityconsultants.com/news/scaqmd-proposed-warehouse-indirect-source-review-for-nox-and-pm10-reductions-rule-2305",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tier 2 E-Plan 2019 Submission & Training-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Tier-2-E-Plan-2019-Submission-Training-Iowa",
    "url": "/news/Tier-2-E-Plan-2019-Submission-Training-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Notice of Public Hearing: ADEM Admin Code 335-7 (Water Supply Program)",
    "apiurl": "/restapi/customnewscontentlvl2/news/notice-of-public-hearing-adem-admin-code-335-7-(water-supply-program)",
    "url": "/news/notice-of-public-hearing-adem-admin-code-335-7-(water-supply-program)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Releases Draft of Amended Rule 4702 for Internal Combustion Engines",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-releases-draft-of-amended-rule-4702-for-internal-combustion-engines",
    "url": "/news/sjvapcd-releases-draft-of-amended-rule-4702-for-internal-combustion-engines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WYDEQ Request for Information on Wellsite Equipment-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/WYDEQ-Request-for-Information-on-Wellsite-Equipment-Wyoming",
    "url": "/news/WYDEQ-Request-for-Information-on-Wellsite-Equipment-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "One Year Later: Benefits and Challenges of Inflation Reduction Act Linger",
    "apiurl": "/restapi/customnewscontentlvl2/news/one-year-later-benefits-and-challenges-of-inflation-reduction-act-linger",
    "url": "/news/one-year-later-benefits-and-challenges-of-inflation-reduction-act-linger",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia DEQ Initiated Site Suitability NOIRA",
    "apiurl": "/restapi/customnewscontentlvl2/news/virginia-deq-initiated-site-suitability-noira",
    "url": "/news/virginia-deq-initiated-site-suitability-noira",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Proposes Hazardous Waste Regulation Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-proposes-hazardous-waste-regulation-updates",
    "url": "/news/mde-proposes-hazardous-waste-regulation-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Missouri Air Permitting Changes Go into Effect-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Missouri-Air-Permitting-Changes-Go-into-Effect-Missouri",
    "url": "/news/Missouri-Air-Permitting-Changes-Go-into-Effect-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The EPA's Air Plan Revisions to Placer County Air Pollution Control District's New Source Review",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-epa's-air-plan-revisions-to-placer-county-air-pollution-control-district's-new-source-review",
    "url": "/news/the-epa's-air-plan-revisions-to-placer-county-air-pollution-control-district's-new-source-review",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates Guidance Document for Hazardous Waste Determinations",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-guidance-document-for-hazardous-waste-determinations",
    "url": "/news/kdhe-updates-guidance-document-for-hazardous-waste-determinations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates Guidance Document for Hazardous Waste Determinations",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-guidance-document-for-hazardous-waste-determinations",
    "url": "/states/kansas/kdhe-updates-guidance-document-for-hazardous-waste-determinations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "A&WMA Midwest Section Environmental Technical Conference, September 30 - October 1-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/A-WMA-Midwest-Section-Environmental-Technical-Conference-September-30---October-1-Nebraska",
    "url": "/news/A-WMA-Midwest-Section-Environmental-Technical-Conference-September-30---October-1-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Updates to Draft Regulation 13: Climate Pollutants, Rule 5: Petroleum Refinery",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-updates-to-draft-regulation-13-climate-pollutants-rule-5-petroleum-refinery",
    "url": "/news/baaqmd-accepting-comments-for-updates-to-draft-regulation-13-climate-pollutants-rule-5-petroleum-refinery",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Updates to Draft Regulation 13: Climate Pollutants, Rule 5: Petroleum Refinery",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-updates-to-draft-regulation-13-climate-pollutants-rule-5-petroleum-refinery",
    "url": "/news/states/california/baaqmd-accepting-comments-for-updates-to-draft-regulation-13--climate-pollutants-rule-5--petroleum-refinery",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PA EQB Proposes New Fee Schedule-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/PA-EQB-Proposes-New-Fee-Schedule-Pennsylvania",
    "url": "/news/PA-EQB-Proposes-New-Fee-Schedule-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Status Update on Maryland’s Short-Lived Climate Pollutants Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/status-update-on-maryland-s-short-lived-climate-pollutants-program",
    "url": "/news/status-update-on-maryland-s-short-lived-climate-pollutants-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Status Update on Maryland’s Short-Lived Climate Pollutants Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/status-update-on-maryland-s-short-lived-climate-pollutants-program",
    "url": "/states/maryland/status-update-on-maryland-s-short-lived-climate-pollutants-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Unified Hazardous Materials Spill/Release and Tier II Reporting System – North Dakota",
    "apiurl": "/restapi/customnewscontentlvl2/news/hazardous-materials-spill-release-tier-ii-reporting-n-dakota",
    "url": "/news/hazardous-materials-spill-release-tier-ii-reporting-n-dakota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SPCC/FRP Basics and Compliance Best Practices",
    "apiurl": "/restapi/customnewscontentlvl2/news/spcc-frp-basics-and-compliance-best-practices",
    "url": "/news/spcc-frp-basics-and-compliance-best-practices",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SPCC/FRP Basics and Compliance Best Practices",
    "apiurl": "/restapi/customnewscontentlvl2/news/spcc-frp-basics-and-compliance-best-practices",
    "url": "/www.trinityconsultants.com/news/spcc-frp-basics-and-compliance-best-practices",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR Field Office 5 Moving to Wallace Building in Des Moines-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-Field-Office-5-Moving-to-Wallace-Building-in-Des-Moines-Iowa",
    "url": "/news/IDNR-Field-Office-5-Moving-to-Wallace-Building-in-Des-Moines-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Winter Ozone in the Upper Green-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Winter-Ozone-in-the-Upper-Green-Wyoming",
    "url": "/news/Winter-Ozone-in-the-Upper-Green-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Louisville Metro – Proposed Draft Regulations and What They Mean for You",
    "apiurl": "/restapi/customnewscontentlvl2/news/louisville-metro-proposed-draft-regulations-and-what-they-mean-for-you",
    "url": "/news/louisville-metro-proposed-draft-regulations-and-what-they-mean-for-you",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario's Emissions Performance Standards (EPS)-Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ontario's-Emissions-Performance-Standards-(EPS)-Ontario",
    "url": "/news/Ontario's-Emissions-Performance-Standards-(EPS)-Ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County Mobile Source Emission Reduction Credits (MERCs) Rulemaking Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-mobile-source-emission-reduction-credits-(mercs)-rulemaking-updates",
    "url": "/news/maricopa-county-mobile-source-emission-reduction-credits-(mercs)-rulemaking-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Proposes Revisions to Air Quality Risk Screening Worksheet - CRITICAL UPDATE-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/NJDEP-Proposes-Revisions-to-Air-Quality-Risk-Screening-Worksheet---CRITICAL-UPDATE-New-Jersey",
    "url": "/news/NJDEP-Proposes-Revisions-to-Air-Quality-Risk-Screening-Worksheet---CRITICAL-UPDATE-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity in 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-in-2020",
    "url": "/news/trinity-in-2020",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Provides Additional EMFAC Off-Model Adjustment Factors for Carbon Dioxide",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "url": "/news/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Provides Additional EMFAC Off-Model Adjustment Factors for Carbon Dioxide",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "url": "/news/state/california/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Provides Additional EMFAC Off-Model Adjustment Factors for Carbon Dioxide",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "url": "/news/state/carb-provides-additional-emfac-off-model-adjustment-factors-for-carbon-dioxide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Court Ruling of UDAQ’s 2015 8-hour Ozone SIP: What Companies Need to Know",
    "apiurl": "/restapi/customnewscontentlvl2/news/court-ruling-of-udaq-2015-8-hour-ozone-sip-what-companies-need-to-know",
    "url": "/news/court-ruling-of-udaq-2015-8-hour-ozone-sip-what-companies-need-to-know",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PCAQCD Files a Notice of Proposed Rulemaking to Amend the West Pinal County Fugitive Dust Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/pcaqcd-files-a-notice-of-proposed-rulemaking-to-amend-the-west-pinal-county-fugitive-dust-rules",
    "url": "/news/pcaqcd-files-a-notice-of-proposed-rulemaking-to-amend-the-west-pinal-county-fugitive-dust-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Piloting Virtual Source Test Observation Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndep-piloting-virtual-source-test-observation-program",
    "url": "/news/ndep-piloting-virtual-source-test-observation-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Piloting Virtual Source Test Observation Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndep-piloting-virtual-source-test-observation-program",
    "url": "/news/state/nevada/ndep-piloting-virtual-source-test-observation-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Industrial Regulatory Impacts Imminent from PFAS Concerns",
    "apiurl": "/restapi/customnewscontentlvl2/news/industrial-regulatory-impacts-imminent-from-pfas-concerns",
    "url": "/news/industrial-regulatory-impacts-imminent-from-pfas-concerns",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Industrial Regulatory Impacts Imminent from PFAS Concerns",
    "apiurl": "/restapi/customnewscontentlvl2/news/industrial-regulatory-impacts-imminent-from-pfas-concerns",
    "url": "/News/Federal/Industrial-Regulatory-Impacts-Imminent-from-PFAS-Concerns/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma DEQ Reissues Multi-Sector OKR05 OPDES Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-deq-reissues-multi-sector-okr05-opdes-permit",
    "url": "/news/oklahoma-deq-reissues-multi-sector-okr05-opdes-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice in New Jersey is Final – Now What?",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-in-new-jersey-is-final-now-what",
    "url": "/news/environmental-justice-in-new-jersey-is-final-now-what",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ has Published Significant Sources for Regional Haze Planning-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-DEQ-has-Published-Significant-Sources-for-Regional-Haze-Planning-Oregon",
    "url": "/news/Oregon-DEQ-has-Published-Significant-Sources-for-Regional-Haze-Planning-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON and MACT Pressure Relief Management Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-and-mact-pressure-relief-management-requirements",
    "url": "/news/mon-and-mact-pressure-relief-management-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CMAS...Is U.S. EPA Getting Ready for ICR and RTR?",
    "apiurl": "/restapi/customnewscontentlvl2/news/cmas-is-us-epa-getting-ready-for-icr-and-rtr",
    "url": "/news/cmas-is-us-epa-getting-ready-for-icr-and-rtr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RIDEM Proposes Regulation for Climate Resilience Fund-Rhode Island",
    "apiurl": "/restapi/customnewscontentlvl2/news/RIDEM-Proposes-Regulation-for-Climate-Resilience-Fund-Rhode-Island",
    "url": "/news/RIDEM-Proposes-Regulation-for-Climate-Resilience-Fund-Rhode-Island",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News March 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-march-2021",
    "url": "/news/good-news-march-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News March 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-march-2021",
    "url": "/www.trinityconsultants.com/good-news-mar-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Scrap Metal Handling and Recycling – Compliance Advisory from NJDEP",
    "apiurl": "/restapi/customnewscontentlvl2/news/scrap-metal-handling-and-recycling-compliance-advisory-from-njdep",
    "url": "/news/scrap-metal-handling-and-recycling-compliance-advisory-from-njdep",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming NM Minor Source Emission Inventory News",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-nm-minor-source-emission-inventory-news",
    "url": "/news/upcoming-nm-minor-source-emission-inventory-news",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2019 Toxic Use Reduction (TUR) Plan Summary and Report Extension",
    "apiurl": "/restapi/customnewscontentlvl2/news/massdep-2019-toxic-use-reduction-(tur)-plan-summary-and-report-extension",
    "url": "/news/massdep-2019-toxic-use-reduction-(tur)-plan-summary-and-report-extension",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Does Your SPCC Plan Make the Grade? Lessons Learned from EPA Inspections-North Carolina",
    "apiurl": "/restapi/customnewscontentlvl2/news/Does-Your-SPCC-Plan-Make-the-Grade-Lessons-Learned-from-EPA-Inspections-North-Carolina",
    "url": "/news/Does-Your-SPCC-Plan-Make-the-Grade-Lessons-Learned-from-EPA-Inspections-North-Carolina",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "First-Ever Tier 1 Shortage Declared for Colorado River for 2022 and Potential Impacts on Arizona Businesses",
    "apiurl": "/restapi/customnewscontentlvl2/news/first-ever-tier-1-shortage-declared-for-colorado-river-for-2022-and-potential-impacts-on-arizona-businesses",
    "url": "/news/first-ever-tier-1-shortage-declared-for-colorado-river-for-2022-and-potential-impacts-on-arizona-businesses",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Renewing Focus on Environmental Justice and Disadvantaged Communities in New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/renewing-focus-on-environmental-justice-and-disadvantaged-communities-in-new-york",
    "url": "/news/renewing-focus-on-environmental-justice-and-disadvantaged-communities-in-new-york",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Proposes Revisions to the Risk Evaluation/Corrective Action Program (RECAP)-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Proposes-Revisions-to-the-Risk-Evaluation-Corrective-Action-Program-(RECAP)-Louisiana",
    "url": "/news/LDEQ-Proposes-Revisions-to-the-Risk-Evaluation-Corrective-Action-Program-(RECAP)-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Philadelphia AMS Finalizes Air Toxics Regulation",
    "apiurl": "/restapi/customnewscontentlvl2/news/philadelphia-ams-finalizes-air-toxics-regulation",
    "url": "/news/philadelphia-ams-finalizes-air-toxics-regulation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD - More Stringent Emission Thresholds Might Mean Additional Permitting Requirements in Coachella Valley",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd---more-stringent-emission-thresholds-might-mean-additional-permitting-requirements-in-coachella-valley",
    "url": "/news/scaqmd---more-stringent-emission-thresholds-might-mean-additional-permitting-requirements-in-coachella-valley",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD - More Stringent Emission Thresholds Might Mean Additional Permitting Requirements in Coachella Valley",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd---more-stringent-emission-thresholds-might-mean-additional-permitting-requirements-in-coachella-valley",
    "url": "/states/california/scaqmd---more-stringent-emission-thresholds-might-mean-additional-permitting-requirements-in-coachella-valley",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MCM NESHAP RTR – Testing and SSM Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/mcm-neshap-rtr-testing-and-ssm-updates",
    "url": "/news/mcm-neshap-rtr-testing-and-ssm-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Gov. Edwards Issues New Executive Orders Addressing GHG Emissions Reduction and Coastal Resilience",
    "apiurl": "/restapi/customnewscontentlvl2/news/gov-edwards-issues-new-executive-orders-addressing-ghg-emissions-reduction-and-coastal-resilience-",
    "url": "/news/gov-edwards-issues-new-executive-orders-addressing-ghg-emissions-reduction-and-coastal-resilience-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Gov. Edwards Issues New Executive Orders Addressing GHG Emissions Reduction and Coastal Resilience",
    "apiurl": "/restapi/customnewscontentlvl2/news/gov-edwards-issues-new-executive-orders-addressing-ghg-emissions-reduction-and-coastal-resilience-",
    "url": "/states/louisiana/gov--edwards-issues-new-executive-orders-addressing-ghg-emissions-reduction-and-coastal-resilience",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approving Changes to Nonattainment New Source Review Permitting Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-approving-changes-to-nonattainment-new-source-review-permitting-rules",
    "url": "/news/epa-approving-changes-to-nonattainment-new-source-review-permitting-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP Draft 2020 Air Monitoring Network Plan and 2020 Five-Year Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/massdep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "url": "/news/massdep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP Draft 2020 Air Monitoring Network Plan and 2020 Five-Year Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/massdep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "url": "/news/state/massachusetts/massdep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ November 2020 Environmental Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/ldeq-november-2020-environmental-updates",
    "url": "/news/ldeq-november-2020-environmental-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Louisiana Approves First State Climate Action Plan in Order to Reach Net-Zero Emissions by 2050",
    "apiurl": "/restapi/customnewscontentlvl2/news/louisiana-approves-first-state-climate-action-plan-in-order-to-reach-net-zero-emissions-by-2050",
    "url": "/news/louisiana-approves-first-state-climate-action-plan-in-order-to-reach-net-zero-emissions-by-2050",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Two Major Updates to North Dakota Well Registration Workbook",
    "apiurl": "/restapi/customnewscontentlvl2/news/two-major-updates-to-north-dakota-well-registration-workbook-",
    "url": "/news/two-major-updates-to-north-dakota-well-registration-workbook-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP Fall 2021 Rulemaking Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-dep-fall-2021-rulemaking-update",
    "url": "/news/maine-dep-fall-2021-rulemaking-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Amendments to 2012 and 2016 NSPS for Oil and Natural Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-amendments-to-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "url": "/news/epa-finalizes-amendments-to-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Amendments to 2012 and 2016 NSPS for Oil and Natural Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-amendments-to-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "url": "/federal/epa-finalizes-amendments-to-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OTC Proceeds with Public Hearing for Additional Control Measures Targeting Pennsylvania’s Coal-Fired EGUs-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/OTC-Proceeds-with-Public-Hearing-for-Additional-Control-Measures-Targeting-Pennsylvania-s-Coal-Fired-EGUs-Pennsylvania",
    "url": "/news/OTC-Proceeds-with-Public-Hearing-for-Additional-Control-Measures-Targeting-Pennsylvania-s-Coal-Fired-EGUs-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Data Manipulation Tools in 3D Analyst 3.0 Make Modeling Faster and Easier",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Data-Manipulation-Tools-in-3D-Analyst-3-0-Make-Modeling-Faster-and-Easier-",
    "url": "/news/New-Data-Manipulation-Tools-in-3D-Analyst-3-0-Make-Modeling-Faster-and-Easier-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Publishes Title V Reporting Guidance Letter-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Publishes-Title-V-Reporting-Guidance-Letter-Washington-DC",
    "url": "/news/DOEE-Publishes-Title-V-Reporting-Guidance-Letter-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are You Qualified for Conditional No-Exposure Exclusion for Stormwater Permit Requirements?",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-you-qualified-for-conditional-no-exposure-exclusion-for-stormwater-permit-requirements",
    "url": "/news/are-you-qualified-for-conditional-no-exposure-exclusion-for-stormwater-permit-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Understanding How the PFAS Air Monitoring Plan Will Affect Your Minnesota Facility",
    "apiurl": "/restapi/customnewscontentlvl2/news/understanding-how-the-pfas-air-monitoring-plan-will-affect-your-minnesota-facility",
    "url": "/news/understanding-how-the-pfas-air-monitoring-plan-will-affect-your-minnesota-facility",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Governing Board Approves Rule 4702 Amendment for Internal Combustion Engines",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-governing-board-approves-rule-4702-amendment-for-internal-combustion-engines",
    "url": "/news/sjvapcd-governing-board-approves-rule-4702-amendment-for-internal-combustion-engines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Benefits and Risks of Optical Gas Imaging (OGI) to Oil & Gas Operators",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-benefits-and-risks-of-optical-gas-imaging-(ogi)-to-oil-gas-operators",
    "url": "/news/the-benefits-and-risks-of-optical-gas-imaging-(ogi)-to-oil-gas-operators",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Benefits and Risks of Optical Gas Imaging (OGI) to Oil & Gas Operators",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-benefits-and-risks-of-optical-gas-imaging-(ogi)-to-oil-gas-operators",
    "url": "/News/Federal/The-Benefits-and-Risks-of-Optical-Gas-Imaging-OGI-to-Oil---Gas-Operators/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Emissions Inventory Requirements for Bexar County!-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Emissions-Inventory-Requirements-for-Bexar-County!-Texas",
    "url": "/news/New-Emissions-Inventory-Requirements-for-Bexar-County!-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Compliance Still Required for Nevada Method 9 Evaluations",
    "apiurl": "/restapi/customnewscontentlvl2/news/compliance-still-required-for-nevada-method-9-evaluations",
    "url": "/news/compliance-still-required-for-nevada-method-9-evaluations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes PFAS Reporting Rule Under TSCA with 10-year Lookback",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-pfas-reporting-rule-under-tsca-with-10-year-lookback",
    "url": "/news/epa-proposes-pfas-reporting-rule-under-tsca-with-10-year-lookback",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes PFAS Reporting Rule Under TSCA with 10-year Lookback",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-pfas-reporting-rule-under-tsca-with-10-year-lookback",
    "url": "/www.trinityconsultants.com/news/epa-proposes-pfas-reporting-rule-under-tsca-with-10-year-lookback",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Accurate Emissions Reporting and Environmental Justice - Why it Matters",
    "apiurl": "/restapi/customnewscontentlvl2/news/accurate-emissions-reporting-and-environmental-justice-why-it-matters",
    "url": "/news/accurate-emissions-reporting-and-environmental-justice-why-it-matters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - September 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-sep",
    "url": "/news/good-news-sep",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - September 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-sep",
    "url": "/company/good-news---sep",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - September 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-sep",
    "url": "/news/good-news---sep",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Wyoming Triennial Emission Inventory for ALL Minor Emission Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/wyoming-triennial-emission-inventory-for-all-minor-emission-sources",
    "url": "/news/wyoming-triennial-emission-inventory-for-all-minor-emission-sources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Final Amendment to the All-Appropriate Inquiries Rule for Phase I Environmental Site Assessments",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-final-amendment-to-the-all-appropriate-inquiries-rule-for-phase-i-environmental-site-assessments",
    "url": "/news/epa-releases-final-amendment-to-the-all-appropriate-inquiries-rule-for-phase-i-environmental-site-assessments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues Temporary Waiver for Electronic Reporting of Certain Bypasses for POTWs",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-temporary-waiver-for-electronic-reporting-of-certain-bypasses-for-potws-",
    "url": "/news/tceq-issues-temporary-waiver-for-electronic-reporting-of-certain-bypasses-for-potws-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues Temporary Waiver for Electronic Reporting of Certain Bypasses for POTWs",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-temporary-waiver-for-electronic-reporting-of-certain-bypasses-for-potws-",
    "url": "/news/state/texas/tceq-issues-temporary-waiver-for-electronic-reporting-of-certain-bypasses-for-potws-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP’s Proposed Significant Fee Schedule Changes: Public Notice Period and New Administrative Permit Renewal Option-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-s-Proposed-Significant-Fee-Schedule-Changes-Public-Notice-Period-and-New-Administrative-Permit-Renewal-Option-Nevada",
    "url": "/news/NDEP-s-Proposed-Significant-Fee-Schedule-Changes-Public-Notice-Period-and-New-Administrative-Permit-Renewal-Option-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes HFC Phasedown under the AIM Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-hfc-phasedown-under-the-aim-act",
    "url": "/news/epa-finalizes-hfc-phasedown-under-the-aim-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County Proposed Updates to Surface Coating Operation Regulations (Rule 336)",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-proposed-updates-to-surface-coating-operation-regulations-(rule-336)",
    "url": "/news/maricopa-county-proposed-updates-to-surface-coating-operation-regulations-(rule-336)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County Proposed Updates to Surface Coating Operation Regulations (Rule 336)",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-proposed-updates-to-surface-coating-operation-regulations-(rule-336)",
    "url": "/states/arizona/maricopa-county-proposed-updates-to-surface-coating-operation-regulations-rule-336",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Phoenix-Mesa Metro Area Attainment Status for 2008 Ozone Standard",
    "apiurl": "/restapi/customnewscontentlvl2/news/phoenix-mesa-metro-area-attainment-status-for-2008-ozone-standard",
    "url": "/news/phoenix-mesa-metro-area-attainment-status-for-2008-ozone-standard",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Phoenix-Mesa Metro Area Attainment Status for 2008 Ozone Standard",
    "apiurl": "/restapi/customnewscontentlvl2/news/phoenix-mesa-metro-area-attainment-status-for-2008-ozone-standard",
    "url": "/www.trinityconsultants.com/news/phoenix-mesa-metro-area-attainment-status-for-2008-ozone-standard",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2021: Training",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2021-training",
    "url": "/news/best-of-2021-training",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Issues Draft NPDES Permit for Industrial Stormwater General Permit – Key Takeaways & Top Five SWPPP Compliance Issues",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-issues-draft-npdes-permit-for-industrial-stormwater-general-permit-key-takeaways-top-five-swppp-compliance-issues",
    "url": "/news/kdhe-issues-draft-npdes-permit-for-industrial-stormwater-general-permit-key-takeaways-top-five-swppp-compliance-issues",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Releases Draft Baghouse Testing Requirements Determination Guidance Document-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-Releases-Draft-Baghouse-Testing-Requirements-Determination-Guidance-Document-Nevada",
    "url": "/news/NDEP-Releases-Draft-Baghouse-Testing-Requirements-Determination-Guidance-Document-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing Vibration from Construction Projects in Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/managing-vibration-from-construction-projects-in-ontario",
    "url": "/news/managing-vibration-from-construction-projects-in-ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LRAPA Identifies Facilities to Review under New Cleaner Air Oregon Rules-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/LRAPA-Identifies-Facilities-to-Review-under-New-Cleaner-Air-Oregon-Rules-Oregon",
    "url": "/news/LRAPA-Identifies-Facilities-to-Review-under-New-Cleaner-Air-Oregon-Rules-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge Feb 2021 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/safebridge-feb-2021-newsletter",
    "url": "/news/safebridge-feb-2021-newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2015 8-hour Ozone SIP to Air Quality Board",
    "apiurl": "/restapi/customnewscontentlvl2/news/2015-8-hour-ozone-sip-to-air-quality-board",
    "url": "/news/2015-8-hour-ozone-sip-to-air-quality-board",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma DEQ Issues Revised General Permit for Oil and Gas Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-deq-issues-revised-general-permit-for-oil-and-gas-facilities",
    "url": "/news/oklahoma-deq-issues-revised-general-permit-for-oil-and-gas-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Complimentary Luncheons - Iowa Regulatory Updates-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Complimentary-Luncheons---Iowa-Regulatory-Updates-Iowa",
    "url": "/news/Complimentary-Luncheons---Iowa-Regulatory-Updates-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Earth Day Reflections from Team Trinity",
    "apiurl": "/restapi/customnewscontentlvl2/news/earth-day-reflections-from-team-trinity",
    "url": "/news/earth-day-reflections-from-team-trinity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "HFC Prohibition Rule in Effect in Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/hfc-prohibition-rule-finalized---310-cmr-7-76",
    "url": "/news/hfc-prohibition-rule-finalized---310-cmr-7-76",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "HFC Prohibition Rule in Effect in Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/hfc-prohibition-rule-finalized---310-cmr-7-76",
    "url": "/news/hfc-prohibition-rule-in-effect-in-massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "HFC Prohibition Rule in Effect in Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/hfc-prohibition-rule-finalized---310-cmr-7-76",
    "url": "/news/hfc-prohibition-rule-finalized-310-cmr-7-76",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Flare Rule Amendments Take Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-flare-rule-amendments-take-effect",
    "url": "/news/sjvapcd-flare-rule-amendments-take-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exemption and Recordkeeping Updates to SC NOx Standard",
    "apiurl": "/restapi/customnewscontentlvl2/news/exemption-and-recordkeeping-updates-to-sc-nox-standard",
    "url": "/news/exemption-and-recordkeeping-updates-to-sc-nox-standard",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exemption and Recordkeeping Updates to SC NOx Standard",
    "apiurl": "/restapi/customnewscontentlvl2/news/exemption-and-recordkeeping-updates-to-sc-nox-standard",
    "url": "/state/exemption-and-recordkeeping-updates-to-sc-nox-standard",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Midwest Environmental Compliance Conference April 23-24-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/Midwest-Environmental-Compliance-Conference-April-23-24-Nebraska",
    "url": "/news/Midwest-Environmental-Compliance-Conference-April-23-24-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to NDEP's Proposed Significant Fee Schedule Changes and Draft Administrative Permit Renewal FAQ Document-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Updates-to-NDEP's-Proposed-Significant-Fee-Schedule-Changes-and-Draft-Administrative-Permit-Renewal-FAQ-Document-Nevada",
    "url": "/news/Updates-to-NDEP's-Proposed-Significant-Fee-Schedule-Changes-and-Draft-Administrative-Permit-Renewal-FAQ-Document-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Annual Air Quality and EPCRA Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-annual-air-quality-and-epcra-reporting",
    "url": "/news/upcoming-annual-air-quality-and-epcra-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maximizing H&S Program Performance with Digital Solutions",
    "apiurl": "/restapi/customnewscontentlvl2/news/maximizing-h-s-program-performance-with-digital-solutions",
    "url": "/news/maximizing-h-s-program-performance-with-digital-solutions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "A&WMA Midwest Section Environmental Technical Conference, September 30 - October 1, 2019-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/A-WMA-Midwest-Section-Environmental-Technical-Conference-September-30---October-1-2019-Kansas",
    "url": "/news/A-WMA-Midwest-Section-Environmental-Technical-Conference-September-30---October-1-2019-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to ATCM for Chromium Electroplating and Chromic Acid Anodizing Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-atcm-for-chromium-electroplating-and-chromic-acid-anodizing-operations",
    "url": "/news/proposed-amendments-to-atcm-for-chromium-electroplating-and-chromic-acid-anodizing-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Dispersion Modeling for Colorado Minor Sources Is Changing",
    "apiurl": "/restapi/customnewscontentlvl2/news/dispersion-modeling-for-colorado-minor-sources-is-changing",
    "url": "/news/dispersion-modeling-for-colorado-minor-sources-is-changing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado Passes New Environmental Justice Bill",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-passes-new-environmental-justice-bill",
    "url": "/news/colorado-passes-new-environmental-justice-bill",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clean Cars Nevada Rulemaking Process Underway",
    "apiurl": "/restapi/customnewscontentlvl2/news/clean-cars-nevada-rulemaking-process-underway",
    "url": "/news/clean-cars-nevada-rulemaking-process-underway",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clean Cars Nevada Rulemaking Process Underway",
    "apiurl": "/restapi/customnewscontentlvl2/news/clean-cars-nevada-rulemaking-process-underway",
    "url": "/www.trinityconsultants.com/clean-cars-nevada-rulemaking-process-underway",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to Illinois Air Pollution Site Fee-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Updates-to-Illinois-Air-Pollution-Site-Fee-Illinois",
    "url": "/news/Updates-to-Illinois-Air-Pollution-Site-Fee-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge - Jun 2022 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/safebridge---jun-2022-newsletter",
    "url": "/news/safebridge---jun-2022-newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Pennsylvania DEP Proposes Revision to BAQ-GPA-GP-1",
    "apiurl": "/restapi/customnewscontentlvl2/news/pennsylvania-dep-proposes-revision-to-baq-gpa-gp-1",
    "url": "/news/pennsylvania-dep-proposes-revision-to-baq-gpa-gp-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA New Residual Risk and Technology Reviews for Chloroprene-and Ethylene Oxide-Emitting Source Categories",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-new-residual-risk-and-technology-reviews-for-chloroprene-and-ethylene-oxide-emitting-source-categories",
    "url": "/news/epa-new-residual-risk-and-technology-reviews-for-chloroprene-and-ethylene-oxide-emitting-source-categories",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA New Residual Risk and Technology Reviews for Chloroprene-and Ethylene Oxide-Emitting Source Categories",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-new-residual-risk-and-technology-reviews-for-chloroprene-and-ethylene-oxide-emitting-source-categories",
    "url": "/news/epa-new-residual-risk-and-technology-reviews-for-chloroprene--and-ethylene-oxide-emitting-source-categories",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regional Haze Rule Second Planning Period-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regional-Haze-Rule-Second-Planning-Period-Colorado",
    "url": "/news/Regional-Haze-Rule-Second-Planning-Period-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Annual Ongoing SO2 Data Requirements Rule Report",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-annual-ongoing-so2-data-requirements-rule-report",
    "url": "/news/2020-annual-ongoing-so2-data-requirements-rule-report",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Greenhouse Gases (GHGs) Reporting for 2019",
    "apiurl": "/restapi/customnewscontentlvl2/news/greenhouse-gases-(ghgs)-reporting-for-2019",
    "url": "/news/greenhouse-gases-(ghgs)-reporting-for-2019",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tennessee APCB Proposes Permit Fee Increases and Incorporation of Federal NESHAP/MACT Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/tennessee-air-pollution-control-board-considers-increase-in-fees-and-incorporation-of-federal-neshap-mact-rules",
    "url": "/news/tennessee-air-pollution-control-board-considers-increase-in-fees-and-incorporation-of-federal-neshap-mact-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tennessee APCB Proposes Permit Fee Increases and Incorporation of Federal NESHAP/MACT Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/tennessee-air-pollution-control-board-considers-increase-in-fees-and-incorporation-of-federal-neshap-mact-rules",
    "url": "/news/article-title-tennessee-air-pollution-control-board-considers-increase-in-fy2023-title-v-fees-addition-of-construction-permit-fees-for-title-v-sources-and-incorporation-by-reference-of-federal-neshap-mact-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Proposed General Permits-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-DEQ-Proposed-General-Permits-Oregon",
    "url": "/news/Oregon-DEQ-Proposed-General-Permits-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Region 8 and Wyoming DEQ Release Study of Enclosed Combustors Which May Provide a New Option for Compliance Testing",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-region-8-and-wyoming-deq-release-study-of-enclosed-combustors-which-may-provide-a-new-option-for-compliance-testing",
    "url": "/news/epa-region-8-and-wyoming-deq-release-study-of-enclosed-combustors-which-may-provide-a-new-option-for-compliance-testing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Region 8 and Wyoming DEQ Release Study of Enclosed Combustors Which May Provide a New Option for Compliance Testing",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-region-8-and-wyoming-deq-release-study-of-enclosed-combustors-which-may-provide-a-new-option-for-compliance-testing",
    "url": "/www.trinityconsultants.com/news/epa-region-8-and-wyoming-deq-release-study-of-enclosed-combustors-which-may-provide-a-new-option-for-compliance-testing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MACT Wastewater Regulations that Overlap with the BWON",
    "apiurl": "/restapi/customnewscontentlvl2/news/mact-wastewater-regulations-that-overlap-with-the-bwon",
    "url": "/news/mact-wastewater-regulations-that-overlap-with-the-bwon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Hearing for Utah Air Permitting and Emission Inventory Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/public-hearing-for-utah-air-permitting-and-emission-inventory-requirements",
    "url": "/news/public-hearing-for-utah-air-permitting-and-emission-inventory-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Vision 2020 - Centralized Data for Environmental Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/vision-2020---centralized-data-for-environmental-reporting",
    "url": "/news/vision-2020---centralized-data-for-environmental-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Request Input on Paved Road Dust Controls",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-request-input-on-paved-road-dust-controls",
    "url": "/news/adeq-request-input-on-paved-road-dust-controls",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Request Input on Paved Road Dust Controls",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-request-input-on-paved-road-dust-controls",
    "url": "/states/arizona/adeq-request-input-on-paved-road-dust-controls",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois EPA eliminates SMB exemption in compliance with US EPA mandate",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-epa-eliminates-smb-exemption-in-compliance-with-us-epa-mandate",
    "url": "/news/illinois-epa-eliminates-smb-exemption-in-compliance-with-us-epa-mandate",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest on Chicago Area Ozone Nonattainment Redesignation from Moderate to Serious-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Latest-on-Chicago-Area-Ozone-Nonattainment-Redesignation-from-Moderate-to-Serious-Illinois",
    "url": "/news/Latest-on-Chicago-Area-Ozone-Nonattainment-Redesignation-from-Moderate-to-Serious-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "GHG Emission Requirements for New On-Road Heavy-Duty Vehicle Manufacturers (Effective 1 Jan 2021)",
    "apiurl": "/restapi/customnewscontentlvl2/news/ghg-emission-requirements-for-new-on-road-heavy-duty-vehicle-manufacturers",
    "url": "/news/ghg-emission-requirements-for-new-on-road-heavy-duty-vehicle-manufacturers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes Revision to the Petition Provisions of the Part 70 Operating Permits Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/ldeq-finalizes-revision-to-the-petition-provisions-of-the-part-70-operating-permits-program",
    "url": "/news/ldeq-finalizes-revision-to-the-petition-provisions-of-the-part-70-operating-permits-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Issues Temporary Episodic Waiver for NetDMRs for Stormwater MSGP Sites",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-issues-temporary-episodic-waiver-for-netdmrs-for-stormwater-msgp-sites",
    "url": "/news/tceq-issues-temporary-episodic-waiver-for-netdmrs-for-stormwater-msgp-sites",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MCAQD Proposed RACT Certification of Rule 348 is Good News for Aerospace Manufacturing and Rework Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/mcaqd-proposed-ract-certification-of-rule-348-is-good-news-for-aerospace-manufacturing-and-rework-operations",
    "url": "/news/mcaqd-proposed-ract-certification-of-rule-348-is-good-news-for-aerospace-manufacturing-and-rework-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Affordable Clean Energy Rule Replacement and CO2 Emission Standards for the Power Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-affordable-clean-energy-rule-replacement-and-co2-emission-standards-for-the-power-industry",
    "url": "/news/epa-proposes-affordable-clean-energy-rule-replacement-and-co2-emission-standards-for-the-power-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Welding Could Require Permitting Statewide - San Diego Data Puts Air Permitting Exemptions at Risk",
    "apiurl": "/restapi/customnewscontentlvl2/news/welding-could-require-permitting-statewide-san-diego-data-puts-air-permitting-exemptions-at-risk",
    "url": "/news/welding-could-require-permitting-statewide-san-diego-data-puts-air-permitting-exemptions-at-risk",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Welding Could Require Permitting Statewide - San Diego Data Puts Air Permitting Exemptions at Risk",
    "apiurl": "/restapi/customnewscontentlvl2/news/welding-could-require-permitting-statewide-san-diego-data-puts-air-permitting-exemptions-at-risk",
    "url": "/news/san-diego-apcd-data-puts-air-permitting-exemptions-at-risk-welding-may-require-permitting-statewide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Welding Could Require Permitting Statewide - San Diego Data Puts Air Permitting Exemptions at Risk",
    "apiurl": "/restapi/customnewscontentlvl2/news/welding-could-require-permitting-statewide-san-diego-data-puts-air-permitting-exemptions-at-risk",
    "url": "/news/san-diego-apcd-data-puts-air-permitting-exemptions-at-risk---welding-may-require-permitting-statewide",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "POTW Reports and Notifications for Wastewater Discharges",
    "apiurl": "/restapi/customnewscontentlvl2/news/potw-reports-and-notifications-for-wastewater-discharges",
    "url": "/news/potw-reports-and-notifications-for-wastewater-discharges",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "POTW Reports and Notifications for Wastewater Discharges",
    "apiurl": "/restapi/customnewscontentlvl2/news/potw-reports-and-notifications-for-wastewater-discharges",
    "url": "/states/texas/potw-reports-and-notifications-for-wastewater-discharges",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Changes to NOx RACT Regulations in the District",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-changes-to-nox-ract-regulations-in-the-district",
    "url": "/news/proposed-changes-to-nox-ract-regulations-in-the-district",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Team Trinity Gears Up for March Madness",
    "apiurl": "/restapi/customnewscontentlvl2/news/team-trinity-gears-up-for-march-madness",
    "url": "/news/team-trinity-gears-up-for-march-madness",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Adds Natural Gas Processing to TRI Reporting Requirement",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-adds-natural-gas-processing-to-tri-reporting-requirement",
    "url": "/news/epa-adds-natural-gas-processing-to-tri-reporting-requirement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON Applicability at Chemical Plants",
    "apiurl": "/restapi/customnewscontentlvl2/news/bwon-applicability-at-chemical-plants",
    "url": "/news/bwon-applicability-at-chemical-plants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON Applicability at Chemical Plants",
    "apiurl": "/restapi/customnewscontentlvl2/news/bwon-applicability-at-chemical-plants",
    "url": "/federal/bwon-applicability-at-chemical-plants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Using a Multimedia Approach for Environmental Reporting Consistency",
    "apiurl": "/restapi/customnewscontentlvl2/news/using-a-multimedia-approach-for-environmental-reporting-consistency",
    "url": "/news/using-a-multimedia-approach-for-environmental-reporting-consistency",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Regulation 22 - Greenhouse Gas Reporting and Emission Reduction Requirements in Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Regulation-22---Greenhouse-Gas-Reporting-and-Emission-Reduction-Requirements-in-Colorado-Colorado",
    "url": "/news/Proposed-Regulation-22---Greenhouse-Gas-Reporting-and-Emission-Reduction-Requirements-in-Colorado-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Regulation 22 - Greenhouse Gas Reporting and Emission Reduction Requirements in Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Regulation-22---Greenhouse-Gas-Reporting-and-Emission-Reduction-Requirements-in-Colorado-Colorado",
    "url": "/news/Proposed-Regulation-22-Greenhouse-Gas-Reporting-and-Emission-Reduction-Requirements-in-Colorado-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CREG Report Released-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/CREG-Report-Released-Wyoming",
    "url": "/news/CREG-Report-Released-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised LDAR Inspection Form Template and Guidance Document-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Revised-LDAR-Inspection-Form-Template-and-Guidance-Document-Colorado",
    "url": "/news/Revised-LDAR-Inspection-Form-Template-and-Guidance-Document-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Publishes Guidance on PFAS Destruction and Disposal",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-publishes-guidance-on-pfas-destruction-and-disposal",
    "url": "/news/epa-publishes-guidance-on-pfas-destruction-and-disposal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Underground Storage Tank Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-underground-storage-tank-reporting-requirements",
    "url": "/news/changes-to-underground-storage-tank-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Underground Storage Tank Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-underground-storage-tank-reporting-requirements",
    "url": "/news/states/california/changes-to-underground-storage-tank-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Serious Ozone Nonattainment May Come Sooner-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Serious-Ozone-Nonattainment-May-Come-Sooner-Colorado",
    "url": "/news/Serious-Ozone-Nonattainment-May-Come-Sooner-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Final Plan 15 Detailing PFAS-related ELGs for Various Source Categories",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-final-plan-15-detailing-pfas-related-elgs-for-various-source-categories",
    "url": "/news/epa-releases-final-plan-15-detailing-pfas-related-elgs-for-various-source-categories",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDPHE COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/cdphe-covid-19-response",
    "url": "/news/cdphe-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nebraska Tip of the Month - Aerosol Can Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/Nebraska-Tip-of-the-Month---Aerosol-Can-Waste-Nebraska",
    "url": "/news/Nebraska-Tip-of-the-Month---Aerosol-Can-Waste-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Aims to Accelerate Schedule for Net Zero Emissions Goals",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-aims-to-accelerate-schedule-for-net-zero-emissions-goals",
    "url": "/news/massachusetts-aims-to-accelerate-schedule-for-net-zero-emissions-goals",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Announces New Environmental Justice Director and Coordinator",
    "apiurl": "/restapi/customnewscontentlvl2/news/deq-announces-new-environmental-justice-director-and-coordinator",
    "url": "/news/deq-announces-new-environmental-justice-director-and-coordinator",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Time to Review SPCC and FRPs - EPA's Top Ranked SPCC and FRP Deficiencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/time-to-review-spcc-and-frps-epas-top-ranked-spcc-and-frp-deficiencies",
    "url": "/news/time-to-review-spcc-and-frps-epas-top-ranked-spcc-and-frp-deficiencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Time to Review SPCC and FRPs - EPA's Top Ranked SPCC and FRP Deficiencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/time-to-review-spcc-and-frps-epas-top-ranked-spcc-and-frp-deficiencies",
    "url": "/www.trinityconsultants.com/news/time-to-review-spcc-and-frps---epa's-top-ranked-spcc-and-frp-deficiencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Time to Review SPCC and FRPs - EPA's Top Ranked SPCC and FRP Deficiencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/time-to-review-spcc-and-frps-epas-top-ranked-spcc-and-frp-deficiencies",
    "url": "/time-to-review-spcc-and-frps---epa's-top-ranked-spcc-and-frp-deficiencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Time to Review SPCC and FRPs - EPA's Top Ranked SPCC and FRP Deficiencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/time-to-review-spcc-and-frps-epas-top-ranked-spcc-and-frp-deficiencies",
    "url": "/news/time-to-review-spcc-and-frps---epa's-top-ranked-spcc-and-frp-deficiencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Refinery Emissions Inventory Guideline Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-refinery-emissions-inventory-guideline-amendments",
    "url": "/news/baaqmd-accepting-comments-for-refinery-emissions-inventory-guideline-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing HAPs in Air Permits: A Challenging New Reality in New Jersey-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/Managing-HAPs-in-Air-Permits-A-Challenging-New-Reality-in-New-Jersey",
    "url": "/news/Managing-HAPs-in-Air-Permits-A-Challenging-New-Reality-in-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing HAPs in Air Permits: A Challenging New Reality in New Jersey-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/Managing-HAPs-in-Air-Permits-A-Challenging-New-Reality-in-New-Jersey",
    "url": "/news/states/new-jersey/managing-haps-in-air-permits--a-challenging-new-reality-in-new-jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Managing HAPs in Air Permits: A Challenging New Reality in New Jersey-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/Managing-HAPs-in-Air-Permits-A-Challenging-New-Reality-in-New-Jersey",
    "url": "/news/Managing-HAPs-in-Air-Permits-A-Challenging-New-Reality-in-New-Jersey-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Considering Revisions to Recently Amended Refrigerant Regulation",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-considering-revisions-to-recently-amended-refrigerant-regulation",
    "url": "/news/carb-considering-revisions-to-recently-amended-refrigerant-regulation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Considering Revisions to Recently Amended Refrigerant Regulation",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-considering-revisions-to-recently-amended-refrigerant-regulation",
    "url": "/www.trinityconsultants.com/news/carb-considering-revisions-to-recently-amended-refrigerant-regulation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Introduced HB20-1265 New Air Toxics Program Proposed for Colorado-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Introduced-HB20-1265-New-Air-Toxics-Program-Proposed-for-Colorado-Colorado",
    "url": "/news/Introduced-HB20-1265-New-Air-Toxics-Program-Proposed-for-Colorado-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York State DEC Publishes Regulatory Guidance for Exemption of R&D Activities from Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-state-dec-publishes-regulatory-guidance-for-exemption-of-r-d-activities-from-permitting",
    "url": "/news/new-york-state-dec-publishes-regulatory-guidance-for-exemption-of-r-d-activities-from-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Permitting Path for Larger Covered Facilities under Hawaii’s Greenhouse Gas Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/permitting-path-for-larger-covered-facilities-under-hawaii-s-greenhouse-gas-program",
    "url": "/news/permitting-path-for-larger-covered-facilities-under-hawaii-s-greenhouse-gas-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Permitting Path for Larger Covered Facilities under Hawaii’s Greenhouse Gas Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/permitting-path-for-larger-covered-facilities-under-hawaii-s-greenhouse-gas-program",
    "url": "/states/permitting-path-for-larger-covered-facilities-under-hawaii-s-greenhouse-gas-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Method 9 Evaluation Requirements Still In Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/method-9-evaluation-requirements-still-in-effect",
    "url": "/news/method-9-evaluation-requirements-still-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Method 9 Evaluation Requirements Still In Effect",
    "apiurl": "/restapi/customnewscontentlvl2/news/method-9-evaluation-requirements-still-in-effect",
    "url": "/states/arizona/method-9-evaluation-requirements-still-in-effect",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Client Satisfaction Remains High",
    "apiurl": "/restapi/customnewscontentlvl2/news/client-satisfaction-scores-remain-high",
    "url": "/news/client-satisfaction-scores-remain-high",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia Cap and Trade Rule Approved by Air Quality Board-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Virginia-Cap-and-Trade-Rule-Approved-by-Air-Quality-Board-Virginia",
    "url": "/news/Virginia-Cap-and-Trade-Rule-Approved-by-Air-Quality-Board-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON Annual Reporting Deadline Approaching",
    "apiurl": "/restapi/customnewscontentlvl2/news/bwon-annual-reporting-coming-soon",
    "url": "/news/bwon-annual-reporting-coming-soon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California updates on Carbon Capture, Utilization and Sequestration",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-updates-on-carbon-capture-utilization-and-sequestration",
    "url": "/news/california-updates-on-carbon-capture-utilization-and-sequestration",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Reinstate Human Health Criteria for Washington State Waters",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-reinstate-human-health-criteria-for-washington",
    "url": "/news/epa-proposes-to-reinstate-human-health-criteria-for-washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Message to SafeBridge Clients: COVID-19 Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/message-to-safebridge-clients-covid-19-updates",
    "url": "/news/message-to-safebridge-clients-covid-19-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Revised Draft PM2.5 Modeling Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-revised-draft-pm2.5-modeling-guidance",
    "url": "/news/epa-releases-revised-draft-pm2.5-modeling-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Boiler MACT Affected Sources Five-Year Tune-Up-North Carolina",
    "apiurl": "/restapi/customnewscontentlvl2/news/Boiler-MACT-Affected-Sources-Five-Year-Tune-Up-North-Carolina",
    "url": "/news/Boiler-MACT-Affected-Sources-Five-Year-Tune-Up-North-Carolina",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Implementation of New Air Toxics Rules by Washington Agencies-Washington",
    "apiurl": "/restapi/customnewscontentlvl2/news/Implementation-of-New-Air-Toxics-Rules-by-Washington-Agencies-Washington",
    "url": "/news/Implementation-of-New-Air-Toxics-Rules-by-Washington-Agencies-Washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Reissues Three VPDES Permits-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/DEQ-Reissues-Three-VPDES-Permits-Virginia",
    "url": "/news/DEQ-Reissues-Three-VPDES-Permits-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Website - Expanded Expertise",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-website-expanded-expertise",
    "url": "/news/new-website-expanded-expertise",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Mississippi Department of Environmental Quality (MDEQ) News for March 2019-Mississippi",
    "apiurl": "/restapi/customnewscontentlvl2/news/Mississippi-Department-of-Environmental-Quality-(MDEQ)-News-for-March-2019-Mississippi",
    "url": "/news/Mississippi-Department-of-Environmental-Quality-(MDEQ)-News-for-March-2019-Mississippi",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah Hazardous Waste Generators Notification Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/utah-hazardous-waste-generators-notification-requirements",
    "url": "/news/utah-hazardous-waste-generators-notification-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Update - EPA Finalizes Guidance on MERPs as a Demonstration Tool for PSD Permitting -Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/Update---EPA-Finalizes-Guidance-on-MERPs-as-a-Demonstration-Tool-for-PSD-Permitting--Kentucky",
    "url": "/news/Update---EPA-Finalizes-Guidance-on-MERPs-as-a-Demonstration-Tool-for-PSD-Permitting--Kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Adds 1-Bromopropane to Clean Air Act HAP List",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-adds-1-bromopropane-to-clean-air-act-hap-list",
    "url": "/news/epa-adds-1-bromopropane-to-clean-air-act-hap-list",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Air Toxics Emission Inventory Reporting Forms Posted",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-air-toxics-emission-inventory-reporting-forms-posted",
    "url": "/news/2020-air-toxics-emission-inventory-reporting-forms-posted",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 Air Toxics Emission Inventory Reporting Forms Posted",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-air-toxics-emission-inventory-reporting-forms-posted",
    "url": "/www.trinityconsultants.com/news/2020-air-toxics-emission-inventory-reporting-forms-posted",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Increased Fees Proposed for Kansas Air Sources-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Increased-Fees-Proposed-for-Kansas-Air-Sources-Kansas",
    "url": "/news/Increased-Fees-Proposed-for-Kansas-Air-Sources-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New England and US EPA COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-england-and-us-epa-covid-19-response",
    "url": "/news/new-england-and-us-epa-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New England and US EPA COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-england-and-us-epa-covid-19-response",
    "url": "/states/massachusetts/new-england-and-us-epa-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to R307-313 and Determining Future Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-r307-313-and-determining-future-compliance",
    "url": "/news/updates-to-r307-313-and-determining-future-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts proposes amendments to 310 CMR 4.00, Timely Action Schedule and Fee Provisions-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/Massachusetts-proposes-amendments-to-310-CMR-4-00-Timely-Action-Schedule-and-Fee-Provisions-Massachusetts",
    "url": "/news/Massachusetts-proposes-amendments-to-310-CMR-4-00-Timely-Action-Schedule-and-Fee-Provisions-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR Easy Air Application System-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-Easy-Air-Application-System-Iowa",
    "url": "/news/IDNR-Easy-Air-Application-System-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine Submits Changes to SIP to Remove Portions from the OTR",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-submits-changes-to-sip-to-remove-portions-from-the-otr",
    "url": "/news/maine-submits-changes-to-sip-to-remove-portions-from-the-otr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine Submits Changes to SIP to Remove Portions from the OTR",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-submits-changes-to-sip-to-remove-portions-from-the-otr",
    "url": "/news/states/maine/maine-submits-changes-to-sip-to-remove-portions-of-maine-from-the-otr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah Attainment Designations - 2015 8-hour Ozone",
    "apiurl": "/restapi/customnewscontentlvl2/news/utah-attainment-designations---2015-8-hour-ozone",
    "url": "/news/utah-attainment-designations---2015-8-hour-ozone",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Proposed Underground Injection Control Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-proposed-underground-injection-control-program",
    "url": "/news/adeq-proposed-underground-injection-control-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Proposed Underground Injection Control Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-proposed-underground-injection-control-program",
    "url": "/states/arizona/adeq-proposed-underground-injection-control-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ Stakeholder Outreach in Preparation of Ozone Attainment Date",
    "apiurl": "/restapi/customnewscontentlvl2/news/udaq-stakeholder-outreach-in-preparation-of-ozone-attainment-date-",
    "url": "/news/udaq-stakeholder-outreach-in-preparation-of-ozone-attainment-date-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The DEQ Air Quality Advisory Council Approves Updating Rules and Schedules A December 2023 Meeting",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-department-of-environmental-quality-(deq)-air-quality-advisory-council-meeting-notice",
    "url": "/news/oklahoma-department-of-environmental-quality-(deq)-air-quality-advisory-council-meeting-notice",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Midwest Environmental Compliance Conference April 23-24, 2019-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Midwest-Environmental-Compliance-Conference-April-23-24-2019-Kansas",
    "url": "/news/Midwest-Environmental-Compliance-Conference-April-23-24-2019-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDPHE - Air Quality Control Division - Rulemaking Hearing Request to the Colorado Air Quality Control Commission",
    "apiurl": "/restapi/customnewscontentlvl2/news/cdphe---air-quality-control-division---rulemaking-hearing-request-to-the-colorado-air-quality-control-commission-",
    "url": "/news/cdphe---air-quality-control-division---rulemaking-hearing-request-to-the-colorado-air-quality-control-commission-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDPHE - Air Quality Control Division - Rulemaking Hearing Request to the Colorado Air Quality Control Commission",
    "apiurl": "/restapi/customnewscontentlvl2/news/cdphe---air-quality-control-division---rulemaking-hearing-request-to-the-colorado-air-quality-control-commission-",
    "url": "/states/colorado/cdphe---air-quality-control-division---rulemaking-hearing-request-to-the-colorado-air-quality-control-commission",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best Practices for Maximizing Flare Effectiveness",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-practices-for-maximizing-flare-effectiveness",
    "url": "/news/best-practices-for-maximizing-flare-effectiveness",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NFPA Combustible Dust Industry Consensus Standard Gives OSHA a New Tool to Cite Employers",
    "apiurl": "/restapi/customnewscontentlvl2/news/nfpa-combustible-dust-industry-consensus-standard-gives-osha-a-new-tool-to-cite-employers",
    "url": "/news/nfpa-combustible-dust-industry-consensus-standard-gives-osha-a-new-tool-to-cite-employers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NFPA Combustible Dust Industry Consensus Standard Gives OSHA a New Tool to Cite Employers",
    "apiurl": "/restapi/customnewscontentlvl2/news/nfpa-combustible-dust-industry-consensus-standard-gives-osha-a-new-tool-to-cite-employers",
    "url": "/federal/nfpa-combustible-dust-industry-consensus-standard-gives-osha-a-new-tool-to-cite-employers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Issues MSGP for Stormwater Discharges from Industrial Activity Incorporating CLCPA Considerations",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-issues-msgp-for-stormwater-discharges-from-industrial-activity-incorporating-clcpa-considerations",
    "url": "/news/new-york-issues-msgp-for-stormwater-discharges-from-industrial-activity-incorporating-clcpa-considerations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Issues MSGP for Stormwater Discharges from Industrial Activity Incorporating CLCPA Considerations",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-issues-msgp-for-stormwater-discharges-from-industrial-activity-incorporating-clcpa-considerations",
    "url": "/news/new-york-issues-msgp-for-stormwater-discharge-from-industrial-activity-incorporating-clcpa-considerations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Enforcement Considerations by Utah Air Quality Board - January 2019-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Enforcement-Considerations-by-Utah-Air-Quality-Board---January-2019-Utah",
    "url": "/news/Enforcement-Considerations-by-Utah-Air-Quality-Board---January-2019-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to Colorado Regulation 3 to Address Disproportionately Impacted Communities",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-revisions-to-colorado-regulation-3-to-address-disproportionately-impacted-communities",
    "url": "/news/proposed-revisions-to-colorado-regulation-3-to-address-disproportionately-impacted-communities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "5 Steps to Digitize EHS Reporting Processes",
    "apiurl": "/restapi/customnewscontentlvl2/news/5-steps-to-proactive-reporting-with-digital-solutions",
    "url": "/news/5-steps-to-proactive-reporting-with-digital-solutions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "5 Steps to Digitize EHS Reporting Processes",
    "apiurl": "/restapi/customnewscontentlvl2/news/5-steps-to-proactive-reporting-with-digital-solutions",
    "url": "/5-Steps-to-Digitize-EHS-Reporting-Processes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Air Program Implements New Fee Schedule-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-Air-Program-Implements-New-Fee-Schedule-Nevada",
    "url": "/news/NDEP-Air-Program-Implements-New-Fee-Schedule-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Five USP Compliance Considerations Compounding Pharmacies May Be Overlooking",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-five-usp-compliance-considerations-compounding-pharmacies-may-be-overlooking",
    "url": "/news/the-five-usp-compliance-considerations-compounding-pharmacies-may-be-overlooking",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR Air Quality Contact Meeting-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/IDNR-Air-Quality-Contact-Meeting-Iowa",
    "url": "/news/IDNR-Air-Quality-Contact-Meeting-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2019 Source Registration and/or Greenhouse Gas Forms Available to Filers-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-2019-Source-Registration-and-or-Greenhouse-Gas-Forms-Available-to-Filers-Massachusetts",
    "url": "/news/MassDEP-2019-Source-Registration-and-or-Greenhouse-Gas-Forms-Available-to-Filers-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Florida's Recent SIP Revisions Streamline the Air Operation Permit Revision Process",
    "apiurl": "/restapi/customnewscontentlvl2/news/florida-air-sip-and-state-regulatory-updates",
    "url": "/news/florida-air-sip-and-state-regulatory-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Announces CEO Transition",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-announces-ceo-transition",
    "url": "/news/trinity-consultants-announces-ceo-transition",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Policy DAR-10: NYSDEC Guidelines on Dispersion Modeling Procedures for Air Quality Impact Analysis",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-policy-dar-10-nysdec-guidelines-on-dispersion-modeling-procedures-for-air-quality-impact-analysis",
    "url": "/news/final-policy-dar-10-nysdec-guidelines-on-dispersion-modeling-procedures-for-air-quality-impact-analysis",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Policy DAR-10: NYSDEC Guidelines on Dispersion Modeling Procedures for Air Quality Impact Analysis",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-policy-dar-10-nysdec-guidelines-on-dispersion-modeling-procedures-for-air-quality-impact-analysis",
    "url": "/state/final-policy-dar-10-nysdec-guidelines-on-dispersion-modeling-procedures-for-air-quality-impact-analysis",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Red Tape Reduction for Gasoline Dispensing Facilities Goes into Effect-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Red-Tape-Reduction-for-Gasoline-Dispensing-Facilities-Goes-into-Effect-Missouri",
    "url": "/news/Red-Tape-Reduction-for-Gasoline-Dispensing-Facilities-Goes-into-Effect-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Colorado Minor Source NSR Modeling Guidelines",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-colorado-minor-source-nsr-modeling-guidelines",
    "url": "/news/proposed-colorado-minor-source-nsr-modeling-guidelines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2019 Condensate Tank Reporting Spreadsheet-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/2019-Condensate-Tank-Reporting-Spreadsheet-Colorado",
    "url": "/news/2019-Condensate-Tank-Reporting-Spreadsheet-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Idaho DEQ Updates Air Dispersion Modeling Applicability Thresholds",
    "apiurl": "/restapi/customnewscontentlvl2/news/idaho-deq-updates-air-dispersion-modeling-applicability-thresholds",
    "url": "/news/idaho-deq-updates-air-dispersion-modeling-applicability-thresholds",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Making Scents of Minnesota's New Odor Legislation",
    "apiurl": "/restapi/customnewscontentlvl2/news/making-scents-of-minnesota-new-odor-legislation",
    "url": "/news/making-scents-of-minnesota-new-odor-legislation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Five Tips for Completing Your AWIA Emergency Response Plan Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/five-tips-for-completing-your-awia-emergency-response-plan-assessment",
    "url": "/news/five-tips-for-completing-your-awia-emergency-response-plan-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Low-Carbon Coalition Publishes a Global Steel Standard to Measure and Report Carbon Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/low-carbon-coalition-publishes-a-global-steel-standard-to-measure-and-report-carbon-emissions",
    "url": "/news/low-carbon-coalition-publishes-a-global-steel-standard-to-measure-and-report-carbon-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves the Iowa Attainment Plan for Muscatine County",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-approves-iowa-attainment-plan-for-muscatine-county",
    "url": "/news/epa-approves-iowa-attainment-plan-for-muscatine-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Annual Storm Water Inspections-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Annual-Storm-Water-Inspections-Kansas",
    "url": "/news/Annual-Storm-Water-Inspections-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Regulation 6, Rule 5 Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-regulation-6-rule-5-amendments",
    "url": "/news/baaqmd-accepting-comments-for-regulation-6-rule-5-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Accepting Comments for Regulation 6, Rule 5 Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-accepting-comments-for-regulation-6-rule-5-amendments",
    "url": "/state/baaqmd-accepting-comments-for-regulation-6-rule-5-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA and US EPA COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-and-us-epa-covid-19-response-",
    "url": "/news/ohio-epa-and-us-epa-covid-19-response-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA and US EPA COVID-19 Response",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-and-us-epa-covid-19-response-",
    "url": "/states/ohio/ohio-epa-and-us-epa-covid-19-response",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Pennsylvania Governor Takes First Step to Join the Regional Greenhouse Gas Initiative-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/Pennsylvania-Governor-Takes-First-Step-to-Join-the-Regional-Greenhouse-Gas-Initiative-Pennsylvania",
    "url": "/news/Pennsylvania-Governor-Takes-First-Step-to-Join-the-Regional-Greenhouse-Gas-Initiative-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "National Waters Protection Rule Vacated in Arizona",
    "apiurl": "/restapi/customnewscontentlvl2/news/national-waters-protection-rule-vacated-in-arizona",
    "url": "/news/national-waters-protection-rule-vacated-in-arizona",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Daily Forecasts Begin April 1-Mississippi",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ozone-Daily-Forecasts-Begin-April-1-Mississippi",
    "url": "/news/Ozone-Daily-Forecasts-Begin-April-1-Mississippi",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest News from TCEQ: Air Permitting Options for the Oil and Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-news-from-tceq-air-permitting-options-for-the-oil-and-gas-industry",
    "url": "/news/latest-news-from-tceq-air-permitting-options-for-the-oil-and-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Latest News from TCEQ: Air Permitting Options for the Oil and Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/latest-news-from-tceq-air-permitting-options-for-the-oil-and-gas-industry",
    "url": "/latest-news-from-tceq-air-permitting-options-for-the-oil-and-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Change to the Annual Emission Statement Deadline in New Jersey-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/Change-to-the-Annual-Emission-Statement-Deadline-in-New-Jersey-New-Jersey",
    "url": "/news/Change-to-the-Annual-Emission-Statement-Deadline-in-New-Jersey-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Updates, Instructions, and Clarifications for the 2020 Emission Inventory",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-updates-instructions-and-clarifications-for-the-2020-emission-inventory",
    "url": "/news/tceq-updates-instructions-and-clarifications-for-the-2020-emission-inventory",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Celebrates Rob Ferry's Distinguished Service Award from the Intenational Liquid Terminals Association",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-celebrates-rob-ferry's-distinguished-service-award-from-ilta",
    "url": "/news/trinity-consultants-celebrates-rob-ferry's-distinguished-service-award-from-ilta",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Judge Blocks Oil & Gas Development on Wyoming Federal Land-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Judge-Blocks-Oil-Gas-Development-on-Wyoming-Federal-Land-Wyoming",
    "url": "/news/Judge-Blocks-Oil-Gas-Development-on-Wyoming-Federal-Land-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Releases Draft Updates to Oil and Gas GOPs",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-releases-draft-updates-to-oil-and-gas-gops",
    "url": "/news/tceq-releases-draft-updates-to-oil-and-gas-gops",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Determining the Effectiveness of the Human Safeguard",
    "apiurl": "/restapi/customnewscontentlvl2/news/determining-the-effectiveness-of-the-human-safeguard",
    "url": "/news/determining-the-effectiveness-of-the-human-safeguard",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Water Board Recommends Changes of Impaired Waterbody Listings",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-water-board-recommends-changes-of-impaired-waterbody-pollutant-combinations-in-four-regions",
    "url": "/news/california-water-board-recommends-changes-of-impaired-waterbody-pollutant-combinations-in-four-regions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Water Board Recommends Changes of Impaired Waterbody Listings",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-water-board-recommends-changes-of-impaired-waterbody-pollutant-combinations-in-four-regions",
    "url": "/news/california-Water-Board-Recommends-Changes-of-Impaired-Waterbody-Listings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA Proposes Changes to the Scheduled Maintenance and Malfunction Reporting Rule (OAC 3745-15)",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-proposes-changes-to-the-scheduled-maintenance-and-malfunction-reporting-rule-(oac-3745-15)",
    "url": "/news/ohio-epa-proposes-changes-to-the-scheduled-maintenance-and-malfunction-reporting-rule-(oac-3745-15)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes AQ380S - Project Emissions and Offset Requirements-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Finalizes-AQ380S---Project-Emissions-and-Offset-Requirements-Louisiana",
    "url": "/news/LDEQ-Finalizes-AQ380S---Project-Emissions-and-Offset-Requirements-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Kansas Tip of the Month - New Tier II Hazards-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Kansas-Tip-of-the-Month---New-Tier-II-Hazards-Kansas",
    "url": "/news/Kansas-Tip-of-the-Month---New-Tier-II-Hazards-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to the SJVAPC District Rules 4306, 4320 and 4311",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-the-sjvapc-district-rules-4306-4320-and-4311-",
    "url": "/news/proposed-amendments-to-the-sjvapc-district-rules-4306-4320-and-4311-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to the SJVAPC District Rules 4306, 4320 and 4311",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-the-sjvapc-district-rules-4306-4320-and-4311-",
    "url": "/news/state/california/proposed-amendments-to-the-sjvapc-district-rules-4306-4320-and-4311-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Visible Emissions Method 9-Mississippi",
    "apiurl": "/restapi/customnewscontentlvl2/news/Visible-Emissions-Method-9-Mississippi",
    "url": "/news/Visible-Emissions-Method-9-Mississippi",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Addressing Meteorological Data Requirements for RMP Offsite Consequence Analyses",
    "apiurl": "/restapi/customnewscontentlvl2/news/addressing-meteorological-data-requirements-for-rmp-offsite-consequence-analyses",
    "url": "/news/addressing-meteorological-data-requirements-for-rmp-offsite-consequence-analyses",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Addressing Meteorological Data Requirements for RMP Offsite Consequence Analyses",
    "apiurl": "/restapi/customnewscontentlvl2/news/addressing-meteorological-data-requirements-for-rmp-offsite-consequence-analyses",
    "url": "/News/Federal/Addressing-Meteorological-Data-Requirements-for-RMP-Offsite-Consequence-Analyses/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CDP Prominence Grows as the Go-To Carbon-Related Sustainability Disclosure Platform",
    "apiurl": "/restapi/customnewscontentlvl2/news/cdp-prominence-grows-as-the-go-to-carbon-related-sustainability-disclosure-platform",
    "url": "/news/cdp-prominence-grows-as-the-go-to-carbon-related-sustainability-disclosure-platform",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "An Inside Look: Trinity's EHS Digital Solutions Services",
    "apiurl": "/restapi/customnewscontentlvl2/news/an-inside-look-trinitys-ehs-digital-solutions-services",
    "url": "/news/an-inside-look-trinitys-ehs-digital-solutions-services",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Pennsylvania DEP COVID-19 Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/pennsylvania-dep-covid-19-updates",
    "url": "/news/pennsylvania-dep-covid-19-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Pennsylvania DEP COVID-19 Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/pennsylvania-dep-covid-19-updates",
    "url": "/states/pennsylvania/pennsylvania-dep-covid-19-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Comments Open on Missouri 2019 Monitoring Network Plan-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/Comments-Open-on-Missouri-2019-Monitoring-Network-Plan-Missouri",
    "url": "/news/Comments-Open-on-Missouri-2019-Monitoring-Network-Plan-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nitrosamines in Pharmaceuticals - an Industry Concern?",
    "apiurl": "/restapi/customnewscontentlvl2/news/nitrosamines-in-pharmaceuticals-an-industry-concern",
    "url": "/news/nitrosamines-in-pharmaceuticals-an-industry-concern",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Publishes Draft General Permit to Construct for Emergency Generators (rated 373kW – 2000 kW)-Maryland",
    "apiurl": "/restapi/customnewscontentlvl2/news/MDE-Publishes-Draft-General-Permit-to-Construct-for-Emergency-Generators-(rated-373kW-2000-kW)-Maryland",
    "url": "/news/MDE-Publishes-Draft-General-Permit-to-Construct-for-Emergency-Generators-(rated-373kW-2000-kW)-Maryland",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDNR Announces New eDMR System",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdnr-announces-new-edmr-system",
    "url": "/news/mdnr-announces-new-edmr-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDNR Announces New eDMR System",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdnr-announces-new-edmr-system",
    "url": "/news/state/missouri/mdnr-announces-new-edmr-system",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2019 Reporting Reminders-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/2019-Reporting-Reminders-Iowa",
    "url": "/news/2019-Reporting-Reminders-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Risk Assessments Now Needed for Nitrosamines in APIs and Drug Products",
    "apiurl": "/restapi/customnewscontentlvl2/news/risk-assessments-now-needed-for-nitrosamines-in-apis-and-drug",
    "url": "/news/risk-assessments-now-needed-for-nitrosamines-in-apis-and-drug",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDNR Restructures Hazardous Waste Program-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/MDNR-Restructures-Hazardous-Waste-Program-Missouri",
    "url": "/news/MDNR-Restructures-Hazardous-Waste-Program-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Terminates Temporary Policy on COVID-19 Implications for Enforcement and Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-terminates-temporary-policy-on-covid-19-implications-for-enforcement-and-compliance",
    "url": "/news/epa-terminates-temporary-policy-on-covid-19-implications-for-enforcement-and-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Terminates Temporary Policy on COVID-19 Implications for Enforcement and Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-terminates-temporary-policy-on-covid-19-implications-for-enforcement-and-compliance",
    "url": "/News/Federal/EPA-Terminates-Temporary-Policy-on-COVID-19-Implications-for-Enforcement-and-Compliance/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Montana CAAAC Meeting Updates-Montana",
    "apiurl": "/restapi/customnewscontentlvl2/news/Montana-CAAAC-Meeting-Updates-Montana",
    "url": "/news/Montana-CAAAC-Meeting-Updates-Montana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Update on PM2.5 NAAQS in Illinois - May 2019-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Update-on-PM2-5-NAAQS-in-Illinois---May-2019-Illinois",
    "url": "/news/Update-on-PM2-5-NAAQS-in-Illinois---May-2019-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Crafting Your Climate Strategy: How to Develop a Decarbonization Strategy",
    "apiurl": "/restapi/customnewscontentlvl2/news/crafting-your-climate-strategy-how-to-develop-a-decarbonization-strategy",
    "url": "/news/crafting-your-climate-strategy-how-to-develop-a-decarbonization-strategy",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sustainable Groundwater Management Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/sustainable-groundwater-management-act",
    "url": "/news/sustainable-groundwater-management-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sustainable Groundwater Management Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/sustainable-groundwater-management-act",
    "url": "/states/sustainable-groundwater-management-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado’s CDPHE Issues Renewed Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-s-cdphe-issues-renewed-stormwater-permit",
    "url": "/news/colorado-s-cdphe-issues-renewed-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Region 7 Meeting Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-region-7-meeting-updates",
    "url": "/news/epa-region-7-meeting-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Substantive Changes to LDEQ Proposed Rule AQ380-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/Substantive-Changes-to-LDEQ-Proposed-Rule-AQ380-Louisiana",
    "url": "/news/Substantive-Changes-to-LDEQ-Proposed-Rule-AQ380-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ NSR and Title V Permit Renewal Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-nsr-and-title-v-permit-renewal-applications",
    "url": "/news/tceq-nsr-and-title-v-permit-renewal-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ NSR and Title V Permit Renewal Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-nsr-and-title-v-permit-renewal-applications",
    "url": "/states/texas/tceq-nsr-and-title-v-permit-renewal-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RIDEM Published Final Rule Amendments to Several Air Quality Regulations -Rhode Island",
    "apiurl": "/restapi/customnewscontentlvl2/news/RIDEM-Published-Final-Rule-Amendments-to-Several-Air-Quality-Regulations--Rhode-Island",
    "url": "/news/RIDEM-Published-Final-Rule-Amendments-to-Several-Air-Quality-Regulations--Rhode-Island",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Regulation for Canadian Petroleum Refineries & Petrochemical Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-regulation-for-canadian-petroleum-refineries-petrochemical-facilities",
    "url": "/news/new-regulation-for-canadian-petroleum-refineries-petrochemical-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD to Remove Ammonia Split Limit from Rule Governing NOx Emissions from Boilers, Steam Generators, and Process Heaters",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-to-remove-ammonia-split-limit-from-rule-governing-nox-emissions-from-boilers-steam-generators-and-process-heaters",
    "url": "/news/scaqmd-to-remove-ammonia-split-limit-from-rule-governing-nox-emissions-from-boilers-steam-generators-and-process-heaters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD to Remove Ammonia Split Limit from Rule Governing NOx Emissions from Boilers, Steam Generators, and Process Heaters",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-to-remove-ammonia-split-limit-from-rule-governing-nox-emissions-from-boilers-steam-generators-and-process-heaters",
    "url": "/states/california/scaqmd-to-remove-ammonia-split-limit-from-rule-governing-nox-emissions-from-boilers-steam-generators-and-process-heaters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Legislative Activity from the Wyoming Joint Minerals, Business, and Economic Development Committee-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Legislative-Activity-from-the-Wyoming-Joint-Minerals-Business-and-Economic-Development-Committee-Wyoming",
    "url": "/news/Legislative-Activity-from-the-Wyoming-Joint-Minerals-Business-and-Economic-Development-Committee-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Ecology Announces Climate Commitment Rulemaking",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-ecology-announces-climate-commitment-rulemaking",
    "url": "/news/washington-ecology-announces-climate-commitment-rulemaking",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Ecology Announces Climate Commitment Rulemaking",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-ecology-announces-climate-commitment-rulemaking",
    "url": "/www.trinityconsultants.com/news/washington-ecology-announces-climate-commitment-rulemaking",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Proposes Stricter Emission Limits for Ovens, Oxidizers, and More",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-proposes-stricter-emission-limits-for-ovens-oxidizers-and-more",
    "url": "/news/scaqmd-proposes-stricter-emission-limits-for-ovens-oxidizers-and-more",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Responsible Care® Support for the Chemical, Oil and Gas Industries",
    "apiurl": "/restapi/customnewscontentlvl2/news/responsible-care-support-for-the-chemical-oil-and-gas-industries",
    "url": "/news/responsible-care-support-for-the-chemical-oil-and-gas-industries",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Responsible Care® Support for the Chemical, Oil and Gas Industries",
    "apiurl": "/restapi/customnewscontentlvl2/news/responsible-care-support-for-the-chemical-oil-and-gas-industries",
    "url": "/federal/responsible-care-support-for-the-chemical-oil-and-gas-industries",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Partner Spotlight: VelocityEHS",
    "apiurl": "/restapi/customnewscontentlvl2/news/partner-spotlight-velocityehs",
    "url": "/news/partner-spotlight-velocityehs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Feedback Requested for Sage Grouse Executive Order-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Public-Feedback-Requested-for-Sage-Grouse-Executive-Order-Wyoming",
    "url": "/news/Public-Feedback-Requested-for-Sage-Grouse-Executive-Order-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Top 10 EHS Auditing Best Practices for During and After the Pandemic",
    "apiurl": "/restapi/customnewscontentlvl2/news/top-10-ehs-auditing-best-practices-for-during-and-after-the-pandemic",
    "url": "/news/top-10-ehs-auditing-best-practices-for-during-and-after-the-pandemic",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2018 Source Registration Due on February 7, 2020 for Triennial Filers-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-2018-Source-Registration-Due-on-February-7-2020-for-Triennial-Filers-Massachusetts",
    "url": "/news/MassDEP-2018-Source-Registration-Due-on-February-7-2020-for-Triennial-Filers-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Substantial Updates to NCDAQs PSD Modeling Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/substantial-updates-to-ncdaqs-psd-modeling-guidance",
    "url": "/news/substantial-updates-to-ncdaqs-psd-modeling-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TDEC Publishes Draft Guidance on Low NOx Technology Requirements for New and Modified Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/tdec-publishes-draft-guidance-on-low-nox-technology-requirements-for-new-and-modified-sources",
    "url": "/news/tdec-publishes-draft-guidance-on-low-nox-technology-requirements-for-new-and-modified-sources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ontario MECP to Introduce Updated Land-Use Compatibility Guideline-UPDATED",
    "apiurl": "/restapi/customnewscontentlvl2/news/ontario-mecp-to-introduce-updated-land-use-compatibility-guideline",
    "url": "/news/ontario-mecp-to-introduce-updated-land-use-compatibility-guideline",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Ontario Regulation for Hazardous and Special Products (HSP)",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-ontario-regulation-for-hazardous-and-special-products-(hsp)",
    "url": "/news/new-ontario-regulation-for-hazardous-and-special-products-(hsp)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Reporting Awareness: Publicly Available Lookup Tools and Managing Perception",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-reporting-awareness-publicly-available-lookup-tools-and-managing-perception",
    "url": "/news/emissions-reporting-awareness-publicly-available-lookup-tools-and-managing-perception",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MaineDEP 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/mainedep-2020-air-monitoring-network-plan",
    "url": "/news/mainedep-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MaineDEP 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/mainedep-2020-air-monitoring-network-plan",
    "url": "/news/state/maine/mainedep-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Crafting Your Climate Strategy: How to Set GHG Targets",
    "apiurl": "/restapi/customnewscontentlvl2/news/crafting-your-climate-strategy-how-to-set-ghg-targets",
    "url": "/news/crafting-your-climate-strategy-how-to-set-ghg-targets",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2020: Training",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2020-training",
    "url": "/news/best-of-2020-training",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New NSPS OOOOa CEDRI Template Required for Annual Reports",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-nsps-ooooa-cedri-template-required-for-annual-reports",
    "url": "/news/new-nsps-ooooa-cedri-template-required-for-annual-reports",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part II: The Cause of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/PHA-Inconsistency-series---partII--The-Cause-of-PHA-Inconsistencies",
    "url": "/news/PHA-Inconsistency-series---partII--The-Cause-of-PHA-Inconsistencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part II: The Cause of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/PHA-Inconsistency-series---partII--The-Cause-of-PHA-Inconsistencies",
    "url": "/Federal/PHA-Inconsistency-Series---Part-II--The-Cause-of-PHA-Inconsistencies/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series - Part II: The Cause of PHA Inconsistencies",
    "apiurl": "/restapi/customnewscontentlvl2/news/PHA-Inconsistency-series---partII--The-Cause-of-PHA-Inconsistencies",
    "url": "/PHA-Inconsistency-series---partII--The-Cause-of-PHA-Inconsistencies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Deadline Nears on Industrial Decarbonization Federal Funding Opportunity DE-FOA-0002936",
    "apiurl": "/restapi/customnewscontentlvl2/news/deadline-nears-on-industrial-decarbonization-federal-funding-opportunity-de-foa-0002936",
    "url": "/news/deadline-nears-on-industrial-decarbonization-federal-funding-opportunity-de-foa-0002936",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Conditionally Exempt Sources and Insignificant Activity Rule Changes Deadlines-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/Conditionally-Exempt-Sources-and-Insignificant-Activity-Rule-Changes-Deadlines-Minnesota",
    "url": "/news/Conditionally-Exempt-Sources-and-Insignificant-Activity-Rule-Changes-Deadlines-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Stack Testing Final Adoption Repeal and Reenactment of R307-165",
    "apiurl": "/restapi/customnewscontentlvl2/news/stack-testing-final-adoption-repeal-and-reenactment-of-r307-165",
    "url": "/news/stack-testing-final-adoption-repeal-and-reenactment-of-r307-165",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Stack Testing Final Adoption Repeal and Reenactment of R307-165",
    "apiurl": "/restapi/customnewscontentlvl2/news/stack-testing-final-adoption-repeal-and-reenactment-of-r307-165",
    "url": "/states/stack-testing-final-adoption-repeal-and-reenactment-of-r307-165",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREAKING - NMED Releases Draft Ozone Precursor Emissions Rules for Public Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/breaking---nmed-releases-draft-ozone-precursor-emissions-rules-for-public-comment",
    "url": "/news/breaking---nmed-releases-draft-ozone-precursor-emissions-rules-for-public-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREAKING - NMED Releases Draft Ozone Precursor Emissions Rules for Public Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/breaking---nmed-releases-draft-ozone-precursor-emissions-rules-for-public-comment",
    "url": "/states/new-mexico/breaking---nmed-releases-draft-ozone-precursor-emissions-rules-for-public-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Compliance Check-Up: Are You in Compliance with CEDRI Reporting Requirements?",
    "apiurl": "/restapi/customnewscontentlvl2/news/compliance-check-up-are-you-in-compliance-with-cedri-reporting-requirements",
    "url": "/news/compliance-check-up-are-you-in-compliance-with-cedri-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Compliance Check-Up: Are You in Compliance with CEDRI Reporting Requirements?",
    "apiurl": "/restapi/customnewscontentlvl2/news/compliance-check-up-are-you-in-compliance-with-cedri-reporting-requirements",
    "url": "/news/federal/compliance-check-up--are-you-in-compliance-with-cedri-reporting-requirements-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Requirements for Manifesting a Nonhazardous Special Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-requirements-for-manifesting-a-nonhazardous-special-waste",
    "url": "/news/new-requirements-for-manifesting-a-nonhazardous-special-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Requirements for Manifesting a Nonhazardous Special Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-requirements-for-manifesting-a-nonhazardous-special-waste",
    "url": "/news/state/illinois/new-requirements-for-manifesting-a-nonhazardous-special-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised BREEZE TankESP Software Accommodates Proposed EPA Emissions Methodologies",
    "apiurl": "/restapi/customnewscontentlvl2/news/Revised-BREEZE-TankESP-Software-Accommodates-Proposed-EPA-Emissions-Methodologies",
    "url": "/news/Revised-BREEZE-TankESP-Software-Accommodates-Proposed-EPA-Emissions-Methodologies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Region 6 BACT Clarification Letter Outlines a New Interpretation of \"Achievable Technology\" for BACT Evaluations",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-region-6-bact-clarification-letter-outlines-a-new-interpretation-of-achievable-technology-for-bact-evaluations",
    "url": "/news/epa-region-6-bact-clarification-letter-outlines-a-new-interpretation-of-achievable-technology-for-bact-evaluations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COGCC’s New Mission and Appointments of New Objective Criteria for Existing Applications-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/COGCC-s-New-Mission-and-Appointments-of-New-Objective-Criteria-for-Existing-Applications-Colorado",
    "url": "/news/COGCC-s-New-Mission-and-Appointments-of-New-Objective-Criteria-for-Existing-Applications-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEQ Update - Air Quality Operating Permit Renewal Forms-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEQ-Update---Air-Quality-Operating-Permit-Renewal-Forms-Nebraska",
    "url": "/news/NDEQ-Update---Air-Quality-Operating-Permit-Renewal-Forms-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Alabama DEM Proposes Revisions to ADEM Admin. Code 335-13 (Solid Waste Program): Notice of Public Hearing",
    "apiurl": "/restapi/customnewscontentlvl2/news/alabama-dem-proposes-revisions-to-adem-admin.-code-335-13-(solid-waste-program)-notice-of-public-hearing",
    "url": "/news/alabama-dem-proposes-revisions-to-adem-admin.-code-335-13-(solid-waste-program)-notice-of-public-hearing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Guidance to Make PALs More User Friendly",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-guidance-to-make-pals-more-user-friendly-",
    "url": "/news/epa-finalizes-guidance-to-make-pals-more-user-friendly-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Guidance to Make PALs More User Friendly",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-guidance-to-make-pals-more-user-friendly-",
    "url": "/news/federal/EPA-Finalizes-Guidance-to-Make-PALs-More-User-Friendly/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Guidance to Make PALs More User Friendly",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-guidance-to-make-pals-more-user-friendly-",
    "url": "/Federal/EPA-Finalizes-Guidance-to-Make-PALs-More-User-Friendly/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Proposes Revisions to the Water Quality Regulations-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Proposes-Revisions-to-the-Water-Quality-Regulations-Louisiana",
    "url": "/news/LDEQ-Proposes-Revisions-to-the-Water-Quality-Regulations-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to MassDEP TURA Reporting - PFAS",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-massdep-tura-reporting---pfas",
    "url": "/news/updates-to-massdep-tura-reporting---pfas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Implications of the 2021 MSGP in Massachusetts and New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/implications-of-the-2021-msgp-in-massachusetts-and-new-hampshire",
    "url": "/news/implications-of-the-2021-msgp-in-massachusetts-and-new-hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP Updates AQ02 & AQ03 Comprehensive Plan Approval Application Forms-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-Updates-AQ02-AQ03-Comprehensive-Plan-Approval-Application-Forms-Massachusetts",
    "url": "/news/MassDEP-Updates-AQ02-AQ03-Comprehensive-Plan-Approval-Application-Forms-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Denver Ozone Redesignation Summary & FAQs-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Denver-Ozone-Redesignation-Summary-FAQs-Colorado",
    "url": "/news/Denver-Ozone-Redesignation-Summary-FAQs-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Comment Period on Adoption of VOC Content Standards-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Public-Comment-Period-on-Adoption-of-VOC-Content-Standards-Colorado",
    "url": "/news/Public-Comment-Period-on-Adoption-of-VOC-Content-Standards-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CA CARB 2020 Cap-and-Trade Forms and Guidance Posted",
    "apiurl": "/restapi/customnewscontentlvl2/news/ca-carb-2020-cap-and-trade-forms-and-guidance-posted",
    "url": "/news/ca-carb-2020-cap-and-trade-forms-and-guidance-posted",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Guidance - Haul Road Requirements-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEE-Guidance---Haul-Road-Requirements-Nebraska",
    "url": "/news/NDEE-Guidance---Haul-Road-Requirements-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Does an Industry Need to Worry about Increased Usage of Sanitizers in SCAQMD?",
    "apiurl": "/restapi/customnewscontentlvl2/news/does-an-industry-need-to-worry-about-increased-usage-of-sanitizers-in-scaqmd-",
    "url": "/news/does-an-industry-need-to-worry-about-increased-usage-of-sanitizers-in-scaqmd-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ISO 45001: 2018 Occupational Health and Safety Management System Standard – Implementation of Integrated Management Systems",
    "apiurl": "/restapi/customnewscontentlvl2/news/iso-45001-2018-occupational-health-and-safety-management-system-standard-implementation-of-integrated-management-systems",
    "url": "/news/iso-45001-2018-occupational-health-and-safety-management-system-standard-implementation-of-integrated-management-systems",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Revise Definition of \"Waters of the United States\"",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-revise-definition-of-waters-of-the-united-states",
    "url": "/news/epa-proposes-to-revise-definition-of-waters-of-the-united-states",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Revise Definition of \"Waters of the United States\"",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-revise-definition-of-waters-of-the-united-states",
    "url": "/News/Federal/EPA-Proposes-to-Revise-Definition-of---Waters-of-the-United-States--/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Retain Current PM10 and PM2.5 NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-retain-current-pm10-and-pm2-5-naaqs",
    "url": "/news/epa-proposes-to-retain-current-pm10-and-pm2-5-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Retain Current PM10 and PM2.5 NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-retain-current-pm10-and-pm2-5-naaqs",
    "url": "/federal/epa-proposes-to-retain-current-pm10-and-pm2-5-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PM10 Nonattainement Area Redesignation To Attainment-Montana",
    "apiurl": "/restapi/customnewscontentlvl2/news/PM10-Nonattainement-Area-Redesignation-To-Attainment-Montana",
    "url": "/news/PM10-Nonattainement-Area-Redesignation-To-Attainment-Montana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Solvent-based Cleaning Rule Revisions and New Requirements-Part 226-New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/Solvent-based-Cleaning-Rule-Revisions-and-New-Requirements-Part-226-New-York",
    "url": "/news/Solvent-based-Cleaning-Rule-Revisions-and-New-Requirements-Part-226-New-York",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Title V Fee Increase for 2021-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Title-V-Fee-Increase-for-2021-Utah",
    "url": "/news/Proposed-Title-V-Fee-Increase-for-2021-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DNREC’s RACT SIP Revision Meets All 2015 Ozone NAAQS Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/dnrec-s-ract-sip-revision-meets-all-2015-ozone-naaqs-requirements",
    "url": "/news/dnrec-s-ract-sip-revision-meets-all-2015-ozone-naaqs-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DNREC’s RACT SIP Revision Meets All 2015 Ozone NAAQS Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/dnrec-s-ract-sip-revision-meets-all-2015-ozone-naaqs-requirements",
    "url": "/news/state/delaware/dnrec-s-ract-sip-revision-meets-all-2015-ozone-naaqs-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Recent Updates to Federal \"Buy Clean\" Initiatives",
    "apiurl": "/restapi/customnewscontentlvl2/news/federal-buy-clean",
    "url": "/news/federal-buy-clean",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves Revised Virginia SIP",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-approves-revised-virginia-sip",
    "url": "/news/epa-approves-revised-virginia-sip",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Water Quality Trading Program is Finalized by LDEQ-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/Water-Quality-Trading-Program-is-Finalized-by-LDEQ-Louisiana",
    "url": "/news/Water-Quality-Trading-Program-is-Finalized-by-LDEQ-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Revising SIP for the 2010 SO2 Primary NAAQS-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/KDHE-Revising-SIP-for-the-2010-SO2-Primary-NAAQS-Kansas",
    "url": "/news/KDHE-Revising-SIP-for-the-2010-SO2-Primary-NAAQS-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Permitting Regulation Changes in Oklahoma",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-permitting-regulation-changes-in-oklahoma",
    "url": "/news/air-permitting-regulation-changes-in-oklahoma",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ Plans to Further Regulate Hot Mix Asphalt Plants",
    "apiurl": "/restapi/customnewscontentlvl2/news/udaq-plans-to-further-regulate-hot-mix-asphalt-plants",
    "url": "/news/udaq-plans-to-further-regulate-hot-mix-asphalt-plants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires WestLand Resources",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-westland-resources",
    "url": "/news/trinity-consultants-acquires-westland-resources",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional Information on NDEP’s Virtual Source Test Observation Program Pilot",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-information-on-ndep-s-virtual-source-test-observation-program-pilot",
    "url": "/news/additional-information-on-ndep-s-virtual-source-test-observation-program-pilot",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional Information on NDEP’s Virtual Source Test Observation Program Pilot",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-information-on-ndep-s-virtual-source-test-observation-program-pilot",
    "url": "/states/nevada/additional-information-on-ndep-s-virtual-source-test-observation-program-pilot",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Accurate Emissions Reporting and Environmental Justice - Why it Matters in Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/accurate-emissions-reporting-and-environmental-justice-why-it-matters-in-kentucky",
    "url": "/news/accurate-emissions-reporting-and-environmental-justice-why-it-matters-in-kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Odor Impact Service Offerings Available in Texas-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Odor-Impact-Service-Offerings-Available-in-Texas-Texas",
    "url": "/news/Odor-Impact-Service-Offerings-Available-in-Texas-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming HMBP Deadline to Include New AB 1429 Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-hmbp-deadline-to-include-new-ab-1429-requirements",
    "url": "/news/upcoming-hmbp-deadline-to-include-new-ab-1429-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming HMBP Deadline to Include New AB 1429 Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-hmbp-deadline-to-include-new-ab-1429-requirements",
    "url": "/news/states/california/upcoming-hmbp-deadline-to-include-new-ab-1429-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio Air Permitting Update - US EPA Finally Approves the Less than 10 TPY BAT Exemption in Ohio's SIP-Ohio",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ohio-Air-Permitting-Update---US-EPA-Finally-Approves-the-Less-than-10-TPY-BAT-Exemption-in-Ohio's-SIP-Ohio",
    "url": "/news/Ohio-Air-Permitting-Update---US-EPA-Finally-Approves-the-Less-than-10-TPY-BAT-Exemption-in-Ohio's-SIP-Ohio",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Stormwater ERA Reports due January 1",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-stormwater-era-reports-due-january-1",
    "url": "/news/california-stormwater-era-reports-due-january-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "South Coast AQMD Warehouse ISR Rule 2305 Immediate Action Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-warehouse-isr-rule-2305-immediate-action-guidance",
    "url": "/news/scaqmd-warehouse-isr-rule-2305-immediate-action-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Court Ruling Creates Uncertainty for Future RTR Rulemakings",
    "apiurl": "/restapi/customnewscontentlvl2/news/court-ruling-creates-uncertainty-for-future-rtr-rulemakings",
    "url": "/news/court-ruling-creates-uncertainty-for-future-rtr-rulemakings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Court Ruling Creates Uncertainty for Future RTR Rulemakings",
    "apiurl": "/restapi/customnewscontentlvl2/news/court-ruling-creates-uncertainty-for-future-rtr-rulemakings",
    "url": "/federal/court-ruling-creates-uncertainty-for-future-rtr-rulemakings",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires Aquatic Environmental Consulting Firm, Ecofish Research",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-aquatic-environmental-consulting-firm-ecofish-research",
    "url": "/news/trinity-consultants-acquires-aquatic-environmental-consulting-firm-ecofish-research",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SPCC Enforcement Action by EPA in Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/spcc-enforcement-action-by-epa-in-wyoming",
    "url": "/news/spcc-enforcement-action-by-epa-in-wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE’s Website Now Referencing New EPA Guidance Database",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-s-website-now-referencing-new-epa-guidance-database",
    "url": "/news/kdhe-s-website-now-referencing-new-epa-guidance-database",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Ecology’s Imminent SEPA GAP Rule to Require Extensive Environmental Assessments",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-ecology-s-imminent-sepa-gap-rule-to-require-extensive-environmental-assessments",
    "url": "/news/washington-ecology-s-imminent-sepa-gap-rule-to-require-extensive-environmental-assessments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Ecology’s Imminent SEPA GAP Rule to Require Extensive Environmental Assessments",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-ecology-s-imminent-sepa-gap-rule-to-require-extensive-environmental-assessments",
    "url": "/www.trinityconsultants.com/news/washington-ecology-s-imminent-sepa-gap-rule-to-require-extensive-environmental-assessments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Getting Serious: The Latest on Ozone and Regulation Number 7 RACT Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/getting-serious-the-latest-on-ozone-and-regulation-number-7-ract-requirements",
    "url": "/news/getting-serious-the-latest-on-ozone-and-regulation-number-7-ract-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA TRI Inquiries-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-TRI-Inquiries-Kansas",
    "url": "/news/EPA-TRI-Inquiries-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emission Inventory Updates - When should you be submitting an emission inventory to the NMED",
    "apiurl": "/restapi/customnewscontentlvl2/news/emission-inventory-updates-when-should-you-be-submitting-an-emission-inventory-to-the-nmed",
    "url": "/news/emission-inventory-updates-when-should-you-be-submitting-an-emission-inventory-to-the-nmed",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emission Inventory Updates - When should you be submitting an emission inventory to the NMED",
    "apiurl": "/restapi/customnewscontentlvl2/news/emission-inventory-updates-when-should-you-be-submitting-an-emission-inventory-to-the-nmed",
    "url": "/emission-inventory-updates---when-should-you-be-submitting-an-emission-inventory-to-the-nmed",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Changes to Formaldehyde Ambient Air Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-changes-to-formaldehyde-ambient-air-limits",
    "url": "/news/proposed-changes-to-formaldehyde-ambient-air-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Climate Commitment Act: Everything you Need to Know about CCA Implementation and Program Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-evergreen-state-washington-climate-commitment-act-implementation-and-program-compliance",
    "url": "/news/the-evergreen-state-washington-climate-commitment-act-implementation-and-program-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 10 - Boiling Down the Impact of New Boiler MACT Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-10-boiling-down-the-impact-of-new-boiler-mact-standards",
    "url": "/news/ehs-quarterly-issue-10-boiling-down-the-impact-of-new-boiler-mact-standards",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Nonattainment Redesignation - How Does it Impact your Permitting Plans for 2020?-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ozone-Nonattainment-Redesignation---How-Does-it-Impact-your-Permitting-Plans-for-2020--Texas",
    "url": "/news/Ozone-Nonattainment-Redesignation---How-Does-it-Impact-your-Permitting-Plans-for-2020--Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Finalizes Updates to Hazardous Waste Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-finalizes-updates-to-hazardous-waste-regulations",
    "url": "/news/mde-finalizes-updates-to-hazardous-waste-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Tip of the Month - Spill Plan Compliance-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/Tip-of-the-Month---Spill-Plan-Compliance-Nebraska",
    "url": "/news/Tip-of-the-Month---Spill-Plan-Compliance-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Tips and Resources for Winter Storm Recovery",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-tips-and-resources-for-winter-storm-recovery",
    "url": "/news/environmental-tips-and-resources-for-winter-storm-recovery",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Quality Research in Utah-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Air-Quality-Research-in-Utah-Utah",
    "url": "/news/Air-Quality-Research-in-Utah-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Proposed Air Quality Updates-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Proposed-Air-Quality-Updates-Louisiana",
    "url": "/news/LDEQ-Proposed-Air-Quality-Updates-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Publishes Rule 4306 and Rule 4320 Draft Rule Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-publishes-rule-4306-and-rule-4320-draft-rule-amendments",
    "url": "/news/sjvapcd-publishes-rule-4306-and-rule-4320-draft-rule-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Publishes Rule 4306 and Rule 4320 Draft Rule Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-publishes-rule-4306-and-rule-4320-draft-rule-amendments",
    "url": "/states/california/sjvapcd-publishes-rule-4306-and-rule-4320-draft-rule-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "National Emission Standards for Hazardous Air Pollutants: Generic Maximum Achievable Control Technology Standards for Ethylene Production Residual Risk and Technology Review",
    "apiurl": "/restapi/customnewscontentlvl2/news/national-emission-standards-for-hazardous-air-pollutants-generic-maximum-achievable-control-technology-standards-for-ethylene-production-residual-risk-and-technology-review",
    "url": "/news/national-emission-standards-for-hazardous-air-pollutants-generic-maximum-achievable-control-technology-standards-for-ethylene-production-residual-risk-and-technology-review",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "National Emission Standards for Hazardous Air Pollutants: Generic Maximum Achievable Control Technology Standards for Ethylene Production Residual Risk and Technology Review",
    "apiurl": "/restapi/customnewscontentlvl2/news/national-emission-standards-for-hazardous-air-pollutants-generic-maximum-achievable-control-technology-standards-for-ethylene-production-residual-risk-and-technology-review",
    "url": "/federal/neshap-generic-emact-standards-rtr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Missouri Hazardous Waste Fee Increase Fails in Missouri General Assembly",
    "apiurl": "/restapi/customnewscontentlvl2/news/missouri-hazardous-waste-fee-increase-fails-in-missouri-general-assembly",
    "url": "/news/missouri-hazardous-waste-fee-increase-fails-in-missouri-general-assembly",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Missouri Hazardous Waste Fee Increase Fails in Missouri General Assembly",
    "apiurl": "/restapi/customnewscontentlvl2/news/missouri-hazardous-waste-fee-increase-fails-in-missouri-general-assembly",
    "url": "/states/missouri/missouri-hazardous-waste-fee-increase-fails-in-missouri-general-assembly",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to New Boiler Rules: How to Attain and Maintain Compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-new-boiler-rules-how-to-attain-and-maintain-compliance",
    "url": "/news/updates-to-new-boiler-rules-how-to-attain-and-maintain-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE  Update - KEIMS-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/KDHE-Update---KEIMS-Kansas",
    "url": "/news/KDHE-Update---KEIMS-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Launches New Online Portal",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-launches-new-online-portal",
    "url": "/news/oregon-deq-launches-new-online-portal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates Air Quality Performance Testing Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-air-quality-performance-testing-guidance",
    "url": "/news/kdhe-updates-air-quality-performance-testing-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates Air Quality Performance Testing Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-air-quality-performance-testing-guidance",
    "url": "/news/state/kansas/kdhe-updates-air-quality-performance-testing-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Boiler MACT is Effective in NC on May 20, 2019-North Carolina",
    "apiurl": "/restapi/customnewscontentlvl2/news/Boiler-MACT-is-Effective-in-NC-on-May-20-2019-North-Carolina",
    "url": "/news/Boiler-MACT-is-Effective-in-NC-on-May-20-2019-North-Carolina",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulations Respecting Reduction in the Release of Methane and Certain Volatile Organic Compounds (Upstream Oil and Gas Sector)-Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-Ontario",
    "url": "/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-Ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The BWON is Making a Comeback",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-bwon-is-making-a-comeback",
    "url": "/news/the-bwon-is-making-a-comeback",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD AB 617 Expedited BARCT Rule Evaluation Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-ab-617-expedited-barct-rule-evaluation-update",
    "url": "/news/sjvapcd-ab-617-expedited-barct-rule-evaluation-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NMED Begins Initial Stakeholder Process for Ozone Attainment Initiative (OAI)-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/NMED-Begins-Initial-Stakeholder-Process-for-Ozone-Attainment-Initiative-(OAI)-New-Mexico",
    "url": "/news/NMED-Begins-Initial-Stakeholder-Process-for-Ozone-Attainment-Initiative-(OAI)-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Strategically Addressing PSD Calculations-Florida",
    "apiurl": "/restapi/customnewscontentlvl2/news/Strategically-Addressing-PSD-Calculations-Florida",
    "url": "/news/Strategically-Addressing-PSD-Calculations-Florida",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Suspends Rule Development for Regulation 13, Rule 2 and Regulation 13, Rule 3",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-suspends-rule-development-for-regulation-13-rule-2-and-regulation-13-rule-3",
    "url": "/news/baaqmd-suspends-rule-development-for-regulation-13-rule-2-and-regulation-13-rule-3",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Suspends Rule Development for Regulation 13, Rule 2 and Regulation 13, Rule 3",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-suspends-rule-development-for-regulation-13-rule-2-and-regulation-13-rule-3",
    "url": "/states/baaqmd-suspends-rule-development-for-regulation-13-rule-2-and-regulation-13-rule-3",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2020: Industry Trade Shows & Presentations",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2020-industry-trade-shows-presentations",
    "url": "/news/best-of-2020-industry-trade-shows-presentations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ to Amend Source Category Exemptions-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/UDAQ-to-Amend-Source-Category-Exemptions-Utah",
    "url": "/news/UDAQ-to-Amend-Source-Category-Exemptions-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Adds Short-Term NAAQS Modeling Requirements to Minor Source Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-adds-short-term-naaqs-modeling-requirements-to-minor-source-permitting",
    "url": "/news/oregon-deq-adds-short-term-naaqs-modeling-requirements-to-minor-source-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RCRA Quick Reference Guides – What is Really Required?",
    "apiurl": "/restapi/customnewscontentlvl2/news/rcra-quick-reference-guides-what-is-really-required",
    "url": "/news/rcra-quick-reference-guides-what-is-really-required",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Notice of Public Hearing: ADEM Admin Code 335-6 (Water Quality Program UST)",
    "apiurl": "/restapi/customnewscontentlvl2/news/notice-of-public-hearing-adem-admin-code-335-6-(water-quality-program-ust)",
    "url": "/news/notice-of-public-hearing-adem-admin-code-335-6-(water-quality-program-ust)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Chemical Manufacturers Section 114 ICR/New RTR Reviews",
    "apiurl": "/restapi/customnewscontentlvl2/news/chemical-manufacturers-section-114-icr-new-rtr-reviews",
    "url": "/news/chemical-manufacturers-section-114-icr-new-rtr-reviews",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires EV Sargent LLC to Enhance Toxicology and Occupational Health Practice",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-ev-sargent-llc-to-enhance-toxicology-and-occupational-health-practice",
    "url": "/news/trinity-acquires-ev-sargent-llc-to-enhance-toxicology-and-occupational-health-practice",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Incorporating Mobile Solutions in EHS Information Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/incorporating-mobile-solutions-in-ehs-information-management",
    "url": "/news/incorporating-mobile-solutions-in-ehs-information-management",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Incorporating Mobile Solutions in EHS Information Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/incorporating-mobile-solutions-in-ehs-information-management",
    "url": "/News/Federal/Incorporating-Mobile-Solutions-in-EHS-Information-Management/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Rulemaking - Chapter 342: Exemption for Certain Quarries from the Aggregate Production Operation Registration Requirements of Texas Water Code, Chapter 28A-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Rulemaking---Chapter-342-Exemption-for-Certain-Quarries-from-the-Aggregate-Production-Operation-Registration-Requirements-of-Texas-Water-Code-Chapter-28A-Texas",
    "url": "/news/Proposed-Rulemaking---Chapter-342-Exemption-for-Certain-Quarries-from-the-Aggregate-Production-Operation-Registration-Requirements-of-Texas-Water-Code-Chapter-28A-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "''Fugitive'' Sources Impacting Source Determinations-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/''Fugitive''-Sources-Impacting-Source-Determinations-Colorado",
    "url": "/news/''Fugitive''-Sources-Impacting-Source-Determinations-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NY Proposes New Rule aligned with Climate Leadership and Community Protection Act for Oil and Natural Gas Sector",
    "apiurl": "/restapi/customnewscontentlvl2/news/ny-proposes-new-rule-aligned-with-climate-leadership-and-community-protection-act-for-oil-and-natural-gas-sector",
    "url": "/news/ny-proposes-new-rule-aligned-with-climate-leadership-and-community-protection-act-for-oil-and-natural-gas-sector",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia DEQ Proposes Changes to Nonmetallic Mineral Processing General Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/virginia-deq-proposes-changes-to-nonmetallic-mineral-processing-general-permit",
    "url": "/news/virginia-deq-proposes-changes-to-nonmetallic-mineral-processing-general-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON and QQQ Overlap",
    "apiurl": "/restapi/customnewscontentlvl2/news/BWON-and-QQQ-Overlap",
    "url": "/news/BWON-and-QQQ-Overlap",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed California Regional Permit Significantly Expands Stormwater Regulations for Commercial, Industrial, and Institutional Facilities in Los Angeles County",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-california-regional-permit-significantly-expands-stormwater-regulations-for-commercial-industrial-and-institutional-facilities-in-los-angeles-county",
    "url": "/news/proposed-california-regional-permit-significantly-expands-stormwater-regulations-for-commercial-industrial-and-institutional-facilities-in-los-angeles-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WOTUS Update AND Impact to Protected Wetlands",
    "apiurl": "/restapi/customnewscontentlvl2/news/wotus-update-and-impact-to-protected-wetlands",
    "url": "/news/wotus-update-and-impact-to-protected-wetlands",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WOTUS Update AND Impact to Protected Wetlands",
    "apiurl": "/restapi/customnewscontentlvl2/news/wotus-update-and-impact-to-protected-wetlands",
    "url": "/federal/wotus-update-and-impact-to-protected-wetlands",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Inflation Reduction Act: An Industry Guide to Energy and Climate-Related Incentives, Risks, and Opportunities",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-inflation-reduction-act-an-industry-guide-to-energy-and-climate-related-incentives-risks-and-opportunities",
    "url": "/news/the-inflation-reduction-act-an-industry-guide-to-energy-and-climate-related-incentives-risks-and-opportunities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizing Amendments to Boiler MACT, Including Changes to Emission Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizing-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "url": "/news/epa-finalizing-amendments-to-boiler-mact-including-changes-to-emission-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Proposes to Hold Warehouse Owners Responsible for Truck Emissions, Other Districts Likely to Follow",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-proposes-to-hold-warehouse-owners-responsible-for-truck-emissions-other-districts-likely-to-follow",
    "url": "/news/scaqmd-proposes-to-hold-warehouse-owners-responsible-for-truck-emissions-other-districts-likely-to-follow",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD Proposes to Hold Warehouse Owners Responsible for Truck Emissions, Other Districts Likely to Follow",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-proposes-to-hold-warehouse-owners-responsible-for-truck-emissions-other-districts-likely-to-follow",
    "url": "/www.trinityconsultants.com/news/scaqmd-proposes-to-hold-warehouse-owners-responsible-for-truck-emissions-other-districts-likely-to-follow",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Proposes New Regulations to Reduce Methane Emissions from Natural Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-proposes-new-regulations-to-reduce-methane-emissions-from-natural-gas-industry",
    "url": "/news/mde-proposes-new-regulations-to-reduce-methane-emissions-from-natural-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Report Released by Secretary of Natural Resources on Revitalizing Environmental Protection in Virginia-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Report-Released-by-Secretary-of-Natural-Resources-on-Revitalizing-Environmental-Protection-in-Virginia-Virginia",
    "url": "/news/Report-Released-by-Secretary-of-Natural-Resources-on-Revitalizing-Environmental-Protection-in-Virginia-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Highlights from the SB 19-181 Stakeholder Meeting-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Highlights-from-the-SB-19-181-Stakeholder-Meeting-Colorado",
    "url": "/news/Highlights-from-the-SB-19-181-Stakeholder-Meeting-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "IDNR EASY Air Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/idnr-easy-air-updates",
    "url": "/news/idnr-easy-air-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "From Marginal to Moderate: Can Northern Wasatch Front Prevent Ozone Nonattainment Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/from-marginal-to-moderate-can-northern-wasatch-front-prevent-ozone-nonattainment-reclassification",
    "url": "/news/from-marginal-to-moderate-can-northern-wasatch-front-prevent-ozone-nonattainment-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois Pollution Control Board Adopts New and Amended Rules to Establish PSD Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-pollution-control-board-adopts-new-and-amended-rules-to-establish-psd-permitting-program",
    "url": "/news/illinois-pollution-control-board-adopts-new-and-amended-rules-to-establish-psd-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois Pollution Control Board Adopts New and Amended Rules to Establish PSD Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/illinois-pollution-control-board-adopts-new-and-amended-rules-to-establish-psd-permitting-program",
    "url": "/states/illinois/illinois-pollution-control-board-adopts-new-and-amended-rules-to-establish-psd-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Revision to the Birmingham Area Limited Maintenance Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-revision-to-the-birmingham-area-limited-maintenance-plan",
    "url": "/news/epa-proposes-revision-to-the-birmingham-area-limited-maintenance-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California DTSC Draft SB 673 Framework Invites Environmental Justice Considerations in Permitting Process",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-dtsc-draft-sb-673-framework-invites-environmental-justice-considerations-in-permitting-process",
    "url": "/news/california-dtsc-draft-sb-673-framework-invites-environmental-justice-considerations-in-permitting-process",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes to Disapprove Interstate Transport Elements of 19 States’ Ozone SIPs",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-to-disapprove-interstate-transport-elements-of-19-states-ozone-sips",
    "url": "/news/epa-proposes-to-disapprove-interstate-transport-elements-of-19-states-ozone-sips",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Climate Accountability Partner Bills to Have Far Reaching Climate Disclosure Impact",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-climate-accountability-partner-bills-to-have-far-reaching-climate-disclosure-impact",
    "url": "/news/california-climate-accountability-partner-bills-to-have-far-reaching-climate-disclosure-impact",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PM2.5 Attainment Determination – Currently in Public Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/pm2-5-attainment-determination-currently-in-public-comment",
    "url": "/news/pm2-5-attainment-determination-currently-in-public-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PM2.5 Attainment Determination – Currently in Public Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/pm2-5-attainment-determination-currently-in-public-comment",
    "url": "/states/pm2-5-attainment-determination-currently-in-public-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Optimizing Workplace Ventilation to Reduce the Spread of COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/optimizing-workplace-ventilation-to-reduce-the-spread-of-covid-19",
    "url": "/news/optimizing-workplace-ventilation-to-reduce-the-spread-of-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Optimizing Workplace Ventilation to Reduce the Spread of COVID-19",
    "apiurl": "/restapi/customnewscontentlvl2/news/optimizing-workplace-ventilation-to-reduce-the-spread-of-covid-19",
    "url": "/news/federal/optimizing-workplace-ventilation-to-reduce-the-spread-of-covid-19",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOE Issues RFI on Establishing a Manufacturing Institute Focused on Decarbonization of the Industrial Sector",
    "apiurl": "/restapi/customnewscontentlvl2/news/doe-issues-rfi-on-establishing-a-manufacturing-institute-decarbonization-of-industrial-sector",
    "url": "/news/doe-issues-rfi-on-establishing-a-manufacturing-institute-decarbonization-of-industrial-sector",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Phase 2 GHG Emission Standards for Heavy-Duty Vehicles Effective for 2021 and Later Model Years",
    "apiurl": "/restapi/customnewscontentlvl2/news/phase-2-ghg-emission-standards-for-heavy-duty-vehicles-effective-for-2021-and-later-model-years",
    "url": "/news/phase-2-ghg-emission-standards-for-heavy-duty-vehicles-effective-for-2021-and-later-model-years",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Rule Requires Federal Contractors to Disclose GHG Emissions and Set Reduction Targets",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-rule-requires-federal-contractors-to-disclose-ghg-emissions-and-set-reduction-targets",
    "url": "/news/proposed-rule-requires-federal-contractors-to-disclose-ghg-emissions-and-set-reduction-targets",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DTSC Proposes Adoption of Stringent Provisions of RCRA Generator Improvements Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/dtsc-proposes-adoption-of-stringent-provisions-of-rcra-generator-improvements-rule",
    "url": "/news/dtsc-proposes-adoption-of-stringent-provisions-of-rcra-generator-improvements-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Uptick in Odor Complaints? Why Odor Nuisances Rise when the Temperature Drops",
    "apiurl": "/restapi/customnewscontentlvl2/news/uptick-in-odor-complaints-why-odor-nuisances-rise-when-the-temperature-drops",
    "url": "/news/uptick-in-odor-complaints-why-odor-nuisances-rise-when-the-temperature-drops",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Virginia DEQ’s New Spill Reporting Portal",
    "apiurl": "/restapi/customnewscontentlvl2/news/virginia-deqs-new-spill-reporting-portal",
    "url": "/news/virginia-deqs-new-spill-reporting-portal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Denver Reclassified as a Severe Nonattainment Area for Ozone",
    "apiurl": "/restapi/customnewscontentlvl2/news/denver-reclassified-as-a-severe-nonattainment-area-for-ozone",
    "url": "/news/denver-reclassified-as-a-severe-nonattainment-area-for-ozone",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Rule Changes affecting Chemical Plants (e.g., SOCMI and Polymers & Resins)",
    "apiurl": "/restapi/customnewscontentlvl2/news/rule-changes-affecting-chemical-plants-(e.g.-socmi-and-polymers-resins)",
    "url": "/news/rule-changes-affecting-chemical-plants-(e.g.-socmi-and-polymers-resins)",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA Updates Industrial Stormwater Permit",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-updates-industrial-stormwater-permit",
    "url": "/news/ohio-epa-updates-industrial-stormwater-permit",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Waste Discharge Requirements for Winery Process Water",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-waste-discharge-requirements-for-winery-process-water",
    "url": "/news/california-waste-discharge-requirements-for-winery-process-water",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Waste Discharge Requirements for Winery Process Water",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-waste-discharge-requirements-for-winery-process-water",
    "url": "/states/california/california-waste-discharge-requirements-for-winery-process-water",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "5 Tips for Success for Minnesota Air Quality Modeling",
    "apiurl": "/restapi/customnewscontentlvl2/news/5-tips-for-success-for-minnesota-air-quality-modeling",
    "url": "/news/5-tips-for-success-for-minnesota-air-quality-modeling",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Changes to Startup, Shutdown, and Malfunction (SSM) Rules are Coming for Facilities in Georgia",
    "apiurl": "/restapi/customnewscontentlvl2/news/changes-to-startup-shutdown-and-malfunction-(ssm)-rules-are-coming-for-facilities-in-georgia",
    "url": "/news/changes-to-startup-shutdown-and-malfunction-(ssm)-rules-are-coming-for-facilities-in-georgia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MPCA Adopts Proposed Exempt Source and Conditionally Insignificant Activities Rulemaking Updates-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/MPCA-Adopts-Proposed-Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-Minnesota",
    "url": "/news/MPCA-Adopts-Proposed-Exempt-Source-and-Conditionally-Insignificant-Activities-Rulemaking-Updates-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Colorado Regulation 7: Updated Inspection Requirements for Tanks Above 2 TPY VOC",
    "apiurl": "/restapi/customnewscontentlvl2/news/colorado-regulation-7-updated-inspection-requirements-for-tanks-above-2-tpy-voc",
    "url": "/news/colorado-regulation-7-updated-inspection-requirements-for-tanks-above-2-tpy-voc",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CTDEEP Draft 2020 Air Monitoring Network Plan and 2020 Five-Year Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/ctdeep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "url": "/news/ctdeep-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revisions to Wyoming Waste Rules-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Revisions-to-Wyoming-Waste-Rules-Wyoming",
    "url": "/news/Revisions-to-Wyoming-Waste-Rules-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Mitigating Risk with Proactive EHS Compliance Management",
    "apiurl": "/restapi/customnewscontentlvl2/news/mitigating-risk-with-proactive-ehs-compliance-management",
    "url": "/news/mitigating-risk-with-proactive-ehs-compliance-management",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Holds Workshop on Statewide Emissions Inventory of Locomotives",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-holds-workshop-on-statewide-emissions-inventory-of-locomotives",
    "url": "/news/carb-holds-workshop-on-statewide-emissions-inventory-of-locomotives",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Holds Workshop on Statewide Emissions Inventory of Locomotives",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-holds-workshop-on-statewide-emissions-inventory-of-locomotives",
    "url": "/news/states/california/carb-holds-workshop-on-statewide-emissions-inventory-of-locomotives",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma DEQ Air Quality Advisory Council Updates Oklahoma Rules on Air Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-deq-air-quality-advisory-council-updates-oklahoma-rules-on-air-permitting",
    "url": "/news/oklahoma-deq-air-quality-advisory-council-updates-oklahoma-rules-on-air-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York to Lower Ozone Season NOx RACT Limits for SCCTs-New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-York-to-Lower-Ozone-Season-NOx-RACT-Limits-for-SCCTs-New-York",
    "url": "/news/New-York-to-Lower-Ozone-Season-NOx-RACT-Limits-for-SCCTs-New-York",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York, N. New Jersey and Long Island May be Reclassified as Serious Nonattainment Areas-New York",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-York-N-New-Jersey-and-Long-Island-May-be-Reclassified-as-Serious-Nonattainment-Areas-New-York",
    "url": "/news/New-York-N-New-Jersey-and-Long-Island-May-be-Reclassified-as-Serious-Nonattainment-Areas-New-York",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOE Initiatives to Assist with Industrial Decarbonization",
    "apiurl": "/restapi/customnewscontentlvl2/news/doe-initiatives-to-assist-with-industrial-decarbonization",
    "url": "/news/doe-initiatives-to-assist-with-industrial-decarbonization",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Routine or Predictable Emissions (ROPE) – New Capture and Recovery Requirements in Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/routine-or-predictable-emissions-(rope)-new-capture-and-recovery-requirements-in-colorado",
    "url": "/news/routine-or-predictable-emissions-(rope)-new-capture-and-recovery-requirements-in-colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Rule: Alabama State Implementation Plan Revisions - CSAPR",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-alabama-state-implementation-plan-revisions---csapr",
    "url": "/news/final-rule-alabama-state-implementation-plan-revisions---csapr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Rule: Alabama State Implementation Plan Revisions - CSAPR",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-alabama-state-implementation-plan-revisions---csapr",
    "url": "/states/alabama/final-rule-alabama-state-implementation-plan-revisions-csapr",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Decision to Remove Air Pollution Nuisance Rule from Ohio SIP Finalized",
    "apiurl": "/restapi/customnewscontentlvl2/news/decision-to-remove-air-pollution-nuisance-rule-from-ohio-sip-finalized",
    "url": "/news/decision-to-remove-air-pollution-nuisance-rule-from-ohio-sip-finalized",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Decision to Remove Air Pollution Nuisance Rule from Ohio SIP Finalized",
    "apiurl": "/restapi/customnewscontentlvl2/news/decision-to-remove-air-pollution-nuisance-rule-from-ohio-sip-finalized",
    "url": "/www.trinityconsultants.com/news/decision-to-remove-air-pollution-nuisance-rule-from-ohio-sip-finalized",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Cleveland and Cincinnati Non-Attainment Re-designation",
    "apiurl": "/restapi/customnewscontentlvl2/news/cleveland-and-cincinnati-non-attainment-re-designation",
    "url": "/news/cleveland-and-cincinnati-non-attainment-re-designation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Department of Air Quality Renamed to Department of Environment and Sustainability-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Department-of-Air-Quality-Renamed-to-Department-of-Environment-and-Sustainability-Nevada",
    "url": "/news/Department-of-Air-Quality-Renamed-to-Department-of-Environment-and-Sustainability-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "July 2019 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/July-2019-Newsletter",
    "url": "/news/July-2019-Newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Proposes Renewed State/NPDES General Discharge Permit for Stormwater",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-proposes-renewed-state-npdes-general-discharge-permit-for-stormwater",
    "url": "/news/mde-proposes-renewed-state-npdes-general-discharge-permit-for-stormwater",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Environmental Justice – What the Chemical and Petroleum Refining Sectors Should Know",
    "apiurl": "/restapi/customnewscontentlvl2/news/environmental-justice-what-the-chemical-and-petroleum-refining-sectors-should-know",
    "url": "/news/environmental-justice-what-the-chemical-and-petroleum-refining-sectors-should-know",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Public Comment Period Open for Las Vegas Valley Carbon Monoxide Maintenance Plan-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Public-Comment-Period-Open-for-Las-Vegas-Valley-Carbon-Monoxide-Maintenance-Plan-Nevada",
    "url": "/news/Public-Comment-Period-Open-for-Las-Vegas-Valley-Carbon-Monoxide-Maintenance-Plan-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Control Equipment Voluntary Monitoring Considerations",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-control-equipment-voluntary-monitoring-considerations",
    "url": "/news/emissions-control-equipment-voluntary-monitoring-considerations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Control Equipment Voluntary Monitoring Considerations",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-control-equipment-voluntary-monitoring-considerations",
    "url": "/state/emissions-control-equipment-voluntary-monitoring-considerations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Department of Ecology – Stormwater Management Manual Updates Coming in 2024",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-department-of-ecology-stormwater-management-manual-updates-coming-in-2024",
    "url": "/news/washington-department-of-ecology-stormwater-management-manual-updates-coming-in-2024",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Launches Environmental Sustainability Research Report",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-launches-environmental-sustainability-research-report",
    "url": "/news/trinity-consultants-launches-environmental-sustainability-research-report",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON Compliance Date – August 12, 2023 – Are You Ready?",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-compliance-date-august-12-2023-are-you-ready",
    "url": "/news/mon-compliance-date-august-12-2023-are-you-ready",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Biden Administration Prepping for Methane Actions",
    "apiurl": "/restapi/customnewscontentlvl2/news/biden-administration-prepping-for-methane-actions",
    "url": "/news/biden-administration-prepping-for-methane-actions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Biden Administration Prepping for Methane Actions",
    "apiurl": "/restapi/customnewscontentlvl2/news/biden-administration-prepping-for-methane-actions",
    "url": "/www.trinityconsultants.com/news/biden-administration-prepping-for-methane-actions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "D.C. Department of Energy & Environment Finalizes Underground Storage Tank Regulations-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/D-C-Department-of-Energy-Environment-Finalizes-Underground-Storage-Tank-Regulations-Washington-DC",
    "url": "/news/D-C-Department-of-Energy-Environment-Finalizes-Underground-Storage-Tank-Regulations-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Complex World of CAA and RCRA LDAR Overlap Provisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-complex-world-of-caa-and-rcra-ldar-overlap-provisions",
    "url": "/news/the-complex-world-of-caa-and-rcra-ldar-overlap-provisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Complex World of CAA and RCRA LDAR Overlap Provisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-complex-world-of-caa-and-rcra-ldar-overlap-provisions",
    "url": "/www.trinityconsultants.com/news/the-complex-world-of-caa-and-rcra-ldar-overlap-provisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2021: News & Publications",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2021-news-publications",
    "url": "/news/best-of-2021-news-publications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Additional Information on Clark County DAQ's New Emissions Statement Requirement",
    "apiurl": "/restapi/customnewscontentlvl2/news/additional-information-on-clark-county-daqs-new-emissions-statement-requirement",
    "url": "/news/additional-information-on-clark-county-daqs-new-emissions-statement-requirement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Short-Term Guidance for Storage Tanks Has Been Updated-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/TCEQ-Short-Term-Guidance-for-Storage-Tanks-Has-Been-Updated-Texas",
    "url": "/news/TCEQ-Short-Term-Guidance-for-Storage-Tanks-Has-Been-Updated-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD’s Permitting Program Enhancements",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-s-permitting-program-enhancements",
    "url": "/news/baaqmd-s-permitting-program-enhancements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Cut Allowable NOx Emissions from Boilers and Heaters",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-cut-allowable-nox-emissions-from-boilers-and-heaters",
    "url": "/news/sjvapcd-cut-allowable-nox-emissions-from-boilers-and-heaters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Cut Allowable NOx Emissions from Boilers and Heaters",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-cut-allowable-nox-emissions-from-boilers-and-heaters",
    "url": "/news/sjvapcd-adoption-of-rule-4306-rule-4320-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Has Proposed a More Stringent PM2.5 NAAQS – How Might This Affect My Company?",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-has-proposed-a-more-stringent-pm2.5-naaqs-how-might-this-affect-my-company",
    "url": "/news/epa-has-proposed-a-more-stringent-pm2.5-naaqs-how-might-this-affect-my-company",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New York Defines Future Greenhouse Gas Limits in Part 496",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-york-defines-future-greenhouse-gas-limits-in-part-496",
    "url": "/news/new-york-defines-future-greenhouse-gas-limits-in-part-496",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series – Part III: Mitigating the Risks",
    "apiurl": "/restapi/customnewscontentlvl2/news/pha-inconsistency-series-part-iii-mitigating-the-risks",
    "url": "/news/pha-inconsistency-series-part-iii-mitigating-the-risks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PHA Inconsistency Series – Part III: Mitigating the Risks",
    "apiurl": "/restapi/customnewscontentlvl2/news/pha-inconsistency-series-part-iii-mitigating-the-risks",
    "url": "/new/federal/pha-inconsistency-series-part-iii-mitigating-the-risks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Permit Electronic Reporting in Arkansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-permit-electronic-reporting-in-arkansas",
    "url": "/news/air-permit-electronic-reporting-in-arkansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Newsom Proposes Cutting 'Red Tape' to Speed up Green Development",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-legislation-to-make-green-development-easier-in-california",
    "url": "/news/new-legislation-to-make-green-development-easier-in-california",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDAQMD Approves Amendments to NSR & PSD Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdaqmd-approves-amendments-to-nsr-psd-rules",
    "url": "/news/mdaqmd-approves-amendments-to-nsr-psd-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDAQMD Approves Amendments to NSR & PSD Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/mdaqmd-approves-amendments-to-nsr-psd-rules",
    "url": "/www.trinityconsultants.com/news/mdaqmd-approves-amendments-to-nsr-psd-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regional Haze and Prong 4 SIP Revisions-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regional-Haze-and-Prong-4-SIP-Revisions-Iowa",
    "url": "/news/Regional-Haze-and-Prong-4-SIP-Revisions-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Uinta Basin Ozone Nonattainment Area Potential Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/uinta-basin-ozone-nonattainment-area-potential-reclassification",
    "url": "/news/uinta-basin-ozone-nonattainment-area-potential-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Uinta Basin Ozone Nonattainment Area Potential Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/uinta-basin-ozone-nonattainment-area-potential-reclassification",
    "url": "/news/states/utah/uinta-basin-ozone-nonattainment-area-potential-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Guidance on Utah’s R307-130 - General Penalty Policy-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/Guidance-on-Utah-s-R307-130---General-Penalty-Policy-Utah",
    "url": "/news/Guidance-on-Utah-s-R307-130---General-Penalty-Policy-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Construction Activities Allowed Prior to Air Permit Issuance in Arizona",
    "apiurl": "/restapi/customnewscontentlvl2/news/construction-activities-allowed-prior-to-air-permit-issuance-in-arizona",
    "url": "/news/construction-activities-allowed-prior-to-air-permit-issuance-in-arizona",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Finalized Changes to AP-42 Chapter 7.1 - Organic Liquid Storage Tanks",
    "apiurl": "/restapi/customnewscontentlvl2/news/finalized-changes-to-ap-42-chapter-7-1-organic-liquid-storage-tanks",
    "url": "/news/finalized-changes-to-ap-42-chapter-7-1-organic-liquid-storage-tanks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Finalized Changes to AP-42 Chapter 7.1 - Organic Liquid Storage Tanks",
    "apiurl": "/restapi/customnewscontentlvl2/news/finalized-changes-to-ap-42-chapter-7-1-organic-liquid-storage-tanks",
    "url": "/News/Federal/Finalized-Changes-to-AP-42-Chapter-7-1---Organic-Liquid-Storage-Tanks/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Finalized Changes to AP-42 Chapter 7.1 - Organic Liquid Storage Tanks",
    "apiurl": "/restapi/customnewscontentlvl2/news/finalized-changes-to-ap-42-chapter-7-1-organic-liquid-storage-tanks",
    "url": "/news/finalized-changes-to-ap-42-chapter-7.1---organic-liquid-storage-tanks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Canada's Environmental Emergency (E2) Regulations – 2019-Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/Canada's-Environmental-Emergency-(E2)-Regulations-2019-Ontario",
    "url": "/news/Canada's-Environmental-Emergency-(E2)-Regulations-2019-Ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Revisions to the Rhode Island’s RACT SIP-Rhode Island",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Revisions-to-the-Rhode-Island-s-RACT-SIP-Rhode-Island",
    "url": "/news/Proposed-Revisions-to-the-Rhode-Island-s-RACT-SIP-Rhode-Island",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Toxchem® and Its Benefits",
    "apiurl": "/restapi/customnewscontentlvl2/news/toxchem-and-its-benefits",
    "url": "/news/toxchem-and-its-benefits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Pending Discontinuation of NJDEP’s General Permit for Research & Development Facilities (GP-020) Requires Action",
    "apiurl": "/restapi/customnewscontentlvl2/news/pending-discontinuation-njdeps-general-permit-for-research-development-facilities-gp-020-requires-action",
    "url": "/news/pending-discontinuation-njdeps-general-permit-for-research-development-facilities-gp-020-requires-action",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Federal Plan for Municipal Solid Waste Landfills",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-federal-plan-for-municipal-solid-waste-landfills",
    "url": "/news/final-federal-plan-for-municipal-solid-waste-landfills",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires Longman Lindsey",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-longman-lindsey",
    "url": "/news/trinity-consultants-acquires-longman-lindsey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed New Regulations under Chapter 115 for Oil & Natural Gas Service in Ozone Nonattainment Areas",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-new-regulations-under-chapter-115-for-oil-natural-gas-service-in-ozone-nonattainment-areas",
    "url": "/news/proposed-new-regulations-under-chapter-115-for-oil-natural-gas-service-in-ozone-nonattainment-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Hearing Is Believing: How Virtual Reality Is Aligning Acoustical Design With Expectations",
    "apiurl": "/restapi/customnewscontentlvl2/news/hearing-is-believing-how-virtual-reality-is-aligning-acoustical-design-with-expectations",
    "url": "/news/hearing-is-believing-how-virtual-reality-is-aligning-acoustical-design-with-expectations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed LDAR Requirement Overhaul for the San Joaquin Valley Air Pollution District",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-ldar-requirement-overhaul-for-the-san-joaquin-valley-air-pollution-district",
    "url": "/news/proposed-ldar-requirement-overhaul-for-the-san-joaquin-valley-air-pollution-district",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "All-In Clark County GHG Reduction Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/all-in-clark-county-ghg-reduction-program",
    "url": "/news/all-in-clark-county-ghg-reduction-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "All-In Clark County GHG Reduction Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/all-in-clark-county-ghg-reduction-program",
    "url": "/news/states/nevada/all-in-clark-county-ghg-reduction-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to CARB HFC Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-carb-hfc-regulations",
    "url": "/news/proposed-amendments-to-carb-hfc-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to CARB HFC Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-carb-hfc-regulations",
    "url": "/news/states/california/proposed-amendments-to-carb-hfc-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Preparing for 2021 Reporting Season in the Pacific Northwest",
    "apiurl": "/restapi/customnewscontentlvl2/news/preparing-for-2021-reporting-season-in-the-pacific-northwest",
    "url": "/news/preparing-for-2021-reporting-season-in-the-pacific-northwest",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Preparing for 2021 Reporting Season in the Pacific Northwest",
    "apiurl": "/restapi/customnewscontentlvl2/news/preparing-for-2021-reporting-season-in-the-pacific-northwest",
    "url": "/preparing-for-2021-reporting-season-in-the-pacific-northwest",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "North Dakota Online Permitting, Records Available in CERIS-ND",
    "apiurl": "/restapi/customnewscontentlvl2/news/north-dakota-online-permitting-records-available-in-ceris-nd",
    "url": "/news/north-dakota-online-permitting-records-available-in-ceris-nd",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ADEQ Announces Orphaned Wells Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/adeq-announces-orphaned-wells-program",
    "url": "/news/adeq-announces-orphaned-wells-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "12 Common SPCC Misconceptions",
    "apiurl": "/restapi/customnewscontentlvl2/news/12-common-spcc-misconceptions",
    "url": "/news/12-common-spcc-misconceptions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "12 Common SPCC Misconceptions",
    "apiurl": "/restapi/customnewscontentlvl2/news/12-common-spcc-misconceptions",
    "url": "/News/Federal/12-Common-SPCC-Misconceptions/",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Petroleum Storage Tank Registrations in Texas Just Got Easier!",
    "apiurl": "/restapi/customnewscontentlvl2/news/petroleum-storage-tank-registrations-in-texas-just-got-easier",
    "url": "/news/petroleum-storage-tank-registrations-in-texas-just-got-easier",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Petroleum Storage Tank Registrations in Texas Just Got Easier!",
    "apiurl": "/restapi/customnewscontentlvl2/news/petroleum-storage-tank-registrations-in-texas-just-got-easier",
    "url": "/news/storage-tank-registrations-in-texas-just-got-easier!",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Petroleum Storage Tank Registrations in Texas Just Got Easier!",
    "apiurl": "/restapi/customnewscontentlvl2/news/petroleum-storage-tank-registrations-in-texas-just-got-easier",
    "url": "/storage-tank-registrations-in-texas-just-got-easier",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Does Your Facility Have a Pandemic Response and/or Preparedness Plan?",
    "apiurl": "/restapi/customnewscontentlvl2/news/does-your-facility-have-a-pandemic-response-and-or-preparedness-plan-",
    "url": "/news/does-your-facility-have-a-pandemic-response-and-or-preparedness-plan-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Inviting Comments on Air Permit Conditions I and II-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEE-Inviting-Comments-on-Air-Permit-Conditions-I-and-II-Nebraska",
    "url": "/news/NDEE-Inviting-Comments-on-Air-Permit-Conditions-I-and-II-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Is your Facility Complying with Ontario Regulations for Silica?",
    "apiurl": "/restapi/customnewscontentlvl2/news/is-your-facility-complying-with-ontario-regulations-for-silica",
    "url": "/news/is-your-facility-complying-with-ontario-regulations-for-silica",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sustainability Efforts through Industrial Decarbonization and Carbon Sequestration",
    "apiurl": "/restapi/customnewscontentlvl2/news/sustainability-efforts-through-industrial-decarbonization-and-carbon-sequestration",
    "url": "/news/sustainability-efforts-through-industrial-decarbonization-and-carbon-sequestration",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Implementation of EPA’s National Compliance & Enforcement Initiatives (NCEI) in Region 7",
    "apiurl": "/restapi/customnewscontentlvl2/news/implementation-of-epa-s-national-compliance-enforcement-initiatives-(ncei)-in-region-7",
    "url": "/news/implementation-of-epa-s-national-compliance-enforcement-initiatives-(ncei)-in-region-7",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Synthetic Minor and Air Permit Fees Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-synthetic-minor-and-air-permit-fees-regulations",
    "url": "/news/doee-finalizes-synthetic-minor-and-air-permit-fees-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Synthetic Minor and Air Permit Fees Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-synthetic-minor-and-air-permit-fees-regulations",
    "url": "/state/doee-finalizes-synthetic-minor-and-air-permit-fees-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MCAQD Adopts Revised Rule 270 on Performance Testing",
    "apiurl": "/restapi/customnewscontentlvl2/news/mcaqd-adopts-revised-rule-270-on-performance-testing",
    "url": "/news/mcaqd-adopts-revised-rule-270-on-performance-testing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Improper Storage of Hazardous Chemicals:  EPA, OSHA, and CISA Publish Joint Safety Advisory",
    "apiurl": "/restapi/customnewscontentlvl2/news/improper-storage-of-hazardous-chemicals-epa-osha-and-cisa-publish-joint-safety-advisory",
    "url": "/news/improper-storage-of-hazardous-chemicals-epa-osha-and-cisa-publish-joint-safety-advisory",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft Regulations Proposed by PADEP Requiring Phasedown of HFC use and Manufacturing",
    "apiurl": "/restapi/customnewscontentlvl2/news/draft-regulations-proposed-by-padep-requiring-phasedown-of-hfc-use-and-manufacturing",
    "url": "/news/draft-regulations-proposed-by-padep-requiring-phasedown-of-hfc-use-and-manufacturing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Addendum to 2018 Ambient Air Monitoring Plan-Georgia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Addendum-to-2018-Ambient-Air-Monitoring-Plan-Georgia",
    "url": "/news/Addendum-to-2018-Ambient-Air-Monitoring-Plan-Georgia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Understanding Air Permitting in Ontario",
    "apiurl": "/restapi/customnewscontentlvl2/news/understanding-air-permitting-in-ontario",
    "url": "/news/understanding-air-permitting-in-ontario",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma DEQ SWMAC Passes Regulation Update and Discusses the Status of Oklahoma PFAS Laws and Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-deq-swmac-passes-regulation-update-and-discusses-the-status-of-oklahoma-pfas-laws-and-regulations",
    "url": "/news/oklahoma-deq-swmac-passes-regulation-update-and-discusses-the-status-of-oklahoma-pfas-laws-and-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "South Coast AQMD Warehouse ISR Rule 2305 Reporting Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/south-coast-aqmd-warehouse-isr-rule-2305-reporting-requirements",
    "url": "/news/south-coast-aqmd-warehouse-isr-rule-2305-reporting-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREEZE ExDAM 9.0 Improved for High Explosive and Vapor Cloud Explosion Modeling",
    "apiurl": "/restapi/customnewscontentlvl2/news/BREEZE-ExDAM-9-0-Improved-for-High-Explosive-and-Vapor-Cloud-Explosion-Modeling",
    "url": "/news/BREEZE-ExDAM-9-0-Improved-for-High-Explosive-and-Vapor-Cloud-Explosion-Modeling",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RIDEM Proposes Revisions to Surface Coating Regulations-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/RIDEM-Proposes-Revisions-to-Surface-Coating-Regulations-Massachusetts",
    "url": "/news/RIDEM-Proposes-Revisions-to-Surface-Coating-Regulations-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Linn County Air Quality Program Update-Iowa",
    "apiurl": "/restapi/customnewscontentlvl2/news/Linn-County-Air-Quality-Program-Update-Iowa",
    "url": "/news/Linn-County-Air-Quality-Program-Update-Iowa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Guidance on New Virginia UST Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/guidance-on-new-virginia-ust-requirements",
    "url": "/news/guidance-on-new-virginia-ust-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Rule Updates of Residual Risk and Technology Review for NESHAP Miscellaneous Organic Chemical Manufacturing",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-updates-of-residual-risk-and-technology-review-for-neshap-miscellaneous-organic-chemical-manufacturing-",
    "url": "/news/final-rule-updates-of-residual-risk-and-technology-review-for-neshap-miscellaneous-organic-chemical-manufacturing-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Rule Updates of Residual Risk and Technology Review for NESHAP Miscellaneous Organic Chemical Manufacturing",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-updates-of-residual-risk-and-technology-review-for-neshap-miscellaneous-organic-chemical-manufacturing-",
    "url": "/news/federal/final-rule-updates-of-residual-risk-and-technology-review-for-neshap-miscellaneous-organic-chemical-manufacturing",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Approaching Regulatory Deadlines-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/Approaching-Regulatory-Deadlines-New-Mexico",
    "url": "/news/Approaching-Regulatory-Deadlines-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reminder: BAAQMD Amendments to Regulation 2: Permits Effective July 1, 2022",
    "apiurl": "/restapi/customnewscontentlvl2/news/reminder-baaqmd-amendments-to-regulation-2-permits-effective-july-1-2022",
    "url": "/news/reminder-baaqmd-amendments-to-regulation-2-permits-effective-july-1-2022",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The BWON Annual Reports are Back in the Spotlight",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-bwon-annual-reports-are-back-in-the-spotlight",
    "url": "/news/the-bwon-annual-reports-are-back-in-the-spotlight",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed SIP Approval for Fuel Sulfur Limitations – New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-sip-approval-for-fuel-sulfur-limitations-new-hampshire",
    "url": "/news/proposed-sip-approval-for-fuel-sulfur-limitations-new-hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NMED’s Newly Released Oil and Gas Sector Ozone Precursor Rule Expected to Impose Stringent Standards to Significantly Reduce Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/nmed-s-newly-released-oil-and-gas-sector-ozone-precursor-rule-expected-to-impose-stringent-standards-to-significantly-reduce-emissions",
    "url": "/news/nmed-s-newly-released-oil-and-gas-sector-ozone-precursor-rule-expected-to-impose-stringent-standards-to-significantly-reduce-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Issues Draft 5-Year Ambient Air Monitoring Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-issues-draft-5-year-ambient-air-monitoring-network-assessment",
    "url": "/news/kdhe-issues-draft-5-year-ambient-air-monitoring-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Issues Draft 5-Year Ambient Air Monitoring Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-issues-draft-5-year-ambient-air-monitoring-network-assessment",
    "url": "/news/state/kansas/kdhe-issues-draft-5-year-ambient-air-monitoring-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regional Haze Rule Second Planning Period-Montana",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regional-Haze-Rule-Second-Planning-Period-Montana",
    "url": "/news/Regional-Haze-Rule-Second-Planning-Period-Montana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Snow Means Ozone (Maybe) in the Upper Green-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Snow-Means-Ozone-(Maybe)-in-the-Upper-Green-Wyoming",
    "url": "/news/Snow-Means-Ozone-(Maybe)-in-the-Upper-Green-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Small but Important Changes to Utah’s Permitting Rule R307-401",
    "apiurl": "/restapi/customnewscontentlvl2/news/small-but-important-changes-to-utah-s-permitting-rule-r307-401",
    "url": "/news/small-but-important-changes-to-utah-s-permitting-rule-r307-401",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Small but Important Changes to Utah’s Permitting Rule R307-401",
    "apiurl": "/restapi/customnewscontentlvl2/news/small-but-important-changes-to-utah-s-permitting-rule-r307-401",
    "url": "/states/small-but-important-changes-to-utah-s-permitting-rule-r307-401",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP to Extend 2018 Annual Emissions Reporting Deadline for Major Sources and Roll Out New Electronic Reporting System-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-to-Extend-2018-Annual-Emissions-Reporting-Deadline-for-Major-Sources-and-Roll-Out-New-Electronic-Reporting-System-Nevada",
    "url": "/news/NDEP-to-Extend-2018-Annual-Emissions-Reporting-Deadline-for-Major-Sources-and-Roll-Out-New-Electronic-Reporting-System-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Renewed Focus on Environmental Justice with Biden EPA Pick",
    "apiurl": "/restapi/customnewscontentlvl2/news/renewed-focus-on-environmental-justice-with-biden-epa-pick",
    "url": "/news/renewed-focus-on-environmental-justice-with-biden-epa-pick",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2018 Source Registration and/or Greenhouse Gas Reports Due on February 26, 2020 for Annual Filers-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-2018-Source-Registration-and-or-Greenhouse-Gas-Reports-Due-on-February-26-2020-for-Annual-Filers-Massachusetts",
    "url": "/news/MassDEP-2018-Source-Registration-and-or-Greenhouse-Gas-Reports-Due-on-February-26-2020-for-Annual-Filers-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Opens Grant Opportunity for GHG Reductions in Construction Materials and Products Sector",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-opens-grant-opportunity-for-ghg-reductions-in-construction-materials-and-products-sector",
    "url": "/news/epa-opens-grant-opportunity-for-ghg-reductions-in-construction-materials-and-products-sector",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "UDAQ Upcoming Changes to Oil and Gas Industry Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/udaq-upcoming-changes-to-oil-and-gas-industry-regulations",
    "url": "/news/udaq-upcoming-changes-to-oil-and-gas-industry-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Application Fees Increase July 1",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndep-application-fees-increase-july-1",
    "url": "/news/ndep-application-fees-increase-july-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Application Fees Increase July 1",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndep-application-fees-increase-july-1",
    "url": "/states/ndep-application-fees-increase-july-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "San Antonio Emission Source Registration Requirements",
    "apiurl": "/restapi/customnewscontentlvl2/news/san-antonio-emission-source-registration-requirements",
    "url": "/news/san-antonio-emission-source-registration-requirements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EKAPCD Proposes Amendments to Ten Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/ekapcd-proposes-amendments-to-ten-rules",
    "url": "/news/ekapcd-proposes-amendments-to-ten-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves State of Texas' Plan to Reduce Ozone-Creating Emissions for Greater Houston Area-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Approves-State-of-Texas-Plan-to-Reduce-Ozone-Creating-Emissions-for-Greater-Houston-Area-Texas",
    "url": "/news/EPA-Approves-State-of-Texas-Plan-to-Reduce-Ozone-Creating-Emissions-for-Greater-Houston-Area-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves State of Texas' Plan to Reduce Ozone-Creating Emissions for Greater Houston Area-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Approves-State-of-Texas-Plan-to-Reduce-Ozone-Creating-Emissions-for-Greater-Houston-Area-Texas",
    "url": "/news/EPA-Approves-State-of-Texas'-Plan-to-Reduce-Ozone-Creating-Emissions-for-Greater-Houston-Area-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Is Now a Washington-Certified Verification Body to Provide Greenhouse Gas (GHG) Third-Party Emissions Verification Services",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-is-now-a-washington-certified-verification-body-to-provide-greenhouse-gas-(ghg)-third-party-emissions-verification-services",
    "url": "/news/trinity-consultants-is-now-a-washington-certified-verification-body-to-provide-greenhouse-gas-(ghg)-third-party-emissions-verification-services",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEP Announces Tool for Electronic Submittal of Permit Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/dep-announces-tool-for-electronic-submittal-of-permit-applications",
    "url": "/news/dep-announces-tool-for-electronic-submittal-of-permit-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEP Announces Tool for Electronic Submittal of Permit Applications",
    "apiurl": "/restapi/customnewscontentlvl2/news/dep-announces-tool-for-electronic-submittal-of-permit-applications",
    "url": "/states/dep-announces-tool-for-electronic-submittal-of-permit-applications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Regional Offices Restructure-Missouri",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Regional-Offices-Restructure-Missouri",
    "url": "/news/EPA-Regional-Offices-Restructure-Missouri",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The IPCC 6th Assessment Report: Key Takeaways You Need to Know",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-ipcc-6th-assessment-report-key-takeaways-you-need-to-know",
    "url": "/news/the-ipcc-6th-assessment-report-key-takeaways-you-need-to-know",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge Adds Hazard Communication and Chemical Regulatory Services",
    "apiurl": "/restapi/customnewscontentlvl2/news/SafeBridge-Adds-Hazard-Communication-and-Chemical-Regulatory-Services",
    "url": "/news/SafeBridge-Adds-Hazard-Communication-and-Chemical-Regulatory-Services",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What method is your facility utilizing for compliance with the Industrial Cleaning Solvent rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-method-is-your-facility-utilizing-for-compliance-with-the-industrial-cleaning-solvent-rule",
    "url": "/news/what-method-is-your-facility-utilizing-for-compliance-with-the-industrial-cleaning-solvent-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD to Adopt New Regulation and Proposed Amendments to Address Industrial Hydrogen Plant GHG Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-to-adopt-new-regulation-and-proposed-amendments-to-address-industrial-hydrogen-plant-ghg-emissions",
    "url": "/news/baaqmd-to-adopt-new-regulation-and-proposed-amendments-to-address-industrial-hydrogen-plant-ghg-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MECT and HECT Annual Reporting Deadline-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/MECT-and-HECT-Annual-Reporting-Deadline-Texas",
    "url": "/news/MECT-and-HECT-Annual-Reporting-Deadline-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What is a BWON Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-is-a-bwon-waste",
    "url": "/news/what-is-a-bwon-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What is a BWON Waste",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-is-a-bwon-waste",
    "url": "/news/federal/what-is-a-bwon-waste",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Crafting Your Climate Strategy: What You Need to Know When Developing a GHG Inventory",
    "apiurl": "/restapi/customnewscontentlvl2/news/crafting-your-climate-strategy-what-you-need-to-know-when-developing-a-ghg-inventory",
    "url": "/news/crafting-your-climate-strategy-what-you-need-to-know-when-developing-a-ghg-inventory",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2018 Report Card on Ambient Air Quality and Changes to Wyoming's Ambient Monitoring Network-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/2018-Report-Card-on-Ambient-Air-Quality-and-Changes-to-Wyoming's-Ambient-Monitoring-Network-Wyoming",
    "url": "/news/2018-Report-Card-on-Ambient-Air-Quality-and-Changes-to-Wyoming's-Ambient-Monitoring-Network-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Fugitive Dust Rules in Pinal County",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-fugitive-dust-rules-in-pinal-county",
    "url": "/news/new-fugitive-dust-rules-in-pinal-county",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ohio EPA Anticipates Non-Attainment Reclassification for Cleveland and Toledo Areas",
    "apiurl": "/restapi/customnewscontentlvl2/news/ohio-epa-anticipates-non-attainment-reclassification-for-cleveland-and-toledo-areas",
    "url": "/news/ohio-epa-anticipates-non-attainment-reclassification-for-cleveland-and-toledo-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Adopts Regulation Requiring Reporting of Heavy-Duty Vehicle Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-adopts-regulation-requiring-reporting-of-heavy-duty-vehicle-operations",
    "url": "/news/carb-adopts-regulation-requiring-reporting-of-heavy-duty-vehicle-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Adopts Regulation Requiring Reporting of Heavy-Duty Vehicle Operations",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-adopts-regulation-requiring-reporting-of-heavy-duty-vehicle-operations",
    "url": "/states/california/carb-adopts-regulation-requiring-reporting-of-heavy-duty-vehicle-operations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft Air Quality Guidance for Existing and New Oilfield Waste Disposal",
    "apiurl": "/restapi/customnewscontentlvl2/news/draft-air-quality-guidance-for-existing-and-new-oilfield-waste-disposal",
    "url": "/news/draft-air-quality-guidance-for-existing-and-new-oilfield-waste-disposal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Publishes RACT III Rule Proposal",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-publishes-ract-iii-rule-proposal",
    "url": "/news/padep-publishes-ract-iii-rule-proposal",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Issues New Inspection and Return to Compliance Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndee-issues-new-inspection-and-return-to-compliance-guidance",
    "url": "/news/ndee-issues-new-inspection-and-return-to-compliance-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE Issues New Inspection and Return to Compliance Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/ndee-issues-new-inspection-and-return-to-compliance-guidance",
    "url": "/news/state/nebraska/ndee-issues-new-inspection-and-return-to-compliance-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Continues with Low Carbon Fuel Standard Implementation Despite COVID-19 Delays",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-continues-with-low-carbon-fuel-standard-implementation-despite-covid-19-delays",
    "url": "/news/california-continues-with-low-carbon-fuel-standard-implementation-despite-covid-19-delays",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Revisions to the Proposed Regulation 7 Changes -Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Revisions-to-the-Proposed-Regulation-7-Changes--Colorado",
    "url": "/news/New-Revisions-to-the-Proposed-Regulation-7-Changes--Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Jersey to Rejoin RGGI-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Jersey-to-Rejoin-RGGI-New-Jersey",
    "url": "/news/New-Jersey-to-Rejoin-RGGI-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Department of Ecology – Industrial Stormwater General Permit Reissuance Process Underway!",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-department-of-ecology-industrial-stormwater-general-permit-reissuance-process-underway",
    "url": "/news/washington-department-of-ecology-industrial-stormwater-general-permit-reissuance-process-underway",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon Greenhouse Gas Reporting and Clean Fuels Program Update-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-Greenhouse-Gas-Reporting-and-Clean-Fuels-Program-Update-Oregon",
    "url": "/news/Oregon-Greenhouse-Gas-Reporting-and-Clean-Fuels-Program-Update-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "GCP-O&G Transition Notification Deadline Fast Approaching-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/GCP-O-G-Transition-Notification-Deadline-Fast-Approaching-New-Mexico",
    "url": "/news/GCP-O-G-Transition-Notification-Deadline-Fast-Approaching-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oklahoma AQD Clarifies Interpretation of “Annual” for NSPS and NESHAPs",
    "apiurl": "/restapi/customnewscontentlvl2/news/oklahoma-aqd-clarifies-interpretation-of-annual-for-nsps-and-neshaps",
    "url": "/news/oklahoma-aqd-clarifies-interpretation-of-annual-for-nsps-and-neshaps",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Powering a Sustainable Future with Immediate and Effective Decarbonization Strategies",
    "apiurl": "/restapi/customnewscontentlvl2/news/powering-a-sustainable-future-with-immediate-and-effective-decarbonization-strategies",
    "url": "/news/powering-a-sustainable-future-with-immediate-and-effective-decarbonization-strategies",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires WAID Environmental",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-consultants-acquires-waid-environmental",
    "url": "/news/trinity-consultants-acquires-waid-environmental",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2022 Arizona Environmental Justice Agenda:  PFAS and Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/2022-arizona-environmental-justice-agenda-pfas-and-permitting",
    "url": "/news/2022-arizona-environmental-justice-agenda-pfas-and-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SCAQMD to Restrict Use of Materials Above VOC Limits",
    "apiurl": "/restapi/customnewscontentlvl2/news/scaqmd-to-restrict-use-of-materials-above-voc-limits",
    "url": "/news/scaqmd-to-restrict-use-of-materials-above-voc-limits",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ Proposes Rule Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/clark-county-daq-proposes-rule-changes",
    "url": "/news/clark-county-daq-proposes-rule-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Increasing Focus on Air Emissions from Hazardous Waste Generators",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-increasing-focus-on-air-emissions-from-hazardous-waste-generators",
    "url": "/news/epa-increasing-focus-on-air-emissions-from-hazardous-waste-generators",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Increasing Focus on Air Emissions from Hazardous Waste Generators",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-increasing-focus-on-air-emissions-from-hazardous-waste-generators",
    "url": "/federal/epa-increasing-focus-on-air-emissions-from-hazardous-waste-generators",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated LDAR Annual Report Form and Guidance Document-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Updated-LDAR-Annual-Report-Form-and-Guidance-Document-Colorado",
    "url": "/news/Updated-LDAR-Annual-Report-Form-and-Guidance-Document-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Joins Other U.S. Climate Alliance States to Roll-Out State Specific GHG Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-joins-other-u-s-climate-alliance-states-to-roll-out-state-specific-ghg-regulations",
    "url": "/news/massachusetts-joins-other-u-s-climate-alliance-states-to-roll-out-state-specific-ghg-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Joins Other U.S. Climate Alliance States to Roll-Out State Specific GHG Regulations",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-joins-other-u-s-climate-alliance-states-to-roll-out-state-specific-ghg-regulations",
    "url": "/states/massachusetts/massachusetts-joins-other-u-s--climate-alliance-states-to-roll-out-state-specific-ghg-regulations",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Your Facility’s General Permit to Limit Potential to Emit (GPLPE) is Expiring November 8th and Action is Required",
    "apiurl": "/restapi/customnewscontentlvl2/news/your-facility-s-general-permit-to-limit-potential-to-emit-gplpe-is-expiring-november-8th-and-action-is-required",
    "url": "/news/your-facility-s-general-permit-to-limit-potential-to-emit-gplpe-is-expiring-november-8th-and-action-is-required",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Your Facility’s General Permit to Limit Potential to Emit (GPLPE) is Expiring November 8th and Action is Required",
    "apiurl": "/restapi/customnewscontentlvl2/news/your-facility-s-general-permit-to-limit-potential-to-emit-gplpe-is-expiring-november-8th-and-action-is-required",
    "url": "/states/connecticut/your-connecticut-facility-s-general-permit-to-limit-potential-to-emit-gplpe-is-expiring-november-8th-and-action-is-required",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Three Ways to Prepare for 2021 Reporting Season",
    "apiurl": "/restapi/customnewscontentlvl2/news/three-ways-to-prepare-for-2021-reporting-season",
    "url": "/news/three-ways-to-prepare-for-2021-reporting-season",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Three Ways to Prepare for 2021 Reporting Season",
    "apiurl": "/restapi/customnewscontentlvl2/news/three-ways-to-prepare-for-2021-reporting-season",
    "url": "/news/federal/three-ways-to-prepare-for-2021-reporting-season",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ October Regulatory Round-Up",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-october-regulatory-round-up-2021",
    "url": "/news/tceq-october-regulatory-round-up-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ October Regulatory Round-Up",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-october-regulatory-round-up-2021",
    "url": "/news/tceq-october-regulatory-round-up",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2017 Source Registrations Now Due-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-2017-Source-Registrations-Now-Due-Massachusetts",
    "url": "/news/MassDEP-2017-Source-Registrations-Now-Due-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Proposes Draft Updates to SB 350 2030 GHG Planning Target Ranges",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-proposes-draft-updates-to-sb-350-2030-ghg-planning-target-ranges",
    "url": "/news/carb-proposes-draft-updates-to-sb-350-2030-ghg-planning-target-ranges",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Proposes Draft Updates to SB 350 2030 GHG Planning Target Ranges",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-proposes-draft-updates-to-sb-350-2030-ghg-planning-target-ranges",
    "url": "/news/states/california/carb-proposes-draft-updates-to-sb-350-2030-ghg-planning-target-ranges",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP Draft Air Monitoring Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-dep-draft-air-monitoring-plan",
    "url": "/news/maine-dep-draft-air-monitoring-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP Draft Air Monitoring Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-dep-draft-air-monitoring-plan",
    "url": "/www.trinityconsultants.com/news/maine-dep-draft-air-monitoring-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Several Changes to Online Interaction with the Wyoming DEQ",
    "apiurl": "/restapi/customnewscontentlvl2/news/several-changes-to-online-interaction-with-the-wyoming-deq",
    "url": "/news/several-changes-to-online-interaction-with-the-wyoming-deq",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Several Changes to Online Interaction with the Wyoming DEQ",
    "apiurl": "/restapi/customnewscontentlvl2/news/several-changes-to-online-interaction-with-the-wyoming-deq",
    "url": "/news/state/wyoming/several-changes-to-online-interaction-with-the-wyoming-deq",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON in the Post-Consent Decree Era",
    "apiurl": "/restapi/customnewscontentlvl2/news/bwon-in-the-post-consent-decree-era",
    "url": "/news/bwon-in-the-post-consent-decree-era",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to Ontario’s Emissions Performance Standards Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-amendments-to-ontario-s-emissions-performance-standards-program",
    "url": "/news/proposed-amendments-to-ontario-s-emissions-performance-standards-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19: EHS Regulatory Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-ehs-regulatory-updates",
    "url": "/news/covid-19-ehs-regulatory-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "COVID-19: EHS Regulatory Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/covid-19-ehs-regulatory-updates",
    "url": "/federal/covid-19-ehs-regulatory-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "No Exposure Certification Deadline is October 1 for California Dischargers",
    "apiurl": "/restapi/customnewscontentlvl2/news/no-exposure-certification-deadline-is-october-1-for-california-dischargers",
    "url": "/news/no-exposure-certification-deadline-is-october-1-for-california-dischargers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA TSCA Rule Targets PFAS Consumer Goods Importers and Industrial Manufacturers with Retroactive Reporting Requirement",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-tsca-rule-targets-pfas-consumer-goods-importers-and-industrial-manufacturers-with-retroactive-reporting-requirement",
    "url": "/news/epa-tsca-rule-targets-pfas-consumer-goods-importers-and-industrial-manufacturers-with-retroactive-reporting-requirement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Changes Annual Reporting System for 2018-Washington",
    "apiurl": "/restapi/customnewscontentlvl2/news/Washington-Changes-Annual-Reporting-System-for-2018-Washington",
    "url": "/news/Washington-Changes-Annual-Reporting-System-for-2018-Washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TRI Reporting Updates and Program News",
    "apiurl": "/restapi/customnewscontentlvl2/news/tri-reporting-updates-and-program-news",
    "url": "/news/tri-reporting-updates-and-program-news",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TRI Reporting Updates and Program News",
    "apiurl": "/restapi/customnewscontentlvl2/news/tri-reporting-updates-and-program-news",
    "url": "/news/federal/tri-reporting-updates-and-program-news",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Quebec Industrial Hygiene Updates to Noise Exposure Regulation",
    "apiurl": "/restapi/customnewscontentlvl2/news/quebec-industrial-hygiene-updates-to-noise-exposure-regulation",
    "url": "/news/quebec-industrial-hygiene-updates-to-noise-exposure-regulation",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Nonattainment International and Exceptional Events Consideration Status Update-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ozone-Nonattainment-International-and-Exceptional-Events-Consideration-Status-Update-Colorado",
    "url": "/news/Ozone-Nonattainment-International-and-Exceptional-Events-Consideration-Status-Update-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP - May 2019 Proposed Rules-Maine",
    "apiurl": "/restapi/customnewscontentlvl2/news/Maine-DEP---May-2019-Proposed-Rules-Maine",
    "url": "/news/Maine-DEP---May-2019-Proposed-Rules-Maine",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The Utah Roadmap-Utah",
    "apiurl": "/restapi/customnewscontentlvl2/news/The-Utah-Roadmap-Utah",
    "url": "/news/The-Utah-Roadmap-Utah",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Climate Bill Becomes Law",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-climate-bill-becomes-law",
    "url": "/news/massachusetts-climate-bill-becomes-law",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Massachusetts Climate Bill Becomes Law",
    "apiurl": "/restapi/customnewscontentlvl2/news/massachusetts-climate-bill-becomes-law",
    "url": "/www.trinityconsultants.com/news/massachusetts-climate-bill-becomes-law",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulations for Industrial Cleaning Solvents across New England",
    "apiurl": "/restapi/customnewscontentlvl2/news/regulations-for-industrial-cleaning-solvents-across-new-england",
    "url": "/news/regulations-for-industrial-cleaning-solvents-across-new-england",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2021 Brings New Hazardous Notification Requirement",
    "apiurl": "/restapi/customnewscontentlvl2/news/2021-brings-new-hazardous-notification-requirement",
    "url": "/news/2021-brings-new-hazardous-notification-requirement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2021 Brings New Hazardous Notification Requirement",
    "apiurl": "/restapi/customnewscontentlvl2/news/2021-brings-new-hazardous-notification-requirement",
    "url": "/news/federal/2021-brings-new-hazardous-notification-requirement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "WDNR's New Registration Construction Permit RCP-G02 and Registration Operating Permit ROP-G02-Wisconsin",
    "apiurl": "/restapi/customnewscontentlvl2/news/WDNR's-New-Registration-Construction-Permit-RCP-G02-and-Registration-Operating-Permit-ROP-G02-Wisconsin",
    "url": "/news/WDNR's-New-Registration-Construction-Permit-RCP-G02-and-Registration-Operating-Permit-ROP-G02-Wisconsin",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maine DEP Proposes New Rule for GHG Inventory Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/maine-dep-proposes-new-rule-for-ghg-inventory-reporting",
    "url": "/news/maine-dep-proposes-new-rule-for-ghg-inventory-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Key NJDEP Air Program Changes in 2019-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/Key-NJDEP-Air-Program-Changes-in-2019-New-Jersey",
    "url": "/news/Key-NJDEP-Air-Program-Changes-in-2019-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming Statewide Triennial Emissions Inventory",
    "apiurl": "/restapi/customnewscontentlvl2/news/upcoming-statewide-triennial-emissions-inventory",
    "url": "/news/upcoming-statewide-triennial-emissions-inventory",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - July",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-july",
    "url": "/news/good-news-july",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News - July",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-july",
    "url": "/news/good-news---july",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Adopts Rule Amendments for Permitting and Reporting Requirements for Fumigants and Other Hazardous Air Pollutants",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-adopts-rule-amendments-for-permitting-and-reporting-requirements-for-fumigants-and-other-hazardous-air-pollutants",
    "url": "/news/njdep-adopts-rule-amendments-for-permitting-and-reporting-requirements-for-fumigants-and-other-hazardous-air-pollutants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Updates to NSR Permitting Tools-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/TCEQ-Updates-to-NSR-Permitting-Tools-Texas",
    "url": "/news/TCEQ-Updates-to-NSR-Permitting-Tools-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MPCA Summer Open House-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/MPCA-Summer-Open-House-Minnesota",
    "url": "/news/MPCA-Summer-Open-House-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Minimize Your Exposure with an LDAR Gap Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/minimize-your-exposure-with-an-ldar-gap-assessment",
    "url": "/news/minimize-your-exposure-with-an-ldar-gap-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Minimize Your Exposure with an LDAR Gap Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/minimize-your-exposure-with-an-ldar-gap-assessment",
    "url": "/federal/minimize-your-exposure-with-an-ldar-gap-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Modeling Tips and Tricks, Part I",
    "apiurl": "/restapi/customnewscontentlvl2/news/modeling-tips-and-tricks-part-i",
    "url": "/news/modeling-tips-and-tricks-part-i",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated IDNR COVID-19 Enforcement and Compliance Protocol",
    "apiurl": "/restapi/customnewscontentlvl2/news/updated-idnr-covid-19-enforcement-and-compliance-protocol",
    "url": "/news/updated-idnr-covid-19-enforcement-and-compliance-protocol",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updated IDNR COVID-19 Enforcement and Compliance Protocol",
    "apiurl": "/restapi/customnewscontentlvl2/news/updated-idnr-covid-19-enforcement-and-compliance-protocol",
    "url": "/states/updated-idnr-covid-19-enforcement-and-compliance-protocol",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD Proposes BACT Policy Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-proposes-bact-policy-update",
    "url": "/news/sjvapcd-proposes-bact-policy-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Phoenix-Mesa Ozone Nonattainment Area May Face  Redesignation to Serious Nonattainment in 2024",
    "apiurl": "/restapi/customnewscontentlvl2/news/phoenix-mesa-ozone-nonattainment-area-may-face-redesignation-to-serious-nonattainment-in-2024",
    "url": "/news/phoenix-mesa-ozone-nonattainment-area-may-face-redesignation-to-serious-nonattainment-in-2024",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Announces Intent to Update Universal Waste Rules",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-announces-intent-to-update-universal-waste-rules",
    "url": "/news/epa-announces-intent-to-update-universal-waste-rules",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEP Revises Class I and Class II Air Permit Applications and Guidance Documents-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEP-Revises-Class-I-and-Class-II-Air-Permit-Applications-and-Guidance-Documents-Nevada",
    "url": "/news/NDEP-Revises-Class-I-and-Class-II-Air-Permit-Applications-and-Guidance-Documents-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 TSCA Chemical Data Reporting – Not Just for Chemical Manufacturers",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-tsca-chemical-data-reporting-not-just-for-chemical-manufacturers",
    "url": "/news/2020-tsca-chemical-data-reporting-not-just-for-chemical-manufacturers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2020 TSCA Chemical Data Reporting – Not Just for Chemical Manufacturers",
    "apiurl": "/restapi/customnewscontentlvl2/news/2020-tsca-chemical-data-reporting-not-just-for-chemical-manufacturers",
    "url": "/federal/2020-tsca-chemical-data-reporting---not-just-for-chemical-manufacturers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Controlling Hazardous Drug Exposure in Patient Care Areas",
    "apiurl": "/restapi/customnewscontentlvl2/news/controlling-hazardous-drug-exposure-in-patient-care-areas",
    "url": "/news/controlling-hazardous-drug-exposure-in-patient-care-areas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final Ozone Federal Implementation Plan (FIP) Released",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-ozone-federal-implementation-plan-released",
    "url": "/news/epa-ozone-federal-implementation-plan-released",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "VTDEC 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/vtdec-2020-air-monitoring-network-plan",
    "url": "/news/vtdec-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "VTDEC 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/vtdec-2020-air-monitoring-network-plan",
    "url": "/news/state/vermont/vtdec-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah's New Boiler Rules Released for Public Comment",
    "apiurl": "/restapi/customnewscontentlvl2/news/utah's-new-boiler-rules-released-for-public-comment",
    "url": "/news/utah's-new-boiler-rules-released-for-public-comment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Cyanide Chemicals MACT Proposed Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/cyanide-chemicals-mact-proposed-amendments",
    "url": "/news/cyanide-chemicals-mact-proposed-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Do You Need a Permit for Your Routine or Predictable Gas Venting Emissions?",
    "apiurl": "/restapi/customnewscontentlvl2/news/do-you-need-a-permit-for-your-routine-or-predictable-gas-venting-emissions",
    "url": "/news/do-you-need-a-permit-for-your-routine-or-predictable-gas-venting-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Do You Need a Permit for Your Routine or Predictable Gas Venting Emissions?",
    "apiurl": "/restapi/customnewscontentlvl2/news/do-you-need-a-permit-for-your-routine-or-predictable-gas-venting-emissions",
    "url": "/www.trinityconsultants.com/do-you-need-a-permit-for-your-routine-or-predictable-gas-venting-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Leak Repair Provisions No Longer Applicable to Substitute Refrigerants",
    "apiurl": "/restapi/customnewscontentlvl2/news/leak-repair-provisions-no-longer-applicable-to-substitute-refrigerants",
    "url": "/news/leak-repair-provisions-no-longer-applicable-to-substitute-refrigerants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Leak Repair Provisions No Longer Applicable to Substitute Refrigerants",
    "apiurl": "/restapi/customnewscontentlvl2/news/leak-repair-provisions-no-longer-applicable-to-substitute-refrigerants",
    "url": "/federal/leak-repair-provisions-no-longer-applicable-to-substitute-refrigerants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Proposed to Adopt New Regulations to Reduce Hydrofluorocarbons",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-proposed-to-adopt-new-regulations-to-reduce-hydrofluorocarbons",
    "url": "/news/mde-proposed-to-adopt-new-regulations-to-reduce-hydrofluorocarbons",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CFATS Reauthorization and Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/cfats-reauthorization-and-updates-",
    "url": "/news/cfats-reauthorization-and-updates-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CFATS Reauthorization and Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/cfats-reauthorization-and-updates-",
    "url": "/ehs-management/cfats-reauthorization-and-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires SafeBridge Consultants, Inc., a Leader in Toxicology and Industrial Hygiene Services",
    "apiurl": "/restapi/customnewscontentlvl2/news/Trinity-Consultants-Acquires-SafeBridge-Consultants-Inc-a-Leader-in-Toxicology-and-Industrial-Hygiene-Services",
    "url": "/news/Trinity-Consultants-Acquires-SafeBridge-Consultants-Inc-a-Leader-in-Toxicology-and-Industrial-Hygiene-Services",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DEQ Proposing Rulemaking to Modify GHG Reporting Program-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/DEQ-Proposing-Rulemaking-to-Modify-GHG-Reporting-Program-Oregon",
    "url": "/news/DEQ-Proposing-Rulemaking-to-Modify-GHG-Reporting-Program-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Incidents and Industry Events that have Influenced Changes in Relief System Design",
    "apiurl": "/restapi/customnewscontentlvl2/news/incidents-and-industry-events-that-have-influenced-changes-in-relief-system-design",
    "url": "/news/incidents-and-industry-events-that-have-influenced-changes-in-relief-system-design",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves Arizona State Plan for Implementing NSPS Cf",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-approves-arizona-state-plan-for-implementing-nsps-cf",
    "url": "/news/epa-approves-arizona-state-plan-for-implementing-nsps-cf",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Approves Arizona State Plan for Implementing NSPS Cf",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-approves-arizona-state-plan-for-implementing-nsps-cf",
    "url": "/states/arizona/epa-approves-arizona-state-plan-for-implementing-nsps-cf",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Stormwater Multi-Sector General Permit (MSGP) NetDMR Electronic Reporting Waiver Issued-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Stormwater-Multi-Sector-General-Permit-(MSGP)-NetDMR-Electronic-Reporting-Waiver-Issued-Texas",
    "url": "/news/Stormwater-Multi-Sector-General-Permit-(MSGP)-NetDMR-Electronic-Reporting-Waiver-Issued-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Inventory Reporting for Class II Permits Changed to Triennial Reporting as of June 1, 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-inventory-reporting-for-class-ii-permits-changed-to-triennial-reporting-as-of-june-1-2021",
    "url": "/news/emissions-inventory-reporting-for-class-ii-permits-changed-to-triennial-reporting-as-of-june-1-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Emissions Inventory Reporting for Class II Permits Changed to Triennial Reporting as of June 1, 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/emissions-inventory-reporting-for-class-ii-permits-changed-to-triennial-reporting-as-of-june-1-2021",
    "url": "/www.trinityconsultants.com/news/emissions-inventory-reporting-for-class-ii-permits-changed-to-triennial-reporting-as-of-june-1-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Nonattainment - Current and Future Implications for NOx and VOC Emitters in Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/ozone-nonattainment-current-and-future-implications-for-nox-and-voc-emitters-in-kentucky",
    "url": "/news/ozone-nonattainment-current-and-future-implications-for-nox-and-voc-emitters-in-kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ozone Nonattainment - Current and Future Implications for NOx and VOC Emitters in Kentucky",
    "apiurl": "/restapi/customnewscontentlvl2/news/ozone-nonattainment-current-and-future-implications-for-nox-and-voc-emitters-in-kentucky",
    "url": "/news/ozone-nonattainment---current-and-future-implications-for-nox-and-voc-emitters-in-kentucky",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Publishes Draft Revisions to the Air Quality Permit Exemptions List",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-publishes-draft-revisions-to-the-air-quality-permit-exemptions-list",
    "url": "/news/padep-publishes-draft-revisions-to-the-air-quality-permit-exemptions-list",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Publishes Draft Revisions to the Air Quality Permit Exemptions List",
    "apiurl": "/restapi/customnewscontentlvl2/news/padep-publishes-draft-revisions-to-the-air-quality-permit-exemptions-list",
    "url": "/news/state/pennsylvania/padep-publishes-draft-revisions-to-the-air-quality-permit-exemptions-list",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "GP09 and GP10: General Permits for Well Production Facilities-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/GP09-and-GP10-General-Permits-for-Well-Production-Facilities-Colorado",
    "url": "/news/GP09-and-GP10-General-Permits-for-Well-Production-Facilities-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "A New Way to Submit Stack Tests and Test Notifications in STEERS",
    "apiurl": "/restapi/customnewscontentlvl2/news/a-new-way-to-submit-stack-tests-and-test-notifications-in-steers",
    "url": "/news/a-new-way-to-submit-stack-tests-and-test-notifications-in-steers",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reminder of the Month - Boiler MACT Compliance Certification Due January 31st-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reminder-of-the-Month---Boiler-MACT-Compliance-Certification-Due-January-31st-Kansas",
    "url": "/news/Reminder-of-the-Month---Boiler-MACT-Compliance-Certification-Due-January-31st-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Common Control",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-common-control",
    "url": "/news/njdep-common-control",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County VOC RACT Rule Updates in the Horizon",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-voc-ract-rule-updates-in-the-horizon",
    "url": "/news/maricopa-county-voc-ract-rule-updates-in-the-horizon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CO2 Budget Trading Program-Maryland",
    "apiurl": "/restapi/customnewscontentlvl2/news/CO2-Budget-Trading-Program-Maryland",
    "url": "/news/CO2-Budget-Trading-Program-Maryland",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New Mexico Governor Signs Executive Order Committing State to Climate Change Action-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/New-Mexico-Governor-Signs-Executive-Order-Committing-State-to-Climate-Change-Action-New-Mexico",
    "url": "/news/New-Mexico-Governor-Signs-Executive-Order-Committing-State-to-Climate-Change-Action-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Important TCEQ Updates-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Important-TCEQ-Updates-Texas",
    "url": "/news/Important-TCEQ-Updates-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PADEP Guidance on Storage Tank Modification and Maintenance, Operator Training Requirements-Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/PADEP-Guidance-on-Storage-Tank-Modification-and-Maintenance-Operator-Training-Requirements-Pennsylvania",
    "url": "/news/PADEP-Guidance-on-Storage-Tank-Modification-and-Maintenance-Operator-Training-Requirements-Pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Preparing for 2021 Emission Inventory Reporting in New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/preparing-for-2021-emission-inventory-reporting-in-new-mexico",
    "url": "/news/preparing-for-2021-emission-inventory-reporting-in-new-mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OSHA Expands Combustible Dust National Emphasis Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/osha-expands-combustible-dust-national-emphasis-program",
    "url": "/news/osha-expands-combustible-dust-national-emphasis-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PCAQCD Proposes Rule Updates for Annual Emissions Inventory Questionnaire and Emissions Statements",
    "apiurl": "/restapi/customnewscontentlvl2/news/pcaqcd-proposes-rule-updates-for-annual-emissions-inventory-questionnaire-and-emissions-statements",
    "url": "/news/pcaqcd-proposes-rule-updates-for-annual-emissions-inventory-questionnaire-and-emissions-statements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PCAQCD Proposes Rule Updates for Annual Emissions Inventory Questionnaire and Emissions Statements",
    "apiurl": "/restapi/customnewscontentlvl2/news/pcaqcd-proposes-rule-updates-for-annual-emissions-inventory-questionnaire-and-emissions-statements",
    "url": "/states/arizona/pcaqcd-proposes-rule-updates-for-annual-emissions-inventory-questionnaire-and-emissions-statements",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Inland Port Air Quality Monitors Installed",
    "apiurl": "/restapi/customnewscontentlvl2/news/inland-port-air-quality-monitors-installed",
    "url": "/news/inland-port-air-quality-monitors-installed",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Evaluating New Rulemaking for Hazardous Air Pollutants-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/NJDEP-Evaluating-New-Rulemaking-for-Hazardous-Air-Pollutants-New-Jersey",
    "url": "/news/NJDEP-Evaluating-New-Rulemaking-for-Hazardous-Air-Pollutants-New-Jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Evaluating New Rulemaking for Hazardous Air Pollutants-New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/NJDEP-Evaluating-New-Rulemaking-for-Hazardous-Air-Pollutants-New-Jersey",
    "url": "/news/states/new-jersey/new-njdep-air-rules-adoption-might-impact-your-facility-in-nj",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Are You Ready for an Agency RCRA Inspection?",
    "apiurl": "/restapi/customnewscontentlvl2/news/are-you-ready-for-an-agency-rcra-inspection",
    "url": "/news/are-you-ready-for-an-agency-rcra-inspection",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Streamlining the Application Process in 2019-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Streamlining-the-Application-Process-in-2019-Texas",
    "url": "/news/Streamlining-the-Application-Process-in-2019-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Issues Updated Air Regulatory Enforcement Policy and SEP Policy",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-issues-updated-air-regulatory-enforcement-policy-and-sep-policy",
    "url": "/news/kdhe-issues-updated-air-regulatory-enforcement-policy-and-sep-policy",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON RTR – Closed Vent Systems",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-rtr-closed-vent-systems",
    "url": "/news/mon-rtr-closed-vent-systems",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Plan Approval: NOx-Tennessee",
    "apiurl": "/restapi/customnewscontentlvl2/news/Air-Plan-Approval-NOx-Tennessee",
    "url": "/news/Air-Plan-Approval-NOx-Tennessee",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MassDEP 2017 Source Registration Due on December 13, 2019-Massachusetts",
    "apiurl": "/restapi/customnewscontentlvl2/news/MassDEP-2017-Source-Registration-Due-on-December-13-2019-Massachusetts",
    "url": "/news/MassDEP-2017-Source-Registration-Due-on-December-13-2019-Massachusetts",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Employee Spotlight: Jim Eggenschwiler",
    "apiurl": "/restapi/customnewscontentlvl2/news/employee-spotlight-jim-eggenschwiler",
    "url": "/news/employee-spotlight-jim-eggenschwiler",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Launch of Colorado Environmental Online Services System-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Launch-of-Colorado-Environmental-Online-Services-System-Colorado",
    "url": "/news/Launch-of-Colorado-Environmental-Online-Services-System-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Updates to Colorado’s Regulation 7 That You Don’t Want to Miss",
    "apiurl": "/restapi/customnewscontentlvl2/news/updates-to-colorado-s-regulation-7-that-you-don-t-want-to-miss",
    "url": "/news/updates-to-colorado-s-regulation-7-that-you-don-t-want-to-miss",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge - Mar 2022 Newsletter",
    "apiurl": "/restapi/customnewscontentlvl2/news/safebridge---mar-2022-newsletter",
    "url": "/news/safebridge---mar-2022-newsletter",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Releases Proposed Air Dispersion Modeling Guidance Revisions",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-releases-proposed-air-dispersion-modeling-guidance-revisions",
    "url": "/news/epa-releases-proposed-air-dispersion-modeling-guidance-revisions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Breaking Down Ohio EPA Construction Allowances",
    "apiurl": "/restapi/customnewscontentlvl2/news/breaking-down-ohio-epa-construction-allowances",
    "url": "/news/breaking-down-ohio-epa-construction-allowances",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Changes to Emission Inventory Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-changes-to-emission-inventory-rule",
    "url": "/news/proposed-changes-to-emission-inventory-rule",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Finalizes Proposed Regulations for Reducing Hydrofluorocarbons",
    "apiurl": "/restapi/customnewscontentlvl2/news/mde-finalizes-proposed-regulations-for-reducing-hydrofluorocarbons",
    "url": "/news/mde-finalizes-proposed-regulations-for-reducing-hydrofluorocarbons",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Ecology Publishes Draft Rulemaking for Climate Commitment Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/washington-ecology-publishes-draft-rulemaking-for-climate-commitment-act",
    "url": "/news/washington-ecology-publishes-draft-rulemaking-for-climate-commitment-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sac Metro AQ Management District Friant Ranch CEQA Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/sacramento-metropolitan-aq-management-district-friant-ranch-ceqa-guidance",
    "url": "/news/sacramento-metropolitan-aq-management-district-friant-ranch-ceqa-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Sac Metro AQ Management District Friant Ranch CEQA Guidance",
    "apiurl": "/restapi/customnewscontentlvl2/news/sacramento-metropolitan-aq-management-district-friant-ranch-ceqa-guidance",
    "url": "/www.trinityconsultants.com/news/sacramento-metropolitan-aq-management-district-friant-ranch-ceqa-guidance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reclassification on DFW and HGB to Serious Ozone Nonattainment: Permitting and RACT Implications-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reclassification-on-DFW-and-HGB-to-Serious-Ozone-Nonattainment-Permitting-and-RACT-Implications-Texas",
    "url": "/news/Reclassification-on-DFW-and-HGB-to-Serious-Ozone-Nonattainment-Permitting-and-RACT-Implications-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes Extensive Revisions to Subpart W",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-extensive-revisions-to-subpart-w",
    "url": "/news/epa-proposes-extensive-revisions-to-subpart-w",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREAKING: TCEQ Adopts Rule to Allow for Commencement of Construction While a Permit Amendment Application is Pending",
    "apiurl": "/restapi/customnewscontentlvl2/news/breaking-tceq-adopts-rule-to-allow-for-commencement-of-construction-while-a-permit-amendment-application-is-pending",
    "url": "/news/breaking-tceq-adopts-rule-to-allow-for-commencement-of-construction-while-a-permit-amendment-application-is-pending",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BREAKING: TCEQ Adopts Rule to Allow for Commencement of Construction While a Permit Amendment Application is Pending",
    "apiurl": "/restapi/customnewscontentlvl2/news/breaking-tceq-adopts-rule-to-allow-for-commencement-of-construction-while-a-permit-amendment-application-is-pending",
    "url": "/news/states/texas/breaking--tceq-adopts-rule-to-allow-for-commencement-of-construction-while-a-permit-amendment-application-is-pending",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Updates Spill Notification Guidance Document – Spill Reporting in Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-updates-spill-notification-guidance-document-spill-reporting-in-kansas",
    "url": "/news/kdhe-updates-spill-notification-guidance-document-spill-reporting-in-kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Draft 2020 KS Ambient Air Monitoring Network Plan Published by KDHE",
    "apiurl": "/restapi/customnewscontentlvl2/news/draft-2020-ks-ambient-air-monitoring-network-plan-published-by-kdhe",
    "url": "/news/draft-2020-ks-ambient-air-monitoring-network-plan-published-by-kdhe",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Risk Management Plans - Are You Up to Date?",
    "apiurl": "/restapi/customnewscontentlvl2/news/risk-management-plans---are-you-up-to-date-",
    "url": "/news/risk-management-plans---are-you-up-to-date-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Risk Management Plans - Are You Up to Date?",
    "apiurl": "/restapi/customnewscontentlvl2/news/risk-management-plans---are-you-up-to-date-",
    "url": "/federal/risk-management-plans---are-you-up-to-date-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "What the 2015 MSGP expiration means for you and how to make sure you stay in compliance",
    "apiurl": "/restapi/customnewscontentlvl2/news/what-the-2015-msgp-expiration-means-for-you-and-how-to-make-sure-you-stay-in-compliance",
    "url": "/news/what-the-2015-msgp-expiration-means-for-you-and-how-to-make-sure-you-stay-in-compliance",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Focuses on “Fleet Rules” and Enforcement",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-focuses-on-fleet-rules-and-enforcement",
    "url": "/news/carb-focuses-on-fleet-rules-and-enforcement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Focuses on “Fleet Rules” and Enforcement",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-focuses-on-fleet-rules-and-enforcement",
    "url": "/states/carb-focuses-on-fleet-rules-and-enforcement",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Hosts Webinar on BACT Requirements for Large Standby Diesel Engines",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-hosts-webinar-on-bact-requirements-for-large-standby-diesel-engines",
    "url": "/news/baaqmd-hosts-webinar-on-bact-requirements-for-large-standby-diesel-engines",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BWON Annual Reports",
    "apiurl": "/restapi/customnewscontentlvl2/news/bwon-annual-reports",
    "url": "/news/bwon-annual-reports",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ODEQ Is Asking 12 Sources for Regional Haze Controls Analyses",
    "apiurl": "/restapi/customnewscontentlvl2/news/odeq-is-asking-12-sources-for-regional-haze-controls-analyses",
    "url": "/news/odeq-is-asking-12-sources-for-regional-haze-controls-analyses",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ODEQ Is Asking 12 Sources for Regional Haze Controls Analyses",
    "apiurl": "/restapi/customnewscontentlvl2/news/odeq-is-asking-12-sources-for-regional-haze-controls-analyses",
    "url": "/states/odeq-is-asking-12-sources-for-regional-haze-controls-analyses",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revision to Lower Sulfur Standard in Heating Oil from 500ppm to 15ppm is Now Final; Enforcement Suspended Until February 28, 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/revision-to-lower-sulfur-standard-in-heating-oil-from-500ppm-to-15ppm-is-now-final-enforcement-suspended-until-february-28-2021",
    "url": "/news/revision-to-lower-sulfur-standard-in-heating-oil-from-500ppm-to-15ppm-is-now-final-enforcement-suspended-until-february-28-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revision to Lower Sulfur Standard in Heating Oil from 500ppm to 15ppm is Now Final; Enforcement Suspended Until February 28, 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/revision-to-lower-sulfur-standard-in-heating-oil-from-500ppm-to-15ppm-is-now-final-enforcement-suspended-until-february-28-2021",
    "url": "/news/state/pennsylvania/revision-to-lower-sulfur-standard-in-heating-oil-from-500ppm-to-15ppm-is-now-final-enforcement-suspended-until-february-28-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposes New NSPS Subpart Kc for Storage Tanks",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-proposes-new-nsps-subpart-kc-for-storage-tanks",
    "url": "/news/epa-proposes-new-nsps-subpart-kc-for-storage-tanks",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Recent Updates to Arizona Tier II Chemical Inventory Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/recent-updates-to-arizona-tier-ii-chemical-inventory-reporting",
    "url": "/news/recent-updates-to-arizona-tier-ii-chemical-inventory-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Changes to Water Quality Permit Fees",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-changes-to-water-quality-permit-fees",
    "url": "/news/proposed-changes-to-water-quality-permit-fees",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "T&B Systems Joins Trinity Consultants",
    "apiurl": "/restapi/customnewscontentlvl2/news/t-b-systems-joins-trinity-consultants",
    "url": "/news/t-b-systems-joins-trinity-consultants",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NMED Announces Minor Source Emissions Inventory for 2020-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/NMED-Announces-Minor-Source-Emissions-Inventory-for-2020-New-Mexico",
    "url": "/news/NMED-Announces-Minor-Source-Emissions-Inventory-for-2020-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "RIDEM Draft 2020 Air Monitoring Network Plan and 2020 Five-Year Network Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/ridem-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "url": "/news/ridem-draft-2020-air-monitoring-network-plan-and-2020-five-year-network-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2020: News & Publications",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2020-news-publications",
    "url": "/news/best-of-2020-news-publications",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "North Carolina Transitioning to Electronic Data Monitoring Reports",
    "apiurl": "/restapi/customnewscontentlvl2/news/north-carolina-transitioning-to-electronic-data-monitoring-reports",
    "url": "/news/north-carolina-transitioning-to-electronic-data-monitoring-reports",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Publishes PFAS Air Testing Method OTM-45",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-publishes-pfas-air-testing-method-otm-45",
    "url": "/news/epa-publishes-pfas-air-testing-method-otm-45",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulatory Change Under a Re-Invented TSCA",
    "apiurl": "/restapi/customnewscontentlvl2/news/regulatory-change-under-a-re-invented-tsca",
    "url": "/news/regulatory-change-under-a-re-invented-tsca",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulatory Change Under a Re-Invented TSCA",
    "apiurl": "/restapi/customnewscontentlvl2/news/regulatory-change-under-a-re-invented-tsca",
    "url": "/news/federal/regulatory-change-under-a-re-invented-tsca",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Illinois Adopts Federal Hazardous Waste Generator Improvements Rule-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Illinois-Adopts-Federal-Hazardous-Waste-Generator-Improvements-Rule-Illinois",
    "url": "/news/Illinois-Adopts-Federal-Hazardous-Waste-Generator-Improvements-Rule-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Utah Considers Regulating NOx Emission Limits for Natural Gas-Fired Boilers, Steam Generators, and Process Heaters",
    "apiurl": "/restapi/customnewscontentlvl2/news/utah-considers-regulating-nox-emission-limits-for-natural-gas-fired-boilers-steam-generators-and-process-heaters",
    "url": "/news/utah-considers-regulating-nox-emission-limits-for-natural-gas-fired-boilers-steam-generators-and-process-heaters",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Update on PM2.5 NAAQS in Illinois - March 2019-Illinois",
    "apiurl": "/restapi/customnewscontentlvl2/news/Update-on-PM2-5-NAAQS-in-Illinois---March-2019-Illinois",
    "url": "/news/Update-on-PM2-5-NAAQS-in-Illinois---March-2019-Illinois",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Ecology Proposes Updates to Toxic Air Pollutant Rule-Washington",
    "apiurl": "/restapi/customnewscontentlvl2/news/Ecology-Proposes-Updates-to-Toxic-Air-Pollutant-Rule-Washington",
    "url": "/news/Ecology-Proposes-Updates-to-Toxic-Air-Pollutant-Rule-Washington",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SafeBridge Consultants, Inc. Announces Improved Sensitivity for Naproxen Sodium Air Samples",
    "apiurl": "/restapi/customnewscontentlvl2/news/SafeBridge-Consultants-Inc-Announces-Improved-Sensitivity-for-Naproxen-Sodium-Air-Samples",
    "url": "/news/SafeBridge-Consultants-Inc-Announces-Improved-Sensitivity-for-Naproxen-Sodium-Air-Samples",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes Revisions to Closure Requirements for Surface Impoundments-Louisiana",
    "apiurl": "/restapi/customnewscontentlvl2/news/LDEQ-Finalizes-Revisions-to-Closure-Requirements-for-Surface-Impoundments-Louisiana",
    "url": "/news/LDEQ-Finalizes-Revisions-to-Closure-Requirements-for-Surface-Impoundments-Louisiana",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Annual Toxic Release Inventory (TRI) Reports due July 1st-Kansas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Annual-Toxic-Release-Inventory-(TRI)-Reports-due-July-1st-Kansas",
    "url": "/news/Annual-Toxic-Release-Inventory-(TRI)-Reports-due-July-1st-Kansas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nevada DEP Proposes Significant Fee Schedule Changes for 2020-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Nevada-DEP-Proposes-Significant-Fee-Schedule-Changes-for-2020-Nevada",
    "url": "/news/Nevada-DEP-Proposes-Significant-Fee-Schedule-Changes-for-2020-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 9 - Ozone Transport Rule Focused on Creating “Good Neighbors”",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-9---ozone-transport-rule-focused-on-creating-good-neighbors",
    "url": "/news/ehs-quarterly-issue-9---ozone-transport-rule-focused-on-creating-good-neighbors",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Amendments to Env-A 1700 Permit Application Forms-New Hampshire",
    "apiurl": "/restapi/customnewscontentlvl2/news/Proposed-Amendments-to-Env-A-1700-Permit-Application-Forms-New-Hampshire",
    "url": "/news/Proposed-Amendments-to-Env-A-1700-Permit-Application-Forms-New-Hampshire",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "CARB Air Toxics Hot Spots EI Criteria Guideline Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/carb-air-toxics-hot-spots-ei-criteria-guideline-amendments",
    "url": "/news/carb-air-toxics-hot-spots-ei-criteria-guideline-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Four-Factor Source Analysis: Guidance on Regional Haze SIPs for the Second Implementation Period",
    "apiurl": "/restapi/customnewscontentlvl2/news/four-factor-source-analysis-guidance-on-regional-haze-sips-for-the-second-implementation-period",
    "url": "/news/four-factor-source-analysis-guidance-on-regional-haze-sips-for-the-second-implementation-period",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Four-Factor Source Analysis: Guidance on Regional Haze SIPs for the Second Implementation Period",
    "apiurl": "/restapi/customnewscontentlvl2/news/four-factor-source-analysis-guidance-on-regional-haze-sips-for-the-second-implementation-period",
    "url": "/news/four-factor-source-analysis--guidance-on-regional-haze-sips-for-the-second-implementation-period",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "LDEQ Finalizes AQ388",
    "apiurl": "/restapi/customnewscontentlvl2/news/ldeq-finalizes-aq388",
    "url": "/news/ldeq-finalizes-aq388",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Washington Department of Ecology - New Environmental Justice Initiative",
    "apiurl": "/restapi/customnewscontentlvl2/news/wde-new-environmental-justice-initiative",
    "url": "/news/wde-new-environmental-justice-initiative",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "More Digital Updates to Air Quality Bureau Compliance Reporting Increases Efficiency-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/More-Digital-Updates-to-Air-Quality-Bureau-Compliance-Reporting-Increases-Efficiency-New-Mexico",
    "url": "/news/More-Digital-Updates-to-Air-Quality-Bureau-Compliance-Reporting-Increases-Efficiency-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Clark County DAQ to Implement Adjusted Fee Schedules-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Clark-County-DAQ-to-Implement-Adjusted-Fee-Schedules-Nevada",
    "url": "/news/Clark-County-DAQ-to-Implement-Adjusted-Fee-Schedules-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Refrigerant Chronic Leaker Reports Due Mar 1",
    "apiurl": "/restapi/customnewscontentlvl2/news/refrigerant-chronic-leaker-reports-due-mar-1",
    "url": "/news/refrigerant-chronic-leaker-reports-due-mar-1",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Permitting & Regulatory Updates for New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-permitting-regulatory-updates-for-new-jersey",
    "url": "/news/air-permitting-regulatory-updates-for-new-jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Permitting & Regulatory Updates for New Jersey",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-permitting-regulatory-updates-for-new-jersey",
    "url": "/states/new-jersey/air-permitting-and-regulatory-updates-for-new-jersey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exemption Revoked, New General Construction Permit for Routine O&G Venting Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/exemption-revoked-new-general-construction-permit-for-routine-o-g-venting-emissions",
    "url": "/news/exemption-revoked-new-general-construction-permit-for-routine-o-g-venting-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Exemption Revoked, New General Construction Permit for Routine O&G Venting Emissions",
    "apiurl": "/restapi/customnewscontentlvl2/news/exemption-revoked-new-general-construction-permit-for-routine-o-g-venting-emissions",
    "url": "/news/state/colorado/exemption-revoked-new-general-construction-permit-for-routine-o-g-venting-emissions",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Updates to RACT Regulations-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Finalizes-Updates-to-RACT-Regulations-Washington-DC",
    "url": "/news/DOEE-Finalizes-Updates-to-RACT-Regulations-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Issued Public Comment Period for 2022 Air Permitting Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/oregon-deq-issued-public-comment-period-for-2022-air-permitting-changes",
    "url": "/news/oregon-deq-issued-public-comment-period-for-2022-air-permitting-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MDE Finalizes New General Permit for Emergency Generators-Maryland",
    "apiurl": "/restapi/customnewscontentlvl2/news/MDE-Finalizes-New-General-Permit-for-Emergency-Generators-Maryland",
    "url": "/news/MDE-Finalizes-New-General-Permit-for-Emergency-Generators-Maryland",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Maricopa County Rule 313 Regulatory Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/maricopa-county-rule-313-regulatory-updates",
    "url": "/news/maricopa-county-rule-313-regulatory-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Revised Source Category Permits for Emergency Engines Subject to RICE MACT",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-revised-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "url": "/news/doee-finalizes-revised-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes Revised Source Category Permits for Emergency Engines Subject to RICE MACT",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-revised-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "url": "/states/washington-dc/doee-finalizes-revised-source-category-permits-for-emergency-engines-subject-to-rice-mact",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Biennial Reporting & Hazardous Waste Regulatory Changes",
    "apiurl": "/restapi/customnewscontentlvl2/news/biennial-reporting-hazardous-waste-regulatory-changes",
    "url": "/news/biennial-reporting-hazardous-waste-regulatory-changes",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Best of 2020: Employee Highlights",
    "apiurl": "/restapi/customnewscontentlvl2/news/best-of-2020-employee-highlights",
    "url": "/news/best-of-2020-employee-highlights",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Bureau of Air COVID-19 Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-bureau-of-air-covid-19-update",
    "url": "/news/kdhe-bureau-of-air-covid-19-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "KDHE Bureau of Air COVID-19 Update",
    "apiurl": "/restapi/customnewscontentlvl2/news/kdhe-bureau-of-air-covid-19-update",
    "url": "/state/news/kdhe-bureau-of-air-covid-19-update",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Amendments to the 2012 and 2016 NSPS for Oil and Natural Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-amendments-to-the-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "url": "/news/epa-finalizes-amendments-to-the-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Finalizes Amendments to the 2012 and 2016 NSPS for Oil and Natural Gas Industry",
    "apiurl": "/restapi/customnewscontentlvl2/news/epa-finalizes-amendments-to-the-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "url": "/federal/epa-finalizes-amendments-to-the-2012-and-2016-nsps-for-oil-and-natural-gas-industry",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "US EPA CAER Coming to Arizona DEQ for 2023 SLEIS Air Emission Reporting",
    "apiurl": "/restapi/customnewscontentlvl2/news/us-epa-caer-coming-to-arizona-deq-for-2023-sleis-air-emission-reporting",
    "url": "/news/us-epa-caer-coming-to-arizona-deq-for-2023-sleis-air-emission-reporting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Updating CEQA Thresholds of Significance for Greenhouse Gases",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-updating-ceqa-thresholds-of-significance-for-greenhouse-gases",
    "url": "/news/baaqmd-updating-ceqa-thresholds-of-significance-for-greenhouse-gases",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EKAPCD Adopts RACT SIP for 2015 Ozone NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/ekapcd-adopts-ract-sip-for-2015-ozone-naaqs",
    "url": "/news/ekapcd-adopts-ract-sip-for-2015-ozone-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EKAPCD Adopts RACT SIP for 2015 Ozone NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/ekapcd-adopts-ract-sip-for-2015-ozone-naaqs",
    "url": "/news/states/california/ekapcd-adopts-ract-sip-for-2015-ozone-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Navigating the World of Sustainability Disclosure",
    "apiurl": "/restapi/customnewscontentlvl2/news/navigating-the-world-of-sustainability-disclosure",
    "url": "/news/navigating-the-world-of-sustainability-disclosure",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Navigating the World of Sustainability Disclosure",
    "apiurl": "/restapi/customnewscontentlvl2/news/navigating-the-world-of-sustainability-disclosure",
    "url": "/ehs-management/navigating-the-world-of-sustainability-disclosure",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Waste Discharge Requirements Order for Winery Process Water Adopted",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-waste-discharge-requirements-order-for-winery-process-water-adopted",
    "url": "/news/california-waste-discharge-requirements-order-for-winery-process-water-adopted",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "The California Clean Hydrogen Program (SB 1075) Kickoff",
    "apiurl": "/restapi/customnewscontentlvl2/news/the-california-clean-hydrogen-program-(sb-1075)-kickoff",
    "url": "/news/the-california-clean-hydrogen-program-(sb-1075)-kickoff",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Potential Regulatory Impacts of 1-Bromopropane for Chemical Facilities",
    "apiurl": "/restapi/customnewscontentlvl2/news/potential-regulatory-impacts-of-1-bromopropane-for-chemical-facilities",
    "url": "/news/potential-regulatory-impacts-of-1-bromopropane-for-chemical-facilities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Iowa Environmental Compliance Updates - Aug 2020",
    "apiurl": "/restapi/customnewscontentlvl2/news/iowa-environmental-compliance-updates",
    "url": "/news/iowa-environmental-compliance-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oil and Gas Industry Emissions Calculation & Regulatory Analysis Workbook-Colorado",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oil-and-Gas-Industry-Emissions-Calculation-Regulatory-Analysis-Workbook-Colorado",
    "url": "/news/Oil-and-Gas-Industry-Emissions-Calculation-Regulatory-Analysis-Workbook-Colorado",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "U.S. EPA MON RTR Reconsideration Review Results",
    "apiurl": "/restapi/customnewscontentlvl2/news/u.s.-epa-mon-rtr-reconsideration-review-results",
    "url": "/news/u.s.-epa-mon-rtr-reconsideration-review-results",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EPA Proposed Revisions to NE SIP and Title V Permits-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/EPA-Proposed-Revisions-to-NE-SIP-and-Title-V-Permits-Nebraska",
    "url": "/news/EPA-Proposed-Revisions-to-NE-SIP-and-Title-V-Permits-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final rule: Alabama State Implementation Plan Revisions – NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-alabama-state-implementation-plan-revisions-naaqs",
    "url": "/news/final-rule-alabama-state-implementation-plan-revisions-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Final rule: Alabama State Implementation Plan Revisions – NAAQS",
    "apiurl": "/restapi/customnewscontentlvl2/news/final-rule-alabama-state-implementation-plan-revisions-naaqs",
    "url": "/states/alabama/final-rule-alabama-state-implementation-plan-revisions-naaqs",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Oregon DEQ Assigning More Rigorous Thresholds for Select Toxic Pollutants-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Oregon-DEQ-Assigning-More-Rigorous-Thresholds-for-Select-Toxic-Pollutants-Oregon",
    "url": "/news/Oregon-DEQ-Assigning-More-Rigorous-Thresholds-for-Select-Toxic-Pollutants-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Finalizes New AQ Regulations for Demand Response Generating Sources",
    "apiurl": "/restapi/customnewscontentlvl2/news/doee-finalizes-new-aq-regulations-for-demand-response-generating-sources-",
    "url": "/news/doee-finalizes-new-aq-regulations-for-demand-response-generating-sources-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "ODEQ Releases Air Data Report for 2019",
    "apiurl": "/restapi/customnewscontentlvl2/news/odeq-releases-air-data-report-for-2019",
    "url": "/news/odeq-releases-air-data-report-for-2019",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NHDES 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/nhdes-2020-air-monitoring-network-plan",
    "url": "/news/nhdes-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NHDES 2020 Air Monitoring Network Plan",
    "apiurl": "/restapi/customnewscontentlvl2/news/nhdes-2020-air-monitoring-network-plan",
    "url": "/news/state/new-hampshire/nhdes-2020-air-monitoring-network-plan",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Reports on Wyoming Resources, Hazards, and Interactive Tool Available-Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/Reports-on-Wyoming-Resources-Hazards-and-Interactive-Tool-Available-Wyoming",
    "url": "/news/Reports-on-Wyoming-Resources-Hazards-and-Interactive-Tool-Available-Wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised Maryland General Permit for Stormwater Discharge Associated with Industrial Activity",
    "apiurl": "/restapi/customnewscontentlvl2/news/revised-maryland-general-permit-for-stormwater-discharge-associated-with-industrial-activity",
    "url": "/news/revised-maryland-general-permit-for-stormwater-discharge-associated-with-industrial-activity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Auditing Best Practices Part 5: Using Tools & Technology",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-auditing-best-practices-part-5-using-tools-technology",
    "url": "/news/ehs-auditing-best-practices-part-5-using-tools-technology",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD 2020 RACT Demonstration and Emissions Statement Program Certification",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-2020-ract-demonstration-and-emissions-statement-program-certification",
    "url": "/news/sjvapcd-2020-ract-demonstration-and-emissions-statement-program-certification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "SJVAPCD 2020 RACT Demonstration and Emissions Statement Program Certification",
    "apiurl": "/restapi/customnewscontentlvl2/news/sjvapcd-2020-ract-demonstration-and-emissions-statement-program-certification",
    "url": "/states/sjvapcd-2020-ract-demonstration-and-emissions-statement-program-certification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Advantages of a “Fleet Permit” for Multiple Fuel-Fired Engine Installations in Wyoming",
    "apiurl": "/restapi/customnewscontentlvl2/news/advantages-of-a-fleet-permit-for-multiple-fuel-fired-engine-installations-in-wyoming",
    "url": "/news/advantages-of-a-fleet-permit-for-multiple-fuel-fired-engine-installations-in-wyoming",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PFAS Discharge Permit Survey",
    "apiurl": "/restapi/customnewscontentlvl2/news/pfas-discharge-permit-survey",
    "url": "/news/pfas-discharge-permit-survey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "PFAS Discharge Permit Survey",
    "apiurl": "/restapi/customnewscontentlvl2/news/pfas-discharge-permit-survey",
    "url": "/states/pfas-discharge-permit-survey",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Modeling Tips and Tricks, Part II",
    "apiurl": "/restapi/customnewscontentlvl2/news/modeling-tips-and-tricks-part-ii",
    "url": "/news/modeling-tips-and-tricks-part-ii",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Air Quality Control Commission Approves Revisions to Colorado Regulation 3 to Address Disproportionately Impacted Communities",
    "apiurl": "/restapi/customnewscontentlvl2/news/air-quality-control-commission-approves-revisions-to-colorado-regulation-3-to-address-disproportionately-impacted-communities",
    "url": "/news/air-quality-control-commission-approves-revisions-to-colorado-regulation-3-to-address-disproportionately-impacted-communities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DOEE Proposes GHG Intensity Limits for Fuels Used in Fuel-Burning Equipment-Washington, DC",
    "apiurl": "/restapi/customnewscontentlvl2/news/DOEE-Proposes-GHG-Intensity-Limits-for-Fuels-Used-in-Fuel-Burning-Equipment-Washington-DC",
    "url": "/news/DOEE-Proposes-GHG-Intensity-Limits-for-Fuels-Used-in-Fuel-Burning-Equipment-Washington-DC",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Consultants Acquires Toxicology Consulting Firm",
    "apiurl": "/restapi/customnewscontentlvl2/news/Trinity-Consultants-Acquires-Toxicology-Consulting-Firm",
    "url": "/news/Trinity-Consultants-Acquires-Toxicology-Consulting-Firm",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Fourth Circuit Court of Appeals Remands Air Permit to APCB-Virginia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Fourth-Circuit-Court-of-Appeals-Remands-Air-Permit-to-APCB-Virginia",
    "url": "/news/Fourth-Circuit-Court-of-Appeals-Remands-Air-Permit-to-APCB-Virginia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Toxicology Regulatory Services (TRS) Joins SafeBridge",
    "apiurl": "/restapi/customnewscontentlvl2/news/Toxicology-Regulatory-Services-TRS-Joins-SafeBridge",
    "url": "/news/Toxicology-Regulatory-Services-TRS-Joins-SafeBridge",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New MCAQD Requirement for Performance Test Protocol Submittals",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-mcaqd-requirement-for-performance-test-protocol-submittals",
    "url": "/news/new-mcaqd-requirement-for-performance-test-protocol-submittals",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "New MCAQD Requirement for Performance Test Protocol Submittals",
    "apiurl": "/restapi/customnewscontentlvl2/news/new-mcaqd-requirement-for-performance-test-protocol-submittals",
    "url": "/states/arizona/new-mcaqd-requirement-for-performance-test-protocol-submittals",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Trinity Acquires AWN Consulting",
    "apiurl": "/restapi/customnewscontentlvl2/news/trinity-acquires-awn-consulting",
    "url": "/news/trinity-acquires-awn-consulting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News May 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-may-2021",
    "url": "/news/good-news-may-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Good News May 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/good-news-may-2021",
    "url": "/www.trinityconsultants.com/news/good-news-may-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Employee Spotlight: Kate McDaniel, PE",
    "apiurl": "/restapi/customnewscontentlvl2/news/employee-spotlight-kate-mcdaniel-pe",
    "url": "/news/employee-spotlight-kate-mcdaniel-pe",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Q2 Industrial Stakeholder/Air Permitting Group Meeting Updates",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-q2-industrial-stakeholder-air-permitting-group-meeting-updates",
    "url": "/news/njdep-q2-industrial-stakeholder-air-permitting-group-meeting-updates",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "BAAQMD Hearing to Adopt Amendments for Particulate Emissions from Refineries",
    "apiurl": "/restapi/customnewscontentlvl2/news/baaqmd-holding-public-hearing-for-the-adoption-of-proposed-amendments-to-regulation-6-rule-5-(particulate-emissions-from-fluidized-catalytic-cracking-units)-on-june-2-2021",
    "url": "/news/baaqmd-holding-public-hearing-for-the-adoption-of-proposed-amendments-to-regulation-6-rule-5-(particulate-emissions-from-fluidized-catalytic-cracking-units)-on-june-2-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "DTSC Proposes Adoption of Stringent Provisions of RCRA Generator Improvements Rule",
    "apiurl": "/restapi/customnewscontentlvl2/news/dtsc-proposes-adoption-of-stringent-provisions-of-rcra-gir",
    "url": "/news/dtsc-proposes-adoption-of-stringent-provisions-of-rcra-gir",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "5 Tips for Completing Your AWIA Risk and Resilience Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/5-tips-for-completing-your-awia-risk-and-resilience-assessment",
    "url": "/news/5-tips-for-completing-your-awia-risk-and-resilience-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "5 Tips for Completing Your AWIA Risk and Resilience Assessment",
    "apiurl": "/restapi/customnewscontentlvl2/news/5-tips-for-completing-your-awia-risk-and-resilience-assessment",
    "url": "/states/texas/5-tips-for-completing-your-awia-risk-and-resilience-assessment",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Regulations Respecting Reduction in the Release of Methane and Certain Volatile Organic Compounds (Upstream Oil and Gas Sector)-British Columbia",
    "apiurl": "/restapi/customnewscontentlvl2/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-British-Columbia",
    "url": "/news/Regulations-Respecting-Reduction-in-the-Release-of-Methane-and-Certain-Volatile-Organic-Compounds-(Upstream-Oil-and-Gas-Sector)-British-Columbia",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OLD MACT Residual Risk and Technology Review Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/old-mact-residual-risk-and-technology-review-amendments",
    "url": "/news/old-mact-residual-risk-and-technology-review-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "OLD MACT Residual Risk and Technology Review Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/old-mact-residual-risk-and-technology-review-amendments",
    "url": "/federal/old-mact-residual-risk-and-technology-review-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Quarterly Issue 8 - TRI Reporting Requirements Expanding and Evolving RY2021 and Beyond",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-quarterly-issue-8---tri-reporting-requirements-expanding-and-evolving-ry2021-and-beyond",
    "url": "/news/ehs-quarterly-issue-8---tri-reporting-requirements-expanding-and-evolving-ry2021-and-beyond",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "“Representative” Gas Samples – State Level Actions-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Representative-Gas-Samples-State-Level-Actions-Texas",
    "url": "/news/Representative-Gas-Samples-State-Level-Actions-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "“Representative” Gas Samples – State Level Actions-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/Representative-Gas-Samples-State-Level-Actions-Texas",
    "url": "/news/-Representative-Gas-Samples-State-Level-Actions-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON Final Amendments: Guidance for the Implementation of the New Work Practice Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-final-amendments-guidance-for-the-implementation-of-the-new-work-practice-standards-",
    "url": "/news/mon-final-amendments-guidance-for-the-implementation-of-the-new-work-practice-standards-",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MON Final Amendments: Guidance for the Implementation of the New Work Practice Standards",
    "apiurl": "/restapi/customnewscontentlvl2/news/mon-final-amendments-guidance-for-the-implementation-of-the-new-work-practice-standards-",
    "url": "/federal/mon-final-amendments---guidance-for-the-implementation-of-the-new-work-practice-standards",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Rule Change to Chapter 116 to adopt Project Emissions Accounting (PEA) under New Source Review (NSR) Preconstruction Permitting Program",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-rule-change-to-chapter-116-to-adopt-project-emissions-accounting-(pea)-under-new-source-review-(nsr)-preconstruction-permitting-program",
    "url": "/news/proposed-rule-change-to-chapter-116-to-adopt-project-emissions-accounting-(pea)-under-new-source-review-(nsr)-preconstruction-permitting-program",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Virtual Water Conference and America’s Water Infrastructure Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-virtual-water-conference-and-america-s-water-infrastructure-act",
    "url": "/news/tceq-virtual-water-conference-and-america-s-water-infrastructure-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ Virtual Water Conference and America’s Water Infrastructure Act",
    "apiurl": "/restapi/customnewscontentlvl2/news/tceq-virtual-water-conference-and-america-s-water-infrastructure-act",
    "url": "/news/state/texas/tceq-virtual-water-conference-and-america-s-water-infrastructure-act",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Design Considerations for the Safe Production of New Therapeutic Modalities",
    "apiurl": "/restapi/customnewscontentlvl2/news/design-considerations-for-the-safe-production-of-new-therapeutic-modalities",
    "url": "/news/design-considerations-for-the-safe-production-of-new-therapeutic-modalities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Design Considerations for the Safe Production of New Therapeutic Modalities",
    "apiurl": "/restapi/customnewscontentlvl2/news/design-considerations-for-the-safe-production-of-new-therapeutic-modalities",
    "url": "/www.trinityconsultants.com/news/design-considerations-for-the-safe-production-of-new-therapeutic-modalities",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "EHS Auditing Best Practices Part 2: Audit Planning",
    "apiurl": "/restapi/customnewscontentlvl2/news/ehs-auditing-best-practices-part-2-audit-planning",
    "url": "/news/ehs-auditing-best-practices-part-2-audit-planning",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Addition of Chlorhexidine to Schedule 1 of CEPA",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-addition-of-chlorhexidine-to-schedule-1-of-cepa",
    "url": "/news/proposed-addition-of-chlorhexidine-to-schedule-1-of-cepa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Proposed Addition of Chlorhexidine to Schedule 1 of CEPA",
    "apiurl": "/restapi/customnewscontentlvl2/news/proposed-addition-of-chlorhexidine-to-schedule-1-of-cepa",
    "url": "/www.trinityconsultants.com/news/proposed-addition-of-chlorhexidine-to-schedule-1-of-cepa",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NFPA 61 Requires Dust Hazard Analyses for Agricultural and Food Processing by End of 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/nfpa-61-requires-dust-hazard-analyses-for-agricultural-and-food-processing-by-end-of-2021",
    "url": "/news/nfpa-61-requires-dust-hazard-analyses-for-agricultural-and-food-processing-by-end-of-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NFPA 61 Requires Dust Hazard Analyses for Agricultural and Food Processing by End of 2021",
    "apiurl": "/restapi/customnewscontentlvl2/news/nfpa-61-requires-dust-hazard-analyses-for-agricultural-and-food-processing-by-end-of-2021",
    "url": "/www.trinityconsultants.com/news/nfpa-61-requires-dust-hazard-analyses-for-agricultural-and-food-processing-by-end-of-2021",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Facilities Subject to CAO Requirements Will Soon Receive LRAPA’s Prioritization Results-Oregon",
    "apiurl": "/restapi/customnewscontentlvl2/news/Facilities-Subject-to-CAO-Requirements-Will-Soon-Receive-LRAPA-s-Prioritization-Results-Oregon",
    "url": "/news/Facilities-Subject-to-CAO-Requirements-Will-Soon-Receive-LRAPA-s-Prioritization-Results-Oregon",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Q&A Session With KDHE",
    "apiurl": "/restapi/customnewscontentlvl2/news/q-a-session-with-kdhe",
    "url": "/news/q-a-session-with-kdhe",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Q&A Session With KDHE",
    "apiurl": "/restapi/customnewscontentlvl2/news/q-a-session-with-kdhe",
    "url": "/news/states/kansas/q---a-session-with-kdhe",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NJDEP Compliance Advisory - Phase II Vapor Recovery Systems",
    "apiurl": "/restapi/customnewscontentlvl2/news/njdep-compliance-advisory---phase-ii-vapor-recovery-systems",
    "url": "/news/njdep-compliance-advisory---phase-ii-vapor-recovery-systems",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Upcoming NM Minor Source Emission Inventory News-New Mexico",
    "apiurl": "/restapi/customnewscontentlvl2/news/Upcoming-NM-Minor-Source-Emission-Inventory-News-New-Mexico",
    "url": "/news/Upcoming-NM-Minor-Source-Emission-Inventory-News-New-Mexico",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Nevada DEP Publishes Stationary Internal Combustion Engine FAQ Document-Nevada",
    "apiurl": "/restapi/customnewscontentlvl2/news/Nevada-DEP-Publishes-Stationary-Internal-Combustion-Engine-FAQ-Document-Nevada",
    "url": "/news/Nevada-DEP-Publishes-Stationary-Internal-Combustion-Engine-FAQ-Document-Nevada",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "MPCA Winter Air Modeling Seminar-Minnesota",
    "apiurl": "/restapi/customnewscontentlvl2/news/MPCA-Winter-Air-Modeling-Seminar-Minnesota",
    "url": "/news/MPCA-Winter-Air-Modeling-Seminar-Minnesota",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "TCEQ 2019 Trade Fair Updates for NSR Workbook and EMEW-Texas",
    "apiurl": "/restapi/customnewscontentlvl2/news/TCEQ-2019-Trade-Fair-Updates-for-NSR-Workbook-and-EMEW-Texas",
    "url": "/news/TCEQ-2019-Trade-Fair-Updates-for-NSR-Workbook-and-EMEW-Texas",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "San Diego APCD Overhauls Fuel Combustion Rules and Permitting",
    "apiurl": "/restapi/customnewscontentlvl2/news/san-diego-apcd-overhauls-fuel-combustion-rules-and-permitting",
    "url": "/news/san-diego-apcd-overhauls-fuel-combustion-rules-and-permitting",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "California Low Carbon Fuel Standard Amendments",
    "apiurl": "/restapi/customnewscontentlvl2/news/california-low-carbon-fuel-standard-amendments",
    "url": "/news/california-low-carbon-fuel-standard-amendments",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "2022 Connecticut Severe Ozone Nonattainment Reclassification",
    "apiurl": "/restapi/customnewscontentlvl2/news/2022-connecticut-severe-ozone-nonattainment-reclassification",
    "url": "/news/2022-connecticut-severe-ozone-nonattainment-reclassification",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "NDEE  Update - SLEIS-Nebraska",
    "apiurl": "/restapi/customnewscontentlvl2/news/NDEE-Update---SLEIS-Nebraska",
    "url": "/news/NDEE-Update---SLEIS-Nebraska",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Revised Environmental Justice Policy Issued in Pennsylvania",
    "apiurl": "/restapi/customnewscontentlvl2/news/revised-environmental-justice-policy-issued-in-pennsylvania",
    "url": "/news/revised-environmental-justice-policy-issued-in-pennsylvania",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "How New York’s CLCPA May Affect Your Next Air Permitting Activity",
    "apiurl": "/restapi/customnewscontentlvl2/news/how-new-yorks-clcpa-may-affect-your-next-air-permitting-activity",
    "url": "/news/how-new-yorks-clcpa-may-affect-your-next-air-permitting-activity",
    "template": "new-news-article",
    "section": "news"
  },
  {
    "name": "Other",
    "apiurl": "/restapi/customcontent/contact-us/other",
    "url": "/contact-us/other",
    "template": "general_content",
    "section": "other"
  },
  {
    "name": "Tank Consulting Services",
    "apiurl": "/restapi/customcontent/contact-us/tank-consulting-services",
    "url": "/contact-us/tank-consulting-services",
    "template": "general_content",
    "section": "tank-consulting-services"
  },
  {
    "name": "Custom Solutions",
    "apiurl": "/restapi/customcontent/contact-us/custom-solutions",
    "url": "/contact-us/custom-solutions",
    "template": "general_content",
    "section": "custom-solutions"
  },
  {
    "name": "Hazardous Release Modeling",
    "apiurl": "/restapi/customcontent/contact-us/hazardous-release-modeling",
    "url": "/contact-us/hazardous-release-modeling",
    "template": "general_content",
    "section": "hazardous-release-modeling"
  },
  {
    "name": "High Explosive Explosion Modeling",
    "apiurl": "/restapi/customcontent/contact-us/high-explosive-explosion-modeling",
    "url": "/contact-us/high-explosive-explosion-modeling",
    "template": "general_content",
    "section": "high-explosive-explosion-modeling"
  },
  {
    "name": "Air Dispersion Modeling",
    "apiurl": "/restapi/customcontent/contact-us/air-dispersion-modeling",
    "url": "/contact-us/air-dispersion-modeling",
    "template": "general_content",
    "section": "air-dispersion-modeling"
  },
  {
    "name": "Vapor Cloud Explosion Modeling",
    "apiurl": "/restapi/customcontent/contact-us/vapor-cloud-explosion-modeling",
    "url": "/contact-us/vapor-cloud-explosion-modeling",
    "template": "general_content",
    "section": "vapor-cloud-explosion-modeling"
  },
  {
    "name": "Thermal Radiation (Fire) Fire Modeling",
    "apiurl": "/restapi/customcontent/contact-us/thermal-radiation-(fire)-fire-modeling",
    "url": "/contact-us/thermal-radiation-(fire)-fire-modeling",
    "template": "general_content",
    "section": "thermal-radiation-(fire)-fire-modeling"
  },
  {
    "name": "Yearly",
    "apiurl": "/restapi/customcontent/contact-us/yearly",
    "url": "/contact-us/yearly",
    "template": "general_content",
    "section": "yearly"
  },
  {
    "name": "Monthly",
    "apiurl": "/restapi/customcontent/contact-us/monthly",
    "url": "/contact-us/monthly",
    "template": "general_content",
    "section": "monthly"
  },
  {
    "name": "Industry",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/industry",
    "url": "/training/industry",
    "template": "new-training-category",
    "section": "training"
  },
  {
    "name": "Industry",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/industry",
    "url": "/training/training-by-Industry",
    "template": "new-training-category",
    "section": "training"
  },
  {
    "name": "Webinar",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/webinar",
    "url": "/training/webinar",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "All Courses",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/all-courses",
    "url": "/training/all-courses",
    "template": "new-training-all-courses",
    "section": "training"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/software",
    "url": "/training/software",
    "template": "training-software-course",
    "section": "training"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/software",
    "url": "/training/software-courses",
    "template": "training-software-course",
    "section": "training"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/software",
    "url": "/software/software-courses",
    "template": "training-software-course",
    "section": "training"
  },
  {
    "name": "Complimentary",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/free",
    "url": "/training/free",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "Complimentary",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/free",
    "url": "/training/Complimentary",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "Seminar",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/seminar",
    "url": "/training/seminar",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "Category",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/course-by-category",
    "url": "/training/course-by-category",
    "template": "training-schedule",
    "section": "training"
  },
  {
    "name": "Search",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/search",
    "url": "/training/search",
    "template": "new-training-search",
    "section": "training"
  },
  {
    "name": "Location Specific",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/location-specific",
    "url": "/training/location-specific",
    "template": "new-training-location-specific",
    "section": "training"
  },
  {
    "name": "Classroom",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/classroom",
    "url": "/training/classroom",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "eLearning",
    "apiurl": "/restapi/customtrainingcontentlvl2/training/elearning",
    "url": "/training/elearning",
    "template": "new-training-format",
    "section": "training"
  },
  {
    "name": "News",
    "apiurl": "/restapi/customnewscontentlvl1/news",
    "url": "/news",
    "template": "new-tri-news",
    "section": "news"
  },
  {
    "name": "Report a problem with the website",
    "apiurl": "/restapi/customcontent/report-problem",
    "url": "/report-problem",
    "template": "general_content",
    "section": "report-a-problem-with-the-website"
  },
  {
    "name": "Education Pricing",
    "apiurl": "/restapi/customcontent/contact-us/education-pricing",
    "url": "/contact-us/education-pricing",
    "template": "general_content",
    "section": "education-pricing"
  },
  {
    "name": "Regulatory Agency Program",
    "apiurl": "/restapi/customcontent/contact-us/regulatory-agency-program",
    "url": "/contact-us/regulatory-agency-program",
    "template": "general_content",
    "section": "regulatory-agency-program"
  },
  {
    "name": "China Pricing",
    "apiurl": "/restapi/customcontent/contact-us/china-pricing",
    "url": "/contact-us/china-pricing",
    "template": "general_content",
    "section": "china-pricing"
  },
  {
    "name": "Volume Discounts",
    "apiurl": "/restapi/customcontent/contact-us/volume-discounts",
    "url": "/contact-us/volume-discounts",
    "template": "general_content",
    "section": "volume-discounts"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-system-requirements",
    "url": "/software/tanks/tankesp/tankesp-system-requirements",
    "template": "general_content",
    "section": "tankesp"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-system-requirements",
    "url": "/software/tank-emissions/tankesp/tankesp-system-requirements",
    "template": "general_content",
    "section": "tankesp"
  },
  {
    "name": "BREEZE AERSCREEN",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/breeze-aerscreen-system-requirements",
    "url": "/software/dispersion/aerscreen/breeze-aerscreen-system-requirements",
    "template": "general_content",
    "section": "breeze-aerscreen"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/vasdip-hardware-requirements",
    "url": "/software/explosion/vasdip/vasdip-hardware-requirements",
    "template": "general_content",
    "section": "vasdip"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/vasdip-hardware-requirements",
    "url": "/software/explosion/vasdip/hardware-requirements",
    "template": "general_content",
    "section": "vasdip"
  },
  {
    "name": "ExDAM System Requirements",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/exdam-system-requirements",
    "url": "/software/explosion/exdam/exdam-system-requirements",
    "template": "general_content",
    "section": "exdam-system-requirements"
  },
  {
    "name": "BREEZE CALPUFF",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/breeze-calpuff",
    "url": "/software/dispersion/calpuff/breeze-calpuff",
    "template": "general_content",
    "section": "breeze-calpuff"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/downwash-analyst",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/downwash-analyst",
    "template": "general_content",
    "section": "downwash-analyst"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/downwash-analyst",
    "url": "/software/air-dispersion---data/downwash-analyst/downwash-analyst",
    "template": "general_content",
    "section": "downwash-analyst"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/downwash-analyst",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/downwash-analyst",
    "template": "general_content",
    "section": "downwash-analyst"
  },
  {
    "name": "BREEZE MetView",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/breeze-metview-system-requirements",
    "url": "/software/air-dispersion---data-visualization/metview/breeze-metview-system-requirements",
    "template": "general_content",
    "section": "breeze-metview"
  },
  {
    "name": "BREEZE MetView",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/breeze-metview-system-requirements",
    "url": "/software/air-dispersion-data-visualization/metview/breeze-metview-system-requirements",
    "template": "general_content",
    "section": "breeze-metview"
  },
  {
    "name": "BREEZE MetView",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/breeze-metview-system-requirements",
    "url": "/software/air-dispersion---data/metview/breeze-metview-system-requirements",
    "template": "general_content",
    "section": "breeze-metview"
  },
  {
    "name": "BREEZE AERMET",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/breeze-aermet-system-requirements",
    "url": "/software/dispersion/aermet/breeze-aermet-system-requirements",
    "template": "general_content",
    "section": "breeze-aermet"
  },
  {
    "name": "SCREEN3",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/screen3",
    "url": "/software/dispersion/screen3/screen3",
    "template": "general_content",
    "section": "screen3"
  },
  {
    "name": "BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-3d-analyst",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/breeze-3d-analyst",
    "template": "general_content",
    "section": "breeze-3d-analyst"
  },
  {
    "name": "BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-3d-analyst",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/breeze-3d-analyst",
    "template": "general_content",
    "section": "breeze-3d-analyst"
  },
  {
    "name": "BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-3d-analyst",
    "url": "/software/air-dispersion---data/3d-analyst/breeze-3d-analyst",
    "template": "general_content",
    "section": "breeze-3d-analyst"
  },
  {
    "name": "LFG Fire/Risk",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/lfg-fire-risk",
    "url": "/software/hazard/lfg-fire-risk/lfg-fire-risk",
    "template": "general_content",
    "section": "lfg-firerisk"
  },
  {
    "name": "LFG Fire/Risk",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/lfg-fire-risk",
    "url": "/software/hazard/lfg-fire-risk-modeling/lfg-fire-risk",
    "template": "general_content",
    "section": "lfg-firerisk"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/incident-analyst",
    "url": "/software/hazard/incident-analyst/incident-analyst",
    "template": "general_content",
    "section": "incident-analyst"
  },
  {
    "name": "BREEZE AERMOD",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/breeze-aermod-system-requirements",
    "url": "/software/dispersion/aermod/breeze-aermod-system-requirements",
    "template": "general_content",
    "section": "breeze-aermod"
  },
  {
    "name": "BREEZE Risk Analyst",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/breeze-risk-analyst-system-requirements",
    "url": "/software/health-risk/risk-analyst/breeze-risk-analyst-system-requirements",
    "template": "general_content",
    "section": "breeze-risk-analyst"
  },
  {
    "name": "BREEZE ROADS",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/breeze-roads",
    "url": "/software/dispersion/roads/breeze-roads",
    "template": "general_content",
    "section": "breeze-roads"
  },
  {
    "name": "Jeanine Duke",
    "apiurl": "/restapi/customcontent/contact-us/jeanine-duke",
    "url": "/contact-us/jeanine-duke",
    "template": "general_content",
    "section": "jeanine-duke"
  },
  {
    "name": "Chantel Bryant",
    "apiurl": "/restapi/customcontent/contact-us/chantel-campbell",
    "url": "/contact-us/chantel-campbell",
    "template": "general_content",
    "section": "chantel-bryant"
  },
  {
    "name": "Sonya Mullins",
    "apiurl": "/restapi/customcontent/contact-us/sonya-mullins",
    "url": "/contact-us/sonya-mullins",
    "template": "general_content",
    "section": "sonya-mullins"
  },
  {
    "name": "Ana Bonilla",
    "apiurl": "/restapi/customcontent/contact-us/ana-bonilla",
    "url": "/contact-us/ana-bonilla",
    "template": "general_content",
    "section": "ana-bonilla"
  },
  {
    "name": "Jay",
    "apiurl": "/restapi/customcontent/contact-us/jay",
    "url": "/contact-us/jay",
    "template": "general_content",
    "section": "jay"
  },
  {
    "name": "Angelica Zureikat",
    "apiurl": "/restapi/customcontent/contact-us/angelica-zureikat",
    "url": "/contact-us/angelica-zureikat",
    "template": "general_content",
    "section": "angelica-zureikat"
  },
  {
    "name": "Kara Butterly",
    "apiurl": "/restapi/customcontent/contact-us/kara-butterly",
    "url": "/contact-us/kara-butterly",
    "template": "general_content",
    "section": "kara-butterly"
  },
  {
    "name": "Jamie Breedlove",
    "apiurl": "/restapi/customcontent/contact-us/jamie-breedlove",
    "url": "/contact-us/jamie-breedlove",
    "template": "general_content",
    "section": "jamie-breedlove"
  },
  {
    "name": "Blade Thomas",
    "apiurl": "/restapi/customcontent/contact-us/blade-thomas",
    "url": "/contact-us/blade-thomas",
    "template": "general_content",
    "section": "blade-thomas"
  },
  {
    "name": "Universal Transverse Mercator (UTM)",
    "apiurl": "/restapi/customcontent/contact-us/universal-transverse-mercator-(utm)",
    "url": "/contact-us/universal-transverse-mercator-(utm)",
    "template": "general_content",
    "section": "universal-transverse-mercator-(utm)"
  },
  {
    "name": "Latitude",
    "apiurl": "/restapi/customcontent/contact-us/latitude",
    "url": "/contact-us/latitude",
    "template": "general_content",
    "section": "latitude"
  },
  {
    "name": "Latitude and Longitude",
    "apiurl": "/restapi/customcontent/contact-us/latitude-and-longitude",
    "url": "/contact-us/latitude-and-longitude",
    "template": "general_content",
    "section": "latitude-and-longitude"
  },
  {
    "name": "Longitude",
    "apiurl": "/restapi/customcontent/contact-us/longitude",
    "url": "/contact-us/longitude",
    "template": "general_content",
    "section": "longitude"
  },
  {
    "name": "BREEZE Software Regulatory Agency Program",
    "apiurl": "/restapi/customcontent/regulatory-agency-program",
    "url": "/regulatory-agency-program",
    "template": "new-regulatory-agency-program",
    "section": "software"
  },
  {
    "name": "Training",
    "apiurl": "/restapi/customtrainingcontent/training",
    "url": "/training",
    "template": "new-training-landing",
    "section": "training"
  },
  {
    "name": "Software",
    "apiurl": "/restapi/customsoftwarecontent/software",
    "url": "/software",
    "template": "new-software-landing",
    "section": "software"
  },
  {
    "name": "Key Features",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/key-features",
    "url": "/software/explosion/vasdip/key-features",
    "template": "general_content",
    "section": "key-features"
  },
  {
    "name": "Module Features",
    "apiurl": "/restapi/customcontent/software/tanks/esp/module-features",
    "url": "/software/tanks/esp/module-features",
    "template": "general_content",
    "section": "module-features"
  },
  {
    "name": "What's New in Version 9",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/what-is-new-in-latest-version",
    "url": "/software/dispersion/aermet/what-is-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-9"
  },
  {
    "name": "What's New in Version 9",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/what-is-new-in-latest-version",
    "url": "/software/dispersion/aermet/what's-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-9"
  },
  {
    "name": "CAL3QHCR Model",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/cal3qhcr-model",
    "url": "/software/dispersion/roads/cal3qhcr-model",
    "template": "general_content",
    "section": "cal3qhcr-model"
  },
  {
    "name": "Append Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/append-data",
    "url": "/software/air-dispersion---data-visualization/metview/append-data",
    "template": "general_content",
    "section": "append-data"
  },
  {
    "name": "Append Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/append-data",
    "url": "/software/air-dispersion---data/metview/append-data",
    "template": "general_content",
    "section": "append-data"
  },
  {
    "name": "Append Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/append-data",
    "url": "/software/air-dispersion-data-visualization/metview/append-data",
    "template": "general_content",
    "section": "append-data"
  },
  {
    "name": "Technical Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/technical-features",
    "url": "/software/tanks/tankesp/technical-features",
    "template": "general_content",
    "section": "technical-features"
  },
  {
    "name": "Technical Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/technical-features",
    "url": "/software/tank-emissions/tankesp/technical-features",
    "template": "general_content",
    "section": "technical-features"
  },
  {
    "name": "DEGADIS Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/degadis-model-features",
    "url": "/software/hazard/lfg-fire-risk/degadis-model-features",
    "template": "general_content",
    "section": "degadis-model-features"
  },
  {
    "name": "DEGADIS Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/degadis-model-features",
    "url": "/software/hazard/lfg-fire-risk-modeling/degadis-model-features",
    "template": "general_content",
    "section": "degadis-model-features"
  },
  {
    "name": "Power and Flexibility",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/power-and-flexibility",
    "url": "/software/dispersion/aermet/power-and-flexibility",
    "template": "general_content",
    "section": "power-and-flexibility"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/basic-features",
    "url": "/software/tanks/tankesp/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/basic-features",
    "url": "/software/tank-emissions/tankesp/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "3D Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/3d-tools",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/3d-tools",
    "template": "general_content",
    "section": "3d-tools"
  },
  {
    "name": "3D Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/3d-tools",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/3d-tools",
    "template": "general_content",
    "section": "3d-tools"
  },
  {
    "name": "3D Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/3d-tools",
    "url": "/software/air-dispersion---data/3d-analyst/3d-tools",
    "template": "general_content",
    "section": "3d-tools"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/basic-features",
    "url": "/software/dispersion/roads/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Data Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/data-rose",
    "url": "/software/air-dispersion---data-visualization/metview/data-rose",
    "template": "general_content",
    "section": "data-rose"
  },
  {
    "name": "What's New in Version 4.0",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/what's-new-in-version-4.0",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/what's-new-in-version-4.0",
    "template": "general_content",
    "section": "what's-new-in-version-4.0"
  },
  {
    "name": "What's New in Version 4.0",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/what's-new-in-version-4.0",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/what's-new-in-version-4.0",
    "template": "general_content",
    "section": "what's-new-in-version-4.0"
  },
  {
    "name": "What's New in Version 4.0",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/what's-new-in-version-4.0",
    "url": "/software/air-dispersion---data/3d-analyst/what's-new-in-version-4.0",
    "template": "general_content",
    "section": "what's-new-in-version-4.0"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/basic-features",
    "url": "/software/dispersion/aermod/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Edition Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/edition-features",
    "url": "/software/tanks/tankesp/edition-features",
    "template": "general_content",
    "section": "edition-features"
  },
  {
    "name": "Edition Features",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/edition-features",
    "url": "/software/tank-emissions/tankesp/edition-features",
    "template": "general_content",
    "section": "edition-features"
  },
  {
    "name": "What's New in Version 3",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/what-is-new-in-latest-version",
    "url": "/software/dispersion/aerscreen/what-is-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-3"
  },
  {
    "name": "What's New in Version 3",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/what-is-new-in-latest-version",
    "url": "/software/dispersion/aerscreen/what's-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-3"
  },
  {
    "name": "What's New in Version 4.0",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/what's-new-in-version-4.0",
    "url": "/software/hazard/incident-analyst/what's-new-in-version-4.0",
    "template": "general_content",
    "section": "what's-new-in-version-4.0"
  },
  {
    "name": "Scientific Model Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-scientific-model",
    "url": "/software/explosion/exdam/features-scientific-model",
    "template": "general_content",
    "section": "scientific-model-features"
  },
  {
    "name": "Scientific Model Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-scientific-model",
    "url": "/software/explosion/exdam/exdam-hexdam",
    "template": "general_content",
    "section": "scientific-model-features"
  },
  {
    "name": "What's New in Version 3.0",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/what's-new-in-version-3.0",
    "url": "/software/dispersion/calpuff/what's-new-in-version-3.0",
    "template": "general_content",
    "section": "what's-new-in-version-3.0"
  },
  {
    "name": "Program Characteristics",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/program-characteristics",
    "url": "/software/hazard/incident-analyst/program-characteristics",
    "template": "general_content",
    "section": "program-characteristics"
  },
  {
    "name": "Differences Between BREEZE ESP+ and BREEZE TankESP",
    "apiurl": "/restapi/customcontent/software/tanks/esp/differences-between-breeze-esp-and-breeze-tankesp",
    "url": "/software/tanks/esp/differences-between-breeze-esp-and-breeze-tankesp",
    "template": "general_content",
    "section": "differences-between-breeze-esp+-and-breeze-tankesp"
  },
  {
    "name": "Results Analysis Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-results-analysis",
    "url": "/software/explosion/exdam/features-results-analysis",
    "template": "general_content",
    "section": "results-analysis-features"
  },
  {
    "name": "Results Analysis Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-results-analysis",
    "url": "/software/explosion/exdam/exdam-vexdam",
    "template": "general_content",
    "section": "results-analysis-features"
  },
  {
    "name": "Key Features",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/key-features",
    "url": "/software/dispersion/roads/key-features",
    "template": "general_content",
    "section": "key-features"
  },
  {
    "name": "Post-Processing Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/post-processing-tools",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/post-processing-tools",
    "template": "general_content",
    "section": "post-processing-tools"
  },
  {
    "name": "User Interface Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-user-interface",
    "url": "/software/explosion/exdam/features-user-interface",
    "template": "general_content",
    "section": "user-interface-features"
  },
  {
    "name": "User Interface Features",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-user-interface",
    "url": "/software/explosion/exdam/exdam-key-features",
    "template": "general_content",
    "section": "user-interface-features"
  },
  {
    "name": "Key Features",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/key-features",
    "url": "/software/health-risk/risk-analyst/key-features",
    "template": "general_content",
    "section": "key-features"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/basic-features",
    "url": "/software/dispersion/aerscreen/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Technical Features",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/technical-features",
    "url": "/software/dispersion/aermod/technical-features",
    "template": "general_content",
    "section": "technical-features"
  },
  {
    "name": "CALINE4 Model",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/caline4-model",
    "url": "/software/dispersion/roads/caline4-model",
    "template": "general_content",
    "section": "caline4-model"
  },
  {
    "name": "Other Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/other-model-features",
    "url": "/software/hazard/lfg-fire-risk/other-model-features",
    "template": "general_content",
    "section": "other-model-features"
  },
  {
    "name": "Other Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/other-model-features",
    "url": "/software/hazard/lfg-fire-risk-modeling/other-model-features",
    "template": "general_content",
    "section": "other-model-features"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/basic-features",
    "url": "/software/dispersion/calpuff/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/features",
    "url": "/software/hazard/lfg-fire-risk/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/features",
    "url": "/software/hazard/lfg-fire-risk-modeling/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "What's New in Latest Version",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/what-is-new-in-latest-version",
    "url": "/software/tanks/tankesp/what-is-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-latest-version"
  },
  {
    "name": "What's New in Latest Version",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/what-is-new-in-latest-version",
    "url": "/software/tanks/tankesp/what's-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-latest-version"
  },
  {
    "name": "What's New in Latest Version",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/what-is-new-in-latest-version",
    "url": "/software/tank-emissions/tankesp/what-is-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-latest-version"
  },
  {
    "name": "Key Features",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/key-features",
    "url": "/software/dispersion/aerscreen/key-features",
    "template": "general_content",
    "section": "key-features"
  },
  {
    "name": "Results Visualization",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/results-visualization",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/results-visualization",
    "template": "general_content",
    "section": "results-visualization"
  },
  {
    "name": "Results Visualization",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/results-visualization",
    "url": "/software/air-dispersion---data/3d-analyst/results-visualization",
    "template": "general_content",
    "section": "results-visualization"
  },
  {
    "name": "Results Visualization",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/results-visualization",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/results-visualization",
    "template": "general_content",
    "section": "results-visualization"
  },
  {
    "name": "What's New in Version 11",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/what-is-new-in-latest-version",
    "url": "/software/dispersion/aermod/what-is-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-11"
  },
  {
    "name": "What's New in Version 11",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/what-is-new-in-latest-version",
    "url": "/software/dispersion/aermod/what's-new-in-latest-version",
    "template": "general_content",
    "section": "what's-new-in-version-11"
  },
  {
    "name": "Ease of Use",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/ease-of-use",
    "url": "/software/dispersion/aermet/ease-of-use",
    "template": "general_content",
    "section": "ease-of-use"
  },
  {
    "name": "Technical Features",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/technical-features",
    "url": "/software/dispersion/calpuff/technical-features",
    "template": "general_content",
    "section": "technical-features"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/features",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/features",
    "url": "/software/air-dispersion---data/downwash-analyst/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/features",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "Fire (Thermal Radiation) and Explosion (Overpressure) Models",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/fire-(thermal-radiation)-and-explosion-(overpressure)-models",
    "url": "/software/hazard/incident-analyst/fire-(thermal-radiation)-and-explosion-(overpressure)-models",
    "template": "general_content",
    "section": "fire-(thermal-radiation)-and-explosion-(overpressure)-models"
  },
  {
    "name": "CAL3QHC Model",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/cal3qhc-model",
    "url": "/software/dispersion/roads/cal3qhc-model",
    "template": "general_content",
    "section": "cal3qhc-model"
  },
  {
    "name": "Visualizations",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/visualizations",
    "url": "/software/air-dispersion---data-visualization/metview/visualizations",
    "template": "general_content",
    "section": "visualizations"
  },
  {
    "name": "Visualizations",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/visualizations",
    "url": "/software/air-dispersion-data-visualization/metview/visualizations",
    "template": "general_content",
    "section": "visualizations"
  },
  {
    "name": "Visualizations",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/visualizations",
    "url": "/software/air-dispersion---data/metview/visualizations",
    "template": "general_content",
    "section": "visualizations"
  },
  {
    "name": "Tabular Data Analysis",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/tabular-data-analysis",
    "url": "/software/air-dispersion---data-visualization/metview/tabular-data-analysis",
    "template": "general_content",
    "section": "tabular-data-analysis"
  },
  {
    "name": "What's New in Version 9.0",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-version9",
    "url": "/software/explosion/exdam/features-version9",
    "template": "general_content",
    "section": "what's-new-in-version-9.0"
  },
  {
    "name": "What's New in Version 9.0",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/features-version9",
    "url": "/software/explosion/exdam/3d-extend-extension",
    "template": "general_content",
    "section": "what's-new-in-version-9.0"
  },
  {
    "name": "Source5 Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/source5-model-features",
    "url": "/software/hazard/lfg-fire-risk/source5-model-features",
    "template": "general_content",
    "section": "source5-model-features"
  },
  {
    "name": "Source5 Model Features",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/source5-model-features",
    "url": "/software/hazard/lfg-fire-risk-modeling/source5-model-features",
    "template": "general_content",
    "section": "source5-model-features"
  },
  {
    "name": "Regulatory Compliance",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/regulatory-compliance",
    "url": "/software/dispersion/aermet/regulatory-compliance",
    "template": "general_content",
    "section": "regulatory-compliance"
  },
  {
    "name": "Toxic/Flammable Gas Dispersion Models",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/toxic-flammable-gas-dispersion-models",
    "url": "/software/hazard/incident-analyst/toxic-flammable-gas-dispersion-models",
    "template": "general_content",
    "section": "toxicflammable-gas-dispersion-models"
  },
  {
    "name": "Export Options",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/export-options",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/export-options",
    "template": "general_content",
    "section": "export-options"
  },
  {
    "name": "Export Options",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/export-options",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/export-options",
    "template": "general_content",
    "section": "export-options"
  },
  {
    "name": "Export Options",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/export-options",
    "url": "/software/air-dispersion---data/3d-analyst/export-options",
    "template": "general_content",
    "section": "export-options"
  },
  {
    "name": "Web-Based Platform",
    "apiurl": "/restapi/customcontent/software/tanks/esp/web-based-platform",
    "url": "/software/tanks/esp/web-based-platform",
    "template": "general_content",
    "section": "web-based-platform"
  },
  {
    "name": "Features",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/features",
    "url": "/software/dispersion/screen3/features",
    "template": "general_content",
    "section": "features"
  },
  {
    "name": "England",
    "apiurl": "/restapi/customcontent/contact-us/england",
    "url": "/contact-us/england",
    "template": "general_content",
    "section": "england"
  },
  {
    "name": "Ireland",
    "apiurl": "/restapi/customcontent/contact-us/ireland",
    "url": "/contact-us/ireland",
    "template": "general_content",
    "section": "ireland"
  },
  {
    "name": "New Zealand",
    "apiurl": "/restapi/customcontent/contact-us/new-zealand",
    "url": "/contact-us/new-zealand",
    "template": "general_content",
    "section": "new-zealand"
  },
  {
    "name": "United Kingdom",
    "apiurl": "/restapi/customcontent/contact-us/united-kingdom",
    "url": "/contact-us/united-kingdom",
    "template": "general_content",
    "section": "united-kingdom"
  },
  {
    "name": "India",
    "apiurl": "/restapi/customcontent/contact-us/india",
    "url": "/contact-us/india",
    "template": "general_content",
    "section": "india"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/contact-us/china",
    "url": "/contact-us/china",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "United States of America",
    "apiurl": "/restapi/customcontent/contact-us/united-states-of-america",
    "url": "/contact-us/united-states-of-america",
    "template": "general_content",
    "section": "united-states-of-america"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/contact-us/australia",
    "url": "/contact-us/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "Careers",
    "apiurl": "/restapi/customcareerscontent/careers",
    "url": "/careers",
    "template": "new-trinity-career",
    "section": "careers"
  },
  {
    "name": "Facebook",
    "apiurl": "/restapi/customcontent/contact-us/facebook",
    "url": "/contact-us/facebook",
    "template": "general_content",
    "section": "facebook"
  },
  {
    "name": "Ads",
    "apiurl": "/restapi/customcontent/contact-us/ads",
    "url": "/contact-us/ads",
    "template": "general_content",
    "section": "ads"
  },
  {
    "name": "Environmental Expert",
    "apiurl": "/restapi/customcontent/contact-us/environmental-expert",
    "url": "/contact-us/environmental-expert",
    "template": "general_content",
    "section": "environmental-expert"
  },
  {
    "name": "Web Search",
    "apiurl": "/restapi/customcontent/contact-us/web-search",
    "url": "/contact-us/web-search",
    "template": "general_content",
    "section": "web-search"
  },
  {
    "name": "Conference",
    "apiurl": "/restapi/customcontent/contact-us/conference",
    "url": "/contact-us/conference",
    "template": "general_content",
    "section": "conference"
  },
  {
    "name": "Colleague",
    "apiurl": "/restapi/customcontent/contact-us/colleague",
    "url": "/contact-us/colleague",
    "template": "general_content",
    "section": "colleague"
  },
  {
    "name": "LinkedIn",
    "apiurl": "/restapi/customcontent/contact-us/linkedin",
    "url": "/contact-us/linkedin",
    "template": "general_content",
    "section": "linkedin"
  },
  {
    "name": "EHS Regulations",
    "apiurl": "/restapi/customcontent/training/ehs-regulations",
    "url": "/training/ehs-regulations",
    "template": "general_content",
    "section": "ehs-regulations"
  },
  {
    "name": "Training by Category",
    "apiurl": "/restapi/customcontent/training/training-by-category",
    "url": "/training/training-by-category",
    "template": "general_content",
    "section": "training-by-category"
  },
  {
    "name": "ehs management",
    "apiurl": "/restapi/customcontent/training/ehs-management",
    "url": "/training/ehs-management",
    "template": "general_content",
    "section": "ehs-management"
  },
  {
    "name": "Training by Industry",
    "apiurl": "/restapi/customcontent/training/training-by-industry",
    "url": "/training/training-by-industry",
    "template": "general_content",
    "section": "training-by-industry"
  },
  {
    "name": "Engineering",
    "apiurl": "/restapi/customconsultinglvl2content/consulting/engineering",
    "url": "/consulting/engineering",
    "template": "new-consulting-category",
    "section": "engineering"
  },
  {
    "name": "Science",
    "apiurl": "/restapi/customconsultinglvl2content/consulting/science",
    "url": "/consulting/science",
    "template": "new-consulting-category",
    "section": "science"
  },
  {
    "name": "Environmental Health & Safety",
    "apiurl": "/restapi/customconsultinglvl2content/consulting/ehs",
    "url": "/consulting/ehs",
    "template": "new-consulting-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Health & Safety",
    "apiurl": "/restapi/customconsultinglvl2content/consulting/ehs",
    "url": "/areas-of-expertise/environmental-health-safety",
    "template": "new-consulting-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/staffinges",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/staffing",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/staffinges/",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/e2hsworkforce",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/staffings",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "Staffing",
    "apiurl": "/restapi/customstaffingcontent/staffinges",
    "url": "/services/Staffing",
    "template": "new-stafﬁng-landing",
    "section": "staffing"
  },
  {
    "name": "TEST",
    "apiurl": "/restapi/customcontent/default-trinitytraining/default-trainingschedule/default-trainingcategorytypes/test",
    "url": "/default-trinitytraining/default-trainingschedule/default-trainingcategorytypes/test",
    "template": "general_content",
    "section": "test"
  },
  {
    "name": "TEST",
    "apiurl": "/restapi/customcontent/default-trinitytraining/default-trainingschedule/default-trainingcategorytypes/test",
    "url": "/training/test",
    "template": "general_content",
    "section": "test"
  },
  {
    "name": "TEST",
    "apiurl": "/restapi/customcontent/default-trinitytraining/default-trainingschedule/default-trainingcategorytypes/test",
    "url": "/training-to-test/test",
    "template": "general_content",
    "section": "test"
  },
  {
    "name": "New York",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/new-york",
    "url": "/locations/north-america/united-states/new-york",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Utah",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/utah",
    "url": "/locations/north-america/united-states/utah",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Connecticut",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/connecticut",
    "url": "/locations/north-america/united-states/connecticut",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Missouri",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/missouri",
    "url": "/locations/north-america/united-states/missouri",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Virginia",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/virginia",
    "url": "/locations/north-america/united-states/virginia",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/texas",
    "url": "/locations/north-america/united-states/texas",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/texas",
    "url": "/location/north-america/united-states/texas",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/texas",
    "url": "/locations/texas",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Ontario",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/canada/ontario",
    "url": "/locations/north-america/canada/ontario",
    "template": "new-individual-state",
    "section": "canada"
  },
  {
    "name": "Ontario",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/canada/ontario",
    "url": "/locations/north-america/canada/toronto",
    "template": "new-individual-state",
    "section": "canada"
  },
  {
    "name": "Indiana",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/indiana",
    "url": "/locations/north-america/united-states/indiana",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "New Hampshire",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/new-hampshire",
    "url": "/locations/north-america/united-states/new-hampshire",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Maharashtra",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/asia/india/maharashtra",
    "url": "/locations/asia/india/maharashtra",
    "template": "new-individual-state",
    "section": "india"
  },
  {
    "name": "Puerto Rico",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/puerto-rico",
    "url": "/locations/north-america/united-states/puerto-rico",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Washington",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/washington",
    "url": "/locations/north-america/united-states/washington",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Delaware",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/delaware",
    "url": "/locations/north-america/united-states/delaware",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Hawaii",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/hawaii",
    "url": "/locations/north-america/united-states/hawaii",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "West Virginia",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/west-virginia",
    "url": "/locations/north-america/united-states/west-virginia",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Pennsylvania",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/pennsylvania",
    "url": "/locations/north-america/united-states/pennsylvania",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Wisconsin",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/wisconsin",
    "url": "/locations/north-america/united-states/wisconsin",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Maryland",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/maryland",
    "url": "/locations/north-america/united-states/maryland",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Maryland",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/maryland",
    "url": "/locations/north-america/united-states/washington-dc",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Kansas",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/kansas",
    "url": "/locations/north-america/united-states/kansas",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/asia/china/asia-pacific-state",
    "url": "/locations/asia/china/asia-pacific-state",
    "template": "new-individual-state",
    "section": "china"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/asia/china/asia-pacific-state",
    "url": "/locations/asia/asia-pacific-country/asia-pacific-state",
    "template": "new-individual-state",
    "section": "china"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/asia/china/asia-pacific-state",
    "url": "/locations/asia-pacific/asia-pacific-country/asia-pacific-state",
    "template": "new-individual-state",
    "section": "china"
  },
  {
    "name": "Nevada",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/nevada",
    "url": "/locations/north-america/united-states/nevada",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Florida",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/florida",
    "url": "/locations/north-america/united-states/florida",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Tennessee",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/tennessee",
    "url": "/locations/north-america/united-states/tennessee",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Kentucky",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/kentucky",
    "url": "/locations/north-america/united-states/kentucky",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Wyoming",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/wyoming",
    "url": "/locations/north-america/united-states/wyoming",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Oklahoma",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/oklahoma",
    "url": "/locations/north-america/united-states/oklahoma",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Illinois",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/illinois",
    "url": "/locations/north-america/united-states/illinois",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "North Dakota",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/north-dakota",
    "url": "/locations/north-america/united-states/north-dakota",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Idaho",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/idaho",
    "url": "/locations/north-america/united-states/idaho",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Mississippi",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/mississippi",
    "url": "/locations/north-america/united-states/mississippi",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/northamerica/unitedstates/texas",
    "url": "/default-mainlocation/northamerica/unitedstates/texas",
    "template": "new-individual-state",
    "section": "unitedstates"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/northamerica/unitedstates/texas",
    "url": "/northamerica/unitedstates/texas",
    "template": "new-individual-state",
    "section": "unitedstates"
  },
  {
    "name": "Queensland",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/Queensland",
    "url": "/locations/australia-location/australia/Queensland",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Queensland",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/Queensland",
    "url": "/locations/australia/australia/Queensland",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Queensland",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/Queensland",
    "url": "/locations/australia/australia/qld",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/location/north-america/texas",
    "url": "/default-mainlocation/location/north-america/texas",
    "template": "new-individual-state",
    "section": "north-america"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/location/north-america/texas",
    "url": "/location/north-america/texas",
    "template": "new-individual-state",
    "section": "north-america"
  },
  {
    "name": "Nebraska",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/nebraska",
    "url": "/locations/north-america/united-states/nebraska",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "South Carolina",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/south-carolina",
    "url": "/locations/north-america/united-states/south-carolina",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Georgia",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/georgia",
    "url": "/locations/north-america/united-states/georgia",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "California",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/california",
    "url": "/locations/north-america/united-states/california",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "California",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/california",
    "url": "/locations/california",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Michigan",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/michigan",
    "url": "/locations/north-america/united-states/michigan",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Oregon",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/oregon",
    "url": "/locations/north-america/united-states/oregon",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Australia State 1",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/australia/australia/austalia-test-1",
    "url": "/default-mainlocation/australia/australia/austalia-test-1",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Australia State 1",
    "apiurl": "/restapi/customlocationcontentlvl2/default-mainlocation/australia/australia/austalia-test-1",
    "url": "/australia/australia/austalia-test-1",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Hampshire",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/europe/europe-country/europe-state",
    "url": "/locations/europe/europe-country/europe-state",
    "template": "new-individual-state",
    "section": "europe-country"
  },
  {
    "name": "Minnesota",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/minnesota",
    "url": "/locations/north-america/united-states/minnesota",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "South Island",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/new-zealand/south-island",
    "url": "/locations/australia-location/new-zealand/south-island",
    "template": "new-individual-state",
    "section": "new-zealand"
  },
  {
    "name": "South Island",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/new-zealand/south-island",
    "url": "/locations/australia/new-zealand/south-island",
    "template": "new-individual-state",
    "section": "new-zealand"
  },
  {
    "name": "British Columbia",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/canada/british-columbia",
    "url": "/locations/north-america/canada/british-columbia",
    "template": "new-individual-state",
    "section": "canada"
  },
  {
    "name": "New Mexico",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/new-mexico",
    "url": "/locations/north-america/united-states/new-mexico",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Massachusetts",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/massachusetts",
    "url": "/locations/north-america/united-states/massachusetts",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Telangana",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/asia/india/telangana",
    "url": "/locations/asia/india/telangana",
    "template": "new-individual-state",
    "section": "india"
  },
  {
    "name": "Vermont",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/vermont",
    "url": "/locations/north-america/united-states/vermont",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Alaska",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/alaska",
    "url": "/locations/north-america/united-states/alaska",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "North Carolina",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/north-carolina",
    "url": "/locations/north-america/united-states/north-carolina",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "North Carolina",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/north-carolina",
    "url": "/locations/north-carolina",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Colorado",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/colorado",
    "url": "/locations/north-america/united-states/colorado",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "South Dakota",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/south-dakota",
    "url": "/locations/north-america/united-states/south-dakota",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Arizona",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/arizona",
    "url": "/locations/north-america/united-states/arizona",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "New South Wales",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/new-south-wales",
    "url": "/locations/australia-location/australia/new-south-wales",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "New South Wales",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/new-south-wales",
    "url": "/locations/australia/australia/new-south-wales",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "New South Wales",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/australia-location/australia/new-south-wales",
    "url": "/locations/australia/australia/nsw",
    "template": "new-individual-state",
    "section": "australia"
  },
  {
    "name": "Saskatchewan",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/canada/saskatchewan",
    "url": "/locations/north-america/canada/saskatchewan",
    "template": "new-individual-state",
    "section": "canada"
  },
  {
    "name": "Leinster",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/europe/ireland/dublin",
    "url": "/locations/europe/ireland/dublin",
    "template": "new-individual-state",
    "section": "ireland"
  },
  {
    "name": "Louisiana",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/louisiana",
    "url": "/locations/north-america/united-states/louisiana",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Louisiana",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/louisiana",
    "url": "/locations/louisiana",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Montana",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/montana",
    "url": "/locations/north-america/united-states/montana",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Iowa",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/iowa",
    "url": "/locations/north-america/united-states/iowa",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Alabama",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/alabama",
    "url": "/locations/north-america/united-states/alabama",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Alabama",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/alabama",
    "url": "/locations/alabama",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Suffolk",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/europe/europe-country/suffolk",
    "url": "/locations/europe/europe-country/suffolk",
    "template": "new-individual-state",
    "section": "europe-country"
  },
  {
    "name": "Arkansas",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/arkansas",
    "url": "/locations/north-america/united-states/arkansas",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Ohio",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/ohio",
    "url": "/locations/north-america/united-states/ohio",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Maine",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/maine",
    "url": "/locations/north-america/united-states/maine",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "New Jersey",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/new-jersey",
    "url": "/locations/north-america/united-states/new-jersey",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "Washington, D.C.",
    "apiurl": "/restapi/customlocationcontentlvl2/locations/north-america/united-states/washington-d.c",
    "url": "/locations/north-america/united-states/washington-d.c",
    "template": "new-individual-state",
    "section": "united-states"
  },
  {
    "name": "2023-2024 EHS Training Brochure",
    "apiurl": "/restapi/customcontent/your-passport-to-ehs-training",
    "url": "/your-passport-to-ehs-training",
    "template": "general_content",
    "section": "2023-2024-ehs-training-brochure"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ganetespib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganetespib",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/analytical-methods/ganetespib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganetespib",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Bendamustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Bendamustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganciclovir",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ganciclovir-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganciclovir",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-air-ih-sample-method",
    "url": "/analytical-methods/ganciclovir-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Cephalexin Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method-2",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method-2",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Cephalexin Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method-2",
    "url": "/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method-2",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/abarelix-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-surface-ih-sample-method",
    "url": "/analytical-methods/abarelix-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Warfarin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/warfarin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/warfarin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Warfarin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/warfarin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/warfarin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tobramycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tobramycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tobramycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tobramycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tobramycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-surface-ih-sample-method",
    "url": "/analytical-methods/tobramycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Fluocinolone Acetonide HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluocinolone-acetonide-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fluocinolone-acetonide-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Fluocinolone Acetonide HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluocinolone-acetonide-hplc-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fluocinolone-acetonide-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Alendronate Sodium Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/alendronate-sodium-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/alendronate-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Alendronate Sodium Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/alendronate-sodium-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/alendronate-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Irinotecan",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Irinotecan",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl m1 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "url": "/analytical-methods/irinotecan-hcl-m1-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/leuprolide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-surface-ih-sample-method",
    "url": "/analytical-methods/leuprolide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Azathioprine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/azathioprine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Azathioprine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-surface-ih-sample-method",
    "url": "/analytical-methods/azathioprine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Thalidomide Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/thalidomide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Thalidomide Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/thalidomide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Betamethasone-Sodium",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone sodium phosphate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-sodium-phosphate-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Betamethasone-Sodium",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Sodium LQL Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-sodium-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Sodium LQL Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/naproxen-sodium-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ticlopidine HCl Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ticlopidine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ticlopidine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ticlopidine HCl Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ticlopidine-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ticlopidine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ranolazine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ranolazine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ranolazine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ranolazine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tenofovir-df-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-air-ih-sample-method",
    "url": "/analytical-methods/tenofovir-df-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Calcitriol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Calcitriol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Calcitriol Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "url": "/analytical-methods/Calcitriol-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Tamsulosin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/flomax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Tamsulosin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flomax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flomax-air-ih-sample-method",
    "url": "/analytical-methods/flomax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/insulin-porcine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Insulin-Porcine-Pancreatic",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/analytical-methods/insulin-porcine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin porcine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-porcine-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Insulin-Porcine-Pancreatic",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Deslorelin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/deslorelin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Deslorelin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "deslorelin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/deslorelin-air-ih-sample-method",
    "url": "/analytical-methods/deslorelin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Estradiol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "estradiol 17 beta air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/estradiol-17-beta-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Estradiol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Amphotericin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Amphotericin B Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Amphotericin-B-Air-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Amphotericin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/lactose-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-surface-ih-sample-method",
    "url": "/analytical-methods/lactose-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/mannitol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-surface-ih-sample-method",
    "url": "/analytical-methods/mannitol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ethinyl Estradiol HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ethinyl-estradiol-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ethinyl Estradiol HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-hplc-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ethinyl-estradiol-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cefdinir",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefdinir-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cefdinir",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefdinir surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefdinir-surface-ih-sample-method",
    "url": "/analytical-methods/cefdinir-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Carmustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/carmustine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Carmustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "carmustine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/carmustine-air-ih-sample-method",
    "url": "/analytical-methods/carmustine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Anagrelide-HCl",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "anagrelide hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/anagrelide-hcl-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Anagrelide-HCl",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "url": "/analytical-methods/tamoxifen-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-T4",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-T4",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "armour thyroid power t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "url": "/analytical-methods/armour-thyroid-power-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ganetespib-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Ganetespib",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/analytical-methods/ganetespib-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganetespib surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganetespib-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Ganetespib",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mylotarg air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mylotarg air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/mylotarg-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mylotarg air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mylotarg air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mylotarg-air-ih-sample-method",
    "url": "/analytical-methods/mylotarg-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pancrelipase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pancrelipase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/pancrelipase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pancrelipase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pancrelipase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pancrelipase-air-ih-sample-method",
    "url": "/analytical-methods/pancrelipase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/glycopyrrolate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Glycopyrrolate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/analytical-methods/glycopyrrolate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "glycopyrrolate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/glycopyrrolate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Glycopyrrolate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Chondroitin-sulfate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/chondroitinase-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Chondroitin-sulfate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chondroitinase surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chondroitinase-surface-ih-sample-method",
    "url": "/analytical-methods/chondroitinase-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cytarabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cytarabine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/analytical-methods/cytarabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cytarabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cytarabine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cytarabine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefazolin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefazolin sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefazolin-sodium-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefazolin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/insulin-air-ih-sample--method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Insulin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/analytical-methods/insulin-air-ih-sample--method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin air ih sample  method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample--method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Insulin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxytetracyclin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxytetracyclin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/oxytetracyclin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxytetracyclin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxytetracyclin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxytetracyclin-air-ih-sample-method",
    "url": "/analytical-methods/oxytetracyclin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Budesonide",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Budesonide Surface IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Budesonide-Surface-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Budesonide",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/florinal-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butalbital-Florinal",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/analytical-methods/florinal-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "florinal air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/florinal-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butalbital-Florinal",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Actinomycin-D",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/dactinomycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Actinomycin-D",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dactinomycin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dactinomycin-surface-ih-sample-method",
    "url": "/analytical-methods/dactinomycin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cyclosporin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cyclosporin-a-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cyclosporin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-air-ih-sample-method",
    "url": "/analytical-methods/cyclosporin-a-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "salinomycin sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "salinomycin sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "salinomycin sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "salinomycin sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "url": "/analytical-methods/salinomycin-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tretinoin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tretinoin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tretinoin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tretinoin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "retinol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "retinol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/retinol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "retinol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "retinol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/retinol-air-ih-sample-method",
    "url": "/analytical-methods/retinol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "url": "/analytical-methods/pergolide-mesylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tenofovir-df-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-surface-ih-sample-method",
    "url": "/analytical-methods/tenofovir-df-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganciclovir-Tripropionate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ganciclovir-Tripropionate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ganciclovir tripropionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "url": "/analytical-methods/ganciclovir-tripropionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "procarbazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "procarbazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "procarbazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "procarbazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "url": "/analytical-methods/procarbazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Irinotecan",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Irinotecan",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "irinotecan hcl surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "url": "/analytical-methods/irinotecan-hcl-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/lactose-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lactose air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lactose-air-ih-sample-method",
    "url": "/analytical-methods/lactose-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tobramycin HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tobramycin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tobramycin HPLC Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-hplc-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tobramycin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Vesanoid Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/vesanoid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/vesanoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Vesanoid Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/vesanoid-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/vesanoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/atropine-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Atropine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/analytical-methods/atropine-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "atropine sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/atropine-sulfate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Atropine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-H",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-H",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Vitamin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Vitamin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cholecalciferol vit d3 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "url": "/analytical-methods/cholecalciferol-vit-d3-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "paclitaxel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "paclitaxel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/paclitaxel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "paclitaxel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "paclitaxel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/paclitaxel-air-ih-sample-method",
    "url": "/analytical-methods/paclitaxel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levonorgestrel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levonorgestrel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/levonorgestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levonorgestrel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levonorgestrel air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levonorgestrel-air-ih-sample-method",
    "url": "/analytical-methods/levonorgestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ribavirin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ribavirin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ribavirin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ribavirin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ribavirin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ribavirin-air-ih-sample-method",
    "url": "/analytical-methods/ribavirin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Dimethyl",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dimethyl fumarate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dimethyl-fumarate-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Dimethyl",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "matulene air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "matulene air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/matulene-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "matulene air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "matulene air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/matulene-air-ih-sample-method",
    "url": "/analytical-methods/matulene-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Enclomiphene-Citrate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Enclomiphene-Citrate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen air ih sample method fixed 11 12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen air ih sample method fixed 11 12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen air ih sample method fixed 11 12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "url": "/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen air ih sample method fixed 11 12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "url": "/analytical-methods/naproxen-air-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tacrolimus air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tacrolimus air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tacrolimus-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tacrolimus air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tacrolimus air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tacrolimus-air-ih-sample-method",
    "url": "/analytical-methods/tacrolimus-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/isotretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Isotretinoin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/analytical-methods/isotretinoin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "isotretinoin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/isotretinoin-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Isotretinoin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norgestimate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norgestimate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/norgestimate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norgestimate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norgestimate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norgestimate-air-ih-sample-method",
    "url": "/analytical-methods/norgestimate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Betamethasone",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Betamethasone",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "betamethasone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "url": "/analytical-methods/betamethasone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cyclosporin-A",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Cyclosporin-A",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cyclosporin a lql air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "url": "/analytical-methods/cyclosporin-a-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-DMx",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "dmx cdmx surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-DMx",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefamandole",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefamandole",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefamandole nafate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "url": "/analytical-methods/cefamandole-nafate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Fosamax",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/fosamax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Fosamax",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fosamax air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fosamax-air-ih-sample-method",
    "url": "/analytical-methods/fosamax-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nac gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nac gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nac gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nac gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "url": "/analytical-methods/nac-gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/everlase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Everlase",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/analytical-methods/everlase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "everlase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/everlase-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Everlase",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefaclor-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefaclor surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefaclor-surface-ih-sample-method",
    "url": "/analytical-methods/cefaclor-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ethinyl Estradiol ELISA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-elisa-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ethinyl-estradiol-elisa-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ethinyl Estradiol ELISA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-elisa-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ethinyl-estradiol-elisa-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df lql surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df lql surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df lql surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tenofovir df lql surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "url": "/analytical-methods/tenofovir-df-lql-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/etretinate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Etretinate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/analytical-methods/etretinate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "etretinate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/etretinate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Etretinate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "url": "/analytical-methods/nafarelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/butalbital-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butalbital",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/analytical-methods/butalbital-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butalbital air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butalbital-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butalbital",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "temazepam air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "temazepam air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/temazepam-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "temazepam air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "temazepam air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/temazepam-air-ih-sample-method",
    "url": "/analytical-methods/temazepam-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/mannitol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mannitol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mannitol-air-ih-sample-method",
    "url": "/analytical-methods/mannitol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/efavirenz-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Efavirenz",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/analytical-methods/efavirenz-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "efavirenz air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/efavirenz-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Efavirenz",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "DMx cDMx Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/dmx-cdmx-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "DMx cDMx Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/dmx-cdmx-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/dmx-cdmx-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ceftazidime-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Ceftazidime",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/analytical-methods/ceftazidime-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ceftazidime surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ceftazidime-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Ceftazidime",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "neofurtulon air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "neofurtulon air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/neofurtulon-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "neofurtulon air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "neofurtulon air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/neofurtulon-air-ih-sample-method",
    "url": "/analytical-methods/neofurtulon-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tigason Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tigason-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tigason-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tigason Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tigason-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tigason-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "spinetoram surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "spinetoram surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/spinetoram-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "spinetoram surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "spinetoram surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/spinetoram-surface-ih-sample-method",
    "url": "/analytical-methods/spinetoram-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Xenical Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/xenical-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/xenical-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Xenical Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/xenical-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/xenical-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Amikacin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Amikacin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "amikacin sulfate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "url": "/analytical-methods/amikacin-sulfate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Sodium LQL Surface IH Sample Method (fixed 11.12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11.12)",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11.12)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Sodium LQL Surface IH Sample Method (fixed 11.12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11.12)",
    "url": "/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11.12)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "terramycin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "terramycin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/terramycin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "terramycin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "terramycin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/terramycin-air-ih-sample-method",
    "url": "/analytical-methods/terramycin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefuroxime-Sodium",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefuroxime-Sodium",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime sodium surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "url": "/analytical-methods/cefuroxime-sodium-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium lql surface ih sample method (fixed 11 12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium lql surface ih sample method (fixed 11 12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium lql surface ih sample method (fixed 11 12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "url": "/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium lql surface ih sample method (fixed 11 12)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-(fixed-11-12)",
    "url": "/analytical-methods/naproxen-sodium-lql-surface-ih-sample-method-fixed-11-12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Bendamustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "bendamustine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/bendamustine-hcl-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Bendamustine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nafarelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "url": "/analytical-methods/nafarelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Air IH Sample Method fixed 11.12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11.12",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11.12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Naproxen Air IH Sample Method fixed 11.12",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11.12",
    "url": "/search-chemical-analytical-methods/naproxen-air-ih-sample-method-fixed-11.12",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tezacitabine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tezacitabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tezacitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tezacitabine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tezacitabine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tezacitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Zopiclone Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/zopiclone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/zopiclone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Zopiclone Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/zopiclone-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/zopiclone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefuroxime",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cefuroxime axetil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cefuroxime-axetil-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cefuroxime",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "prasterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "prasterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/prasterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "prasterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "prasterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/prasterone-air-ih-sample-method",
    "url": "/analytical-methods/prasterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Digoxin-C",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/digoxin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Digoxin-C",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-surface-ih-sample-method",
    "url": "/analytical-methods/digoxin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/leuprolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "leuprolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/leuprolide-air-ih-sample-method",
    "url": "/analytical-methods/leuprolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/calcitriol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Calcitriol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/analytical-methods/calcitriol-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "calcitriol air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/calcitriol-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Calcitriol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pilocarpine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pilocarpine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pilocarpine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pilocarpine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "url": "/analytical-methods/pilocarpine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Flunisolide",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/flunisolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Flunisolide",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "flunisolide air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/flunisolide-air-ih-sample-method",
    "url": "/analytical-methods/flunisolide-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levothyroxine t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levothyroxine t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levothyroxine t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "levothyroxine t4 air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "url": "/analytical-methods/levothyroxine-t4-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Digoxin EIA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method-2",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method-2",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Digoxin EIA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method-2",
    "url": "/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method-2",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "progesterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "progesterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/progesterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "progesterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "progesterone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/progesterone-air-ih-sample-method",
    "url": "/analytical-methods/progesterone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tolcapone Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tolcapone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tolcapone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tolcapone Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tolcapone-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tolcapone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Triptorelin Acetate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/triptorelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Triptorelin Acetate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/triptorelin-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lyophilised triptorelin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lyophilised triptorelin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lyophilised triptorelin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lyophilised triptorelin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "url": "/analytical-methods/lyophilised-triptorelin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Zalcitabine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/zalcitabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/zalcitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Zalcitabine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/zalcitabine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/zalcitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Diatrizoic",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "diatrizoic acid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/diatrizoic-acid-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Diatrizoic",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nebivolol hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nebivolol hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nebivolol hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "nebivolol hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "url": "/analytical-methods/nebivolol-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium (lql method)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium (lql method)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/naproxen-sodium-lql-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium (lql method)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "url": "/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium (lql method)",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-(lql-method)",
    "url": "/analytical-methods/naproxen-sodium-lql-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/azathioprine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Azathioprine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/analytical-methods/azathioprine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "azathioprine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/azathioprine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Azathioprine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "url": "/analytical-methods/niraparib-tosylate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/docetaxel-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Docetaxel",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/analytical-methods/docetaxel-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "docetaxel surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/docetaxel-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Docetaxel",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "teriparatide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "teriparatide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/teriparatide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "teriparatide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "teriparatide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/teriparatide-surface-ih-sample-method",
    "url": "/analytical-methods/teriparatide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butoconazole",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "butoconazole nitrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/butoconazole-nitrate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Butoconazole",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamoxifen citrate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "url": "/analytical-methods/tamoxifen-citrate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamsulosin hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamsulosin hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamsulosin hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "tamsulosin hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "url": "/analytical-methods/tamsulosin-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "pergolide mesylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "url": "/analytical-methods/pergolide-mesylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "taxotere surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "taxotere surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/taxotere-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "taxotere surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "taxotere surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/taxotere-surface-ih-sample-method",
    "url": "/analytical-methods/taxotere-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Fludrocortisone",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fludrocortisone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fludrocortisone-acetate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Fludrocortisone",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Clozapine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/clozapine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Clozapine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "clozapine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/clozapine-air-ih-sample-method",
    "url": "/analytical-methods/clozapine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Gamma",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Gamma",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "gamma calicheamicin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "url": "/analytical-methods/gamma-calicheamicin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Digoxin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/digoxin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Digoxin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin-eia-air-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-eia-air-ih-sample-method",
    "url": "/analytical-methods/digoxin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/norethindrone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-air-ih-sample-method",
    "url": "/analytical-methods/norethindrone-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tenofovir DF LQL Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tenofovir-df-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tenofovir DF LQL Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tenofovir-df-lql-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tenofovir-df-lql-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Doxycycline-Hyclate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Doxycycline-Hyclate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxycycline hyclate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "url": "/analytical-methods/doxycycline-hyclate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Insulin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/insulin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Insulin Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/insulin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "triptorelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "triptorelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "triptorelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "triptorelin acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "url": "/analytical-methods/triptorelin-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/firocoxib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-ML713",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/analytical-methods/firocoxib-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "firocoxib air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/firocoxib-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-ML713",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lansoprazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lansoprazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/lansoprazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lansoprazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "lansoprazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/lansoprazole-air-ih-sample-method",
    "url": "/analytical-methods/lansoprazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Actinomycin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/actinomycin-d-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Actinomycin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "actinomycin d surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/actinomycin-d-surface-ih-sample-method",
    "url": "/analytical-methods/actinomycin-d-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "niraparib tosylate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "url": "/analytical-methods/niraparib-tosylate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Digoxin-T",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/digoxin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Digoxin-T",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "digoxin hplc air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/digoxin-hplc-air-ih-sample-method",
    "url": "/analytical-methods/digoxin-hplc-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ranolazine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-surface-ih-sample-method",
    "url": "/analytical-methods/ranolazine-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "norethindrone acetate surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "url": "/analytical-methods/norethindrone-acetate-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-5-Fluorouracil",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/fluorouracil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-5-Fluorouracil",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-air-ih-sample-method",
    "url": "/analytical-methods/fluorouracil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/darifenacin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Darifenacin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/analytical-methods/darifenacin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "darifenacin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/darifenacin-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Darifenacin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Insulin-Human",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/insulin-human-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/insulin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Insulin-Human",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/analytical-methods/insulin-human-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "insulin human air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/insulin-human-air-ih-sample-method",
    "url": "/analytical-methods/insulin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Chlormadinone-Acetate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Chlormadinone-Acetate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "chlormadinone acetate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "url": "/analytical-methods/chlormadinone-acetate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Desogestrel Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/desogestrel-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/desogestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Desogestrel Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/desogestrel-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/desogestrel-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "milnacipran hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "milnacipran hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "milnacipran hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "milnacipran hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "url": "/analytical-methods/milnacipran-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Ethinyl-Estradiol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ethinyl estradiol surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ethinyl-estradiol-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Ethinyl-Estradiol",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "sertindole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "sertindole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/sertindole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "sertindole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "sertindole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/sertindole-air-ih-sample-method",
    "url": "/analytical-methods/sertindole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxfendazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxfendazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/oxfendazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxfendazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "oxfendazole air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/oxfendazole-air-ih-sample-method",
    "url": "/analytical-methods/oxfendazole-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Acitretin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/acitretin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Acitretin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "acitretin air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/acitretin-air-ih-sample-method",
    "url": "/analytical-methods/acitretin-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "maytansinoid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "maytansinoid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/maytansinoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "maytansinoid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "maytansinoid air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/maytansinoid-air-ih-sample-method",
    "url": "/analytical-methods/maytansinoid-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Bromocriptine-Mesylate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Bromocriptine-Mesylate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Bromocriptine Mesylate Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "url": "/analytical-methods/Bromocriptine-Mesylate-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cephalexin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalexin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/analytical-methods/cephalexin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalexin-surface-ih-sample-method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalexin-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalexin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tobramycin EIA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-eia-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/tobramycin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Tobramycin EIA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/tobramycin-eia-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/tobramycin-eia-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/naproxen-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "naproxen sodium air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/naproxen-sodium-air-ih-sample-method",
    "url": "/analytical-methods/naproxen-sodium-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Lactase-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Lactase-beta",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/analytical-methods/Lactase-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Lactase Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Lactase-Air-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Lactase-beta",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/doxifluridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Doxifluridine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/analytical-methods/doxifluridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "doxifluridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/doxifluridine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Doxifluridine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ketorolac-Tromethamine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Ketorolac Tromethamine Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/Ketorolac-Tromethamine-Air-IH-Sample-Method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Ketorolac-Tromethamine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/capecitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Capecitabine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/analytical-methods/capecitabine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "capecitabine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/capecitabine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Capecitabine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/floxuridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Floxuridine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/analytical-methods/floxuridine-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "floxuridine air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/floxuridine-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Floxuridine",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/cephalothin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cephalothin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/analytical-methods/cephalothin-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "cephalothin surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/cephalothin-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-Cephalosporin-Cephalothin",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "orlistat air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "orlistat air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/orlistat-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "orlistat air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "orlistat air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/orlistat-air-ih-sample-method",
    "url": "/analytical-methods/orlistat-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "thalidomide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "thalidomide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/thalidomide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "thalidomide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "thalidomide surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/thalidomide-surface-ih-sample-method",
    "url": "/analytical-methods/thalidomide-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Fluocinolone Acetonide ELISA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluocinolone-acetonide-elisa-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fluocinolone-acetonide-elisa-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Fluocinolone Acetonide ELISA Air IH Sample Method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluocinolone-acetonide-elisa-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fluocinolone-acetonide-elisa-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Lactase",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/beta-galactosidase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Lactase",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "beta galactosidase air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/beta-galactosidase-air-ih-sample-method",
    "url": "/analytical-methods/beta-galactosidase-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Hydroxycarbamate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Hydroxycarbamate",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "hydroxycarbamate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "url": "/analytical-methods/hydroxycarbamate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary-Enclomiphene",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary-Enclomiphene",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "enclomiphene citrate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "url": "/analytical-methods/enclomiphene-citrate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/abarelix-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-air-sampling-method-summary",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/analytical-methods/abarelix-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "abarelix air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/abarelix-air-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-air-sampling-method-summary",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "ranolazine hcl air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "url": "/analytical-methods/ranolazine-hcl-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "testosterone propionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "testosterone propionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/testosterone-propionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "testosterone propionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "testosterone propionate air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/testosterone-propionate-air-ih-sample-method",
    "url": "/analytical-methods/testosterone-propionate-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/industrial-hygiene-surface-sampling-method-summary-5-Fluorouracil-Efludex",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/fluorouracil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/analytical-methods/industrial-hygiene-surface-sampling-method-summary-5-Fluorouracil-Efludex",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "fluorouracil surface ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/fluorouracil-surface-ih-sample-method",
    "url": "/analytical-methods/fluorouracil-surface-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mycophenolate mofetil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mycophenolate mofetil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mycophenolate mofetil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "url": "/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "mycophenolate mofetil air ih sample method",
    "apiurl": "/restapi/customlaboratorysubsubcategoriescontent/search-chemical-analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "url": "/analytical-methods/mycophenolate-mofetil-air-ih-sample-method",
    "template": "new-sub-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Part 61 NESHAP Compliance Support",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/Part-61-Neshap-Compliance-Support",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/Part-61-Neshap-Compliance-Support",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Part 61 NESHAP Compliance Support",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/Part-61-Neshap-Compliance-Support",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/Part-61-Neshap-Compliance",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "NAEM Research Report: The Emergence of Virtual EHS Audits",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/ehs-management/Auditing-and-Verification/naem-research-report-the-emergence-of-virtual-ehs-audits",
    "url": "/consulting/ehs/ehs-management/Auditing-and-Verification/naem-research-report-the-emergence-of-virtual-ehs-audits",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Virtual and Remote Auditing eBook",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/ehs-management/Auditing-and-Verification/virtual-and-remote-auditing-ebook",
    "url": "/consulting/ehs/ehs-management/Auditing-and-Verification/virtual-and-remote-auditing-ebook",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Virtual and Remote Auditing eBook",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/ehs-management/Auditing-and-Verification/virtual-and-remote-auditing-ebook",
    "url": "/auditing-ebook",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Permit By Rule (PBR)",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/Air-Quality-Permitting/permit-by-rule",
    "url": "/consulting/ehs/air-quality/Air-Quality-Permitting/permit-by-rule",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Photochemical Grid Modeling",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/Air-Dispersion-Modeling/photochemical-modeling",
    "url": "/consulting/ehs/air-quality/Air-Dispersion-Modeling/photochemical-modeling",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Photochemical Grid Modeling",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/Air-Dispersion-Modeling/photochemical-modeling",
    "url": "/consulting/ehs-services/air-quality/Air-Dispersion-Modeling/photochemical-modeling",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Automation Solutions",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-solutions",
    "url": "/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-solutions",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Applied Meteorology",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/applied-meteorology",
    "url": "/consulting/ehs/air-quality/ambient-monitoring-service-integration/applied-meteorology",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Applied Meteorology",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/applied-meteorology",
    "url": "/consulting/ehs-services/air-quality/ambient-and-meteorological-monitoring/applied-meteorology",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Applied Meteorology",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/applied-meteorology",
    "url": "/consulting/ehs/air-quality/ambient-and-meteorological-monitoring/applied-meteorology",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Applied Meteorology",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/applied-meteorology",
    "url": "/environmental-consulting/ehs-services/air-quality/ambient-and-meteorological-monitoring/applied-meteorology",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Ambient Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/ambient-monitoring",
    "url": "/consulting/ehs/air-quality/ambient-monitoring-service-integration/ambient-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Ambient Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/ambient-monitoring",
    "url": "/consulting/ehs/air-quality/ambient-and-meteorological-monitoring/ambient-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Automation Platforms",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-platforms",
    "url": "/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-platforms",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Meteorological Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/meteorological-monitoring",
    "url": "/consulting/ehs/air-quality/ambient-monitoring-service-integration/meteorological-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Meteorological Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/meteorological-monitoring",
    "url": "/consulting/ehs-services/air-quality/ambient-and-meteorological-monitoring/meteorological-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Meteorological Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/meteorological-monitoring",
    "url": "/environmental-consulting/ehs-services/air-quality/ambient-and-meteorological-monitoring/meteorological-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Meteorological Monitoring",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/ambient-monitoring-service-integration/meteorological-monitoring",
    "url": "/consulting/ehs/air-quality/ambient-and-meteorological-monitoring/meteorological-monitoring",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-engineering",
    "url": "/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-engineering",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering-technology/automation-engineering",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering/automation-engineering",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering-technology/automation-engineering-technology",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Regional Haze Rule Background",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/Regional-Haze/rule-background",
    "url": "/consulting/ehs/air-quality/Regional-Haze/rule-background",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM/RMP Mechanical Integrity",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/health-safety/psm-rmp-support/mechanical-integrity",
    "url": "/consulting/ehs/health-safety/psm-rmp-support/mechanical-integrity",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM/RMP Mechanical Integrity",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/health-safety/psm-rmp-support/mechanical-integrity",
    "url": "/consulting/ehs/health-safety/psm-rmp-support/psm-rmp-mechanical-integrity",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality Services",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/air-quality-services-in-australia/air-quality-services",
    "url": "/consulting/ehs/air-quality/air-quality-services-in-australia/air-quality-services",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Hygiene Air Sampling Method Summary - Abarelix",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-method-summary---abarelix",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/analytical-method-summary---abarelix",
    "template": "new-consulting-individual-category-detail-type",
    "section": "science"
  },
  {
    "name": "NSPS Compliance Support",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/NSPS-Compliance-support",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/NSPS-Compliance-support",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "NSPS Compliance Support",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/NSPS-Compliance-support",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/NSPS-Compliance",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Procedure for Sample Submission",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis/procedure-for-sample-submission",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis/procedure-for-sample-submission",
    "template": "new-consulting-individual-category-detail-type",
    "section": "science"
  },
  {
    "name": "State Stormwater Pollution Prevention Planning",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/water-quality/stormwater-pollution-prevention-plan-swppp/state-stormwater-pollution-prevention-planning",
    "url": "/consulting/ehs/water-quality/stormwater-pollution-prevention-plan-swppp/state-stormwater-pollution-prevention-planning",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "State Stormwater Pollution Prevention Planning",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/water-quality/stormwater-pollution-prevention-plan-swppp/state-stormwater-pollution-prevention-planning",
    "url": "/state-swppp",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM/RMP Compliance Auditing",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/health-safety/psm-rmp-support/compliance-auditing",
    "url": "/consulting/ehs/health-safety/psm-rmp-support/compliance-auditing",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Automation Migration Services",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-migration-services",
    "url": "/consulting/engineering/biopharma-engineering-services/automation-engineering/automation-migration-services",
    "template": "new-consulting-individual-category-detail-type",
    "section": "engineering"
  },
  {
    "name": "Part 63 NESHAP Compliance Support",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/part-63-neshap-compliance-support",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance/part-63-neshap-compliance-support",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Compliance Audits",
    "apiurl": "/restapi/customconsultinglvl5content/consulting/ehs/chemical-compliance/PSM-RMP-Support/compliance-audits",
    "url": "/consulting/ehs/chemical-compliance/PSM-RMP-Support/compliance-audits",
    "template": "new-consulting-individual-category-detail-type",
    "section": "environmental-health-safety"
  },
  {
    "name": "Engineering & EHS Staffing",
    "apiurl": "/restapi/customcontent/contact-us/engineering-ehs-staffing",
    "url": "/contact-us/engineering-ehs-staffing",
    "template": "general_content",
    "section": "engineering-ehs-staffing"
  },
  {
    "name": "Complex Building Design",
    "apiurl": "/restapi/customcontent/contact-us/complex-building-design",
    "url": "/contact-us/complex-building-design",
    "template": "general_content",
    "section": "complex-building-design"
  },
  {
    "name": "Audio-Visual/Security/Technology Services",
    "apiurl": "/restapi/customcontent/contact-us/audio-visual-security-technology-services",
    "url": "/contact-us/audio-visual-security-technology-services",
    "template": "general_content",
    "section": "audio-visualsecuritytechnology-services"
  },
  {
    "name": "Life Science Technical Services",
    "apiurl": "/restapi/customcontent/contact-us/life-science-technical-services",
    "url": "/contact-us/life-science-technical-services",
    "template": "general_content",
    "section": "life-science-technical-services"
  },
  {
    "name": "EHS Consulting",
    "apiurl": "/restapi/customcontent/contact-us/ehs-consulting",
    "url": "/contact-us/ehs-consulting",
    "template": "general_content",
    "section": "ehs-consulting"
  },
  {
    "name": "Acoustics/Vibration Support",
    "apiurl": "/restapi/customcontent/contact-us/acoustics-vibration-support",
    "url": "/contact-us/acoustics-vibration-support",
    "template": "general_content",
    "section": "acousticsvibration-support"
  },
  {
    "name": "BREEZE",
    "apiurl": "/restapi/customcontent/contact-us/breeze",
    "url": "/contact-us/breeze",
    "template": "general_content",
    "section": "breeze"
  },
  {
    "name": "Digital Solutions",
    "apiurl": "/restapi/customcontent/contact-us/digital-solutions",
    "url": "/contact-us/digital-solutions",
    "template": "general_content",
    "section": "digital-solutions"
  },
  {
    "name": "General FAQs",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/general-faqs",
    "url": "/software/explosion/exdam/general-faqs",
    "template": "general_content",
    "section": "general-faqs"
  },
  {
    "name": "ExDAM Technical Questions",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/exdam-technical-questions",
    "url": "/software/explosion/exdam/exdam-technical-questions",
    "template": "general_content",
    "section": "exdam-technical-questions"
  },
  {
    "name": "Bio-Pharma Science Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/biopharma-science-services",
    "url": "/consulting/science/biopharma-science-services",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Bio-Pharma Science Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/biopharma-science-services",
    "url": "/consulting/science/biopharma-services",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Bio-Pharma Science Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/biopharma-science-services",
    "url": "/environmental-consulting/science/biopharma-services",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Geologic / Hydrogeologic Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/geologic-hydrogeologic-services",
    "url": "/consulting/science/geologic-hydrogeologic-services",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Chemical Compliance",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/chemical-compliance",
    "url": "/consulting/ehs/chemical-compliance",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Chemical Compliance",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/chemical-compliance",
    "url": "/environmental-consulting/ehs-services/chemical-compliance",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Chemical Compliance",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/chemical-compliance",
    "url": "/consulting/ehs-services/chemical-compliance",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Chemical Compliance",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/chemical-compliance",
    "url": "/consulting/environmental-health-safety/chemical-compliance",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/air-quality",
    "url": "/consulting/ehs/air-quality",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Process Safety",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/process-safety",
    "url": "/consulting/engineering/process-safety",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Bio-Pharma Engineering Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/biopharma-engineering-services",
    "url": "/consulting/engineering/biopharma-engineering-services",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Bio-Pharma Engineering Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/biopharma-engineering-services",
    "url": "/consulting/engineering/biopharma-services",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Bio-Pharma Engineering Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/biopharma-engineering-services",
    "url": "/advent/biopharma-services",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Toxicology",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/toxicology",
    "url": "/consulting/science/toxicology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Health & Safety",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/health-safety",
    "url": "/consulting/ehs/health-safety",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Hygiene Analytical Laboratory",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/industrial-hygiene-analytical-laboratory",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Pharmacy Compounding Program Support",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/pharmacy-compounding-program-support",
    "url": "/consulting/engineering/pharmacy-compounding-program-support",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "EHS Litigation Support",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/litigation-support",
    "url": "/consulting/ehs/litigation-support",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Water Quality and Marine Ecology Monitoring",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/water-quality-and-marine-ecology-monitoring",
    "url": "/consulting/science/water-quality-and-marine-ecology-monitoring",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Healthcare Cleanroom and Research Facilities",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/healthcare-cleanroom-and-research-facilities",
    "url": "/consulting/science/healthcare-cleanroom-and-research-facilities",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Built Environment",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/built-environment",
    "url": "/consulting/engineering/built-environment",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Terrestrial Ecology",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/terrestrial-ecology",
    "url": "/consulting/science/terrestrial-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Water Quality",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/water-quality",
    "url": "/consulting/ehs/water-quality",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Water Quality",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/water-quality",
    "url": "/consulting/ehs/water-quality-regulations",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/waste-management",
    "url": "/consulting/ehs/waste-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/waste-management",
    "url": "/consulting/environmental-health-safety/waste-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/waste-management",
    "url": "/consulting/ehs-services/waste-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/waste-management",
    "url": "/environmental-consulting/ehs-services/waste-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/ehs-management",
    "url": "/consulting/ehs/ehs-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/ehs-management",
    "url": "/consulting/ehs-services/ehs-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/ehs-management",
    "url": "/environmental-consulting/ehs-services/ehs-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/ehs-management",
    "url": "/consulting/environmental-health-safety/ehs-management",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality in Australia",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/air-quality-in-australia",
    "url": "/consulting/ehs/air-quality-in-australia",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "ESG / Sustainability",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/esg-and-sustainability",
    "url": "/consulting/ehs/esg-and-sustainability",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "ESG / Sustainability",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/esg-and-sustainability",
    "url": "/trinityconsultants.com/consulting/ehs/climate-change-and-sustainability",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "ESG / Sustainability",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/esg-and-sustainability",
    "url": "/esg-sustainability",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "ESG / Sustainability",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/esg-and-sustainability",
    "url": "/consulting/ehs/climate-change-and-sustainability",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "ESG / Sustainability",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/esg-and-sustainability",
    "url": "/climate-change-and-sustainability",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Tank Roof Design",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/tank-roof-design",
    "url": "/consulting/engineering/tank-roof-design",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Environmental Impact Analysis",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/ehs/environmental-impact-analysis",
    "url": "/consulting/ehs/environmental-impact-analysis",
    "template": "new-consulting-individual-category",
    "section": "environmental-health-safety"
  },
  {
    "name": "Engine Certification",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/engine-certification",
    "url": "/consulting/engineering/engine-certification",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Engine Certification",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/engine-certification",
    "url": "/consulting/science/engine-certification",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Engine Certification",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/engineering/engine-certification",
    "url": "/environmental-consulting/engineering/engine-certification",
    "template": "new-consulting-individual-category",
    "section": "engineering"
  },
  {
    "name": "Aquatic Environmental Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/aquatic-ecology",
    "url": "/consulting/science/aquatic-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/aquatic-ecology",
    "url": "/consulting/science2/aquatic-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/aquatic-ecology",
    "url": "/consulting/air-quality/aquatic-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/aquatic-ecology",
    "url": "/environmental-consulting/science/aquatic-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Services",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/aquatic-ecology",
    "url": "/environmental-consulting/science2/aquatic-ecology",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Ingredient Approval and Product Safety",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/ingredient-approval",
    "url": "/consulting/science/ingredient-approval",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Ingredient Approval and Product Safety",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/ingredient-approval",
    "url": "/environmental-consulting/science/regulatory-services-for-ingredient-approval",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "Ingredient Approval and Product Safety",
    "apiurl": "/restapi/customconsultinglvl3content/consulting/science/ingredient-approval",
    "url": "/environmental-consulting/science/ingredient-approval",
    "template": "new-consulting-individual-category",
    "section": "science"
  },
  {
    "name": "BRMS Access Request",
    "apiurl": "/restapi/customcontent/software-request-key",
    "url": "/software-request-key",
    "template": "general_content",
    "section": "brms-access-request"
  },
  {
    "name": "Asia & Pacific",
    "apiurl": "/restapi/customcontent/default-mainlocation/asia-pacific",
    "url": "/default-mainlocation/asia-pacific",
    "template": "general_content",
    "section": "asia-pacific"
  },
  {
    "name": "Asia & Pacific",
    "apiurl": "/restapi/customcontent/default-mainlocation/asia-pacific",
    "url": "/asia-pacific",
    "template": "general_content",
    "section": "asia-pacific"
  },
  {
    "name": "Asia",
    "apiurl": "/restapi/customcontent/locations/asia",
    "url": "/locations/asia",
    "template": "general_content",
    "section": "asia"
  },
  {
    "name": "Asia",
    "apiurl": "/restapi/customcontent/locations/asia",
    "url": "/locations/asia-pacific",
    "template": "general_content",
    "section": "asia"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/locations/australia-location",
    "url": "/locations/australia-location",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/locations/australia-location",
    "url": "/locations/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "Location",
    "apiurl": "/restapi/customcontent/default-mainlocation/location",
    "url": "/default-mainlocation/location",
    "template": "general_content",
    "section": "location"
  },
  {
    "name": "Location",
    "apiurl": "/restapi/customcontent/default-mainlocation/location",
    "url": "/location",
    "template": "general_content",
    "section": "location"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/locations/north-america",
    "url": "/locations/north-america",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/locations/north-america",
    "url": "/location/north-america",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "Europe",
    "apiurl": "/restapi/customcontent/locations/europe",
    "url": "/locations/europe",
    "template": "general_content",
    "section": "europe"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica",
    "url": "/default-mainlocation/northamerica",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica",
    "url": "/northamerica",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "Europe",
    "apiurl": "/restapi/customcontent/default-mainlocation/europe",
    "url": "/default-mainlocation/europe",
    "template": "general_content",
    "section": "europe"
  },
  {
    "name": "Europe",
    "apiurl": "/restapi/customcontent/default-mainlocation/europe",
    "url": "/europe",
    "template": "general_content",
    "section": "europe"
  },
  {
    "name": "Process Design",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/process-design",
    "url": "/consulting/engineering/biopharma-engineering-services/process-design",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Process Design",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/process-design",
    "url": "/environmental-consulting/engineering/biopharma-services/process-design",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Process Design",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/process-design",
    "url": "/consulting/engineering/biopharma-services/process-design",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Hazard Communications and GHS Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "url": "/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Hazard Communications and GHS Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "url": "/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Hazard Communications and GHS Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "url": "/consulting/ehs/chemical-compliance/hazard-communication-and-ghs-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Hazard Communications and GHS Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "url": "/consulting/ehs/chemical-compliance/Hazard-Communication-and-GHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Hazard Communications and GHS Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Hazard-Communications-and-GHS-services",
    "url": "/consulting/ehs/chemical-compliance/Hazard-Communication-and-GHS-Services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Decarbonization Strategy Development",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/decarbonization-strategy-development",
    "url": "/consulting/ehs/esg-and-sustainability/decarbonization-strategy-development",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "LDAR Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/LDAR-Support",
    "url": "/consulting/ehs/air-quality/LDAR-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Third-Party Verification Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/third-party-verification-services",
    "url": "/consulting/ehs/air-quality/third-party-verification-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality Services in Australia",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/air-quality-services-in-australia",
    "url": "/consulting/ehs/air-quality/air-quality-services-in-australia",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Fuels Regulations Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/fuels-regulations",
    "url": "/consulting/ehs/air-quality/fuels-regulations",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Fuels Regulations Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/fuels-regulations",
    "url": "/consulting/ehs/air-quality/fuels-regulations-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Tank Roof Design Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/tank-roof-design/tank-roof-design-services",
    "url": "/consulting/engineering/tank-roof-design/tank-roof-design-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "NESHAP and NSPS Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance",
    "url": "/consulting/ehs/air-quality/NESHAP-and-NSPS-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Compliance Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/waste-compliance-support",
    "url": "/consulting/ehs/waste-management/waste-compliance-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Analytical Methods",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Sample Analysis",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Sample Analysis",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis",
    "url": "/environmental-consulting/science/industrial-hygiene-analytical-laboratory/sample-analysis",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Due Diligence Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Due-Diligence-Support",
    "url": "/consulting/ehs/ehs-management/Due-Diligence-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Due Diligence Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Due-Diligence-Support",
    "url": "/consulting/ehs-services/ehs-management/Due-Diligence-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Due Diligence Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Due-Diligence-Support",
    "url": "/environmental-consulting/ehs-services/ehs-management/Due-Diligence-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Statistical Analysis of Environmental Data",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/statistical-analysis-environmental-data",
    "url": "/consulting/science/aquatic-ecology/statistical-analysis-environmental-data",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Statistical Analysis of Environmental Data",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/statistical-analysis-environmental-data",
    "url": "/consulting/science/aquatic-ecology/statistical-analysis-of-environmental-data",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Statistical Analysis of Environmental Data",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/statistical-analysis-environmental-data",
    "url": "/environmental-consulting/science/aquatic-ecology/statistical-analysis-of-environmental-data",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Environmental Services - Built Environment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/environmental-services-built-environment",
    "url": "/consulting/engineering/built-environment/environmental-services-built-environment",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Fish and Fish Habitat Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/fish-and-fish-habitat-studies",
    "url": "/consulting/science/aquatic-ecology/fish-and-fish-habitat-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Fish and Fish Habitat Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/fish-and-fish-habitat-studies",
    "url": "/environmental-consulting/science/aquatic-ecology/fish-and-fish-habitat-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "sample string 823",
    "apiurl": "/restapi/customconsultinglvl4content/sampledata1",
    "url": "/sampledata1",
    "template": "new-consulting-individual-category-detail",
    "section": "sample-string-823"
  },
  {
    "name": "EHS Management Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Management-Systems",
    "url": "/consulting/ehs/ehs-management/EHS-Management-Systems",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Management-Systems",
    "url": "/environmental-consulting/ehs-services/ehs-management/EHS-Management-Systems",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Management Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Management-Systems",
    "url": "/consulting/ehs-services/ehs-management/EHS-Management-Systems",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Release Inventory (TRI)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Toxic-Release-Inventory",
    "url": "/consulting/ehs/chemical-compliance/Toxic-Release-Inventory",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Release Inventory (TRI)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/Toxic-Release-Inventory",
    "url": "/consulting/ehs/chemical-compliance/Toxics-Release-Inventory",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/services/chemical-compliance/toxic-substance-control-act-and-reporting-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/environmental-consulting/ehs-services/chemical-compliance/toxic-substance-control-act-tsca-reporting-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/consulting/ehs-services/chemical-compliance/toxic-substance-control-act-tsca-reporting-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/consulting/ehs/chemical-compliance/toxic-substance-control-act-tsca-reporting-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxic Substance Control Act & Reporting Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting",
    "url": "/consulting/ehs/chemical-compliance/toxic-substance-control-act-and-reporting-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Air Quality Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/industrial-air-quality-litigation",
    "url": "/consulting/ehs/litigation-support/industrial-air-quality-litigation",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Air Quality Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/industrial-air-quality-litigation",
    "url": "/consulting/ehs/litigation-support/industrial-air-quality-litigation-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Permitting and Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Permitting-and-Compliance",
    "url": "/consulting/ehs/water-quality/Permitting-and-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Permitting and Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Permitting-and-Compliance",
    "url": "/consulting/ehs/water-quality-regulations/Permitting-and-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Permitting and Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Permitting-and-Compliance",
    "url": "/consulting/ehs-services/water-quality-regulations/Permitting-and-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Permitting and Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Permitting-and-Compliance",
    "url": "/environmental-consulting/ehs-services/water-quality-regulations/Permitting-and-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Stormwater Pollution Prevention Plan (SWPPP)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/stormwater-pollution-prevention-plan-swppp",
    "url": "/consulting/ehs/water-quality/stormwater-pollution-prevention-plan-swppp",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Ecological Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-assessment",
    "url": "/consulting/science/terrestrial-ecology/ecological-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Ecological Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-assessment",
    "url": "/consulting/science/terrestrial-ecology/planning-applications-development",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Weather Related Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/weather-related-litigation",
    "url": "/consulting/ehs/litigation-support/weather-related-litigation",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Weather Related Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/weather-related-litigation",
    "url": "/consulting/ehs/litigation-support/weather-related-litigation-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Odor Analysis",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Odor-Analysis",
    "url": "/consulting/ehs/ehs-management/Odor-Analysis",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Odor Analysis",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Odor-Analysis",
    "url": "/environmental-consulting/ehs-services/ehs-management/Odor-Analysis",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Odor Analysis",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Odor-Analysis",
    "url": "/consulting/ehs-services/ehs-management/Odor-Analysis",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "sample string 8",
    "apiurl": "/restapi/customconsultinglvl4content/sampledata",
    "url": "/sampledata",
    "template": "new-consulting-individual-category-detail",
    "section": "sample-string-8"
  },
  {
    "name": "Commissioning, Qualification and Validation",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/commissioning-qualification-and-validation",
    "url": "/consulting/engineering/biopharma-engineering-services/commissioning-qualification-and-validation",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Commissioning, Qualification and Validation",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/commissioning-qualification-and-validation",
    "url": "/consulting/engineering/biopharma-services/commissioning-qualification-and-validation",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Commissioning, Qualification and Validation",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/commissioning-qualification-and-validation",
    "url": "/environmental-consulting/engineering/biopharma-services/commissioning-qualification-and-validation",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/overview",
    "url": "/consulting/ehs/litigation-support/overview",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Technical Support and Training",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/technical-support-and-training",
    "url": "/consulting/science/aquatic-ecology/technical-support-and-training",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Quality Assurance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/quality-assurance",
    "url": "/consulting/science/ingredient-approval/quality-assurance",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Quality Assurance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/quality-assurance",
    "url": "/environmental-consulting/science/regulatory-services-for-ingredient-approval/quality-assurance",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Quality Assurance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/quality-assurance",
    "url": "/environmental-consulting/science/ingredient-approval/quality-assurance",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Biology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Aquatic-Biology",
    "url": "/consulting/ehs/water-quality/Aquatic-Biology",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Aquatic Biology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Aquatic-Biology",
    "url": "/consulting/ehs/water-quality-regulations/Aquatic-Biology",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Aquatic Biology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Aquatic-Biology",
    "url": "/consulting/ehs-services/water-quality-regulations/Aquatic-Biology",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Aquatic Biology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Aquatic-Biology",
    "url": "/environmental-consulting/ehs-services/water-quality-regulations/Aquatic-Biology",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Regional Haze Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Regional-Haze",
    "url": "/consulting/ehs/air-quality/Regional-Haze",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Compounding Pharmacy Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/pharmacy-compounding-program-support/pharmacy-compounding-program-support",
    "url": "/consulting/engineering/pharmacy-compounding-program-support/pharmacy-compounding-program-support",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Ecological Survey",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-survey",
    "url": "/consulting/science/terrestrial-ecology/ecological-survey",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Ecological Survey",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-survey",
    "url": "/consulting/science/terrestrial-ecology/ecological-survey-and-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Ambient Monitoring and Service Integration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/ambient-monitoring-service-integration",
    "url": "/consulting/ehs/air-quality/ambient-monitoring-service-integration",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Ambient Monitoring and Service Integration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/ambient-monitoring-service-integration",
    "url": "/consulting/ehs/air-quality/ambient-and-meteorological-monitoring",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Geologic / Hydrogeologic Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/geologic-hydrogeologic-services/geologic-hydrogeologic-services",
    "url": "/consulting/science/geologic-hydrogeologic-services/geologic-hydrogeologic-services",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Environmental Geochemistry",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/environmental-geochemistry",
    "url": "/consulting/science/aquatic-ecology/environmental-geochemistry",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Regulatory Permitting, Authorizations, and Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/regulatory-permitting-authorizations-and-compliance",
    "url": "/consulting/science/aquatic-ecology/regulatory-permitting-authorizations-and-compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Environmental Effects Monitoring (EEM)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/environmental-effects-monitoring",
    "url": "/consulting/science/aquatic-ecology/environmental-effects-monitoring",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Environmental Effects Monitoring (EEM)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/environmental-effects-monitoring",
    "url": "/consulting/science/aquatic-ecology/environmental-effects-monitoring-eem",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Health Risk Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Health-Risk-Assessment",
    "url": "/consulting/ehs/health-safety/Health-Risk-Assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Health Risk Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Health-Risk-Assessment",
    "url": "/consulting/ehs/health-safety/Risk-Assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Building Sustainability & Environmental",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/sustainability-services",
    "url": "/consulting/engineering/built-environment/sustainability-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Building Sustainability & Environmental",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/sustainability-services",
    "url": "/building-sustainability",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Tank Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Tank-Services",
    "url": "/consulting/ehs/ehs-management/Tank-Services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Tank Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Tank-Services",
    "url": "/consulting/ehs-services/ehs-management/Tank-Services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Tank Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Tank-Services",
    "url": "/environmental-consulting/ehs-services/ehs-management/Tank-Services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS and Sustainability Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "url": "/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS and Sustainability Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "url": "/consulting/ehs/ehs-management/Environmental-and-Sustainability-Reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS and Sustainability Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "url": "/environmental-and-sustainability-reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS and Sustainability Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "url": "/environmental-consulting/ehs-services/ehs-management/Environmental-and-Sustainability-Reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS and Sustainability Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-and-Sustainability-Reporting",
    "url": "/consulting/ehs-services/ehs-management/Environmental-and-Sustainability-Reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Building Acoustics Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/building-services/acoustics-support",
    "url": "/consulting/engineering/building-services/acoustics-support",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Mobile Source Support and Engine Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/engine-certification/mobile-source-support-and-engine-certification",
    "url": "/consulting/engineering/engine-certification/mobile-source-support-and-engine-certification",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Mobile Source Support and Engine Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/engine-certification/mobile-source-support-and-engine-certification",
    "url": "/environmental-consulting/engineering/engine-certification/mobile-source-support-and-engine-certification",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Facility & Site Condition Assessments",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/facility-site-condition-assessments",
    "url": "/consulting/engineering/built-environment/facility-site-condition-assessments",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Air Dispersion Modeling",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Air-Dispersion-Modeling",
    "url": "/consulting/ehs/air-quality/Air-Dispersion-Modeling",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Process Safety Management/Risk Management Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/psm-rmp-support",
    "url": "/consulting/ehs/health-safety/psm-rmp-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Process Safety Management/Risk Management Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/psm-rmp-support",
    "url": "/consulting/ehs/chemical-compliance/psm-rmp-compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Monitoring and Sampling Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/monitoring-sampling",
    "url": "/consulting/ehs/water-quality/monitoring-sampling",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Monitoring and Sampling Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/monitoring-sampling",
    "url": "/environmental-consulting/ehs-services/water-quality-regulations/monitoring-sampling",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Monitoring and Sampling Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/monitoring-sampling",
    "url": "/consulting/ehs-services/water-quality-regulations/monitoring-sampling",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Monitoring and Sampling Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/monitoring-sampling",
    "url": "/consulting/ehs/water-quality-regulations/monitoring-sampling",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Analytical Laboratory",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/industrial-hygiene-analytical-laboratory/analytical-laboratory",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-laboratory",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Safety Testing Program Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/study-design",
    "url": "/consulting/science/ingredient-approval/study-design",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Safety Testing Program Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/study-design",
    "url": "/environmental-consulting/science/ingredient-approval/study-design",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Biological and Ecological Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-biological-and-ecological-assessment",
    "url": "/consulting/science/aquatic-ecology/aquatic-biological-and-ecological-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Monitoring and Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-environmental-monitoring-and-assessment",
    "url": "/consulting/science/aquatic-ecology/aquatic-environmental-monitoring-and-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Monitoring and Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-environmental-monitoring-and-assessment",
    "url": "/environmental-consulting/science/aquatic-ecology/aquatic-environmental-monitoring-and-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Contaminant Dispersion and Hydrodynamic Mixing in Aquatic Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/contaminant-dispersion-hydrodynamic-mixing",
    "url": "/consulting/science/aquatic-ecology/contaminant-dispersion-hydrodynamic-mixing",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Contaminant Dispersion and Hydrodynamic Mixing in Aquatic Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/contaminant-dispersion-hydrodynamic-mixing",
    "url": "/consulting/science/aquatic-ecology/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Contaminant Dispersion and Hydrodynamic Mixing in Aquatic Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/contaminant-dispersion-hydrodynamic-mixing",
    "url": "/environmental-consulting/science/aquatic-ecology/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Air Quality Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality-in-australia/air-quality-services",
    "url": "/consulting/ehs/air-quality-in-australia/air-quality-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "CDP Verification and Assurance",
    "apiurl": "/restapi/customconsultinglvl4content/cdp-verification-and-assurance",
    "url": "/cdp-verification-and-assurance",
    "template": "new-consulting-individual-category-detail",
    "section": "cdp-verification-and-assurance"
  },
  {
    "name": "Industrial Hygiene and Occupational Safety",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/industrial-hygiene-occupational-safety",
    "url": "/consulting/ehs/health-safety/industrial-hygiene-occupational-safety",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Industrial Hygiene and Occupational Safety",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/industrial-hygiene-occupational-safety",
    "url": "/consulting/ehs/health-safety/industrial-hygiene-and-occupational-safety-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Pandemic Response Planning",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/pandemic-response-planning",
    "url": "/consulting/ehs/health-safety/pandemic-response-planning",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Pandemic Response Planning",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/pandemic-response-planning",
    "url": "/consulting/ehs/health-safety/pandemic-response-plan",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSI & Data Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/psi-data-management",
    "url": "/consulting/engineering/process-safety/psi-data-management",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Antimicrobials and Specialty Chemical Regulatory Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/antimicrobials-and-specialty-chemical-regulatory-support",
    "url": "/consulting/science/ingredient-approval/antimicrobials-and-specialty-chemical-regulatory-support",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Cap-and-Trade Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Cap-and-Trade",
    "url": "/consulting/ehs/air-quality/Cap-and-Trade",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Cap-and-Trade Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Cap-and-Trade",
    "url": "/consulting/ehs/air-quality/Cap-and-Trade-Services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Toxicology Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/toxicology/toxicology-services",
    "url": "/consulting/science/toxicology/toxicology-services",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/EHS",
    "url": "/consulting/ehs/health-safety/EHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/EHS",
    "url": "/consulting/environmental-health-safety/health-safety/EHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/EHS",
    "url": "/environmental-consulting/ehs-services/health-safety/EHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/EHS",
    "url": "/consulting/ehs-services/health-safety/EHS",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "California Proposition 65 Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/California-Proposition-65",
    "url": "/consulting/ehs/chemical-compliance/California-Proposition-65",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "California Proposition 65 Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/California-Proposition-65",
    "url": "/consulting/ehs/chemical-compliance/California-Proposition-65-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Panel Design & Fabrication",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/panel-design-fabrication",
    "url": "/consulting/engineering/biopharma-engineering-services/panel-design-fabrication",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Pressure Relief Systems",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/pressure-relief-systems",
    "url": "/consulting/engineering/process-safety/pressure-relief-systems",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Industrial Hygiene for Life Sciences",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/industrial-hygiene-for-life-sciences",
    "url": "/consulting/ehs/health-safety/industrial-hygiene-for-life-sciences",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "CFATS Compliance",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/CFATS-Compliance",
    "url": "/consulting/ehs/chemical-compliance/CFATS-Compliance",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Sustainability Program Support and ESG Disclosure",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "url": "/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Sustainability Program Support and ESG Disclosure",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "url": "/consulting/ehs/climate-change-and-sustainability/enterprise-sustainability-program-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Sustainability Program Support and ESG Disclosure",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "url": "/consulting/ehs/esg-and-sustainability/enterprise-sustainability-program-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Sustainability Program Support and ESG Disclosure",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "url": "/enterprise-sustainability-program-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Sustainability Program Support and ESG Disclosure",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/esg-and-sustainability-support",
    "url": "/trinityconsultants.com/consulting/ehs/climate-change-and-sustainability/enterprise-sustainability-program-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Combustible Dust Risk Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/combustible-dust-risk-management",
    "url": "/consulting/ehs/health-safety/combustible-dust-risk-management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Combustible Dust Risk Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/combustible-dust-risk-management",
    "url": "/Services/EHS-Management/Combustible-Dust-Risk-Management/",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality Permitting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Air-Quality-Permitting",
    "url": "/consulting/ehs/air-quality/Air-Quality-Permitting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality Permitting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Air-Quality-Permitting",
    "url": "/consulting/ehs-services/air-quality/Air-Quality-Permitting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality Permitting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Air-Quality-Permitting",
    "url": "/environmental-consulting/ehs-services/air-quality/Air-Quality-Permitting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Justice",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/environmental-justice",
    "url": "/consulting/ehs/ehs-management/environmental-justice",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Water Quality and Marine Ecology Monitoring",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/water-quality-and-marine-ecology-monitoring/water-quality-and-marine-ecology-monitoring",
    "url": "/consulting/science/water-quality-and-marine-ecology-monitoring/water-quality-and-marine-ecology-monitoring",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Emissions Inventory and Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Emissions-Reporting",
    "url": "/consulting/ehs/air-quality/Emissions-Reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Ecological Licensing, Mitigation and Enhancement",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-licensing-mitigation-enhancement",
    "url": "/consulting/science/terrestrial-ecology/ecological-licensing-mitigation-enhancement",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Ecological Licensing, Mitigation and Enhancement",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/terrestrial-ecology/ecological-licensing-mitigation-enhancement",
    "url": "/consulting/science/terrestrial-ecology/ecological-contracting",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "General Process Safety Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/general-process-safety-services",
    "url": "/consulting/engineering/process-safety/general-process-safety-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "General Process Safety Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/general-process-safety-services",
    "url": "/consulting/engineering/process-safety/general-process-safety",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "General Process Safety Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/general-process-safety-services",
    "url": "/provenance-process-safety-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Process Development for Biopharmaceuticals",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/biopharma-science-services/process-development-for-pharmaceuticals",
    "url": "/consulting/science/biopharma-science-services/process-development-for-pharmaceuticals",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Process Development for Biopharmaceuticals",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/biopharma-science-services/process-development-for-pharmaceuticals",
    "url": "/consulting/science/biopharma-services/process-development-for-pharmaceuticals",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Habitat Evaluations",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-habitat-evaluations",
    "url": "/consulting/science/aquatic-ecology/aquatic-habitat-evaluations",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Habitat Evaluations",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-habitat-evaluations",
    "url": "/environmental-consulting/science/aquatic-ecology/aquatic-habitat-evaluations",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Emissions Testing",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Emissions-Testing",
    "url": "/consulting/ehs/air-quality/Emissions-Testing",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Emissions Testing",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Emissions-Testing",
    "url": "/environmental-consulting/ehs-services/air-quality/Emissions-Testing",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Emissions Testing",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Emissions-Testing",
    "url": "/consulting/ehs-services/air-quality/Emissions-Testing",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Business Risk Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Business-Risk-Management",
    "url": "/consulting/ehs/ehs-management/EHS-Business-Risk-Management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Business Risk Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Business-Risk-Management",
    "url": "/environmental-consulting/ehs-services/ehs-management/EHS-Business-Risk-Management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "EHS Business Risk Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/EHS-Business-Risk-Management",
    "url": "/consulting/ehs-services/ehs-management/EHS-Business-Risk-Management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Environmental Sustainability Metrics",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/esg-and-sustainability/environmental-sustainability-metrics",
    "url": "/consulting/ehs/esg-and-sustainability/environmental-sustainability-metrics",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Photochemical Grid Modeling",
    "apiurl": "/restapi/customconsultinglvl4content/photochemical-grid-modeling",
    "url": "/photochemical-grid-modeling",
    "template": "new-consulting-individual-category-detail",
    "section": "photochemical-grid-modeling"
  },
  {
    "name": "NEPA / CEQA Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/environmental-impact-analysis/nepa-ceqa-support",
    "url": "/consulting/ehs/environmental-impact-analysis/nepa-ceqa-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/automation-engineering",
    "url": "/consulting/engineering/biopharma-engineering-services/automation-engineering",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/automation-engineering",
    "url": "/environmental-consulting/engineering/biopharma-services/automation-engineering-technology",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering-technology",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Aquatic Toxicological Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-toxicological-studies",
    "url": "/consulting/science/aquatic-ecology/aquatic-toxicological-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Chemistry Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-chemistry-studies",
    "url": "/consulting/science/aquatic-ecology/aquatic-chemistry-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Chemistry Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-chemistry-studies",
    "url": "/consulting/science/aquatic-ecology/6-aquatic-chemistry-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Chemistry Studies",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-chemistry-studies",
    "url": "/environmental-consulting/science/aquatic-ecology/6-aquatic-chemistry-studies",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Acoustics, Noise, Vibration & Lighting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/acoustics-noise-vibration-lighting/acoustics-noise-vibration-lighting",
    "url": "/consulting/engineering/acoustics-noise-vibration-lighting/acoustics-noise-vibration-lighting",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Potent-Compound-Safety",
    "url": "/consulting/ehs/health-safety/Potent-Compound-Safety",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Potent-Compound-Safety",
    "url": "/consulting/ehs/health-safety/Potent-Compound-Safety-Triangle",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Potent-Compound-Safety",
    "url": "/consulting/environmental-health-safety/health-safety/Potent-Compound-Safety-Triangle",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Potent-Compound-Safety",
    "url": "/consulting/ehs-services/health-safety/Potent-Compound-Safety-Triangle",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Potent-Compound-Safety",
    "url": "/environmental-consulting/ehs-services/health-safety/Potent-Compound-Safety-Triangle",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Project Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/project-management",
    "url": "/consulting/engineering/biopharma-engineering-services/project-management",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Project Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/project-management",
    "url": "/environmental-consulting/engineering/biopharma-services/project-management",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Project Management",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/project-management",
    "url": "/consulting/engineering/biopharma-services/project-management",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "TSDF Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/TSDF-support",
    "url": "/consulting/ehs/waste-management/TSDF-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "TSDF Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/TSDF-support",
    "url": "/consulting/ehs/waste-management/Industrial-and-Solid-Waste-Management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Spill Prevention, Control, and Countermeasure Plan",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Spill-Prevention-Control-Countermeasure-Plan",
    "url": "/consulting/ehs/water-quality/Spill-Prevention-Control-Countermeasure-Plan",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Spill Prevention, Control, and Countermeasure Plan",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Spill-Prevention-Control-Countermeasure-Plan",
    "url": "/consulting/ehs/water-quality-regulations/Spill-Prevention-Control-Countermeasure-Plan",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Spill Prevention, Control, and Countermeasure Plan",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/water-quality/Spill-Prevention-Control-Countermeasure-Plan",
    "url": "/consulting/ehs/water-quality-regulations/Spill-Prevention-Control-and-Countermeasure-Plan",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/mechanical-integrity-inspections",
    "url": "/consulting/engineering/process-safety/mechanical-integrity-inspections",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/mechanical-integrity-inspections",
    "url": "/consulting/engineering/process-safety/fixed-equipment-mechanical-integrity-inspections",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/mechanical-integrity-inspections",
    "url": "/provenance-mechanical-integrity-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Continuous Emissions Monitoring Support (CEMS)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/air-quality/Emissions-Monitoring-Support",
    "url": "/consulting/ehs/air-quality/Emissions-Monitoring-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Combustion Specialty",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/Combustion-Specialty",
    "url": "/consulting/ehs/waste-management/Combustion-Specialty",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Building Technology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/building-technology-services",
    "url": "/consulting/engineering/built-environment/building-technology-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Building Technology",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/building-technology-services",
    "url": "/consulting/engineering/built-environment/building-technology",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Facility Operations Services",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/transitional-operational-services",
    "url": "/consulting/engineering/built-environment/transitional-operational-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Noise and Vibration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Noise-and-Vibration",
    "url": "/consulting/ehs/ehs-management/Noise-and-Vibration",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Noise and Vibration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Noise-and-Vibration",
    "url": "/consulting/ehs-services/ehs-management/Noise-and-Vibration",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Noise and Vibration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Noise-and-Vibration",
    "url": "/environmental-consulting/ehs-services/ehs-management/Noise-and-Vibration",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Land Use Planning",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/environmental-impact-analysis/land-use-planning",
    "url": "/consulting/ehs/environmental-impact-analysis/land-use-planning",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Aquatic Environmental Impact Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-environmental-impact-assessment",
    "url": "/consulting/science/aquatic-ecology/aquatic-environmental-impact-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Aquatic Environmental Impact Assessment",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-environmental-impact-assessment",
    "url": "/environmental-consulting/science/aquatic-ecology/aquatic-environmental-impact-assessment",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Food and Beverage Regulatory Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/food-and-beverage-regulatory-support",
    "url": "/consulting/science/ingredient-approval/food-and-beverage-regulatory-support",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Auditing and Verification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Auditing-and-Verification",
    "url": "/consulting/ehs/ehs-management/Auditing-and-Verification",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Auditing and Verification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Auditing-and-Verification",
    "url": "/environmental-consulting/ehs-services/ehs-management/Auditing-and-Verification",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Auditing and Verification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Auditing-and-Verification",
    "url": "/auditing-and-verification",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Auditing and Verification",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/ehs-management/Auditing-and-Verification",
    "url": "/consulting/ehs-services/ehs-management/Auditing-and-Verification",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Chemical Inventory Reporting",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/chemical-inventory-reporting",
    "url": "/consulting/ehs/chemical-compliance/chemical-inventory-reporting",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM / RMP Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/PSM-RMP-Support",
    "url": "/consulting/ehs/chemical-compliance/PSM-RMP-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM / RMP Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/PSM-RMP-Support",
    "url": "/consulting/ehs-services/chemical-compliance/PSM-RMP-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "PSM / RMP Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/PSM-RMP-Support",
    "url": "/environmental-consulting/ehs-services/chemical-compliance/PSM-RMP-Support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Acoustics, Noise & Vibration",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/building-acoustics-noise-and-vibration-services",
    "url": "/consulting/engineering/built-environment/building-acoustics-noise-and-vibration-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "IPL Lifecycle Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/ipl-lifecycle-support",
    "url": "/consulting/engineering/process-safety/ipl-lifecycle-support",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Aquatic Environmental Monitoring",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/aquatic-ecology/aquatic-environmental-monitoring",
    "url": "/consulting/science/aquatic-ecology/aquatic-environmental-monitoring",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Air Quality and Noise Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/air-quality-noise-litigation",
    "url": "/consulting/ehs/litigation-support/air-quality-noise-litigation",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Air Quality and Noise Litigation Support",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/litigation-support/air-quality-noise-litigation",
    "url": "/consulting/ehs/litigation-support/air-quality-noise-litigation-support",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "PHA and Risk Assessments",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/pha-and-risk-assessments",
    "url": "/consulting/engineering/process-safety/pha-and-risk-assessments",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "PHA and Risk Assessments",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/process-safety/pha-and-risk-assessments",
    "url": "/consulting/engineering/process-safety/pha-risk-assessments",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Safety and Loss Control",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/health-safety/Safety-Loss-Control",
    "url": "/consulting/ehs/health-safety/Safety-Loss-Control",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "CFD Modeling for Facility Design",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/biopharma-engineering-services/cfd-modeling-for-facility-design",
    "url": "/consulting/engineering/biopharma-engineering-services/cfd-modeling-for-facility-design",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Refrigerant Management Services (ODS)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/refrigerant-management",
    "url": "/consulting/ehs/chemical-compliance/refrigerant-management",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Refrigerant Management Services (ODS)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/refrigerant-management",
    "url": "/consulting/ehs/chemical-compliance/refrigerant-management-services",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Scientific and Regulatory Affairs",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/regulatory-interface",
    "url": "/consulting/science/ingredient-approval/regulatory-interface",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Scientific and Regulatory Affairs",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/regulatory-interface",
    "url": "/environmental-consulting/science/ingredient-approval/regulatory-interface",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Scientific and Regulatory Affairs",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/science/ingredient-approval/regulatory-interface",
    "url": "/environmental-consulting/science/regulatory-services-for-ingredient-approval/regulatory-interface",
    "template": "new-consulting-individual-category-detail",
    "section": "science"
  },
  {
    "name": "Facility Response Plans (FRP)",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/chemical-compliance/facility-response-plans-frp",
    "url": "/consulting/ehs/chemical-compliance/facility-response-plans-frp",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Waste Characterization",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/waste-characterization",
    "url": "/consulting/ehs/waste-management/waste-characterization",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Commissioning",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/commissioning-services",
    "url": "/consulting/engineering/built-environment/commissioning-services",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Commissioning",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/engineering/built-environment/commissioning-services",
    "url": "/commissioning",
    "template": "new-consulting-individual-category-detail",
    "section": "engineering"
  },
  {
    "name": "Alternative Fuels and Beneficial Reuse",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/Alternative-Fuel-Beneficial-Reuse",
    "url": "/consulting/ehs/waste-management/Alternative-Fuel-Beneficial-Reuse",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Alternative Fuels and Beneficial Reuse",
    "apiurl": "/restapi/customconsultinglvl4content/consulting/ehs/waste-management/Alternative-Fuel-Beneficial-Reuse",
    "url": "/consulting/ehs/waste-management/Alternative-Fuel-and-Beneficial-Reuse",
    "template": "new-consulting-individual-category-detail",
    "section": "environmental-health-safety"
  },
  {
    "name": "Romania",
    "apiurl": "/restapi/customcontent/contact-us/romania",
    "url": "/contact-us/romania",
    "template": "general_content",
    "section": "romania"
  },
  {
    "name": "Bangladesh",
    "apiurl": "/restapi/customcontent/contact-us/bangladesh",
    "url": "/contact-us/bangladesh",
    "template": "general_content",
    "section": "bangladesh"
  },
  {
    "name": "Nepal",
    "apiurl": "/restapi/customcontent/contact-us/nepal",
    "url": "/contact-us/nepal",
    "template": "general_content",
    "section": "nepal"
  },
  {
    "name": "Malaysia",
    "apiurl": "/restapi/customcontent/contact-us/malaysia",
    "url": "/contact-us/malaysia",
    "template": "general_content",
    "section": "malaysia"
  },
  {
    "name": "Indonesia",
    "apiurl": "/restapi/customcontent/contact-us/indonesia",
    "url": "/contact-us/indonesia",
    "template": "general_content",
    "section": "indonesia"
  },
  {
    "name": "Mexico",
    "apiurl": "/restapi/customcontent/contact-us/mexico",
    "url": "/contact-us/mexico",
    "template": "general_content",
    "section": "mexico"
  },
  {
    "name": "Brazil",
    "apiurl": "/restapi/customcontent/contact-us/brazil",
    "url": "/contact-us/brazil",
    "template": "general_content",
    "section": "brazil"
  },
  {
    "name": "South Africa",
    "apiurl": "/restapi/customcontent/contact-us/south-africa",
    "url": "/contact-us/south-africa",
    "template": "general_content",
    "section": "south-africa"
  },
  {
    "name": "Turkey",
    "apiurl": "/restapi/customcontent/contact-us/turkey",
    "url": "/contact-us/turkey",
    "template": "general_content",
    "section": "turkey"
  },
  {
    "name": "Singapore",
    "apiurl": "/restapi/customcontent/contact-us/singapore",
    "url": "/contact-us/singapore",
    "template": "general_content",
    "section": "singapore"
  },
  {
    "name": "Chile",
    "apiurl": "/restapi/customcontent/contact-us/chile",
    "url": "/contact-us/chile",
    "template": "general_content",
    "section": "chile"
  },
  {
    "name": "Sri Lanka",
    "apiurl": "/restapi/customcontent/contact-us/sri-lanka",
    "url": "/contact-us/sri-lanka",
    "template": "general_content",
    "section": "sri-lanka"
  },
  {
    "name": "Sultanate of Oman",
    "apiurl": "/restapi/customcontent/contact-us/sultanate-of-oman",
    "url": "/contact-us/sultanate-of-oman",
    "template": "general_content",
    "section": "sultanate-of-oman"
  },
  {
    "name": "United Arab Emirates",
    "apiurl": "/restapi/customcontent/contact-us/united-arab-emirates",
    "url": "/contact-us/united-arab-emirates",
    "template": "general_content",
    "section": "united-arab-emirates"
  },
  {
    "name": "Italy",
    "apiurl": "/restapi/customcontent/contact-us/italy",
    "url": "/contact-us/italy",
    "template": "general_content",
    "section": "italy"
  },
  {
    "name": "Peru",
    "apiurl": "/restapi/customcontent/contact-us/peru",
    "url": "/contact-us/peru",
    "template": "general_content",
    "section": "peru"
  },
  {
    "name": "Philippines",
    "apiurl": "/restapi/customcontent/contact-us/philippines",
    "url": "/contact-us/philippines",
    "template": "general_content",
    "section": "philippines"
  },
  {
    "name": "Israel",
    "apiurl": "/restapi/customcontent/contact-us/israel",
    "url": "/contact-us/israel",
    "template": "general_content",
    "section": "israel"
  },
  {
    "name": "Poland",
    "apiurl": "/restapi/customcontent/contact-us/poland",
    "url": "/contact-us/poland",
    "template": "general_content",
    "section": "poland"
  },
  {
    "name": "Colombia",
    "apiurl": "/restapi/customcontent/contact-us/colombia",
    "url": "/contact-us/colombia",
    "template": "general_content",
    "section": "colombia"
  },
  {
    "name": "Vietnam",
    "apiurl": "/restapi/customcontent/contact-us/vietnam",
    "url": "/contact-us/vietnam",
    "template": "general_content",
    "section": "vietnam"
  },
  {
    "name": "South Korea",
    "apiurl": "/restapi/customcontent/contact-us/south-korea",
    "url": "/contact-us/south-korea",
    "template": "general_content",
    "section": "south-korea"
  },
  {
    "name": "Argentina",
    "apiurl": "/restapi/customcontent/contact-us/argentina",
    "url": "/contact-us/argentina",
    "template": "general_content",
    "section": "argentina"
  },
  {
    "name": "Create Account",
    "apiurl": "/restapi/customcontent/create-account",
    "url": "/create-account",
    "template": "general_content",
    "section": "create-account"
  },
  {
    "name": "Technology",
    "apiurl": "/restapi/customtechnologycontent/technology",
    "url": "/technology",
    "template": "new-technology-landing",
    "section": "technology"
  },
  {
    "name": "Provenance Process Safety/Risk Management",
    "apiurl": "/restapi/customservicebrandcontent/provenance-process-safety-risk-management",
    "url": "/provenance-process-safety-risk-management",
    "template": "new-trinity-service-brand",
    "section": "provenance-process-safetyrisk-management"
  },
  {
    "name": "Trinity Consultants Australia",
    "apiurl": "/restapi/customservicebrandcontent/trinity-consultants-australia",
    "url": "/trinity-consultants-australia",
    "template": "new-trinity-service-brand",
    "section": "trinity-consultants-australia"
  },
  {
    "name": "Trinity Consultants Australia",
    "apiurl": "/restapi/customservicebrandcontent/trinity-consultants-australia",
    "url": "/australia",
    "template": "new-trinity-service-brand",
    "section": "trinity-consultants-australia"
  },
  {
    "name": "SafeBridge Regulatory & Life Sciences Group",
    "apiurl": "/restapi/customservicebrandcontent/safebridge-regulatory-life-sciences-group",
    "url": "/safebridge-regulatory-life-sciences-group",
    "template": "new-trinity-service-brand",
    "section": "safebridge-regulatory-life-sciences-group"
  },
  {
    "name": "SafeBridge Regulatory & Life Sciences Group",
    "apiurl": "/restapi/customservicebrandcontent/safebridge-regulatory-life-sciences-group",
    "url": "/safebridge",
    "template": "new-trinity-service-brand",
    "section": "safebridge-regulatory-life-sciences-group"
  },
  {
    "name": "Minnow Aquatic Environmental Services",
    "apiurl": "/restapi/customservicebrandcontent/Minnow-Aquatic-Environmental-Services",
    "url": "/Minnow-Aquatic-Environmental-Services",
    "template": "new-trinity-service-brand",
    "section": "minnow-aquatic-environmental-services"
  },
  {
    "name": "Minnow Aquatic Environmental Services",
    "apiurl": "/restapi/customservicebrandcontent/Minnow-Aquatic-Environmental-Services",
    "url": "/minnow",
    "template": "new-trinity-service-brand",
    "section": "minnow-aquatic-environmental-services"
  },
  {
    "name": "Advent Engineering Life Sciences Solutions",
    "apiurl": "/restapi/customservicebrandcontent/advent-life-sciences-engineering-services",
    "url": "/advent-life-sciences-engineering-services",
    "template": "new-trinity-service-brand",
    "section": "advent-engineering-life-sciences-solutions"
  },
  {
    "name": "Advent Engineering Life Sciences Solutions",
    "apiurl": "/restapi/customservicebrandcontent/advent-life-sciences-engineering-services",
    "url": "/Advent-Engineering-Life-Science-Solutions",
    "template": "new-trinity-service-brand",
    "section": "advent-engineering-life-sciences-solutions"
  },
  {
    "name": "Advent Engineering Life Sciences Solutions",
    "apiurl": "/restapi/customservicebrandcontent/advent-life-sciences-engineering-services",
    "url": "/Advent",
    "template": "new-trinity-service-brand",
    "section": "advent-engineering-life-sciences-solutions"
  },
  {
    "name": "Advent Engineering Life Sciences Solutions",
    "apiurl": "/restapi/customservicebrandcontent/advent-life-sciences-engineering-services",
    "url": "/Aztec",
    "template": "new-trinity-service-brand",
    "section": "advent-engineering-life-sciences-solutions"
  },
  {
    "name": "Mechanical Reliability Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/mechanical-reliability-engineer-albany",
    "url": "/staffinges/available-positions/mechanical-reliability-engineer-albany",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-manager-ar",
    "url": "/staffinges/available-positions/safety-manager-ar",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Chemical Structural Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/chemical-structural-engineer-albany",
    "url": "/staffinges/available-positions/chemical-structural-engineer-albany",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental, Health, Safety & Security Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-health-safety-security-manager",
    "url": "/staffinges/available-positions/environmental-health-safety-security-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Project Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-project-manager",
    "url": "/staffinges/available-positions/environmental-project-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-engineer",
    "url": "/staffinges/available-positions/environmental-engineer",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental, Health, Safety & Security Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-health-safety-security-manager-PA",
    "url": "/staffinges/available-positions/environmental-health-safety-security-manager-PA",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHSS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehss-manager",
    "url": "/staffinges/available-positions/ehss-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "R&D Polymer Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/rd-polymer-engineer-detroit",
    "url": "/staffinges/available-positions/rd-polymer-engineer-detroit",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Lead",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-lead-joplin",
    "url": "/staffinges/available-positions/environmental-lead-joplin",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Air Specialist, CEMS",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/air-specialist-cems-pa",
    "url": "/staffinges/available-positions/air-specialist-cems-pa",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Engineering Recruiter",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/engineering-recruiter",
    "url": "/staffinges/available-positions/engineering-recruiter",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Laboratory Safety Specialist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/laboratory-safety-specialist-boston",
    "url": "/staffinges/available-positions/laboratory-safety-specialist-boston",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-manager-albany",
    "url": "/staffinges/available-positions/ehs-manager-albany",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Regulatory Specialist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/regulatory-specialist-durango",
    "url": "/staffinges/available-positions/regulatory-specialist-durango",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Training Specialist - Mining & Metals Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-training-specialist",
    "url": "/staffinges/available-positions/safety-training-specialist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Training Specialist - Mining & Metals Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-training-specialist",
    "url": "/staffinges/available-positions/safety-training-specialist---mining-metals-industry",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Training Specialist - Mining & Metals Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-training-specialist",
    "url": "/staffing/available-positions/safety-training-specialist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Training Specialist - Mining & Metals Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-training-specialist",
    "url": "/staffings/available-positions/safety-training-specialist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Lead",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-lead-dallas",
    "url": "/staffinges/available-positions/safety-lead-dallas",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Specialist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-specialist-mt-vernon",
    "url": "/staffinges/available-positions/ehs-specialist-mt-vernon",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Engineering Recruiter, E2HS",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/engineering-recruiter-e2hs",
    "url": "/staffinges/available-positions/engineering-recruiter-e2hs",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-engineer-TX",
    "url": "/staffinges/available-positions/environmental-engineer-TX",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "OC EHS Lead",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/oc-ehs-lead-ks",
    "url": "/staffinges/available-positions/oc-ehs-lead-ks",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Sr. Manufacturing Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/sr-manufacturing-engineer-indiana",
    "url": "/staffinges/available-positions/sr-manufacturing-engineer-indiana",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Senior Environmental Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/senior-environmental-manager",
    "url": "/staffinges/available-positions/senior-environmental-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-manager",
    "url": "/staffinges/available-positions/ehs-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental, Health and Safety (EH&S) Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-health-and-safety-ehs-manager",
    "url": "/staffinges/available-positions/environmental-health-and-safety-ehs-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental, Health and Safety (EH&S) Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-health-and-safety-ehs-manager",
    "url": "/staffing/available-positions/environmental-health-and-safety-ehs-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Waste Coordinator",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/waste-coordinator",
    "url": "/staffinges/available-positions/waste-coordinator",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Safety Coordinator",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/safety-coordinator",
    "url": "/staffinges/available-positions/safety-coordinator",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-manager-sedalia",
    "url": "/staffinges/available-positions/ehs-manager-sedalia",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-manager-maryville",
    "url": "/staffinges/available-positions/ehs-manager-maryville",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental, Health & Safety Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-health-safety-manager",
    "url": "/staffinges/available-positions/environmental-health-safety-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Sr. Industrial Hygienist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/sr-industrial-hygienist-boston",
    "url": "/staffinges/available-positions/sr-industrial-hygienist-boston",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Lead Scientist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/lead-scientist-wixom",
    "url": "/staffinges/available-positions/lead-scientist-wixom",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Health and Safety Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/health-and-safety-manager-boston",
    "url": "/staffinges/available-positions/health-and-safety-manager-boston",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Individual Position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/individual-position",
    "url": "/default-trinitystaffing/default-category/individual-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "Individual Position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/individual-position",
    "url": "/available-positions/individual-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "Individual Position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/individual-position",
    "url": "/ehs-recruiters/available-positions/available-positions/individual-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/default-trinitystaffing/default-category/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/staffing/available-positions/test-position/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/ehs-recruiters/available-positions/test-position/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/default-subcategory/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/default-trinitystaffing/default-category/default-subcategory/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "test position",
    "apiurl": "/restapi/customstaffinglvl3content/default-trinitystaffing/default-category/test-position",
    "url": "/test-position/test-position",
    "template": "new-stafﬁng-individual-position",
    "section": "moved-items"
  },
  {
    "name": "Industrial Hygienist - Biotechnology Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/industrial-hygienist",
    "url": "/staffinges/available-positions/industrial-hygienist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Industrial Hygienist - Biotechnology Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/industrial-hygienist",
    "url": "/staffings/available-positions/industrial-hygienist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Industrial Hygienist - Biotechnology Industry",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/industrial-hygienist",
    "url": "/staffing/available-positions/industrial-hygienist",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-manager",
    "url": "/staffinges/available-positions/environmental-manager",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "EHS Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-manager-columbus",
    "url": "/staffinges/available-positions/ehs-manager-columbus",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "OC EHS Leader",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/ehs-leader-portland",
    "url": "/staffinges/available-positions/ehs-leader-portland",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "DSM Process Safety and EHS Specialist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/dsm-process-safety-and-ehs-specialist-nj",
    "url": "/staffinges/available-positions/dsm-process-safety-and-ehs-specialist-nj",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Environmental Manager",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/environmental-manager-okc",
    "url": "/staffinges/available-positions/environmental-manager-okc",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Sr. Process Engineer",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/sr-process-engineer-albany",
    "url": "/staffinges/available-positions/sr-process-engineer-albany",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Sr. Industrial Hygienist",
    "apiurl": "/restapi/customstaffinglvl3content/staffinges/available-positions/sr-industrial-hygienist-albany",
    "url": "/staffinges/available-positions/sr-industrial-hygienist-albany",
    "template": "new-stafﬁng-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Individual Position",
    "apiurl": "/restapi/customcontent/apply-for-job",
    "url": "/apply-for-job",
    "template": "general_content",
    "section": "individual-position"
  },
  {
    "name": "AERMOD-Ready",
    "apiurl": "/restapi/customcontent/contact-us/aermod-ready",
    "url": "/contact-us/aermod-ready",
    "template": "general_content",
    "section": "aermod-ready"
  },
  {
    "name": "Meta 2",
    "apiurl": "/restapi/customcontent/contact-us/meta-2",
    "url": "/contact-us/meta-2",
    "template": "general_content",
    "section": "meta-2"
  },
  {
    "name": "ISC-Ready",
    "apiurl": "/restapi/customcontent/contact-us/isc-ready",
    "url": "/contact-us/isc-ready",
    "template": "general_content",
    "section": "isc-ready"
  },
  {
    "name": "NED Terrain",
    "apiurl": "/restapi/customcontent/contact-us/ned-terrain",
    "url": "/contact-us/ned-terrain",
    "template": "general_content",
    "section": "ned-terrain"
  },
  {
    "name": "CAL3QHCR-Ready",
    "apiurl": "/restapi/customcontent/contact-us/cal3qhcr-ready",
    "url": "/contact-us/cal3qhcr-ready",
    "template": "general_content",
    "section": "cal3qhcr-ready"
  },
  {
    "name": "DEM Terrain",
    "apiurl": "/restapi/customcontent/contact-us/dem-terrain",
    "url": "/contact-us/dem-terrain",
    "template": "general_content",
    "section": "dem-terrain"
  },
  {
    "name": "AERMET-Ready",
    "apiurl": "/restapi/customcontent/contact-us/aermet-ready",
    "url": "/contact-us/aermet-ready",
    "template": "general_content",
    "section": "aermet-ready"
  },
  {
    "name": "Custom",
    "apiurl": "/restapi/customcontent/contact-us/custom",
    "url": "/contact-us/custom",
    "template": "general_content",
    "section": "custom"
  },
  {
    "name": "Meta 1",
    "apiurl": "/restapi/customcontent/contact-us/meta-1",
    "url": "/contact-us/meta-1",
    "template": "general_content",
    "section": "meta-1"
  },
  {
    "name": "STAR",
    "apiurl": "/restapi/customcontent/contact-us/star",
    "url": "/contact-us/star",
    "template": "general_content",
    "section": "star"
  },
  {
    "name": "CALPUFF-Ready",
    "apiurl": "/restapi/customcontent/contact-us/calpuff-ready",
    "url": "/contact-us/calpuff-ready",
    "template": "general_content",
    "section": "calpuff-ready"
  },
  {
    "name": "ADMS",
    "apiurl": "/restapi/customcontent/contact-us/adms",
    "url": "/contact-us/adms",
    "template": "general_content",
    "section": "adms"
  },
  {
    "name": "CALMET-Ready",
    "apiurl": "/restapi/customcontent/contact-us/calmet-ready",
    "url": "/contact-us/calmet-ready",
    "template": "general_content",
    "section": "calmet-ready"
  },
  {
    "name": "Life Science",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/life-science",
    "url": "/training/Industry/life-science",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Cement",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/cement",
    "url": "/training/Industry/cement",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Industry Specific",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/industry-specific",
    "url": "/training/course-by-category/industry-specific",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Industry Specific",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/industry/industry-specific",
    "url": "/training/industry/industry-specific",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Industry Specific",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/industry/industry-specific",
    "url": "/training/training-by-Industry/industry-specific",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Mining",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/minings",
    "url": "/training/Industry/minings",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Landfills",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/landfills",
    "url": "/training/Industry/landfills",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Semiconductors",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/semiconductors",
    "url": "/training/Industry/semiconductors",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Environmental Analysis",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/environmental-analysis",
    "url": "/training/course-by-category/environmental-analysis",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Iron & Steel",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/iron-steel",
    "url": "/training/Industry/iron-steel",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Chemicals / Pharmaceuticals",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/chemical-pharmaceuticals",
    "url": "/training/Industry/chemical-pharmaceuticals",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Regulatory Agency",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/regulatory-agency",
    "url": "/training/course-by-category/regulatory-agency",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Regulatory Agency",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/regulatory-agency",
    "url": "/training/course-by-category/location-specific",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Regulatory Agency",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/regulatory-agency",
    "url": "/training/regulatory-agency",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Environmental Reporting",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/environmental-reporting",
    "url": "/training/course-by-category/environmental-reporting",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Air Quality Modeling",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/air-quality-modeling",
    "url": "/training/course-by-category/air-quality-modeling",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Liquid Terminals",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/liquid-terminals",
    "url": "/training/Industry/liquid-terminals",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Data Centers",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/data-centers",
    "url": "/training/Industry/data-centers",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Industrial Sand",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/industrial-sand",
    "url": "/training/Industry/industrial-sand",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "EHS Regulations",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/ehs-regulations",
    "url": "/training/course-by-category/ehs-regulations",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/software/software-courses",
    "url": "/training/software/software-courses",
    "template": "new-training-individual-category",
    "section": "software-courses"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/software/software-courses",
    "url": "/training/software-courses/software-courses",
    "template": "new-training-individual-category",
    "section": "software-courses"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/software/software-courses",
    "url": "/training/software-courses/tanks-course",
    "template": "new-training-individual-category",
    "section": "software-courses"
  },
  {
    "name": "Forest Products",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/forest-products",
    "url": "/training/Industry/forest-products",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Power Generation",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/power-generation",
    "url": "/training/Industry/power-generation",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Oil & Natural Gas/Refining",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/oil-natural-gas-refining",
    "url": "/training/Industry/oil-natural-gas-refining",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Petroleum Refining",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/petroleum-refining",
    "url": "/training/Industry/petroleum-refining",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "EHS&S Management",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/course-by-category/ehs-s-management",
    "url": "/training/course-by-category/ehs-s-management",
    "template": "new-training-individual-category",
    "section": "category"
  },
  {
    "name": "Chemical Manufacturing",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/chemical-manufacturing",
    "url": "/training/Industry/chemical-manufacturing",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Industrial Hygiene",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/industrial-hygiene",
    "url": "/training/Industry/industrial-hygiene",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Oil & Natural Gas",
    "apiurl": "/restapi/customtrainingcontentlvl3/training/Industry/oil-natural-gas",
    "url": "/training/Industry/oil-natural-gas",
    "template": "new-training-individual-category",
    "section": "industry"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/herbert-els",
    "url": "/about-us/operational-leaders/individual-bio/herbert-els",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/herbert-els",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/albert-dyrness",
    "url": "/about-us/operational-leaders/individual-bio/albert-dyrness",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/albert-dyrness",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/kirk-lowery",
    "url": "/about-us/operational-leaders/individual-bio/kirk-lowery",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/kirk-lowery",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/vineet-masuraha",
    "url": "/about-us/operational-leaders/individual-bio/vineet-masuraha",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/vineet-masuraha",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/shishir-mohan",
    "url": "/about-us/operational-leaders/individual-bio/shishir-mohan",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/shishir-mohan",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/brian-burdorf",
    "url": "/about-us/operational-leaders/individual-bio/brian-burdorf",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/brian-burdorf",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/eddie-al-rayes",
    "url": "/about-us/operational-leaders/individual-bio/eddie-al-rayes",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/eddie-al-rayes",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/christine-chambers",
    "url": "/about-us/operational-leaders/individual-bio/christine-chambers",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/christine-chambers",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/weiping-dai",
    "url": "/about-us/operational-leaders/individual-bio/weiping-dai",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/weiping-dai",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/inaas-darrat",
    "url": "/about-us/operational-leaders/individual-bio/inaas-darrat",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/inaas-darrat",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/melissa-hillman",
    "url": "/about-us/operational-leaders/individual-bio/melissa-hillman",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/melissa-hillman",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/tony-jabon",
    "url": "/about-us/operational-leaders/individual-bio/tony-jabon",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/tony-jabon",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/brad-james",
    "url": "/about-us/operational-leaders/individual-bio/brad-james",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/brad-james",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/rich-jenks",
    "url": "/about-us/operational-leaders/individual-bio/rich-jenks",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/rich-jenks",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/robert-liles",
    "url": "/about-us/operational-leaders/individual-bio/robert-liles",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/robert-liles",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/casey-lenhart",
    "url": "/about-us/operational-leaders/individual-bio/casey-lenhart",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/casey-lenhart",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/wendy-merz",
    "url": "/about-us/operational-leaders/individual-bio/wendy-merz",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/wendy-merz",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/tom-muscenti",
    "url": "/about-us/operational-leaders/individual-bio/tom-muscenti",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/tom-muscenti",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/patrick-nonhof",
    "url": "/about-us/operational-leaders/individual-bio/patrick-nonhof",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders/individual-bio/patrick-nonhof",
    "url": "/about-us1/operational-leaders",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders",
    "url": "/about-us/operational-leaders",
    "template": "new-operational-leaders",
    "section": "about-us"
  },
  {
    "name": "Operational Leaders",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/operational-leaders",
    "url": "/about-us1/operational-leaders",
    "template": "new-operational-leaders",
    "section": "about-us"
  },
  {
    "name": "Railroads & Rail Yards",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/railroads",
    "url": "/industries/manufacturing-industrial-mining/railroads",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Electric Power",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/electric-power",
    "url": "/industries/energy/electric-power",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Electric Power",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/electric-power",
    "url": "/test/energy/electric-power",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Electric Power",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/electric-power",
    "url": "/industries/energy/electric-power-OLD",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Chemical",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/chemical",
    "url": "/industries/manufacturing-industrial-mining/chemical",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Aerospace",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/aerospace",
    "url": "/industries/manufacturing-industrial-mining/aerospace",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Lime",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/lime",
    "url": "/industries/manufacturing-industrial-mining/lime",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Petroleum Refining",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/petroleum-refining",
    "url": "/industries/manufacturing-industrial-mining/petroleum-refining",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "General Manufacturing",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/general-manufacturing",
    "url": "/industries/manufacturing-industrial-mining/general-manufacturing",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Food & Beverage",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/food-beverage",
    "url": "/industries/manufacturing-industrial-mining/food-beverage",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Pulp, Paper & Tissue",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/pulp-paper",
    "url": "/industries/manufacturing-industrial-mining/pulp-paper",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Pulp, Paper & Tissue",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/pulp-paper",
    "url": "/test/non-industrial-consultants/pulp-paper",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Pulp, Paper & Tissue",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/pulp-paper",
    "url": "/industries/industrial-consultants/pulp-paper",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Pulp, Paper & Tissue",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/pulp-paper",
    "url": "/industries/non-industrial-consultants/pulp-paper",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Pulp, Paper & Tissue",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/pulp-paper",
    "url": "/test/non-industrial/pulp-paper",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Life Sciences",
    "apiurl": "/restapi/customindustrylvl3content/industries/life-sciences/life-science",
    "url": "/industries/life-sciences/life-science",
    "template": "new-industry-individual-service",
    "section": "life-sciences"
  },
  {
    "name": "Life Sciences",
    "apiurl": "/restapi/customindustrylvl3content/industries/life-sciences/life-science",
    "url": "/industries/life-sciences/life-sciences",
    "template": "new-industry-individual-service",
    "section": "life-sciences"
  },
  {
    "name": "Life Sciences",
    "apiurl": "/restapi/customindustrylvl3content/industries/life-sciences/life-science",
    "url": "/industries/life-sciences/life-science---test",
    "template": "new-industry-individual-service",
    "section": "life-sciences"
  },
  {
    "name": "Life Sciences",
    "apiurl": "/restapi/customindustrylvl3content/industries/life-sciences/life-science",
    "url": "/industries/life-sciences/general-life-science",
    "template": "new-industry-individual-service",
    "section": "life-sciences"
  },
  {
    "name": "Wood Products",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/wood-products",
    "url": "/industries/manufacturing-industrial-mining/wood-products",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Semiconductor",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/semiconductor",
    "url": "/industries/manufacturing-industrial-mining/semiconductor",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Lithium Ion Battery Manufacturing and Recycling",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/lithium-ion-battery-manufacturing-and-recycling",
    "url": "/industries/manufacturing-industrial-mining/lithium-ion-battery-manufacturing-and-recycling",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Terminals",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/terminals",
    "url": "/industries/energy/terminals",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Terminals",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/terminals",
    "url": "/test/energy/terminals",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Aggregates",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/aggregates",
    "url": "/industries/manufacturing-industrial-mining/aggregates",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Deepwater Ports, Offshore E&P",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/deepwater-ports-offshore-e-p",
    "url": "/industries/energy/deepwater-ports-offshore-e-p",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Agriculture",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/agriculture",
    "url": "/industries/manufacturing-industrial-mining/agriculture",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Portland Cement",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/portland-cement",
    "url": "/industries/manufacturing-industrial-mining/portland-cement",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Portland Cement",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/portland-cement",
    "url": "/industries/industrial-consultants/portland-cement",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Portland Cement",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/portland-cement",
    "url": "/test/non-industrial-consultants/portland-cement",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Portland Cement",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/portland-cement",
    "url": "/test/non-industrial/portland-cement",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Portland Cement",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/portland-cement",
    "url": "/industries/non-industrial-consultants/portland-cement",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Mining",
    "apiurl": "/restapi/customindustrylvl3content/industries/manufacturing-industrial-mining/metals-mining",
    "url": "/industries/manufacturing-industrial-mining/metals-mining",
    "template": "new-industry-individual-service",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "Oil & Gas",
    "apiurl": "/restapi/customindustrylvl3content/industries/energy/oil-gas",
    "url": "/industries/energy/oil-gas",
    "template": "new-industry-individual-service",
    "section": "energy"
  },
  {
    "name": "Government",
    "apiurl": "/restapi/customindustrylvl3content/industries/commercial-institutional/government",
    "url": "/industries/commercial-institutional/government",
    "template": "new-industry-individual-service",
    "section": "commercial/institutional"
  },
  {
    "name": "Solid Waste & Recycling",
    "apiurl": "/restapi/customindustrylvl3content/industries/commercial-institutional/solid-waste-recycling",
    "url": "/industries/commercial-institutional/solid-waste-recycling",
    "template": "new-industry-individual-service",
    "section": "commercial/institutional"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customcontent/contact-us/vasdip",
    "url": "/contact-us/vasdip",
    "template": "general_content",
    "section": "vasdip"
  },
  {
    "name": "HEXDAM",
    "apiurl": "/restapi/customcontent/contact-us/hexdam",
    "url": "/contact-us/hexdam",
    "template": "general_content",
    "section": "hexdam"
  },
  {
    "name": "Privacy Policy",
    "apiurl": "/restapi/customcontent/privacy-policy",
    "url": "/privacy-policy",
    "template": "general_content",
    "section": "privacy-policy"
  },
  {
    "name": "Custom Training",
    "apiurl": "/restapi/customcontent/request-training-quote",
    "url": "/request-training-quote",
    "template": "general_content",
    "section": "custom-training"
  },
  {
    "name": "Custom Training",
    "apiurl": "/restapi/customcontent/request-training-quote",
    "url": "/training/request-training-quote",
    "template": "general_content",
    "section": "custom-training"
  },
  {
    "name": "22112 32-bit",
    "apiurl": "/restapi/customcontent/contact-us/22112-32-bit",
    "url": "/contact-us/22112-32-bit",
    "template": "general_content",
    "section": "22112-32-bit"
  },
  {
    "name": "13350",
    "apiurl": "/restapi/customcontent/contact-us/13350",
    "url": "/contact-us/13350",
    "template": "general_content",
    "section": "13350"
  },
  {
    "name": "12345",
    "apiurl": "/restapi/customcontent/contact-us/12345",
    "url": "/contact-us/12345",
    "template": "general_content",
    "section": "12345"
  },
  {
    "name": "16216r",
    "apiurl": "/restapi/customcontent/contact-us/16216r",
    "url": "/contact-us/16216r",
    "template": "general_content",
    "section": "16216r"
  },
  {
    "name": "21112 64-bit",
    "apiurl": "/restapi/customcontent/contact-us/21112-64-bit",
    "url": "/contact-us/21112-64-bit",
    "template": "general_content",
    "section": "21112-64-bit"
  },
  {
    "name": "09292",
    "apiurl": "/restapi/customcontent/contact-us/09292",
    "url": "/contact-us/09292",
    "template": "general_content",
    "section": "09292"
  },
  {
    "name": "12060",
    "apiurl": "/restapi/customcontent/contact-us/12060",
    "url": "/contact-us/12060",
    "template": "general_content",
    "section": "12060"
  },
  {
    "name": "07026",
    "apiurl": "/restapi/customcontent/contact-us/07026",
    "url": "/contact-us/07026",
    "template": "general_content",
    "section": "07026"
  },
  {
    "name": "22112 64-bit",
    "apiurl": "/restapi/customcontent/contact-us/22112-64-bit",
    "url": "/contact-us/22112-64-bit",
    "template": "general_content",
    "section": "22112-64-bit"
  },
  {
    "name": "15181",
    "apiurl": "/restapi/customcontent/contact-us/15181",
    "url": "/contact-us/15181",
    "template": "general_content",
    "section": "15181"
  },
  {
    "name": "19191 64-bit",
    "apiurl": "/restapi/customcontent/contact-us/19191-64-bit",
    "url": "/contact-us/19191-64-bit",
    "template": "general_content",
    "section": "19191-64-bit"
  },
  {
    "name": "19191 32-bit",
    "apiurl": "/restapi/customcontent/contact-us/19191-32-bit",
    "url": "/contact-us/19191-32-bit",
    "template": "general_content",
    "section": "19191-32-bit"
  },
  {
    "name": "21112 32-bit",
    "apiurl": "/restapi/customcontent/contact-us/21112-32-bit",
    "url": "/contact-us/21112-32-bit",
    "template": "general_content",
    "section": "21112-32-bit"
  },
  {
    "name": "18081 64-bit",
    "apiurl": "/restapi/customcontent/contact-us/18081-64-bit",
    "url": "/contact-us/18081-64-bit",
    "template": "general_content",
    "section": "18081-64-bit"
  },
  {
    "name": "14134",
    "apiurl": "/restapi/customcontent/contact-us/14134",
    "url": "/contact-us/14134",
    "template": "general_content",
    "section": "14134"
  },
  {
    "name": "18081 32-bit",
    "apiurl": "/restapi/customcontent/contact-us/18081-32-bit",
    "url": "/contact-us/18081-32-bit",
    "template": "general_content",
    "section": "18081-32-bit"
  },
  {
    "name": "Globals",
    "apiurl": "/restapi/customcontent/contact-us",
    "url": "/contact-us",
    "template": "general_content",
    "section": "globals"
  },
  {
    "name": "Publications",
    "apiurl": "/restapi/customehspublication/publications",
    "url": "/publications",
    "template": "new-trinity-ehs-publication",
    "section": "publications"
  },
  {
    "name": "Publications",
    "apiurl": "/restapi/customehspublication/publications",
    "url": "/resources/publications",
    "template": "new-trinity-ehs-publication",
    "section": "publications"
  },
  {
    "name": "Janet Martin",
    "apiurl": "/restapi/customcontent/contact-us/janet-martin",
    "url": "/contact-us/janet-martin",
    "template": "general_content",
    "section": "janet-martin"
  },
  {
    "name": "Staffing Team",
    "apiurl": "/restapi/customcontent/contact-us/staffing-team",
    "url": "/contact-us/staffing-team",
    "template": "general_content",
    "section": "staffing-team"
  },
  {
    "name": "Christina Ridge",
    "apiurl": "/restapi/customcontent/contact-us/christina-ridge",
    "url": "/contact-us/christina-ridge",
    "template": "general_content",
    "section": "christina-ridge"
  },
  {
    "name": "Pressure-Impulse Data",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/pressure-impulse-data",
    "url": "/software/explosion/vasdip/pressure-impulse-data",
    "template": "general_content",
    "section": "pressure-impulse-data"
  },
  {
    "name": "Model Run and Results Analysis",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/model-run-results-analysis",
    "url": "/software/explosion/exdam/model-run-results-analysis",
    "template": "general_content",
    "section": "model-run-and-results-analysis"
  },
  {
    "name": "Model Setup",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/model-setup",
    "url": "/software/health-risk/risk-analyst/model-setup",
    "template": "general_content",
    "section": "model-setup"
  },
  {
    "name": "Surface Data",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/surface-data",
    "url": "/software/dispersion/aermet/surface-data",
    "template": "general_content",
    "section": "surface-data"
  },
  {
    "name": "Post-Processing Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/post-processing-tools",
    "url": "/software/air-dispersion---data/3d-analyst/post-processing-tools",
    "template": "general_content",
    "section": "post-processing-tools"
  },
  {
    "name": "Post-Processing Tools",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/post-processing-tools",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/post-processing-tools",
    "template": "general_content",
    "section": "post-processing-tools"
  },
  {
    "name": "Site and Meteorological Data",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/site-and-meteorological-data",
    "url": "/software/tanks/tankesp/site-and-meteorological-data",
    "template": "general_content",
    "section": "site-and-meteorological-data"
  },
  {
    "name": "Site and Meteorological Data",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/site-and-meteorological-data",
    "url": "/software/tank-emissions/tankesp/site-and-meteorological-data",
    "template": "general_content",
    "section": "site-and-meteorological-data"
  },
  {
    "name": "Wind Roses and Plotting",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/wind-roses-and-plotting",
    "url": "/software/air-dispersion---data-visualization/metview/wind-roses-and-plotting",
    "template": "general_content",
    "section": "wind-roses-and-plotting"
  },
  {
    "name": "Wind Roses and Plotting",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/wind-roses-and-plotting",
    "url": "/software/air-dispersion---data/metview/wind-roses-and-plotting",
    "template": "general_content",
    "section": "wind-roses-and-plotting"
  },
  {
    "name": "Wind Roses and Plotting",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/wind-roses-and-plotting",
    "url": "/software/air-dispersion-data-visualization/metview/wind-roses-and-plotting",
    "template": "general_content",
    "section": "wind-roses-and-plotting"
  },
  {
    "name": "Source5 Model",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/source5-model",
    "url": "/software/hazard/lfg-fire-risk/source5-model",
    "template": "general_content",
    "section": "source5-model"
  },
  {
    "name": "Source5 Model",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/source5-model",
    "url": "/software/hazard/lfg-fire-risk-modeling/source5-model",
    "template": "general_content",
    "section": "source5-model"
  },
  {
    "name": "Multiple Sources and Receptors",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/multiple-sources-and-receptors",
    "url": "/software/dispersion/screen3/multiple-sources-and-receptors",
    "template": "general_content",
    "section": "multiple-sources-and-receptors"
  },
  {
    "name": "Data Entry",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/data-entry",
    "url": "/software/tanks/tankesp/data-entry",
    "template": "general_content",
    "section": "data-entry"
  },
  {
    "name": "Data Entry",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/data-entry",
    "url": "/software/tank-emissions/tankesp/data-entry",
    "template": "general_content",
    "section": "data-entry"
  },
  {
    "name": "Chemical Database",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/chemical-database",
    "url": "/software/health-risk/risk-analyst/chemical-database",
    "template": "general_content",
    "section": "chemical-database"
  },
  {
    "name": "BPIP Output Mode",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/bpip-output-mode",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/bpip-output-mode",
    "template": "general_content",
    "section": "bpip-output-mode"
  },
  {
    "name": "BPIP Output Mode",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/bpip-output-mode",
    "url": "/software/air-dispersion---data/downwash-analyst/bpip-output-mode",
    "template": "general_content",
    "section": "bpip-output-mode"
  },
  {
    "name": "BPIP Output Mode",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/bpip-output-mode",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/bpip-output-mode",
    "template": "general_content",
    "section": "bpip-output-mode"
  },
  {
    "name": "Integrated Tools",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/integrated-tools",
    "url": "/software/hazard/lfg-fire-risk/integrated-tools",
    "template": "general_content",
    "section": "integrated-tools"
  },
  {
    "name": "Integrated Tools",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/integrated-tools",
    "url": "/software/hazard/lfg-fire-risk-modeling/integrated-tools",
    "template": "general_content",
    "section": "integrated-tools"
  },
  {
    "name": "Standalone Editions",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/standalone-editions",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/standalone-editions",
    "template": "general_content",
    "section": "standalone-editions"
  },
  {
    "name": "Standalone Editions",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/standalone-editions",
    "url": "/software/air-dispersion---data/3d-analyst/standalone-editions",
    "template": "general_content",
    "section": "standalone-editions"
  },
  {
    "name": "Standalone Editions",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/standalone-editions",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/standalone-editions",
    "template": "general_content",
    "section": "standalone-editions"
  },
  {
    "name": "Results and Mapping",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/results-and-mapping",
    "url": "/software/health-risk/risk-analyst/results-and-mapping",
    "template": "general_content",
    "section": "results-and-mapping"
  },
  {
    "name": "Advanced Features",
    "apiurl": "/restapi/customcontent/software/tanks/esp/advanced-features",
    "url": "/software/tanks/esp/advanced-features",
    "template": "general_content",
    "section": "advanced-features"
  },
  {
    "name": "Basic Features",
    "apiurl": "/restapi/customcontent/software/tanks/esp/basic-features",
    "url": "/software/tanks/esp/basic-features",
    "template": "general_content",
    "section": "basic-features"
  },
  {
    "name": "Parallel Processing",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/parallel-processing",
    "url": "/software/dispersion/aermod/parallel-processing",
    "template": "general_content",
    "section": "parallel-processing"
  },
  {
    "name": "Results Analysis ",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/results-analysis",
    "url": "/software/hazard/incident-analyst/results-analysis",
    "template": "general_content",
    "section": "results-analysis-"
  },
  {
    "name": "Scenario Development Tools",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/scenario-development-tools",
    "url": "/software/dispersion/calpuff/scenario-development-tools",
    "template": "general_content",
    "section": "scenario-development-tools"
  },
  {
    "name": "Charts and Tables",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/charts-and-tables",
    "url": "/software/dispersion/screen3/charts-and-tables",
    "template": "general_content",
    "section": "charts-and-tables"
  },
  {
    "name": "Model Setup and Execution",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/model-setup-and-execution",
    "url": "/software/dispersion/aerscreen/model-setup-and-execution",
    "template": "general_content",
    "section": "model-setup-and-execution"
  },
  {
    "name": "Consulting Services",
    "apiurl": "/restapi/customcontent/software/tanks/esp/consulting-services",
    "url": "/software/tanks/esp/consulting-services",
    "template": "general_content",
    "section": "consulting-services"
  },
  {
    "name": "Graphical Results",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/graphical-results",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/graphical-results",
    "template": "general_content",
    "section": "graphical-results"
  },
  {
    "name": "Graphical Results",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/graphical-results",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/graphical-results",
    "template": "general_content",
    "section": "graphical-results"
  },
  {
    "name": "Graphical Results",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/graphical-results",
    "url": "/software/air-dispersion---data/3d-analyst/graphical-results",
    "template": "general_content",
    "section": "graphical-results"
  },
  {
    "name": "PRIME Plume Rise View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/prime-plume-rise-view",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/prime-plume-rise-view",
    "template": "general_content",
    "section": "prime-plume-rise-view"
  },
  {
    "name": "PRIME Plume Rise View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/prime-plume-rise-view",
    "url": "/software/air-dispersion---data/downwash-analyst/prime-plume-rise-view",
    "template": "general_content",
    "section": "prime-plume-rise-view"
  },
  {
    "name": "PRIME Plume Rise View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/prime-plume-rise-view",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/prime-plume-rise-view",
    "template": "general_content",
    "section": "prime-plume-rise-view"
  },
  {
    "name": "Fire Models",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/fire-models",
    "url": "/software/hazard/lfg-fire-risk/fire-models",
    "template": "general_content",
    "section": "fire-models"
  },
  {
    "name": "Fire Models",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/fire-models",
    "url": "/software/hazard/lfg-fire-risk-modeling/fire-models",
    "template": "general_content",
    "section": "fire-models"
  },
  {
    "name": "CALINE Series of Models",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/caline-series-of-models",
    "url": "/software/dispersion/roads/caline-series-of-models",
    "template": "general_content",
    "section": "caline-series-of-models"
  },
  {
    "name": "Damage Data Calculations",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/damage-data-calculations",
    "url": "/software/explosion/vasdip/damage-data-calculations",
    "template": "general_content",
    "section": "damage-data-calculations"
  },
  {
    "name": "Sources and Receptors",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/sources-and-receptors",
    "url": "/software/dispersion/roads/sources-and-receptors",
    "template": "general_content",
    "section": "sources-and-receptors"
  },
  {
    "name": "Onsite Station Data",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/onsite-station-data",
    "url": "/software/dispersion/aermet/onsite-station-data",
    "template": "general_content",
    "section": "onsite-station-data"
  },
  {
    "name": "Source Modules",
    "apiurl": "/restapi/customcontent/software/tanks/esp/source-modules",
    "url": "/software/tanks/esp/source-modules",
    "template": "general_content",
    "section": "source-modules"
  },
  {
    "name": "Model Objects",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/model-objects",
    "url": "/software/dispersion/aerscreen/model-objects",
    "template": "general_content",
    "section": "model-objects"
  },
  {
    "name": "12 Industry-Standard Models",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/12-industry-standard-models",
    "url": "/software/hazard/incident-analyst/12-industry-standard-models",
    "template": "general_content",
    "section": "12-industry-standard-models"
  },
  {
    "name": "Integrated User Interface ",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/integrated-user-interface",
    "url": "/software/dispersion/screen3/integrated-user-interface",
    "template": "general_content",
    "section": "integrated-user-interface-"
  },
  {
    "name": "Source Term Wizard",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/source-term-wizard",
    "url": "/software/hazard/incident-analyst/source-term-wizard",
    "template": "general_content",
    "section": "source-term-wizard"
  },
  {
    "name": "Meteorology",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/meteorology",
    "url": "/software/dispersion/roads/meteorology",
    "template": "general_content",
    "section": "meteorology"
  },
  {
    "name": "Programs and Processors",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/programs-and-processors",
    "url": "/software/dispersion/calpuff/programs-and-processors",
    "template": "general_content",
    "section": "programs-and-processors"
  },
  {
    "name": "Results Analysis",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/results-analysis",
    "url": "/software/dispersion/aerscreen/results-analysis",
    "template": "general_content",
    "section": "results-analysis"
  },
  {
    "name": "Roadways",
    "apiurl": "/restapi/customcontent/software/dispersion/roads/roadways",
    "url": "/software/dispersion/roads/roadways",
    "template": "general_content",
    "section": "roadways"
  },
  {
    "name": "Model Objects",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/model-objects",
    "url": "/software/dispersion/calpuff/model-objects",
    "template": "general_content",
    "section": "model-objects"
  },
  {
    "name": "GEP Stack Height View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/gep-stack-height-view",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/gep-stack-height-view",
    "template": "general_content",
    "section": "gep-stack-height-view"
  },
  {
    "name": "GEP Stack Height View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/gep-stack-height-view",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/gep-stack-height-view",
    "template": "general_content",
    "section": "gep-stack-height-view"
  },
  {
    "name": "GEP Stack Height View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/gep-stack-height-view",
    "url": "/software/air-dispersion---data/downwash-analyst/gep-stack-height-view",
    "template": "general_content",
    "section": "gep-stack-height-view"
  },
  {
    "name": "Risk Modules",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/risk-modules",
    "url": "/software/health-risk/risk-analyst/risk-modules",
    "template": "general_content",
    "section": "risk-modules"
  },
  {
    "name": "Meteorology",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/meteorology",
    "url": "/software/dispersion/aerscreen/meteorology",
    "template": "general_content",
    "section": "meteorology"
  },
  {
    "name": "Model Objects",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/model-objects",
    "url": "/software/dispersion/aermod/model-objects",
    "template": "general_content",
    "section": "model-objects"
  },
  {
    "name": "TankESP Editions",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-editions",
    "url": "/software/tanks/tankesp/tankesp-editions",
    "template": "general_content",
    "section": "tankesp-editions"
  },
  {
    "name": "TankESP Editions",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-editions",
    "url": "/software/tank-emissions/tankesp/tankesp-editions",
    "template": "general_content",
    "section": "tankesp-editions"
  },
  {
    "name": "Seamless Integration",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/seamless-integration",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/seamless-integration",
    "template": "general_content",
    "section": "seamless-integration"
  },
  {
    "name": "Seamless Integration",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/seamless-integration",
    "url": "/software/air-dispersion---data/3d-analyst/seamless-integration",
    "template": "general_content",
    "section": "seamless-integration"
  },
  {
    "name": "Seamless Integration",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/seamless-integration",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/seamless-integration",
    "template": "general_content",
    "section": "seamless-integration"
  },
  {
    "name": "Vulnerability Parameters for Export",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/vulnerability-parameters-for-export",
    "url": "/software/explosion/vasdip/vulnerability-parameters-for-export",
    "template": "general_content",
    "section": "vulnerability-parameters-for-export"
  },
  {
    "name": "Integration with BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/dispersion/calpuff/integration-with-breeze-3d-analyst",
    "url": "/software/dispersion/calpuff/integration-with-breeze-3d-analyst",
    "template": "general_content",
    "section": "integration-with-breeze-3d-analyst"
  },
  {
    "name": "Advanced Science",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/advanced-science",
    "url": "/software/explosion/exdam/advanced-science",
    "template": "general_content",
    "section": "advanced-science"
  },
  {
    "name": "Scenario Development Tools",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/scenario-development-tools",
    "url": "/software/dispersion/aermod/scenario-development-tools",
    "template": "general_content",
    "section": "scenario-development-tools"
  },
  {
    "name": "Land Use Data",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/land-use-data",
    "url": "/software/dispersion/aermet/land-use-data",
    "template": "general_content",
    "section": "land-use-data"
  },
  {
    "name": "Diagnostics",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/diagnostics",
    "url": "/software/dispersion/screen3/diagnostics",
    "template": "general_content",
    "section": "diagnostics"
  },
  {
    "name": "Chemical Database",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/chemical-database",
    "url": "/software/hazard/incident-analyst/chemical-database",
    "template": "general_content",
    "section": "chemical-database"
  },
  {
    "name": "Who Should Use ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/who-should-use",
    "url": "/software/explosion/exdam/who-should-use",
    "template": "general_content",
    "section": "who-should-use-exdam"
  },
  {
    "name": "Who Should Use ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/who-should-use",
    "url": "/software/explosion/exdam/model-run-and-analysis",
    "template": "general_content",
    "section": "who-should-use-exdam"
  },
  {
    "name": "Results and Reporting",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/results-and-reporting",
    "url": "/software/tanks/tankesp/results-and-reporting",
    "template": "general_content",
    "section": "results-and-reporting"
  },
  {
    "name": "Results and Reporting",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/results-and-reporting",
    "url": "/software/tank-emissions/tankesp/results-and-reporting",
    "template": "general_content",
    "section": "results-and-reporting"
  },
  {
    "name": "Tabular Data Analysis",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/tabular-data-analysis",
    "url": "/software/air-dispersion-data-visualization/metview/tabular-data-analysis",
    "template": "general_content",
    "section": "tabular-data-analysis"
  },
  {
    "name": "Tabular Data Analysis",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/tabular-data-analysis",
    "url": "/software/air-dispersion---data/metview/tabular-data-analysis",
    "template": "general_content",
    "section": "tabular-data-analysis"
  },
  {
    "name": "Upper Air Data",
    "apiurl": "/restapi/customcontent/software/dispersion/aermet/upper-air-data",
    "url": "/software/dispersion/aermet/upper-air-data",
    "template": "general_content",
    "section": "upper-air-data"
  },
  {
    "name": "Height of Wake Effect View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/height-of-wake-effect-view",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst/height-of-wake-effect-view",
    "template": "general_content",
    "section": "height-of-wake-effect-view"
  },
  {
    "name": "Height of Wake Effect View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/height-of-wake-effect-view",
    "url": "/software/air-dispersion---data/downwash-analyst/height-of-wake-effect-view",
    "template": "general_content",
    "section": "height-of-wake-effect-view"
  },
  {
    "name": "Height of Wake Effect View",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/downwash-analyst/height-of-wake-effect-view",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst/height-of-wake-effect-view",
    "template": "general_content",
    "section": "height-of-wake-effect-view"
  },
  {
    "name": "Structure and Body Components",
    "apiurl": "/restapi/customcontent/software/explosion/vasdip/structure-and-body-components",
    "url": "/software/explosion/vasdip/structure-and-body-components",
    "template": "general_content",
    "section": "structure-and-body-components"
  },
  {
    "name": "DEGADIS Model",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/degadis-model",
    "url": "/software/hazard/lfg-fire-risk/degadis-model",
    "template": "general_content",
    "section": "degadis-model"
  },
  {
    "name": "DEGADIS Model",
    "apiurl": "/restapi/customcontent/software/hazard/lfg-fire-risk/degadis-model",
    "url": "/software/hazard/lfg-fire-risk-modeling/degadis-model",
    "template": "general_content",
    "section": "degadis-model"
  },
  {
    "name": "Data Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/data-rose",
    "url": "/software/air-dispersion---data/metview/data-rose",
    "template": "general_content",
    "section": "data-rose"
  },
  {
    "name": "Data Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/data-rose",
    "url": "/software/air-dispersion-data-visualization/metview/data-rose",
    "template": "general_content",
    "section": "data-rose"
  },
  {
    "name": "AERMOD Editions",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/aermod-editions",
    "url": "/software/dispersion/aermod/aermod-editions",
    "template": "general_content",
    "section": "aermod-editions"
  },
  {
    "name": "ExDAM vs CFD Applications",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/EXDAM-vs-CFD",
    "url": "/software/explosion/exdam/EXDAM-vs-CFD",
    "template": "general_content",
    "section": "exdam-vs-cfd-applications"
  },
  {
    "name": "ExDAM vs CFD Applications",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/EXDAM-vs-CFD",
    "url": "/software/explosion/exdam/who-should-use-exdam",
    "template": "general_content",
    "section": "exdam-vs-cfd-applications"
  },
  {
    "name": "Data Processing",
    "apiurl": "/restapi/customcontent/contact-us/data-processing",
    "url": "/contact-us/data-processing",
    "template": "general_content",
    "section": "data-processing"
  },
  {
    "name": "High Speed Modeling",
    "apiurl": "/restapi/customcontent/contact-us/high-speed-modeling",
    "url": "/contact-us/high-speed-modeling",
    "template": "general_content",
    "section": "high-speed-modeling"
  },
  {
    "name": "Specialized Consulting Services",
    "apiurl": "/restapi/customcontent/contact-us/specialized-consulting-services",
    "url": "/contact-us/specialized-consulting-services",
    "template": "general_content",
    "section": "specialized-consulting-services"
  },
  {
    "name": "Yes",
    "apiurl": "/restapi/customcontent/contact-us/yes",
    "url": "/contact-us/yes",
    "template": "general_content",
    "section": "yes"
  },
  {
    "name": "No",
    "apiurl": "/restapi/customcontent/contact-us/no",
    "url": "/contact-us/no",
    "template": "general_content",
    "section": "no"
  },
  {
    "name": "Chemicals",
    "apiurl": "/restapi/customcontent/contact-us/chemicals",
    "url": "/contact-us/chemicals",
    "template": "general_content",
    "section": "chemicals"
  },
  {
    "name": "Automotive",
    "apiurl": "/restapi/customcontent/contact-us/automotive",
    "url": "/contact-us/automotive",
    "template": "general_content",
    "section": "automotive"
  },
  {
    "name": "Lithium Ion Battery Manufacturing and Recycling",
    "apiurl": "/restapi/customcontent/contact-us/lithium-ion-battery-manufacturing-and-recycling",
    "url": "/contact-us/lithium-ion-battery-manufacturing-and-recycling",
    "template": "general_content",
    "section": "lithium-ion-battery-manufacturing-and-recycling"
  },
  {
    "name": "Aerospace",
    "apiurl": "/restapi/customcontent/contact-us/aerospace",
    "url": "/contact-us/aerospace",
    "template": "general_content",
    "section": "aerospace"
  },
  {
    "name": "Education",
    "apiurl": "/restapi/customcontent/contact-us/education",
    "url": "/contact-us/education",
    "template": "general_content",
    "section": "education"
  },
  {
    "name": "Power Generation",
    "apiurl": "/restapi/customcontent/contact-us/power-generation",
    "url": "/contact-us/power-generation",
    "template": "general_content",
    "section": "power-generation"
  },
  {
    "name": "Petroleum Refining",
    "apiurl": "/restapi/customcontent/contact-us/petroleum-refining",
    "url": "/contact-us/petroleum-refining",
    "template": "general_content",
    "section": "petroleum-refining"
  },
  {
    "name": "Agricultural Processing",
    "apiurl": "/restapi/customcontent/contact-us/agricultural-processing",
    "url": "/contact-us/agricultural-processing",
    "template": "general_content",
    "section": "agricultural-processing"
  },
  {
    "name": "Forest Products",
    "apiurl": "/restapi/customcontent/contact-us/forest-products",
    "url": "/contact-us/forest-products",
    "template": "general_content",
    "section": "forest-products"
  },
  {
    "name": "Mining",
    "apiurl": "/restapi/customcontent/contact-us/mining",
    "url": "/contact-us/mining",
    "template": "general_content",
    "section": "mining"
  },
  {
    "name": "Transportation",
    "apiurl": "/restapi/customcontent/contact-us/transportation",
    "url": "/contact-us/transportation",
    "template": "general_content",
    "section": "transportation"
  },
  {
    "name": "Regulatory Agency",
    "apiurl": "/restapi/customcontent/contact-us/regulatory-agency",
    "url": "/contact-us/regulatory-agency",
    "template": "general_content",
    "section": "regulatory-agency"
  },
  {
    "name": "Pharmaceutical/Bio-Pharmaceutical",
    "apiurl": "/restapi/customcontent/contact-us/pharmaceutical-bio-pharmaceutical",
    "url": "/contact-us/pharmaceutical-bio-pharmaceutical",
    "template": "general_content",
    "section": "pharmaceuticalbio-pharmaceutical"
  },
  {
    "name": "Landfills",
    "apiurl": "/restapi/customcontent/contact-us/landfills",
    "url": "/contact-us/landfills",
    "template": "general_content",
    "section": "landfills"
  },
  {
    "name": "Technology",
    "apiurl": "/restapi/customcontent/contact-us/technology",
    "url": "/contact-us/technology",
    "template": "general_content",
    "section": "technology"
  },
  {
    "name": "General Manufacturing",
    "apiurl": "/restapi/customcontent/contact-us/general-manufacturing",
    "url": "/contact-us/general-manufacturing",
    "template": "general_content",
    "section": "general-manufacturing"
  },
  {
    "name": "Liquid Terminals",
    "apiurl": "/restapi/customcontent/contact-us/liquid-terminals",
    "url": "/contact-us/liquid-terminals",
    "template": "general_content",
    "section": "liquid-terminals"
  },
  {
    "name": "Oil & Gas Production/Processing",
    "apiurl": "/restapi/customcontent/contact-us/oil-gas-production-processing",
    "url": "/contact-us/oil-gas-production-processing",
    "template": "general_content",
    "section": "oil-gas-productionprocessing"
  },
  {
    "name": "Consulting",
    "apiurl": "/restapi/customcontent/contact-us/consulting",
    "url": "/contact-us/consulting",
    "template": "general_content",
    "section": "consulting"
  },
  {
    "name": "Cement, Lime & Aggregates",
    "apiurl": "/restapi/customcontent/contact-us/cement-lime-aggregates",
    "url": "/contact-us/cement-lime-aggregates",
    "template": "general_content",
    "section": "cement,-lime-aggregates"
  },
  {
    "name": "Aquatic Habitat Evaluations",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/aquatic-habitat-evaluations",
    "url": "/minnow-aquatic-environmental-services/aquatic-habitat-evaluations",
    "template": "general_content",
    "section": "aquatic-habitat-evaluations"
  },
  {
    "name": "Aquatic Habitat Evaluations",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/aquatic-habitat-evaluations",
    "url": "/consulting/science/aquatic-ecology/aquatic-habitat-evaluations",
    "template": "general_content",
    "section": "aquatic-habitat-evaluations"
  },
  {
    "name": "Acoustics, Noise, Vibration & Lighting",
    "apiurl": "/restapi/customcontent/trinity-consultants-australia/acoustics-noise-vibration-lighting",
    "url": "/trinity-consultants-australia/acoustics-noise-vibration-lighting",
    "template": "general_content",
    "section": "acoustics,-noise,-vibration-lighting"
  },
  {
    "name": "Toxicology",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/toxicology",
    "url": "/safebridge-regulatory-life-sciences-group/toxicology",
    "template": "general_content",
    "section": "toxicology"
  },
  {
    "name": "Toxicology",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/toxicology",
    "url": "/consulting/science/toxicology",
    "template": "general_content",
    "section": "toxicology"
  },
  {
    "name": "Environmental Effects Monitoring",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/environmental-effects-monitoring",
    "url": "/minnow-aquatic-environmental-services/environmental-effects-monitoring",
    "template": "general_content",
    "section": "environmental-effects-monitoring"
  },
  {
    "name": "Environmental Effects Monitoring",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/environmental-effects-monitoring",
    "url": "/consulting/science/aquatic-ecology/environmental-effects-monitoring-eem",
    "template": "general_content",
    "section": "environmental-effects-monitoring"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/automation-engineering",
    "url": "/advent-life-sciences-engineering-services/automation-engineering",
    "template": "general_content",
    "section": "automation-engineering"
  },
  {
    "name": "Automation Engineering",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/automation-engineering",
    "url": "/consulting/engineering/biopharma-services/automation-engineering",
    "template": "general_content",
    "section": "automation-engineering"
  },
  {
    "name": "Project Management",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/project-management",
    "url": "/advent-life-sciences-engineering-services/project-management",
    "template": "general_content",
    "section": "project-management"
  },
  {
    "name": "Project Management",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/project-management",
    "url": "/consulting/engineering/biopharma-services/project-management",
    "template": "general_content",
    "section": "project-management"
  },
  {
    "name": "Panel Design & Fabrication",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/panel-design-fabrication",
    "url": "/advent-life-sciences-engineering-services/panel-design-fabrication",
    "template": "general_content",
    "section": "panel-design-fabrication"
  },
  {
    "name": "Regulatory Permitting, Authorizations, and Compliance",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/regulatory-permitting-authorizations-and-compliance",
    "url": "/minnow-aquatic-environmental-services/regulatory-permitting-authorizations-and-compliance",
    "template": "general_content",
    "section": "regulatory-permitting,-authorizations,-and-compliance"
  },
  {
    "name": "Regulatory Permitting, Authorizations, and Compliance",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/regulatory-permitting-authorizations-and-compliance",
    "url": "/consulting/science/aquatic-ecology/regulatory-permitting-authorizations-and-compliance",
    "template": "general_content",
    "section": "regulatory-permitting,-authorizations,-and-compliance"
  },
  {
    "name": "Hazard Communication and GHS Services",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/hazard-communication-and-ghs-services",
    "url": "/safebridge-regulatory-life-sciences-group/hazard-communication-and-ghs-services",
    "template": "general_content",
    "section": "hazard-communication-and-ghs-services"
  },
  {
    "name": "Hazard Communication and GHS Services",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/hazard-communication-and-ghs-services",
    "url": "/consulting/ehs/chemical-compliance/hazard-communication-and-ghs-services",
    "template": "general_content",
    "section": "hazard-communication-and-ghs-services"
  },
  {
    "name": "Process Development for Biopharmaceuticals",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/process-development-for-pharmaceuticals",
    "url": "/advent-life-sciences-engineering-services/process-development-for-pharmaceuticals",
    "template": "general_content",
    "section": "process-development-for-biopharmaceuticals"
  },
  {
    "name": "Pressure Relief Systems",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/pressure-relief-systems",
    "url": "/provenance-process-safety-risk-management/pressure-relief-systems",
    "template": "general_content",
    "section": "pressure-relief-systems"
  },
  {
    "name": "Pressure Relief Systems",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/pressure-relief-systems",
    "url": "/provenance-consulting/pressure-relief-systems",
    "template": "general_content",
    "section": "pressure-relief-systems"
  },
  {
    "name": "Pressure Relief Systems",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/pressure-relief-systems",
    "url": "/provenance-process-safety-risk-management-program/pressure-relief-systems",
    "template": "general_content",
    "section": "pressure-relief-systems"
  },
  {
    "name": "Aquatic Chemistry Studies",
    "apiurl": "/restapi/customcontent/Minnow-Aquatic-Environmental-Services/block-2",
    "url": "/Minnow-Aquatic-Environmental-Services/block-2",
    "template": "general_content",
    "section": "aquatic-chemistry-studies"
  },
  {
    "name": "Aquatic Chemistry Studies",
    "apiurl": "/restapi/customcontent/Minnow-Aquatic-Environmental-Services/block-2",
    "url": "/consulting/science/aquatic-ecology/6-aquatic-chemistry-studies",
    "template": "general_content",
    "section": "aquatic-chemistry-studies"
  },
  {
    "name": "General PSM Services",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/general-psm-services",
    "url": "/provenance-process-safety-risk-management/general-psm-services",
    "template": "general_content",
    "section": "general-psm-services"
  },
  {
    "name": "General PSM Services",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/general-psm-services",
    "url": "/provenance-process-safety-risk-management-program/psm-service-overview",
    "template": "general_content",
    "section": "general-psm-services"
  },
  {
    "name": "General PSM Services",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/general-psm-services",
    "url": "/provenance-process-safety-risk-management-program/general-psm-services",
    "template": "general_content",
    "section": "general-psm-services"
  },
  {
    "name": "General PSM Services",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/general-psm-services",
    "url": "/provenance-consulting/general-psm-services",
    "template": "general_content",
    "section": "general-psm-services"
  },
  {
    "name": "Aquatic Environmental Monitoring",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/aquatic-environmental-monitoring",
    "url": "/minnow-aquatic-environmental-services/aquatic-environmental-monitoring",
    "template": "general_content",
    "section": "aquatic-environmental-monitoring"
  },
  {
    "name": "Aquatic Environmental Monitoring",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/aquatic-environmental-monitoring",
    "url": "/consulting/science/aquatic-ecology/aquatic-environmental-monitoring",
    "template": "general_content",
    "section": "aquatic-environmental-monitoring"
  },
  {
    "name": "Air Quality Services",
    "apiurl": "/restapi/customcontent/trinity-consultants-australia/air-quality-services",
    "url": "/trinity-consultants-australia/air-quality-services",
    "template": "general_content",
    "section": "air-quality-services"
  },
  {
    "name": "Aquatic Toxicological Studies",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/aquatic-toxicological-studies",
    "url": "/minnow-aquatic-environmental-services/aquatic-toxicological-studies",
    "template": "general_content",
    "section": "aquatic-toxicological-studies"
  },
  {
    "name": "Statistical Analysis of Environmental Data",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/statistical-analysis-of-environmental-data",
    "url": "/minnow-aquatic-environmental-services/statistical-analysis-of-environmental-data",
    "template": "general_content",
    "section": "statistical-analysis-of-environmental-data"
  },
  {
    "name": "Statistical Analysis of Environmental Data",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/statistical-analysis-of-environmental-data",
    "url": "/consulting/science/aquatic-ecology/statistical-analysis-of-environmental-data",
    "template": "general_content",
    "section": "statistical-analysis-of-environmental-data"
  },
  {
    "name": "Contaminant Dispersion and Hydrodynamic Mixing in Aquatic Systems",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "url": "/minnow-aquatic-environmental-services/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "template": "general_content",
    "section": "contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems"
  },
  {
    "name": "Contaminant Dispersion and Hydrodynamic Mixing in Aquatic Systems",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "url": "/consulting/science/aquatic-ecology/contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems",
    "template": "general_content",
    "section": "contaminant-dispersion-and-hydrodynamic-mixing-in-aquatic-systems"
  },
  {
    "name": "PSI & Data Management",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/psi-data-management",
    "url": "/provenance-process-safety-risk-management/psi-data-management",
    "template": "general_content",
    "section": "psi-data-management"
  },
  {
    "name": "PSI & Data Management",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/psi-data-management",
    "url": "/provenance-consulting/psi-data-management",
    "template": "general_content",
    "section": "psi-data-management"
  },
  {
    "name": "PSI & Data Management",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/psi-data-management",
    "url": "/provenance-process-safety-risk-management-program/psi-data-management",
    "template": "general_content",
    "section": "psi-data-management"
  },
  {
    "name": "Industrial Hygiene for Life Sciences",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/industrial-hygiene-for-life-sciences",
    "url": "/safebridge-regulatory-life-sciences-group/industrial-hygiene-for-life-sciences",
    "template": "general_content",
    "section": "industrial-hygiene-for-life-sciences"
  },
  {
    "name": "Industrial Hygiene for Life Sciences",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/industrial-hygiene-for-life-sciences",
    "url": "/consulting/ehs/health-safety/industrial-hygiene-for-life-sciences",
    "template": "general_content",
    "section": "industrial-hygiene-for-life-sciences"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/potent-compound-safety-certification",
    "url": "/safebridge-regulatory-life-sciences-group/potent-compound-safety-certification",
    "template": "general_content",
    "section": "potent-compound-safety-certification"
  },
  {
    "name": "Potent Compound Safety Certification",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/potent-compound-safety-certification",
    "url": "/consulting/ehs/health-safety/Potent-Compound-Safety",
    "template": "general_content",
    "section": "potent-compound-safety-certification"
  },
  {
    "name": "Safety and Loss Control",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/safety-and-loss-control",
    "url": "/safebridge-regulatory-life-sciences-group/safety-and-loss-control",
    "template": "general_content",
    "section": "safety-and-loss-control"
  },
  {
    "name": "Technical Support and Training",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/technical-support-and-training",
    "url": "/minnow-aquatic-environmental-services/technical-support-and-training",
    "template": "general_content",
    "section": "technical-support-and-training"
  },
  {
    "name": "Technical Support and Training",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/technical-support-and-training",
    "url": "/consulting/science/aquatic-ecology/technical-support-and-training",
    "template": "general_content",
    "section": "technical-support-and-training"
  },
  {
    "name": "PSM/RMP Support",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/psm-rmp-support",
    "url": "/provenance-process-safety-risk-management/psm-rmp-support",
    "template": "general_content",
    "section": "psmrmp-support"
  },
  {
    "name": "PSM/RMP Support",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/psm-rmp-support",
    "url": "/provenance-consulting/psm-rmp-support",
    "template": "general_content",
    "section": "psmrmp-support"
  },
  {
    "name": "Commissioning, Qualification and Validation",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/commissioning-qualification-and-validation",
    "url": "/advent-life-sciences-engineering-services/commissioning-qualification-and-validation",
    "template": "general_content",
    "section": "commissioning,-qualification-and-validation"
  },
  {
    "name": "Commissioning, Qualification and Validation",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/commissioning-qualification-and-validation",
    "url": "/consulting/engineering/biopharma-services/commissioning-qualification-and-validation",
    "template": "general_content",
    "section": "commissioning,-qualification-and-validation"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/mechanical-integrity-inspections",
    "url": "/provenance-process-safety-risk-management/mechanical-integrity-inspections",
    "template": "general_content",
    "section": "mechanical-integrity-inspections"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/mechanical-integrity-inspections",
    "url": "/provenance-consulting/mechanical-integrity-inspections",
    "template": "general_content",
    "section": "mechanical-integrity-inspections"
  },
  {
    "name": "Mechanical Integrity & Inspections",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/mechanical-integrity-inspections",
    "url": "/provenance-process-safety-risk-management-program/mechanical-integrity-inspections",
    "template": "general_content",
    "section": "mechanical-integrity-inspections"
  },
  {
    "name": "Process Design",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/process-design",
    "url": "/advent-life-sciences-engineering-services/process-design",
    "template": "general_content",
    "section": "process-design"
  },
  {
    "name": "Process Design",
    "apiurl": "/restapi/customcontent/advent-life-sciences-engineering-services/process-design",
    "url": "/consulting/engineering/biopharma-services",
    "template": "general_content",
    "section": "process-design"
  },
  {
    "name": "Ingredient Approval",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/ingredient-approval",
    "url": "/safebridge-regulatory-life-sciences-group/ingredient-approval",
    "template": "general_content",
    "section": "ingredient-approval"
  },
  {
    "name": "Ingredient Approval",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/ingredient-approval",
    "url": "/consulting/science/Ingredient-Approval",
    "template": "general_content",
    "section": "ingredient-approval"
  },
  {
    "name": "Analytical Laboratory",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/analytical-laboratory",
    "url": "/safebridge-regulatory-life-sciences-group/analytical-laboratory",
    "template": "general_content",
    "section": "analytical-laboratory"
  },
  {
    "name": "Analytical Laboratory",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/analytical-laboratory",
    "url": "/consulting/ehs/health-safety/Analytical-Laboratory",
    "template": "general_content",
    "section": "analytical-laboratory"
  },
  {
    "name": "Fish and Fish Habitat Studies",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/fish-and-fish-habitat-studies",
    "url": "/minnow-aquatic-environmental-services/fish-and-fish-habitat-studies",
    "template": "general_content",
    "section": "fish-and-fish-habitat-studies"
  },
  {
    "name": "Fish and Fish Habitat Studies",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/fish-and-fish-habitat-studies",
    "url": "/consulting/science/aquatic-ecology/fish-and-fish-habitat-studies",
    "template": "general_content",
    "section": "fish-and-fish-habitat-studies"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/environmental-health-safety-program-support",
    "url": "/safebridge-regulatory-life-sciences-group/environmental-health-safety-program-support",
    "template": "general_content",
    "section": "environmental-health-safety-program-support"
  },
  {
    "name": "Environmental Health & Safety Program Support",
    "apiurl": "/restapi/customcontent/safebridge-regulatory-life-sciences-group/environmental-health-safety-program-support",
    "url": "/consulting/ehs/health-safety/EHS",
    "template": "general_content",
    "section": "environmental-health-safety-program-support"
  },
  {
    "name": "Aquatic Biological and Ecological Assessment",
    "apiurl": "/restapi/customcontent/Minnow-Aquatic-Environmental-Services/block1",
    "url": "/Minnow-Aquatic-Environmental-Services/block1",
    "template": "general_content",
    "section": "aquatic-biological-and-ecological-assessment"
  },
  {
    "name": "Water Quality and Marine Ecology Monitoring",
    "apiurl": "/restapi/customcontent/trinity-consultants-australia/water-quality-and-marine-ecology-monitoring",
    "url": "/trinity-consultants-australia/water-quality-and-marine-ecology-monitoring",
    "template": "general_content",
    "section": "water-quality-and-marine-ecology-monitoring"
  },
  {
    "name": "Environmental Geochemistry",
    "apiurl": "/restapi/customcontent/minnow-aquatic-environmental-services/environmental-geochemistry",
    "url": "/minnow-aquatic-environmental-services/environmental-geochemistry",
    "template": "general_content",
    "section": "environmental-geochemistry"
  },
  {
    "name": "IPL Lifecycle Support",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/ipl-lifecycle-support",
    "url": "/provenance-process-safety-risk-management/ipl-lifecycle-support",
    "template": "general_content",
    "section": "ipl-lifecycle-support"
  },
  {
    "name": "IPL Lifecycle Support",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/ipl-lifecycle-support",
    "url": "/provenance-consulting/ipl-lifecycle-support",
    "template": "general_content",
    "section": "ipl-lifecycle-support"
  },
  {
    "name": "IPL Lifecycle Support",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/ipl-lifecycle-support",
    "url": "/provenance-process-safety-risk-management-program/ipl-lifecycle-support",
    "template": "general_content",
    "section": "ipl-lifecycle-support"
  },
  {
    "name": "PHA and Risk Assessments",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/pha-and-risk-assessments",
    "url": "/provenance-process-safety-risk-management/pha-and-risk-assessments",
    "template": "general_content",
    "section": "pha-and-risk-assessments"
  },
  {
    "name": "PHA and Risk Assessments",
    "apiurl": "/restapi/customcontent/provenance-process-safety-risk-management/pha-and-risk-assessments",
    "url": "/provenance-consulting/pha-and-risk-assessments",
    "template": "general_content",
    "section": "pha-and-risk-assessments"
  },
  {
    "name": "Aquatic Environmental Impact Assessment",
    "apiurl": "/restapi/customcontent/Minnow-Aquatic-Environmental-Services/service-block-1",
    "url": "/Minnow-Aquatic-Environmental-Services/service-block-1",
    "template": "general_content",
    "section": "aquatic-environmental-impact-assessment"
  },
  {
    "name": "Aquatic Environmental Impact Assessment",
    "apiurl": "/restapi/customcontent/Minnow-Aquatic-Environmental-Services/service-block-1",
    "url": "/consulting/science/aquatic-ecology/aquatic-environmental-impact-assessment",
    "template": "general_content",
    "section": "aquatic-environmental-impact-assessment"
  },
  {
    "name": "General Information",
    "apiurl": "/restapi/customcontent/software/support/faq/faq",
    "url": "/software/support/faq/faq",
    "template": "general_content",
    "section": "general-information"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customcontent/software/support/release-notes/tankesp",
    "url": "/software/support/release-notes/tankesp",
    "template": "general_content",
    "section": "tankesp"
  },
  {
    "name": "ROADS",
    "apiurl": "/restapi/customcontent/software/support/release-notes/roads",
    "url": "/software/support/release-notes/roads",
    "template": "general_content",
    "section": "roads"
  },
  {
    "name": "Purchasing",
    "apiurl": "/restapi/customcontent/software/support/faq/purchasing",
    "url": "/software/support/faq/purchasing",
    "template": "general_content",
    "section": "purchasing"
  },
  {
    "name": "Support",
    "apiurl": "/restapi/customcontent/software/support/faq/support",
    "url": "/software/support/faq/support",
    "template": "general_content",
    "section": "support"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customcontent/software/support/release-notes/3d-analyst",
    "url": "/software/support/release-notes/3d-analyst",
    "template": "general_content",
    "section": "3d-analyst"
  },
  {
    "name": "AERMOD",
    "apiurl": "/restapi/customcontent/software/support/release-notes/aermod",
    "url": "/software/support/release-notes/aermod",
    "template": "general_content",
    "section": "aermod"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customcontent/software/support/release-notes/incident-analyst",
    "url": "/software/support/release-notes/incident-analyst",
    "template": "general_content",
    "section": "incident-analyst"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customcontent/software/support/release-notes/metview",
    "url": "/software/support/release-notes/metview",
    "template": "general_content",
    "section": "metview"
  },
  {
    "name": "Maintenance",
    "apiurl": "/restapi/customcontent/software/support/faq/maintenance",
    "url": "/software/support/faq/maintenance",
    "template": "general_content",
    "section": "maintenance"
  },
  {
    "name": "Software Updates",
    "apiurl": "/restapi/customcontent/software/support/faq/software-updates",
    "url": "/software/support/faq/software-updates",
    "template": "general_content",
    "section": "software-updates"
  },
  {
    "name": "AERMET",
    "apiurl": "/restapi/customcontent/software/support/release-notes/aermet",
    "url": "/software/support/release-notes/aermet",
    "template": "general_content",
    "section": "aermet"
  },
  {
    "name": "AERSCREEN",
    "apiurl": "/restapi/customcontent/software/support/release-notes/aerscreen",
    "url": "/software/support/release-notes/aerscreen",
    "template": "general_content",
    "section": "aerscreen"
  },
  {
    "name": "Maintenance and Support",
    "apiurl": "/restapi/customcontent/software/support/maintenance-support-agreements/maintenance-and-support",
    "url": "/software/support/maintenance-support-agreements/maintenance-and-support",
    "template": "general_content",
    "section": "maintenance-and-support"
  },
  {
    "name": "Maintenance and Support",
    "apiurl": "/restapi/customcontent/software/support/maintenance-support-agreements/maintenance-and-support",
    "url": "/software/support/maintenance-and-support-agreements/maintenance-and-support",
    "template": "general_content",
    "section": "maintenance-and-support"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customcontent/software/support/release-notes/downwash-analyst",
    "url": "/software/support/release-notes/downwash-analyst",
    "template": "general_content",
    "section": "downwash-analyst"
  },
  {
    "name": "ExDAM",
    "apiurl": "/restapi/customcontent/software/support/release-notes/exdam",
    "url": "/software/support/release-notes/exdam",
    "template": "general_content",
    "section": "exdam"
  },
  {
    "name": "CALPUFF",
    "apiurl": "/restapi/customcontent/software/support/release-notes/calpuff",
    "url": "/software/support/release-notes/calpuff",
    "template": "general_content",
    "section": "calpuff"
  },
  {
    "name": "Licensing",
    "apiurl": "/restapi/customcontent/software/support/faq/licensing",
    "url": "/software/support/faq/licensing",
    "template": "general_content",
    "section": "licensing"
  },
  {
    "name": "AERMOD FAQs",
    "apiurl": "/restapi/customcontent/software/support/faq/aermod",
    "url": "/software/support/faq/aermod",
    "template": "general_content",
    "section": "aermod-faqs"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customcontent/software/support/release-notes/vasdip",
    "url": "/software/support/release-notes/vasdip",
    "template": "general_content",
    "section": "vasdip"
  },
  {
    "name": "The Five USP Compliance Considerations Compounding Pharmacies May Be Overlooking",
    "apiurl": "/restapi/customcontent/home/the-five-usp-compliance-considerations-compounding-pharmacies-may-be-overlooking",
    "url": "/home/the-five-usp-compliance-considerations-compounding-pharmacies-may-be-overlooking",
    "template": "general_content",
    "section": "the-five-usp-compliance-considerations-compounding-pharmacies-may-be-overlooking"
  },
  {
    "name": "How to Minimize Your Footprint and Maximize Your Handprint",
    "apiurl": "/restapi/customcontent/home/how-to-minimize-your-footprint-and-maximize-your-handprint",
    "url": "/home/how-to-minimize-your-footprint-and-maximize-your-handprint",
    "template": "general_content",
    "section": "how-to-minimize-your-footprint-and-maximize-your-handprint"
  },
  {
    "name": "Why It’s Vital to Consider the Built Environment at the Start of Your USP <797>/<800> Compliance Journey",
    "apiurl": "/restapi/customcontent/home/why-its-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "url": "/home/why-its-vital-to-consider-the-built-environment-at-the-start-of-your-usp-797-800-compliance-journey",
    "template": "general_content",
    "section": "why-it’s-vital-to-consider-the-built-environment-at-the-start-of-your-usp-<797><800>-compliance-journey"
  },
  {
    "name": "Powering a Sustainable Future with Immediate and Effective Decarbonization Strategies",
    "apiurl": "/restapi/customcontent/home/powering-a-sustainable-future-with-immediate-and-effective-decarbonization-strategies",
    "url": "/home/powering-a-sustainable-future-with-immediate-and-effective-decarbonization-strategies",
    "template": "general_content",
    "section": "powering-a-sustainable-future-with-immediate-and-effective-decarbonization-strategies"
  },
  {
    "name": "Submit Remote Modeling",
    "apiurl": "/restapi/customcontent/remote-modeling-requirements",
    "url": "/remote-modeling-requirements",
    "template": "general_content",
    "section": "submit-remote-modeling"
  },
  {
    "name": "Submit Remote Modeling",
    "apiurl": "/restapi/customcontent/remote-modeling-requirements",
    "url": "/submit-remote-modeling",
    "template": "general_content",
    "section": "submit-remote-modeling"
  },
  {
    "name": "Job Alert Sign Up",
    "apiurl": "/restapi/customcontent/job-alert-sign-up",
    "url": "/job-alert-sign-up",
    "template": "general_content",
    "section": "job-alert-sign-up"
  },
  {
    "name": "Operational & Maintenance",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/operational-maintenance",
    "url": "/technology/our-approach/operational-maintenance",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Operational & Maintenance",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/operational-maintenance",
    "url": "/environmental-technology/our-approach/operational-maintenance",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Data Analytics & Regulatory Reporting",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/data-analytics-reg-reporting",
    "url": "/technology/our-approach/data-analytics-reg-reporting",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Data Analytics & Regulatory Reporting",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/data-analytics-reg-reporting",
    "url": "/technology/our-approach/data-analytics",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Deployment",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/deployment",
    "url": "/technology/our-approach/deployment",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Deployment",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/deployment",
    "url": "/environmental-technology/our-approach/deployment",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Strategy & Planning",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/strategy-planning",
    "url": "/technology/our-approach/strategy-planning",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Agile Build & Integration",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/agile-build-and-integration",
    "url": "/technology/our-approach/agile-build-and-integration",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Agile Build & Integration",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/agile-build-and-integration",
    "url": "/technology/our-approach/system-integration",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Design & Development",
    "apiurl": "/restapi/customtechnologylvl3content/technology/our-approach/design-development",
    "url": "/technology/our-approach/design-development",
    "template": "new-technology-individual-sub-category",
    "section": "our-approach"
  },
  {
    "name": "Industry Specific",
    "apiurl": "/restapi/customcontent/training/training-by-industry/industry-specific",
    "url": "/training/training-by-industry/industry-specific",
    "template": "general_content",
    "section": "industry-specific"
  },
  {
    "name": "Introductory Courses",
    "apiurl": "/restapi/customcontent/training/training-by-category/introductory-courses",
    "url": "/training/training-by-category/introductory-courses",
    "template": "general_content",
    "section": "introductory-courses"
  },
  {
    "name": "EHS&S Management",
    "apiurl": "/restapi/customcontent/training/training-by-category/ehs-s-management",
    "url": "/training/training-by-category/ehs-s-management",
    "template": "general_content",
    "section": "ehss-management"
  },
  {
    "name": "EHS Regulations",
    "apiurl": "/restapi/customcontent/training/training-by-category/ehs-regulations",
    "url": "/training/training-by-category/ehs-regulations",
    "template": "general_content",
    "section": "ehs-regulations"
  },
  {
    "name": "Technical Courses",
    "apiurl": "/restapi/customcontent/training/training-by-category/technical-courses",
    "url": "/training/training-by-category/technical-courses",
    "template": "general_content",
    "section": "technical-courses"
  },
  {
    "name": "Environmental Auditing",
    "apiurl": "/restapi/customcontent/training/training-by-category/environmental-auditing",
    "url": "/training/training-by-category/environmental-auditing",
    "template": "general_content",
    "section": "environmental-auditing"
  },
  {
    "name": "Software Courses",
    "apiurl": "/restapi/customcontent/training/training-by-category/software-courses",
    "url": "/training/training-by-category/software-courses",
    "template": "general_content",
    "section": "software-courses"
  },
  {
    "name": "Environmental Analysis",
    "apiurl": "/restapi/customcontent/training/training-by-category/environmental-analysis",
    "url": "/training/training-by-category/environmental-analysis",
    "template": "general_content",
    "section": "environmental-analysis"
  },
  {
    "name": "Environmental Reporting",
    "apiurl": "/restapi/customcontent/training/training-by-category/environmental-reporting",
    "url": "/training/training-by-category/environmental-reporting",
    "template": "general_content",
    "section": "environmental-reporting"
  },
  {
    "name": "Environmental Management",
    "apiurl": "/restapi/customcontent/training/training-by-category/environmental-management",
    "url": "/training/training-by-category/environmental-management",
    "template": "general_content",
    "section": "environmental-management"
  },
  {
    "name": "Advent Supports Rapid Vaccine Rollout",
    "apiurl": "/restapi/customcontent/home/advent-supports-rapid-vaccine-rollout",
    "url": "/home/advent-supports-rapid-vaccine-rollout",
    "template": "general_content",
    "section": "advent-supports-rapid-vaccine-rollout"
  },
  {
    "name": "Penske Partners with Trinity",
    "apiurl": "/restapi/customcontent/home/penske-partners-with-trinity",
    "url": "/home/penske-partners-with-trinity",
    "template": "general_content",
    "section": "penske-partners-with-trinity"
  },
  {
    "name": "Nucor Provides Transparency of Steel's Environmental Impact with Trinity",
    "apiurl": "/restapi/customcontent/home/nucor-provides-transparency-of-steel's-environmental-impact-with-trinity",
    "url": "/home/nucor-provides-transparency-of-steel's-environmental-impact-with-trinity",
    "template": "general_content",
    "section": "nucor-provides-transparency-of-steel's-environmental-impact-with-trinity"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/careers/Recruiting",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/environmental-consultant-career/recruiting",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/careers/www.trinity-careers.com",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/careers/Recruiting",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/environmental-consultant-career/recruiting",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Recruiting",
    "apiurl": "/restapi/customcareerslvl2content/careers/Recruiting",
    "url": "/careers/www.trinity-careers.com",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Career Program",
    "apiurl": "/restapi/customcareerslvl2content/careers/career-program",
    "url": "/careers/career-program",
    "template": "",
    "section": "careers"
  },
  {
    "name": "Career Program",
    "apiurl": "/restapi/customcareerslvl2content/careers/career-program",
    "url": "/careers/career-program",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/careers/available-positions",
    "template": "new-trinity-available-positions",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/about/careers/available-positions",
    "template": "new-trinity-available-positions",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/career/available-positions",
    "template": "new-trinity-available-positions",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/careers/available-positions",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/about/careers/available-positions",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customcareerslvl2content/careers/available-positions",
    "url": "/career/available-positions",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Benefits",
    "apiurl": "/restapi/customcareerslvl2content/careers/benefits",
    "url": "/careers/benefits",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Benefits",
    "apiurl": "/restapi/customcareerslvl2content/careers/benefits",
    "url": "/environmental-consultant-career/benefits",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Benefits",
    "apiurl": "/restapi/customcareerslvl2content/careers/benefits",
    "url": "/careers/benefits",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Benefits",
    "apiurl": "/restapi/customcareerslvl2content/careers/benefits",
    "url": "/environmental-consultant-career/benefits",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Culture",
    "apiurl": "/restapi/customcareerslvl2content/careers/culture",
    "url": "/careers/culture",
    "template": "new-trinity-company-career",
    "section": "careers"
  },
  {
    "name": "Culture",
    "apiurl": "/restapi/customcareerslvl2content/careers/culture",
    "url": "/careers/culture",
    "template": "new-trinity-position",
    "section": "careers"
  },
  {
    "name": "Prince Edward Island",
    "apiurl": "/restapi/customcontent/contact-us/Prince-Edward-Island",
    "url": "/contact-us/Prince-Edward-Island",
    "template": "general_content",
    "section": "prince-edward-island"
  },
  {
    "name": "Saskatchewan",
    "apiurl": "/restapi/customcontent/contact-us/Saskatchewan",
    "url": "/contact-us/Saskatchewan",
    "template": "general_content",
    "section": "saskatchewan"
  },
  {
    "name": "Nevada",
    "apiurl": "/restapi/customcontent/contact-us/Nevada",
    "url": "/contact-us/Nevada",
    "template": "general_content",
    "section": "nevada"
  },
  {
    "name": "Oregon",
    "apiurl": "/restapi/customcontent/contact-us/Oregon",
    "url": "/contact-us/Oregon",
    "template": "general_content",
    "section": "oregon"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customcontent/contact-us/Texas",
    "url": "/contact-us/Texas",
    "template": "general_content",
    "section": "texas"
  },
  {
    "name": "Ohio",
    "apiurl": "/restapi/customcontent/contact-us/Ohio",
    "url": "/contact-us/Ohio",
    "template": "general_content",
    "section": "ohio"
  },
  {
    "name": "Telangana",
    "apiurl": "/restapi/customcontent/contact-us/telangana",
    "url": "/contact-us/telangana",
    "template": "general_content",
    "section": "telangana"
  },
  {
    "name": "Leinster",
    "apiurl": "/restapi/customcontent/contact-us/leinster",
    "url": "/contact-us/leinster",
    "template": "general_content",
    "section": "leinster"
  },
  {
    "name": "Hawaii",
    "apiurl": "/restapi/customcontent/contact-us/Hawaii",
    "url": "/contact-us/Hawaii",
    "template": "general_content",
    "section": "hawaii"
  },
  {
    "name": "British Columbia",
    "apiurl": "/restapi/customcontent/contact-us/British-Columbia",
    "url": "/contact-us/British-Columbia",
    "template": "general_content",
    "section": "british-columbia"
  },
  {
    "name": "Georgia",
    "apiurl": "/restapi/customcontent/contact-us/Georgia",
    "url": "/contact-us/Georgia",
    "template": "general_content",
    "section": "georgia"
  },
  {
    "name": "Wyoming",
    "apiurl": "/restapi/customcontent/contact-us/Wyoming",
    "url": "/contact-us/Wyoming",
    "template": "general_content",
    "section": "wyoming"
  },
  {
    "name": "Arkansas",
    "apiurl": "/restapi/customcontent/contact-us/Arkansas",
    "url": "/contact-us/Arkansas",
    "template": "general_content",
    "section": "arkansas"
  },
  {
    "name": "Massachusetts",
    "apiurl": "/restapi/customcontent/contact-us/Massachusetts",
    "url": "/contact-us/Massachusetts",
    "template": "general_content",
    "section": "massachusetts"
  },
  {
    "name": "New Brunswick",
    "apiurl": "/restapi/customcontent/contact-us/New-Brunswick",
    "url": "/contact-us/New-Brunswick",
    "template": "general_content",
    "section": "new-brunswick"
  },
  {
    "name": "Iowa",
    "apiurl": "/restapi/customcontent/contact-us/Iowa",
    "url": "/contact-us/Iowa",
    "template": "general_content",
    "section": "iowa"
  },
  {
    "name": "South Dakota",
    "apiurl": "/restapi/customcontent/contact-us/South-Dakota",
    "url": "/contact-us/South-Dakota",
    "template": "general_content",
    "section": "south-dakota"
  },
  {
    "name": "New South Wales",
    "apiurl": "/restapi/customcontent/contact-us/new-south-wales",
    "url": "/contact-us/new-south-wales",
    "template": "general_content",
    "section": "new-south-wales"
  },
  {
    "name": "Utah",
    "apiurl": "/restapi/customcontent/contact-us/Utah",
    "url": "/contact-us/Utah",
    "template": "general_content",
    "section": "utah"
  },
  {
    "name": "Yukon",
    "apiurl": "/restapi/customcontent/contact-us/Yukon",
    "url": "/contact-us/Yukon",
    "template": "general_content",
    "section": "yukon"
  },
  {
    "name": "Hainan Province",
    "apiurl": "/restapi/customcontent/contact-us/hainan-province",
    "url": "/contact-us/hainan-province",
    "template": "general_content",
    "section": "hainan-province"
  },
  {
    "name": "Northwest Territories",
    "apiurl": "/restapi/customcontent/contact-us/Northwest-Territories",
    "url": "/contact-us/Northwest-Territories",
    "template": "general_content",
    "section": "northwest-territories"
  },
  {
    "name": "Calgary",
    "apiurl": "/restapi/customcontent/contact-us/Calgary",
    "url": "/contact-us/Calgary",
    "template": "general_content",
    "section": "calgary"
  },
  {
    "name": "Washington, DC",
    "apiurl": "/restapi/customcontent/contact-us/Washington-DC",
    "url": "/contact-us/Washington-DC",
    "template": "general_content",
    "section": "washington,-dc"
  },
  {
    "name": "Washington",
    "apiurl": "/restapi/customcontent/contact-us/Washington",
    "url": "/contact-us/Washington",
    "template": "general_content",
    "section": "washington"
  },
  {
    "name": "New Mexico",
    "apiurl": "/restapi/customcontent/contact-us/New-Mexico",
    "url": "/contact-us/New-Mexico",
    "template": "general_content",
    "section": "new-mexico"
  },
  {
    "name": "Idaho",
    "apiurl": "/restapi/customcontent/contact-us/Idaho",
    "url": "/contact-us/Idaho",
    "template": "general_content",
    "section": "idaho"
  },
  {
    "name": "Nova Scotia",
    "apiurl": "/restapi/customcontent/contact-us/Nova-Scotia",
    "url": "/contact-us/Nova-Scotia",
    "template": "general_content",
    "section": "nova-scotia"
  },
  {
    "name": "Vermont",
    "apiurl": "/restapi/customcontent/contact-us/Vermont",
    "url": "/contact-us/Vermont",
    "template": "general_content",
    "section": "vermont"
  },
  {
    "name": "North Carolina",
    "apiurl": "/restapi/customcontent/contact-us/North-Carolina",
    "url": "/contact-us/North-Carolina",
    "template": "general_content",
    "section": "north-carolina"
  },
  {
    "name": "Alabama",
    "apiurl": "/restapi/customcontent/contact-us/Alabama",
    "url": "/contact-us/Alabama",
    "template": "general_content",
    "section": "alabama"
  },
  {
    "name": "International",
    "apiurl": "/restapi/customcontent/contact-us/International",
    "url": "/contact-us/International",
    "template": "general_content",
    "section": "international"
  },
  {
    "name": "Indiana",
    "apiurl": "/restapi/customcontent/contact-us/Indiana",
    "url": "/contact-us/Indiana",
    "template": "general_content",
    "section": "indiana"
  },
  {
    "name": "Maine",
    "apiurl": "/restapi/customcontent/contact-us/Maine",
    "url": "/contact-us/Maine",
    "template": "general_content",
    "section": "maine"
  },
  {
    "name": "Maharashtra",
    "apiurl": "/restapi/customcontent/contact-us/maharashtra",
    "url": "/contact-us/maharashtra",
    "template": "general_content",
    "section": "maharashtra"
  },
  {
    "name": "South Carolina",
    "apiurl": "/restapi/customcontent/contact-us/South-Carolina",
    "url": "/contact-us/South-Carolina",
    "template": "general_content",
    "section": "south-carolina"
  },
  {
    "name": "Zhejiang",
    "apiurl": "/restapi/customcontent/contact-us/Zhejiang",
    "url": "/contact-us/Zhejiang",
    "template": "general_content",
    "section": "zhejiang"
  },
  {
    "name": "Newfoundland and Labrador",
    "apiurl": "/restapi/customcontent/contact-us/Newfoundland-and-Labrador",
    "url": "/contact-us/Newfoundland-and-Labrador",
    "template": "general_content",
    "section": "newfoundland-and-labrador"
  },
  {
    "name": "Arizona",
    "apiurl": "/restapi/customcontent/contact-us/Arizona",
    "url": "/contact-us/Arizona",
    "template": "general_content",
    "section": "arizona"
  },
  {
    "name": "Kansas",
    "apiurl": "/restapi/customcontent/contact-us/Kansas",
    "url": "/contact-us/Kansas",
    "template": "general_content",
    "section": "kansas"
  },
  {
    "name": "Nunavut",
    "apiurl": "/restapi/customcontent/contact-us/Nunavut",
    "url": "/contact-us/Nunavut",
    "template": "general_content",
    "section": "nunavut"
  },
  {
    "name": "Maryland",
    "apiurl": "/restapi/customcontent/contact-us/Maryland",
    "url": "/contact-us/Maryland",
    "template": "general_content",
    "section": "maryland"
  },
  {
    "name": "Queensland",
    "apiurl": "/restapi/customcontent/contact-us/queensland",
    "url": "/contact-us/queensland",
    "template": "general_content",
    "section": "queensland"
  },
  {
    "name": "Missouri",
    "apiurl": "/restapi/customcontent/contact-us/Missouri",
    "url": "/contact-us/Missouri",
    "template": "general_content",
    "section": "missouri"
  },
  {
    "name": "North Dakota",
    "apiurl": "/restapi/customcontent/contact-us/North-Dakota",
    "url": "/contact-us/North-Dakota",
    "template": "general_content",
    "section": "north-dakota"
  },
  {
    "name": "California",
    "apiurl": "/restapi/customcontent/contact-us/California",
    "url": "/contact-us/California",
    "template": "general_content",
    "section": "california"
  },
  {
    "name": "Tennessee",
    "apiurl": "/restapi/customcontent/contact-us/Tennessee",
    "url": "/contact-us/Tennessee",
    "template": "general_content",
    "section": "tennessee"
  },
  {
    "name": "Nebraska",
    "apiurl": "/restapi/customcontent/contact-us/Nebraska",
    "url": "/contact-us/Nebraska",
    "template": "general_content",
    "section": "nebraska"
  },
  {
    "name": "District of Columbia",
    "apiurl": "/restapi/customcontent/contact-us/district-of-columbia",
    "url": "/contact-us/district-of-columbia",
    "template": "general_content",
    "section": "district-of-columbia"
  },
  {
    "name": "Oklahoma",
    "apiurl": "/restapi/customcontent/contact-us/Oklahoma",
    "url": "/contact-us/Oklahoma",
    "template": "general_content",
    "section": "oklahoma"
  },
  {
    "name": "Puerto Rico",
    "apiurl": "/restapi/customcontent/contact-us/Puerto-Rico",
    "url": "/contact-us/Puerto-Rico",
    "template": "general_content",
    "section": "puerto-rico"
  },
  {
    "name": "New Hampshire",
    "apiurl": "/restapi/customcontent/contact-us/New-Hampshire",
    "url": "/contact-us/New-Hampshire",
    "template": "general_content",
    "section": "new-hampshire"
  },
  {
    "name": "Canada",
    "apiurl": "/restapi/customcontent/contact-us/Canada",
    "url": "/contact-us/Canada",
    "template": "general_content",
    "section": "canada"
  },
  {
    "name": "Manitoba",
    "apiurl": "/restapi/customcontent/contact-us/Manitoba",
    "url": "/contact-us/Manitoba",
    "template": "general_content",
    "section": "manitoba"
  },
  {
    "name": "Quebec",
    "apiurl": "/restapi/customcontent/contact-us/Quebec",
    "url": "/contact-us/Quebec",
    "template": "general_content",
    "section": "quebec"
  },
  {
    "name": "Mississippi",
    "apiurl": "/restapi/customcontent/contact-us/Mississippi",
    "url": "/contact-us/Mississippi",
    "template": "general_content",
    "section": "mississippi"
  },
  {
    "name": "Wisconsin",
    "apiurl": "/restapi/customcontent/contact-us/Wisconsin",
    "url": "/contact-us/Wisconsin",
    "template": "general_content",
    "section": "wisconsin"
  },
  {
    "name": "West Virginia",
    "apiurl": "/restapi/customcontent/contact-us/West-Virginia",
    "url": "/contact-us/West-Virginia",
    "template": "general_content",
    "section": "west-virginia"
  },
  {
    "name": "Pennsylvania",
    "apiurl": "/restapi/customcontent/contact-us/Pennsylvania",
    "url": "/contact-us/Pennsylvania",
    "template": "general_content",
    "section": "pennsylvania"
  },
  {
    "name": "Guam",
    "apiurl": "/restapi/customcontent/contact-us/Guam",
    "url": "/contact-us/Guam",
    "template": "general_content",
    "section": "guam"
  },
  {
    "name": "Kentucky",
    "apiurl": "/restapi/customcontent/contact-us/Kentucky",
    "url": "/contact-us/Kentucky",
    "template": "general_content",
    "section": "kentucky"
  },
  {
    "name": "Alberta",
    "apiurl": "/restapi/customcontent/contact-us/Alberta",
    "url": "/contact-us/Alberta",
    "template": "general_content",
    "section": "alberta"
  },
  {
    "name": "United Kingdom",
    "apiurl": "/restapi/customcontent/contact-us/United-Kingdom",
    "url": "/contact-us/United-Kingdom",
    "template": "general_content",
    "section": "united-kingdom"
  },
  {
    "name": "Rhode Island",
    "apiurl": "/restapi/customcontent/contact-us/Rhode-Island",
    "url": "/contact-us/Rhode-Island",
    "template": "general_content",
    "section": "rhode-island"
  },
  {
    "name": "South Island",
    "apiurl": "/restapi/customcontent/contact-us/south-island",
    "url": "/contact-us/south-island",
    "template": "general_content",
    "section": "south-island"
  },
  {
    "name": "Washington, D.C.",
    "apiurl": "/restapi/customcontent/contact-us/washington-d.c",
    "url": "/contact-us/washington-d.c",
    "template": "general_content",
    "section": "washington,-d.c."
  },
  {
    "name": "Montana",
    "apiurl": "/restapi/customcontent/contact-us/Montana",
    "url": "/contact-us/Montana",
    "template": "general_content",
    "section": "montana"
  },
  {
    "name": "Toronto",
    "apiurl": "/restapi/customcontent/contact-us/toronto",
    "url": "/contact-us/toronto",
    "template": "general_content",
    "section": "toronto"
  },
  {
    "name": "Beijing",
    "apiurl": "/restapi/customcontent/contact-us/beijing",
    "url": "/contact-us/beijing",
    "template": "general_content",
    "section": "beijing"
  },
  {
    "name": "Delaware",
    "apiurl": "/restapi/customcontent/contact-us/Delaware",
    "url": "/contact-us/Delaware",
    "template": "general_content",
    "section": "delaware"
  },
  {
    "name": "New Jersey",
    "apiurl": "/restapi/customcontent/contact-us/New-Jersey",
    "url": "/contact-us/New-Jersey",
    "template": "general_content",
    "section": "new-jersey"
  },
  {
    "name": "Guangdong",
    "apiurl": "/restapi/customcontent/contact-us/guangdong",
    "url": "/contact-us/guangdong",
    "template": "general_content",
    "section": "guangdong"
  },
  {
    "name": "Alaska",
    "apiurl": "/restapi/customcontent/contact-us/Alaska",
    "url": "/contact-us/Alaska",
    "template": "general_content",
    "section": "alaska"
  },
  {
    "name": "Colorado",
    "apiurl": "/restapi/customcontent/contact-us/Colorado",
    "url": "/contact-us/Colorado",
    "template": "general_content",
    "section": "colorado"
  },
  {
    "name": "Michigan",
    "apiurl": "/restapi/customcontent/contact-us/Michigan",
    "url": "/contact-us/Michigan",
    "template": "general_content",
    "section": "michigan"
  },
  {
    "name": "Illinois",
    "apiurl": "/restapi/customcontent/contact-us/Illinois",
    "url": "/contact-us/Illinois",
    "template": "general_content",
    "section": "illinois"
  },
  {
    "name": "New York",
    "apiurl": "/restapi/customcontent/contact-us/New-York",
    "url": "/contact-us/New-York",
    "template": "general_content",
    "section": "new-york"
  },
  {
    "name": "Florida",
    "apiurl": "/restapi/customcontent/contact-us/Florida",
    "url": "/contact-us/Florida",
    "template": "general_content",
    "section": "florida"
  },
  {
    "name": "Connecticut",
    "apiurl": "/restapi/customcontent/contact-us/Connecticut",
    "url": "/contact-us/Connecticut",
    "template": "general_content",
    "section": "connecticut"
  },
  {
    "name": "Louisiana",
    "apiurl": "/restapi/customcontent/contact-us/Louisiana",
    "url": "/contact-us/Louisiana",
    "template": "general_content",
    "section": "louisiana"
  },
  {
    "name": "Suffolk",
    "apiurl": "/restapi/customcontent/contact-us/suffolk",
    "url": "/contact-us/suffolk",
    "template": "general_content",
    "section": "suffolk"
  },
  {
    "name": "Virginia",
    "apiurl": "/restapi/customcontent/contact-us/Virginia",
    "url": "/contact-us/Virginia",
    "template": "general_content",
    "section": "virginia"
  },
  {
    "name": "Minnesota",
    "apiurl": "/restapi/customcontent/contact-us/Minnesota",
    "url": "/contact-us/Minnesota",
    "template": "general_content",
    "section": "minnesota"
  },
  {
    "name": "Request Data Quote",
    "apiurl": "/restapi/customcontent/request-data-quote",
    "url": "/request-data-quote",
    "template": "general_content",
    "section": "request-data-quote"
  },
  {
    "name": "Environmental Health & Safety",
    "apiurl": "/restapi/customcontent/environmental-health-safety",
    "url": "/environmental-health-safety",
    "template": "general_content",
    "section": "environmental-health-safety"
  },
  {
    "name": "Engineering",
    "apiurl": "/restapi/customcontent/engineering",
    "url": "/engineering",
    "template": "general_content",
    "section": "engineering"
  },
  {
    "name": "Accidental Hazardous Release",
    "apiurl": "/restapi/customcontent/contact-us/accidental-hazardous-release",
    "url": "/contact-us/accidental-hazardous-release",
    "template": "general_content",
    "section": "accidental-hazardous-release"
  },
  {
    "name": "Tank Services",
    "apiurl": "/restapi/customcontent/contact-us/tank-services",
    "url": "/contact-us/tank-services",
    "template": "general_content",
    "section": "tank-services"
  },
  {
    "name": "Explosion Damage Assessment",
    "apiurl": "/restapi/customcontent/contact-us/explosion-damage-assessment",
    "url": "/contact-us/explosion-damage-assessment",
    "template": "general_content",
    "section": "explosion-damage-assessment"
  },
  {
    "name": "Data Visualization and Analysis Tools",
    "apiurl": "/restapi/customcontent/contact-us/data-visualization-and-analysis-tools",
    "url": "/contact-us/data-visualization-and-analysis-tools",
    "template": "general_content",
    "section": "data-visualization-and-analysis-tools"
  },
  {
    "name": "Health Risk Assessment",
    "apiurl": "/restapi/customcontent/contact-us/health-risk-assessment",
    "url": "/contact-us/health-risk-assessment",
    "template": "general_content",
    "section": "health-risk-assessment"
  },
  {
    "name": "Continuous Release",
    "apiurl": "/restapi/customcontent/contact-us/continuous-release",
    "url": "/contact-us/continuous-release",
    "template": "general_content",
    "section": "continuous-release"
  },
  {
    "name": "Home",
    "apiurl": "/restapi/customcontent/home",
    "url": "/home",
    "template": "general_content",
    "section": "home"
  },
  {
    "name": "High Speed Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/high-speed-modeling-services",
    "url": "/software/high-speed-modeling-services",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "High Speed Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/high-speed-modeling-services",
    "url": "/software/remote-modeling-services",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "High Speed Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/high-speed-modeling-services",
    "url": "/software/high-speed-modeling-services",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "High Speed Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/high-speed-modeling-services",
    "url": "/software/remote-modeling-services",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/resources",
    "template": "new-software-resources",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/software-resources",
    "template": "new-software-resources",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/sw-resources",
    "template": "new-software-resources",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/resources",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/software-resources",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Software Resources",
    "apiurl": "/restapi/customsoftwareresourcescontent/software/resources",
    "url": "/software/sw-resources",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-model-development",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-development-services",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-solutions",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-model-development",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-development-services",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Custom Development Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/custom-model-development",
    "url": "/software/custom-solutions",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Regulatory Agency Program",
    "apiurl": "/restapi/customcontent/software/regulatory-agency-program",
    "url": "/software/regulatory-agency-program",
    "template": "regulatory-agency-program",
    "section": "software"
  },
  {
    "name": "Regulatory Agency Program",
    "apiurl": "/restapi/customcontent/software/regulatory-agency-program",
    "url": "/software/regulatory-agency-program",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Specialty Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/specialty-modeling-services",
    "url": "/software/specialty-modeling-services",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "Specialty Modeling Services",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/specialty-modeling-services",
    "url": "/software/specialty-modeling-services",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Meteorological and Terrain Data",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/model-ready-data",
    "url": "/software/model-ready-data",
    "template": "new-software-modeling-ready-data",
    "section": "software"
  },
  {
    "name": "Meteorological and Terrain Data",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/model-ready-data",
    "url": "/software/model-ready-data",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Global Partners",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/global-partners",
    "url": "/software/global-partners",
    "template": "new-software-global-partners",
    "section": "software"
  },
  {
    "name": "Global Partners",
    "apiurl": "/restapi/customsoftwareservicecontentlvl2/software/global-partners",
    "url": "/software/global-partners",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Support",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl2/software/support",
    "url": "/software/support",
    "template": "new-software-support",
    "section": "software"
  },
  {
    "name": "Support",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl2/software/support",
    "url": "/software/software-support",
    "template": "new-software-support",
    "section": "software"
  },
  {
    "name": "Support",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl2/software/support",
    "url": "/software/support",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "Support",
    "apiurl": "/restapi/customsoftwaresupportcontentlvl2/software/support",
    "url": "/software/software-support",
    "template": "software-software-categories",
    "section": "software"
  },
  {
    "name": "SCREEN3",
    "apiurl": "/restapi/customcontent/contact-us/screen3",
    "url": "/contact-us/screen3",
    "template": "general_content",
    "section": "screen3"
  },
  {
    "name": "TankESP FX",
    "apiurl": "/restapi/customcontent/contact-us/tankesp-fx",
    "url": "/contact-us/tankesp-fx",
    "template": "general_content",
    "section": "tankesp-fx"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customcontent/contact-us/metview",
    "url": "/contact-us/metview",
    "template": "general_content",
    "section": "metview"
  },
  {
    "name": "AERMET",
    "apiurl": "/restapi/customcontent/contact-us/aermet",
    "url": "/contact-us/aermet",
    "template": "general_content",
    "section": "aermet"
  },
  {
    "name": "LFG Fire/Risk",
    "apiurl": "/restapi/customcontent/contact-us/lfg-fire-risk",
    "url": "/contact-us/lfg-fire-risk",
    "template": "general_content",
    "section": "lfg-firerisk"
  },
  {
    "name": "AERSCREEN",
    "apiurl": "/restapi/customcontent/contact-us/aerscreen",
    "url": "/contact-us/aerscreen",
    "template": "general_content",
    "section": "aerscreen"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customcontent/contact-us/incident-analyst",
    "url": "/contact-us/incident-analyst",
    "template": "general_content",
    "section": "incident-analyst"
  },
  {
    "name": "Risk Analyst",
    "apiurl": "/restapi/customcontent/contact-us/risk-analyst",
    "url": "/contact-us/risk-analyst",
    "template": "general_content",
    "section": "risk-analyst"
  },
  {
    "name": "TankESP LT",
    "apiurl": "/restapi/customcontent/contact-us/tankesp-lt",
    "url": "/contact-us/tankesp-lt",
    "template": "general_content",
    "section": "tankesp-lt"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customcontent/contact-us/downwash-analyst",
    "url": "/contact-us/downwash-analyst",
    "template": "general_content",
    "section": "downwash-analyst"
  },
  {
    "name": "TankESP PRO",
    "apiurl": "/restapi/customcontent/contact-us/tankesp-pro",
    "url": "/contact-us/tankesp-pro",
    "template": "general_content",
    "section": "tankesp-pro"
  },
  {
    "name": "AERMOD",
    "apiurl": "/restapi/customcontent/contact-us/aermod",
    "url": "/contact-us/aermod",
    "template": "general_content",
    "section": "aermod"
  },
  {
    "name": "CALPUFF",
    "apiurl": "/restapi/customcontent/contact-us/calpuff",
    "url": "/contact-us/calpuff",
    "template": "general_content",
    "section": "calpuff"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customcontent/contact-us/3d-analyst",
    "url": "/contact-us/3d-analyst",
    "template": "general_content",
    "section": "3d-analyst"
  },
  {
    "name": "ROADS",
    "apiurl": "/restapi/customcontent/contact-us/roads",
    "url": "/contact-us/roads",
    "template": "general_content",
    "section": "roads"
  },
  {
    "name": "ExDAM",
    "apiurl": "/restapi/customcontent/contact-us/exdam",
    "url": "/contact-us/exdam",
    "template": "general_content",
    "section": "exdam"
  },
  {
    "name": "Software Solutions",
    "apiurl": "/restapi/customtechnologylvl2content/technology/software-solutions",
    "url": "/technology/software-solutions",
    "template": "new-technology-individual-category",
    "section": "technology"
  },
  {
    "name": "About EHS Digital Solutions",
    "apiurl": "/restapi/customtechnologylvl2content/technology/about-ehs-digital-solutions",
    "url": "/technology/about-ehs-digital-solutions",
    "template": "new-technology-individual-category",
    "section": "technology"
  },
  {
    "name": "About EHS Digital Solutions",
    "apiurl": "/restapi/customtechnologylvl2content/technology/about-ehs-digital-solutions",
    "url": "/technology/about-us",
    "template": "new-technology-individual-category",
    "section": "technology"
  },
  {
    "name": "Our Approach",
    "apiurl": "/restapi/customtechnologylvl2content/technology/our-approach",
    "url": "/technology/our-approach",
    "template": "new-technology-individual-category",
    "section": "technology"
  },
  {
    "name": "Trinity Consultants Announces CEO Transition",
    "apiurl": "/restapi/customcontent/home/trinity-consultants-announces-ceo-transition",
    "url": "/home/trinity-consultants-announces-ceo-transition",
    "template": "general_content",
    "section": "trinity-consultants-announces-ceo-transition"
  },
  {
    "name": "Waid Environmental Joins Trinity",
    "apiurl": "/restapi/customcontent/home/waid-environmental-joins-trinity",
    "url": "/home/waid-environmental-joins-trinity",
    "template": "general_content",
    "section": "waid-environmental-joins-trinity"
  },
  {
    "name": "State\\Province News",
    "apiurl": "/restapi/customcontent/contact-us/state-province-news",
    "url": "/contact-us/state-province-news",
    "template": "general_content",
    "section": "state\\province-news"
  },
  {
    "name": "Federal News",
    "apiurl": "/restapi/customcontent/contact-us/federal-news",
    "url": "/contact-us/federal-news",
    "template": "general_content",
    "section": "federal-news"
  },
  {
    "name": "Sr. Associates  - Acoustics",
    "apiurl": "/restapi/customcareerslvl3content/careers/available-positions/sr-associates-acoustics-dc",
    "url": "/careers/available-positions/sr-associates-acoustics-dc",
    "template": "new-trinity-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Entry Level Environmental Consultant",
    "apiurl": "/restapi/customcareerslvl3content/careers/available-positions/entry-level-environmental-consultant",
    "url": "/careers/available-positions/entry-level-environmental-consultant",
    "template": "new-trinity-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Senior Associate Engineers",
    "apiurl": "/restapi/customcareerslvl3content/careers/available-positions/senior-associate-engineers-ny",
    "url": "/careers/available-positions/senior-associate-engineers-ny",
    "template": "new-trinity-individual-position",
    "section": "available-positions"
  },
  {
    "name": "Data for CALPUFF and CALMET",
    "apiurl": "/restapi/customcontent/contact-us/data-calpuff-calmet",
    "url": "/contact-us/data-calpuff-calmet",
    "template": "general_content",
    "section": "data-for-calpuff-and-calmet"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customcontent/contact-us/tankesp",
    "url": "/contact-us/tankesp",
    "template": "general_content",
    "section": "tankesp"
  },
  {
    "name": "Data for ISC and CAL3QHCR",
    "apiurl": "/restapi/customcontent/contact-us/data-isc-cal3qhcr",
    "url": "/contact-us/data-isc-cal3qhcr",
    "template": "general_content",
    "section": "data-for-isc-and-cal3qhcr"
  },
  {
    "name": "LFG Fire/Risk Modeling",
    "apiurl": "/restapi/customcontent/contact-us/lfg-fire-risk-modeling",
    "url": "/contact-us/lfg-fire-risk-modeling",
    "template": "general_content",
    "section": "lfg-firerisk-modeling"
  },
  {
    "name": "Specialized Consulting",
    "apiurl": "/restapi/customcontent/contact-us/specialized-consulting",
    "url": "/contact-us/specialized-consulting",
    "template": "general_content",
    "section": "specialized-consulting"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customcontent/contact-us/vaspid",
    "url": "/contact-us/vaspid",
    "template": "general_content",
    "section": "vasdip"
  },
  {
    "name": "Data for AERMOD and AERMET",
    "apiurl": "/restapi/customcontent/contact-us/data-aermod-aermet",
    "url": "/contact-us/data-aermod-aermet",
    "template": "general_content",
    "section": "data-for-aermod-and-aermet"
  },
  {
    "name": "Consulting",
    "apiurl": "/restapi/customconsultingcontent/consulting",
    "url": "/consulting",
    "template": "new-consulting-landing",
    "section": "consulting"
  },
  {
    "name": "Data for ISC and CAL3QHCR",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-isc-cal3qhcr",
    "url": "/software/model-ready-data/data-isc-cal3qhcr",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for ISC and CAL3QHCR",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-isc-cal3qhcr",
    "url": "/software/meteorological-and-terrain-data/data-for-isc-and-cal3qhcr",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for ISC and CAL3QHCR",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-isc-cal3qhcr",
    "url": "/software/model-ready-data/data-for-isc-and-cal3qhcr",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Pricing",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/pricing",
    "url": "/software/model-ready-data/pricing",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/custom-model-development/overview",
    "url": "/software/custom-model-development/overview",
    "template": "new-software-software-sub-categories",
    "section": "custom-development-services"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/custom-model-development/overview",
    "url": "/software/custom-development-services/overview",
    "template": "new-software-software-sub-categories",
    "section": "custom-development-services"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/specialty-modeling-services/overview",
    "url": "/software/specialty-modeling-services/overview",
    "template": "new-software-software-sub-categories",
    "section": "specialty-modeling-services"
  },
  {
    "name": "Cluster Computing",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/high-speed-modeling-services/cluster-computing",
    "url": "/software/high-speed-modeling-services/cluster-computing",
    "template": "new-software-software-sub-categories",
    "section": "high-speed-modeling-services"
  },
  {
    "name": "Meteorological Analysis",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/meteorological-analysis",
    "url": "/software/model-ready-data/meteorological-analysis",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Meteorological Analysis",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/meteorological-analysis",
    "url": "/software/meteorological-and-terrain-data/meteorological-analysis",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Standard Computing",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/high-speed-modeling-services/standard-computing",
    "url": "/software/high-speed-modeling-services/standard-computing",
    "template": "new-software-software-sub-categories",
    "section": "high-speed-modeling-services"
  },
  {
    "name": "Accidental Hazardous Release Modeling",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/specialty-modeling-services/accidental-hazardous-release-modeling",
    "url": "/software/specialty-modeling-services/accidental-hazardous-release-modeling",
    "template": "new-software-software-sub-categories",
    "section": "specialty-modeling-services"
  },
  {
    "name": "Explosion Modeling",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/specialty-modeling-services/explosion-modeling",
    "url": "/software/specialty-modeling-services/explosion-modeling",
    "template": "new-software-software-sub-categories",
    "section": "specialty-modeling-services"
  },
  {
    "name": "Frequently Asked Questions",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/support/faq",
    "url": "/software/support/faq",
    "template": "new-software-software-sub-categories",
    "section": "support"
  },
  {
    "name": "Release Notes",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/support/release-notes",
    "url": "/software/support/release-notes",
    "template": "new-software-software-sub-categories",
    "section": "support"
  },
  {
    "name": "Custom Datasets",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/custom-datasets",
    "url": "/software/model-ready-data/custom-datasets",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Certified Consulting Meteorologists",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/certified-consulting-meteorologists",
    "url": "/software/model-ready-data/certified-consulting-meteorologists",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Certified Consulting Meteorologists",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/certified-consulting-meteorologists",
    "url": "/software/meteorological-and-terrain-data/certified-consulting-meteorologists",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Why Choose Us",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/why-choose-us",
    "url": "/software/model-ready-data/why-choose-us",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Why Choose Us",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/why-choose-us",
    "url": "/software/meteorological-and-terrain-data/why-choose-us",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for CALPUFF and CALMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-calpuff-calmet",
    "url": "/software/model-ready-data/data-calpuff-calmet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for CALPUFF and CALMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-calpuff-calmet",
    "url": "/software/meteorological-and-terrain-data/data-for-calpuff-and-calmet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for CALPUFF and CALMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-calpuff-calmet",
    "url": "/software/model-ready-data/data-for-calpuff-and-calmet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for ADMS",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-adms",
    "url": "/software/model-ready-data/data-adms",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for ADMS",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-adms",
    "url": "/software/meteorological-and-terrain-data/data-for-adms",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for ADMS",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-adms",
    "url": "/software/model-ready-data/data-for-adms",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/high-speed-modeling-services/overview",
    "url": "/software/high-speed-modeling-services/overview",
    "template": "new-software-software-sub-categories",
    "section": "high-speed-modeling-services"
  },
  {
    "name": "Data for AERMOD and AERMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-aermod-aermet",
    "url": "/software/model-ready-data/data-aermod-aermet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for AERMOD and AERMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-aermod-aermet",
    "url": "/software/meteorological-and-terrain-data/data-for-aermod-and-aermet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Data for AERMOD and AERMET",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/data-aermod-aermet",
    "url": "/software/model-ready-data/data-for-aermod-and-aermet",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Air Dispersion Modeling",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/specialty-modeling-services/air-dispersion-modeling",
    "url": "/software/specialty-modeling-services/air-dispersion-modeling",
    "template": "new-software-software-sub-categories",
    "section": "specialty-modeling-services"
  },
  {
    "name": "Maintenance and Support Agreements",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/support/maintenance-support-agreements",
    "url": "/software/support/maintenance-support-agreements",
    "template": "new-software-software-sub-categories",
    "section": "support"
  },
  {
    "name": "Maintenance and Support Agreements",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/support/maintenance-support-agreements",
    "url": "/software/support/maintenance-and-support-agreements",
    "template": "new-software-software-sub-categories",
    "section": "support"
  },
  {
    "name": "Frequently Asked Questions",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/faq",
    "url": "/software/model-ready-data/faq",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Frequently Asked Questions",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/faq",
    "url": "/software/model-ready-data/model-ready-data-faqs",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Frequently Asked Questions",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/model-ready-data/faq",
    "url": "/software/meteorological-and-terrain-data/model-ready-data-faqs",
    "template": "new-software-software-sub-categories",
    "section": "meteorological-and-terrain-data"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customsoftwareservicecontentlvl3/software/global-partners/overview",
    "url": "/software/global-partners/overview",
    "template": "new-software-software-sub-categories",
    "section": "global-partners"
  },
  {
    "name": "Degree",
    "apiurl": "/restapi/customcontent/contact-us/degree",
    "url": "/contact-us/degree",
    "template": "general_content",
    "section": "degree"
  },
  {
    "name": "Diploma",
    "apiurl": "/restapi/customcontent/contact-us/diploma",
    "url": "/contact-us/diploma",
    "template": "general_content",
    "section": "diploma"
  },
  {
    "name": "Specialty & Agro Chemicals America",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/specialty-agro-chemicals-america",
    "url": "/about-us/trade-shows/specialty-agro-chemicals-america",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "NAEM Software, Innovation, & Technology Showcase",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/naem-software-innovation-technology-showcase",
    "url": "/about-us/trade-shows/naem-software-innovation-technology-showcase",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Kirkwood Safety Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/kirkwood-safety-conference",
    "url": "/about-us/trade-shows/kirkwood-safety-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "SCMA Annual Meeting",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/scma-annual-meeting",
    "url": "/about-us/trade-shows/scma-annual-meeting",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Enablon - SPF World 2020",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/enablon---spf-world-2020",
    "url": "/about-us/trade-shows/enablon---spf-world-2020",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "SOT Annual Meeting and ToxExpo",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/sot-annual-meeting-and-toxexpo",
    "url": "/about-us/trade-shows/sot-annual-meeting-and-toxexpo",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Spring CAPCA Workshop & Forum",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/spring-capca-workshop-forum",
    "url": "/about-us/trade-shows/spring-capca-workshop-forum",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ASHP Midyear Clinical Meeting & Exhibiton",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ashp-midyear-clinical-meeting-exhibiton",
    "url": "/about-us/trade-shows/ashp-midyear-clinical-meeting-exhibiton",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "OSBA Ohio Environment, Energy & Resources Law Seminar",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/osba-ohio-environment-energy-resources-law-seminar",
    "url": "/about-us/trade-shows/osba-ohio-environment-energy-resources-law-seminar",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "EHS Seminar & Tradeshow",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ehs-seminar-tradeshow",
    "url": "/about-us/trade-shows/ehs-seminar-tradeshow",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Mid-Atlantic Buildings & Facilities Management Trade Show & Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/mid-atlantic-buildings-facilities-management-trade-show-conference",
    "url": "/about-us/trade-shows/mid-atlantic-buildings-facilities-management-trade-show-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "NAEM EHS & Sustainability Management Forum",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/naem-ehs-sustainability-management-forum",
    "url": "/about-us/trade-shows/naem-ehs-sustainability-management-forum",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "American College of Toxicology (ACT) Annual Meeting",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/american-college-of-toxicology-(act)-annual-meeting",
    "url": "/about-us/trade-shows/american-college-of-toxicology-(act)-annual-meeting",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "IPSE Canada Symposium",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ipse-canada-symposium",
    "url": "/about-us/trade-shows/ipse-canada-symposium",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Georgia Environmental Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/georgia-environmental-conference",
    "url": "/about-us/trade-shows/georgia-environmental-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AIHA - Carolinas Local Section",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/aiha---carolinas-local-section",
    "url": "/about-us/trade-shows/aiha---carolinas-local-section",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE - CaSA Life Sciences Technology Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe---casa-life-sciences-technology-conference",
    "url": "/about-us/trade-shows/ispe---casa-life-sciences-technology-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AWMA Niagara Frontier Section",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/awma-niagara-frontier-section",
    "url": "/about-us/trade-shows/awma-niagara-frontier-section",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "HPAPI Summit",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/hpapi-summit",
    "url": "/about-us/trade-shows/hpapi-summit",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Michigan Environmental Compliance Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/michigan-environmental-compliance-conference",
    "url": "/about-us/trade-shows/michigan-environmental-compliance-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Chemistry Council of NJ Annual Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/chemistry-council-of-nj-annual-conference",
    "url": "/about-us/trade-shows/chemistry-council-of-nj-annual-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Environmental Federation of Oklahoma",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/environmental-federation-of-oklahoma",
    "url": "/about-us/trade-shows/environmental-federation-of-oklahoma",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Texas Environmental Superconference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/texas-environmental-superconference",
    "url": "/about-us/trade-shows/texas-environmental-superconference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AAPS PharmSci 360",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/aaps-pharmsci-360",
    "url": "/about-us/trade-shows/aaps-pharmsci-360",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Kentucky Environmental Permitting and Reporting Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/kentucky-environmental-permitting-and-reporting",
    "url": "/about-us/trade-shows/kentucky-environmental-permitting-and-reporting",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AFPM Summit",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/2021-afpm-summit",
    "url": "/about-us/trade-shows/2021-afpm-summit",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE Delaware Valley Chapter",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe-delaware-valley-chapter",
    "url": "/about-us/trade-shows/ispe-delaware-valley-chapter",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Manufacturers' Education Council (MEC)",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/manufacturers'-education-council-(mec)",
    "url": "/about-us/trade-shows/manufacturers'-education-council-(mec)",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "SETAC North America Annual Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/setac-north-america-annua",
    "url": "/about-us/trade-shows/setac-north-america-annua",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Missouri Waste Control Coalition",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/missouri-waste-control-coalition",
    "url": "/about-us/trade-shows/missouri-waste-control-coalition",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "InfoComm",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/infocomm",
    "url": "/about-us/trade-shows/infocomm",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE Vendor Night Outdoors at The Park in the Eureka Building",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe-vendor-night-outdoors-at-the-park-in-the-eureka-building",
    "url": "/about-us/trade-shows/ispe-vendor-night-outdoors-at-the-park-in-the-eureka-building",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AIChE Spring Meeting and Global Congress on Process Safety (GCPS)",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/2021-aiche-spring-meeting-and-gcps",
    "url": "/about-us/trade-shows/2021-aiche-spring-meeting-and-gcps",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Council of Industrial Boiler Owners Annual Meeting",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/cibo-combined-boiler-emissions-virtual-conference",
    "url": "/about-us/trade-shows/cibo-combined-boiler-emissions-virtual-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE Annual Meeting and Expo",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe-annual-meeting-and-expo",
    "url": "/about-us/trade-shows/ispe-annual-meeting-and-expo",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "PDAC",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/pdac",
    "url": "/about-us/trade-shows/pdac",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Virginia EHS Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/virginia-ehs-conference",
    "url": "/about-us/trade-shows/virginia-ehs-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Northeast Industrial Hygiene Conference & Expo",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/northeast-industrial-hygiene-conference-expo",
    "url": "/about-us/trade-shows/northeast-industrial-hygiene-conference-expo",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Extractables & Leachables Summit",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/extractables-leachables-summit",
    "url": "/about-us/trade-shows/extractables-leachables-summit",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AFPM Environmental Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/afpm",
    "url": "/about-us/trade-shows/afpm",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "API Inspection and Mechanical Integrity Summit",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/api-storage-tank-conference-and-safe-tank-entry-workshop",
    "url": "/about-us/trade-shows/api-storage-tank-conference-and-safe-tank-entry-workshop",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Kansas Environmental Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/kansas-environmental-conference",
    "url": "/about-us/trade-shows/kansas-environmental-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "IEEE-IAS/PCA Cement Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ieee-ias-pca-cement-conference-2021",
    "url": "/about-us/trade-shows/ieee-ias-pca-cement-conference-2021",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Cority Connect Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/cority-connect-conference-2021",
    "url": "/about-us/trade-shows/cority-connect-conference-2021",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "South Carolina Manufacturers Alliance (SCMA) Annual Environmental Meeting",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/south-carolina-manufacturers-alliance-(scma)-annual-environmental-meeting",
    "url": "/about-us/trade-shows/south-carolina-manufacturers-alliance-(scma)-annual-environmental-meeting",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "TCEQ Environmental Trade Fair and Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/tceq-environmental-trade-fair-and-conference-etfc",
    "url": "/about-us/trade-shows/tceq-environmental-trade-fair-and-conference-etfc",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "MEC Annual Business & Industry's Sustainability and Environmental Health & Safety Symposium",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/mec-annual-business-industry's-sustainability-and-environmental-health-safety-symposium",
    "url": "/about-us/trade-shows/mec-annual-business-industry's-sustainability-and-environmental-health-safety-symposium",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "CPhI North America",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/cphi-north-america",
    "url": "/about-us/trade-shows/cphi-north-america",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Interphex",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/interphex-conference",
    "url": "/about-us/trade-shows/interphex-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AWMA Annual Conference & Exhibition",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/awma-annual-conference-exhibition",
    "url": "/about-us/trade-shows/awma-annual-conference-exhibition",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "HansonWade World ADC San Diego",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/hanson-wade-world-adc-europe-2021---virtual",
    "url": "/about-us/trade-shows/hanson-wade-world-adc-europe-2021---virtual",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Smithers Extractables & Leachables",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/smithers-extractables-leachables-europe",
    "url": "/about-us/trade-shows/smithers-extractables-leachables-europe",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Midwest Environmental Compliance Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/midwest-environmental-compliance-conference",
    "url": "/about-us/trade-shows/midwest-environmental-compliance-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "IA/IL Safety Council Professional Development Conference & Expo",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ia-il-safety-council-professional-development-conference-expo",
    "url": "/about-us/trade-shows/ia-il-safety-council-professional-development-conference-expo",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Louisiana AWMA",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/louisiana-awma",
    "url": "/about-us/trade-shows/louisiana-awma",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Manufacturers' Education Council (MEC) Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/manufacturers'-education-council-(mec)-conference",
    "url": "/about-us/trade-shows/manufacturers'-education-council-(mec)-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE Boston Product Show",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe-boston-product-show",
    "url": "/about-us/trade-shows/ispe-boston-product-show",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Florida Environmental Permitting Summer School",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/florida-environmental-permitting-summer-school",
    "url": "/about-us/trade-shows/florida-environmental-permitting-summer-school",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "IFT First Annual Event and Expo",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ift-first-annual-event-and-expo",
    "url": "/about-us/trade-shows/ift-first-annual-event-and-expo",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "GFL AWMA/GVC NYWEA Annual Joint Seminar",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/gfl-awma-gvc-nywea-annual-joint-seminar",
    "url": "/about-us/trade-shows/gfl-awma-gvc-nywea-annual-joint-seminar",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "ISPE Singapore Affiliate Conference & Exhibition",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ispe-singapore-affiliate-conference-exhibition",
    "url": "/about-us/trade-shows/ispe-singapore-affiliate-conference-exhibition",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "North Carolina Environmental, Energy, and Health & Safety School",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/north-carolina-environmental-energy-and-health-safety-school",
    "url": "/about-us/trade-shows/north-carolina-environmental-energy-and-health-safety-school",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "AIHce EXP",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/aihce-exp",
    "url": "/about-us/trade-shows/aihce-exp",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "NCASI Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/ncasi",
    "url": "/about-us/trade-shows/ncasi",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "SupplySide West & Food Ingredients North America",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/supplyside-west-food-ingredients-north-america",
    "url": "/about-us/trade-shows/supplyside-west-food-ingredients-north-america",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "NAEM Sustainability Impact Conference Watch Party",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/naem-watch-party-atlanta-ga",
    "url": "/about-us/trade-shows/naem-watch-party-atlanta-ga",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Fall CAPCA Workshop & Forum",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/capca-virtual-conference",
    "url": "/about-us/trade-shows/capca-virtual-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "Indiana Environmental Conference",
    "apiurl": "/restapi/customcontent/about-us/trade-shows/indiana-chamber-environmental-conference",
    "url": "/about-us/trade-shows/indiana-chamber-environmental-conference",
    "template": "trade-shows",
    "section": "trade-shows-events"
  },
  {
    "name": "South",
    "apiurl": "/restapi/customcontent/contact-us/south",
    "url": "/contact-us/south",
    "template": "general_content",
    "section": "south"
  },
  {
    "name": "North",
    "apiurl": "/restapi/customcontent/contact-us/north",
    "url": "/contact-us/north",
    "template": "general_content",
    "section": "north"
  },
  {
    "name": "Request a Software Demo",
    "apiurl": "/restapi/customcontent/software-demo",
    "url": "/software-demo",
    "template": "general_content",
    "section": "request-a-software-demo"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/locations/australia-location/australia",
    "url": "/locations/australia-location/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/locations/australia-location/australia",
    "url": "/locations/australia/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/north-america",
    "url": "/default-mainlocation/location/north-america",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "North America",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/north-america",
    "url": "/location/north-america",
    "template": "general_content",
    "section": "north-america"
  },
  {
    "name": "Ireland",
    "apiurl": "/restapi/customcontent/locations/europe/ireland",
    "url": "/locations/europe/ireland",
    "template": "general_content",
    "section": "ireland"
  },
  {
    "name": "United Kingdom",
    "apiurl": "/restapi/customcontent/locations/europe/europe-country",
    "url": "/locations/europe/europe-country",
    "template": "general_content",
    "section": "united-kingdom"
  },
  {
    "name": "Canada",
    "apiurl": "/restapi/customcontent/locations/north-america/canada",
    "url": "/locations/north-america/canada",
    "template": "general_content",
    "section": "canada"
  },
  {
    "name": "Canada",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica/canada",
    "url": "/default-mainlocation/northamerica/canada",
    "template": "general_content",
    "section": "canada"
  },
  {
    "name": "Canada",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica/canada",
    "url": "/northamerica/canada",
    "template": "general_content",
    "section": "canada"
  },
  {
    "name": "United States",
    "apiurl": "/restapi/customcontent/locations/north-america/united-states",
    "url": "/locations/north-america/united-states",
    "template": "general_content",
    "section": "united-states"
  },
  {
    "name": "United States",
    "apiurl": "/restapi/customcontent/locations/north-america/united-states",
    "url": "/location/north-america/united-states",
    "template": "general_content",
    "section": "united-states"
  },
  {
    "name": "New Zealand",
    "apiurl": "/restapi/customcontent/locations/australia-location/new-zealand",
    "url": "/locations/australia-location/new-zealand",
    "template": "general_content",
    "section": "new-zealand"
  },
  {
    "name": "New Zealand",
    "apiurl": "/restapi/customcontent/locations/australia-location/new-zealand",
    "url": "/locations/australia/new-zealand",
    "template": "general_content",
    "section": "new-zealand"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/china",
    "url": "/default-mainlocation/location/china",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/china",
    "url": "/location/china",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "United States",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica/unitedstates",
    "url": "/default-mainlocation/northamerica/unitedstates",
    "template": "general_content",
    "section": "united-states"
  },
  {
    "name": "United States",
    "apiurl": "/restapi/customcontent/default-mainlocation/northamerica/unitedstates",
    "url": "/northamerica/unitedstates",
    "template": "general_content",
    "section": "united-states"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/locations/asia/china",
    "url": "/locations/asia/china",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/locations/asia/china",
    "url": "/locations/asia/asia-pacific-country",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customcontent/locations/asia/china",
    "url": "/locations/asia-pacific/asia-pacific-country",
    "template": "general_content",
    "section": "china"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/australia",
    "url": "/default-mainlocation/location/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "Australia",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/australia",
    "url": "/location/australia",
    "template": "general_content",
    "section": "australia"
  },
  {
    "name": "United Kingdom",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/united-kingdom",
    "url": "/default-mainlocation/location/united-kingdom",
    "template": "general_content",
    "section": "united-kingdom"
  },
  {
    "name": "United Kingdom",
    "apiurl": "/restapi/customcontent/default-mainlocation/location/united-kingdom",
    "url": "/location/united-kingdom",
    "template": "general_content",
    "section": "united-kingdom"
  },
  {
    "name": "India",
    "apiurl": "/restapi/customcontent/locations/asia/india",
    "url": "/locations/asia/india",
    "template": "general_content",
    "section": "india"
  },
  {
    "name": "Contact Information",
    "apiurl": "/restapi/customcontent/contact-us/contact-information",
    "url": "/contact-us/contact-information",
    "template": "general_content",
    "section": "contact-information"
  },
  {
    "name": "Request a Trial License",
    "apiurl": "/restapi/customcontent/request-a-trial-license",
    "url": "/request-a-trial-license",
    "template": "general_content",
    "section": "request-a-trial-license"
  },
  {
    "name": "VideoIcon",
    "apiurl": "/restapi/customcontent/contact-us/videoicon",
    "url": "/contact-us/videoicon",
    "template": "general_content",
    "section": "videoicon"
  },
  {
    "name": "NewsIcon",
    "apiurl": "/restapi/customcontent/contact-us/newsicon",
    "url": "/contact-us/newsicon",
    "template": "general_content",
    "section": "newsicon"
  },
  {
    "name": "TrainingIcon",
    "apiurl": "/restapi/customcontent/contact-us/trainingicon",
    "url": "/contact-us/trainingicon",
    "template": "general_content",
    "section": "trainingicon"
  },
  {
    "name": "PDFIcon",
    "apiurl": "/restapi/customcontent/contact-us/pdficon",
    "url": "/contact-us/pdficon",
    "template": "general_content",
    "section": "pdficon"
  },
  {
    "name": "ProductIcon",
    "apiurl": "/restapi/customcontent/contact-us/producticon",
    "url": "/contact-us/producticon",
    "template": "general_content",
    "section": "producticon"
  },
  {
    "name": "MediaIcon",
    "apiurl": "/restapi/customcontent/contact-us/mediaicon",
    "url": "/contact-us/mediaicon",
    "template": "general_content",
    "section": "mediaicon"
  },
  {
    "name": "TradeShowIcon",
    "apiurl": "/restapi/customcontent/contact-us/trandshowicon",
    "url": "/contact-us/trandshowicon",
    "template": "general_content",
    "section": "tradeshowicon"
  },
  {
    "name": "BREEZE Software Support",
    "apiurl": "/restapi/customcontent/contact-us/breeze-software-support",
    "url": "/contact-us/breeze-software-support",
    "template": "general_content",
    "section": "breeze-software-support"
  },
  {
    "name": "Request Software Quote",
    "apiurl": "/restapi/customcontent/request-software-quote",
    "url": "/request-software-quote",
    "template": "general_content",
    "section": "request-software-quote"
  },
  {
    "name": "Creating Animations with Incident Analyst Results using BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/creating-animations-with-incident-analyst-results-using-breeze-3d-analyst",
    "url": "/software/hazard/incident-analyst/creating-animations-with-incident-analyst-results-using-breeze-3d-analyst",
    "template": "general_content",
    "section": "creating-animations-with-incident-analyst-results-using-breeze-3d-analyst"
  },
  {
    "name": "TankESP Software Product Suite Version 5.0 Overview Tutorial",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-software-product-suite-version-5.0-overview-tutorial",
    "url": "/software/tanks/tankesp/tankesp-software-product-suite-version-5.0-overview-tutorial",
    "template": "general_content",
    "section": "tankesp-software-product-suite-version-5.0-overview-tutorial"
  },
  {
    "name": "TankESP Software Product Suite Version 5.0 Overview Tutorial",
    "apiurl": "/restapi/customcontent/software/tanks/tankesp/tankesp-software-product-suite-version-5.0-overview-tutorial",
    "url": "/software/tank-emissions/tankesp/tankesp-software-product-suite-version-5.0-overview-tutorial",
    "template": "general_content",
    "section": "tankesp-software-product-suite-version-5.0-overview-tutorial"
  },
  {
    "name": "Import 3D Data Files - ExDAM 3D Extend Module",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/import-3d-data-files---exdam-3d-extend-module",
    "url": "/software/explosion/exdam/import-3d-data-files---exdam-3d-extend-module",
    "template": "general_content",
    "section": "import-3d-data-files--exdam-3d-extend-module"
  },
  {
    "name": "BREEZE AERSCREEN Demo",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/breeze-aerscreen-demo",
    "url": "/software/dispersion/aerscreen/breeze-aerscreen-demo",
    "template": "general_content",
    "section": "breeze-aerscreen-demo"
  },
  {
    "name": "Tutorial: Creating Roadway Sources Using Drawing Tools in BREEZE AERMOD",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-creating-roadway-sources-using-drawing-tools-in-breeze-aermod",
    "url": "/software/dispersion/aermod/tutorial-creating-roadway-sources-using-drawing-tools-in-breeze-aermod",
    "template": "general_content",
    "section": "tutorial:-creating-roadway-sources-using-drawing-tools-in-breeze-aermod"
  },
  {
    "name": "03: Quickly Define Meteorological Conditions and Levels of Concern – BREEZE Incident Analyst ",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/03-quickly-define-meteorological-conditions-and-levels-of-concern-breeze-incident-analyst",
    "url": "/software/hazard/incident-analyst/03-quickly-define-meteorological-conditions-and-levels-of-concern-breeze-incident-analyst",
    "template": "general_content",
    "section": "03:-quickly-define-meteorological-conditions-and-levels-of-concern-–-breeze-incident-analyst-"
  },
  {
    "name": "05: Analyze and Visualize Model Results – BREEZE Incident Analyst ",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/05-analyze-and-visualize-model-results-breeze-incident-analyst",
    "url": "/software/hazard/incident-analyst/05-analyze-and-visualize-model-results-breeze-incident-analyst",
    "template": "general_content",
    "section": "05:-analyze-and-visualize-model-results-–-breeze-incident-analyst-"
  },
  {
    "name": "Animating Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/animating-gridded-meteorological-data",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/animating-gridded-meteorological-data",
    "template": "general_content",
    "section": "animating-gridded-meteorological-data"
  },
  {
    "name": "Animating Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/animating-gridded-meteorological-data",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/animating-gridded-meteorological-data",
    "template": "general_content",
    "section": "animating-gridded-meteorological-data"
  },
  {
    "name": "Animating Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/animating-gridded-meteorological-data",
    "url": "/software/air-dispersion---data/3d-analyst/animating-gridded-meteorological-data",
    "template": "general_content",
    "section": "animating-gridded-meteorological-data"
  },
  {
    "name": "BREEZE SCREEN3 Overview",
    "apiurl": "/restapi/customcontent/software/dispersion/screen3/breeze-screen3-overview",
    "url": "/software/dispersion/screen3/breeze-screen3-overview",
    "template": "general_content",
    "section": "breeze-screen3-overview"
  },
  {
    "name": "Sample Grid Results - BREEZE ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/sample-grid-results---breeze-exdam",
    "url": "/software/explosion/exdam/sample-grid-results---breeze-exdam",
    "template": "general_content",
    "section": "sample-grid-results--breeze-exdam"
  },
  {
    "name": "BREEZE Incident Analyst: Results Analysis - Plume Dispersion",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "url": "/software/hazard/incident-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "template": "general_content",
    "section": "breeze-incident-analyst:-results-analysis--plume-dispersion"
  },
  {
    "name": "Tutorial: How to import data from a spreadsheet into BREEZE AERMOD",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-how-to-import-data-from-a-spreadsheet-into-breeze-aermod",
    "url": "/software/dispersion/aermod/tutorial-how-to-import-data-from-a-spreadsheet-into-breeze-aermod",
    "template": "general_content",
    "section": "tutorial:-how-to-import-data-from-a-spreadsheet-into-breeze-aermod"
  },
  {
    "name": "Phase I - Structure Development",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/phase-i---structure-development",
    "url": "/software/explosion/exdam/phase-i---structure-development",
    "template": "general_content",
    "section": "phase-i--structure-development"
  },
  {
    "name": "West, Texas Explosion Analysis",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/west-texas-explosion-analysis",
    "url": "/software/explosion/exdam/west-texas-explosion-analysis",
    "template": "general_content",
    "section": "west,-texas-explosion-analysis"
  },
  {
    "name": "Import Structures from Text Files (.txt) - 3D Extend Module",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/import-structures-from-text-files-(.txt)---3d-extend-module",
    "url": "/software/explosion/exdam/import-structures-from-text-files-(.txt)---3d-extend-module",
    "template": "general_content",
    "section": "import-structures-from-text-files-(.txt)--3d-extend-module"
  },
  {
    "name": "04: Multiple Ways to Add Sources and Receptors – BREEZE Incident Analyst ",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/04-multiple-ways-to-add-sources-and-receptors-breeze-incident-analyst",
    "url": "/software/hazard/incident-analyst/04-multiple-ways-to-add-sources-and-receptors-breeze-incident-analyst",
    "template": "general_content",
    "section": "04:-multiple-ways-to-add-sources-and-receptors-–-breeze-incident-analyst-"
  },
  {
    "name": "BREEZE AERSCREEN Overview",
    "apiurl": "/restapi/customcontent/software/dispersion/aerscreen/breeze-aerscreen-overview",
    "url": "/software/dispersion/aerscreen/breeze-aerscreen-overview",
    "template": "general_content",
    "section": "breeze-aerscreen-overview"
  },
  {
    "name": "Tutorial: Importing Base Maps in BREEZE AERMOD",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-importing-base-maps-in-breeze-aermod",
    "url": "/software/dispersion/aermod/tutorial-importing-base-maps-in-breeze-aermod",
    "template": "general_content",
    "section": "tutorial:-importing-base-maps-in-breeze-aermod"
  },
  {
    "name": "02: Editing an Existing Building Structure – BREEZE ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/02-editing-an-existing-building-structure-breeze-exdam",
    "url": "/software/explosion/exdam/02-editing-an-existing-building-structure-breeze-exdam",
    "template": "general_content",
    "section": "02:-editing-an-existing-building-structure-–-breeze-exdam"
  },
  {
    "name": "01: Getting Started – BREEZE Incident Analyst",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/01-getting-started-breeze-incident-analyst",
    "url": "/software/hazard/incident-analyst/01-getting-started-breeze-incident-analyst",
    "template": "general_content",
    "section": "01:-getting-started-–-breeze-incident-analyst"
  },
  {
    "name": "Working with Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/working-with-gridded-meteorological-data",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/working-with-gridded-meteorological-data",
    "template": "general_content",
    "section": "working-with-gridded-meteorological-data"
  },
  {
    "name": "Working with Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/working-with-gridded-meteorological-data",
    "url": "/software/air-dispersion---data/3d-analyst/working-with-gridded-meteorological-data",
    "template": "general_content",
    "section": "working-with-gridded-meteorological-data"
  },
  {
    "name": "Working with Gridded Meteorological Data",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/working-with-gridded-meteorological-data",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/working-with-gridded-meteorological-data",
    "template": "general_content",
    "section": "working-with-gridded-meteorological-data"
  },
  {
    "name": "BREEZE AERMOD Overview Tutorial",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/breeze-aermod-overview-tutorial",
    "url": "/software/dispersion/aermod/breeze-aermod-overview-tutorial",
    "template": "general_content",
    "section": "breeze-aermod-overview-tutorial"
  },
  {
    "name": "BREEZE CALPUFF: Results Analysis - Puff Dispersion of Pollutants",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "template": "general_content",
    "section": "breeze-calpuff:-results-analysis--puff-dispersion-of-pollutants"
  },
  {
    "name": "BREEZE CALPUFF: Results Analysis - Puff Dispersion of Pollutants",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "url": "/software/air-dispersion---data/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "template": "general_content",
    "section": "breeze-calpuff:-results-analysis--puff-dispersion-of-pollutants"
  },
  {
    "name": "BREEZE CALPUFF: Results Analysis - Puff Dispersion of Pollutants",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/breeze-calpuff-results-analysis---puff-dispersion-of-pollutants",
    "template": "general_content",
    "section": "breeze-calpuff:-results-analysis--puff-dispersion-of-pollutants"
  },
  {
    "name": "Tutorial: Creating Animations in BREEZE 3D Analyst",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-creating-animations-in-breeze-3d-analyst",
    "url": "/software/dispersion/aermod/tutorial-creating-animations-in-breeze-3d-analyst",
    "template": "general_content",
    "section": "tutorial:-creating-animations-in-breeze-3d-analyst"
  },
  {
    "name": "01: Build a Basic Building Structure – BREEZE ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/01-build-a-basic-building-structure-breeze-exdam",
    "url": "/software/explosion/exdam/01-build-a-basic-building-structure-breeze-exdam",
    "template": "general_content",
    "section": "01:-build-a-basic-building-structure-–-breeze-exdam"
  },
  {
    "name": "Tutorial: Importing Buildings in BREEZE AERMOD",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-importing-buildings-in-breeze-aermod",
    "url": "/software/dispersion/aermod/tutorial-importing-buildings-in-breeze-aermod",
    "template": "general_content",
    "section": "tutorial:-importing-buildings-in-breeze-aermod"
  },
  {
    "name": "BREEZE Incident Analyst: Results Analysis - Plume Dispersion",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "url": "/software/air-dispersion---data-visualization/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "template": "general_content",
    "section": "breeze-incident-analyst:-results-analysis--plume-dispersion"
  },
  {
    "name": "BREEZE Incident Analyst: Results Analysis - Plume Dispersion",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "url": "/software/air-dispersion-data-visualization/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "template": "general_content",
    "section": "breeze-incident-analyst:-results-analysis--plume-dispersion"
  },
  {
    "name": "BREEZE Incident Analyst: Results Analysis - Plume Dispersion",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "url": "/software/air-dispersion---data/3d-analyst/breeze-incident-analyst-results-analysis---plume-dispersion",
    "template": "general_content",
    "section": "breeze-incident-analyst:-results-analysis--plume-dispersion"
  },
  {
    "name": "Introduction to BREEZE ExDAM 9.0",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/introduction-to-breeze-exdam-9.0",
    "url": "/software/explosion/exdam/introduction-to-breeze-exdam-9.0",
    "template": "general_content",
    "section": "introduction-to-breeze-exdam-9.0"
  },
  {
    "name": "Building Structure Demonstration - Lakeshore Condo Scenario",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/building-structure-demonstration---lakeshore-condo-scenario",
    "url": "/software/explosion/exdam/building-structure-demonstration---lakeshore-condo-scenario",
    "template": "general_content",
    "section": "building-structure-demonstration--lakeshore-condo-scenario"
  },
  {
    "name": "Introduction to the Wind Rose, Data Rose, and Pollution Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "url": "/software/air-dispersion---data-visualization/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "template": "general_content",
    "section": "introduction-to-the-wind-rose,-data-rose,-and-pollution-rose"
  },
  {
    "name": "Introduction to the Wind Rose, Data Rose, and Pollution Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "url": "/software/air-dispersion---data/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "template": "general_content",
    "section": "introduction-to-the-wind-rose,-data-rose,-and-pollution-rose"
  },
  {
    "name": "Introduction to the Wind Rose, Data Rose, and Pollution Rose",
    "apiurl": "/restapi/customcontent/software/air-dispersion---data-visualization/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "url": "/software/air-dispersion-data-visualization/metview/introduction-to-the-wind-rose-data-rose-and-pollution-rose",
    "template": "general_content",
    "section": "introduction-to-the-wind-rose,-data-rose,-and-pollution-rose"
  },
  {
    "name": "BREEZE ExDAM Overview: Petroleum Plant Siting",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/breeze-exdam-overview-petroleum-plant-siting",
    "url": "/software/explosion/exdam/breeze-exdam-overview-petroleum-plant-siting",
    "template": "general_content",
    "section": "breeze-exdam-overview:-petroleum-plant-siting"
  },
  {
    "name": "Import Structures from Esri Shapefiles - ExDAM 3D Extend Module",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/import-structures-from-esri-shapefiles---exdam-3d-extend-module",
    "url": "/software/explosion/exdam/import-structures-from-esri-shapefiles---exdam-3d-extend-module",
    "template": "general_content",
    "section": "import-structures-from-esri-shapefiles--exdam-3d-extend-module"
  },
  {
    "name": "Create Detailed 3D Structures with XY Block Contour Function - ExDAM 3D Extend Module",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/create-detailed-3d-structures-with-xy-block-contour-function---exdam-3d-extend-module",
    "url": "/software/explosion/exdam/create-detailed-3d-structures-with-xy-block-contour-function---exdam-3d-extend-module",
    "template": "general_content",
    "section": "create-detailed-3d-structures-with-xy-block-contour-function--exdam-3d-extend-module"
  },
  {
    "name": "Introduction to BREEZE Incident Analyst 3.0",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/introduction-to-breeze-incident-analyst-3.0",
    "url": "/software/hazard/incident-analyst/introduction-to-breeze-incident-analyst-3.0",
    "template": "general_content",
    "section": "introduction-to-breeze-incident-analyst-3.0"
  },
  {
    "name": "Overview",
    "apiurl": "/restapi/customcontent/software/health-risk/risk-analyst/overview",
    "url": "/software/health-risk/risk-analyst/overview",
    "template": "general_content",
    "section": "overview"
  },
  {
    "name": "Phase II and III - Grid, Model Run, and Results Analysis",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/phase-ii-and-iii---grid-model-run-and-results-analysis",
    "url": "/software/explosion/exdam/phase-ii-and-iii---grid-model-run-and-results-analysis",
    "template": "general_content",
    "section": "phase-ii-and-iii--grid,-model-run,-and-results-analysis"
  },
  {
    "name": "Tutorial: Gridded Plume Downwash",
    "apiurl": "/restapi/customcontent/software/dispersion/aermod/tutorial-gridded-plume-downwash",
    "url": "/software/dispersion/aermod/tutorial-gridded-plume-downwash",
    "template": "general_content",
    "section": "tutorial:-gridded-plume-downwash"
  },
  {
    "name": "02: How to Select and Add Chemicals – BREEZE Incident Analyst",
    "apiurl": "/restapi/customcontent/software/hazard/incident-analyst/02-how-to-select-and-add-chemicals-breeze-incident-analyst",
    "url": "/software/hazard/incident-analyst/02-how-to-select-and-add-chemicals-breeze-incident-analyst",
    "template": "general_content",
    "section": "02:-how-to-select-and-add-chemicals-–-breeze-incident-analyst"
  },
  {
    "name": "03: Adding a Vapor Cloud and Sample Grid – BREEZE ExDAM",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/03-adding-a-vapor-cloud-and-sample-grid-breeze-exdam",
    "url": "/software/explosion/exdam/03-adding-a-vapor-cloud-and-sample-grid-breeze-exdam",
    "template": "general_content",
    "section": "03:-adding-a-vapor-cloud-and-sample-grid-–-breeze-exdam"
  },
  {
    "name": "Building Structure Demonstration - City Scenario",
    "apiurl": "/restapi/customcontent/software/explosion/exdam/building-structure-demonstration---city-scenario",
    "url": "/software/explosion/exdam/building-structure-demonstration---city-scenario",
    "template": "general_content",
    "section": "building-structure-demonstration--city-scenario"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffinges/focus-area",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffing/focus-area",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffing/focus-areas",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffings/focus-area",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffinges/focus-area",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffing/focus-area",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffing/focus-areas",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Focus Areas",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/focus-area",
    "url": "/staffings/focus-area",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffinges/search-options",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/ehs-recruiters/options",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffings/search-options",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffing/options",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffing/search-options",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffinges/search-options",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/ehs-recruiters/options",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffings/search-options",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffing/options",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Options",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-options",
    "url": "/staffing/search-options",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Have questions? Contact Us.",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/contactus",
    "url": "/staffinges/contactus",
    "template": "",
    "section": "staffing"
  },
  {
    "name": "Have questions? Contact Us.",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/contactus",
    "url": "/contactus",
    "template": "",
    "section": "staffing"
  },
  {
    "name": "Have questions? Contact Us.",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/contactus",
    "url": "/staffinges/contactus",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Have questions? Contact Us.",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/contactus",
    "url": "/contactus",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffinges/search-benefits",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffings/search-benefits",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffing/search-benefits",
    "template": "new-staffing-common",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffinges/search-benefits",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffings/search-benefits",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Search Benefits",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/search-benefits",
    "url": "/staffing/search-benefits",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffinges/submit-job-request",
    "template": "new-staffing-submit-a-job-request",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffings/submit-job-request",
    "template": "new-staffing-submit-a-job-request",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffing/submit-job-request",
    "template": "new-staffing-submit-a-job-request",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/ehs-recruiters/submit-a-job-request",
    "template": "new-staffing-submit-a-job-request",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/ehs-recruiters/submit-job-request",
    "template": "new-staffing-submit-a-job-request",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffinges/submit-job-request",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffings/submit-job-request",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/staffing/submit-job-request",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/ehs-recruiters/submit-a-job-request",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Submit a Job Request",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/submit-job-request",
    "url": "/ehs-recruiters/submit-job-request",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffinges/available-positions",
    "template": "new-staffing-available-positions",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffings/available-positions",
    "template": "new-staffing-available-positions",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffing/available-positions",
    "template": "new-staffing-available-positions",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/ehs-recruiters/available-positions",
    "template": "new-staffing-available-positions",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffinges/available-positions",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffings/available-positions",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/staffing/available-positions",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "Available Positions",
    "apiurl": "/restapi/customstaffinglvl2content/staffinges/available-positions",
    "url": "/ehs-recruiters/available-positions",
    "template": "stafﬁng-category",
    "section": "staffing"
  },
  {
    "name": "About Us",
    "apiurl": "/restapi/customaboutuscontent/about-us",
    "url": "/about-us",
    "template": "new-about-us",
    "section": "about-us"
  },
  {
    "name": "IT",
    "apiurl": "/restapi/customcontent/contact-us/it",
    "url": "/contact-us/it",
    "template": "general_content",
    "section": "it"
  },
  {
    "name": "Software",
    "apiurl": "/restapi/customcontent/contact-us/software",
    "url": "/contact-us/software",
    "template": "general_content",
    "section": "software"
  },
  {
    "name": "Marketing",
    "apiurl": "/restapi/customcontent/contact-us/marketing",
    "url": "/contact-us/marketing",
    "template": "general_content",
    "section": "marketing"
  },
  {
    "name": "HR",
    "apiurl": "/restapi/customcontent/contact-us/hr",
    "url": "/contact-us/hr",
    "template": "general_content",
    "section": "hr"
  },
  {
    "name": "Finance",
    "apiurl": "/restapi/customcontent/contact-us/finance",
    "url": "/contact-us/finance",
    "template": "general_content",
    "section": "finance"
  },
  {
    "name": "Trinity Supports Aerospace Leader",
    "apiurl": "/restapi/customcontent/home/trinity-supports-aerospace-leader",
    "url": "/home/trinity-supports-aerospace-leader",
    "template": "general_content",
    "section": "trinity-supports-aerospace-leader"
  },
  {
    "name": "Water Quality Monitoring Solution Supports",
    "apiurl": "/restapi/customcontent/home/water-quality-monitoring-solution-supports",
    "url": "/home/water-quality-monitoring-solution-supports",
    "template": "general_content",
    "section": "water-quality-monitoring-solution-supports"
  },
  {
    "name": "Annual Compliance Reporting",
    "apiurl": "/restapi/customcontent/home/annual-compliance-reporting",
    "url": "/home/annual-compliance-reporting",
    "template": "general_content",
    "section": "annual-compliance-reporting"
  },
  {
    "name": "AIHA Guidance Document Guides USP <800>",
    "apiurl": "/restapi/customcontent/home/aiha-guidance-document-guides-usp-800",
    "url": "/home/aiha-guidance-document-guides-usp-800",
    "template": "general_content",
    "section": "aiha-guidance-document-guides-usp-<800>"
  },
  {
    "name": "Submit Resume",
    "apiurl": "/restapi/customcontent/apply-online-form",
    "url": "/apply-online-form",
    "template": "general_content",
    "section": "submit-resume"
  },
  {
    "name": "Submit Resume",
    "apiurl": "/restapi/customcontent/apply-online-form",
    "url": "/submit-resume",
    "template": "general_content",
    "section": "submit-resume"
  },
  {
    "name": "Submit Resume",
    "apiurl": "/restapi/customcontent/apply-online-form",
    "url": "/staffing-resume",
    "template": "general_content",
    "section": "submit-resume"
  },
  {
    "name": "Submit Resume",
    "apiurl": "/restapi/customcontent/apply-online-form",
    "url": "/apply-online",
    "template": "general_content",
    "section": "submit-resume"
  },
  {
    "name": "Resources",
    "apiurl": "/restapi/customresourcescontent/resources",
    "url": "/resources",
    "template": "new-trinity-resource",
    "section": "resources"
  },
  {
    "name": "Software Support Request",
    "apiurl": "/restapi/customcontent/software-support-request",
    "url": "/software-support-request",
    "template": "general_content",
    "section": "software-support-request"
  },
  {
    "name": "Vegan",
    "apiurl": "/restapi/customcontent/contact-us/vegan",
    "url": "/contact-us/vegan",
    "template": "general_content",
    "section": "vegan"
  },
  {
    "name": "Vegetarian",
    "apiurl": "/restapi/customcontent/contact-us/vegetarian",
    "url": "/contact-us/vegetarian",
    "template": "general_content",
    "section": "vegetarian"
  },
  {
    "name": "Celiac Disease (Gluten Free)",
    "apiurl": "/restapi/customcontent/contact-us/celiac-disease-(gluten-free)",
    "url": "/contact-us/celiac-disease-(gluten-free)",
    "template": "general_content",
    "section": "celiac-disease-(gluten-free)"
  },
  {
    "name": "Kosher",
    "apiurl": "/restapi/customcontent/contact-us/kosher",
    "url": "/contact-us/kosher",
    "template": "general_content",
    "section": "kosher"
  },
  {
    "name": "Allergies/Other",
    "apiurl": "/restapi/customcontent/contact-us/allergies-other",
    "url": "/contact-us/allergies-other",
    "template": "general_content",
    "section": "allergiesother"
  },
  {
    "name": "Halal",
    "apiurl": "/restapi/customcontent/contact-us/halal",
    "url": "/contact-us/halal",
    "template": "general_content",
    "section": "halal"
  },
  {
    "name": "Contact Us",
    "apiurl": "/restapi/customcontent/contactus",
    "url": "/contactus",
    "template": "general_content",
    "section": "contact-us"
  },
  {
    "name": "Commercial/Institutional",
    "apiurl": "/restapi/customindustrylvl2content/industries/commercial-institutional",
    "url": "/industries/commercial-institutional",
    "template": "new-industry-landing-secondary",
    "section": "commercialinstitutional"
  },
  {
    "name": "Commercial/Institutional",
    "apiurl": "/restapi/customindustrylvl2content/industries/commercial-institutional",
    "url": "/test/commercial-institutional",
    "template": "new-industry-landing-secondary",
    "section": "commercialinstitutional"
  },
  {
    "name": "Life Sciences",
    "apiurl": "/restapi/customindustrylvl2content/industries/life-sciences",
    "url": "/industries/life-sciences",
    "template": "new-industry-landing-secondary",
    "section": "life-sciences"
  },
  {
    "name": "Energy",
    "apiurl": "/restapi/customindustrylvl2content/industries/energy",
    "url": "/industries/energy",
    "template": "new-industry-landing-secondary",
    "section": "energy"
  },
  {
    "name": "Energy",
    "apiurl": "/restapi/customindustrylvl2content/industries/energy",
    "url": "/test/energy",
    "template": "new-industry-landing-secondary",
    "section": "energy"
  },
  {
    "name": "Manufacturing - Industrial - Mining",
    "apiurl": "/restapi/customindustrylvl2content/industries/manufacturing-industrial-mining",
    "url": "/industries/manufacturing-industrial-mining",
    "template": "new-industry-landing-secondary",
    "section": "manufacturing--industrial--mining"
  },
  {
    "name": "EPA Acid Rain Information",
    "apiurl": "/restapi/customcontent/contact-us/epa-acid-rain-information",
    "url": "/contact-us/epa-acid-rain-information",
    "template": "general_content",
    "section": "epa-acid-rain-information"
  },
  {
    "name": "New York State Department of Environmental Conservation",
    "apiurl": "/restapi/customcontent/contact-us/new-york-state-department-of-environmental-conservation",
    "url": "/contact-us/new-york-state-department-of-environmental-conservation",
    "template": "general_content",
    "section": "new-york-state-department-of-environmental-conservation"
  },
  {
    "name": "Arizona Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/arizona-department-of-environmental-quality",
    "url": "/contact-us/arizona-department-of-environmental-quality",
    "template": "general_content",
    "section": "arizona-department-of-environmental-quality"
  },
  {
    "name": "NYC Department of Environmental Protection",
    "apiurl": "/restapi/customcontent/contact-us/nyc-department-of-environmental-protection",
    "url": "/contact-us/nyc-department-of-environmental-protection",
    "template": "general_content",
    "section": "nyc-department-of-environmental-protection"
  },
  {
    "name": "Olympic Region Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/olympic-region-clean-air-agency",
    "url": "/contact-us/olympic-region-clean-air-agency",
    "template": "general_content",
    "section": "olympic-region-clean-air-agency"
  },
  {
    "name": "Spokane Regional Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/spokane-regional-clean-air-agency",
    "url": "/contact-us/spokane-regional-clean-air-agency",
    "template": "general_content",
    "section": "spokane-regional-clean-air-agency"
  },
  {
    "name": "Pennsylvania Department of Environmental Protection",
    "apiurl": "/restapi/customcontent/contact-us/pennsylvania-department-of-environmental-protection",
    "url": "/contact-us/pennsylvania-department-of-environmental-protection",
    "template": "general_content",
    "section": "pennsylvania-department-of-environmental-protection"
  },
  {
    "name": "Manufacture Alabama",
    "apiurl": "/restapi/customcontent/contact-us/manufacture-alabama",
    "url": "/contact-us/manufacture-alabama",
    "template": "general_content",
    "section": "manufacture-alabama"
  },
  {
    "name": "Polk County",
    "apiurl": "/restapi/customcontent/contact-us/polk-county",
    "url": "/contact-us/polk-county",
    "template": "general_content",
    "section": "polk-county"
  },
  {
    "name": "LRAPA",
    "apiurl": "/restapi/customcontent/contact-us/lrapa",
    "url": "/contact-us/lrapa",
    "template": "general_content",
    "section": "lrapa"
  },
  {
    "name": "Idaho Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/idaho-department-of-environmental-quality",
    "url": "/contact-us/idaho-department-of-environmental-quality",
    "template": "general_content",
    "section": "idaho-department-of-environmental-quality"
  },
  {
    "name": "Kansas Department of Health and Environment",
    "apiurl": "/restapi/customcontent/contact-us/kansas-department-of-health-and-environment",
    "url": "/contact-us/kansas-department-of-health-and-environment",
    "template": "general_content",
    "section": "kansas-department-of-health-and-environment"
  },
  {
    "name": "Maryland Department of the Environment",
    "apiurl": "/restapi/customcontent/contact-us/maryland-department-of-the-environment",
    "url": "/contact-us/maryland-department-of-the-environment",
    "template": "general_content",
    "section": "maryland-department-of-the-environment"
  },
  {
    "name": "http://www.yakimacleanair.org/",
    "apiurl": "/restapi/customcontent/contact-us/http-www.yakimacleanair.org",
    "url": "/contact-us/http-www.yakimacleanair.org",
    "template": "general_content",
    "section": "http:www.yakimacleanair.org"
  },
  {
    "name": "Department of Energy and Environment",
    "apiurl": "/restapi/customcontent/contact-us/department-of-energy-and-environment",
    "url": "/contact-us/department-of-energy-and-environment",
    "template": "general_content",
    "section": "department-of-energy-and-environment"
  },
  {
    "name": "Massachusetts Department of Environmental Protection",
    "apiurl": "/restapi/customcontent/contact-us/massachusetts-department-of-environmental-protection",
    "url": "/contact-us/massachusetts-department-of-environmental-protection",
    "template": "general_content",
    "section": "massachusetts-department-of-environmental-protection"
  },
  {
    "name": "Allegheny County Health Department",
    "apiurl": "/restapi/customcontent/contact-us/allegheny-county-health-department",
    "url": "/contact-us/allegheny-county-health-department",
    "template": "general_content",
    "section": "allegheny-county-health-department"
  },
  {
    "name": "Chemical and Pollution Control",
    "apiurl": "/restapi/customcontent/contact-us/chemical-and-pollution-control",
    "url": "/contact-us/chemical-and-pollution-control",
    "template": "general_content",
    "section": "chemical-and-pollution-control"
  },
  {
    "name": "EPA 2003 Hazardous Waste Report Form and Instructions",
    "apiurl": "/restapi/customcontent/contact-us/epa-2003-hazardous-waste-report-form-and-instructions",
    "url": "/contact-us/epa-2003-hazardous-waste-report-form-and-instructions",
    "template": "general_content",
    "section": "epa-2003-hazardous-waste-report-form-and-instructions"
  },
  {
    "name": "City of Albuquerque/Bernalillo County Environmental Health Department",
    "apiurl": "/restapi/customcontent/contact-us/city-of-albuquerque-bernalillo-county-environmental-health-department",
    "url": "/contact-us/city-of-albuquerque-bernalillo-county-environmental-health-department",
    "template": "general_content",
    "section": "city-of-albuquerquebernalillo-county-environmental-health-department"
  },
  {
    "name": "EPA Region 7",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-7",
    "url": "/contact-us/epa-region-7",
    "template": "general_content",
    "section": "epa-region-7"
  },
  {
    "name": "Oregon Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/oregon-department-of-environmental-quality",
    "url": "/contact-us/oregon-department-of-environmental-quality",
    "template": "general_content",
    "section": "oregon-department-of-environmental-quality"
  },
  {
    "name": "Environmental Federation of Oklahoma",
    "apiurl": "/restapi/customcontent/contact-us/environmental-federation-of-oklahoma",
    "url": "/contact-us/environmental-federation-of-oklahoma",
    "template": "general_content",
    "section": "environmental-federation-of-oklahoma"
  },
  {
    "name": "Ohio Environmental Protection Agency",
    "apiurl": "/restapi/customcontent/contact-us/ohio-environmental-protection-agency",
    "url": "/contact-us/ohio-environmental-protection-agency",
    "template": "general_content",
    "section": "ohio-environmental-protection-agency"
  },
  {
    "name": "Tennessee Department of Environment & Conservation (TDEC)",
    "apiurl": "/restapi/customcontent/contact-us/tennessee-department-of-environment-conservation-(tdec)",
    "url": "/contact-us/tennessee-department-of-environment-conservation-(tdec)",
    "template": "general_content",
    "section": "tennessee-department-of-environment-conservation-(tdec)"
  },
  {
    "name": "California Air Resources Board",
    "apiurl": "/restapi/customcontent/contact-us/california-air-resources-board",
    "url": "/contact-us/california-air-resources-board",
    "template": "general_content",
    "section": "california-air-resources-board"
  },
  {
    "name": "Colorado Department of Public Health & Environment",
    "apiurl": "/restapi/customcontent/contact-us/colorado-department-of-public-health-environment",
    "url": "/contact-us/colorado-department-of-public-health-environment",
    "template": "general_content",
    "section": "colorado-department-of-public-health-environment"
  },
  {
    "name": "California Air Resources Board (Current Rules List)",
    "apiurl": "/restapi/customcontent/contact-us/california-air-resources-board-(current-rules-list)",
    "url": "/contact-us/california-air-resources-board-(current-rules-list)",
    "template": "general_content",
    "section": "california-air-resources-board-(current-rules-list)"
  },
  {
    "name": "Board of Professional Engineers of Queensland (BPEQ)",
    "apiurl": "/restapi/customcontent/contact-us/board-of-professional-engineers-of-queensland-(bpeq)",
    "url": "/contact-us/board-of-professional-engineers-of-queensland-(bpeq)",
    "template": "general_content",
    "section": "board-of-professional-engineers-of-queensland-(bpeq)"
  },
  {
    "name": "EPA Region 1",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-1",
    "url": "/contact-us/epa-region-1",
    "template": "general_content",
    "section": "epa-region-1"
  },
  {
    "name": "Queensland Legislation",
    "apiurl": "/restapi/customcontent/contact-us/queensland-legislation",
    "url": "/contact-us/queensland-legislation",
    "template": "general_content",
    "section": "queensland-legislation"
  },
  {
    "name": "Indiana Department of Environmental Management",
    "apiurl": "/restapi/customcontent/contact-us/indiana-department-of-environmental-management",
    "url": "/contact-us/indiana-department-of-environmental-management",
    "template": "general_content",
    "section": "indiana-department-of-environmental-management"
  },
  {
    "name": "EPA Air Toxics Website",
    "apiurl": "/restapi/customcontent/contact-us/epa-air-toxics-website",
    "url": "/contact-us/epa-air-toxics-website",
    "template": "general_content",
    "section": "epa-air-toxics-website"
  },
  {
    "name": "Wisconsin Department of Natural Resources",
    "apiurl": "/restapi/customcontent/contact-us/wisconsin-department-of-natural-resources",
    "url": "/contact-us/wisconsin-department-of-natural-resources",
    "template": "general_content",
    "section": "wisconsin-department-of-natural-resources"
  },
  {
    "name": "New Mexico Environment Department",
    "apiurl": "/restapi/customcontent/contact-us/new-mexico-environment-department",
    "url": "/contact-us/new-mexico-environment-department",
    "template": "general_content",
    "section": "new-mexico-environment-department"
  },
  {
    "name": "Southwest Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/southwest-clean-air-agency",
    "url": "/contact-us/southwest-clean-air-agency",
    "template": "general_content",
    "section": "southwest-clean-air-agency"
  },
  {
    "name": "Quick Link 1",
    "apiurl": "/restapi/customcontent/contact-us/quick-link-1",
    "url": "/contact-us/quick-link-1",
    "template": "general_content",
    "section": "quick-link-1"
  },
  {
    "name": "Air & Waste Management Association - Alabama Chapter",
    "apiurl": "/restapi/customcontent/contact-us/air-waste-management-association---alabama-chapter",
    "url": "/contact-us/air-waste-management-association---alabama-chapter",
    "template": "general_content",
    "section": "air-waste-management-association--alabama-chapter"
  },
  {
    "name": "Provenance Consulting, a Trinity Consultants Co.",
    "apiurl": "/restapi/customcontent/contact-us/provenance-consulting-a-trinity-consultants-co",
    "url": "/contact-us/provenance-consulting-a-trinity-consultants-co",
    "template": "general_content",
    "section": "provenance-consulting,-a-trinity-consultants-co."
  },
  {
    "name": "Georgia Department of Natural Resources",
    "apiurl": "/restapi/customcontent/contact-us/georgia-department-of-natural-resources",
    "url": "/contact-us/georgia-department-of-natural-resources",
    "template": "general_content",
    "section": "georgia-department-of-natural-resources"
  },
  {
    "name": "EPA Region 9",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-9",
    "url": "/contact-us/epa-region-9",
    "template": "general_content",
    "section": "epa-region-9"
  },
  {
    "name": "Environment and Climate Change Canada NPRI",
    "apiurl": "/restapi/customcontent/contact-us/environment-and-climate-change-canada-npri",
    "url": "/contact-us/environment-and-climate-change-canada-npri",
    "template": "general_content",
    "section": "environment-and-climate-change-canada-npri"
  },
  {
    "name": "EPA Region 3",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-3",
    "url": "/contact-us/epa-region-3",
    "template": "general_content",
    "section": "epa-region-3"
  },
  {
    "name": "Northwest Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/northwest-clean-air-agency",
    "url": "/contact-us/northwest-clean-air-agency",
    "template": "general_content",
    "section": "northwest-clean-air-agency"
  },
  {
    "name": "Air & Waste Management Association - Georgia Chapter",
    "apiurl": "/restapi/customcontent/contact-us/air-waste-management-association---georgia-chapter",
    "url": "/contact-us/air-waste-management-association---georgia-chapter",
    "template": "general_content",
    "section": "air-waste-management-association--georgia-chapter"
  },
  {
    "name": "Wyoming Oil and Gas Conservation Commission",
    "apiurl": "/restapi/customcontent/contact-us/wyoming-oil-and-gas-conservation-commission",
    "url": "/contact-us/wyoming-oil-and-gas-conservation-commission",
    "template": "general_content",
    "section": "wyoming-oil-and-gas-conservation-commission"
  },
  {
    "name": "EPA Oil Spill Program Homepage",
    "apiurl": "/restapi/customcontent/contact-us/epa-oil-spill-program-homepage",
    "url": "/contact-us/epa-oil-spill-program-homepage",
    "template": "general_content",
    "section": "epa-oil-spill-program-homepage"
  },
  {
    "name": "Australian Acoustical Society (AAS)",
    "apiurl": "/restapi/customcontent/contact-us/australian-acoustical-society-(aas)",
    "url": "/contact-us/australian-acoustical-society-(aas)",
    "template": "general_content",
    "section": "australian-acoustical-society-(aas)"
  },
  {
    "name": "EPA Region 10",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-10",
    "url": "/contact-us/epa-region-10",
    "template": "general_content",
    "section": "epa-region-10"
  },
  {
    "name": "El Dorado County Air Quality Management District",
    "apiurl": "/restapi/customcontent/contact-us/el-dorado-county-air-quality-management-district",
    "url": "/contact-us/el-dorado-county-air-quality-management-district",
    "template": "general_content",
    "section": "el-dorado-county-air-quality-management-district"
  },
  {
    "name": "Emission Factors",
    "apiurl": "/restapi/customcontent/contact-us/emission-factors",
    "url": "/contact-us/emission-factors",
    "template": "general_content",
    "section": "emission-factors"
  },
  {
    "name": "Iowa Department of Natural Resources",
    "apiurl": "/restapi/customcontent/contact-us/iowa-department-of-natural-resources",
    "url": "/contact-us/iowa-department-of-natural-resources",
    "template": "general_content",
    "section": "iowa-department-of-natural-resources"
  },
  {
    "name": "Mississippi Department of Environmental Quality (MDEQ)",
    "apiurl": "/restapi/customcontent/contact-us/mississippi-department-of-environmental-quality-(mdeq)",
    "url": "/contact-us/mississippi-department-of-environmental-quality-(mdeq)",
    "template": "general_content",
    "section": "mississippi-department-of-environmental-quality-(mdeq)"
  },
  {
    "name": "EPA International Waste Activities",
    "apiurl": "/restapi/customcontent/contact-us/epa-international-waste-activities",
    "url": "/contact-us/epa-international-waste-activities",
    "template": "general_content",
    "section": "epa-international-waste-activities"
  },
  {
    "name": "Air & Waste Management Association – Oklahoma Chapter",
    "apiurl": "/restapi/customcontent/contact-us/air-waste-management-association-oklahoma-chapter",
    "url": "/contact-us/air-waste-management-association-oklahoma-chapter",
    "template": "general_content",
    "section": "air-waste-management-association-–-oklahoma-chapter"
  },
  {
    "name": "Utah Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/utah-department-of-environmental-quality",
    "url": "/contact-us/utah-department-of-environmental-quality",
    "template": "general_content",
    "section": "utah-department-of-environmental-quality"
  },
  {
    "name": "City of Huntsville Air Program",
    "apiurl": "/restapi/customcontent/contact-us/city-of-huntsville-air-program",
    "url": "/contact-us/city-of-huntsville-air-program",
    "template": "general_content",
    "section": "city-of-huntsville-air-program"
  },
  {
    "name": "Brisbane City Council Noise Complaints",
    "apiurl": "/restapi/customcontent/contact-us/brisbane-city-council-noise-complaints",
    "url": "/contact-us/brisbane-city-council-noise-complaints",
    "template": "general_content",
    "section": "brisbane-city-council-noise-complaints"
  },
  {
    "name": "Pinal County Air Quality Control District (PCAQCD)",
    "apiurl": "/restapi/customcontent/contact-us/pinal-county-air-quality-control-district-(pcaqcd)",
    "url": "/contact-us/pinal-county-air-quality-control-district-(pcaqcd)",
    "template": "general_content",
    "section": "pinal-county-air-quality-control-district-(pcaqcd)"
  },
  {
    "name": "Missouri Department of Natural Resources",
    "apiurl": "/restapi/customcontent/contact-us/missouri-department-of-natural-resources",
    "url": "/contact-us/missouri-department-of-natural-resources",
    "template": "general_content",
    "section": "missouri-department-of-natural-resources"
  },
  {
    "name": "Texas Commission on Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/texas-commission-on-environmental-quality",
    "url": "/contact-us/texas-commission-on-environmental-quality",
    "template": "general_content",
    "section": "texas-commission-on-environmental-quality"
  },
  {
    "name": "Georgia Environmental Protection Division (EPD)",
    "apiurl": "/restapi/customcontent/contact-us/georgia-environmental-protection-division-(epd)",
    "url": "/contact-us/georgia-environmental-protection-division-(epd)",
    "template": "general_content",
    "section": "georgia-environmental-protection-division-(epd)"
  },
  {
    "name": "Florida Department of Environmental Protection",
    "apiurl": "/restapi/customcontent/contact-us/florida-department-of-environmental-protection",
    "url": "/contact-us/florida-department-of-environmental-protection",
    "template": "general_content",
    "section": "florida-department-of-environmental-protection"
  },
  {
    "name": "Ontario Toxic Reduction Act Reports",
    "apiurl": "/restapi/customcontent/contact-us/ontario-toxic-reduction-act-reports",
    "url": "/contact-us/ontario-toxic-reduction-act-reports",
    "template": "general_content",
    "section": "ontario-toxic-reduction-act-reports"
  },
  {
    "name": "IEPA",
    "apiurl": "/restapi/customcontent/contact-us/iepa",
    "url": "/contact-us/iepa",
    "template": "general_content",
    "section": "iepa"
  },
  {
    "name": "New Jersey Department of Environmental Protection",
    "apiurl": "/restapi/customcontent/contact-us/new-jersey-department-of-environmental-protection",
    "url": "/contact-us/new-jersey-department-of-environmental-protection",
    "template": "general_content",
    "section": "new-jersey-department-of-environmental-protection"
  },
  {
    "name": "Australian Government – Australia’s Guide to Environmentally Sustainable Homes / Noise Control",
    "apiurl": "/restapi/customcontent/contact-us/australian-government-australia-s-guide-to-environmentally-sustainable-homes-noise-control",
    "url": "/contact-us/australian-government-australia-s-guide-to-environmentally-sustainable-homes-noise-control",
    "template": "general_content",
    "section": "australian-government-–-australia’s-guide-to-environmentally-sustainable-homes--noise-control"
  },
  {
    "name": "Alabama Department of Environmental Management",
    "apiurl": "/restapi/customcontent/contact-us/alabama-department-of-environmental-management",
    "url": "/contact-us/alabama-department-of-environmental-management",
    "template": "general_content",
    "section": "alabama-department-of-environmental-management"
  },
  {
    "name": "Louisiana Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/louisiana-department-of-environmental-quality",
    "url": "/contact-us/louisiana-department-of-environmental-quality",
    "template": "general_content",
    "section": "louisiana-department-of-environmental-quality"
  },
  {
    "name": "EPA Acid Rain Program Annual Reconciliation Fact Sheet",
    "apiurl": "/restapi/customcontent/contact-us/epa-acid-rain-program-annual-reconciliation-fact-sheet",
    "url": "/contact-us/epa-acid-rain-program-annual-reconciliation-fact-sheet",
    "template": "general_content",
    "section": "epa-acid-rain-program-annual-reconciliation-fact-sheet"
  },
  {
    "name": "Acid Rain Program Forms",
    "apiurl": "/restapi/customcontent/contact-us/acid-rain-program-forms",
    "url": "/contact-us/acid-rain-program-forms",
    "template": "general_content",
    "section": "acid-rain-program-forms"
  },
  {
    "name": "EPA Region 6",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-6",
    "url": "/contact-us/epa-region-6",
    "template": "general_content",
    "section": "epa-region-6"
  },
  {
    "name": "Maricopa County Air Quality Department",
    "apiurl": "/restapi/customcontent/contact-us/maricopa-county-air-quality-department",
    "url": "/contact-us/maricopa-county-air-quality-department",
    "template": "general_content",
    "section": "maricopa-county-air-quality-department"
  },
  {
    "name": "OSHA Combustible Dust National Emphasis Program",
    "apiurl": "/restapi/customcontent/contact-us/osha-combustible-dust-national-emphasis-program",
    "url": "/contact-us/osha-combustible-dust-national-emphasis-program",
    "template": "general_content",
    "section": "osha-combustible-dust-national-emphasis-program"
  },
  {
    "name": "Queensland Transport Noise Corridor (MP 4.4)",
    "apiurl": "/restapi/customcontent/contact-us/queensland-transport-noise-corridor-(mp-4.4)",
    "url": "/contact-us/queensland-transport-noise-corridor-(mp-4.4)",
    "template": "general_content",
    "section": "queensland-transport-noise-corridor-(mp-4.4)"
  },
  {
    "name": "Linn County",
    "apiurl": "/restapi/customcontent/contact-us/linn-county",
    "url": "/contact-us/linn-county",
    "template": "general_content",
    "section": "linn-county"
  },
  {
    "name": "Colusa County Air Pollution Control District",
    "apiurl": "/restapi/customcontent/contact-us/colusa-county-air-pollution-control-district",
    "url": "/contact-us/colusa-county-air-pollution-control-district",
    "template": "general_content",
    "section": "colusa-county-air-pollution-control-district"
  },
  {
    "name": "Kentucky Division for Air Quality",
    "apiurl": "/restapi/customcontent/contact-us/kentucky-division-for-air-quality",
    "url": "/contact-us/kentucky-division-for-air-quality",
    "template": "general_content",
    "section": "kentucky-division-for-air-quality"
  },
  {
    "name": "Air & Waste Management Association - East Tennessee Chapter",
    "apiurl": "/restapi/customcontent/contact-us/air-waste-management-association---east-tennessee-chapter",
    "url": "/contact-us/air-waste-management-association---east-tennessee-chapter",
    "template": "general_content",
    "section": "air-waste-management-association--east-tennessee-chapter"
  },
  {
    "name": "Quick Link 2",
    "apiurl": "/restapi/customcontent/contact-us/quick-link-2",
    "url": "/contact-us/quick-link-2",
    "template": "general_content",
    "section": "quick-link-2"
  },
  {
    "name": "Antelope Valley Air Quality Management District",
    "apiurl": "/restapi/customcontent/contact-us/antelope-valley-air-quality-management-district",
    "url": "/contact-us/antelope-valley-air-quality-management-district",
    "template": "general_content",
    "section": "antelope-valley-air-quality-management-district"
  },
  {
    "name": "EPA Air Toxics",
    "apiurl": "/restapi/customcontent/contact-us/epa-air-toxics",
    "url": "/contact-us/epa-air-toxics",
    "template": "general_content",
    "section": "epa-air-toxics"
  },
  {
    "name": "EPA Quarterly Emissions Submissions Methods/User Registration",
    "apiurl": "/restapi/customcontent/contact-us/epa-quarterly-emissions-submissions-methods-user-registration",
    "url": "/contact-us/epa-quarterly-emissions-submissions-methods-user-registration",
    "template": "general_content",
    "section": "epa-quarterly-emissions-submissions-methodsuser-registration"
  },
  {
    "name": "Minnesota Pollution Control Agency",
    "apiurl": "/restapi/customcontent/contact-us/minnesota-pollution-control-agency",
    "url": "/contact-us/minnesota-pollution-control-agency",
    "template": "general_content",
    "section": "minnesota-pollution-control-agency"
  },
  {
    "name": "Puget Sound Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/puget-sound-clean-air-agency",
    "url": "/contact-us/puget-sound-clean-air-agency",
    "template": "general_content",
    "section": "puget-sound-clean-air-agency"
  },
  {
    "name": "Oklahoma Department of Environmental Quality (ODEQ)",
    "apiurl": "/restapi/customcontent/contact-us/oklahoma-department-of-environmental-quality-(odeq)",
    "url": "/contact-us/oklahoma-department-of-environmental-quality-(odeq)",
    "template": "general_content",
    "section": "oklahoma-department-of-environmental-quality-(odeq)"
  },
  {
    "name": "Ontario Ministry of the Environment and Climate Change",
    "apiurl": "/restapi/customcontent/contact-us/ontario-ministry-of-the-environment-and-climate-change",
    "url": "/contact-us/ontario-ministry-of-the-environment-and-climate-change",
    "template": "general_content",
    "section": "ontario-ministry-of-the-environment-and-climate-change"
  },
  {
    "name": "Queensland Government Live Air Quality Data",
    "apiurl": "/restapi/customcontent/contact-us/queensland-government-live-air-quality-data",
    "url": "/contact-us/queensland-government-live-air-quality-data",
    "template": "general_content",
    "section": "queensland-government-live-air-quality-data"
  },
  {
    "name": "Benton Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/benton-clean-air-agency",
    "url": "/contact-us/benton-clean-air-agency",
    "template": "general_content",
    "section": "benton-clean-air-agency"
  },
  {
    "name": "EPA Region 4",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-4",
    "url": "/contact-us/epa-region-4",
    "template": "general_content",
    "section": "epa-region-4"
  },
  {
    "name": "Philadelphia Department of Health - Air Management Services",
    "apiurl": "/restapi/customcontent/contact-us/philadelphia-department-of-health---air-management-services",
    "url": "/contact-us/philadelphia-department-of-health---air-management-services",
    "template": "general_content",
    "section": "philadelphia-department-of-health--air-management-services"
  },
  {
    "name": "Association of Australasian Acoustical Consultants (AAAC)",
    "apiurl": "/restapi/customcontent/contact-us/association-of-australasian-acoustical-consultants-(aaac)",
    "url": "/contact-us/association-of-australasian-acoustical-consultants-(aaac)",
    "template": "general_content",
    "section": "association-of-australasian-acoustical-consultants-(aaac)"
  },
  {
    "name": "Louisville Metro Air Pollution Control District",
    "apiurl": "/restapi/customcontent/contact-us/louisville-metro-air-pollution-control-district",
    "url": "/contact-us/louisville-metro-air-pollution-control-district",
    "template": "general_content",
    "section": "louisville-metro-air-pollution-control-district"
  },
  {
    "name": "Pima County Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/prima-county-department-of-environmental-quality",
    "url": "/contact-us/prima-county-department-of-environmental-quality",
    "template": "general_content",
    "section": "pima-county-department-of-environmental-quality"
  },
  {
    "name": "Wyoming Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/wyoming-department-of-environmental-quality",
    "url": "/contact-us/wyoming-department-of-environmental-quality",
    "template": "general_content",
    "section": "wyoming-department-of-environmental-quality"
  },
  {
    "name": "Clean Air Society of Australia and New Zealand (CASANZ)",
    "apiurl": "/restapi/customcontent/contact-us/clean-air-society-of-australia-and-new-zealand-(casanz)",
    "url": "/contact-us/clean-air-society-of-australia-and-new-zealand-(casanz)",
    "template": "general_content",
    "section": "clean-air-society-of-australia-and-new-zealand-(casanz)"
  },
  {
    "name": "North Carolina Department of Environment & Natural Resources",
    "apiurl": "/restapi/customcontent/contact-us/north-carolina-department-of-environment-natural-resources",
    "url": "/contact-us/north-carolina-department-of-environment-natural-resources",
    "template": "general_content",
    "section": "north-carolina-department-of-environment-natural-resources"
  },
  {
    "name": "EPA Region 2",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-2",
    "url": "/contact-us/epa-region-2",
    "template": "general_content",
    "section": "epa-region-2"
  },
  {
    "name": "Feather River Air Quality Management District",
    "apiurl": "/restapi/customcontent/contact-us/feather-river-air-quality-management-district",
    "url": "/contact-us/feather-river-air-quality-management-district",
    "template": "general_content",
    "section": "feather-river-air-quality-management-district"
  },
  {
    "name": "Jefferson County Department of Public Health - Air Pollution Control",
    "apiurl": "/restapi/customcontent/contact-us/jefferson-county-department-of-public-health---air-pollution-control",
    "url": "/contact-us/jefferson-county-department-of-public-health---air-pollution-control",
    "template": "general_content",
    "section": "jefferson-county-department-of-public-health--air-pollution-control"
  },
  {
    "name": "EPA Region 5",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-5",
    "url": "/contact-us/epa-region-5",
    "template": "general_content",
    "section": "epa-region-5"
  },
  {
    "name": "Bay Area Air Quality Management District",
    "apiurl": "/restapi/customcontent/contact-us/bay-area-air-quality-management-district",
    "url": "/contact-us/bay-area-air-quality-management-district",
    "template": "general_content",
    "section": "bay-area-air-quality-management-district"
  },
  {
    "name": "Michigan Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/michigan-department-of-environmental-quality",
    "url": "/contact-us/michigan-department-of-environmental-quality",
    "template": "general_content",
    "section": "michigan-department-of-environmental-quality"
  },
  {
    "name": "Washington Department of Ecology (Air Quality Program)",
    "apiurl": "/restapi/customcontent/contact-us/washington-department-of-ecology-(air-quality-program)",
    "url": "/contact-us/washington-department-of-ecology-(air-quality-program)",
    "template": "general_content",
    "section": "washington-department-of-ecology-(air-quality-program)"
  },
  {
    "name": "Virginia Department of Environmental Quality",
    "apiurl": "/restapi/customcontent/contact-us/virginia-department-of-environmental-quality",
    "url": "/contact-us/virginia-department-of-environmental-quality",
    "template": "general_content",
    "section": "virginia-department-of-environmental-quality"
  },
  {
    "name": "Yakima Clean Air Agency",
    "apiurl": "/restapi/customcontent/contact-us/yakima-clean-air-agency",
    "url": "/contact-us/yakima-clean-air-agency",
    "template": "general_content",
    "section": "yakima-clean-air-agency"
  },
  {
    "name": "Amador Air District",
    "apiurl": "/restapi/customcontent/contact-us/amador-air-district",
    "url": "/contact-us/amador-air-district",
    "template": "general_content",
    "section": "amador-air-district"
  },
  {
    "name": "EPA Region 8",
    "apiurl": "/restapi/customcontent/contact-us/epa-region-8",
    "url": "/contact-us/epa-region-8",
    "template": "general_content",
    "section": "epa-region-8"
  },
  {
    "name": "Brisbane City Council Air Quality Complaints",
    "apiurl": "/restapi/customcontent/contact-us/brisbane-city-council-air-quality-complaints",
    "url": "/contact-us/brisbane-city-council-air-quality-complaints",
    "template": "general_content",
    "section": "brisbane-city-council-air-quality-complaints"
  },
  {
    "name": "Butte County Air Quality Management District",
    "apiurl": "/restapi/customcontent/contact-us/butte-county-air-quality-management-district",
    "url": "/contact-us/butte-county-air-quality-management-district",
    "template": "general_content",
    "section": "butte-county-air-quality-management-district"
  },
  {
    "name": "Environment and Climate Change Canada Acts and Regulations",
    "apiurl": "/restapi/customcontent/contact-us/environment-and-climate-change-canada-acts-and-regulations",
    "url": "/contact-us/environment-and-climate-change-canada-acts-and-regulations",
    "template": "general_content",
    "section": "environment-and-climate-change-canada-acts-and-regulations"
  },
  {
    "name": "Report writing",
    "apiurl": "/restapi/customcontent/contact-us/report-writing",
    "url": "/contact-us/report-writing",
    "template": "general_content",
    "section": "report-writing"
  },
  {
    "name": "Essay writing",
    "apiurl": "/restapi/customcontent/contact-us/essay-writing",
    "url": "/contact-us/essay-writing",
    "template": "general_content",
    "section": "essay-writing"
  },
  {
    "name": "Fujian",
    "apiurl": "/restapi/customlocationcontentlvl3/location/china/china/fujian",
    "url": "/location/china/china/fujian",
    "template": "new-individual-locations",
    "section": "fujian"
  },
  {
    "name": "North Dakota",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/north-dakota",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/north-dakota",
    "template": "new-individual-locations",
    "section": "north-dakota"
  },
  {
    "name": "Oklahoma",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oklahoma",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oklahoma",
    "template": "new-individual-locations",
    "section": "oklahoma"
  },
  {
    "name": "Arkansas",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/arkansas",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/arkansas",
    "template": "new-individual-locations",
    "section": "arkansas"
  },
  {
    "name": "Louisiana Lake Charles",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/louisiana-lake-charles",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/louisiana-lake-charles",
    "template": "new-individual-locations",
    "section": "louisiana-lake-charles"
  },
  {
    "name": "California Bakersfield",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california-bakersfield",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california-bakersfield",
    "template": "new-individual-locations",
    "section": "california-bakersfield"
  },
  {
    "name": "Christchurch",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/new-zealand/south-island/christchurch",
    "url": "/locations/australia-location/new-zealand/south-island/christchurch",
    "template": "new-individual-locations",
    "section": "south-island"
  },
  {
    "name": "Christchurch",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/new-zealand/south-island/christchurch",
    "url": "/locations/australia/new-zealand/south-island/christchurch",
    "template": "new-individual-locations",
    "section": "south-island"
  },
  {
    "name": "Chicago",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/illinois/chicago",
    "url": "/locations/north-america/united-states/illinois/chicago",
    "template": "new-individual-locations",
    "section": "illinois"
  },
  {
    "name": "Arizona",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/arizona",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/arizona",
    "template": "new-individual-locations",
    "section": "arizona"
  },
  {
    "name": "Houston-Katy",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/houston-katy",
    "url": "/locations/north-america/united-states/texas/houston-katy",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Albany",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/new-york/albany",
    "url": "/locations/north-america/united-states/new-york/albany",
    "template": "new-individual-locations",
    "section": "new-york"
  },
  {
    "name": "Foxboro",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/massachusetts/foxboro",
    "url": "/locations/north-america/united-states/massachusetts/foxboro",
    "template": "new-individual-locations",
    "section": "massachusetts"
  },
  {
    "name": "Arkansas Little Rock",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/arkansas-little-rock",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/arkansas-little-rock",
    "template": "new-individual-locations",
    "section": "arkansas-little-rock"
  },
  {
    "name": "Louisiana",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/louisiana",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/louisiana",
    "template": "new-individual-locations",
    "section": "louisiana"
  },
  {
    "name": "British Columbia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/british-columbia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/british-columbia",
    "template": "new-individual-locations",
    "section": "british-columbia"
  },
  {
    "name": "Missouri",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/missouri",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/missouri",
    "template": "new-individual-locations",
    "section": "missouri"
  },
  {
    "name": "China",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/china",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/china",
    "template": "new-individual-locations",
    "section": "china"
  },
  {
    "name": "Hangzhou",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/china/asia-pacific-state/asia-pacific-location",
    "url": "/locations/asia/china/asia-pacific-state/asia-pacific-location",
    "template": "new-individual-locations",
    "section": "asia-pacific-state"
  },
  {
    "name": "Fort Collins",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/colorado/fort-collins",
    "url": "/locations/north-america/united-states/colorado/fort-collins",
    "template": "new-individual-locations",
    "section": "colorado"
  },
  {
    "name": "South Dakota",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/south-dakota",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/south-dakota",
    "template": "new-individual-locations",
    "section": "south-dakota"
  },
  {
    "name": "Omaha",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/nebraska/lincoln",
    "url": "/locations/north-america/united-states/nebraska/lincoln",
    "template": "new-individual-locations",
    "section": "nebraska"
  },
  {
    "name": "Omaha",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/nebraska/lincoln",
    "url": "/locations/north-america/united-states/nebraska/omaha",
    "template": "new-individual-locations",
    "section": "nebraska"
  },
  {
    "name": "British Columbia Victoria",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/british-columbia-victoria",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/british-columbia-victoria",
    "template": "new-individual-locations",
    "section": "british-columbia-victoria"
  },
  {
    "name": "Qatar",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/qatar",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/qatar",
    "template": "new-individual-locations",
    "section": "qatar"
  },
  {
    "name": "California Mountain View",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california-mountain-view",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california-mountain-view",
    "template": "new-individual-locations",
    "section": "california-mountain-view"
  },
  {
    "name": "Pittsburgh",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/pennsylvania/pittsburgh",
    "url": "/locations/north-america/united-states/pennsylvania/pittsburgh",
    "template": "new-individual-locations",
    "section": "pennsylvania"
  },
  {
    "name": "Wyoming Cheyenne",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/wyoming-cheyenne",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/wyoming-cheyenne",
    "template": "new-individual-locations",
    "section": "wyoming-cheyenne"
  },
  {
    "name": "Ohio Cleveland",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ohio-cleveland",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ohio-cleveland",
    "template": "new-individual-locations",
    "section": "ohio-cleveland"
  },
  {
    "name": "Pennsylvania Philadelphia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/pennsylvania-philadelphia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/pennsylvania-philadelphia",
    "template": "new-individual-locations",
    "section": "pennsylvania-philadelphia"
  },
  {
    "name": "Washington Seattle",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/washington-seattle",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/washington-seattle",
    "template": "new-individual-locations",
    "section": "washington-seattle"
  },
  {
    "name": "Minnesota Minneapolis",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/minnesota-minneapolis",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/minnesota-minneapolis",
    "template": "new-individual-locations",
    "section": "minnesota-minneapolis"
  },
  {
    "name": "Delaware",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/delaware",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/delaware",
    "template": "new-individual-locations",
    "section": "delaware"
  },
  {
    "name": "Oregon(1)",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oregon(1)",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oregon(1)",
    "template": "new-individual-locations",
    "section": "oregon(1)"
  },
  {
    "name": "Washington, DC",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/washington-dc",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/washington-dc",
    "template": "new-individual-locations",
    "section": "washington,-dc"
  },
  {
    "name": "Florida",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/florida",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/florida",
    "template": "new-individual-locations",
    "section": "florida"
  },
  {
    "name": "Covington",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/kentucky/covington",
    "url": "/locations/north-america/united-states/kentucky/covington",
    "template": "new-individual-locations",
    "section": "kentucky"
  },
  {
    "name": "Reno",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/nevada/reno",
    "url": "/locations/north-america/united-states/nevada/reno",
    "template": "new-individual-locations",
    "section": "nevada"
  },
  {
    "name": "Reno",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/nevada/reno",
    "url": "/nevada",
    "template": "new-individual-locations",
    "section": "nevada"
  },
  {
    "name": "London",
    "apiurl": "/restapi/customlocationcontentlvl3/location/united-kingdom/united-kingdom/london",
    "url": "/location/united-kingdom/united-kingdom/london",
    "template": "new-individual-locations",
    "section": "london"
  },
  {
    "name": "Guangzhou",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/china/asia-pacific-state/guangzhou",
    "url": "/locations/asia/china/asia-pacific-state/guangzhou",
    "template": "new-individual-locations",
    "section": "asia-pacific-state"
  },
  {
    "name": "Ontario Toronto",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ontario-toronto",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ontario-toronto",
    "template": "new-individual-locations",
    "section": "ontario-toronto"
  },
  {
    "name": "Seattle",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington/seattle",
    "url": "/locations/north-america/united-states/washington/seattle",
    "template": "new-individual-locations",
    "section": "washington"
  },
  {
    "name": "Seattle",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington/seattle",
    "url": "/locations/washington/",
    "template": "new-individual-locations",
    "section": "washington"
  },
  {
    "name": "Seattle",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington/seattle",
    "url": "/locations/seattle/",
    "template": "new-individual-locations",
    "section": "washington"
  },
  {
    "name": "California Sacramento",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california-sacramento",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california-sacramento",
    "template": "new-individual-locations",
    "section": "california-sacramento"
  },
  {
    "name": "Guelph",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/guelph",
    "url": "/locations/north-america/canada/ontario/guelph",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Guelph",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/guelph",
    "url": "/locations/north-america/canada/toronto/guelph",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Charlotte",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/north-carolina/charlotte",
    "url": "/locations/north-america/united-states/north-carolina/charlotte",
    "template": "new-individual-locations",
    "section": "north-carolina"
  },
  {
    "name": "Kansas City",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/kansas-kansas-city",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/kansas-kansas-city",
    "template": "new-individual-locations",
    "section": "kansas-city"
  },
  {
    "name": "Dallas",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/dallas",
    "url": "/locations/north-america/united-states/texas/dallas",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Washington, D.C.",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington-d.c/washington-dc",
    "url": "/locations/north-america/united-states/washington-d.c/washington-dc",
    "template": "new-individual-locations",
    "section": "washington-d.c"
  },
  {
    "name": "Washington, D.C.",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington-d.c/washington-dc",
    "url": "/locations/north-america/united-states/washington-dc/washington-dc",
    "template": "new-individual-locations",
    "section": "washington-d.c"
  },
  {
    "name": "Washington, D.C.",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/washington-d.c/washington-dc",
    "url": "/locations/north-america/united-states/maryland/washington-dc",
    "template": "new-individual-locations",
    "section": "washington-d.c"
  },
  {
    "name": "Boise",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/idaho/boise",
    "url": "/locations/north-america/united-states/idaho/boise",
    "template": "new-individual-locations",
    "section": "idaho"
  },
  {
    "name": "Oakland",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/oakland",
    "url": "/locations/north-america/united-states/california/oakland",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Phoenix",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/arizona/phoenix",
    "url": "/locations/north-america/united-states/arizona/phoenix",
    "template": "new-individual-locations",
    "section": "arizona"
  },
  {
    "name": "Phoenix",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/arizona/phoenix",
    "url": "/locations/arizona/phoenix/",
    "template": "new-individual-locations",
    "section": "arizona"
  },
  {
    "name": "Phoenix",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/arizona/phoenix",
    "url": "/locations/arizona/",
    "template": "new-individual-locations",
    "section": "arizona"
  },
  {
    "name": "Irvine",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/irvine",
    "url": "/locations/north-america/united-states/california/irvine",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Irvine",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/irvine",
    "url": "/locations/washington",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Saskatoon",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/saskatchewan/saskatoon",
    "url": "/locations/north-america/canada/saskatchewan/saskatoon",
    "template": "new-individual-locations",
    "section": "saskatchewan"
  },
  {
    "name": "Florida Orlando",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/florida-orlando",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/florida-orlando",
    "template": "new-individual-locations",
    "section": "florida-orlando"
  },
  {
    "name": "Kansas",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/kansas",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/kansas",
    "template": "new-individual-locations",
    "section": "kansas"
  },
  {
    "name": "Salt Lake City",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/utah/salt-lake-city",
    "url": "/locations/north-america/united-states/utah/salt-lake-city",
    "template": "new-individual-locations",
    "section": "utah"
  },
  {
    "name": "Virginia Roanoke",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/virginia-roanoke",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/virginia-roanoke",
    "template": "new-individual-locations",
    "section": "virginia-roanoke"
  },
  {
    "name": "Borger",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/borger",
    "url": "/locations/north-america/united-states/texas/borger",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Saskatchewan",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/saskatchewan",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/saskatchewan",
    "template": "new-individual-locations",
    "section": "saskatchewan"
  },
  {
    "name": "New Jersey",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-jersey",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-jersey",
    "template": "new-individual-locations",
    "section": "new-jersey"
  },
  {
    "name": "Indiana",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/indiana",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/indiana",
    "template": "new-individual-locations",
    "section": "indiana"
  },
  {
    "name": "New York",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-york",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-york",
    "template": "new-individual-locations",
    "section": "new-york"
  },
  {
    "name": "Arizona Phoenix",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/arizona-phoenix",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/arizona-phoenix",
    "template": "new-individual-locations",
    "section": "arizona-phoenix"
  },
  {
    "name": "Wisconsin",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/wisconsin",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/wisconsin",
    "template": "new-individual-locations",
    "section": "wisconsin"
  },
  {
    "name": "Charlottesville",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/virginia/charlottesville",
    "url": "/locations/north-america/united-states/virginia/charlottesville",
    "template": "new-individual-locations",
    "section": "virginia"
  },
  {
    "name": "Georgia Atlanta",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/georgia-atlanta",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/georgia-atlanta",
    "template": "new-individual-locations",
    "section": "georgia-atlanta"
  },
  {
    "name": "Princeton",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/new-jersey/princeton",
    "url": "/locations/north-america/united-states/new-jersey/princeton",
    "template": "new-individual-locations",
    "section": "new-jersey"
  },
  {
    "name": "Oregon Portland",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oregon-portland",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oregon-portland",
    "template": "new-individual-locations",
    "section": "oregon-portland"
  },
  {
    "name": "Mississippi",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/mississippi",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/mississippi",
    "template": "new-individual-locations",
    "section": "mississippi"
  },
  {
    "name": "Kentucky",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/kentucky",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/kentucky",
    "template": "new-individual-locations",
    "section": "kentucky"
  },
  {
    "name": "Cleveland",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/ohio/cleveland",
    "url": "/locations/north-america/united-states/ohio/cleveland",
    "template": "new-individual-locations",
    "section": "ohio"
  },
  {
    "name": "Nebraska",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/nebraska",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/nebraska",
    "template": "new-individual-locations",
    "section": "nebraska"
  },
  {
    "name": "Baton Rouge",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/louisiana/baton-rouge",
    "url": "/locations/north-america/united-states/louisiana/baton-rouge",
    "template": "new-individual-locations",
    "section": "louisiana"
  },
  {
    "name": "Brisbane (ASK)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/brisbane-(ask)",
    "url": "/locations/australia-location/australia/Queensland/brisbane-(ask)",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Brisbane (ASK)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/brisbane-(ask)",
    "url": "/locations/australia/australia/Queensland/brisbane-ask",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Hawaii",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/hawaii",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/hawaii",
    "template": "new-individual-locations",
    "section": "hawaii"
  },
  {
    "name": "Vermont",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/vermont",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/vermont",
    "template": "new-individual-locations",
    "section": "vermont"
  },
  {
    "name": "Bakersfield",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/bakersfield",
    "url": "/locations/north-america/united-states/california/bakersfield",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Ontario Georgetown",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ontario-georgetown",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ontario-georgetown",
    "template": "new-individual-locations",
    "section": "ontario-georgetown"
  },
  {
    "name": "Utah",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/utah",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/utah",
    "template": "new-individual-locations",
    "section": "utah"
  },
  {
    "name": "Boston",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/massachusetts/boston",
    "url": "/locations/north-america/united-states/massachusetts/boston",
    "template": "new-individual-locations",
    "section": "massachusetts"
  },
  {
    "name": "Hyderabad",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/india/telangana/hyderabad",
    "url": "/locations/asia/india/telangana/hyderabad",
    "template": "new-individual-locations",
    "section": "telangana"
  },
  {
    "name": "Utah",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/united-states/utah",
    "url": "/location/north-america/united-states/utah",
    "template": "new-individual-locations",
    "section": "utah"
  },
  {
    "name": "New Mexico Albuquerque",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-mexico-albuquerque",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-mexico-albuquerque",
    "template": "new-individual-locations",
    "section": "new-mexico-albuquerque"
  },
  {
    "name": "OfficeName",
    "apiurl": "/restapi/customlocationcontentlvl3/default-mainlocation/northamerica/unitedstates/texas/dallas",
    "url": "/default-mainlocation/northamerica/unitedstates/texas/dallas",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "OfficeName",
    "apiurl": "/restapi/customlocationcontentlvl3/default-mainlocation/northamerica/unitedstates/texas/dallas",
    "url": "/northamerica/unitedstates/texas/dallas",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Ontario",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/canada/ontario",
    "url": "/location/north-america/canada/ontario",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Guangzhou",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/guangzhou",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/guangzhou",
    "template": "new-individual-locations",
    "section": "guangzhou"
  },
  {
    "name": "Missouri St. Louis",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/missouri-st.-louis",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/missouri-st.-louis",
    "template": "new-individual-locations",
    "section": "missouri-st.-louis"
  },
  {
    "name": "Orlando",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/florida/orlando",
    "url": "/locations/north-america/united-states/florida/orlando",
    "template": "new-individual-locations",
    "section": "florida"
  },
  {
    "name": "Kansas City",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/kansas/kansas-city",
    "url": "/locations/north-america/united-states/kansas/kansas-city",
    "template": "new-individual-locations",
    "section": "kansas"
  },
  {
    "name": "Pune",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/india/maharashtra/pune",
    "url": "/locations/asia/india/maharashtra/pune",
    "template": "new-individual-locations",
    "section": "maharashtra"
  },
  {
    "name": "Virginia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/virginia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/virginia",
    "template": "new-individual-locations",
    "section": "virginia"
  },
  {
    "name": "Alaska",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/alaska",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/alaska",
    "template": "new-individual-locations",
    "section": "alaska"
  },
  {
    "name": "Beijing",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/china/asia-pacific-state/beijing",
    "url": "/locations/asia/china/asia-pacific-state/beijing",
    "template": "new-individual-locations",
    "section": "asia-pacific-state"
  },
  {
    "name": "Belfast",
    "apiurl": "/restapi/customlocationcontentlvl3/location/united-kingdom/united-kingdom/belfast",
    "url": "/location/united-kingdom/united-kingdom/belfast",
    "template": "new-individual-locations",
    "section": "belfast"
  },
  {
    "name": "Ontario",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ontario",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ontario",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Australia Location Information 1",
    "apiurl": "/restapi/customlocationcontentlvl3/default-mainlocation/australia/australia/austalia-test-1/australia-location-information-1",
    "url": "/default-mainlocation/australia/australia/austalia-test-1/australia-location-information-1",
    "template": "new-individual-locations",
    "section": "austalia-test-1"
  },
  {
    "name": "Australia Location Information 1",
    "apiurl": "/restapi/customlocationcontentlvl3/default-mainlocation/australia/australia/austalia-test-1/australia-location-information-1",
    "url": "/australia/australia/austalia-test-1/australia-location-information-1",
    "template": "new-individual-locations",
    "section": "austalia-test-1"
  },
  {
    "name": "Pennsylvania",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/pennsylvania",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/pennsylvania",
    "template": "new-individual-locations",
    "section": "pennsylvania"
  },
  {
    "name": "Idaho",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/idaho",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/idaho",
    "template": "new-individual-locations",
    "section": "idaho"
  },
  {
    "name": "New Orleans",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/louisiana/new-orleans",
    "url": "/locations/north-america/united-states/louisiana/new-orleans",
    "template": "new-individual-locations",
    "section": "louisiana"
  },
  {
    "name": "Massachusetts Boston",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/massachusetts-boston",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/massachusetts-boston",
    "template": "new-individual-locations",
    "section": "massachusetts-boston"
  },
  {
    "name": "Minnesota",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/minnesota",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/minnesota",
    "template": "new-individual-locations",
    "section": "minnesota"
  },
  {
    "name": "Illinois",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/illinois",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/illinois",
    "template": "new-individual-locations",
    "section": "illinois"
  },
  {
    "name": "Mississippi Jackson",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/mississippi-jackson",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/mississippi-jackson",
    "template": "new-individual-locations",
    "section": "mississippi-jackson"
  },
  {
    "name": "Louisiana New Orleans",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/louisiana-new-orleans",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/louisiana-new-orleans",
    "template": "new-individual-locations",
    "section": "louisiana-new-orleans"
  },
  {
    "name": "Colorado Denver",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/colorado-denver",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/colorado-denver",
    "template": "new-individual-locations",
    "section": "colorado-denver"
  },
  {
    "name": "Roanoke",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/virginia/roanoke",
    "url": "/locations/north-america/united-states/virginia/roanoke",
    "template": "new-individual-locations",
    "section": "virginia"
  },
  {
    "name": "Connecticut",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/connecticut",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/connecticut",
    "template": "new-individual-locations",
    "section": "connecticut"
  },
  {
    "name": "Liverpool",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/liverpool",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/liverpool",
    "template": "new-individual-locations",
    "section": "liverpool"
  },
  {
    "name": "Rhode Island",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/rhode-island",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/rhode-island",
    "template": "new-individual-locations",
    "section": "rhode-island"
  },
  {
    "name": "Georgetown",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/georgetown",
    "url": "/locations/north-america/canada/ontario/georgetown",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Georgetown",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/georgetown",
    "url": "/locations/north-america/canada/toronto/georgetown",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Frederick",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/maryland/maryland",
    "url": "/locations/north-america/united-states/maryland/maryland",
    "template": "new-individual-locations",
    "section": "maryland"
  },
  {
    "name": "Frederick",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/maryland/maryland",
    "url": "/locations/north-america/united-states/washington-dc/maryland",
    "template": "new-individual-locations",
    "section": "maryland"
  },
  {
    "name": "Brisbane (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/brisbane-ane",
    "url": "/locations/australia-location/australia/Queensland/brisbane-ane",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Brisbane (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/brisbane-ane",
    "url": "/locations/australia/australia/Queensland/brisbane-ane",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Bahrain",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/bahrain",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/bahrain",
    "template": "new-individual-locations",
    "section": "bahrain"
  },
  {
    "name": "Victoria",
    "apiurl": "/restapi/customlocationcontentlvl3/location/australia/australia/victoria",
    "url": "/location/australia/australia/victoria",
    "template": "new-individual-locations",
    "section": "victoria"
  },
  {
    "name": "Montana",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/montana",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/montana",
    "template": "new-individual-locations",
    "section": "montana"
  },
  {
    "name": "Sydney (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/new-south-wales/sydney-australia-(ane)",
    "url": "/locations/australia-location/australia/new-south-wales/sydney-australia-(ane)",
    "template": "new-individual-locations",
    "section": "new-south-wales"
  },
  {
    "name": "Sydney (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/new-south-wales/sydney-australia-(ane)",
    "url": "/locations/australia/australia/new-south-wales/sydney",
    "template": "new-individual-locations",
    "section": "new-south-wales"
  },
  {
    "name": "Sydney (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/new-south-wales/sydney-australia-(ane)",
    "url": "/locations/australia/australia/new-south-wales/sydney-australia-ane",
    "template": "new-individual-locations",
    "section": "new-south-wales"
  },
  {
    "name": "Sydney (ANE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/new-south-wales/sydney-australia-(ane)",
    "url": "/locations/australia/australia/new-south-wales/sydney-australia",
    "template": "new-individual-locations",
    "section": "new-south-wales"
  },
  {
    "name": "Illinois Chicago",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/illinois-chicago",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/illinois-chicago",
    "template": "new-individual-locations",
    "section": "illinois-chicago"
  },
  {
    "name": "Minneapolis",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/minnesota/oakdale",
    "url": "/locations/north-america/united-states/minnesota/oakdale",
    "template": "new-individual-locations",
    "section": "minnesota"
  },
  {
    "name": "Minneapolis",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/minnesota/oakdale",
    "url": "/locations/north-america/united-states/minnesota/minneapolis",
    "template": "new-individual-locations",
    "section": "minnesota"
  },
  {
    "name": "New York City",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-york-city",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-york-city",
    "template": "new-individual-locations",
    "section": "new-york-city"
  },
  {
    "name": "Hainan",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/hainan",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/hainan",
    "template": "new-individual-locations",
    "section": "hainan"
  },
  {
    "name": "Texas Corpus Christi",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/texas-corpus-christi",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/texas-corpus-christi",
    "template": "new-individual-locations",
    "section": "texas-corpus-christi"
  },
  {
    "name": "Ohio",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ohio",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ohio",
    "template": "new-individual-locations",
    "section": "ohio"
  },
  {
    "name": "California Oakland",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california-oakland",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california-oakland",
    "template": "new-individual-locations",
    "section": "california-oakland"
  },
  {
    "name": "Nevada",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/nevada",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/nevada",
    "template": "new-individual-locations",
    "section": "nevada"
  },
  {
    "name": "Utah Salt Lake City",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/utah-salt-lake-city",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/utah-salt-lake-city",
    "template": "new-individual-locations",
    "section": "utah-salt-lake-city"
  },
  {
    "name": "Portland",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/oregon/portland",
    "url": "/locations/north-america/united-states/oregon/portland",
    "template": "new-individual-locations",
    "section": "oregon"
  },
  {
    "name": "Dallas",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/united-states/dallas",
    "url": "/location/north-america/united-states/dallas",
    "template": "new-individual-locations",
    "section": "dallas"
  },
  {
    "name": "Victoria",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/british-columbia/victoria",
    "url": "/locations/north-america/canada/british-columbia/victoria",
    "template": "new-individual-locations",
    "section": "british-columbia"
  },
  {
    "name": "Philadelphia",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/pennsylvania/philadelphia",
    "url": "/locations/north-america/united-states/pennsylvania/philadelphia",
    "template": "new-individual-locations",
    "section": "pennsylvania"
  },
  {
    "name": "Texas Dallas",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/texas-dallas",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/texas-dallas",
    "template": "new-individual-locations",
    "section": "texas-dallas"
  },
  {
    "name": "North Carolina Raleigh",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/north-carolina-raleigh",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/north-carolina-raleigh",
    "template": "new-individual-locations",
    "section": "north-carolina-raleigh"
  },
  {
    "name": "North Carolina Raleigh",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/north-carolina-raleigh",
    "url": "/location/north-america/north-carolina",
    "template": "new-individual-locations",
    "section": "north-carolina-raleigh"
  },
  {
    "name": "Washington",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/washington",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/washington",
    "template": "new-individual-locations",
    "section": "washington"
  },
  {
    "name": "California",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Lawrence",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/massachusetts/lawrence",
    "url": "/locations/north-america/united-states/massachusetts/lawrence",
    "template": "new-individual-locations",
    "section": "massachusetts"
  },
  {
    "name": "Ohio Columbus",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ohio-columbus",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ohio-columbus",
    "template": "new-individual-locations",
    "section": "ohio-columbus"
  },
  {
    "name": "Texas",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/texas",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/texas",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Wyoming",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/wyoming",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/wyoming",
    "template": "new-individual-locations",
    "section": "wyoming"
  },
  {
    "name": "Plano",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/united-states/plano",
    "url": "/location/north-america/united-states/plano",
    "template": "new-individual-locations",
    "section": "plano"
  },
  {
    "name": "Birmingham",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/alabama/birmingham",
    "url": "/locations/north-america/united-states/alabama/birmingham",
    "template": "new-individual-locations",
    "section": "alabama"
  },
  {
    "name": "North Carolina Charlotte",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/north-carolina-charlotte",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/north-carolina-charlotte",
    "template": "new-individual-locations",
    "section": "north-carolina-charlotte"
  },
  {
    "name": "Raleigh",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/north-carolina/raleigh",
    "url": "/locations/north-america/united-states/north-carolina/raleigh",
    "template": "new-individual-locations",
    "section": "north-carolina"
  },
  {
    "name": "New Mexico",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-mexico",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-mexico",
    "template": "new-individual-locations",
    "section": "new-mexico"
  },
  {
    "name": "Dublin",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/europe/ireland/dublin/dublin",
    "url": "/locations/europe/ireland/dublin/dublin",
    "template": "new-individual-locations",
    "section": "dublin"
  },
  {
    "name": "Iowa Burlington",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/iowa-burlington",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/iowa-burlington",
    "template": "new-individual-locations",
    "section": "iowa-burlington"
  },
  {
    "name": "Brisbane Australia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/brisbane-australia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/brisbane-australia",
    "template": "new-individual-locations",
    "section": "brisbane-australia"
  },
  {
    "name": "Frisco",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/united-states/frisco",
    "url": "/location/north-america/united-states/frisco",
    "template": "new-individual-locations",
    "section": "frisco"
  },
  {
    "name": "Wisconsin Milwaukee",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/wisconsin-milwaukee",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/wisconsin-milwaukee",
    "template": "new-individual-locations",
    "section": "wisconsin-milwaukee"
  },
  {
    "name": "Jackson",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/mississippi/jackson",
    "url": "/locations/north-america/united-states/mississippi/jackson",
    "template": "new-individual-locations",
    "section": "mississippi"
  },
  {
    "name": "Iowa Des Moines",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/iowa-des-moines",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/iowa-des-moines",
    "template": "new-individual-locations",
    "section": "iowa-des-moines"
  },
  {
    "name": "Iowa",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/iowa",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/iowa",
    "template": "new-individual-locations",
    "section": "iowa"
  },
  {
    "name": "Oklahoma City",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oklahoma-city",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oklahoma-city",
    "template": "new-individual-locations",
    "section": "oklahoma-city"
  },
  {
    "name": "Ventura",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/ventura",
    "url": "/locations/north-america/united-states/california/ventura",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Louisiana Baton Rouge",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/louisiana-baton-rouge",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/louisiana-baton-rouge",
    "template": "new-individual-locations",
    "section": "louisiana-baton-rouge"
  },
  {
    "name": "West Des Moines",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/iowa/west-des-moines",
    "url": "/locations/north-america/united-states/iowa/west-des-moines",
    "template": "new-individual-locations",
    "section": "iowa"
  },
  {
    "name": "West Des Moines",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/iowa/west-des-moines",
    "url": "/locations/iowa/des-moines",
    "template": "new-individual-locations",
    "section": "iowa"
  },
  {
    "name": "Corpus Christi",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/corpus-christi",
    "url": "/locations/north-america/united-states/texas/corpus-christi",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Corpus Christi",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/corpus-christi",
    "url": "/locations/north-america/united-states/texas/office-information",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Mountain View",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/mountain-view",
    "url": "/locations/north-america/united-states/california/mountain-view",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Puerto Rico",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/puerto-rico",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/puerto-rico",
    "template": "new-individual-locations",
    "section": "puerto-rico"
  },
  {
    "name": "Hainan Province - Hainan",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/china/asia-pacific-state/hainan",
    "url": "/locations/asia/china/asia-pacific-state/hainan",
    "template": "new-individual-locations",
    "section": "asia-pacific-state"
  },
  {
    "name": "Alabama",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/alabama",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/alabama",
    "template": "new-individual-locations",
    "section": "alabama"
  },
  {
    "name": "Oklahoma Tulsa",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oklahoma-tulsa",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oklahoma-tulsa",
    "template": "new-individual-locations",
    "section": "oklahoma-tulsa"
  },
  {
    "name": "Cheyenne",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/wyoming/cheyenne",
    "url": "/locations/north-america/united-states/wyoming/cheyenne",
    "template": "new-individual-locations",
    "section": "wyoming"
  },
  {
    "name": "Kentucky Covington",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/kentucky-covington",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/kentucky-covington",
    "template": "new-individual-locations",
    "section": "kentucky-covington"
  },
  {
    "name": "Hainan",
    "apiurl": "/restapi/customlocationcontentlvl3/location/china/china/hainan",
    "url": "/location/china/china/hainan",
    "template": "new-individual-locations",
    "section": "hainan"
  },
  {
    "name": "Pennsylvania Pittsburgh",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/pennsylvania-pittsburgh",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/pennsylvania-pittsburgh",
    "template": "new-individual-locations",
    "section": "pennsylvania-pittsburgh"
  },
  {
    "name": "Sacramento",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/sacramento",
    "url": "/locations/north-america/united-states/california/sacramento",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Hampshire",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/europe/europe-country/europe-state/hampshire",
    "url": "/locations/europe/europe-country/europe-state/hampshire",
    "template": "new-individual-locations",
    "section": "europe-state"
  },
  {
    "name": "Indiana Indianapolis",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/indiana-indianapolis",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/indiana-indianapolis",
    "template": "new-individual-locations",
    "section": "indiana-indianapolis"
  },
  {
    "name": "New Jersey Princeton",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-jersey-princeton",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-jersey-princeton",
    "template": "new-individual-locations",
    "section": "new-jersey-princeton"
  },
  {
    "name": "New York Albany",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-york-albany",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-york-albany",
    "template": "new-individual-locations",
    "section": "new-york-albany"
  },
  {
    "name": "Alabama Birmingham",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/alabama-birmingham",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/alabama-birmingham",
    "template": "new-individual-locations",
    "section": "alabama-birmingham"
  },
  {
    "name": "Tennessee",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/tennessee",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/tennessee",
    "template": "new-individual-locations",
    "section": "tennessee"
  },
  {
    "name": "Idaho Boise",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/idaho-boise",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/idaho-boise",
    "template": "new-individual-locations",
    "section": "idaho-boise"
  },
  {
    "name": "Michigan",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/michigan",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/michigan",
    "template": "new-individual-locations",
    "section": "michigan"
  },
  {
    "name": "Great Falls",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/montana/great-falls",
    "url": "/locations/north-america/united-states/montana/great-falls",
    "template": "new-individual-locations",
    "section": "montana"
  },
  {
    "name": "Michigan Ann Arbor",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/michigan-ann-arbor",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/michigan-ann-arbor",
    "template": "new-individual-locations",
    "section": "michigan-ann-arbor"
  },
  {
    "name": "Gladstone (VE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/gladstone-ve",
    "url": "/locations/australia-location/australia/Queensland/gladstone-ve",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Gladstone (VE)",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/australia-location/australia/Queensland/gladstone-ve",
    "url": "/locations/australia/australia/Queensland/gladstone-ve",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Colorado",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/colorado",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/colorado",
    "template": "new-individual-locations",
    "section": "colorado"
  },
  {
    "name": "Charleston",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/west-virginia/west-virginia",
    "url": "/locations/north-america/united-states/west-virginia/west-virginia",
    "template": "new-individual-locations",
    "section": "west-virginia"
  },
  {
    "name": "Charleston",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/west-virginia/west-virginia",
    "url": "/locations/north-america/united-states/west-virginia/west-virginia---test",
    "template": "new-individual-locations",
    "section": "west-virginia"
  },
  {
    "name": "Austin",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/austin",
    "url": "/locations/north-america/united-states/texas/austin",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "Albuquerque",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/new-mexico/albuquerque",
    "url": "/locations/north-america/united-states/new-mexico/albuquerque",
    "template": "new-individual-locations",
    "section": "new-mexico"
  },
  {
    "name": "South Carolina",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/south-carolina",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/south-carolina",
    "template": "new-individual-locations",
    "section": "south-carolina"
  },
  {
    "name": "Maryland",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/maryland",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/maryland",
    "template": "new-individual-locations",
    "section": "maryland"
  },
  {
    "name": "Virginia Charlottesville",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/virginia-charlottesville",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/virginia-charlottesville",
    "template": "new-individual-locations",
    "section": "virginia-charlottesville"
  },
  {
    "name": "Bury St. Edmunds",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/europe/europe-country/suffolk/bury-st.-edmunds",
    "url": "/locations/europe/europe-country/suffolk/bury-st.-edmunds",
    "template": "new-individual-locations",
    "section": "suffolk"
  },
  {
    "name": "Ontario Guelph",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/ontario-guelph",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/ontario-guelph",
    "template": "new-individual-locations",
    "section": "ontario-guelph"
  },
  {
    "name": "Denver",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/colorado/denver",
    "url": "/locations/north-america/united-states/colorado/denver",
    "template": "new-individual-locations",
    "section": "colorado"
  },
  {
    "name": "Denver",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/colorado/denver",
    "url": "/colorado-denver",
    "template": "new-individual-locations",
    "section": "colorado"
  },
  {
    "name": "Massachusetts",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/massachusetts",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/massachusetts",
    "template": "new-individual-locations",
    "section": "massachusetts"
  },
  {
    "name": "Oregon",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/oregon",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/oregon",
    "template": "new-individual-locations",
    "section": "oregon"
  },
  {
    "name": "Knoxville",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/tennessee/knoxville",
    "url": "/locations/north-america/united-states/tennessee/knoxville",
    "template": "new-individual-locations",
    "section": "tennessee"
  },
  {
    "name": "Knoxville",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/tennessee/knoxville",
    "url": "/tennessee",
    "template": "new-individual-locations",
    "section": "tennessee"
  },
  {
    "name": "San Diego",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/san-diego",
    "url": "/locations/north-america/united-states/california/san-diego",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Indianapolis",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/indiana/indianapolis",
    "url": "/locations/north-america/united-states/indiana/indianapolis",
    "template": "new-individual-locations",
    "section": "indiana"
  },
  {
    "name": "Quebec",
    "apiurl": "/restapi/customlocationcontentlvl3/location/north-america/canada/quebec",
    "url": "/location/north-america/canada/quebec",
    "template": "new-individual-locations",
    "section": "quebec"
  },
  {
    "name": "Tulsa",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/oklahoma/tulsa",
    "url": "/locations/north-america/united-states/oklahoma/tulsa",
    "template": "new-individual-locations",
    "section": "oklahoma"
  },
  {
    "name": "St. Louis",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/missouri/st.-louis",
    "url": "/locations/north-america/united-states/missouri/st.-louis",
    "template": "new-individual-locations",
    "section": "missouri"
  },
  {
    "name": "San Ramon",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/california/san-ramon",
    "url": "/locations/north-america/united-states/california/san-ramon",
    "template": "new-individual-locations",
    "section": "california"
  },
  {
    "name": "Columbus",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/ohio/columbus",
    "url": "/locations/north-america/united-states/ohio/columbus",
    "template": "new-individual-locations",
    "section": "ohio"
  },
  {
    "name": "West Virginia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/west-virginia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/west-virginia",
    "template": "new-individual-locations",
    "section": "west-virginia"
  },
  {
    "name": "Xi'an",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/asia/china/asia-pacific-state/xi'an",
    "url": "/locations/asia/china/asia-pacific-state/xi'an",
    "template": "new-individual-locations",
    "section": "asia-pacific-state"
  },
  {
    "name": "Atlanta",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/georgia/atlanta",
    "url": "/locations/north-america/united-states/georgia/atlanta",
    "template": "new-individual-locations",
    "section": "georgia"
  },
  {
    "name": "Houston",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/texas/houston",
    "url": "/locations/north-america/united-states/texas/houston",
    "template": "new-individual-locations",
    "section": "texas"
  },
  {
    "name": "New York City",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/new-york/new-york-city-office",
    "url": "/locations/north-america/united-states/new-york/new-york-city-office",
    "template": "new-individual-locations",
    "section": "new-york"
  },
  {
    "name": "Toronto",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/toronto",
    "url": "/locations/north-america/canada/ontario/toronto",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Toronto",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/canada/ontario/toronto",
    "url": "/locations/north-america/canada/toronto/toronto",
    "template": "new-individual-locations",
    "section": "ontario"
  },
  {
    "name": "Beijing",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/beijing",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/beijing",
    "template": "new-individual-locations",
    "section": "beijing"
  },
  {
    "name": "California Irvine",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/california-irvine",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/california-irvine",
    "template": "new-individual-locations",
    "section": "california-irvine"
  },
  {
    "name": "Queensland",
    "apiurl": "/restapi/customlocationcontentlvl3/location/australia/australia/queensland",
    "url": "/location/australia/australia/queensland",
    "template": "new-individual-locations",
    "section": "queensland"
  },
  {
    "name": "Ann Arbor",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/michigan/ann-arbor",
    "url": "/locations/north-america/united-states/michigan/ann-arbor",
    "template": "new-individual-locations",
    "section": "michigan"
  },
  {
    "name": "New Hampshire",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/new-hampshire",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/new-hampshire",
    "template": "new-individual-locations",
    "section": "new-hampshire"
  },
  {
    "name": "Milwaukee",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/wisconsin/milwaukee",
    "url": "/locations/north-america/united-states/wisconsin/milwaukee",
    "template": "new-individual-locations",
    "section": "wisconsin"
  },
  {
    "name": "Texas Austin",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/texas-austin",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/texas-austin",
    "template": "new-individual-locations",
    "section": "texas-austin"
  },
  {
    "name": "Georgia",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/georgia",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/georgia",
    "template": "new-individual-locations",
    "section": "georgia"
  },
  {
    "name": "Burlington",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/iowa/burlington",
    "url": "/locations/north-america/united-states/iowa/burlington",
    "template": "new-individual-locations",
    "section": "iowa"
  },
  {
    "name": "Little Rock",
    "apiurl": "/restapi/customlocationcontentlvl3/locations/north-america/united-states/arkansas/little-rock",
    "url": "/locations/north-america/united-states/arkansas/little-rock",
    "template": "new-individual-locations",
    "section": "arkansas"
  },
  {
    "name": "Texas Houston",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/texas-houston",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/texas-houston",
    "template": "new-individual-locations",
    "section": "texas-houston"
  },
  {
    "name": "Maine",
    "apiurl": "/restapi/customlocationcontentlvl3/default-location/default-locationcategory/default-locationsubcategory/maine",
    "url": "/default-location/default-locationcategory/default-locationsubcategory/maine",
    "template": "new-individual-locations",
    "section": "maine"
  },
  {
    "name": "2019",
    "apiurl": "/restapi/customcontent/contact-us/2019",
    "url": "/contact-us/2019",
    "template": "general_content",
    "section": "2019"
  },
  {
    "name": "2020",
    "apiurl": "/restapi/customcontent/contact-us/2020",
    "url": "/contact-us/2020",
    "template": "general_content",
    "section": "2020"
  },
  {
    "name": "2013",
    "apiurl": "/restapi/customcontent/contact-us/2013",
    "url": "/contact-us/2013",
    "template": "general_content",
    "section": "2013"
  },
  {
    "name": "2010",
    "apiurl": "/restapi/customcontent/contact-us/2010",
    "url": "/contact-us/2010",
    "template": "general_content",
    "section": "2010"
  },
  {
    "name": "2014",
    "apiurl": "/restapi/customcontent/contact-us/2014",
    "url": "/contact-us/2014",
    "template": "general_content",
    "section": "2014"
  },
  {
    "name": "2012",
    "apiurl": "/restapi/customcontent/contact-us/2012",
    "url": "/contact-us/2012",
    "template": "general_content",
    "section": "2012"
  },
  {
    "name": "2018",
    "apiurl": "/restapi/customcontent/contact-us/2018",
    "url": "/contact-us/2018",
    "template": "general_content",
    "section": "2018"
  },
  {
    "name": "2017",
    "apiurl": "/restapi/customcontent/contact-us/2017",
    "url": "/contact-us/2017",
    "template": "general_content",
    "section": "2017"
  },
  {
    "name": "2015",
    "apiurl": "/restapi/customcontent/contact-us/2015",
    "url": "/contact-us/2015",
    "template": "general_content",
    "section": "2015"
  },
  {
    "name": "2011",
    "apiurl": "/restapi/customcontent/contact-us/2011",
    "url": "/contact-us/2011",
    "template": "general_content",
    "section": "2011"
  },
  {
    "name": "2016",
    "apiurl": "/restapi/customcontent/contact-us/2016",
    "url": "/contact-us/2016",
    "template": "general_content",
    "section": "2016"
  },
  {
    "name": "2021",
    "apiurl": "/restapi/customcontent/contact-us/2021",
    "url": "/contact-us/2021",
    "template": "general_content",
    "section": "2021"
  },
  {
    "name": "Request Consulting Quote",
    "apiurl": "/restapi/customcontent/request-a-quote",
    "url": "/request-a-quote",
    "template": "general_content",
    "section": "request-consulting-quote"
  },
  {
    "name": "Request Consulting Quote",
    "apiurl": "/restapi/customcontent/request-a-quote",
    "url": "/request-data-quote",
    "template": "general_content",
    "section": "request-consulting-quote"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/3d-analyst",
    "url": "/software/air-dispersion---data-visualization/3d-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/3d-analyst",
    "url": "/software/air-dispersion---data/3d-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/3d-analyst",
    "url": "/software/air-dispersion-data-visualization/3d-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "3D Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/3d-analyst",
    "url": "/software/products/3d-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "AERMOD",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aermod",
    "url": "/software/dispersion/aermod",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "AERMOD",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aermod",
    "url": "/software/products/aermod/",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/downwash-analyst",
    "url": "/software/air-dispersion---data-visualization/downwash-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/downwash-analyst",
    "url": "/software/air-dispersion---data/downwash-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/downwash-analyst",
    "url": "/software/products/downwash-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "Downwash Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/downwash-analyst",
    "url": "/software/air-dispersion-data-visualization/downwash-analyst",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/hazard/incident-analyst",
    "url": "/software/hazard/incident-analyst",
    "template": "new-software-product",
    "section": "hazard"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/hazard/incident-analyst",
    "url": "/software/products/incident-analyst",
    "template": "new-software-product",
    "section": "hazard"
  },
  {
    "name": "Incident Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/hazard/incident-analyst",
    "url": "/software/incident-analyst",
    "template": "new-software-product",
    "section": "hazard"
  },
  {
    "name": "LFG Fire/Risk",
    "apiurl": "/restapi/customsoftwareproductcontent/software/hazard/lfg-fire-risk",
    "url": "/software/hazard/lfg-fire-risk",
    "template": "new-software-product",
    "section": "hazard"
  },
  {
    "name": "LFG Fire/Risk",
    "apiurl": "/restapi/customsoftwareproductcontent/software/hazard/lfg-fire-risk",
    "url": "/software/products/lfg-fire-risk",
    "template": "new-software-product",
    "section": "hazard"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customsoftwareproductcontent/software/explosion/vasdip",
    "url": "/software/explosion/vasdip",
    "template": "new-software-product",
    "section": "explosion"
  },
  {
    "name": "VASDIP",
    "apiurl": "/restapi/customsoftwareproductcontent/software/explosion/vasdip",
    "url": "/software/products/vasdip",
    "template": "new-software-product",
    "section": "explosion"
  },
  {
    "name": "SCREEN3",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/screen3",
    "url": "/software/dispersion/screen3",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "SCREEN3",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/screen3",
    "url": "/software/products/screen3",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "Risk Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/health-risk/risk-analyst",
    "url": "/software/health-risk/risk-analyst",
    "template": "new-software-product",
    "section": "health-risk"
  },
  {
    "name": "Risk Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/health-risk/risk-analyst",
    "url": "/software/Risk-Analyst",
    "template": "new-software-product",
    "section": "health-risk"
  },
  {
    "name": "Risk Analyst",
    "apiurl": "/restapi/customsoftwareproductcontent/software/health-risk/risk-analyst",
    "url": "/software/products/risk-analyst",
    "template": "new-software-product",
    "section": "health-risk"
  },
  {
    "name": "SANGEA",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/sangea",
    "url": "/software/dispersion/sangea",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "AERSCREEN",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aerscreen",
    "url": "/software/dispersion/aerscreen",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "AERSCREEN",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aerscreen",
    "url": "/software/products/aerscreen",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "CALPUFF",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/calpuff",
    "url": "/software/dispersion/calpuff",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "CALPUFF",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/calpuff",
    "url": "/software/products/calpuff",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "ROADS",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/roads",
    "url": "/software/dispersion/roads",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "ROADS",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/roads",
    "url": "/software/products/roads",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "ExDAM",
    "apiurl": "/restapi/customsoftwareproductcontent/software/explosion/exdam",
    "url": "/software/explosion/exdam",
    "template": "new-software-product",
    "section": "explosion"
  },
  {
    "name": "ExDAM",
    "apiurl": "/restapi/customsoftwareproductcontent/software/explosion/exdam",
    "url": "/software/exdam",
    "template": "new-software-product",
    "section": "explosion"
  },
  {
    "name": "ExDAM",
    "apiurl": "/restapi/customsoftwareproductcontent/software/explosion/exdam",
    "url": "/software/products/exdam",
    "template": "new-software-product",
    "section": "explosion"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/metview",
    "url": "/software/air-dispersion---data-visualization/metview",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/metview",
    "url": "/software/air-dispersion-data-visualization/metview",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/metview",
    "url": "/software/air-dispersion---data/metview",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "MetView",
    "apiurl": "/restapi/customsoftwareproductcontent/software/air-dispersion---data-visualization/metview",
    "url": "/software/products/metview",
    "template": "new-software-product",
    "section": "data-analysis"
  },
  {
    "name": "AERMET",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aermet",
    "url": "/software/dispersion/aermet",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "AERMET",
    "apiurl": "/restapi/customsoftwareproductcontent/software/dispersion/aermet",
    "url": "/software/products/aermet",
    "template": "new-software-product",
    "section": "air-dispersion"
  },
  {
    "name": "ESP+",
    "apiurl": "/restapi/customsoftwareproductcontent/software/tanks/esp",
    "url": "/software/tanks/esp",
    "template": "new-software-product",
    "section": "tanks/-terminals"
  },
  {
    "name": "ESP+",
    "apiurl": "/restapi/customsoftwareproductcontent/software/tanks/esp",
    "url": "/esp-plus",
    "template": "new-software-product",
    "section": "tanks/-terminals"
  },
  {
    "name": "ESP+",
    "apiurl": "/restapi/customsoftwareproductcontent/software/tanks/esp",
    "url": "/software/products/esp",
    "template": "new-software-product",
    "section": "tanks/-terminals"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customsoftwareproductcontent/software/tanks/tankesp",
    "url": "/software/tanks/tankesp",
    "template": "new-software-product",
    "section": "tanks/-terminals"
  },
  {
    "name": "TankESP",
    "apiurl": "/restapi/customsoftwareproductcontent/software/tanks/tankesp",
    "url": "/software/products/tankesp",
    "template": "new-software-product",
    "section": "tanks/-terminals"
  },
  {
    "name": "Experienced",
    "apiurl": "/restapi/customcontent/contact-us/experienced",
    "url": "/contact-us/experienced",
    "template": "general_content",
    "section": "experienced"
  },
  {
    "name": "Intern",
    "apiurl": "/restapi/customcontent/contact-us/intern",
    "url": "/contact-us/intern",
    "template": "general_content",
    "section": "intern"
  },
  {
    "name": "Entry-Level",
    "apiurl": "/restapi/customcontent/contact-us/entry-level",
    "url": "/contact-us/entry-level",
    "template": "general_content",
    "section": "entry-level"
  },
  {
    "name": "Search Chemical Analytical Methods",
    "apiurl": "/restapi/customlaboratorysubcategoriescontent/search-chemical-analytical-methods",
    "url": "/consulting/science/industrial-hygiene-analytical-laboratory/analytical-methods/search-chemical-analytical-methods",
    "template": "new-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "Search Chemical Analytical Methods",
    "apiurl": "/restapi/customlaboratorysubcategoriescontent/search-chemical-analytical-methods",
    "url": "/search-chemical-analytical-methods",
    "template": "new-safebridge-landing",
    "section": "search-chemical-analytical-methods"
  },
  {
    "name": "EHS Quarterly Issue 5",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-5",
    "url": "/publications/ehs-quarterly-issue-5",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-5"
  },
  {
    "name": "Winter 2017 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2017-eq",
    "url": "/publications/winter-2017-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2017-eq"
  },
  {
    "name": "EHS Quarterly Issue 3",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-3",
    "url": "/publications/ehs-quarterly-issue-3",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-3"
  },
  {
    "name": "Spring 2013 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2013-eq",
    "url": "/publications/spring-2013-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2013-eq"
  },
  {
    "name": "Winter 2009 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2009-eq",
    "url": "/publications/winter-2009-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2009-eq"
  },
  {
    "name": "Spring 2011 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2011-eq",
    "url": "/publications/spring-2011-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2011-eq"
  },
  {
    "name": "Winter 2019 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2019-eq",
    "url": "/publications/winter-2019-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2019-eq"
  },
  {
    "name": "Winter 2014 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2014-eq",
    "url": "/publications/winter-2014-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2014-eq"
  },
  {
    "name": "Spring 2016 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2016-eq",
    "url": "/publications/spring-2016-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2016-eq"
  },
  {
    "name": "Fall 2011 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2011-eq",
    "url": "/publications/fall-2011-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2011-eq"
  },
  {
    "name": "Spring 2010 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2010-eq",
    "url": "/publications/spring-2010-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2010-eq"
  },
  {
    "name": "Summer 2014 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2014-eq",
    "url": "/publications/summer-2014-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2014-eq"
  },
  {
    "name": "Winter 2018 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2018-eq",
    "url": "/publications/winter-2018-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2018-eq"
  },
  {
    "name": "Summer 2016 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2016-eq",
    "url": "/publications/summer-2016-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2016-eq"
  },
  {
    "name": "Summer 2019 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2019-eq",
    "url": "/publications/summer-2019-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2019-eq"
  },
  {
    "name": "Summer 2010 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2010-eq",
    "url": "/publications/summer-2010-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2010-eq"
  },
  {
    "name": "EHS Quarterly Issue 11",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-11",
    "url": "/publications/ehs-quarterly-issue-11",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-11"
  },
  {
    "name": "EHS Quarterly Issue 1",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-1",
    "url": "/publications/ehs-quarterly-issue-1",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-1"
  },
  {
    "name": "EHS Quarterly Issue 2",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-2",
    "url": "/publications/ehs-quarterly-issue-2",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-2"
  },
  {
    "name": "Fall 2013 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2013-eq",
    "url": "/publications/fall-2013-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2013-eq"
  },
  {
    "name": "Winter 2015 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2015-eq",
    "url": "/publications/winter-2015-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2015-eq"
  },
  {
    "name": "EHS Quarterly Issue 12",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-12",
    "url": "/publications/ehs-quarterly-issue-12",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-12"
  },
  {
    "name": "Winter 2016 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2016-eq",
    "url": "/publications/winter-2016-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2016-eq"
  },
  {
    "name": "Fall 2015 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2015-eq",
    "url": "/publications/fall-2015-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2015-eq"
  },
  {
    "name": "Summer 2017 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2017-eq",
    "url": "/publications/summer-2017-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2017-eq"
  },
  {
    "name": "Summer 2011 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2011-eq",
    "url": "/publications/summer-2011-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2011-eq"
  },
  {
    "name": "EHS Quarterly Issue 10",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-10",
    "url": "/publications/ehs-quarterly-issue-10",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-10"
  },
  {
    "name": "Spring 2012 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2012-eq",
    "url": "/publications/spring-2012-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2012-eq"
  },
  {
    "name": "Spring 2018 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2018-eq",
    "url": "/publications/spring-2018-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2018-eq"
  },
  {
    "name": "Summer 2009 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2009-eq",
    "url": "/publications/summer-2009-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2009-eq"
  },
  {
    "name": "Fall 2016 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2016-eq",
    "url": "/publications/fall-2016-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2016-eq"
  },
  {
    "name": "Fall 2018 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2018-eq",
    "url": "/publications/fall-2018-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2018-eq"
  },
  {
    "name": "EHS Quarterly Issue 7",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-7",
    "url": "/publications/ehs-quarterly-issue-7",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-7"
  },
  {
    "name": "Summer 2012 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2012-eq",
    "url": "/publications/summer-2012-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2012-eq"
  },
  {
    "name": "EHS Quarterly Issue 6",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-6",
    "url": "/publications/ehs-quarterly-issue-6",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-6"
  },
  {
    "name": "EHS Quarterly Issue 8",
    "apiurl": "/restapi/customehssubpublication/publications/march-2022",
    "url": "/publications/march-2022",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-8"
  },
  {
    "name": "Spring 2017 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2017-eq",
    "url": "/publications/spring-2017-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2017-eq"
  },
  {
    "name": "Summer 2015 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2015-eq",
    "url": "/publications/summer-2015-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2015-eq"
  },
  {
    "name": "Fall 2017 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2017-eq",
    "url": "/publications/fall-2017-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2017-eq"
  },
  {
    "name": "Spring 2014 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2014-eq",
    "url": "/publications/spring-2014-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2014-eq"
  },
  {
    "name": "EHS Quarterly Issue 9",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-9",
    "url": "/publications/ehs-quarterly-issue-9",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-9"
  },
  {
    "name": "Summer 2018 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/summer-2018-eq",
    "url": "/publications/summer-2018-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "summer-2018-eq"
  },
  {
    "name": "Spring 2009 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2009-eq",
    "url": "/publications/spring-2009-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2009-eq"
  },
  {
    "name": "Winter 2011 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2011-eq",
    "url": "/publications/winter-2011-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2011-eq"
  },
  {
    "name": "Fall 2012 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2012-eq",
    "url": "/publications/fall-2012-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2012-eq"
  },
  {
    "name": "Fall 2010 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2010-eq",
    "url": "/publications/fall-2010-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2010-eq"
  },
  {
    "name": "Winter 2013 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/winter-2013",
    "url": "/publications/winter-2013",
    "template": "new-trinity-ehs-sub-publication",
    "section": "winter-2013-eq"
  },
  {
    "name": "Fall 2019 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2019-eq",
    "url": "/publications/fall-2019-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2019-eq"
  },
  {
    "name": "Fall 2009 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2009-eq",
    "url": "/publications/fall-2009-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2009-eq"
  },
  {
    "name": "Spring 2019 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/spring-2019-eq",
    "url": "/publications/spring-2019-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "spring-2019-eq"
  },
  {
    "name": "EHS Quarterly Issue 4",
    "apiurl": "/restapi/customehssubpublication/publications/ehs-quarterly-issue-4",
    "url": "/publications/ehs-quarterly-issue-4",
    "template": "new-trinity-ehs-sub-publication",
    "section": "ehs-quarterly-issue-4"
  },
  {
    "name": "Fall 2014 EQ",
    "apiurl": "/restapi/customehssubpublication/publications/fall-2014-eq",
    "url": "/publications/fall-2014-eq",
    "template": "new-trinity-ehs-sub-publication",
    "section": "fall-2014-eq"
  },
  {
    "name": "ESP+",
    "apiurl": "/restapi/customcontent/contact-us/esp",
    "url": "/contact-us/esp",
    "template": "general_content",
    "section": "esp+"
  },
  {
    "name": "ESP+",
    "apiurl": "/restapi/customcontent/contact-us/esp",
    "url": "/esp-plus",
    "template": "general_content",
    "section": "esp+"
  },
  {
    "name": "Design",
    "apiurl": "/restapi/customcontent/contact-us/design",
    "url": "/contact-us/design",
    "template": "general_content",
    "section": "design"
  },
  {
    "name": "Development",
    "apiurl": "/restapi/customcontent/contact-us/development",
    "url": "/contact-us/development",
    "template": "general_content",
    "section": "development"
  },
  {
    "name": "Locations",
    "apiurl": "/restapi/customlocationcontent/locations",
    "url": "/locations",
    "template": "new-locations",
    "section": "locations"
  },
  {
    "name": "Locations",
    "apiurl": "/restapi/customlocationcontent/locations",
    "url": "/additional-links/locations",
    "template": "new-locations",
    "section": "locations"
  },
  {
    "name": "Locations",
    "apiurl": "/restapi/customlocationcontent/locations",
    "url": "/location",
    "template": "new-locations",
    "section": "locations"
  },
  {
    "name": "Policies",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/policies",
    "url": "/about-us/policies",
    "template": "new-policies",
    "section": "about-us"
  },
  {
    "name": "Policies",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/policies",
    "url": "/about-us/policies",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Trade Shows & Events",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/trade-shows",
    "url": "/about-us/trade-shows",
    "template": "new-trade-shows",
    "section": "about-us"
  },
  {
    "name": "Trade Shows & Events",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/trade-shows",
    "url": "/trade-shows",
    "template": "new-trade-shows",
    "section": "about-us"
  },
  {
    "name": "Trade Shows & Events",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/trade-shows",
    "url": "/about-us/trade-shows",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Trade Shows & Events",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/trade-shows",
    "url": "/trade-shows",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "EHS Quarterly",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/ehs-quarterly",
    "url": "/resources/publications",
    "template": "new-mission",
    "section": "about-us"
  },
  {
    "name": "EHS Quarterly",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/ehs-quarterly",
    "url": "/media/environmental-quarterly",
    "template": "new-mission",
    "section": "about-us"
  },
  {
    "name": "EHS Quarterly",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/ehs-quarterly",
    "url": "/resources/publications",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "EHS Quarterly",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/ehs-quarterly",
    "url": "/media/environmental-quarterly",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us/mission",
    "template": "new-mission",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us/mission-vision-values",
    "template": "new-mission",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us1/mission",
    "template": "new-mission",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us/mission",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us/mission-vision-values",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Mission, Vision & Values",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/mission",
    "url": "/about-us1/mission",
    "template": "new-aboutus-category",
    "section": "about-us"
  },
  {
    "name": "Quality",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/policies/quality",
    "url": "/about-us/policies/quality",
    "template": "new-individual-policy",
    "section": "quality"
  },
  {
    "name": "ESG",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/policies/sustainability",
    "url": "/about-us/policies/sustainability",
    "template": "new-individual-policy",
    "section": "esg"
  },
  {
    "name": "Show 1",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/trade-shows/show-1",
    "url": "/about-us/trade-shows/show-1",
    "template": "new-individual-policy",
    "section": "show-1"
  },
  {
    "name": "Health, Safety & Environmental",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/policies/hse-policy",
    "url": "/about-us/policies/hse-policy",
    "template": "new-individual-policy",
    "section": "health,-safety-environmental"
  },
  {
    "name": "Health, Safety & Environmental",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/policies/hse-policy",
    "url": "/about-us1/policies/hse-policy",
    "template": "new-individual-policy",
    "section": "health,-safety-environmental"
  },
  {
    "name": "Health, Safety & Environmental",
    "apiurl": "/restapi/customaboutuslvl3content/about-us/policies/hse-policy",
    "url": "/about-us/policies/he-policy",
    "template": "new-individual-policy",
    "section": "health,-safety-environmental"
  },
  {
    "name": "Submit Job Request",
    "apiurl": "/restapi/customcontent/submit-job-request",
    "url": "/submit-job-request",
    "template": "general_content",
    "section": "submit-job-request"
  },
  {
    "name": "Industries",
    "apiurl": "/restapi/customindustrycontent/industries",
    "url": "/industries",
    "template": "new-industry-landing",
    "section": "industries"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/paul-greywall",
    "url": "/about-us/executive-committee/individual-bio/paul-greywall",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/paul-greywall",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/jay-hofmann",
    "url": "/about-us/executive-committee/individual-bio/jay-hofmann",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/jay-hofmann",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/rancie-bernal",
    "url": "/about-us/executive-committee/individual-bio/rancie-bernal",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/rancie-bernal",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/charles-brower",
    "url": "/about-us/executive-committee/individual-bio/charles-brower",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/charles-brower",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/dave-larsen",
    "url": "/about-us/executive-committee/individual-bio/dave-larsen",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/dave-larsen",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/nav-marocha",
    "url": "/about-us/executive-committee/individual-bio/nav-marocha",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/nav-marocha",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/mike-remsberg",
    "url": "/about-us/executive-committee/individual-bio/mike-remsberg",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/mike-remsberg",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/alison-ritterbusch",
    "url": "/about-us/executive-committee/individual-bio/alison-ritterbusch",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/alison-ritterbusch",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/hung-ming-sue-sung",
    "url": "/about-us/executive-committee/individual-bio/hung-ming-sue-sung",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee Individual Bio",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee/individual-bio/hung-ming-sue-sung",
    "url": "/about-us1/executive-committee",
    "template": "new-individual-bio",
    "section": "about-us"
  },
  {
    "name": "Executive Committee",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee",
    "url": "/about-us/executive-committee",
    "template": "new-executive-committee",
    "section": "about-us"
  },
  {
    "name": "Executive Committee",
    "apiurl": "/restapi/customaboutuslvl2content/about-us/executive-committee",
    "url": "/about-us1/executive-committee",
    "template": "new-executive-committee",
    "section": "about-us"
  },
  {
    "name": "Trinity Consultants Australia",
    "apiurl": "/restapi/customcontent/home/trinity-consultants-australia",
    "url": "/home/trinity-consultants-australia",
    "template": "general_content",
    "section": "trinity-consultants-australia"
  },
  {
    "name": "Minnow Aquatic Environmental",
    "apiurl": "/restapi/customcontent/home/minnow-aquatic-environmental",
    "url": "/home/minnow-aquatic-environmental",
    "template": "general_content",
    "section": "minnow-aquatic-environmental"
  },
  {
    "name": "Minnow Aquatic Environmental",
    "apiurl": "/restapi/customcontent/home/minnow-aquatic-environmental",
    "url": "/home/minnow",
    "template": "general_content",
    "section": "minnow-aquatic-environmental"
  },
  {
    "name": "SafeBridge Regulatory & Life Sciences Group",
    "apiurl": "/restapi/customcontent/home/SafeBridge-Regulatory-Life-Science-Group",
    "url": "/home/SafeBridge-Regulatory-Life-Science-Group",
    "template": "general_content",
    "section": "safebridge-regulatory-life-sciences-group"
  },
  {
    "name": "SafeBridge Regulatory & Life Sciences Group",
    "apiurl": "/restapi/customcontent/home/SafeBridge-Regulatory-Life-Science-Group",
    "url": "/home/safebridge",
    "template": "general_content",
    "section": "safebridge-regulatory-life-sciences-group"
  },
  {
    "name": "Provenance Consulting",
    "apiurl": "/restapi/customcontent/home/provenance-process-safety-risk-management",
    "url": "/home/provenance-process-safety-risk-management",
    "template": "general_content",
    "section": "provenance-consulting"
  },
  {
    "name": "Provenance Consulting",
    "apiurl": "/restapi/customcontent/home/provenance-process-safety-risk-management",
    "url": "/home/provenance-consulting",
    "template": "general_content",
    "section": "provenance-consulting"
  },
  {
    "name": "Advent Engineering Life Science Solutions",
    "apiurl": "/restapi/customcontent/home/Advent-Engineering-Life-Science-Solutions",
    "url": "/home/Advent-Engineering-Life-Science-Solutions",
    "template": "general_content",
    "section": "advent-engineering-life-science-solutions"
  },
  {
    "name": "Advent Engineering Life Science Solutions",
    "apiurl": "/restapi/customcontent/home/Advent-Engineering-Life-Science-Solutions",
    "url": "/home/advent",
    "template": "general_content",
    "section": "advent-engineering-life-science-solutions"
  },
  {
    "name": "Working Buildings",
    "apiurl": "/restapi/customcontent/home/working-buildings",
    "url": "/home/working-buildings",
    "template": "general_content",
    "section": "working-buildings"
  },
  {
    "name": "AERMAT",
    "apiurl": "/restapi/customcontent/contact-us/aermat",
    "url": "/contact-us/aermat",
    "template": "general_content",
    "section": "aermat"
  }
]
