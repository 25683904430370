export const title = 'Recent Available Positions';
export const link = 'See all available positions ›';

export const widgetData = [
  {
    department_title: 'Corporate IT Department',
    job_title: 'Business Development Coordinator',
    location: 'Corpus Christi',
    multiple_locations:
      'Multiple locations in USA - North Carolina, Boston, San Francisco Bay Area, Texas…',
  },
  {
    department_title: 'Corporate IT Department',
    job_title: 'Business Development Coordinator',
    location: 'Corpus Christi',
    multiple_locations:
      'Multiple locations in USA - North Carolina, Boston, San Francisco Bay Area, Texas…',
  },
  {
    department_title: 'Corporate IT Department',
    job_title: 'Business Development Coordinator',
    location: 'Corpus Christi',
    multiple_locations:
      'Multiple locations in USA - North Carolina, Boston, San Francisco Bay Area, Texas…',
  },
  {
    department_title: 'Corporate IT Department',
    job_title: 'Business Development Coordinator',
    location: 'Corpus Christi',
    multiple_locations:
      'Multiple locations in USA - North Carolina, Boston, San Francisco Bay Area, Texas…',
  },
];

export default widgetData;
