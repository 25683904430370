import React from 'react';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SafeBridgeCheckBoxContainer from './SafeBridgeCheckBoxContainer';
import search from '../../components/ehs-quarterly/images/search.svg';
import './SafeBridgeRootComponent.scss';
import InputRange from 'react-input-range';
import moment from 'moment';
import { trinityTrainingDateFormatter } from '../../utilities/CommonUtilities';
import { safeBridgeSearchConstant } from '../../redux/safe-bridge/safe-bridge-action';

function SafeBridgeFilterRootComponent(props) {
  const getDynamicTreeElements = () =>
    props.facetsList &&
    props.facetsList
      .map((outerElement) => {
        if (outerElement.value.length > 0) {
          return (
            <SafeBridgeCheckBoxContainer
              outerElement={outerElement}
              {...props}
            />
          );
        }
        return null;
      })
      .filter((e) => e !== null);

  const getSearchBar = () => (
    <div className="search-box">
      <input
        type="text"
        placeholder="Keyword or Phrase"
        className="search-input"
        value={props.searchBoxText}
        onKeyDown={(e) => e.key === 'Enter' && props.onSearchEnter()}
        onChange={(e) => props.setSearchBoxText(e.target.value)}
      />
      <img
        onClick={props.onSearchEnter}
        src={search}
        alt={Math.random().toString()}
      />
    </div>
  );

  return (
    <div className="outside-filter-container">
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="activity-search-label">Safebridge Search</div>
        <div>
          <span
            style={{
              cursor: 'pointer',
              color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
            }}
            onClick={props.resetAllFiltersAndSearch}
          >
            Clear All{' '}
            <i
              className="fa fa-window-close"
              style={{
                fontSize: '20px',
                color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
              }}
              aria-hidden="true"
            />
          </span>
        </div>
      </div>

      <div className="search-filter-container">{getSearchBar()}</div>

      <div className="activity-dropdown">
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <div style={{ marginTop: '1rem' }}>
            <span className="tree-view-key-font">Issue Dates</span>
            <div className="tree-key-element-border" />

            <span>
              {trinityTrainingDateFormatter(props.rangeState.min)} to{' '}
              {trinityTrainingDateFormatter(props.rangeState.max)}
            </span>
            <div className="general-search-date-range-slider">
              <InputRange
                minValue={moment(safeBridgeSearchConstant).toDate().getTime()}
                maxValue={moment().toDate().getTime()}
                formatLabel={(val) => ''}
                value={props.rangeState}
                step={6}
                onChangeComplete={props.rangePickerChangeComplete}
                onChange={props.onChangeHandleChange}
              />
            </div>
          </div>

          <div>{getDynamicTreeElements()}</div>
        </TreeView>
      </div>
    </div>
  );
}

export default SafeBridgeFilterRootComponent;
