import React from 'react';
import '../TrinitySiteSearch.scss';
import moment from 'moment';
import { BASE_URL, WEBSITE_URL } from '../../../js/api/_config';
import trainingIcon from '../images/training-icon-350x300.png';
import { trinityTrainingDateFormatter } from '../../../utilities/CommonUtilities';

function TrainingServiceResourceCard(props) {
  return (
    <div
      className="context-card"
      onClick={() => window.open(WEBSITE_URL + escape(props.elementData.Url))}
    >
      <div className="image-container">
        <img
          alt={`TrinityBulletField${Math.random()}`}
          className={
            props.elementData.Image
              ? 'image-present-class'
              : 'image-not-present-class'
          }
          src={
            props.elementData.Image
              ? BASE_URL + props.elementData.Image
              : trainingIcon
          }
        />
      </div>
      <div className="side-data">
        {props.elementData.Title && (
          <div className="title-tag-container">
            <span className="context-trinitysearch-title">
              {props.elementData.Title}
            </span>
            {props.elementData.Category && (
              <div className="tag-beside-title">
                {props.elementData.Category}
              </div>
            )}
          </div>
        )}
        <div className="context-text">
          {props.elementData.Location === 'Online' && (
            <span>
              <strong>Training Format: </strong>
              {props.elementData.Location}
            </span>
          )}
          {props.elementData.Location !== 'Online' && (
            <span>
              <strong>Location: </strong>
              {props.elementData.Location}
            </span>
          )}
          {props.elementData.Location !== 'Online' && (
            <span>
              <strong>Training Date: </strong>
              {props.elementData.DatePublished &&
                props.elementData.DatePublished.split('T') &&
                props.elementData.DatePublished.split('T').length > 0 &&
                props.elementData.DatePublished.split('T')[0] &&
                trinityTrainingDateFormatter(
                  props.elementData.DatePublished.split('T')[0]
                )}
            </span>
          )}

          {/* <span>
                        <ContentWell content={props.elementData.Content}/>
                    </span> */}
        </div>
      </div>
    </div>
  );
}

export default TrainingServiceResourceCard;
