import placeHolderImg from '../../../../../images/about/testBioPic.svg';

export const title = 'Executive Committee';

export const boxCtaData = [
  {
    name: 'Mike Remsberg',
    job_title: 'Managing Director, Trinity Consultants',
    src: placeHolderImg,
  },
  {
    name: 'John Iwanski',
    job_title: 'Managing Director, Trinity Consultants',
    src: placeHolderImg,
  },
  {
    name: 'Shishir Mohan',
    job_title: 'Managing Director, Trinity Consultants',
    src: placeHolderImg,
  },
  {
    name: 'Vineet Masuraha',
    job_title: 'Managing Director, Trinity Consultants',
    src: placeHolderImg,
  },
  {
    name: 'Weiping Dai',
    job_title: 'Director, BREEZE Software/China Operations',
    src: placeHolderImg,
  },
  {
    name: 'Allan Ader',
    job_title: 'Managing Director, SafeBridge Consultants',
    src: placeHolderImg,
  },
  {
    name: 'Mike Remsberg',
    job_title: 'Managing Director, Trinity Consultants',
    src: placeHolderImg,
  },
  {
    name: 'Cynthia Russell',
    job_title: 'Managing Director, Minnow Environmental',
    src: placeHolderImg,
  },
];

export default boxCtaData;
