import React, { Suspense, useEffect } from 'react';
import { useHistory } from 'react-router';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewSoftwareSupportLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-software-support-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-software-support-content-component')
  );

  const history = useHistory();
  const [isFromMegaMenu, setIsFromMegaMenu] = React.useState(false);

  useEffect(() => {
    const { location } = history;
    const { state } = location;
    if (location && state) {
      setIsFromMegaMenu(state.isFromMegaMenu);
    }
  }, []);

  return (
    <div className="software-support software mb-5">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent isFromMegaMenu={isFromMegaMenu} />
      </Suspense>
    </div>
  );
}

export default NewSoftwareSupportLandingPage;
