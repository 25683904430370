import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import SupportSideWidget from '../../../components/software/software-support/support-side-widget/support-side-widget.component';
import FiliteredSupportContentWell from '../../../components/software/software-support/software-support.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function SoftwareSupport({ page: { MainContent, SoftwareSupportContent } }) {
  const history = useHistory();

  const [isFromMegaMenu, setIsFromMegaMenu] = React.useState(false);

  useEffect(() => {
    const { location } = history;
    const { state } = location;
    if (location && state) {
      setIsFromMegaMenu(state.isFromMegaMenu);
    }
  }, []);

  if (MainContent) {
    return (
      <div className="software-support software mb-5">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          downArrow
          colorCategory="default"
        />

        <div className="sm-up-trinity-container">
          {SoftwareSupportContent &&
            SoftwareSupportContent.GlobalSoftwareSupport && (
              <ContactCta
                data={SoftwareSupportContent.GlobalSoftwareSupport}
                LeftSideContent={
                  SoftwareSupportContent.GlobalSoftwareSupport.LeftContent
                }
                LeftSideLink={
                  SoftwareSupportContent.GlobalSoftwareSupport.LeftLink
                }
                LeftSideLinkText={
                  SoftwareSupportContent.GlobalSoftwareSupport.LeftLinkText
                }
                CenterContent={
                  SoftwareSupportContent.GlobalSoftwareSupport.CenterContent
                }
                CenterPhoneNumber={
                  SoftwareSupportContent.GlobalSoftwareSupport.CenterLinkText
                }
                RightSideContent={
                  SoftwareSupportContent.GlobalSoftwareSupport.RightContent
                }
                RightSidePhoneNumber={
                  SoftwareSupportContent.GlobalSoftwareSupport.RightLinkText
                }
              />
            )}
          {SoftwareSupportContent ? (
            <ContentWithSidebar
              left={
                <Row className="no-gutter-around">
                  <Col>
                    <div className="xs-only-trinity-container">
                      <FiliteredSupportContentWell
                        isFromMegaMenu={isFromMegaMenu}
                        SoftwareSupportContent={SoftwareSupportContent}
                      />
                    </div>
                  </Col>
                </Row>
              }
              right={
                <Row className="no-gutter-around">
                  <Col>
                    <div className="xs-only-trinity-container">
                      {SoftwareSupportContent &&
                        SoftwareSupportContent.AdditionalSoftwareServices && (
                          <SupportSideWidget
                            data={
                              SoftwareSupportContent.AdditionalSoftwareServices
                            }
                          />
                        )}
                    </div>
                  </Col>
                </Row>
              }
            />
          ) : null}
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareSupport;
