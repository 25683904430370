import React from 'react';
import './pay-assign-attendee-form.styles.scss';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Parser from 'html-react-parser';
import Skeleton from 'react-loading-skeleton';
import { updateCartAttendeesAction } from '../../../../redux/shopping-cart/shopping-cart.action';

function ReviewPayAssignedAttendee({
  productTitle,
  sku,
  variantSku,
  relatedAttendees,
}) {
  // const {states, countries, industries} = useAllDropdownContent();

  const history = useHistory();
  const dispatch = useDispatch();

  const cartattendeesArray = useSelector(
    ({ cart }) => cart.cartattendees,
    shallowEqual
  );

  const handleEditAttendee = async (event) => {
    dispatch(updateCartAttendeesAction(null));
    const attendeeId = Number(event.target.id);

    const preparedData = [].concat.apply(
      [],
      cartattendeesArray.map((e) => e.attendees)
    );
    const attendeeObj = preparedData.filter((m) => m.ID === attendeeId);

    if (attendeeObj && attendeeObj.length > 0) {
      await dispatch(updateCartAttendeesAction(attendeeObj[0]));
    }

    history.push('/shopping-cart/attendees');
  };

  return (
    <div className="outer-assign-attendee-container">
      <div className="review-pay-assign-attendee-form">
        {cartattendeesArray ? (
          cartattendeesArray.map((outerElement, index) => (
            <>
              {index > 0 && <hr />}
              <h2 className="pb-2 form-title">
                {Parser(outerElement.CourseName)}
              </h2>
              {outerElement.attendees.map((item, index) => (
                <form>
                  <div className="left pr-md-4">
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <span className="pb-2 form-title">
                        {`Attendee #${index + 1}`}
                      </span>
                      <div style={{ marginLeft: 3 }}>
                        <div className="edit-profile">
                          <i
                            className="fa fa-pencil edit"
                            style={{ marginLeft: 5 }}
                            aria-hidden="true"
                            id={item.ID}
                            onClick={handleEditAttendee}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-section contact-information">
                      <p className="name">{`${item.FirstName} ${item.LastName}`}</p>
                      <p className="job-title">{item.Title}</p>
                      <p className="email">{item.EmailAddress}</p>
                      <p className="phone">{item.Phone}</p>
                      <p className="company-name">{item.Company}</p>
                      {item?.DietaryRestrictionOption === 'y' && (
                        <p className="sub-data-font">
                          {item?.DietaryRestrictionMealOptions &&
                            item?.DietaryRestrictionMealOptions}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="right pl-md-4">
                    <div className="form-section company-information">
                      <h3 className="pb-2 form-title">&nbsp;</h3>
                      <p className="description">
                        {item.IndustryName ? item.IndustryName : 'N/A'}
                      </p>
                      <p className="address">{item.Address}</p>
                      <p className="address">{item.AddressTwo}</p>
                      <p className="city">{`${item.City}, ${item.StateName}`}</p>
                      <p className="postal-code">{`${item.PostalCode}, ${item.CountryName}`}</p>
                      {item?.DietaryRestrictionOption === 'y' &&
                        item?.DietaryRestrictionMealOptionsOther &&
                        item?.DietaryRestrictionMealOptionsOther !== 'None' && (
                          <p style={{ width: '350px', wordWrap: 'break-word' }}>
                            {item?.DietaryRestrictionMealOptionsOther}
                          </p>
                        )}
                    </div>
                  </div>
                </form>
              ))}
            </>
          ))
        ) : (
          <Skeleton height={100} />
        )}
      </div>
    </div>
  );
}

export default ReviewPayAssignedAttendee;
