import pdf from '../src/components/Documents/Code_of_Business_Conduct.pdf';

export const footerStaticData = {
  expertiseLevel: [
    {
      name: 'Environmental Consulting',
      Description: '',
      path: '/consulting/ehs',
      uniqueId: '421fc28f-1a8d-461f-bfb9-b4a9ef4485b9',
      Level: 1,
    },
    {
      name: 'Water & Ecology',
      Description: '',
      path: '/consulting/water-ecology',
      uniqueId: '28729a5f-5094-4da2-9d06-8ebccb15cfa1',
      Level: 1,
    },
    {
      name: 'Built Environment',
      Description: '',
      path: '/consulting/engineering',
      uniqueId: '72c17d8b-4ea4-4d37-82da-55c94c0144cd',
      Level: 1,
    },
    {
      name: 'Life Sciences',
      Description: '',
      path: '/consulting/science',
      uniqueId: '72c17d8b-4ea4-4d37-82da-55c94c0144ef',
      Level: 1,
    },
  ],
  software: [
    {
      name: 'Air Dispersion - Continuous Release',
      path: '/software',
      uniqueId: '26136adf-d88e-4538-80e7-0a7d26b6507d',
      Level: 1,
    },
    {
      name: 'Air Dispersion - Data Visualization',
      path: '/software',
      uniqueId: 'c9613162-4ba6-4118-8922-e0ae04e70af7',
      Level: 1,
    },
    {
      name: 'Hazard',
      path: '/software',
      uniqueId: 'b5b2861d-4002-4b52-8fef-26802c25f931',
      Level: 1,
    },
    {
      name: 'Explosion',
      path: '/software',
      uniqueId: '23c98a30-2681-4aaa-b35a-3ebe74e3ea74',
      Level: 1,
    },
    {
      name: 'Health Risk',
      path: '/software',
      uniqueId: 'c7e0c1b2-34b9-4cac-9959-145798b0c010',
      Level: 1,
    },
    {
      name: 'Tanks',
      path: '/software',
      uniqueId: '8b796e58-77e0-480f-aae6-3b303edbc634',
      Level: 1,
    },
    {
      name: 'Request a Demo',
      path: '/software/software-demo',
      uniqueId: '03696c68-e757-4f4f-b80c-295e02ebfc94',
      Level: 1,
    },
  ],
  additional_links: [
    {
      name: 'About Us',
      path: '/about-us',
      uniqueId: 'd5665912-0f88-4c62-8da8-bd7f935965a6',
      Level: 1,
    },
    {
      name: 'News',
      path: '/news',
      uniqueId: '6d9b91ef-fa78-4725-90f2-3a9ebfea654f',
      Level: 1,
    },
    {
      name: 'Careers',
      path: '/careers',
      uniqueId: '4a110458-5542-4498-94c3-4759ebe6fc55',
      Level: 1,
    },
    {
      name: 'Locations',
      path: '/locations',
      uniqueId: 'eff8d9d8-b5b8-4b8b-bd0b-29623ffba97d',
      Level: 1,
    },
    {
      name: 'Contact Us',
      path: '/contactus',
      uniqueId: '0acc5d30-1ed7-459b-86b7-845d419ca91b',
      Level: 1,
    },
  ],
  account_separate_links: [
    {
      name: 'My Trinity Account',
      path: '/my-account',
      uniqueId: '8427bfff-a8c9-44a8-823c-12dc1c09d4a8',
      Level: 1,
    },
    {
      name: 'Payments',
      path: 'https://payments.trinityconsultants.com',
      uniqueId: '8427bfff-a8c9-44a8-823c-12dc1c09d4b9',
      Level: 1,
    },
    {
      name: 'Privacy Policy',
      path: '/privacy-policy',
      uniqueId: '8427bfff-a8c9-44a8-823c-12dc1c09d4b9',
      Level: 1,
    },
    {
      name: 'Code Of Business Conduct',
      path: pdf,
      uniqueId: '8427bfff-a8c9-44a8-823c-12dc1c09d4b9',
      Level: 1,
    },
  ],
  share: [
    {
      name: 'LinkedIn',
      path: 'https://www.linkedin.com/shareArticle?mini=true&url=https://www.trinityconsultants.com&title=&summary=&source=',
      uniqueId: 'af2767a1-5a19-428f-9e49-3e2fe7562d15',
      Level: 1,
    },
    {
      name: 'Facebook',
      path: 'https://www.facebook.com/sharer/sharer.php?u=https://www.trinityconsultants.com',
      uniqueId: 'b0f7f2f8-8d91-40e4-b581-122cf01b50ae',
      Level: 1,
    },
    {
      name: 'Twitter',
      path: 'https://twitter.com/intent/tweet?text=https://www.trinityconsultants.com',
      uniqueId: 'f6d17070-431b-4a2a-9347-36e8f69a05bf',
      Level: 1,
    },
    {
      name: 'Email',
      path: 'mailto:?&subject=Trinity Consultants Website&body=https://www.trinityconsultants.com',
      uniqueId: '46b29eb3-883c-424a-abe3-81019829d4cb',
      Level: 1,
    },
  ],
  connectwithus: [
    {
      name: 'LinkedIn',
      path: 'http://www.linkedin.com/company/trinity-consultants',
      uniqueId: 'af2767a1-5a19-428f-9e49-3e2fe7562d15',
      Level: 1,
    },
    {
      name: 'Facebook',
      path: 'https://www.facebook.com/TrinityConsults/',
      uniqueId: 'b0f7f2f8-8d91-40e4-b581-122cf01b50ae',
      Level: 1,
    },
    {
      name: 'Twitter',
      path: 'http://twitter.com/trinityconsults',
      uniqueId: 'f6d17070-431b-4a2a-9347-36e8f69a05bf',
      Level: 1,
    },
    {
      name: 'YouTube',
      path: 'http://www.youtube.com/trinityconsultants',
      uniqueId: '46b29eb3-883c-424a-abe3-81019829d4cb',
      Level: 1,
    },
  ],
  services: [
    {
      name: 'Consulting',
      path: '/consulting',
      uniqueId: 'befe08e3-e553-44f5-ae8b-934f658b3fd7',
      Level: 1,
    },
    {
      name: 'Staffing',
      path: '/staffing',
      uniqueId: 'eaf44242-c8e9-46f7-8493-4375724ccf2a',
      Level: 1,
    },
    {
      name: 'Training',
      path: '/training',
      uniqueId: '5ccbf2d2-1d5f-4c2d-92ac-2f28687b35fa',
      Level: 1,
    },
    {
      name: 'Technology',
      path: '/technology',
      uniqueId: '8e6648c1-571d-4d4e-bf7f-c7264ec6d9e5',
      Level: 1,
    },
  ],
  SpecialityConsultingTeam: [
    {
      name: 'Advent Engineering Life Science Solutions',
      path: '/advent-life-sciences-engineering-services',
      Level: 1,
    },
    {
      name: 'Minnow Aquatic Environmental Services',
      path: '/minnow-aquatic-environmental-services',
      Level: 1,
    },
    {
      name: 'SafeBridge Regulatory & Life Sciences Group',
      path: '/safebridge-regulatory-life-sciences-group',
      Level: 1,
    },
  ],
};
