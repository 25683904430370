import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../commons/custom-button/custom-button.component';

import './reset-failed.styles.scss';

function ResetFailed() {
  return (
    <div className="reset-failed module-spacer">
      <div className="form-container background action-item">
        <h2>Password Reset Failed</h2>
        <p className="blurb">
          We're sorry, but the link you clicked appears to be invalid.
        </p>

        <Link to="/login">
          <CustomButton>Continue</CustomButton>
        </Link>
      </div>
    </div>
  );
}

export default ResetFailed;
