import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Redirect } from 'react-router-dom';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';

import './checkout-form.styles.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import {
  getPaymentIntentRequest,
  stripePaymentSuccessRequest,
} from '../../../../js/api/shopping-cart/_payment-cart';
import {
  cartCountUpdate,
  updateAllowCartStepsAction,
  UpdateCartConfirmParams,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import PaymentInProgressModal from './PaymentInProgressModal';
import { sendPurchaseEventToGA4 } from '../../../../TrackGoogleAnalytics';

const CARD_OPTIONS_NUMBER = {
  iconStyle: 'solid',
  showIcon: true,
  placeholder: 'Card Number',
  style: {
    base: {
      fontSize: '1rem',
      color: '#424770',
      letterSpacing: '0.025em',
      // fontFamily: "FiraSans,Source Code Pro, monospace",
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#9e2146',
    },
    // complete: {
    //   fontFamily: "Montserrat",
    //   color: "#008000",
    //   fontWeight: 500,
    //   fontSize: "16px",
    //   fontSmoothing: "antialiased",
    //   "::placeholder": {
    //     color: "#008000",
    //   },
    //   ":-webkit-autofill": {
    //     color: "#008000",
    //   },
    // },
    // empty: {
    //   fontFamily: "Montserrat",
    //   color: "#32325D",
    //   fontWeight: 500,
    //   fontSize: "16px",
    //   fontSmoothing: "antialiased",
    //   "::placeholder": {
    //     color: "#222222",
    //   },
    //   ":-webkit-autofill": {
    //     color: "#e39f48",
    //   },
    // },
  },
};
const CARD_OPTIONS_MMYY = {
  iconStyle: 'default',
  placeholder: 'MM/YY',
  style: {
    base: {
      fontSize: '1rem',
      color: '#424770',
      letterSpacing: '0.025em',
      // fontFamily: "FiraSans, Source Code Pro, monospace",
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const CARD_OPTIONS_CVC = {
  iconStyle: 'default',
  placeholder: 'CVC',
  style: {
    base: {
      fontSize: '1rem',
      color: '#424770',
      letterSpacing: '0.025em',
      // fontFamily: "FiraSans, Source Code Pro, monospace",
      '::placeholder': {
        color: '#000000',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};
const create_UUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

function CardField({ onChange }) {
  return (
    <>
      <div className="FormRow">
        <CardNumberElement
          className="react-strap-elements"
          options={CARD_OPTIONS_NUMBER}
          onChange={onChange}
        />
      </div>
      <br />
      <div>
        <Row>
          <Col className="pr-2">
            <CardExpiryElement
              className="react-strap-elements"
              onChange={onChange}
              options={CARD_OPTIONS_MMYY}
            />
          </Col>
          <Col className="pl-2">
            <CardCvcElement
              className="react-strap-elements"
              onChange={onChange}
              options={CARD_OPTIONS_CVC}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

function Field({ label, id, type, required, autoComplete, value, onChange }) {
  return (
    <div className="FormRow">
      {/* <label htmlFor={id} className="FormRowLabel">
        {label}
      </label> */}
      <input
        className="FormRowInput"
        id={id}
        type={type}
        required={required}
        autoComplete={autoComplete}
        value={value}
        placeholder={label}
        onChange={onChange}
      />
    </div>
  );
}

function SubmitButton({ processing, error, children, disabled }) {
  return (
    <button
      className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
      type="submit"
      disabled={processing || disabled}
    >
      {processing ? 'Processing...' : children}
    </button>
  );
}

function ErrorMessage({ children }) {
  return (
    <div className="ErrorMessage" role="alert">
      <svg width="16" height="16" viewBox="0 0 17 17">
        <path
          fill="#fff"
          d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
        />
        <path
          fill="red"
          d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
        />
      </svg>
      {children}
    </div>
  );
}

const getCartPaymentIntent = async () => {};

function CheckoutForm() {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentResult, setPaymentResult] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const [orderGuid, setOrderGuid] = useState();
  const normaluser = useSelector(({ user }) => user.loggedin_user);
  const improfileuser = useSelector(({ cart }) =>
    cart.impersonateMember ? cart.impersonateMember.userprofile : null
  );

  const shoppingCartData = useSelector(({ cart }) => cart?.cart);

  const basketId = useSelector(({ cart }) =>
    cart ? cart?.cart?.BasketID : null
  );

  const [userInfo, setUserInfo] = useState(
    improfileuser !== null && improfileuser ? improfileuser : normaluser
  );

  const cartcategorypama = useSelector(({ cart }) => cart.cartcategorypamas);

  useEffect(() => {
    async function checkPaymentIntent() {
      let memberId = null;
      if (cartitemimpersonate) {
        memberId = cartitemimpersonate.memberId;
      }
      const data = {
        memberid: memberId,
        basketid: basketId,
      };

      const paymentIntentResponse = await getPaymentIntentRequest(data);
      if (paymentIntentResponse) {
        setOrderGuid(paymentIntentResponse.IdempotencyKey);
        const paymentIntent = paymentIntentResponse.PaymentIntent;
        if (paymentIntent) {
          setClientSecret(paymentIntent.ClientSecret);
        }
      }
    }
    checkPaymentIntent();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    sendPurchaseEventToGA4(shoppingCartData);

    const billingDetails = {
      name: event.target.name.value,
      email: event.target.email.value,
      phone: event.target.phone.value,
      // address: {
      //   city: event.target.city.value,
      //   state: event.target.state.value,
      //   postal_code: event.target.postalCode.value,
      //   line1: event.target.address.value
      // },
    };

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      setProcessing(true);
    }

    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: billingDetails,
    });

    if (payload.error) {
      setError(payload.error);
      setProcessing(false);
      return;
    }
    setPaymentMethod(payload.paymentMethod);

    const { id } = payload.paymentMethod;
    if (id) {
      try {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: id,
          idempotency_key: create_UUID,
        });

        if (result.error) {
          if (
            result.error &&
            result.error.payment_intent &&
            result.error.payment_intent.status === 'succeeded'
          ) {
            setProcessing(true);

            // retrive payment intent object
            const respaymentIntent = await stripe.retrievePaymentIntent(
              result.error.payment_intent.client_secret
            );
            const type = `${respaymentIntent.paymentIntent.object}.${respaymentIntent.paymentIntent.status}`;
            const { livemode } = respaymentIntent.paymentIntent;
            let memberId = null;
            if (cartitemimpersonate) {
              memberId = cartitemimpersonate.memberId;
            }
            const data = {
              livemode,
              type,
              Data: respaymentIntent.paymentIntent,
              memberid: memberId,
              isbothcategory: cartitems.IsBothCategory,
              isonlytraining: cartitems.IsOnlyTraining,
              isonlysoftware: cartitems.IsOnlySoftware,
              // name: event.target.name.value,
              // email: event.target.email.value,
              // phone: event.target.phone.value,
              Username: userInfo.Username,
            };

            // Email send with receipt data.

            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            const stripeResponse = await stripePaymentSuccessRequest(data);

            // if (stripeResponse === 1) {
            if (stripeResponse) {
              if (stripeResponse.PaymentSuccess) {
                sendPurchaseEventToGA4(
                  shoppingCartData,
                  true,
                  stripeResponse.OrderConfirmationNumber
                );
                setPaymentResult(1);
                const categoryparams = {
                  isOnlySoftware: cartitems.IsOnlySoftware,
                  isOnlyTraining: cartitems.IsOnlyTraining,
                  bothCategory: cartitems.IsBothCategory,
                  orderConfirmationNumber:
                    stripeResponse.OrderConfirmationNumber,
                };
                dispatch(UpdateCartConfirmParams(categoryparams));
                dispatch(cartCountUpdate(1));
                dispatch(updateAllowCartStepsAction(5));
              }
            }
          } else {
            // Show error to your customer (e.g., insufficient funds)
            setError(result.error);
            setProcessing(false);
            return;
          }
        } else {
          // The payment has been processed!
          const type = `${result.paymentIntent.object}.${result.paymentIntent.status}`;
          const { livemode } = result.paymentIntent;

          // retrive payment intent object
          // const respaymentIntent = await stripe.retrievePaymentIntent(clientSecret);
          let memberId = null;
          if (cartitemimpersonate) {
            memberId = cartitemimpersonate.memberId;
          }
          const data = {
            livemode,
            type,
            Data: result.paymentIntent,
            memberid: memberId,
            isbothcategory: cartitems.IsBothCategory,
            isonlytraining: cartitems.IsOnlyTraining,
            isonlysoftware: cartitems.IsOnlySoftware,
            // name: event.target.name.value,
            // email: event.target.email.value,
            // phone: event.target.phone.value,
            Username: userInfo.Username,
          };

          if (result.paymentIntent.status === 'succeeded') {
            // Email send with receipt data.

            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            const stripeResponse = await stripePaymentSuccessRequest(data);
            // if (stripeResponse === 1) {
            if (stripeResponse) {
              if (stripeResponse.PaymentSuccess) {
                sendPurchaseEventToGA4(
                  shoppingCartData,
                  true,
                  stripeResponse.OrderConfirmationNumber
                );

                setPaymentResult(1);
                const categoryparams = {
                  isOnlySoftware: cartitems.IsOnlySoftware,
                  isOnlyTraining: cartitems.IsOnlyTraining,
                  bothCategory: cartitems.IsBothCategory,
                  orderConfirmationNumber:
                    stripeResponse.OrderConfirmationNumber,
                };
                dispatch(UpdateCartConfirmParams(categoryparams));
                dispatch(cartCountUpdate(1));
                dispatch(updateAllowCartStepsAction(5));
              }
            }
          }
        }
      } catch (error) {
        setError(error);
      }
    }

    setProcessing(false);
  };
  if (clientSecret) {
    return paymentResult ? (
      <Redirect to="/shopping-cart/attendees/review-order/payment/order-confirmation" />
    ) : (
      // <div className="Result">
      //   <div className="ResultMessage">{paymentMethod.id}</div>
      // </div>
      <div className="shadow-lg p-3 mb-5 bg-white rounded">
        <div className="outer-payment-container">
          <PaymentInProgressModal processing={processing} />
          <form autoComplete="off" onSubmit={handleSubmit}>
            <fieldset>
              {/* <legend className=" card-only">Pay with card</legend> */}
              <div className="combine-row">
                <div className="field">
                  <Field
                    label="Name on Card"
                    id="name"
                    type="text"
                    required
                    name="fakeusernameremembered"
                    autoComplete="off"
                  />
                </div>
              </div>

              <div className="combine-row">
                <div className="field">
                  <Field
                    label="Email"
                    id="email"
                    type="email"
                    required
                    name="email"
                    autoComplete="none"
                  />
                </div>
                <div className="field">
                  <Field
                    label="Phone"
                    id="phone"
                    required
                    name="phone"
                    autoComplete="none"
                  />
                </div>
              </div>
              <div className="combine-row card-field">
                <div className="field">
                  <CardField
                    onChange={(e) => {
                      setError(e.error);
                      setCardComplete(e.complete);
                    }}
                  />
                </div>
              </div>
              <br />

              <div className="combine-row">
                {/* <div className="field">
                  <Field
                    label="Address"
                    id="address"
                    type="text"
                    required
                    name="address"
                    autoComplete="address"
                  />
                </div>
              </div>

              <div className="combine-row">
                <div className="field">
                  <Field
                    label="City"
                    id="city"
                    type="text"
                    required
                    name="city"
                    autoComplete="city"
                  />
                </div>
                <div className="field">
                  <Field
                    label="State"
                    id="state"
                    type="text"
                    required
                    name="state"
                    autoComplete="state"
                  />
                </div>
                <div className="field">
                  <Field
                    label="Postal Code"
                    id="postalCode"
                    required
                    name="postalCode"
                    autoComplete="zip"
                  />
                </div> */}
              </div>

              {error && <ErrorMessage>{error.message}</ErrorMessage>}
              <SubmitButton
                processing={processing}
                error={error}
                disabled={!stripe}
              >
                <NumberFormat
                  value={cartitems ? cartitems.OrderTotal : 0}
                  displayType="text"
                  thousandSeparator
                  prefix="Pay $"
                  decimalScale={2}
                  fixedDecimalScale
                />
                {/* ${cartitems ? cartitems.OrderTotal : 0} */}
              </SubmitButton>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }
  return null;
}

export default CheckoutForm;
