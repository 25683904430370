import { WidgetBoxTypes } from './widget-box.types';

const INITIAL_STATE = {
  trainingcourseservice: [],
  trainingcourseindustries: [],
  trainingcourseproduct: [],
  resources: [],
  news: [],
  consultingproduct: [],
  industriesproduct: [],
};

const widgetboxReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WidgetBoxTypes.RELATED_TRAINING_COURSES_SERVICE_DATA:
      if (action.payload) {
        return {
          ...state,
          trainingcourseservice: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_TRAINING_COURSES_INDUSTRIES_DATA:
      if (action.payload) {
        return {
          ...state,
          trainingcourseindustries: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_TRAINING_COURSES_PRODUCT_DATA:
      if (action.payload) {
        return {
          ...state,
          trainingcourseproduct: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_TRAINING_COURSES_LOCATION_DATA:
      if (action.payload) {
        return {
          ...state,
          trainingcourselocation: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_RESOURCES_DATA:
      if (action.payload) {
        return {
          ...state,
          resources: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_NEWS_DATA:
      if (action.payload) {
        return {
          ...state,
          news: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_PRODUCT_CONSULTING_DATA:
      if (action.payload) {
        return {
          ...state,
          consultingproduct: action,
        };
      }
      return {
        ...state,
      };

    case WidgetBoxTypes.RELATED_PRODUCT_INDUSTRIES_DATA:
      if (action.payload) {
        return {
          ...state,
          industriesproduct: action,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default widgetboxReducer;
