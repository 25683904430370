import moment from 'moment';
import { SoftwareResourcesSearchTypes } from './software-resource.types';
import { compareValues } from '../../../utilities/CommonUtilities';

const SOFTWARE_RESOURCE_SEARCH_STATIC_DATA = {
  lastSearchData: {},
  currentSearchData: {},
};

export const softwareResourceSearchData = (
  state = SOFTWARE_RESOURCE_SEARCH_STATIC_DATA,
  action
) => {
  switch (action.type) {
    case SoftwareResourcesSearchTypes.SOFTWARE_RESOURCE_SEARCH:
      if (action.payload) {
        const payloadData = action.payload;

        const totalDocumentCount = action.payload['@odata.count'];
        const searchFacetsObject = payloadData['@search.facets'];

        let preparedSearchFacets = [];
        let preparedObject = {};
        let datePublished = {};

        if (searchFacetsObject) {
          datePublished = {
            startDate: moment('1975-03-04T10:57:02.444Z'),
            endDate: moment().toISOString(),
          };

          preparedSearchFacets = Object.keys(searchFacetsObject)
            .filter((e) => e !== 'DatePublished')
            .map((e) => {
              const value = searchFacetsObject[e].filter(
                (element) => element.value.length !== 0
              );
              return {
                key: e,
                value, // .sort(compareValues('count', 'desc'))
              };
            });
        }

        preparedObject = {
          ...preparedObject,
          ...action.payload,
          totalDocumentCount,
          preparedSearchFacets,
          datePublished,
        };
        return {
          ...state,
          currentSearchData: preparedObject,
        };
      }
      return {
        ...state,
      };

    case SoftwareResourcesSearchTypes.SOFTWARE_RESOURCE_LAST_SEARCH:
      if (action.payload) {
        return {
          ...state,
          lastSearchData: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
