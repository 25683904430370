import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './assign-attendee-form.styles.scss';
// import { useForm } from "react-hook-form";
import * as Sentry from '@sentry/react';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES } from '../../../../js/api/_config';
import {
  addAttendeesToCourse,
  updateAttendeeItem,
} from '../../../../js/api/shopping-cart/_attendees-cart';
import useForm from '../../../../js/custom-hooks/useForm';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';
import {
  cartAttendeesUpdate,
  updateCartAttendeesAction,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import { compareValues } from '../../../../utilities/CommonUtilities';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import AssignAttendeeList from '../assign-attendee-data/assign-attendee-data';
import validate from './assign-attendee.validationRule';

function AssignAttendeeForm({ products, DropDownContent, mainContent }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [accountinfo, setAccountinfo] = useState(false);

  // const [dropDownContent, setDropDownContent] = useState(DropDownContent);
  const [Country, setCountry] = useState(null);
  const [Industry, setIndustry] = useState(null);
  const [State, setState] = useState(null);
  const [mealOptions, setMealOptions] = useState(null);

  // const { values, register, handleSubmit, handleChange, errors, reset } = useForm();
  // const profileuser = useSelector(({ user }) => user.loggedin_user);
  const normaluser = useSelector(({ user }) => user.loggedin_user);
  const improfileuser = useSelector(({ cart }) =>
    cart.impersonateMember ? cart.impersonateMember.userprofile : null
  );
  const [profileuser, setProfileuser] = useState(improfileuser || normaluser);
  const [tempUser, settempUser] = useState({});

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );
  const [submitting, setSubmitting] = useState(false);
  const cartattendeesArray = useSelector(
    ({ cart }) => cart.cartattendees,
    shallowEqual
  );
  const attendeescountupdate = useSelector(
    ({ cart }) => cart.attendeescount_update,
    shallowEqual
  );
  const formupdateattendees = useSelector(({ cart }) => cart.updateattendees);
  const [attformLoaded, setAttformLoaded] = useState(false);
  const [registerAttendeesCount, setregisterAttendeesCount] = useState(0);

  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );

  const cartItems = useSelector(({ cart }) => cart.cart, shallowEqual);

  const [currentCourseData, setCurrentCourseData] = useState({});
  const [currentSelectedCourse, setCurrentSelectedCourse] = useState('');

  const handleChangeForSelectionCourse = (event) => {
    event.persist();
    const currentData = products.filter(
      (e) =>
        e.variantsku ===
        event.nativeEvent.target[event.nativeEvent.target.selectedIndex].value
    )[0];
    setCurrentCourseData(currentData);
    setCurrentSelectedCourse(
      event.nativeEvent.target[event.nativeEvent.target.selectedIndex].value
    );

    if (
      ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES.includes(
        currentData?.sessionTypeName
      )
    ) {
      values.isDietary = 'y';
      values.dietaryRestrictions = '';
      values.otherDietInstructions = '';
    } else {
      values.isDietary = 'n';
      values.dietaryRestrictions = '';
      values.otherDietInstructions = '';
    }
  };

  const resetCourseState = () => {
    setCurrentCourseData({});
    setCurrentSelectedCourse('');
  };

  const setCourseNameForEdit = (courseName, variantSku) => {
    const dataObject = products.filter(
      (e) => e.productTitle === courseName && e.variantsku === variantSku
    )[0];
    setCurrentCourseData(dataObject);
    setCurrentSelectedCourse(variantSku);
  };

  const getAttendeesLength = (courseName, variantSku) => {
    const filteredList = cartattendeesArray.filter(
      (e) => e.CourseName === courseName && e.VariantSku === variantSku
    )[0];
    return filteredList ? filteredList.attendees?.length : 0;
  };

  const checkForDisable = (courseName, variantSku) => {
    const attendeesLength = getAttendeesLength(courseName, variantSku);
    const dataObject = products.filter(
      (e) => e.productTitle === courseName && e.variantsku === variantSku
    )[0];
    return !(attendeesLength < dataObject.quantity);
  };

  const submitData = async (submissionObj) => {
    try {
      if (submissionObj != undefined && submissionObj != null) {
        let memberId = null;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
        setSubmitting(true);
        if (submissionObj.ID <= 0) {
          const attendeesarr = [];
          attendeesarr.push(submissionObj);
          const data = {
            requeststream: attendeesarr,
            memberid: memberId,
          };

          const response = await addAttendeesToCourse(data);
          if (response) {
            dispatch(cartAttendeesUpdate(1));
          }
          setSubmitting(false);
          clearControls();
        }

        if (submissionObj.ID > 0) {
          const preparedData = [].concat.apply(
            [],
            cartattendeesArray.map((e) => e.attendees)
          );
          const attendeeObj = preparedData.filter(
            (m) => m.ID === submissionObj.ID
          );

          if (attendeeObj && attendeeObj?.length > 0) {
            const data = {
              attendee: submissionObj, // attendeeObj[0]
              memberid: memberId,
            };
            const getcartResponse = await updateAttendeeItem(data);
            if (getcartResponse === 1) {
              // dispatch(cartCountUpdate(1));
              dispatch(cartAttendeesUpdate(1));
            }
            setSubmitting(false);
            clearControls();
            dispatch(updateCartAttendeesAction(null));
            // history.push(location.pathname);
          }
        }
        // RK--commented as this "Thanks You page" is not required for Attendee Form
        // history.push('/thank-you-page')
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in Submit call');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in Submit call',
        },
      });
    }
  };

  const clearControls = async () => {
    setAccountinfo(false);
    values.firstName = '';
    values.lastName = '';
    values.title = '';
    values.companyName = '';
    values.addressLine1 = '';
    values.addressLine2 = '';
    values.countryId = '';
    values.city = '';
    values.stateId = '';
    values.industryId = '';
    values.postalCode = '';
    values.phone = '';
    values.email = '';
    values.dietaryRestrictions = '';
    values.otherDietInstructions = '';
    values.isDietary = 'n';
    dispatch(updateCartAttendeesAction(null));
    resetCourseState();
    // settempUser(null);
  };

  function submitSuccess() {
    setSubmitting(true);
    /* if (!formupdateattendees && registerAttendeesCount === attendeesCount) {
            handleNotify('You have registered max attendees.', ToasterPositions.BottomRight, ToasterTypes.Warning);
            setSubmitting(false);
            return;
        } */

    if (!currentSelectedCourse) {
      handleNotify('Please select a course.', ToasterTypes.Warning);
      setSubmitting(false);
      return;
    }

    const attendeesObj = {
      ID: formupdateattendees ? formupdateattendees.ID : 0,
      FirstName: values.firstName !== undefined ? values.firstName.trim() : '',
      LastName: values.lastName !== undefined ? values.lastName.trim() : '',
      Title: values.title !== undefined ? values.title.trim() : '',
      Company:
        values.companyName !== undefined ? values.companyName.trim() : '',
      IndustryId: values.industryId != null ? values.industryId : '',
      Address:
        values.addressLine1 !== undefined ? values.addressLine1.trim() : '',
      AddressTwo:
        values.addressLine2 !== undefined ? values.addressLine2.trim() : '',
      CountryId: values.countryId != null ? values.countryId : '-1',
      City: values.city !== undefined ? values.city.trim() : '',
      StateId: values.stateId != null ? values.stateId : '-1',
      PostalCode:
        values.postalCode !== undefined ? values.postalCode.trim() : '',
      Phone: values.phone !== undefined ? values.phone.trim() : '',
      EmailAddress: values.email !== undefined ? values.email.trim() : '',
      Sku: currentCourseData && currentCourseData.sku,
      VariantSku: currentCourseData && currentCourseData.variantsku,

      // Dietary instruction data
      DietaryRestrictionOption: 'n',
    };

    if (values?.isDietary === 'y') {
      attendeesObj['DietaryRestrictionOption'] = 'y';
      attendeesObj['DietaryRestrictionMealOptions'] = mealOptions?.filter(
        (e) => e.Id === values.dietaryRestrictions
      )[0]?.Name;
      if (Number(values.dietaryRestrictions) === 6) {
        attendeesObj['DietaryRestrictionMealOptionsOther'] =
          values.otherDietInstructions;
      }
    }

    submitData(attendeesObj);
    resetCourseState();
  }

  const handleAccountInfo = (event) => {
    if (event.target.checked) {
      setAccountinfo(true);
      // settempUser(profileuser);
      values.firstName = profileuser.FirstName;
      values.lastName = profileuser.LastName;
      values.title = profileuser.Title;
      values.companyName = profileuser.Company;
      values.addressLine1 = profileuser.Address ? profileuser.Address : '';
      values.addressLine2 = profileuser.AddressTwo
        ? profileuser.AddressTwo
        : '';
      // + (profileuser.AddressTwo ? + ' ' + profileuser.AddressTwo : '')) : (profileuser.AddressTwo ? profileuser.AddressTwo : '');
      values.countryId = profileuser.CountryID;
      values.city = profileuser.City;
      values.stateId = profileuser.StateID;
      values.industryId = profileuser.IndustryID;
      values.postalCode = profileuser.PostalCode;
      values.phone = profileuser.PhoneNumber;
      values.email = profileuser.Email;
      values.isDietary = ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES.includes(
        currentCourseData?.sessionTypeName
      )
        ? 'y'
        : 'n';
      values.dietaryRestrictions = mealOptions?.filter(
        (e) => e.Name === profileuser?.DietaryRestrictionMealOptions
      )[0]?.Id;
      values.otherDietInstructions =
        profileuser.DietaryRestrictionMealOptionsOther;
    } else {
      setAccountinfo(false);
      // settempUser(null);
      values.firstName = '';
      values.lastName = '';
      values.title = '';
      values.companyName = '';
      values.addressLine1 = '';
      values.addressLine2 = '';
      values.countryId = '';
      values.city = '';
      values.stateId = '';
      values.industryId = '';
      values.postalCode = '';
      values.phone = '';
      values.email = '';
      values.isDietary = 'y';
      values.dietaryRestrictions = '';
      values.otherDietInstructions = '';
    }
  };

  useEffect(() => {
    if (DropDownContent != undefined && DropDownContent.Industry) {
      setIndustry(DropDownContent.Industry);
    }
    if (DropDownContent != undefined && DropDownContent.Country) {
      setCountry(DropDownContent.Country);
    }
    if (DropDownContent != undefined && DropDownContent.State) {
      setState(DropDownContent.State);
    }
    if (
      DropDownContent != undefined &&
      DropDownContent.DietaryRestrictionsMealOptions
    ) {
      setMealOptions(DropDownContent.DietaryRestrictionsMealOptions);
    }

    const result = [];
    if (cartattendeesArray) {
      /* if (variantSku && sku) {
                const hasItem = cartattendeesArray.filter(m => m.VariantSku === variantSku && m.Sku === sku);
                if (hasItem.length > 0) {
                    result = hasItem;
                }
            } else {
                const hasItem = cartattendeesArray.filter(m => m.Sku === sku);
                if (hasItem.length > 0) {
                    result = hasItem;
                }
            } */
    }
    setregisterAttendeesCount(result?.length);
  }, [
    cartattendeesArray != undefined && cartattendeesArray?.length,
    attendeescountupdate,
    DropDownContent,
  ]);

  useEffect(() => {
    clearControls();
  }, []);

  useEffect(() => {
    setAttformLoaded(false);

    if (formupdateattendees) {
      settempUser(profileuser);
      values.firstName = formupdateattendees?.FirstName;
      values.lastName = formupdateattendees?.LastName;
      values.title = formupdateattendees?.Title;
      values.companyName = formupdateattendees?.Company;
      values.addressLine1 = formupdateattendees?.Address
        ? formupdateattendees.Address
        : '';
      values.addressLine2 = formupdateattendees?.AddressTwo
        ? formupdateattendees.AddressTwo
        : '';
      values.countryId = formupdateattendees?.CountryId;
      values.city = formupdateattendees?.City;
      values.stateId = formupdateattendees?.StateId;
      values.industryId = formupdateattendees?.IndustryId;
      values.postalCode = formupdateattendees?.PostalCode;
      values.phone = formupdateattendees?.Phone;
      values.email = formupdateattendees?.EmailAddress;
      values.isDietary = formupdateattendees?.DietaryRestrictionOption;
      values.dietaryRestrictions = mealOptions?.filter(
        (e) => e.Name === formupdateattendees?.DietaryRestrictionMealOptions
      )[0]?.Id;
      values.otherDietInstructions =
        formupdateattendees?.DietaryRestrictionMealOptionsOther;
    }
    setAttformLoaded(true);
  }, [formupdateattendees]);

  const currentSessionSelected = products?.filter(
    (e) => e?.variantsku === currentSelectedCourse
  )[0]?.sessionTypeName;

  return (
    <div className="outer-assign-attendee-container">
      <div className="assign-attendee-form">
        {/* <h2 className="pb-2 form-title">
                        {productTitle} <span
                        className="attendee-title">Attendees# {registerAttendeesCount + 1} (Total {attendeesCount}) </span>{" "}
                    </h2> */}

        <Row style={{ width: '100%' }}>
          <Col xs={12} md={12} lg={6}>
            {products ? (
              <>
                <span className="assign-attendee-top-heading">
                  Add Attendee(s) to the Course
                </span>
                <hr />
                <SelectList
                  onChange={handleChangeForSelectionCourse}
                  name="courseSelection"
                  value={currentSelectedCourse}
                  defaultText="— Choose Course to Add Attendee(s) —"
                  className="mb-2"
                >
                  {products.map((individualProduct) => (
                    <option
                      key={Math.random()}
                      disabled={checkForDisable(
                        individualProduct.productTitle,
                        individualProduct.variantsku
                      )}
                      className="filter"
                      value={individualProduct.variantsku}
                    >
                      {individualProduct.productTitle} -
                      {individualProduct.sessionTypeName} (
                      {getAttendeesLength(
                        individualProduct.productTitle,
                        individualProduct.variantsku
                      )}{' '}
                      / {individualProduct.quantity} Attendee(s) Added)
                    </option>
                  ))}
                </SelectList>
              </>
            ) : (
              <Skeleton height={80} />
            )}

            <form className="inner-form" onSubmit={handleSubmit} noValidate>
              <div className="form-section contact-information">
                <div style={{ marginTop: '0.7rem' }}>
                  <input
                    type="checkbox"
                    name="infoCheck"
                    id="infoCheck"
                    onChange={handleAccountInfo}
                    // value={accountinfo}
                    checked={accountinfo}
                  />

                  <label className="pl-2 pr-4" htmlFor="infoCheck">
                    Use the account information
                  </label>
                </div>

                {errors.firstName && (
                  <span className="help">{errors.firstName}</span>
                )}
                <TextInput
                  className={`${errors.firstName && 'error'}`}
                  placeholder="First Name"
                  name="firstName"
                  value={values ? values.firstName : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true, maxLength: 20 })}
                />

                {errors.lastName && (
                  <span className="help">{errors.lastName}</span>
                )}
                <TextInput
                  className={`${errors.lastName && 'error'}`}
                  placeholder="Last Name"
                  name="lastName"
                  value={values ? values.lastName : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true, pattern: /^[A-Za-z]+$/i })}
                />

                {errors.title && <span className="help">{errors.title}</span>}
                <TextInput
                  className={`${errors.title && 'error'}`}
                  placeholder="Title"
                  name="title"
                  value={values ? values.title : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true })}
                />

                {errors.email && <span className="help">{errors.email}</span>}
                <TextInput
                  className={`${errors.email && 'error'}`}
                  placeholder="Email"
                  name="email"
                  type="email"
                  value={values ? values.email : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true })}
                />
                {errors.phone && <span className="help">{errors.phone}</span>}
                <TextInput
                  className={`${errors.phone && 'error'}`}
                  placeholder="Phone"
                  name="phone"
                  value={values ? values.phone : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true })}
                />
                {errors.companyName && (
                  <span className="help">{errors.companyName}</span>
                )}
                <TextInput
                  className={`${errors.companyName && 'error'}`}
                  placeholder="Company Name"
                  name="companyName"
                  value={values ? values.companyName : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true })}
                />

                {errors.industryId && (
                  <span className="help">{errors.industryId}</span>
                )}
                <SelectList
                  className={`${errors.industryId && 'error'}`}
                  defaultText="— Industry —"
                  name="industryId"
                  onChange={handleChange}
                  value={values ? values.industryId : ''}
                  required={!!errors.industryId}
                >
                  {Industry?.map((element, index) => (
                    <option key={element.Name} value={element.Id}>
                      {element.Name}
                    </option>
                  ))}
                </SelectList>

                {errors.addressLine1 && (
                  <span className="help">{errors.addressLine1}</span>
                )}
                <TextInput
                  className={`${errors.addressLine1 && 'error'}`}
                  placeholder="Business Address, Line1"
                  name="addressLine1"
                  value={values ? values.addressLine1 : ''}
                  onChange={handleChange}
                  required
                  autoComplete="off"
                  // ref={register({ required: true })}
                />
                <TextInput
                  className={`${errors.addressLine2 && 'error'}`}
                  placeholder="Business Address, Line2"
                  name="addressLine2"
                  value={values ? values.addressLine2 : ''}
                  onChange={handleChange}
                  required={false}
                  autoComplete="off"
                  // ref={register({ required: true })}
                />
                <Row>
                  <Col xs={8} className="pr-2">
                    {errors.city && <span className="help">{errors.city}</span>}
                    <TextInput
                      className={`${errors.city && 'error'}`}
                      placeholder="City"
                      name="city"
                      value={values ? values.city : ''}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                      // ref={register({ required: true })}
                    />
                  </Col>
                  <Col xs={4}>
                    {errors.stateId && (
                      <span className="help">{errors.stateId}</span>
                    )}
                    <SelectList
                      defaultText="— State —"
                      name="stateId"
                      onChange={handleChange}
                      value={values ? values.stateId : ''}
                      required={!!errors.stateId}
                    >
                      {State?.map((element, index) => (
                        <option key={element.Name} value={element.Id}>
                          {element.Name}
                        </option>
                      ))}
                    </SelectList>
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} className="pr-2">
                    {errors.postalCode && (
                      <span className="help">{errors.postalCode}</span>
                    )}
                    <TextInput
                      className={`${errors.postalCode && 'error'}`}
                      placeholder="Postal Code"
                      name="postalCode"
                      value={values ? values.postalCode : ''}
                      onChange={handleChange}
                      required
                      autoComplete="off"
                      // ref={register({ required: true })}
                    />
                  </Col>
                  <Col xs={8}>
                    {/* <SelectList
                    defaultText="— Country —"
                    name={"CountryID"}
                  ></SelectList> */}
                    {errors.countryId && (
                      <span className="help">{errors.countryId}</span>
                    )}
                    <SelectList
                      defaultText="— Country —"
                      name="countryId"
                      onChange={handleChange}
                      required
                      value={values ? values.countryId : ''}
                      required={!!errors.countryId}
                    >
                      {Country?.map((element, index) => (
                        <option key={element.Name} value={element.Id}>
                          {element.Name}
                        </option>
                      ))}
                    </SelectList>
                  </Col>
                </Row>

                {ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES.includes(
                  currentSessionSelected
                ) && (
                  <Row>
                    <Col xs={12}>
                      <Row className="bottom-dietary-component">
                        <p className="caption-text">
                          Do you have any dietary restrictions?
                        </p>
                      </Row>

                      <Row className="radio-bottom-dietary-wrapper">
                        <div
                          onChange={handleChange}
                          className="radio-wrapper-diet"
                        >
                          <input
                            checked={values?.isDietary === 'y'}
                            className="radio-button"
                            type="radio"
                            id="isDietary"
                            name="isDietary"
                            value="y"
                          />
                          <label className="label-text" for="isDietary">
                            Yes
                          </label>
                          <input
                            checked={
                              values?.isDietary === undefined ||
                              values?.isDietary === 'n'
                            }
                            className="radio-button-right"
                            type="radio"
                            id="isDietary"
                            name="isDietary"
                            value="n"
                          />
                          <label className="label-text" for="isDietary">
                            No
                          </label>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                )}

                {ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES.includes(
                  currentSessionSelected
                ) &&
                  values?.isDietary === 'y' && (
                    <Row className="dietary-drop-down-block">
                      <p className="top-caption">
                        Dietary restriction must be indicated here to receive
                        the appropriate meal options during the course. Please
                        select any of the following that applies
                      </p>
                      <Col xs={12}>
                        {values?.isDietary && errors.dietaryRestrictions && (
                          <span className="help">
                            {errors.dietaryRestrictions}
                          </span>
                        )}
                        <SelectList
                          defaultText="— Dietary Restrictions —"
                          name="dietaryRestrictions"
                          onChange={handleChange}
                          value={values ? values.dietaryRestrictions : ''}
                          required={!!errors.dietaryRestrictions}
                        >
                          {mealOptions
                            ?.sort(compareValues('Name', 'desc'))
                            ?.map((element, index) => (
                              <option key={element.Name} value={element.Id}>
                                {element.Name}
                              </option>
                            ))}
                        </SelectList>
                      </Col>
                    </Row>
                  )}

                {ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES.includes(
                  currentSessionSelected
                ) &&
                  values?.isDietary === 'y' &&
                  Number(values.dietaryRestrictions) === 6 && (
                    <Row className="dietary-other-instruction-block">
                      <Col xs={12}>
                        <p className="top-caption">
                          Please enter your food allergies
                        </p>
                        {errors.otherDietInstructions && (
                          <span className="help">
                            {errors.otherDietInstructions}
                          </span>
                        )}
                        <TextInput
                          className={`${
                            errors.otherDietInstructions && 'error'
                          }`}
                          placeholder=""
                          name="otherDietInstructions"
                          value={values ? values.otherDietInstructions : ''}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                        />
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              mainContent?.DietaryRestrictionsMealOptionsDetail,
                          }}
                        />
                      </Col>
                    </Row>
                  )}

                <div className="mb-4 mt-md-0 submission-button-container">
                  <CustomButton
                    type="submit"
                    className="mb-1 mr-sm-2 mt-4"
                    loading={submitting}
                  >
                    {formupdateattendees === null
                      ? 'Add Attendee'
                      : formupdateattendees && formupdateattendees.ID == 0
                      ? 'Add Attendee'
                      : 'Update Attendee'}
                  </CustomButton>

                  <CustomButton
                    type="button"
                    altColors
                    className="mb-1 mt-4"
                    onClick={clearControls}
                  >
                    Clear
                  </CustomButton>
                </div>
              </div>
            </form>
          </Col>

          <Col xs={12} md={12} lg={6}>
            <AssignAttendeeList
              productTitle={currentCourseData && currentCourseData.productTitle}
              sku={currentCourseData && currentSelectedCourse.sku}
              setCourseNameForEdit={setCourseNameForEdit}
              products={products}
              variantSku={
                currentSelectedCourse && currentSelectedCourse.variantsku
              }
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AssignAttendeeForm;
