import React, { useEffect, useState } from 'react';
import TrainingAzureSearchPostList from './TrainingAzureSearchPostList';
import { makeStyles, SwipeableDrawer } from '@material-ui/core';
import './TrainingAzureSearchRootComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import {
  processTrainingParameterAndSendQuery,
  sendToURL,
  trainingSearchRelevance,
} from '../../../../utilities/CommonUtilities';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import TrainingAzureFilterRootComponent from './TrainingAzureFilterRootComponent';
import {
  getTrainingAzureSearchData,
  staticTrainingAzureSearchData,
} from '../../../../redux/training/training-new-search/training-azure-search.action';

function TrainingAzureSearchRootComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openSwappableDrawer, setOpenSwappableDrawer] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [dynamicSearchData, setDynamicSearchData] = React.useState([]);
  const [searchBoxText, setSearchBoxText] = React.useState('');

  const [filterSelection, setFilterSelection] = React.useState('');
  const [isFreeCheckboxChecked, setIsFreeCheckboxChecked] = useState(false);

  const [rangeState, setRangeState] = useState({
    min: moment().toDate().getTime(),
    max: moment().add(1, 'years').toDate().getTime(),
  });

  const [priceRangeState, setPriceRangeState] = useState({
    min: 0,
    max: 5000,
  });

  const currentTrainingAzureSearchData = useSelector(
    (state) => state.trainingAzureSearchData.currentSearchData
  );
  const lastSearchData = useSelector(
    (state) => state.trainingAzureSearchData.lastSearchData
  );
  const freecoursecount = useSelector(
    (state) => state.trainingAzureSearchData.freeCourseCount
  );

  useEffect(() => {
    let data = {};
    if (window.location?.pathname === '/training/webinar') {
      data = { ds: ['ByFormat|Webinar'] };
      setDynamicSearchData(['ByFormat|Webinar']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (window.location?.pathname === '/training/seminar') {
      data = { ds: ['ByFormat|Seminar'] };
      setDynamicSearchData(['ByFormat|Seminar']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (window.location?.pathname === '/training/complimentary') {
      data = { ds: ['ByFormat|Complimentary'] };
      setDynamicSearchData(['ByFormat|Complimentary']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (window.location?.pathname === '/training/classroom') {
      data = { ds: ['ByFormat|Classroom'] };
      setDynamicSearchData(['ByFormat|Classroom']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      window.location?.pathname ===
      '/training/course-by-category/air-quality-modeling'
    ) {
      data = { ds: ['ByCategory|Air Quality Modeling'] };
      setDynamicSearchData(['ByCategory|Air Quality Modeling']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      window.location?.pathname ===
      '/training/course-by-category/ehs-regulations'
    ) {
      data = { ds: ['ByCategory|EHS Regulations'] };
      setDynamicSearchData(['ByCategory|EHS Regulations']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/course-by-category/regulatory-agency'.toLowerCase()
    ) {
      data = { ds: ['ByCategory|Regulatory Agency'] };
      setDynamicSearchData(['ByCategory|Regulatory Agency']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      window.location?.pathname ===
      '/training/course-by-category/ehs-s-management'
    ) {
      data = { ds: ['ByCategory|EHS&S Management'] };
      setDynamicSearchData(['ByCategory|EHS&S Management']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      window.location?.pathname ===
      '/training/course-by-category/environmental-analysis'
    ) {
      data = { ds: ['ByCategory|Environmental Analysis'] };
      setDynamicSearchData(['ByCategory|Environmental Analysis']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      window.location?.pathname ===
      '/training/course-by-category/environmental-reporting'
    ) {
      data = { ds: ['ByCategory|Environmental Reporting'] };
      setDynamicSearchData(['ByCategory|Environmental Reporting']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/industrial-hygiene'.toLowerCase()
    ) {
      data = { ds: ['ByCategory|Industrial Hygiene'] };
      setDynamicSearchData(['ByCategory|Industrial Hygiene']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/cement'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Cement'] };
      setDynamicSearchData(['ByIndustry|Cement']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/chemical-manufacturing'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Chemical Manufacturing'] };
      setDynamicSearchData(['ByIndustry|Chemical Manufacturing']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/chemical-pharmaceuticals'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Chemicals/Pharmaceuticals'] };
      setDynamicSearchData(['ByIndustry|Chemicals/Pharmaceuticals']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/data-centers'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Data Centers'] };
      setDynamicSearchData(['ByIndustry|Data Centers']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/forest-products'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Forest Products'] };
      setDynamicSearchData(['ByIndustry|Forest Products']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/industrial-sand'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Industrial Sand'] };
      setDynamicSearchData(['ByIndustry|Industrial Sand']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/iron-steel'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Iron & Steel'] };
      setDynamicSearchData(['ByIndustry|Iron & Steel']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/landfills'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Landfills'] };
      setDynamicSearchData(['ByIndustry|Landfills']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/liquid-terminals'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Liquid Terminals'] };
      setDynamicSearchData(['ByIndustry|Liquid Terminals']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/mining'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|mining'] };
      setDynamicSearchData(['ByIndustry|mining']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/oil-natural-gas'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Oil & Natural Gas'] };
      setDynamicSearchData(['ByIndustry|Oil & Natural Gas']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/oil-natural-gas-refining'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Oil & Natural Gas/Refining'] };
      setDynamicSearchData(['ByIndustry|Oil & Natural Gas/Refining']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/petroleum-refining'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Petroleum Refining'] };
      setDynamicSearchData(['ByIndustry|Petroleum Refining']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/power-generation'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Power Generation'] };
      setDynamicSearchData(['ByIndustry|Power Generation']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/semiconductors'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Semiconductors'] };
      setDynamicSearchData(['ByIndustry|Semiconductors']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/industry-specific'.toLowerCase()
    ) {
      data = { ds: ['ByCategory|Industry Specific'] };
      setDynamicSearchData(['ByCategory|Industry Specific']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/industry/life-science'.toLowerCase()
    ) {
      data = { ds: ['ByIndustry|Life Science'] };
      setDynamicSearchData(['ByIndustry|Life Science']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/course-by-category/location-specific'.toLowerCase()
    ) {
      data = { ds: ['ByCategory|Location Specific'] };
      setDynamicSearchData(['ByCategory|Location Specific']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    } else if (
      (window.location?.pathname).toLowerCase() ===
      '/training/software/software-courses'.toLowerCase()
    ) {
      data = { ds: ['ByCategory|Software Courses'] };
      setDynamicSearchData(['ByCategory|Software Courses']);
      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    }

    if (window.location.search.length > 0) {
      data = qs.parse(window.location.search.substring(1));

      if (data?.ds) setDynamicSearchData(data?.ds);

      if (data?.rs) {
        setRangeState({
          min: Number.parseInt(data?.rs.min),
          max: Number.parseInt(data?.rs.max),
        });
      }

      if (data?.sbt) setSearchBoxText(data?.sbt);

      if (data?.drp) setFilterSelection(data?.drp);

      if (data?.pg) setActivePage(data?.pg);

      if (data?.prs) {
        setPriceRangeState({
          min: Number.parseInt(data?.prs.min),
          max: Number.parseInt(data?.prs.max),
        });
        if (
          data?.prs.min &&
          parseInt(data?.prs.min) === 0 &&
          data?.prs.max &&
          parseInt(data?.prs.max) === 0
        )
          setIsFreeCheckboxChecked(true);
        else setIsFreeCheckboxChecked(false);
      }

      // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 0)
    }
    // else {
    //     dispatch(getTrainingAzureSearchData(staticTrainingAzureSearchData))
    // }
    // setTimeout(() => dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data))), 1500)
    // dispatch(getTrainingAzureSearchData(processTrainingParameterAndSendQuery(staticTrainingAzureSearchData, data)));

    if (Object.keys(data).length > 0) {
      dispatch(
        getTrainingAzureSearchData(
          processTrainingParameterAndSendQuery(
            staticTrainingAzureSearchData,
            data
          )
        )
      );
    } else {
      dispatch(getTrainingAzureSearchData(staticTrainingAzureSearchData));
    }
  }, [window.location]);

  // ADVANCED SEARCH FILTERS
  useEffect(() => {
    if (
      lastSearchData.orderby === 'search.score() desc' ||
      lastSearchData.orderby === ''
    )
      setFilterSelection('relevance');
    else setFilterSelection(lastSearchData.orderby);
  }, [lastSearchData]);

  const toggleDrawer = () => {
    setOpenSwappableDrawer((e) => !e);
  };

  const getFilterButton = () => (
    <div className="resource-filter-btn">
      <div style={{ marginBottom: '1rem' }}>
        {currentTrainingAzureSearchData.totalDocumentCount > 0 ? (
          currentTrainingAzureSearchData.totalDocumentCount < 10 ? (
            <span>
              {' '}
              Showing
              {currentTrainingAzureSearchData.totalDocumentCount} courses{' '}
            </span>
          ) : (
            <span>
              {' '}
              Showing {activePage * 10 - 9} - {activePage * 10} of{' '}
              {currentTrainingAzureSearchData.totalDocumentCount} courses{' '}
            </span>
          )
        ) : (
          <span> No Results Available </span>
        )}
      </div>

      <button className="button-style" onClick={toggleDrawer}>
        <i
          className="fa fa-filter"
          style={{ fontSize: '20px' }}
          aria-hidden="true"
        />
      </button>
    </div>
  );

  const handlePageChange = (pageNumber) => {
    // let newObject = Object.assign({}, lastSearchData, {"skip": (pageNumber * 10) - 10})
    // dispatch(getTrainingAzureSearchData(newObject))
    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: rangeState,
      pg: pageNumber,
      drp: filterSelection,
      prs: priceRangeState,
    };
    sendToURL(history, data);
    setActivePage(pageNumber);
  };

  const onChangeDynamicCheckBox = (parentKey, selectedValue, isChecked) => {
    let preparedArray = [];

    if (isChecked) {
      preparedArray = dynamicSearchData.concat(`${parentKey}|${selectedValue}`);
      setDynamicSearchData(preparedArray);
    } else {
      preparedArray = dynamicSearchData.filter(
        (e) => e !== `${parentKey}|${selectedValue}`
      );
      setDynamicSearchData(preparedArray);
    }

    const newArray = preparedArray.map((outerElement, index) => {
      const key = outerElement.split('|')[0];
      const value = outerElement.split('|')[1];

      if (preparedArray.length - 1 === index) {
        if (
          key == 'ByCategory' ||
          key == 'ByIndustry' ||
          key == 'RegulatoryAgency' ||
          key == 'BusinessUnit'
        ) {
          return `(${key}/any(t: t eq '${value}'))`;
        }
        return `(${key} eq '${value}')`;
      }
      if (
        key == 'ByCategory' ||
        key == 'ByIndustry' ||
        key == 'RegulatoryAgency' ||
        key == 'BusinessUnit'
      ) {
        return `(${key}/any(t: t eq '${value}')) and`;
      }
      return `(${key} eq '${value}') and `;
    });

    let dynamicString = newArray.toString().split(',').join('');

    let minvalue = moment(rangeState.min).toISOString();
    minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

    let maxvalue = moment(rangeState.max).toISOString();
    maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

    const preparedDate = `${minvalue}|${maxvalue}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;

    const preparedAmount = `${priceRangeState.min}|${priceRangeState.max}`;
    const newAmountString = `Amount,values: ${preparedAmount}`;

    let finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );
    finalChangedData = finalChangedData.filter((e) => !e.includes('Amount'));

    finalChangedData.push(newDateString);
    finalChangedData.push(newAmountString);

    const currentData = lastSearchData.filter.toString().split('and');

    let preparedDateFilter = currentData.map((element, index) => {
      if (element.includes('DatePublished')) {
        return element;
      }
      return '';
    });

    preparedDateFilter =
      preparedDateFilter && preparedDateFilter.filter((x) => x !== '');
    const strPreparedDateFilter =
      preparedDateFilter &&
      preparedDateFilter.toString().split(',').join(' and ');

    if (strPreparedDateFilter.length > 0 && dynamicString.length > 0) {
      dynamicString = `${dynamicString} and ${strPreparedDateFilter}`;
    } else if (strPreparedDateFilter.length > 0 && dynamicString.length === 0) {
      dynamicString = strPreparedDateFilter;
    }

    let preparedAmountFilter = currentData.map((element, index) => {
      if (element.includes('Amount')) {
        return element;
      }
      return '';
    });

    preparedAmountFilter =
      preparedAmountFilter && preparedAmountFilter.filter((x) => x !== '');
    const strPreparedAmountFilter =
      preparedAmountFilter &&
      preparedAmountFilter.toString().split(',').join(' and ');

    if (strPreparedAmountFilter.length > 0 && dynamicString.length > 0) {
      dynamicString = `${dynamicString} and ${strPreparedAmountFilter}`;
    } else if (
      strPreparedAmountFilter.length > 0 &&
      dynamicString.length === 0
    ) {
      dynamicString = strPreparedAmountFilter;
    }

    let finalFilterString = '';

    finalFilterString =
      dynamicString.replace(/[ ]/g, '').length > 0
        ? // ? "DatePublished ge " + moment(rangeState.min).toISOString() + " and DatePublished le " + moment(rangeState.max).toISOString() + " and " + dynamicString
          dynamicString
        : '';

    const newObject = {
      ...lastSearchData,
      filter: finalFilterString,
      facets: finalChangedData,
      skip: 0,
    };

    dispatch(getTrainingAzureSearchData(newObject));

    setTimeout(() => {
      const data = {
        ds: preparedArray,
        sbt: searchBoxText,
        rs: rangeState,
        pg: 1,
        drp: filterSelection,
        prs: priceRangeState,
      };

      sendToURL(history, data);

      setActivePage(1);
    }, 1000);
  };

  const resetAllFiltersAndSearch = () => {
    if (lastSearchData !== staticTrainingAzureSearchData) {
      setOpenSwappableDrawer(false);
      setActivePage(1);
      setDynamicSearchData([]);
      setSearchBoxText('');
      setRangeState({
        min: moment().toDate().getTime(),
        max: moment().add(1, 'years').toDate().getTime(),
      });
      setPriceRangeState({
        min: 0,
        max: 5000,
      });
      setIsFreeCheckboxChecked(false);
      dispatch(getTrainingAzureSearchData(staticTrainingAzureSearchData));
      window.history.replaceState(null, '', `${history?.location?.pathname}`);
    } else {
      handleNotify('There are no filters applied.', ToasterTypes.Error);
    }
  };

  const rangePickerChangeComplete = (value) => {
    let minvalue = moment(value.min).toISOString();
    minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

    let maxvalue = moment(value.max).toISOString();
    maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

    const preparedDate = `${minvalue}|${maxvalue}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;

    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );

    const currentData = lastSearchData.filter.toString().split(' and ');

    const preparedData = currentData
      .map((element, index) => {
        if (element.includes('DatePublished')) {
          return '';
        }
        if (currentData.length - 1 === index) {
          return element;
        }
        return `${element} and `;
      })
      .toString()
      .split(',')
      .join('');

    let newFilters = '';

    if (preparedData.length === 0) {
      let minvalue = moment(rangeState.min).toISOString();
      minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

      let maxvalue = moment(rangeState.max).toISOString();
      maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

      newFilters = `DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
    } else {
      let minvalue = moment(rangeState.min).toISOString();
      minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

      let maxvalue = moment(rangeState.max).toISOString();
      maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

      const anddata = preparedData.toString().split(' and ');
      if (
        anddata[anddata.length - 1] == ' ' ||
        anddata[anddata.length - 1] == ''
      ) {
        newFilters = `${preparedData} DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
      } else {
        newFilters = `${preparedData} and DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
      }
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: value,
      pg: activePage,
      drp: filterSelection,
      prs: priceRangeState,
    };
    setTimeout(() => {
      const newObject = {
        ...lastSearchData,
        facets: finalChangedData
          .filter((e) => e !== e.toString().includes('DatePublished'))
          .concat(newDateString),
        filter: newFilters,
      };

      sendToURL(history, data);
      dispatch(getTrainingAzureSearchData(newObject));
    }, 2000);
  };

  const onSearchEnter = () => {
    if (searchBoxText.trim().toString().length === 0) {
      // RP - SD39 : Training page- Advanced search Filter
      const newObject = {
        ...lastSearchData,
        search: '*',
        orderby: 'DatePublished asc',
      };
      dispatch(getTrainingAzureSearchData(newObject));

      setTimeout(() => {
        const data = {
          ds: dynamicSearchData,
          sbt: searchBoxText,
          rs: rangeState,
          pg: 1,
          drp: filterSelection,
          prs: priceRangeState,
        };

        sendToURL(history, data);
        setActivePage(1);
      }, 1000);
    } else {
      const newObject = {
        ...lastSearchData,
        search: searchBoxText,
        orderby: 'search.score() desc',
      };
      dispatch(getTrainingAzureSearchData(newObject));

      setTimeout(() => {
        const data = {
          ds: dynamicSearchData,
          sbt: searchBoxText,
          rs: rangeState,
          pg: 1,
          drp: 'relevance',
          prs: priceRangeState,
        };

        sendToURL(history, data);
        setActivePage(1);
        setFilterSelection('relevance');
      }, 1000);
    }
  };

  const onChangeHandleChange = (value) => {
    setRangeState(value);
  };

  const onChangeHandlePriceChange = (value) => {
    setPriceRangeState(value);
  };

  const rangePickerPriceChangeComplete = (value) => {
    setIsFreeCheckboxChecked(false);
    const preparedAmount = `${value.min}|${value.max}`;
    const newAmountString = `Amount,values: ${preparedAmount}`;

    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('Amount')
    );

    const currentData = lastSearchData.filter.toString().split(' and ');

    const preparedData = currentData
      .map((element, index) => {
        if (element.includes('Amount')) {
          return '';
        }
        if (currentData.length - 1 === index) {
          return element;
        }
        return `${element} and `;
      })
      .toString()
      .split(',')
      .join('');

    let newFilters = '';

    if (preparedData.length === 0) {
      newFilters = `Amount ge ${priceRangeState.min} and Amount le ${priceRangeState.max}`;
    } else {
      const anddata = preparedData.toString().split(' and ');
      if (
        anddata[anddata.length - 1] == ' ' ||
        anddata[anddata.length - 1] == ''
      ) {
        newFilters = `${preparedData} Amount ge ${priceRangeState.min} and Amount le ${priceRangeState.max}`;
      } else {
        newFilters = `${preparedData} and Amount ge ${priceRangeState.min} and Amount le ${priceRangeState.max}`;
      }
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: rangeState,
      pg: activePage,
      drp: filterSelection,
      prs: value,
    };
    setTimeout(() => {
      const newObject = {
        ...lastSearchData,
        facets: finalChangedData
          .filter((e) => e !== e.includes('Amount'))
          .concat(newAmountString),
        filter: newFilters,
      };

      sendToURL(history, data);
      dispatch(getTrainingAzureSearchData(newObject));
    }, 2000);
  };

  const OnChangeFreeCheckBox = (isChecked) => {
    setIsFreeCheckboxChecked(isChecked);

    if (isChecked) {
      setPriceRangeState({
        min: 0,
        max: 0,
      });

      const preparedAmount = `${0}|${0}`;
      const newAmountString = `Amount,values: ${preparedAmount}`;

      const finalChangedData = lastSearchData.facets.filter(
        (e) => !e.includes('Amount')
      );

      const currentData = lastSearchData.filter.toString().split(' and ');

      const preparedData = currentData
        .map((element, index) => {
          if (element.includes('Amount')) {
            return '';
          }
          if (currentData.length - 1 === index) {
            return element;
          }
          return `${element} and `;
        })
        .toString()
        .split(',')
        .join('');

      let newFilters = '';

      if (preparedData.length === 0) {
        newFilters = `Amount ge ${0} and Amount le ${0}`;
      } else {
        var anddata = preparedData.toString().split(' and ');
        if (
          anddata[anddata.length - 1] == ' ' ||
          anddata[anddata.length - 1] == ''
        ) {
          newFilters = `${preparedData} Amount ge ${0} and Amount le ${0}`;
        } else {
          newFilters = `${preparedData} and Amount ge ${0} and Amount le ${0}`;
        }
      }

      const data = {
        ds: dynamicSearchData,
        sbt: searchBoxText,
        rs: rangeState,
        pg: activePage,
        drp: filterSelection,
        prs: { min: 0, max: 0 },
      };
      setTimeout(() => {
        const newObject = {
          ...lastSearchData,
          facets: finalChangedData
            .filter((e) => e !== e.includes('Amount'))
            .concat(newAmountString),
          filter: newFilters,
        };

        sendToURL(history, data);
        dispatch(getTrainingAzureSearchData(newObject));
      }, 2000);
    } else {
      setPriceRangeState({
        min: 0,
        max: 5000,
      });

      const preparedAmount = `${0}|${5000}`;
      const newAmountString = `Amount,values: ${preparedAmount}`;

      const finalChangedData = lastSearchData.facets.filter(
        (e) => !e.includes('Amount')
      );

      const currentData = lastSearchData.filter.toString().split(' and ');

      const preparedData = currentData
        .map((element, index) => {
          if (element.includes('Amount')) {
            return '';
          }
          if (currentData.length - 1 === index) {
            return element;
          }
          return `${element} and `;
        })
        .toString()
        .split(',')
        .join('');

      let newFilters = '';

      if (preparedData.length === 0) {
        newFilters = `Amount ge ${0} and Amount le ${5000}`;
      } else {
        var anddata = preparedData.toString().split(' and ');
        if (
          anddata[anddata.length - 1] == ' ' ||
          anddata[anddata.length - 1] == ''
        ) {
          newFilters = `${preparedData} Amount ge ${0} and Amount le ${5000}`;
        } else {
          newFilters = `${preparedData} and Amount ge ${0} and Amount le ${5000}`;
        }
      }

      const data = {
        ds: dynamicSearchData,
        sbt: searchBoxText,
        rs: rangeState,
        pg: activePage,
        drp: filterSelection,
        prs: { min: 0, max: 5000 },
      };
      setTimeout(() => {
        const newObject = {
          ...lastSearchData,
          facets: finalChangedData
            .filter((e) => e !== e.includes('Amount'))
            .concat(newAmountString),
          filter: newFilters,
        };

        sendToURL(history, data);
        dispatch(getTrainingAzureSearchData(newObject));
      }, 2000);
    }
  };

  const getCommonResourceFilter = () => (
    <TrainingAzureFilterRootComponent
      searchBoxText={searchBoxText}
      setSearchBoxText={setSearchBoxText}
      onSearchEnter={onSearchEnter}
      dynamicSearchData={dynamicSearchData}
      openSwappableDrawer={openSwappableDrawer}
      rangeState={rangeState}
      setRangeState={setRangeState}
      onChangeHandleChange={onChangeHandleChange}
      rangePickerChangeComplete={rangePickerChangeComplete}
      facetsList={
        currentTrainingAzureSearchData &&
        currentTrainingAzureSearchData.preparedSearchFacets
      }
      onChangeDynamicCheckBox={onChangeDynamicCheckBox}
      OnChangeFreeCheckBox={OnChangeFreeCheckBox}
      isFreeCheckboxChecked={isFreeCheckboxChecked}
      freecoursecount={freecoursecount}
      isShowRedIcon={lastSearchData === staticTrainingAzureSearchData}
      resetAllFiltersAndSearch={resetAllFiltersAndSearch}
      priceRangeState={priceRangeState}
      setPriceRangeState={setPriceRangeState}
      onChangeHandlePriceChange={onChangeHandlePriceChange}
      rangePickerPriceChangeComplete={rangePickerPriceChangeComplete}
    />
  );

  const onChangeDropDownData = (key) => {
    let newObject = {};

    if (key === 'relevance' || key === 'Relevance') {
      newObject = { ...lastSearchData, orderby: 'search.score() desc' };
      // setFilterSelection('None')
    } else {
      newObject = { ...lastSearchData, orderby: key };
      // setFilterSelection('None')
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      pg: activePage,
      drp: key,
    };
    sendToURL(history, data);
    dispatch(getTrainingAzureSearchData(newObject));
  };

  return (
    <div>
      <div className="resource-search-outer-container">
        <div className="main-content">
          <div className="search-filter-container">{getFilterButton()}</div>
          <div className="activity-container">
            <div className="active-resource-left-column">
              <div className="active-search-filter">
                {getCommonResourceFilter()}

                <div className="active-search-filter desktop-mode">
                  <SwipeableDrawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    open={openSwappableDrawer}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                  >
                    {getCommonResourceFilter()}
                  </SwipeableDrawer>
                </div>
              </div>
            </div>

            <div className="active-block">
              <div>
                <div className="root-search-outer-top-container">
                  <div className="left-outer-search-container">
                    <div style={{ marginBottom: '1rem' }}>
                      {currentTrainingAzureSearchData.totalDocumentCount > 0 ? (
                        currentTrainingAzureSearchData.totalDocumentCount <
                        10 ? (
                          <span>
                            {' '}
                            Showing{' '}
                            {
                              currentTrainingAzureSearchData.totalDocumentCount
                            }{' '}
                            courses{' '}
                          </span>
                        ) : (
                          <span>
                            {' '}
                            Showing {activePage * 10 - 9} - {activePage * 10} of{' '}
                            {currentTrainingAzureSearchData.totalDocumentCount}{' '}
                            courses{' '}
                          </span>
                        )
                      ) : (
                        <span> No Results Available </span>
                      )}
                    </div>
                  </div>

                  <div className="right-outer-search-container">
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="relevance-outlined-label">
                        Sort By
                      </InputLabel>
                      <Select
                        labelId="relevance-label"
                        id="relevance-selector"
                        value={
                          !filterSelection || filterSelection === ''
                            ? 'relevance'
                            : filterSelection
                        }
                        // value={!filterSelection || filterSelection === "None" || filterSelection === "relevance" || filterSelection === "search.score() desc" ? "relevance" : filterSelection}
                        onChange={(e) => onChangeDropDownData(e.target.value)}
                        label="Relevance"
                      >
                        <MenuItem value="relevance">Relevance</MenuItem>
                        {trainingSearchRelevance.map((element) => (
                          <MenuItem value={element.key}>
                            {element.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <TrainingAzureSearchPostList
                activePage={activePage}
                valueList={currentTrainingAzureSearchData?.preparedGroupedList}
              />

              {currentTrainingAzureSearchData &&
                currentTrainingAzureSearchData?.preparedGroupedList?.length >
                  0 && (
                  <div className="resource-search-pagination-outer">
                    <Pagination
                      className="pagination"
                      prevPageText="Previous"
                      nextPageText="Next"
                      hideDisabled
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={
                        currentTrainingAzureSearchData.totalDocumentCount
                      }
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 350,
  },
}));

export default TrainingAzureSearchRootComponent;
