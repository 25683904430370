import React from 'react';
import { Link } from 'react-router-dom';

import CustomButton from '../../../commons/custom-button/custom-button.component';

import './request-demo.styles.scss';

function RequestDemoWidget({ title }) {
  const link = 'Request a Demo';
  return (
    <div className="request-demo-container">
      <p className="title">{title}</p>

      <Link to="/software-demo" className="request-demo-link">
        <CustomButton>{link}</CustomButton>
      </Link>
    </div>
  );
}

export default RequestDemoWidget;
