import { FeedbackActionTypes } from './feedback.type';

const FEEDBACK_INITIAL_STATE = {
  feedbackData: '',
  acceptanceStatus: '',
};

const feedbackReducer = (state = FEEDBACK_INITIAL_STATE, action) => {
  switch (action.type) {
    case FeedbackActionTypes.GET_FEEDBACK_DATA:
      if (action.payload) {
        return {
          ...state,
          feedbackData: action.payload.surveyQuestions,
          acceptanceStatus: action.payload.attendeeSurveyResponse,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default feedbackReducer;
