import React, { useReducer } from 'react';

function reducer(state, { key, value }) {
  return {
    ...state,
    [key]: value,
  };
}

// example initial_state that must get passed (this is REQUIRED)
// const INITIAL_STATE = {
//     email: '',
//     username: '',
//     password: ''
// }

const useInputCollection = (initial_state) => {
  const [state, dispatch] = useReducer(reducer, initial_state);

  const updateInput = (e) => {
    dispatch({ key: e.target.name, value: e.target.value });
  };

  const updatePhoneNumber = (e) => {
    const numbers = e.target.value.replace(/\D+/g, '');

    let formattedNumber = '';
    if (numbers === '') {
      dispatch({ key: e.target.name, value: formattedNumber });
    } else {
      if (numbers.length < 4) {
        formattedNumber += `(${numbers.substring(0, 3)}`;
      } else if (numbers.length < 7) {
        formattedNumber += `(${numbers.substring(0, 3)}) ${numbers.substring(
          3,
          6
        )}`;
      } else {
        formattedNumber += `(${numbers.substring(0, 3)}) ${numbers.substring(
          3,
          6
        )}-${numbers.substring(6, 10)}`;
      }

      dispatch({ key: e.target.name, value: formattedNumber });
    }
  };

  return { state, updateInput, updatePhoneNumber };
};

export default useInputCollection;
