import React from 'react';
import './image-with-overlay-text-listing.styles.scss';
import ImageWithTextOverlay from '../image-with-overlay-text-cta/image-with-overlay-text-cta.component';
import { truncatetext } from '../../../js/helpers/_truncate-text';

// RP #Task 92 Policies

function ImageWithOverlayTextListing({ ctas, colorCategory }) {
  return (
    <div className="image-with-overlay-text-listing sm-up-module">
      {ctas &&
        ctas.map((item) => (
          <div className="image-text-cta-container">
            <ImageWithTextOverlay
              category={colorCategory}
              title={item.Category.Title}
              content={item.Category.Content}
              inner_content={truncatetext(
                item.Category.SectionPolicyIntroText.replace(/<[^>]+>/g, ''),
                300
              )}
              content_link={item.Category.url}
              image={item.Category.SectionPolicyImage_src}
              ispolicies
            />
          </div>
        ))}
    </div>
  );
}

export default ImageWithOverlayTextListing;
