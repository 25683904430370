import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewConsultingLandingPage({
  page: { MainContent, lstIntrotext, breadCrumbList },
}) {
  console.log(MainContent);

  const HeroComponent = React.lazy(() =>
    import('./new-consulting-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-consulting-content-component')
  );

  return (
    <div className="consulting-landing consulting relative">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          headerData={MainContent}
          lstIntrotext={lstIntrotext}
          breadCrumbList={breadCrumbList}
        />
        <ContentComponent />
      </Suspense>
    </div>
  );
}

export default NewConsultingLandingPage;
