export function _removeClasses(els, _class, delay) {
  setTimeout(() => {
    els.forEach((el) => {
      el.classList.remove(_class);
    });
  }, delay);
}

export function removeClasses(els, _class) {
  els.forEach((el) => {
    el.classList.remove(_class);
  });
}

export function setCategoriesToCtas(ctas) {
  if (!ctas || ctas.length <= 0) return null;
  if (ctas[0].categories) return ctas;

  const newCtas = [];

  ctas.forEach((cta) => {
    const categories = [
      {
        title: cta.Category.Title,
        id: cta.Category.Section,
      },
    ];

    cta.SubContent.forEach((subCta) => {
      newCtas.push({ ...subCta, categories });
    });
  });

  return newCtas;
}
