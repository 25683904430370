import StaffingLpModel from './staffing-models/staffing-lp.model';

const staffingPages = [
  // {
  //   name: "Submit a Resume",
  //   path: "/apply-online",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "staffing-resume",
  //   modules: StaffingLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Submit Resume",
  //     },
  //   }),
  // },
  {
    name: 'Submit a Resume',
    path: '/apply-online',
    apiurl: '/restapi/customapplyonlinecontent/applyonline',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'new-staffing-resume',
    modules: StaffingLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Submit Resume',
      },
    }),
  },
];

export default JSON.stringify(staffingPages);
