import React, { useState } from 'react';

import LoginForm from '../../../components/ecommerce/forms/login-form/login-form.component';
import EmailLoginForm from '../../../components/ecommerce/forms/email-form/email-login-form.component';

function UserLogin() {
  return (
    <div className="login">
      <LoginForm />
    </div>
  );
}

export default UserLogin;
