import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../js/custom-hooks/useForm';
import validate from './software-demo.validationRules';
import {
  backLink,
  companyBlurb,
  informationBlurb,
  link,
  productBlurb,
} from './software-demo.data';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../commons/recaptcha/recaptcha.component';
import { submitSoftwareDemoResponse } from '../../../js/api/softwareForm/_softwareDemo.data';
import './software-demo.styles.scss';
import { createToken } from '../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../commons/helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../js/api/_config';

function SoftwareDemoForm({ data, mainData, status }) {
  // #RP - Task 63 - Software Demo
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const [Product] = useState(
    status != noContent && dropDownContent.Product
      ? dropDownContent.Product
      : null
  );
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitSoftwareDemoResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone != null ? values.phone.trim() : '0',
      Company: values.companyName.trim(),
      Address: values.address != null ? values.address.trim() : 'None',
      AddressTwo: values.address2 != null ? values.address2.trim() : 'None',
      City: values.city != null ? values.city.trim() : 'None',
      State: values.state != null ? values.state.trim() : 'None',
      PostalCode: values.postalCode != null ? values.postalCode.trim() : 'None',
      Country: values.country != null ? values.country.trim() : 'None',
      Industry: values.industry != null ? values.industry.trim() : 'None',
      SoftwareProduct:
        values.softwareproduct != null ? values.softwareproduct.trim() : 'None',
      ScheduleDate1:
        values.scheduleDate1 != null ? values.scheduleDate1.trim() : 'None',
      ScheduleDate2:
        values.scheduleDate2 != null ? values.scheduleDate2.trim() : 'None',
      ScheduleDate3:
        values.scheduleDate3 != null ? values.scheduleDate3.trim() : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      HowDidYouHearAboutUs:
        values.hearaboutus != null ? values.hearaboutus.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.software_demo.UniqueId,
      Catalog: ListOfFormUniqueData.software_demo.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    // KT add class software-demo-form-container-changed 11-15-2021
    <div
      className={`software-demo-form-container software-demo-form-container-changed ${
        errors && Object.keys(errors).length !== 0 ? 'error_Form' : ''
      }`}
    >
      <div className="back-wrapper xs-only-trinity-container">
        {backLink && (
          <a onClick={() => history.goBack()} className="back-link">
            {backLink}
          </a>
        )}
      </div>
      <form className="demo-form" onSubmit={handleSubmit} noValidate>
        <p
          className="title"
          dangerouslySetInnerHTML={{ __html: mainContent.SubTitle }}
        />
        <p
          className="blurb"
          dangerouslySetInnerHTML={{ __html: mainContent.Content }}
        />
        <Row>
          <Col className="left" xs={12} sm={6}>
            <div className="information">
              {informationBlurb && (
                <p className="information-blurb form-title">
                  {informationBlurb}
                </p>
              )}
              <div className="max-height-input">
                <TextInput
                  placeholder="First Name"
                  required
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  value={values.firstName || ''}
                  autoComplete="off"
                  className={`${errors.firstName && 'error'}`}
                  maxlength={100}
                />
                {errors.firstName && (
                  <span className="help">{errors.firstName}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Last Name"
                  required
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={values.lastName || ''}
                  autoComplete="off"
                  className={`${errors.lastName && 'error'}`}
                  maxlength={100}
                />
                {errors.lastName && (
                  <span className="help">{errors.lastName}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Job Title"
                  required
                  type="text"
                  name="jobTitle"
                  onChange={handleChange}
                  value={values.jobTitle || ''}
                  autoComplete="off"
                  className={`${errors.jobTitle && 'error'}`}
                  maxlength={100}
                />
                {errors.jobTitle && (
                  <span className="help">{errors.jobTitle}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Business Email"
                  type="email"
                  name="email"
                  required
                  onChange={handleChange}
                  value={values.email || ''}
                  autoComplete="off"
                  className={`${errors.email && 'error'}`}
                  maxlength={200}
                />
                {errors.email && <span className="help">{errors.email}</span>}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Business Phone"
                  autoComplete="off"
                  className={` ${errors.phone && 'error'}`}
                  type="number"
                  name="phone"
                  onChange={handleChange}
                  value={values.phone || ''}
                  required
                  maxlength={100}
                />
                {errors.phone && <span className="help">{errors.phone}</span>}
              </div>
            </div>
            <div className="company-information">
              {companyBlurb && (
                <p className="company-blurb form-title">{companyBlurb}</p>
              )}
              <div className="max-height-input">
                <TextInput
                  placeholder="Company Name"
                  required
                  type="text"
                  name="companyName"
                  onChange={handleChange}
                  value={values.companyName || ''}
                  autoComplete="off"
                  className={`${errors.companyName && 'error'}`}
                  maxlength={100}
                />
                {errors.companyName && (
                  <span className="help">{errors.companyName}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Business Address 1"
                  required
                  type="text"
                  name="address"
                  onChange={handleChange}
                  value={values.address || ''}
                  autoComplete="off"
                  className={`${errors.address && 'error'}`}
                  maxlength={300}
                />
                {errors.address && (
                  <span className="help">{errors.address}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Business Address 2"
                  required
                  type="text"
                  name="address2"
                  onChange={handleChange}
                  value={values.address2 || ''}
                  autoComplete="off"
                  className={`${errors.address2 && 'error'}`}
                  maxlength={300}
                />
                {errors.address2 && (
                  <span className="help">{errors.address2}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="City"
                  required
                  type="text"
                  name="city"
                  onChange={handleChange}
                  value={values.city || ''}
                  autoComplete="off"
                  className={`${errors.city && 'error'}`}
                  maxlength={100}
                />
                {errors.city && <span className="help">{errors.city}</span>}
              </div>
              <div className="max-height-input">
                <SelectList
                  defaultText="— State/Province —"
                  className="mb-2"
                  name="state"
                  required
                  onChange={stateclick}
                  value={values.state}
                >
                  {State &&
                    State.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Postal Code"
                  required
                  name="postalCode"
                  onChange={handleChange}
                  value={values.postalCode || ''}
                  autoComplete="off"
                  className={`${errors.postalCode && 'error'}`}
                  maxlength={100}
                />
                {errors.postalCode && (
                  <span className="help">{errors.postalCode}</span>
                )}
              </div>
              <div className="max-height-input">
                <SelectList
                  defaultText="— Country —"
                  onChange={handleChange}
                  name="Country"
                  className="mb-2"
                  required
                  value={values.countryName}
                >
                  {Country &&
                    Country.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
              <div className="max-height-input">
                <SelectList
                  defaultText="— Industry —"
                  name="industry"
                  required
                  onChange={handleChange}
                  value={values.industry}
                >
                  {status != noContent &&
                    Industry.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
            </div>
          </Col>
          <Col className="right" xs={12} sm={6}>
            <div className="product-information">
              {productBlurb && (
                <p className="product-blurb form-title">{productBlurb}</p>
              )}
              <div className="max-height-input">
                <SelectList
                  className="mb-2"
                  defaultText="— Select a software product —"
                  name="softwareproduct"
                  onChange={handleChange}
                  value={values.softwareproduct}
                >
                  {status != noContent &&
                    Product.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Please provide three potential dates to schedule this demo"
                  name="scheduleDate1"
                  onChange={handleChange}
                  value={values.scheduleDate1}
                  maxlength={200}
                />
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Please provide three potential dates to schedule this demo"
                  name="scheduleDate2"
                  onChange={handleChange}
                  value={values.scheduleDate2}
                  maxlength={200}
                />
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Please provide three potential dates to schedule this demo"
                  name="scheduleDate3"
                  onChange={handleChange}
                  value={values.scheduleDate3}
                  maxlength={200}
                />
              </div>
              <div className="max-height-input">
                <textarea
                  name="information"
                  onChange={handleChange}
                  value={values.information}
                  className="mb-3"
                  placeholder="Please include any additional information"
                  maxLength={200}
                />
              </div>
              <div className="max-height-input">
                <SelectList
                  className="mb-2"
                  defaultText="— How did you hear about us? —"
                  name="hearaboutus"
                  onChange={handleChange}
                  value={values.hearaboutus}
                >
                  {status != noContent &&
                    HowDidYouHearAboutUs.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
              <div className="max-height-input">
                <textarea
                  name="comments"
                  placeholder="comments"
                  required
                  onChange={handleChange}
                  value={values.comments}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className="recaptcha">
          <div
            style={
              errors &&
              errors.recaptchaValue && {
                border: '1px solid red',
                width: 'fit-content',
              }
            }
          >
            <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
          </div>
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
      <div className="back-wrapper">
        {backLink && (
          <a href="/software-product" className="back-link">
            {backLink}
          </a>
        )}
      </div>
    </div>
  );
  return null;
}

export default SoftwareDemoForm;
