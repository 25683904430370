import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import {
  activePageNumber,
  getPageWiseSoftwareResource,
  loadSoftwareResource,
  selectSoftwareResourceActionFilter,
} from '../../../redux/software-resource/software-resource.action';
import CustomButton from '../../commons/custom-button/custom-button.component';
import SoftwareResourceActiveSearchFilter from './software-resource-active-search-filter.component';

function SoftwareResourceAdvanceFilter() {
  const dispatch = useDispatch();

  const pkeyword = useSelector(
    ({ softwareresource }) => softwareresource.keywordFilter
  );
  const pmodelingCategory = useSelector(
    ({ softwareresource }) => softwareresource.modelingcategoryFilter
  );
  const pproduct = useSelector(
    ({ softwareresource }) => softwareresource.productFilter
  );
  const pindustry = useSelector(
    ({ softwareresource }) => softwareresource.industryFilter
  );
  const pservice = useSelector(
    ({ softwareresource }) => softwareresource.serviceFilter
  );
  const plocation = useSelector(
    ({ softwareresource }) => softwareresource.locationFilter
  );
  const softwareresourceLoading = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourceLoading
  );
  const softwareresourceFilter = useSelector(
    ({ softwareresource }) => softwareresource.filterList
  );
  const psoftwareresourcetype = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourcetype
  );

  const [keyword, setKeyword] = useState([]);
  const [modelingCategory, setModelingCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [service, setService] = useState([]);
  const [location, setLocation] = useState([]);
  const [softwareresourcetype, setSoftwareResourceType] = useState([]);

  const handleChange = (event) => {
    event.persist();
    if (event.target.name === 'ModelingCategory') {
      setModelingCategory((modelingCategory) => ({
        ...modelingCategory,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Product') {
      setProduct((product) => ({
        ...product,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Industry') {
      setIndustry((industry) => ({
        ...industry,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Service') {
      setService((service) => ({
        ...service,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Location') {
      setLocation((location) => ({
        ...location,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Keyword') {
      setKeyword((keyword) => ({
        ...keyword,
        Name: event.target.value,
        Id: '0',
      }));
    } else if (event.target.name === 'SoftwareResourceType') {
      setSoftwareResourceType((softwareresourcetype) => ({
        ...softwareresourcetype,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSuccess();
  };

  function submitSuccess() {
    if (
      modelingCategory != null &&
      modelingCategory.Id != undefined &&
      modelingCategory.Id != '— Modeling Category —' &&
      modelingCategory.Name != '— Modeling Category —' &&
      modelingCategory.Id != 0 &&
      modelingCategory.Id != '0'
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(
          modelingCategory,
          pmodelingCategory,
          'Modeling_Category'
        )
      );
    }
    if (
      product != null &&
      product.Id != undefined &&
      product.Id != '— Product —' &&
      product.Name != '— Product —' &&
      product.Id != 0 &&
      product.Id != '0'
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(product, pproduct, 'Product')
      );
    }
    if (
      industry != null &&
      industry.Id != undefined &&
      industry.Id != '— Industry —' &&
      industry.Name != '— Industry —' &&
      industry.Id != 0 &&
      industry.Id != '0'
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(industry, pindustry, 'Industry')
      );
    }
    if (
      service != null &&
      service.Id != undefined &&
      service.Id != '— Service —' &&
      service.Name != '— Service —' &&
      service.Id != 0 &&
      service.Id != '0'
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(service, pservice, 'Service')
      );
    }
    if (
      location != null &&
      location.Id != undefined &&
      location.Id != '— Location —' &&
      location.Name != '— Location —' &&
      location.Id != 0 &&
      location.Id != '0'
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(location, plocation, 'Location')
      );
    }
    if (
      keyword != null &&
      keyword != undefined &&
      keyword.Id != null &&
      keyword.Name != ''
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(keyword, pkeyword, 'Keyword')
      );
    }
    if (
      softwareresourcetype != null &&
      softwareresourcetype != undefined &&
      softwareresourcetype.Id != null &&
      softwareresourcetype.Name != ''
    ) {
      dispatch(
        selectSoftwareResourceActionFilter(
          softwareresourcetype,
          psoftwareresourcetype,
          'SoftwareResourceType'
        )
      );
    }

    const selectmodelingcategory = [];
    const selectproduct = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];
    const selectsoftwareresourcetype = [];

    pmodelingCategory.map((item) => {
      selectmodelingcategory.push(item.Id);
    });
    pproduct.map((item) => {
      selectproduct.push(item.Id);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    pservice.map((item) => {
      selectservice.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.map((item) => {
      selectkeyword.push(item.Name);
    });
    psoftwareresourcetype.map((item) => {
      selectsoftwareresourcetype.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      ModelingCategory: selectmodelingcategory,
      Product: selectproduct,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
      SoftwareResourceType: selectsoftwareresourcetype,
    };

    dispatch(loadSoftwareResource(true));
    dispatch(getPageWiseSoftwareResource(submissionObj));
    dispatch(activePageNumber(1));

    setKeyword({ Name: '', Id: null });
    setModelingCategory({ Name: '— Modeling Category —', Id: null });
    setProduct({ Name: '— Product —', Id: null });
    setIndustry({ Name: '— Industry —', Id: null });
    setService({ Name: '— Service —', Id: null });
    setLocation({ Name: '— Location —', Id: null });
    setSoftwareResourceType({ Name: '— Software Resource Type —', Id: null });
  }

  return (
    <div className="sidebar-with-filters module-spacer-bottom">
      <div className="advanced-search">
        <h2>ADVANCED SEARCH</h2>

        <SoftwareResourceActiveSearchFilter />

        <TextInput
          placeholder="Keyword or phrase"
          type="text"
          name="Keyword"
          onChange={handleChange}
          aria-label="Search"
          value={keyword.Name ? keyword.Name : ''}
          autoComplete="off"
          className="xs-sm-only-small-text mb-sm-2"
        />

        {softwareresourceFilter &&
          softwareresourceFilter.AllSoftwareResourceTypeData && (
            <SelectList
              onChange={handleChange}
              name="SoftwareResourceType"
              value={
                softwareresourcetype.Id
                  ? softwareresourcetype.Id
                  : '— Software Resource Type —'
              }
              defaultText="— Software Resource Type —"
              className="mb-2"
            >
              {softwareresourceFilter.AllSoftwareResourceTypeData.map(
                (item) => (
                  <option className="filter" value={item.Id}>
                    {item.Name}
                  </option>
                )
              )}
            </SelectList>
          )}

        {softwareresourceFilter &&
          softwareresourceFilter.AllModelingCategoryData && (
            <SelectList
              onChange={handleChange}
              name="ModelingCategory"
              value={
                modelingCategory.Id
                  ? modelingCategory.Id
                  : '— Modeling Category —'
              }
              defaultText="— Modeling Category —"
              className="mb-2"
            >
              {softwareresourceFilter.AllModelingCategoryData.map((item) => (
                <option className="filter" value={item.Id}>
                  {item.Name}
                </option>
              ))}
            </SelectList>
          )}

        {softwareresourceFilter && softwareresourceFilter.AllProductData && (
          <SelectList
            onChange={handleChange}
            name="Product"
            value={product.Id ? product.Id : '— Product —'}
            defaultText="— Product —"
            className="mb-2"
          >
            {softwareresourceFilter.AllProductData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {softwareresourceFilter && softwareresourceFilter.AllIndustryData && (
          <SelectList
            onChange={handleChange}
            name="Industry"
            value={industry.Id ? industry.Id : '— Industry —'}
            defaultText="— Industry —"
            className="mb-2"
          >
            {softwareresourceFilter.AllIndustryData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {softwareresourceFilter && softwareresourceFilter.AllServiceData && (
          <SelectList
            onChange={handleChange}
            name="Service"
            value={service.Id ? service.Id : '— Service —'}
            defaultText="— Service —"
            className="mb-2"
          >
            {softwareresourceFilter.AllServiceData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {/* {softwareresourceFilter && softwareresourceFilter.AllLocationData && (
                 <SelectList
                   onChange={handleChange}
                   name={"Location"}
                   value={location.Id ? location.Id : "— Location —"}
                   defaultText="— Location —"
                   className="mb-2"
                 >
                   {softwareresourceFilter.AllLocationData.map((item) => (
                     <option
                       className={`filter`}
                       value={item.Id}
                     >
                       {item.Name}
                     </option>
                   ))}
                 </SelectList>
               )} */}

        <CustomButton
          onClick={handleSubmit}
          loading={softwareresourceLoading}
          btndisabled={softwareresourceLoading}
          className="ml-auto mr-auto d-block button-spacer"
        >
          Search
        </CustomButton>
      </div>
    </div>
  );
}

export default SoftwareResourceAdvanceFilter;
