import React from 'react';
import Hero from '../../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../../images/about/policiesHero.svg';
import ContentWell from '../../../../components/commons/content-well/content-well.component';
import { defaultContent } from '../../../../components/commons/content-well/content-well.data';
import Loader from '../../../../components/commons/helpers/loader/loader.component';

// #RP - Task 124 Career
function ComCareer({ page: { MainContent } }) {
  if (MainContent) {
    return (
      <div className="mission-container">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="standard"
          colorCategory="policies"
          description={MainContent.HeaderContent}
        />
        <div className="trinity-container">
          <ContentWell content={MainContent.Content} />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default ComCareer;
