import { TrainingActionTypes } from './training.types';

const INITIAL_STATE = {
  TrainingFormatFilterData: [],
  TrainingCategoryFilterData: [],
  TrainingIndustryFilterData: [],
  TrainingDateFilterData: [],
  TrainingLocationFilterData: [],
  TrainingRegulatoryAgencyFilterData: [],
  TrainingList: {},
  TotalItemsCount: '',
  TrainingLoading: false,
  PageNumber: 1,
  PerPageCount: 0,
  TrainingFormatFilterSelected: [],
  TrainingCategoryFilterSelected: [],
  TrainingIndustryFilterSelected: [],
  TrainingDateFilterSelected: [],
  TrainingLocationFilterSelected: [],
  TrainingRegulatoryAgencyFilterSelected: [],
  TrainingKeywordFilterSelected: [],
  TrainingBusinessUnitFilterSelected: [],
  IgnoreDefaultSearch: false,
};

export const trainingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TrainingActionTypes.RESET_TRAINING_FILTER_SET:
      return {
        ...state,
        TrainingFormatFilterSelected: [],
        TrainingCategoryFilterSelected: [],
        TrainingIndustryFilterSelected: [],
        TrainingDateFilterSelected: [],
        TrainingLocationFilterSelected: [],
        TrainingRegulatoryAgencyFilterSelected: [],
        TrainingKeywordFilterSelected: [],
        TrainingBusinessUnitFilterSelected: [],
      };
    case TrainingActionTypes.TRAINING_FORMAT_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingFormatFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_CATEGORY_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingCategoryFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_INDUSTRY_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingIndustryFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_DATE_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingDateFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_LOCATION_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingLocationFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_REGULATORYAGENCY_FILTER_DATA:
      if (action.payload) {
        return {
          ...state,
          TrainingRegulatoryAgencyFilterData: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.GET_PAGEWISE_TRAINING:
      if (action.payload) {
        return {
          ...state,
          TrainingList: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TOTAL_ITEM_COUNT:
      if (action.payload) {
        return {
          ...state,
          TotalItemsCount: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_LOADING:
      return {
        ...state,
        TrainingLoading: action.payload,
      };
    case TrainingActionTypes.TRAINING_IGNORE_DEFAULT_SEARCH:
      return {
        ...state,
        IgnoreDefaultSearch: action.payload,
      };
    case TrainingActionTypes.ACTIVE_PAGE_NUMBER:
      if (action.payload) {
        return {
          ...state,
          PageNumber: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.PER_PAGE_COUNT:
      if (action.payload) {
        return {
          ...state,
          PerPageCount: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_FORMAT_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingFormatFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_CATEGORY_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingCategoryFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_INDUSTRY_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingIndustryFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_DATE_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingDateFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_LOCATION_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingLocationFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_REGULATORYAGENCY_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingRegulatoryAgencyFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_KEYWORD_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingKeywordFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    case TrainingActionTypes.TRAINING_BUSINESS_UNIT_FILTER_SELECTED:
      if (action.payload) {
        return {
          ...state,
          TrainingBusinessUnitFilterSelected: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default trainingReducer;
