import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../../commons/custom-button/custom-button.component';

import './confirmation-sidebar.styles.scss';

function ConfirmationSideBar({ paymentID }) {
  const cartcategorypama = useSelector(({ cart }) => cart.cartcategorypamas);

  const [isOnlySoftware, setIsOnlySoftware] = useState(
    cartcategorypama ? cartcategorypama.isOnlySoftware : false
  );
  const [isOnlyTraining, setIsOnlyTraining] = useState(
    cartcategorypama ? cartcategorypama.isOnlyTraining : false
  );
  const [bothCategory, setBothCategory] = useState(
    cartcategorypama ? cartcategorypama.bothCategory : false
  );
  const [orderConfirmationNumber, setOrderConfirmationNumber] = useState(
    cartcategorypama ? cartcategorypama.orderConfirmationNumber : ''
  );
  const history = useHistory();

  const location = useLocation();
  const pageState = location.state;

  const handleMyAccount = async () => {
    history.push('/my-account');
  };

  return (
    <div className="outer-confirm-container">
      <div className="confirmation-container">
        {pageState && pageState.paymentCompleted ? (
          <p className="title">
            <i className="fa fa-check pr-2" aria-hidden="true" /> Thank You!
            Your order has been successfully processed.
          </p>
        ) : isOnlySoftware ? (
          <p className="title">
            <i className="fa fa-check pr-2" aria-hidden="true" /> Thank You!
            Your order has been successfully processed.
          </p>
        ) : isOnlyTraining || bothCategory ? (
          <p className="title">
            <i className="fa fa-check pr-2" /> Thank you! Your order is being
            processed.
          </p>
        ) : null}
        <p className="blurb-1">
          Please check your email for order confirmation and an itemized receipt
          of your purchase.{' '}
        </p>
        <p className="blurb-2">
          Your purchases are always available in the{' '}
          <Link to="/my-account">My Order History</Link> section within the{' '}
          <Link to="/my-account">My Account</Link> profile.
        </p>
        <p className="order-confirm-number">
          Order Confirmation:
          {orderConfirmationNumber}
        </p>
        <div className="button">
          <Link to="/my-account">
            <CustomButton>My Account</CustomButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationSideBar;
