import { ENDPOINTS } from '../_config';

// #RP - Task  - Create Account
export const getAccountPageData = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_ACCOUNT_DETAIL);
    if (request.status === 204) {
      // 204 => No content in backend
      return request;
    }
    return await request.json();
  } catch (e) {
    return null;
  }
};

export const submitUserAccountResponse = async (fields, token) => {
  const location =
    ENDPOINTS.PROXY + ENDPOINTS.SUBMIT_AZURE_QUEUE.CREATE_USER_ACCOUNT;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    return await fetchResponse.json();
  } catch (e) {
    return e;
  }
};
