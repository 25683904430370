import React, { useState } from 'react';
import moment from 'moment';
import stripHtml from 'string-strip-html';
import { formatDateStamp } from '../../../../js/helpers/_format-date-stamp';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../../../js/helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';

function ShoppingCartProductItemDisplay({ cta, showwarning = false }) {
  const [productItem] = useState(cta);
  const [displayWarning] = useState(showwarning);
  const changedateformat = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).format('MMMM DD, YYYY');
    }
  };

  const getRegDate = (item) => {
    if (item) {
      return (
        moment(item.StartDate).format('Do MMMM, YYYY') +
        (item.EndDate && ` - ${moment(item.EndDate).format('Do MMMM, YYYY')}`)
      );
    }
    return '';
  };

  const getLocation = (item) => {
    if (item) {
      const city = item.City ? item.City : '';
      const state = item.State ? item.State : '';
      return city ? city + (state ? `, ${state}` : '') : state;
    }
    return '';
  };

  const priceChangeWarning = (item) => {
    if (item) {
      handleNotify(item, ToasterTypes.Warning);
    }
  };

  const courseExpireWarning = (item) => {
    if (item) {
      handleNotify(item, ToasterTypes.Warning);
    }
  };

  if (productItem) {
    console.log(cta.SessionTypeName);
    return (
      <div>
        {' '}
        {cta.Category === 'Training' ? (
          cta.SessionTypeName.toLowerCase() === 'ondemand' ? (
            <>
              {cta.ProductName}
              <br />
              Online
            </>
          ) : (
            <span>
              {stripHtml(cta.ProductName).result} <br />
              {getLocation(cta)} <br />
              {getRegDate(cta)}{' '}
            </span>
          )
        ) : (
          cta.ProductName
        )}
        {displayWarning && cta.PriceChange ? (
          <i
            className="fa fa-exclamation-circle"
            aria-hidden="true"
            onClick={() => priceChangeWarning(cta.PriceChangeWarningText)}
          />
        ) : (
          ''
        )}
        {displayWarning && cta.IsCourseAboutToExpire ? (
          <i
            className="fa fa-exclamation-circle"
            aria-hidden="true"
            onClick={() =>
              courseExpireWarning(cta.CourseAboutToExpireWarningText)
            }
          />
        ) : (
          ''
        )}
      </div>
    );
  }
  return null;
}

export default ShoppingCartProductItemDisplay;
