import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './release-notes.styles.scss';

function ReleaseNotesPage({ notes }) {
  return (
    <div className="notes-container">
      {notes &&
        notes
          .sort((a, b) => (a.Title > b.Title ? 1 : -1))
          .map((note, i) => (
            <Row>
              <Col xs={12} sm={3}>
                <p className="name">{note.Title}</p>
              </Col>
              <Col xs={12} sm={9} className="version-container">
                {note.ReleaseNotesCategory.sort((a, b) =>
                  parseFloat(a.Title) < parseFloat(b.Title) ? 1 : -1
                ).map((version, index) => (
                  <ul key={index}>
                    <li>
                      <a href={version.RouteUrl}>{version.Title}</a>
                    </li>
                  </ul>
                ))}
              </Col>
            </Row>
          ))}
      <hr />

      {/*
      {aermodData.map((note, i) => {
        return (
          <Row>
            <Col xs={12} sm={3}>
              <p className="name">{note.name}</p>
            </Col>
            <Col xs={12} sm={9} className="version-container">
              {note.versions.map((version) => (
                <ul>
                  <li>
                    <a href="/release-notes">{version.version}</a>
                  </li>
                </ul>
              ))}
            </Col>
          </Row>
        );
      })}
      <hr />
      {exdamData.map((note, i) => {
        return (
          <Row>
            <Col xs={12} sm={3}>
              <p className="name">{note.name}</p>
            </Col>
            <Col xs={12} sm={9} className="version-container">
              {note.versions.map((version) => (
                <ul>
                  <li>
                    <a href="/release-notes">{version.version}</a>
                  </li>
                </ul>
              ))}
            </Col>
          </Row>
        );
      })}
      <hr />
      */}
    </div>
  );
}

export default ReleaseNotesPage;
