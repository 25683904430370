import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseBtn from '../../commons/helpers/close-btn/close-btn.component';
import {
  activePageNumber,
  getPageWiseSoftwareResource,
  loadSoftwareResource,
  removeSoftwareResourceActionFilter,
} from '../../../redux/software-resource/software-resource.action';

function SoftwareResourceActiveSearchFilter() {
  const dispatch = useDispatch();
  const pkeyword = useSelector(
    ({ softwareresource }) => softwareresource.keywordFilter
  );
  const pmodelingCategory = useSelector(
    ({ softwareresource }) => softwareresource.modelingcategoryFilter
  );
  const pproduct = useSelector(
    ({ softwareresource }) => softwareresource.productFilter
  );
  const pindustry = useSelector(
    ({ softwareresource }) => softwareresource.industryFilter
  );
  const pservice = useSelector(
    ({ softwareresource }) => softwareresource.serviceFilter
  );
  const plocation = useSelector(
    ({ softwareresource }) => softwareresource.locationFilter
  );
  const resourceLoading = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourceLoading
  );
  const psoftwareresourcetype = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourcetype
  );

  const [rkeyword, setRKeyword] = useState(false);
  const [rmodelingCategory, setRModelingCategory] = useState(false);
  const [rproduct, setRProduct] = useState(false);
  const [rindustry, setRIndustry] = useState(false);
  const [rservice, setRService] = useState(false);
  const [rlocation, setRLocation] = useState(false);
  const [rsoftwareresourcetype, setRSoftwareResourceType] = useState(false);

  useEffect(() => {
    if (rkeyword) {
      submitSuccess();
      setRKeyword(false);
    } else if (rmodelingCategory) {
      submitSuccess();
      setRModelingCategory(false);
    } else if (rproduct) {
      submitSuccess();
      setRProduct(false);
    } else if (rindustry) {
      submitSuccess();
      setRIndustry(false);
    } else if (rlocation) {
      submitSuccess();
      setRLocation(false);
    } else if (rservice) {
      submitSuccess();
      setRService(false);
    } else if (rsoftwareresourcetype) {
      submitSuccess();
      setRSoftwareResourceType(false);
    }
  }, [
    pmodelingCategory,
    pproduct,
    pindustry,
    pservice,
    plocation,
    pkeyword,
    psoftwareresourcetype,
  ]);

  function submitSuccess() {
    const selectmodelingcategory = [];
    const selectproduct = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];
    const selectsoftwareresourcetype = [];

    pmodelingCategory.forEach((item) => {
      selectmodelingcategory.push(item.Id);
    });
    pproduct.forEach((item) => {
      selectproduct.push(item.Id);
    });
    pindustry.forEach((item) => {
      selectindustry.push(item.Id);
    });
    pservice.forEach((item) => {
      selectservice.push(item.Id);
    });
    plocation.forEach((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.forEach((item) => {
      selectkeyword.push(item.Name);
    });
    psoftwareresourcetype.map((item) => {
      selectsoftwareresourcetype.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      ModelingCategory: selectmodelingcategory,
      Product: selectproduct,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
      SoftwareResourceType: selectsoftwareresourcetype,
    };

    dispatch(loadSoftwareResource(true));
    dispatch(getPageWiseSoftwareResource(submissionObj));
    dispatch(activePageNumber(1));
  }

  const handleModelingCategoryClick = (item) => {
    if (!resourceLoading) {
      dispatch(
        removeSoftwareResourceActionFilter(
          item,
          pmodelingCategory,
          'Modeling_Category'
        )
      );
      setRModelingCategory(true);
    }
  };

  const handleProductClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeSoftwareResourceActionFilter(item, pproduct, 'Product'));
      setRProduct(true);
    }
  };

  const handleIndustryClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeSoftwareResourceActionFilter(item, pindustry, 'Industry'));
      setRIndustry(true);
    }
  };

  const handleServiceClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeSoftwareResourceActionFilter(item, pservice, 'Service'));
      setRService(true);
    }
  };

  const handleLocationClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeSoftwareResourceActionFilter(item, plocation, 'Location'));
      setRLocation(true);
    }
  };

  const handleKeywordClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeSoftwareResourceActionFilter(item, pkeyword, 'Keyword'));
      setRKeyword(true);
    }
  };

  const handleSoftwareResourceTypeClick = (item) => {
    if (!resourceLoading) {
      dispatch(
        removeSoftwareResourceActionFilter(
          item,
          psoftwareresourcetype,
          'SoftwareResourceType'
        )
      );
      setRSoftwareResourceType(true);
    }
  };

  return (
    <div className="">
      <h3 className="active-filters-title">Active Search Filters:</h3>
      <ul>
        {pmodelingCategory && pmodelingCategory.length > 0
          ? pmodelingCategory.map((item) => (
              <li
                className="list-item bg-tags"
                onClick={() => handleModelingCategoryClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pproduct && pproduct.length > 0
          ? pproduct.map((item) => (
              <li
                className="list-item bg-tags"
                onClick={() => handleProductClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pindustry && pindustry.length > 0
          ? pindustry.map((item) => (
              <li
                className="list-item bg-news"
                onClick={() => handleIndustryClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pservice && pservice.length > 0
          ? pservice.map((item) => (
              <li
                className="list-item bg-industry"
                onClick={() => handleServiceClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {plocation && plocation.length > 0
          ? plocation.map((item) => (
              <li
                className="list-item bg-pdate"
                onClick={() => handleLocationClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pkeyword && pkeyword.length > 0
          ? pkeyword.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleKeywordClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {psoftwareresourcetype && psoftwareresourcetype.length > 0
          ? psoftwareresourcetype.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleSoftwareResourceTypeClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default SoftwareResourceActiveSearchFilter;
