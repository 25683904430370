export const title =
  'Complimentary Webinar: Navigating Environmental Requirements During Challenging Times';

export const content =
  'While our thoughts are with everyone impacted by recent events, we are diligently working with our clients to meet their environmental responsibilities while restrictions may impact their ability to carry out required activities, and to make operational changes necessary to meet production demands of critical essential products as a result of the COVID-19 pandemic.';

export const blurb =
  'Join us for this free webinar to hear about strategies to address unanticipated non-compliance situations and permitting for new projects.';

export const link = 'Sign up';
