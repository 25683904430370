import TradeShowModal from './about-modals/tradeshow.modals';
import AboutLpModal from './about-modals/aboutlp.modals';
import AvaPositionsModal from './about-modals/ava-positions.modals';
import CareersLpModal from './about-modals/careers-lp.modals';
import JobAlertModal from './about-modals/job-alert.modals';
import IndiviLocation from './about-modals/locations-indiv.modals';
import PoliciesIndividualModal from './about-modals/policies-secondary';

const aboutPages = [
  // {
  //   name: "About",
  //   path: "/about",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "AboutLP",
  //   modules: AboutLpModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "About Us",
  //       hero_description:
  //         "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>",
  //       hero_ctas: [
  //         {
  //           title: "Our Story",
  //           sub_title: "For Employers",
  //           content:
  //             "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas eiusmod.",
  //           link: {
  //             href: "/about/our-story",
  //             text: "Suspdisse poten ›",
  //           },
  //           categories: [
  //             {
  //               title: "About",
  //               id: "gen",
  //             },
  //           ],
  //         },
  //         {
  //           title: "Mission",
  //           sub_title: "For Job Seekers",
  //           content:
  //             "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas eiusmod.",
  //           link: {
  //             href: "/about/mission",
  //             text: "Suspdisse poten ›",
  //           },
  //           categories: [
  //             {
  //               title: "About",
  //               id: "job",
  //             },
  //           ],
  //         },
  //         ,
  //         {
  //           title: "Policies",
  //           sub_title: "For Job Seekers",
  //           content:
  //             "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas eiusmod.",
  //           link: {
  //             href: "/about/policies",
  //             text: "Suspdisse poten ›",
  //           },
  //           categories: [
  //             {
  //               title: "About",
  //               id: "about",
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //   }),
  // },
  // {
  //   name: "Operational Leaders",
  //   path: "/operational-leaders",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "OpLeaders",
  //   modules: OpLeadersModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Operational Leaders",
  //       hero_description:
  //         "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>",
  //     },
  //   }),
  // },
  // {
  //   name: "Executive Committee",
  //   path: "/executive-committee",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "ExecCom",
  //   modules: ExecComModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Executive Committee",
  //       hero_description:
  //         "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>",
  //     },
  //   }),
  // },
  // {
  //   name: "Individual Bio",
  //   path: "/individual-bio",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "IndivBio",
  // },
  // {
  //   name: "Policies",
  //   path: "/about/policies",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "Policies",
  //   modules: PoliciesModals({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Policies",
  //       hero_description:
  //         "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>",
  //     },
  //     content:
  //       "Donec ultricies lectus eu tellus consectetur feugiat. Praesent eu dolor in purus semper feugiat quis id nisl. Donec sit amet sodales quam. Sed odio diam, molestie sed felis ut, varius bibendum arcu.",
  //     content_link: "Learn more >",
  //     triple_cta_one: [
  //       {
  //         src: "",
  //         alt: "",
  //         title: "HSE Policy",
  //       },
  //       {
  //         triple_cta_two_src: "",
  //         triple_cta_two_alt: "",
  //         title: "Quality Policy",
  //       },
  //       {
  //         src: "",
  //         alt: "",
  //         title: "Sustainability Policy",
  //       },
  //     ],
  //   }),
  // },
  {
    name: 'Indivdual Policy',
    path: '/about/policies/individual-policy',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'policies-individual-category',
    modules: PoliciesIndividualModal({
      hero: {
        hero_src: '',
        hero_title: 'Individual Policy',
        hero_description:
          '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>',
      },
      content:
        'Donec ultricies lectus eu tellus consectetur feugiat. Praesent eu dolor in purus semper feugiat quis id nisl. Donec sit amet sodales quam. Sed odio diam, molestie sed felis ut, varius bibendum arcu.',
      content_link: 'Learn more >',
      triple_cta_one: [
        {
          src: '',
          alt: '',
          title: 'HSE Policy',
        },
        {
          triple_cta_two_src: '',
          triple_cta_two_alt: '',
          title: 'Quality Policy',
        },
        {
          src: '',
          alt: '',
          title: 'Sustainability Policy',
        },
      ],
    }),
  },
  // {
  //   name: "Trade Shows",
  //   path: "/about-us/trade-shows",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "TradeShows",
  //   modules: TradeShowModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Trade Shows & Events",
  //     },
  //   }),
  // },
  // {
  //   name: "News",
  //   path: "/environmental-news-media",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "NewsMediaLP",
  // },
  // {
  //   name: "News & Media",
  //   path: "/environmental-consulting-news",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "NewsMediaSecondary",
  // },
  // {
  //   name: "Careers",
  //   path: "/careers",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "Careers",
  //   modules: CareersLpModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Trinity Consultants Careers",
  //       hero_description:
  //         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.",
  //     },
  //     inner_content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim.",
  //     content:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim.",
  //     triple_cta_one: [
  //       {
  //         src: "",
  //         alt: "",
  //         title: "Culture ›",
  //       },
  //       {
  //         triple_cta_two_src: "",
  //         triple_cta_two_alt: "",
  //         title: "Benefits ›",
  //       },
  //       {
  //         src: "",
  //         alt: "",
  //         title: "Recruiting ›",
  //       },
  //     ],
  //   }),
  // },
  // {
  //   name: "Available Positions",
  //   path: "/available-positions",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "AvaPositions",
  //   modules: AvaPositionsModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Trinity Consultants Available Positions",
  //     },
  //   }),
  // },
  {
    name: 'Individual Positions',
    path: '/individual-positions',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'IndivPositions',
    modules: AvaPositionsModal({
      hero: {
        hero_src: '',
        hero_title: 'Individual Position',
      },
    }),
  },
  {
    name: 'Job Alert',
    path: '/job-alert-sign-up',
    template: 'JobAlert',
    modules: JobAlertModal({
      hero: {
        hero_src: '',
        hero_title: 'Receive Job Alerts',
      },
    }),
  },
  // {
  //   name: "Subscribe",
  //   path: "/subscribe",
  //   template: "SubscribeForm",
  //   modules: JobAlertModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Subscribe",
  //     },
  //   }),
  // },
  {
    name: 'Subscribe',
    path: '/subscribe',
    apiurl: '/restapi/customsubscribecontent/subscribe',
    template: 'new-SubscribeForm',
    modules: JobAlertModal({
      hero: {
        hero_src: '',
        hero_title: 'Subscribe',
      },
    }),
  },
  {
    name: 'Contact Us',
    path: '/contactus',
    apiurl: '/restapi/customcontactuscontent/contactus',
    template: 'new-contact-us',
    modules: JobAlertModal({
      hero: {
        hero_title: 'Contact Us',
      },
    }),
  },
  // {
  //   name: "Contact Us",
  //   path: "/contactus",
  //   template: "contact-us",
  //   modules: JobAlertModal({
  //     hero: {
  //       hero_title: "Contact Us",
  //     },
  //   }),
  // },
  // {
  //   name: "News",
  //   path: "/news",
  //   template: "news",
  //   modules: JobAlertModal({
  //     hero: {
  //       hero_title: "News",
  //     },
  //   }),
  // },
  {
    name: 'Impersonate Users',
    path: '/admin/impersonate',
    template: 'impersonate',
    modules: JobAlertModal({
      hero: {
        hero_title: 'Impersonate Users',
      },
    }),
  },
  {
    name: 'TempRoute',
    path: '/survey',
    template: 'tempRoute',
    modules: JobAlertModal({
      hero: {
        hero_title: 'TempRoute',
      },
    }),
  },
  {
    name: 'Individual Location',
    path: '/individual-location',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'IndiviLocation',
    modules: IndiviLocation({
      hero: {
        hero_src: '',
        hero_title: 'Individual Location Title',
      },
    }),
  },
  // {
  //   name: "Website Problem",
  //   path: "/report-problem",
  //   template: "report-problem",
  //   modules: JobAlertModal({
  //     hero: {
  //       hero_title: "Report a problem with the website",
  //     },
  //   }),
  // },
  {
    name: 'Website Problem',
    path: '/report-problem',
    template: 'new-report-problem',
    apiurl: '/restapi/customreportwebsiteproblemcontent/reportwebsiteproblem',
    modules: JobAlertModal({
      hero: {
        hero_title: 'Report a problem with the website',
      },
    }),
  },
  // {
  //   name: "Privacy Policy",
  //   path: "/privacy-policy",
  //   template: "privacy-policy",
  //   modules: JobAlertModal({
  //     hero: {
  //       hero_title: "Privacy Policy",
  //     },
  //   }),
  // },
  {
    name: 'Education Program',
    path: '/education-program',
    template: 'education-program',
    modules: AboutLpModal({
      hero: {
        hero_src: '',
        hero_title: 'Education Program',
        hero_description:
          'BREEZE provides market-leading air dispersion, fire, and explosion modeling software for EHS professionals around the world',
      },
    }),
  },
  {
    name: 'Regulatory Agency Program',
    path: '/regulatory-agency-program',
    template: 'regulatory-agency-program',
    modules: AboutLpModal({
      hero: {
        hero_src: '',
        hero_title: 'Regulatory Agency Program',
        hero_description:
          'BREEZE provides market-leading air dispersion, fire, and explosion modeling software for EHS professionals around the world',
      },
    }),
  },

  // {
  //   name: "Mission",
  //   path: "/about/mission",
  //   template: "mission",
  //   modules: AboutLpModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Mission",
  //     },
  //   }),
  // },
  // {
  //   name: "Our Story",
  //   path: "/about/our-story",
  //   template: "our-story",
  //   modules: AboutLpModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Our Story",
  //     },
  //   }),
  // },
  // {
  //   name: "Create Account",
  //   path: "/create-account",
  //   template: "create-account",
  //   modules: AboutLpModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Our Story",
  //     },
  //   }),
  //  },
  {
    name: 'Create Account',
    path: '/create-account',
    apiurl: '/restapi/customcreateuseraccountcontent/createuseraccount',
    template: 'new-create-account',
    modules: AboutLpModal({
      hero: {
        hero_src: '',
        hero_title: '',
      },
    }),
  },
  {
    name: 'Download Course Catalog',
    path: '/training/download-course-catalog',
    template: 'download-course-catalog',
    modules: AboutLpModal({
      hero: {
        hero_src: '',
        hero_title: '',
      },
    }),
  },
];

export default JSON.stringify(aboutPages);
