import React from 'react';

import Hero from '../../../components/commons/hero/hero.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';

function Industries({ page: { MainContent, CategoryContent } }) {
  if (MainContent) {
    return (
      <div className="industries industries-lp">
        <div className="hero_indus">
          <Hero
            src={MainContent.HeaderImage_src}
            mobileImgSrc={MainContent.HeaderMobileImage_src}
            alt={MainContent.HeaderImage_alt}
            title={MainContent.Title}
            description={MainContent.Content}
            template="landing"
            downArrow
            colorCategory="industries"
          />
        </div>
        <div className="sm-up-trinity-container">
          {CategoryContent ? (
            <FilteredCtaGrid ctas={CategoryContent} onlySmallCtas />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default Industries;
