import React, { useMemo, useState } from 'react';

export default function CheckBoxContainer(props) {
  const { outerElement } = props;
  const [readMore, setReadMore] = useState(false);

  const data = useMemo(() => {
    if (!outerElement.value) {
      return [];
    }
    const temp = [...outerElement.value];
    if (temp.length > 5 && !readMore) {
      return temp.slice(0, 4);
    }
    return temp;
  }, [outerElement, readMore]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <span className="tree-view-key-font">
        {outerElement.key === 'Format'
          ? 'Training Format'
          : outerElement.key === 'resourcetype'
          ? 'Resource Type'
          : outerElement.key === 'ModelingCategory'
          ? 'Modeling Category'
          : outerElement.key === 'Training_Industry'
          ? 'Industry'
          : outerElement.key === 'State'
          ? 'Location'
          : outerElement.key === 'Categories'
          ? 'Resource Categories'
          : outerElement.key === 'Article_Tags'
          ? 'News Category'
          : outerElement.key.split('_').join(' ')}
      </span>
      <div className="tree-key-element-border" />
      {data.map((innerElement) => (
        <div className="trinity-search-checkbox-elements">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div>
              <input
                type="checkbox"
                id={innerElement.value}
                name={innerElement.value}
                onChange={(e) =>
                  props.onChangeDynamicCheckBox(
                    outerElement.key,
                    innerElement.value,
                    e.target.checked
                  )
                }
                checked={props.dynamicSearchData.includes(
                  `${outerElement.key}|${innerElement.value}`
                )}
                value={innerElement.value}
              />
              <span className="tree-view-font-checkbox">
                {innerElement.value}
              </span>
            </div>
            <div>
              <span className="tree-view-font-checkbox-count">
                ({innerElement.count})
              </span>
            </div>
          </div>
        </div>
      ))}
      {outerElement.value.length > 5 && (
        <a
          style={{ color: '#ef6008', cursor: 'pointer' }}
          onClick={() => setReadMore((e) => !e)}
        >
          {readMore ? 'View Less ' : 'View More '}
          <i
            className={readMore ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
            style={{ fontSize: '15px', color: '#ef6008' }}
            aria-hidden="true"
          />
        </a>
      )}
    </div>
  );
}
