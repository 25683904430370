import React, { useEffect } from 'react';
import './widget-box.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import CommonWidgetBox from './common-widgetBox.component';
import {
  trainingcoursedata,
  resourcesdata,
  newsdata,
  productdata,
} from '../../../../redux/widget-box/widget-box.action';

function ProductConnectWidgetBox({ relatedProduct }) {
  return (
    <div>
      {relatedProduct && relatedProduct.length > 0 && (
        <div className="widgetBox-container">
          <h5 className="widget-title">Related Product</h5>
          {relatedProduct &&
            relatedProduct.map((widget) => (
              <CommonWidgetBox
                widgetImage={widget.GlobalIcon}
                widgetContent={widget.ProductName}
                widgetContentLink={widget.Url}
                widgetDate=""
                widgetSubContent=""
                singleLinkContent=""
                singleLink=""
              />
            ))}
          <div className="widget-link">
            <a
              href="/software"
              target="_blank"
              className="single-link"
              rel="noreferrer"
            >
              All Product ›
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductConnectWidgetBox;
