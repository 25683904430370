import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import { shallowEqual, useSelector } from 'react-redux';
import { ENDPOINTS } from '../../../js/api/_config';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import ShoppingCardHeaderComponent from './ShoppingCartHeaderComponent';
import ShoppingCartWrapperComponent from './ShoppingCartWrapperComponent';
import './shopping-cart.styles.scss';

function ShoppingCart() {
  const [pageContent, setPageContent] = React.useState(null);

  const cartItems = useSelector(({ cart }) => cart.cart, shallowEqual);

  useEffect(() => {
    async function getDataFromURL() {
      const response = await getPageDataByApiUrl(
        ENDPOINTS.USER_CART_ACCOUNT.SHOPPING_CART_URL
      );
      return response;
    }

    getDataFromURL().then((response) => {
      setPageContent(response);
    });
  }, []);

  return (
    <Row className="shopping-cart module-bottom-spacer trinity-container">
      <ShoppingCardHeaderComponent MainContent={pageContent?.MainContent} />
      <ShoppingCartWrapperComponent
        cartItems={cartItems}
        MainContent={pageContent?.MainContent}
      />
    </Row>
  );
}

export default ShoppingCart;
