import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Geocode from 'react-geocode';
import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/about/careers/careers-ava/content-well-link/content-well-link.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import LocationNewsListings from '../../../components/about/indivi-location/location-news-listing/location-news-lisiting';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import LeadershipWidget from '../../../components/about/indivi-location/widgets/local-leadership/leadership.component';
import LocalLinksWidget from '../../../components/about/indivi-location/widgets/local-links/local-links.component';
import IndividualMap from '../../../components/about/indivi-location/indivi-map/map.component';
import './indivi-state.styles.scss';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  resourcesdata,
  trainingcourselocationdata,
} from '../../../redux/widget-box/widget-box.action';
import StateMap from '../../../components/about/indivi-location/indivi-map/state-map.component';
import ShelterMap from '../../../components/about/indivi-location/indivi-map/state-map.component';

// RP - Individual State
function IndiviState({ page: { MainContent, IndividualLocationContent } }) {
  const dispatch = useDispatch();
  const [stateLatitude, setStateLatitude] = useState();
  const [stateLongitude, setStateLongitude] = useState();

  Geocode.setApiKey('AIzaSyBM2AvVtQ27kN9h3guGJwgCGIqiVE-laNk');
  Geocode.setLanguage('en');
  Geocode.fromAddress(IndividualLocationContent.OfficeInformation.Title).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      setStateLatitude(lat);
      setStateLongitude(lng);
    },
    (error) => {
      console.error(error);
    }
  );

  const defaultResponse = {
    type: '',
    payload: [],
    status: true,
  };

  const training = useSelector(({ widget }) => widget.trainingcourselocation);

  const news = defaultResponse;
  const product = defaultResponse;
  const resources = defaultResponse;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        await trainingcourselocationdata(window.location.pathname, 'state')
      );
    };
    fetchData();
  }, []);

  if (
    IndividualLocationContent &&
    news !== undefined &&
    news.status &&
    resources !== undefined &&
    resources.status &&
    training !== undefined &&
    training.status &&
    product !== undefined &&
    product.status
  ) {
    return (
      <div className="individual-location-container">
        <Hero
          src={
            IndividualLocationContent.OfficeInformation.HeaderImage
              ? IndividualLocationContent.OfficeInformation.HeaderImage
              : MainContent.HeaderImage_src
          }
          mobileImgSrc={
            IndividualLocationContent.OfficeInformation.HeaderMobileImage
              ? IndividualLocationContent.OfficeInformation.HeaderMobileImage
              : MainContent.HeaderMobileImage_src
          }
          template="standard"
          colorCategory="transparent"
        />

        <Col className="trinity-container">
          <ContentWithSidebar
            isCustom
            left={
              <div>
                <h2>{IndividualLocationContent.OfficeInformation.Title}</h2>
                <ContentWell
                  content={
                    IndividualLocationContent.OfficeInformation.Content
                      ? IndividualLocationContent.OfficeInformation.Content
                      : ''
                  }
                />
                {IndividualLocationContent &&
                  IndividualLocationContent.NewsData &&
                  IndividualLocationContent.NewsData.length > 0 && (
                    <LocationNewsListings
                      newsTitle="State News"
                      data={IndividualLocationContent.NewsData}
                      isOffice={false}
                    />
                  )}
              </div>
            }
            right={
              <Row>
                <Col className="left">
                  <ShelterMap
                    data={
                      IndividualLocationContent.OfficeInformation
                        .MapLocationData
                    }
                    Latitude={
                      stateLatitude != undefined && stateLatitude != null
                        ? stateLatitude
                        : '31.968599'
                    }
                    Longitude={
                      stateLongitude != undefined && stateLongitude != null
                        ? stateLongitude
                        : '-99.901810'
                    }
                  />
                  <br />
                  <LeadershipWidget
                    data={IndividualLocationContent.LocalLeadership}
                  />
                  <WidgetBox
                    relatedTrainingCourseContent={training}
                    relatedResourcesContent={resources}
                    relatedNewsContent={news}
                    relatedProductContent={product}
                    isLocation
                  />
                  {IndividualLocationContent != undefined &&
                    IndividualLocationContent.LocalLinks != undefined &&
                    IndividualLocationContent.LocalLinks.length > 0 && (
                      <LocalLinksWidget
                        data={IndividualLocationContent.LocalLinks}
                      />
                    )}
                </Col>
              </Row>
            }
          />
        </Col>
      </div>
    );
  }
  return <Loader />;
}

export default IndiviState;
