import React, { useCallback, useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './shopping-cart-items.styles.scss';

import * as Sentry from '@sentry/react';
import moment from 'moment';
import 'rc-input-number/assets/index.css';
import Table from 'react-bootstrap/Table';
import Skeleton from 'react-loading-skeleton';
import NumberFormat from 'react-number-format';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from '../../../TrackGoogleAnalytics';
import { GA4_EVENT_MAPPPING } from '../../../js/api/_config';
import {
  addCartItem,
  applyDiscountOnCartItems,
  removeCartItem,
  updateCartItemsPost,
} from '../../../js/api/shopping-cart/_shopping-cart';
import { handleNotify } from '../../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../../js/helpers/toaster/toaster-types';
import {
  UpdateImpersonateMember,
  addSoftwareItemToCart,
  cartCountUpdate,
  updateCartItem,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { UserActionTypes } from '../../../redux/user/user.types';
import CustomButton from '../../commons/custom-button/custom-button.component';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import EmptyShoppingCart from './empty-shopping-cart/empty-shopping-cart';
import QuantityPickerFn from './quantity-picker/quantity-picker-f.component';
import ShoppingCartProductItemWarning from './shopping-cart-product-items/shopping-cart-product-item-warning.component';
import ShoppingCartProductItemDisplay from './shopping-cart-product-items/shopping-cart-product-items.component';

function ShoppingCartItems({ mainContent }) {
  var isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const dispatch = useDispatch();
  const history = useHistory();
  let rlocation = useLocation();

  const [submitting, setSubmitting] = useState(false);

  const [showPartnerBox, setPartnerBox] = useState(false);
  const [partnerCourse, setPartnerCourse] = useState([]);
  const [uniqueRelatedCourse, setUniqueRelatedCourse] = useState([]);
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const updatedcartitems = useSelector(
    ({ cart }) => cart.update_cart,
    shallowEqual
  );
  const user = useSelector(({ user }) => user.tokens.access_token);

  const userEmailData = useSelector(({ user }) => user.loggedin_user); //RK--Changed from Verified_Email to loggedin_user
  const userMemberIdData = useSelector(({ cart }) => cart.impersonateMember);
  const [profileuser, setProfileuser] = useState(
    userMemberIdData && userMemberIdData.userprofile
      ? userMemberIdData.userprofile.Email
      : userEmailData != null
      ? userEmailData.Username
      : ''
  ); //added missing logic for impersonate

  const [cartCount, setCartCount] = useState(
    cartitems && cartitems.OrderLines ? cartitems.OrderLines.length : 0
  );
  const [values, setValues] = useState({
    trainingDiscountCode: '',
    softwareDiscountCode: '',
  });
  const [Customers, setCustomers] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );

  let checkForOnDemandOnly = cartitems?.OrderLines.filter(
    (e) => e.Price === 0 && e.SessionTypeName === 'OnDemand'
  ).length;
  let isOnlyOnDemand =
    checkForOnDemandOnly > 0 &&
    checkForOnDemandOnly === cartitems?.OrderLines.length;

  // useEffect(() => {
  //   const fetchCustomers = async () => {
  //     const customers = await getCustomers();
  //     setCustomers(customers);
  //     if (cartitemimpersonate) {
  //       let memberId = cartitemimpersonate.memberId;
  //       setSelectedCustomer(memberId);
  //     }
  //     else {
  //       setSelectedCustomer(null);
  //     }
  //   };
  //   fetchCustomers();
  // }, [cartitemimpersonate.memberId])

  const handleCustomerChange = (event) => {
    event.persist();
    if (event.target != undefined) setSelectedCustomer(event.target.value);
    //dispatch(cartCountUpdate(event.target.value));
    const impersonate = {
      name: event.target.options[event.target.selectedIndex].label,
      memberId: event.target.value,
    };
    dispatch(UpdateImpersonateMember(impersonate));
    dispatch(cartCountUpdate(1));
    //(event.target.options[event.target.selectedIndex].label));
    //history.push("/shopping-cart");

    // ImpersonationBannerData(impersonatedUser:event.target.options[event.target.selectedIndex].label);
  };
  const notify = () =>
    handleNotify('Course Name is about to expire!', ToasterTypes.Warning);

  const handleDelete = async (event) => {
    try {
      const orderLineID = event.target.id;
      const getcartResponse = await removeItemFromCart(orderLineID);
      if (getcartResponse === 1) {
        dispatch(cartCountUpdate(1));
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in handle delete method');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in handle delete method',
        },
      });
    }
  };

  const removeItemFromCart = async (itemid) => {
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      orderlineid: itemid,
      MemberId: memberId,
    };
    const getResponse = await removeCartItem(data);
    return getResponse;
  };

  const updateItemForCart = async (event) => {
    try {
      let newItems = [];
      updatedcartitems.forEach((element) => {
        const hasItem = cartitems.OrderLines.filter(
          (orderline) => orderline.OrderLineID === element.orderlineid
        );
        if (hasItem.length > 0) {
          if (hasItem[0].Quantity !== element.updated_qty) {
            newItems.push({
              orderlineid: element.orderlineid,
              quantity: element.updated_qty,
            });
          }
        }
      });
      await (async () => {
        // use await here
        await updateItemInCart(newItems);
      })();

      setTimeout(() => {
        if (isIE) {
          window.location.reload();
        }
      }, 4000);
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in Update cart');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in Update cart',
        },
      });
    }
  };

  const updateItemInCart = async (items) => {
    if (items.length > 0) {
      let successCount = 0;

      let memberId = null;
      if (cartitemimpersonate) {
        memberId = cartitemimpersonate.memberId;
      }

      items.forEach(async (element) => {
        const orderLineID = element.orderlineid;
        const orderQty = element.quantity;
        const data = {
          orderlineid: orderLineID,
          quantity: orderQty,
          memberid: memberId,
        };
        const getcartResponse = await updateCartItemsPost(data);
        if (getcartResponse === 1) {
          successCount++;
        }
        if (successCount === items.length) {
          handleNotify('Qty has been updated for cart.', ToasterTypes.Success);
          dispatch(cartCountUpdate(1));
        }
      });
    }

    //const getcartResponse = await updateCartItemsPost(null);
  };

  const changedateformat = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).format('MMMM DD, YYYY');
    }
  };

  const getproductName = (ctaValue) => {
    if (ctaValue) {
      let productName = ctaValue.ProductName;
      if (ctaValue.Category === 'Training') {
        const startdate = ctaValue.StartDate
          ? changedateformat(ctaValue.StartDate)
          : '';
        const enddate = ctaValue.EndDate
          ? changedateformat(ctaValue.EndDate)
          : '';
        const city = ctaValue.City ? ctaValue.City : '';
        const state = ctaValue.State ? ctaValue.State : '';
        const citystate = city ? city + (state ? ', ' + state : '') : state;
        const trainigproduct = productName + '<br />' + citystate;
        const fulldate = startdate
          ? '<br />' + startdate + (enddate ? '-' + enddate : '')
          : '';
        let notification = '';

        if (ctaValue.MaxAttendees - ctaValue.TotalRegisterAttendees <= 2) {
          notification =
            ctaValue.MaxAttendees > 0
              ? ctaValue.MaxAttendees - ctaValue.TotalRegisterAttendees <= 2
                ? '<i class="fa fa-exclamation-circle" aria-hidden="true" onClick=' +
                  { notify } +
                  ' /> '
                : ctaValue.MaxAttendees - ctaValue.TotalRegisterAttendees
              : '';
        }
        productName = trainigproduct + fulldate + notification;
      }

      return productName;
    }
    return '';
  };

  const getPartnerCourses = (ctaValue) => {
    if (ctaValue) {
      setPartnerBox(!showPartnerBox);

      const partnerVariants = [...partnerCourse];
      const uniqueRelatedCourses = [];
      let isVarianrExist = false;
      ctaValue.PartnerCourses.map((img) => {
        // const uniqueVaraint = uniqueRelatedCourses.filter(m => m.CourseProductId === img.CourseProductId
        //   && m.RelatedCourseIndex === img.RelatedCourseIndex
        //   && m.ProductId === img.ProductId);
        if (uniqueRelatedCourses.indexOf(img.RelatedCourseIndex) === -1) {
          //if (uniqueVaraint.length === 0) {
          const filterCourse = ctaValue.PartnerCourses.filter(
            (m) => m.RelatedCourseIndex === img.RelatedCourseIndex
          );
          // uniqueRelatedCourses.push({
          //   CourseProductId: img.CourseProductId,
          //   RelatedCourseIndex: img.RelatedCourseIndex,
          //   ProductId: img.ProductId
          // });
          const variantExist = partnerVariants.filter(
            (m) =>
              m.RelatedCourseIndex === img.RelatedCourseIndex &&
              m.ProductId === img.ProductId &&
              m.CourseProductId === img.CourseProductId
          );
          // partnerVariants.forEach(element => {
          //   if (element.ProductId === img.ProductId
          //     && element.CourseProductId === img.CourseProductId) {

          //   }
          //   else {
          //     partnerVariants.push(...filterCourse);
          //   }
          // });
          if (variantExist.length === 0) {
            partnerVariants.push(...filterCourse);
          }
        }
      });

      //const uniqueCourse = [...new Set(partnerVariants.map(m => m.ProductId && m.CourseProductId))]
      const result = [];
      const map = new Map();
      for (const item of partnerVariants) {
        if (!map.has(item.id)) {
          map.set(item.id, true); // set any value to Map
          result.push({
            id: item.id,
            name: item.name,
          });
        }
      }
      setUniqueRelatedCourse(uniqueRelatedCourses);
      setPartnerCourse(partnerVariants);
    }
  };

  const getPartnerCourseLocationDetail = (ctaValue) => {
    if (ctaValue) {
      const city = ctaValue.City ? ctaValue.City : '';
      const state = ctaValue.State ? ctaValue.State : '';
      const citystate = city ? city + (state ? ', ' + state : '') : state;
      return citystate;
    }
    return '';
  };
  const getPartnerCourseDateDetail = (ctaValue) => {
    if (ctaValue) {
      const startdate = ctaValue.StartDate
        ? changedateformat(ctaValue.StartDate)
        : '';
      const enddate = ctaValue.EndDate
        ? changedateformat(ctaValue.EndDate)
        : '';
      const fulldate = startdate
        ? startdate + (enddate ? '-' + enddate : '')
        : '';
      return fulldate;
    }
    return '';
  };
  const handleAddToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, 1);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
      }
      TrackGoogleAnalyticsEvent(
        GA4_EVENT_MAPPPING.ADD_TO_CART.category,
        GA4_EVENT_MAPPPING.ADD_TO_CART.event_name,
        GA4_EVENT_MAPPPING.ADD_TO_CART.label,
        {
          item: event.target.id,
          url: window.location.pathname,
        }
      );
    } else {
      const data = {
        productid: event.target.id,
        quantity: 1,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter(rlocation.pathname);
      history.push('/login');
    }
  };

  const addItemToCart = async (itemid, itemqty) => {
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      productid: itemid,
      quantity: itemqty,
      memberid: memberId,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };

  const addRouter = useCallback(
    (redirect_router) =>
      dispatch({
        type: UserActionTypes.REDIRECT_ROUTER,
        payload: {
          redirect_router,
        },
      }),
    [dispatch]
  );

  const applyDiscountCode = async () => {
    try {
      setSubmitting(true);
      let discountCodeArray = [];
      //hardcoded for not allowing customers to use the partner25 discount
      if (
        values.trainingDiscountCode &&
        ((values.trainingDiscountCode.toLowerCase() !== 'partner25' &&
          values.trainingDiscountCode.toLowerCase() === 'trinityemployee' &&
          profileuser &&
          profileuser.toLowerCase().includes('trinityconsultants.com')) ||
          (values.trainingDiscountCode.toLowerCase() !== 'partner25' &&
            values.trainingDiscountCode.toLowerCase() !== 'trinityemployee'))
      ) {
        discountCodeArray.push({
          DiscountType: 'Training',
          DiscountCode: values.trainingDiscountCode,
        });
      }
      if (values.softwareDiscountCode) {
        discountCodeArray.push({
          DiscountType: 'Software',
          DiscountCode: values.softwareDiscountCode,
        });
      }
      if (discountCodeArray.length > 0) {
        const data = {
          memberId: cartitemimpersonate.memberId,
          EmailAddress: profileuser,
          discountcodes: discountCodeArray,
        };
        const getcartResponse = await applyDiscountOnCartItems(data);
        if (getcartResponse && getcartResponse.isValidDiscount) {
          handleNotify('Discount code applied to cart.', ToasterTypes.Success);
          dispatch(cartCountUpdate(1));
          setValues({ trainingDiscountCode: '', softwareDiscountCode: '' });
          setSubmitting(false);
        } else {
          setSubmitting(false);
          handleNotify('Discount code is not valid!', ToasterTypes.Error);
        }
      } else {
        setSubmitting(false);
        handleNotify('Please enter discount code.', ToasterTypes.Warning);
      }

      setTimeout(() => {
        if (isIE) {
          window.location.reload();
        }
      }, 4000);

      //APPLY_DISCOUNT
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in Apply Discount Codes');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in Apply Discount Codes',
        },
      });
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    dispatch(updateCartItem([])); // need to reset temp redux updateCartItem array when page refresh

    if (isIE) {
      setCartCount(localStorage.getItem('lastUpdatedCount'));
    }
  }, []);

  useEffect(() => {
    setCartCount(
      cartitems != undefined && cartitems.ItemsCount > 0
        ? cartitems.ItemsCount
        : 0
    );
  }, [
    cartitems != undefined && cartitems.ItemsCount > 0
      ? cartitems.ItemsCount
      : 0,
  ]);

  const setQtyControl = (item) => {
    // let maxQty = item?.MaxAttendees || 10;
    let maxQty = 10;
    if (item?.MaxAttendees && Number(item?.MaxAttendees) <= 10) {
      maxQty = Number(item?.MaxAttendees);
    }

    if (item && item.Category.toLowerCase() === 'training') {
      if (
        item.SessionTypeName.toLowerCase() === 'ondemand' ||
        item.SessionTypeName.toLowerCase() === 'elearning'
      ) {
        maxQty = 1;
      } else {
        const remainingQty = item.MaxAttendees - item.TotalRegisterAttendees;
        if (remainingQty < 10) {
          maxQty = remainingQty;
        }
      }
    }
    return maxQty;
  };

  if (cartitems !== undefined && cartitems.ItemsCount > 0) {
    return (
      <>
        <br />
        <div className="new-shopping-cart-items">
          {isIE && (
            <div className={'alert alert-info'}>
              <i className="fa fa-info-circle" aria-hidden="true" /> This
              website no longer supports the use of Internet Explorer version 10
              and below. We recommend updating your browser to the latest
              versions of Microsoft Edge, Google Chrome or Mozilla Firefox for
              the best experience.
            </div>
          )}
          <div className="inner-cart-container">
            <Table bordered>
              <thead>
                <tr className="head-titles">
                  <th></th>
                  {/*<th className="image"></th>*/}
                  <th className={'table-header-align'}>Item</th>
                  <th className={'table-header-align image'}>Category</th>
                  <th className={'table-header-align'}>Price</th>
                  <th className={'table-header-align image'}>Discount</th>
                  <th className={'table-header-align'}>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {!cartitems ? (
                  <Skeleton />
                ) : (
                  cartitems.OrderLines.map((cta, index) => (
                    <tr>
                      <td className="delete">
                        <i
                          class="fa fa-trash"
                          aria-hidden="true"
                          id={cta.OrderLineID}
                          onClick={handleDelete}
                        ></i>
                      </td>
                      <td className="name">
                        <ShoppingCartProductItemDisplay
                          cta={cta}
                          showwarning={true}
                        />
                        {cta.Category.toLowerCase() === 'training' ? (
                          <ShoppingCartProductItemWarning
                            cta={cta}
                            requireattendees={
                              cartitems.AttendeesSessionTypeName
                            }
                          />
                        ) : (
                          ''
                        )}
                        {cta.PartnerCourses.length > 0 ? (
                          <p className="partner-course-link">
                            <span onClick={() => getPartnerCourses(cta)}>
                              Partner Course
                            </span>
                          </p>
                        ) : (
                          ''
                        )}
                      </td>
                      <td className="image section-name">
                        {cta.Category.toLowerCase() === 'training'
                          ? cta.SessionTypeName
                          : cta.Category}
                      </td>
                      <td className="price">
                        {' '}
                        {cta.Price > 0 ? (
                          <>
                            <NumberFormat
                              value={cta.Price}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </>
                        ) : (
                          'Free'
                        )}
                      </td>
                      <td className={'image'}>
                        {cta.Discount > 0 ? (
                          <>
                            <NumberFormat
                              value={cta.Discount}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </>
                        ) : (
                          <>
                            <NumberFormat
                              value={0}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </>
                        )}
                      </td>
                      <td>
                        <QuantityPickerFn
                          min={1}
                          max={setQtyControl(cta)}
                          id={cta.OrderLineID}
                          defaultValue={cta.Quantity}
                          originalValue={cta.Quantity}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>

          <Row className="xs-only-trinity-container mb-md-2 discounts-container">
            {cartitems.OrderTotal > 0 &&
            cartitems.OrderLines.filter(
              (orderline) => orderline.Category === 'Software'
            ).length > 0 ? (
              <Col xs={12} sm={3} className="discount-input-container">
                <TextInput
                  id="softwareDiscountCode"
                  name="softwareDiscountCode"
                  value={values ? values.softwareDiscountCode : ''}
                  onChange={handleChange}
                  placeholder={'Software Discount Code'}
                  autoComplete="off"
                />
              </Col>
            ) : (
              ''
            )}
            {cartitems.OrderTotal > 0 &&
            cartitems.OrderLines.filter(
              (orderline) => orderline.Category === 'Training'
            ).length > 0 ? (
              <Col xs={12} className="discount-input-container">
                <TextInput
                  id="trainingDiscountCode"
                  name="trainingDiscountCode"
                  value={values ? values.trainingDiscountCode : ''}
                  onChange={handleChange}
                  placeholder={'Training Discount Code'}
                  autoComplete="off"
                  autoFocus={true}
                />
              </Col>
            ) : (
              <Col xs={12} className="discount-input-container"></Col>
            )}
            {cartitems.OrderTotal > 0 ? (
              <Col xs={12} sm={3} className="discounts">
                <CustomButton
                  loading={submitting}
                  onClick={applyDiscountCode}
                  className="apply-discounts-btn m-sm-0"
                  variableWidth={true}
                >
                  Apply Discount Codes
                </CustomButton>
              </Col>
            ) : (
              <Col xs={12} sm={3} className="discounts"></Col>
            )}
            {!isOnlyOnDemand && (
              <React.Fragment>
                <Col xs={12} sm={2} className="update">
                  <CustomButton
                    className="update-cart-btn m-sm-0"
                    variableWidth={true}
                    altColors
                    onClick={updateItemForCart}
                  >
                    Update Cart
                  </CustomButton>
                </Col>
                {/* <div className="xs-only-trinity-container">
                                    {
                                        !mainContent
                                            ? <Skeleton height={20} />
                                            : <p className="pb-2 privacy">{mainContent.FooterContent}</p>
                                    }
                                </div> */}
                <div className="xs-only-trinity-container">
                  {
                    // !mainContent
                    //     ? <Skeleton height={20} />
                    <p className="pb-2 privacy">
                      {
                        "By placing your order, you agree to Trinity's privacy notice, and conditions of use"
                      }
                    </p>
                  }
                </div>
              </React.Fragment>
            )}
            {showPartnerBox && partnerCourse.length > 0 && (
              <div className="partner-course-container">
                <i
                  class="fa fa-times"
                  style={{ cursor: 'pointer' }}
                  aria-hidden="true"
                  onClick={() => setPartnerBox(!showPartnerBox)}
                />
                <p className="course-name title">
                  {partnerCourse[0].MainCourseTitle}
                </p>
                <p className="sub-name">
                  {' '}
                  Don't miss this partner course held consecutively for maximum
                  effectiveness and convenience
                </p>
                {partnerCourse.map((cta, index) => (
                  <>
                    <div className="lower-content">
                      <p className="title-course">{cta.Title}</p>
                    </div>
                    <hr />
                    <div className="lower-content">
                      <p className="location">
                        {getPartnerCourseLocationDetail(cta)}
                      </p>
                      <p className="location">
                        {getPartnerCourseDateDetail(cta)}
                      </p>
                      <p className="location">{cta.SessionTypeName}</p>
                      <div className="buttons">
                        <CustomButton
                          altColors={true}
                          onClick={handleAddToShoppingCart}
                          id={cta.ProductId}
                        >
                          Add to Cart &gt;
                        </CustomButton>
                      </div>
                    </div>
                  </>
                ))}
                <p className="blurb">
                  Save 25% on course fees when you register for partner courses.
                </p>
              </div>
            )}
          </Row>
        </div>
      </>
    );
  } else {
    return <EmptyShoppingCart />;
  }
}

export default ShoppingCartItems;
