import React from 'react';
import testImage from '../../../../images/industries/pdf.svg';
import ResourceTags from '../resource-tags/resource-tags.component';
import { widgetData } from '../resource-listing.data.js';
import './resource-posts.styles.scss';

// RK Task#114 fixed null check
function ResourcePosts({ posts }) {
  return (
    <div className="posts-container">
      {posts &&
        posts.map((item, i) => (
          <div key={i} className="widgetBox-wrapper">
            <div className="widget-wrapper">
              {item.GlobalIcon && (
                <img
                  src={item.GlobalIcon !== '' ? item.GlobalIcon : null}
                  alt=""
                />
              )}
              <div className="content">
                <p className="date">{item.strPublishedDate}</p>
                <a
                  href={item.Url}
                  target="_blank"
                  className="inside-link"
                  rel="noreferrer"
                >
                  {item.Title}
                </a>
                <p className="name">{item.AuthorName}</p>
                {/* <ResourceTags
                                    tagData={item.Tags}
                                /> */}
              </div>
            </div>
            <hr />
          </div>
        ))}
    </div>
  );
}

export default ResourcePosts;
