import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './image-carousel.styles.scss';

import SectionTitle from '../section-title/section-title.component';
import Carousel from '../carousel/carousel.component';

function ImageCarousel({ headtitle, producttours }) {
  const images = [];

  if (producttours) {
    producttours.forEach((imgelement) => {
      if (imgelement) {
        images.push({
          image_title: imgelement.Title,
          image_content: imgelement.Content,
          src: imgelement.IconUrl,
          alt: imgelement.IconAlternativeText,
        });
      }
    });
  }

  if (images) {
    return (
      <div className="image-carousel">
        <SectionTitle title={headtitle} />
        <div className="carousel-container">
          <Carousel unslickOnMobile={false}>
            {images &&
              images.map(({ src, alt, image_title, image_content }, index) => (
                <div className="inner-carousel" key={index}>
                  <div className="slick-item-container">
                    <Link to="/software-product-tour">
                      <img
                        src={src}
                        alt={alt}
                        className="thumb"
                        height="190"
                        width="265"
                      />
                    </Link>
                  </div>
                  <div className="image-content">
                    <p className="image-title">{image_title}</p>
                    {/* <p className="content">{image_content}</p> */}
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{ __html: image_content }}
                    />
                  </div>
                </div>
              ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default ImageCarousel;
