import React, { useEffect, useState } from 'react';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './request-a-key.validationRules';
import { checkbox_title, link } from './request-a-key.data';
import TextInput from '../../form-inputs/text-input/text-input.component';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import './request-a-key.styles.scss';
import {
  createToken,
  submitAzureQueueData,
} from '../../../../js/api/_azure-queue.data';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '../../../../js/api/_config';

function SoftwareRequestForm({ data, mainData, status }) {
  // #RP - Task 73 - Software request a key
  const [dropDownContent, setDropDownContent] = useState(data);
  const [noContent] = useState(204);
  const [showInput, setShowInput] = useState(false);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Product] = useState(
    status != noContent && dropDownContent.Product
      ? dropDownContent.Product
      : null
  );
  const [State, setState] = useState();
  const [Country, setCountry] = useState();
  const SubTitle = mainData.SubTitle != null ? mainData.SubTitle : '';
  const SubContent = mainData.SubContent != null ? mainData.SubContent : '';

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, []);

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitAzureQueueData(submissionObj, token);
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      Product: values.product != null ? values.product.trim() : '',
      RegistrationNumber:
        values.registrationNumber != null
          ? values.registrationNumber.trim()
          : '',
      CustomerID: values.customerID != null ? values.customerID.trim() : '',
      firstname: values.firstName.trim(),
      lastname: values.lastName.trim(),
      companyname: values.companyName.trim(),
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : '',
      PostalCode: values.postalCode.trim(),
      Phone: values.phone.trim(),
      companyemail: values.email.trim(),
      Country: values.Country.trim(),
      UniqueId: 'TC',
    };

    submitData(submissionObj);
  }

  return (
    <div className="request-a-key-form-container">
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <p
          className="form-title"
          dangerouslySetInnerHTML={{ __html: SubTitle }}
        />
        <p
          className="form-blurb"
          dangerouslySetInnerHTML={{ __html: SubContent }}
        />
        <SelectList
          className="mb-2"
          defaultText="— Select a product —"
          name="product"
          onChange={handleChange}
          value={values.product}
        >
          {status != noContent &&
            Product.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>
        {errors.registrationNumber && (
          <span className="help">{errors.registrationNumber}</span>
        )}
        <TextInput
          placeholder="Product Registration Number"
          required
          type="number"
          name="registrationNumber"
          onChange={handleChange}
          value={values.registrationNumber || ''}
          autoComplete="off"
          className={`${errors.registrationNumber && 'error'}`}
        />
        <div className="checkbox-container">
          <p className="inner-title">{checkbox_title}</p>
          <div className="first-input">
            <input
              type="radio"
              name="siteID"
              id="1"
              value="value1"
              className="radiocheck"
              onClick={() => setShowInput(true)}
            />
            <label htmlFor="yes">Yes</label>
          </div>
          <div className="second-input">
            <input
              type="radio"
              name="siteID"
              id="2"
              value="value1"
              className="radiocheck"
              onClick={() => setShowInput(false)}
            />
            <label htmlFor="no">No</label>
          </div>
        </div>

        {showInput && (
          <TextInput
            placeholder="Customer ID"
            type="text"
            name="customerID"
            onChange={handleChange}
            value={values.customerID || ''}
            autoComplete="off"
          />
        )}

        {errors.firstName && <span className="help">{errors.firstName}</span>}
        <TextInput
          placeholder="First Name"
          required
          type="text"
          name="firstName"
          onChange={handleChange}
          value={values.firstName || ''}
          autoComplete="off"
          className={`${errors.firstName && 'error'}`}
        />
        {errors.lastName && <span className="help">{errors.lastName}</span>}
        <TextInput
          placeholder="Last Name"
          required
          type="text"
          name="lastName"
          onChange={handleChange}
          value={values.lastName || ''}
          autoComplete="off"
          className={`${errors.lastName && 'error'}`}
        />
        {errors.companyName && (
          <span className="help">{errors.companyName}</span>
        )}
        <TextInput
          placeholder="Company Name"
          required
          type="text"
          name="companyName"
          onChange={handleChange}
          value={values.companyName || ''}
          autoComplete="off"
          className={`${errors.companyName && 'error'}`}
        />
        {errors.city && <span className="help">{errors.city}</span>}
        <TextInput
          placeholder="City"
          required
          type="text"
          name="city"
          onChange={handleChange}
          value={values.city || ''}
          autoComplete="off"
          className={`${errors.city && 'error'}`}
        />

        <SelectList
          defaultText="— Country —"
          name="Country"
          onChange={handleChange}
          value={values.Country}
        >
          {Country &&
            Country.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>

        <SelectList
          defaultText="— State / Province —"
          name="state"
          onChange={handleChange}
          onClick={stateclick}
          value={values.state}
        >
          {State &&
            State.map(({ Name }) => (
              <option key={Name} value={Name}>
                {Name}
              </option>
            ))}
        </SelectList>
        {errors.postalCode && <span className="help">{errors.postalCode}</span>}
        <TextInput
          placeholder="Postal Code"
          required
          name="postalCode"
          onChange={handleChange}
          value={values.postalCode || ''}
          autoComplete="off"
          className={`${errors.postalCode && 'error'}`}
        />

        {errors.phone && <span className="help">{errors.phone}</span>}
        <TextInput
          placeholder="Business Phone"
          required
          type="number"
          name="phone"
          onChange={handleChange}
          value={values.phone || ''}
          autoComplete="off"
          className={`${errors.phone && 'error'}`}
        />
        {errors.email && <span className="help">{errors.email}</span>}
        <TextInput
          placeholder="Business Email"
          type="email"
          name="email"
          required
          onChange={handleChange}
          value={values.email || ''}
          autoComplete="off"
          className={`${errors.email && 'error'}`}
        />
        <div className="recaptcha">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default SoftwareRequestForm;
