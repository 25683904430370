export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.jobTitle) {
    errors.jobTitle = 'Job Title is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone number field - ex: 1234567890';
  }
  if (!values.university) {
    errors.university = 'University or College name is required';
  }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (!values.address2) {
    errors.address2 = 'Address is required';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }
  if (!values.companyName) {
    errors.companyName = 'Company Name is required';
  }
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (!values.registrationNumber) {
    errors.registrationNumber = 'Registration Number is required';
  }
  if (!values.customerID) {
    errors.customerID = 'Customer ID is required';
  }
  if (!values.confirmEmail) {
    errors.confirmEmail = 'Email is required';
  }
  if (!values.projectName) {
    errors.projectName = 'Project Name is required';
  }
  if (!values.pageUrl) {
    errors.pageUrl = 'Page URL is required';
  }
  return errors;
}
