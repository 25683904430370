import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './changePassword.styles.scss';
import { useLocation } from 'react-router-dom';
import ChangePasswordForm from './change-password-form/change-password-form.component';

function ChangeProfilePassword() {
  const location = useLocation();

  return (
    <Row className="edit-profile-page trinity-container">
      <Col xs={12} className="text-content-container">
        <div className="text-content background transparent">
          <div className="inner-wrapper  trinity-container">
            <h1 className="m-0">Change Password</h1>
          </div>
        </div>
      </Col>
      <ChangePasswordForm
        userName={
          location &&
          location.state &&
          location.state.userInfo &&
          location.state.userInfo.Username
        }
      />
    </Row>
  );
}
export default ChangeProfilePassword;
