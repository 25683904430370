import React, { Suspense } from 'react';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import './indivi-location.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NewIndividualLocationLandingPage({
  page: { MainContent, StateName, breadCrumbList },
}) {
  const isIE = /* @cc_on!@ */ false || !!document.documentMode;
  const HeroComponent = React.lazy(() =>
    import('./new-individual-location-hero-component')
  );
  const NewIndividualLocationNewsComponent = React.lazy(() =>
    import('./new-individual-location-news-component')
  );
  const NewIndividualLocationMapComponent = React.lazy(() =>
    import('./new-individual-location-map-component')
  );
  const NewIndividualLocationLeadershipComponent = React.lazy(() =>
    import('./new-individual-location-leadership-component')
  );
  const NewIndividualLocationLocallinkComponent = React.lazy(() =>
    import('./new-individual-location-locallink-component')
  );
  const NewIndividualLocationWidgetboxComponent = React.lazy(() =>
    import('./new-individual-location-widgetbox-component')
  );

  return (
    <div className="individual-location-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
        <Col className="trinity-container">
          <ContentWithSidebar
            isCustom
            left={
              <div>
                <h2>
                  {MainContent.Title}
                  {StateName && `, ${StateName}`}
                </h2>
                <ContentWell
                  content={MainContent.Content ? MainContent.Content : ''}
                />
                {MainContent.HideStateNews !== 'Yes' && (
                  <NewIndividualLocationNewsComponent
                    MainContent={MainContent}
                  />
                )}
              </div>
            }
            right={
              <Row>
                <Col className="left">
                  {!isIE ? (
                    <NewIndividualLocationMapComponent
                      MainContent={MainContent}
                      StateName={StateName}
                    />
                  ) : (
                    ''
                  )}
                  <br />
                  <NewIndividualLocationLeadershipComponent
                    MainContent={MainContent}
                  />
                  <NewIndividualLocationWidgetboxComponent
                    MainContent={MainContent}
                  />
                  <NewIndividualLocationLocallinkComponent
                    MainContent={MainContent}
                  />
                </Col>
              </Row>
            }
          />
        </Col>
      </Suspense>
    </div>
  );
}

export default NewIndividualLocationLandingPage;
