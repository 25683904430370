import React, { useState, useEffect } from 'react';
import useForm from '../../../../../js/custom-hooks/useForm';
import validate from './problem-form.validationRules';
import { title, link, uploadTitle, uploadLink } from './problem-form.data';
import TextInput from '../../../form-inputs/text-input/text-input.component';
import SelectList from '../../../form-inputs/select-list/select-list.component';
import CustomButton from '../../../custom-button/custom-button.component';
import ReCaptcha from '../../../recaptcha/recaptcha.component';
import {
  submitFileDataWebsiteProblem,
  submitReportWebsiteProblemResponse,
  submitWebsiteProblemPageData,
} from '../../../../../js/api/specialty-pages/_website-problem.data';
import './problem-form.styles.scss';
import {
  createToken,
  submitAzureQueueData,
} from '../../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../../helpers/form-uniqueid/form-uniqueid';
import { useHistory } from 'react-router-dom';

// #RP - Task 71 - Website Problem
function ProblemForm({ mainData, status }) {
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);
  const [fileData, setFile] = useState(null);

  function readFile(file) {
    const files = { base64: '', fileList: file };
    const reader = new FileReader();
    reader.onload = readSuccess;
    function readSuccess(evt) {
      files.base64 = evt.target.result;
      setFile(files);
    }
    reader.readAsDataURL(file);
  }

  const onfileChange = (e) => {
    if (e.target.files != null && e.target.files.length > 0)
      readFile(e.target.files[0]);
  };

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const submitData = async (submissionObj, fileObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitReportWebsiteProblemResponse(
        submissionObj,
        token
      );
      const fileResponse = await submitFileDataWebsiteProblem(fileObj, token);
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      EmailAddress: values.email.trim(),
      PageURL: values.pageUrl != undefined ? values.pageUrl.trim() : 'None',
      Description:
        values.description != null ? values.description.trim() : 'None',
      File: fileData != null ? fileData.base64 : 'None',
      FileName: fileData != null ? fileData.fileList.name : 'None',
      FormUniqueId: ListOfFormUniqueData.report_website_problem.UniqueId,
      Catalog: ListOfFormUniqueData.report_website_problem.Catalog,
    };

    const formData = new FormData();
    formData.set('FirstName', values.firstName.trim());
    formData.set('LastName', values.lastName.trim());
    formData.set('File', fileData != null ? fileData.base64 : 'None');
    formData.set(
      'FileName',
      fileData != null ? fileData.fileList.name : 'None'
    );

    submitData(submissionObj, formData);
    // submitClientDetail(submissionObj,mainContent);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    <div className="problem-form-container">
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <p className="form-title">{title}</p>

        <TextInput
          placeholder="First Name"
          required
          type="text"
          name="firstName"
          onChange={handleChange}
          value={values.firstName || ''}
          autoComplete="off"
          className={`${errors.firstName && 'error'}`}
          maxlength={100}
        />
        {errors.firstName && <span className="help">{errors.firstName}</span>}

        <TextInput
          placeholder="Last Name"
          required
          type="text"
          name="lastName"
          onChange={handleChange}
          value={values.lastName || ''}
          autoComplete="off"
          className={`${errors.lastName && 'error'}`}
          maxlength={100}
        />
        {errors.lastName && <span className="help">{errors.lastName}</span>}

        <TextInput
          placeholder="Email Address"
          type="email"
          name="email"
          required
          onChange={handleChange}
          value={values.email || ''}
          autoComplete="off"
          className={`${errors.email && 'error'}`}
          maxlength={200}
        />
        {errors.email && <span className="help">{errors.email}</span>}

        <TextInput
          placeholder="Page URL"
          required
          type="text"
          name="pageUrl"
          onChange={handleChange}
          value={values.pageUrl || ''}
          autoComplete="off"
          className={`${errors.pageUrl && 'error'}`}
          maxlength={200}
        />
        {errors.pageUrl && <span className="help">{errors.pageUrl}</span>}

        <textarea
          placeholder="Please enter a detailed description of the problem: "
          onChange={handleChange}
          value={values.description}
          name="description"
          autoComplete="off"
          maxLength={1000}
        />

        <div className="react-file-reader">
          <p className="title">{uploadTitle}</p>
          <input
            type="file"
            className="react-file-reader-input"
            multiple={false}
            onChange={onfileChange}
          />
          <div className="react-file-reader-button" />
        </div>
        <div className="recaptcha mb-2 mt-4">
          <div
            style={
              errors &&
              errors.recaptchaValue && {
                border: '1px solid red',
                width: 'fit-content',
              }
            }
          >
            <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
          </div>
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default ProblemForm;
