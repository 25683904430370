import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import CloseBtn from '../../commons/helpers/close-btn/close-btn.component';
import {
  activePageNumber,
  getPageWiseNews,
  getUserPageWiseNews,
  loadNews,
  removeNewsActionFilter,
} from '../../../redux/news/news.action';

function NewsActiveSearchFilter() {
  const dispatch = useDispatch();
  const tagFilterData = useSelector(({ news }) => news.tagsFilter);
  const newsFilterData = useSelector(({ news }) => news.newsFilter);
  const industryFilterData = useSelector(({ news }) => news.industryFilter);
  const publishedDateFilterData = useSelector(
    ({ news }) => news.publishedDateFilter
  );
  const locationFilterData = useSelector(({ news }) => news.locationFilter);
  const publishedFromDate = useSelector(({ news }) => news.publishedFromDate);
  const publishedToDate = useSelector(({ news }) => news.publishedToDate);
  const newsLoading = useSelector(({ news }) => news.newsLoading);

  const [rtag, setRTag] = useState(false);
  const [rnews, setRNews] = useState(false);
  const [rindustry, setRIndustry] = useState(false);
  const [rlocation, setRLocation] = useState(false);
  const [rdate, setRDate] = useState(false);
  const [pFDate, setPFDate] = useState(false);
  const [pTDate, setPTDate] = useState(false);

  useEffect(() => {
    if (rtag) {
      submitSuccess();
      setRTag(false);
    } else if (rnews) {
      submitSuccess();
      setRNews(false);
    } else if (rindustry) {
      submitSuccess();
      setRIndustry(false);
    } else if (rlocation) {
      submitSuccess();
      setRLocation(false);
    } else if (rdate) {
      submitSuccess();
      setRDate(false);
    } else if (pFDate && pTDate) {
      submitSuccess();
      setPFDate(false);
      setPTDate(false);
    }
  }, [
    tagFilterData,
    newsFilterData,
    industryFilterData,
    publishedDateFilterData,
    locationFilterData,
    publishedFromDate,
    publishedToDate,
  ]);

  function submitSuccess() {
    const selecttags = [];
    const selectnews = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectdate = [];

    tagFilterData.forEach((item) => {
      selecttags.push(item.Name);
    });
    newsFilterData.forEach((item) => {
      selectnews.push(item.Id);
    });
    industryFilterData.forEach((item) => {
      selectindustry.push(item.Id);
    });
    publishedDateFilterData.forEach((item) => {
      selectdate.push(item.Id);
    });
    locationFilterData.forEach((item) => {
      selectlocation.push(item.Id);
    });

    const submissionObj = {
      PageNumber: 1,
      Tags: selecttags,
      NewsCategory: selectnews,
      IndustryCategory: selectindustry,
      Location: selectlocation,
      PublishedDate: selectdate,
      PublishedFromDate: publishedFromDate,
      PublishedToDate: publishedToDate,
    };

    dispatch(loadNews(true));
    dispatch(getUserPageWiseNews(submissionObj));
    dispatch(activePageNumber(1));
  }

  const handleTagClick = (item) => {
    if (!newsLoading) {
      dispatch(removeNewsActionFilter(item, tagFilterData, 'Tags'));
      setRTag(true);
    }
  };

  const handleNewsClick = (item) => {
    if (!newsLoading) {
      dispatch(removeNewsActionFilter(item, newsFilterData, 'News'));
      setRNews(true);
    }
  };

  const handleIndustryClick = (item) => {
    if (!newsLoading) {
      dispatch(removeNewsActionFilter(item, industryFilterData, 'Industry'));
      setRIndustry(true);
    }
  };

  const handlePublishedDateClick = (item) => {
    if (!newsLoading) {
      dispatch(
        removeNewsActionFilter(item, publishedDateFilterData, 'PublishedDate')
      );
      setRDate(true);
    }
  };

  const handleLocationClick = (item) => {
    if (!newsLoading) {
      dispatch(removeNewsActionFilter(item, locationFilterData, 'Location'));
      setRLocation(true);
    }
  };

  const handlePublishedFromToDateClick = () => {
    if (!newsLoading) {
      dispatch(removeNewsActionFilter(null, null, 'PublishedFromDate'));
      dispatch(removeNewsActionFilter(null, null, 'PublishedToDate'));
      setPFDate(true);
      setPTDate(true);
    }
  };

  return (
    <div className="">
      <h3 className="active-filters-title">Active Search Filters:</h3>
      <ul>
        {tagFilterData && tagFilterData.length > 0
          ? tagFilterData.map((item) => (
              <li
                className="list-item bg-tags"
                onClick={() => handleTagClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {newsFilterData && newsFilterData.length > 0
          ? newsFilterData.map((item) => (
              <li
                className="list-item bg-news"
                onClick={() => handleNewsClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {industryFilterData && industryFilterData.length > 0
          ? industryFilterData.map((item) => (
              <li
                className="list-item bg-industry"
                onClick={() => handleIndustryClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {publishedDateFilterData && publishedDateFilterData.length > 0
          ? publishedDateFilterData.map((item) => (
              <li
                className="list-item bg-pdate"
                onClick={() => handlePublishedDateClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {locationFilterData && locationFilterData.length > 0
          ? locationFilterData.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleLocationClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {publishedFromDate && publishedToDate && (
          <li
            className="list-item bg-location"
            onClick={handlePublishedFromToDateClick}
          >
            <span className="flist-item-text">
              {`${moment(publishedFromDate).format('DD/MM/yyyy')} - ${moment(
                publishedToDate
              ).format('DD/MM/yyyy')}`}
            </span>
            {!newsLoading && <CloseBtn className="ml-1 ml-sm-4" />}
          </li>
        )}
      </ul>
    </div>
  );
}

export default NewsActiveSearchFilter;
