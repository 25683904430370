import React, { Suspense, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';

import './review-order.styles.scss';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import { ENDPOINTS } from '../../../js/api/_config';
import ReviewOrderHeaderComponent from './ReviewOrderHeaderComponent';
import ReviewOrderContentComponent from './ReviewOrderContentComponent';

function ReviewOrderPage() {
  const [reviewOrderContent, setReviewOrderContent] = useState(null);

  useEffect(() => {
    async function getDataFromURL() {
      const response = await getPageDataByApiUrl(
        ENDPOINTS.USER_CART_ACCOUNT.REVIEW_CART_URL
      );
      console.log(response);
      return response;
    }

    getDataFromURL().then((response) => {
      setReviewOrderContent(response);
    });
  }, []);

  // const ReviewOrderHeaderComponent = React.lazy(() => import('./ReviewOrderHeaderComponent'));
  // const ReviewOrderContentComponent = React.lazy(() => import('./ReviewOrderContentComponent'));

  return (
    <Row className="review-order-container trinity-container">
      {/* <Suspense fallback={<Loader/>}> */}
      <ReviewOrderHeaderComponent
        MainContent={reviewOrderContent?.MainContent}
      />
      {/* </Suspense> */}
      {/* <Suspense fallback={<Loader/>}> */}
      <ReviewOrderContentComponent
        MainContent={reviewOrderContent?.MainContent}
      />
      {/* </Suspense> */}
    </Row>
  );
}

export default ReviewOrderPage;
