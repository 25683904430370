import React from 'react';
import './product-list-sidebar.styles.scss';

import SidebarProductListItem from './product-list-item/product-list-item.component';
import SidebarContactCta from './sidebar-contact-cta/sidebar-contact-cta.component';
import SidebarProductDownload from './sidebar-product-download/sidebar-product-download.component';

function ProductListSidebar({
  productlist,
  mainContent,
  isFromSoftwareProduct,
}) {
  const items = [];

  if (productlist) {
    const itemvariants = productlist.Variants;
    if (
      productlist.HasExtensions === 'False' &&
      productlist.HasModule === 'False'
    ) {
      items.push({
        title: productlist.Title,
        retailPrice: productlist.ProductPrice_Price,
        salePrice: productlist.ProductPrice_BundleRetailPrice,
        id: productlist.Id,
      });
    }
    itemvariants.forEach((element) => {
      if (element.IsExtension === 'True' || element.IsModule === 'True') {
        items.push({
          title: element.Title,
          retailPrice: element.ProductPrice_BundleRetailPrice,
          salePrice: element.ProductPrice_Price,
          id: element.Id,
        });
      }
    });
  }
  if (items) {
    return (
      <div className="product-list-sidebar">
        <SidebarContactCta isFromSoftwareProduct={isFromSoftwareProduct} />
        <div className="product-list-item">
          {items.map((cta, index) => (
            <SidebarProductListItem
              key={index}
              title={cta.title}
              maxQty={10}
              price={cta.salePrice}
              productId={cta.id}
              productData={cta}
              mainContent={mainContent}
            />
          ))}
        </div>
        <SidebarProductDownload />
      </div>
    );
  }
}

export default ProductListSidebar;
