import { ENDPOINTS } from './_config';

export const filterNewsMedia = async (submissionObj) => {
  try {
    const request = await fetch(ENDPOINTS.GET_NEWS_MEDIA, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submissionObj),
    });
    const response = await request.json();

    return response;
  } catch (e) {
    return null;
  }
};
