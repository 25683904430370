import { ResourceService } from './resource.service';
import { ResourceActionTypes } from './resource.type';

export const getPageWiseResource = (resource) => (dispatch) => {
  ResourceService.getPageWiseResource(resource).then((response) => {
    if (response) {
      const resourceData = response.data.ResourceContent;
      dispatch({
        type: ResourceActionTypes.GET_PAGEWISE_RESOURCE,
        payload: resourceData,
      });

      if (resourceData.length > 0) {
        dispatch({
          type: ResourceActionTypes.TOTAL_ITEM_COUNT,
          payload: response.data.ResourceTotalCount,
        });
      }
      dispatch({
        type: ResourceActionTypes.RESOURCE_LOADING,
        payload: false,
      });
    } else if (response && response.data == '') {
      dispatch({
        type: ResourceActionTypes.GET_PAGEWISE_RESOURCE,
        payload: [],
      });
    } else {
      dispatch({
        type: ResourceActionTypes.RESOURCE_LOADING,
        payload: false,
      });
    }
  });
};

export const getNewPageWiseResource = (resource) => (dispatch) => {
  ResourceService.getNewPageWiseResource(resource).then((response) => {
    if (response) {
      const resourceData = response.data.ResourceContent;
      dispatch({
        type: ResourceActionTypes.GET_PAGEWISE_RESOURCE,
        payload: resourceData,
      });

      if (resourceData.length > 0) {
        dispatch({
          type: ResourceActionTypes.TOTAL_ITEM_COUNT,
          payload: response.data.ResourceTotalCount,
        });
      }
      dispatch({
        type: ResourceActionTypes.RESOURCE_LOADING,
        payload: false,
      });
    } else if (response && response.data == '') {
      dispatch({
        type: ResourceActionTypes.GET_PAGEWISE_RESOURCE,
        payload: [],
      });
    } else {
      dispatch({
        type: ResourceActionTypes.RESOURCE_LOADING,
        payload: false,
      });
    }
  });
};

export const loadResource = (booleanParameter) => (dispatch) => {
  dispatch({
    type: ResourceActionTypes.RESOURCE_LOADING,
    payload: booleanParameter,
  });
};

export const activePageNumber = (pagenumber) => (dispatch) => {
  dispatch({
    type: ResourceActionTypes.ACTIVE_PAGE_NUMBER,
    payload: pagenumber,
  });
};

export const loadFilter = () => (dispatch) => {
  ResourceService.getResourceFilterData().then((response) => {
    if (response) {
      dispatch({
        type: ResourceActionTypes.FILTER_LOADING,
        payload: response.data,
        responseStatus: true,
      });
    }
  });
};

export const loadNewFilter = () => (dispatch) => {
  ResourceService.getNewResourceFilterData().then((response) => {
    if (response) {
      dispatch({
        type: ResourceActionTypes.FILTER_LOADING,
        payload: response.data,
        responseStatus: true,
      });
    }
  });
};

export const selectResourceActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata;
      } else {
        pdata.push({ Id: filtername.Id, Name: filtername.Name });
      }

      if (method == 'Keyword') {
        dispatch({ type: ResourceActionTypes.KEYWORD_FILTER, payload: pdata });
      } else if (method == 'Resource_Type') {
        dispatch({
          type: ResourceActionTypes.RESOURCE_TYPE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({ type: ResourceActionTypes.INDUSTRY_FILTER, payload: pdata });
      } else if (method == 'Service') {
        dispatch({ type: ResourceActionTypes.SERVICE_FILTER, payload: pdata });
      } else if (method == 'Location') {
        dispatch({ type: ResourceActionTypes.LOCATION_FILTER, payload: pdata });
      }
    }
  };

export const removeResourceActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata.filter((x) => x.Name != filtername.Name);
      } else {
        pdata = pdata;
      }

      if (method == 'Keyword') {
        dispatch({ type: ResourceActionTypes.KEYWORD_FILTER, payload: pdata });
      } else if (method == 'Resource_Type') {
        dispatch({
          type: ResourceActionTypes.RESOURCE_TYPE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({ type: ResourceActionTypes.INDUSTRY_FILTER, payload: pdata });
      } else if (method == 'Service') {
        dispatch({ type: ResourceActionTypes.SERVICE_FILTER, payload: pdata });
      } else if (method == 'Location') {
        dispatch({ type: ResourceActionTypes.LOCATION_FILTER, payload: pdata });
      }
    }
  };

export const getAllTorontoContent = () => (dispatch) => {
  dispatch({
    type: ResourceActionTypes.GET_TORONTO_CONTENT,
    payload: [],
    responseStatus: false,
  });
  ResourceService.getTorontoContentData().then((response) => {
    if (response) {
      dispatch({
        type: ResourceActionTypes.GET_TORONTO_CONTENT,
        payload: response.data,
        responseStatus: true,
      });
    } else {
      dispatch({
        type: ResourceActionTypes.GET_TORONTO_CONTENT,
        payload: [],
        responseStatus: true,
      });
    }
  });
};
