import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import TextInput from '../../../../commons/form-inputs/text-input/text-input.component';
import CustomButton from '../../../../commons/custom-button/custom-button.component';

import './reset-password.styles.scss';
import {
  userResetPasswordValidToken,
  userResetPasswordRequest,
} from '../../../../../js/api/_user-email-exist';
import Loader from '../../../../commons/helpers/loader/loader.component';

function ResetPasswordForm() {
  const history = useHistory();
  const [status, setStatus] = useState(null);
  const { id } = useParams();
  const [validEmailAddress, setValidEmailAddress] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] = useState(null);
  const [trypassword, setTrypassword] = useState(null);
  const passwordOnChange = (e) => setPassword(e.target.value);
  const trypasswordOnChange = (e) => setTrypassword(e.target.value);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const data = {
        validtoken: id,
      };
      const getResponse = await userResetPasswordValidToken(id);

      if (getResponse && getResponse.TokenIsValid) {
        setValidEmailAddress(getResponse.ValidEmailAddress);
      } else {
        history.push('/reset-failed');
      }
      setStatus(getResponse);
    };
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setErrorMessage('');

    const getResponse = await userResetPasswordValidToken(id);
    if (getResponse && getResponse.TokenIsValid) {
      const response = await handleResetPasswordRequest();

      if (response && response.ChangeSuccessfully) {
        history.push('/reset-successful');
      } else if (response && !response.ChangeSuccessfully) {
        setSubmitting(false);
        return setErrorMessage(response.PasswordMessage);
      }
    } else {
      setSubmitting(false);
      return setErrorMessage('Token is not valid , please request again');
    }
    setSubmitting(false);
  };

  const handleResetPasswordRequest = async () => {
    const data = {
      emailaddress: validEmailAddress,
      password,
      validtoken: id,
      confirmpassword: trypassword,
    };
    const response = await userResetPasswordRequest(data);
    return response;
  };

  if (status) {
    return (
      <div className="reset-pswd-form module-spacer">
        {errorMessage && <p className="text error p-0">*{errorMessage}</p>}
        <div className="form-container background action-item">
          <h2>Create a New Password</h2>
          <hr />

          <TextInput
            placeholder="john@myemail.com"
            label="Email"
            type="email"
            value={validEmailAddress || ''}
            disabled
          />
          <TextInput
            placeholder="Choose a new Password"
            label="Choose a new Password"
            type="password"
            onChange={(e) => passwordOnChange(e)}
            required
            value={password || ''}
            autoComplete="off"
          />
          <TextInput
            placeholder="Confirm Password"
            label="Confirm Password"
            type="password"
            onChange={(e) => trypasswordOnChange(e)}
            required
            value={trypassword || ''}
            autoComplete="off"
          />

          <CustomButton
            onClick={handleSubmit}
            type="submit"
            loading={submitting}
          >
            Continue
          </CustomButton>
        </div>
        <p
          style={{
            fontWeight: '500',
            marginTop: '1rem',
            marginBottom: '0',
            padding: '0',
          }}
        >
          Did you change your email address?
        </p>
        <p style={{ padding: '0' }}>
          If you do not remember your email address or still cannot access your
          account, please feel free to give us a call at +1 800.613.4473 or send
          an email to <a href="#">password-help@trinityconsultants.com</a> and
          we will get back to you within 1 business day. We apologize for any
          inconvenience.
        </p>
      </div>
    );
  }
  return <Loader />;
}

export default ResetPasswordForm;
