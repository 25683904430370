import React, { Component } from 'react';
import ModalVideo from 'react-modal-video';
import { title, blurb } from './video-cta.data';
import './video-cta.styles.scss';
import cta from '../../../images/homepage/video-img.png';

class VideoCta extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  // openModal function
  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  render() {
    return (
      <div className="video-cta-container ">
        <img src={cta} alt="" />
        <div className="text-wrapper trinity-container">
          <p className="title">{title}</p>
          <p className="copy">{blurb}</p>
        </div>
        <div className="modal-video-container">
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId="L61p2uyiMSo"
            onClose={() => this.setState({ isOpen: false })}
          />
          <button onClick={this.openModal}>
            <i className="fa fa-play-circle" />
          </button>
        </div>
      </div>
    );
  }
}

export default VideoCta;
