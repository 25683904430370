import React from 'react';

import './custom-marker.styles.scss';

function CustomMarker({ text, image }) {
  return (
    <div className="marker-container">
      <img src={image} alt="" />
      <div className="marker-content">
        <a href="#">{text}</a>
      </div>
    </div>
  );
}

export default CustomMarker;
