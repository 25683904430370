import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class MinnowServices {
  static getMinnowDAta() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.MINNOW.GET_MINNOW_DATA,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getServiceBrandTrainingList(parameterURL) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.MINNOW.SERVICE_BRAND_TRAINING_URL}/${parameterURL}`,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getServiceBrandNewsList(parameterURL, pageName) {
    return axios({
      method: 'GET',
      url: `${
        ENDPOINTS.MINNOW.SERVICE_BRAND_NEWS + parameterURL
      }&PageName=${pageName}`,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }

  static getServiceBrandEventList(parameterURL) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.MINNOW.SERVICE_BRAND_EVENTS_URL}/${parameterURL}`,
    })
      .then((e) => e.data)
      .catch((e) => e);
  }
}
