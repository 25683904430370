import TrainingLpModel from './training-models/training-lp.model';
import TrainingSecondaryModel from './training-models/training-secondary.model';
// RK Task#99 made changes to training
const trainingPages = [
  // {
  //   name: "Training-landing",
  //   path: "/training",
  //   apiurl:"/restapi/customcontent/training",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "Training-landing",
  //   modules: TrainingLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Training",
  //       hero_description: `<p>
  //                   <strong>The EHS training you need in the environment that’s best for you.</strong></br>
  //                   Seminars. Classrooms. Online. We provide the best training in the EHS industry. The training you need to move your company forward.
  //               </p>`,
  //     },
  //   }),
  // },
  {
    name: 'TrainingSecondary',
    path: '/training/secondary',
    apiurl: '/restapi/customcontent/training/secondary',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'TrainingSecondary',
    modules: TrainingSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'EHS & S Management Training',
        hero_description: `<p>
                    Trinity teaches courses on EHS auditing, CDP reporting, EHS risk management, sustainability reporting, ISO 14001, and life cycle analysis for EHS professionals.
                </p>`,
        hero_link: {
          src: '',
          text: 'View All Training Courses',
        },
      },
    }),
  },
  {
    name: 'Training Tertiary',
    path: '/training-tertiary',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'TrainingTertiary',
  },
  {
    name: 'Custom Training',
    path: '/custom-training',
    template: 'custom-training',
    modules: TrainingSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'Custom/Onsite Training',
      },
    }),
  },
  {
    name: 'Request Training Quote',
    path: '/request-training-quote',
    apiurl: '/restapi/customrequesttrainingquotecontent/requesttrainingquote',
    template: 'new-request-training-quote',
    modules: TrainingSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'Custom Training',
        hero_description: 'Custom/Onsite Training from BREEZE',
      },
    }),
  },
  // {
  //   name: "Request Training Quote",
  //   path: "/request-training-quote",
  //   template: "request-training-quote",
  //   modules: TrainingSecondaryModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Custom Training",
  //       hero_description: "Custom/Onsite Training from BREEZE",
  //     },
  //   }),
  // },
  {
    name: 'Request Training Quote',
    path: '/training/request-training-quote',
    template: 'request-training-quote',
    modules: TrainingSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'Custom Training',
        hero_description: 'Custom/Onsite Training from BREEZE',
      },
    }),
  },
  {
    name: 'TrainingLP-2',
    path: '/training2',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'TrainingLP2',
    modules: TrainingLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Training 2',
        hero_description: `<p>
                    <strong>The EHS training you need in the environment that’s best for you.</strong></br>
                    Seminars. Classrooms. Online. We provide the best training in the EHS industry. The training you need to move your company forward.
                </p>`,
      },
    }),
  },
  {
    name: 'Training Search',
    path: '/training-search',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'TrainingSearch',
    modules: TrainingSecondaryModel({
      hero: {
        hero_src: '',
        hero_title: 'Training Search',
        hero_description: `<p>
                    Trinity teaches courses on EHS auditing, CDP reporting, EHS risk management, sustainability reporting, ISO 14001, and life cycle analysis for EHS professionals.
                </p>`,
        hero_link: {
          src: '',
          text: 'View All Training Courses',
        },
      },
    }),
  },
  // {
  //   name: "Training Search Result",
  //   path: "/training/search",
  //   template: "training-search",
  //   modules: TrainingLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Training Search Result",
  //     },
  //   }),
  // },
  {
    name: 'Training Software Result',
    path: '/training/software',
    template: 'training-software',
    modules: TrainingLpModel({
      hero: {
        hero_src: '',
        hero_title: 'Training Software Result',
      },
    }),
  },
  // {
  //   name: "Training All Courses",
  //   path: "/training/all-courses",
  //   template: "training-all-courses",
  //   modules: TrainingLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "All Training Courses",
  //     },
  //   }),
  // },
];

export default JSON.stringify(trainingPages);
