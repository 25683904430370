import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import cartReducer from './shopping-cart/shopping-cart.reducer';
import userReducer from './user/user.reducer';
import trainingReducer from './training/training.reducer';
import { dropdownContentReducer } from './dropdown-content/dropdown-content.reducer';
import clientReducer from './clientdata/client.reducer';
import newsReducer from './news/news.reducer';
import minnowReducer from './minnowdata/minnow.reducer';
import widgetReducer from './widget-box/widget-box.reducer';
import feedbackReducer from './feed-back-data/feedback.reducer';
import impersonateUser from './impersonate-users/impersonate.reducer';
import {
  countryStateList,
  stateWiseMetaInformation,
  trainingCourses,
} from './course-training/course.reducer';
import homePageReducer from './new-home-page/new-home.reducer';
import resourceReducer from './resource/resource.reducer';
import softwareResourceReducer from './software-resource/software-resource.reducer';
import { trinitySearchData } from './trinity-search/trinity-search.reducer';
import { softwareResourceSearchData } from './resource-search/software-resource/software-resource.reducer';
import { generalResourceSearchData } from './resource-search/general-resource/general-resource.reducer';
import { ehsQuarterly } from './ehs-quarterly/ehs.reducer';
import { generalNewsSearchData } from './news-search/news-search.reducer';
import { trainingAzureSearchData } from './training/training-new-search/training-azure-search.reducer';
import { safeBridgeAzureSearchData } from './safe-bridge/safe-bridge-reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'cart'],
};

const rootReducer = combineReducers({
  cart: cartReducer,
  user: userReducer,
  dropdownContent: dropdownContentReducer,
  training: trainingReducer,
  client: clientReducer,
  news: newsReducer,
  minnow: minnowReducer,
  feedback: feedbackReducer,
  widget: widgetReducer,
  impersonateUser,
  trainingCourses,
  countryStateList,
  stateWiseMetaInformation,
  homePageData: homePageReducer,
  resource: resourceReducer,
  softwareresource: softwareResourceReducer,
  trinitySearchData,
  ehsQuarterly,
  softwareResourceSearchData,
  generalResourceSearchData,
  generalNewsSearchData,
  trainingAzureSearchData,
  safeBridgeAzureSearchData,
});

export default persistReducer(persistConfig, rootReducer);
