import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../commons/custom-button/custom-button.component';
import './product-item.styles.scss';

import { trackTrainingGA4Event } from '../../../TrackGoogleAnalytics';
import { addCartItem } from '../../../js/api/shopping-cart/_shopping-cart';
import {
  addSoftwareItemToCart,
  cartCountUpdate,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { UserActionTypes } from '../../../redux/user/user.types';
import { trinityTrainingDateFormatter } from '../../../utilities/CommonUtilities';

// JP #Task RK Task#
function ProductWebinarItem({ title, product }) {
  const [price, setPrice] = React.useState('');

  const isIE = /* @cc_on!@ */ !!document.documentMode;

  const multipleDays = true;
  const { CEU, City, State, date, time } = product;

  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.tokens.access_token);
  const loggedInUserData = useSelector(({ user }) => user.loggedin_user);

  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const history = useHistory();
  const location = useLocation();

  const getPrice = (data, type) => {
    const filteredData =
      data.Variants &&
      data.Variants.filter((e) => e.SessionTypeName.toLowerCase() === type);
    if (filteredData.length > 0) {
      const firstData = filteredData[0];
      if (firstData && firstData.VariantPrice) {
        return firstData.VariantPrice;
      }
      return product && product.Price;
    }
    return product && product.Price;
  };

  useEffect(() => {
    switch (title.toString().toLowerCase()) {
      case 'webinar':
        setPrice(getPrice(product, 'webinar'));
        break;
      case 'seminar':
        setPrice(getPrice(product, 'seminar'));
        break;
      case 'ondemand':
        setPrice(getPrice(product, 'ondemand'));
        break;
      case 'elearning':
        setPrice(getPrice(product, 'elearning'));
        break;
      default:
        setPrice(product && product.Price);
    }
  }, []);

  const changedateformat = (dateValue) => {
    if (dateValue) {
      return trinityTrainingDateFormatter(dateValue);
    }
  };

  const timeDisplay = (variant, i) => {
    const newVariant = variant.StartTime;
    if (variant && variant.StartTime !== '') {
      return (
        <>
          <span className="day mr-1">
            Day
            {i + 1}:
          </span>
          <p className="time">
            {variant.StartTime}
            {variant.EndTime ? `- ${variant.EndTime}` : null}
            {variant.TimeZone ? `- ${variant.TimeZone}` : null}
          </p>
        </>
      );
    }
  };

  const handleAddToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, 1);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
      }
    } else {
      trackTrainingGA4Event(product, event.target.id, false);
      const data = {
        productid: event.target.id,
        quantity: 1,
        product: product,
        isTraining: true,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter(location.pathname);
      history.push('/login');
    }

    if (isIE) {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  const handleBuyNowToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, 1);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
        history.push('/shopping-cart');
      }
    } else {
      trackTrainingGA4Event(product, event.target.id, false);
      const data = {
        productid: event.target.id,
        quantity: 1,
        product: product,
        isTraining: true,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter('/shopping-cart');
      history.push('/login');
    }

    if (isIE) {
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    }
  };

  const addItemToCart = async (itemid, itemqty) => {
    trackTrainingGA4Event(product, itemid, true);
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      productid: itemid,
      quantity: itemqty,
      memberid: memberId,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };

  const addRouter = useCallback(
    (redirect_router) =>
      dispatch({
        type: UserActionTypes.REDIRECT_ROUTER,
        payload: {
          redirect_router,
        },
      }),
    [dispatch]
  );

  return (
    <div className="product-item">
      <div className="product-name-container">
        <h3 className="product-title">
          {`${title}`}
          <br />
          <span className="product-subtitle">
            {CEU === 0 ? '' : `${CEU} CEUs`}
          </span>
        </h3>
      </div>
      <div className="second-wrapper">
        {/* <p className="price hide-in-mobile">{price}</p> */}

        {/* {
         product.Variants !== null ?
         product.Variants.map(variant => {
           return <div key={variant.Sku}>variant.Sku</div>
         }) :null
       }
        */}
        {product.Variants !== null
          ? product.Variants.sort((a, b) => {
              if (a && a.StartDate && b && b.StartDate) {
                return new Date(a.StartDate).getTime() >
                  new Date(b.StartDate).getTime()
                  ? 1
                  : -1;
              }
              return 0;
            }).map((variant) => (
              <>
                {variant.SessionTypeName.toLowerCase() === 'ondemand' &&
                title.toString().toLowerCase() === 'ondemand' ? (
                  <div className="location-cart-wrapper">
                    <div className="content-product-wrapper">
                      <div className="locations">
                        <p className="price hide-in-mobile">
                          {variant.VariantPrice}
                        </p>
                        <p className="location">
                          {variant.City}

                          {variant.State ? `, ${variant.State}` : null}
                        </p>

                        <div className="warning-text">
                          <p className="warning-1">Access anytime on demand</p>
                        </div>
                      </div>

                      <div className="price-display-in-mobile">
                        <p className="price">{variant.VariantPrice}</p>
                      </div>
                    </div>
                    <div className="buttons">
                      <CustomButton
                        altColors
                        id={variant.ProductId}
                        onClick={handleAddToShoppingCart}
                      >
                        Add to Cart &gt;
                      </CustomButton>
                      <CustomButton
                        id={variant.ProductId}
                        onClick={handleBuyNowToShoppingCart}
                      >
                        Buy Now &gt;
                      </CustomButton>
                    </div>
                  </div>
                ) : variant.SessionTypeName.toLowerCase() ===
                  title.toString().toLowerCase().replace(/ /g, '') ? (
                  <div className="location-cart-wrapper">
                    <div className="content-product-wrapper">
                      <div className="locations">
                        <p className="price hide-in-mobile">
                          {variant.VariantPrice}
                        </p>
                        <p className="location">
                          {variant.City}

                          {variant.State ? `, ${variant.State}` : null}
                        </p>
                        {variant.SessionTypeName.toLowerCase() !=
                        'coursesubscription' ? (
                          multipleDays ? (
                            <p className="multiple-days mt-1">
                              <span className="date">
                                {changedateformat(variant.StartDate)}{' '}
                                {variant.EndDate &&
                                  !moment(variant.StartDate).isSame(
                                    variant.EndDate,
                                    'day'
                                  ) &&
                                  `- ${changedateformat(variant.EndDate)}`}
                                {variant.StartTime1 && variant.EndTime1 ? (
                                  <p className="time">
                                    {variant.StartTime1} -{variant.EndTime1}{' '}
                                    {variant.TimeZone ? variant.TimeZone : ''}
                                  </p>
                                ) : null}
                                {variant.StartTime2 && variant.EndTime2 ? (
                                  <p className="time">
                                    {variant.StartTime2} -{variant.EndTime2}{' '}
                                    {variant.TimeZone ? variant.TimeZone : ''}
                                  </p>
                                ) : null}
                                {variant.StartTime3 && variant.EndTime3 ? (
                                  <p className="time">
                                    {variant.StartTime3} -{variant.EndTime3}{' '}
                                    {variant.TimeZone ? variant.TimeZone : ''}
                                  </p>
                                ) : null}
                              </span>
                            </p>
                          ) : (
                            <>
                              <p className="date">{date}</p>
                              <p className="time">{time}</p>
                            </>
                          )
                        ) : (
                          ''
                        )}
                        <div className="warning-text">
                          {variant.RegistrationCloseDays > 0 &&
                          variant.RegistrationCloseDays <= 7 ? (
                            <>
                              <p className="warning-1">
                                Registration Closes in
                              </p>
                              <p className="warning-2">
                                {' '}
                                &nbsp;
                                {Math.round(variant.RegistrationCloseDays)}{' '}
                                day(s)!
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="warning-text">
                          {variant.RegistrationCloseHours > 0 ? (
                            <>
                              <p className="warning-1">
                                Registration Closes in
                              </p>
                              <p className="warning-2">
                                {' '}
                                &nbsp;
                                {Math.round(
                                  variant.RegistrationCloseHours
                                )}{' '}
                                hour(s)!
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>

                      <div className="price-display-in-mobile">
                        <p className="price">{variant.VariantPrice}</p>
                      </div>
                    </div>
                    <div className="buttons">
                      <CustomButton
                        altColors
                        id={variant.ProductId}
                        onClick={handleAddToShoppingCart}
                      >
                        Add to Cart &gt;
                      </CustomButton>
                      <CustomButton
                        id={variant.ProductId}
                        onClick={handleBuyNowToShoppingCart}
                      >
                        Buy Now &gt;
                      </CustomButton>
                    </div>
                  </div>
                ) : null}
              </>
            ))
          : null}
      </div>
    </div>
  );
}

export default ProductWebinarItem;
