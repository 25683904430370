import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './software-secondary.styles.scss';

function NewSoftwareServiceLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-software-service-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-software-service-content-component')
  );

  return (
    <div className="software-secondary software">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewSoftwareServiceLandingPage;
