import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class SoftwareResourceService {
  static async getPageWiseSoftwareResource(request) {
    return axios({
      method: 'POST',
      url:
        ENDPOINTS.PROXY +
        ENDPOINTS.SOFTWARE_RESOURCE_FILTER.GET_PAGE_WISE_SOFTWARE_RESOURCE_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: request.PageNumber,
        KeywordorPhrase: request.KeywordorPhrase,
        ModelingCategory: request.ModelingCategory,
        Product: request.Product,
        Services: request.Services,
        Industry: request.Industry,
        Location: request.Location,
        SoftwareResourceType: request.SoftwareResourceType,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getNewPageWiseSoftwareResource(request) {
    return axios({
      method: 'POST',
      url:
        ENDPOINTS.PROXY +
        ENDPOINTS.SOFTWARE_RESOURCE_FILTER
          .GET_NEW_PAGE_WISE_SOFTWARE_RESOURCE_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: request.PageNumber,
        KeywordorPhrase: request.KeywordorPhrase,
        ModelingCategory: request.ModelingCategory,
        Product: request.Product,
        Services: request.Services,
        Industry: request.Industry,
        Location: request.Location,
        SoftwareResourceType: request.SoftwareResourceType,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getSoftwareResourceFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.SOFTWARE_RESOURCE_FILTER.SOFTWARE_RESOURCE_FILTER_DATA,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}
