import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ShoppingCartProgressBar from '../../../components/ecommerce/shopping-cart-progress/shopping-cart-progress.component';

function PaymentHeaderComponent() {
  return (
    <Row>
      <Col xs={12} className="text-content-container">
        <div className="text-content background transparent">
          <div className="inner-wrapper  trinity-container">
            <h1 className="m-0">Shopping Cart</h1>
          </div>
        </div>
      </Col>
      <ShoppingCartProgressBar />
    </Row>
  );
}

export default PaymentHeaderComponent;
