import React from 'react';
// import { contactData, title } from "./job-request.data";
import './job-request.styles.scss';

function JobRequestWidget({ contactData, title, status }) {
  const data = contactData;
  const noContent = 204;
  return (
    <div className="request-container">
      <p className="title">{title}</p>
      {status !== noContent &&
        data.map((item) => (
          <div className="contacts">
            <div className="contact">
              <p className="name">{item.Name}</p>
              <p className="job-title">{item.JobTitle}</p>
              <a href={`tel:${item.PhoneNumber}`} className="phone">
                {item.PhoneNumber}
              </a>
              <a href={`mailto:${item.EmailAddress}`} className="email">
                {item.EmailAddress}
              </a>
            </div>
          </div>
        ))}
    </div>
  );
}

export default JobRequestWidget;
