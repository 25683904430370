import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './TrainingByLocation.scss';
import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';
import Hero from '../commons/hero/hero.component';
import heroImageSrc from '../../images/training/secondary-a-hero.jpg';
import TrainingAdvanceSearchFilterDataListing from '../training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../redux/training/training.action';

function TrainingByLocationStateWise(props) {
  const dispatch = useDispatch();
  const stateWiseMetaInformationData = useSelector(
    ({ stateWiseMetaInformation }) => stateWiseMetaInformation
  );

  const state = { Name: props.stateId, ID: props.stateId };
  const pstate = [];
  const category = { Name: 'Location Specific', ID: 'Location Specific' };
  const pcategory = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: [],
      TrainingCategory: ['Location Specific'],
      TrainingIndustry: [],
      TrainingLocation: [
        props.stateId.toString().replace('-', ' ').toLowerCase(),
      ],
      TrainingDate: [],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(selectTrainingActionFilter(state, pstate, 'Training_Location'));
    dispatch(
      selectTrainingActionFilter(category, pcategory, 'Training_Category')
    );
    submit();
  }, [props.stateId]);

  const getTitleText = (str) => {
    if (!str) return '';

    const splitStr = str.replace('-', ' ').toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  };

  return (
    <>
      {stateWiseMetaInformationData && (
        <Helmet>
          <meta charSet="utf-8" />
          {stateWiseMetaInformationData.MetaDescription && (
            <meta
              name="description"
              content={stateWiseMetaInformationData.MetaDescription}
            />
          )}
          {stateWiseMetaInformationData.MetaTitle && (
            <title>{stateWiseMetaInformationData.MetaTitle}</title>
          )}
        </Helmet>
      )}
      <div className="training-list-table">
        <Hero
          src={heroImageSrc}
          alt={`${getTitleText(props.stateId)} image`}
          mobileImgSrc=""
          title={getTitleText(props.stateId)}
          template="featured"
          search={false}
          description={stateWiseMetaInformationData.MetaDescription}
          colorCategory="train"
        />

        <div className="trinity-container" style={{ marginBottom: '20px' }}>
          <TrainingAdvanceSearchFilterDataListing Product={[]} />
          {/* <TrainingListingWithFilters isCustom={true} Product={trainingCourses.trainingByLocationList}/> */}
        </div>
      </div>
    </>
  );
}

export default TrainingByLocationStateWise;
