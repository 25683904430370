import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './contact-us.validationRules';
import TextInput from '../../form-inputs/text-input/text-input.component';
import { link } from './contact-us.data';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import { submitContactUsResponse } from '../../../../js/api/contactus/_contactus.data';
import './contact-us-form.styles.scss';
import { submitClientDetail } from '../../sitefinity-javascript-sdk/sitefinity-insight.data';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';

function ContactUsForm({ data, mainData, status }) {
  // #RP - Task 46 - Bind data to ContactUs Form in Front-End.
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country] = useState(
    status != noContent && dropDownContent.Country
      ? dropDownContent.Country
      : null
  );
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );

  const handleCheckboxChange = (e) => {
    values.mailingList = e.target.checked;
  };

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitContactUsResponse(
        submissionObj,
        token
      );
      const navigationState = {
        title: 'Thank you for contacting us!',
        subTitle:
          'We will respond within two business days. For immediate assistance, <br/>please call 972 661-8100.',
        instructionMessage: 'Please click on the below button to continue.',
      };

      history.push('/thank-you-page', navigationState);
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      Company: values.companyName.trim(),
      Industry: values.Industry != null ? values.Industry.trim() : 'None',
      Address: values.address.trim(),
      Country: values.Country != null ? values.Country.trim() : 'None',
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      PhoneNumber: values.phone.trim(),
      FaxNumber: values.fax != null ? values.fax.trim() : 'None',
      EmailAddress: values.email.trim(),
      AddMeToTheMailingList:
        values.mailingList != undefined ? values.mailingList : false,
      Comment: values.Comment != null ? values.Comment.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.ContactUs.UniqueId,
      Catalog: ListOfFormUniqueData.ContactUs.Catalog,
    };

    submitData(submissionObj);
    submitClientDetail(submissionObj, mainContent);
  }

  return (
    <div className="contact-us-form-container">
      {/* <a id="btn-touchpoint-persona" class="btn btn-lg btn-info" role="button"
          sfdi-trigger="click" sfdi-predicate="Visit" sfdi-object="Visit Dev documentation page" sfdi-obj-metadata>
          Visit Simulate action
       </a>

       <a id="btn-touchpoint-persona" class="btn btn-lg btn-info" role="button"
          sfdi-trigger="click" sfdi-predicate="click" sfdi-object="Click Dev documentation page" sfdi-obj-metadata>
          Click Simulate action
       </a> */}
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <Row className="contact-container94">
          <Col xs={12} sm={6} className="left">
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />

            {errors.jobTitle && <span className="help">{errors.jobTitle}</span>}
            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
              maxlength={200}
            />
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company Name"
              required
              type="text"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              autoComplete="off"
              className={`${errors.companyName && 'error'}`}
              maxlength={200}
            />
            <SelectList
              defaultText="— Select an Industry —"
              name="Industry"
              onChange={handleChange}
              value={values.industryName}
            >
              {status != noContent &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />
          </Col>
          <Col xs={12} sm={6} className="right">
            <SelectList
              defaultText="— Country —"
              onChange={handleChange}
              name="Country"
              value={values.countryName}
            >
              {status != noContent &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={200}
            />

            <SelectList
              defaultText="— State —"
              name="State"
              onChange={handleChange}
              value={values.stateName}
            >
              {status != noContent &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />

            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />

            <TextInput
              placeholder="Fax"
              name="fax"
              autoComplete="off"
              type="text"
              onChange={handleChange}
              value={values.fax || ''}
              maxlength={100}
            />

            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Company Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={200}
            />
            <div className="checkbox-container">
              <input
                type="checkbox"
                name="mailingList"
                id="mailingList"
                value={values.maillingList}
                onChange={(e) => handleCheckboxChange(e)}
              />
              <label htmlFor="mailingList">Add me to the mailing list?</label>
            </div>

            <textarea
              name="Comment"
              placeholder="Comments"
              onChange={handleChange}
              value={values.Comment}
              maxLength={1000}
            />
          </Col>
          <div className="recaptcha">
            <ReCaptcha />
          </div>
          <div className="link">
            <CustomButton className="heroLink">{link}</CustomButton>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default ContactUsForm;
