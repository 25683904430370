import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { persistor, store } from './redux/store';
import App from './App';
import { SENTRY_KEY } from './js/api/_config';

// TEMPORARY - switching to hashrouter to handle all routing on client side
// => this will need to change back to browserrouter
// => the server will need to handle the intial route

{
  /* RP - Add Sentry Changes */
}
Sentry.init({
  dsn: SENTRY_KEY,
  // autoSessionTracking: true,
  // integrations: [new Integrations.BrowserTracing()],
  // tracesSampleRate: 1.0,
  release: '0.1.0',
});

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter basename="/">
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </BrowserRouter>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);
