import React from 'react';

function ConnectWidgetBox({
  widgetTitle,
  widgetImage,
  widgetContent,
  widgetContentLink,
  widgetDate,
  widgetSubContent,
  widgetText,
  widgetLink,
  alt,
}) {
  return (
    <div>
      <div className="widget-content">
        <div className="widget-text">
          {widgetContent && (
            <a href={widgetContentLink} className="content">
              {widgetContent}
            </a>
          )}
          {widgetDate && <p className="date">{widgetDate}</p>}
          {widgetSubContent && (
            <span className="sub-content">{widgetSubContent}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default ConnectWidgetBox;
