import React, { useEffect, useState } from 'react';

import Testimonials from '../../../components/software/testimonials/testimonials.component';
import Hero from '../../../components/commons/hero/hero.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import SoftwareFilterGrid from './software-filter-grid/software-filter-grid';

function getHeroCtaContent(categoryContent) {
  return categoryContent.map((section) => section.Category);
}

function Software({ page: { MainContent, CategoryContent } }) {
  /* NL: TASK 40 # Software Landing Page */
  const [heroCtas, setHeroCtas] = useState(
    CategoryContent ? getHeroCtaContent(CategoryContent) : null
  );

  if (MainContent) {
    return (
      <div className="software-lp software">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          downArrow
          heroCtas={heroCtas}
          colorCategory="software"
        />
        <div className="sm-up-trinity-container">
          <ContactCta
            Title="Contact us to learn more about our software products"
            subTitle="Contact Software Support"
            subTitleLink="/support-request"
          />
          {CategoryContent ? (
            <SoftwareFilterGrid
              ctas={CategoryContent}
              isFromSoftware
              gridItemWidth="width-33"
              optionTitle="— Select Software Type —"
            />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
        {/* <Testimonials/> */}
      </div>
    );
  }

  return <Loader />;
}

export default Software;
