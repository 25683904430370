import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/about/careers/careers-ava/content-well-link/content-well-link.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import LocationNewsListings from '../../../components/about/indivi-location/location-news-listing/location-news-lisiting';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import LeadershipWidget from '../../../components/about/indivi-location/widgets/local-leadership/leadership.component';
import LocalLinksWidget from '../../../components/about/indivi-location/widgets/local-links/local-links.component';
import IndividualMap from '../../../components/about/indivi-location/indivi-map/map.component';
import './indivi-location.styles.scss';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  resourcesdata,
  trainingcourselocationdata,
} from '../../../redux/widget-box/widget-box.action';

// RP # TASK 100 Individual Location
function IndiviLocation({ page: { MainContent, IndividualLocationContent } }) {
  const dispatch = useDispatch();

  const defaultResponse = {
    type: '',
    payload: [],
    status: true,
  };

  const resources = useSelector(({ widget }) => widget.resources);
  const training = useSelector(({ widget }) => widget.trainingcourselocation);

  const news = defaultResponse;
  const product = defaultResponse;

  useEffect(() => {
    const fetchData = async () => {
      dispatch(await resourcesdata(window.location.pathname, 'location'));
      dispatch(
        await trainingcourselocationdata(window.location.pathname, 'location')
      );
    };
    fetchData();
  }, []);

  if (
    IndividualLocationContent &&
    news !== undefined &&
    news.status &&
    resources !== undefined &&
    resources.status &&
    training !== undefined &&
    training.status &&
    product !== undefined &&
    product.status
  ) {
    return (
      <div className="individual-location-container">
        <Hero
          src={
            IndividualLocationContent.OfficeInformation.HeaderImage
              ? IndividualLocationContent.OfficeInformation.HeaderImage
              : MainContent.HeaderImage_src
          }
          mobileImgSrc={
            IndividualLocationContent.OfficeInformation.HeaderMobileImage
              ? IndividualLocationContent.OfficeInformation.HeaderMobileImage
              : MainContent.HeaderMobileImage_src
          }
          template="standard"
          colorCategory="transparent"
        />

        <Col className="trinity-container">
          <ContentWithSidebar
            isCustom
            left={
              <div>
                <h2>
                  {`${IndividualLocationContent.OfficeInformation.Title}, ${IndividualLocationContent.OfficeInformation.StateName}`}
                </h2>
                <ContentWell
                  content={
                    IndividualLocationContent.OfficeInformation.Content
                      ? IndividualLocationContent.OfficeInformation.Content
                      : ''
                  }
                />
                {IndividualLocationContent &&
                  IndividualLocationContent.OfficeInformation.HideStateNews !==
                    'Yes' &&
                  IndividualLocationContent.NewsData &&
                  IndividualLocationContent.NewsData.length > 0 && (
                    <LocationNewsListings
                      newsTitle="Local News"
                      data={IndividualLocationContent.NewsData}
                      isOffice
                    />
                  )}
              </div>
            }
            right={
              <Row>
                <Col className="left">
                  {IndividualLocationContent.OfficeInformation.Latitude !=
                    undefined &&
                    IndividualLocationContent.OfficeInformation.Latitude !=
                      '' &&
                    IndividualLocationContent.OfficeInformation.Longitude !=
                      undefined &&
                    IndividualLocationContent.OfficeInformation.Longitude !=
                      '' && (
                      <IndividualMap
                        city={
                          IndividualLocationContent.OfficeInformation.City || ''
                        }
                        state={
                          IndividualLocationContent.OfficeInformation
                            .StateName || ''
                        }
                        latitude={
                          IndividualLocationContent.OfficeInformation.Latitude
                            ? IndividualLocationContent.OfficeInformation
                                .Latitude
                            : 0
                        }
                        longitude={
                          IndividualLocationContent.OfficeInformation.Longitude
                            ? IndividualLocationContent.OfficeInformation
                                .Longitude
                            : 0
                        }
                      />
                    )}
                  <br />
                  <LeadershipWidget
                    data={IndividualLocationContent.LocalLeadership}
                  />
                  <WidgetBox
                    relatedTrainingCourseContent={training}
                    relatedResourcesContent={resources}
                    relatedNewsContent={news}
                    relatedProductContent={product}
                    isLocation
                  />
                  {IndividualLocationContent != undefined &&
                    IndividualLocationContent.LocalLinks != undefined &&
                    IndividualLocationContent.LocalLinks.length > 0 && (
                      <LocalLinksWidget
                        data={IndividualLocationContent.LocalLinks}
                      />
                    )}
                </Col>
              </Row>
            }
          />
        </Col>
      </div>
    );
  }
  return <Loader />;
}

export default IndiviLocation;
