import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  serviceBrandEventList,
  serviceBrandNewsList,
  serviceBrandTrainingList,
} from '../../../redux/minnowdata/minnow.action';
import './minnow-page.scss';
import Skeleton from 'react-loading-skeleton';

function NewMinnowLandingPage({ page: { MainContent, breadCrumbList } }) {
  const HeroComponent = React.lazy(() => import('./new-minnow-hero-component'));
  const CategoryComponent = React.lazy(() =>
    import('./new-minnow-category-content-component')
  );
  const EventComponent = React.lazy(() =>
    import('./new-minnow-event-content-component')
  );
  const AuthorComponent = React.lazy(() =>
    import('./new-minnow-author-content-component')
  );
  const NewsComponent = React.lazy(() =>
    import('./new-minnow-news-content-component')
  );

  const newsData = useSelector((state) => state.minnow.newsList?.payload);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceBrandEventList(window.location.pathname));
    dispatch(
      serviceBrandTrainingList(window.location.pathname.replace('/', ''))
    );
    dispatch(serviceBrandNewsList(window.location.pathname, 'minnow'));
  }, []);

  return (
    // KT add class minnow-lp-changed 11-18-2021
    // RK -- CG -- Old designer has added minnow-lp-changed class.So removing it as it was not needed based on ticket from marketing -- 02/21/2022
    <div className="minnow-lp">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>

      <div className="trinity-container">
        <Suspense fallback={<Loader />}>
          <ContactCta
            LeftSideContent={MainContent.LeftSideContent}
            LeftSideLink={MainContent.LeftSideLink}
            LeftSideLinkText={MainContent.LeftSideLinkText}
            CenterContent={MainContent.CenterContent}
            CenterPhoneNumber={MainContent.CenterPhoneNumber}
            data={MainContent}
            RightSideContent={MainContent.RightSideContent}
            RightSidePhoneNumber={MainContent.RightSidePhoneNumber}
          />
        </Suspense>
      </div>

      <div className="trinity-container">
        <p className="title-custom">Services</p>

        <Suspense fallback={<Loader />}>
          <CategoryComponent MainContent={MainContent} />
        </Suspense>
      </div>

      <div className="trinity-container">
        <Suspense fallback={<Loader />}>
          <div className="first-content">
            <p className="title-custom">{MainContent.ContentTitle}</p>
            <div
              className="more-content"
              dangerouslySetInnerHTML={{
                __html: MainContent.Content,
              }}
            />
          </div>
        </Suspense>
      </div>

      <div className="trinity-container">
        <Suspense fallback={<Loader />}>
          <EventComponent MainContent={MainContent} />
        </Suspense>

        {/* KT - Add view all link */}
        {newsData && newsData.length > 0 && (
          <p>
            <span className="title-custom">{'Related News '}</span>
            <span>
              <a href="/news" target="_blank">
                {'View All News >'}
              </a>
            </span>
          </p>
        )}
        {newsData && newsData.length > 0 && (
          <div>
            <div className="">
              <Suspense fallback={<Skeleton height={180} />}>
                <NewsComponent />
              </Suspense>
            </div>
          </div>
        )}

        <Suspense fallback={<Skeleton height={200} />}>
          <AuthorComponent MainContent={MainContent} />
        </Suspense>
      </div>
    </div>
  );
}

export default NewMinnowLandingPage;
