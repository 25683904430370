import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/training_lp_hero.png';
import Map from '../../../components/locations/map/map.component';
import GlobalOfficeDetails from '../../../components/locations/global-partners/global-office-details/office-details.component';
import CountryDetails from '../../../components/locations/locations-secondary/country-details-cta/country-details-cta.component';
import GlobalSearchByFilter from '../../../components/locations/global-partners/global-search-by-filter/search-by-filter.component';
import GlobalRegionTabs from '../../../components/locations/global-partners/global-region-tabs/region-tabs.component';

import './global-partners.styles.scss';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';

// RP #Task 147 Global Partners
function GlobalPartners({ page: { MainContent, LocationContent } }) {
  if (MainContent && LocationContent) {
    return (
      <div className="global-partners-container">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.MobileImage_src}
          alt={MainContent.HeaderImage_alt}
          template="landing"
          downArrow
          title={MainContent.Title}
          description={`${MainContent.HeaderContent}Work with a local authorized partner to easily purchase BREEZE Software`}
          colorCategory="locations"
        />

        <div className="trinity-container">
          <p className="title">{MainContent.SubTitle}</p>
          <ContentWell
            content={
              '<p>The BREEZE Global Alliance Program consists of esteemed businesses and individuals around the world who work closely with us to expand the presence of our BREEZE software and services. We provide key programs that offer business development and joint marketing benefits, including access to new customers, new channel opportunities, co-marketing activities, event co-hosting, and networking opportunities with other Global Partners. The Global Partners market, promote, and sell BREEZE products and services as part of their recommended business solutions or services. These partners may provide strategic implementation services for their customers in support of BREEZE products, or they may contract with us for these services. In addition, these partners refer prospects to us, thus providing value-added products and services to their core clientele. For each of the partners, we offer a program designed to make the relationship a profitable and growing segment of their core business. All members of our Global Alliance Program have ready access to product marketing materials, industry research, and advance upgrade announcements.</p>\n' +
              "<p>If you are interested in joining the BREEZE Global Alliance Program, please contact us at&nbsp;+1 (972) 661-8881&nbsp;or&nbsp;<a href='mailto:breeze@trinityconsultants.com'>breeze@trinityconsultants.com</a>. Similarly, to make a purchase through one of our local partners, please find their contact information below.</p>"
            }
          />
          <br />
          <br />
          {/* <GlobalRegionTabs /> */}
          {/* <GlobalSearchByFilter /> */}
          {/* <CountryDetails country={"United States"} region={"North America"} /> */}
          {LocationContent && (
            <GlobalOfficeDetails
              officeContent={LocationContent.OfficeInformation}
            />
          )}
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default GlobalPartners;
