import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './software-product-detail.styles.scss';

function NewSoftwareProductDetailLandingPage({
  page: { MainContent, SoftwareContent },
}) {
  const ProductDisplay = React.lazy(() =>
    import('./new-software-product-display-component')
  );
  const ProductCarousel = React.lazy(() =>
    import('./new-software-product-carousel-component')
  );
  const IconList = React.lazy(() =>
    import('./new-software-product-iconlist-component')
  );
  const FilterContentwell = React.lazy(() =>
    import('./new-software-product-filter-contentwell-component')
  );
  const FilterSoftwareProduct = React.lazy(() =>
    import('./new-software-product-filter-software-product-component')
  );
  const FilterSoftwareResourceProduct = React.lazy(() =>
    import('./new-software-resources-content-component')
  );
  const FilterVideoCarouselProduct = React.lazy(() =>
    import('./new-software-video-carousel-content-component')
  );
  const SoftwareRequirements = React.lazy(() =>
    import('./new-software-requirement-content-component')
  );
  const SoftwareProductContactus = React.lazy(() =>
    import('./new-software-product-contactus-component')
  );
  const ProductList = React.lazy(() =>
    import('./new-software-product-list-component')
  );
  const TrainingRelated = React.lazy(() =>
    import('./new-software-product-training-related-content-component')
  );

  return (
    <div className="software-product-detail module-spacer software">
      <div className="sm-up-trinity-container">
        <div className="main-product-container">
          <div className="main-content">
            <Suspense fallback={<Loader />}>
              <ProductDisplay
                MainContent={MainContent}
                SoftwareContent={SoftwareContent}
              />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <ProductCarousel MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <IconList MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <FilterContentwell MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <FilterSoftwareProduct MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <FilterSoftwareResourceProduct MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <FilterVideoCarouselProduct MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <SoftwareRequirements MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <TrainingRelated MainContent={MainContent} />
            </Suspense>

            <Suspense fallback={<Loader />}>
              <SoftwareProductContactus />
            </Suspense>
          </div>
          <Suspense fallback={<Loader />}>
            <ProductList MainContent={MainContent} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default NewSoftwareProductDetailLandingPage;
