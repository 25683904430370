import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import './create-account.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function NewCreateAccountLandingPage({ page: { MainContent } }) {
  const ContentComponent = React.lazy(() =>
    import('./new-create-account-content-component')
  );

  return (
    <div>
      {/* KT add class create-account-changed-responsive 11-12-2021 */}
      <Row className="create-account trinity-container create-account-changed-responsive">
        <Suspense fallback={<Loader />}>
          <Col xs={12} sm={8}>
            <ContentComponent MainContent={MainContent} />
          </Col>
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Col xs={12} sm={4} className="sidebar">
            <ContentWell
              className="module-spacer"
              content={MainContent != null ? MainContent.SideContent : ''}
            />
          </Col>
        </Suspense>
      </Row>
    </div>
  );
}

export default NewCreateAccountLandingPage;
