import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { device } from '../misc/DeviceSize';

const PrimaryBackgroundContainer = styled.div((props) => [
  tw`py-20 lg:py-24 bg-primary-500 relative px-10`,
  `
    
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${props.promoCodeDesktopImage});
        
    @media screen and ${device.laptop} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeDesktopImage});
    }
    
    @media screen and ${device.laptopL} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeDesktopImage});
    }
    
    @media screen and ${device.mobileL} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    
    @media screen and ${device.mobileM} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    
    @media screen and ${device.mobileS} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    `,
]);

/*
const Container = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`relative -mx-8 -mt-8 bg-center bg-cover`
]); */

const Row = tw.div`px-8 max-w-screen-lg mx-auto flex items-center relative flex-col lg:flex-row text-center lg:text-left px-10`;
{
  /* KT - remove tailwindcss max width class */
}
const ColumnContainer = tw.div`lg:w-1/2`;

const TextContainer = tw(ColumnContainer)``;
const Text = tw.h5`text-gray-100 text-2xl sm:text-3xl font-bold`;

const ContentWithPaddingXl = tw.div`mx-auto py-10 lg:py-20`;

const Container = styled.div`
  background: #f4f4f4;
  overflowy: 'hidden';
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
`;

const SubText = tw.h5`text-gray-100 sm:text-xl font-normal`;
const SubTextDisclaimer = tw.span`text-gray-100 sm:text-xl lg:text-sm font-hairline`;

function DiscountCode({
  heading = 'Leap Into Training',
  primaryLinkText = 'Contact Us',
  primaryLinkUrl = 'http://google.com',
  content = 'SAVE 29% on any single classroom training course when you register in February',
  disclaimerText = 'Any single classroom training course when you register in February',
  discountCode = 'LEAPYEAR29',
  pushDownFooter = true,
  promoCodeDesktopImage = 'https://i.ibb.co/59QBcHR/Oct-Promo-BAN.jpg',
  promoCodeMobileImage = 'https://i.ibb.co/b2rdqDD/Microsoft-Teams-image.png',
  isFromTraining,
}) {
  return (
    <Container
      onClick={() => window.open('/training')}
      style={{ cursor: 'pointer', backgroundColor: isFromTraining && '#FFF' }}
    >
      {/* KT - Added div for make same as training page start */}
      <div className="sm-up-trinity-container">
        <div className="trinity-container">
          {/* KT - Added div for make same as training page end */}
          <ContentWithPaddingXl>
            <PrimaryBackgroundContainer
              promoCodeDesktopImage={promoCodeDesktopImage}
              promoCodeMobileImage={promoCodeMobileImage}
            >
              <Row>
                <TextContainer>
                  <Text style={{ color: '#F7FAFC' }}>{heading}</Text>
                  <SubText style={{ color: '#F7FAFC' }}>{content}</SubText>

                  <SubTextDisclaimer style={{ color: '#F7FAFC' }}>
                    {disclaimerText}
                  </SubTextDisclaimer>
                </TextContainer>
              </Row>
            </PrimaryBackgroundContainer>
          </ContentWithPaddingXl>
          {/* KT - Added div for make same as training page start */}
        </div>
      </div>
      {/* KT - Added div for make same as training page end */}
    </Container>
  );
}

export default DiscountCode;
