import testImage from '../../../../../images/about/testBioPic.svg';

export const BioData = [
  {
    testImage,
    alt: 'alt',
    name: 'Jay Hoffman',
    title: 'President/CEO',
    phone: '(972) 661-8100',
    emailLink: 'Email',
    email: 'example@gmail.com',
  },
];

export default BioData;
