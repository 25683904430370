import moment from 'moment';
import React, { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../commons/custom-button/custom-button.component';
import './partner-item.styles.scss';

import { trackTrainingGA4Event } from '../../../TrackGoogleAnalytics';
import { addCartItem } from '../../../js/api/shopping-cart/_shopping-cart';
import {
  addSoftwareItemToCart,
  cartCountUpdate,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { UserActionTypes } from '../../../redux/user/user.types';
import { trinityTrainingDateFormatter } from '../../../utilities/CommonUtilities';

// JP #Task 41
function PartnerItem({ title, content, location, date, subContent, product }) {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.tokens.access_token);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const history = useHistory();
  const rlocation = useLocation();

  const changedateformat = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).format('MMMM DD, YYYY');
    }
  };

  const handleAddToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, 1);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
      }
    } else {
      trackTrainingGA4Event(product, event.target.id, false, true);
      const data = {
        productid: event.target.id,
        quantity: 1,
        product: product,
        isTraining: true,
        isPartner: true,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter(rlocation.pathname);
      history.push('/login');
    }
  };

  const handleBuyNowToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, 1);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
        history.push('/shopping-cart');
      }
    } else {
      const data = {
        productid: event.target.id,
        quantity: 1,
        product: product,
        isTraining: true,
        isPartner: true,
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter('/shopping-cart');
      history.push('/login');
    }
  };

  const addItemToCart = async (itemid, itemqty) => {
    trackTrainingGA4Event(product, itemid, true, true);
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      productid: itemid,
      quantity: itemqty,
      memberid: memberId,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };

  const addRouter = useCallback(
    (redirect_router) =>
      dispatch({
        type: UserActionTypes.REDIRECT_ROUTER,
        payload: {
          redirect_router,
        },
      }),
    [dispatch]
  );

  const onClickItemName = (variantInfo) => {
    const newName = variantInfo.Title.replace(/ /g, '-');
    window.open(
      `/training/${variantInfo.ID}/${newName.replace(/[^a-zA-Z ]/g, '-')}`,
      '_blank'
    );
  };

  return (
    <div className="partner-item">
      <div className="partner-name-container">
        <h3 className="title">{title}</h3>
      </div>
      <div className="second-wrapper">
        <p className="content">{content}</p>

        {product && product.RelatedProduct.length > 0
          ? product.RelatedProduct.map((variant) => (
              <>
                <div className="location-cart-wrapper">
                  <div className="locations">
                    <p
                      className="subContent"
                      onClick={() => onClickItemName(variant)}
                    >
                      {variant.Title}
                    </p>

                    <p className="date">
                      {trinityTrainingDateFormatter(variant.StartDate)}
                    </p>
                    <p className="location">
                      {variant.City}
                      {variant.State ? `, ${variant.State}` : null}{' '}
                    </p>
                    {/* {variant.RegistrationCloseDays<=7?(<><div className="warning-text">
                        <p className="warning-1">
                          Hurry Up! Registration is almost closed!
                        </p>
                        <p className="warning-2">{variant.RegistrationCloseDays} Courses Left!</p>
                      </div></>):""} */}
                  </div>
                  <div className="buttons">
                    <CustomButton
                      altColors
                      id={variant.ID}
                      onClick={handleAddToShoppingCart}
                    >
                      Add to Cart &gt;
                    </CustomButton>
                  </div>
                </div>
                {/* {index>=1?<hr />:null} */}
                {/* second item */}
                {/* <hr className='hr' />

                  <hr /> */}
              </>
            ))
          : null}
        {/* <div className='location-cart-wrapper'>
          <div className='locations'>
            <p className='date'>{date}</p>
            <p className='subContent'>{subContent}</p>
          </div>
          <div className='buttons'>
            <CustomButton altColors={true}>Add to Cart &gt;</CustomButton>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default PartnerItem;
