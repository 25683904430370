import axios from 'axios';
import {
  ENDPOINTS,
  SITE_SEARCH_API_KEY,
  SITE_SEARCH_AUTHORITY,
} from '../../js/api/_config';

export class ImpersonateServices {
  static getImpersonateUsers(searchRequestBody) {
    return axios({
      method: 'POST',
      url: `${ENDPOINTS.IMPERSONATE_SEARCH_GENERAL}`,
      headers: {
        'api-key': SITE_SEARCH_API_KEY,
        'content-type': 'application/json',
        authority: SITE_SEARCH_AUTHORITY,
      },
      data: searchRequestBody,
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }
}
