import React from 'react';
import PasswordHelpForm from '../../../components/ecommerce/forms/password-forms/password-help/password-help.component';

function PasswordHelp() {
  return (
    <div className="password-help">
      <PasswordHelpForm />
    </div>
  );
}

export default PasswordHelp;
