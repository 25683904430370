import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';
import userReducer from '../user/user.reducer';

export class ClientServices {
  static getSoftwareDataDownloads(emailAddress) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.CLIENT.GET_SOFTWARE_DATA_DOWNLOADS}/${emailAddress}`,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static queuedData(email, memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.GET_QUEUED_DATA,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        emailaddress: email,
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static completedData(email, memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.GET_COMPLETED_DATA,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        emailaddress: email,
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getOrderHistory(memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.GET_ORDER_HISTORY,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getModelJobStatus() {}

  static getTrainingCertificates(memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.TRAINING_CERTIFICATES,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getWebinars(memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.GET_WEBINAR,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getOldOrderHistory(emailAddress) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.CLIENT.OLD_ORDER_HISTORY}?UserEmailAddress=${emailAddress}`,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getELearningList(memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.PROXY + ENDPOINTS.CLIENT.GET_ELEARNING_DATA,
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        memberid: memberId,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static getBRMSKey(emailAddress, memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.GET_BRMS_KEY,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        emailaddress: emailAddress,
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  // NL:Task172: Download Training Certificate
  static getTrainingCertificateFile(attendeesId, memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.DOWNLOAD_TRAINING_CERTIFICATE,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        id: attendeesId,
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getOrderCertificateFile(orderId, memberId) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.DOWNLOAD_ORDER_RECEIPT,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        orderid: orderId,
        memberid: memberId,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static downloadOldDataCertificate(shoppingCardID) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.CLIENT.SHOPPING_CARD_CERTIFICATE}?ShoppingCartId=${shoppingCardID}`,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getSoftwareAndDataDownloadCertificate(fileName) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.SOFTWARE_AND_DATA_DOWNLOAD,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        SoftwareFileName: fileName,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getOnDemandVideoURL(courseName, webinarType, userToken) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.VIDEO_STORAGE_SERVICES,
      headers: {
        authorization: `Bearer ${userToken}`,
      },
      params: {
        CourseName: courseName,
        WebinarType: webinarType,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getELearningVideoURL(courseName, webinarType, userToken) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.VIDEO_STORAGE_SERVICES,
      headers: {
        authorization: `Bearer ${userToken}`,
      },
      params: {
        CourseName: courseName,
        WebinarType: webinarType,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getBRMSDownload(fileUUID, fileName, userToken) {
    return axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.BRMS_DOWNLOAD,
      headers: {
        authorization: `Bearer ${userToken}`,
      },
      params: {
        fileGuid: fileUUID,
        fileName,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getClientFeedBackCertificate = (data) =>
    axios({
      method: 'GET',
      url: ENDPOINTS.CLIENT.FEEDBACK_CERTIFICATE,
      params: data,
      /* headers: {
                'authorization': "Bearer " + localStorage.getItem("access-token")
            }, */
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));

  static submitMaintenanceExpiredAnalytics = (data, profileuser) =>
    axios({
      method: 'POST',
      url: ENDPOINTS.CLIENT.MAINTENANCE_EXPIRED_ANALYTICS,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
      params: {
        ActivationStatus: data.ActivationStatus,
        CustomerId: data.CustomerId,
        EditionName: data.EditionName,
        EndDate: data.EndDate,
        Released: data.Released,
        Software: data.Software,
        SoftwareFileName: data.SoftwareFileName,
        UserEmail: profileuser,
      },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
}
