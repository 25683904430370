import React, { useEffect } from 'react';
import './training-secondary.styles.scss';
import { useDispatch } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';

// RK Task#104 Training Secondary Change
// JP #Task 41 RK Task#100
function TrainingSecondaryClassroom({
  page: {
    Product,
    MainContent,
    CategoryContent,
    TrainingCategory,
    TrainingSecondaryProductsByType,
    TrainingSecondaryProducts,
    TrainingSelectedCategory,
    TrainingSelectedCategoryId,
  },
}) {
  const dispatch = useDispatch();

  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const format = { Name: 'Classroom', ID: '49' };
  const pformat = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: ['Classroom'],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: [],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(selectTrainingActionFilter(format, pformat, 'Training_Format'));
    submit();
  }, []);

  return (
    <div className="training training-secondary">
      <Hero
        src={
          MainContent.HeaderImage_src
            ? MainContent.HeaderImage_src
            : heroImageSrc
        }
        alt={`${TrainingCategory.Name} image`}
        mobileImgSrc={MainContent?.HeaderMobileImage_src}
        title={TrainingCategory.Name}
        template="standard" // Changed from Landing to Standard
        // search={true}
        // description={TrainingCategory.description} //Need to add category description
        link={heroLink}
        colorCategory="train"
      />
      <div className="trinity-container">
        {/* <TrainingListingWithFilters Product={TrainingSecondaryProducts!=undefined?TrainingSecondaryProducts:TrainingSecondaryProductsByType} TrainingSelectedCat={TrainingCategory} /> */}
        <TrainingAdvanceSearchFilterDataListing Product={Product} />
      </div>
    </div>
  );
}

export default TrainingSecondaryClassroom;
