import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activePageNumber,
  getNewPageWiseResource,
  loadResource,
  removeResourceActionFilter,
} from '../../../../redux/resource/resource.action';
import CloseBtn from '../../../commons/helpers/close-btn/close-btn.component';

function ResourceActiveSearchFilter() {
  const dispatch = useDispatch();
  const pkeyword = useSelector(({ resource }) => resource.keywordFilter);
  const presourceType = useSelector(
    ({ resource }) => resource.resourcetypeFilter
  );
  const pindustry = useSelector(({ resource }) => resource.industryFilter);
  const pservice = useSelector(({ resource }) => resource.serviceFilter);
  const plocation = useSelector(({ resource }) => resource.locationFilter);
  const resourceLoading = useSelector(
    ({ resource }) => resource.resourceLoading
  );

  const [rkeyword, setRKeyword] = useState(false);
  const [rresourceType, setRResourceType] = useState(false);
  const [rindustry, setRIndustry] = useState(false);
  const [rservice, setRService] = useState(false);
  const [rlocation, setRLocation] = useState(false);

  useEffect(() => {
    if (rkeyword) {
      submitSuccess();
      setRKeyword(false);
    } else if (rresourceType) {
      submitSuccess();
      setRResourceType(false);
    } else if (rindustry) {
      submitSuccess();
      setRIndustry(false);
    } else if (rlocation) {
      submitSuccess();
      setRLocation(false);
    } else if (rservice) {
      submitSuccess();
      setRService(false);
    }
  }, [presourceType, pindustry, pservice, plocation, pkeyword]);

  function submitSuccess() {
    const selectresourcetype = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];

    presourceType.map((item) => {
      selectresourcetype.push(item.Name);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    pservice.map((item) => {
      selectservice.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.map((item) => {
      selectkeyword.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      ResourceType: selectresourcetype,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
    };

    dispatch(loadResource(true));
    dispatch(getNewPageWiseResource(submissionObj));
    dispatch(activePageNumber(1));
  }

  const handleResourceTypeClick = (item) => {
    if (!resourceLoading) {
      dispatch(
        removeResourceActionFilter(item, presourceType, 'Resource_Type')
      );
      setRResourceType(true);
    }
  };

  const handleIndustryClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeResourceActionFilter(item, pindustry, 'Industry'));
      setRIndustry(true);
    }
  };

  const handleServiceClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeResourceActionFilter(item, pservice, 'Service'));
      setRService(true);
    }
  };

  const handleLocationClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeResourceActionFilter(item, plocation, 'Location'));
      setRLocation(true);
    }
  };

  const handleKeywordClick = (item) => {
    if (!resourceLoading) {
      dispatch(removeResourceActionFilter(item, pkeyword, 'Keyword'));
      setRKeyword(true);
    }
  };

  return (
    <div className="">
      <h3 className="active-filters-title">Active Search Filters:</h3>
      <ul>
        {presourceType && presourceType.length > 0
          ? presourceType.map((item, i) => (
              <li
                className="list-item bg-tags"
                onClick={() => handleResourceTypeClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pindustry && pindustry.length > 0
          ? pindustry.map((item, i) => (
              <li
                className="list-item bg-news"
                onClick={() => handleIndustryClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pservice && pservice.length > 0
          ? pservice.map((item, i) => (
              <li
                className="list-item bg-industry"
                onClick={() => handleServiceClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {plocation && plocation.length > 0
          ? plocation.map((item, i) => (
              <li
                className="list-item bg-pdate"
                onClick={() => handleLocationClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pkeyword && pkeyword.length > 0
          ? pkeyword.map((item, i) => (
              <li
                className="list-item bg-location"
                onClick={() => handleKeywordClick(item)}
              >
                <span className="flist-item-text">{item.Name} </span>
                {!resourceLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default ResourceActiveSearchFilter;
