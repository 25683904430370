import React, { useEffect, useState } from 'react';
import './footer.styles.scss';

import { Link } from 'react-router-dom';

import Loader from '../helpers/loader/loader.component';

import { ReactComponent as FacebookIcon } from '../../../images/facebook-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../../images/youtube-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../images/linkin-icon.svg';
import { ReactComponent as TwitterIcon } from '../../../images/twitter-icon.svg';
import { ReactComponent as MailIcon } from '../../../images/email-white.svg';

import { ReactComponent as GsaIcon } from '../../../images/footer/gsa-advantage.svg';
import { getFooterItems } from '../../../js/api/_footer';
import { timeStampMinutesDifference } from '../../../js/helpers/_time-stamp-minutes-difference';
import { footerStaticData } from '../../../FooterStaticData';

// waiting on api before making this component completely dynamic
// => not sure how the data will be structured

function Footer() {
  const buildFooterNavItems = (data) =>
    data != undefined
      ? data.map((navItem, i) => (
          <li key={i}>
            <a href={navItem.path} target="_blank" rel="noreferrer">
              {navItem.name} ›
            </a>
          </li>
        ))
      : '';

  const [footerItems, setFooterItems] = useState(footerStaticData);

  useEffect(() => {
    setFooterData();
  }, []);

  const setFooterData = async () => {
    /* const cachedFooterData = JSON.parse(localStorage.getItem("footer"));

        let footerTimeStamp = +localStorage.getItem("footer-time-stamp");
        let currentTimeStamp = Date.now();

        let minutesDifference = timeStampMinutesDifference(
            currentTimeStamp,
            footerTimeStamp
        );

        if (minutesDifference > 30 || !cachedFooterData) {
            const response = await getFooterItems();
            setFooterItems(response);
        } else {
            setFooterItems(cachedFooterData);
            getFooterItems();
        } */
  };

  const year = new Date().getFullYear();

  const socialData = footerItems && footerItems.connectwithus;
  const shareData = footerItems && footerItems.share;

  // Task 133: JC Footer Changes

  if (footerItems) {
    return (
      <footer>
        <div className="footer-content-container trinity-container">
          <div className="footer-menu">
            <div className="col1">
              <ul>
                <li>
                  <Link className="headline" to="/">
                    Services ›
                  </Link>
                </li>
                {buildFooterNavItems(footerItems.services)}
              </ul>
              {/* <div className="footer-empty-line" /> */}
              {/* <ul>
                <li>
                  <Link className="headline" to="/">
                    Speciality Consulting Teams ›
                  </Link>
                </li>
                {buildFooterNavItems(footerItems.SpecialityConsultingTeam)}
              </ul> */}
            </div>
            <div className="expertise-newsletter-container col2">
              <ul>
                <li>
                  <Link className="headline" to="/">
                    Business Areas of Expertise ›
                  </Link>
                </li>
                {buildFooterNavItems(footerItems.expertiseLevel)}
              </ul>
              <div className="newsletter desktop">
                <Link className="" to="/subscribe">
                  Subscribe ›
                </Link>
                <p style={{ marginTop: '10px' }}>
                  <i>To receive relevant, informative thought leadership</i>
                </p>
              </div>
            </div>
            <div className="col3">
              <ul>
                <li>
                  <Link className="headline" to="/">
                    Software ›
                  </Link>
                </li>
                {buildFooterNavItems(footerItems.software)}
              </ul>
            </div>
            <div className="col4">
              <ul>{buildFooterNavItems(footerItems.additional_links)}</ul>
              <div className="footer-empty-line" />
              <ul>{buildFooterNavItems(footerItems.account_separate_links)}</ul>
            </div>
          </div>
          <div className="speciality-container">
            <div className="left">
              <div className="social-text">
                <p className="headline">Connect With Us:</p>

                <a
                  target="_blank"
                  className="footer-links"
                  href={socialData[0].path}
                  rel="noreferrer"
                >
                  <p className="social-text">LinkedIn ›</p>
                </a>

                <a
                  target="_blank"
                  className="footer-links"
                  href={socialData[1].path}
                  rel="noreferrer"
                >
                  <p className="social-text">Facebook ›</p>
                </a>

                <a
                  target="_blank"
                  className="footer-links"
                  href={socialData[2].path}
                  rel="noreferrer"
                >
                  <p className="social-text">Twitter ›</p>
                </a>

                <a
                  target="_blank"
                  className="footer-links"
                  href={socialData[3].path}
                  rel="noreferrer"
                >
                  <p className="social-text">Youtube ›</p>
                </a>
              </div>
            </div>

            <div className="right">
              <GsaIcon className="bottom-bar-icon" />

              <div className="social-icons">
                <p className="headline">Share</p>
                <a target="_blank" href={shareData[0].path} rel="noreferrer">
                  <LinkedinIcon />
                </a>

                <a target="_blank" href={shareData[1].path} rel="noreferrer">
                  <FacebookIcon />
                </a>

                <a target="_blank" href={shareData[2].path} rel="noreferrer">
                  <TwitterIcon />
                </a>

                <a
                  style={{ marginTop: '3px' }}
                  target="_blank"
                  href={shareData[3].path}
                  rel="noreferrer"
                >
                  <MailIcon />
                </a>
              </div>
            </div>

            <div className="newsletter mobile">
              <p>Receive our EHS Quarterly newsletter.</p>
              <button className="subscribe-btn">Subscribe</button>
            </div>
          </div>
        </div>

        <div className="copyright-contact-container">
          <div className="contact-information">
            <p>
              Trinity Consultants, Inc. 12700 Park Central Dr, Ste 600 Dallas,
              TX 75251 +1 800.229.6655 / 972.661.8100
            </p>
          </div>
          {/* RK--update the links and data format */}
          <div className="copyright">
            <p>
              {`© ${year} Trinity Consultants, Inc. All Rights Reserved.`}
              <a className="copyright-links" href="/privacy-policy">
                {' | Privacy Policy'}
              </a>
              <a className="copyright-links" href="/report-problem">
                {' | Having Website Issues?'}
              </a>
            </p>
          </div>
        </div>
      </footer>
    );
  }

  return <Loader />;
}

export default Footer;
