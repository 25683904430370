import React, { useState, useEffect } from 'react';
import './training-listing-with-filters.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import KeywordSearch from "../keyword-search/keyword-search.component";
// import AdvancedSearch from "../advanced-search/advanced-search.component";
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';

import WidgetBox from '../widgetBox/widgetBox-with-button/widget-box.component';
import SidebarWithFilters from '../../commons/sidebar-with-filters/sidebar-with-filters.component';

import {
  getCategoryProducts,
  getProductsByCategory,
  getProductsByCategoryName,
  getGlobalDropdown,
} from '../../../js/api/_training_api';
import useInputCollection from '../../../js/custom-hooks/useInputCollection';
import Loader from '../../commons/helpers/loader/loader.component';
import { productTitle } from '../../software/request-software-quote/request-software-quote.data';

const INITIAL_STATE = {
  KeywordPhrase: '',
  ResouceType: '',
  Industry: '',
  Location: '',
  Softwares: '',
};

// RK Task#104 Training Secondary Change
// JP #Task 41
function TrainingListingWithFilters({
  Product,
  TrainingSelectedCat,
  isCustom,
}) {
  const { state, updateInput } = useInputCollection(INITIAL_STATE);
  const [newsMedia, setNewsMedia] = useState(null);
  const [globalDropDown, setGlobalDropDown] = useState(null);
  const [filterCategory, setfilterCategory] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();

    const submissionObj = {
      Keyword: state.KeywordPhrase,
      Resource: state.ResouceType,
      Software: state.Softwares,
      Industry: state.Industry,
      Location: state.Location,
    };
  }

  return (
    <div className="module-spacer">
      <ContentWithSidebar
        left={
          <Row className="no-gutter-around xs-only-trinity-container">
            <Col>
              <div className="wrapper xs-only-trinity-container">
                <WidgetBox
                  isCustom={isCustom}
                  filterCategory={Product}
                  newsMedia={newsMedia}
                />
              </div>
            </Col>
          </Row>
        }
        right={
          <SidebarWithFilters
            updateInput={updateInput}
            state={state}
            handleSubmit={handleSubmit}
            selectedCategory={TrainingSelectedCat}
          />
        }
      />
    </div>
  );
}

export default TrainingListingWithFilters;
