// setting default content to fill in placeholder data for fields not filled out
function TrainingSecondaryModel({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = 'Aquatic Environmental Monitoring',
    hero_description = '',
    hero_link = {
      src: '',
      text: '',
    },
  },
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_link: {
        src: hero_link.src,
        text: hero_link.text,
      },
    },
  };
}

export default TrainingSecondaryModel;
