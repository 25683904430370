import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import stripHtml from 'string-strip-html';
import HeroWidget from '../../../components/resources-and-media/news-media/hero-widget/hero-widget.component';
import ArticleTags from '../../../components/resources-and-media/news-media/article-tags/article-tags.component';
import SocialLinks from '../../../components/resources-and-media/news-media/social-links/social-links.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import './news-individual-article.styles.scss';
import AuthorDetails from '../../../components/resources-and-media/news-media/author-details/author-details.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { truncatetext } from '../../../js/helpers/_truncate-text';
import { WEBSITE_URL } from '../../../js/api/_config';

// RP #Task 106 News Article
function NewsIndividualArticle({ page: { NewsContent } }) {
  const onClickSocialIcon = (type, newsContent) => {
    const contentLink = `${WEBSITE_URL}/news/${newsContent.Url}`;

    const content = newsContent.Content.toString().replace(/[,.]/g, '');
    if (type === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${contentLink}`,
        '_blank'
      );
    } else if (type === 'linkedin') {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${contentLink}`,
        '_blank'
      );
    } else if (type === 'twitter') {
      window.open(
        `https://twitter.com/intent/tweet?text=${contentLink}`,
        '_blank'
      );
    } else {
      // let emailContent = `mailto:?subject=${newsContent.Title}&body=<html><body><a href='${contentLink}'>google</a></body></html>`
      const emailContent = `mailto:?subject=${newsContent.Title}&body=${contentLink}`;
      window.open(emailContent);
    }
  };

  if (NewsContent && NewsContent.NewsData) {
    return (
      <div className="indiv-container indiviual">
        <div className="trinity-container module-spacer">
          <Row className="top-container">
            {/* on mobile this is supposed to go full width */}
            {/* <Col className={'d-md-none'} xs={12}>
                            <h1 style={{textAlign: "left"}}>{NewsContent ? NewsContent.NewsData[0].Title : ""}</h1>
                            {NewsContent &&
                            <p className="date">
                                {NewsContent ? NewsContent.NewsData[0].strPublishedDate : ""}
                            </p>
                            }
                        </Col> */}
            <Col xs={12} sm={8} md={8} className="pr-sm-3 pr-md-4">
              <h1 style={{ textAlign: 'left' }}>
                {NewsContent ? NewsContent.NewsData[0].Title : ''}
              </h1>
              {NewsContent && (
                <p className="date d-none d-md-block">
                  {NewsContent ? NewsContent.NewsData[0].strPublishedDate : ''}
                </p>
              )}
              <ContentWell
                content={NewsContent ? NewsContent.NewsData[0].Content : ''}
              />
            </Col>
            <Col sm={4} md={4}>
              <SocialLinks
                newsContent={NewsContent.NewsData[0]}
                onClickSocialIcon={onClickSocialIcon}
              />
              {NewsContent && (
                <img
                  src={NewsContent.NewsData[0].ImageUrl}
                  alt=""
                  className="indiv-image"
                />
              )}
            </Col>
          </Row>
          {NewsContent && NewsContent.NewsData[0].AuthorContent.length > 0 && (
            <hr className="first" />
          )}
          <Row className="middle-container">
            <Col sm={6} md={8}>
              {NewsContent ? (
                <Row>
                  {NewsContent.NewsData[0].AuthorContent.length > 0 && (
                    <p className="author-title">
                      About the author
                      {NewsContent.NewsData[0].AuthorContent.length > 1
                        ? 's'
                        : ''}
                    </p>
                  )}
                  {NewsContent.NewsData[0].AuthorContent.map((author) => (
                    <Col xs={12}>
                      <AuthorDetails
                        name={author.Name}
                        phone={author.Phone}
                        city={author.City}
                        state={author.State}
                        jobTitle={author.Title}
                        authorImg={author.ImageUrl}
                        content={truncatetext(author.Content, 500)}
                      />
                    </Col>
                  ))}
                </Row>
              ) : null}
            </Col>
            <Col sm={6} md={4}>
              <HeroWidget
                title="Subscribe for the latest news & information"
                link="Subscribe"
              />
            </Col>
          </Row>
          {NewsContent && NewsContent.NewsData[0].Tags.length > 0 && (
            <hr className="second" />
          )}
          {NewsContent && NewsContent.NewsData[0].Tags.length > 0 && (
            <ArticleTags
              title="Article Tags"
              list={NewsContent.NewsData[0].Tags}
            />
          )}
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default NewsIndividualArticle;
