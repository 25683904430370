import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Hero from '../commons/hero/hero.component';
import Loader from '../commons/helpers/loader/loader.component';
import ContentWell from '../commons/content-well/content-well.component';
import ehsImage from './images/No_Image.png';
import './EHSPublication.scss';
import { Col, Row } from 'react-bootstrap';

function EHSSecondLevel({ page: { MainContent, DownloadTechSheet } }) {
  if (MainContent != undefined && DownloadTechSheet != undefined) {
    return (
      <div>
        <Hero
          src={MainContent && MainContent.HeaderImage_src}
          mobileImgSrc={MainContent && MainContent.HeaderMobileImage_src}
          alt=""
          title={MainContent && MainContent.Title}
          template="standard"
          colorCategory="policies"
        />

        <div
          className="trinity-container module-spacer"
          style={{ paddingBottom: 20 }}
        >
          <Row className="top-container">
            <Col lg={8}>
              <div>
                <ContentWell content={MainContent.Content} />
              </div>
              <div className="button-container">
                {DownloadTechSheet && DownloadTechSheet.Url && (
                  <button
                    onClick={() => window.open(DownloadTechSheet.Url, '_blank')}
                    className="common-button issue-detail-button"
                  >
                    DOWNLOAD (PDF)
                  </button>
                )}
              </div>
            </Col>
            <Col lg={4} sm={4} md={4}>
              <img
                className="indiv-image"
                src={
                  MainContent &&
                  MainContent.EHSQuarterlyIntroImage_src &&
                  MainContent.EHSQuarterlyIntroImage_src.length > 0
                    ? MainContent.EHSQuarterlyIntroImage_src
                    : ehsImage
                }
                alt="TestImage"
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default EHSSecondLevel;
