import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import './edit-profile-form.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import validate from './email-profile.validationRule';
import useForm from '../../../../js/custom-hooks/useForm';
import { profileUpdateUser } from '../../../../js/api/_register-user';
import { getUserProfileRes } from '../../../../js/api/auth/_user-profile';
import { addLoggedinUser } from '../../../../redux/user/user.action';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';
import { CartActionTypes } from '../../../../redux/shopping-cart/shopping-cart.types';

function EditProfileForm({ data, mainData, status }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const normaluser = useSelector(({ user }) => user.loggedin_user);
  const improfileuser = useSelector(({ cart }) =>
    cart.impersonateMember ? cart.impersonateMember.userprofile : null
  );

  const [noContent] = useState(204);
  const [dropDownContent, setDropDownContent] = useState(data);
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );
  const [errorMessage, setErrorMesssage] = useState(null);
  const [Country] = useState(
    status != noContent && dropDownContent.Country
      ? dropDownContent.Country
      : null
  );
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );

  const [userInfo, setUserInfo] = useState(
    improfileuser !== null && improfileuser ? improfileuser : normaluser
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  useEffect(() => {
    values.Address = userInfo.Address;
    values.AddressTwo = userInfo.AddressTwo;
    values.City = userInfo.City;
    values.Company = userInfo.Company;
    values.CountryID = userInfo.CountryID;
    values.Email = userInfo.Email;
    values.FirstName = userInfo.FirstName;
    values.IndustryID = userInfo.IndustryID;
    values.LastName = userInfo.LastName;
    values.PhoneNumber = userInfo.PhoneNumber;
    values.PostalCode = userInfo.PostalCode;
    values.StateID = userInfo.StateID;
    values.Title = userInfo.Title;
    values.Username = userInfo.Username;
    values.UserID = userInfo.UserID;
    setIsLoaded(true);
  }, []);

  const submitData = async (createAccountSubmissionObj) => {
    if (createAccountSubmissionObj != undefined) {
      setSubmitting(true);
      const request = await profileUpdateUser(createAccountSubmissionObj);
      const response = await request;
      setSubmitting(false);
      if (response.error) {
        setErrorMesssage(response.message);
        return;
      }
      const userresponse = await getUserProfile(
        improfileuser && improfileuser.UserID
      );

      if (userresponse) {
        if (userresponse.User) {
          const data = {
            name: `${userresponse.User.FirstName} ${userresponse.User.LastName}`,
            memberId: userresponse.User.UserID,
            userprofile: userresponse.User,
          };

          if (improfileuser) {
            dispatch({
              type: CartActionTypes.UPDATE_IMPERSONATE,
              payload: data,
            });
          } else {
            dispatch(addLoggedinUser(userresponse.User));
          }
        }
      }
      handleNotify(
        'You have suceesfully updated profile.',
        ToasterTypes.Success
      );
      handleCancel();
    }
  };

  function submitSuccess() {
    const createAccountSubmissionObj = {
      user: {
        userid: values.UserID,
        username: values.Username.trim(),
        email: values.Email.trim(),
        firstname: values.FirstName.trim(),
        lastname: values.LastName.trim(),
        title: values.Title.trim(),
        company: values.Company.trim(),
        IndustryID: values.IndustryID != null ? values.IndustryID : '',
        Address: values.Address.trim(),
        AddressTwo: values.AddressTwo != null ? values.AddressTwo.trim() : '',
        PostalCode: values.PostalCode.trim(),
        City: values.City.trim(),
        StateID: values.StateID != null ? values.StateID : '',
        CountryID: values.CountryID != null ? values.CountryID : '',
        PhoneNumber: values.PhoneNumber.trim().replace(/\D+/g, ''),
        FaxNumber: '',
      },
    };

    submitData(createAccountSubmissionObj);
  }

  const getUserProfile = async (memberID) => {
    const getResponse = await getUserProfileRes(memberID);
    return getResponse;
  };

  const handleCancel = async () => {
    history.push('/my-account');
  };

  if (values && isLoaded) {
    return (
      <div className="edit-profile-container">
        {errorMessage && <p className="text error">*{errorMessage}</p>}
        <form>
          <Row className="edit-profile-form">
            <Col xs={12} sm={6} className="left">
              <h2 className="section-name">Contact information</h2>

              <TextInput
                placeholder="User Name"
                label="User Name"
                type="text"
                autoComplete="off"
                name="Username"
                value={values ? values.Username : ''}
                className={`${errors.Username && 'error'}`}
                disabled
                maxlength={100}
              />
              {errors.Username && (
                <span className="help">{errors.Username}</span>
              )}

              <TextInput
                placeholder="First Name"
                label="First Name"
                type="text"
                autoComplete="off"
                className="first-name"
                name="FirstName"
                value={values ? values.FirstName : ''}
                className={`${errors.FirstName && 'error'}`}
                onChange={handleChange}
                maxlength={100}
              />
              {errors.FirstName && (
                <span className="help">{errors.FirstName}</span>
              )}

              <TextInput
                placeholder="Last Name"
                label="Last Name"
                type="text"
                autoComplete="off"
                className={`${errors.LastName && 'error'}`}
                name="LastName"
                value={values ? values.LastName : ''}
                onChange={handleChange}
              />
              {errors.LastName && (
                <span className="help">{errors.LastName}</span>
              )}

              <TextInput
                placeholder="Title"
                label="Title"
                type="text"
                autoComplete="off"
                className={`${errors.Title && 'error'}`}
                name="Title"
                value={values ? values.Title : ''}
                onChange={handleChange}
              />
              {errors.Title && <span className="help">{errors.Title}</span>}

              <TextInput
                placeholder="Email Address"
                label="Email Address"
                type="email"
                autoComplete="off"
                className={`${errors.Email && 'error'}`}
                name="Email"
                value={values ? values.Email : ''}
                onChange={handleChange}
                // readonly="true"
              />
              {errors.Email && <span className="help">{errors.Email}</span>}
              <SelectList
                label="Industry"
                defaultText="— Industry —"
                className="mb-1"
                name="IndustryID"
                onChange={handleChange}
                value={values ? values.IndustryID : ''}
              >
                {Industry &&
                  Industry.map((element, index) => (
                    <option key={element.Name} value={element.Id}>
                      {element.Name}
                    </option>
                  ))}
              </SelectList>

              <TextInput
                placeholder="Company Name"
                label="Company Name"
                type="text"
                autoComplete="off"
                className={`${errors.Company && 'error'}`}
                name="Company"
                value={values ? values.Company : ''}
                onChange={handleChange}
              />
              {errors.Company && <span className="help">{errors.Company}</span>}
            </Col>
            <Col xs={12} sm={6} className="right">
              <h2 className="section-name">Company information</h2>

              <TextInput
                placeholder="Business Address: Line 1"
                label="Business Address: Line 1"
                type="text"
                autoComplete="off"
                className={`${errors.Address && 'error'}`}
                name="Address"
                value={values ? values.Address : ''}
                onChange={handleChange}
              />
              {errors.Address && <span className="help">{errors.Address}</span>}
              <TextInput
                placeholder="Business Address: Line 2"
                label="Business Address: Line 2"
                type="text"
                autoComplete="off"
                className="address-2"
                name="AddressTwo"
                value={values ? values.AddressTwo : ''}
                onChange={handleChange}
              />

              <TextInput
                placeholder="City"
                label="City"
                type="text"
                autoComplete="off"
                className={`${errors.City && 'error'}`}
                name="City"
                value={values ? values.City : ''}
                onChange={handleChange}
              />
              {errors.City && <span className="help">{errors.City}</span>}
              <SelectList
                label="State"
                defaultText="— State —"
                className={`mb-2 ${errors.StateID && 'error'}`}
                name="StateID"
                onChange={handleChange}
                value={values ? values.StateID : ''}
              >
                {State &&
                  State.map((element, index) => (
                    <option key={element.Name} value={element.Id}>
                      {element.Name}
                    </option>
                  ))}
              </SelectList>
              {errors.StateID && <span className="help">{errors.StateID}</span>}

              <SelectList
                className={`mb-2 ${errors.CountryID && 'error'}`}
                label="Country"
                defaultText="— Country —"
                name="CountryID"
                onChange={handleChange}
                required
                value={values ? values.CountryID : ''}
              >
                {Country &&
                  Country.map((element, index) => (
                    <option key={element.Name} value={element.Id}>
                      {element.Name}
                    </option>
                  ))}
              </SelectList>
              {errors.CountryID && (
                <span className="help">{errors.CountryID}</span>
              )}

              <TextInput
                placeholder="Postal Code"
                label="Postal Code"
                autoComplete="off"
                className={`${errors.PostalCode && 'error'}`}
                name="PostalCode"
                value={values ? values.PostalCode : ''}
                onChange={handleChange}
              />
              {errors.PostalCode && (
                <span className="help">{errors.PostalCode}</span>
              )}

              <TextInput
                placeholder="Phone"
                label="Phone"
                type="number"
                autoComplete="off"
                className={`${errors.PhoneNumber && 'error'}`}
                name="PhoneNumber"
                value={values ? values.PhoneNumber : ''}
                onChange={handleChange}
              />
              {errors.PhoneNumber && (
                <span className="help">{errors.PhoneNumber}</span>
              )}
              {/* <div className="label">
                <label htmlFor="ehs-interest">
                  Please indicate which states/provinces are of interest for EHS
                  regulatory news.
              </label>
                <SelectList
                  name="ehs-interest"
                  defaultText="— Texas —"
                  className="mb-2"
                />
              </div> */}
            </Col>
          </Row>
        </form>
        <div className="profile-button-container">
          <Link to="/my-account">
            <CustomButton
              type="submit"
              loading={submitting}
              onClick={handleSubmit}
              className="changes"
            >
              Save Changes
            </CustomButton>
          </Link>

          <CustomButton
            type="button"
            onClick={handleCancel}
            altColors
            className="cancel"
          >
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  }
  return null;
}

export default EditProfileForm;
