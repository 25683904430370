import React, { useState, useEffect } from 'react';
import './widget-box.styles.scss';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formatDateStamp } from '../../../js/helpers/_format-date-stamp';

// JP #Task 41
function WidgetBox({ content, categories }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const changedateformat = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).format('MMMM DD, YYYY');
    }
  };

  const handleCourseClick = (e, categoryName, product) => {
    e.preventDefault();
    localStorage.setItem('prodVariant', JSON.stringify(product));
    history.push(`/training/${categoryName}/${product.Name}`);
  };
  const handleClick = (e, category) => {
    e.preventDefault();
    localStorage.clear('category');

    localStorage.setItem(
      'category',
      JSON.stringify({
        catId: category.ID,
        catName: category.Name,
        catDesc: category.Description,
      })
    );
    history.push(`/training/${category.Name}`);
  };

  function WidgetContent(props) {
    const { CategoryContent } = props.content;
    return (
      <div className="first-wrapper">
        <p className="header-title">Courses by Category</p>
        <p
          className="main-sub-content"
          dangerouslySetInnerHTML={{ __html: CategoryContent }}
        />
      </div>
    );
  }

  function CategoryConent(props) {
    const { category } = props;
    return (
      <>
        <div className="content-wrapper">
          {category.products && category.products.length > 0 ? (
            <div className="first-content">
              <p className="title">{category.Name} ›</p>
              {/* <p>{category.Description}</p> */}
              <p dangerouslySetInnerHTML={{ __html: category.Description }} />
            </div>
          ) : null}

          {category.products && category.products.length > 0
            ? category.products.slice(0, 3).map((product, index) => (
                <React.Fragment key={product.ID}>
                  <div className="widget-wrapper">
                    <div className="left-content">
                      <p className="date">
                        {product.Variants && product.Variants.length > 0
                          ? changedateformat(product.Variants[0].StartDate)
                          : null}
                      </p>
                      <a
                        href={`/training/${category.Name.replace(
                          /\s+/g,
                          '-'
                        )}/${product.Name.replace(/\s+/g, '-')}`}
                        onClick={(e) =>
                          handleCourseClick(e, category.Name, product)
                        }
                        className="inside-link"
                      >
                        {product.Name}
                      </a>
                    </div>
                    <div className="right-content">
                      <p className="location">
                        {product.Variants && product.Variants.length > 0
                          ? product.Variants[0].City
                          : null}{' '}
                        {product.Variants && product.Variants.length > 0
                          ? `${product.Variants[0].State},${product.Variants[0].State}`
                          : null}
                      </p>
                    </div>
                  </div>
                  <hr />
                </React.Fragment>
              ))
            : null}
        </div>
        {category.products && category.products.length > 0 ? (
          <>
            <div className="outer-link-wrapper">
              <a
                href={`/training/${category.Name.replace(/\s+/g, '-')}`}
                onClick={(e) => handleClick(e, category)}
                className="outer-link"
              >
                View More ›
              </a>
            </div>
            <hr className="widget-hr" />{' '}
          </>
        ) : null}
      </>
    );
  }

  return (
    <div className="training-widgetBox-wrapper">
      {content ? <WidgetContent content={content} /> : null}
      {categories !== undefined && categories.length > 0
        ? categories.map((category, i) => (
            <React.Fragment key={category.ID}>
              {category.Name !== 'Industry-Specific' ? (
                <CategoryConent key={i} category={category} />
              ) : null}
            </React.Fragment>
          ))
        : null}

      {content && categories !== undefined && categories.length > 0 ? (
        <div className="first-wrapper">
          <p className="header-title">Courses by Industry</p>
          <p
            className="main-sub-content"
            dangerouslySetInnerHTML={{ __html: content.IndustryContent }}
          />
        </div>
      ) : null}
      {categories !== undefined && categories.length > 0
        ? categories.map((category, i) => (
            <React.Fragment key={category.ID}>
              {category.Name === 'Industry-Specific' ? (
                <CategoryConent key={i} category={category} />
              ) : null}
            </React.Fragment>
          ))
        : null}
    </div>
  );
}

export default withRouter(WidgetBox);
