export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstname) {
    errors.firstname = 'First Name is required';
  }
  if (!values.lastname) {
    errors.lastname = 'Last Name is required';
  }
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.PhoneNumber) {
    errors.PhoneNumber = 'Phone number field - ex: 1234567890';
  }
  if (!values.Address) {
    errors.Address = 'Address is required';
  }
  if (!values.City) {
    errors.City = 'City is required';
  }
  if (!values.PostalCode) {
    errors.PostalCode = 'Postal Code is required';
  }
  if (!values.company) {
    errors.company = 'Company is required';
  }
  // if (!values.username) {
  //     errors.username = "User Name is required";
  // }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  if (!values.confirmpassword) {
    errors.confirmpassword = 'Confirm password is required';
  }

  return errors;
}
