import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewTechnologyLandingPage({ page: { MainContent, breadCrumbList } }) {
  const HeroComponent = React.lazy(() =>
    import('./new-technology-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-technology-content-component')
  );

  return (
    <div className="technology-services-lp technology">
      <Suspense fallback={null}>
        <HeroComponent data={MainContent} breadCrumbList={breadCrumbList} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewTechnologyLandingPage;
