import React from 'react';
import { title } from './leadership.data';
import './leadership.styles.scss';
import NoImageAvailable from '../../../../../images/no-image-available/NoImageAvailable.jpg';
import { compareValues } from '../../../../../utilities/CommonUtilities';

//RP # TASK 100 Individual Location
const LeadershipWidget = ({ data }) => {
  //const data = leaderData;

  return (
    <div className="leadership-container">
      <p className="title">{title}</p>
      {data &&
        data.sort(compareValues('City')).map((item) => (
          <div className="content-wrapper">
            <div className="image">
              {item.ImageUrl.length > 0 ? (
                <img src={item.ImageUrl} style={{ width: '200px' }} alt="" />
              ) : (
                <img
                  src={NoImageAvailable}
                  style={{ width: '200px', alignSelf: 'center' }}
                  alt={'test'}
                />
              )}
            </div>
            <div className="content">
              <p className="name">{item.Name}</p>
              <p className="job_title">{item.Title}</p>
              <p className="company-title">{item.Company}</p>
              <a href={`tel:${item.Phone}`} className="phone">
                P: {item.Phone}
              </a>
              {item.Fax && item.Fax.length > 0 && (
                <a href={item.Fax} className="fax">
                  F: {item.Fax}
                </a>
              )}
              <a href={'mailto:' + item.Email} className="email">
                Email >
              </a>
              <p>
                {item.AddressLine1 != undefined &&
                  item.AddressLine1 != '' &&
                  item.AddressLine1}
                {item.AddressLine1 != undefined &&
                  item.AddressLine1 != '' &&
                  item.AddressLine2 != undefined &&
                  item.AddressLine2 != '' &&
                  ', '}
                {item.AddressLine2 != undefined &&
                  item.AddressLine2 != '' &&
                  item.AddressLine2}
              </p>
              {item.AuthorOfficeUrl ? (
                <a href={item.AuthorOfficeUrl && item.AuthorOfficeUrl}>
                  {item.City && item.City}
                  {item.State && ', ' + item.State} >
                </a>
              ) : (
                <>
                  {item.City && item.City}
                  {item.State && ', ' + item.State}
                </>
              )}
              <br />
              <a href={item.LearnMoreLink} className="custom-a">
                Learn More >
              </a>
            </div>
          </div>
        ))}
    </div>
  );
};

export default LeadershipWidget;
