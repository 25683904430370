import testImage from '../../../../../images/about/testBioPic.svg';

export const title = 'Local Leadership';

export const leaderData = [
  {
    leaderImg: testImage,
    name: 'Mike Remsberg',
    job_title: 'Managing Director,',
    company_title: 'Trinity Consultants',
    address_1: '4950 Pleasant St.',
    address_2: 'West Des Moines, IA 50266',
    phone: '(515) 225-4303',
    fax: '(515) 225-4193',
    email: 'Email Mike ›',
  },
];
