import ServiceBrandedModel from './service-branded-models/service-branded.model';

const serviceBrandedPages = [
  // {
  //   name: "Minnow",
  //   path: "/minnow",
  //   template: "minnow",
  //   modules: ServiceBrandedModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Minnow",
  //       hero_description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit. Malesuada fames ac turpis egestas integer. Tellus mauris a diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Ac odio tempor orci dapibus. Risus in hendrerit gravida rutrum. Ligula ullamcorper malesuada proin libero nunc consequat interdum. Porttitor leo a`,
  //     },
  //   }),
  // },
];

export default JSON.stringify(serviceBrandedPages);
