import React, { useCallback, useEffect, useState } from 'react';
import './minnow-cta-grid.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/* import FilterControls from "./filter-controls/software-filter-controls.component.jsx";
import BoxCta from "../cta/box-cta/box-cta.component"; */

import { setCategoriesToCtas } from '../../../js/helpers/general.functions.js';
import { colorClassMatcher } from '../../../js/helpers/_color-class-matcher';
import { sortCtas } from '../../../js/helpers/_sort-ctas';
import FilterControls from '../../../components/commons/filtered-cta-grid/filter-controls/filter-controls.component';
import BoxCta from './minnow-box-cta-component';

function getCategories(ctas) {
  if (!ctas) return null;

  const allCategories = [];
  ctas.map((item) => {
    allCategories.push(item.Category);
  });
  return allCategories;

  // if (ctas[0].categories)
  //     return ctas.reduce((allCategories, {categories}) => {
  //         categories.forEach((category) => {
  //             allCategories[category.title] = category.id;
  //         });

  //         return allCategories;
  //     }, {});
  // else
  //     return ctas.filter(x => x.SubContent.length > 0).reduce((allCategories, cta) => {
  //         allCategories[cta.Category.Title] = colorClassMatcher(
  //             cta.Category.Section
  //         );
  //         return allCategories;
  //     }, {});
}

function MinnowCtaGrid({
  bgImage,
  ctas,
  onlySmallCtas,
  activeStarterSection,
  optionTitle,
}) {
  // const [allCtas, setAllCtas] = useState([]);
  // const [activeContent, setActiveContent] = useState(null);
  const [categories, setCategories] = useState(
    ctas.length > 0 ? getCategories(ctas) : null
  );

  // stopping function from rerendering select list (this is passed as prop)
  // const filterContent = useCallback(
  //     (e) => {
  //         let activeCategory = e;
  //         if (e.target) activeCategory = e.target.getAttribute("data-category");

  //         if (activeCategory === null) activeCategory = e.target.value;
  //         if (activeCategory === "all") return setActiveContent(allCtas);

  //         const activeCtas = allCtas.filter((cta) => {
  //             return cta.categories[0].id === activeCategory ? true : false;
  //         });

  //         // defaulting to all ctas if no matches
  //         setActiveContent(activeCtas.length ? activeCtas : allCtas);
  //     },
  //     [allCtas]
  // );

  // useEffect(() => {
  //     if (ctas.length > 0) {
  //         let ctasUpdated = setCategoriesToCtas(ctas);
  //         ctasUpdated = sortCtas(ctasUpdated);

  //         setAllCtas(ctasUpdated);
  //     }
  // }, []);

  // useEffect(() => {
  //     if (allCtas.length) {
  //         if (activeStarterSection) {
  //             filterContent(activeStarterSection);
  //         } else {
  //             setActiveContent(allCtas);
  //         }
  //     }
  // }, [allCtas]);

  const backgroundImage = {
    backgroundImage: bgImage ? `url('${bgImage}')` : '',
    backgroundSize: bgImage ? 'cover' : '',
  };

  if (categories != null) {
    return (
      <div
        id="filiteredGrid"
        className={`filtered-cta-grid 
        ${bgImage ? 'bg-img' : 'module-spacer'}
        ${onlySmallCtas ? 'only-small-ctas' : 'standard'}
      `}
      >
        <div style={backgroundImage} aria-hidden="true" className="bg-image" />

        {/* <FilterControls
                    filter={filterContent}
                    categories={categories}
                    title={optionTitle}
                /> */}

        <Row className="filter-row">
          {categories
            .sort((a, b) => a.Title.localeCompare(b.Title))
            .map((cta, i) => (
              <Col
                key={`${cta.title}${i + Math.random() * 10}`}
                className="grid-box-width pr-sm-1 pb-1 pb-sm-1"
              >
                {cta.link ? (
                  <BoxCta
                    title={cta.title}
                    smallBoxCta={!!onlySmallCtas}
                    content={cta.content ? cta.IntroText : null}
                    body={bgImage ? null : cta.description}
                    link={bgImage ? null : cta.link.href}
                    categories={cta.categories}
                    color={i % 2 === 0 ? 'bg-green-dark' : 'bg-green-light'}
                    transparent={bgImage ? true : null}
                  />
                ) : (
                  <BoxCta
                    title={cta.Title}
                    smallBoxCta={!!onlySmallCtas}
                    content={onlySmallCtas ? null : cta.IntroText}
                    body={bgImage ? null : cta.description}
                    link={cta.url}
                    color={i % 2 === 0 ? 'bg-green-dark' : 'bg-green-light'}
                    categories={cta.categories}
                    transparent={bgImage ? true : null}
                  />
                )}
              </Col>
            ))}
        </Row>
      </div>
    );
  }

  return null;
}

export default MinnowCtaGrid;
