import React, { useState, useEffect } from 'react';
import './resource-listing-with-filters.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSelector, useDispatch } from 'react-redux';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import ResourceListing from '../software-resource-listing/resource-listing.component';
import SidebarWithFilters from '../../commons/sidebar-with-filters/sidebar-with-filters.component';
import useInputCollection from '../../../js/custom-hooks/useInputCollection';
import { filterNewsMedia } from '../../../js/api/_news-media';
import Loader from '../../commons/helpers/loader/loader.component';
import SidebarButton from '../../commons/sidebar-with-filters/sidebar-button/sidebar-button.component';
import SoftwareResourceAdvanceFilter from '../software-resource-advance-filter/software-resource-advance-filter.component';
import {
  getPageWiseSoftwareResource,
  loadSoftwareResource,
  activePageNumber,
} from '../../../redux/software-resource/software-resource.action';

// RP #Task 119 Resources
function SoftwareResourceListingWithFilter() {
  const dispatch = useDispatch();
  const pkeyword = useSelector(
    ({ softwareresource }) => softwareresource.keywordFilter
  );
  const pmodelingCategory = useSelector(
    ({ softwareresource }) => softwareresource.modelingcategoryFilter
  );
  const pproduct = useSelector(
    ({ softwareresource }) => softwareresource.productFilter
  );
  const pindustry = useSelector(
    ({ softwareresource }) => softwareresource.industryFilter
  );
  const pservice = useSelector(
    ({ softwareresource }) => softwareresource.serviceFilter
  );
  const plocation = useSelector(
    ({ softwareresource }) => softwareresource.locationFilter
  );
  const psoftwareresourcetype = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourcetype
  );
  const resourceLoading = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourceLoading
  );

  const resourceList = useSelector(
    ({ softwareresource }) => softwareresource.softwareresourceList
  );
  const totalItemsCount = useSelector(
    ({ softwareresource }) => softwareresource.totalItemsCount
  );
  const activePage = useSelector(
    ({ softwareresource }) => softwareresource.pagenumber
  );

  const handlePageChange = (pageNumber) => {
    const selectmodelingcategory = [];
    const selectproduct = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];
    const selectsoftwareresourcetype = [];

    pmodelingCategory.map((item) => {
      selectmodelingcategory.push(item.Id);
    });
    pproduct.map((item) => {
      selectproduct.push(item.Id);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    pservice.map((item) => {
      selectservice.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.map((item) => {
      selectkeyword.push(item.Name);
    });
    psoftwareresourcetype.map((item) => {
      selectsoftwareresourcetype.push(item.Name);
    });

    const submissionObj = {
      PageNumber: pageNumber,
      ModelingCategory: selectmodelingcategory,
      Product: selectproduct,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
      SoftwareResourceType: selectsoftwareresourcetype,
    };

    dispatch(loadSoftwareResource(true));
    dispatch(getPageWiseSoftwareResource(submissionObj));
    dispatch(activePageNumber(pageNumber));
  };

  return (
    <div className="outer-wrapper module-spacer-bottom sm-up-module-spacer-top">
      <Row className="no-gutter-around xs-only-trinity-container">
        <Col xs={12} sm={8}>
          <div className="wrapper">
            <ResourceListing
              resourceContent={resourceList}
              resourceLoading={resourceLoading}
              postsPerPage={15}
              totalItemsCount={totalItemsCount}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
          </div>
        </Col>

        <Col xs={12} sm={4}>
          <SoftwareResourceAdvanceFilter />
          <SidebarButton resourcesText="Resources" resourcesLink="resources" />
        </Col>
      </Row>
    </div>
  );
}

export default SoftwareResourceListingWithFilter;
