import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BioProfile from '../../../../components/about/careers/careers-indiv/bio-profile/bio-profile.component';
import BioSideWidget from '../../../../components/about/careers/careers-indiv/bio-profile/bio-side-widget/side-widget.component';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import { getOperationalLeaderPageData } from '../../../../js/api/about/_operational-leaders.data';
import Hero from '../../../../components/commons/hero/hero.component';
import './indivi-bio.styles.scss';

function IndivBio({ page: { DropDownContent } }) {
  // #RP - Task 89 - Bind data to Individual Bio Form in Front-End.
  if (DropDownContent && DropDownContent.SelectedAuthor != null) {
    return (
      <Row className="indiv-container">
        <Col xs={12} className="text-content-container">
          <div className="text-content background transparent">
            <div className="inner-wrapper  trinity-container">
              <p className="m-0 name">{DropDownContent.SelectedAuthor.Name}</p>
              <p className="title">{DropDownContent.SelectedAuthor.Title}</p>
            </div>
          </div>
        </Col>

        <Col className="trinity-container">
          <Row>
            <Col className="top-container">
              <BioProfile
                selectedAuthor={DropDownContent.SelectedAuthor}
                individualcontent={
                  DropDownContent.SelectedAuthor.IndividualContent
                }
              />
              <Col sm={6} md={4} className="resume-container">
                <BioSideWidget data={DropDownContent} />
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
  return <Loader />;
}

export default IndivBio;
