export const ResourceActionTypes = {
  GET_PAGEWISE_RESOURCE: 'GET_PAGEWISE_RESOURCE',
  TOTAL_ITEM_COUNT: 'TOTAL_ITEM_COUNT',
  RESOURCE_LOADING: 'RESOURCE_LOADING',
  ACTIVE_PAGE_NUMBER: 'ACTIVE_PAGE_NUMBER',
  FILTER_LOADING: 'FILTER_LOADING',
  KEYWORD_FILTER: 'KEYWORD_FILTER',
  RESOURCE_TYPE_FILTER: 'RESOURCE_TYPE_FILTER',
  INDUSTRY_FILTER: 'INDUSTRY_FILTER',
  SERVICE_FILTER: 'SERVICE_FILTER',
  LOCATION_FILTER: 'LOCATION_FILTER',
  GET_TORONTO_CONTENT: 'GET_TORONTO_CONTENT',
};
