import React from 'react';
import './ehs-empty-table.scss';

function EHSEmptyPageComponent(props) {
  return (
    <div className="trinity-container empty-table">
      <div
        style={{
          height: '50vh',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <h2 className="first-line">{props.message}</h2>
      </div>
    </div>
  );
}

export default EHSEmptyPageComponent;
