import React from 'react';
import './loader.styles.scss';
import ContentLoader from 'react-content-loader';

// using a css loader for now
// => may use a gif later
// const Loader = () => (
//     <div style={{height: "300px", position: "relative"}}>
//         <div className={`loader-container module-spacer trinity-container`}>
//             <div className="loader">Loading...</div>
//         </div>
//     </div>
// )

function Loader(props) {
  return (
    <ContentLoader
      viewBox="0 0 100% 100%"
      height="100vh"
      width="100%"
      {...props}
    >
      <rect x="3" y="3" rx="10" ry="10" width="100%" height="100%" />
      <rect x="6" y="190" rx="0" ry="0" width="100%" height="100%" />
      <rect x="4" y="215" rx="0" ry="0" width="100%" height="100%" />
      <rect x="4" y="242" rx="0" ry="0" width="274" height="20" />
    </ContentLoader>
  );
}

export default Loader;
