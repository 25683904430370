import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../commons/custom-button/custom-button.component';
import CheckoutForm from './checkout-form/checkout-form.component';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import './payment.styles.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  cartCountUpdate,
  updateAllowCartStepsAction,
  UpdateCartConfirmParams,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { payofflinePaymentRequest } from '../../../js/api/shopping-cart/_payment-cart';

// Production Key
// const stripePromise = loadStripe('pk_test_AXHtV1KkuXgl0UxBp2ShjIJv00REWi5ONr');

// Staging Key
const stripePromise = loadStripe('pk_live_6iRx3oKq1BN4thbfGgRYLmFZ00WRlSwPKu');
//loadStripe("pk_test_AXHtV1KkuXgl0UxBp2ShjIJv00REWi5ONr");

function Payment({ mainContent }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [hideForm, setHideForm] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const isbackend_user = useSelector(({ user }) => user.isbackend_user);
  const [submitting, setSubmitting] = useState(false);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const cartcategorypama = useSelector(({ cart }) => cart.cartcategorypamas);
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const handleOfflineSubmitClick = async () => {
    setSubmitting(true);

    // process invoice
    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }

    const data = {
      memberid: memberId,
      isbothcategory: cartitems.IsBothCategory,
      isonlytraining: cartitems.IsOnlyTraining,
      isonlysoftware: cartitems.IsOnlySoftware,
    };
    // skip payment and confirm order
    const payOfflineResponse = await payofflinePaymentRequest(data);
    if (payOfflineResponse) {
      if (payOfflineResponse.PaymentSuccess) {
        const categoryparams = {
          isOnlySoftware: cartitems.IsOnlySoftware,
          isOnlyTraining: cartitems.IsOnlyTraining,
          bothCategory: cartitems.IsBothCategory,
          orderConfirmationNumber: payOfflineResponse.OrderConfirmationNumber,
        };
        dispatch(UpdateCartConfirmParams(categoryparams));
        dispatch(cartCountUpdate(1));
        dispatch(updateAllowCartStepsAction(5));
        history.push(`${location.pathname}/order-confirmation`);
      }
    }
    setSubmitting(false);
  };

  const handleOfflineClick = () => {
    setHideForm(false);

    setShowButton(true);
  };

  const handleCardClick = () => {
    setHideForm(true);
    setShowButton(false);
  };

  return (
    <div className="payment-container">
      {isbackend_user ? (
        <div className="payment-inputs">
          <label htmlFor="card">
            <input
              type="radio"
              name="option"
              id="card"
              onClick={handleCardClick}
            />
            Pay Online{' '}
          </label>

          <label htmlFor="pay-offline">
            <input
              type="radio"
              name="option"
              id="pay-offline"
              onClick={handleOfflineClick}
            />
            Pay Offline{' '}
          </label>
        </div>
      ) : null}
      {hideForm ? (
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      ) : null}
      {showButton && (
        <div className="button">
          <CustomButton onClick={handleOfflineSubmitClick} loading={submitting}>
            Complete Order
          </CustomButton>
        </div>
      )}
    </div>
  );
}

export default Payment;
