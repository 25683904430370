import React from 'react';
import { useHistory } from 'react-router-dom';
import './order-fulfill-style.scss';
import '../../components/commons/cta/box-cta/box-cta.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch } from 'react-redux';
import Hero from '../../components/commons/hero/hero.component';
import adminLandingSrc from '../../images/homepage/adminLandingSrc.png';
import CustomButton from '../../components/commons/custom-button/custom-button.component';
import TextInput from '../../components/commons/form-inputs/text-input/text-input.component';
import { getUserOrderFullFillmentData } from '../../redux/order-fulfill/order.action';

function OrderFulfillPage() {
  const [orderGuid, setOrderGuid] = React.useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const onClickSubmitButton = () => {
    console.log(orderGuid);
    dispatch(getUserOrderFullFillmentData(''));
  };

  return (
    <div className="software-lp software">
      <Hero
        src={adminLandingSrc}
        title="Order Fulfill Page"
        template="standard"
        altMobileTitle
      />

      <div className="sm-up-trinity-container">
        <div className="filtered-cta-grid  module-spacer only-small-ctas">
          <Row className="filter-row-custom">
            <Col lg={10} xs={12} md={12} sm={12}>
              <div style={{ marginTop: 5, marginRight: 10 }}>
                <TextInput
                  value={orderGuid}
                  onChange={(e) => setOrderGuid(e.target.value)}
                  placeholder="Order"
                  type="text"
                  autoComplete="off"
                  name="Username"
                  maxlength={100}
                />
              </div>
            </Col>

            <Col lg={2} xs={12} md={12} sm={12}>
              <div>
                <CustomButton onClick={onClickSubmitButton} title="Submit" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default OrderFulfillPage;
