import React, { useState } from 'react';
import './filtered-image-content-well.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// import SectionTitle from "../../software/section-title/section-title.component";

// import { objData } from "./filtered-content.data";
import CustomButton from '../../commons/custom-button/custom-button.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import { BASE_URL } from '../../../js/api/_config';

function FilteredSoftwareProduct({ title, moduledata, heading }) {
  const objData = [];
  moduledata =
    moduledata != undefined && moduledata
      ? moduledata.sort((a, b) => a.SortOrder - b.SortOrder)
      : null;

  if (moduledata) {
    moduledata.forEach((element, index) => {
      const isActive = index === 0;
      objData.push({
        section: element.Title,
        active: isActive,
        content: element.Content,
        brochure: element.Browcher,
        sortOrder: element.SortOrder,
      });
    });
  }
  const [data, setData] = useState(objData);

  function filterContent(activeSection) {
    const newData = data.map((group) => {
      if (activeSection === group.section) {
        group.active = true;
      } else {
        group.active = false;
      }
      return group;
    });

    setData(newData);
  }

  if (data) {
    return (
      <div className="filtered-image-content-well">
        <h2>{heading}</h2>
        {title && <h2>{title}</h2>}
        <SelectList
          className="d-md-none"
          onChange={(e) => filterContent(e.target.value)}
          defaultText="— Topics —"
          label="To learn more about software products"
        >
          {data.map(({ section, active }, index) => (
            <option
              key={index}
              className={`filter
                    ${active ? 'active' : ''}
                `}
              value={section}
            >
              {section}
            </option>
          ))}
        </SelectList>

        <hr className="mobile-seperator d-md-none" />

        <div className="content">
          <div className="title-filters">
            {data.map(({ section, active }, index) => (
              <h3
                key={index}
                onClick={() => filterContent(section)}
                className={`filter ${active ? 'active' : ''}`}
              >
                {section}
              </h3>
            ))}
          </div>
        </div>
        {data.map((group, index) => {
          if (group.active) {
            return (
              <>
                <div
                  key={index}
                  className="content-well"
                  dangerouslySetInnerHTML={{ __html: group.content }}
                />
                {group.brochure && group.brochure.length > 0 && (
                  <CustomButton
                    onClick={() =>
                      window.open(BASE_URL + group.brochure, '_blank')
                    }
                    title="Download Brochure"
                  />
                )}
              </>
            );
          }
        })}
        {/* {
                    data.length > 0 && <CustomButton title={'Download Brochure'}/>
                } */}
      </div>
    );
  }
}

export default FilteredSoftwareProduct;
