import React, { useState, useEffect } from 'react';
import './quantity-picker.styles.scss';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { updateCartItem } from '../../../../redux/shopping-cart/shopping-cart.action';

function QuantityPickerFn({ min, max, id, defaultValue, originalValue }) {
  // const { disableDec, disableInc } = this.state;
  const [disableDec, setdisableDec] = useState(false);
  const [disableInc, setdisableInc] = useState(false);
  const [currentQty, setcurrentQty] = useState(defaultValue);
  const [maxQty, setmaxQty] = useState(max);
  const [minQty, setminQty] = useState(min);
  const [productId, setproductId] = useState(id);
  const [originalQty, setoriginalQty] = useState(originalValue);
  const [isIncrementDecrement, setisIncrementDecrement] = useState(false);
  const [isLoaded, setIsLoaded] = useState(null);

  const dispatch = useDispatch();
  const updatedcartitems = useSelector(
    ({ cart }) => cart.update_cart,
    shallowEqual
  );

  const increment = () => {
    if (minQty === maxQty) {
      setdisableDec(true);
      return;
    }
    const plusState = currentQty + 1;
    if (currentQty < maxQty) {
      setcurrentQty(plusState);
    }
    if (currentQty === maxQty - 1) {
      setdisableInc(true);
    }
    if (currentQty === minQty) {
      setdisableDec(false);
    }
    setisIncrementDecrement(true);
  };

  const decrement = () => {
    if (minQty === maxQty) {
      setdisableInc(true);
      return;
    }
    const minusState = currentQty - 1;
    if (currentQty > minQty) {
      setcurrentQty(minusState);
      if (currentQty === minQty + 1) {
        setdisableDec(true);
      }
    } else {
      setcurrentQty(minQty);
    }
    if (currentQty === maxQty) {
      setdisableInc(false);
    }

    setisIncrementDecrement(true);
  };

  useEffect(() => {
    if (currentQty === minQty) {
      setdisableDec(true);
    }
    if (minQty === maxQty) {
      setdisableDec(true);
      setdisableInc(true);
    }
    if (isIncrementDecrement) {
      if (updatedcartitems && updatedcartitems.length > 0) {
        const hasItem = updatedcartitems.filter(
          (orderline) => orderline.orderlineid === productId
        );
        if (hasItem.length === 0) {
          const updatecart = {
            updated_qty: currentQty,
            old_qty: originalQty,
            orderlineid: productId,
          };
          updatedcartitems.push(updatecart);
          dispatch(updateCartItem(updatedcartitems));
        } else {
          hasItem[0].updated_qty = currentQty;
          dispatch(updateCartItem(updatedcartitems));
        }
      } else {
        const updatecart = {
          updated_qty: currentQty,
          old_qty: originalQty,
          orderlineid: productId,
        };
        updatedcartitems.push(updatecart);
        dispatch(updateCartItem(updatedcartitems));
      }
    }
    setIsLoaded(true);
  }, [currentQty]);

  if (isLoaded) {
    return (
      <span className="quantity-picker">
        <button
          className={`${
            disableDec ? 'mod-disable ' : ''
          }quantity-modifier modifier-left`}
          onClick={decrement}
        >
          &ndash;
        </button>
        <input
          className="quantity-display"
          type="text"
          value={currentQty}
          readOnly
        />
        <button
          className={`${
            disableInc ? 'mod-disable ' : ''
          }quantity-modifier modifier-right`}
          onClick={increment}
        >
          &#xff0b;
        </button>
      </span>
    );
  }
  return null;
}

export default QuantityPickerFn;
