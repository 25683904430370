import React from 'react';
import ResetPasswordForm from '../../../components/ecommerce/forms/password-forms/reset-password/reset-password.component';

function ResetPassword(id) {
  return (
    <div className="pswd-reset">
      <ResetPasswordForm />
    </div>
  );
}

export default ResetPassword;
