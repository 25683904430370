import React, { useState, useReducer, useEffect } from 'react';
import './software-support.styles.scss';
import Parser from 'html-react-parser';
import LibraryResources from './library-listing/resource-listing.component';
import { objData } from './software-support.data';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import ReleaseNotesPage from '../release-notes/release-notes.component';
import FAQs from './faqs/faqs.component';
import Loader from '../../commons/helpers/loader/loader.component';
import { ENDPOINTS } from '../../../js/api/_config';

const initialState = {
  faqs: {
    action: 'faq',
    title: 'FAQs',
    active: true,
  },
  releaseNotes: {
    action: 'release-notes',
    title: 'Release Notes',
    active: false,
  },
  agreements: {
    action: 'agreements',
    title: 'Maintenance and Support Agreements',
    active: false,
  },
  // library: {
  //   action: "library",
  //   title: "Library",
  //   active: false,
  // },
};

const resetState = {
  ...initialState,
  faqs: {
    ...initialState.faqs,
    active: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'faq':
      return {
        ...resetState,
        faqs: {
          ...resetState.faqs,
          active: true,
        },
      };
    case 'release-notes':
      return {
        ...resetState,
        releaseNotes: {
          ...resetState.releaseNotes,
          active: true,
        },
      };
    case 'agreements':
      return {
        ...resetState,
        agreements: {
          ...resetState.agreements,
          active: true,
        },
      };
    // case "library":
    //   return {
    //     ...resetState,
    //     library: {
    //       ...resetState.library,
    //       active: true,
    //     },
    //   };

    default:
      return { ...initialState };
  }
};

function FilteredSoftwareSupport({ isFromMegaMenu }) {
  const [MaintenanceSupportData, setMaintenanceSupportData] =
    React.useState('');
  const [FAQSupportData, setFAQSupportData] = React.useState('');
  const [ReleasenotesSupportData, setReleasenotesSupportData] =
    React.useState('');
  const [MaintenanceMainData, setMaintenanceMainData] = React.useState('');

  const getMaintenanceSupportDataFromAPIs = async () =>
    await fetch(ENDPOINTS.SOFTWARE_SUPPORT_MAINTENANCE_CATEGORY_CONTENT).then(
      (response) => response.json()
    );

  const getFAQSupportDataFromAPIs = async () =>
    await fetch(ENDPOINTS.SOFTWARE_SUPPORT_FAQ_CATEGORY_CONTENT).then(
      (response) => response.json()
    );

  const getReleasenotesSupportDataFromAPIs = async () =>
    await fetch(ENDPOINTS.SOFTWARE_SUPPORT_RELEASENOTES_CATEGORY_CONTENT).then(
      (response) => response.json()
    );

  useEffect(() => {
    getMaintenanceSupportDataFromAPIs().then((e) => {
      setMaintenanceSupportData(e.SoftwareSupportContent.MaintenanceSupport);
      setMaintenanceMainData(e.MainContent);
    });
  }, []);

  useEffect(() => {
    getFAQSupportDataFromAPIs().then((e) => {
      setFAQSupportData(e.SoftwareSupportContent.FAQ);
    });
  }, []);

  useEffect(() => {
    getReleasenotesSupportDataFromAPIs().then((e) => {
      setReleasenotesSupportData(e.SoftwareSupportContent.ReleaseNotes);
    });
  }, []);

  const titles = [
    'FAQs',
    'Release Notes',
    'Maintenance and Support Agreements',
  ];

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    isFromMegaMenu && dispatch({ type: 'release-notes' });
  }, [isFromMegaMenu]);

  if (MaintenanceSupportData && FAQSupportData && ReleasenotesSupportData) {
    return (
      <div className="filtered-support-info">
        <div className="filters">
          <div className="title-filters-select">
            <select
              value={Object.values(state).find((e) => e.active).action}
              onChange={(e) => dispatch({ type: e.target.value })}
            >
              {Object.entries(state).map(([actionTitle, dataObj]) => (
                <option value={dataObj.action}>{dataObj.title}</option>
              ))}
            </select>
          </div>

          <hr className="mobile-seperator d-md-none" />

          <div className="title-filters">
            {Object.entries(state).map(([actionTitle, dataObj]) => (
              <h3
                onClick={() => dispatch({ type: dataObj.action })}
                className={`filter
                                ${dataObj.active ? 'active' : ''}
                            `}
              >
                {dataObj.title}
              </h3>
            ))}
          </div>
        </div>

        <div className="displays">
          {/* {state.agreements.active && <div>{MaintenanceSupportData.map((item, index) => (<p>{item.Title}</p>))}</div>} */}
          {state.agreements.active &&
            MaintenanceMainData &&
            Parser(MaintenanceMainData.Content)}

          {state.faqs.active && FAQSupportData && (
            <FAQs accordionList={FAQSupportData} />
          )}
          {state.releaseNotes.active && ReleasenotesSupportData && (
            <ReleaseNotesPage notes={ReleasenotesSupportData} />
          )}
          {/* {state.library.active && <LibraryResources />} */}
        </div>
      </div>
    );
  }

  return <Loader />;
}

export default FilteredSoftwareSupport;
