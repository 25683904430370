import * as Sentry from '@sentry/react';
import { ENDPOINTS } from '../_config';
import { handleNotify } from '../../helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../helpers/toaster/toaster-types';

const axios = require('axios');

export const addAttendeesToCourse = async (item) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.ADD_ATTENDEE;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
      // "X-Requested-With":"XMLHttpRequest"--removed XMLHTTPRequest
    },
    body: JSON.stringify(item),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      handleNotify('Attendee has been added to course!', ToasterTypes.Success);
      const response = await fetchResponse.json();
      return response;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while adding attendees to course', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while adding attendees to course', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in add attendees');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in add attendees',
      },
    });
    return null;
  }
};

export const getAttendees = async (item) => {
  let cartContentResponse;
  // const auth = "Bearer " + localStorage.getItem("access-token");
  // const location = ENDPOINTS.PROXY + ENDPOINTS.CART.GET_CART_ATTENDEES;

  // const request = await fetch(location, {
  //     method: 'GET',
  //     headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': auth,
  //     },
  //     params: {
  //         memberid: item
  //     }
  // }).then(response => response.json())
  //     .then(data => {
  //         cartContentResponse = data;
  //     })
  //     .catch((error) => {
  //         console.error('getCartItem--Error:', error);
  //     });

  const config = {
    method: 'get',
    url: ENDPOINTS.PROXY + ENDPOINTS.CART.GET_CART_ATTENDEES,
    headers: {
      authorization: `Bearer ${localStorage.getItem('access-token')}`,
    },
    params: {
      memberid: item,
    },
  };
  try {
    const resp = await axios(config);
    cartContentResponse = resp.data;
  } catch (err) {
    return null;
  }
  return cartContentResponse;
};

export const removeAttendeeItem = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.REMOVE_CART_ATTENDEE;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      handleNotify(
        'Attendee has been removed from course.',
        ToasterTypes.Success
      );
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify(
      'Error while removing attendee from course',
      ToasterTypes.Error
    );
    return null;
  } catch (e) {
    handleNotify(
      'Error while removing attendee from course',
      ToasterTypes.Error
    );
    return null;
  }
};

export const updateAttendeeItem = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.UPDATE_CART_ATTENDEE;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      handleNotify(
        'Attendee has been updated to course.',
        ToasterTypes.Success
      );
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while updating attendee to course', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while updating attendee to course', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in update attendees');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in update attendees',
      },
    });
    return null;
  }
};
