import React from 'react';
import './training-secondary.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';

function TrainingSearchResult({ page: { MainContent, Product } }) {
  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const dispatch = useDispatch();

  const pformat = useSelector(
    ({ training }) => training.TrainingFormatFilterSelected
  );
  const pcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterSelected
  );
  const pindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterSelected
  );
  const plocation = useSelector(
    ({ training }) => training.TrainingLocationFilterSelected
  );
  const pdate = useSelector(
    ({ training }) => training.TrainingDateFilterSelected
  );
  const pkeyword = useSelector(
    ({ training }) => training.TrainingKeywordFilterSelected
  );
  const IgnoreDefaultSearch = useSelector(
    ({ training }) => training.IgnoreDefaultSearch
  );

  if (
    IgnoreDefaultSearch == false &&
    pformat.length == 0 &&
    pcategory.length == 0 &&
    pindustry.length == 0 &&
    plocation.length == 0 &&
    pdate.length == 0 &&
    pkeyword.length == 0
  ) {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: [],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: ['Next 30 Days'],
      TrainingKeywords: [],
      IgnoreDefaultSearch,
    };

    dispatch(
      selectTrainingActionFilter(
        { ID: '1', Name: 'Next 30 Days' },
        pdate,
        'Training_Date'
      )
    );
    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  }

  return (
    <div className="training training-secondary">
      <Hero
        src={heroImageSrc}
        alt="Training Result image"
        mobileImgSrc={heroImageSrc}
        title="Training Result"
        template="standard" // Changed from Landing to Standard
        link={heroLink}
        colorCategory="train"
      />
      <div className="trinity-container">
        <TrainingAdvanceSearchFilterDataListing Product={Product} />
      </div>
    </div>
  );
}

export default TrainingSearchResult;
