export const widgetData = [
  {
    date: 'January 3, 2020',
    link: 'EPA Proposes Project Emissions Accounting Rule',
    name: 'Bethany Frink / Amy Morgan',
  },
  {
    date: 'January 3, 2020',
    link: 'EPA Proposes Project Emissions Accounting Rule',
    name: 'Bethany Frink / Amy Morgan',
  },
  {
    date: 'January 3, 2020',
    link: 'EPA Proposes Project Emissions Accounting Rule',
    name: 'Bethany Frink / Amy Morgan',
  },
];

export default widgetData;
