import React from 'react';
import './featured-product.styles.scss';

import { ReactComponent as FeaturedItemThumb } from '../../../images/software/featured-product.svg';

function FeaturedProduct({
  title,
  maxQty,
  RelatedProductLink,
  price,
  description,
  iconurl,
}) {
  return (
    <div className="featured-product">
      <h3 className="featured-title">Featured Related Product</h3>
      <div className="product-information">
        <div className="left">
          {iconurl && (
            <img
              width="53"
              height="53"
              src={iconurl}
              className="featured-item-icon"
            />
          )}
          {!iconurl && <FeaturedItemThumb className="featured-item-icon" />}
          {/* <FeaturedItemThumb className="featured-item-icon" /> */}
          <div className="price-title-container">
            <a href={RelatedProductLink} className="learn-more">
              <h2 className="title">{title}</h2>
            </a>
            <p className="price">${price}</p>
          </div>
        </div>
        {title && (
          <div className="right">
            <div className="description-container">
              <p className="description">{description}</p>
              <a href={RelatedProductLink} className="learn-more">
                Learn more about {title} >
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FeaturedProduct;
