import React from 'react';
import './empty-shopping-cart.scss';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import CustomButton from '../../../commons/custom-button/custom-button.component';

function EmptyShoppingCart() {
  const history = useHistory();

  const onClickTraining = () => {
    history.push('/training');
  };

  const onClickSoftware = () => {
    history.push('/software');
  };

  return (
    <div className="empty-shopping-cart">
      <div className="trinity-container">
        <div className="outer-container">
          <FontAwesomeIcon icon={faShoppingCart} className="smile-icon" />
          <hr />
          <h2 className="outer-font">No Items in Your Cart</h2>
          <span className="light-font">Add items to it now.</span>
          <Row>
            <Col>
              <CustomButton
                title="Go To Training"
                onClick={onClickTraining}
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Col>

            <Col>
              <CustomButton
                title="Go To Software"
                onClick={onClickSoftware}
                style={{ marginTop: 20, marginBottom: 20 }}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default EmptyShoppingCart;
