import React from 'react';
import { tags } from './resource.tags.data';
import './resource-tags.styles.scss';

// RP #Task 119 Resources
function ResourceTags({ tagData }) {
  return (
    <div className="tags-container">
      <ul>
        {tagData &&
          tagData.map((tag) => (
            <li className="list-item-solid list-item" key={tag.Id}>
              {tag.Name}
            </li>
          ))}
      </ul>
    </div>
  );
}

export default ResourceTags;
