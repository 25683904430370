// JP #Task
export const TrainingActionTypes = {
  TRAINING_FORMAT_FILTER_DATA: 'TRAINING_FORMAT_FILTER_DATA',
  TRAINING_CATEGORY_FILTER_DATA: 'TRAINING_CATEGORY_FILTER_DATA',
  TRAINING_INDUSTRY_FILTER_DATA: 'TRAINING_INDUSTRY_FILTER_DATA',
  TRAINING_DATE_FILTER_DATA: 'TRAINING_DATE_FILTER_DATA',
  TRAINING_LOCATION_FILTER_DATA: 'TRAINING_LOCATION_FILTER_DATA',
  TRAINING_REGULATORYAGENCY_FILTER_DATA:
    'TRAINING_REGULATORYAGENCY_FILTER_DATA',

  GET_PAGEWISE_TRAINING: 'GET_PAGEWISE_TRAINING',
  TOTAL_ITEM_COUNT: 'TOTAL_ITEM_COUNT',
  TRAINING_LOADING: 'TRAINING_LOADING',
  ACTIVE_PAGE_NUMBER: 'ACTIVE_PAGE_NUMBER',
  PER_PAGE_COUNT: 'PER_PAGE_COUNT',
  TRAINING_IGNORE_DEFAULT_SEARCH: 'TRAINING_IGNORE_DEFAULT_SEARCH',

  TRAINING_FORMAT_FILTER_SELECTED: 'TRAINING_FORMAT_FILTER_SELECTED',
  TRAINING_CATEGORY_FILTER_SELECTED: 'TRAINING_CATEGORY_FILTER_SELECTED',
  TRAINING_INDUSTRY_FILTER_SELECTED: 'TRAINING_INDUSTRY_FILTER_SELECTED',
  TRAINING_DATE_FILTER_SELECTED: 'TRAINING_DATE_FILTER_SELECTED',
  TRAINING_LOCATION_FILTER_SELECTED: 'TRAINING_LOCATION_FILTER_SELECTED',
  TRAINING_REGULATORYAGENCY_FILTER_SELECTED:
    'TRAINING_REGULATORYAGENCY_FILTER_SELECTED',
  TRAINING_KEYWORD_FILTER_SELECTED: 'TRAINING_KEYWORD_FILTER_SELECTED',
  TRAINING_BUSINESS_UNIT_FILTER_SELECTED:
    'TRAINING_BUSINESS_UNIT_FILTER_SELECTED',
  RESET_TRAINING_FILTER_SET: 'RESET_TRAINING_FILTER_SET',
};
