import { confirmPassword } from './_confirm-password';

export const validateRequiredFields = (fields, requiredFields) => {
  let errorMessage;

  requiredFields.forEach((requiredField) => {
    if (fields[requiredField] === '') {
      errorMessage = 'All required fields must be filled out.';
    }
  });

  // may remove this and put it into another function to keep this one pure
  if (fields.password && fields.confirmpassword) {
    const validPassword = confirmPassword(
      fields.password,
      fields.confirmpassword
    );

    if (!validPassword) {
      errorMessage = 'Passwords must match & be at least 7 characters long.';
    }
  }

  return !errorMessage ? true : errorMessage;
};
