import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '../../js/api/_config';

export class DocumentServices {
  static getDocument() {
    const history = useHistory();
    return axios({
      method: 'GET',
      url: ENDPOINTS.DOWNLOAD_COURSE_CATALOG,
    })
      .then((e) => {
        // temporary download course url
        // window.open(e.data.TrainingCourseCatalogUrl, "_blank")
        window.open(
          'http://resources.trinityconsultants.com/tciwebsiteresources/docs/default-source/trinity-training-resources/education-brochure.pdf',
          '_blank'
        );
        history.push('/');
      })
      .catch((e) => e.response.data);
  }
}
