import LocationsModal from './locations-modals';

const locationsPages = [
  // {
  //   name: "Locations",
  //   path: "/locations",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "locations",
  //   modules: LocationsModal({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Our Locations",
  //     },
  //   }),
  // },
  {
    name: 'Locations Secondary',
    path: '/locations-secondary',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'locationsSecondary',
    modules: LocationsModal({
      hero: {
        hero_src: '',
        hero_title: 'Locations Secondary',
        hero_description: 'hero content goes here',
      },
    }),
  },
  {
    name: 'Training By Location',
    path: '/training-location',

    template: 'TrainingByLocation',
    modules: LocationsModal({
      hero: {
        hero_src: '',
        hero_title: 'Training Location',
        hero_description: 'hero content goes here',
      },
    }),
  },
  {
    name: 'Global Partners',
    path: '/global-partners',

    template: 'GlobalPartners',
    modules: LocationsModal({
      hero: {
        hero_src: '',
        hero_title: 'Global Partners',
        hero_description:
          'BREEZE provides market-leading air dispersion, fire, and explosion modeling software for EHS professionals around the world',
      },
    }),
  },
];

export default JSON.stringify(locationsPages);
