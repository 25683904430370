import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import TrainingByIndustryFilteredCtaGrid from './training-by-industry-filtered-cta-grid';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Hero from '../../../components/commons/hero/hero.component';

function TrainingIndustry({ page: { MainContent, CategoryContent }, section }) {
  if (MainContent) {
    return (
      <div className={`general-container ${section || ''}`}>
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="standard"
          altMobileTitle
          colorCategory="gen"
        />
        <div>
          {CategoryContent ? (
            <TrainingByIndustryFilteredCtaGrid
              ctas={CategoryContent}
              gridItemWidth="width-33"
              onlySmallCtas
              activeStarterSection="industry"
              optionTitle="— Select Software Type —"
            />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              grid not connected
            </h2>
          )}
        </div>
        <div className="trinity-container module-spacer">
          <Col xs={12} lg={10}>
            <ContentWell content={MainContent.Content} />
          </Col>
        </div>
      </div>
    );
  }

  return null;
}

export default TrainingIndustry;
