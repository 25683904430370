import { HomePageServices } from './new-home.services';
import { HomePageActionTypes } from './new-home.types';

export const courseTypeConstant = {
  WEBINAR: 'Webinar',
  SEMINAR: 'Seminar',
  CLASSROOM: 'Classroom',
};

export const getHomePageData = () => (dispatch, getState) => {
  HomePageServices.getHomePageData().then((response) => {
    if (response) {
      localStorage.setItem('homePageData', JSON.stringify(response));
      localStorage.setItem('homePageTimeStamp', Date.now());
      dispatch({
        type: HomePageActionTypes.GET_HOME_PAGE_DATA,
        payload: response,
      });
    }
  });
};

export const getHomeTrainingData = () => (dispatch, getState) => {
  HomePageServices.getHomeTrainingData().then((response) => {
    if (response) {
      localStorage.setItem('homeTrainingData', JSON.stringify(response));
      localStorage.setItem('homeTrainingTimeStamp', Date.now());
      dispatch({
        type: HomePageActionTypes.GET_HOME_TRAINING_DATA,
        payload: response,
      });
    }
  });
};

export const getCommonCoursesData = (type) => (dispatch, getState) => {
  let data = {};

  if (type === courseTypeConstant.WEBINAR) {
    data = {
      PageNumber: 1,
      TrainingFormat: [courseTypeConstant.WEBINAR],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingDate: [],
      TrainingLocation: [],
      TrainingKeywords: [],
    };
  } else if (type === courseTypeConstant.SEMINAR) {
    data = {
      PageNumber: 1,
      TrainingFormat: [courseTypeConstant.SEMINAR],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingDate: [],
      TrainingLocation: [],
      TrainingKeywords: [],
    };
  } else {
    data = {
      PageNumber: 1,
      TrainingFormat: [courseTypeConstant.CLASSROOM],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingDate: [],
      TrainingLocation: [],
      TrainingKeywords: [],
    };
  }

  HomePageServices.getCommonCoursesData(data).then((response) => {
    if (response) {
      if (type === courseTypeConstant.CLASSROOM) {
        dispatch({
          type: HomePageActionTypes.CLASSROOM_HOME_PAGE_DATA,
          payload: response,
        });
      } else if (type === courseTypeConstant.WEBINAR) {
        dispatch({
          type: HomePageActionTypes.WEBINAR_HOME_PAGE_DATA,
          payload: response,
        });
      } else {
        dispatch({
          type: HomePageActionTypes.SEMINAR_HOME_PAGE_DATA,
          payload: response,
        });
      }
    }
  });
};
