import React from 'react';
import './SafeBridgeSecondLevel.scss';
import * as axios from 'axios';
import { trinityTrainingDateFormatter } from '../../../utilities/CommonUtilities';
import { ENDPOINTS } from '../../../js/api/_config';

function SafeBridgeContentComponent({ responseData }) {
  const rowComponent = (title, content) => (
    <div className="safe-bridge-row">
      <span className="safe-bridge-sub-heading">{title}</span>
      <br />
      <h7 className="safe-bridge-sub-heading-content">{content}</h7>
    </div>
  );

  // TODO Analytical Methods
  const onClickDownloadPDF = () => {
    const url =
      responseData.url.split('/')[responseData.url.split('/').length - 1];
    axios(ENDPOINTS.SAFE_BRIDGE_SEARCH.SAFEBRIDGE_PDF_CONTENT + url, {
      method: 'GET',
      responseType: 'application/json',
      headers: {
        authorization: localStorage.getItem('userToken'),
      },
    })
      .then((response) => {
        const tdata = `data:application/pdf;base64,${response.data.FileByte}`;
        const blob = base64toBlob(tdata);
        const url = URL.createObjectURL(blob);
        window.open(url);
      })
      .catch((error) => {});
  };

  const base64toBlob = (data) => {
    const base64WithoutPrefix = data.substr(
      'data:application/pdf;base64,'.length
    );

    const bytes = window.atob(base64WithoutPrefix);
    let { length } = bytes;
    const out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  const onClickViewPDF = () => {
    const url =
      responseData.url.split('/')[responseData.url.split('/').length - 1];
    axios(ENDPOINTS.SAFE_BRIDGE_SEARCH.SAFEBRIDGE_PDF_CONTENT + url, {
      method: 'GET',
      responseType: 'application/json',
      headers: {
        authorization: localStorage.getItem('userToken'),
      },
    })
      .then((response) => {
        const tdata = `data:application/pdf;base64,${response.data.FileByte}`;
        const blob = base64toBlob(tdata);
        const url = URL.createObjectURL(blob);
        print(url);
      })
      .catch((error) => {});
  };

  const print = (blobUrl) => {
    const newWindow = window.open('', '', 'width=100, height=100');

    if (newWindow) {
      const document = newWindow.document.open();
      document.close();
      newWindow.moveTo(0, 0);
      newWindow.resizeTo(window.screen.width, window.screen.height);
      // const contentType = 'application/pdf';
      // const b64Data = base64Data;
      // const blob = this.b64toBlob(b64Data, contentType);
      // const blobUrl = URL.createObjectURL(blob);

      document.write(`<iframe id="pdfDocument"
          src="${blobUrl}" 
          width="100%"
          height="100%"
          frameBorder="0" ></iframe>`);

      const docs = document.getElementById('pdfDocument');

      setTimeout(() => {
        docs.contentWindow.print();
      }, 1000);
    }
  };

  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < 768;

  return (
    <div>
      {/* <hr /> */}
      <div className="row">
        {isMobile && (
          <div className="col-md-2">
            <div className="row safe-bridge-heading">
              {/* <span className={"safe-bridge-top-heading"}>{""}</span> */}
              <div>
                <button
                  className="safe-bridge-print-button-style safe-bridge-tooltip"
                  style={{ marginRight: 5 }}
                  onClick={onClickDownloadPDF}
                >
                  {/* <span className="safe-bridge-tooltiptext">Download</span> */}
                  <i
                    className="fa fa-download"
                    style={{ fontSize: '20px' }}
                    aria-hidden="true"
                  />
                </button>

                <button
                  className="safe-bridge-print-button-style safe-bridge-tooltip"
                  onClick={onClickViewPDF}
                >
                  {/* <span className="safe-bridge-tooltiptext">Print</span> */}
                  <i
                    className="fa fa-print"
                    style={{ fontSize: '20px' }}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="col-md-5">
          {responseData &&
            responseData.NameOfCompound &&
            rowComponent('Name(s) Of Compound', responseData?.NameOfCompound)}
          {responseData &&
            responseData.MethodReportNumber &&
            rowComponent(
              'Method Report Number',
              responseData?.MethodReportNumber
            )}
          {responseData &&
            responseData.SwabbingMedia &&
            rowComponent('Sampling Media', responseData?.SwabbingMedia)}
          {responseData &&
            responseData.SwabbingSolution &&
            rowComponent(
              'Recommended Minimum Sampling Volume',
              responseData?.SwabbingSolution
            )}
          {responseData &&
            responseData.RecommendedMaximumSamplingVolume &&
            rowComponent(
              'Recommended Maximum Sampling Volume',
              responseData?.RecommendedMaximumSamplingVolume
            )}
          {responseData &&
            responseData.StorageConditions &&
            rowComponent('Storage Conditions', responseData?.StorageConditions)}
          {responseData &&
            responseData.ShippingRequirements &&
            rowComponent(
              'Shipping Requirements',
              responseData?.ShippingRequirements
            )}
          {responseData &&
            responseData.MethodValidatedforAirVolume &&
            rowComponent(
              'Method Validated for Air Volume',
              responseData?.MethodValidatedforAirVolume
            )}
          {responseData &&
            responseData.SwabbingMedia &&
            rowComponent('Swabbing Media', responseData?.SwabbingMedia)}
          {responseData &&
            responseData.DateIssued &&
            rowComponent(
              'Date Issued',
              trinityTrainingDateFormatter(responseData?.DateIssued)
            )}
        </div>

        <div className="col-md-5">
          {responseData &&
            responseData.CASNumber &&
            rowComponent('CAS #', responseData?.CASNumber)}
          {responseData &&
            responseData.AnalyticalMethod &&
            rowComponent('Analytical Method', responseData?.AnalyticalMethod)}
          {responseData &&
            responseData.Title &&
            rowComponent('Sampling Rate', responseData?.Title)}
          {responseData &&
            responseData.QuantificationLimit &&
            rowComponent(
              'Quantification Limit',
              responseData?.QuantificationLimit
            )}
          {responseData &&
            responseData.ShippingRequirements &&
            rowComponent(
              'Shipping Requirements',
              responseData?.ShippingRequirements
            )}
          {responseData &&
            responseData.SwabbingSolution &&
            rowComponent('Swabbing Solution', responseData?.SwabbingSolution)}
          {responseData &&
            responseData.SuggestedAirSamplingFilterHandlingTechniques &&
            rowComponent(
              'Suggested Air Sampling Filter Handling Techniques',
              responseData?.SuggestedAirSamplingFilterHandlingTechniques
            )}
          {responseData &&
            responseData.DetectionLimitofAnalyticalMethod &&
            rowComponent(
              'Detection Limit of Analytical Method',
              responseData?.DetectionLimitofAnalyticalMethod
            )}
          {responseData &&
            responseData.RelatedDocument &&
            rowComponent('Related Document', responseData?.RelatedDocument)}
          {responseData &&
            responseData.AverageRecoveryfrom &&
            rowComponent(
              'Average Recovery from',
              responseData?.AverageRecoveryfrom
            )}
        </div>

        {!isMobile && (
          <div className="col-md-2">
            <div className="row safe-bridge-heading">
              {/* <span className={"safe-bridge-top-heading"}>{""}</span> */}
              <div>
                <button
                  className="safe-bridge-print-button-style safe-bridge-tooltip"
                  style={{ marginRight: 5 }}
                  onClick={onClickDownloadPDF}
                >
                  {/* <span className="safe-bridge-tooltiptext">Download</span> */}
                  <i
                    className="fa fa-download"
                    style={{ fontSize: '20px' }}
                    aria-hidden="true"
                  />
                </button>

                <button
                  className="safe-bridge-print-button-style safe-bridge-tooltip"
                  onClick={onClickViewPDF}
                >
                  {/* <span className="safe-bridge-tooltiptext">Print</span> */}
                  <i
                    className="fa fa-print"
                    style={{ fontSize: '20px' }}
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SafeBridgeContentComponent;
