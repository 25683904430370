import React, { useEffect } from 'react';
import './admin-news-media.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Hero from '../../components/commons/hero/hero.component';
import {
  getAdminPageWiseNews,
  getNewsPageData,
  loadFilter,
  loadNews,
} from '../../redux/news/news.action';
import Loader from '../../components/commons/helpers/loader/loader.component';
import AdminNewsListingWithFilters from './AdminNewsListingWithFilters';

function AdminNewsMedia() {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectlocation = [];
  const newsFilter = useSelector(({ news }) => news.filterList);
  const newsPageData = useSelector(({ news }) => news.newsPageData);
  const plocation = useSelector(({ news }) => news.locationFilter);
  plocation.map((item) => {
    selectlocation.push(item.Id);
  });

  const isAdminUser = useSelector(({ user }) => user.isbackend_user);

  const newsData = {
    PageNumber: 1,
    Tags: '',
    NewsCategory: '',
    IndustryCategory: '',
    Location: selectlocation,
    PublishedDate: '',
  };

  useEffect(() => {
    if (!isAdminUser) {
      history.push('/admin/login');
    }
    dispatch(loadNews(true));
    dispatch(getAdminPageWiseNews(newsData));
    dispatch(getNewsPageData());
    dispatch(loadFilter());
  }, []);

  if (newsFilter && newsFilter.responseStatus) {
    return (
      <div className="news news-lp">
        <Hero
          src={newsPageData.HeaderImage_src}
          mobileImgSrc={newsPageData.HeaderMobileImage_src}
          alt={newsPageData.HeaderImage_alt}
          template="standard"
          title="Admin News"
        />
        <div className="sm-up-trinity-container">
          <AdminNewsListingWithFilters />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default AdminNewsMedia;
