import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ConfirmationSidebar from '../../../components/ecommerce/order-confirmation/confirmation-sidebar/confirmation-sidebar.component';
import ShoppingCartProgressBar from '../../../components/ecommerce/shopping-cart-progress/shopping-cart-progress.component';
import './order-confirmation.styles.scss';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import { ENDPOINTS } from '../../../js/api/_config';
import CartLoader from '../CartLoader';

function OrderConfirmationPage() {
  // const [orderConfirmationData, setOrderConfirmationData] = useState(null);

  // useEffect(() => {
  //     async function getDataFromURL() {
  //         let response = await getPageDataByApiUrl(ENDPOINTS.USER_CART_ACCOUNT.ORDER_CONFIRMATION);
  //         console.log(response);
  //         return response;
  //     }

  //     getDataFromURL().then(response => {
  //         setOrderConfirmationData(response)
  //     });

  // }, []);

  // if (!orderConfirmationData) {
  //     return <CartLoader />
  // }

  return (
    <Row className="order-confirmation-page trinity-container">
      <Col xs={12} className="text-content-container">
        <div className="text-content background transparent">
          <div className="inner-wrapper  trinity-container">
            <h1 className="m-0">Order Confirmation</h1>
          </div>
        </div>
      </Col>

      <Col xs={12}>
        <ShoppingCartProgressBar />
      </Col>

      <div className="sidebar xs-only-trinity-container">
        <ConfirmationSidebar />
      </div>
    </Row>
  );
}

export default OrderConfirmationPage;
