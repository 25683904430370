import React from 'react';

import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';
// import { boxCtaData } from "../industry-landing/industries.data";

// import heroImageSrc from "../../../images/industries/industries1_hero.png";

function IndustrialB({ page: { MainContent, CategoryContent }, section }) {
  if (MainContent) {
    return (
      <div className="industrial-secondary-b">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="landing"
          downArrow
          colorCategory={section}
        />
        <div className="sm-up-trinity-container module-spacer">
          {/* The filtered content well was removed && replaced by a standard content well */}
          {/* This was requested after an event tracking meeting */}
          <Col className="xs-only-trinity-container" md={10}>
            {/* <ContentWell
            content={MainContent.IntroText}
          /> */}
          </Col>

          {CategoryContent ? (
            <FilteredCtaGrid
              activeStarterSection={section}
              ctas={CategoryContent}
              onlySmallCtas
            />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default IndustrialB;
