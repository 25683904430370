import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Pagination from 'react-js-pagination';
import Loader from '../../components/commons/helpers/loader/loader.component';
import Hero from '../../components/commons/hero/hero.component';
import './ResourcesDocuments.styles.scss';
import ResourcesWidgetBox from './ResourcesWidgetBox';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTorontoContent } from '../../redux/resource/resource.action';

function ResourcesDocuments() {
  const dispatch = useDispatch();

  const torontoContent = useSelector(({ resource }) => resource.torontoContent);

  useEffect(() => {
    dispatch(getAllTorontoContent());
  }, []);

  if (torontoContent.dataLoaded) {
    return (
      <div className="trade-shows trade">
        <Hero
          src="https://trinityprod.trinityconsultants.com/images/default-source/siteassets/resources/resources_l1desktop.jpg?sfvrsn=d769878_2"
          mobileImgSrc="https://trinityprod.trinityconsultants.com/images/default-source/siteassets/resources/resources_l1desktop.jpg?sfvrsn=d769878_2"
          alt="Resources Documents"
          title="Toronto Files"
          template="standard"
        />
        {torontoContent &&
          torontoContent.data &&
          torontoContent.data.length > 0 && (
            <div className="trinity-container main-content">
              <Row>
                <div className="wrapper">
                  <ResourcesWidgetBox data={torontoContent.data} />
                </div>
              </Row>
            </div>
          )}
      </div>
    );
  }
  return <Loader />;
}

export default ResourcesDocuments;
