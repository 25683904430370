import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../commons/custom-button/custom-button.component';

import './reset-successful.styles.scss';

function ResetSuccessful() {
  return (
    <div className="reset-successful module-spacer">
      <div className="form-container background action-item">
        <h2>Your New Password is Set!</h2>
        <p className="blurb">
          Your new password has been successfully updated in our system. Click
          continue to proceed to sign in.
        </p>

        <Link to="/user-login">
          <CustomButton>Continue</CustomButton>
        </Link>
      </div>
    </div>
  );
}

export default ResetSuccessful;
