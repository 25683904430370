import React from 'react';
import { FormWidgetData } from './subscribe-form-widget.data';
import './subscribe-form-widget.styles.scss';

// RP Task 62 - Subscribe form
function SubscribeFormWidget({ item }) {
  return (
    <div className="outer-widget-container">
      <div className="form-widget-container">
        <p
          className="title"
          dangerouslySetInnerHTML={{ __html: item.SideTitle1 }}
        />
        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: item.SideContent1 }}
        />
      </div>
      <div className="form-widget-container">
        <p
          className="title"
          dangerouslySetInnerHTML={{ __html: item.SideTitle2 }}
        />
        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: item.SideContent2 }}
        />
      </div>
    </div>
  );
}

export default SubscribeFormWidget;
