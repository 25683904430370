import React from 'react';
import './cta-grid.styles.scss';
import Row from 'react-bootstrap/Row';
import { boxCtaData, title } from './cta.data';

function ExecGrid({ data, mainData, status, url }) {
  // #RP - Task - Bind data to Executive Commitee Form in Front-End.
  const noContent = 204;
  const mainTitle =
    status != noContent && mainData != null ? mainData.Title : '';
  const commiteeData = status != noContent && data != null ? data.Author : '';
  const routeUrl = `${url}/individual-bio`;

  return (
    <div className="box-container">
      <p className="title">{mainTitle}</p>
      <div className="ctas">
        {commiteeData &&
          commiteeData.map((item, i) => (
            <div
              key={i}
              className="bkImg"
              style={{ backgroundImage: `url(${item.ImageUrl})` }}
            >
              <div className="content">
                {item.Name && (
                  <p className="name">
                    <a href={routeUrl + item.Link}>{item.Name}</a>
                  </p>
                )}
                {item.Title && (
                  <p className="job_title">
                    {item.Title}
                    {item != '' && item.Company != ''
                      ? `, ${item.Company}`
                      : ''}
                  </p>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ExecGrid;
