import React, { useState, useEffect } from 'react';
import FilterControls from '../../commons/filtered-cta-grid/filter-controls/filter-controls.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import SearchInput from '../../commons/form-inputs/search-input/search-input.component';
import { dates } from '../../../pages/training/training-lp/dates.data';

import './search-widget.styles.scss';
import { getLocations } from '../../../js/api/dropdowns/_get-locations';
import { getGlobalIndustries } from '../../../js/api/dropdowns/_get-industries';
import { getResourceTypes } from '../../../js/api/dropdowns/_get-resource-type';
import { getSoftwares } from '../../../js/api/dropdowns/_get-softwares';
import { getCategories } from '../../../js/api/_training_api';

// JP #Task 41
function SearchWidget({ title }) {
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [resources, setResources] = useState([]);
  const [softwares, setSoftwares] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await getLocations();
      if (response) setLocations(response);
    };

    const fetchIndustries = async () => {
      const response = await getGlobalIndustries();
      if (response) setIndustries(response);
    };

    const fetchResources = async () => {
      const response = await getResourceTypes();
      if (response) setResources(response);
    };

    const fetchSoftwares = async () => {
      const response = await getSoftwares();
      if (response) setSoftwares(response);
    };

    const fetchCategories = async () => {
      const response = await getCategories();
      if (response) setCategories(response);
    };

    fetchCategories();
    // fetchSoftwares();
    fetchResources();
    fetchIndustries();
    fetchLocations();
  }, []);

  return (
    <div className="widget-container">
      <h2 className="search-title">{title}</h2>
      <form className="form-inline ">
        <SearchInput className="xs-sm-only-small-text mb-sm-2" />
        <SelectList defaultText="All Resource Types" className="mb-2">
          {resources.map((resource) => (
            <option
              key={resource.Title}
              className={`filter
                    `}
              value={resource.id}
            >
              {resource.Title}
            </option>
          ))}
        </SelectList>

        <SelectList defaultText="All Categories" className="mb-2">
          {categories.map((category) => (
            <option
              key={category.Text}
              className={`filter
                    `}
              value={category.id}
            >
              {category.Text}
            </option>
          ))}
        </SelectList>

        <SelectList defaultText="All Industries" className="mb-2">
          {industries.map((industry) => (
            <option
              key={industry.Title}
              className={`filter
                    `}
              value={industry.id}
            >
              {industry.Title}
            </option>
          ))}
        </SelectList>
        <SelectList defaultText="All Dates" className="mb-2">
          {dates.map((date) => (
            <option
              key={date.Value}
              className={`filter
                    `}
              value={date.Value}
            >
              {date.Text}
            </option>
          ))}
        </SelectList>
        <SelectList defaultText="All Locations" className="mb-2">
          {locations.map((location) => (
            <option
              key={location.id}
              className={`filter
                    `}
              value={location.id}
            >
              {location.Title}
            </option>
          ))}
        </SelectList>
      </form>

      <div className="button-container">
        <button className="btn" type="submit">
          Search
        </button>
      </div>
    </div>
  );
}

export default SearchWidget;
