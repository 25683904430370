import { CartActionTypes } from './shopping-cart.types';

export const addCartItem = (item) => ({
  type: CartActionTypes.ADD_CART_ITEM,
  payload: item,
});

export const toggleCartVisibility = () => ({
  type: CartActionTypes.TOGGLE_CART_VIEW,
});

export const cartCountUpdate = (countUpdate) => ({
  type: CartActionTypes.CART_COUNT_UPDATE,
  payload: countUpdate,
});
export const UpdateImpersonateMember = (impersonateMember) => ({
  type: CartActionTypes.UPDATE_IMPERSONATE,
  payload: impersonateMember,
});
export const addSoftwareItemToCart = (item) => ({
  type: CartActionTypes.ADD_SOFTWARE_ITEM,
  payload: item,
});

export const addTrainingItemToCart = (item) => ({
  type: CartActionTypes.ADD_TRAINING_ITEM,
  payload: item,
});

export const addCartBasketId = (item) => ({
  type: CartActionTypes.BASKET_ID,
  payload: item,
});

export const getCartItem = (item) => ({
  type: CartActionTypes.GET_CART_ITEM,
  payload: item,
});

export const resetCartItem = (item) => ({
  type: CartActionTypes.RESET_CART,
  payload: item,
});

export const updateCartItem = (item) => ({
  type: CartActionTypes.UPDATE_CART,
  payload: item,
});

export const getCartAttendeesItem = (item) => ({
  type: CartActionTypes.GET_CART_ATTENDEES,
  payload: item,
});

export const cartAttendeesUpdate = (item) => ({
  type: CartActionTypes.ATTENDEES_COUNT_UPDATE,
  payload: item,
});
export const addCartAttendeesAction = (item) => ({
  type: CartActionTypes.ADD_CART_ATTENDEES,
  payload: item,
});

export const updateCartAttendeesAction = (item) => ({
  type: CartActionTypes.UPDATE_CART_ATTENDEES,
  payload: item,
});

export const updateAllowCartStepsAction = (item) => ({
  type: CartActionTypes.CART_STEPS,
  payload: item,
});

export const UpdateCartConfirmParams = (item) => ({
  type: CartActionTypes.CART_CATEGORY_PARAMS,
  payload: item,
});
