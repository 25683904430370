import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../../../../commons/custom-button/custom-button.component';

import './password-help.styles.scss';

function PasswordHelp() {
  return (
    <div className="password-help module-spacer">
      <div className="form-container background action-item">
        <h2>Password Help</h2>
        <p className="blurb">
          Instructions for resetting your password have been sent to your email
          address.
        </p>

        <Link to="/user-login">
          <CustomButton>Return to Sign in</CustomButton>
        </Link>
      </div>
    </div>
  );
}

export default PasswordHelp;
