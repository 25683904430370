import React from 'react';

import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';

function ConsultingSecondaryA({
  page: { MainContent, CategoryContent },
  section,
}) {
  if (MainContent) {
    return (
      <div className={`consulting consulting-category ${section || ''}`}>
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          colorCategory={section}
          downArrow
        />
        <div className="sm-up-trinity-container">
          <ContactCta
            Title="Contact us to learn how we can help your business."
            subTitle="Contact Trinity Consulting"
            subTitleLink="#"
          />

          {CategoryContent ? (
            <FilteredCtaGrid
              onlySmallCtas
              gridColumnClasses="col-sm-2"
              isFromConsulting
              ctas={CategoryContent}
              activeStarterSection={section}
            />
          ) : (
            <h2 className="trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default ConsultingSecondaryA;
