import React, { useEffect, useState } from 'react';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './webinar-form.validationRules';
import { blurb, content, link, title } from './webinar-form.data';
import TextInput from '../../form-inputs/text-input/text-input.component';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import './webinar-form.styles.scss';
import { submitWebinarSignUpResponse } from '../../../../js/api/specialty-pages/_webinar-signup.data';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';
import { useHistory } from 'react-router-dom';
import { ENDPOINTS } from '../../../../js/api/_config';

// RP #Task 57 - Webinar Signup web form
function WebinarForm({ data, mainData, status }) {
  const [dropDownContent] = useState(data);
  const [noContent] = useState(204);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  const submitData = async (submissionObj) => {
    if (submissionObj !== undefined && submissionObj !== null) {
      const token = await createToken();
      await submitWebinarSignUpResponse(submissionObj, token);
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.title.trim(),
      Company: values.companyName.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      PostalCode: values.postalCode.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      Country: values.CountryName != null ? values.countryName.trim() : 'None',
      Industry:
        values.industryName != null ? values.industryName.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.webinar_signup.UniqueId,
      Catalog: ListOfFormUniqueData.webinar_signup.Catalog,
    };

    submitData(submissionObj);
  }

  return (
    <div className="webinar-form-container">
      <p className="title">{title}</p>
      <p className="content">{content}</p>
      <p className="blurb">{blurb}</p>

      <form className="webinar-form" onSubmit={handleSubmit} noValidate>
        <div className="inner-form">
          <div className="left">
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />
            {errors.title && <span className="help">{errors.title}</span>}
            <TextInput
              placeholder="Title"
              required
              type="text"
              name="title"
              onChange={handleChange}
              value={values.title || ''}
              autoComplete="off"
              className={`${errors.title && 'error'}`}
              maxlength={100}
            />
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company Name"
              required
              type="text"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              autoComplete="off"
              className={`${errors.companyName && 'error'}`}
              maxlength={100}
            />
            <SelectList
              defaultText="— Industry —"
              name="industryName"
              onChange={handleChange}
              value={values.industryName}
              required
            >
              {status !== noContent &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />
          </div>
          <div className="right">
            <SelectList
              defaultText="— Country —"
              name="CountryName"
              value={values.countryName}
              required
              onChange={handleChange}
            >
              {Country &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={100}
            />

            <SelectList
              defaultText="— State/Province —"
              name="State"
              onChange={stateclick}
              value={values.stateName}
            >
              {State &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Company Email Address"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={100}
            />
          </div>
        </div>
        <div className="recaptcha">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default WebinarForm;
