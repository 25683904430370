import React, { useEffect, useState } from 'react';
import './training-search.styles.scss';
import { useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import TrainingListingWithFilters from '../../../components/training/training-listing-with-filters/training-listing-with-filters.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import { getTrainingSecondaryContent } from '../../../js/api/_training_api';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import useInputCollection from '../../../js/custom-hooks/useInputCollection';

const INITIAL_STATE = {
  KeywordPhrase: '',
  ResouceType: '',
  Industry: '',
  Location: '',
  Softwares: '',
};

// JP #Task 41
function TrainingSearch(id) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state, updateInput } = useInputCollection(INITIAL_STATE);

  const searchItem = useSelector((state) => state.training);
  const heroLink = { src: '/training', text: 'View All Training Courses' };
  const fetchTrainingSecondayContent = async () => {
    const response = await getTrainingSecondaryContent();
    if (response) setContent(response.MainContent);
  };

  useEffect(() => {
    const selectedCatgory = JSON.parse(localStorage.getItem('category'));
    setSelectedCategory(selectedCatgory);

    fetchTrainingSecondayContent();
  }, []);

  if (content === null) {
    return <Loader />;
  }

  return (
    <div className="training training-search">
      {content != null ? (
        <Hero
          src={content.HeaderImage_src ? content.HeaderImage_src : heroImageSrc}
          alt={`${selectedCategory?.catName} image`}
          mobileImgSrc={content?.HeaderMobileImage_src}
          title={selectedCategory?.catName}
          template="featured"
          search
          description={selectedCategory?.catDesc}
          link={heroLink}
          colorCategory="train"
        />
      ) : null}
      <div className="trinity-container">
        <TrainingListingWithFilters />
      </div>
    </div>
  );
}

export default TrainingSearch;
