import React, { useEffect } from 'react';
import './assign-attendee-form-listing.styles.scss';

import { shallowEqual, useSelector } from 'react-redux';
import AssignAttendeeForm from '../assign-attendee-form/assign-attendee-form.component';

function AssignAttendeeFormListing({
  products,
  dropdowndata,
  mainContent = {},
}) {
  // products should have a {product-title, attendees}

  const attendeescountupdate = useSelector(
    ({ cart }) => cart.attendeescount_update,
    shallowEqual
  );

  useEffect(() => {}, [attendeescountupdate]);

  return (
    <div className="assign-attendee-form-listing">
      <AssignAttendeeForm
        // productTitle={stripHtml(product.productTitle).result + `:`}
        DropDownContent={dropdowndata}
        products={products}
        mainContent={mainContent}
        // attendeesCount={product.quantity}
        // sku={product.sku}
        // variantSku={product.variantsku}
      />
    </div>
  );
}

export default AssignAttendeeFormListing;
