import React from 'react';
import './search-input.styles.scss';

function SearchInput({ className, placeholder, searchIcon, ...otherProps }) {
  return (
    <div className="search-container w-100">
      <input
        className={`trinity-search-input w-100 ${className || ''}`}
        type="text"
        placeholder={placeholder || 'Keyword or phrase'}
        aria-label="Search"
        {...otherProps}
      />
      {searchIcon && <i className="fa fa-search" aria-hidden="true" />}
    </div>
  );
}

export default SearchInput;
