import React from 'react';
import './close-btn.styles.scss';

function CloseBtn({ className, ...otherProps }) {
  return (
    <span
      aria-label="An X indicating the use of a close button"
      className={`close-btn ${className || ''}`}
    />
  );
}

export default CloseBtn;
