import React from 'react';

export const progressData = [
  {
    id: 1,
    icon: <i className="fa fa-shopping-cart" aria-hidden="true" />,
    progressName: 'Shopping Cart',
    link: '/shopping-cart',
  },
  {
    id: 2,
    icon: <i className="fa fa-users" aria-hidden="true" />,
    progressName: 'Attendees',
    link: '/shopping-cart/attendees',
  },
  {
    id: 3,
    icon: <i className="fa fa-file-text-o" aria-hidden="true" />,
    progressName: 'Review',
    link: '/shopping-cart/attendees/review-order',
  },
  {
    id: 4,
    icon: <i className="fa fa-credit-card" aria-hidden="true" />,
    progressName: 'Payment',
    link: '/shopping-cart/attendees/review-order/payment',
  },
  {
    id: 5,
    icon: <i className="fa fa-check-circle-o" aria-hidden="true" />,
    progressName: 'Confirmation',
    link: '/shopping-cart/attendees/review-order/payment/order-confirmation',
  },
];
