import React from 'react';
import './sidebar-product-download.styles.scss';
import { BASE_URL, WEBSITE_URL } from '../../../../js/api/_config';

function SidebarProductDownload() {
  return (
    <div className="sidebar-product-download">
      <a href={`${WEBSITE_URL}/software/support`}>Release Notes</a>
      {/* <a href="https://localhost:3000">Download Adobe Acrobat Reader</a> */}
    </div>
  );
}

export default SidebarProductDownload;
