import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Pagination from 'react-js-pagination';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import Hero from '../../../components/commons/hero/hero.component';
import WidgetBox from '../../../components/about/widgets/widget-box.component';
import './trade-shows.styles.scss';

function TradeShows({ page: { MainContent, AboutUsCategoryContent } }) {
  const pagerecords =
    AboutUsCategoryContent != undefined &&
    AboutUsCategoryContent.TradeShowRecordsPerPage != undefined
      ? AboutUsCategoryContent.TradeShowRecordsPerPage
      : 10;
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(pagerecords);
  const [indexOfFirstPost, setIndexOfFirstPost] = useState();
  const [indexOfLastPost, setIndexOfLastPost] = useState();
  const [currentPosts, setCurrentPosts] = useState(null);
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (
      AboutUsCategoryContent != undefined &&
      AboutUsCategoryContent.TradeShowsContent != undefined
    )
      setPosts(AboutUsCategoryContent.TradeShowsContent);
    setIndexOfLastPost(currentPage * postsPerPage);
    setIndexOfFirstPost(indexOfLastPost - postsPerPage);
    if (
      AboutUsCategoryContent != undefined &&
      AboutUsCategoryContent.TradeShowsContent != undefined
    ) {
      setCurrentPosts(
        AboutUsCategoryContent.TradeShowsContent.slice(
          indexOfFirstPost,
          indexOfLastPost
        )
      );
    }
  }, [
    AboutUsCategoryContent,
    currentPage,
    postsPerPage,
    indexOfLastPost,
    indexOfFirstPost,
    posts,
  ]);

  if (MainContent && AboutUsCategoryContent) {
    const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
      setCurrentPage(pageNumber);
    };

    return (
      <div className="trade-shows trade">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="standard"
          colorCategory="policies"
        />

        {currentPosts &&
          currentPosts.length > 0 &&
          currentPosts != '' &&
          posts &&
          postsPerPage && (
            <div className="trinity-container main-content">
              <Row>
                <div className="wrapper">
                  <WidgetBox data={currentPosts} />
                  <div>
                    <Pagination
                      innerClass="pagination justify-content-start"
                      prevPageText="Previous"
                      nextPageText="Next"
                      activePage={activePage}
                      itemsCountPerPage={postsPerPage}
                      totalItemsCount={posts.length}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                    />
                  </div>
                </div>
              </Row>
            </div>
          )}
      </div>
    );
  }
  return <Loader />;
}

export default TradeShows;
