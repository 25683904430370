import React from 'react';
import './region-tabs.styles.scss';
import { useHistory } from 'react-router-dom';
import { Panel, Tabs, useTabState } from '../../../tab-bar/TrinityTabSheet';

function TrainingRegionTabs(props) {
  const cn = (...args) => args.filter(Boolean).join(' ');

  function Tab({ children }) {
    const { isActive, onClick } = useTabState();

    return (
      <button className={cn('tab', isActive && 'active')} onClick={onClick}>
        {children}
      </button>
    );
  }

  const history = useHistory();

  /* const onClickState = (innerElement) => {
        history.push({
            // link: innerElement.link,
            pathname: "",
            metaInformation: innerElement
        })
    } */

  return (
    <div className="region-container">
      <Tabs index={props.index}>
        <div className="tabs ">
          <div className="tab-list">
            {props.tabData.map((e, index) => (
              <Tab key={index}>{e.Name}</Tab>
            ))}
          </div>
          <hr />
          <div className="panel-container">
            {props.tabData.map((outerElement) => (
              <Panel>
                <div className="row col-12">
                  {outerElement.States.map((innerElement) => (
                    <div className="col-sm-3 col-md-2">
                      <a href={innerElement.LinkPath} className="panel-info">
                        {innerElement.Name}
                      </a>
                    </div>
                  ))}
                </div>
              </Panel>
            ))}
          </div>
        </div>
      </Tabs>
    </div>
  );
}

export default TrainingRegionTabs;
