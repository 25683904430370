import React, { Suspense } from 'react';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './consulting-job-request-individual-category-detail.styles.scss';

function NewStaffingJobRequestLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-staffing-job-request-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-staffing-job-request-content')
  );

  return (
    <div className="job-request-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          section={section}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} section={section} />
      </Suspense>
    </div>
  );
}

export default NewStaffingJobRequestLandingPage;
