import React, { useEffect, useState } from 'react';
import './TrinitySiteSearch.scss';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, SwipeableDrawer } from '@material-ui/core';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import authorIcon from './images/author-icon-400x400.png';
import search from '../ehs-quarterly/images/search.svg';
import AllComponentRootPage from './all-components/AllComponentRootPage';
import { trinitySearchData } from '../../redux/trinity-search/trinity-search.action';
import { TrinitySearchServices } from '../../redux/trinity-search/trinity-search.services';
import TrinitySiteSearchFilterComponent from './TrinitySiteSearchFilterComponent';
import Loader from '../commons/helpers/loader/loader.component';
import { handleNotify } from '../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../js/helpers/toaster/toaster-types';
import { BASE_URL } from '../../js/api/_config';
import 'react-input-range/lib/css/index.css';
import {
  processParameterAndSendQuery,
  sendToURL,
} from '../../utilities/CommonUtilities';

function TrinitySiteSearch() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const siteSearchData = useSelector((state) => state.trinitySearchData);
  const lastSearchData = useSelector(
    (state) => state.trinitySearchData.lastSearchData
  );

  const [openSwipableDrawer, setOpenSwipeableDrawer] = useState(false);

  const [searchBoxText, setSearchBoxText] = React.useState('*');
  const [filterSelection, setFilterSelection] = React.useState('');
  const [dynamicSearchData, setDynamicSearchData] = React.useState([]);
  const [activePage, setActivePage] = useState(1);
  const [rangeState, setRangeState] = useState({
    min: moment(TrinitySearchServices.dateStartConstant).toDate().getTime(),
    max: moment().add(1, 'y').toDate().getTime(),
  });

  useEffect(() => {
    dispatch(
      trinitySearchData(TrinitySearchServices.initialNewSearchStaticData)
    );
  }, []);

  useEffect(() => {
    if (
      lastSearchData.orderby === 'search.score() desc' ||
      lastSearchData.orderby === ''
    )
      setFilterSelection('relevance');
    else setFilterSelection(lastSearchData.orderby);
  }, [lastSearchData]);

  useEffect(() => {
    if (window.location.search.length > 0) {
      const data = qs.parse(window.location.search.substring(1));

      if (data?.ds) setDynamicSearchData(data?.ds);

      if (data?.rs) {
        setRangeState({
          min: Number.parseInt(data?.rs.min),
          max: Number.parseInt(data?.rs.max),
        });
      }

      if (data?.sbt) setSearchBoxText(data?.sbt);

      if (data?.drp) setFilterSelection(data?.drp);

      if (data?.pg) setActivePage(data?.pg);

      const preparedData = processParameterAndSendQuery(
        TrinitySearchServices.initialNewSearchStaticData,
        data
      );
      // if (preparedData?.search.length > 0) {
      //     preparedData = Object.assign({}, preparedData, { search:  + preparedData.search  })
      // }
      setTimeout(() => dispatch(trinitySearchData(preparedData)), 1500);
    } else {
      dispatch(
        trinitySearchData(TrinitySearchServices.initialNewSearchStaticData)
      );
    }
  }, [window.location]);

  const onChangeHandleChange = (value) => {
    setRangeState(value);
  };

  const handlePageChange = (pageNumber) => {
    const newObject = { ...lastSearchData, skip: pageNumber * 10 - 10 };
    setActivePage(pageNumber);
    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: rangeState,
      pg: pageNumber,
      drp: filterSelection,
    };
    sendToURL(history, data);
    dispatch(trinitySearchData(newObject));
  };

  const onChangeDropDownData = (key) => {
    // RP - ADVANCED SEARCH FILTERS
    let newObject = {};
    if (key === 'relevance') {
      newObject = { ...lastSearchData, orderby: 'search.score() desc' };
    } else {
      newObject = { ...lastSearchData, orderby: key };
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: rangeState,
      pg: activePage,
      drp: key,
    };
    sendToURL(history, data);

    dispatch(trinitySearchData(newObject));
  };

  const rangePickerChangeComplete = (value) => {
    let minvalue = moment(value.min).toISOString();
    minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

    let maxvalue = moment(value.max).toISOString();
    maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

    const preparedDate = `${minvalue}|${maxvalue}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;

    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );

    const currentData = lastSearchData.filter.toString().split('and');

    const preparedData = currentData
      .map((element, index) => {
        if (element.includes('DatePublished')) {
          return '';
        }
        if (currentData.length - 1 === index) {
          return element;
        }
        return `${element} and `;
      })
      .toString()
      .split(',')
      .join('');

    let newFilters = '';

    if (preparedData.length === 0) {
      let minvalue = moment(rangeState.min).toISOString();
      minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

      let maxvalue = moment(rangeState.max).toISOString();
      maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

      newFilters = `DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
    } else if (preparedData.trim().endsWith('and')) {
      let minvalue = moment(rangeState.min).toISOString();
      minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

      let maxvalue = moment(rangeState.max).toISOString();
      maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

      newFilters = `${preparedData} DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
    } else {
      let minvalue = moment(rangeState.min).toISOString();
      minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

      let maxvalue = moment(rangeState.max).toISOString();
      maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

      newFilters = `${preparedData} and DatePublished ge ${minvalue} and DatePublished le ${maxvalue}`;
    }

    // if (newFilters.includes("Training") || newFilters.includes("Training_Category") || newFilters.includes("Training_Industry") || newFilters.includes("Format")) {
    //     newFilters = lastSearchData.filter
    // }
    // let newFilters = lastSearchData.filter + " and DatePublished ge " + moment(rangeState.min).toISOString() + " and DatePublished le " + moment(rangeState.max).toISOString()

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: value,
      pg: activePage,
      drp: filterSelection,
    };

    setTimeout(() => {
      const newObject = {
        ...lastSearchData,
        facets: finalChangedData
          .filter((e) => e !== e.toString().includes('DatePublished'))
          .concat(newDateString),
        filter: newFilters,
      };

      sendToURL(history, data);
      dispatch(trinitySearchData(newObject));
    }, 2000);
  };

  const toggleDrawer = () => {
    setOpenSwipeableDrawer((e) => !e);
  };

  const resetAllFiltersAndSearch = () => {
    if (lastSearchData !== TrinitySearchServices.initialNewSearchStaticData) {
      setSearchBoxText('*');
      // setActiveStaticSearch([tabListConstant.all])
      setDynamicSearchData([]);
      setRangeState({
        min: moment(TrinitySearchServices.dateStartConstant).toDate().getTime(),
        max: moment().add(1, 'y').toDate().getTime(),
      });
      dispatch(
        trinitySearchData(TrinitySearchServices.initialNewSearchStaticData)
      );
      window.history.replaceState(null, '', `${history?.location?.pathname}`);
    } else {
      handleNotify('There are no filters applied.', ToasterTypes.Error);
    }
  };

  const filterButtonClick = () => {
    toggleDrawer();
  };

  const onSearchInTrinitySite = () => {
    setActivePage(1);
    let newSearch = '';

    if (searchBoxText.trim().toString().length === 0) {
      newSearch = '*';
      setSearchBoxText('*');
    } else {
      newSearch = searchBoxText;
    }

    setTimeout(() => {
      setActivePage(1);
      setFilterSelection('relevance');

      const data = {
        ds: dynamicSearchData,
        sbt: searchBoxText,
        rs: rangeState,
        pg: 1,
        drp: 'relevance',
      };
      sendToURL(history, data);
    }, 1000);

    setTimeout(() => {
      setDynamicSearchData([]);
      const newObject = {
        ...TrinitySearchServices.initialNewSearchStaticData,
        search: newSearch,
        orderby: 'search.score() desc',
      };
      dispatch(trinitySearchData(newObject));
    }, 500);
  };

  const getImageSlider = () => {
    const authorList =
      siteSearchData.siteSearchData &&
      siteSearchData.siteSearchData.preparedAuthorList;

    return (
      <div className="image-slider-container">
        {authorList.map((element) => (
          <div className="image-name-container">
            <div className="image-style">
              <img
                alt={Math.random().toString()}
                src={
                  element.authorImage
                    ? BASE_URL + element.authorImage
                    : authorIcon
                }
              />
            </div>
            <div className="image-label">{element.authorName}</div>
          </div>
        ))}
      </div>
    );
  };

  const getSearchBar = () => (
    <div className="search-box">
      <input
        type="text"
        placeholder="Search Here"
        className="search-input"
        value={searchBoxText}
        onKeyDown={(e) => e.key === 'Enter' && onSearchInTrinitySite()}
        onChange={(e) => setSearchBoxText(e.target.value)}
      />
      <img
        style={{
          width: '20px',
          height: '20px',
          marginRight: '10px',
          cursor: 'pointer',
        }}
        onClick={onSearchInTrinitySite}
        src={search}
        alt={Math.random().toString()}
      />
    </div>
  );
  const getFilterButton = () => (
    <div className="filter-btn">
      <button className="button-style" onClick={filterButtonClick}>
        <i
          className="fa fa-filter"
          style={{ fontSize: '20px' }}
          aria-hidden="true"
        />
      </button>
    </div>
  );

  const onChangeDynamicCheckBox = (parentKey, selectedValue, isChecked) => {
    let preparedArray = [];

    if (isChecked) {
      preparedArray = dynamicSearchData.concat(`${parentKey}|${selectedValue}`);
      setDynamicSearchData(preparedArray);
    } else {
      preparedArray = dynamicSearchData.filter(
        (e) => e !== `${parentKey}|${selectedValue}`
      );
      setDynamicSearchData(preparedArray);
    }

    const newArray = preparedArray.map((outerElement, index) => {
      const key = outerElement.split('|')[0];
      const value = outerElement.split('|')[1];

      if (preparedArray.length - 1 === index) {
        if (TrinitySearchServices.collectionFacetsList.includes(key)) {
          return `(${key}/any(t: t eq '${value}'))`;
        }
        return `(${key} eq '${value}')`;
      }
      if (TrinitySearchServices.collectionFacetsList.includes(key)) {
        return `(${key}/any(t: t eq '${value}')) and `;
      }
      return `(${key} eq '${value}') and `;
    });

    // let staticSearchString = "";

    /* if (checkArray.length > 0) {
            staticSearchString = checkArray.map((e, index) => {
                if ((checkArray.length - 1) === index) {
                    return "(Type eq '" + e + "')"
                } else {
                    return "(Type eq '" + e + "') or "
                }
            }).toString().replace(",", "")
        } */

    let dynamicString = newArray.toString().split(',').join('');

    let minvalue = moment(rangeState.min).toISOString();
    minvalue = `${minvalue.split('T')[0]}T00:00:00.000Z`;

    let maxvalue = moment(rangeState.max).toISOString();
    maxvalue = `${maxvalue.split('T')[0]}T23:59:00.000Z`;

    const preparedDate = `${minvalue}|${maxvalue}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;

    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );
    finalChangedData.push(newDateString);

    const currentData = lastSearchData.filter.toString().split('and');

    let preparedDateFilter = currentData.map((element, index) => {
      if (element.includes('DatePublished')) {
        return element;
      }
      return '';
    });

    preparedDateFilter =
      preparedDateFilter && preparedDateFilter.filter((x) => x !== '');
    const strPreparedDateFilter =
      preparedDateFilter &&
      preparedDateFilter.toString().split(',').join(' and ');

    if (strPreparedDateFilter.length > 0 && dynamicString.length > 0) {
      dynamicString = `${dynamicString} and ${strPreparedDateFilter}`;
    } else if (strPreparedDateFilter.length > 0 && dynamicString.length === 0) {
      dynamicString = strPreparedDateFilter;
    }

    let finalFilterString = '';

    finalFilterString =
      dynamicString.replace(/[ ]/g, '').length > 0
        ? // ? "DatePublished ge " + moment(rangeState.min).toISOString() + " and DatePublished le " + moment(rangeState.max).toISOString() + " and " + dynamicString
          // : "DatePublished ge " + moment(rangeState.min).toISOString() + " and DatePublished le " + moment(rangeState.max).toISOString()
          dynamicString
        : '';

    if (
      (dynamicString.includes('Training') ||
        dynamicString.includes('Training_Category') ||
        dynamicString.includes('Training_Industry') ||
        dynamicString.includes('Format')) &&
      finalFilterString.includes('DatePublished')
    ) {
      finalFilterString = dynamicString;
    }

    const newObject = {
      ...lastSearchData,
      filter: finalFilterString,
      facets: finalChangedData,
      skip: 0,
    };

    dispatch(trinitySearchData(newObject));

    setTimeout(() => {
      const data = {
        ds: preparedArray,
        sbt: searchBoxText,
        rs: rangeState,
        pg: 1,
        drp: filterSelection,
      };
      sendToURL(history, data);
      setActivePage(1);
    }, 1000);
  };

  if (!siteSearchData.siteSearchData) {
    return <Loader />;
  }

  const getCommonFilters = () => (
    <TrinitySiteSearchFilterComponent
      facetsList={
        siteSearchData.siteSearchData &&
        siteSearchData.siteSearchData.preparedSearchFacets
      }
      resetAllFiltersAndSearch={resetAllFiltersAndSearch}
      onChangeDynamicCheckBox={onChangeDynamicCheckBox}
      // activeStaticSearch={activeStaticSearch}
      isShowRedIcon={
        lastSearchData === TrinitySearchServices.initialNewSearchStaticData
      }
      dynamicSearchData={dynamicSearchData}
      rangeState={rangeState}
      onChangeHandleChange={onChangeHandleChange}
      openSwipableDrawer={openSwipableDrawer}
      rangePickerChangeComplete={rangePickerChangeComplete}
    />
  );

  return (
    <div>
      <Row className="shopping-cart module-bottom-spacer trinity-container">
        <Col xs={12} className="text-content-container">
          <div
            className="text-content background transparent"
            style={{ backgroundColor: '#9f9f9f' }}
          >
            <div className="inner-wrapper  trinity-container">
              <h1 className="m-0">Search</h1>
            </div>
          </div>
        </Col>
      </Row>
      <div className="trinity-container">
        <div className="trinity-site-search-container">
          <div className="main-content">
            <div className="search-filter-container">
              {getSearchBar()}
              {getFilterButton()}
            </div>
            <div className="activity-container">
              <div className="active-search-filter">
                {getCommonFilters()}

                <div className="active-search-filter desktop-mode">
                  <SwipeableDrawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    open={openSwipableDrawer}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                  >
                    {getCommonFilters()}
                  </SwipeableDrawer>
                </div>
              </div>
              <div className="active-block">
                {/* {getTabBar()} */}
                {siteSearchData.siteSearchData &&
                  siteSearchData.siteSearchData.preparedAuthorList.length > 0 &&
                  getImageSlider()}
                <div className="context-container">
                  <AllComponentRootPage
                    setActivePage={setActivePage}
                    activePage={activePage}
                    filterSelection={filterSelection}
                    onChangeDropDownData={onChangeDropDownData}
                    lastSearchData={lastSearchData}
                    totalDataCount={
                      siteSearchData.siteSearchData.totalDataCount
                    }
                    handlePageChange={handlePageChange}
                    dataList={siteSearchData.siteSearchData.value}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 350,
  },
}));

export default TrinitySiteSearch;
