import React from 'react';

// import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';

import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import GeneralContentFilteredCtaGrid from './general-content-filtered-cta-grid';

function GeneralContent({ page: { MainContent, CategoryContent }, section }) {
  if (MainContent) {
    return (
      <div className={`general-container ${section || ''}`}>
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="landingl2"
          altMobileTitle
          colorCategory="gen"
        />
        <div className="sm-up-trinity-container">
          {CategoryContent ? (
            <GeneralContentFilteredCtaGrid
              ctas={CategoryContent}
              gridItemWidth="width-33"
              onlySmallCtas
              activeStarterSection={section}
              optionTitle="— Select Software Type —"
            />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              {/* grid not connected */}
            </h2>
          )}
        </div>
        <div className="trinity-container module-spacer">
          <Col xs={12} lg={10}>
            <ContentWell content={MainContent.Content} />
          </Col>
        </div>
      </div>
    );
  }

  return null;
}

export default GeneralContent;
