import React from 'react';
import './product-display.styles.scss';

import { useHistory } from 'react-router-dom';
import ProductThumbCarousel from '../product-thumb-carousel/product-thumb-carousel.component';
import NoImageAvailableComponent from '../product-thumb-carousel/no-image-available-component';
import FeaturedProduct from '../featured-product/featured-product.component';
import CustomButton from '../../commons/custom-button/custom-button.component';

function ProductDisplay({
  sec1MainContent,
  sec1ProductImageContent,
  sec1RelatedProduct,
  techsheetURL,
}) {
  const images = [];

  if (sec1ProductImageContent) {
    sec1ProductImageContent.forEach((imgelement) => {
      if (imgelement) {
        images.push({ src: imgelement.Url, alt: imgelement.AlternativeText });
      }
    });
  }

  const history = useHistory();
  const handleApplyClick = (url) => {
    if (url.includes('http')){ 
      window.open(url);
    }
    else{
      history.push('/software-demo');
    }
  };
  const handleRedirectTest = () => {
    history.push('/software-demo');
  };

  const handleDownloadTechSheet = () => {
    window.open(techsheetURL, '_blank');

    // fetch(techsheetURL)
    //     .then(response => {
    //         response.blob().then(blob => {
    //             let url = window.URL.createObjectURL(blob);
    //             let a = document.createElement('a');
    //             a.href = url;
    //             //a.download = 'employees.json';
    //             a.target = "blank";
    //             a.click();
    //         });
    //         //window.location.href = response.url;
    //     });
  };

  return (
    <div className="product-display">
      <div className="product-images">
        {sec1ProductImageContent.length > 0 ? (
          <ProductThumbCarousel images={images} />
        ) : (
          <NoImageAvailableComponent />
        )}
      </div>
      <div className="content">
        <div className="header xs-only-trinity-container">
          <div className="top">
            {sec1MainContent.ProductIcon_src && (
              <div className="icon">
                <img
                  width="53"
                  height="53"
                  src={sec1MainContent.ProductIcon_src}
                  alt={sec1MainContent.ProductIcon_alt}
                />
              </div>
            )}
            <h1 className="title">{sec1MainContent.Title}</h1>
          </div>
          <div className="bottom">
            {sec1MainContent.Button1Text &&
              sec1MainContent.Button1Text.trim().length > 0 && (
                <CustomButton
                  arrow
                  variableWidth
                 onClick={() => handleApplyClick(sec1MainContent.Button1Link)}
                  className="custom-button"
                >
                  {' '}
                  {sec1MainContent.Button1Text}{' '}
                </CustomButton>
              )}
            {sec1MainContent.Button2Text &&
              sec1MainContent.Button2Text.trim().length > 0 && (
                <CustomButton
                  arrow
                  variableWidth
                  className="ml-sm-2 custom-button"
                  onClick={handleDownloadTechSheet}
                >
                  {' '}
                  {sec1MainContent.Button2Text}{' '}
                </CustomButton>
              )}

            <CustomButton
              arrow
              variableWidth
              className="ml-sm-2 custom-button"
              onClick={() => history.push('/software/software-resources')}
            >
              {' '}
              Software Resources{' '}
            </CustomButton>
            {/* <button className="cta">Request a demo ></button>
            <button className="cta">Download Tech Sheet ></button> */}
          </div>
        </div>
        <div className="the-content xs-only-trinity-container">
          <p>
            <strong>{sec1MainContent.ProductSubtitle}</strong>
          </p>
          <div dangerouslySetInnerHTML={{ __html: sec1MainContent.Content }} />
        </div>

        {sec1RelatedProduct &&
          sec1RelatedProduct.Title &&
          sec1RelatedProduct.Title.length > 0 && (
            <FeaturedProduct
              title={sec1RelatedProduct.Title ? sec1RelatedProduct.Title : ''}
              maxQty={10}
              RelatedProductLink={
                sec1MainContent &&
                sec1MainContent.FeaturedRelatedProduct_link !== undefined
                  ? sec1MainContent.FeaturedRelatedProduct_link
                  : ''
              }
              price={
                sec1RelatedProduct.ProductPrice_Price
                  ? sec1RelatedProduct.ProductPrice_Price
                  : 0
              }
              description={
                sec1MainContent &&
                sec1MainContent.FeaturedRelatedProduct_introText !== undefined
                  ? sec1MainContent.FeaturedRelatedProduct_introText
                  : ''
              }
              iconurl={sec1MainContent.FeaturedRelatedProduct_iconurl}
            />
          )}
      </div>
    </div>
  );
}

export default ProductDisplay;
