import React from 'react';
import { tags } from './article-tags.data';
import './article-tags.styles.scss';

function ArticleTags({ title, list }) {
  return (
    <div className="tags-container">
      <p className="tags-title">{title}</p>
      <ul>
        {list &&
          list.map((tag) => (
            <>
              <li className="list-item" key={tag.Id}>
                {tag.Name}
              </li>
              <span className="bar-seperator">|</span>
            </>
          ))}
      </ul>
    </div>
  );
}

export default ArticleTags;
