import React, { useEffect, useState } from 'react';
import './shopping-cart-sidebar.styles.scss';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import * as Sentry from '@sentry/react';
import CustomButton from '../../commons/custom-button/custom-button.component';
import { removeDiscountItem } from '../../../js/api/shopping-cart/_shopping-cart';
import {
  cartCountUpdate,
  updateAllowCartStepsAction,
  UpdateCartConfirmParams,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { ToasterTypes } from '../../../js/helpers/toaster/toaster-types';
import { handleNotify } from '../../../js/helpers/toaster/toaster-notify';
import { zeroPaymentSuccessRequest } from '../../../js/api/shopping-cart/_payment-cart';
import { getAttendees } from '../../../js/api/shopping-cart/_attendees-cart';

function ShoppingCartSidebar({ buttonText, mainContent }) {
  const isIE = /* @cc_on!@ */ !!document.documentMode;

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const [submitting, setSubmitting] = useState(false);
  const [cartCount, setCartCount] = useState(
    cartitems && cartitems.OrderLines ? cartitems.OrderLines.length : 0
  );

  const checkForOnDemandOnly = cartitems?.OrderLines.filter(
    (e) => e.Price === 0 && e.SessionTypeName === 'OnDemand'
  ).length;
  const isOnlyOnDemand =
    checkForOnDemandOnly > 0 &&
    checkForOnDemandOnly === cartitems?.OrderLines.length;

  // let isOnlyFreeWebinar = cartitems !== undefined && cartitems.ItemsCount > 0 && cartitems.OrderLines && cartitems.OrderLines.length == 1 &&
  //     cartitems.OrderLines.find(x => x.SessionTypeName == "Webinar" && x.Price == 0) != null &&
  //     Object.keys(cartitems.OrderLines.find(x => x.SessionTypeName == "Webinar" && x.Price == 0)).length > 0 ? true : false;

  // const [totalexistattendeesrequired, setTotalexistattendeesrequired] = useState([]);

  // const attendeescountupdate = useSelector(({ cart }) => cart.attendeescount_update, shallowEqual);

  // useEffect(() => {
  //     let getcartitemResponse;

  //     async function getCartAttendees() {
  //         let memberId;
  //         if (cartitemimpersonate) {
  //             memberId = cartitemimpersonate.memberId;
  //         }
  //         getcartitemResponse = await getAttendees(memberId);
  //         if (getcartitemResponse) {
  //             const attendees = getcartitemResponse.Attendees && [].concat.apply([], getcartitemResponse.Attendees.map(e => e.attendees));
  //             let attendeesItems = [];
  //             getcartitemResponse && getcartitemResponse.Attendees.forEach(e => {
  //                 attendeesItems = attendeesItems.concat([
  //                     {
  //                         variantsku: e.VariantSku,
  //                         quantity: e.attendees.length
  //                     }
  //                 ])
  //             })
  //             if (attendees && attendees.length > 0) {
  //                 setTotalexistattendeesrequired(attendeesItems);
  //             } else {
  //                 setTotalexistattendeesrequired(attendeesItems);
  //             }
  //         }
  //     }
  //     getCartAttendees();

  // }, [attendeescountupdate]);

  const handleReviewOrder = async () => {
    try {
      if (isOnlyOnDemand) {
        setSubmitting(true);

        let memberId = null;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
        const data = {
          memberid: memberId,
          isbothcategory: cartitems.IsBothCategory,
          isonlytraining: cartitems.IsOnlyTraining,
          isonlysoftware: cartitems.IsOnlySoftware,
        };
        // skip payment and confirm order
        const stripeResponse = await zeroPaymentSuccessRequest(data);

        if (
          stripeResponse &&
          stripeResponse.status &&
          stripeResponse.status === 1
        ) {
          const categoryparams = {
            isOnlySoftware: cartitems.IsOnlySoftware,
            isOnlyTraining: cartitems.IsOnlyTraining,
            bothCategory: cartitems.IsBothCategory,
            orderConfirmationNumber:
              stripeResponse.data.OrderConfirmationNumber,
          };
          dispatch(UpdateCartConfirmParams(categoryparams));
          dispatch(cartCountUpdate(1));
          dispatch(updateAllowCartStepsAction(5));
          const pageState = {
            paymentCompleted: stripeResponse.data.PaymentStatus === 'Completed',
          };
          history.push(
            `${location.pathname}/attendees/review-order/payment/order-confirmation`,
            pageState
          );
        }
      }
      // else if (isOnlyFreeWebinar) {
      //     let checkVariable = false;
      //     products && products.forEach(product => {
      //         let attendee = totalexistattendeesrequired.filter(e => e.variantsku === product.variantsku)[0]
      //         if (attendee === undefined) {
      //             checkVariable = true;
      //         }
      //         if (attendee && (attendee.quantity !== product.quantity)) {
      //             checkVariable = true;
      //         }
      //     })

      //     if (checkVariable) {
      //         handleNotify('Fill out all the required attendees before reviewing order.', ToasterTypes.Warning);

      //     } else {
      //         setSubmitting(true);

      //         let memberId = null;
      //         if (cartitemimpersonate) {
      //             memberId = cartitemimpersonate.memberId;
      //         }
      //         const data = {
      //             memberid: memberId,
      //             isbothcategory: cartitems.IsBothCategory,
      //             isonlytraining: cartitems.IsOnlyTraining,
      //             isonlysoftware: cartitems.IsOnlySoftware
      //         };
      //         // skip payment and confirm order
      //         const stripeResponse = await zeroPaymentSuccessRequest(data);

      //         if (stripeResponse &&
      //             stripeResponse.status &&
      //             stripeResponse.status === 1) {
      //             const categoryparams = {
      //                 isOnlySoftware: cartitems.IsOnlySoftware,
      //                 isOnlyTraining: cartitems.IsOnlyTraining,
      //                 bothCategory: cartitems.IsBothCategory,
      //                 orderConfirmationNumber: stripeResponse.data.OrderConfirmationNumber
      //             };
      //             dispatch(UpdateCartConfirmParams(categoryparams));
      //             dispatch(cartCountUpdate(1));
      //             dispatch(updateAllowCartStepsAction(5));
      //             const pageState = {
      //                 paymentCompleted: stripeResponse.data.PaymentStatus === 'Completed'
      //             }
      //             history.push(location.pathname + '/attendees/review-order/payment/order-confirmation', pageState);
      //         }

      //         setSubmitting(false);
      //     }
      // }
      else {
        setSubmitting(true);
        const hasAttendeesRequired = cartitems.IsRegisterAttendeesRequired;
        if (hasAttendeesRequired) {
          dispatch(updateAllowCartStepsAction(2));
          history.push(`${location.pathname}/attendees`);
        } else {
          dispatch(updateAllowCartStepsAction(3));
          history.push(`${location.pathname}/attendees/review-order`);
        }
        setSubmitting(false);
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in Sidebar');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in Sidebar',
        },
      });
    }
  };
  const removeDiscount = (item) => {
    const response = removeDiscountItem({
      MemberId: cartitemimpersonate.MemberId,
      DiscountId: item,
    });
    // RK Fixed Bug
    if (response != undefined) {
      handleNotify('Discount Removed from cart.', ToasterTypes.Success);
      dispatch(cartCountUpdate(1));
    }

    setTimeout(() => {
      if (isIE) {
        window.location.reload();
      }
    }, 4000);
  };
  useEffect(() => {
    setCartCount(
      cartitems != undefined && cartitems.ItemsCount > 0
        ? cartitems.ItemsCount
        : 0
    );
  }, [
    cartitems != undefined && cartitems.ItemsCount > 0
      ? cartitems.ItemsCount
      : 0,
  ]);

  if (cartitems && cartitems.ItemsCount > 0) {
    return (
      <div className="outer-container">
        <div className="shopping-cart-sidebar-container">
          <div className="top-container">
            <CustomButton loading={submitting} onClick={handleReviewOrder}>
              {cartitems.IsRegisterAttendeesRequired ? 'Continue' : buttonText}
            </CustomButton>
            {/* {
                            mainContent
                                ? <div className="order-blurb"
                                       dangerouslySetInnerHTML={{__html: mainContent.OrderHeaderText}}/>
                                : <Skeleton/>
                        } */}
          </div>

          <hr />
          <div className="order-summary">
            <p className="title">Order Summary</p>
            <div className="summary-inner">
              <div className="left">
                <p className="items-title">
                  Items (
                  {cartitems != undefined && cartitems.ItemsCount > 0
                    ? cartitems.ItemsCount
                    : 0}
                  ):
                </p>
                <p className="discount-title">Discount: </p>
                <p className="shipping-title">Shipping & handling:</p>
                <p className="tax-title">Total before tax: </p>
                <p className="estimated-tax-title">
                  Estimated tax to be collected:
                </p>
              </div>
              <div className="right">
                <p className="items-total">
                  <NumberFormat
                    value={cartitems.SubTotal}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </p>
                <p className="discount-total">
                  <NumberFormat
                    value={cartitems.DiscountTotal}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </p>
                <p className="shipping-total">
                  <NumberFormat
                    value={cartitems.ShippingTotal}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </p>
                <hr />
                <p className="total-before">
                  <NumberFormat
                    value={cartitems.TotalBeforeTax}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </p>
                <p className="estimated-total">
                  <NumberFormat
                    value={cartitems.TaxTotal}
                    displayType="text"
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                  />
                </p>
              </div>
            </div>
            <hr />
            <div className="order-total-container">
              <p className="total-title">Order total:</p>
              <p className="order-total-amount">
                <NumberFormat
                  value={cartitems.OrderTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
            </div>
          </div>
        </div>

        <div className="discount-information">
          <div className="discount-code">
            {cartitems != undefined &&
              cartitems.DiscountCodes != undefined &&
              Object.entries(cartitems.DiscountCodes).map(([key, value]) => (
                <>
                  <i className="fa fa-tag" aria-hidden="true" />{' '}
                  <span>{value}</span>{' '}
                  <span
                    className="fa-times"
                    onClick={() => removeDiscount(key)}
                  />
                </>
              ))}
          </div>

          <p className="discount-blurb">
            {cartitems.DiscountTotal === 0
              ? 'Discounts have not been applied to your order.'
              : 'Discounts has been applied to your order.'}
          </p>
        </div>
      </div>
    );
  }
  return null;
}

export default ShoppingCartSidebar;
