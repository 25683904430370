import React, { useState } from 'react';
import './contact-overlay.styles.scss';

function ContactOverlay({ visible, setVisible }) {
  // The data for this component will most likely come from redux / global api call
  // => waiting for api to make this dynamic

  return (
    <div
      className={`contact-overlay trinity-container
           ${visible ? 'visible' : ''}`}
    >
      <span
        onClick={() => setVisible(false)}
        aria-label="A button to close the contact overlay section"
        className="close-btn"
      />
      <h4 className="headline">
        Contact us to learn how we can help your business.
      </h4>
      <div className="call-information-container info-container">
        <p className="info-headline">GIVE US A CALL</p>
        <p className="call-info">
          Within the U.S.{' '}
          <a href="tel:+18002296655" className="phone-number">
            800.229.6655
          </a>
        </p>
        <p className="call-info">
          Outside the U.S.{' '}
          <a href="tel:+19726618100" className="phone-number">
            +1 972.661.8100
          </a>
        </p>
      </div>
      <div className="email-information-container info-container">
        <p className="info-headline">EMAIL</p>
        <a href="mailto:hello@trinityconsultants.com" className="email-info">
          hello@trinityconsultants.com
        </a>
      </div>
    </div>
  );
}

export default ContactOverlay;
