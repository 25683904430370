import React, { useState } from 'react';
import './review-shopping-cart-items.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputNumber from 'rc-input-number';
import 'rc-input-number/assets/index.css';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { ReactComponent as SoftwareIcon } from '../../../images/ecommerce/cart-software-icon.svg';
import { cartCountUpdate } from '../../../redux/shopping-cart/shopping-cart.action';
import { formatDateStamp } from '../../../js/helpers/_format-date-stamp';
import ShoppingCartProductItemDisplay from '../new-shopping-cart-items/shopping-cart-product-items/shopping-cart-product-items.component';
import ShoppingCartProductItemWarning from '../new-shopping-cart-items/shopping-cart-product-items/shopping-cart-product-item-warning.component';

function ReviewShoppingCartItems() {
  const dispatch = useDispatch();
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);

  const history = useHistory();
  const location = useLocation();

  const handleEdit = async () => {
    const hasTraining = cartitems.OrderLines.filter(
      (orderline) => orderline.Category === 'Training'
    ).length;
    if (hasTraining) {
      history.push('/shopping-cart');
    } else {
      history.push('/shopping-cart');
    }
  };

  const changedateformat = (dateValue) => {
    if (dateValue) {
      return moment(dateValue).format('MMMM DD, YYYY');
    }
  };

  const getproductName = (ctaValue) => {
    if (ctaValue) {
      let productName = ctaValue.ProductName;
      if (ctaValue.Category === 'Training') {
        const startdate = ctaValue.StartDate
          ? changedateformat(ctaValue.StartDate)
          : '';
        const enddate = ctaValue.EndDate
          ? changedateformat(ctaValue.EndDate)
          : '';
        const city = ctaValue.City ? ctaValue.City : '';
        const state = ctaValue.State ? ctaValue.State : '';
        const citystate = city ? city + (state ? `, ${state}` : '') : state;
        const trainigproduct = `${productName}<br />${citystate}`;
        const fulldate = startdate
          ? `<br />${startdate}${enddate ? `-${enddate}` : ''}`
          : '';
        productName = trainigproduct + fulldate;
      }

      return productName;
    }
    return '';
  };

  return (
    <div className="review-shopping-cart-items">
      <Table bordered>
        <thead>
          <tr className="head-titles">
            <th />
            {/* <th></th> */}
            <th className="table-header-align">Item</th>
            <th className="table-header-align image">Category</th>
            <th className="table-header-align">Price</th>
            <th className="table-header-align image">Discount</th>
            <th className="table-header-align">Quantity</th>
          </tr>
        </thead>
        <tbody>
          {cartitems !== undefined &&
            cartitems.OrderLines != undefined &&
            cartitems.OrderLines.map((cta, index) => (
              <tr>
                <td className="edit">
                  <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    id={cta.OrderLineID}
                    onClick={handleEdit}
                  />
                </td>
                {/* <td className="image">
                <SoftwareIcon className="product-icon" />
              </td> */}
                <td className="name">
                  <ShoppingCartProductItemDisplay cta={cta} />
                </td>
                <td className="image section-name">
                  {cta.Category.toLowerCase() === 'training'
                    ? cta.SessionTypeName
                    : cta.Category}
                </td>
                <td className="price">
                  {cta.Price > 0 ? (
                    <NumberFormat
                      value={cta.Price}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    'Free'
                  )}
                </td>
                <td className="image">
                  {cta.Discount > 0 ? (
                    <NumberFormat
                      value={cta.Discount}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  ) : (
                    <NumberFormat
                      value={0}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      fixedDecimalScale
                    />
                  )}
                </td>
                <td>
                  <label htmlFor="number">{cta.Quantity}</label>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default ReviewShoppingCartItems;
