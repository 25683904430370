import React from 'react';
import Col from 'react-bootstrap/Col';

function ShoppingCardHeaderComponent() {
  return (
    <Col xs={12} className="text-content-container">
      <div className="text-content background transparent">
        <div className="inner-wrapper  trinity-container">
          <h1 className="m-0">Review Shopping Cart</h1>
        </div>
      </div>
    </Col>
  );
}

export default ShoppingCardHeaderComponent;
