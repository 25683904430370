import ConsultingTertiaryModel from './consulting-models/consulting-tertiary.model';
import ConsultingSecondaryBModel from './consulting-models/consulting-secondary-b.model';
import ConsultingSecondaryAModel from './consulting-models/consulting-secondary-a.model';
import ConsultingPrimaryModel from './consulting-models/consulting-primary.model';
import ConsultingDataTableModel from './consulting-models/consulting-data-table.model';
import calender from '../../images/industries/calender.svg';
import pdf from '../../images/industries/pdf.svg';
import video from '../../images/industries/video.svg';
import factory from '../../images/industries/factory.svg';

// commenting out to connect this to the api
const consultingPages = [
  {
    name: 'Ehs data table',
    path: '/consulting-data-table',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'ConsultingDataTable',
    modules: ConsultingDataTableModel({
      widgets: ['this is a widget'],
      hero: {
        hero_src: '',
        hero_title: 'Air Dispersion Modeling',
      },
      content_area: `<p>
          <strong>Chemical Analytical Methods</strong></br>
          If you wish to submit a sample, please follow these sample submission procedures.
      </p>`,
      table_data: [
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'test',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'test2',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'test',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'test2',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
        {
          chemical: 'Aberelix',
          cas: '331231-132-31',
          sampling: 'Air',
          method: 'EUSA',
          sensitivity: '12',
        },
      ],
    }),
  },
  {
    name: 'Ehs Landing',
    path: '/ehs-testing-landing',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'ehs-landing',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Environment, Health & Safety',
        hero_description:
          'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.',
      },
    }),
  },
  {
    name: 'Submit Job Request',
    path: '/submit-job-request',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'consulting-job-request-individual-category-detail',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Submit Job Request',
      },
    }),
  },
  // {
  //   name: "Sign Up",
  //   path: "/webinar-signup",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "webinar-sign-up",
  //   modules: ConsultingPrimaryModel({
  //     hero: {
  //       hero_title: "Webinar",
  //     },
  //   }),
  // },
  {
    name: 'Sign Up',
    path: '/webinar-signup',
    apiurl: '/restapi/customwebinarsignupcontent/webinarsignup',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'new-webinar-sign-up',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Webinar',
      },
    }),
  },
  // {
  //   name: "Request Service Quote",
  //   path: "/request-service-quote",
  //   template: "request-service-quote",
  //   modules: ConsultingPrimaryModel({
  //     hero: {
  //       hero_title: "Request a Quote for Meteorological/Terrain Data",
  //     },
  //   }),
  // },
  {
    name: 'Request Service Quote',
    path: '/request-service-quote',
    apiurl: '/restapi/customrequestservicequotecontent/requestservicequote',
    template: 'new-request-service-quote',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Request a Quote for Meteorological/Terrain Data',
      },
    }),
  },
  // {
  //   name: "Request A Quote",
  //   path: "/request-a-quote",
  //   template: "request-a-quote",
  //   modules: ConsultingPrimaryModel({
  //     hero: {
  //       hero_title: "Request a Quote for Meteorological/Terrain Data",
  //     },
  //   }),
  // },
  {
    name: 'Request A Quote',
    path: '/request-a-quote',
    apiurl: '/restapi/customrequestservicequotecontent/requestservicequote',
    template: 'new-request-service-quote',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Request a Quote for Meteorological/Terrain Data',
      },
    }),
  },
  {
    name: 'Request Data Quote',
    path: '/request-data-quote',
    template: 'request-data-quote',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Request Data Quote',
        hero_description:
          'Meteorological and geospatial data for environmental modeling',
      },
    }),
  },
  // {
  //   name: "Request Modeling Data",
  //   path: "/software/request-data-quote",
  //   template: "request-data-quote",
  //   modules: ConsultingPrimaryModel({
  //     hero: {
  //       hero_title: "Request Modeling Data for Meteorological/Terrain Data",
  //     },
  //   }),
  // },

  {
    name: 'Request Data Quote',
    path: '/software/request-data-quote',
    template: 'new-request-data-quote',
    apiurl:
      '/restapi/customsoftwareservicecontentlvl2/software/request-data-quote',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Request Data Quote',
        hero_description:
          'Meteorological and geospatial data for environmental modeling',
      },
    }),
  },
  // {
  //   name: "Request Data",
  //   path: "/software/software-demo",
  //   template: "request-software-demo",
  //   modules: ConsultingPrimaryModel({
  //     hero: {
  //       hero_title: "Request Data for Meteorological/Terrain Data",
  //     },
  //   }),
  // },
  {
    name: 'Request Data',
    path: '/software/software-demo',
    apiurl: '/restapi/customsoftwaredemocontent/softwaredemo',
    template: 'new-request-software-demo',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: 'Request Data for Meteorological/Terrain Data',
      },
    }),
  },
  {
    name: 'Regulatory Agency Program',
    path: '/breeze-software-regulatory-agency-program',
    apiurl:
      '/restapi/customregulatoryagencyprogramcontent/regulatoryagencyprogram',
    template: 'new-regulatory-agency-program',
    modules: ConsultingPrimaryModel({
      hero: {
        hero_title: '',
      },
    }),
  },
];

export default JSON.stringify(consultingPages);
