import React, { useRef, useState } from 'react';
import './hero.styles.scss';
import Parser from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import stripHtml from 'string-strip-html';
import { ReactComponent as MobileCallIcon } from '../../../images/mobile-call-icon.svg';

import ContactOverlay from './contact-overlay/contact-overlay.component';

import BoxCta from '../cta/box-cta/box-cta.component';
import SpecialityHeroCta from './specialty-hero-cta/speciality-hero-cta.component';
import { renderUniqueId } from '../../../js/helpers/_render-unique-id';
import BreadCrumbComponent from './BreadCrumbComponent';
import styles from '../../../css/global.variables.scss';

function Hero({
  mobileImgSrc,
  src,
  alt,
  title,
  description,
  template,
  altLayout,
  transparent = false,
  colorCategory = '',
  link,
  heroCtasCustomMarkup = false,
  search = false,
  downArrow = false,
  heroCtas = false,
  heroCtasTitle = false,
  specialityHeroCta = false,
  specialityHeroCtaData,
  leanMoreClasses,
  headerIntroText,
  consultingheaderIntroText,
  tabletImage,
  breadCrumbList,
}) {
  const [contactOverlayVisible, setContactOverlayVisible] = useState(false);

  const myRef = useRef(null);

  const scrollToRef = (ref) =>
    window.scrollTo({
      left: 0,
      top: ref.current.offsetTop + 175,
      behavior: 'smooth',
    });

  function DownArrow() {
    const myRef = useRef(null);

    const executeScroll = () => scrollToRef(myRef);
    //   useMountEffect(() => scrollToRef(myRef)); // Scroll on mount

    return (
      <div ref={myRef} className="down-arrow">
        <button onClick={executeScroll}>
          <span />
        </button>
      </div>
    );
  }

  function aboutIntroText() {
    headerIntroText =
      headerIntroText.length > 3
        ? headerIntroText.slice(0, 3)
        : headerIntroText;
    return (
      <div className="trinity-container hero-ctas-container">
        <Row className="">
          {headerIntroText.map((cta, i) => (
            <Col xs={4} key={i} className="pl-2">
              <div
                className={`
                                box-cta background xs-only-trinity-container transparent
                                ${i === 0 ? 'env' : ''}
                                ${i === 1 ? 'eng' : ''}
                                ${i === 2 ? 'sci' : ''}
                            `}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'fit-content',
                }}
              >
                <p className="text" style={{ textAlign: 'left' }}>
                  {Parser(cta.IntroText)}
                </p>
                <a
                  href={cta.Url}
                  className="text"
                  style={{ color: '#FFF', alignSelf: 'start' }}
                >
                  Learn more...
                </a>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  function consultingIntroText() {
    consultingheaderIntroText =
      consultingheaderIntroText.length > 3
        ? consultingheaderIntroText.slice(0, 3)
        : consultingheaderIntroText;
    return (
      <div className="trinity-container hero-ctas-container">
        <Row className="">
          {consultingheaderIntroText.map((cta, i) => (
            <Col xs={4} key={i} className="pl-2">
              <div
                className={`
                                box-cta background xs-only-trinity-container transparent
                                ${i === 0 ? 'eng' : ''}
                                ${i === 1 ? 'sci' : ''}
                                ${i === 2 ? 'env' : ''}
                            `}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <p className="text">{Parser(cta)}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  function renderHeroCtas() {
    if (heroCtasCustomMarkup) {
      return (
        <div
          className={`trinity-container hero-ctas-container transparent ${
            transparent ? 'transparent' : ''
          }`}
        >
          <Row className="">
            {heroCtasCustomMarkup.map((cta, i) => (
              <Col
                xs={4}
                key={renderUniqueId(cta.title)}
                className={altLayout ? 'pr-2' : 'pl-2'}
              >
                <div
                  className={`
                        box-cta background xs-only-trinity-container 
                        transparent
                        ${cta.colorCategory ? cta.colorCategory : ''}
                        ${leanMoreClasses && leanMoreClasses}
                    `}
                >
                  <div dangerouslySetInnerHTML={{ __html: cta.markup }} />
                  <a href={cta.link}>Learn More...</a>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      );
    }

    return (
      <div className="trinity-container hero-ctas-container">
        {heroCtasTitle ? (
          <h2 className="hero-ctas-title">{heroCtasTitle}</h2>
        ) : null}
        <Row className="">
          {heroCtas.map((cta, i) => (
            <Col
              xs={4}
              key={renderUniqueId(cta.title)}
              className={altLayout ? 'pr-2' : 'pl-2'}
            >
              {!cta.categories ? (
                <div
                  className={`
                      box-cta background xs-only-trinity-container transparent
                      ${
                        cta.Title === 'Environmental Health & Safety'
                          ? 'env'
                          : ''
                      }
                      ${cta.Title === 'Engineering' ? 'eng' : ''}
                      ${cta.Title === 'Science' ? 'sci' : ''}
                  `}
                  dangerouslySetInnerHTML={{ __html: cta.IntroText }}
                />
              ) : (
                <BoxCta
                  title={cta.title}
                  subTitle={cta.sub_title}
                  content={cta.content}
                  link={cta.link}
                  categories={cta.categories}
                  transparent
                />
              )}
            </Col>
          ))}
        </Row>
      </div>
    );
  }

  return (
    <>
      <div
        className={`hero-container 
                    ${
                      template === 'standard' &&
                      breadCrumbList &&
                      breadCrumbList.length > 0
                        ? 'standard-breadcrumb'
                        : template
                    } ${search ? 'search' : ''} ${
          altLayout ? 'alternate-layout' : ''
        }`}
      >
        <div
          className={`hero module-spacer-bottom ${
            template === 'landingl2' && 'landing-changed'
          } ${!description ? 'title-only' : ''}`}
        >
          <picture className="img-cover">
            <source
              media={`(max-width: ${styles.breakpointSm}px)`}
              srcSet={mobileImgSrc}
            />
            <img
              className="img-cover display-hero-desktop-image-root"
              src={src}
              alt={alt}
            />
            <img
              className="img-cover display-hero-table-image-root"
              src={tabletImage || src}
              alt={alt}
            />
          </picture>

          {/* had to do coditional checks to figure out if it's from api or not */}
          {heroCtas ? renderHeroCtas() : null}

          {headerIntroText ? aboutIntroText() : null}

          {consultingheaderIntroText ? consultingIntroText() : null}

          <MobileCallIcon
            className="mobile-call-icon trinity-container d-sm-none"
            onClick={() => setContactOverlayVisible(true)}
          />

          <ContactOverlay
            visible={contactOverlayVisible}
            setVisible={setContactOverlayVisible}
          />

          <div
            className={`text-content background transparent ${
              colorCategory || 'default'
            }`}
          >
            <div className="trinity-container text-content-inner-container row">
              <div className={`content ${specialityHeroCta ? 'col' : ''}`}>
                <h1 className="hero-title">{title}</h1>
                {description && (
                  <div className="hero-description hero-description-changed-for-mobile">
                    {' '}
                    {/* KT add class hero-description-changed-for-mobile to hide description into mobile 10-28-2021 */}
                    <p>{stripHtml(description).result}</p>
                  </div>
                )}

                {/* TODO -- need to place search in it's own component */}
                {search ? (
                  <div className="search-container">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Keyword or phrase"
                      aria-label="Search"
                    />
                    <i className="fa fa-search" aria-hidden="true" />
                  </div>
                ) : null}

                {link ? (
                  <a href={link.src} className="view-more-link">
                    {link.text} ›
                  </a>
                ) : null}
              </div>

              {specialityHeroCta ? (
                <div
                  className={`${
                    specialityHeroCtaData.url ===
                    '/software/specialty-modeling-services'
                      ? 'side-content-specialityModel col-md-4 col-sm-5 col-xs-12 col-lg-4 col-xl-4'
                      : 'side-content col-md-4 col-sm-5 col-xs-12'
                  }`}
                >
                  <SpecialityHeroCta data={specialityHeroCtaData} />
                </div>
              ) : null}
            </div>
          </div>

          {/* The "down-arrow" component is causing the page to immediatly scroll to it on load (bug) */}
          {downArrow && <DownArrow />}
        </div>

        {template === 'standard' ? (
          <div>
            {title && (
              <h1 className="mobile-title trinity-container mobile-hero-title hero-title">
                {title}
              </h1>
            )}
            {/* KT add class hero-description-changed-for-mobile to hide description into mobile 10-28-2021 */}
            {description && (
              <div
                className="mobile-description hero-description trinity-container hero-description-changed-for-mobile"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        ) : null}

        {template === 'featured' || template === 'landing' ? (
          <>
            {/* KT add class hero-description-changed-for-mobile to hide description into mobile 10-28-2021 */}
            {description && (
              <div
                className="mobile-description hero-description trinity-container hero-description-changed-for-mobile"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </>
        ) : null}

        {breadCrumbList && breadCrumbList.length > 0 && (
          <div
            className={
              template === 'standard'
                ? 'breadcrumb-outer-container-standard'
                : 'breadcrumb-outer-container'
            }
          >
            <BreadCrumbComponent breadCrumbList={breadCrumbList} />
          </div>
        )}
      </div>
      <span ref={myRef} />
    </>
  );
}

export default Hero;
