import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './country-details-cta.styles.scss';

function CountryDetails({ selectedCountry, country, selectedRegion, region }) {
  return (
    <div className="country-details-container">
      <p className="country">{country}</p>
      <Row className="region-contact">
        <Col xs={12} sm={4}>
          <p className="region">
            {selectedRegion} {region}
          </p>
        </Col>
        <Col xs={12} sm={4} className="contact">
          <i className="fa fa-phone" aria-hidden="true" />{' '}
          <a href="tel:+866-830-0796">
            Contact Trinity
            {country}
          </a>
        </Col>
        <Col xs={12} sm={4} className="information">
          <i className="fa fa-info" aria-hidden="true" />{' '}
          <a href="/">
            Visit
            {country} Website
          </a>
        </Col>
      </Row>
    </div>
  );
}

export default CountryDetails;
