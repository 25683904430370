import React from 'react';
import './filter-button.styles.scss';

function FilterButton({ children, sort, colorCategory, ...otherProps }) {
  return (
    <button
      {...otherProps}
      className={`box-background filter-button ${colorCategory}`}
    >
      {children}
    </button>
  );
}

export default FilterButton;
