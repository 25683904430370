export const defaultContent = `<p>
Our Approach Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Phasellus suscipit placerat iaculis. In auctor tortor vel condimentum
euismod. Praesent sed mattis nulla. Fusce tempor lectus ipsum, eget
feugiat orci pellentesque ac. Phasellus egestas quam interdum urna
iaculis pharetra. Curabitur venenatis, odio vitae sodales mollis, tellus
diam consectetur turpis, at ullamcorper ipsum justo id arcu. Proin
ultricies sodales diam.
</p>
<p>
Our Approach Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Phasellus suscipit placerat iaculis. In auctor tortor vel condimentum
euismod. Praesent sed mattis nulla. Fusce tempor lectus ipsum, eget
feugiat orci pellentesque ac. Phasellus egestas quam interdum urna
iaculis pharetra. Curabitur venenatis, odio vitae sodales mollis, tellus
diam consectetur turpis, at ullamcorper ipsum justo id arcu. Proin
ultricies sodales diam.
</p>`;
