import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './job-request.validationRules';
import TextInput from '../../form-inputs/text-input/text-input.component';
import { contactTitle, link, title } from './job-request-form.data';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import './job-request-form.styles.scss';
import { submitJobRequestResponse } from '../../../../js/api/consulting-ehs/_jobRequest.data';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';

function ResumeBlock2({ data, status }) {
  // #RP - Task 59 - Submit Job Request
  const [dropDownContent, setDropDownContent] = useState(data);
  const [noContent] = useState(204);

  const [TypeofAssignment] = useState(
    status != noContent && dropDownContent.TypeofAssignment
      ? dropDownContent.TypeofAssignment
      : null
  );
  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );
  const history = useHistory();

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitJobRequestResponse(
        submissionObj,
        token
      );
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.title.trim(),
      PhoneNumber: values.phone.trim(),
      EmailAddress: values.email.trim(),
      Company: values.companyName != null ? values.companyName.trim() : 'None',
      JobTitle: values.jobTitle.trim(),
      RequiredEducationLevel:
        values.requiredEducationLevel != null
          ? values.requiredEducationLevel.trim()
          : 'None',
      TypeofAssignment:
        values.assignment != null ? values.assignment.trim() : 'None',
      StartDate: values.startDate != null ? values.startDate.trim() : 'None',
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.submit_job_request.UniqueId,
      Catalog: ListOfFormUniqueData.submit_job_request.Catalog,
    };

    submitData(submissionObj);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    <div
      className={`job-request-form-container ${
        errors && Object.keys(errors).length !== 0 ? 'error_Form' : ''
      }`}
    >
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <div className="left">
          <p className="form-title">{title}</p>
          <div className="max-height-input">
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
          </div>
          <div className="max-height-input">
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
          </div>
          <div className="max-height-input">
            <TextInput
              placeholder="Title"
              required
              type="text"
              name="title"
              onChange={handleChange}
              value={values.title || ''}
              autoComplete="off"
              className={`${errors.title && 'error'}`}
              maxlength={200}
            />
            {errors.title && <span className="help">{errors.title}</span>}
          </div>
          <div className="max-height-input">
            <TextInput
              placeholder="CompanyName"
              autoComplete="off"
              className={` ${errors.companyName && 'error'}`}
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              required
              maxlength={100}
            />
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
          </div>
          <div className="max-height-input">
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
          </div>
          <div className="max-height-input">
            <TextInput
              placeholder="Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={100}
            />
            {errors.email && <span className="help">{errors.email}</span>}
          </div>
        </div>

        <div className="right">
          <Row>
            {/* KT change col to sm -8 11-12-2021 */}
            <Col sm={8} className="pr-2">
              <p className="form-title">{contactTitle}</p>
              <div className="max-height-input">
                <TextInput
                  placeholder="Job Title"
                  required
                  type="text"
                  name="jobTitle"
                  onChange={handleChange}
                  value={values.jobTitle || ''}
                  autoComplete="off"
                  className={`${errors.jobTitle && 'error'}`}
                  maxlength={100}
                />
                {errors.jobTitle && (
                  <span className="help">{errors.jobTitle}</span>
                )}
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Required Education Level"
                  name="requiredEducationLevel"
                  onChange={handleChange}
                  value={values.requiredEducationLevel}
                  maxlength={200}
                />
              </div>
              <div className="max-height-input">
                <SelectList
                  className="mb-2"
                  defaultText="— Type of Assignment —"
                  name="assignment"
                  onChange={handleChange}
                  value={values.assignment}
                >
                  {status != noContent &&
                    TypeofAssignment.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
              <div className="max-height-input">
                <TextInput
                  placeholder="Start Date"
                  required
                  name="startDate"
                  onChange={handleChange}
                  value={values.startDate || ''}
                  autoComplete="off"
                  className={`${errors.startDate && 'error'}`}
                  maxlength={200}
                />
                {errors.startDate && (
                  <span className="help">{errors.startDate}</span>
                )}
              </div>
            </Col>
            <Col xs={8} className="pr-2">
              <div className="max-height-input">
                <TextInput
                  placeholder="City"
                  required
                  type="text"
                  name="city"
                  onChange={handleChange}
                  value={values.city || ''}
                  autoComplete="off"
                  className={`${errors.city && 'error'}`}
                  maxlength={100}
                />
                {errors.city && <span className="help">{errors.city}</span>}
              </div>
            </Col>
            <Col xs={4}>
              <div className="max-height-input">
                <SelectList
                  defaultText="— State —"
                  name="State"
                  onChange={handleChange}
                  value={values.state}
                >
                  {status != noContent &&
                    State.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </div>
            </Col>
          </Row>
          <div className="max-height-input">
            <textarea
              name="information"
              placeholder="Additional Information"
              onChange={handleChange}
              value={values.information}
              maxLength={200}
            />
          </div>
          <div className="recaptcha">
            <div
              style={
                errors &&
                errors.recaptchaValue && {
                  border: '1px solid red',
                  width: 'fit-content',
                }
              }
            >
              <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
            </div>
          </div>
          <div className="link">
            <CustomButton className="heroLink">{link}</CustomButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ResumeBlock2;
