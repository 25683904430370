import React from 'react';
import './homepage.styles.scss';

import Hero from '../../components/commons/hero/hero.component';
import Logos from '../../components/homepage/logo-container/logo-container.component';
import ContentWell from '../../components/commons/content-well/content-well.component';
import CtaText from '../../components/homepage/cta-text/cta.component';
import WidgetBox from '../../components/homepage/widgetBox-component/WidgetBox.component.js';
import ImageContent from '../../components/homepage/image-content-well/image-content.component';
import heroImageSrc from '../../images/homepage/homepage_hero.png';
import ContentImg from '../../images/homepage/provenance.svg';
import VideoCta from '../../components/homepage/video-cta/video-cta.component';
import { contentWellData } from './homepage.data';

function Homepage({ page: { modules } }) {
  // const data = widgetData.slice(0, 3);
  return (
    <div className="homepage-container">
      <Hero
        src={heroImageSrc}
        alt={modules.hero.hero_alt}
        title={modules.hero.hero_title}
        template="standard"
      />{' '}
      <Logos />
      <div className="trinity-container">
        <VideoCta />
        <ImageContent src={ContentImg} />
        <ContentWell content={contentWellData} />
        <CtaText />
        <div className="widgetBox-wrapper">
          <WidgetBox />
        </div>
      </div>
    </div>
  );
}

export default Homepage;
