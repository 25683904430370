import React from 'react';
import { LeadersInfo } from './side-widget.data';
import './side-widget.styles.scss';

function BioSideWidget({ data }) {
  // #RP - Task 89 - Bind data to Individual Bio Form in Front-End.
  if (
    data.SelectedAuthor.AuthorFrom == 'executive-committee' ||
    data.SelectedAuthor.AuthorFrom == 'operational-leaders'
  ) {
    const url = `${window.location.origin}/about-us/${data.SelectedAuthor.AuthorFrom}/individual-bio`;
    // const url="";
    //    if(!window.location.href.includes('individual-bio')){
    //    url = "individual-bio";
    //    }
    return (
      <div className="bio-widget-container">
        <p className="title">
          {data.SelectedAuthor.AuthorFrom == 'executive-committee'
            ? 'Executive Committee'
            : 'Operational Leaders'}{' '}
        </p>
        {data &&
          data.Author.map((each) => (
            <div className="leaders-info">
              <a href={url + each.Link} className="name">
                {each.Name}
              </a>
              <p className="job-title">{each.Title}</p>
            </div>
          ))}
      </div>
    );
  }
  return <div />;
}

export default BioSideWidget;
