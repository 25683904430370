import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import { dataTitle, link, personalTitle } from './request-data-quote.data';
import TextInput from '../../form-inputs/text-input/text-input.component';
import SelectList from '../../form-inputs/select-list/select-list.component';
import CustomButton from '../../custom-button/custom-button.component';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './request-data-quote.validationRules';
import { submitRequestDataQuoteResponse } from '../../../../js/api/specialty-pages/_request-data-quote.data';
import './request-data-quote.styles.scss';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';

function RequestDataForm({ data, mainData, status }) {
  // #RP - Task 68 - Request Data Quote
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const [Country] = useState(
    status != noContent && dropDownContent.Country
      ? dropDownContent.Country
      : null
  );
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );

  const [DataFormat] = useState(
    status != noContent && dropDownContent.DataFormat
      ? dropDownContent.DataFormat
      : null
  );
  const [CoordinateType] = useState(
    status != noContent && dropDownContent.CoordinateType
      ? dropDownContent.CoordinateType
      : null
  );
  const [Year] = useState(
    status != noContent && dropDownContent.Year ? dropDownContent.Year : null
  );
  const [North] = useState(
    status != noContent && dropDownContent.UTMDirections
      ? dropDownContent.UTMDirections
      : null
  );
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitRequestDataQuoteResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Company: values.companyTitle.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode != null ? values.postalCode.trim() : 'None',
      Country: values.Country != null ? values.Country.trim() : 'None',
      Industry: values.Industry != null ? values.Industry.trim() : 'None',
      DataFormat: values.dataformat != null ? values.dataformat.trim() : 'None',
      CoordinateType:
        values.coordinatetype != null ? values.coordinatetype.trim() : 'None',
      Easting: values.easting != null ? values.easting.trim() : 'None',
      Northing: values.northing != null ? values.northing.trim() : 'None',
      UTMZone: values.utmzone != null ? values.utmzone.trim() : 'None',
      North: values.north != null ? values.north.trim() : 'None',
      StartYear: values.startyear != null ? values.startyear.trim() : 'None',
      EndYear: values.endyear != null ? values.endyear.trim() : 'None',
      Requirements:
        values.requirements != null ? values.requirements.trim() : 'None',
      HowDidYouHearAboutUs:
        values.howdidyouhearaboutus != null
          ? values.howdidyouhearaboutus.trim()
          : 'None',
      FormUniqueId: ListOfFormUniqueData.request_data_quote.UniqueId,
      Catalog: ListOfFormUniqueData.request_data_quote.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    // KT add class request-data-form-changed-responsive 11-12-2021
    <div
      className={`request-data-form request-data-form-changed-responsive ${
        errors && Object.keys(errors).length !== 0 ? 'error_Form' : ''
      }`}
    >
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="form-title">{personalTitle}</p>
            <div className="max-height-input">
              <TextInput
                placeholder="First Name"
                required
                type="text"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                autoComplete="off"
                className={`${errors.firstName && 'error'}`}
                maxlength={100}
              />
              {errors.firstName && (
                <span className="help">{errors.firstName}</span>
              )}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Last Name"
                required
                type="text"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                autoComplete="off"
                className={`${errors.lastName && 'error'}`}
                maxlength={100}
              />
              {errors.lastName && (
                <span className="help">{errors.lastName}</span>
              )}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Job Title"
                required
                type="text"
                name="jobTitle"
                onChange={handleChange}
                value={values.jobTitle}
                autoComplete="off"
                className={`${errors.jobTitle && 'error'}`}
                maxlength={200}
              />
              {errors.jobTitle && (
                <span className="help">{errors.jobTitle}</span>
              )}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Company Email"
                type="email"
                name="email"
                required
                onChange={handleChange}
                value={values.email}
                autoComplete="off"
                className={`${errors.email && 'error'}`}
                maxlength={200}
              />
              {errors.email && <span className="help">{errors.email}</span>}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Business Phone"
                autoComplete="off"
                className={` ${errors.phone && 'error'}`}
                type="test"
                name="phone"
                onChange={handleChange}
                value={values.phone || ''}
                required
                maxlength={100}
              />
              {errors.phone && <span className="help">{errors.phone}</span>}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Company Title"
                autoComplete="off"
                className={` ${errors.companyTitle && 'error'}`}
                type="text"
                name="companyTitle"
                onChange={handleChange}
                value={values.companyTitle || ''}
                required
                maxlength={200}
              />
              {errors.companyTitle && (
                <span className="help">{errors.companyTitle}</span>
              )}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Business Address"
                required
                type="text"
                name="address"
                onChange={handleChange}
                value={values.address || ''}
                autoComplete="off"
                className={`${errors.address && 'error'}`}
                maxlength={300}
              />
              {errors.address && <span className="help">{errors.address}</span>}
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="City"
                required
                type="text"
                name="city"
                onChange={handleChange}
                value={values.city || ''}
                autoComplete="off"
                className={`${errors.city && 'error'}`}
                maxlength={200}
              />
              {errors.city && <span className="help">{errors.city}</span>}
            </div>
            <div className="max-height-input">
              <SelectList
                defaultText="— State / Province —"
                className="mb-2"
                name="State"
                onChange={handleChange}
                value={values.stateName}
              >
                {status != noContent &&
                  State.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Postal Code"
                required
                name="postalCode"
                onChange={handleChange}
                value={values.postalCode || ''}
                autoComplete="off"
                className={`${errors.postalCode && 'error'}`}
                maxlength={100}
              />
              {errors.postalCode && (
                <span className="help">{errors.postalCode}</span>
              )}
            </div>
            <div className="max-height-input">
              <SelectList
                defaultText="— Country —"
                onChange={handleChange}
                className="mb-2"
                name="Country"
                value={values.countryName}
              >
                {status != noContent &&
                  Country.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="max-height-input">
              <SelectList
                defaultText="— Select an Industry —"
                className="mb-2"
                name="Industry"
                onChange={handleChange}
                value={values.industryName}
              >
                {status != noContent &&
                  Industry.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
          </Col>

          <Col xs={12} sm={6} className="right">
            <p className="form-title">{dataTitle}</p>
            <div className="max-height-input">
              <SelectList
                defaultText="— What format do you need? —"
                name="dataformat"
                onChange={handleChange}
                value={values.dataformat}
              >
                {status != noContent &&
                  DataFormat.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="max-height-input">
              <SelectList
                defaultText="— What coordinate type would you like to use? —"
                name="coordinatetype"
                onChange={handleChange}
                value={values.coordinatetype}
              >
                {status != noContent &&
                  CoordinateType.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Easting"
                name="easting"
                autoComplete="off"
                type="text"
                onChange={handleChange}
                value={values.easting || ''}
                maxlength={200}
              />
            </div>
            <div className="max-height-input">
              <TextInput
                placeholder="Northing"
                name="northing"
                autoComplete="off"
                type="text"
                onChange={handleChange}
                value={values.northing || ''}
                maxlength={200}
              />
            </div>
            <Row className="date-year-container">
              <Col xs={12} sm={5} className="left-zone">
                <div className="max-height-input">
                  <TextInput
                    placeholder="UTM Zone"
                    name="utmzone"
                    autoComplete="off"
                    type="text"
                    onChange={handleChange}
                    value={values.utmzone || ''}
                    maxlength={200}
                  />
                </div>
                <div className="max-height-input">
                  <SelectList
                    defaultText="— Start Year —"
                    name="startyear"
                    onChange={handleChange}
                    value={values.startyear}
                  >
                    {status != noContent &&
                      Year.map(({ Name }) => (
                        <option key={Name} value={Name}>
                          {Name}
                        </option>
                      ))}
                  </SelectList>{' '}
                </div>
              </Col>
              <Col xs={12} sm={5} className="right-zone">
                <div className="max-height-input">
                  <SelectList
                    defaultText="— North —"
                    name="north"
                    onChange={handleChange}
                    value={values.north}
                  >
                    {status != noContent &&
                      North.map(({ Name }) => (
                        <option key={Name} value={Name}>
                          {Name}
                        </option>
                      ))}
                  </SelectList>
                </div>
                <div className="max-height-input">
                  <SelectList
                    defaultText="— End Year —"
                    name="endyear"
                    onChange={handleChange}
                    value={values.endyear}
                  >
                    {status != noContent &&
                      Year.map(({ Name }) => (
                        <option key={Name} value={Name}>
                          {Name}
                        </option>
                      ))}
                  </SelectList>
                </div>
              </Col>
            </Row>
            <div className="max-height-input">
              <textarea
                placeholder="Any other requirements we should know?"
                onChange={handleChange}
                value={values.requirements}
                name="requirements"
                maxLength={1000}
              />
            </div>
            <div className="max-height-input">
              <SelectList
                defaultText="— How did you hear about us? —"
                name="howdidyouhearaboutus"
                onChange={handleChange}
                value={values.howdidyouhearaboutus}
              >
                {status != noContent &&
                  HowDidYouHearAboutUs.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="max-height-input">
              <textarea
                placeholder="Comments"
                onChange={handleChange}
                value={values.comments}
                name="comments"
                maxLength={1000}
              />
            </div>
          </Col>
        </Row>

        <div className="recaptcha">
          <div
            style={
              errors &&
              errors.recaptchaValue && {
                border: '1px solid red',
                width: 'fit-content',
              }
            }
          >
            <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
          </div>
        </div>
        <div className="link">
          <CustomButton type="submit" className="heroLink">
            {link}
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

export default RequestDataForm;
