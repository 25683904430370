import React from 'react';
import { linkData, title } from './local-links.data';
import './local-links.styles.scss';

// RP # TASK 100 Individual Location
function LocalLinksWidget({ data }) {
  // const data = linkData;
  return (
    <div className="links-container">
      <p className="title">{title}</p>
      {data &&
        data.map((item) => (
          <div className="content-links">
            <ul>
              <li className="name">
                <a href={item.Link}>{item.Title}</a>
              </li>
            </ul>
          </div>
        ))}
    </div>
  );
}

export default LocalLinksWidget;
