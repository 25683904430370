import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import AssignAttendeeFormListing from '../../../components/ecommerce/forms/assign-attendee-form-listing/assign-attendee-form-listing.component';
import TrainingPolicies from '../../../components/ecommerce/shopping-cart-sidebar/training-policies-sidebar/training-policies-sidebar.component';
import {
  addCartAttendeesAction,
  getCartAttendeesItem,
} from '../../../redux/shopping-cart/shopping-cart.action';
import { getAttendees } from '../../../js/api/shopping-cart/_attendees-cart';

function AssignAttendeeWrapperComponent({
  CustomDropDownContent,
  MainContent,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const [trainingProduct, settrainingProduct] = useState(null);
  const [totalattendeesrequired, setTotalattendeesrequired] = useState(0);
  const attendeescountupdate = useSelector(
    ({ cart }) => cart.attendeescount_update,
    shallowEqual
  );
  const user = useSelector(({ user }) => user.tokens.access_token);
  const [attendeesSessionTypeName, setAttendeesSessionTypeName] = useState(
    cartitems.AttendeesSessionTypeName
  );
  const cartitemimpersonate = useSelector(({ cart }) => cart.impersonateMember);
  useEffect(() => {
    try {
      const hasTraining = cartitems.OrderLines.filter(
        (orderline) => orderline.Category === 'Training'
      ).length;

      if (hasTraining === 0) {
        history.push('shopping-cart');
      }
      if (hasTraining > 0) {
        const trainings = cartitems.OrderLines.filter(
          (orderline) => orderline.Category === 'Training'
        );

        const prod = [];
        let totalattendeesrequired = [];
        trainings.forEach((element) => {
          if (
            attendeesSessionTypeName.includes(
              element.SessionTypeName.toLowerCase()
            )
          ) {
            prod.push({
              productTitle: element.ProductName,
              quantity: element.Quantity,
              sku: element.Sku,
              variantsku: element.VariantSku,
              sessionTypeName: element.SessionTypeName,
              relatedattendees: [],
            });
            totalattendeesrequired = totalattendeesrequired.concat([
              {
                variantsku: element.VariantSku,
                quantity: element.Quantity,
              },
            ]);
          }
        });

        setTotalattendeesrequired(totalattendeesrequired);

        async function checkUserLoggedIn(items) {
          if (user) {
            let cartcount = 0;
            const getcartitemResponse = await getCartAttendees();

            if (getcartitemResponse) {
              const attendees = getcartitemResponse.Attendees;
              dispatch(addCartAttendeesAction(attendees));

              if (attendees && attendees.length > 0) {
                cartcount = attendees.length;
                // ordercart.OrderLines.forEach((a) => (cartcount += a.Quantity));
                if (items) {
                  items.forEach((element) => {
                    if (element.variantsku) {
                      const hasItem = attendees.filter(
                        (m) =>
                          m.VariantSku === element.variantsku &&
                          m.Sku === element.sku
                      );
                      if (hasItem.length > 0) {
                        element.relatedattendees = hasItem;
                      }
                    } else {
                      const hasItem = attendees.filter(
                        (m) => m.Sku === element.sku
                      );
                      if (hasItem.length > 0) {
                        element.relatedattendees = hasItem;
                      }
                    }
                  });
                }
              }
            }
            settrainingProduct(items);
          }
        }

        checkUserLoggedIn(prod);
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in data bind');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in data bind',
        },
      });
    }
  }, [attendeescountupdate]);

  const getCartAttendees = async () => {
    let memberId;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const getResponse = await getAttendees(memberId);
    return getResponse;
  };

  const getCartAttendeesCount = async () => {
    let cartcount = 0;

    const getcartitemResponse = await getCartAttendees();

    if (getcartitemResponse) {
      const attendees = getcartitemResponse.Attendees;
      dispatch(getCartAttendeesItem(attendees));

      if (attendees && attendees.length > 0) {
        cartcount = attendees.length;
        // ordercart.OrderLines.forEach((a) => (cartcount += a.Quantity));
        if (trainingProduct) {
          trainingProduct.forEach((element) => {
            if (element.variantsku) {
              const hasItem = attendees.filter(
                (m) =>
                  m.VariantSku === element.variantsku && m.Sku === element.Sku
              );
              if (hasItem.length > 0) {
                element.relatedattendees.push(hasItem[0]);
              }
            } else {
              const hasItem = attendees.filter((m) => m.Sku === element.Sku);
              if (hasItem.length > 0) {
                element.relatedattendees.push(hasItem[0]);
              }
            }
          });
        }
        settrainingProduct(trainingProduct);
      }
    }
    return cartcount;
  };

  useEffect(() => {}, [attendeescountupdate]);

  return (
    <>
      <Col xs={12} md={9} lg={8} className="xs-only-trinity-container">
        <AssignAttendeeFormListing
          dropdowndata={CustomDropDownContent}
          products={trainingProduct}
          mainContent={MainContent}
        />
      </Col>
      <Col xs={12} md={3} lg={4} className="sidebar xs-only-trinity-container">
        <TrainingPolicies
          buttonText="Continue"
          products={trainingProduct}
          // mainContent={MainContent}
          totalAttendeesRequired={totalattendeesrequired}
        />
      </Col>
    </>
  );
}

export default AssignAttendeeWrapperComponent;
