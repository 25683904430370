export const sortCtas = (ctas) =>
  ctas.sort((a, b) => {
    if (a && b && a.Title && b.Title) {
      return b.Title > a.Title ? -1 : 1;
    }
    return 0;
  });

export const sortCategoryContent = (categoryContents) => {
  if (!categoryContents) return [];
  return categoryContents.sort((a, b) => {
    if (
      a &&
      b &&
      a.Category &&
      b.Category &&
      b.Category.Title &&
      a.Category.Title
    ) {
      return b.Category.Title > a.Category.Title ? -1 : 1;
    }
    return 0;
  });
};

export const sortingbySortorderInServiceBrand = (ctas) => {
  if (!ctas) return [];
  return ctas.sort((a, b) => {
    if (a && b && a.SortOrder && b.SortOrder) {
      return parseInt(b.SortOrder) > parseInt(a.SortOrder) ? -1 : 1;
    }
    return 0;
  });
};

export const sortingbySortorder = (ctas) => {
  if (!ctas) return [];
  return ctas.sort((a, b) => {
    if (
      a &&
      b &&
      a.Category &&
      b.Category &&
      a.Category.SortOrder &&
      b.Category.SortOrder
    ) {
      return parseInt(b.Category.SortOrder) > parseInt(a.Category.SortOrder)
        ? -1
        : 1;
    }
    return 0;
  });
};

export const replacespecialcharacter = (data) => {
  const specialCharactersInAzure = [
    "'",
    '+',
    '=',
    '&&',
    '||',
    '!',
    '{',
    '}',
    '[',
    ']',
    '^',
    '~',
    '*',
    '?',
    ':',
    '/',
    '"',
    '' / '',
    '\\',
    '`',
    ';',
    '@',
    '(',
    ')',
    '-',
    '--',
    "'",
    '"',
    '"',
  ];
  if (data) {
    let replaceString = data;
    for (let i = 0; i < specialCharactersInAzure.length; i++) {
      replaceString = replaceString.replace(specialCharactersInAzure[i], ' ');
    }
    return replaceString;
  }
};

export const replacewashintondc = (data) => {
  if (data) {
    data = data.replace('Washington DC', 'Washington, DC');
    return data;
  }
};

export const SortingByDatePublished = (data) => {
  if (data) {
    data = data
      ? data.sort((a, b) => {
          if (a && a.DatePublished && b && b.DatePublished)
            return a.DatePublished > b.DatePublished ? 1 : -1;
          return 0;
        })
      : [];
  }
  return data;
};
