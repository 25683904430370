import moment from 'moment';
import { TrinitySearchTypes } from './trinity-search.types';
import { TrinitySearchServices } from './trinity-search.services';
import { compareValues } from '../../utilities/CommonUtilities';

const TRINITY_SEARCH_INITIAL_STATE = {
  lastSearchData: TrinitySearchServices.initialNewSearchStaticData,
  siteSearchData: '',
};

export const trinitySearchData = (
  state = TRINITY_SEARCH_INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TrinitySearchTypes.TRINITY_SEARCH_NEWS_DATA:
      // Changes for the static facets

      /* if (action.payload) {
                let payLoadData = action.payload
                let searchFacetsObject = payLoadData["@search.facets"]

                let preparedSearchFacets = []
                let datePublished = {}

                if (searchFacetsObject) {
                    datePublished = {
                        startDate: moment("1975-03-04T10:57:02.444Z"),
                        endDate: moment().toISOString()
                    }

                    let existingData = action.currentState.siteSearchData.preparedSearchFacets

                    if (existingData && existingData.length > 0) {
                        preparedSearchFacets = existingData.map(existingElement => {
                            let existingArray = existingElement.value
                            let checkElement = searchFacetsObject[existingElement.key]

                            let newArray = existingArray.map(existingKey => {
                                let checkFilter = checkElement.filter(newKey => newKey.value === existingKey.value)
                                if (checkFilter.length > 0) {
                                    return {
                                        value: checkFilter[0].value,
                                        count: checkFilter[0].count
                                    }
                                } else {
                                    return {
                                        value: existingKey.value,
                                        count: 0
                                    }
                                }
                            })

                            return {
                                key: existingElement.key,
                                value: newArray
                            }
                        })
                    } else {
                        preparedSearchFacets = Object.keys(searchFacetsObject).filter(e => e !== "DatePublished").map(e => {
                            /!*let currentCheckElement = searchFacetsObject[e];

                            let checkForEmpty = searchFacetsObject[e].filter(element => element.value.length === 0)
                            console.log("^^^^^^^^^^^^^^^");
                            console.log(e);
                            console.log(checkForEmpty);
                            /!*if (checkForEmpty.length > 0) {
                                return Object.assign({
                                    key: e,
                                    value: checkForEmpty.sort(compareValues('count', 'desc'))
                                })
                            }*!/!*!/

                            let getNonEmptyValue = searchFacetsObject[e].filter(element => element.value.length !== 0)
                            return Object.assign({
                                key: e,
                                value: getNonEmptyValue.sort(compareValues('count', 'desc'))
                            })
                        })
                    }

                    /!*if (existingData && existingData.length > 0) {
                        console.log("getting in");
                        let data = existingData.map(currentElement => {
                            console.log("%%%%%%%%%%%%%");
                            console.log(Object.keys(searchFacetsObject));
                            console.log(currentElement);

                            let checkValue = Object.keys(searchFacetsObject).includes(currentElement.key)
                            console.log(checkValue);

                            let newValues = currentElement.value.map(e => e)
                            console.log(newValues);
                            return newValues
                        })
                        console.log(data);
                    }*!/

                }

                let categoryObject = preparedSearchFacets.filter(e => e.key === 'Category');
                let otherObjects = preparedSearchFacets.filter(e => e.key !== 'Category').sort(compareValues('key'))

                let emptyArray = []

                preparedSearchFacets = emptyArray.concat(categoryObject, otherObjects)

                let preparedObject = Object.assign(action.payload, {preparedSearchFacets}, {datePublished})

                let valueList = action.payload["value"]

                if (valueList) {
                    let newArray = valueList.filter(e => e.Author_Name !== null && e.Author_Name.length > 0).map(e => {
                        return {
                            authorName: e.Author_Name,
                            author: e.Author,
                            authorImage: e.AuthorImage
                        }
                    }).filter(e => e.authorImage !== "" || e.authorImage !== null).slice(0, 4)
                        .filter((v, i, a) => a.findIndex(t => (t.Author_Name === v.Author_Name && t.author === v.author)) === i)

                    preparedObject = Object.assign(preparedObject, {preparedAuthorList: newArray})
                }

                let totalDataCount = action.payload["@odata.count"]

                if (totalDataCount) {
                    preparedObject = Object.assign(preparedObject, {totalDataCount: totalDataCount})
                }

                return {
                    ...state,
                    siteSearchData: preparedObject,
                };
            } else {
                return {
                    ...state
                }
            } */

      // Changes for the Dynamic facets

      if (action.payload) {
        const payLoadData = action.payload;
        const searchFacetsObject = payLoadData['@search.facets'];

        let preparedSearchFacets = [];
        let datePublished = {};

        if (searchFacetsObject) {
          datePublished = {
            startDate: moment('1975-03-04T10:57:02.444Z'),
            endDate: moment().toISOString(),
          };

          preparedSearchFacets = Object.keys(searchFacetsObject)
            .filter((e) => e !== 'DatePublished')
            .map((e) => {
              const value = searchFacetsObject[e].filter(
                (element) => element.value.length !== 0
              );
              return {
                key: e,
                value: value.sort(compareValues('count', 'desc')),
              };
            });
        }

        const categoryObject = preparedSearchFacets.filter(
          (e) => e.key === 'Category'
        );
        const otherObjects = preparedSearchFacets
          .filter((e) => e.key !== 'Category')
          .sort(compareValues('key'));

        const emptyArray = [];

        preparedSearchFacets = emptyArray.concat(categoryObject, otherObjects);

        let preparedObject = Object.assign(
          action.payload,
          { preparedSearchFacets },
          { datePublished }
        );

        const valueList = action.payload.value;

        if (valueList) {
          const newArray = valueList
            .filter((e) => e.Author_Name !== null && e.Author_Name.length > 0)
            .map((e) => ({
              authorName: e.Author_Name,
              author: e.Author,
              authorImage: e.AuthorImage,
            }))
            .filter((e) => e.authorImage !== '' || e.authorImage !== null)
            .slice(0, 4)
            .filter(
              (v, i, a) =>
                a.findIndex(
                  (t) =>
                    t.Author_Name === v.Author_Name && t.author === v.author
                ) === i
            );

          preparedObject = Object.assign(preparedObject, {
            preparedAuthorList: newArray,
          });
        }

        const totalDataCount = action.payload['@odata.count'];

        if (totalDataCount) {
          preparedObject = Object.assign(preparedObject, { totalDataCount });
        }

        return {
          ...state,
          siteSearchData: {
            ...preparedObject,
            value: preparedObject?.value?.map((e) => {
              console.log(e);
              const url =
                e?.Category === 'Training'
                  ? e?.Url?.replace(/[^a-zA-Z0-9\\/]/g, '-')
                  : e?.Url;
              return { ...e, Url: url };
            }),
          },
        };
      }
      return {
        ...state,
      };

    // Comment code till here.

    case TrinitySearchTypes.LAST_SEARCH_DATA:
      if (action.payload) {
        return {
          ...state,
          lastSearchData: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
