import React, { Suspense } from 'react';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './ind-positions.styles.scss';

function NewStaffingIndPositionsLandingPage({
  page: {
    MainContent,
    HiringManagerContent,
    RecruitingManagerContent,
    breadCrumbList,
    PositionState,
    PositionCity,
  },
  name,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-staffing-ind-positions-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-staffing-ind-positions-content-component')
  );

  return (
    <div className="individual-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent
          MainContent={MainContent}
          name={name}
          HiringManagerContent={HiringManagerContent}
          RecruitingManagerContent={RecruitingManagerContent}
          PositionState={PositionState}
          PositionCity={PositionCity}
        />
      </Suspense>
    </div>
  );
}

export default NewStaffingIndPositionsLandingPage;
