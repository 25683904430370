export const WidgetBoxTypes = {
  RELATED_TRAINING_COURSES_SERVICE_DATA:
    'RELATED_TRAINING_COURSES_SERVICE_DATA',
  RELATED_TRAINING_COURSES_INDUSTRIES_DATA:
    'RELATED_TRAINING_COURSES_INDUSTRIES_DATA',
  RELATED_TRAINING_COURSES_PRODUCT_DATA:
    'RELATED_TRAINING_COURSES_PRODUCT_DATA',
  RELATED_TRAINING_COURSES_LOCATION_DATA:
    'RELATED_TRAINING_COURSES_LOCATION_DATA',
  RELATED_RESOURCES_DATA: 'RELATED_RESOURCES_DATA',
  RELATED_NEWS_DATA: 'RELATED_NEWS_DATA',
  RELATED_PRODUCT_CONSULTING_DATA: 'RELATED_PRODUCT_CONSULTING_DATA',
  RELATED_PRODUCT_INDUSTRIES_DATA: 'RELATED_PRODUCT_INDUSTRIES_DATA',
};
