import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewAboutusLandingPage({
  page: { MainContent, DropDownContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-aboutus-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-aboutus-content-component')
  );

  return (
    <div className="consulting-landing consulting relative">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          data={MainContent}
          DropDownContent={
            DropDownContent != undefined &&
            DropDownContent.lstAboutUsCategoryIntroText
          }
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent data={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewAboutusLandingPage;
