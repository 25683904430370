import React, { Suspense, useEffect, useState } from 'react';
import './payment-page.styles.scss';
import { shallowEqual, useSelector } from 'react-redux';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import { ENDPOINTS } from '../../../js/api/_config';
import Payment from '../../../components/ecommerce/payment/payment.component';
import PaymentHeaderComponent from './PaymentHeaderComponent';

function PaymentConfirmationPage() {
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const [cartCount, setcartCount] = useState(0);

  const [paymentConfirmationContent, setPaymentConfirmationContent] =
    useState(null);

  useEffect(() => {
    // async function getDataFromURL() {
    //     return await getPageDataByApiUrl(ENDPOINTS.USER_CART_ACCOUNT.PAYMENT_CART_URL);
    // }

    // getDataFromURL().then(response => {
    //     setPaymentConfirmationContent(response)
    // });

    let cartcount = 0;
    if (cartitems && cartitems.ItemsCount > 0) {
      cartitems.OrderLines.forEach((a) => (cartcount += a.Quantity));
    }
    setcartCount(cartcount);
  }, []);

  // const PaymentHeaderComponent = React.lazy(() => import("./PaymentHeaderComponent"));
  // const PaymentPage = React.lazy(() => import("../../../components/ecommerce/payment/payment.component"))
  return (
    <div className="payment-page-container trinity-container">
      {/* <Suspense fallback={<Loader />}>
                <PaymentHeaderComponent />
            </Suspense> */}
      <PaymentHeaderComponent />
      <Payment />
      {/* <Suspense fallback={<Loader />}>
                <PaymentPage  />
            </Suspense> */}
    </div>
  );
}

export default PaymentConfirmationPage;
