import * as Sentry from '@sentry/react';
import { ENDPOINTS } from './_config';
import { ToasterPositions } from '../helpers/toaster/toaster-positions';
import { ToasterTypes } from '../helpers/toaster/toaster-types';
import { handleNotify } from '../helpers/toaster/toaster-notify';

export const userEmailExist = async (email) => {
  try {
    const request = await fetch(ENDPOINTS.EMAIL_EXISTS + email);
    const response = await request.json();

    if (response.Exists) return response.Exists;
    return false;
  } catch (e) {
    return false;
  }
};

export const userEmailExistRequest = async (email) => {
  try {
    const request = await fetch(ENDPOINTS.EMAIL_EXISTS + email);
    // const request = await fetch("http://httpstat.us/500");
    const response = await request.json();
    return response;
  } catch (e) {
    // Sentry.setUser({ email });
    // Sentry.reactRouterV3Instrumentation()
    // const storeStringify = JSON.stringify(email, e);
    // Sentry.addBreadcrumb({
    //     category: "auth",
    //     message: "Authenticated user " + email,
    //     level: Sentry.Severity.Info,
    // });
    {
      /* RP - Add Sentry Changes */
    }
    Sentry.setExtra('Login Page', 'Email Exists Api Error');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Email Exists Api Error',
      },
    });
    // Sentry.withScope(function (scope) {
    //     scope.setTag("my-tag", "my value");
    //     scope.setLevel("warning");
    //     // will be tagged with my-tag="my value"
    //     Sentry.captureException(new Error("my error"));
    // });
    // Sentry.eventFromException(new Error("something wrong"),e,e);
    // Sentry.
    // Sentry.setContext("character", {
    //     name: "Mighty Fighter",
    //     age: 19,
    //     attack_type: "melee",
    // });
    // Sentry.captureException(new Error("something went wrong"), {
    //     tags: {
    //         section: "new articles",
    //     },
    // });

    return null;
  }
};

export const userForgotPasswordRequest = async (items) => {
  const location = ENDPOINTS.PROXY + ENDPOINTS.USER.FORGOTPASSWORD_REQUEST;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 204) {
      return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while sending request', ToasterTypes.Error);
    return null;
  } catch (e) {
    handleNotify('Error while sending request', ToasterTypes.Error);
    return null;
  }
};

export const userResetPasswordValidToken = async (token) => {
  const location =
    ENDPOINTS.PROXY + ENDPOINTS.USER.RESETPASSWORD_VALIDTOKEN + token;
  try {
    const request = await fetch(location);
    const response = await request.json();

    if (response.TokenIsValid) return response;
    return false;
  } catch (e) {
    return false;
  }
};

export const userResetPasswordRequest = async (items) => {
  const location = ENDPOINTS.PROXY + ENDPOINTS.USER.RESETPASSWORD_REQUEST;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      const response = await fetchResponse.json();
      return response;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    if (fetchResponse.status === 500) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while sending request', ToasterTypes.Error);
    return null;
  } catch (e) {
    handleNotify('Error while sending request', ToasterTypes.Error);
    return null;
  }
};

export const adminuserEmailExist = async (email) => {
  try {
    const request = await fetch(ENDPOINTS.ADMINUSER.EMAIL_EXISTS + email);
    const response = await request.json();

    if (response) return response;
    return false;
  } catch (e) {
    return false;
  }
};
