import React, { useState, useEffect } from 'react';
import Hero from '../../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../../images/about/policiesHero.svg';
import JobAlertWidget from '../../../../components/commons/forms/job-alert-side-widget/job-alert-side-widget.component';
import { getJobAlertPageData } from '../../../../js/api/consulting-ehs/_jobAlert.data';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './job-alert.style.scss';

function JobAlert({ page: { MainContent } }) {
  // #RP - Task 129 - Staffing Job Alert Sign Up
  const [responseMainContent, setResponseMainContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      if (MainContent && MainContent != undefined && MainContent != null) {
        setStatus(200);
        setResponseMainContent(MainContent);
      } else {
        const getResponse = await getJobAlertPageData();

        if (getResponse) {
          setStatus(getResponse.status);
          setResponseMainContent(getResponse.MainContent);
        }
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent && responseMainContent != undefined)
  ) {
    return (
      <div className="alert-container">
        <Hero
          src={responseMainContent ? responseMainContent.HeaderImage_src : ''}
          mobileImgSrc={
            responseMainContent ? responseMainContent.MobileImage_src : ''
          }
          alt={responseMainContent ? responseMainContent.HeaderImage_alt : ''}
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="staffing"
        />

        {/* KT add class job-alert-changed-responsive 11-12-2021 */}
        <div className="trinity-container job-alert-changed-responsive">
          <JobAlertWidget data={responseMainContent} />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default JobAlert;
