import { MinnowActionTypes } from './minnow.types';

const MINNOW_INITIAL_STATE = {
  minnowData: '',
  trainingList: [],
  eventList: [],
  newsList: [],
};

const minnowReducer = (state = MINNOW_INITIAL_STATE, action) => {
  switch (action.type) {
    case MinnowActionTypes.GET_MINNOW_DATA:
      if (action.payload) {
        return {
          ...state,
          minnowData: action.payload,
        };
      }
      return {
        ...state,
      };

    case MinnowActionTypes.SERVICE_BRAND_TRAINING:
      if (action.payload) {
        return {
          ...state,
          trainingList: action,
        };
      }
      return {
        ...state,
      };

    case MinnowActionTypes.SERVICE_BRAND_NEWS:
      if (action.payload) {
        return {
          ...state,
          newsList: action,
        };
      }
      return {
        ...state,
      };

    case MinnowActionTypes.SERVICE_BRAND_EVENTS:
      if (action.payload) {
        return {
          ...state,
          eventList: action,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default minnowReducer;
