import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class NewsServices {
  static async getUserNews(newsrequest) {
    return axios({
      method: 'POST',
      url: ENDPOINTS.PROXY + ENDPOINTS.NEWS.GET_USER_NEWS,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: newsrequest.PageNumber,
        Tags: newsrequest.Tags,
        NewsCategory: newsrequest.NewsCategory,
        IndustryCategory: newsrequest.IndustryCategory,
        Location: newsrequest.Location,
        PublishedDate: newsrequest.PublishedDate,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getAdminNews(newsRequest) {
    return axios({
      method: 'POST',
      url: ENDPOINTS.PROXY + ENDPOINTS.NEWS.GET_ADMIN_NEWS,
      headers: {
        Accept: 'application/json',
      },
      data: {
        PageNumber: newsRequest.PageNumber,
        Tags: newsRequest.Tags,
        NewsCategory: newsRequest.NewsCategory,
        IndustryCategory: newsRequest.IndustryCategory,
        Location: newsRequest.Location,
        PublishedFromDate: newsRequest.PublishedFromDate,
        PublishedToDate: newsRequest.PublishedToDate,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getNewsFilterData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEWS.GET_NEWS_FILTER_DATA,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }

  static async getNewsPageData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.NEWS.GET_NEWS_PAGE_DATA,
      headers: {
        authorization: `Bearer ${localStorage.getItem('access-token')}`,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}
