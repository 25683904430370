import React from 'react';
import './ResourcesWidgetBox.styles.scss';
import CustomButton from '../../components/commons/custom-button/custom-button.component';

function ResourcesWidgetBox({ data }) {
  // const data = WidgetData;
  return (
    <div className="widgetBox-wrapper">
      <div className="content-wrapper">
        {data &&
          data.map((item) => (
            <div className="widget-wrapper">
              <div className="left-content">
                <div className="image">
                  <img src={item.GlobalIcon} alt="" />
                </div>
                <div className="inner-content">
                  <p className="inside-link">{item.Title}</p>
                  {item.PublishedDate && (
                    <p className="date">{item.PublishedDate}</p>
                  )}
                </div>
              </div>
              <div className="right-content">
                <CustomButton
                  onClick={() => window.open(item.Url, '_blank')}
                  title="Download"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ResourcesWidgetBox;
