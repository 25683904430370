import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RE_CAPTCHA_SITE_KEY } from '../../../js/api/_config';
// RK Task#135 Updated RecaptchaKey
function Recaptcha(props) {
  return (
    // Test key from google admin recaptcha
    <ReCAPTCHA
      sitekey={RE_CAPTCHA_SITE_KEY}
      onChange={(e) => props.onChangeRecaptchaValue(e)}
      size="compact"
    />
  );
}

export default Recaptcha;

// Trinity key: 6LfOm_8UAAAAAFp4c3abjvUv4iL1zBRJ_sNcItB3
// Personal key for testing: 6LcEa-wUAAAAAJpUaKhFh9OoxrKTHAyb0-2cW52L
