import React, { useState } from 'react';

import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';

function getHeroCtaContent(categoryContent) {
  return categoryContent.map((section) => section.Category);
}

function swap(arr) {
  if (arr) {
    [arr[1], arr[2]] = [arr[2], arr[1]];
  }
  return arr;
}

function ConsultingPrimary({ page: { MainContent, CategoryContent } }) {
  const [heroCtas, setHeroCtas] = useState(
    CategoryContent ? getHeroCtaContent(CategoryContent) : null
  );

  const tempArray = heroCtas && heroCtas;

  if (MainContent) {
    return (
      <div className="consulting-landing consulting relative">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          downArrow
          heroCtas={tempArray}
        />

        <div className="sm-up-trinity-container">
          <ContactCta
            Title="Contact us to learn how we can help your business."
            subTitle="Contact Trinity Consulting"
            subTitleLink="#"
          />

          {CategoryContent ? (
            <FilteredCtaGrid
              isFromConsulting
              bgImage={MainContent.BackgroundImage_src}
              ctas={CategoryContent}
              onlySmallCtas
              optionTitle="— Select Consulting Type —"
            />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default ConsultingPrimary;
