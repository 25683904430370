import moment from 'moment';
import { ClientActionTypes } from './client.types';

export function CustomDateFormat(input) {
  // console.log("-----------------");
  // console.log(input);
  // console.log(moment(input, 'DD/MM/YYYY h:mm:ss').toDate());
  const d = moment(input, 'DD/MM/YYYY h:mm:ss').toDate();
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date =
    `${month[d.getMonth().toString()]} ${d.getDate().toString()} ` +
    `, ${d.getFullYear().toString()}`;
  const time = d
    .toLocaleTimeString()
    .toLowerCase()
    .replace(/([\d]+:[\d]+):[\d]+(\s\w+)/g, '$1$2');
  return `${date} ${time}`;
}

function formatDollar(num) {
  if (num !== null && num !== undefined) {
    const p = num.toFixed(2).split('.');
    return `$${p[0]
      .split('')
      .reverse()
      .reduce(
        (acc, num, i, orig) =>
          num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc,
        ''
      )}.${p[1]}`;
  }
}

export function processStatusData(data) {
  const myJobs = [];
  const processedData = data.map((e) =>
    Object.assign(e, { Status: 'Running', RunTime: '' })
  );

  for (let i = 0; i <= processedData.length - 1; i++) {
    if (data[i].ResultsHttp && data[i].ResultsHttp.includes('http')) {
      data[i].Status = 'Completed';
    }

    if (
      data[i].StartTime != null &&
      data[i].Status != null &&
      !data[i].IsFailed &&
      data[i].StartTime > '1910-01-01' &&
      (data[i].EndTime <= '1910-01-01' ||
        data[i].EndTime === undefined ||
        data[i].EndTime == null)
    ) {
      data[i].EndTime = null;
      data[i].Status = 'Running';
      const runTime =
        new Date(
          new Date().getTime() -
            new Date(Date.parse(data[i].StartTime)).getTime()
        ) / 1000;
      if (runTime > 0) {
        const days = Math.floor(runTime / (3600 * 24));
        let hours = Math.floor((runTime - days * (3600 * 24)) / 3600);
        let minutes = Math.floor(
          (runTime - days * (3600 * 24) - hours * 3600) / 60
        );
        let seconds = Math.floor(
          runTime - days * (3600 * 24) - hours * 3600 - minutes * 60
        );

        if (hours < 10) {
          hours = `0${hours}`;
        }
        if (minutes < 10) {
          minutes = `0${minutes}`;
        }
        if (seconds < 10) {
          seconds = `0${seconds}`;
        }

        data[i].RunTime = `${hours}:${minutes}:${seconds}`;
      }
    }
    if (
      data[i].StartTime <= '1910-01-01' ||
      new Date(data[i].StartTime) === new Date('001-01-01 00:00')
    ) {
      if (
        !data[i].IsFailed &&
        !data[i].IsCancelled &&
        data[i].Status.toUpperCase() !== 'COMPLETED'
      )
        data[i].Status = 'Queued';
    }

    if (data[i].EndTime <= '1910-01-01') {
      data[i].EndTime = null;
    }

    // StartTimeed and not EndTimeed so status is running
    if (
      data[i].StartTime > '1910-01-01' &&
      data[i].Status.toUpperCase() !== 'FAILED' &&
      !data[i].IsFailed &&
      (data[i].EndTime <= '1910-01-01' ||
        data[i].EndTime == undefined ||
        data[i].EndTime == null)
    ) {
      if (data[i].Status.toUpperCase() === 'UNKNOWN') {
        data[i].Status = 'Failed';
        myJobs.push(data[i]);
      }
    }

    if (data[i].Status.toUpperCase() === 'FAILED') {
      data[i].InvoiceAmount = formatDollar(0);
      myJobs.push(data[i]);
    }

    if (data[i].Status.toUpperCase() === 'FAILED' && data[i].IsCancelled) {
      data[i].CssClass = 'danger';
      data[i].Status = 'Cancelled';
      data[i].InvoiceAmount = '$0.00';
      if (
        data[i].StartTime !== undefined &&
        data[i].EndTime !== undefined &&
        data[i].EndTime.length > 0 &&
        data[i].StartTime.length > 0
      ) {
        const runTime =
          (new Date(Date.parse(data[i].EndTime)).getTime() -
            new Date(Date.parse(data[i].StartTime)).getTime()) /
          1000;
        if (runTime > 0) {
          const days = Math.floor(runTime / (3600 * 24));
          let hours = Math.floor((runTime - days * (3600 * 24)) / 3600);
          let minutes = Math.floor(
            (runTime - days * (3600 * 24) - hours * 3600) / 60
          );
          let seconds = Math.floor(
            runTime - days * (3600 * 24) - hours * 3600 - minutes * 60
          );

          if (hours < 10) {
            hours = `0${hours}`;
          }
          if (minutes < 10) {
            minutes = `0${minutes}`;
          }
          if (seconds < 10) {
            seconds = `0${seconds}`;
          }

          data[i].RunTime = `${hours}:${minutes}:${seconds}`;
        }
      }

      myJobs.push(data[i]);
    }

    if (data[i].ArrivalTime) {
      data[i].ArrivalTime = CustomDateFormat(data[i].ArrivalTime);
    }

    if (data[i].StartTime) {
      data[i].StartTime = CustomDateFormat(data[i].StartTime);
    }

    if (data[i].EndTime) {
      data[i].EndTime = CustomDateFormat(data[i].EndTime);
    }

    myJobs.push(data[i]);
  }

  return myJobs;
}

const CLIENT_INITIAL_STATE = {
  brmsKey: {},
  brmsUserId: '',
  orderHistoryList: [],
  softwareAndDataDownloadList: [],
  jobStatusList: [],
  trainingCertificatesList: [],
  webinarList: [],
  eLearningList: [],
  queuedData: [],
  completed: [],
  oldOrderHistory: [],
  oldOnDemand: [],
  oldLearning: [],
};

const clientReducer = (state = CLIENT_INITIAL_STATE, action) => {
  switch (action.type) {
    case ClientActionTypes.GET_ORDER_HISTORY:
      if (action.payload) {
        return {
          ...state,
          orderHistoryList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.GET_SOFTWARE_DATA_DOWNLOADS:
      if (action.payload) {
        return {
          ...state,
          softwareAndDataDownloadList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.GET_QUEUED_DATA:
      if (action.payload) {
        return {
          ...state,
          queuedData: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.GET_COMPLETED_DATA:
      if (action.payload) {
        return {
          ...state,
          completed: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.GET_MODEL_JOB_STATUS:
      if (action.payload) {
        return {
          ...state,
          jobStatusList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.TRAINING_CERTIFICATES:
      if (action.payload) {
        return {
          ...state,
          trainingCertificatesList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.WEBINAR:
      if (action.payload) {
        return {
          ...state,
          webinarList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.ELEARNING_LIST:
      if (action.payload) {
        return {
          ...state,
          eLearningList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.BRMS_KEY:
      if (action.payload) {
        return {
          ...state,
          brmsKey: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.BRMS_USER_ID:
      if (action.payload) {
        return {
          ...state,
          brmsUserId: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.OLD_LEARNING:
      if (action.payload) {
        return {
          ...state,
          oldLearning: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.OLD_ON_DEMANDD:
      if (action.payload) {
        return {
          ...state,
          oldOnDemand: action.payload,
        };
      }
      return {
        ...state,
      };

    case ClientActionTypes.OLD_ORDER_HISTORY:
      if (action.payload) {
        return {
          ...state,
          oldOrderHistory: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default clientReducer;
