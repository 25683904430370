import axios from 'axios';
import moment from 'moment';
import {
  ENDPOINTS,
  SITE_SEARCH_API_KEY,
  SITE_SEARCH_AUTHORITY,
} from '../../js/api/_config';
import {
  replacespecialcharacter,
  replacewashintondc,
} from '../../js/helpers/_sort-ctas';

export class TrinitySearchServices {
  static dateStartConstant = '2001-01-01T10:57:02.444Z';

  static collectionFacetsList = [
    'Tag',
    'Categories',
    'Training_Category',
    'Training_Industry',
    'State',
    'Author_Name',
    'Product',
    'Services',
    'ResourceType',
    'ModelingCategory',
  ];

  static initialNewSearchStaticData = {
    count: true,
    skip: 0,
    top: 10,
    searchMode: 'any',
    queryType: 'simple',
    orderby: 'DatePublished desc',
    facets: [
      'Article_Tags,count:30,sort:count',
      'Tag,count:30,sort:count',
      'Category,count:30,sort:count',
      'State,count:100,sort:count',
      'Author_Name,count:30,sort:count',
      'Categories,count:30,sort:count',
      'Format,count:30,sort:count',
      'Training_Category,count:30,sort:count',
      'Training_Industry,count:30,sort:count',
      'Product,count:30,sort:count',
      'Services,count:30,sort:count',
      'ResourceType,count:30,sort:count',
      // "IndustryType,count:10,sort:count",
      'ModelingCategory,count:30,sort:count',
      `DatePublished,values:${
        TrinitySearchServices.dateStartConstant
      }|${moment().add(1, 'y').toDate().toISOString()}`,
      // "Type,count:5,sort:count"
    ],
    filter: '',
    search: '*',
  };

  static searchForNewsSiteSearch(requestBody) {
    const newSearchFilterString =
      requestBody.search !== '*' &&
      requestBody.filter &&
      requestBody.filter.length > 0
        ? `${
            requestBody.filter
          } and (search.ismatchscoring('${replacespecialcharacter(
            requestBody.search
          )}', 'Title', 'full', 'any') or search.ismatch('${replacespecialcharacter(
            requestBody.search
          )}', 'Content', 'full', 'all') or search.ismatch('${replacespecialcharacter(
            requestBody.search
          )}', 'Tag', 'full', 'all'))`
        : requestBody.search !== '*' && requestBody.filter.length === 0
        ? ` (search.ismatchscoring('${replacespecialcharacter(
            requestBody.search
          )}', 'Title', 'full', 'any') or search.ismatch('${replacespecialcharacter(
            requestBody.search
          )}', 'Content', 'full', 'all') or search.ismatch('${replacespecialcharacter(
            requestBody.search
          )}', 'Tag', 'full', 'all'))`
        : requestBody.filter && requestBody.filter.length > 0
        ? requestBody.filter
        : '';

    const trinityRequestBody = {
      ...requestBody, // "top": 10,
      // "skip": 0,
      filter: replacewashintondc(newSearchFilterString),
      search: requestBody.search ? replacewashintondc(requestBody.search) : '*',
      scoringStatistics: 'global',
      // "orderby":"search.score() desc"
    };

    return axios({
      method: 'POST',
      url: ENDPOINTS.TRINITY_SEARCH.SEARCH_FOR_NEWS,
      headers: {
        'api-key': SITE_SEARCH_API_KEY,
        'content-type': 'application/json',
        authority: SITE_SEARCH_AUTHORITY,
      },
      data: trinityRequestBody,
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? '' : ''));
  }
}
