import React, { useState } from 'react';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import Loader from '../../../commons/helpers/loader/loader.component';
import SearchByFilter from '../search-by-filter/search-by-filter.component';
import './region-tabs.styles.scss';

// RP #Task 94 Location
function RegionTabs({ ctas, lcontent }) {
  // all data
  const [allRegion, setAllRegion] = useState(lcontent.OfficeRegion);
  const [allCountry, setAllCountry] = useState(lcontent.OfficeCountry);
  const [allState, setAllState] = useState(lcontent.OfficeState);
  const [allLocation, setAllLocation] = useState(lcontent.OfficeInformation);

  // selected data
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [regionData, setRegionData] = useState(null);
  const [countryData, setCountryData] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [locationData, setLocationData] = useState(null);

  if (!regionData) setRegionData(allRegion);
  if (!countryData) setCountryData(allCountry);
  if (!stateData) setStateData(allState);
  if (!locationData) setLocationData(allLocation);
  if (!selectedRegion) setSelectedRegion('All Regions');

  // Region Click
  function onRegionClick(data) {
    const newRegionData = regionData.map((group) => {
      if (data === group.RegionName) {
        group.Active = true;
      } else {
        group.Active = false;
      }
      return group;
    });
    setRegionData(newRegionData);
    setSelectedRegion(data);

    const newCountryData = [];
    allCountry.forEach((group) => {
      if (data === group.RegionName || data === 'All Regions') {
        newCountryData.push({
          CountryName: group.CountryName,
          SortOrder: group.SortOrder,
          CountryUrl: group.CountryUrl,
          RegionName: group.RegionName,
          Active: true,
          isShowStateLink: group.isShowStateLink,
          VisitStateLink: group.VisitStateLink,
        });
      }
    });
    setCountryData(newCountryData);
    setSelectedCountry([]);

    const newStateData = [];
    allState.forEach((group) => {
      if (data === group.RegionName || data === 'All Regions') {
        newStateData.push({
          StateName: group.StateName,
          SortOrder: group.SortOrder,
          StateUrl: group.StateUrl,
          RegionName: group.RegionName,
          CountryName: group.CountryName,
          Active: true,
          isShowStateLink: group.isShowStateLink,
          VisitStateLink: group.VisitStateLink,
        });
      }
    });
    setStateData(newStateData);
    setSelectedState([]);

    const newLocationData = [];
    allLocation.forEach((group) => {
      if (data === group.RegionName || data === 'All Regions') {
        newLocationData.push({
          Title: group.Title,
          ServiceName: group.ServiceName,
          Address1: group.Address1,
          Address2: group.Address2,
          City: group.City,
          Zip: group.Zip,
          PhoneNumber: group.PhoneNumber,
          FaxNumber: group.FaxNumber,
          DirectionLink: group.DirectionLink,
          VisitOfficeLink: group.VisitOfficeLink,
          VisitStateLink: group.VisitStateLink,
          Content: group.Content,
          Latitude: group.Latitude,
          Longitude: group.Longitude,
          HeaderImage: group.HeaderImage,
          MetaTitle: group.MetaTitle,
          MetaDescription: group.MetaDescription,
          Url: group.Url,
          RegionName: group.RegionName,
          CountryName: group.CountryName,
          StateName: group.StateName,
          Active: true,
          OfficeCountryName: group.OfficeCountryName,
          OfficeStateName: group.OfficeStateName,
          isShowStateLink: group.isShowStateLink,
        });
      }
    });
    setLocationData(newLocationData);
  }

  // Country Click
  function onCountryClick(data) {
    const newCountryData = countryData.map((group) => {
      if (data === group.CountryName) {
        group.Active = true;
      } else {
        group.Active = false;
      }
      return group;
    });
    setCountryData(newCountryData);

    const newCountryName = [];
    allCountry.forEach((group) => {
      if (data === group.CountryName) {
        newCountryName.push({
          CountryName: group.CountryName,
          SortOrder: group.SortOrder,
          CountryUrl: group.CountryUrl,
          RegionName: group.RegionName,
          VisitStateLink: group.VisitStateLink,
          Active: true,
          isShowStateLink: group.isShowStateLink,
        });
      }
    });
    setSelectedCountry(newCountryName);

    const newStateData = [];
    allState.forEach((group) => {
      if (data === group.CountryName) {
        newStateData.push({
          StateName: group.StateName,
          SortOrder: group.SortOrder,
          StateUrl: group.StateUrl,
          RegionName: group.RegionName,
          CountryName: group.CountryName,
          VisitStateLink: group.VisitStateLink,
          Active: group.Active,
          isShowStateLink: group.isShowStateLink,
        });
      }
    });
    setStateData(newStateData);
    setSelectedState([]);

    const newLocationData = [];
    allLocation.forEach((group) => {
      if (data === group.CountryName) {
        newLocationData.push({
          Title: group.Title,
          ServiceName: group.ServiceName,
          Address1: group.Address1,
          Address2: group.Address2,
          City: group.City,
          Zip: group.Zip,
          PhoneNumber: group.PhoneNumber,
          FaxNumber: group.FaxNumber,
          DirectionLink: group.DirectionLink,
          VisitOfficeLink: group.VisitOfficeLink,
          VisitStateLink: group.VisitStateLink,
          Content: group.Content,
          Latitude: group.Latitude,
          Longitude: group.Longitude,
          HeaderImage: group.HeaderImage,
          MetaTitle: group.MetaTitle,
          MetaDescription: group.MetaDescription,
          Url: group.Url,
          RegionName: group.RegionName,
          CountryName: group.CountryName,
          StateName: group.StateName,
          Active: group.Active,
          OfficeCountryName: group.OfficeCountryName,
          OfficeStateName: group.OfficeStateName,
          isShowStateLink: group.isShowStateLink,
        });
      }
    });
    setLocationData(newLocationData);
  }

  function filterContent(data) {
    const newRegionData = regionData.map((group) => {
      if (data === group.RegionName) {
        group.active = true;
      } else {
        group.active = false;
      }
      return group;
    });

    setRegionData(newRegionData);
  }

  if (regionData && countryData && stateData && locationData) {
    return (
      <div className="outer-region-container">
        <div className="region-container">
          <SelectList
            className="d-sm-none"
            onChange={(e) => filterContent(e.target.value)}
            defaultText="— Locations —"
          >
            {regionData.map(({ RegionName, Active }) => (
              <option
                className={`filter
                    ${Active ? 'active' : ''}
                `}
              >
                {RegionName}
              </option>
            ))}
          </SelectList>
          <div className="content">
            <div className="title-filters">
              {regionData.map(({ RegionName, Active }) => (
                <p
                  onClick={() => onRegionClick(RegionName)}
                  className={`filter
                    ${Active ? 'active' : ''}
                `}
                  value={RegionName}
                >
                  {RegionName}
                </p>
              ))}
            </div>
            <hr className="seperator" />
            <div className="content-well">
              {countryData
                .sort((a, b) => (a.CountryName > b.CountryName ? 1 : -1))
                .map(({ CountryName, Active }) => (
                  <a
                    href="javascript:void(0)"
                    onClick={() => onCountryClick(CountryName)}
                    className="panel-info more-than-one filter active"
                  >
                    {CountryName}
                  </a>
                ))}
            </div>
          </div>
        </div>

        <SearchByFilter
          allCountry={allCountry}
          allState={allState}
          allLocation={allLocation}
          countryData={countryData}
          stateData={stateData}
          locationData={locationData}
          selectedRegion={selectedRegion}
          selectedCountry={selectedCountry}
          selectedState={selectedState}
        />
      </div>
    );
  }
  return <Loader />;
}

export default RegionTabs;
