import React from 'react';
import './content-well-link.styles.scss';

function ContentWell({ content }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content || '' }}
      className="content-well the-content"
    />
  );
}

export default ContentWell;
