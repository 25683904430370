import React from 'react';
import './advanced-search.styles.scss';

import SelectList from '../../form-inputs/select-list/select-list.component';
import SearchInput from '../../form-inputs/search-input/search-input.component';
import CustomButton from '../../custom-button/custom-button.component';
import useDropdownContent from '../../../../js/custom-hooks/useDropdownContent';
import useInputCollection from '../../../../js/custom-hooks/useInputCollection';
import { filterNewsMedia } from '../../../../js/api/_news-media';
import ActiveSearchFilter from '../../../training/active-search-filter-widget/active-search-filter.component';

// This component will eventually replace the "advanced-search" component
// making this one to have filtering / use hooks

// may change prop names / markup => just using originals for now
// RK Task#104 Training Secondary Change
const INITIAL_STATE = {
  KeywordPhrase: '',
  ResouceType: '',
  Industry: '',
  Location: '',
  Softwares: '',
};

function AdvancedSearchRefactor({
  title,
  subText,
  subTitle,
  resourceTextLink,
  resourceText,
  selectedCategory,
}) {
  const { locations, resourceTypes, industries, softwares } =
    useDropdownContent([
      'locations',
      'resourceTypes',
      'industries',
      'softwares',
    ]);
  const { state, updateInput } = useInputCollection(INITIAL_STATE);

  const handleSubmit = (e) => {
    e.preventDefault();

    const submissionObj = {
      Keyword: state.KeywordPhrase,
      Resource: state.ResouceType,
      Softwares: state.Softwares,
      Industry: state.Industry,
      Location: state.Location,
    };

    filterNewsMedia(submissionObj);
  };

  return (
    <div className="advanced-search">
      <h2>ADVANCED SEARCH</h2>
      {selectedCategory != null ? (
        <ActiveSearchFilter filter={selectedCategory} />
      ) : null}

      <h3>Filter your search results:</h3>
      <SearchInput
        value={state.KeywordPhrase}
        onChange={updateInput}
        name="KeywordPhrase"
        className="xs-sm-only-small-text mb-sm-2"
      />
      {/* These need to come from the api */}
      {resourceTypes && (
        <SelectList
          onChange={updateInput}
          name="ResouceType"
          defaultText="— Resource Type —"
          className="mb-2"
        >
          {resourceTypes.map((resource) => (
            <option
              className={`filter
                        `}
              value={resource.id}
            >
              {resource.Title}
            </option>
          ))}
        </SelectList>
      )}

      {softwares && (
        <SelectList
          onChange={updateInput}
          name="Softwares"
          defaultText="— Softwares —"
          className="mb-2"
        >
          {softwares.map((softwares) => (
            <option
              className={`filter
                        `}
              value={softwares.id}
            >
              {softwares.Title}
            </option>
          ))}
        </SelectList>
      )}

      {/* These need to come from api */}
      {industries && (
        <SelectList
          onChange={updateInput}
          name="Industry"
          defaultText="— Industry —"
          className="mb-2"
        >
          {industries.map((industry) => (
            <option
              className={`filter
                        `}
              value={industry.id}
            >
              {industry.Title}
            </option>
          ))}
        </SelectList>
      )}

      {locations && (
        <SelectList
          onChange={updateInput}
          name="Location"
          defaultText="— Location —"
        >
          {locations.map((location) => (
            <option
              className={`filter
                            `}
              value={location.id}
            >
              {location.Title}
            </option>
          ))}
        </SelectList>
      )}

      <CustomButton
        onClick={handleSubmit}
        className="ml-auto mr-auto d-block button-spacer"
      >
        Search
      </CustomButton>
    </div>
  );
}

export default AdvancedSearchRefactor;
