import React, { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Testimonials from '../../../components/software/testimonials/testimonials.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import Hero from '../../../components/commons/hero/hero.component';
import BoxCta from '../../../components/commons/cta/box-cta/box-cta.component';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import widgetData from '../../../components/commons/widgets/widgetBox-component/widgetBox-data';

function getHeroCtaContent(categoryContent) {
  return categoryContent.map((section) => section.Category);
}

function SoftwareSecondaryA({
  page: { MainContent, CategoryContent },
  section,
}) {
  const [heroCtas, setHeroCtas] = useState(
    CategoryContent ? getHeroCtaContent(CategoryContent) : null
  );

  const data = widgetData;
  if (MainContent) {
    return (
      <div className="software-lp software">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.Content}
          template="landing"
          downArrow
          heroCtas={heroCtas}
        />
        <div className="sm-up-trinity-container">
          <ContactCta
            Title="Contact us to learn more about our software products"
            subTitle="Contact Software Support"
            subTitleLink="/support-request"
          />
          {CategoryContent ? (
            <FilteredCtaGrid
              gridColumnClasses="col-sm-2"
              ctas={CategoryContent}
              activeStarterSection={section}
            />
          ) : (
            <h2 className="trinity-container module-spacer">
              No category content available
            </h2>
          )}
        </div>
      </div>
    );
  }

  return null;
}

export default SoftwareSecondaryA;
