import axios from 'axios';
import {
  ENDPOINTS,
  SITE_SEARCH_API_KEY,
  SITE_SEARCH_AUTHORITY,
} from '../../js/api/_config';

export class SafeBridgeServices {
  static searchForGeneralData(requestBody) {
    return axios({
      method: 'POST',
      url: ENDPOINTS.SAFE_BRIDGE_SEARCH.SAFE_BRIDGE_URL,
      headers: {
        'api-key': SITE_SEARCH_API_KEY,
        'content-type': 'application/json',
        authority: SITE_SEARCH_AUTHORITY,
      },
      data: requestBody,
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? '' : ''));
  }
}
