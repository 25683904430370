import React from 'react';
import './image-with-overlay-text-listing.styles.scss';
import ImageWithTextOverlay from '../image-with-overlay-text-cta/image-with-overlay-text-cta.component';

// RP #Task 124 Career
function ImageWithOverlayTextListing({ ctas, colorCategory }) {
  const sortedCtas = ctas
    ? ctas.sort((a, b) => {
        if (a && a.SortOrder && b && b.SortOrder)
          return parseInt(a.SortOrder) > parseInt(b.SortOrder) ? 1 : -1;
        return 0;
      })
    : [];

  return (
    <div className="image-with-overlay-text-listing sm-up-module">
      {sortedCtas &&
        sortedCtas.map((item) => (
          <div className="image-text-cta-container">
            <ImageWithTextOverlay
              category={colorCategory}
              title={item.Title}
              content={item.Content}
              inner_content={item.IntroText}
              content_link={item.url}
              image={item.IntroImage}
              ispolicies
            />
          </div>
        ))}
    </div>
  );
}

export default ImageWithOverlayTextListing;
