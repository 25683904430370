import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewRequestDataQuoteLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-request-data-quote-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-request-data-quote-content-component')
  );

  return (
    <div className="data-request-page-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewRequestDataQuoteLandingPage;
