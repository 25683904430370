import React, { useEffect } from 'react';
import './software-resources.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import SoftwareResourceListingWithFilters from '../../../components/software/software-resource-listing-with-filters/resource-listing-with-filters.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  getPageWiseSoftwareResource,
  loadFilter,
  loadSoftwareResource,
} from '../../../redux/software-resource/software-resource.action';

// RP #Task 119 Resources
function SoftwareResources({ page: { MainContent } }) {
  const dispatch = useDispatch();

  const resourceFilter = useSelector(
    ({ softwareresource }) => softwareresource.filterList
  );

  const resourceData = {
    PageNumber: 1,
    KeywordorPhrase: [],
    ModelingCategory: [],
    Product: [],
    Industry: [],
    Services: [],
    Location: [],
    SoftwareResourceType: [],
  };

  useEffect(() => {
    dispatch(loadSoftwareResource(true));
    dispatch(getPageWiseSoftwareResource(resourceData));
    dispatch(loadFilter());
  }, []);

  if (MainContent && resourceFilter) {
    return (
      <div className="software resources">
        <Hero
          src={MainContent.HeaderImage_src ? MainContent.HeaderImage_src : ''}
          mobileImgSrc={
            MainContent.MobileImage_src ? MainContent.MobileImage_src : ''
          }
          alt={MainContent.HeaderImage_alt ? MainContent.HeaderImage_alt : ''}
          template="standard"
          title={MainContent.Title ? MainContent.Title : ''}
        />
        <div className="sm-up-trinity-container">
          <SoftwareResourceListingWithFilters />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareResources;
