import React, { Suspense, useEffect } from 'react';
import './attendee.styles.scss';

import Row from 'react-bootstrap/Row';
import * as Sentry from '@sentry/react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import { ENDPOINTS } from '../../../js/api/_config';
import AssignAttendeeHeaderComponent from './AssignAttendeeHeaderComponent';
import AssignAttendeeWrapperComponent from './AssignAttendeeWrapperComponent';

function AttendeePage() {
  const [contentData, setContentData] = React.useState(null);

  useEffect(() => {
    try {
      async function getDataFromURL() {
        const response = await getPageDataByApiUrl(
          ENDPOINTS.USER_CART_ACCOUNT.ATTENDEES_CART_URL
        );
        return response;
      }

      getDataFromURL().then((response) => {
        setContentData(response);
      });
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in hero data API');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in hero data API',
        },
      });
    }
  }, []);

  // const AssignAttendeeHeaderComponent = React.lazy(() => import("./AssignAttendeeHeaderComponent"));
  // const AssignAttendeeWrapperComponent = React.lazy(() => import("./AssignAttendeeWrapperComponent"));

  // if (!contentData) {
  //     return <CartLoader/>
  // }

  return (
    <Row className="attendee-page-container module-bottom-spacer sm-up-trinity-container">
      {/* <Suspense fallback={<Loader/>}> */}
      <AssignAttendeeHeaderComponent MainContent={contentData?.MainContent} />
      {/* </Suspense> */}

      {/* <Suspense fallback={<Loader/>}> */}
      <AssignAttendeeWrapperComponent
        CustomDropDownContent={contentData?.CustomDropDownContent}
        MainContent={contentData?.MainContent}
      />
      {/* </Suspense> */}
    </Row>
  );
}

export default AttendeePage;
