import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class TrackingServices {
  static getGA4Categories(id) {
    return axios({
      method: 'GET',
      url: `${ENDPOINTS.ANALYTICS_SEARCH}/${id}`,
      //   headers: {
      //     'api-key': SITE_SEARCH_API_KEY,
      //     'content-type': 'application/json',
      //     authority: SITE_SEARCH_AUTHORITY,
      //   },
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }
}
