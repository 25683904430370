// Image Component Constants

// export const CLASSROOM_URL = "/training/classroom";
// export const WEBINAR_URL = "/training/webinar";
// export const SEMINAR_URL = "/training/seminar";
// export const E_LEARNING_URL = "/training/complimentary";
export const COURSESUBSCRIPTIONURL =
  '/training/search?ds%5B0%5D=ByFormat%7CCourseSubscription';
export const CLASSROOM_URL = '/training/search?ds%5B0%5D=ByFormat%7CClassroom';
export const WEBINAR_URL = '/training/search?ds%5B0%5D=ByFormat%7CWebinar';
export const SEMINAR_URL = '/training/search?ds%5B0%5D=ByFormat%7CSeminar';
export const E_LEARNING_URL =
  '/training/search?sbt=&rs%5Bmin%5D=1645601297495&rs%5Bmax%5D=1677137297495&pg=1&drp=DatePublished%20asc&prs%5Bmin%5D=0&prs%5Bmax%5D=0'; // RP - SD39 : Training page- Advanced search Filter
export const COMPLIMENTARY_URL =
  '/training/search?sbt=&rs%5Bmin%5D=1676692038754&rs%5Bmax%5D=1708228038754&pg=1&drp=DatePublished%20asc&prs%5Bmin%5D=0&prs%5Bmax%5D=0';
