import React, { Suspense, useEffect } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './news-individual-article.styles.scss';
import { getPageDataByApiUrl } from '../../../js/api/get-pages.data';
import { Helmet } from 'react-helmet';
import NotFoundPage from '../../specialty-pages/error-display/not-found.page';

function NewNewsIndividualArticleLandingPage() {
  const [mainContent, setMainContent] = React.useState({});
  const [strPublishedDate, setStrPublishedDate] = React.useState('');

  const ContentComponent = React.lazy(() =>
    import('./new-news-individual-article-content-component')
  );
  const AuthorComponent = React.lazy(() =>
    import('./new-news-individual-article-author-component')
  );
  const TagComponent = React.lazy(() =>
    import('./new-news-individual-article-tag-component')
  );

  useEffect(async () => {
    const response = await getPageDataByApiUrl(
      `/restapi/customnewscontentlvl2${window.location.pathname}`
    );
    if (response) {
      setMainContent(response.MainContent && response.MainContent);
      setStrPublishedDate(
        response.strPublishedDate && response.strPublishedDate
      );
    }
  }, [window.location]);

  //https://sentry.io/organizations/tci-cp/issues/2717855861/?project=5967072

  if (mainContent === undefined) {
    return <NotFoundPage />;
  }

  if (mainContent && Object.keys(mainContent).length > 0) {
    return (
      <>
        <Helmet link={[{ rel: 'canonical', href: window.location.href }]}>
          <meta charSet="utf-8" />
          {mainContent.MetaDescription && (
            <meta name="description" content={mainContent.MetaDescription} />
          )}
          {mainContent.MetaTitle && <title>{mainContent.MetaTitle}</title>}
          <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div className="indiv-container indiviual">
          <div className="trinity-container module-spacer">
            <Suspense fallback={<Loader />}>
              <ContentComponent
                MainContent={mainContent}
                strPublishedDate={strPublishedDate}
              />
              <AuthorComponent MainContent={mainContent} />
              <TagComponent MainContent={mainContent} />
            </Suspense>
          </div>
        </div>
      </>
    );
  }
  return <Loader />;
}

export default NewNewsIndividualArticleLandingPage;
