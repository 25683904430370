import React, { useEffect, useState } from 'react';
import './training-secondary.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import useInputCollection from '../../../js/custom-hooks/useInputCollection';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';

const INITIAL_STATE = {
  KeywordPhrase: '',
  ResouceType: '',
  Industry: '',
  Location: '',
  Softwares: '',
};
// RK Task#104 Training Secondary Change
// JP #Task 41 RK Task#100
function TrainingSecondarySeminar({
  page: {
    Product,
    MainContent,
    CategoryContent,
    TrainingCategory,
    TrainingSecondaryProductsByType,
    TrainingSecondaryProducts,
    TrainingSelectedCategory,
    TrainingSelectedCategoryId,
  },
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [content, setContent] = useState(null);
  // const [loading, setLoading] = useState(true);
  const { state, updateInput } = useInputCollection(INITIAL_STATE);

  const searchItem = useSelector((state) => state.training);
  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const dispatch = useDispatch();

  const format = { Name: 'Seminar', ID: '53' };
  const pformat = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: ['Seminar'],
      TrainingCategory: [],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: [],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(selectTrainingActionFilter(format, pformat, 'Training_Format'));
    submit();
  }, []);

  return (
    <div className="training training-secondary">
      <Hero
        src={
          MainContent.HeaderImage_src
            ? MainContent.HeaderImage_src
            : heroImageSrc
        }
        alt={`${TrainingCategory.Name} image`}
        mobileImgSrc={MainContent?.HeaderMobileImage_src}
        title={TrainingCategory.Name}
        template="standard" // Changed from Landing to Standard
        // search={true}
        // description={TrainingCategory.description} //Need to add category description
        link={heroLink}
        colorCategory="train"
      />
      <div className="trinity-container">
        {/* <TrainingListingWithFilters Product={TrainingSecondaryProducts!=undefined?TrainingSecondaryProducts:TrainingSecondaryProductsByType} TrainingSelectedCat={TrainingCategory} /> */}
        <TrainingAdvanceSearchFilterDataListing Product={Product} />
      </div>
    </div>
  );
}

export default TrainingSecondarySeminar;
