import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { content, blurb } from './education-program.data';
import Image from '../../../../images/about/Eucation Program - About BREEZE.jpg';

import './education-program.styles.scss';

function EducationProgramWidget() {
  return (
    <div className="program-widget-container">
      <p className="content">{content}</p>
      <p className="blurb">{blurb}</p>
    </div>
  );
}

export default EducationProgramWidget;
