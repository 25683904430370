import React from 'react';
import './software-box-cta.styles.scss';

import { SANGEA_LINK } from '../../../../js/api/_config';

function SoftwareBoxCTA({
  title,
  className,
  smallBoxCta,
  subTitle,
  content,
  link,
  color,
  categories,
  transparent,
  test,
  isFromSoftware,
}) {
  return (
    <a
      className={`
                ${className || ''}
                software-box-cta custom-border xs-only-trinity-container
                ${transparent ? 'transparent' : ''} 
                ${categories ? categories[0].id : ''}
                ${smallBoxCta ? 'small-software-box-cta' : ''}
                ${color}
            `}
      href={title === 'SANGEA' ? SANGEA_LINK : link}
      target={title === 'SANGEA' ? '_blank' : '_self'}
      rel="noreferrer"
    >
      {title && (
        <div className="title">
          <div dangerouslySetInnerHTML={{ __html: title }} />{' '}
          {/* NL # TASK 40 */}
          {smallBoxCta && <span className="arrow d-none d-sm-inline">›</span>}
        </div>
      )}
      {subTitle && <div className="sub-title">{subTitle}</div>}
      {content && isFromSoftware ? (
        <div className="custom-block">{content}</div>
      ) : (
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
      {link ? (
        <div className="bottom-link">
          <span className="link-text">
            Learn More <span className="arrow">›</span>
          </span>
        </div>
      ) : (
        ''
      )}
    </a>
  );
}

export default SoftwareBoxCTA;
