import { ENDPOINTS } from '../_config';

export const getUserIsLoggedin = async () => {
  try {
    const auth = `Bearer ${localStorage.getItem('access-token')}`;
    const location = ENDPOINTS.PROXY + ENDPOINTS.CHECK_USER_LOGGEDIN;
    const settings = {
      method: 'GET',
      headers: {
        Authorization: auth,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      const response = await fetchResponse.json();
      return response.LoggedIn;
    }
    // const response = await request.json();
    // const fetchResponse = await fetch(location, settings);
    return false;
  } catch (e) {
    return false;
  }
};
