import React, { useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TrackGoogleAnalyticsEvent,
  trackSoftwareGA4Event,
} from '../../../../TrackGoogleAnalytics';
import { GA4_EVENT_MAPPPING } from '../../../../js/api/_config';
import { getUserIsLoggedin } from '../../../../js/api/auth/_login';
import {
  addCartItem,
  getCartItem,
} from '../../../../js/api/shopping-cart/_shopping-cart';
import {
  addSoftwareItemToCart,
  cartCountUpdate,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import { CartActionTypes } from '../../../../redux/shopping-cart/shopping-cart.types';
import { UserActionTypes } from '../../../../redux/user/user.types';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import './product-list-item.styles.scss';

function SidebarProductListItem({
  title,
  price,
  maxQty,
  productId,
  productData,
  mainContent,
  ...otherProps
}) {
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user.tokens.access_token);
  const loggedInUserData = useSelector(({ user }) => user.loggedin_user);

  const [productQty, setproductQty] = useState(1);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const history = useHistory();
  const location = useLocation();

  const handleQtyChange = (event) => {
    const qty = event.target.value;
    setproductQty(qty);
  };

  const handleAddToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, productQty);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
      }
    } else {
      trackSoftwareGA4Event(productData, mainContent, productQty, false);
      const data = {
        productid: event.target.id,
        quantity: productQty,
        isSoftware: true,
        softwareData: {
          mainContent,
          productData,
          productQty,
        },
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter(location.pathname);
      history.push('/login');
    }
  };

  const handleBuyNowToShoppingCart = async (event) => {
    if (user) {
      const getcartResponse = await addItemToCart(event.target.id, productQty);
      if (getcartResponse === 0) {
        dispatch(cartCountUpdate(1));
        history.push('/shopping-cart');
      }
    } else {
      trackSoftwareGA4Event(productData, mainContent, productQty, false);
      const data = {
        productid: event.target.id,
        quantity: productQty,
        isSoftware: true,
        softwareData: {
          mainContent,
          productData,
          productQty,
        },
      };
      dispatch(addSoftwareItemToCart(data));
      addRouter('/shopping-cart');
      history.push('/login');
    }
  };

  const fetchUserIsLoggedin = async () => {
    const getResponse = await getUserIsLoggedin();
    return getResponse;
  };

  const addItemToCart = async (itemid, itemqty) => {
    trackSoftwareGA4Event(productData, mainContent, productQty, true);

    let memberId = null;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    const data = {
      productid: itemid,
      quantity: itemqty,
      memberid: memberId,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };

  const getCartItems = async () => {
    const getResponse = await getCartItem(null);
    return getResponse;
  };

  const addRouter = useCallback(
    (redirect_router) =>
      dispatch({
        type: UserActionTypes.REDIRECT_ROUTER,
        payload: {
          redirect_router,
        },
      }),
    [dispatch]
  );

  const addCartItems = useCallback(
    (cart_items) =>
      dispatch({
        type: CartActionTypes.GET_CART_ITEM,
        payload: {
          cart_items,
        },
      }),
    [dispatch]
  );

  const updateCartCount = useCallback(
    (countUpdate) =>
      dispatch({
        type: CartActionTypes.CART_COUNT_UPDATE,
        payload: {
          countUpdate,
        },
      }),
    [dispatch]
  );

  maxQty = new Array(maxQty).fill('');

  if (price) {
    return (
      <div
        className="product-list-item"
        style={{ padding: '1px' }}
        {...otherProps}
      >
        <div className="title-price-container">
          <h3 className="title">{title}</h3>
          <p className="price">${price}</p>
        </div>
        <div className="quantity">
          <label htmlFor="quantity-select">Qty:</label>
          <select
            name=""
            id={`quantity-select-${productId}`}
            onChange={handleQtyChange}
          >
            {maxQty.map((x, i) => (
              <option key={i} value={`${i + 1}`}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="buttons">
          <CustomButton
            rounded
            arrow
            altColors
            id={productId}
            onClick={handleAddToShoppingCart}
          >
            Add to Cart &gt;
          </CustomButton>
          <CustomButton
            rounded
            arrow
            id={productId}
            onClick={handleBuyNowToShoppingCart}
          >
            Buy Now &gt;
          </CustomButton>
        </div>
      </div>
    );
  }

  return null;
}

export default SidebarProductListItem;
