import BreezeLogo from '../../../images/homepage/breeze.svg';
import AdventLogo from '../../../images/homepage/advent.svg';
import SafebridgeLogo from '../../../images/homepage/safebridge.svg';
import TrispyrianLogo from '../../../images/homepage/trispyrian.svg';

export const logos = [
  {
    image: BreezeLogo,
  },
  {
    image: AdventLogo,
  },
  {
    image: SafebridgeLogo,
  },
  {
    image: TrispyrianLogo,
  },
];
