// currently returns the url and the title
// => only from Category, not from SubContent (could change later)
export const getLinksFromCategoryContent = (CategoryContent) => {
  const mappedCategories = CategoryContent.map(({ Category }) => ({
    url: Category.url,
    text: Category.Title,
    sortOrder: Category.SortOrder,
  }));

  return mappedCategories.sort((a, b) => {
    if (a && a.sortOrder && b && b.sortOrder)
      return parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1;
    return 0;
  });
};
