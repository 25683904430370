import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewSoftwareLandingPage({ page: { MainContent, breadCrumbList } }) {
  const HeroComponent = React.lazy(() =>
    import('./new-software-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-software-content-component')
  );

  return (
    <div className="consulting-landing consulting relative">
      <Suspense fallback={<Loader />}>
        <HeroComponent data={MainContent} breadCrumbList={breadCrumbList} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent />
      </Suspense>
    </div>
  );
}

export default NewSoftwareLandingPage;
