import React, { Suspense } from 'react';
import './EHSPublication.scss';
import Loader from '../commons/helpers/loader/loader.component';

function EHSPublicationPage() {
  const EHSHeaderComponent = React.lazy(() => import('./EHSHeaderComponent'));
  const EHSContentComponent = React.lazy(() => import('./EHSContentComponent'));

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <EHSHeaderComponent />
      </Suspense>

      <Suspense fallback={<Loader />}>
        <EHSContentComponent />
      </Suspense>
    </div>
  );
}

export default EHSPublicationPage;
