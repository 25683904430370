import TechnologyLpModel from './technology-models/technology-lp.model';
import TechnologySecondary from './technology-models/technology-secondary.model';

const technologyPages = [
  // {
  //   name: "Technology",
  //   path: "/environmental-technology",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "technologyLp",
  //   modules: TechnologyLpModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Technology Services",
  //       hero_description: `<p>
  //                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit. Malesuada fames ac turpis egestas integer. Tellus mauris a diam maecenas sed enim ut sem. Nunc aliquet bibendum enim facilisis. Ac odio tempor orci dapibus. Risus in hendrerit gravida rutrum. Ligula ullamcorper malesuada proin libero nunc consequat interdum. Porttitor leo a!
  //               </p>`,
  //     },
  //     ctas: [
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //       {
  //         categories: [
  //           {
  //             title: "Breeze",
  //             id: "breeze",
  //           },
  //         ],
  //         title: "Strategy & Planning",
  //         content: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
  //         link: {
  //           href: "daafaf",
  //           text: "fakjnafkl",
  //         },
  //       },
  //     ],
  // }),
  // },
  // {
  //   name: "Our Approach",
  //   path: "/our-approach",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "technology-secondary",
  //   modal: TechnologySecondary({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Our Approach",
  //     },
  //   }),
  // },
];

export default JSON.stringify(technologyPages);
