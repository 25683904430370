import React from 'react';
import Pagination from 'react-js-pagination';
import { useDispatch } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
  tabListConstant,
  trinitySearchRelevanceChanges,
} from '../../../utilities/CommonUtilities';
import TrainingServiceResourceCard from '../common-components/TrainingServicesResourceCard';
import SoftwareListingResourceCard from '../common-components/SoftwareListingResourceCard';
import NewsServicesResourceCard from '../common-components/NewsServicesResourceCard';

function AllComponentRootPage(props) {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="root-search-outer-top-container">
        <div className="left-outer-search-container">
          {props.totalDataCount > 0 ? (
            props.totalDataCount < 10 ? (
              <span>
                {' '}
                Showing
                {props.totalDataCount} results{' '}
              </span>
            ) : (
              <span>
                {' '}
                Showing {props.activePage * 10 - 9} - {props.activePage * 10} of{' '}
                {props.totalDataCount} results{' '}
              </span>
            )
          ) : (
            <span> No Data Available </span>
          )}
        </div>

        {/* RP - ADVANCED SEARCH FILTERS */}
        <div className="right-outer-search-container">
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel id="relevance-outlined-label">Sort By</InputLabel>
            <Select
              labelId="relevance-label"
              id="relevance-selector"
              value={
                !props.filterSelection || props.filterSelection === ''
                  ? 'relevance'
                  : props.filterSelection
              }
              // value={props.filterSelection === "" ? "relevance" : props.filterSelection}
              onChange={(e) => props.onChangeDropDownData(e.target.value)}
              label="Relevance"
            >
              <MenuItem value="relevance">Relevance</MenuItem>
              {trinitySearchRelevanceChanges.map((element) => (
                <MenuItem value={element.key}>{element.value}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {props.dataList &&
        props.dataList.map((element, index) => {
          if (
            element.Category === tabListConstant.news ||
            element.Category === tabListConstant.services ||
            element.Category === tabListConstant.resources
          ) {
            return (
              <div style={{ marginTop: index === 0 ? '' : '1rem' }}>
                <NewsServicesResourceCard
                  elementData={element}
                  category={element.Category}
                />
              </div>
            );
          }
          if (element.Category === tabListConstant.trainings) {
            return (
              <div style={{ marginTop: index === 0 ? '' : '1rem' }}>
                <TrainingServiceResourceCard
                  elementData={element}
                  category={element.Category}
                />
              </div>
            );
          }
          return (
            <div style={{ marginTop: index === 0 ? '' : '1rem' }}>
              <SoftwareListingResourceCard
                elementData={element}
                category={element.Category}
              />
            </div>
          );
        })}

      {/* <div style={{marginTop: "1rem"}}>
                <TrainingServiceResourceCard/>
            </div>
            <div style={{marginTop: "1rem"}}>
                <SoftwareListingResourceCard/>
            </div> */}

      {props.dataList && props.dataList.length > 0 && (
        <div className="bottom-pagination-trinity-site-search">
          <Pagination
            prevPageText="Previous"
            nextPageText="Next"
            activePage={props.activePage}
            itemsCountPerPage={10}
            totalItemsCount={props.totalDataCount}
            pageRangeDisplayed={3}
            onChange={props.handlePageChange}
          />
        </div>
      )}
    </div>
  );
}

export default AllComponentRootPage;
