import React, { useEffect, useState } from 'react';
import SafeBridgeSearchPostList from './SafeBridgeSearchPostList';
import { makeStyles, SwipeableDrawer } from '@material-ui/core';
import './SafeBridgeRootComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'react-js-pagination';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import SafeBridgeFilterRootComponent from './SafeBridgeFilterRootComponent';
import {
  processTrainingParameterAndSendQuery,
  safeBridgeRelevance,
  sendToURL,
} from '../../utilities/CommonUtilities';
import { handleNotify } from '../../js/helpers/toaster/toaster-notify';
import { ToasterTypes } from '../../js/helpers/toaster/toaster-types';
import {
  getSafeBridgeAzureSearchData,
  safeBridgeSearchConstant,
  staticSafeBridgeSearchData,
} from '../../redux/safe-bridge/safe-bridge-action';

function SafeBridgeSearchRootComponent() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [openSwappableDrawer, setOpenSwappableDrawer] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [dynamicSearchData, setDynamicSearchData] = React.useState([]);
  const [searchBoxText, setSearchBoxText] = React.useState('');

  const [filterSelection, setFilterSelection] = React.useState('');

  const [rangeState, setRangeState] = useState({
    min: moment(safeBridgeSearchConstant).toDate().getTime(),
    max: moment().toDate().getTime(),
  });

  const safeBridgeAzureSearchData = useSelector(
    (state) => state.safeBridgeAzureSearchData.currentSearchData
  );
  const lastSearchData = useSelector(
    (state) => state.safeBridgeAzureSearchData.lastSearchData
  );

  useEffect(() => {
    let data = {};

    if (window.location.search.length > 0) {
      data = qs.parse(window.location.search.substring(1));

      if (data?.ds) setDynamicSearchData(data?.ds);

      if (data?.rs) {
        setRangeState({
          min: Number.parseInt(data?.rs.min),
          max: Number.parseInt(data?.rs.max),
        });
      }

      if (data?.sbt) setSearchBoxText(data?.sbt);

      if (data?.drp) setFilterSelection(data?.drp);

      if (data?.pg) setActivePage(data?.pg);

      setTimeout(
        () =>
          dispatch(
            getSafeBridgeAzureSearchData(
              processTrainingParameterAndSendQuery(
                staticSafeBridgeSearchData,
                data
              )
            )
          ),
        1500
      );
    } else {
      dispatch(getSafeBridgeAzureSearchData(staticSafeBridgeSearchData));
    }
  }, [window.location]);

  useEffect(() => {
    setFilterSelection(lastSearchData.orderby);
  }, [lastSearchData]);

  const toggleDrawer = () => {
    setOpenSwappableDrawer((e) => !e);
  };

  const getFilterButton = () => (
    <div className="resource-filter-btn">
      <div style={{ marginBottom: '1rem' }}>
        {safeBridgeAzureSearchData.totalDocumentCount > 0 ? (
          safeBridgeAzureSearchData.totalDocumentCount < 10 ? (
            <span>
              {' '}
              Showing
              {safeBridgeAzureSearchData.totalDocumentCount} results{' '}
            </span>
          ) : (
            <span>
              {' '}
              Showing {activePage * 10 - 9} - {activePage * 10} of{' '}
              {safeBridgeAzureSearchData.totalDocumentCount} results{' '}
            </span>
          )
        ) : (
          <span> No Results Available </span>
        )}
      </div>

      <button className="button-style" onClick={toggleDrawer}>
        <i
          className="fa fa-filter"
          style={{ fontSize: '20px' }}
          aria-hidden="true"
        />
      </button>
    </div>
  );

  const handlePageChange = (pageNumber) => {
    // let newObject = Object.assign({}, lastSearchData, {"skip": (pageNumber * 10) - 10})
    // dispatch(getSafeBridgeAzureSearchData(newObject))
    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: rangeState,
      pg: pageNumber,
      drp: filterSelection,
    };
    sendToURL(history, data);
    setActivePage(pageNumber);
  };

  const onChangeDynamicCheckBox = (parentKey, selectedValue, isChecked) => {
    let preparedArray = [];

    if (isChecked) {
      preparedArray = dynamicSearchData.concat(`${parentKey}|${selectedValue}`);
      setDynamicSearchData(preparedArray);
    } else {
      preparedArray = dynamicSearchData.filter(
        (e) => e !== `${parentKey}|${selectedValue}`
      );
      setDynamicSearchData(preparedArray);
    }

    const newArray = preparedArray.map((outerElement, index) => {
      const key = outerElement.split('|')[0];
      const value = outerElement.split('|')[1];

      if (preparedArray.length - 1 === index) {
        return `(${key} eq '${value}')`;
      }
      return `(${key} eq '${value}') and `;
    });

    const dynamicString = newArray.toString().split(',').join('');
    const preparedDate = `${moment(rangeState.min).toISOString()}|${moment(
      rangeState.max
    ).toISOString()}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;
    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );

    let finalFilterString = '';

    finalFilterString =
      dynamicString.replace(/[ ]/g, '').length > 0
        ? // ? "DatePublished ge " + moment(rangeState.min).toISOString() + " and DatePublished le " + moment(rangeState.max).toISOString() + " and " + dynamicString
          dynamicString
        : '';

    const newObject = {
      ...lastSearchData,
      filter: finalFilterString,
      facets: finalChangedData
        .filter((e) => e !== e.toString().includes('DatePublished'))
        .concat(newDateString),
      skip: 0,
    };

    dispatch(getSafeBridgeAzureSearchData(newObject));

    setTimeout(() => {
      const data = {
        ds: preparedArray,
        sbt: searchBoxText,
        pg: 1,
        drp: filterSelection,
      };
      console.log(data);
      sendToURL(history, data);

      setActivePage(1);
    }, 1000);
  };

  const resetAllFiltersAndSearch = () => {
    if (lastSearchData !== staticSafeBridgeSearchData) {
      setOpenSwappableDrawer(false);
      setActivePage(1);
      setDynamicSearchData([]);
      setSearchBoxText('');
      setRangeState({
        min: moment(safeBridgeSearchConstant).toDate().getTime(),
        max: moment().toDate().getTime(),
      });
      dispatch(getSafeBridgeAzureSearchData(staticSafeBridgeSearchData));
      window.history.replaceState(null, '', `${history?.location?.pathname}`);
    } else {
      handleNotify('There are no filters applied.', ToasterTypes.Error);
    }
  };

  const rangePickerChangeComplete = (value) => {
    const preparedDate = `${moment(value.min).toISOString()}|${moment(
      value.max
    ).toISOString()}`;
    const newDateString = `DatePublished,values: ${preparedDate}`;

    const finalChangedData = lastSearchData.facets.filter(
      (e) => !e.includes('DatePublished')
    );

    const currentData = lastSearchData.filter.toString().split('and');

    const preparedData = currentData
      .map((element, index) => {
        if (element.includes('DatePublished')) {
          return '';
        }
        if (currentData.length - 1 === index) {
          return element;
        }
        return `${element} and `;
      })
      .toString()
      .split(',')
      .join('');

    let newFilters = '';

    if (preparedData.length === 0) {
      newFilters = `DatePublished ge ${moment(
        rangeState.min
      ).toISOString()} and DatePublished le ${moment(
        rangeState.max
      ).toISOString()}`;
    } else {
      newFilters = `${preparedData} DatePublished ge ${moment(
        rangeState.min
      ).toISOString()} and DatePublished le ${moment(
        rangeState.max
      ).toISOString()}`;
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      rs: value,
      pg: activePage,
      drp: filterSelection,
    };
    setTimeout(() => {
      const newObject = {
        ...lastSearchData,
        facets: finalChangedData
          .filter((e) => e !== e.toString().includes('DatePublished'))
          .concat(newDateString),
        filter: newFilters,
      };

      sendToURL(history, data);
      dispatch(getSafeBridgeAzureSearchData(newObject));
    }, 2000);
  };

  const onSearchEnter = () => {
    let searchTextData = '';

    if (searchBoxText.trim().toString().length === 0) {
      searchTextData = '*';
    } else {
      searchTextData = `ShortDescription: ${searchBoxText} OR LongDescription: ${searchBoxText} OR Title: ${searchBoxText}`;
    }

    const newObject = { ...lastSearchData, search: searchTextData };
    dispatch(getSafeBridgeAzureSearchData(newObject));

    setTimeout(() => {
      const data = {
        ds: dynamicSearchData,
        sbt: searchBoxText,
        rs: rangeState,
        pg: 1,
        drp: filterSelection,
      };
      sendToURL(history, data);

      setActivePage(1);
    }, 1000);
  };

  const onChangeHandleChange = (value) => {
    setRangeState(value);
  };

  const getCommonResourceFilter = () => (
    <SafeBridgeFilterRootComponent
      searchBoxText={searchBoxText}
      setSearchBoxText={setSearchBoxText}
      onSearchEnter={onSearchEnter}
      dynamicSearchData={dynamicSearchData}
      openSwappableDrawer={openSwappableDrawer}
      rangeState={rangeState}
      setRangeState={setRangeState}
      onChangeHandleChange={onChangeHandleChange}
      rangePickerChangeComplete={rangePickerChangeComplete}
      facetsList={
        safeBridgeAzureSearchData &&
        safeBridgeAzureSearchData.preparedSearchFacets
      }
      onChangeDynamicCheckBox={onChangeDynamicCheckBox}
      isShowRedIcon={lastSearchData === staticSafeBridgeSearchData}
      resetAllFiltersAndSearch={resetAllFiltersAndSearch}
    />
  );

  const onChangeDropDownData = (key) => {
    let newObject = {};
    if (key === 'relevance') {
      newObject = { ...lastSearchData, orderby: '' };
    } else {
      newObject = { ...lastSearchData, orderby: key };
    }

    const data = {
      ds: dynamicSearchData,
      sbt: searchBoxText,
      pg: activePage,
      drp: key,
    };
    sendToURL(history, data);

    dispatch(getSafeBridgeAzureSearchData(newObject));
  };

  return (
    <div>
      {/* KT add resource-search-outer-container-changed -- 10/12/2021 */}
      <div className="resource-search-outer-container resource-search-outer-container-changed">
        <div className="main-content">
          <div className="search-filter-container">{getFilterButton()}</div>
          <div className="activity-container">
            <div className="active-resource-left-column active-resource-left-column-changed">
              {/* KT add class active-search-filter-changed -- 10/12/2021 */}
              <div className="active-search-filter active-search-filter-changed">
                {getCommonResourceFilter()}

                <div className="active-search-filter desktop-mode active-search-filter-changed">
                  <SwipeableDrawer
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    anchor="right"
                    open={openSwappableDrawer}
                    onClose={toggleDrawer}
                    onOpen={toggleDrawer}
                  >
                    {getCommonResourceFilter()}
                  </SwipeableDrawer>
                </div>
              </div>
            </div>

            <div className="active-block">
              <div>
                <div className="root-search-outer-top-container">
                  <div className="left-outer-search-container">
                    <div style={{ marginBottom: '1rem' }}>
                      {safeBridgeAzureSearchData.totalDocumentCount > 0 ? (
                        safeBridgeAzureSearchData.totalDocumentCount < 10 ? (
                          <span>
                            {' '}
                            Showing{' '}
                            {safeBridgeAzureSearchData.totalDocumentCount}{' '}
                            results{' '}
                          </span>
                        ) : (
                          <span>
                            {' '}
                            Showing {activePage * 10 - 9} - {activePage * 10} of{' '}
                            {safeBridgeAzureSearchData.totalDocumentCount}{' '}
                            results{' '}
                          </span>
                        )
                      ) : (
                        <span> No Results Available </span>
                      )}
                    </div>
                  </div>

                  <div className="right-outer-search-container">
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="relevance-outlined-label">
                        Sort By
                      </InputLabel>
                      <Select
                        labelId="relevance-label"
                        id="relevance-selector"
                        value={!filterSelection ? 'relevance' : filterSelection}
                        onChange={(e) => onChangeDropDownData(e.target.value)}
                        label="Relevance"
                      >
                        <MenuItem value="relevance">Relevance</MenuItem>
                        {safeBridgeRelevance.map((element) => (
                          <MenuItem value={element.key}>
                            {element.value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>

              <SafeBridgeSearchPostList
                activePage={activePage}
                valueList={safeBridgeAzureSearchData?.value}
              />

              {safeBridgeAzureSearchData &&
                safeBridgeAzureSearchData?.value?.length > 0 && (
                  <div className="resource-search-pagination-outer">
                    <Pagination
                      className="pagination"
                      prevPageText="Previous"
                      nextPageText="Next"
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={
                        safeBridgeAzureSearchData.totalDocumentCount
                      }
                      pageRangeDisplayed={3}
                      onChange={handlePageChange}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 400,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 350,
  },
}));

export default SafeBridgeSearchRootComponent;
