import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './cta-grid.styles.scss';
import {
  title,
  grid_title_1,
  grid_title_2,
  content,
  link,
  image,
  name,
  person_content,
  person_title,
  person_link,
} from './cta-grid.data';
import { truncatetext } from '../../../../../js/helpers/_truncate-text';

function AboutLPGrid({ data, mainData, status, colorCategory = '' }) {
  // #RP - Task  - Bind data to AboutUs Form in Front-End.
  const [noContent] = useState(204);

  const mainTitle =
    status != noContent && mainData != null ? mainData.Subtitle : '';

  const leftContentTitle =
    status != noContent && mainData != null ? mainData.LeftContentTitle : '';
  const leftContent =
    status != noContent && mainData != null
      ? truncatetext(mainData.LeftContent, 170)
      : '';
  const leftContentLink =
    status != noContent && mainData != null ? mainData.LeftContentLink : '';

  const centerContentTitle =
    status != noContent && mainData != null ? mainData.CenterContentTitle : '';
  const centerContent =
    status != noContent && mainData != null
      ? truncatetext(mainData.CenterContent, 170)
      : '';
  const centerContentLink =
    status != noContent && mainData != null ? mainData.CenterContentLink : '';

  const authorName =
    status != noContent && data != null ? data.Author[0].Name : '';
  const authorTitle =
    status != noContent && data != null ? data.Author[0].Title : '';
  const authorCompany =
    status != noContent && data != null ? data.Author[0].Company : '';
  const authorContent =
    status != noContent && data != null
      ? truncatetext(data.Author[0].Content, 170)
      : '';
  const authorLink =
    status != noContent && data != null ? data.Author[0].AuthorUrl : '';
  const authorImage =
    status != noContent && data != null ? data.Author[0].ImageUrl : '';

  return (
    // KT add class box-container-changed 10-22-2021
    <div className="box-container box-container-changed">
      <p className="title" dangerouslySetInnerHTML={{ __html: mainTitle }} />
      <div className="ctas">
        <div className="inner-grid-container box-executive">
          <div className="content ">
            {leftContentTitle && (
              <p
                className="name"
                dangerouslySetInnerHTML={{ __html: leftContentTitle }}
              />
            )}
            {leftContent && (
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: leftContent }}
              />
            )}
            {leftContentLink && <a href={leftContentLink}>Learn more...</a>}
          </div>
        </div>
        <div className="inner-grid-container box-operation">
          <div className="content ">
            {centerContentTitle && (
              <p
                className="name"
                dangerouslySetInnerHTML={{ __html: centerContentTitle }}
              />
            )}
            {centerContent && (
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: centerContent }}
              />
            )}
            {centerContentLink && <a href={centerContentLink}>Learn more...</a>}
          </div>
        </div>
        <div className="image-box">
          <img src={authorImage} alt="" />
        </div>
        <div className="inner-grid-container box-staff">
          <div className="content ">
            {authorName && (
              <p className="name">
                {authorName}
                <span className="job_title">
                  {authorTitle}
                  {authorCompany != '' ? `, ${authorCompany}` : ''}
                </span>
              </p>
            )}
            {authorContent && (
              <p
                className="text"
                dangerouslySetInnerHTML={{ __html: authorContent }}
              />
            )}
            {authorLink && <a href={authorLink}>Learn more...</a>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutLPGrid;
