import React from 'react';
import './resource-listing-with-filters.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useDispatch, useSelector } from 'react-redux';
import ResourceListing from '../resource-listing/resource-landing.component';
import SidebarButton from '../../commons/sidebar-with-filters/sidebar-button/sidebar-button.component';
import {
  activePageNumber,
  getNewPageWiseResource,
  loadResource,
} from '../../../redux/resource/resource.action';
import ResourceAdvanceFilter from '../resource-listing/resource-advance-filter/resource-advance-filter.component';
import Loader from '../../commons/helpers/loader/loader.component';

// RP #Task 119 Resources
function ResourceListingWithFilter() {
  const dispatch = useDispatch();
  const pkeyword = useSelector(({ resource }) => resource.keywordFilter);
  const presourceType = useSelector(
    ({ resource }) => resource.resourcetypeFilter
  );
  const pindustry = useSelector(({ resource }) => resource.industryFilter);
  const pservice = useSelector(({ resource }) => resource.serviceFilter);
  const plocation = useSelector(({ resource }) => resource.locationFilter);
  const resourceLoading = useSelector(
    ({ resource }) => resource.resourceLoading
  );

  const resourceList = useSelector(({ resource }) => resource.resourceList);
  const totalItemsCount = useSelector(
    ({ resource }) => resource.totalItemsCount
  );
  const activePage = useSelector(({ resource }) => resource.pagenumber);

  const handlePageChange = (pageNumber) => {
    const selectresourcetype = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];

    presourceType.map((item) => {
      selectresourcetype.push(item.Name);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    pservice.map((item) => {
      selectservice.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.map((item) => {
      selectkeyword.push(item.Name);
    });

    const submissionObj = {
      PageNumber: pageNumber,
      ResourceType: selectresourcetype,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
    };

    dispatch(loadResource(true));
    dispatch(getNewPageWiseResource(submissionObj));
    dispatch(activePageNumber(pageNumber));
  };

  return (
    <div className="outer-wrapper module-spacer-bottom sm-up-module-spacer-top">
      <Row className="no-gutter-around xs-only-trinity-container">
        <Col xs={12} sm={8}>
          <div className="wrapper">
            {resourceLoading &&
            resourceList &&
            activePage &&
            totalItemsCount ? (
              <Loader />
            ) : (
              <ResourceListing
                resourceContent={resourceList}
                resourceLoading={resourceLoading}
                postsPerPage={15}
                totalItemsCount={totalItemsCount}
                activePage={activePage}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </Col>

        <Col xs={12} sm={4}>
          <ResourceAdvanceFilter />
          <SidebarButton
            resourcesText="Software Resources"
            resourcesLink="software/resources"
          />
          {/* <SidebarWithFilters
            resourceText="Software Resources"
            resourceTextLink="/software/resources"
          /> */}
        </Col>
      </Row>
    </div>
  );
}

export default ResourceListingWithFilter;
