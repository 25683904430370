import * as Sentry from '@sentry/react';
import { ENDPOINTS } from '../_config';
import { handleNotify } from '../../helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../helpers/toaster/toaster-types';

export const getPaymentIntentRequest = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.GET_PAYMENT_INTENT;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      // handleNotify('Item has been added to cart!', ToasterTypes.Success);
      return await fetchResponse.json();
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while adding item to cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    handleNotify('Error while adding item to cart', ToasterTypes.Error);
    return null;
  }
};

export const stripePaymentSuccessRequest = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.PAYMENT_SUCCESS_HOOK;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);

    if (fetchResponse.status === 200) {
      const response = await fetchResponse.json();
      if (response) {
        // return response.PaymentSuccess === true ? 1 : 0;
        return response;
      }
      return null;
      // return 0;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  }
};

export const zeroPaymentSuccessRequest = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.FREE_PAYMENT_HOOK;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      const response = await fetchResponse.json();
      // handleNotify('Qty has been update for cart.', ToasterTypes.Success);
      return {
        status: 1,
        data: response,
      };
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in Sidebar');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in Sidebar',
      },
    });
    return null;
  }
};

export const payofflinePaymentRequest = async (items) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;

  const location = ENDPOINTS.PROXY + ENDPOINTS.CART.PAYOFFLINE_PAYMENT;
  const settings = {
    method: 'POST',
    headers: {
      Authorization: auth,
      Accept: 'application/json',
      'Content-Type': 'application/json', // "multipart/form-data",
    },
    body: JSON.stringify(items),
  };

  try {
    const fetchResponse = await fetch(location, settings);
    if (fetchResponse.status === 200) {
      // handleNotify('Qty has been update for cart.', ToasterTypes.Success);
      const response = await fetchResponse.json();
      if (response) {
        // return response.PaymentSuccess === true ? 1 : 0;
        return response;
      }
      return null;
      // return 1;
    }
    if (fetchResponse.status === 400) {
      handleNotify(fetchResponse.statusText, ToasterTypes.Error);
      return null;
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    return null;
  } catch (e) {
    {
      /* RP - Add Sentry Changes */
    }
    handleNotify('Error while updating item for cart', ToasterTypes.Error);
    Sentry.setExtra('Shopping Cart', 'Error in updating item for cart');
    Sentry.captureException(new Error(e), {
      tags: {
        section: 'Error in updating item for cart',
      },
    });
    return null;
  }
};
