import React from 'react';
import './breadcrumbs.styles.scss';

import { Link, useHistory } from 'react-router-dom';

import SelectList from '../form-inputs/select-list/select-list.component';

function Breadcrumbs({ links }) {
  const currentUrlPath = window.location.pathname;
  const history = useHistory();

  const redirectOnChange = (url) => {
    history.push(url);
  };

  if (links) {
    return (
      <div className="breadcrumbs module-spacer">
        <SelectList
          className="d-md-none"
          onChange={(e) => redirectOnChange(e.target.value)}
          defaultText="— Sub Categories —"
          label="To view additional sub categories, select a title below"
        >
          {links.map(({ url, text }) => (
            <option className="filter" value={url}>
              {text}
            </option>
          ))}
        </SelectList>

        <hr className="mobile-seperator d-md-none" />

        <div className="title-filters">
          {links.map(({ url, text }) => (
            <Link
              to={url}
              className={`filter
                            ${currentUrlPath.includes(url) ? 'active' : ''}
                        `}
            >
              {text}
            </Link>
          ))}
        </div>
      </div>
    );
  }

  return null;
}

export default Breadcrumbs;
