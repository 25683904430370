import { RelatedDataServices } from './widget-box.services';
import { WidgetBoxTypes } from './widget-box.types';

export const trainingcourseservicedata =
  async (coursename, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedTrainingCourseServiceData(
      coursename,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_TRAINING_COURSES_SERVICE_DATA,
          payload: response.data.relatedData,
          status: true,
        });
      }
    });
  };
export const trainingcourseindustriesdata =
  async (coursename, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedTrainingCourseIndustriesData(
      coursename,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_TRAINING_COURSES_INDUSTRIES_DATA,
          payload: response.data.relatedData,
          status: true,
        });
      }
    });
  };
export const trainingcourseproductdata =
  async (coursename, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedTrainingCourseProductData(
      coursename,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_TRAINING_COURSES_PRODUCT_DATA,
          payload: response.data && response.data.relatedData,
          status: true,
        });
      }
    });
  };
export const trainingcourselocationdata =
  async (coursename, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedTrainingCourseLocationData(
      coursename,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_TRAINING_COURSES_LOCATION_DATA,
          payload: response.data.relatedData,
          status: true,
        });
      }
    });
  };
export const resourcesdata =
  async (resourcesname, pagename, category) => async (dispatch) => {
    RelatedDataServices.getRelatedResourcesData(
      resourcesname,
      pagename,
      category
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_RESOURCES_DATA,
          payload: response.data,
          status: true,
        });
      }
    });
  };
export const newsdata = async (newsname, pagename) => async (dispatch) => {
  RelatedDataServices.getRelatedNewsData(newsname, pagename).then(
    (response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_NEWS_DATA,
          payload: response.data,
          status: true,
        });
      }
    }
  );
};
export const consultingproductdata =
  async (productname, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedProductConsultingData(
      productname,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_PRODUCT_CONSULTING_DATA,
          payload: response.data,
          status: true,
        });
      }
    });
  };
export const industriesproductdata =
  async (productname, pagename) => async (dispatch) => {
    RelatedDataServices.getRelatedProductIndustriesData(
      productname,
      pagename
    ).then((response) => {
      if (response) {
        dispatch({
          type: WidgetBoxTypes.RELATED_PRODUCT_INDUSTRIES_DATA,
          payload: response.data,
          status: true,
        });
      }
    });
  };
