import React from 'react';
import './text-input.styles.scss';

function TextInput({
  className,
  placeholder,
  label,
  type,
  required,
  maxlength,
  autoFocus,
  ...otherProps
}) {
  return (
    <label className={`text-input w-100 ${required ? 'required' : ''}`}>
      {label}
      <input
        className={`trinity-text-input w-100 ${className || ''}`}
        type={type || 'text'}
        placeholder={placeholder || ''}
        aria-label="Text input"
        required={!!required}
        maxLength={maxlength}
        {...otherProps}
        autoFocus={autoFocus === true}
      />
      {required && <span className="required-text">*</span>}
    </label>
  );
}

export default TextInput;
