import React from 'react';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TrainingAzureSearchCheckBoxContainer, {
  FreeCheckBoxContainer,
} from './TrainingAzureSearchCheckBoxContainer';
import search from '../../../../components/ehs-quarterly/images/search.svg';
import './TrainingAzureSearchRootComponent.scss';
import InputRange from 'react-input-range';
import moment from 'moment';
import { trinityTrainingDateFormatter } from '../../../../utilities/CommonUtilities';

function TrainingAzureFilterRootComponent(props) {
  function moveArrayItemToNewIndex(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  const getDynamicTreeElements = () => {
    props.facetsList &&
      props.facetsList.map((item, index) => {
        if (item.key === 'BusinessUnit')
          moveArrayItemToNewIndex(props.facetsList, index, 0);
        else if (item.key === 'ByFormat')
          moveArrayItemToNewIndex(props.facetsList, index, 1);
        else if (item.key === 'ByCategory')
          moveArrayItemToNewIndex(props.facetsList, index, 2);
        else if (item.key === 'ByIndustry')
          moveArrayItemToNewIndex(props.facetsList, index, 3);
        else if (item.key === 'State')
          moveArrayItemToNewIndex(props.facetsList, index, 4);
        // else if (item.key === "ByLocation")
        //     moveArrayItemToNewIndex(props.facetsList, index, 5);
        else if (item.key === 'Amount')
          moveArrayItemToNewIndex(props.facetsList, index, 5);
        else if (item.key === 'RegulatoryAgency')
          moveArrayItemToNewIndex(props.facetsList, index, 6);
      });

    return (
      props.facetsList &&
      props.facetsList
        .map((outerElement) => {
          if (outerElement.value.length > 0) {
            return (
              <TrainingAzureSearchCheckBoxContainer
                outerElement={outerElement}
                {...props}
              />
            );
          }
          return null;
        })
        .filter((e) => e !== null)
    );
  };

  const getFreeCheckboxData = () => <FreeCheckBoxContainer {...props} />;

  const getSearchBar = () => (
    <div className="search-box">
      <input
        type="text"
        placeholder="Keyword or Phrase"
        className="search-input"
        value={props.searchBoxText}
        onKeyDown={(e) => e.key === 'Enter' && props.onSearchEnter()}
        onChange={(e) => props.setSearchBoxText(e.target.value)}
      />
      <img
        onClick={props.onSearchEnter}
        src={search}
        alt={Math.random().toString()}
      />
    </div>
  );

  return (
    <div className="outside-filter-container">
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="activity-search-label">Training Search</div>
        <div>
          <span
            style={{
              cursor: 'pointer',
              color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
            }}
            onClick={props.resetAllFiltersAndSearch}
          >
            Clear All{' '}
            <i
              className="fa fa-window-close"
              style={{
                fontSize: '20px',
                color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
              }}
              aria-hidden="true"
            />
          </span>
        </div>
      </div>

      <div className="training-search-filter-container">{getSearchBar()}</div>

      <div className="activity-dropdown">
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <div style={{ marginTop: '1rem' }}>
            <span className="tree-view-key-font">Training Dates</span>
            <div className="tree-key-element-border" />

            <span>
              {trinityTrainingDateFormatter(props.rangeState.min)} to{' '}
              {trinityTrainingDateFormatter(props.rangeState.max)}
            </span>
            <div className="general-search-date-range-slider">
              <InputRange
                minValue={moment().toDate().getTime()}
                maxValue={moment().add(1, 'years').toDate().getTime()}
                formatLabel={(val) => ''}
                value={props.rangeState}
                step={6}
                onChangeComplete={props.rangePickerChangeComplete}
                onChange={props.onChangeHandleChange}
              />
            </div>
          </div>

          <div style={{ marginTop: '1rem' }}>
            <span className="tree-view-key-font">Price</span>
            <div className="tree-key-element-border" />

            <span>
              ${props.priceRangeState.min} to ${props.priceRangeState.max}
            </span>
            <div className="general-search-date-range-slider">
              <InputRange
                minValue="0"
                maxValue="5000"
                formatLabel={(val) => ''}
                value={props.priceRangeState}
                step={6}
                onChangeComplete={props.rangePickerPriceChangeComplete}
                onChange={props.onChangeHandlePriceChange}
              />
            </div>
          </div>
          <div>
            {getFreeCheckboxData()}
            {getDynamicTreeElements()}
          </div>
        </TreeView>
      </div>
    </div>
  );
}

export default TrainingAzureFilterRootComponent;
