import React from 'react';

function CategoryListing({ data }) {
  return (
    <div>
      {data &&
        data.map((position, i) => (
          <div key={i} className="widgetBox-wrapper">
            <div className="widget-wrapper">
              <div className="content">
                <a className="ssname" href={position.Url}>
                  {position.Title}
                </a>
                <p
                  className="ssjob_title"
                  dangerouslySetInnerHTML={{ __html: position.IntroText }}
                />
              </div>
            </div>
            <br />
          </div>
        ))}
    </div>
  );
}

export default CategoryListing;
