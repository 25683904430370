import React, { useEffect } from 'react';
import './training-error-display.scss';
import { faFrown } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../../../components/commons/custom-button/custom-button.component';
import { ENDPOINTS } from '../../../../js/api/_config';

function TrainingErrorDisplay() {
  const [display, setDisplay] = React.useState(false);
  let history = useHistory();

  const meta = {
    title: '404 - Page Not Found',
    description: 'Page not found',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  const onClickBackToLogin = () => {
    history.push('/');
  };

  useEffect(() => {
    fetch(ENDPOINTS.URL_404.NOT_FOUND_URL)
      .then((e) => {})
      .catch((e) => {});
    setTimeout(() => {
      setDisplay(true);
    }, 1000);
  }, []);

  return (
    <div className="training-error-display">
      <div className="trinity-container">
        <div className={'outer-container'}>
          {/* <FontAwesomeIcon icon={faFrown} className={"smile-icon"}/> */}
          {/* <hr/> */}
          {/*<span className={'outer-font'}>Sorry !!</span>*/}
          <br />
          <br />
          {/*<h2 className={'light-font-large'}>Oops !! Looks like page is gone</h2>*/}
          {/* <span className={'light-font'}>Course is not available.</span> */}
          <span className={'light-font'}>
            There are no sessions scheduled at this time. Please return to our{' '}
            <a href={'/training'}>Training page</a> and search for another
            course.
          </span>
          {/*<span className={'light-font'}>Please click on the below button to continue.</span>*/}
          <br />
          {/* <CustomButton title={"Go Back To Home"}
                                  onClick={onClickBackToLogin}
                                  style={{marginTop: 20, marginBottom: 20}}/> */}
        </div>
      </div>
    </div>
  );
}

export default TrainingErrorDisplay;
