import React, { Fragment } from 'react';
import { Row, Col } from 'react-bootstrap';
// import { officeContent } from "./office-details.data";
import './office-details.styles.scss';

// RP #Task 147 Global Partners
function GlobalOfficeDetails({ officeContent }) {
  return (
    // KT add class location-outer-office-container-changed 10-27-2021
    <Row className="location-outer-office-container location-outer-office-container-global-changed">
      {officeContent &&
        officeContent.map((detail) => (
          <Col
            xs={12}
            sm={6}
            md={3}
            className="office-details-container office-details-container-changed"
          >
            {/* KT  remove pr-2 for remove right side padding */}
            <div className="office-card">
              {/* KT  start add class to crate same design as location page */}
              <div className="office-box">
                {/* KT  end add class to crate same design as location page */}
                <Row>
                  <Col xs={12}>
                    <p className="city">{detail.CountryName}</p>
                  </Col>
                  {/* <Col xs={6}>
                      <p className="service-label">{detail.ServiceName}</p>
                    </Col> */}
                </Row>
                <br />
                {/* add className state-changed */}
                <a
                  target="_blank"
                  className="content-container state state-changed"
                  href={detail.PartnerLink}
                  rel="noreferrer"
                >
                  {detail.PartnerName}
                </a>
                <br />
                <br />
                {/* add className content-container-changed */}
                <Row className="content-container content-container-changed">
                  {/* KT  change column style 6 to 7 */}
                  <Col
                    xs={12}
                    sm={12}
                    className="address-container address-container-changed"
                  >
                    <p className="title">Address</p>
                    <p className="address1">
                      {detail.Address1}
                      {detail.Address2 != '' ? `, ${detail.Address2}` : ''}
                      {detail.City !== '' ? `, ${detail.City}` : ''}
                      {detail.StateName != '' ? `, ${detail.StateName}` : ''}
                      {detail.Zip != '' ? `, ${detail.Zip}` : ''}
                    </p>
                    {/* KT add class mb-0 for remove marign from bottom add  Get Directions here */}
                    {/* <div className="visit-office visit-office-changed"> */}
                    {/*    <i className="fa fa-map-marker" aria-hidden="true"></i>{" "} */}
                    {/*    /!*KT  add class Name wrap-changed*!/ */}
                    {/*    <a className="wrap-changed" href={detail.VisitOfficeLink} target="_blank"> */}
                    {/*        Get Directions */}
                    {/*    </a> */}
                    {/* </div> */}
                    {/* <p className="address2 mb-0">
                                            {detail.City}
                                            {detail.StateName != "" ? ", " + detail.StateName : ""}
                                            {detail.Zip != "" ? ", " + detail.Zip : ""}
                                        </p> */}
                  </Col>
                  {/* KT  change column style 6 to 5 */}
                  {/* KT  add class Name title-changed */}
                  <Col
                    xs={12}
                    sm={12}
                    className="office-contact-container office-contact-container-new office-contact-container-changed"
                  >
                    <p className="title title-changed">Contact</p>
                    <div className="contact-info">
                      {/* KT  add class  common-flex-changed */}
                      <div className="phone common-flex-changed">
                        <i className="fa fa-phone" aria-hidden="true" />{' '}
                        {/* KT  add class Name wrap-changed */}
                        <a
                          className="wrap-changed"
                          title={detail.PhoneNumber}
                          href={`tel:${detail.PhoneNumber}`}
                        >
                          {detail.PhoneNumber}
                        </a>
                      </div>
                      {detail.FaxNumber && (
                        <div className="fax common-flex-changed">
                          {/* KT  add class  common-flex-changed */}
                          <i className="fa fa-fax" aria-hidden="true" />{' '}
                          <a className="wrap-changed" href={detail.FaxNumber}>
                            {detail.FaxNumber}
                          </a>
                        </div>
                      )}
                    </div>
                    {/* KT add Send Email here */}
                    <div className="visit-office ">
                      <i className="fa fa-envelope" aria-hidden="true" />{' '}
                      <a href={detail.Email}>Send Email</a>
                    </div>
                  </Col>
                  {/* KT comment below code  change xs class 12 to 7 for responsive view */}
                  {/* <Col xs={12} sm={7} lg={7} md={7} className={"mb-3"}> */}
                  {/*    <div className="visit-office"> */}
                  {/*        <i class="fa fa-map-marker" aria-hidden="true"></i>{" "} */}
                  {/*        /!*KT  add class Name wrap-changed*!/ */}
                  {/*        <a className="wrap-changed" href={detail.VisitOfficeLink} target="_blank"> */}
                  {/*            Get Directions */}
                  {/*    </a> */}
                  {/*    </div> */}
                  {/* </Col> */}
                  {/* KT  change xs class 12 to 5 for responsive view */}
                  {/* <Col xs={12} sm={5} lg={5} md={5} className={"mb-3"}> */}
                  {/*    <div className="visit-office"> */}
                  {/*        <i className="fa fa-envelope" aria-hidden="true" />{" "} */}
                  {/*        <a href={detail.Email}>Send Email</a> */}
                  {/*    </div> */}
                  {/* </Col> */}
                </Row>
              </div>
            </div>
          </Col>
        ))}
    </Row>
  );
}

export default GlobalOfficeDetails;
