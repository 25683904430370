import React, { useState } from 'react';
import SelectList from '../../../form-inputs/select-list/select-list.component';
import { objData } from '../../filtered-content.data';

import './list-content-well.styles.scss';

function FiliteredContentList({ posts, loading }) {
  const [data, setData] = useState(objData);
  function filterContent(activeSection) {
    const newData = data.map((group) => {
      if (activeSection === group.section) {
        group.active = true;
      } else {
        group.active = false;
      }
      return group;
    });

    setData(newData);
  }

  if (loading) {
    return <p>loading...</p>;
  }
  return (
    <div className="select-list-filitered-container">
      <SelectList
        className="d-md-none"
        onChange={(e) => filterContent(e.target.value)}
        defaultText="— Topics —"
        label="To learn more about EXDAM features, select topics below"
      >
        {data.map(({ section, active }) => (
          <option
            className={`filter
                    ${active ? 'active' : ''}
                `}
            value={section}
          >
            {section}
          </option>
        ))}
      </SelectList>

      <hr className="mobile-seperator d-md-none" />

      <div className="content">
        <div className="title-filters">
          {data.map(({ section, active }) => (
            <h3
              onClick={() => filterContent(section)}
              className={`filter
                    ${active ? 'active' : ''}
                `}
            >
              {section}
            </h3>
          ))}
        </div>
        {data.map((group) => {
          if (group.active) {
            return (
              <div
                className="content-well"
                dangerouslySetInnerHTML={{ __html: group.content }}
              />
            );
          }
        })}
      </div>
    </div>
  );
}

export default FiliteredContentList;
