import { ENDPOINTS } from '../_config';
import { addMinutesToDateStamp } from '../../helpers/_add-minutes-to-date-stamp';

// this returns an access token
export const authenticateUser = async ({ username, password }) => {
  const formBody = new FormData();

  const data = [
    // client requested most of this info be put in a config
    ['client_id', 'trinityfrontend'],
    ['client_secret', '2658e63b2342470d94177c546919bafg'],
    ['grant_type', 'password'],
    ['scope', 'openid offline_access'],
    ['username', username],
    ['password', password],
    ['membershipProvider', 'CustomMembershipProvider'],
  ];

  try {
    const request = await fetch(ENDPOINTS.PROXY + ENDPOINTS.AUTHENTICATION, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(data),
    });
    const response = await request.json();

    if (response.error) return { access_token: null, refresh_token: null };

    const authExpirationTime = addMinutesToDateStamp(Date.now(), 55);

    localStorage.setItem('access-token', response.access_token); // after 1 hour this won't work
    localStorage.setItem('refresh-token', response.refresh_token); // use this to get a new access token
    localStorage.setItem('access-token-expiration', authExpirationTime); // setting expiration time to be 55 minutes after getting auth
    // set to refresh right before every hour
    // const TOKEN_REFRESH = 60 * 60 * 1000;

    // TODO replace with existing function of the change
    /* setInterval(() => {
            let getRefreshToken = localStorage.getItem("refresh-token")
            localStorage.setItem("access-token", "");
            authRequest()
        }, 198000) */

    return response;
  } catch (e) {
    return { access_token: null, refresh_token: null };
  }
};

// working test username & password
// username: 'jbtest@nowhere.com',
// password: 'johnwashere',
