import React from 'react';
import './software-filter-button.scss';

function SoftwareFilterButton({
  children,
  sort,
  colorCategory,
  ...otherProps
}) {
  if (colorCategory === 'tanks-terminals') {
    colorCategory = 'tanks';
  }
  return (
    <button
      {...otherProps}
      className={`custom-background transparent software-filter-button ${colorCategory}`}
    >
      {children}
    </button>
  );
}

export default SoftwareFilterButton;
