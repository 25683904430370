import { ResourceActionTypes } from './resource.type';

const RESOURCE_INITIAL_STATE = {
  resourceList: [],
  totalItemsCount: '',
  resourceLoading: false,
  filterList: '',
  keywordFilter: [],
  resourcetypeFilter: [],
  industryFilter: [],
  serviceFilter: [],
  locationFilter: [],
  pagenumber: 1,
  torontoContent: {
    data: [],
    dataLoaded: false,
  },
};

const resourceReducer = (state = RESOURCE_INITIAL_STATE, action) => {
  switch (action.type) {
    case ResourceActionTypes.GET_PAGEWISE_RESOURCE:
      if (action.payload) {
        return {
          ...state,
          resourceList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.TOTAL_ITEM_COUNT:
      if (action.payload) {
        return {
          ...state,
          totalItemsCount: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.RESOURCE_LOADING:
      return {
        ...state,
        resourceLoading: action.payload,
      };
    case ResourceActionTypes.FILTER_LOADING:
      if (action.payload) {
        return {
          ...state,
          filterList: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.KEYWORD_FILTER:
      if (action.payload) {
        return {
          ...state,
          keywordFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.RESOURCE_TYPE_FILTER:
      if (action.payload) {
        return {
          ...state,
          resourcetypeFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.INDUSTRY_FILTER:
      if (action.payload) {
        return {
          ...state,
          industryFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.SERVICE_FILTER:
      if (action.payload) {
        return {
          ...state,
          serviceFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.LOCATION_FILTER:
      if (action.payload) {
        return {
          ...state,
          locationFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case ResourceActionTypes.ACTIVE_PAGE_NUMBER:
      return {
        ...state,
        pagenumber: action.payload,
      };
    case ResourceActionTypes.GET_TORONTO_CONTENT:
      return {
        ...state,
        torontoContent: {
          data: action.payload,
          dataLoaded: action.responseStatus,
        },
      };
    default:
      return state;
  }
};

export default resourceReducer;
