import React, { useEffect, useState } from 'react';
import './widget-box.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import CommonWidgetBox from './common-widgetBox.component';
import {
  trainingcoursedata,
  resourcesdata,
  newsdata,
  productdata,
  trainingcourseservicedata,
  trainingcourseindustriesdata,
  industriesproductdata,
  trainingcourseproductdata,
  consultingproductdata,
  trainingcourselocationdata,
} from '../../../../redux/widget-box/widget-box.action';
import TrainingConnectWidgetBox from './training-connect.component';
import ContactUs from '../../../../pages/about/contact-us/contact-us.page';
import ResourcesConnectWidgetBox from './resources-connect.component';
import NewsConnectWidgetBox from './news-connect.component';
import ProductConnectWidgetBox from './product-connect.component';
import Loader from '../../helpers/loader/loader.component';

// RP #Task 152 Related Data
function WidgetBox({
  relatedTrainingCourseContent,
  relatedResourcesContent,
  relatedNewsContent,
  relatedProductContent,
  isFromConsulting,
  isFromIndustry,
  isLocation,
}) {
  if (
    relatedTrainingCourseContent != undefined &&
    relatedTrainingCourseContent.status &&
    relatedResourcesContent != undefined &&
    relatedResourcesContent.status &&
    relatedNewsContent != undefined &&
    relatedNewsContent.status &&
    relatedProductContent != undefined &&
    relatedProductContent.status
  ) {
    return (
      <div>
        {relatedTrainingCourseContent &&
          relatedTrainingCourseContent.payload.length > 0 && (
            <TrainingConnectWidgetBox
              isFromConsulting={isFromConsulting}
              isFromIndustry={isFromIndustry}
              isLocation={isLocation}
              relatedTrainingCourse={relatedTrainingCourseContent.payload}
            />
          )}

        {relatedResourcesContent &&
          relatedResourcesContent.payload.length > 0 && (
            <ResourcesConnectWidgetBox
              relatedResources={relatedResourcesContent.payload}
            />
          )}

        {relatedNewsContent && relatedNewsContent.payload.length > 0 && (
          <NewsConnectWidgetBox relatedNews={relatedNewsContent.payload} />
        )}

        {relatedProductContent && relatedProductContent.payload.length > 0 && (
          <ProductConnectWidgetBox
            relatedProduct={relatedProductContent.payload}
          />
        )}
      </div>
    );
  }
  return null;
}

export default WidgetBox;
