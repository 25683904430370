import React, { useState, useEffect, useCallback } from 'react';
import './create-account-form.styles.scss';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CustomButton from '../../../commons/custom-button/custom-button.component';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import ReCaptcha from '../../../commons/recaptcha/recaptcha.component';

// import useAllDropdownContent from "../../../../js/custom-hooks/useAllDropdownContent";
import useInputCollection from '../../../../js/custom-hooks/useInputCollection';

import { registerUser } from '../../../../js/api/_register-user';
import { confirmPassword } from '../../../../js/helpers/_confirm-password';
import { UserActionTypes } from '../../../../redux/user/user.types';
import { validateRequiredFields } from '../../../../js/helpers/_validate-required-fields';
import useDropdownContent from '../../../../js/custom-hooks/useDropdownContent';
import {
  submitAccountPageData,
  submitUserAccountResponse,
} from '../../../../js/api/create-account/_create-account.data';
import validate from './create-account.validationRule';
import useForm from '../../../../js/custom-hooks/useForm';
import { ListOfFormUniqueData } from '../../../commons/helpers/form-uniqueid/form-uniqueid';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ENDPOINTS } from '../../../../js/api/_config';

// used names that matched up with database
// the api also has a "FaxNumber", and "username" field.. is this needed?
function CreateAccountForm({ data, mainData, status }) {
  // #RP - Task 46 - Bind data to ContactUs Form in Front-End.
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const header = mainData != null ? mainData.Content : '';
  const footer = mainData != null ? mainData.FooterContent : '';

  const addVerifiedEmail = useCallback(
    (email) =>
      dispatch({ type: UserActionTypes.VERIFIED_EMAIL, payload: email }),
    [dispatch]
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMesssage] = useState(null);

  const displayPasswordError = (password, confirmPass) => {
    // don't want to display before user starts typing
    if (
      (password != undefined && !password.length) ||
      (confirmPass != undefined && !confirmPass.length)
    )
      return false;
    if (password != undefined && confirmPass != undefined) {
      const validPassword = confirmPassword(password, confirmPass);
      return !validPassword;
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
  };

  useEffect(() => {
    getStateDataFromAPIs(values.CountryID).then((e) => {
      setState(e);
    });
  }, [values.CountryID]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.CountryID).then((e) => {
      setState(e);
    });
  };

  const submitData = async (
    createAccountSubmissionObj,
    secondsubmissionObj
  ) => {
    if (
      createAccountSubmissionObj != undefined &&
      secondsubmissionObj != null
    ) {
      setSubmitting(true);
      const request = await registerUser(createAccountSubmissionObj);
      const token = await createToken();
      const submitResponse = await submitUserAccountResponse(
        secondsubmissionObj,
        token
      );
      const response = await request;
      setSubmitting(false);

      if (response.error) {
        setErrorMesssage(response.message);
        return;
      }

      addVerifiedEmail(createAccountSubmissionObj.user.email);
      history.push('/user-login');
    }
  };

  function submitSuccess() {
    if (!displayPasswordError(values.password, values.confirmpassword)) {
      const createAccountSubmissionObj = {
        user: {
          username: values.email.trim(),
          email: values.email.trim(),
          firstname: values.firstname.trim(),
          lastname: values.lastname.trim(),
          title: values.title.trim(),
          company: values.company.trim(),
          IndustryID: values.IndustryID != null ? values.IndustryID.trim() : '',
          Address: values.Address.trim(),
          AddressTwo: values.AddressTwo != null ? values.AddressTwo.trim() : '',
          PostalCode: values.PostalCode.trim(),
          City: values.City.trim(),
          StateID: values.StateID != null ? values.StateID.trim() : '',
          CountryID: values.CountryID != null ? values.CountryID.trim() : '',
          PhoneNumber: values.PhoneNumber.trim().replace(/\D+/g, ''),
          FaxNumber: '',
        },
        password: values.password.trim(),
        confirmpassword: values.confirmpassword.trim(),
      };
      const secondsubmissionObj = {
        user: {
          UserName: values.email.trim(),
          EmailAddress: values.email.trim(),
          FirstName: values.firstname.trim(),
          LastName: values.lastname.trim(),
          Title: values.title.trim(),
          Company: values.company.trim(),
          Industry:
            values.IndustryID != null ? values.IndustryID.trim() : 'None',
          Address: values.Address.trim(),
          AddressTwo:
            values.AddressTwo != null ? values.AddressTwo.trim() : 'None',
          PostalCode: values.PostalCode.trim(),
          City: values.City.trim(),
          State: values.StateID != null ? values.StateID.trim() : 'None',
          Country: values.CountryID != null ? values.CountryID.trim() : 'None',
          PhoneNumber: values.PhoneNumber.trim().replace(/\D+/g, ''),
          FaxNumber: 'None',
          FormUniqueId: ListOfFormUniqueData.create_account.UniqueId,
          Catalog: ListOfFormUniqueData.create_account.Catalog,
        },
        Password: values.password.trim(),
        ConfirmPassword: values.confirmpassword.trim(),
      };

      submitData(createAccountSubmissionObj, secondsubmissionObj);
    }
  }

  return (
    <div className="create-account-form module-spacer">
      {errorMessage && <p className="text error">*{errorMessage}</p>}
      <h1>Create an Account</h1>
      <p className="pb-2" dangerouslySetInnerHTML={{ __html: header }} />
      <form>
        <div className="left pr-md-4">
          <div className="form-section contact-information">
            <h2>Contact Information</h2>

            {/* {errors.username && (<span className="help">{errors.username}</span>)}
            <TextInput
              placeholder={"User Name"}
              name={"username"}
              onChange={handleChange}
              value={values.username}
              required={true}
              autoComplete="off"
              className={`${errors.username && "error"}`}
              type="text"
            /> */}

            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Email Address (User Name)"
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email}
              required
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              type="text"
              maxlength={100}
            />

            {errors.firstname && (
              <span className="help">{errors.firstname}</span>
            )}
            <TextInput
              placeholder="First Name"
              name="firstname"
              onChange={handleChange}
              value={values.firstname}
              required
              autoComplete="off"
              className={`${errors.firstname && 'error'}`}
              type="text"
              maxlength={100}
            />

            {errors.lastname && <span className="help">{errors.lastname}</span>}
            <TextInput
              placeholder="Last Name"
              name="lastname"
              onChange={handleChange}
              value={values.lastname}
              required
              autoComplete="off"
              className={`${errors.lastname && 'error'}`}
              type="text"
              maxlength={100}
            />

            {errors.title && <span className="help">{errors.title}</span>}
            <TextInput
              placeholder="Title"
              name="title"
              onChange={handleChange}
              value={values.title}
              required
              autoComplete="off"
              className={`${errors.title && 'error'}`}
              type="text"
              maxlength={200}
            />

            {errors.PhoneNumber && (
              <span className="help">{errors.PhoneNumber}</span>
            )}
            <TextInput
              placeholder="Phone Number, ex. 999-999-999"
              name="PhoneNumber"
              format="(###) ###-####"
              // pattern="^-?[0-9]\d*\.?\d*$"
              type="text"
              onChange={handleChange}
              value={values.PhoneNumber}
              required
              autoComplete="off"
              className={`${errors.PhoneNumber && 'error'}`}
              maxlength={100}
            />
          </div>

          <div className="form-section job-information">
            <h2>Create a Password</h2>

            {errors.password && <span className="help">{errors.password}</span>}
            <TextInput
              placeholder="Choose a password"
              name="password"
              onChange={handleChange}
              value={values.password}
              type="password"
              required
              autoComplete="off"
              className={`${errors.password && 'error'}`}
              maxlength={100}
            />

            {errors.confirmpassword && (
              <span className="help">{errors.confirmpassword}</span>
            )}
            <TextInput
              placeholder="Confirm password"
              name="confirmpassword"
              onChange={handleChange}
              value={values.confirmpassword}
              type="password"
              required
              autoComplete="off"
              className={`${errors.confirmpassword && 'error'}`}
              maxlength={100}
            />
            {displayPasswordError(values.password, values.confirmpassword) && (
              <p className="text inner-paragraph error">
                * passwords must match & be at least 7 characters long
              </p>
            )}
          </div>
        </div>

        <div className="right pl-md-4">
          <div className="form-section company-information">
            <h2>Company Information</h2>
            <SelectList
              defaultText="— Industry —"
              name="IndustryID"
              onChange={handleChange}
              value={values.IndustryID}
            >
              {status != noContent &&
                //   Industry.map(({ Name }) => (
                //   <option key={Name} value={Name}>
                //     {Name}
                //   </option>
                // ))
                Industry.map((element, index) => (
                  <option key={element.Name} value={element.Id}>
                    {element.Name}
                  </option>
                ))}
            </SelectList>

            {errors.company && <span className="help">{errors.company}</span>}
            <TextInput
              placeholder="Company Name"
              name="company"
              onChange={handleChange}
              value={values.company}
              required
              autoComplete="off"
              className={`${errors.company && 'error'}`}
              type="text"
              maxlength={100}
            />

            {errors.Address && <span className="help">{errors.Address}</span>}
            <TextInput
              placeholder="Business Address, Line 1"
              name="Address"
              onChange={handleChange}
              value={values.Address}
              required
              autoComplete="off"
              className={`${errors.Address && 'error'}`}
              type="text"
              maxlength={300}
            />
            <TextInput
              placeholder="Business Address, Line 2"
              name="AddressTwo"
              onChange={handleChange}
              value={values.AddressTwo}
              autoComplete="off"
              type="text"
              maxlength={300}
            />
            <Row>
              <Col xs={6} className="pr-2">
                <SelectList
                  defaultText="— Country —"
                  name="CountryID"
                  onChange={handleChange}
                  value={values.CountryId}
                >
                  {Country &&
                    Country.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </Col>

              <Col xs={6}>
                <SelectList
                  defaultText="— State / Province —"
                  name="StateID"
                  onChange={handleChange}
                  onClick={stateclick}
                  value={values.StateID}
                >
                  {State &&
                    State.map(({ Name }) => (
                      <option key={Name} value={Name}>
                        {Name}
                      </option>
                    ))}
                </SelectList>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="pr-2">
                {errors.City && <span className="help">{errors.City}</span>}
                <TextInput
                  className="w-100"
                  placeholder="Location City"
                  name="City"
                  onChange={handleChange}
                  value={values.City}
                  required
                  autoComplete="off"
                  className={`${errors.City && 'error'}`}
                  type="text"
                  maxlength={100}
                />
              </Col>
              <Col xs={6}>
                {errors.PostalCode && (
                  <span className="help">{errors.PostalCode}</span>
                )}
                <TextInput
                  placeholder="Postal Code"
                  name="PostalCode"
                  onChange={handleChange}
                  value={values.PostalCode}
                  required
                  autoComplete="off"
                  className={`${errors.PostalCode && 'error'}`}
                  maxlength={100}
                />
              </Col>
            </Row>
          </div>
          <p className="pt-md-4 pb-md-4">
            <ReCaptcha />
          </p>
          <CustomButton
            loading={submitting}
            onClick={handleSubmit}
            type="submit"
            className="mb-4 mt-4"
          >
            SUBMIT
          </CustomButton>
          <p className="pb-2" dangerouslySetInnerHTML={{ __html: footer }} />
        </div>
      </form>
    </div>
  );
}

export default CreateAccountForm;
