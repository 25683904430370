import React, { useEffect } from 'react';
import './news-listing-with-filters.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import ResourceListing from '../resource-listing/resource-listing.component';
import SidebarWithFilters from '../../commons/sidebar-with-filters/sidebar-with-filters.component';
import {
  activePageNumber,
  getPageWiseNews,
  getUserPageWiseNews,
  loadNews,
} from '../../../redux/news/news.action';
import NewsAdvanceFilters from './news-advance-filter.component';
import Loader from '../../commons/helpers/loader/loader.component';

// RP #Task 106 News Landing
function NewsListingWithFilter() {
  const [postsPerPage, setPostPerPage] = React.useState({});

  const dispatch = useDispatch();
  const newsList = useSelector(({ news }) => news.newsList);
  const totalItemsCount = useSelector(({ news }) => news.totalItemsCount);
  const newsLoading = useSelector(({ news }) => news.newsLoading);
  const newsFilter = useSelector(({ news }) => news.filterList);
  const activePage = useSelector(({ news }) => news.pagenumber);

  const ptags = useSelector(({ news }) => news.tagsFilter);
  const pnews = useSelector(({ news }) => news.newsFilter);
  const pindustry = useSelector(({ news }) => news.industryFilter);
  const pdate = useSelector(({ news }) => news.publishedDateFilter);
  const plocation = useSelector(({ news }) => news.locationFilter);

  const publishedDate = [
    {
      Name: 'Past 30 Days',
      Id: '1',
    },
    {
      Name: 'Past 60 Days',
      Id: '2',
    },
    {
      Name: 'Past 90 Days',
      Id: '3',
    },
    {
      Name: 'Past 6 Months',
      Id: '4',
    },
    {
      Name: 'Past 9 Months',
      Id: '5',
    },
    {
      Name: 'Past 12 Months',
      Id: '6',
    },
  ];

  const handlePageChange = (pageNumber) => {
    const selecttags = [];
    const selectnews = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectdate = [];

    ptags.map((item) => {
      selecttags.push(item.Name);
    });
    pnews.map((item) => {
      selectnews.push(item.Id);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pdate.map((item) => {
      selectdate.push(item.Id);
    });

    const submissionObj = {
      PageNumber: pageNumber,
      Tags: selecttags,
      NewsCategory: selectnews,
      IndustryCategory: selectindustry,
      Location: selectlocation,
      PublishedDate: selectdate,
    };

    dispatch(loadNews(true));
    dispatch(getUserPageWiseNews(submissionObj));
    dispatch(activePageNumber(pageNumber));
  };

  return (
    <div className="outer-wrapper module-spacer-bottom sm-up-module-spacer-top">
      <Row className="no-gutter-around xs-only-trinity-container">
        <Col xs={12} sm={8}>
          <div className="wrapper">
            <ResourceListing
              newsLoading={newsLoading}
              newsList={newsList}
              postsPerPage={postsPerPage}
              totalItemsCount={totalItemsCount}
              activePage={activePage}
              handlePageChange={handlePageChange}
            />
          </div>
        </Col>

        <Col xs={12} sm={4}>
          {newsFilter ? (
            <NewsAdvanceFilters
              dataSource={newsFilter.payload.data}
              publishedDateSource={publishedDate}
            />
          ) : (
            <Loader />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default NewsListingWithFilter;
