import React, { useState } from 'react';

import './pagination.styles.scss';

function Pagination({
  postsPerPage,
  totalPosts,
  paginate,
  previous,
  next,
  first,
  last,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      <li className="page-item">
        <a className="page-link" href="javascript:;" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
          <span className="sr-only">Previous</span>
        </a>
      </li>
      <li className="page-item">
        <a className="page-link" href="javascript:;" tabIndex="-1">
          Previous
        </a>
      </li>
      {pageNumbers.map((number) => (
        <li key={number} className="">
          <a onClick={() => paginate(number)} href="javascript:;" className="">
            {number}
          </a>
        </li>
      ))}
      <li className="page-item">
        <a className="page-link" href="javascript:;">
          Next
        </a>
      </li>
      <li className="page-item">
        <a className="page-link" href="javascript:;" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
          <span className="sr-only">Next</span>
        </a>
      </li>
    </ul>
  );
}
export default Pagination;
