export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.pageUrl) {
    errors.pageUrl = 'PageUrl is required';
  }
  if (!values.recaptchaValue) {
    errors.recaptchaValue = 'recaptcha is required';
  }

  return errors;
}
