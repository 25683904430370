import { NewsActionTypes } from './news.types';

const NEWS_INITIAL_STATE = {
  newsList: [],
  totalItemsCount: '',
  newsLoading: false,
  filterList: '',
  tagsFilter: [],
  newsFilter: [],
  industryFilter: [],
  publishedDateFilter: [],
  locationFilter: [],
  publishedFromDate: null,
  publishedToDate: null,
  pagenumber: 1,
  newsPageData: {},
};

function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

const newsReducer = (state = NEWS_INITIAL_STATE, action) => {
  switch (action.type) {
    case NewsActionTypes.GET_PAGEWISE_NEWS:
      if (action.payload) {
        return {
          ...state,
          newsList: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.TOTAL_ITEM_COUNT:
      if (action.payload) {
        return {
          ...state,
          totalItemsCount: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.NEWS_LOADING:
      return {
        ...state,
        newsLoading: action.payload,
      };
    case NewsActionTypes.FILTER_LOADING:
      if (action.payload) {
        return {
          ...state,
          filterList: action,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.NEWS_PAGE_DATA:
      if (action.payload) {
        return {
          ...state,
          newsPageData: action.payload && action.payload.data.MainContent,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.TAGS_FILTER:
      if (action.payload) {
        return {
          ...state,
          tagsFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.NEWS_CATEGORY_FILTER:
      if (action.payload) {
        return {
          ...state,
          newsFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.INDUSTRY_CATEGORY_FILTER:
      if (action.payload) {
        return {
          ...state,
          industryFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.PUBLISHED_DATE_FILTER:
      if (action.payload) {
        return {
          ...state,
          publishedDateFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.LOCATION_FILTER:
      if (action.payload) {
        return {
          ...state,
          locationFilter: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.PUBLISHED_FROM_DATE_FILTER:
      if (action.payload === null) {
        return {
          ...state,
          publishedFromDate: null,
        };
      }
      if (action.payload) {
        return {
          ...state,
          publishedFromDate: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.PUBLISHED_TO_DATE_FILTER:
      if (action.payload === null) {
        return {
          ...state,
          publishedToDate: null,
        };
      }
      if (action.payload) {
        return {
          ...state,
          publishedToDate: action.payload,
        };
      }
      return {
        ...state,
      };

    case NewsActionTypes.ACTIVE_PAGE_NUMBER:
      return {
        ...state,
        pagenumber: action.payload,
      };
    default:
      return state;
  }
};

export default newsReducer;
