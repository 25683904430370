import React from 'react';
import './no-image-available-styles.scss';
import NoImageAvailable from '../../../images/no-image-available/NoImageAvailable.jpg';

function NoImageAvailableComponent() {
  return (
    <div className="main-div">
      <img
        src={NoImageAvailable}
        alt="test"
        style={{ width: '200px', alignSelf: 'center' }}
      />
    </div>
  );
}

export default NoImageAvailableComponent;
