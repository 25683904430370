import React from 'react';
import * as Sentry from '@sentry/react';
import EmailLoginForm from '../../../components/ecommerce/forms/email-form/email-login-form.component';

function EmailLogin() {
  function FallbackComponent() {
    return <div>An error has occurred</div>;
  }

  const myFallback = <FallbackComponent />;

  return (
    <div className="email-login">
      <Sentry.ErrorBoundary fallback={myFallback}>
        <EmailLoginForm />
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default EmailLogin;
