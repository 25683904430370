import React, { useEffect, useState } from 'react';
import './training-tertiary.styles.scss';

import Parser from 'html-react-parser';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import stripHtml from 'string-strip-html';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import SingleWidget from '../../../components/commons/widgets/single-widget/single-widget.component';
import PartnerLayout from '../../../components/training/partner-layout/partner-layout.component';
import ProductLayout from '../../../components/training/product-layout/product-layout.component';
import WidgetPdf from '../../../images/training/pdf.svg';
import { getSingleProduct } from '../../../js/api/_training_api';
import removeStyleFromHTMLString from '../../../utilities/CommonUtilities';
import TrainingErrorDisplay from './training-error-display/training-error-display.page';
import {
  LINKEDIN_CONVERSION_ID,
  LINKEDIN_PARTNER_ID,
  REMOTE_LINKEDIN_TRACKING_SCRIPT,
} from '../../../js/api/_config';
import LinkedInTag from 'react-linkedin-insight';

// JP #Task
function TrainingTertiary(id) {
  const { trainingId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (
      window.location.pathname ===
      '/training/160060/Waste-Management-Workshop---Developing-Sustainable-Waste-Programs'
    ) {
      LinkedInTag.init(LINKEDIN_PARTNER_ID);
      LinkedInTag.track(LINKEDIN_CONVERSION_ID);
    }

    if (trainingId) {
      const fetchProductDetails = async (id) => {
        const response = await getSingleProduct(id);
        if (
          response &&
          response.Variants !== undefined &&
          response.Variants.length > 0
        )
          setProduct(response);
        else setProduct(0);
      };

      fetchProductDetails(trainingId);
    }
  }, []);

  if (product === null) return <Loader />;

  if (product === 0) return <TrainingErrorDisplay />;

  const getTestimonialRow = (element) => (
    <div key={element} style={{ marginRight: '1rem' }}>
      <hr />
      <span>{Parser(element)}</span>
    </div>
  );

  return (
    <div className="training-tertiary training">
      <Helmet link={[{ rel: 'canonical', href: window.location.href }]}>
        <meta charSet="utf-8" />
        {product && product.MetaDescription && (
          <meta name="description" content={product.MetaDescription} />
        )}
        {product && product.MetaTitle && <title>{product.MetaTitle}</title>}
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {/* <Hero
                alt={`${product?.Name} image`}
                template={'standard'}
                title={stripHtml(product?.Name).result}
                colorCategory={'train'}
            /> */}

      <Row className="shopping-cart module-bottom-spacer trinity-container">
        <Col xs={12} className="text-content-container">
          <div
            className="text-content background transparent"
            style={{ backgroundColor: 'rgba(7, 175, 154, 0.85)' }}
          >
            <div className="inner-wrapper  trinity-container">
              <h1 className="m-0">{stripHtml(product?.Title).result}</h1>
            </div>
          </div>
        </Col>
      </Row>

      <div className="trinity-container module-spacer">
        <Row>
          <Col sm={6} md={7}>
            {/* <Row className="top-container">
                            <Col xs={12} sm={8} md={8} className="pr-sm-3 pr-md-4">
                                <h1 style={{textAlign: "left"}}>{stripHtml(product?.Title).result}</h1>
                            </Col>
                        </Row> */}

            {product !== null ? (
              <div className="content-well">
                {Parser(
                  product && product.LongDescription != undefined
                    ? removeStyleFromHTMLString(product.LongDescription)
                    : null
                )}
              </div>
            ) : null}

            {product &&
            product.Agenda !== undefined &&
            product.Agenda.length > 0 ? (
              <>
                <h5>Course Topics</h5>
                <div className="content-well">
                  {Parser(
                    product && product.Agenda != undefined
                      ? product.Agenda
                      : null
                  )}
                </div>
              </>
            ) : null}

            {product &&
              product.Testimonials &&
              product.Testimonials.filter((e) => e !== null).length > 0 &&
              product.Testimonials.length > 0 && (
                <Row>
                  <h5>What others are saying about this course</h5>
                  {product.Testimonials.map((element) => (
                    <Col sm={12}>{getTestimonialRow(element)}</Col>
                  ))}
                </Row>
              )}
          </Col>
          <Col sm={6} md={5}>
            {/* <ProductLayout product={product} /> */}
            <ProductLayout product={product} />
            {product.AgendaPDF && product.AgendaPDF.length > 0 && (
              <div className="widget-wrapper">
                <SingleWidget
                  src={WidgetPdf}
                  link={product.AgendaPDF}
                  alt="pdf-image"
                  singleLinkText="Download the Course Agenda:"
                  // singleLinkSub='Download Adobe Acrobat Reader'
                />
              </div>
            )}
            <PartnerLayout product={product} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TrainingTertiary;
