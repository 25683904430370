export default function validate(values) {
  const errors = {};
  if (!values.NewPassword) {
    errors.NewPassword = 'New password is required';
  }
  if (!values.SetConfirmPassword) {
    errors.SetConfirmPassword = 'Confirm password is required';
  }
  if (values.NewPassword !== values.SetConfirmPassword) {
    errors.NewPassword = 'New password and confirm password should match';
    errors.SetConfirmPassword =
      'New password and confirm password should match';
  }

  return errors;
}
