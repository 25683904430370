import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
  trainingcategoryfilterdata,
  trainingdatefilterdata,
  trainingformatfilterdata,
  TrainingIgnoreDefaultSearch,
  trainingindustryfilterdata,
  traininglocationfilterdata,
} from '../../../redux/training/training.action';
import TrainingActiveSearchFilter from './training-active-search-filter.component';
import './training-active-search-filter.styles.scss';
import { useHistory } from 'react-router-dom';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';

function TrainingAdvanceSearchFilter({ istrainingladning }) {
  istrainingladning = istrainingladning = undefined ? false : istrainingladning;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(trainingformatfilterdata());
    dispatch(trainingcategoryfilterdata());
    dispatch(trainingindustryfilterdata());
    dispatch(traininglocationfilterdata());
    dispatch(trainingdatefilterdata());
  }, []);

  const dformat = useSelector(
    ({ training }) => training.TrainingFormatFilterData
  );
  const dcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterData
  );
  const dindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterData
  );
  const dlocation = useSelector(
    ({ training }) => training.TrainingLocationFilterData
  );
  const ddate = useSelector(({ training }) => training.TrainingDateFilterData);

  const pformat = useSelector(
    ({ training }) => training.TrainingFormatFilterSelected
  );
  const pcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterSelected
  );
  const pindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterSelected
  );
  const plocation = useSelector(
    ({ training }) => training.TrainingLocationFilterSelected
  );
  const pdate = useSelector(
    ({ training }) => training.TrainingDateFilterSelected
  );
  const pkeyword = useSelector(
    ({ training }) => training.TrainingKeywordFilterSelected
  );
  const trainingLoading = useSelector(
    ({ training }) => training.TrainingLoading
  );
  let IgnoreDefaultSearch = useSelector(
    ({ training }) => training.IgnoreDefaultSearch
  );

  const [format, setFormat] = useState([]);
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [location, setLocation] = useState([]);
  const [date, setDate] = useState([]);
  const [keyword, setKeyword] = useState([]);

  const handleChange = (event) => {
    event.persist();
    const name =
      event.nativeEvent.target[event.nativeEvent.target.selectedIndex];
    const { value } = event.target;
    if (event.target.name === 'Format') {
      if (name.text && name.text.includes('— By Format —')) {
        setFormat({});
      } else {
        setFormat((format) => ({
          ...format,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Category') {
      if (name.text && name.text.includes('— By Category —')) {
        setCategory({});
      } else {
        setCategory((category) => ({
          ...category,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Industry') {
      if (name.text && name.text.includes('— By Industry —')) {
        setIndustry({});
      } else {
        setIndustry((industry) => ({
          ...industry,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Location') {
      if (name.text && name.text.includes('— By Location —')) {
        setLocation({});
      } else {
        setLocation((location) => ({
          ...location,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Date') {
      if (name.text && name.text.includes('— By Date —')) {
        setDate({});
      } else {
        setDate((date) => ({
          ...date,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Keyword') {
      setKeyword((keyword) => ({
        ...keyword,
        Name: event.target.value,
        ID: '0',
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSuccess();
  };

  function submitSuccess() {
    if (
      format != null &&
      format.ID !== undefined &&
      format.ID !== '— Format —' &&
      format.Name !== '— Format —' &&
      format.ID !== 0 &&
      format.ID !== '0'
    )
      dispatch(selectTrainingActionFilter(format, pformat, 'Training_Format'));
    if (
      category != null &&
      category.ID !== undefined &&
      category.ID !== '— Category —' &&
      category.Name !== '— Category —' &&
      category.ID !== 0 &&
      category.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(category, pcategory, 'Training_Category')
      );
    }
    if (
      industry != null &&
      industry.ID !== undefined &&
      industry.ID !== '— Industry —' &&
      industry.Name !== '— Industry —' &&
      industry.ID !== 0 &&
      industry.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(industry, pindustry, 'Training_Industry')
      );
    }
    if (
      location != null &&
      location.ID !== undefined &&
      location.ID !== '— Location —' &&
      location.Name !== '— Location —' &&
      location.ID !== 0 &&
      location.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(location, plocation, 'Training_Location')
      );
    }
    if (
      date != null &&
      date.ID !== undefined &&
      date.ID !== '— Date —' &&
      date.Name !== '— Date —' &&
      date.ID !== 0 &&
      date.ID !== '0'
    )
      dispatch(selectTrainingActionFilter(date, pdate, 'Training_Date'));
    if (
      keyword !== null &&
      keyword !== undefined &&
      keyword.Name !== '' &&
      keyword.ID != null
    ) {
      dispatch(
        selectTrainingActionFilter(keyword, pkeyword, 'Training_Keyword')
      );
    }

    if (
      pformat.length == 0 &&
      pcategory.length == 0 &&
      pindustry.length == 0 &&
      pdate.length == 0 &&
      plocation.length == 0 &&
      pkeyword.length == 0
    ) {
      IgnoreDefaultSearch = true;
      dispatch(TrainingIgnoreDefaultSearch(true));
    } else {
      IgnoreDefaultSearch = false;
      dispatch(TrainingIgnoreDefaultSearch(false));
    }

    const selectformat = [];
    const selectcategory = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectdate = [];
    const selectkeyword = [];

    pformat.forEach((item) => {
      selectformat.push(item.Name);
    });
    pcategory.forEach((item) => {
      selectcategory.push(item.Name);
    });
    pindustry.forEach((item) => {
      selectindustry.push(item.Name);
    });
    plocation.forEach((item) => {
      selectlocation.push(item.Name);
    });
    pdate.forEach((item) => {
      selectdate.push(item.Name);
    });
    pkeyword.forEach((item) => {
      selectkeyword.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: selectformat,
      TrainingCategory: selectcategory,
      TrainingIndustry: selectindustry,
      TrainingLocation: selectlocation,
      TrainingDate: selectdate,
      TrainingKeywords: selectkeyword,
      IgnoreDefaultSearch,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));

    setFormat({ Name: '— Format —', ID: null });
    setCategory({ Name: '— Category —', ID: null });
    setIndustry({ Name: '— Industry —', ID: null });
    setLocation({ Name: '— Location —', ID: null });
    setDate({ Name: '— Date —', ID: null });
    setKeyword({ Name: '', ID: null });

    history.push({
      pathname: '/training/search',
      id: submissionObj,
    });
  }

  return (
    <div className="sidebar-with-filters module-spacer-bottom">
      <div className="advanced-search">
        <h2>ADVANCED SEARCH</h2>

        <TrainingActiveSearchFilter istrainingladning={false} />

        <TextInput
          placeholder="Keyword or phrase"
          type="text"
          name="Keyword"
          onChange={handleChange}
          aria-label="Search"
          value={keyword.Name ? keyword.Name : ''}
          autoComplete="off"
          className="xs-sm-only-small-text mb-sm-2"
        />

        {/* <SearchInput className="xs-sm-only-small-text mb-sm-2" /> */}

        {dformat && (
          <SelectList
            onChange={handleChange}
            name="Format"
            value={format.ID ? format.ID : '— By Format —'}
            defaultText="— By Format —"
            className="mb-2"
          >
            {dformat.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dcategory && (
          <SelectList
            onChange={handleChange}
            name="Category"
            value={category.ID ? category.ID : '— By Category —'}
            defaultText="— By Category —"
            className="mb-2"
          >
            {dcategory.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dindustry && (
          <SelectList
            onChange={handleChange}
            name="Industry"
            value={industry.ID ? industry.ID : '— By Industry —'}
            defaultText="— By Industry —"
            className="mb-2"
          >
            {dindustry.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {ddate && (
          <SelectList
            onChange={handleChange}
            name="Date"
            value={date.ID ? date.ID : '— By Date —'}
            defaultText="— By Date —"
            className="mb-2"
          >
            {ddate.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dlocation && (
          <SelectList
            onChange={handleChange}
            name="Location"
            value={location.ID ? location.ID : '— By Location —'}
            defaultText="— By Location —"
            className="mb-2"
          >
            {dlocation.map((item) => (
              <option className="filter" value={item.LocatioId}>
                {item.LocationName}
              </option>
            ))}
          </SelectList>
        )}

        <CustomButton
          onClick={handleSubmit}
          loading={trainingLoading}
          btndisabled={trainingLoading}
          className="ml-auto mr-auto d-block button-spacer"
        >
          Search
        </CustomButton>
      </div>
    </div>
  );
}

export default TrainingAdvanceSearchFilter;
