import React, { useEffect, useState } from 'react';
import './feed-back-pages.scss';
import { Form } from 'react-bootstrap';
import ReCaptcha from '../../components/commons/recaptcha/recaptcha.component';
import CustomButton from '../../components/commons/custom-button/custom-button.component';
import Hero from '../../components/commons/hero/hero.component';
import { useDispatch, useSelector } from 'react-redux';
import { getFeedbackData } from '../../redux/feed-back-data/feedback.action';
import Loader from '../../components/commons/helpers/loader/loader.component';
import { FeedbackServices } from '../../redux/feed-back-data/feedback.services';
import { useHistory } from 'react-router-dom';
import { handleNotify } from '../../js/helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../js/helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../js/helpers/toaster/toaster-types';
import EmptyTableComponent from '../../components/ecommerce/empty-component/empty-table-compontent';
import ErrorDisplay from '../specialty-pages/error-display/not-found.page';
import EmptyShoppingCart from '../../components/ecommerce/new-shopping-cart-items/empty-shopping-cart/empty-shopping-cart';
import $ from 'jquery';

const FeedbackComponent = ({
  content,
  subTitle,
  subContent,
  questionHeading,
  data,
  props,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [radioIds, setRadioIds] = useState([]);

  let userFeedBackData = [];

  const [radiouserFeedBackData, setRadioUserFeedBackData] = useState([]);
  const [textFeedBackData, setTextUserFeedBackData] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [recaptchaValue, setRecaptchaValue] = useState();
  const [recaptchaError, setRecaptchaError] = useState(false);

  const feedBackData = useSelector((state) => state.feedback.feedbackData);
  const acceptanceStatusData = useSelector(
    (state) => state.feedback.acceptanceStatus
  );

  useEffect(() => {
    dispatch(getFeedbackData(props.token));

    if (
      acceptanceStatusData !== undefined &&
      acceptanceStatusData.Complete &&
      acceptanceStatusData.SurveyResponseId === 0 &&
      acceptanceStatusData.Name === 'None'
    ) {
      history.push({
        pathname: '/feedBackClosedPage',
        state: {
          SessionId: acceptanceStatusData.SessionId,
          AttendeeId: acceptanceStatusData.AttendeeId,
        },
      });
    } else if (
      acceptanceStatusData !== undefined &&
      acceptanceStatusData.Complete
    ) {
      history.push({
        pathname: '/feedBackThankYouPage',
        state: {
          SessionId: acceptanceStatusData.SessionId,
          AttendeeId: acceptanceStatusData.AttendeeId,
        },
      });
    }
  }, [acceptanceStatusData]);

  useEffect(() => {
    var objradioIds = [];
    radiouserFeedBackData.map((item) => {
      var rids = item.Id + '_' + item.UserAnswer[0];
      objradioIds.push(rids);
    });
    objradioIds.map((item) => {
      $('input[name="radioName"][value="' + item + '"]').prop('checked', true);
    });
    textFeedBackData
      .filter((x) => x.QuestionType === 'Text')
      .map((item) => {
        $('textarea#' + item.Id).val(item.UserAnswer);
      });
  }, [radioIds]);

  const history = useHistory();

  if (typeof feedBackData === 'string') {
    return <Loader />;
  }

  const onChangeData = (element, questionData) => {
    if (userFeedBackData.length === 0 && finalData.length > 0)
      userFeedBackData = finalData;
    userFeedBackData = userFeedBackData
      .filter((e) => e.Id !== questionData.Id)
      .concat(Object.assign(questionData, { UserAnswer: [element] }));
    //setUserFeedBackData(uobj);
    //setRadioUserFeedBackData(userFeedBackData.filter(x => x.QuestionType === "MultipleChoice"));
  };

  const onChangeRecaptchaValue = (changevalue) => {
    setRecaptchaValue(changevalue);

    if (userFeedBackData.length === 0 && finalData.length > 0)
      userFeedBackData = finalData;

    let data = {
      requeststream: userFeedBackData,
    };

    const multichoice = feedBackData.filter(
      (x) => x.QuestionType === 'MultipleChoice'
    );
    const selectedmultichoice = userFeedBackData.filter(
      (x) => x.QuestionType === 'MultipleChoice'
    );

    setFinalData(userFeedBackData);
    setRadioUserFeedBackData(
      userFeedBackData.filter((x) => x.QuestionType === 'MultipleChoice')
    );
    setTextUserFeedBackData(
      userFeedBackData.filter((x) => x.QuestionType === 'Text')
    );

    let selectedIds = [];
    selectedmultichoice.map((data) => {
      selectedIds.push(data.Id);
    });

    var obj = [];
    if (selectedIds.length > 0) {
      for (var i = 0; i < multichoice.length; i++) {
        var mid = multichoice[i].Id;
        var sid = selectedIds.filter((x) => x === mid);
        if (sid == undefined || sid === null || sid.length == 0) obj.push(mid);
      }
    } else {
      multichoice.map((item) => {
        obj.push(item.Id);
      });
    }

    var objradioIds = [];
    userFeedBackData
      .filter((x) => x.QuestionType === 'MultipleChoice')
      .map((item) => {
        var rids = item.Id + '_' + item.UserAnswer[0];
        objradioIds.push(rids);
      });
    setRadioIds(objradioIds);
  };

  const onSubmitData = () => {
    if (userFeedBackData.length === 0 && finalData.length > 0)
      userFeedBackData = finalData;

    let data = {
      requeststream: userFeedBackData,
    };

    let firstQuestion = feedBackData && feedBackData[0];
    let textfieldlengh = data.requeststream.filter(
      (x) => x.Id == 223 || x.Id == 224 || x.Id == 225 || x.Id == 226
    ).length;

    setErrors([]);
    const multichoice = feedBackData.filter(
      (x) => x.QuestionType === 'MultipleChoice'
    );
    const selectedmultichoice = userFeedBackData.filter(
      (x) => x.QuestionType === 'MultipleChoice'
    );

    setFinalData(userFeedBackData);
    setRadioUserFeedBackData(
      userFeedBackData.filter((x) => x.QuestionType === 'MultipleChoice')
    );
    setTextUserFeedBackData(
      userFeedBackData.filter((x) => x.QuestionType === 'Text')
    );

    let selectedIds = [];
    selectedmultichoice.map((data) => {
      selectedIds.push(data.Id);
    });

    var obj = [];
    if (selectedIds.length > 0) {
      for (var i = 0; i < multichoice.length; i++) {
        var mid = multichoice[i].Id;
        var sid = selectedIds.filter((x) => x === mid);
        if (sid == undefined || sid === null || sid.length == 0) obj.push(mid);
      }
    } else {
      multichoice.map((item) => {
        obj.push(item.Id);
      });
    }

    setErrors(obj);

    var objradioIds = [];
    userFeedBackData
      .filter((x) => x.QuestionType === 'MultipleChoice')
      .map((item) => {
        var rids = item.Id + '_' + item.UserAnswer[0];
        objradioIds.push(rids);
      });
    setRadioIds(objradioIds);

    if (!recaptchaValue) {
      setRecaptchaError(true);
    } else {
      setRecaptchaError(false);
    }

    // if (feedBackData.length - 4 > data.requeststream.length - textfieldlengh) {
    //     handleNotify('Please fill & select all the questions and answers.', ToasterTypes.Error);
    // }
    if (obj.length === 0 && recaptchaValue) {
      FeedbackServices.submitFeedBackData(data).then(() => {
        handleNotify('Feedback submitted successfully.', ToasterTypes.Success);
        history.push({
          pathname: '/feedBackThankYouPage',
          state: {
            SessionId: firstQuestion.SessionId,
            AttendeeId: firstQuestion.AttendeeId,
          },
        });
      });
    }
  };

  if (feedBackData === undefined) {
    return (
      <EmptyTableComponent
        message={'No survey available.'}
        style={{ marginTop: 8 }}
      />
    );
  }

  return (
    <>
      <Hero
        title={'Course Evaluation'}
        template={'standard'}
        altMobileTitle={true}
        colorCategory={'gen'}
      />
      {/*KT add class feedback-responsive-changed 11-16-2021 */}
      <div className={'trinity-container feedback feedback-responsive-changed'}>
        <div className={'outer-div'}>
          <div className={'sub-title-content'}>
            <h5 className={'sub-title'}>
              {feedBackData &&
                feedBackData.length > 0 &&
                feedBackData[0].CourseName}
            </h5>
            <p className={'sub-content'}>{subContent}</p>
          </div>
          <div className={'que-heading'}>
            <p>{questionHeading}</p>
          </div>
          {feedBackData &&
            feedBackData.map((question) => {
              var eid =
                errors &&
                errors.length > 0 &&
                errors.find((x) => x === question.Id);
              switch (question.QuestionType.toLowerCase()) {
                case 'multiplechoice':
                  return (
                    <div
                      key={Math.random()}
                      className={'radio-design'}
                      style={
                        eid && eid > 0
                          ? {
                              border: '1px solid red',
                              paddingLeft: '10px',
                              marginTop: '5px',
                            }
                          : {}
                      }
                    >
                      <p
                        className={'question-text'}
                        style={{ display: 'inline-block' }}
                      >
                        {eid && eid > 0 ? (
                          <span>
                            <span>{question.QuestionValue}</span>
                            <span style={{ color: 'red' }}>{'*'}</span>
                          </span>
                        ) : (
                          <span>
                            {question.QuestionValue}{' '}
                            <span style={{ color: 'red' }}>{'*'}</span>
                          </span>
                        )}
                      </p>
                      <Form>
                        {['radio'].map((type) => (
                          <div className={'radio-div'}>
                            {question.AvailableAnswers.map((option) => (
                              <Form.Check
                                inline
                                label={option.Value}
                                type={'radio'}
                                key={Math.random()}
                                name={'radioName'}
                                onChange={(event) =>
                                  onChangeData(option.Id, question)
                                }
                                id={option.value}
                                value={question.Id + '_' + option.Id}
                              />
                            ))}
                          </div>
                        ))}
                      </Form>
                    </div>
                  );

                case 'text':
                  return (
                    <div key={Math.random()} className={'sub-div'}>
                      <Form.Group
                        key={Math.random()}
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <p className={'question-text'}>
                          {question.QuestionValue}
                        </p>
                        <Form.Control
                          key={Math.random()}
                          as="textarea"
                          rows="3"
                          onChange={(event) =>
                            onChangeData(event.target.value, question)
                          }
                          className={'area'}
                          id={question.Id}
                          controlId={question.Id}
                        />
                      </Form.Group>
                    </div>
                  );
              }
            })}

          <div className="recaptcha-div">
            <div
              style={
                recaptchaError === true
                  ? { border: '1px solid red', width: 'fit-content' }
                  : {}
              }
            >
              <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
            </div>
          </div>

          <div className="custome-button-div">
            <CustomButton
              className="heroLink"
              onClick={() => onSubmitData()}
              title={'Submit'}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedbackComponent;
