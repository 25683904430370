import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CustomTrainingForm from './custom-training-form/custom-training-form.component';
import './custom-training.styles.scss';

// RP #Task 64 Request Training Quote
function CustomTrainingOuter({ data, mainData, status }) {
  const LeftContent = mainData !== null ? mainData.LeftContent : '';
  const RightContent = mainData !== null ? mainData.RIghtContent : '';
  const MainContent = mainData !== null ? mainData.MainContent : '';
  return (
    <div className="custom-training-outer-container">
      <div className="top-content">
        <Row>
          <Col xs={12} sm={6}>
            <p
              className="content"
              dangerouslySetInnerHTML={{ __html: LeftContent }}
            />
          </Col>
          <Col xs={12} sm={6}>
            <div className="right-content">
              <p
                className="content"
                dangerouslySetInnerHTML={{ __html: RightContent }}
              />
            </div>
          </Col>
          <p
            className="content mb-1"
            dangerouslySetInnerHTML={{ __html: MainContent }}
          />
        </Row>
      </div>
      <CustomTrainingForm data={data} mainData={mainData} status={status} />
    </div>
  );
}

export default CustomTrainingOuter;
