import React from 'react';
import Carousel from '../carousel/carousel.component';
import { title, testimonials } from './testimonials.data';
import Pagination from '../../resources-and-media/pagination/pagination.component';

import './testimonials.styles.scss';

function Testimonials() {
  return (
    <div className="testimonals-container trinity-container">
      <p className="title">{title}</p>
      <hr />
      <Carousel unslickOnMobile={false}>
        {testimonials.map((testimonial) => (
          <div className="testimonal">
            <p className="content">{testimonial.content}</p>
            <p className="name">{testimonial.name}</p>
            <p className="job-title">{testimonial.jobTitle}</p>
            <p className="company-name">{testimonial.companyName}</p>
          </div>
        ))}
      </Carousel>
      <Pagination />
    </div>
  );
}

export default Testimonials;
