import { DropdownContentActionTypes } from './dropdown-content.types';

const INITIAL_STATE = {
  userStates: [],
  userCountries: [],
  userIndustries: [],
  industries: [],
  locations: [],
  resourceTypes: [],
  softwares: [],
};

export const dropdownContentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DropdownContentActionTypes.ADD_ALL_INDUSTRIES:
      return { ...state, industries: action.payload };
    case DropdownContentActionTypes.ADD_ALL_LOCATIONS:
      return { ...state, locations: action.payload };
    case DropdownContentActionTypes.ADD_ALL_RESOURCE_TYPES:
      return { ...state, resourceTypes: action.payload };
    case DropdownContentActionTypes.ADD_ALL_SOFTWARES:
      return { ...state, softwares: action.payload };
    case DropdownContentActionTypes.ADD_ALL_USER_COUNTRIES:
      return { ...state, userCountries: action.payload };
    case DropdownContentActionTypes.ADD_ALL_USER_INDUSTRIES:
      return { ...state, userIndustries: action.payload };
    case DropdownContentActionTypes.ADD_ALL_USER_STATES:
      return { ...state, userStates: action.payload };
    default:
      return { ...state };
  }
};
