export const CartActionTypes = {
  ADD_CART_ITEM: 'ADD_CART_ITEM',
  REMOVE_CART_ITEM: 'REMOVE_CART_ITEM',
  CLEAR_CART: 'CLEAR_CART',
  TOGGLE_CART_VIEW: 'TOGGLE_CART_VIEW',
  GET_CART_ITEM: 'GET_CART_ITEM',
  CART_COUNT_UPDATE: 'CART_COUNT_UPDATE',
  ADD_SOFTWARE_ITEM: 'ADD_SOFTWARE_ITEM',
  ADD_TRAINING_ITEM: 'ADD_TRAINING_ITEM',
  BASKET_ID: 'BASKET_ID',
  RESET_CART: 'RESET_CART',
  UPDATE_CART: 'UPDATE_CART',
  GET_CART_ATTENDEES: 'GET_CART_ATTENDEES',
  ATTENDEES_COUNT_UPDATE: 'ATTENDEES_COUNT_UPDATE',
  ADD_CART_ATTENDEES: 'ADD_CART_ATTENDEES',
  UPDATE_CART_ATTENDEES: 'UPDATE_CART_ATTENDEES',
  UPDATE_IMPERSONATE: 'UPDATE_IMPERSONATE',
  CART_STEPS: 'CART_STEPS',
  CART_CATEGORY_PARAMS: 'CART_CATEGORY_PARAMS',
};
