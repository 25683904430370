import React from 'react';
import Col from 'react-bootstrap/Col';
import EmptyShoppingCart from '../../../components/ecommerce/new-shopping-cart-items/empty-shopping-cart/empty-shopping-cart';
import NewShoppingCartItems from '../../../components/ecommerce/new-shopping-cart-items/shopping-cart-items.component';
import ShoppingCartProgressBar from '../../../components/ecommerce/shopping-cart-progress/shopping-cart-progress.component';
import ShoppingCartSidebar from '../../../components/ecommerce/shopping-cart-sidebar/shopping-cart-sidebar.component';

function ShoppingCartWrapperComponent({ cartItems, MainContent }) {
  return (
    <>
      {cartItems !== undefined && cartItems.ItemsCount > 0 ? (
        <>
          <ShoppingCartProgressBar />
          <Col xs={12} md={8} lg={8} style={{ marginTop: -20 }}>
            <NewShoppingCartItems mainContent={MainContent} />
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className="sidebar xs-only-trinity-container"
          >
            <ShoppingCartSidebar
              buttonText="Continue"
              mainContent={MainContent}
            />
          </Col>
        </>
      ) : (
        <Col xs={12} md={12} lg={12}>
          <EmptyShoppingCart />
        </Col>
      )}
    </>
  );
}

export default ShoppingCartWrapperComponent;
