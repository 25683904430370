import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from '../../../../js/custom-hooks/formValidationRules';
import {
  informationTitle,
  link,
  schoolTitle,
  supportTitle,
  uploadLink,
  uploadTitle,
} from './education-program-form.data';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../../commons/recaptcha/recaptcha.component';

import './education-program-form.styles.scss';

function EducationProgramForm() {
  const upload = () => {
    document.getElementById('upload-file').click();
  };
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  function submitSuccess() {
    history.push('/thank-you-page');
  }

  return (
    <div className="program-form-container">
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="title">{informationTitle}</p>
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
            />
            {errors.jobTitle && <span className="help">{errors.jobTitle}</span>}
            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
            />

            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
            />

            <p className="title">{schoolTitle}</p>
            {errors.university && (
              <span className="help">{errors.university}</span>
            )}
            <TextInput
              placeholder="University or College name"
              required
              type="text"
              name="university"
              onChange={handleChange}
              value={values.university || ''}
              autoComplete="off"
              className={`${errors.university && 'error'}`}
            />
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
            />
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
            />

            <SelectList defaultText="— Select a State —" className="pb-2" />
            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
            />

            <SelectList defaultText="— Select a Country —" />
          </Col>
          <Col xs={12} sm={6} className="right">
            <p className="title">{supportTitle}</p>
            <div className="upload">
              <p className="blurb">{uploadTitle}</p>
              {/* Still need to fix the click issue */}
              <div className="upload-link">
                <CustomButton className="upload-link" onClick={upload}>
                  {uploadLink}
                </CustomButton>
                <input type="file" id="upload-file" />
              </div>
              <textarea
                className="description-area"
                placeholder="Please inclue any additional information"
              />
              <SelectList defaultText="— How did you hear about us? —" />
            </div>
          </Col>
        </Row>
        <div className="recaptcha mb-2">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default EducationProgramForm;
