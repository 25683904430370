import React, { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import { resetTrainingFilterSet } from '../../../redux/training/training.action';

function NewTrainingLandingPage({
  page: {
    MainContent,
    PromoSectionContent,
    breadCrumbList,
    CourseCatalogContent,
  },
}) {
  const dispatch = useDispatch();

  const HeroComponent = React.lazy(() =>
    import('./new-training-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-training-content-component')
  );

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetTrainingFilterSet());
    }, 1500);
  }, []);

  return (
    <div className="training_lp training">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent
          MainContent={MainContent}
          PromoSectionContent={PromoSectionContent}
          CourseCatalogContent={CourseCatalogContent}
        />
      </Suspense>
    </div>
  );
}

export default NewTrainingLandingPage;
