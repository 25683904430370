import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function ComStaffing({ page: { MainContent } }) {
  if (MainContent) {
    return (
      <div className="general-container">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          template="standard"
          altMobileTitle
          colorCategory="staffing"
        />
        <div className="trinity-container module-spacer">
          <Col xs={12} lg={10}>
            <ContentWell content={MainContent.Content} />
          </Col>
        </div>
      </div>
    );
  }

  return <Loader />;
}

export default ComStaffing;
