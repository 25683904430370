import React, { useState, useEffect } from 'react';
import './sidebar-button.styles.scss';

import CustomButton from '../../custom-button/custom-button.component';

function SidebarButton({ resourcesText, resourcesLink }) {
  return (
    <div className="advanced-search-button">
      <h2>
        <a href={`/${resourcesLink}`}>{resourcesText} &gt;</a>
      </h2>
    </div>
  );
}

export default SidebarButton;
