import React from 'react';
import './image-with-overlay-text-listing.styles.scss';
import ImageWithTextOverlay from '../cta/image-with-overlay-text-cta/image-with-overlay-text-cta.component';

import { renderUniqueId } from '../../../js/helpers/_render-unique-id';

function ImageWithOverlayTextListing({ ctas, colorCategory }) {
  return (
    <div className="image-with-overlay-text-listing sm-up-module-spacer">
      {ctas.map(({ Category }, i) => (
        <div
          key={renderUniqueId(Category.IntroImage_title)}
          className="image-text-cta-container"
        >
          <ImageWithTextOverlay
            category={colorCategory}
            title={Category.Title}
            href={Category.url}
            image={{
              src: Category.IntroImage_src,
              alt: Category.IntroImage_alt,
            }}
          />
        </div>
      ))}
    </div>
  );
}

export default ImageWithOverlayTextListing;
