import { ENDPOINTS } from '../_config';

export const submitJobAlertSignUpResponse = async (fields, token) => {
  const location =
    ENDPOINTS.PROXY + ENDPOINTS.SUBMIT_AZURE_QUEUE.JOB_ALERT_SIGNUP;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
};
