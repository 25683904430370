import React from 'react';
import testImage from '../../../../images/industries/pdf.svg';
import ResourceTags from '../resource-tags/resource-tags.component';
import { widgetData } from '../resource-listing.data.js';
import './resource-posts.styles.scss';

// RP #Task 119 Resources
function ResourcePosts({ posts }) {
  const handlePDFClick = (resourceUrl) => {
    if (resourceUrl) window.open(resourceUrl);

    // fetch(resourceUrl)
    //   .then(response => {
    //     response.blob().then(blob => {
    //       let url = window.URL.createObjectURL(blob);
    //       let a = document.createElement('a');
    //       a.href = url;
    //       a.target = "blank";
    //       a.click();
    //     });
    //   });
  };

  return (
    <div className="posts-container">
      {posts &&
        posts.map((item, i) => (
          <div key={i} className="widgetBox-wrapper">
            <div className="widget-wrapper">
              <img
                src={item.GlobalIcon != '' ? item.GlobalIcon : null}
                alt=""
              />
              <div className="content">
                <p className="date">{item.strPublishedDate}</p>
                <a
                  href="javascript:void(0)"
                  onClick={() => handlePDFClick(item.Url)}
                  className="inside-link"
                >
                  {item.Title}
                </a>
                <p className="name">{item.AuthorName}</p>
                <ResourceTags tagData={item.Tags} />
              </div>
            </div>

            <hr />
          </div>
        ))}
    </div>
  );
}

export default ResourcePosts;
