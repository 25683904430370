import React from 'react';
import { Col } from 'react-bootstrap';
import './image-content.styles.scss';
import {
  CLASSROOM_URL,
  E_LEARNING_URL,
  SEMINAR_URL,
  WEBINAR_URL,
  COMPLIMENTARY_URL,
} from '../../constants/LinkConstants';

// Jayant Chauhan (Design changes for the component, Getting table data from the backend so fixed from the UI)
function ImageContent({ content }) {
  return (
    <Col md={8} sm={6}>
      <div className="image-text-container container">
        <div className="image-content-outer-wrapper-training-top">
          <div className="wrapper-inner-training-top-container">
            <div className="inner-sub-wrapper-container">
              <a
                className="link-button"
                target="_blank"
                href={window.location.origin + CLASSROOM_URL}
                rel="noreferrer"
              >
                <span className="link-text">Classroom ›</span>
              </a>
            </div>
          </div>

          <div className="wrapper-inner-training-top-container">
            <div className="inner-sub-wrapper-container">
              <a
                className="link-button"
                target="_blank"
                href={window.location.origin + WEBINAR_URL}
                rel="noreferrer"
              >
                <span className="link-text">Webinar ›</span>
              </a>
            </div>
          </div>

          <div className="wrapper-inner-training-top-container">
            <div className="inner-sub-wrapper-container">
              <a
                className="link-button"
                target="_blank"
                href={window.location.origin + SEMINAR_URL}
                rel="noreferrer"
              >
                <span className="link-text">Seminar ›</span>
              </a>
            </div>
          </div>

          <div className="wrapper-inner-training-top-container">
            <div className="inner-sub-wrapper-container">
              <a
                className="link-button"
                target="_blank"
                href={window.location.origin + COMPLIMENTARY_URL}
                rel="noreferrer"
              >
                <span className="link-text">Complimentary ›</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
}

export default ImageContent;
