import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { removeAttendeeItem } from '../../../../js/api/shopping-cart/_attendees-cart';
import {
  cartAttendeesUpdate,
  updateCartAttendeesAction,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import './assign-attendee-data.styles.scss';

function AssignAttendeeList({
  productTitle,
  sku,
  variantSku,
  setCourseNameForEdit,
  products,
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const cartattendeesArray = useSelector(
    ({ cart }) => cart.cartattendees,
    shallowEqual
  );

  const attendeescountupdate = useSelector(
    ({ cart }) => cart.attendeescount_update,
    shallowEqual
  );

  const [cartAttendeesContent, setcartAttendeesContent] = useState(
    cartattendeesArray
      ? cartattendeesArray.filter(
          (m) => m.VariantSku === variantSku && m.Sku === sku
        )
      : null
  );

  const [hasattendeeUpdate, setHasattendeeUpdate] = useState(false);

  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );

  const handleDelete = async (event) => {
    try {
      const attendeeId = Number(event.target.id);
      let memberId = null;
      if (cartitemimpersonate) {
        memberId = cartitemimpersonate.memberId;
      }
      const data = {
        attendeeid: attendeeId,
        memberid: memberId,
      };

      const preparedData = [].concat.apply(
        [],
        cartattendeesArray.map((e) => e.attendees)
      );

      const attendeeObj = preparedData.filter((m) => m.ID === attendeeId);
      if (attendeeObj && attendeeObj.length > 0) {
        const getcartResponse = await removeAttendeeItem(data);
        if (getcartResponse === 1) {
          // dispatch(cartCountUpdate(1));
          dispatch(cartAttendeesUpdate(1));
        }
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in attendee data delete call');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in attendee data delete call',
        },
      });
    }
  };

  const handleUpdate = async (event) => {
    try {
      dispatch(updateCartAttendeesAction(null));
      const attendeeId = Number(event.target.id);

      const preparedData = [].concat.apply(
        [],
        cartattendeesArray.map((e) => e.attendees)
      );
      const attendeeObj = preparedData.filter((m) => m.ID === attendeeId);

      const filteredCourseData = cartattendeesArray
        .map((e) => {
          const filteredList = e.attendees.filter(
            (inner) => inner.ID === attendeeId
          );

          return {
            courseName: e.CourseName,
            value: filteredList.length,
            variantSku: filteredList.length > 0 && filteredList[0].VariantSku,
          };
        })
        .filter((e) => e.value !== 0)[0];

      setCourseNameForEdit(
        filteredCourseData.courseName,
        filteredCourseData.variantSku
      );
      if (attendeeObj && attendeeObj.length > 0) {
        await dispatch(updateCartAttendeesAction(attendeeObj[0]));
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in attendee data update call');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in attendee data update call',
        },
      });
    }
  };

  const handleCopy = async (event) => {
    try {
      dispatch(updateCartAttendeesAction(null));
      const attendeeId = Number(event.target.id);

      const preparedData = [].concat.apply(
        [],
        cartattendeesArray.map((e) => e.attendees)
      );
      const attendeeObj = preparedData.filter((m) => m.ID === attendeeId);

      const filteredCourseData = cartattendeesArray
        .map((e) => {
          const filteredList = e.attendees.filter(
            (inner) => inner.ID === attendeeId
          );

          return {
            courseName: e.CourseName,
            value: filteredList.length,
            variantSku: filteredList.length > 0 && filteredList[0].VariantSku,
          };
        })
        .filter((e) => e.value !== 0)[0];

      setCourseNameForEdit(
        filteredCourseData.courseName,
        filteredCourseData.variantSku
      );
      if (attendeeObj && attendeeObj.length > 0) {
        const newPreparedObject = { ...attendeeObj[0], ID: '0' };
        await dispatch(updateCartAttendeesAction(newPreparedObject));
      }
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Attendee Page', 'Error in attendee data update call');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in attendee data update call',
        },
      });
    }
  };

  useEffect(() => {
    setHasattendeeUpdate(false);
    let result = [];
    if (cartattendeesArray) {
      if (variantSku && sku) {
        const hasItem = cartattendeesArray.filter(
          (m) => m.VariantSku === variantSku && m.Sku === sku
        );
        if (hasItem.length > 0) {
          result = hasItem;
        }
      } else {
        const hasItem = cartattendeesArray.filter((m) => m.Sku === sku);
        if (hasItem.length > 0) {
          result = hasItem;
        }
      }
    }
    setcartAttendeesContent(result);
    setHasattendeeUpdate(true);
  }, [
    cartattendeesArray.length,
    attendeescountupdate,
    variantSku,
    JSON.stringify(cartattendeesArray),
  ]);

  const getSessionTypeName = (courseName, variantSku) => {
    const filteredData = products?.filter(
      (e) => e.productTitle === courseName && e.variantsku === variantSku
    )[0];
    return (
      filteredData && {
        sessionTypeName: filteredData.sessionTypeName,
        totalMembers: filteredData.quantity,
      }
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Copy Attendee Information
    </Tooltip>
  );

  const renderEditToolTip = (props) => (
    <Tooltip id="edit-button-tooltip" {...props}>
      Edit Attendee
    </Tooltip>
  );

  const renderDeleteToolTip = (props) => (
    <Tooltip id="delete-button-tooltip" {...props}>
      Delete Attendee
    </Tooltip>
  );

  let warningArray = [];

  warningArray = cartattendeesArray
    ?.filter((e) => {
      const filteredProductData = getSessionTypeName(
        e.CourseName,
        e.VariantSku
      );
      return (
        e.attendees !== undefined &&
        e.attendees.length !== filteredProductData?.totalMembers
      );
    })
    .map((e) => e.CourseName);

  return (
    <div className="assign-attendees-listing-outer">
      <span className="assign-attendee-top-heading">Attendee(s) Data</span>
      {warningArray !== undefined && warningArray.length > 0 && (
        <table>
          <tr>
            <td>
              <span className="assign-attendee-warning">
                <i
                  className="fa fa-exclamation-triangle fa-2x"
                  aria-hidden="true"
                />
              </span>
            </td>
            <td style={{ marginLeft: '5px' }}>
              <span className="assign-attendee-warning">
                Add/omit Attendees for{' '}
                <strong>{warningArray.join(', ')}</strong> to equal Total
                Attendees
              </span>
            </td>
          </tr>
        </table>
      )}
      {cartattendeesArray !== undefined &&
        cartattendeesArray.map((cartAttendee, index) => {
          const filteredProductData = getSessionTypeName(
            cartAttendee.CourseName,
            cartAttendee.VariantSku
          );
          return (
            <div key={index}>
              <hr />
              <span className="assign-attendee-course-name">
                {cartAttendee.CourseName}
              </span>
              <div>
                <span className="assign-attendee-course-sub-name">
                  {filteredProductData ? (
                    <>
                      {filteredProductData.sessionTypeName} -{' '}
                      {`${cartAttendee.attendees.length} of ${filteredProductData.totalMembers} Attendee(s) Added`}
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </span>
              </div>

              {cartAttendee !== undefined &&
                cartAttendee.attendees !== undefined &&
                cartAttendee.attendees.map((item, index) => (
                  <div key={index} className="sub-outer-view">
                    <div className="sub-top-bar">
                      <div className="top-attendee-heading">
                        Attendee #{index + 1}
                      </div>

                      <div>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderEditToolTip}
                        >
                          <i
                            className="fa fa-pencil icon-outer-view"
                            id={item.ID}
                            style={{ marginRight: '10px' }}
                            onClick={handleUpdate}
                            aria-hidden="true"
                          />
                        </OverlayTrigger>

                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderDeleteToolTip}
                        >
                          <i
                            className="fa fa-trash icon-outer-view"
                            id={item.ID}
                            onClick={handleDelete}
                            aria-hidden="true"
                          />
                        </OverlayTrigger>
                      </div>
                    </div>

                    <div className="sub-bottom-bar-view">
                      <div className="left-bottom-bar-view">
                        <span className="sub-data-font">{item.FirstName}</span>
                        <span className="sub-data-font">{item.Title}</span>
                        <span className="sub-data-font">
                          {item.EmailAddress}
                        </span>
                        <span className="sub-data-font">{item.Company}</span>
                        {item?.DietaryRestrictionOption === 'y' && (
                          <span className="sub-data-font">
                            {item?.DietaryRestrictionMealOptions &&
                              item?.DietaryRestrictionMealOptions}
                          </span>
                        )}
                      </div>

                      <div className="right-bottom-bar-view">
                        <span className="sub-data-font">{item.Company}</span>
                        <span className="sub-data-font">{item.Address}</span>
                        <span className="sub-data-font">{item.City}</span>
                        <span className="sub-data-font">{item.PostalCode}</span>

                        {item?.DietaryRestrictionOption === 'y' &&
                          item?.DietaryRestrictionMealOptionsOther &&
                          item?.DietaryRestrictionMealOptionsOther !==
                            'None' && (
                            <span
                              className="sub-data-font"
                              style={{ width: '250px' }}
                            >
                              {item?.DietaryRestrictionMealOptionsOther}
                            </span>
                          )}
                      </div>
                    </div>

                    <div className="bottom-copy-bar-view">
                      {/* <div>
                        {item?.DietaryRestrictionOption === 'y' && (
                          <span className="sub-data-font">
                            <CheckIcon fontSize="small" />
                            Dietary Instructions{' '}
                            {item?.DietaryRestrictionMealOptionsOther &&
                              `(${item?.DietaryRestrictionMealOptionsOther})`}
                          </span>
                        )}
                      </div> */}

                      <div>
                        <span
                          id={item.ID}
                          onClick={handleCopy}
                          style={{
                            fontSize: '12px',
                            marginRight: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          Copy Attendee
                        </span>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <i
                            className="fa fa-clone icon-outer-view"
                            id={item.ID}
                            onClick={handleCopy}
                            aria-hidden="true"
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          );
        })}
    </div>
  );
}

export default AssignAttendeeList;
