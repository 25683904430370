import calender from '../../../../images/industries/calender.svg';
import pdf from '../../../../images/industries/pdf.svg';
import video from '../../../../images/industries/video.svg';
import factory from '../../../../images/industries/factory.svg';
import classroomImg from '../../../../images/homepage/classroom.svg';
import WebinarsImg from '../../../../images/homepage/Webinars.svg';
import SeminarsImg from '../../../../images/homepage/Seminars.svg';

export const singleWidgetLinks = [
  {
    widgetTitle: 'Upcoming Training Courses',
    widgetContent: 'Accidental Release Modeling Workshop',
    widgetDate: 'June 23, 2020',
    widgetSubContent: 'Dallas, TX',
    widgetLink: 'All Training Courses ›',
    widgetImage: classroomImg,
  },
  {
    widgetTitle: 'Related Resources',
    widgetContent: 'Air Dispersion Modeling Services Sheet',
    widgetDate: 'June 23, 2020',
    singleLink: 'Download Adobe Acrobat Reader',
    widgetLink: 'All Resources ›',
    widgetImage: WebinarsImg,
  },
  {
    widgetTitle: 'Related News',
    widgetContent: 'News article headline goes here',
    widgetDate: 'June 23, 2020',
    singleLink: 'Download Adobe Acrobat Reader',
    widgetLink: 'All News ›',
    widgetImage: SeminarsImg,
  },
  {
    widgetTitle: 'Related Products',
    widgetContent: 'AERSCREEN',
    widgetDate: 'June 23, 2020',
    widgetSubContent: 'A screening model',
    widgetLink: 'All Products ›',
    widgetImage: SeminarsImg,
  },
];

export default singleWidgetLinks;
