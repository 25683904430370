import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StaffingSubmitResumeForm from '../../../components/staffing/forms/resume-form/resume.component';
import JobDetailsWidget from '../../../components/staffing/widgets/job-details-widget/job-details.component';
import Hero from '../../../components/commons/hero/hero.component';
import { getResumePageData } from '../../../js/api/staffing/_resume.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function StaffingSubmitResume({ page: { modules } }) {
  // RP #Task 55 - Apply online form
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [responseAuthor, setResponseAuthor] = useState([]);
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getResumePageData();
      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
        setResponseAuthor(getResponse.Authors);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.State !== undefined &&
      responseAuthor.length > 0)
  ) {
    return (
      <div className="individual-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="policies"
        />
        <div className="trinity-container">
          <Row>
            <Col xs={12} sm={8} className="resume-container">
              <StaffingSubmitResumeForm
                data={responseDropDownContent}
                author={responseAuthor}
                status={status}
              />
            </Col>
            <Col xs={12} sm={4}>
              <JobDetailsWidget />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default StaffingSubmitResume;
