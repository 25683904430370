import React from 'react';
import { SupportServices, title } from './support-side-widget.data';
import { truncatetext } from '../../../../js/helpers/_truncate-text';
import './support-side-widget.styles.scss';

function SupportSideWidget({ data }) {
  return (
    <div className={data.length !== 0 ? 'services-container' : 'services'}>
      <p className="title">{title}</p>
      {data &&
        data.map((item) => (
          <div className="inner-services">
            <a href={item.Link} className="service-title">
              {item.LinkText}
            </a>
            <p
              className="content"
              dangerouslySetInnerHTML={{ __html: item.Content }}
            />
          </div>
        ))}
    </div>
  );
}

export default SupportSideWidget;
