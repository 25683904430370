import React, { useState } from 'react';
import './filter-controls.styles.scss';

import FilterButton from '../filter-button/filter-button.component';
import { renderUniqueId } from '../../../../js/helpers/_render-unique-id';

const FilterControls = React.memo((props) => {
  const { filter, categories, title } = props;

  const [categoriesArray] = useState(
    categories ? Object.entries(categories) : null
  );

  if (categoriesArray) {
    return (
      <div className="category-filter-controls">
        <div className="trinity-container relative fluid">
          <select
            onChange={(e) => filter(e)}
            name="filter-select"
            id="filter-select"
          >
            <option value="" selected disabled hidden>
              {title}
            </option>
            <option data-category="all" value="all">
              All
            </option>
            {categoriesArray.map(([category, categoryId]) => (
              <option
                data-category={categoryId}
                value={categoryId}
                key={renderUniqueId(categoryId)}
              >
                {category}
              </option>
            ))}
          </select>
          <span className="arrow d-sm-none" />
        </div>

        <div className="filter-buttons d-none d-sm-flex flex-wrap">
          <FilterButton
            data-category="all"
            onClick={(e) => filter(e)}
            colorCategory="all"
          >
            All
          </FilterButton>
          {categoriesArray.map(([category, categoryId]) => (
            <FilterButton
              data-category={categoryId}
              key={renderUniqueId(categoryId)}
              onClick={(e) => filter(e)}
              colorCategory={categoryId}
            >
              {category}
            </FilterButton>
          ))}
        </div>
      </div>
    );
  }

  return null;
});

export default FilterControls;
