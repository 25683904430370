import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  removeTrainingActionFilter,
  TrainingIgnoreDefaultSearch,
} from '../../../redux/training/training.action';
import CloseBtn from '../../commons/helpers/close-btn/close-btn.component';
import './training-active-search-filter.styles.scss';

function TrainingActiveSearchFilter({ istrainingladning }) {
  const dispatch = useDispatch();
  const pformat = useSelector(
    ({ training }) => training.TrainingFormatFilterSelected
  );
  const pcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterSelected
  );
  const pindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterSelected
  );
  const plocation = useSelector(
    ({ training }) => training.TrainingLocationFilterSelected
  );
  const pregulatoryagency = useSelector(
    ({ training }) => training.TrainingRegulatoryAgencyFilterSelected
  );
  const pdate = useSelector(
    ({ training }) => training.TrainingDateFilterSelected
  );
  const pkeyword = useSelector(
    ({ training }) => training.TrainingKeywordFilterSelected
  );
  // const pbusinessunit = useSelector(({ training }) => training.TrainingBusinessUnitFilterSelected);
  const trainingLoading = useSelector(
    ({ training }) => training.TrainingLoading
  );
  const IgnoreDefaultSearch = useSelector(
    ({ training }) => training.IgnoreDefaultSearch
  );

  const [rformat, setRFormat] = useState(false);
  const [rcategory, setRCategory] = useState(false);
  const [rindustry, setRIndustry] = useState(false);
  const [rlocation, setRLocation] = useState(false);
  const [rregulatoryagency, setRRegulatoryAgency] = useState(false);
  const [rdate, setRDate] = useState(false);
  const [rkeyword, setRKeyword] = useState(false);
  // const [rbusinessunit, setRBusinessunit] = useState(false);

  useEffect(() => {
    if (
      pformat.length == 0 &&
      pcategory.length == 0 &&
      pindustry.length == 0 &&
      pdate.length == 0 &&
      pkeyword.length == 0 &&
      pregulatoryagency.length == 0 &&
      plocation.length == 0
    )
      dispatch(TrainingIgnoreDefaultSearch(true));
    else dispatch(TrainingIgnoreDefaultSearch(false));

    if (rformat) {
      submitSuccess();
      setRFormat(false);
    } else if (rcategory) {
      submitSuccess();
      setRCategory(false);
    } else if (rindustry) {
      submitSuccess();
      setRIndustry(false);
    } else if (rlocation) {
      submitSuccess();
      setRLocation(false);
    } else if (rregulatoryagency) {
      submitSuccess();
      setRRegulatoryAgency(false);
    } else if (rdate) {
      submitSuccess();
      setRDate(false);
    } else if (rkeyword) {
      submitSuccess();
      setRKeyword(false);
    }
    // else if (rbusinessunit) {
    //     submitSuccess();
    //     setRBusinessunit(false);
    // }
  }, [
    pformat,
    pcategory,
    pindustry,
    pdate,
    plocation,
    pregulatoryagency,
    pkeyword,
    // pbusinessunit,
    IgnoreDefaultSearch,
  ]);

  function submitSuccess() {
    const selectformat = [];
    const selectcategory = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectregulatoryagency = [];
    const selectdate = [];
    const selectkeyword = [];
    // const selectbusinessunit = [];

    pformat.forEach((item) => {
      selectformat.push(item.Name);
    });
    pcategory.forEach((item) => {
      selectcategory.push(item.Name);
    });
    pindustry.forEach((item) => {
      selectindustry.push(item.Name);
    });
    pdate.forEach((item) => {
      selectdate.push(item.Name);
    });
    plocation.forEach((item) => {
      selectlocation.push(item.Name);
    });
    pregulatoryagency.forEach((item) => {
      selectregulatoryagency.push(item.Name);
    });
    pkeyword.forEach((item) => {
      selectkeyword.push(item.Name);
    });
    // pbusinessunit.forEach((item) => {
    //     selectbusinessunit.push(item.Name)
    // });

    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: selectformat,
      TrainingCategory: selectcategory,
      TrainingIndustry: selectindustry,
      TrainingLocation: selectlocation,
      TrainingRegulatoryAgency: selectregulatoryagency,
      TrainingDate: selectdate,
      TrainingKeywords: selectkeyword,
      // TrainingBusinessUnit: selectbusinessunit,
      IgnoreDefaultSearch,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  }

  const handleFormatClick = (item) => {
    if (!trainingLoading) {
      dispatch(removeTrainingActionFilter(item, pformat, 'Training_Format'));
      setRFormat(true);
      if (
        pformat.length == 1 &&
        pcategory.length == 0 &&
        pindustry.length == 0 &&
        pdate.length == 0 &&
        pkeyword.length == 0 &&
        pregulatoryagency.length == 0 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleCategoryClick = (item) => {
    if (!trainingLoading) {
      dispatch(
        removeTrainingActionFilter(item, pcategory, 'Training_Category')
      );
      setRCategory(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 1 &&
        pindustry.length == 0 &&
        pdate.length == 0 &&
        pkeyword.length == 0 &&
        pregulatoryagency.length == 0 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleIndustryClick = (item) => {
    if (!trainingLoading) {
      dispatch(
        removeTrainingActionFilter(item, pindustry, 'Training_Industry')
      );
      setRIndustry(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 0 &&
        pindustry.length == 1 &&
        pdate.length == 0 &&
        pkeyword.length == 0 &&
        pregulatoryagency.length == 0 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleDateClick = (item) => {
    if (!trainingLoading) {
      dispatch(removeTrainingActionFilter(item, pdate, 'Training_Date'));
      setRDate(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 0 &&
        pindustry.length == 0 &&
        pdate.length == 1 &&
        pkeyword.length == 0 &&
        pregulatoryagency.length == 0 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleLocationClick = (item) => {
    if (!trainingLoading) {
      dispatch(
        removeTrainingActionFilter(item, plocation, 'Training_Location')
      );
      setRLocation(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 0 &&
        pindustry.length == 0 &&
        pdate.length == 0 &&
        plocation.length == 1 &&
        pkeyword.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleKeywordClick = (item) => {
    if (!trainingLoading) {
      dispatch(removeTrainingActionFilter(item, pkeyword, 'Training_Keyword'));
      setRKeyword(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 0 &&
        pindustry.length == 0 &&
        pdate.length == 0 &&
        pkeyword.length == 1 &&
        pregulatoryagency.length == 0 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  const handleRegulatoryAgencyClick = (item) => {
    if (!trainingLoading) {
      dispatch(
        removeTrainingActionFilter(item, pkeyword, 'Training_RegulatoryAgency')
      );
      setRKeyword(true);
      if (
        pformat.length == 0 &&
        pcategory.length == 0 &&
        pindustry.length == 0 &&
        pdate.length == 0 &&
        pkeyword.length == 0 &&
        pregulatoryagency.length == 1 &&
        plocation.length == 0
      )
        dispatch(TrainingIgnoreDefaultSearch(true));
      else dispatch(TrainingIgnoreDefaultSearch(false));
    }
  };

  // const handleBusinessUnitClick = (item) => {
  //     if (!trainingLoading) {
  //         dispatch(removeTrainingActionFilter(item, pbusinessunit, "Training_Business_Unit"));
  //         setRFormat(true);
  //         if (pformat.length == 1 && pcategory.length == 0 && pindustry.length == 0 && pdate.length == 0 && plocation.length == 0 && pkeyword.length == 0 && pbusinessunit.length == 0)
  //             dispatch(TrainingIgnoreDefaultSearch(true));
  //         else
  //             dispatch(TrainingIgnoreDefaultSearch(false));
  //     }
  // };

  return (
    <div className="">
      {!istrainingladning && (
        <h3 className="active-filters-title">Active Search Filters:</h3>
      )}
      <ul>
        {pformat && pformat.length > 0
          ? pformat.map((item) => (
              <li
                className="list-item bg-tags"
                onClick={() => handleFormatClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pcategory && pcategory.length > 0
          ? pcategory.map((item) => (
              <li
                className="list-item bg-news"
                onClick={() => handleCategoryClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pindustry && pindustry.length > 0
          ? pindustry.map((item) => (
              <li
                className="list-item bg-industry"
                onClick={() => handleIndustryClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pdate && pdate.length > 0
          ? pdate.map((item) => (
              <li
                className="list-item bg-pdate"
                onClick={() => handleDateClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {plocation && plocation.length > 0
          ? plocation.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleLocationClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pregulatoryagency && pregulatoryagency.length > 0
          ? pregulatoryagency.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleRegulatoryAgencyClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {pkeyword && pkeyword.length > 0
          ? pkeyword.map((item) => (
              <li
                className="list-item bg-location"
                onClick={() => handleKeywordClick(item)}
              >
                <span className="flist-item-text">
                  {item.Name &&
                    item.Name.charAt(0).toUpperCase() + item.Name.slice(1)}{' '}
                </span>
                {!trainingLoading && <CloseBtn className="ml-1 ml-sm-4" />}
              </li>
            ))
          : null}

        {/* {pbusinessunit && pbusinessunit.length > 0
                    ? pbusinessunit.map((item) => (
                        <li className='list-item bg-location' onClick={() => handleBusinessUnitClick(item)}>
                            <span
                                className='flist-item-text'>{item.Name && (item.Name.charAt(0).toUpperCase() + item.Name.slice(1))} </span>
                            {!trainingLoading && <CloseBtn className='ml-1 ml-sm-4' />}
                        </li>
                    ))
                    : null} */}
      </ul>
    </div>
  );
}

export default TrainingActiveSearchFilter;
