import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activePageNumber,
  getNewPageWiseResource,
  loadResource,
  selectResourceActionFilter,
} from '../../../../redux/resource/resource.action';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import ResourceActiveSearchFilter from './resource-active-search-filter.component';

function ResourceAdvanceFilter() {
  const dispatch = useDispatch();
  const pkeyword = useSelector(({ resource }) => resource.keywordFilter);
  const presourceType = useSelector(
    ({ resource }) => resource.resourcetypeFilter
  );
  const pindustry = useSelector(({ resource }) => resource.industryFilter);
  const pservice = useSelector(({ resource }) => resource.serviceFilter);
  const plocation = useSelector(({ resource }) => resource.locationFilter);
  const resourceLoading = useSelector(
    ({ resource }) => resource.resourceLoading
  );
  const resourceFilter = useSelector(({ resource }) => resource.filterList);

  const [keyword, setKeyword] = useState([]);
  const [resourceType, setResourceType] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [service, setService] = useState([]);
  const [location, setLocation] = useState([]);

  const handleChange = (event) => {
    event.persist();
    if (event.target.name === 'ResourceType') {
      setResourceType((resourceType) => ({
        ...resourceType,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Industry') {
      setIndustry((industry) => ({
        ...industry,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Service') {
      setService((service) => ({
        ...service,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Location') {
      setLocation((location) => ({
        ...location,
        Name: event.nativeEvent.target[event.nativeEvent.target.selectedIndex]
          .text,
        Id: event.target.value,
      }));
    } else if (event.target.name === 'Keyword') {
      setKeyword((keyword) => ({
        ...keyword,
        Name: event.target.value,
        Id: '0',
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSuccess();
  };

  function submitSuccess() {
    if (
      resourceType != null &&
      resourceType.Id != undefined &&
      resourceType.Id != '— Resource Type —' &&
      resourceType.Name != '— Resource Type —' &&
      resourceType.Id != 0 &&
      resourceType.Id != '0'
    ) {
      dispatch(
        selectResourceActionFilter(resourceType, presourceType, 'Resource_Type')
      );
    }
    if (
      industry != null &&
      industry.Id != undefined &&
      industry.Id != '— Industry —' &&
      industry.Name != '— Industry —' &&
      industry.Id != 0 &&
      industry.Id != '0'
    )
      dispatch(selectResourceActionFilter(industry, pindustry, 'Industry'));
    if (
      service != null &&
      service.Id != undefined &&
      service.Id != '— Service —' &&
      service.Name != '— Service —' &&
      service.Id != 0 &&
      service.Id != '0'
    )
      dispatch(selectResourceActionFilter(service, pservice, 'Service'));
    if (
      location != null &&
      location.Id != undefined &&
      location.Id != '— Location —' &&
      location.Name != '— Location —' &&
      location.Id != 0 &&
      location.Id != '0'
    )
      dispatch(selectResourceActionFilter(location, plocation, 'Location'));
    if (
      keyword != null &&
      keyword != undefined &&
      keyword.Id != null &&
      keyword.Name != ''
    )
      dispatch(selectResourceActionFilter(keyword, pkeyword, 'Keyword'));

    const selectresourcetype = [];
    const selectindustry = [];
    const selectservice = [];
    const selectlocation = [];
    const selectkeyword = [];

    presourceType.map((item) => {
      selectresourcetype.push(item.Name);
    });
    pindustry.map((item) => {
      selectindustry.push(item.Id);
    });
    pservice.map((item) => {
      selectservice.push(item.Id);
    });
    plocation.map((item) => {
      selectlocation.push(item.Id);
    });
    pkeyword.map((item) => {
      selectkeyword.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      ResourceType: selectresourcetype,
      Industry: selectindustry,
      Services: selectservice,
      Location: selectlocation,
      KeywordorPhrase: selectkeyword,
    };

    dispatch(loadResource(true));
    dispatch(getNewPageWiseResource(submissionObj));
    dispatch(activePageNumber(1));

    setKeyword({ Name: '', Id: null });
    setResourceType({ Name: '— Resource Type —', Id: null });
    setIndustry({ Name: '— Industry —', Id: null });
    setService({ Name: '— Service —', Id: null });
    setLocation({ Name: '— Location —', Id: null });
  }

  return (
    <div className="sidebar-with-filters module-spacer-bottom">
      <div className="advanced-search">
        <h2>ADVANCED SEARCH</h2>

        <ResourceActiveSearchFilter />

        <TextInput
          placeholder="Keyword or phrase"
          type="text"
          name="Keyword"
          onChange={handleChange}
          aria-label="Search"
          value={keyword.Name ? keyword.Name : ''}
          autoComplete="off"
          className="xs-sm-only-small-text mb-sm-2"
        />

        {resourceFilter && resourceFilter.AllResourceTypeData && (
          <SelectList
            onChange={handleChange}
            name="ResourceType"
            value={resourceType.Id ? resourceType.Id : '— Resource Type —'}
            defaultText="— Resource Type —"
            className="mb-2"
          >
            {resourceFilter.AllResourceTypeData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {resourceFilter && resourceFilter.AllIndustryData && (
          <SelectList
            onChange={handleChange}
            name="Industry"
            value={industry.Id ? industry.Id : '— Industry —'}
            defaultText="— Industry —"
            className="mb-2"
          >
            {resourceFilter.AllIndustryData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {resourceFilter && resourceFilter.AllServiceData && (
          <SelectList
            onChange={handleChange}
            name="Service"
            value={service.Id ? service.Id : '— Service —'}
            defaultText="— Service —"
            className="mb-2"
          >
            {resourceFilter.AllServiceData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {resourceFilter && resourceFilter.AllLocationData && (
          <SelectList
            onChange={handleChange}
            name="Location"
            value={location.Id ? location.Id : '— Location —'}
            defaultText="— Location —"
            className="mb-2"
          >
            {resourceFilter.AllLocationData.map((item) => (
              <option className="filter" value={item.Id}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        <CustomButton
          onClick={handleSubmit}
          loading={resourceLoading}
          btndisabled={resourceLoading}
          className="ml-auto mr-auto d-block button-spacer"
        >
          Search
        </CustomButton>
      </div>
    </div>
  );
}

export default ResourceAdvanceFilter;
