import HomepageModal from './homepage-modal';

const HomePages = [
  {
    name: 'Homepage',
    path: '/',
    template: 'homepage',
    modules: HomepageModal({
      hero: {
        hero_src: '',
        hero_title: 'HELPING BUSINESSES ACHIEVE THEIR ENVIRONMENTAL OBJECTIVES',
      },
    }),
  },
];

export default JSON.stringify(HomePages);
