import React, { useEffect, useState } from 'react';

function ShoppingCartProductItemWarning({ cta, requireattendees }) {
  const [maxAttendees] = useState(cta.MaxAttendees);
  const [totalRegisterAttendees] = useState(cta.TotalRegisterAttendees);
  const [remainingAttendees] = useState(maxAttendees - totalRegisterAttendees);
  const [attendeesSessionTypeName] = useState(requireattendees);
  const [sessionTypeName] = useState(cta.SessionTypeName);
  const [hasAttendees, setHasAttendees] = useState(false);

  useEffect(() => {
    if (attendeesSessionTypeName.includes(sessionTypeName.toLowerCase())) {
      setHasAttendees(true);
    }
  }, []);

  if (hasAttendees && remainingAttendees <= 5) {
    return (
      <div className="warning-text">
        <p className="warning-1">
          Only
          {remainingAttendees}
        </p>
        <p className="warning-2">&nbsp; seats left &nbsp; !</p>
      </div>
    );
  }
  return null;
}

export default ShoppingCartProductItemWarning;
