import React from 'react';
import Col from 'react-bootstrap/Col';
import Skeleton from 'react-loading-skeleton';
import ShoppingCartProgressBar from '../../../components/ecommerce/shopping-cart-progress/shopping-cart-progress.component';

function ReviewOrderHeaderComponent({ MainContent }) {
  return (
    <>
      <Col xs={12} className="text-content-container">
        <div className="text-content background transparent">
          <div className="inner-wrapper  trinity-container">
            {/* {
                            MainContent
                                ? <h1 className="m-0">{MainContent.Title}</h1>
                                : <Skeleton width={300} height={45}/>
                        } */}
            <h1 className="m-0">Review Order</h1>
          </div>
        </div>
      </Col>
      <ShoppingCartProgressBar />
    </>
  );
}

export default ReviewOrderHeaderComponent;
