import React from 'react';
import './admin-resource-posts.styles.scss';

// RK Task#114 fixed null check
function AdminResourcePosts({ posts }) {
  return (
    <div className="posts-container">
      {posts &&
        posts.map((item, i) => (
          <div key={i} className="widgetBox-wrapper">
            <div className="widget-wrapper">
              {item.GlobalIcon && (
                <img
                  src={item.GlobalIcon !== '' ? item.GlobalIcon : null}
                  alt=""
                />
              )}
              <div className="content">
                <p className="date">{item.strPublishedDate}</p>
                <a href={item.Url} className="inside-link">
                  {item.Title}
                </a>
                <p className="name">{item.AuthorName}</p>
                {/* <ResourceTags
                                    tagData={item.Tags}
                                /> */}
              </div>
            </div>
            <hr />
          </div>
        ))}
    </div>
  );
}

export default AdminResourcePosts;
