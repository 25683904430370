import { CourseActionTypes } from './course.types';

const COURSE_INITIAL_STATE = {
  trainingByLocationList: '',
};

export const trainingCourses = (state = COURSE_INITIAL_STATE, action) => {
  switch (action.type) {
    case CourseActionTypes.GET_TRAINING_DATA_BY_LOCATION:
      if (action.payload) {
        return {
          ...state,
          trainingByLocationList: action.payload,
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const countryStateList = (state = '', action) => {
  switch (action.type) {
    case CourseActionTypes.GET_COUNTRY_STATE_WISE_DATA:
      if (action.payload) {
        return action.payload;
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const stateWiseMetaInformation = (state = '', action) => {
  switch (action.type) {
    case CourseActionTypes.STATE_WISE_META_INFORMATION:
      if (action.payload) {
        return action.payload;
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};
