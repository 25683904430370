import { ImpersonateServices } from './impersonate.services';
import { impersonateTypes } from './impersonate.types';

export const getAllImpersonateUsers =
  (skip, top, search = '') =>
  (dispatch, getState) => {
    const requestBody = {
      count: true,
      skip: skip * top,
      top: top,
      searchMode: 'any',
      queryType: 'simple',
      search: '*',
      orderby: 'search.score() desc',
      search: search,
      // scoringStatistics: 'global',
    };

    ImpersonateServices.getImpersonateUsers(requestBody).then((response) => {
      dispatch({
        type: impersonateTypes.GET_ALL_IMPERSONATE_USERS,
        payload: response != null && response ? response : [],
      });
    });
  };
