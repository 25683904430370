import React from 'react';
import ResourcePosts from './resource-posts/resource-posts.component';
import './resource-listing.styles.scss';
import Pagination from 'react-js-pagination';
import Loader from '../../commons/helpers/loader/loader.component';

// RP #Task 106 News Landing
function ResourceListing(props) {
  if (props.newsLoading) {
    return <Loader />;
  }

  const changedData = (newsList, deleteKey) =>
    newsList.map((newsElement) => {
      delete newsElement[deleteKey];
      return newsElement;
    });

  if (props.newsList && props.newsList.length > 0) {
    return (
      <div className="outerwrapper">
        <h3 style={{ marginBottom: '2rem' }}>
          Total{' '}
          <span style={{ color: 'rgba(114, 152, 54, 0.85)' }}>
            {props.totalItemsCount}
          </span>{' '}
          news found
        </h3>
        <ResourcePosts posts={changedData(props.newsList, 'GlobalIcon')} />
        <Pagination
          prevPageText="Previous"
          nextPageText="Next"
          activePage={props.activePage}
          itemsCountPerPage={15} // TODO: Hardcoding this one to fix the issues. Changes will get introduce later : Vishal(Suggested) + Jayant(Done)
          totalItemsCount={props.totalItemsCount}
          pageRangeDisplayed={5}
          onChange={props.handlePageChange}
        />
      </div>
    );
  }
  return (
    <div className="outerwrapper">
      <p>No Records found</p>
    </div>
  );
}

export default ResourceListing;
