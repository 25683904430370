import React from 'react';

import './accordion.styles.scss';

function Accordion({ title, description, expand, onClick }) {
  return (
    <div className="each-accordion">
      <dt className={expand ? 'title is-expanded' : 'title'} onClick={onClick}>
        {title}
      </dt>
      <dd
        className={expand ? 'content is-expanded' : 'content'}
        onClick={onClick}
      >
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </dd>
    </div>
  );
}

export default Accordion;
