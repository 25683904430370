import React, { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import heroImageSrc from '../../../../images/about/policiesHero.svg';
import Hero from '../../../../components/commons/hero/hero.component';
import ImageWithOverlayTextListing from '../../../../components/about/image-with-overlay-text-listing/image-with-overlay-text-listing.component';
import Breadcrumbs from '../../../../components/commons/breadcrumbs/breadcrumbs.component';
import { getLinksFromCategoryContent } from '../../../../js/helpers/_get-links-from-category-content';
import ContentWell from '../../../../components/commons/content-well/content-well.component';
import './policies-individual-category.styles.scss';
import { defaultContent } from '../../../../components/commons/content-well/content-well.data';
import { getPoliciesPageData } from '../../../../js/api/about/_individual-policy.data';
import Loader from '../../../../components/commons/helpers/loader/loader.component';

function PoliciesIndividual({ page: { MainContent }, section }) {
  // #RP - Task 92 - Bind data to Policy-Individual Form in Front-End.
  const [responseCategoryContent, setResponseCategoryContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getPoliciesPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseCategoryContent(getResponse.CategoryContent);
      }
    };
    fetchData();
  }, []);

  if (
    MainContent &&
    MainContent.Title != null &&
    responseCategoryContent &&
    Object.keys(responseCategoryContent).length > 0
  ) {
    return (
      <div className={`policy policies-individual-category ${section || ''}`}>
        <Hero
          src={MainContent != null ? MainContent.HeaderImage_src : ''}
          mobileImgSrc={
            MainContent != null ? MainContent.HeaderMobileImage_src : ''
          }
          alt={MainContent != null ? MainContent.HeaderImage_alt : ''}
          title={MainContent != null ? MainContent.Title : ''}
          description={
            MainContent.HeaderText != null ? MainContent.HeaderText : ''
          }
          template="landing"
          colorCategory="policies"
        />
        <div className="sm-up-trinity-container individual-policy-content-container module-spacer-bottom">
          <ImageWithOverlayTextListing
            ctas={responseCategoryContent}
            colorCategory="policies"
          />

          <div className="xs-only-trinity-container mt-5">
            <ContentWell content={MainContent.Content} />
          </div>
        </div>
      </div>
    );
  }
  if (status == noContent)
    return <div>Please contact admin for page content</div>;
  return <Loader />;
}

export default PoliciesIndividual;
