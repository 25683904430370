import React from 'react';
import { TreeView } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import './TrinitySiteSearch.scss';
import moment from 'moment';
import InputRange from 'react-input-range';
import { TrinitySearchServices } from '../../redux/trinity-search/trinity-search.services';
import CheckboxContainer from './common-components/checkbox-container/CheckBoxContainer';
import { trinityTrainingDateFormatter } from '../../utilities/CommonUtilities';

function TrinitySiteSearchFilterComponent(props) {
  const getDynamicTreeElements = () =>
    props.facetsList &&
    props.facetsList
      .map((outerElement) => {
        if (outerElement.value.length > 0) {
          return <CheckboxContainer outerElement={outerElement} {...props} />;
        }
        return null;
      })
      .filter((e) => e !== null);

  /* const getStaticTreeNodes = () => {
        return (
            <div>
                <div>
                    <span className={"tree-view-font"}>Categories</span>
                    <div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.all} name={tabListConstant.all}
                                   checked={props.activeStaticSearch.includes(tabListConstant.all)}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.all)}
                                   value={tabListConstant.all}/>
                            <span className={"tree-view-font"}>{tabListConstant.all} ({props.allCounts})</span>
                        </div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.services} name={tabListConstant.services}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.services)}
                                   checked={props.activeStaticSearch.includes(tabListConstant.services)}
                                   value={tabListConstant.services}/>
                            <span className={"tree-view-font"}>{tabListConstant.services}({props.servicesCounts})</span>
                        </div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.trainings} name={tabListConstant.trainings}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.trainings)}
                                   checked={props.activeStaticSearch.includes(tabListConstant.trainings)}
                                   value={tabListConstant.trainings}/>
                            <span
                                className={"tree-view-font"}>{tabListConstant.trainings} ({props.trainingsCounts})</span>
                        </div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.news} name={tabListConstant.news}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.news)}
                                   checked={props.activeStaticSearch.includes(tabListConstant.news)}
                                   value={tabListConstant.news}/>
                            <span className={"tree-view-font"}>{tabListConstant.news} ({props.newsCounts})</span>
                        </div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.software}
                                   name={tabListConstant.software}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.software)}
                                   checked={props.activeStaticSearch.includes(tabListConstant.software)}
                                   value={tabListConstant.software}/>
                            <span
                                className={"tree-view-font"}>{tabListConstant.software} ({props.softwareCounts})</span>
                        </div>
                        <div className="current-year--issue-list">
                            <input type="checkbox" id={tabListConstant.resources} name={tabListConstant.resources}
                                   onChange={(e) => props.onChangeStaticSearchCategories(e.target.checked, tabListConstant.resources)}
                                   checked={props.activeStaticSearch.includes(tabListConstant.resources)}
                                   value={tabListConstant.resources}/>
                            <span
                                className={"tree-view-font"}>{tabListConstant.resources} ({props.resourcesCounts})</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }; */

  return (
    <div className="outside-filter-container">
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className="activity-search-label">Search Filters</div>
        <div>
          <span
            style={{
              cursor: 'pointer',
              color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
            }}
            onClick={props.resetAllFiltersAndSearch}
          >
            Clear All{' '}
            <i
              className="fa fa-window-close"
              style={{
                fontSize: '20px',
                color: !props.isShowRedIcon && 'rgb(239, 96, 8)',
              }}
              aria-hidden="true"
            />
          </span>
        </div>
      </div>
      <div className="activity-dropdown">
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {/* {getStaticTreeNodes()} */}

          <div style={{ marginTop: '1rem' }}>
            <span className="tree-view-key-font">Published Dates</span>
            <div className="tree-key-element-border" />

            <span>
              {trinityTrainingDateFormatter(props.rangeState.min)} to{' '}
              {trinityTrainingDateFormatter(props.rangeState.max)}
            </span>
            <div
              className="date-range-slider"
              style={{ margin: props.openSwipableDrawer && '2rem' }}
            >
              <InputRange
                minValue={moment(TrinitySearchServices.dateStartConstant)
                  .toDate()
                  .getTime()}
                maxValue={moment().add(1, 'y').toDate().getTime()}
                step={604800}
                formatLabel={(val) => ''}
                value={props.rangeState}
                onChangeComplete={props.rangePickerChangeComplete}
                onChange={props.onChangeHandleChange}
              />
            </div>
          </div>

          <div>{getDynamicTreeElements()}</div>
        </TreeView>
      </div>
    </div>
  );
}

export default TrinitySiteSearchFilterComponent;
