import * as Sentry from '@sentry/react';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CustomButton from '../../../components/commons/custom-button/custom-button.component';
import ReviewPayAssignedAttendeeFormListing from '../../../components/ecommerce/review-shopping-cart-items/review-pay-assign-attendee-form-listing/pay-assign-attendee-form-listing.component';
import ReviewShoppingCartItems from '../../../components/ecommerce/review-shopping-cart-items/review-shopping-cart-items.component';
import ReviewTrainingPolices from '../../../components/ecommerce/review-shopping-cart-items/review-training-policies-sidebar/training-policies-sidebar.component';
import ReviewShoppingCartSidebar from '../../../components/ecommerce/review-shopping-cart-items/shopping-cart-sidebar/shopping-cart-sidebar.component';
import { getAttendees } from '../../../js/api/shopping-cart/_attendees-cart';
import { zeroPaymentSuccessRequest } from '../../../js/api/shopping-cart/_payment-cart';
import {
  UpdateCartConfirmParams,
  addCartAttendeesAction,
  cartCountUpdate,
  updateAllowCartStepsAction,
} from '../../../redux/shopping-cart/shopping-cart.action';

function ReviewOrderContentComponent({ MainContent }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const [trainingProduct, settrainingProduct] = useState(null);
  const user = useSelector(({ user }) => user.tokens.access_token);
  const [attendeesSessionTypeName] = useState(
    cartitems.AttendeesSessionTypeName
  );
  const [submitting, setSubmitting] = useState(false);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );

  useEffect(() => {
    const hasTraining = cartitems.OrderLines.filter(
      (orderline) => orderline.Category === 'Training'
    ).length;
    if (cartitems && cartitems.OrderLines.length === 0) {
      history.push('shopping-cart');
    }
    if (hasTraining > 0) {
      const trainings = cartitems.OrderLines.filter(
        (orderline) => orderline.Category === 'Training'
      );
      const prod = [];
      trainings.forEach((element) => {
        if (
          attendeesSessionTypeName.includes(
            element.SessionTypeName.toLowerCase()
          )
        ) {
          prod.push({
            productTitle: element.ProductName,
            quantity: element.Quantity,
            sku: element.Sku,
            variantsku: element.VariantSku,
            relatedattendees: [],
          });
        }
      });

      async function checkUserLoggedIn(items) {
        if (user) {
          let cartcount = 0;
          const getcartitemResponse = await getCartAttendees();

          if (getcartitemResponse) {
            const attendees = getcartitemResponse.Attendees;
            dispatch(addCartAttendeesAction(attendees));

            if (attendees && attendees.length > 0) {
              cartcount = attendees.length;
              // ordercart.OrderLines.forEach((a) => (cartcount += a.Quantity));
              if (items) {
                items.forEach((element) => {
                  if (element.variantsku) {
                    const hasItem = attendees.filter(
                      (m) =>
                        m.VariantSku === element.variantsku &&
                        m.Sku === element.sku
                    );
                    if (hasItem.length > 0) {
                      element.relatedattendees = hasItem;
                    }
                  } else {
                    const hasItem = attendees.filter(
                      (m) => m.Sku === element.sku
                    );
                    if (hasItem.length > 0) {
                      element.relatedattendees = hasItem;
                    }
                  }
                });
              }
            }
          }

          settrainingProduct(items);
        }
      }

      checkUserLoggedIn(prod);
    } else {
      settrainingProduct(null);
    }
  }, []);

  const getCartAttendees = async () => {
    let memberId;
    if (cartitemimpersonate) {
      memberId = cartitemimpersonate.memberId;
    }
    return await getAttendees(memberId);
  };

  const handlePayNow = async () => {
    try {
      setSubmitting(true);
      if (cartitems.OrderTotal > 0) {
        dispatch(updateAllowCartStepsAction(4));
        history.push(`${location.pathname}/payment`);
      } else {
        let memberId = null;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
        const data = {
          memberid: memberId,
          isbothcategory: cartitems.IsBothCategory,
          isonlytraining: cartitems.IsOnlyTraining,
          isonlysoftware: cartitems.IsOnlySoftware,
        };
        // skip payment and confirm order
        const stripeResponse = await zeroPaymentSuccessRequest(data);

        if (
          stripeResponse &&
          stripeResponse.status &&
          stripeResponse.status === 1
        ) {
          const categoryparams = {
            isOnlySoftware: cartitems.IsOnlySoftware,
            isOnlyTraining: cartitems.IsOnlyTraining,
            bothCategory: cartitems.IsBothCategory,
            orderConfirmationNumber:
              stripeResponse.data.OrderConfirmationNumber,
          };
          dispatch(UpdateCartConfirmParams(categoryparams));
          dispatch(cartCountUpdate(1));
          dispatch(updateAllowCartStepsAction(5));
          const pageState = {
            paymentCompleted: stripeResponse.data.PaymentStatus === 'Completed',
          };
          history.push(
            `${location.pathname}/payment/order-confirmation`,
            pageState
          );
        }
      }
      setSubmitting(false);
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in review order paynow');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in review order paynow',
        },
      });
    }
  };

  const handleCancel = async () => {
    setSubmitting(true);
    history.push('/shopping-cart');
    setSubmitting(false);
  };

  return (
    <>
      <Col xs={12} md={9} lg={8}>
        <ReviewShoppingCartItems />
        <hr />
        <ReviewPayAssignedAttendeeFormListing products={trainingProduct} />

        <div className="mt-4 mb-4 mt-md-0 submission-button-container">
          <CustomButton
            loading={submitting}
            onClick={handlePayNow}
            type="submit"
            className="mb-1 mr-sm-2 mt-4"
          >
            Pay Now
          </CustomButton>
          <CustomButton
            loading={submitting}
            onClick={handleCancel}
            altColors
            type="submit"
            className="mb-1 mt-4"
          >
            Cancel
          </CustomButton>
        </div>
      </Col>
      <Col xs={12} md={3} lg={4} className="sidebar xs-only-trinity-container">
        <ReviewShoppingCartSidebar
          buttonText="Pay Now"
          // mainContent={MainContent}
        />
        <ReviewTrainingPolices />
      </Col>
    </>
  );
}

export default ReviewOrderContentComponent;
