import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../../js/custom-hooks/useForm';
import validate from './custom-training-validationRules';
import TextInput from '../../../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../../../commons/form-inputs/select-list/select-list.component';
import ReCaptcha from '../../../../commons/recaptcha/recaptcha.component';
import CustomButton from '../../../../commons/custom-button/custom-button.component';
import { link } from './custom-training-form.data';
import { submitRequestTrainingQuoteResponse } from '../../../../../js/api/trainingForm/_request-training-quote.data';
import './custom-training-form.styles.scss';
import { createToken } from '../../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../../../commons/helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../../../js/api/_config';

function CustomTrainingForm({ data, mainData, status }) {
  // #RP - Task 64 - Request Training Quote
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const SubTitle = mainData != null ? mainData.SubTitle : '';

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitRequestTrainingQuoteResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.title.trim(),
      Company: values.companyName.trim(),
      Industry: values.industry != null ? values.industry.trim() : 'None',
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : 'None',
      PostalCode: values.postalCode != null ? values.postalCode.trim() : 'None',
      Country: values.country != null ? values.country.trim() : 'None',
      PhoneNumber: values.phone != null ? values.phone.trim() : '0',
      FaxNumber: values.fax != null ? values.fax.trim() : '0',
      EmailAddress: values.email.trim(),
      TopicsofInterest:
        values.topicsofinterest != null
          ? values.topicsofinterest.trim()
          : 'None',
      NumberofParticipants:
        values.numberofparticipants != null
          ? values.numberofparticipants.trim()
          : '0',
      Timeframe: values.timeframe != null ? values.timeframe.trim() : 'None',
      FormUniqueId: ListOfFormUniqueData.request_training_quote.UniqueId,
      Catalog: ListOfFormUniqueData.request_training_quote.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  return (
    <div className="custom-training-form-container">
      <form className="inner-form" onSubmit={handleSubmit} noValidate>
        <p
          className="form-title"
          dangerouslySetInnerHTML={{ __html: SubTitle }}
        />
        <Row>
          <Col xs={12} sm={6} className="pr-4">
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />
            {errors.title && <span className="help">{errors.title}</span>}
            <TextInput
              placeholder="Title"
              required
              type="text"
              name="title"
              onChange={handleChange}
              value={values.title || ''}
              autoComplete="off"
              className={`${errors.title && 'error'}`}
              maxlength={200}
            />
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company"
              required
              type="text"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              autoComplete="off"
              className={`${errors.companyName && 'error'}`}
              maxlength={200}
            />
            <SelectList
              required
              className="mb-2"
              defaultText="— Select an Industry —"
              onChange={handleChange}
              name="industry"
              value={values.industry}
            >
              {status !== noContent &&
                Industry &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={200}
            />
            <SelectList
              className="mb-2"
              defaultText="— Select a Country —"
              name="countryName"
              value={values.countryName}
              onChange={handleChange}
            >
              {Country &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            <SelectList
              className="mb-2"
              defaultText="— State/Province —"
              name="state"
              onChange={stateclick}
              value={values.state}
            >
              {State &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.postalCode && (
              <span className="help">{errors.postalCode}</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />
          </Col>
          <Col xs={12} sm={6}>
            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            <TextInput
              placeholder="Fax"
              autoComplete="off"
              type="text"
              name="fax"
              onChange={handleChange}
              value={values.fax}
              maxlength={100}
            />
            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={100}
            />

            <TextInput
              placeholder="Topics of Interest"
              type="text"
              name="topicsofinterest"
              onChange={handleChange}
              value={values.topicsofinterest}
              autoComplete="off"
              maxlength={200}
            />
            <TextInput
              placeholder="Number of participants"
              type="text"
              name="numberofparticipants"
              onChange={handleChange}
              value={values.numberofparticipants}
              autoComplete="off"
              maxlength={200}
            />
            <TextInput
              placeholder="Timeframe"
              type="text"
              name="timeframe"
              onChange={handleChange}
              value={values.timeframe}
              autoComplete="off"
              maxlength={200}
            />
            <textarea
              name="comments"
              placeholder="comments"
              required
              onChange={handleChange}
              value={values.comments}
            />
          </Col>

          <div className="bottom-container mt-5">
            <div className="recaptcha">
              <ReCaptcha />
            </div>
            <div className="link">
              <CustomButton className="heroLink">{link}</CustomButton>
            </div>
          </div>
        </Row>
      </form>
    </div>
  );
}

export default CustomTrainingForm;
