import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import { locationsData } from './locations-map.data.js';
import customMarkerImage from '../../../../images/red_dot.gif';
import CustomMarker from '../custom-marker/custom-marker.component';
import './google-map.styles.scss';

class GoogleMap extends Component {
  static defaultProps = {
    center: {
      lat: 31.395308317072,

      lng: -99.555269414963,
    },
  };

  displayMarkers = () => {
    const data = locationsData;
    return data.map((location, index) => (
      <CustomMarker
        key={index}
        id={index}
        lat={location.latitude}
        lng={location.longitude}
        text={location.state}
        image={customMarkerImage}
      />
    ));
  };

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBM2AvVtQ27kN9h3guGJwgCGIqiVE-laNk' }}
          defaultCenter={this.props.center}
          defaultZoom={4}
        >
          {this.displayMarkers()}
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
