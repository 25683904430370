import React from 'react';
import {
  job_title,
  job_id,
  industry_name,
  location,
  job_type,
  pay_rate,
} from './job-details.data';

import './job-details.styles.scss';

function JobDetailsWidget() {
  return (
    <div className="job-details-container">
      <p className="title">{job_title}</p>
      <p className="job-id">
        Job Id:
        {job_id}
      </p>
      <p className="industry-name">
        Industry:
        {industry_name}
      </p>
      <p className="location">
        Location:
        {location}
      </p>
      <p className="job-type">
        Job Type:
        {job_type}
      </p>
      <p className="pay-rate">
        Pay Rate:
        {pay_rate}
      </p>
    </div>
  );
}

export default JobDetailsWidget;
