import { ENDPOINTS } from './_config';

export const createToken = async () => {
  const tokenJson = {
    id: 0,
    username: 'TrinityMessageService',
    password: 'DC28CF68-1EFB-4A5B-B513-429135499DFB',
    token: 'string',
  };
  const location = ENDPOINTS.PROXY + ENDPOINTS.CREATE_AZURE_QUEUE_TOKEN;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tokenJson),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    const data = await fetchResponse.json();
    return data.token;
  } catch (e) {
    return e;
  }
};

export const submitAzureQueueData = async (fields, token) => {
  const location = ENDPOINTS.PROXY + ENDPOINTS.SUBMIT_FORM_AZURE_QUEUE;
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  try {
    const fetchResponse = await fetch(location, settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
};
