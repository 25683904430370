import React from 'react';

// import KeywordSearch from './keyword-search/keyword-search.component';
import AdvancedSearch from './advanced-search/advanced-search.component';
// RK Task#104 Training Secondary Change
function SidebarWithFilters({
  updateInput,
  state,
  handleSubmit,
  resourceText,
  resourceTextLink,
  selectedCategory,
}) {
  return (
    <div className="sidebar-with-filters module-spacer-bottom">
      <AdvancedSearch
        updateInput={updateInput}
        state={state}
        handleSubmit={handleSubmit}
        resourceText={resourceText}
        resourceTextLink={resourceTextLink}
        selectedCategory={selectedCategory}
      />
    </div>
  );
}

export default SidebarWithFilters;
