import React, { useEffect, useState } from 'react';
import './FeedBackThankYouStyles.scss';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../../components/commons/custom-button/custom-button.component';
import { ClientServices } from '../../../redux/clientdata/client.services';

function FeedBackClosedPage({
  title = 'Sorry!!!',
  subTitle = 'Survey has been closed',
  instructionMessage = 'Please click on the below button to continue.',
}) {
  const history = useHistory();
  const [paramTitle, setParamTitle] = useState(null);
  const [paramSubTitle, setParamSubTitle] = useState(null);
  const [paramInstructionMessage, setParamInstructionMessage] = useState(null);

  useEffect(() => {
    const { location } = history;
    const { state } = location;
    if (location && state) {
      setParamTitle(state.title);
      setParamSubTitle(state.subTitle);
      setParamInstructionMessage(state.instructionMessage);
    }
  }, [history.location.state]);

  function createAndDownloadBlobFile(body, filename, extension = 'pdf') {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      let link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        // document.body.appendChild(link);
        link.click();
        // document.body.removeChild(link);
      }
    }
  }

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64); // Comment this if not using base64
    const bytes = new Uint8Array(binaryString.length);
    return bytes.map((byte, i) => binaryString.charCodeAt(i));
  }

  const onDownloadFeedBackCertificate = () => {
    const data = history.location.state;
    ClientServices.getClientFeedBackCertificate(data).then((response) => {
      if (response.FileContents !== undefined) {
        const arrayBuffer = base64ToArrayBuffer(response.FileContents);
        createAndDownloadBlobFile(arrayBuffer, 'FeedBackCertificate');
      }
    });
  };

  return (
    <div className="thankyou-display">
      <div className="trinity-container">
        <div className="outer-container">
          <FontAwesomeIcon icon={faSmile} className="smile-icon" />
          <hr />
          <span className="outer-font">{paramTitle || title}</span>
          <h2
            className="light-font"
            dangerouslySetInnerHTML={{ __html: paramSubTitle || subTitle }}
          />
          <br />
          {/* <span className={'light-font'}>{paramInstructionMessage || instructionMessage}</span> */}
          <CustomButton
            title="Download Certificate"
            onClick={onDownloadFeedBackCertificate}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
        </div>
      </div>
    </div>
  );
}

export default FeedBackClosedPage;
