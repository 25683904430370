import React, { useCallback, useEffect, useState } from 'react';
import './login-form.styles.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { useCookies } from 'react-cookie';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';

import InitializeGoogleAnalytics, {
  trackLoginGA4Event,
  trackSoftwareGA4Event,
  trackTrainingGA4Event,
} from '../../../../TrackGoogleAnalytics';
import {
  isLoginFirsttimeUpdate,
  updateLastActivitydate,
} from '../../../../js/api/_register-user';
import { authenticateUser } from '../../../../js/api/auth/_auth';
import { getUserProfileRes } from '../../../../js/api/auth/_user-profile';
import { addCartItem } from '../../../../js/api/shopping-cart/_shopping-cart';
import { cartCountUpdate } from '../../../../redux/shopping-cart/shopping-cart.action';
import { addLoggedinUser } from '../../../../redux/user/user.action';
import { UserActionTypes } from '../../../../redux/user/user.types';

function LoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  const verifiedUserEmail = useSelector(({ user }) => user.verified_email);
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState(!!verifiedUserEmail);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [username, setUsername] = useState(verifiedUserEmail || '');
  const [password, setPassword] = useState('');

  const usernameOnChange = (e) => setUsername(e.target.value);
  const passwordOnChange = (e) => setPassword(e.target.value);
  /* NL: TASK 61 # Shopping Cart */
  const redirect_router = useSelector(({ user }) => user.redirect_router);
  const software_item = useSelector(({ cart }) => cart.software_item);
  const cartitemupdate = useSelector(({ cart }) => cart.cartitemupdate);
  const [cookies, removeCookie] = useCookies(['basketid']);

  useEffect(() => {
    InitializeGoogleAnalytics();
  }, []);

  /* NL: TASK 61 # Shopping Cart */
  const handleSubmit = async () => {
    trackLoginGA4Event(username);
    setSubmitting(true);
    const { access_token, refresh_token } = await authenticateUser({
      username,
      password,
    });

    if (access_token) {
      addUser(access_token, refresh_token);
      const getcartitemResponse = await getUserProfile();
      if (getcartitemResponse) {
        if (getcartitemResponse.User) {
          // const isLoginFirsttime = getcartitemResponse.User.IsLoginFirsttime;
          // if (!isLoginFirsttime) {
          //     // need to update to true
          //     await postLoginFirstTimeCheck();
          // }

          await postUpdateLastActivitydate();
          dispatch(addLoggedinUser(getcartitemResponse.User));
        }
      }
      removeCookie('basketid', '');
      if (redirect_router) {
        if (software_item) {
          // Added for GA4 Training
          if (software_item?.isTraining) {
            trackTrainingGA4Event(
              software_item?.product,
              software_item.productid,
              true,
              software_item?.isPartner
            );
          }

          // Added for GA4 Training
          if (software_item?.isSoftware) {
            const { mainContent, productData, productQty } =
              software_item?.softwareData;
            trackSoftwareGA4Event(productData, mainContent, productQty, true);
          }

          const getcartResponse = await addItemToCart(
            software_item.productid,
            software_item.quantity
          );
          if (getcartResponse === 0) {
            dispatch(cartCountUpdate(1));
          }
          const redirect_name = redirect_router.redirect_router;
          history.push(redirect_name);
        } else {
          const redirect_name = redirect_router.redirect_router;
          history.push(redirect_name);
        }
      } else {
        const {
          Address,
          City,
          Company,
          CountryID,
          Email,
          FirstName,
          LastName,
          PhoneNumber,
          PostalCode,
          StateID,
          Title,
          // IndustryID,
        } = getcartitemResponse.User;

        if (
          !FirstName ||
          !LastName ||
          !Title ||
          !Email ||
          !Company ||
          !Address ||
          !City ||
          !StateID ||
          !CountryID ||
          !PostalCode ||
          !PhoneNumber
        ) {
          history.push('/edit-profile');
        } else {
          history.push('/my-account');
        }
      }
    } else {
      setErrorMessage('The username or password is incorrect');
      setSubmitting(false);
    }
  };

  const postLoginFirstTimeCheck = async () => {
    const getResponse = await isLoginFirsttimeUpdate();
  };

  const postUpdateLastActivitydate = async () => {
    const getResponse = await updateLastActivitydate();
  };

  const getUserProfile = async () => {
    const getResponse = await getUserProfileRes();
    return getResponse;
  };

  const addItemToCart = async (itemid, itemqty) => {
    const data = {
      productid: itemid,
      quantity: itemqty,
    };

    const getResponse = await addCartItem(data);
    return getResponse;
  };

  const addUser = useCallback(
    (access_token, refresh_token) =>
      dispatch({
        type: UserActionTypes.ADD_USER,
        payload: {
          access_token,
          refresh_token,
        },
      }),
    [dispatch]
  );

  const onKeyUpPassword = (e) => {
    if (e.key === 'Enter' && !submitting) {
      handleSubmit(e);
    }
  };

  return (
    <div className="login-form module-spacer">
      {errorMessage && <p className="text error p-0">*{errorMessage}</p>}
      <div className="form-container background action-item">
        <h2>Sign In</h2>
        {hasVerifiedEmail ? (
          <p className="email-text">
            <span className="verfied-email">{username}</span>
            <span
              className="change-btn"
              onClick={() => setHasVerifiedEmail(false)}
            >
              Change
            </span>
          </p>
        ) : (
          <TextInput
            onChange={(e) => usernameOnChange(e)}
            placeholder=""
            label="Username"
            value={username}
          />
        )}

        <TextInput
          onChange={(e) => passwordOnChange(e)}
          placeholder=""
          label="Password"
          type="password"
          value={password}
          onKeyDown={onKeyUpPassword}
        />
        <Link
          to="/forgot-password"
          className="forgot-password mb-3"
          onClick={() => {}}
        >
          Forgot your password?
        </Link>
        {/* <div className="signed-in-box">
                    <input type="checkbox" name="" id="keepSignedIn" className="mr-2"/>
                    <label htmlFor="keepSignedIn">Keep me signed in.</label>
                </div> */}

        <CustomButton loading={submitting} onClick={handleSubmit}>
          Sign In
        </CustomButton>
      </div>
      <p>
        Need help? Email us at <br />
        <a href="mailto:password-help@trinityconsultants.com">
          password-help@trinityconsultants.com
        </a>
      </p>
    </div>
  );
}

export default LoginForm;
