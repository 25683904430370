import React, { useState } from 'react';
import CustomButton from '../custom-button/custom-button.component';

import './cookie-banner.styles.scss';
import { useCookies } from 'react-cookie';

function CookieBanner() {
  const [showCookieBox, setShowCookieBox] = useState(true);
  const [cookies, setCookie] = useCookies(['name']);

  const onCookieClick = () => {
    setShowCookieBox(!showCookieBox);
    const expires = new Date(Date.now() + 3600 * 1000 * 24 * 365);
    setCookie('trinityCookie', Math.random(), {
      path: '/',
      expires,
      // maxAge: 1000,
    });
  };

  // const onDeclineClick = () => {
  //   setShowCookieBox(!showCookieBox);
  // };

  return (
    <>
      {showCookieBox && (
        <div className="cookie-container fixed-bottom w-100">
          <p className="text">
            This website uses cookies to ensure you get the best experience on
            our website. By using our site, you agree to site tracking.{' '}
            <a href="/privacy-policy">Learn more</a>
          </p>

          <div className="cookie-buttons">
            <CustomButton
              variableWidth
              onClick={() => onCookieClick()}
              className="allow"
            >
              Allow cookies
            </CustomButton>
            <CustomButton
              variableWidth
              altColors
              className="decline"
              onClick={() => onCookieClick()}
            >
              Decline
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBanner;
