import React from 'react';
import './icon-list.styles.scss';

import IconListItem from './icon-list-item/icon-list-item.component';

function IconList({ relatedprograms }) {
  if (relatedprograms) {
    return (
      <div className="icon-list">
        {relatedprograms.map((cta, index) => (
          <IconListItem
            key={index}
            title={cta.Title}
            description={cta.Content}
            iconurl={cta.IconUrl}
            buttontext={cta.ButtonText}
            buttonlink={cta.ButtonLink}
          />
        ))}

        {/* <IconListItem
                    title={'REGULATORY AGENCY PROGRAM'}
                    description={`For multiple license users located at either one site or at multiple sites within one country`}
                />
                <IconListItem
                    title={'REGULATORY AGENCY PROGRAM'}
                    description={`For multiple license users located at either one site or at multiple sites within one country`}
                />
                <IconListItem
                    title={'REGULATORY AGENCY PROGRAM'}
                    description={`For multiple license users located at either one site or at multiple sites within one country`}
                /> */}
      </div>
    );
  }
}

export default IconList;
