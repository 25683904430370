import React, { useEffect } from 'react';
import './training-secondary.styles.scss';
import { useDispatch } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import {
  activePageNumber,
  getPageWiseTraining,
  loadTraining,
  selectTrainingActionFilter,
} from '../../../redux/training/training.action';
import TrainingAdvanceSearchFilterDataListing from '../../../components/training/training-advance-search-filter-data-listing/training-advance-search-filter-data-listing.component';

// RK Task#104 Training Secondary Change
// JP #Task 41 RK Task#100
function TrainingSecondary({
  page: { Product, MainContent, TrainingCategory },
}) {
  const heroLink = { src: '/training', text: 'View All Training Courses' };

  const dispatch = useDispatch();

  const category = {
    Name:
      TrainingCategory !== undefined &&
      TrainingCategory &&
      TrainingCategory.Name,
    ID: TrainingCategory && TrainingCategory.ID,
  };
  const pcategory = [];

  const submit = () => {
    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: [],
      TrainingCategory: [
        TrainingCategory !== undefined && TrainingCategory !== null
          ? TrainingCategory.Name
          : '',
      ],
      TrainingIndustry: [],
      TrainingLocation: [],
      TrainingDate: [],
      IgnoreDefaultSearch: false,
    };

    dispatch(loadTraining(true));
    dispatch(getPageWiseTraining(submissionObj));
    dispatch(activePageNumber(1));
  };

  useEffect(() => {
    dispatch(
      selectTrainingActionFilter(category, pcategory, 'Training_Category')
    );
    submit();
  }, [TrainingCategory]);

  return (
    <div className="training training-secondary">
      {MainContent != null ? (
        <Hero
          src={
            MainContent.HeaderImage_src
              ? MainContent.HeaderImage_src
              : heroImageSrc
          }
          alt={`${
            TrainingCategory !== undefined &&
            TrainingCategory.Name !== undefined &&
            TrainingCategory.Name
          } image`}
          mobileImgSrc={MainContent?.HeaderMobileImage_src}
          title={
            TrainingCategory !== undefined &&
            TrainingCategory.Name !== undefined
              ? TrainingCategory.Name
              : null
          }
          template="standard" // Changed from Landing to Standard
          description={
            TrainingCategory !== undefined &&
            TrainingCategory.description !== undefined
              ? TrainingCategory.description
              : null
          } // Need to add category description
          link={heroLink}
          colorCategory="train"
        />
      ) : null}
      <div className="trinity-container">
        {TrainingCategory !== undefined && TrainingCategory && (
          <TrainingAdvanceSearchFilterDataListing Product={Product} />
        )}
        {/* <TrainingListingWithFilters
          Product={TrainingSecondaryProducts!=undefined?TrainingSecondaryProducts:TrainingSecondaryProductsByType}
          TrainingSelectedCat={TrainingCategory} /> */}
      </div>
    </div>
  );
}

export default TrainingSecondary;
