import { ENDPOINTS } from '../_config';

// #RP - Task  - About Us
export const getAboutUsPageData = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_ABOUTUS_DETAIL);
    if (request.status === 204) {
      // 204 => No content in backend
      return request;
    }
    return await request.json();
  } catch (e) {
    return null;
  }
};
