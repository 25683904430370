import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './author-details.styles.scss';

function AuthorDetails({
  authorImg,
  name,
  phone,
  city,
  state,
  jobTitle,
  content,
}) {
  return (
    <div className="author-details">
      <div className="inner-container">
        {authorImg && <img src={authorImg} className="author-image" />}

        <div className="content-wrapper">
          <p className="name">{name}</p>
          <p className="position">{jobTitle}</p>
          <p className="location">
            {city},{state}
          </p>
          <p className="content">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default AuthorDetails;
