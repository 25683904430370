import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import {
  companyTitle,
  helpTitle,
  link,
  personalTitle,
  productTitle,
} from './request-software-quote.data';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../commons/recaptcha/recaptcha.component';
import useForm from '../../../js/custom-hooks/useForm';
import validate from './request-software-quote.validationRules';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import { submitRequestSoftwareQuoteResponse } from '../../../js/api/softwareForm/_requestSoftwareQuote.data';
import './request-software-quote.styles.scss';
import { createToken } from '../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../commons/helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../js/api/_config';

function RequestSoftwareForm({ data, mainData, status }) {
  // #RP - Task 46 - Bind data to ContactUs Form in Front-End.
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );
  const [Product] = useState(
    status != noContent && dropDownContent.Product
      ? dropDownContent.Product
      : null
  );
  const [LicenseType] = useState(
    status != noContent && dropDownContent.LicenseType
      ? dropDownContent.LicenseType
      : null
  );
  const Content = mainData != null ? mainData.Content : '';

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitRequestSoftwareQuoteResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Company: values.companyName.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      Country: values.Country != null ? values.Country.trim() : 'None',
      Industry: values.Industry != null ? values.Industry.trim() : 'None',
      Product: values.Product != null ? values.Product.trim() : 'None',
      NumberofLicenses: values.licenses != null ? values.licenses.trim() : '0',
      LicenseType:
        values.LicenseType != null ? values.LicenseType.trim() : 'None',
      Comments: values.comment != null ? values.comment.trim() : 'None',
      HowDidYouHearAboutUs:
        values.howdidyouhearaboutus != null
          ? values.howdidyouhearaboutus.trim()
          : 'None',
      FormUniqueId: ListOfFormUniqueData.request_software_quote.UniqueId,
      Catalog: ListOfFormUniqueData.request_software_quote.Catalog,
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  return (
    <div className="software-form-container">
      <p className="blurb " dangerouslySetInnerHTML={{ __html: Content }} />
      <form onSubmit={handleSubmit} noValidate>
        <Row>
          <Col xs={12} sm={6} className="left">
            <p className="form-title">{personalTitle}</p>
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}
            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />

            {errors.jobTitle && <span className="help">{errors.jobTitle}</span>}
            <TextInput
              placeholder="Job Title"
              required
              type="text"
              name="jobTitle"
              onChange={handleChange}
              value={values.jobTitle || ''}
              autoComplete="off"
              className={`${errors.jobTitle && 'error'}`}
              maxlength={200}
            />

            {errors.email && <span className="help">{errors.email}</span>}
            <TextInput
              placeholder="Business Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={200}
            />

            {errors.phone && <span className="help">{errors.phone}</span>}
            <TextInput
              placeholder="Business Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />

            <p className="form-title">{companyTitle}</p>
            {errors.companyName && (
              <span className="help">{errors.companyName}</span>
            )}
            <TextInput
              placeholder="Company Name"
              autoComplete="off"
              className={` ${errors.companyName && 'error'}`}
              type="text"
              name="companyName"
              onChange={handleChange}
              value={values.companyName || ''}
              required
              maxlength={200}
            />
            {errors.address && <span className="help">{errors.address}</span>}
            <TextInput
              placeholder="Business Address"
              required
              type="text"
              name="address"
              onChange={handleChange}
              value={values.address || ''}
              autoComplete="off"
              className={`${errors.address && 'error'}`}
              maxlength={300}
            />
            {errors.city && <span className="help">{errors.city}</span>}
            <TextInput
              placeholder="City"
              required
              type="text"
              name="city"
              onChange={handleChange}
              value={values.city || ''}
              autoComplete="off"
              className={`${errors.city && 'error'}`}
              maxlength={200}
            />
            <SelectList
              defaultText="— State —"
              className="mb-2"
              name="State"
              onChange={stateclick}
              value={values.State}
            >
              {State &&
                State.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            {errors.postalCode && (
              <span className="help">Postal Code is required</span>
            )}
            <TextInput
              placeholder="Postal Code"
              required
              name="postalCode"
              onChange={handleChange}
              value={values.postalCode || ''}
              autoComplete="off"
              className={`${errors.postalCode && 'error'}`}
              maxlength={100}
            />
            <SelectList
              defaultText="— Country —"
              onChange={handleChange} // (e) => setActiveCountryId(e.target.value)}
              className="mb-2"
              name="Country"
              value={values.Country}
            >
              {Country &&
                Country.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
            <SelectList
              defaultText="— Select an Industry —"
              className="mb-2"
              name="Industry"
              onChange={handleChange}
              value={values.industryName}
            >
              {status != noContent &&
                Industry.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
          </Col>

          <Col xs={12} sm={6} className="right">
            <p className="form-title">{productTitle}</p>
            <SelectList
              defaultText="— Software Product —"
              className="mb-2"
              name="Product"
              onChange={handleChange}
              value={values.Product}
            >
              {status != noContent &&
                Product.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            {errors.licenses && <span className="help">{errors.licenses}</span>}
            <TextInput
              placeholder="Number of licenses"
              autoComplete="off"
              className={` ${errors.licenses && 'error'}`}
              type="number"
              name="licenses"
              onChange={handleChange}
              value={values.licenses || ''}
              required
              maxlength={200}
            />
            <SelectList
              defaultText="— Will these licenses be used soley at one site, or at multiple sites within one country? —"
              className="mb-2"
              name="LicenseType"
              onChange={handleChange}
              value={values.LicenseType}
            >
              {status != noContent &&
                LicenseType.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>

            <p className="form-title">{helpTitle}</p>
            <textarea
              placeholder="Comments"
              name="comment"
              onChange={handleChange}
              value={values.comment}
              maxLength={1000}
            />
            <SelectList
              defaultText="— How did you hear about us? —"
              className="mb-2"
              name="howdidyouhearaboutus"
              onChange={handleChange}
              value={values.howdidyouhearaboutus}
            >
              {status != noContent &&
                HowDidYouHearAboutUs.map(({ Name }) => (
                  <option key={Name} value={Name}>
                    {Name}
                  </option>
                ))}
            </SelectList>
          </Col>
        </Row>
        <div className="recaptcha">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton type="submit" className="heroLink">
            {link}
          </CustomButton>
        </div>
      </form>
    </div>
  );
}

export default RequestSoftwareForm;
