import React, { useMemo, useState } from 'react';

export default function TrainingAzureSearchCheckBoxContainer(props) {
  const { outerElement } = props;
  const [readMore, setReadMore] = useState(false);

  const data = useMemo(() => {
    if (!outerElement.value) {
      return [];
    }
    const temp = [...outerElement.value];
    if (temp.length > 5 && !readMore) {
      return temp.slice(0, 4);
    }
    return temp;
  }, [outerElement, readMore]);

  return (
    <div style={{ marginTop: '1rem' }}>
      <span className="tree-view-key-font">
        {/* {
                    outerElement.key === "ByFormat" && outerElement.value.length == 1 && outerElement.value[0].value == 'Complimentary'
                        ? "" : outerElement.key === "ByFormat" && "Session By Format"
                } */}
        {outerElement.key === 'State'
          ? 'Session By Location'
          : // : outerElement.key === "ByLocation"
          //     ? "Session By Jurisdiction"
          outerElement.key === 'ByCategory'
          ? 'Session By Category'
          : outerElement.key === 'ByIndustry'
          ? 'Session By Industry'
          : outerElement.key === 'DisplayName'
          ? 'Display Name'
          : outerElement.key === 'BusinessUnit'
          ? 'Session By Business Unit'
          : outerElement.key === 'ByFormat'
          ? 'Session By Format'
          : outerElement.key === 'RegulatoryAgency'
          ? 'Session By Regulatory Agency'
          : outerElement.key !== 'ByFormat' &&
            outerElement.key.split('_').join(' ')}
      </span>
      {
        // outerElement.key === "ByFormat" && outerElement.value.length == 1 && outerElement.value[0].value == 'Complimentary'
        //     ? <div></div> :
        <div className="tree-key-element-border" />
      }
      {data.map((innerElement) => {
        if (innerElement && innerElement.value !== 'Complimentary') {
          return (
            <div className="trinity-search-checkbox-elements">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    id={innerElement.value}
                    name={innerElement.value}
                    onChange={(e) =>
                      props.onChangeDynamicCheckBox(
                        outerElement.key,
                        innerElement.value,
                        e.target.checked
                      )
                    }
                    checked={props.dynamicSearchData.includes(
                      `${outerElement.key}|${innerElement.value}`
                    )}
                    value={innerElement.value}
                  />
                  <span className="tree-view-font-checkbox">
                    {innerElement.value}
                  </span>
                </div>
                <div>
                  <span className="tree-view-font-checkbox-count">
                    ({innerElement.count})
                  </span>
                </div>
              </div>
            </div>
          );
        }
      })}
      {outerElement.value.length > 5 && (
        <a
          style={{ color: '#ef6008', cursor: 'pointer' }}
          onClick={() => setReadMore((e) => !e)}
        >
          {readMore ? 'View Less ' : 'View More '}
          <i
            className={readMore ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
            style={{ fontSize: '15px', color: '#ef6008' }}
            aria-hidden="true"
          />
        </a>
      )}
    </div>
  );
}

export function FreeCheckBoxContainer(props) {
  return (
    <div style={{ marginTop: '1rem' }}>
      <div className="trinity-search-checkbox-elements">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            <input
              type="checkbox"
              id="freeid"
              name="freeid"
              onChange={(e) => props.OnChangeFreeCheckBox(e.target.checked)}
              checked={props.isFreeCheckboxChecked}
              value="freevalue"
            />
            <span className="tree-view-font-checkbox">Free</span>
          </div>
          <div>
            <span className="tree-view-font-checkbox-count">
              ({props.freecoursecount})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
