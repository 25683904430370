import { FeedbackServices } from './feedback.services';
import { FeedbackActionTypes } from './feedback.type';

export const getFeedbackData = (feedBackID) => (dispatch, getState) => {
  FeedbackServices.getFeedback(feedBackID).then((response) => {
    if (response) {
      dispatch({
        type: FeedbackActionTypes.GET_FEEDBACK_DATA,
        payload: response,
      });
    }
  });
};
