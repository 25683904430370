import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Hero from '../../../components/commons/hero/hero.component';
import Subscribe from '../../../components/commons/forms/subscribe/subscribe.component';
import SubscribeFormWidget from '../../../components/commons/widgets/subscribe-form-widget/subscribe-form-widget.component';
import { getSubscribePageData } from '../../../js/api/subscribe/_subscribe.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function SubscribeForm({ page: { modules } }) {
  // #RP - Task 62 - Subscribe.
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getSubscribePageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country !== undefined)
  ) {
    return (
      <div className="subscribe-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="subscribe"
        />

        <div className="trinity-container">
          <Row>
            <Col sm={4}>
              <SubscribeFormWidget
                item={
                  status !== noContent && responseMainContent
                    ? responseMainContent
                    : ''
                }
              />
            </Col>
            <Col sm={8}>
              <Subscribe
                data={responseDropDownContent}
                mainData={responseMainContent}
                status={status}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SubscribeForm;
