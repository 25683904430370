import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { getAllPageUrls } from '../js/api/get-pages.data.js';

import SoftwareDownload from './ecommerce/software-download/software-download.page';
import UserLogin from './ecommerce/user-login/user-login.page';
import PageWrapper from './page-wrapper.component';

import AdminLandingPage from '../admin/admin-landing/admin-landing-page';
import AdminNewsMedia from '../admin/news-admin/AdminNewsMedia';
import OrderFulfillPage from '../admin/order-fulfill/order-fulfill-page';
import { AzureMediaPlayer } from '../components/commons/azure-media-player/azure-media-player';
import SubscribeThankyouPage from '../components/commons/forms/subscribe/subsribe-thankyou-page/subscribe-thankyou.page';
import Loader from '../components/commons/helpers/loader/loader.component';
import ImpersonationBanner from '../components/commons/navigation/impersonation-banner/impersonation-banner.component.jsx';
import EhsIndividualArticleLandingPage from '../components/ehs-quarterly/ehs-individual-article/ehs-individual-article-landing-page';
import TrinitySiteSearch from '../components/sitesearch/TrinintySiteSearch';
import TrainingByLocationStateWise from '../components/training-location/TrainingByLocationStateWise';
import TrainingThirdLevel from '../components/training-location/TrainingThirdLevel';
import { getUserIsLoggedin } from '../js/api/auth/_login.js';
import { timeStampMinutesDifference } from '../js/helpers/_time-stamp-minutes-difference.js';
import { resetCartItem } from '../redux/shopping-cart/shopping-cart.action';
import { removeUser } from '../redux/user/user.action';
import ScrollToTop from '../ScrollToTop';
import NewSubscribeSecondLanding from './about/new-subscribe-second-level/new-subscribe-second-landing';
import EhsLanding from './consulting-ehs/ehs-landing/ehs-landing.page.jsx';
import AdminLogin from './ecommerce/admin-login/user-login.page';
import AttendeePage from './ecommerce/attendee/attendee.page.jsx';
import ChangeProfilePassword from './ecommerce/change-password/changePassword';
import CreateAccount from './ecommerce/create-account/create-account.page.jsx';
import EditProfile from './ecommerce/edit-profile/edit-profile.page';
import EmailLogin from './ecommerce/email-login/email-login.page.jsx';
import OrderConfirmationPage from './ecommerce/order-confirmation/order-confirmation.page';
import ForgotPassword from './ecommerce/password-pages/forgot-password.page';
import PasswordHelp from './ecommerce/password-pages/password-help.page';
import ResetFailed from './ecommerce/password-pages/reset-failed.page';
import ResetPassword from './ecommerce/password-pages/reset-password.page';
import ResetSuccessful from './ecommerce/password-pages/reset-succesful.page';
import PaymentConfirmationPage from './ecommerce/payment-page/payment.page';
import ReviewOrderPage from './ecommerce/review-order/review-order.page';
import ShoppingCart from './ecommerce/shopping-cart/shopping-cart.component';
import FeedBackPage from './feed-back/feed-back-form-page';
import FeedBackClosedPage from './feed-back/thank-you/FeedBackClosedPage';
import FeedBackThankYouPage from './feed-back/thank-you/FeedBackThankYouPage';
import TrainingByLocation from './locations/training-by-location/training-by-location.page';
import NewNewsIndividualArticleLandingPage from './resources-and-news/new-news-individual-article/new-news-individual-article-landing-page';
import ResourcesDocuments from './resourcesDocuments/ResourcesDocuments';
import SoftwareDemo from './software/software-demo/software-demo.page.jsx';
import NotFoundPage from './specialty-pages/error-display/not-found.page.jsx';
import BreezeThankYouPage from './thankyou/breeze-thank-you-page';
import ThankYouPage from './thankyou/thankyou.page';
import NewTrainingSearchWrapper from './training/new-training-landing-page/new-training-azure-search/NewTrainingSearchWrapper';
import TrainingTertiary from './training/training-tertiary/training-tertiary.page.jsx';

function PageRouting() {
  const user = useSelector(({ user }) => user.tokens.access_token);
  const dispatch = useDispatch();

  const adminUser = useSelector(({ user }) =>
    user.isbackend_user ? user.isbackend_user : null
  );

  const [routeData, setRouteData] = useState(null);

  useEffect(() => {
    setPagesForRouting();
  }, []);

  async function setPagesForRouting() {
    // currently caching routes in local storage in function...
    // => not sure how I will handle this in production yet
    // => mainly to speed up dev load times
    const cachedRoutes = JSON.parse(localStorage.getItem('routes'));

    let routesTimeStamp = +localStorage.getItem('routes-time-stamp');
    let currentTimeStamp = Date.now();

    let minutesDifference = timeStampMinutesDifference(
      currentTimeStamp,
      routesTimeStamp
    );

    if (minutesDifference > 15 || !cachedRoutes) {
      const pageData = await getAllPageUrls();
      setRouteData(pageData);
    } else {
      setRouteData(cachedRoutes);
      // getAllPageUrls();
    }
  }

  async function checkUserLoggedIn() {
    return await getUserIsLoggedin(null);
  }

  const protectedUserRoute = (component) => {
    if (!user) {
      return <Redirect to="/login" />;
    } else if (user) {
      const userIsLoggedIn = checkUserLoggedIn();
      if (!userIsLoggedIn) {
        return <Redirect to="/login" />;
      }
    }
    return component;
  };

  const protectedAdminRoute = (component) => {
    if (!adminUser) {
      return <Redirect to="/login" />;
    }
    return component;
  };

  // for routes that people need to be logged out to access

  const protectedLogoutRoute = (component) => {
    if (user) return <Redirect to="/my-account" />;
    return component;
  };

  const ProtectedShoppingCartUserRoute = ({ component, ...options }) => {
    if (!user) {
      dispatch(removeUser(null));
      dispatch(resetCartItem(null));
      return <Redirect to="/login" />;
    } else if (user) {
      const userIsLoggedIn = checkUserLoggedIn();
      if (!userIsLoggedIn) {
        dispatch(removeUser(null));
        dispatch(resetCartItem(null));
        return <Redirect to="/login" />;
      }
    }
    return <Route {...options} component={component} />;
  };

  // having to do some conditional checks
  // to figure out if data is coming from real api
  // or placeholder api

  if (!routeData) {
    return <Loader />;
  }

  return (
    <ScrollToTop>
      <Switch>
        <Route
          exact
          path={'/subscribe/thank-you-for-subscribing/:id'}
          render={(props) => <NewSubscribeSecondLanding />}
        />
        {/*<Route exact path={"/safeBridge"}
                       render={(props) => <SafeBridgeSearchWrapper/>}
                />
                <Route exact path={"/safeBridge/:safeBridgeId"}
                       render={(props) => <SafeBridgeSecondLevel {...props} />}
                />*/}
        <Route
          exact
          path={'/news/:id'}
          render={(props) => <NewNewsIndividualArticleLandingPage {...props} />}
        />
        <Route
          exact
          path={'/resources/publications/:newsId'}
          render={(props) => (
            <EhsIndividualArticleLandingPage
              {...props}
              newsId={props.match.params.newsId}
            />
          )}
        />
        <ProtectedShoppingCartUserRoute
          exact
          path={'/shopping-cart'}
          render={(props) => <ShoppingCart {...props} />}
        />
        <ProtectedShoppingCartUserRoute
          exact
          path={'/shopping-cart/attendees'}
          render={(props) => <AttendeePage {...props} />}
        />
        <ProtectedShoppingCartUserRoute
          exact
          path={'/shopping-cart/attendees/review-order'}
          render={(props) => <ReviewOrderPage {...props} />}
        />
        <ProtectedShoppingCartUserRoute
          exact
          path={'/shopping-cart/attendees/review-order/payment'}
          render={(props) => <PaymentConfirmationPage {...props} />}
        />
        <ProtectedShoppingCartUserRoute
          exact
          path={
            '/shopping-cart/attendees/review-order/payment/order-confirmation'
          }
          render={(props) => <OrderConfirmationPage {...props} />}
        />
        {routeData &&
          routeData.map((page, i) => (
            <Route
              key={page.path + i + Math.random() * 20}
              exact
              path={page.path ? page.path : page.url}
              render={() => (
                <PageWrapper
                  page={page}
                  template={page.template}
                  section={page.section}
                />
              )}
            />
          ))}
        <Route
          exact
          path={
            '/training/location-specific/:countryId/:stateId/:productId/:productName'
          }
          render={(props) => (
            <TrainingThirdLevel
              productId={props.match.params.productId}
              stateId={props.match.params.stateId}
            />
          )}
        />
        } />
        <Route
          exact
          path={'/training/location-specific/:countryId/:stateId'}
          render={(props) => (
            <TrainingByLocationStateWise
              otherParams={props.match.params}
              countryId={props.match.params.countryId}
              stateId={props.match.params.stateId}
            />
          )}
        />
        <Route
          exact
          path={'/training/location-specific/:countryId(united-states|canada)'}
          render={(props) => (
            <TrainingByLocation
              countryId={props.match.params.countryId}
              stateId={props.match.params.stateId}
            />
          )}
        />
        } />
        <Route
          exact
          path={'/training/:searchId'}
          render={(props) => <NewTrainingSearchWrapper id={props} />}
        />
        <Route
          exact
          path={'/training/:trainingId/:title'}
          render={(props) => <TrainingTertiary id={props} />}
        />
        <Route
          exact
          path={'/software/software-demo'}
          render={() => <SoftwareDemo />}
        />
        <Route
          exact
          path={'/my-account'}
          render={() => protectedUserRoute(<SoftwareDownload />)}
        />
        <Route
          exact
          path={'/impersonate/:id'}
          render={(props) => (
            <ImpersonationBanner token={props.match.params.id} />
          )}
        />
        <Route
          exact
          path={'/survey/:id'}
          render={(props) => <FeedBackPage token={props.match.params.id} />}
        />
        <Route exact path={'/login'} render={() => <EmailLogin />} />
        <Route exact path={'/admin/login'} render={() => <AdminLogin />} />
        <Route exact path={'/admin/news'} render={() => <AdminNewsMedia />} />
        <Route
          exact
          path={'/admin/landingPage'}
          render={() => <AdminLandingPage />}
        />
        <Route exact path={'/ehs-landing'} render={() => <EhsLanding />} />
        <Route exact path={'/user-login'} render={() => <UserLogin />} />
        <Route exact path={'/thank-you-page'} render={() => <ThankYouPage />} />
        <Route
          exact
          path={'/software/thank-you-page'}
          render={() => <BreezeThankYouPage />}
        />
        <Route
          exact
          path={'/subscribe-thank-you-page'}
          render={() => <SubscribeThankyouPage />}
        />
        <Route
          exact
          path={'/resources/toronto-files'}
          render={() => <ResourcesDocuments />}
        />
        <Route
          exact
          path={'/feedBackThankYouPage'}
          render={(props) => <FeedBackThankYouPage {...props} />}
        />
        <Route
          exact
          path={'/feedBackClosedPage'}
          render={(props) => <FeedBackClosedPage {...props} />}
        />
        <Route
          exact
          path={'/search'}
          render={(props) => <TrinitySiteSearch {...props} />}
        />
        <Route
          exact
          path={'/create-account'}
          render={() => protectedLogoutRoute(<CreateAccount />)}
        />
        <Route
          exact
          path={'/edit-profile'}
          render={() => protectedUserRoute(<EditProfile />)}
        />
        <Route
          exact
          path={'/changePassword'}
          render={() => protectedUserRoute(<ChangeProfilePassword />)}
        />
        <Route
          exact
          path={'/orderFulFillPage'}
          render={() => protectedAdminRoute(<OrderFulfillPage />)}
        />
        {/*<Route
                exact
                path={"/shopping-cart"}
                render={() => protectedUserRoute(<ShoppingCart/>)}
            />*/}
        <Route
          exact
          path={'/My-Trinity-Account'}
          render={() => <Redirect to="/login" />}
        />
        {/*<Route
                exact
                path={"/attendees"}
                render={() => (
                    <AttendeePage/>
                )}
            />
            <Route
                exact
                path={"/review-order"}
                render={() => (
                    <ReviewOrderPage/>
                    // This will eventually be locked to a login
                    // protectedUserRoute(<ShoppingCart />)
                )}
            />
            <Route
                exact
                path="/order-confirmation"
                render={() => <OrderConfirmation/>}
            />*/}
        {/*<Route exact path="/payment" render={() => <Payment/>}/>*/}
        <Route
          exact
          path="/resources/toronto"
          render={() => <ResourcesDocuments />}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => <ForgotPassword />}
        />
        <Route exact path="/password-help" render={() => <PasswordHelp />} />
        <Route
          exact
          path={'/reset-password/:id'}
          render={(props) => <ResetPassword id={props} />}
        />
        <Route
          exact
          path="/reset-successful"
          render={() => <ResetSuccessful />}
        />
        <Route exact path="/reset-failed" render={() => <ResetFailed />} />
        <Route
          exact
          path="/client/playback"
          render={() => <AzureMediaPlayer />}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </ScrollToTop>
  );
}

export default PageRouting;
