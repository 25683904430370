import React from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/about/policiesHero.svg';
import RegulatoryAgencyProgramForm from '../../../components/about/forms/regulatory-agency-program/regulatory-agency-program.component';

function RegulatoryAgencyProgram({ page: { modules } }) {
  return (
    <div className="regulatory-agency-program-container">
      <Hero
        src={heroImageSrc}
        alt={modules.hero.hero_alt}
        title={modules.hero.hero_title}
        description={modules.hero.hero_description}
        template="standard"
        colorCategory="policies"
      />
      <div className="trinity-container">
        <RegulatoryAgencyProgramForm />
      </div>
    </div>
  );
}

export default RegulatoryAgencyProgram;
