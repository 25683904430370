import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class OrderFulfillServices {
  static async getUserService(userGUID) {
    return axios({
      method: 'POST',
      url: ENDPOINTS.PROXY + ENDPOINTS.ORDER_FULFILLMENT.GET_FULFILLMENT_DATA,
      headers: {
        Accept: 'application/json',
      },
      data: {
        OrderGuid: userGUID,
      },
    })
      .then((e) => e)
      .catch((e) => e);
  }
}
