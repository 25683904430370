import React, { Suspense } from 'react';
import './software-download.styles.scss';

import Loader from '../../../components/commons/helpers/loader/loader.component';

function SoftwareDownload() {
  const UserInfoDisplay = React.lazy(() =>
    import(
      '../../../components/ecommerce/user-info-display/user-info-display.component'
    )
  );

  const FilteredLoginInfo = React.lazy(() =>
    import(
      '../../../components/ecommerce/filtered-login-info/filtered-login-info.component'
    )
  );

  return (
    <div className="software-download module-spacer">
      <Suspense fallback={<Loader />}>
        <div className="trinity-container">
          <UserInfoDisplay />
          <hr className="section-seperator" />
        </div>
      </Suspense>

      <Suspense fallback={<Loader />}>
        <FilteredLoginInfo />
      </Suspense>
    </div>
  );
}

export default SoftwareDownload;
