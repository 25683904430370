import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { widgetLink } from './widgetBox-data.js';
import './widget-box.styles.scss';

function isEven(x) {
  return x % 2 == 0;
}

function isOdd(x) {
  return !isEven(x);
}

function WidgetBox({ widgettitle, widgetdata }) {
  if (widgetdata) {
    return (
      <div className="software-widgetBox-container">
        <h2>{widgettitle}</h2>
        <Row>
          <Col xs={12} sm={6}>
            {widgetdata.slice(0, 6).map(
              (cta, index) =>
                isEven(index) && (
                  <div className="widget-content" key={index}>
                    {cta.GlobalIcon && (
                      <img
                        src={cta.GlobalIcon}
                        style={{
                          height: 90,
                          width: 70,
                        }}
                        className="widget-icon"
                      />
                    )}
                    <div className="widget-text">
                      {cta.Title && (
                        <a href={cta.Url} className="content">
                          {cta.Title}
                        </a>
                      )}
                      {cta.StartDate && (
                        <p className="date">
                          {cta.StartDate != undefined &&
                          cta.StartDate.trim() != ','
                            ? cta.StartDate
                            : ''}
                        </p>
                      )}
                      {cta && (
                        <span className="sub-content">
                          {cta.IsOnline == true
                            ? 'Online'
                            : cta.City +
                              (cta.City != null &&
                              cta.City != undefined &&
                              cta.City != '' &&
                              cta.State != undefined &&
                              cta.State != null &&
                              cta.State != ''
                                ? ', '
                                : '') +
                              cta.State}
                        </span>
                      )}
                    </div>
                  </div>
                )
            )}
          </Col>
          <Col xs={12} sm={6}>
            {widgetdata.slice(0, 6).map(
              (cta, index) =>
                isOdd(index) && (
                  <div className="widget-content" key={index}>
                    {cta.GlobalIcon && (
                      <img
                        src={cta.GlobalIcon}
                        style={{
                          height: 90,
                          width: 70,
                        }}
                        className="widget-icon"
                      />
                    )}
                    <div className="widget-text">
                      {cta.Title && (
                        <a href={cta.Url} className="content">
                          {cta.Title}
                        </a>
                      )}
                      {cta.StartDate && (
                        <p className="date">
                          {cta.StartDate != undefined &&
                          cta.StartDate.trim() != ','
                            ? cta.StartDate
                            : ''}
                        </p>
                      )}
                      {cta && (
                        <span className="sub-content">
                          {cta.IsOnline == true
                            ? 'Online'
                            : cta.City +
                              (cta.City != null &&
                              cta.City != undefined &&
                              cta.City != '' &&
                              cta.State != undefined &&
                              cta.State != null &&
                              cta.State != ''
                                ? ', '
                                : '') +
                              cta.State}
                        </span>
                      )}
                    </div>
                  </div>
                )
            )}
          </Col>
        </Row>

        <div className="widget-link">
          {widgetLink && (
            <a
              href="/training/software/software-courses"
              className="single-link"
            >
              {widgetLink}
            </a>
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default WidgetBox;
