import { ENDPOINTS } from '../_config';
import { addMinutesToDateStamp } from '../../helpers/_add-minutes-to-date-stamp';

export const authRefresh = async () => {
  const formBody = new FormData();

  const data = {
    client_id: 'trinityfrontend',
    client_secret: '2658e63b2342470d94177c546919bafg',
    grant_type: 'refresh_token',
    refresh_token: localStorage.getItem('refresh-token'),
  };

  Object.entries(data).forEach(([key, value]) => {
    formBody.append(key, value);
  });

  try {
    const request = await fetch(ENDPOINTS.PROXY + ENDPOINTS.AUTHENTICATION, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams([...formBody]),
    });
    const response = await request.json();

    if (response.error) return { access_token: null, refresh_token: null };

    const authExpirationTime = addMinutesToDateStamp(Date.now(), 55);

    localStorage.setItem('access-token', response.access_token);
    localStorage.setItem('refresh-token', response.refresh_token);
    localStorage.setItem('access-token-expiration', authExpirationTime);

    return response;
  } catch (e) {
    return { access_token: null, refresh_token: null };
  }
};
