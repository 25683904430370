import ReactGA from 'react-ga4';
import { GA4_EVENT_MAPPPING, GA4_MEASUREMENT_ID } from './js/api/_config';
import { TrackingServices } from './redux/google-analytics/tracking.services';

const InitializeGoogleAnalytics = () => {
  // For Local
  // ReactGA.initialize(GA4_MEASUREMENT_ID, {
  //   gaOptions: {
  //     debug_mode: true,
  //   },
  //   gtagOptions: {
  //     debug_mode: true,
  //   },
  // });

  // For Production
  ReactGA.initialize(GA4_MEASUREMENT_ID);
  console.log(`GA INITIALIZED with ${GA4_MEASUREMENT_ID}`);
};

const TrackGoogleAnalyticsEvent = (category, event_name, label, data) => {
  console.log(
    `GA4 event = category:${category}| eventName: ${event_name}| label: ${label}`,
    JSON.stringify(data, null, 2)
  );

  // Send GA4 Event
  ReactGA.gtag('event', event_name, data);
};

const trackLoginGA4Event = (userName) => {
  TrackGoogleAnalyticsEvent(
    GA4_EVENT_MAPPPING.USER_LOGIN.category,
    GA4_EVENT_MAPPPING.USER_LOGIN.event_name,
    GA4_EVENT_MAPPPING.USER_LOGIN.label,
    { username: userName }
  );
};

const trackTrainingGA4Event = async (
  product,
  variantId,
  isLoggedIn = false,
  isPartnerItem = false
) => {
  console.log('Variant ID ----->', variantId);
  console.log(product);
  let currentProductVariant = {};

  if (isPartnerItem) {
    currentProductVariant = product?.RelatedProduct?.filter(
      (e) => Number(e.ID) === Number(variantId)
    )[0];
  } else {
    currentProductVariant = product?.Variants?.filter(
      (e) => Number(e.ProductId) === Number(variantId)
    )[0];
  }

  console.log('Training Data ----->');
  console.log(currentProductVariant);

  const trainingCategoryResponse = await TrackingServices.getGA4Categories(
    currentProductVariant?.ProductId
  );

  console.log('trainingCategoryResponse', trainingCategoryResponse);

  const payload = {
    currency: 'USD',
    event_name: isLoggedIn
      ? GA4_EVENT_MAPPPING.ADD_TO_CART.event_name
      : GA4_EVENT_MAPPPING.SELECT_ITEM.event_name,
    value: currentProductVariant?.VariantPrice,
    items: [
      {
        item_category: product?.Type,
        item_id: currentProductVariant?.ProductId,
        item_name: currentProductVariant?.Title,
        item_type: currentProductVariant?.SessionTypeName,
        item_type_industry:
          trainingCategoryResponse?.industries &&
          trainingCategoryResponse?.industries + '',
        item_type_category:
          trainingCategoryResponse?.categories &&
          trainingCategoryResponse?.categories + '',
        registration_end_date: currentProductVariant?.RegistrationEndDate,
        start_date: currentProductVariant?.StartDate,
        end_date: currentProductVariant?.EndDate,
        city: currentProductVariant?.City,
        state: currentProductVariant?.State,
        country: currentProductVariant?.Country,
        price: currentProductVariant?.VariantPrice,
        discount: 0.0,
        quantity: 1,
      },
    ],
  };

  if (isLoggedIn) {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.ADD_TO_CART.category,
      GA4_EVENT_MAPPPING.ADD_TO_CART.event_name,
      GA4_EVENT_MAPPPING.ADD_TO_CART.label,
      payload
    );
  } else {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.SELECT_ITEM.category,
      GA4_EVENT_MAPPPING.SELECT_ITEM.event_name,
      GA4_EVENT_MAPPPING.SELECT_ITEM.label,
      payload
    );
  }
};

const trackSoftwareGA4Event = async (
  productData,
  mainContent,
  productQty,
  isLoggedIn
) => {
  console.log('Inside Software Tracking Event');
  console.log('productData', productData);
  console.log('mainContent', mainContent);

  const softwareCategoryResponse = await TrackingServices.getGA4Categories(
    productData?.id
  );

  const payload = {
    currency: 'USD',
    value: productData?.salePrice,
    items: [
      {
        item_category: 'Software',
        item_id: productData?.id,
        item_name: mainContent?.Title,
        item_type_industry:
          softwareCategoryResponse?.industries &&
          softwareCategoryResponse?.industries + '',
        item_type_category:
          softwareCategoryResponse?.categories &&
          softwareCategoryResponse?.categories + '',
        retailPrice: productData?.retailPrice,
        price: productData?.salePrice,
        discount: 0.0,
        quantity: productQty && Number(productQty),
      },
    ],
  };

  if (isLoggedIn) {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.ADD_TO_CART.category,
      GA4_EVENT_MAPPPING.ADD_TO_CART.event_name,
      GA4_EVENT_MAPPPING.ADD_TO_CART.label,
      payload
    );
  } else {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.SELECT_ITEM.category,
      GA4_EVENT_MAPPPING.SELECT_ITEM.event_name,
      GA4_EVENT_MAPPPING.SELECT_ITEM.label,
      payload
    );
  }
};

const sendPurchaseEventToGA4 = async (
  cartPayload,
  isPaymentDone,
  transationId
) => {
  console.log('Shopping Cart Data ----->');
  console.log(cartPayload);

  const items = [];

  for (const orderPayload of cartPayload?.OrderLines) {
    console.log('orderPayload--->>>');
    console.log(orderPayload);

    const categoryResponse = await TrackingServices.getGA4Categories(
      orderPayload?.ProductID
    );

    console.log('categoryResponse--->>>');
    console.log(categoryResponse);

    if (orderPayload?.Category === 'Training') {
      items.push({
        item_category: orderPayload?.Category,
        item_id: orderPayload?.ProductID,
        item_name: orderPayload?.ProductName,
        item_type: orderPayload?.SessionTypeName,
        discount_code: orderPayload?.Discount,
        registration_end_date: orderPayload?.EndDate,
        start_date: orderPayload?.StartDate,
        end_date: orderPayload?.EndDate,
        city: orderPayload?.City,
        state: orderPayload?.State,
        country: orderPayload?.Country,
        price: orderPayload?.Total,
        discount: orderPayload?.Discount,
        quantity: orderPayload?.Quantity,
        item_type_industry:
          categoryResponse?.industries && categoryResponse?.industries + '',
        item_type_category:
          categoryResponse?.categories && categoryResponse?.categories + '',
      });
    } else {
      items.push({
        item_category: orderPayload?.Category,
        item_id: orderPayload?.ProductID,
        item_name: orderPayload?.ProductName,
        price: orderPayload?.Price,
        discount: orderPayload?.Discount,
        quantity: orderPayload?.Quantity,
        item_type_industry:
          categoryResponse?.industries && categoryResponse?.industries + '',
        item_type_category:
          categoryResponse?.categories && categoryResponse?.categories + '',
      });
    }
  }

  const payload = {
    transaction_id: isPaymentDone && transationId,
    value: cartPayload?.OrderTotal,
    tax: cartPayload?.TaxTotal,
    shipping: cartPayload?.ShippingTotal,
    currency: 'USD',
    items: items,
  };

  if (isPaymentDone) {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.PURCHASE.category,
      GA4_EVENT_MAPPPING.PURCHASE.event_name,
      GA4_EVENT_MAPPPING.PURCHASE.label,
      payload
    );
  } else {
    TrackGoogleAnalyticsEvent(
      GA4_EVENT_MAPPPING.ADD_PAYMENT_INFO.category,
      GA4_EVENT_MAPPPING.ADD_PAYMENT_INFO.event_name,
      GA4_EVENT_MAPPPING.ADD_PAYMENT_INFO.label,
      payload
    );
  }
};

export default InitializeGoogleAnalytics;

export {
  InitializeGoogleAnalytics,
  TrackGoogleAnalyticsEvent,
  trackTrainingGA4Event,
  sendPurchaseEventToGA4,
  trackLoginGA4Event,
  trackSoftwareGA4Event,
};
