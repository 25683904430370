import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../../js/custom-hooks/useForm';
import validate from '../../../../staffing/forms/resume-form/resume.validationRules';
import TextInput from '../../../../commons/form-inputs/text-input/text-input.component';
import {
  addTitle,
  careerTitle,
  contactTitle,
  link,
  subTitle,
  title,
  uploadTitle,
} from './resume.data';
import SelectList from '../../../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../../../commons/recaptcha/recaptcha.component';
import './resume.styles.scss';
import {
  CreateUpdateDocument,
  submitApplyForJobResponse,
  submitFileDataApplyForJob,
} from '../../../../../js/api/applyforjob/_ind-positions.data';
import { createToken } from '../../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../../../commons/helpers/form-uniqueid/form-uniqueid';

function ResumeBlock({
  data,
  mainData,
  status,
  HiringManagerContent,
  RecruitingManagerContent,
  name,
}) {
  // #RP - Task 70 - Apply for job
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);
  const [fileData, setFile] = useState(null);
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [State] = useState(
    status != noContent && dropDownContent.State ? dropDownContent.State : null
  );
  const [CareerLevel] = useState(
    status != noContent && dropDownContent.CareerLevel
      ? dropDownContent.CareerLevel
      : null
  );
  const [Education] = useState(
    status != noContent && dropDownContent.Education
      ? dropDownContent.Education
      : null
  );
  const [WorkAuthorization] = useState(
    status != noContent && dropDownContent.WorkAuthorization
      ? dropDownContent.WorkAuthorization
      : null
  );

  function readFile(file) {
    const files = { base64: '', fileList: file };
    const reader = new FileReader();
    reader.onload = readSuccess;
    function readSuccess(evt) {
      files.base64 = evt.target.result;
      setFile(files);
    }
    reader.readAsDataURL(file);
  }

  const onfileChange = (e) => {
    if (e.target.files != null && e.target.files.length > 0)
      readFile(e.target.files[0]);
  };

  const submitData = async (submissionObj, fileObj, documentObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitApplyForJobResponse(
        submissionObj,
        token
      );
      const fileResponse = await submitFileDataApplyForJob(fileObj, token);
      const uploadDocument = await CreateUpdateDocument(documentObj);
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      PhoneNumber: values.phone.trim(),
      EmailAddress: values.email.trim(),
      CareerLevel:
        values.CareerLevel != null ? values.CareerLevel.trim() : 'None',
      EducationLevel:
        values.EducationLevel != null ? values.EducationLevel.trim() : 'None',
      WorkAuthorization:
        values.WorkAuthorization != null
          ? values.WorkAuthorization.trim()
          : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      File: fileData != null ? fileData.base64 : 'None',
      FileName: fileData != null ? fileData.fileList.name : 'None',
      FormUniqueId: ListOfFormUniqueData.apply_for_job.UniqueId,
      Catalog: ListOfFormUniqueData.apply_for_job.Catalog,
      HiringManager: HiringManagerContent,
      RecruitingManager: RecruitingManagerContent,
      PostDate: mainData.PostDate,
      JobTitle: mainData.JobTitle,
    };

    const formData = new FormData();
    formData.set('FirstName', values.firstName.trim());
    formData.set('LastName', values.lastName.trim());
    formData.set('File', fileData != null ? fileData.base64 : 'None');
    formData.set(
      'FileName',
      fileData != null ? fileData.fileList.name : 'None'
    );

    const selectedHiringManager = [];
    const selectedRecruitingManager = [];
    HiringManagerContent.map((item) => {
      selectedHiringManager.push(item.Email);
    });
    RecruitingManagerContent.map((item) => {
      selectedRecruitingManager.push(item.Email);
    });

    const documentObj = {
      JobTitle: mainData.JobTitle,
      PostingDate:
        mainData.PostDate != undefined && mainData.PostDate != null
          ? mainData.PostDate.split(' ')[0]
          : '',
      FileName: fileData.fileList.name,
      FileByte:
        fileData.base64 != undefined && fileData.base64 != null
          ? fileData.base64.split(',')[1]
          : '',
      FileExtension:
        fileData.fileList != undefined && fileData.fileList != null
          ? `.${fileData.fileList.name.split('.').pop()}`
          : '',
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      PhoneNumber: values.phone.trim(),
      EmailAddress: values.email.trim(),
      WorkAuthorization:
        values.WorkAuthorization != null
          ? values.WorkAuthorization.trim()
          : 'None',
      CareerLevel:
        values.CareerLevel != null ? values.CareerLevel.trim() : 'None',
      EducationLevel:
        values.EducationLevel != null ? values.EducationLevel.trim() : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      IsStaffing: name == 'Staffing',
      HiringManager:
        selectedHiringManager != undefined && selectedHiringManager != null
          ? selectedHiringManager.join(';')
          : '',
      RecruitingManager:
        selectedRecruitingManager != undefined &&
        selectedRecruitingManager != null
          ? selectedRecruitingManager.join(';')
          : '',
    };

    submitData(submissionObj, formData, documentObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  return (
    <div className="resume-inner-container">
      <form className="resume-form" onSubmit={handleSubmit} noValidate>
        <p className="form-title">{title}</p>
        <div className="first-section">
          <p className="title">{contactTitle}</p>
          {errors.firstName && <p className="help">{errors.firstName}</p>}
          <TextInput
            placeholder="First Name"
            required
            type="text"
            name="firstName"
            onChange={handleChange}
            value={values.firstName || ''}
            autoComplete="off"
            className={`${errors.firstName && 'error'}`}
            maxlength={100}
          />
          {errors.lastName && <p className="help">{errors.lastName}</p>}
          <TextInput
            placeholder="Last Name"
            required
            type="text"
            name="lastName"
            onChange={handleChange}
            value={values.lastName || ''}
            autoComplete="off"
            className={`${errors.lastName && 'error'}`}
            maxlength={100}
          />
          {errors.address && <p className="help">{errors.address}</p>}
          <TextInput
            placeholder="Address"
            required
            type="text"
            name="address"
            onChange={handleChange}
            value={values.address || ''}
            autoComplete="off"
            className={`${errors.address && 'error'}`}
            maxlength={300}
          />
          <Row>
            <Col xs={8} className="pr-2">
              {errors.city && <p className="help">{errors.city}</p>}
              <TextInput
                placeholder="City"
                required
                type="text"
                name="city"
                onChange={handleChange}
                value={values.city || ''}
                autoComplete="off"
                className={`${errors.city && 'error'}`}
                maxlength={100}
              />
            </Col>
            <Col xs={4}>
              <SelectList
                defaultText="— State —"
                name="State"
                onChange={handleChange}
                value={values.State}
              >
                {status != noContent &&
                  State.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </Col>
          </Row>
          {errors.postalCode && <p className="help">{errors.postalCode}</p>}
          <TextInput
            placeholder="Postal Code"
            required
            name="postalCode"
            onChange={handleChange}
            value={values.postalCode || ''}
            autoComplete="off"
            className={`${errors.postalCode && 'error'}`}
            maxlength={100}
          />
          {errors.phone && <p className="help">{errors.phone}</p>}
          <TextInput
            placeholder="Phone"
            autoComplete="off"
            className={` ${errors.phone && 'error'}`}
            type="number"
            name="phone"
            onChange={handleChange}
            value={values.phone || ''}
            required
            maxlength={100}
          />
          {errors.email && <p className="help">{errors.email}</p>}
          <TextInput
            placeholder="Email"
            type="email"
            name="email"
            required
            onChange={handleChange}
            value={values.email || ''}
            autoComplete="off"
            className={`${errors.email && 'error'}`}
            maxlength={100}
          />
        </div>
        <div className="second-section">
          <p className="title">{careerTitle}</p>
          <SelectList
            className="mb-2"
            defaultText="— Career Level —"
            name="CareerLevel"
            onChange={handleChange}
            value={values.careerLevel}
            maxlength={200}
          >
            {status != noContent &&
              CareerLevel.map(({ Name }) => (
                <option key={Name} value={Name}>
                  {Name}
                </option>
              ))}
          </SelectList>
          <SelectList
            className="mb-2"
            defaultText="— Education Level —"
            name="EducationLevel"
            onChange={handleChange}
            value={values.education}
          >
            {status != noContent &&
              Education.map(({ Name }) => (
                <option key={Name} value={Name}>
                  {Name}
                </option>
              ))}
          </SelectList>
        </div>
        <div className="third-section">
          <p className="title">{addTitle}</p>
          <p className="sub-title">{subTitle}</p>
          <SelectList
            className="mb-2"
            defaultText="— Select —"
            name="WorkAuthorization"
            onChange={handleChange}
            value={values.WorkAuthorization}
          >
            {status != noContent &&
              WorkAuthorization.map(({ Name }) => (
                <option key={Name} value={Name}>
                  {Name}
                </option>
              ))}
          </SelectList>
          <textarea
            name="information"
            placeholder="Provide any additional information"
            onChange={handleChange}
            value={values.information}
            maxLength={200}
          />

          <div className="react-file-reader">
            <p className="title">{uploadTitle}</p>
            <input
              type="file"
              className="react-file-reader-input"
              multiple={false}
              onChange={onfileChange}
            />
            <div className="react-file-reader-button" />
          </div>
          <div className="recaptcha">
            <ReCaptcha />
          </div>
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default ResumeBlock;
