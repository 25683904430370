import moment from 'moment';
import { SafeBridgeServices } from './safe-bridge-services';
import { SafeBridgeNewSearchTypesConstants } from './safe-bridge-types';

export const safeBridgeSearchConstant = '2001-01-01T10:57:02.444Z';

export const staticSafeBridgeSearchData = {
  count: true,
  skip: 0,
  top: 10,
  searchMode: 'any',
  queryType: 'simple',
  filter: '',
  facets: [
    'NameOfCompound,count:50,sort:count',
    'MethodReportNumber,count:50,sort:count',
    'AnalyticalMethod,count:50,sort:count',
    'QuantificationLimit,count:50,sort:count',
    `DatePublished,values:${safeBridgeSearchConstant}|${moment()
      .toDate()
      .toISOString()}`,
  ],
  search: '*',
};

export const getSafeBridgeAzureSearchData = (requestBody) => {
  const safeBridgeRequestBody = { ...requestBody, top: 5000, skip: 0 };
  return (dispatch) => {
    SafeBridgeServices.searchForGeneralData(safeBridgeRequestBody).then(
      (response) => {
        dispatch({
          type: SafeBridgeNewSearchTypesConstants.LAST_SAFE_BRIDGE_SEARCH,
          payload: requestBody,
        });

        dispatch({
          type: SafeBridgeNewSearchTypesConstants.SAFE_BRIDGE_AZURE_SEARCH,
          payload: response,
          lastQuery: requestBody,
        });
      }
    );
  };
};
