import React, { useEffect, useState } from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import SoftwareDemoForm from '../../../components/software/software-demo-form/software-demo.component';
import { getSoftwareDemoPageData } from '../../../js/api/softwareForm/_softwareDemo.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function SoftwareDemo({ page: { modules } }) {
  // #RP - Task 63 - Software Demo
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getSoftwareDemoPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country !== undefined)
  ) {
    return (
      <div className="software-demo-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
        />
        <div className="trinity-container">
          <SoftwareDemoForm
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareDemo;
