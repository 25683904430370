import { ActionTypes, CartActionTypes } from './shopping-cart.types';

const INITIAL_STATE = {
  visible: false,
  cart: [],
  cart_update: 0,
  software_item: {},
  basketid: null,
  update_cart: [],
  cartattendees: [],
  attendeescount_update: 0,
  updateattendees: null,
  impersonateMember: { name: 'None', memberId: null, userprofile: null },
  allowcartsteps: 1,
  cartcategorypamas: {
    isOnlySoftware: false,
    isOnlyTraining: false,
    bothCategory: false,
    orderConfirmationNumber: '',
  }, // carteamilcategory
};

const cartReducer = (statecart = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartActionTypes.ADD_CART_ITEM:
      return {
        ...statecart,
        cart: action.payload, // TODO wrap in a function that adds items && accounts for quantity
      };
    case CartActionTypes.REMOVE_CART_ITEM:
      return {
        ...statecart,
        cart: action.payload, // TODO wrap in a function that deletes items && acounts for quantity
      };
    case CartActionTypes.CLEAR_CART:
      return {
        ...statecart,
        cart: [],
      };
    case CartActionTypes.TOGGLE_CART_VIEW:
      return {
        ...statecart,
        visible: !statecart.visible,
      };
    case CartActionTypes.GET_CART_ITEM:
      return {
        ...statecart,
        cart: statecart.cart,
      };
    case CartActionTypes.CART_COUNT_UPDATE:
      localStorage.setItem(
        'lastUpdatedCount',
        statecart.cart_update + action.payload
      );
      return {
        ...statecart,
        cart_update: statecart.cart_update + action.payload,
      };
    case CartActionTypes.UPDATE_IMPERSONATE:
      return {
        ...statecart,
        impersonateMember: action.payload,
      };
    case CartActionTypes.ADD_SOFTWARE_ITEM:
      return {
        ...statecart,
        software_item: action.payload,
      };
    case CartActionTypes.ADD_TRAINING_ITEM:
      return {
        ...statecart,
        software_item: action.payload,
      };
    case CartActionTypes.BASKET_ID:
      return {
        ...statecart,
        basketid: action.payload,
      };
    case CartActionTypes.RESET_CART:
      return INITIAL_STATE;
    case CartActionTypes.UPDATE_CART:
      return {
        ...statecart,
        update_cart: action.payload,
      };
    case CartActionTypes.GET_CART_ATTENDEES:
      return {
        ...statecart,
        cartattendees: statecart.cartattendees,
      };
    case CartActionTypes.ATTENDEES_COUNT_UPDATE:
      return {
        ...statecart,
        attendeescount_update: statecart.attendeescount_update + action.payload,
      };
    case CartActionTypes.ADD_CART_ATTENDEES:
      return {
        ...statecart,
        cartattendees: action.payload,
      };
    case CartActionTypes.UPDATE_CART_ATTENDEES:
      return {
        ...statecart,
        updateattendees: action.payload,
      };
    case CartActionTypes.CART_STEPS:
      return {
        ...statecart,
        allowcartsteps:
          action.payload /* NL : Task#185 Shopping Cart Steps Enable */,
      };
    case CartActionTypes.CART_CATEGORY_PARAMS:
      return {
        ...statecart,
        cartcategorypamas: action.payload,
      };
    default:
      return statecart; // INITIAL_STATE // this is not initial state otherwise it's load default state
  }
};

export default cartReducer;
