import React, { useState, useEffect } from 'react';
import './create-account.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CreateAccountForm from '../../../components/ecommerce/forms/create-account-form/create-account-form.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';

import { getAccountPageData } from '../../../js/api/create-account/_create-account.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function CreateAccount() {
  // #RP - Task  - Create Account.
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getAccountPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.CustomDropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country != undefined)
  ) {
    return (
      <Row className="create-account trinity-container">
        <Col xs={12} sm={8}>
          <CreateAccountForm
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </Col>
        <Col xs={12} sm={4} className="sidebar">
          <ContentWell
            className="module-spacer"
            content={
              responseMainContent != null ? responseMainContent.SideContent : ''
            }
          />
        </Col>
      </Row>
    );
  }
  return <Loader />;
}

export default CreateAccount;
