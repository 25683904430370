export const title = 'Submit Your Resume';
export const personalTitle = 'Personal Information';
export const contactTitle = 'Contact Information';
export const addressTitle = 'Address Information';
export const educationTitle = 'Education Information';
export const addTitle = 'Additional Information';
export const subTitle = 'Are you authorized to work in the United States?';
export const uploadTitle = 'Upload Resume';
export const link = 'Apply now';

export const uploadLink = 'Choose File';

export const jobDetails = [
  {
    id: '0',
    industry: 'Oil & Gas',
    location: 'Denver,CO',
    jobType: 'Executive',
    payRate: '',
  },
];
