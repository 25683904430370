import { useDispatch, useSelector } from 'react-redux';
import { NewsServices } from './news.services';
import { NewsActionTypes } from './news.types';

export const getUserPageWiseNews = (news) => (dispatch, getState) => {
  NewsServices.getUserNews(news).then((response) => {
    if (response && response !== 404 && response.data != '') {
      const newsData = response.data.NewsContent.NewsData;
      dispatch({
        type: NewsActionTypes.GET_PAGEWISE_NEWS,
        payload: newsData,
      });

      if (newsData.length > 0) {
        dispatch({
          type: NewsActionTypes.TOTAL_ITEM_COUNT,
          payload: newsData[0].TotalCount,
        });
      }
      dispatch({
        type: NewsActionTypes.NEWS_LOADING,
        payload: false,
      });
    } else if (response && response.data == '') {
      dispatch({
        type: NewsActionTypes.GET_PAGEWISE_NEWS,
        payload: [],
      });
    } else {
      dispatch({
        type: NewsActionTypes.NEWS_LOADING,
        payload: false,
      });
    }
  });
};

export const getAdminPageWiseNews = (news) => (dispatch, getState) => {
  NewsServices.getAdminNews(news).then((response) => {
    if (response && response !== 404 && response.data != '') {
      const newsData = response.data.NewsContent.NewsData;
      dispatch({
        type: NewsActionTypes.GET_PAGEWISE_NEWS,
        payload: newsData,
      });

      if (newsData.length > 0) {
        dispatch({
          type: NewsActionTypes.TOTAL_ITEM_COUNT,
          payload: newsData[0].TotalCount,
        });
      }
      dispatch({
        type: NewsActionTypes.NEWS_LOADING,
        payload: false,
      });
    } else if (response && response.data == '') {
      dispatch({
        type: NewsActionTypes.GET_PAGEWISE_NEWS,
        payload: [],
      });
    } else {
      dispatch({
        type: NewsActionTypes.NEWS_LOADING,
        payload: false,
      });
    }
  });
};

export const loadNews = (booleanParameter) => (dispatch, getState) => {
  dispatch({
    type: NewsActionTypes.NEWS_LOADING,
    payload: booleanParameter,
  });
};

export const activePageNumber = (pagenumber) => (dispatch) => {
  dispatch({
    type: NewsActionTypes.ACTIVE_PAGE_NUMBER,
    payload: pagenumber,
  });
};

export const loadFilter = () => (dispatch) => {
  NewsServices.getNewsFilterData().then((response) => {
    if (response) {
      dispatch({
        type: NewsActionTypes.FILTER_LOADING,
        payload: response,
        responseStatus: true,
      });
    }
  });
};

export const selectNewsActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (method === 'PublishedFromDate') {
      dispatch({
        type: NewsActionTypes.PUBLISHED_FROM_DATE_FILTER,
        payload: pdata,
      });
      return;
    }
    if (method === 'PublishedToDate') {
      dispatch({
        type: NewsActionTypes.PUBLISHED_TO_DATE_FILTER,
        payload: pdata,
      });
      return;
    }
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Id == filtername.Id).length > 0
      );

      if (fdata) {
        pdata = pdata;
      } else {
        pdata.push({ Id: filtername.Id, Name: filtername.Name });
      }

      if (method == 'Tags') {
        dispatch({ type: NewsActionTypes.TAGS_FILTER, payload: pdata });
      } else if (method == 'News') {
        dispatch({
          type: NewsActionTypes.NEWS_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({
          type: NewsActionTypes.INDUSTRY_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'PublishedDate') {
        dispatch({
          type: NewsActionTypes.PUBLISHED_DATE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Location') {
        dispatch({ type: NewsActionTypes.LOCATION_FILTER, payload: pdata });
      }
    }
  };

export const removeNewsActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (method === 'PublishedFromDate') {
      dispatch({
        type: NewsActionTypes.PUBLISHED_FROM_DATE_FILTER,
        payload: null,
      });
      return;
    }
    if (method === 'PublishedToDate') {
      dispatch({
        type: NewsActionTypes.PUBLISHED_TO_DATE_FILTER,
        payload: null,
      });
      return;
    }
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Id == filtername.Id).length > 0
      );

      if (fdata) {
        pdata = pdata.filter((x) => x.Id != filtername.Id);
      } else {
        pdata = pdata;
      }

      if (method == 'Tags') {
        dispatch({ type: NewsActionTypes.TAGS_FILTER, payload: pdata });
      } else if (method == 'News') {
        dispatch({
          type: NewsActionTypes.NEWS_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({
          type: NewsActionTypes.INDUSTRY_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'PublishedDate') {
        dispatch({
          type: NewsActionTypes.PUBLISHED_DATE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Location') {
        dispatch({ type: NewsActionTypes.LOCATION_FILTER, payload: pdata });
      }
    }
  };

export const selectNewsLocationActionFilter = (pdata) => (dispatch) => {
  dispatch({ type: NewsActionTypes.LOCATION_FILTER, payload: pdata });
};

export const getNewsPageData = () => (dispatch) => {
  NewsServices.getNewsPageData().then((response) => {
    if (response) {
      dispatch({
        type: NewsActionTypes.NEWS_PAGE_DATA,
        payload: response,
      });
    }
  });
};
