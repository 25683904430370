function PoliciesIndividualModal({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = '',
    hero_description = '',
    hero_ctas = [],
    hero_ctas_title = '',
  },
  content = '',
  content_link = '',
  triple_cta_one = [],
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
      hero_ctas,
      hero_ctas_title,
    },
    content,
    content_link,
    triple_cta_one,
  };
}

export default PoliciesIndividualModal;
