// RP # TASK 100 Individual Location
import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={5}
    defaultCenter={{
      lat: parseFloat(props.stateLatitude),
      lng: parseFloat(props.stateLongitude),
    }}
  >
    {props.markers.map((marker) => (
      // const onClick = props.onClick.bind(this, marker)
      <Marker
        key={marker.id}
        // onClick={onClick}
        position={{
          lat: parseFloat(marker.Latitude),
          lng: parseFloat(marker.Longitude),
        }}
      >
        {/* {props.selectedMarker === marker && */}
        <InfoWindow>
          <div className="address-card">
            <p className="address1">
              {marker.Address1}
              {marker.Address2 != '' ? `, ${marker.Address2}` : ''}
              <br />
              {marker.City != '' ? marker.City : ''}
              {marker.State != '' ? `, ${marker.State}` : ''}
              <br />
              {marker.Zip != '' ? marker.Zip : ''}
            </p>
          </div>
        </InfoWindow>
        {/* } */}
      </Marker>
    ))}
  </GoogleMap>
));

export default class IndividualMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shelters: [
        {
          Latitude: this.props.latitude,
          Longitude: this.props.longitude,
          City: this.props.city,
          State: this.props.state,
          Address1: this.props.address1,
          Address2: this.props.address2,
          Zip: this.props.zip,
        },
      ],
      selectedMarker: false,
      stateLatitude: this.props.latitude,
      stateLongitude: this.props.longitude,
    };
  }

  handleClick = (marker, event) => {
    this.setState({ selectedMarker: marker });
  };

  render() {
    return (
      <MapWithAMarker
        selectedMarker={this.state.selectedMarker}
        markers={this.state.shelters}
        stateLatitude={this.state.stateLatitude}
        stateLongitude={this.state.stateLongitude}
        // onClick={this.handleClick}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBM2AvVtQ27kN9h3guGJwgCGIqiVE-laNk&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: '100%' }} />}
        containerElement={<div style={{ height: '400px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    );
  }
}
