// ********************************************************
// ********************************************************
// **
// **  Matches the correct color class by the section tag
// **
// ********************************************************
// ********************************************************

// this might be become useless now that the api does this

export function colorClassMatcher(section) {
  switch (section) {
    case 'Environmental Health & Safety':
      return 'env';
    case 'Science':
      return 'sci';
    case 'Engineering':
      return 'eng';
    default:
      return section;
  }
}
