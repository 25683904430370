import React, { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactWidget from '../../../components/commons/widgets/contact-widget/contact-widget.component';
import ContactUsForm from '../../../components/commons/forms/contact-us-form/contact-us.component';
import Hero from '../../../components/commons/hero/hero.component';
import { getContactUsPageData } from '../../../js/api/contactus/_contactus.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './contact-us.styles.scss';

function ContactUs({ page: { MainContent, CategoryContent, modules } }) {
  // #RP - Task 46 - Bind data to ContactUs Form in Front-End.
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getContactUsPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country !== undefined)
  ) {
    return (
      <div className="contact-us-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="contact-us"
        />
        <Row className="trinity-container">
          <Col sm={8}>
            <div className="content-widget-container ">
              <ContactUsForm
                data={responseDropDownContent}
                mainData={responseMainContent}
                status={status}
              />
            </div>
          </Col>

          <Col sm={4} className="contact-outer">
            <ContactWidget
              data={
                status !== noContent && responseMainContent
                  ? responseMainContent
                  : ''
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
  return <Loader />;
}

export default ContactUs;
