import React, { useEffect, useState, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { DropdownContentActionTypes } from '../../redux/dropdown-content/dropdown-content.types';
import { getUserStates } from '../api/dropdowns/_get-user-states';
import { getUserCountries } from '../api/dropdowns/_get-user-countries';
import { getUserIndustries } from '../api/dropdowns/_get-user-industries';
import { getLocations } from '../api/dropdowns/_get-locations';
import { getResourceTypes } from '../api/dropdowns/_get-resource-type';
import { getIndustries } from '../api/dropdowns/_get-industries';
import { getSoftwares } from '../api/dropdowns/_get-softwares';

// example selection param:
// ['userStates', 'userCountries'] -- THIS MUST BE AN ARRAY (even for 1)
function useDropdownContent(selections = []) {
  const [dropdownMappedActions, setDropdownMappedActions] = useState({
    userStates: {
      type: DropdownContentActionTypes.ADD_ALL_USER_STATES,
      method: getUserStates,
    },
    userCountries: {
      type: DropdownContentActionTypes.ADD_ALL_USER_COUNTRIES,
      method: getUserCountries,
    },
    userIndustries: {
      type: DropdownContentActionTypes.ADD_ALL_USER_INDUSTRIES,
      method: getUserIndustries,
    },
    locations: {
      type: DropdownContentActionTypes.ADD_ALL_LOCATIONS,
      method: getLocations,
    },
    resourceTypes: {
      type: DropdownContentActionTypes.ADD_ALL_RESOURCE_TYPES,
      method: getResourceTypes,
    },
    industries: {
      type: DropdownContentActionTypes.ADD_ALL_INDUSTRIES,
      method: getIndustries,
    },
    softwares: {
      type: DropdownContentActionTypes.ADD_ALL_SOFTWARES,
      method: getSoftwares,
    },
  });

  const dispatch = useDispatch();

  const dropdownContent = useSelector(({ dropdownContent }) => dropdownContent);

  // JP #Task commented for now
  useEffect(() => {
    const dropdownsNotInReduxYet = selections.filter((selection) => {
      if (!dropdownContent[selection].length) return true;
      return false;
    });

    // only add dropdowns that are not already in Redux
    addDropdownsToRedux(dropdownsNotInReduxYet);
  }, []);

  const addDropdownsToRedux = useCallback(
    async (selectedDropdowns) => {
      try {
        // make a request for all missing dropdown content
        const requestDropdownContent = await Promise.all(
          selectedDropdowns.map((selectedDropdown) => {
            if (!dropdownMappedActions[selectedDropdown]) return null;
            return dropdownMappedActions[selectedDropdown].method();
          })
        );

        // add the missing content to redux after it is fetched
        selectedDropdowns.forEach((selected, i) => {
          dispatch({
            type: dropdownMappedActions[selected].type,
            payload: requestDropdownContent[i],
          });
        });
      } catch (e) {}
    },
    [dispatch]
  );

  // return allDropdownContent;
  return dropdownContent;
}

export default useDropdownContent;
