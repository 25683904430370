export const objData = [
  {
    section: 'ExDam Key Features',
    active: false,
    content: `
        <p><strong>BREEZE ExDAM HExDAM Module</strong></p>

        <p>BREEZE ExDAM HExDAM module contains the High Explosive (HE) numerical model. It provides a fast and accurate means to predict over/dynamic pressure, impulse, and the damage/injury due to primary/secondary HEs with consideration of shielding effect.</p>
        
        <p><strong>Product Features:</strong></p>
        
        <ul>
        <li>changed content</li>
        <li>Provides users with a fully 3D interactive interface with 200 CAD-like key strokes and shortcuts to make structure modeling easier and faster</li>
        <li> Facilitates users to set up primary HEs: TNT-equivalent yields and locations</li>
        <li>Facilitates users to set up secondary HEs: threshold, TNT-equivalent yields, and locations</li>
        <li> Predicts both peak overpressure and peak dynamic pressure due to primary/secondary HEs based on the curves derived for nuclear blast effects</li>
        <li> changing contentevels based on the incident pressure and impulse due to primary/secondary HEs</li>
        <li> Displays results in a variety of formats, including 2D and 3D interactive views, animations, and in a table</li>
        </ul>

        <p><strong>What’s Included?</strong></p>
        
        <p>
        • One free year of technical support and maintenance (upgrades) for the registered user
        • Shipping & handling of a USB hardware key (required in order to access the software)
        </p>

        <p><strong>Price: $10,995.00</strong></p>

        `,
  },
  {
    section: 'ExDAM HExDAM',
    active: true,
    content: `
        <p><strong>BREEZE ExDAM HExDAM section</strong></p>

        <p>BREEZE ExDAM HExDAM module contains the High Explosive (HE) numerical model. It provides a fast and accurate means to predict over/dynamic pressure, impulse, and the damage/injury due to primary/secondary HEs with consideration of shielding effect.</p>
        
        <p><strong>Product Features:</strong></p>
        
        <ul>
        <li>changed content</li>
        <li>Provides users with a fully 3D interactive interface with 200 CAD-like key strokes and shortcuts to make structure modeling easier and faster</li>
        <li> Facilitates users to set up primary HEs: TNT-equivalent yields and locations</li>
        <li>Facilitates users to set up secondary HEs: threshold, TNT-equivalent yields, and locations</li>
        <li> Predicts both peak overpressure and peak dynamic pressure due to primary/secondary HEs based on the curves derived for nuclear blast effects</li>
        <li> Predicts damage/injury levels based on the incident pressure and impulse due to primary/secondary HEs</li>
        <li> Displays results in a variety of formats, including 2D and 3D interactive views, animations, and in a table</li>
        </ul>

        <p><strong>What’s Included?</strong></p>
        
        <p>
        • One free year of technical support and maintenance (upgrades) for the registered user
        • Shipping & handling of a USB hardware key (required in order to access the software)
        </p>

        <p><strong>Price: $10,995.00</strong></p>

        `,
  },
  {
    section: 'ExDAM VExDAM',
    active: false,
    content: `
        <p><strong>BREEZE ExDAM VExDAM Module</strong></p>

        <p>BREEZE ExDAM HExDAM module contains the High Explosive (HE) numerical model. It provides a fast and accurate means to predict over/dynamic pressure, impulse, and the damage/injury due to primary/secondary HEs with consideration of shielding effect.</p>
        
        <p><strong>Product Features:</strong></p>
        
        <ul>
            <li>changed content</li>
            <li>Provides users with a fully 3D interactive interface with 200 CAD-like key strokes and shortcuts to make structure modeling easier and faster</li>
            <li> Facilitates users to set up primary HEs: TNT-equivalent yields and locations</li>
            <li> changing content TNT-equivalent yields, and locations</li>
            <li> Predicts both peak overpressure and peak dynamic pressure due to primary/secondary HEs based on the curves derived for nuclear blast effects</li>
            <li> Predicts damage/injury levels based on the incident pressure and impulse due to primary/secondary HEs</li>
            <li> Displays results in a variety of formats, including 2D and 3D interactive views, animations, and in a table</li>
        </ul>

        <p><strong>What’s Included?</strong></p>
        
        <p>
        • One free year of technical support and maintenance (upgrades) for the registered user
        • Shipping & handling of a USB hardware key (required in order to access the software)
        </p>

        <p><strong>Price: $10,995.00</strong></p>

        `,
  },
  {
    section: '3D Extend Extension',
    active: false,
    content: `
        <p><strong>BREEZE 3D Extend Extension Module</strong></p>

        <p>BREEZE ExDAM HExDAM module contains the High Explosive (HE) numerical model. It provides a fast and accurate means to predict over/dynamic pressure, impulse, and the damage/injury due to primary/secondary HEs with consideration of shielding effect.</p>
        
        <p><strong>Product Features:</strong></p>
        
        <ul>
        <li>changed content</li>
        <li>Provides users with a fully 3D interactive interface with 200 CAD-like key strokes and shortcuts to make structure modeling easier and faster</li>
        <li> changing content y HEs: TNT-equivalent yields and locations</li>
        <li>Facilitates users to set up secondary HEs: threshold, TNT-equivalent yields, and locations</li>
        <li> Predicts both peak overpressure and peak dynamic pressure due to primary/secondary HEs based on the curves derived for nuclear blast effects</li>
        <li> Predicts damage/injury levels based on the incident pressure and impulse due to primary/secondary HEs</li>
        <li> Displays results in a variety of formats, including 2D and 3D interactive views, animations, and in a table</li>
        </ul>

        <p><strong>What’s Included?</strong></p>
        
        <p>
        • One free year of technical support and maintenance (upgrades) for the registered user
        • Shipping & handling of a USB hardware key (required in order to access the software)
        </p>

        <p><strong>Price: $10,995.00</strong></p>

        `,
  },
];
