import React from 'react';
import '../../../about/indivi-location/widgets/local-leadership/leadership.styles.scss';

// RP # TASK 152 Industry Expert
export function IndustryExpertWidget({ title, data }) {
  return (
    <div className="leadership-container">
      <p className="title">{title}</p>
      {data &&
        data.map((item) => (
          <div className="content-wrapper">
            <div className="content">
              <p className="name">{item.Name}</p>
              {/* <p className="address-1">{item.AddressLine1}{item.AddressLine2 ? ', ' + item.AddressLine2 : ''}</p>             */}
              <div style={{ display: 'flex' }}>
                <a href={item.Phone} className="phone">
                  {item.Phone}
                </a>
                {item.City && (
                  <span className="phone">
                    &nbsp;{' '}
                    {`- ${
                      item.City +
                      (item.State && item.State !== '' && `, ${item.State}`)
                    }`}
                  </span>
                )}
              </div>
              <a href={`mailto:${item.Email}`} className="email">
                {item.Email}
              </a>
            </div>
          </div>
        ))}
    </div>
  );
}

export function ContactUsWidget({ title, data }) {
  return (
    <div className="leadership-container">
      <p className="title">{title}</p>
      {data &&
        data.map((item, index) => (
          <div key={index.toString()} className="content-wrapper">
            <div className="content">
              <p className="name">{item.Name}</p>
              {/* <p className="address-1">{item.AddressLine1}{item.AddressLine2 ? ', ' + item.AddressLine2 : ''}</p>             */}
              <a href={`phone:${item.Phone}`} className="phone">
                {item.Phone}
              </a>
              <a href={`mailto:${item.Email}`} className="email">
                {item.Email}
              </a>
            </div>
          </div>
        ))}
      {data != undefined && data.length == 0 && (
        <div className="content-wrapper">
          <div className="content">
            <p className="phone">
              For assistance, contact Trinity at 800.229.6655.
            </p>
            <p className="phone">Outside the U.S., dial +1 972.661.8100.</p>
            <br />
            <a href="/contactus" className="name">
              {'Contact us online >'}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default IndustryExpertWidget;
