import React, { useEffect, useState } from 'react';
import './training-lp.styles.scss';
// import "../training-lp-2.styles.scss";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import ImageContent from '../../../components/training/image-content/image-content.component';
import heroImageSrc from '../../../images/training/training_lp_hero.png';
import FilteredCtaGrid from '../../../components/commons/filtered-cta-grid/filtered-cta-grid.component';
import TrainingLandingAdvanceSearchFilter from '../../../components/training/training-advance-search-filter/training-landing-advance-search-filter.component';
import TrainingImageBar from '../training-image-bar/training-image-bar';
import { TrainingActionTypes } from '../../../redux/training/training.types';
import { getHomePageData } from '../../../redux/new-home-page/new-home.action';
import DiscountCode from '../../../components/new-home-page/components/features/DiscountCode';
import Loader from '../../../components/commons/helpers/loader/loader.component';

// RK Task#104 Training Secondary Change
// JP #Task 41 RK Task#99
function TrainingLP({
  page: { modules, MainContent, CategoryContent, TrainingCategory },
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHomePageData());
  }, []);

  const homePageData = useSelector((state) => state.homePageData);
  const [content, setContent] = useState({});
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  dispatch({
    type: TrainingActionTypes.TRAINING_FORMAT_FILTER_SELECTED,
    payload: [],
  });
  dispatch({
    type: TrainingActionTypes.TRAINING_CATEGORY_FILTER_SELECTED,
    payload: [],
  });
  dispatch({
    type: TrainingActionTypes.TRAINING_INDUSTRY_FILTER_SELECTED,
    payload: [],
  });
  dispatch({
    type: TrainingActionTypes.TRAINING_DATE_FILTER_SELECTED,
    payload: [],
  });
  dispatch({
    type: TrainingActionTypes.TRAINING_LOCATION_FILTER_SELECTED,
    payload: [],
  });
  dispatch({
    type: TrainingActionTypes.TRAINING_KEYWORD_FILTER_SELECTED,
    payload: [],
  });

  const pageCurrentData =
    homePageData.pageData && homePageData.pageData.HomeContent;
  console.log(pageCurrentData);

  if (!pageCurrentData) {
    return <Loader />;
  }

  return (
    <div className="training_lp training">
      <Hero
        src={
          MainContent.HeaderImage_src
            ? MainContent.HeaderImage_src
            : heroImageSrc
        }
        mobileImgSrc={MainContent?.HeaderMobileImage_src}
        title={MainContent?.Title}
        template="landing"
        search={false}
        description={MainContent?.HeaderText}
        link=""
        colorCategory="training"
      />
      <div className="trinity-container">
        <div className="top-container-training">
          <Row>
            <ImageContent content={MainContent} />
            <Col md={4} sm={6}>
              <TrainingLandingAdvanceSearchFilter istrainingladning />
              {/* <SearchWidget title="Find the training you're looking for:" /> */}
            </Col>
          </Row>
        </div>
      </div>
      <div className="trinity-container">
        {/* <TrainingImageBar promoCodeDesktopImage={MainContent.PromocodeDesktopImage_src}
                                  promoCodeMobileImage={MainContent.PromocodeMobileImage_src}/> */}
        <DiscountCode
          heading={pageCurrentData.PromoSectionTitle}
          promoCodeDesktopImage={MainContent.PromocodeDesktopImage_src}
          promoCodeMobileImage={MainContent.PromocodeMobileImage_src}
          content={pageCurrentData.IntroText}
          isFromTraining
          disclaimerText={pageCurrentData.PromocodeDisclaimer}
        />
      </div>
      <div className="sm-up-trinity-container">
        {CategoryContent ? (
          <FilteredCtaGrid
            ctas={CategoryContent}
            gridItemWidth="width-33"
            onlySmallCtas
            activeStarterSection
            optionTitle="— Select Software Type —"
          />
        ) : (
          <h2 className="xs-only-trinity-container module-spacer">
            grid not connected
          </h2>
        )}
      </div>
    </div>
  );
}

export default TrainingLP;
