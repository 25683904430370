import React from 'react';
import newsIcon from '../../sitesearch/images/news-icon-350x300.png';
import servicesIcon from '../../sitesearch/images/services-icon-350x300.png';
import resourcesIcon from '../../sitesearch/images/resources-icon-350x300.png';
import '../TrinitySiteSearch.scss';
import moment from 'moment';
import { BASE_URL, WEBSITE_URL, AZURE_CDN_URL } from '../../../js/api/_config';
import {
  tabListConstant,
  trinityTrainingDateFormatter,
} from '../../../utilities/CommonUtilities';

function NewsServicesResourceCard(props) {
  const getDefaultImage = (type) => {
    switch (type) {
      case tabListConstant.news:
        return newsIcon;
      case tabListConstant.resources:
        return resourcesIcon;
      case tabListConstant.services:
        return servicesIcon;
      default:
        return newsIcon;
    }
  };
  const getDefaultWebsiteUrl = (type) => {
    switch (type) {
      case tabListConstant.resources:
        return AZURE_CDN_URL;
      default:
        return WEBSITE_URL;
    }
  };
  return props.elementData.Url != null &&
    props.elementData.Url != undefined &&
    props.elementData.Url.toLowerCase().includes(
      'media.trinityconsultants.com'
    ) ? (
    <a
      className="context-card flipbookurl-decoration"
      style={{ textDecoration: 'none' }}
      href={props.elementData.Url}
      target="_blank"
    >
      <div className="image-container">
        <img
          alt={'TrinityBulletField' + Math.random()}
          className={
            props.elementData.Image
              ? 'image-present-class'
              : 'image-not-present-class'
          }
          src={
            props.elementData.Image
              ? BASE_URL + props.elementData.Image
              : getDefaultImage(props.category)
          }
        />
      </div>
      <div className="side-data" style={{ textDecoration: 'none' }}>
        <div className="title-tag-container" style={{ textDecoration: 'none' }}>
          <span
            className="context-trinitysearch-title"
            style={{ textDecoration: 'none' }}
          >
            {props.elementData.Title}
          </span>
          {props.elementData.Category && (
            <div className="tag-beside-title">{props.elementData.Category}</div>
          )}
        </div>
        <div className="context-text">
          {/* RP - Fix Date Issue */}
          <span>
            <strong>Published Date: </strong>
            {props.elementData.DatePublished &&
              props.elementData.DatePublished.split('T') &&
              props.elementData.DatePublished.split('T').length > 0 &&
              props.elementData.DatePublished.split('T')[0] &&
              trinityTrainingDateFormatter(
                props.elementData.DatePublished.split('T')[0]
              )}
          </span>
          {props.elementData.Author && (
            <span>
              <strong>Author: </strong>
              {props.elementData.AuthorName}
            </span>
          )}
        </div>
      </div>
    </a>
  ) : (
    <div
      className="context-card"
      onClick={() =>
        window.open(
          getDefaultWebsiteUrl(props.category) + escape(props.elementData.Url)
        )
      }
    >
      <div className="image-container">
        <img
          alt={'TrinityBulletField' + Math.random()}
          className={
            props.elementData.Image
              ? 'image-present-class'
              : 'image-not-present-class'
          }
          src={
            props.elementData.Image
              ? BASE_URL + props.elementData.Image
              : getDefaultImage(props.category)
          }
        />
      </div>
      <div className="side-data">
        <div className="title-tag-container">
          <span className="context-trinitysearch-title">
            {props.elementData.Title}
          </span>
          {props.elementData.Category && (
            <div className="tag-beside-title">{props.elementData.Category}</div>
          )}
        </div>
        <div className="context-text">
          <span>
            <strong>Published Date: </strong>
            {props.elementData.DatePublished &&
              props.elementData.DatePublished.split('T') &&
              props.elementData.DatePublished.split('T').length > 0 &&
              props.elementData.DatePublished.split('T')[0] &&
              trinityTrainingDateFormatter(
                props.elementData.DatePublished.split('T')[0]
              )}
          </span>
          {props.elementData.Author && (
            <span>
              <strong>Author: </strong>
              {props.elementData.AuthorName}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsServicesResourceCard;
