import React, { useState } from 'react';

import DataTable from './data-table/data-table.component';
import ContentWell from '../../commons/content-well/content-well.component';
import SearchInput from '../../commons/form-inputs/search-input/search-input.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';

function FilteredDataTable({ tableData, contentAboveTable }) {
  const [activeTableData, setActiveTableData] = useState(initTableData());
  const [chemicalList, setChemicalList] = useState(getChemicalList());

  function getChemicalList() {
    const uniqueChemicalsObject = tableData.reduce(
      (chemicalList, { chemical }) => {
        chemicalList[chemical] = 1;
        return chemicalList;
      },
      {}
    );

    return Object.keys(uniqueChemicalsObject);
  }

  function initTableData() {
    const initiliazedTableData = [];

    tableData.forEach((tableItem) => {
      initiliazedTableData.push({
        ...tableItem,
        activeSearch: true,
        activeSelect: true,
      });
    });

    return initiliazedTableData;
  }

  // Using css classes to filter because the mobile / desktop filters are different
  // => didn't want to render two different data sets

  function selectFilter(selectedChemical) {
    selectedChemical = selectedChemical.toLowerCase();

    const filteredData = [];

    activeTableData.forEach((tableItem) => {
      const chemical = tableItem.chemical.toLowerCase();

      if (chemical === selectedChemical || selectedChemical === 'all') {
        filteredData.push({ ...tableItem, activeSelect: true });
      } else {
        filteredData.push({ ...tableItem, activeSelect: false });
      }
    });

    setActiveTableData(filteredData);
  }

  function searchFilter(searchText) {
    const searchTextEmpty = searchText.trim() === '';
    searchText = searchText.toLowerCase();

    const filteredData = [];

    activeTableData.forEach((tableItem) => {
      const chemical = tableItem.chemical.toLowerCase();

      if (chemical.includes(searchText) || searchTextEmpty) {
        filteredData.push({ ...tableItem, activeSearch: true });
      } else {
        filteredData.push({ ...tableItem, activeSearch: false });
      }
      return false;
    });

    setActiveTableData(filteredData);
  }

  // not sure if titles will be changing
  // => temporarily storing titles for placeholder data for now
  const titleList = [
    'Chemical',
    'CAS#',
    'Sampling',
    'Method',
    'Analytical Sensitivity',
  ];

  if (activeTableData) {
    return (
      <div className="filtered-data-table module-spacer">
        {contentAboveTable && contentAboveTable}

        <SearchInput
          onChange={(e) => searchFilter(e.target.value)}
          searchIcon
          placeholder="Search for a chemical"
        />

        <SelectList
          defaultText="— Resource Type —"
          className="mb-4 mt-1 d-sm-none"
          onChange={(e) => selectFilter(e.target.value)}
        >
          <option className="filter" value="All" key="all">
            All
          </option>
          {chemicalList.map((title) => (
            <option className="filter" value={title} key={title}>
              {title}
            </option>
          ))}
        </SelectList>

        <DataTable titleList={titleList} tableData={activeTableData} />
      </div>
    );
  }
  return null;
}

export default FilteredDataTable;
