import React from 'react';
import './feed-back-pages.scss';
import FeedbackComponent from './feed-back-component';

function FeedBackPage(props) {
  return (
    <div>
      <FeedbackComponent
        content="Please provide your evaluation of the following course:"
        subTitle="Fundamentals of Tank Emission Calculations"
        subContent=""
        questionHeading="Please rate the course of the following factors"
        props={props}
        data={data}
      />
    </div>
  );
}

const options = [
  {
    label: 'Far Below Average',
    value: 'farBelowAverage',
  },
  {
    label: 'Below Average',
    value: 'belowAverage',
  },
  {
    label: 'Average',
    value: 'average',
  },
  {
    label: 'Above Average',
    value: 'aboveAverage',
  },
  {
    label: 'Far Above Average',
    value: 'farAboveAverage',
  },
];
let data = [
  {
    Id: 1,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'How well did the course meet your expectations overall?',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 1,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 3,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Course content applicability and usefulness',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 2,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 4,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Appropriate technical depth',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 3,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 5,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Overall presentation of the material',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 4,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 6,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Instructor organization and preparedness',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 5,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 7,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Instructor was easy to understand',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 6,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 8,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Instructor encouraged participation throughout the class',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 7,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 9,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Instructor was skilled in listening',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 8,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 10,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Technical knowledge and competence of instructor',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 9,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 11,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Motivation and enthusiasm of instructor',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 10,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 12,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue:
      'Instructor was genuinely interested in whether or not I learned ',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 11,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 13,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Usefulness of handouts and course manual',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 12,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 14,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Effectiveness of visual teaching aids',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 13,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 15,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'MultipleChoice',
    SurveyType: 'Classroom',
    QuestionValue: 'Classroom setup was conducive to learning',
    AvailableAnswers: [
      {
        Id: 1,
        Value: 'Far Below Average',
        SortOrder: 10,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 2,
        Value: 'Below Average',
        SortOrder: 20,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 3,
        Value: 'Average',
        SortOrder: 30,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 4,
        Value: 'Above Average',
        SortOrder: 40,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
      {
        Id: 5,
        Value: 'Far Above Average',
        SortOrder: 50,
        CreateDate: '03/27/2017',
        LastModifiedDate: '03/27/2017',
        CreateUser: 'trinitysite',
        LastModifiedUser: 'trinitysite',
      },
    ],
    SortOrder: 14,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 16,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'Text',
    SurveyType: 'Classroom',
    QuestionValue: 'Comments on the facility?',
    AvailableAnswers: [],
    SortOrder: 15,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 17,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'Text',
    SurveyType: 'Classroom',
    QuestionValue: 'How could the course instruction be improved?',
    AvailableAnswers: [],
    SortOrder: 16,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 18,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'Text',
    SurveyType: 'Classroom',
    QuestionValue: 'How could the course materials be improved?',
    AvailableAnswers: [],
    SortOrder: 17,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 19,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'Text',
    SurveyType: 'Classroom',
    QuestionValue: 'What did you like best about the course?',
    AvailableAnswers: [],
    SortOrder: 18,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
  {
    Id: 20,
    SessionId: 2498,
    AttendeeId: 26745,
    CourseName: 'Introduction to Air Quality Regulations',
    QuestionType: 'Text',
    SurveyType: 'Classroom',
    QuestionValue:
      'What environmental publications or online resources do you most frequently review?',
    AvailableAnswers: [],
    SortOrder: 19,
    CreateDate: '11/18/2014',
    LastModifiedDate: '11/18/2014',
    CreateUser: 'TCIUSA\\erydberg',
    LastModifiedUser: 'TCIUSA\\erydberg',
  },
];

export default FeedBackPage;
