import React from 'react';
import './minnow-box-cta-styles.scss';

import { Link } from 'react-router-dom';

function BoxCta({
  title,
  className,
  smallBoxCta,
  subTitle,
  content,
  link,
  color,
  categories,
  transparent,
  test,
  contentClassName,
}) {
  return (
    <Link
      className={`
                ${className || ''}
                box-cta background xs-only-trinity-container
                ${transparent ? 'transparent' : ''} 
                ${categories ? categories[0].id : ''}
               ${color || ''}
            `}
      to={link}
      target="_blank"
    >
      {title && (
        <div className="title">
          <div dangerouslySetInnerHTML={{ __html: title }} />{' '}
          {/* NL # TASK 40 */}
          {smallBoxCta && <span className="arrow d-none d-sm-inline">›</span>}
          {/* <span className="arrow">›</span> */}
        </div>
      )}
      {subTitle && <div className="sub-title">{subTitle}</div>}
      {content && (
        <div
          className={`custom-block custom-style${
            contentClassName && contentClassName
          }`}
        >
          {content}
        </div>
      )}
      {link ? (
        <div className="card__minnow-footer">
          <span className="link-text">{'View More >'}</span>
        </div>
      ) : null}
    </Link>
  );
}

export default BoxCta;
