import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import WebsiteProblemWidget from '../../../components/commons/widgets/website-problem-widget/website-problem.component';
import WebsiteProblemContent from '../../../components/commons/forms/website-problem/website-problem.component';
import WebsiteProblemForm from '../../../components/commons/forms/website-problem/problem-form/problem-form.component';
import { getWebsiteProblemPageData } from '../../../js/api/specialty-pages/_website-problem.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function WebsiteProblem({ page: { modules } }) {
  // #RP - Task 71 - Website Problem
  const [responseMainContent, setResponseMainContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getWebsiteProblemPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent && responseMainContent.Title != null)
  ) {
    return (
      <div className="webinar-container mb-5">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="policies"
        />

        <div className="trinity-container">
          <Row>
            <Col xs={12} sm={8} className="left">
              <div className="content-container">
                <WebsiteProblemContent
                  mainData={responseMainContent}
                  status={status}
                />
              </div>
              <hr />
              <div className="bottom-form-container">
                <WebsiteProblemForm
                  mainData={responseMainContent}
                  status={status}
                />
              </div>
            </Col>
            <Col xs={12} sm={4} className="widget">
              <WebsiteProblemWidget
                mainData={responseMainContent}
                status={status}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default WebsiteProblem;
