import React from 'react';
import './select-list.styles.scss';

function SelectList({
  children,
  defaultText,
  filter,
  label,
  className,
  required,
  ...otherProps
}) {
  return (
    <label className={`trinity-select-list ${className || ''}`}>
      {label ? (
        <span className="label">{label}</span>
      ) : (
        <span className="sr-only">A select dropdown list</span>
      )}
      <div className="select-wrapper">
        <select
          {...otherProps}
          defaultValue={defaultText}
          className={`select-list ${className || ''}`}
          // style={required ? { border: '1px solid rgb(255, 0, 0)' } : {}}
        >
          <option value={defaultText}>
            {defaultText}
            &nbsp;
          </option>
          {children}
        </select>
        {required && <span className="required-text">*</span>}
      </div>
    </label>
  );
}

export default SelectList;
