import React from 'react';
import { Link } from 'react-router-dom';
import Hero from '../../../components/commons/hero/hero.component';
import SingleReleaseNote from '../../../components/software/release-notes/single-release-notes/single-release-notes.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './release-notes.styles.scss';

function ReleaseNotes({ page: { SoftwareSupportContent } }) {
  const backLink = '< Go Back';

  if (SoftwareSupportContent) {
    return (
      <div className="release-notes-page">
        {SoftwareSupportContent.ReleaseNotes[0] && (
          <Hero
            src={SoftwareSupportContent.ReleaseNotes[0].HeaderImage}
            mobileImgSrc={
              SoftwareSupportContent.ReleaseNotes[0].HeaderMobileImage
            }
            alt={SoftwareSupportContent.ReleaseNotes[0].HeaderImage}
            title={SoftwareSupportContent.ReleaseNotes[0].Title}
            description={SoftwareSupportContent.ReleaseNotes[0].HeaderContent}
            template="standard"
          />
        )}

        <div xs={12} className="trinity-container">
          {SoftwareSupportContent.ReleaseNotes[0] && (
            <SingleReleaseNote
              data={SoftwareSupportContent.ReleaseNotes[0].ReleaseNotesCategory}
              releaseName={SoftwareSupportContent.ReleaseNotes[0].Title}
            />
          )}
          {backLink && (
            <Link
              to="/software/support"
              className="back-link "
              style={{ display: 'block', marginBottom: '2rem' }}
            >
              {backLink}
            </Link>
          )}

          {SoftwareSupportContent.ReleaseNotes[0] &&
            SoftwareSupportContent.ReleaseNotes[0].ReleaseNotesCategory.map(
              (version) => {
                if (
                  SoftwareSupportContent.ReleaseNotes[0].SelectedVersion ===
                  version.UrlName
                ) {
                  return (
                    <div>
                      <p className="title">
                        {`${SoftwareSupportContent.ReleaseNotes[0].Title} `}
                        {version.Title}
                      </p>
                      <p className="content">{version.HeaderContent}</p>
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: version.Content,
                        }}
                      />
                    </div>
                  );
                }
              }
            )}

          {backLink && (
            <Link
              to="/software/support"
              className="back-link"
              style={{ display: 'block', marginTop: '2rem' }}
            >
              {backLink}
            </Link>
          )}
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default ReleaseNotes;
