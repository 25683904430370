import React from 'react';
import Parser from 'html-react-parser';
import './widget-box.styles.scss';
import { trinityTrainingDateFormatter } from '../../../utilities/CommonUtilities';

function WidgetBox({ data }) {
  // const data = WidgetData;

  const preparePresenterText = (text) => {
    const textArray = text.split(',');
    let finalString = '';
    let tempString = '';

    textArray.forEach((element, index) => {
      if (index % 2 !== 0) {
        finalString = `${finalString + tempString + element};`;
      } else {
        tempString = `${element},`;
      }
    });
    return finalString.substring(0, finalString.length - 1);
  };

  return (
    <div className="widgetBox-wrapper">
      <div className="content-wrapper">
        {data
          // data.sort((a, b) => new Date(a.strEventDate) - new Date(b.strEventDate))
          .map((item) => (
            <div className="widget-wrapper">
              <div className="left-content">
                <div className="image">
                  <img src={item.TrandShowGlobalIcon} alt="" />
                </div>
                <div className="inner-content">
                  <a href={item.TradeShowLink} className="inside-link">
                    {item.Title}
                  </a>
                  {item.strEventDate && (
                    <p className="date">
                      {trinityTrainingDateFormatter(item.strEventDate)}
                    </p>
                  )}
                  {item.BoothNumber && (
                    <p className="booth-number">
                      Booth Number:
                      <a> {item.BoothNumber}</a>
                    </p>
                  )}
                  {/* <p>
                                            {item.Presenter &&
                                            <span>Presenter:<a> {preparePresenterText(item.Presenter)}</a></span>}
                                        </p>

                                            <p>
                                                {item.Presenter && item.PresentationIntroText &&
                                                <span>Presentation: {item.PresentationIntroText}</span>}
                                                {!item.Presenter && item.PresentationIntroText &&
                                                <span>Presentation: {item.PresentationIntroText}</span>}
                                            </p> */}

                  <p>
                    {item.PresentationContent && (
                      <span>{Parser(item.PresentationContent)}</span>
                    )}
                  </p>

                  <a
                    href={item.TradeShowLink}
                    target="_blank"
                    className="learn-link"
                    rel="noreferrer"
                  >
                    Learn More &gt;
                  </a>
                </div>
              </div>
              <div className="right-content">
                <p className="location">
                  {item.City}
                  {item.State ? `, ${item.State}` : ''}
                </p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default WidgetBox;
