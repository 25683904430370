import React, { Suspense } from 'react';
import Loader from '../../../../components/commons/helpers/loader/loader.component';

function NewComCareerLandingPage({ page: { MainContent, breadCrumbList } }) {
  const HeroComponent = React.lazy(() =>
    import('./new-com-career-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-com-career-content-component')
  );

  return (
    <div className="mission-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewComCareerLandingPage;
