import React from 'react';

import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';

import heroImageSrc from '../../../images/consulting-ehs/small-hero-ehs.png';
import FilteredDataTable from '../../../components/consulting/filtered-data-table/filtered-data-table.component';
import ImageLeftWithContentWell from '../../../components/consulting/image-left-with-content-well/image-left-with-content-well.component';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import widgetData from '../../../components/commons/widgets/widgetBox-component/widgetBox-data';

function ConsultingDataTable({ page: { modules } }) {
  const data = widgetData.slice(0, 1);
  return (
    <div className="consulting consulting-data-table">
      <Hero
        src={heroImageSrc}
        alt={modules.hero.hero_alt}
        title={modules.hero.hero_title}
        template="standard"
        altMobileTitle
        colorCategory="env"
      />

      <div className="trinity-container">
        <ContactCta />
      </div>

      <div className="sm-up-trinity-container">
        <ContentWithSidebar
          left={
            <div className="xs-only-trinity-container">
              <ImageLeftWithContentWell />
              <div className="pr-sm-5">
                <FilteredDataTable
                  contentAboveTable={
                    <ContentWell content={modules.content_area} />
                  }
                  tableData={modules.table_data}
                />
              </div>
            </div>
          }
          right={
            <Col sm={12} md={12}>
              {data.map((widget, k) => (
                <Col key={widget.title + k} className="widget-box">
                  <WidgetBox
                    widgetTitle={widget.widgetTitle}
                    widgetContent={widget.widgetContent}
                    widgetSubContent={widget.widgetSubContent}
                    singleLink={widget.singleLink}
                    widgetLink={widget.widgetLink}
                    widgetImage={widget.widgetImage}
                  />
                </Col>
              ))}
            </Col>
          }
        />
      </div>
    </div>
  );
}

export default ConsultingDataTable;
