const resourcesPages = [
  // {
  //   name: "News",
  //   path: "/news",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "news-media",
  // },
  // {
  //   name: "Resources",
  //   path: "/resources",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "resources",
  // },
  // {
  //   name: "News Ind Article",
  //   path: "/news-article",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description",
  //   },
  //   template: "NewsIndividualArticle",
  // },
];

export default JSON.stringify(resourcesPages);
