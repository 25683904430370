// setting default content to fill in placeholder data for fields not filled out
function TrainingLpModel({
  hero: {
    hero_src = '',
    hero_alt = '',
    hero_title = 'Aquatic Environmental Monitoring',
    hero_description = '',
  },
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
      hero_description,
    },
  };
}

export default TrainingLpModel;
