import { ENDPOINTS } from './_config';

export const registerUser = async (fields) => {
  try {
    const request = await fetch(ENDPOINTS.PROXY + ENDPOINTS.REGISTER_USER, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fields),
    });
    if (request.status === 204) {
      return {
        response: request,
        error: false,
      };
    }
    if (request.status === 500) {
      const response = await request.json();
      if (response.ResponseStatus) {
        if (response.ResponseStatus.ErrorCode === 'Exception') {
          return { message: response.ResponseStatus.Message, error: true };
        }
      }
    }
    return {
      message: 'There was an error processing your request',
      error: true,
    };
  } catch (e) {
    return {
      error: true,
      message: 'There was an error processing your request',
    };
  }
};

export const profileUpdateUser = async (fields) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;
  try {
    const request = await fetch(
      ENDPOINTS.PROXY + ENDPOINTS.USER.PROFILE_UPDATE_USER,
      {
        method: 'POST',
        headers: {
          Authorization: auth,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fields),
      }
    );

    if (request.ok) {
      // return {response: request, error: false}
      return {
        response: request,
        error: false,
      };
    }
    return {
      message: 'There was an error processing your request',
      error: true,
    };
  } catch (e) {
    return {
      error: true,
      message: 'There was an error processing your request',
    };
  }
};

export const updateUserPassword = async (fields) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;
  try {
    const request = await fetch(
      ENDPOINTS.PROXY + ENDPOINTS.USER.PROFILE_PASSWORD_UPDATE,
      {
        method: 'POST',
        headers: {
          Authorization: auth,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(fields),
      }
    );

    if (request.ok) {
      // return {response: request, error: false}
      return {
        response: request,
        error: false,
      };
    }
    return {
      message: 'There was an error processing your request',
      error: true,
    };
  } catch (e) {
    return {
      error: true,
      message: 'There was an error processing your request',
    };
  }
};

export const isLoginFirsttimeUpdate = async () => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;
  try {
    const request = await fetch(
      ENDPOINTS.PROXY + ENDPOINTS.USER.ISLOGIN_FIRSTTIME,
      {
        method: 'POST',
        headers: {
          Authorization: auth,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (request.ok) {
      // return {response: request, error: false}
      return {
        response: request,
        error: false,
      };
    }
    return {
      message: 'There was an error processing your request',
      error: true,
    };
  } catch (e) {
    return {
      error: true,
      message: 'There was an error processing your request',
    };
  }
};

export const updateLastActivitydate = async (fields) => {
  const auth = `Bearer ${localStorage.getItem('access-token')}`;
  try {
    const request = await fetch(
      ENDPOINTS.PROXY + ENDPOINTS.USER.UPDATE_LAST_ACTIVITY_DATE,
      {
        method: 'POST',
        headers: {
          Authorization: auth,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (request.ok) {
      // return {response: request, error: false}
      return {
        response: request,
        error: false,
      };
    }
    return {
      message: 'There was an error processing your request',
      error: true,
    };
  } catch (e) {
    return {
      error: true,
      message: 'There was an error processing your request',
    };
  }
};
