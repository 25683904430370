import React from 'react';
import './widget-box.styles.scss';
import { trinityTrainingDateFormatter } from '../../../../utilities/CommonUtilities';

function CommonWidgetBox({
  widgetImage,
  widgetContent,
  widgetContentLink,
  widgetDate,
  widgetSubContent,
  singleLinkContent,
  singleLink,
  alt,
  city,
  state,
  startDate,
}) {
  return (
    <div>
      <div className="widget-content">
        {widgetImage && (
          <img src={widgetImage} alt={alt} className="widget-icon" />
        )}
        <div className="widget-text">
          {widgetContent && (
            <a
              href={widgetContentLink}
              target="_blank"
              className="content"
              rel="noreferrer"
            >
              {widgetContent}
            </a>
          )}
          {widgetDate && (
            <p className="date">{trinityTrainingDateFormatter(widgetDate)}</p>
          )}
          {widgetSubContent && (
            <span className="sub-content">{widgetSubContent}</span>
          )}
          {singleLinkContent && (
            <a
              href={singleLink}
              target="_blank"
              className="widget-single-link"
              rel="noreferrer"
            >
              {singleLinkContent}
            </a>
          )}
          <p className="widget-time">
            {' '}
            {startDate && startDate}{' '}
            {city && ` - ${city}${state !== '' ? `, ${state}` : ''}`}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CommonWidgetBox;
