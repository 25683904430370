import React from 'react';
import './custom-button.styles.scss';
import SmallLoader from '../helpers/small-loader/small-loader.component';

function CustomButton({
  children,
  rounded = false,
  arrow = false,
  altColors = false,
  variableWidth = false,
  category,
  className,
  loading,
  title,
  onClick,
  btndisabled = false,
  hideBorderOnHover = false,
  ...otherProps
}) {
  return (
    <button
      className={`custom-button
                    ${arrow ? 'has-arrow' : ''}
                    ${category || ''} 
                    ${category ? 'category-filter' : ''}
                    ${altColors ? 'alt-colors' : ''}
                    ${rounded ? 'rounded-btn' : ''}
                    ${variableWidth ? 'variable-width' : ''}
                    ${loading ? 'loading' : ''}
                    ${
                      hideBorderOnHover ? 'button-border-hide' : 'button-border'
                    }
                    ${className || ''}
                `}
      onClick={loading ? () => {} : onClick}
      {...otherProps}
      disabled={btndisabled}
    >
      {children}
      {title}
      {loading && <SmallLoader />}
    </button>
  );
}

export default CustomButton;
