import React from 'react';
import {
  title,
  content_1,
  blurb_1,
  blurbData,
  bottomContent,
} from './privacy-policy.data';

import './privacy-policy.styles.scss';

function PrivacyPolicyForm() {
  return (
    <div className="policy-form-container">
      <p>Privacy Policy</p>

      <p>Last Updated: November 2020</p>

      <p>
        Thank you for visiting the web site of Trinity Consultants, Inc.
        (&ldquo;we&rdquo; or &ldquo;Trinity Consultants&rdquo;). This privacy
        policy tells you how we use personal information collected at this site.
        Please read this privacy policy carefully before using the site or
        submitting any personal information because it may affect your rights.
        By using the site, you are accepting the practices described in this
        privacy policy. These practices may be changed, but any changes will be
        posted and changes will only apply to activities and information on a
        going forward, not retroactive basis. You are encouraged to review the
        privacy policy whenever you visit the site to make sure that you
        understand how any personal information you provide will be used.
      </p>

      <p>
        This website is designed for general audiences and we do not
        specifically target any individual under the age of 18. You may not
        access or use this website if you are 18 years of age or younger.
      </p>

      <p>
        <strong>Collection and Use of Information</strong>
      </p>
      <p>
        We may collect the personally identifiable information listed below when
        you voluntarily access our web site or otherwise voluntarily submit such
        information to us.
      </p>

      <p>
        We may collect the following types of personally identifiable
        information from you:
      </p>
      <ul>
        <li>Your name;</li>
        <li>Your postal address;</li>
        <li>Your email address;</li>
        <li>The company you work for and your position;</li>
        <li>Your phone number;</li>
        <li>Your fax number;</li>
        <li>Your IP address;</li>
        <li>
          Your consumer history (i.e., records of services and products
          purchased from us or through our site); and/or
        </li>
        <li>Your br/owsing and other activity on our website.</li>
      </ul>

      <p>
        The personally identifiable information you provide may be used by us
        for the following purposes:
      </p>
      <ul>
        <li>
          To process or respond to your specific request, such as a request to
          subscribe to our publications, join our mailing list, or communicate
          with us using our online contact form;
        </li>
        <li>
          To contact you with information, publications, promotions, and
          opportunities;
        </li>
        <li>To market our services and products;</li>
        <li>To provide customer service; and</li>
        <li>To enforce this privacy policy.</li>
      </ul>

      <p>
        We may store the personally identifiable information described above for
        the purposes described above. We do not sell your data to any
        third-party. In the event that you make a purchase from our website,
        this purchase and the billing process is administered by a third-party.
        Please see the privacy policy of such third-party for their privacy
        policy and practices. The third-party we currently use to provide such
        purchase and billing services is Paypal, Inc. Additionally, we use a
        third-party service provider, Act-On Software, Inc.
        (&ldquo;Act-On&rdquo;), to assist with analytics and marketing. Act-On
        may access user personal information to provide such services, and users
        should review the privacy policies governing Act-On&rsquo;s privacy
        practices.
      </p>

      <p>
        <strong>Cookie/Tracking Technology</strong>
      </p>
      <p>
        The site may use cookie and tracking technology depending on the
        features offered. Cookies are small files applied to an internet
        br/owser to track a user&rsquo;s movement within a website. Cookie and
        tracking technology are useful for gathering information such as
        br/owser type and operating system, tracking the number of visitors to
        the site, and understanding how visitors use the site. Cookies can also
        help customize the site for visitors. Cookies may be tied to your
        personally identifiable information if you have previously provided such
        information. Aggregate cookie and tracking information may be shared
        with third parties, but such aggregated information will not
        specifically identify you.
      </p>

      <p>
        <strong>Distribution of Information</strong>
      </p>
      <p>
        We may share information with governmental agencies or other companies
        assisting us in fraud prevention or investigation. We may do so when:
        (1) permitted or required by law; or, (2) trying to protect against or
        prevent actual or potential fraud or unauthorized transactions; or, (3)
        investigating fraud which has already taken place. Information is not
        provided pursuant to this paragraph for marketing purposes.
      </p>

      <p>
        <strong>Commitment to Data Security</strong>
      </p>
      <p>
        Your personally identifiable information is kept secure by us using
        reasonable security measures. Only authorized employees, agents and
        contractors (who have agreed to keep information secure and
        confidential) have access to this information. All emails and
        newsletters from this site allow you to opt out of further mailings.
        While we utilize reasonable security measures, you should also protect
        your personally identifiable information and not share it with any party
        or provide any party with access to such information, including us, if
        you do not wish to do so.
      </p>

      <p>
        <strong>Third-Party Services, Links, and Websites</strong>
      </p>
      <p>
        The privacy practices set forth in this privacy policy are for this web
        site only. If you link to other web sites, please review the privacy
        policies posted at those sites. We do not control or manage the
        activities of any third-party, its website(s), or any of their data
        collection practices, and are therefore not responsible for any of their
        policies or practices. We do not necessarily endorse any third-party for
        which a link is provided on our web site. We also use third-party data
        storage providers to store our data, which data may include user
        personal information. These third-party data storage providers do not
        access data unless necessary for maintenance or other similar purposes.
      </p>

      <p>
        <strong>California Residents</strong>
      </p>
      <p>
        Our practices and this policy are designed to comply with the California
        Consumer Privacy Act (&ldquo;CCPA&rdquo;). If you are a resident of
        California, you may have certain rights with respect to your personally
        identifiable information. In addition to the other rights set forth
        herein, if you are a California resident, then you also have the right
        to:
      </p>
      <ul>
        <li>
          If you believe that your privacy rights under the CCPA have been
          violated, you may notify us in writing so that we may cure the
          violation, if a violation does indeed exist;
        </li>
        <li>
          While we do not sell your data to third-parties or intend to do so,
          you may opt out of this practice by using the link at the bottom of
          our website labeled &ldquo;Do Not Sell My Personal Information&rdquo;;
        </li>
        <li>
          Request information about how and why your personally identifiable
          information is collected, used, and stored;
        </li>
        <li>
          Request copies of your personally identifiable information that is
          collected, used, or stored;
        </li>
        <li>
          Request the categories or names of third-parties with whom your
          personally identifiable information is shared; and
        </li>
        <li>
          Subject to certain exceptions, request the deletion of all personally
          identifiable information held by us.
        </li>
      </ul>

      <p>
        In the event that you would like to inquire about or enforce any of your
        rights under the CCPA, please contact us at hello@trinityconsultants.com
        and we will promptly respond. You may also call us at 800. 229.6655.
      </p>

      <p>
        If you are a California resident then California Civil Code Section
        1798.83 permits you to request information regarding the disclosure of
        your personal information by us to third-parties for the
        third-parties&rsquo; direct marketing purposes. To make such a request,
        please send an email to hello@trinityconsultants.com.
      </p>

      <p>
        If you are a California resident under 18, and a registered user of any
        site where this policy is posted, California Business and Professions
        Code Section 22581 permits you to request and obtain removal of content
        or information you have publicly posted. To make such a request, please
        send an email with a detailed description of the specific content or
        information to hello@trinityconsultants.com. Please be aware that such
        request does not ensure complete or comprehensive removal of the content
        or information you have posted and that there may be circumstance in
        which the law does not require or allow removal even if requested.
      </p>

      <p>
        The Company has the right to verify the identity of any user making any
        request described above, and the Company shall have no obligation to
        address a request if the user making such request does not provide
        reasonable verification.
      </p>

      <p>
        <strong>General Data Protection Regulation</strong>
      </p>
      <p>
        Our practices and this policy are designed to comply with the General
        Data Protection Regulation (&ldquo;GDPR&rdquo;). If you are a resident
        of the European Union or the European Economic Area, we are required to
        inform you that you have certain rights regarding the personally
        identifiable information you provide to us. In addition to the other
        rights set forth herein, if you are a resident of the European Union or
        the European Economic Area, you also have the right to:
      </p>

      <ul>
        <li>
          Request a copy of the personally identifiable information held by us;
        </li>
        <li>
          Request that we provide you access to and/or rectify personally
          identifiable information that is inaccurate or out-of-date;
        </li>
        <li>
          Request erasure of your personally identifiable information where it
          is no longer necessary for us to retain it;
        </li>
        <li>
          Withdraw consent to the processing of your personal information at any
          time;
        </li>
        <li>
          Request that the data controller provide you with your personally
          identifiable information and, where possible, to transmit that data
          directly to another data controller, where applicable. This right of
          portability only applies where the processing is based on consent or
          is necessary for the performance of a contract and if we process the
          personal information by automated means;
        </li>
        <li>Request a restriction on further processing, if applicable;</li>
        <li>
          Object to processing of your personal information if the processing is
          based on a legitimate interest, is for direct marketing, or is for
          scientific research and statistics;
        </li>
        <li>Lodge a complaint with a supervisory authority; and</li>
        <li>
          Be notified of a security br/each affecting your personal information
          within 72 hours.
        </li>
      </ul>

      <p>
        Our Data Protection Officer is Paul Smith and may be reached at
        800.229.6655.
      </p>

      <p>
        More information regarding the GDPR and your rights under the GDPR may
        be obtained from the European Commission.
      </p>

      <p>
        <strong>Privacy Contact Information</strong>
      </p>
      <p>
        If you have any questions, concerns, or comments about our privacy
        policy you may contact us using the information below:
      </p>

      <p>
        By e-mail: hello@trinityconsultants.com <br />
        By Phone: 972-661-8100
      </p>

      <p>
        We reserve the right to make changes to this policy. Any changes to this
        policy will be posted and the date of the most recent change will be
        posted above.
      </p>
    </div>
  );
}

export default PrivacyPolicyForm;
