import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './resume.validationRules';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import {
  addTitle,
  contactTitle,
  educationTitle,
  link,
  subTitle,
  title,
  uploadTitle,
} from './resume.data';

import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../../commons/recaptcha/recaptcha.component';
import './resume.styles.scss';
import {
  submitApplyOnlineResponse,
  submitFileDataApplyOnline,
} from '../../../../js/api/staffing/_resume.data';
import { createToken } from '../../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../../commons/helpers/form-uniqueid/form-uniqueid';

// RP #Task 55 - Apply online webform
function StaffingSubmitResumeForm({ data, author, status }) {
  const [dropDownContent, setDropDownContent] = useState(data);
  const [fileData, setFile] = useState(null);
  const [noContent] = useState(204);

  function readFile(file) {
    const files = { base64: '', fileList: file };
    const reader = new FileReader();
    reader.onload = readSuccess;

    function readSuccess(evt) {
      files.base64 = evt.target.result;
      setFile(files);
    }

    reader.readAsDataURL(file);
  }

  const onfileChange = (e) => {
    if (e.target.files != null && e.target.files.length > 0)
      readFile(e.target.files[0]);
  };

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [State] = useState(
    status !== noContent && dropDownContent.State ? dropDownContent.State : null
  );
  const [Career] = useState(
    status !== noContent && dropDownContent.CareerLevel
      ? dropDownContent.CareerLevel
      : null
  );
  const [Education] = useState(
    status !== noContent && dropDownContent.Education
      ? dropDownContent.Education
      : null
  );
  const [authorContent] = useState(
    status !== noContent && author ? author : null
  );

  const submitData = async (submissionObj, fileObj) => {
    if (submissionObj !== undefined && submissionObj !== null) {
      const token = await createToken();
      await submitApplyOnlineResponse(submissionObj, token);
      await submitFileDataApplyOnline(fileObj, token);
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Address: values.address.trim(),
      City: values.city.trim(),
      State: values.state != null ? values.state.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      CareerLevel:
        values.careerLevel != null ? values.careerLevel.trim() : 'None',
      Education: values.education != null ? values.education.trim() : 'None',
      WorkAuthor: values.workAuthor != null ? values.workAuthor.trim() : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      File: fileData != null ? fileData.base64 : 'None',
      FileName: fileData != null ? fileData.fileList.name : 'None',
      FormUniqueId: ListOfFormUniqueData.apply_online.UniqueId,
      Catalog: ListOfFormUniqueData.apply_online.Catalog,
    };

    const formData = new FormData();
    formData.set('FirstName', values.firstName.trim());
    formData.set('LastName', values.lastName.trim());
    formData.set('File', fileData != null ? fileData.base64 : 'None');
    formData.set(
      'FileName',
      fileData != null ? fileData.fileList.name : 'None'
    );

    submitData(submissionObj, formData);
  }

  return (
    <div className="staffing-resume-form-container">
      <form className="resume-form" onSubmit={handleSubmit} noValidate>
        <p className="form-title">{title}</p>
        <Row>
          <Col xs={12} sm={6} className="left">
            <div className="personal-section">
              <div className="title">{contactTitle}</div>
              {errors.firstName && (
                <span className="help">{errors.firstName}</span>
              )}
              <TextInput
                placeholder="First Name"
                required
                type="text"
                name="firstName"
                onChange={handleChange}
                value={values.firstName || ''}
                autoComplete="off"
                className={`${errors.firstName && 'error'}`}
                maxlength={100}
              />
              {errors.lastName && (
                <span className="help">{errors.lastName}</span>
              )}
              <TextInput
                placeholder="Last Name"
                required
                type="text"
                name="lastName"
                onChange={handleChange}
                value={values.lastName || ''}
                autoComplete="off"
                className={`${errors.lastName && 'error'}`}
                maxlength={100}
              />
              {errors.email && <span className="help">{errors.email}</span>}
              <TextInput
                placeholder="Email"
                type="email"
                name="email"
                required
                onChange={handleChange}
                value={values.email || ''}
                autoComplete="off"
                className={`${errors.email && 'error'}`}
                maxlength={100}
              />
              {errors.phone && <span className="help">{errors.phone}</span>}
              <TextInput
                placeholder="Phone"
                autoComplete="off"
                className={` ${errors.phone && 'error'}`}
                type="number"
                name="phone"
                onChange={handleChange}
                value={values.phone || ''}
                required
                maxlength={100}
              />
              {errors.address && <span className="help">{errors.address}</span>}
              <TextInput
                placeholder="Address"
                required
                type="text"
                name="address"
                onChange={handleChange}
                value={values.address || ''}
                autoComplete="off"
                className={`${errors.address && 'error'}`}
                maxlength={300}
              />
              {errors.city && <span className="help">{errors.city}</span>}
              <TextInput
                placeholder="City"
                required
                type="text"
                name="city"
                onChange={handleChange}
                value={values.city || ''}
                autoComplete="off"
                className={`${errors.city && 'error'}`}
                maxlength={100}
              />
              <SelectList
                className="mb-2"
                defaultText="— Select a State —"
                name="state"
                onChange={handleChange}
                value={values.state}
              >
                {status !== noContent &&
                  State.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
              {errors.postalCode && (
                <span className="help">{errors.postalCode}</span>
              )}
              <TextInput
                placeholder="Postal Code"
                required
                name="postalCode"
                onChange={handleChange}
                value={values.postalCode || ''}
                autoComplete="off"
                className={`${errors.postalCode && 'error'}`}
                maxlength={100}
              />
            </div>
          </Col>
          <Col xs={12} sm={6} className="right">
            <div className="education-section">
              <div className="title">{educationTitle}</div>
              <SelectList
                className="mb-2"
                defaultText="— Select a Career Level —"
                required
                name="careerLevel"
                onChange={handleChange}
                value={values.careerLevel}
              >
                {status !== noContent &&
                  Career.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
              <SelectList
                className="mb-2"
                defaultText="— Select an Education —"
                required
                name="education"
                onChange={handleChange}
                value={values.education}
              >
                {status !== noContent &&
                  Education.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
            <div className="additional-section">
              <p className="title">{addTitle}</p>
              <p className="sub-title">{subTitle}</p>
              <SelectList
                className="mb-2"
                defaultText="— Yes —"
                required
                name="workAuthor"
                onChange={handleChange}
                value={values.workAuthor}
              >
                {status !== noContent &&
                  authorContent.map(({ WorkAuthorization_title }) => (
                    <option
                      key={WorkAuthorization_title}
                      value={WorkAuthorization_title}
                    >
                      {WorkAuthorization_title}
                    </option>
                  ))}
              </SelectList>
              <textarea
                name="information"
                placeholder="Provide any additional information"
                value={values.information}
                onChange={handleChange}
                maxLength={200}
              />
            </div>
            <div className="react-file-reader">
              <p className="title">{uploadTitle}</p>
              <input
                type="file"
                className="react-file-reader-input"
                multiple={false}
                onChange={onfileChange}
              />
              <div className="react-file-reader-button" />
            </div>
          </Col>
        </Row>

        <div className="recaptcha mb-2 mt-4">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default StaffingSubmitResumeForm;
