import React, { useEffect, useState } from 'react';
import './thankyou.scss';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomButton from '../../components/commons/custom-button/custom-button.component';

function ThankYouPage({
  title = 'Thank you for your submission!',
  subTitle = "If you've requested specific information. <br/> You'll receive a prompt response from Trinity staff member.",
  instructionMessage = 'Please click on the below button to continue.',
}) {
  const history = useHistory();
  const [paramTitle, setParamTitle] = useState(null);
  const [paramSubTitle, setParamSubTitle] = useState(null);
  const [paramInstructionMessage, setParamInstructionMessage] = useState(null);

  useEffect(() => {
    const { location } = history;
    const { state } = location;
    if (location && state) {
      setParamTitle(state.title);
      setParamSubTitle(state.subTitle);
      setParamInstructionMessage(state.instructionMessage);
    }
  }, [history.location.state]);

  const onClickBackToLogin = () => {
    history.push('/');
  };

  return (
    <div className="thankyou-display">
      <div className="trinity-container">
        <div className="outer-container">
          <FontAwesomeIcon icon={faSmile} className="smile-icon" />
          <hr />
          <span className="outer-font">{paramTitle || title}</span>
          <h2
            className="light-font"
            dangerouslySetInnerHTML={{ __html: paramSubTitle || subTitle }}
          />
          <br />
          <span className="light-font">
            {paramInstructionMessage || instructionMessage}
          </span>
          <CustomButton
            title="Go Back To Home"
            onClick={onClickBackToLogin}
            style={{ marginTop: 20, marginBottom: 20 }}
          />
        </div>
      </div>
    </div>
  );
}

export default ThankYouPage;
