import React, { useEffect, useState, useRef, createRef } from 'react';
import './carousel.styles.scss';

import Slider from 'react-slick';

import styles from '../../../css/global.variables.scss';

function Carousel({ children, unslickOnMobile, infinite }) {
  let mobileSettings = {};

  if (unslickOnMobile) {
    mobileSettings = {
      breakpoint: parseInt(styles.breakpointSm),
      settings: {
        unslick: true,
      },
    };
  }

  const settings = {
    arrows: true,
    infinite,
    speed: 500,
    initialSlide: 0,
    variableWidth: true,
    mobileFirst: true,
    responsive: [mobileSettings],
  };

  if (children) {
    return (
      <div
        className={`carousel
              ${unslickOnMobile ? 'unslickForMobile' : ''}
            `}
      >
        <Slider {...settings}>{children}</Slider>
      </div>
    );
  }
  return null;
}

Carousel.defaultProps = {
  infinite: true,
};

export default Carousel;
