import axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export class CourseServices {
  static getTrainingDataList(countryName, stateName, isBoth) {
    let preparedURL = `${ENDPOINTS.TRAINING_BY_LOCATION.GET_TRAINING_DATA}?countryName=${countryName}`;
    if (isBoth) {
      preparedURL = `${preparedURL}&stateName=${stateName}`;
    }

    return axios({
      method: 'GET',
      url: preparedURL,
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }

  static getCountryStateData() {
    return axios({
      method: 'GET',
      url: ENDPOINTS.TRAINING_BY_LOCATION.GET_COUNTRY_STATE_WISE_DATA,
    })
      .then((e) => e.data)
      .catch((e) => (e.response !== undefined ? e.response.data : null));
  }
}
