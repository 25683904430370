import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import Hero from '../../../components/commons/hero/hero.component';
import AboutLPGrid from '../../../components/service-branded/minnow/minnow-grid-cta/cta-grid.component';
import {
  serviceBrandEventList,
  serviceBrandNewsList,
  serviceBrandTrainingList,
} from '../../../redux/minnowdata/minnow.action';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import MinnowCtaGrid from './minnow-cta-grid-component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './minnow-page.scss';

function MinnowPage({
  page: { CategoryContent, MainContent, ServiceBrandAuthor },
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getMinnowData());
    dispatch(serviceBrandEventList(window.location.pathname));
    dispatch(
      serviceBrandTrainingList(window.location.pathname.replace('/', ''))
    );
    dispatch(serviceBrandNewsList(window.location.pathname, 'minnow'));
  }, []);

  const data = useSelector((state) => state.minnow);

  if (
    data != undefined &&
    data != null &&
    data.eventList != undefined &&
    data.eventList != null &&
    data.eventList.status == true &&
    data.newsList != undefined &&
    data.newsList != null &&
    data.newsList.status == true &&
    data.trainingList != undefined &&
    data.trainingList != null &&
    data.trainingList.status == true
  ) {
    return (
      <div className="minnow-lp">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          title=""
          description=""
          template="featured" // RK changed the standard to featured for testing the service Branding
          colorCategory="transparent"
          downArrow={false}
        />

        <div className="sm-up-trinity-container">
          {/* {CategoryContent ? (
                    <FilteredCtaGrid
                        bgImage={MainContent.BackgroundImage_src}
                        ctas={CategoryContent}
                        onlySmallCtas={true}
                        optionTitle={"— Select Consulting Type —"}
                    />
                ) : (
                    <h2 className="xs-only-trinity-container module-spacer">
                        No category content available
                    </h2>
                )} */}

          {/* <MinnowCtaGrid
                    ctas={data.minnowData.CategoryContent}
                    onlySmallCtas={false}
                    optionTitle={"— Select Consulting Type —"}
                    activeStarterSection={true}
                /> */}

          {/* styling is already set to not display the controls */}
          <ContactCta
            LeftSideContent={MainContent.LeftSideContent}
            LeftSideLink={MainContent.LeftSideLink}
            LeftSideLinkText={MainContent.LeftSideLinkText}
            CenterContent={MainContent.CenterContent}
            CenterPhoneNumber={MainContent.CenterPhoneNumber}
            data={MainContent}
            RightSideContent={MainContent.RightSideContent}
            RightSidePhoneNumber={MainContent.RightSidePhoneNumber}
          />

          <div className="first-content">
            <p className="title-custom">{MainContent.ContentTitle}</p>
            <div
              className="more-content"
              dangerouslySetInnerHTML={{
                __html: MainContent.Content,
              }}
            />
          </div>

          <p className="title-custom">Services</p>

          {CategoryContent && CategoryContent.length > 0 ? (
            <MinnowCtaGrid ctas={CategoryContent} onlySmallCtas={false} />
          ) : (
            <h2 className="xs-only-trinity-container module-spacer">
              No category content available
            </h2>
          )}

          {data.trainingList.payload.length > 0 && (
            <div className="courses-container">
              <Row style={{ padding: 10 }}>
                <Col className="top-heading" xs={12} md={4} lg={2}>
                  <h4>Upcoming Training</h4>
                </Col>

                <div className="col">
                  {data.trainingList.payload.map((element) => (
                    <div className="row outer-row">
                      <div className="col-xs-12 col-md-8 col-lg-8 px-1">
                        <h6>{element.DisplayName}</h6>
                      </div>

                      <div className="col-xs-12 col-md-2 col-lg-2 px-1">
                        <h6>
                          {element.TrainingDate &&
                            moment(element.TrainingDate).format('DD MMM YYYY')}
                        </h6>
                      </div>

                      <div className="col-xs-12 col-md-2 col-lg-2">
                        <h6 className="light-blue">
                          <a
                            href={element.TrainingUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn More
                          </a>
                        </h6>
                      </div>
                    </div>
                  ))}

                  <div className="row outer-row">
                    <div className="col-xs-12 col-md-10 col-lg-10" />

                    <div className="col-xs-12 col-md-2 col-lg-2">
                      <h6 className="light-blue">
                        <a href="/training" target="_blank">
                          View All Training
                        </a>
                      </h6>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          )}

          {data.newsList.payload.length > 0 && (
            <div className="courses-container">
              <Row style={{ padding: 10 }}>
                <Col className="top-heading" xs={12} md={4} lg={2}>
                  <h4>Related News</h4>
                </Col>

                <div className="col">
                  {data.newsList.payload.map((element) => (
                    <div className="row outer-row">
                      <div className="col-xs-12 col-md-2 col-lg-2">
                        <h6>{}</h6>
                      </div>

                      <div className="col-xs-12 col-md-7 col-lg-7">
                        <h6>{element.Title}</h6>
                      </div>

                      <div className="col-xs-12 col-md-2 col-lg-2">
                        <h6>
                          {element.strPublishedDate &&
                            moment(element.strPublishedDate).format(
                              'DD MMM YYYY'
                            )}
                        </h6>
                      </div>

                      <div className="col-xs-12 col-md-1 col-lg-1">
                        <h6 className="light-blue">
                          <a
                            href={element.Url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn More
                          </a>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          )}

          {data.eventList.payload.Contentlength > 0 && (
            <div className="courses-container">
              <Row style={{ padding: 10 }}>
                <Col className="top-heading" xs={12} md={4} lg={2}>
                  <h4>Upcoming Events</h4>
                </Col>

                <div className="col">
                  {data.eventList.payload.map((element, index) => (
                    <div className="row outer-row" key={index}>
                      <div className="col-xs-12 col-md-2 col-lg-2">
                        <h6>{`${element.City}, ${element.State}`}</h6>
                      </div>

                      <div className="col-xs-12 col-md-7 col-lg-7">
                        <h6>{element.PresentationIntroText}</h6>
                      </div>

                      <div className="col-xs-12 col-md-2 col-lg-2">
                        <h6>
                          {element.strEventDate &&
                            moment(element.strEventDate).format('DD MMM YYYY')}
                        </h6>
                      </div>

                      <div className="col-xs-12 col-md-1 col-lg-1">
                        <h6 className="light-blue">
                          <a
                            href={element.RouteUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Learn More
                          </a>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </Row>
            </div>
          )}

          <AboutLPGrid data={ServiceBrandAuthor} title="Leadership " />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default MinnowPage;
