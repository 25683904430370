import React from 'react';
import './location-news-listing.data';
import './location-news-listing.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectNewsActionFilter } from '../../../../redux/news/news.action';
import { trinityTrainingDateFormatter } from '../../../../utilities/CommonUtilities';

// RP # TASK 100 Individual Location
function LocationNewsListing({ data, newsTitle, isOffice }) {
  const newsListing = data;
  const newsContentTitle = newsTitle;
  const dispatch = useDispatch();
  const history = useHistory();

  const plocation = useSelector(({ news }) => news.locationFilter);
  const location =
    newsListing != null && newsListing.length > 0
      ? { Name: newsListing[0].stateName, Id: newsListing[0].stateId }
      : null;

  const newsclick = () => {
    // if (isOffice === true)
    //   dispatch(selectNewsLocationActionFilter(category != null && category.length > 0 ? category : []));
    // else
    dispatch(selectNewsActionFilter(location, plocation, 'Location'));
    history.push({ pathname: '/news' });
  };

  return (
    <div className="location-news-container">
      <div className="inner-news-container">
        <p className="title">{newsContentTitle}</p>
        {newsListing &&
          newsListing.map((item, i) => (
            <div key={i} className="widgetBox-wrapper">
              <div className="widget-wrapper">
                <div className="content">
                  <p className="date">
                    {trinityTrainingDateFormatter(item.strPublishedDate)}
                  </p>
                  <a href={item.Url} className="inside-link">
                    {item.Title}
                  </a>
                  {/* <p className="content" dangerouslySetInnerHTML={{ __html: truncatetext(item.Content,300) }}></p> */}
                </div>
              </div>
            </div>
          ))}
      </div>
      <a className="bottom-link newspointer" onClick={() => newsclick()}>
        More News &gt;
      </a>
    </div>
  );
}

export default LocationNewsListing;
