import React, { useEffect, useState } from 'react';
import './training-lp-2.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Hero from '../../../components/commons/hero/hero.component';
import SearchWidget from '../../../components/training/search-widget/search-widget.component';
import WidgetBox from '../../../components/training/widgetBox/widget-box.component';
import ImageContent from '../../../components/training/image-content/image-content.component';
import heroImageSrc from '../../../images/training/training_lp_hero.png';

import {
  getTrainingContent,
  getCategoryProducts,
  getCategory,
  getCategories,
} from '../../../js/api/_training_api';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import useDropdownContent from '../../../js/custom-hooks/useDropdownContent';
import { getUserStates } from '../../../js/api/dropdowns/_get-user-states';

// JP #Task 41
function TrainingLP2({ page: { modules } }) {
  const [content, setContent] = useState({});
  const [categories, setCategories] = useState([]);

  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getTrainingContent();
      if (response) setContent(response.MainContent);
    };

    const fetchCategoryProducts = async () => {
      const response = await getCategoryProducts();
      if (response) setCategories(response);
    };

    const fetchStates = async () => {
      const res = await getUserStates();
      if (res) setStates(res);
    };

    // const fetchCategories = async () => {
    //   const response = await getCategories();
    //   if (response) setCategories(response[0].Categories);
    // };
    // fetchStates();
    fetchData();
    // fetchCategories();
    fetchCategoryProducts();
  }, []);

  if (Object.keys(content).length === 0 || categories.length === 0) {
    return <Loader />;
  }

  return (
    <div className="training_lp_2 training">
      {/* <Hero
        src={content?.BackgroundImage_src}
        alt={modules.hero_alt}
        template={"landing"}
        // search={true}
        title={content?.Title}
        description={content?.HeaderText}
        downArrow={true}
      /> */}
      <Hero
        src={content.HeaderImage_src ? content.HeaderImage_src : heroImageSrc}
        alt={modules.hero_alt}
        mobileImgSrc={content?.HeaderMobileImage_src}
        title={content?.Title}
        template="landing"
        search={false}
        description={content?.HeaderText}
        link=""
      />
      <div className="trinity-container">
        <div className="top-container">
          <Row>
            <ImageContent content={content} />
            <Col md={4} sm={6}>
              <SearchWidget title="Find the training you're looking for:" />
            </Col>
          </Row>
        </div>
        <Row>
          {Object.keys(content).length !== 0 && categories.length > 0 ? (
            <div className="wrapper">
              <WidgetBox content={content} categories={categories} />
              {/* <hr className='widget-hr' /> */}
              <div className="second-widget">
                <WidgetBox />
              </div>
            </div>
          ) : null}
        </Row>
      </div>
    </div>
  );
}

export default TrainingLP2;
