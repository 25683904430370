export const title =
  'Please complete the form below to schedule a complimentary, live online demonstration. ';
export const blurb =
  'Our online demos show you a variety of features and functions of the software, including model setup and analysis of model results. During the demo you will also have a chance to ask us any questions about our BREEZE software.';

export const informationBlurb = 'Your Information';
export const companyBlurb = 'Company Information';
export const productBlurb = 'Product Information';
export const backLink = '< Go Back';
export const link = 'Request Demo';
