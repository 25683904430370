import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewStaffingSubmitResumeLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-staffing-submit-resume-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-staffing-submit-resume-content-component')
  );

  return (
    <div className="individual-container">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} />
      </Suspense>
    </div>
  );
}

export default NewStaffingSubmitResumeLandingPage;
