/** *****************************************************
 ********************************************************
 **
 **  Api request for page data
 **
 ********************************************************
 ******************************************************* */

import { CUSTOM_SITE_MAP_JSON_URL, ENDPOINTS } from './_config.js';
import { CUSTOM_SITE_MAP } from '../../routes/cutomSiteMap';

// this is for prototyping -- eventually it will get deleted as we connect to sitefinity
export async function getPageUrlFromSection(section) {
  if (section !== '') return JSON.parse(section);
}

// this will replace the above function for getting inital urls
async function getPageUrlsFromSitefinity(pagesEndpoint) {
  try {
    const request = await fetch(CUSTOM_SITE_MAP_JSON_URL);
    return request.json();
  } catch (error) {
    return CUSTOM_SITE_MAP;
  }
}

export async function getAllPageUrls() {
  const requestAllPages = await Promise.all([
    getPageUrlFromSection(ENDPOINTS.HOMEPAGE),
    getPageUrlFromSection(ENDPOINTS.LOCATIONS),
    getPageUrlFromSection(ENDPOINTS.RESOURCES),
    getPageUrlFromSection(ENDPOINTS.CONSULTING),
    getPageUrlFromSection(ENDPOINTS.ENGINEERING),
    getPageUrlFromSection(ENDPOINTS.SCIENCE),
    getPageUrlFromSection(ENDPOINTS.GENERAL),
    getPageUrlFromSection(ENDPOINTS.INDUSTRIES),
    getPageUrlFromSection(ENDPOINTS.SOFTWARE),
    getPageUrlFromSection(ENDPOINTS.STAFFING),
    getPageUrlFromSection(ENDPOINTS.TECHNOLOGY),
    getPageUrlFromSection(ENDPOINTS.TRAINING),
    getPageUrlFromSection(ENDPOINTS.ABOUT),
    getPageUrlFromSection(ENDPOINTS.SERVICEBRANDED),

    getPageUrlsFromSitefinity(ENDPOINTS.SITEFINITYCONNECTION), // this is connected to sitefinity -- only for testing / dev purposes
  ]);

  const allPageData = requestAllPages.flat(1);

  localStorage.setItem('routes', JSON.stringify(allPageData));
  localStorage.setItem('routes-time-stamp', Date.now());

  return allPageData;
}

// this function will probably get replaced after api updates
export async function getPageDataByApiUrl(apiUrl) {
  let pageResponseData;
  const request = await fetch(`${ENDPOINTS.BASE_URL}${apiUrl}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => {
      pageResponseData = data;
    })
    .catch((error) => {});

  return pageResponseData;
}
