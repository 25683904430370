export const ListOfFormUniqueData = {
  ContactUs: {
    UniqueId: '5C46AACC-62D0-48F0-AA4B-709A3FAAA25E',
    Catalog: 'Trinity',
  },
  Subscribe: {
    UniqueId: '5594E0A7-555B-4938-B124-B45726EACAC1',
    Catalog: 'Trinity',
  },
  request_key: {
    UniqueId: 'B0FEEC85-226E-4C23-AC54-C0B7B808270F',
    Catalog: 'Breeze',
  },
  software_demo: {
    UniqueId: '2D1DF213-6935-483F-A4D4-C0C3DCBAD90B',
    Catalog: 'Breeze',
  },
  apply_online: {
    UniqueId: '74703B5C-BA65-48DB-B8DE-8096ED3BB3A6',
    Catalog: 'Trinity',
  },
  request_service_quote: {
    UniqueId: '1440A34B-8C35-41E0-902B-1D73E0302287',
    Catalog: 'Breeze',
  },
  submit_job_request: {
    UniqueId: '0DB1BF34-C72A-4DFF-A1E2-F776D19EB73D',
    Catalog: 'Trinity',
  },
  generate_software_key: {
    UniqueId: '531F4AFC-75FE-450B-9C04-5FA4B9D70608',
    Catalog: 'Breeze',
  },
  webinar_signup: {
    UniqueId: '812AF6C1-3AC8-433F-AF6A-46A6B4599D8D',
    Catalog: 'Trinity',
  },
  // software_demo : { UniqueId : '2298476D-96D0-4934-BCB4-69B5A652321A', Catalog : 'Breeze' } ,
  request_training_quote: {
    UniqueId: '0FCF47AE-FAB5-435C-8922-FAEA5F2239AC',
    Catalog: 'Breeze',
  },
  report_website_problem: {
    UniqueId: '45783781-70EF-4C1C-A6CC-C647E2C8890B',
    Catalog: 'Trinity',
  },
  submit_remote_modeling_form: {
    UniqueId: 'EF3E04BD-17D5-4C3F-B329-C8C0D3E047F0',
    Catalog: 'Breeze',
  },
  request_data_quote: {
    UniqueId: '970B9A58-4E02-4207-86EC-5B46694119D5',
    Catalog: 'Breeze',
  },
  request_software_quote: {
    UniqueId: '96AF6B63-7CAF-45E2-B54F-2D89CF8C7CE8',
    Catalog: 'Breeze',
  },
  request_trial: {
    UniqueId: '5F8262F5-01FC-4344-9482-42FF5CDBBBF8',
    Catalog: 'Breeze',
  },
  create_account: {
    UniqueId: '57C4139C-3BF5-4F15-BAC9-AE9BCA8AC667',
    Catalog: 'Trinity',
  },
  apply_for_job: {
    UniqueId: 'BB55D523-4C1A-479A-BBC5-00FEB168205D',
    Catalog: 'Trinity',
  },
  job_alert_sign_up: {
    UniqueId: 'c37a6798-b2c7-474f-822e-46d342e51997',
    Catalog: 'Trinity',
  },
  regulatory_agency_program: {
    UniqueId: '078ebbe0-89d9-438f-9685-7d5c7fb71533',
    Catalog: 'Breeze',
  },
};
