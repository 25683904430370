import React, { useState } from 'react';
import CloseBtn from '../../commons/helpers/close-btn/close-btn.component';
import { title, filterName } from './active-search-filter.data.js';
import './active-search-filter.styles.scss';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// RK Task#104 Training Secondary Change
// JP #Task 41
function SearchFilter({ filter }) {
  const searchFields = useSelector((state) => state.training);

  // We are not maintaining search for every redux state, So need to check for null & undefined
  const [searchItems, setsearchItems] = useState(
    searchFields.search && searchFields.search[0]
  );
  // const { ResouceType, Industry, Location, Software } = searchFields;
  const { catName } = filter;
  const history = useHistory();

  const handleClick = (e) => {
    localStorage.clear('category');
    history.goBack();
  };

  return (
    <div className="search-filter-container">
      <h3 className="active-filters-title">Active Search Filters:</h3>
      <ul>
        <li className="list-item" onClick={() => handleClick()}>
          <span className="flist-item-text">
            {filter != null && filter.Name != null ? filter.Name : catName}{' '}
          </span>
          <CloseBtn className="ml-1 ml-sm-4" />
        </li>
        {searchFields.search &&
        searchFields.search[0] &&
        searchFields.search[0].length > 0
          ? searchFields.search[0].map((item, i) => (
              <li className="list-item" onClick={() => handleClick()}>
                <span className="flist-item-text">{item.value} </span>
                <CloseBtn className="ml-1 ml-sm-4" />
              </li>
            ))
          : null}
      </ul>
    </div>
  );
}

export default withRouter(SearchFilter);
