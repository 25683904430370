import React, { useState } from 'react';
import './software-filter-control.scss';

import { renderUniqueId } from '../../../../js/helpers/_render-unique-id';
import SoftwareFilterButton from '../filter-button/software-filter-button.component';

const SoftwareFilterControls = React.memo((props) => {
  const { filter, categories, title } = props;

  const [categoriesArray, setCategoriesArray] = useState(
    categories ? Object.entries(categories) : null
  );

  if (categoriesArray) {
    return (
      <div className="software-filter-control">
        <div className="trinity-container relative fluid">
          <select
            onChange={(e) => filter(e)}
            name="filter-select"
            id="filter-select"
          >
            <option value="" selected disabled hidden>
              {title}
            </option>
            <option data-category="all" value="all">
              All
            </option>
            {categoriesArray
              .sort((a, b) => {
                const cId1 = a[1];
                const cId2 = b[1];
                return cId1.localeCompare(cId2);
              })
              .map(([category, categoryId]) => (
                <option
                  data-category={categoryId}
                  value={categoryId}
                  key={renderUniqueId(categoryId)}
                >
                  {category}
                </option>
              ))}
          </select>
          <span className="arrow d-sm-none" />
        </div>

        <div className="filter-buttons d-none d-sm-flex flex-wrap">
          <SoftwareFilterButton
            data-category="all"
            onClick={(e) => filter(e)}
            colorCategory="all"
          >
            All
          </SoftwareFilterButton>
          {categoriesArray.map(([category, categoryId], i) => (
            <SoftwareFilterButton
              data-category={categoryId}
              key={renderUniqueId(categoryId)}
              onClick={(e) => filter(e)}
              colorCategory={categoryId}
            >
              {category}
            </SoftwareFilterButton>
          ))}
        </div>
      </div>
    );
  }

  return null;
});

export default SoftwareFilterControls;
