import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewTechnologyIndividualCategoryLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-technology-individual-category-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-technology-individual-category-content-component')
  );

  return (
    <div
      className={`technology technology-individual-category ${section || ''}`}
    >
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          section={section}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} section={section} />
      </Suspense>
    </div>
  );
}

export default NewTechnologyIndividualCategoryLandingPage;
