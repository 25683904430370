import React, { useEffect, useState } from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import SoftwareRequestTrialForm from '../../../components/software/request-a-trial-form/request-a-trial.component';
import { getSoftwareRequestTrialPageData } from '../../../js/api/softwareForm/_softwareRequestTrail.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function SoftwareTrial({ page: { modules } }) {
  // #RP - Task 67 - Software request trial
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getSoftwareRequestTrialPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country !== undefined)
  ) {
    return (
      <div className="software-request-a-trial-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="policies"
          description={modules.hero.hero_description}
        />
        <div className="trinity-container">
          <SoftwareRequestTrialForm
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default SoftwareTrial;
