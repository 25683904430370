import React, { useEffect, useState } from 'react';
import { progressData } from './progress.data';
import './shopping-cart-progress.styles.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

function ShoppingCartProgressBar() {
  const location = useLocation();
  const [progressStep, setprogressStep] = useState(null);
  const [isOnDemandOnly, setIsOnDemandOnly] = useState(false);

  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );

  const cartSteps = useSelector(
    ({ cart }) => cart.allowcartsteps,
    shallowEqual
  );
  // const [cartSteps, setCartSteps] = useState(cartCount > 0 ? cartitems.allowcartsteps : 1);

  useEffect(() => {
    if (cartitems !== undefined && cartitems.ItemsCount > 0) {
      const hasAttendees = cartitems.IsRegisterAttendeesRequired;

      const checkForOnDemandOnly = cartitems?.OrderLines.filter(
        (e) => e.Price === 0 && e.SessionTypeName === 'OnDemand'
      ).length;
      // let checkForOnDemandOnly = cartitems?.OrderLines.filter(e => e.Price === 0 && (e.SessionTypeName === "OnDemand" || e.SessionTypeName.toLowerCase() === "webinar")).length
      const isOnDemandOnlyAvailable =
        checkForOnDemandOnly > 0 &&
        checkForOnDemandOnly === cartitems?.OrderLines.length;
      setIsOnDemandOnly(isOnDemandOnlyAvailable);

      if (isOnDemandOnlyAvailable) {
        const newprogressData = progressData.filter(
          (item) =>
            !['Attendees', 'Review', 'Payment'].includes(item.progressName)
        );
        setprogressStep(newprogressData);
      } else if (!hasAttendees) {
        const newprogressData = progressData.filter(
          (item) => item.progressName !== 'Attendees'
        );
        setprogressStep(newprogressData);
      } else {
        setprogressStep(progressData);
      }
    }
  }, [
    cartitems !== undefined && cartitems.ItemsCount > 0
      ? cartitems.ItemsCount
      : 0,
    cartitemimpersonate.memberId,
    cartSteps,
  ]);

  const customLength = progressStep && progressStep.length;

  if (cartitems !== undefined && cartitems.ItemsCount > 0 && progressStep) {
    return (
      <div className="progress-container">
        {progressStep.map((name, index) => (
          <div
            className={`
                                    ${
                                      isOnDemandOnly
                                        ? 'progress-step-custom-for-on-demand'
                                        : customLength % 2 === 0
                                        ? 'progress-step-custom'
                                        : 'progress-step'
                                    }
                                    ${
                                      name.progressName
                                        ? name.link === location.pathname ||
                                          index <=
                                            progressData.findIndex(
                                              (e) =>
                                                e.link === location.pathname
                                            )
                                          ? 'fill cart_link_active'
                                          : 'fill '
                                        : ''
                                    }`}
          >
            {name.id <= cartSteps ? (
              <Link to={name.id <= cartSteps ? name.link : '/'}>
                <span>{name.icon}</span>{' '}
                <span className="sm-d-none">{name.progressName}</span>
              </Link>
            ) : (
              <>
                <span>{name.icon}</span>{' '}
                <span className="sm-d-none">{name.progressName}</span>
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
  return null;
}

export default ShoppingCartProgressBar;
