import React from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/about/policiesHero.svg';
import ImageWithOverlayTextListing from '../../../components/about/image-with-overlay-text-listing/image-with-overlay-text-listing.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './policies.styles.scss';

// RP #Task 92 Policies

function Policies({ page: { MainContent, CategoryContent } }) {
  if (
    MainContent &&
    MainContent.Title != null &&
    CategoryContent &&
    CategoryContent != null
  ) {
    return (
      <div className="policies policies-container">
        <Hero
          src={MainContent.HeaderImage_src ? MainContent.HeaderImage_src : ''}
          mobileImgSrc={
            MainContent.HeaderMobileImage_src
              ? MainContent.HeaderMobileImage_src
              : ''
          }
          alt={MainContent.HeaderImage_alt ? MainContent.HeaderImage_alt : ''}
          title={
            MainContent.Title
              ? MainContent.Title
              : 'Please contact admin for page content'
          }
          description={MainContent ? MainContent.IntroText : ''}
          template="landing"
          colorCategory="policies"
        />
        <div className="sm-up-trinity-container">
          <ImageWithOverlayTextListing
            ctas={CategoryContent}
            colorCategory="policies"
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default Policies;
