import React, { useEffect, useState } from 'react';
import './training-tertiary.styles.scss';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';
import Hero from '../commons/hero/hero.component';
import ContentWell from '../commons/content-well/content-well.component';
import SingleWidget from '../commons/widgets/single-widget/single-widget.component';
import ProductLayout from '../training/product-layout/product-layout.component';
import PartnerLayout from '../training/partner-layout/partner-layout.component';

import heroImageSrc from '../../images/training/training-tertiary-hero.png';
import WidgetPdf from '../../images/training/pdf.svg';
import { getSingleProduct } from '../../js/api/_training_api';
import Loader from '../commons/helpers/loader/loader.component';

function TrainingThirdLevel(props) {
  const [product, setProduct] = useState(null);
  const [metaData, setMetaData] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async (id) => {
      const response = await getSingleProduct(id);

      setProduct(response);

      setMetaData({
        metaTitle: response.MetaTitle,
        metaDescription: response.MetaDescription,
      });
    };

    fetchProductDetails(props.productId);
  }, []);

  if (product === null) return <Loader />;

  return (
    <div className="training-tertiary training">
      {metaData && (
        <Helmet>
          <meta charSet="utf-8" />
          {metaData.metaDescription && (
            <meta name="description" content={metaData.metaDescription} />
          )}
          {metaData.metaTitle && <title>{metaData.metaTitle}</title>}
        </Helmet>
      )}
      <Hero
        src={heroImageSrc}
        alt={`${product?.Title} image`}
        template="standard"
        title={product?.Title}
        colorCategory="train"
      />
      <div className="trinity-container module-spacer">
        <Row>
          <Col sm={6} md={7}>
            {/* <ContentWell content={product && product.ShortDescription !== undefined ? product.ShortDescription : null}/> */}
            <ContentWell
              content={
                product && product.LongDescription !== undefined
                  ? product.LongDescription
                  : null
              }
            />
            <ContentWell
              content={
                product && product.Agenda !== undefined ? product.Agenda : null
              }
            />
          </Col>
          <Col sm={6} md={5}>
            <div className="widget-wrapper">
              <SingleWidget
                src={WidgetPdf}
                alt="pdf-image"
                singleLinkText="Download the Course Agenda:"
                singleLinkContent="Air Compliance Auditing for Industrial Facilities"
                singleLinkSub="Download Adobe Acrobat Reader"
              />
            </div>

            {/* <ProductLayout product={product} /> */}
            <ProductLayout product={product} />
            <PartnerLayout product={product} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default TrainingThirdLevel;
