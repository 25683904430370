import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';

export default function removeStyleFromHTMLString(actualString) {
  const regex1 = /<\/?span[^>]*>/g;
  const regex2 = 'style=("|\')[^("|\')]*("|\')';
  const regex3 = '<font[^><]*>|<.font[^><]*>';
  const newString = new RegExp(`${regex1}|${regex2}|${regex3}`, 'g');
  const newString2 =
    actualString.length > 0 &&
    actualString &&
    actualString.replace(newString, '');
  return newString2;
}
export const trinitySearchTrainingDateFormatter = (dateValue) => {
  if (dateValue) {
    return moment.utc(dateValue.toLocaleString()).format('DD MMM YYYY');
  }
  return '';
};
export function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (
      !Object.prototype.hasOwnProperty.call(a, key) ||
      !Object.prototype.hasOwnProperty.call(b, key)
    ) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export const tabListConstant = {
  all: 'All',
  services: 'Services',
  trainings: 'Training',
  news: 'News',
  software: 'Software',
  resources: 'Resources',
};

// RP - ADVANCED SEARCH FILTERS
export const trinitySearchRelevanceChanges = [
  {
    key: 'DatePublished desc',
    value: 'Date Published',
  },
  {
    key: 'Title',
    value: 'Title',
  },
];

export const generalSearchRelevance = [
  {
    key: 'DatePublished desc',
    value: 'Date Published',
  },
  {
    key: 'Title',
    value: 'Title',
  },
];

export const trainingSearchRelevance = [
  {
    key: 'DatePublished asc',
    value: 'Training Date',
  },
  {
    key: 'DisplayName',
    value: 'Training Title',
  },
];

export const safeBridgeRelevance = [
  {
    key: 'DatePublished asc',
    value: 'Date Published',
  },
  {
    key: 'Title',
    value: 'Title',
  },
];

export const processParameterAndSendQuery = (
  staticGeneralData,
  searchedData
) => {
  let dataObject = staticGeneralData;

  if (searchedData?.sbt?.length > 0) {
    dataObject = { ...dataObject, search: searchedData?.sbt };
  } else {
    dataObject = { ...dataObject, search: '*' };
  }

  dataObject = { ...dataObject, skip: searchedData?.pg * 10 - 10 };

  if (searchedData?.drp?.length > 0) {
    dataObject = {
      ...dataObject,
      orderby: searchedData?.drp === 'relevance' ? '' : searchedData?.drp,
    };
  } else {
    dataObject = { ...dataObject, orderby: '' };
  }

  if (searchedData?.ds?.length > 0) {
    const newArray = searchedData?.ds.map((outerElement, index) => {
      const key = outerElement.split('|')[0].toString();
      const value = outerElement.split('|')[1].toString();

      if (searchedData?.ds.length - 1 === index) {
        if (
          key === 'ByCategory' ||
          key === 'ByIndustry' ||
          key === 'RegulatoryAgency' ||
          key === 'BusinessUnit'
        ) {
          return `(${key}/any(t: t eq '${value}'))`;
        }
        return `(${key} eq '${value}')`;
      }
      if (
        key === 'ByCategory' ||
        key === 'ByIndustry' ||
        key === 'RegulatoryAgency' ||
        key === 'BusinessUnit'
      ) {
        return `(${key}/any(t: t eq '${value}')) and `;
      }
      return `(${key} eq '${value}') and `;
    });

    let dynamicString = newArray.toString().split(',').join('');

    if (dynamicString.length === 0) {
      dynamicString = `DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    } else if (dynamicString.trim().endsWith('and')) {
      dynamicString = `${dynamicString} DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    } else {
      dynamicString = `${dynamicString} and DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    }
    dataObject = { ...dataObject, filter: dynamicString };
  }
  console.log(JSON.stringify(dataObject, null, 2));

  return dataObject;
};

export const processTrainingParameterAndSendQuery = (
  staticGeneralData,
  searchedData
) => {
  // RP - SD39 : Training page- Advanced search Filter
  let dataObject = staticGeneralData;

  if (searchedData?.sbt?.length > 0) {
    const newString = searchedData?.sbt;
    dataObject = { ...dataObject, search: newString };
  } else {
    dataObject = { ...dataObject, search: '*' };
  }

  dataObject = { ...dataObject, skip: searchedData?.pg * 10 - 10 };

  if (searchedData?.drp?.length > 0) {
    dataObject = {
      ...dataObject,
      orderby:
        searchedData?.drp === 'relevance' && searchedData?.sbt?.length > 0
          ? ''
          : searchedData?.drp,
    };
  } else {
    dataObject = {
      ...dataObject,
      orderby: searchedData?.sbt?.length > 0 ? '' : 'DatePublished asc',
    };
  }

  let dynamicString = '';
  if (searchedData?.ds?.length > 0) {
    const newArray = searchedData?.ds.map((outerElement, index) => {
      const key = outerElement.split('|')[0];
      const value = outerElement.split('|')[1];

      if (searchedData?.ds.length - 1 === index) {
        if (
          key === 'ByCategory' ||
          key === 'ByIndustry' ||
          key === 'RegulatoryAgency' ||
          key === 'BusinessUnit'
        ) {
          return `(${key}/any(t: t eq '${value}'))`;
        }
        return `(${key} eq '${value}')`;
      }
      if (
        key === 'ByCategory' ||
        key === 'ByIndustry' ||
        key === 'RegulatoryAgency' ||
        key === 'BusinessUnit'
      ) {
        return `(${key}/any(t: t eq '${value}')) and `;
      }
      return `(${key} eq '${value}') and `;
    });

    dynamicString = newArray.toString().split(',').join('');
    dataObject = { ...dataObject, filter: dynamicString };
  }

  if (searchedData?.rs?.min.length > 0) {
    if (dynamicString.length === 0) {
      dynamicString = `DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    } else if (dynamicString.trim().endsWith('and')) {
      dynamicString = `${dynamicString} DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    } else {
      dynamicString = `${dynamicString} and DatePublished ge ${moment(
        Number(searchedData?.rs.min)
      ).toISOString()} and DatePublished le ${moment(
        Number(searchedData?.rs.max)
      ).toISOString()}`;
    }
    dataObject = { ...dataObject, filter: dynamicString };
  }

  if (searchedData?.prs?.min.length > 0) {
    if (dynamicString.length === 0) {
      dynamicString = `Amount ge ${Number(
        searchedData?.prs.min
      )} and Amount le ${Number(searchedData?.prs.max)}`;
    } else if (dynamicString.trim().endsWith('and')) {
      dynamicString = `${dynamicString} Amount ge ${Number(
        searchedData?.prs.min
      )} and Amount le ${Number(searchedData?.prs.max)}`;
    } else {
      dynamicString = `${dynamicString} and Amount ge ${Number(
        searchedData?.prs.min
      )} and Amount le ${Number(searchedData?.prs.max)}`;
    }
    dataObject = { ...dataObject, filter: dynamicString };
  }

  return dataObject;
};

export const sendToURL = (historyObject, searchObject) => {
  const url = qs.stringify(searchObject);
  window.history.replaceState(
    null,
    '',
    `${historyObject?.location?.pathname}?${url}`
  );
};

export const createURLAndReturn = (searchObject) => qs.stringify(searchObject);

export const trinityTrainingDateFormatter = (dateValue) => {
  if (dateValue) {
    return moment(dateValue).format('DD MMM YYYY');
  }
  return '';
};

// Only for array
export const groupBy = (data, key) =>
  _(data)
    .groupBy((item) => item[key])
    .sortBy((group) => data.indexOf(group[0]))
    .value();
