import React from 'react';
import './product-layout.styles.scss';

import ProductItem from '../product-item/product-item.component';
import ProductWebinarItem from '../product-item/product-item-webinar.component';

// JP #Task
function ProductLayout({ product }) {
  const productList = [];
  let template;
  const date = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

  console.log('00000000');
  console.log(date);

  if (product.Variants && product.Variants.length) {
    const filtereddata = product.Variants.filter(
      (x) => new Date(x.StartDate) <= new Date(date)
    );
    const pdata = { ...product };
    pdata.Variants = filtereddata;

    const hasProductVariant = pdata.Variants.some(
      (variant) => variant.SessionTypeName.toLowerCase() == 'classroom'
    );

    if (hasProductVariant) {
      template = <ProductItem product={pdata} />;
    }
    const hasWebinarProductVariant = pdata.Variants.some(
      (variant) => variant.SessionTypeName.toLowerCase() == 'webinar'
    );
    const hasOnDemandProductVariant = product.Variants.some(
      (variant) => variant.SessionTypeName.toLowerCase() == 'ondemand'
    );
    const hasELearningProductVariant = product.Variants.some(
      (variant) => variant.SessionTypeName.toLowerCase() == 'elearning'
    );
    const hasSeminarProductVariant = pdata.Variants.some(
      (variant) => variant.SessionTypeName.toLowerCase() == 'seminar'
    );
    const hasCourseSubscriptionProductVariant = pdata.Variants.some(
      
      (variant) => variant.SessionTypeName.toLowerCase() == 'coursesubscription'
    );

    if (hasWebinarProductVariant) {
      productList.push(<ProductWebinarItem title="Webinar" product={pdata} />);
    }
    if (hasCourseSubscriptionProductVariant) {
      productList.push(
        <ProductWebinarItem title="Course Subscription" product={pdata} />
      );
    }
    if (hasOnDemandProductVariant) {
      productList.push(
        <ProductWebinarItem title="OnDemand" product={product} />
      );
    }
    if (hasELearningProductVariant) {
      productList.push(
        <ProductWebinarItem title="eLearning" product={product} />
      );
    }
    if (hasSeminarProductVariant) {
      productList.push(<ProductWebinarItem title="Seminar" product={pdata} />);
    }
  }
  return (
    <div className="product-layout">
      {template}
      <br />
      {productList.map((e) => (
        <>
          <br />
          {e}
        </>
      ))}
    </div>
  );
}

export default ProductLayout;
