// JP #Task 41
export const dates = [
  // {Text:'All Dates',Value:'All Dates'},
  { Text: 'Next 30 Days', Value: 'Next 30 Days' },
  { Text: 'Next 60 Days', Value: 'Next 60 Days' },
  { Text: 'Next 90 Days', Value: 'Next 90 Days' },
  { Text: 'Next 6 Months', Value: 'Next 6 Months' },
  { Text: 'Next 9 Months', Value: 'Next 9 Months' },
  { Text: 'Next 12 Months', Value: 'Next 12 Months' },
];
