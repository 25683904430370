import React, { Suspense } from 'react';
import { useHistory, useLocation } from 'react-router';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewSoftwareCommonLandingPage({
  page: { MainContent, breadCrumbList },
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-software-common-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-software-common-content-component')
  );

  const history = useHistory();
  const path = useLocation();

  React.useEffect(() => {
    if (path.pathname === '/software/support/release-notes') {
      history.push({
        pathname: '/software/support',
        state: {
          isFromMegaMenu: true,
        },
      });
    }
  });

  if (path.pathname !== '/software/support/release-notes') {
    return (
      <div className="general-container">
        <Suspense fallback={<Loader />}>
          <HeroComponent
            MainContent={MainContent}
            breadCrumbList={breadCrumbList}
          />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <ContentComponent MainContent={MainContent} />
        </Suspense>
      </div>
    );
  }

  return <Loader />;
}

export default NewSoftwareCommonLandingPage;
