import React from 'react';
import './content-well.styles.scss';
import removeStyleFromHTMLString from '../../../utilities/CommonUtilities';

function ContentWell({ content, className }) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content || '' }}
      className={`content-well ${className || ''}`}
    />
  );
}

export default ContentWell;
