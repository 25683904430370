import { ENDPOINTS } from '../_config';

const axios = require('axios');

export const getUserProfileRes = async (memberId) => {
  const config = {
    method: 'get',
    url: ENDPOINTS.PROXY + ENDPOINTS.GET_USER_PROFILE,
    headers: {
      authorization: `Bearer ${localStorage.getItem('access-token')}`,
    },
    params: {
      memberid: memberId,
    },
  };
  try {
    const resp = await axios(config);
    return resp.data;
  } catch (err) {
    // Handle Error Here
    return null;
  }
};
