import React from 'react';
import './pay-assign-attendee-form-listing.styles.scss';
import ReviewPayAssignedAttendeeForm from '../review-pay-assign-attendee-form/pay-assign-attendee-form.component';

function ReviewPayAssignedAttendeeFormListing({ products }) {
  // products should have a {product-title, attendees}

  if (products) {
    return (
      <div className="review-assign-attendee-form-listing">
        <ReviewPayAssignedAttendeeForm />
      </div>
    );
  }
  return null;
}

export default ReviewPayAssignedAttendeeFormListing;
