import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import SafeBridgeContentComponent from './SafeBridgeContentComponent';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import BreadCrumbComponent from '../../../components/commons/hero/BreadCrumbComponent';

function SafeBridgeSecondLevel({ page }) {
  return (
    <div className="training training-secondary">
      {/* <Hero
                src={page?.MainContent.HeaderImage_src}
                alt={page?.MainContent.HeaderImage_alt}
                mobileImgSrc={page?.MainContent.HeaderMobileImage_src}
                title={page?.MainContent.Title}
                template={"standard"} //Changed from Landing to Standard
                colorCategory="train"
                breadCrumbList={page?.breadCrumbList}
            /> */}
      <Row className="shopping-cart module-bottom-spacer trinity-container">
        <Col xs={12} className="text-content-container">
          <div
            className="text-content background transparent"
            style={{ backgroundColor: 'rgba(7, 175, 154, 0.85)' }}
          >
            <div className="inner-wrapper  trinity-container">
              <h1 className="m-0">
                {page?.MainContent.Title.replace(/\b(\w)/g, (s) =>
                  s.toUpperCase()
                )}
              </h1>
            </div>
          </div>
        </Col>
      </Row>
      <div
        className="breadcrumb-outer-container-standard"
        style={{ marginTop: '-16px' }}
      >
        <BreadCrumbComponent breadCrumbList={page?.breadCrumbList} />
      </div>
      <div className="trinity-container">
        <SafeBridgeContentComponent responseData={page?.MainContent} />
        <hr />
        <div className="row">
          <ContentWell content={page?.MainContent.Content} />
        </div>
      </div>
    </div>
  );
}

export default SafeBridgeSecondLevel;
