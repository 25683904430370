const sciencePages = [
  {
    name: 'Science',
    path: '/science',
    meta: {
      title: 'this is a meta title',
      description: 'this is a meta description',
    },
    template: 'EhsPrimary',
  },
];

export default JSON.stringify(sciencePages);
