import { getEHSFilterData } from '../../components/ehs-quarterly/ehs-quarterly.data';
import { EHSActionTypes } from './ehs.types';
import {
  getEHSPublicationDataFromAPI,
  searchForEHSPublication,
} from './ehs.services';

export const getAllEHSQuarterly = () => (dispatch, getState) => {
  getEHSFilterData().then((response) => {
    dispatch({
      type: EHSActionTypes.GET_ALL_EHS_DATA,
      payload:
        response != null ? response.SearchData && response.SearchData : [],
    });
  });
};

export const getEHSPublicationData = () => (dispatch) => {
  getEHSPublicationDataFromAPI().then((response) => {
    dispatch({
      type: EHSActionTypes.EHS_PUBLICATION_DATA,
      payload: response !== null ? response.MainContent : {},
    });
  });
};

export const staticEHSSearchData = {
  count: true,
  skip: 0,
  top: 100,
  searchMode: 'all',
  queryType: 'full',
  facets: [
    'PublicationCategoryTitle,count:100,sort:value',
    'Year,count:100,sort:value',
  ],
  search: '*',
};

export const getSearchEHSPublicationData = (searchData) => (dispatch) => {
  searchForEHSPublication(searchData).then((response) => {
    dispatch({
      type: EHSActionTypes.EHS_SEARCH_LAST_QUERY,
      payload: searchData,
    });

    dispatch({
      type: EHSActionTypes.EHS_SEARCH_DATA,
      payload: response || {},
    });
  });
};
