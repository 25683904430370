import React, { Suspense } from 'react';
import tw from 'twin.macro';
import Loader from '../../../commons/helpers/loader/loader.component';

export const HighlightedText = tw.span`text-primary-500`;

function NewHomePage() {
  const HomePageContentComponent = React.lazy(() =>
    import('./HomePageContentComponent')
  );
  const HomePageTrainingComponent = React.lazy(() =>
    import('./TrainingHomePageComponent')
  );
  const ContactUsComponent = React.lazy(() =>
    import('./ContactUsHomePageComponent')
  );

  return (
    <div style={{ overflow: 'hidden' }}>
      <Suspense fallback={<Loader />}>
        <HomePageContentComponent />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <HomePageTrainingComponent />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContactUsComponent />
      </Suspense>
    </div>
  );
}

export default NewHomePage;
