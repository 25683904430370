import React, { useEffect, useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactWidget from '../../../../components/consulting/widgets/job-request-widget/job-request.component';
import JobRequestForm from '../../../../components/commons/forms/job-request-form/job-request-form.component';
import ContentWell from '../../../../components/commons/content-well/content-well.job.component';
import Hero from '../../../../components/commons/hero/hero.component';
import { getJobRequestPageData } from '../../../../js/api/consulting-ehs/_jobRequest.data';
import Loader from '../../../../components/commons/helpers/loader/loader.component';
import './consulting-job-request-individual-category-detail.styles.scss';

function SubmitJobRequest({ page: { MainContent, CategoryContent, modules } }) {
  // #RP - Task 59 - Submit Job Request
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getJobRequestPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status === noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.State !== undefined)
  ) {
    return (
      <div className="job-request-container">
        <Hero
          src={
            status !== noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status !== noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status !== noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status !== noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory={
            MainContent &&
            Object.keys(MainContent).length > 0 &&
            MainContent.url.split('/')[1] === 'staffinges'
              ? 'staffing'
              : 'policies'
          }
        />
        {/* KT add class trinity-container-changed-responsive 11-12-2021 */}
        <Row className="trinity-container trinity-container-changed-responsive">
          <Col sm={8}>
            <div className="content-widget-container ">
              <ContentWell
                className="mb-4"
                content={
                  status !== noContent && responseMainContent
                    ? responseMainContent
                    : ''
                }
              />
              <JobRequestForm data={responseDropDownContent} status={status} />
            </div>
          </Col>

          <Col sm={4} className="contact-outer">
            <ContactWidget
              contactData={
                status !== noContent && responseDropDownContent.StaffingContacts
                  ? responseDropDownContent.StaffingContacts
                  : ''
              }
              title={
                status !== noContent && responseMainContent.SideTitle
                  ? responseMainContent.SideTitle
                  : ''
              }
              status={status}
            />
          </Col>
        </Row>
      </div>
    );
  }
  return <Loader />;
}

export default SubmitJobRequest;
