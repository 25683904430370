import { UserActionTypes } from './user.types';
import { UserServices } from './user.services';

export const addLoggedinUser = (item) => ({
  type: UserActionTypes.LOGGEDIN_USER,
  payload: item,
});

export const addUserToken = (item) => ({
  type: UserActionTypes.ADD_USER,
  payload: item,
});
export const getImpersonateDetails = (item) => (dispatch) => {
  UserServices.getImpersonateDetails(item.token).then((response) => {
    dispatch({
      type: UserActionTypes.GET_IMPERSONATE_DETAILS,
      payload: response != null && response.Data ? response.Data : [],
    });
  });
};
export const removeUser = (item) => ({
  type: UserActionTypes.REMOVE_USER,
  payload: item,
});

export const addIsBackendUser = (item) => ({
  type: UserActionTypes.ISBACKEND_USER,
  payload: item,
});

export const addIsUserLoginFirstTime = (item) => ({
  type: UserActionTypes.ISUSER_LOGIN_FIRSTTIME,
  payload: item,
});

export const subscribeUserData = (item) => ({
  type: UserActionTypes.SUBSCRIBE_USER_DATA,
  payload: item,
});
