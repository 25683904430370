import React from 'react';
import SafeBridgeSearchRootComponent from './SafeBridgeSearchRootComponent';
import Hero from '../../components/commons/hero/hero.component';
import ContentWell from '../../components/commons/content-well/content-well.component';

function SafeBridgeSearchWrapper({ page }) {
  return (
    <div className="training training-secondary">
      <Hero
        src={page?.MainContent.HeaderImage_src}
        alt={page?.MainContent.HeaderImage_alt}
        mobileImgSrc={page?.MainContent.HeaderMobileImage_src}
        title={page?.MainContent.Title}
        template="standard" // Changed from Landing to Standard
        colorCategory="science" // RP - Sathish color changes in safebridge page
        breadCrumbList={page?.breadCrumbList}
      />
      <div className="trinity-container">
        <div className="row">
          <ContentWell content={page?.MainContent.Content} />
        </div>
        <SafeBridgeSearchRootComponent />
      </div>
    </div>
  );
}

export default SafeBridgeSearchWrapper;
