import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './office-details.styles.scss';
import Parser from 'html-react-parser';
import _ from 'lodash';

function compareValues(key, order = 'asc') {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
}

const getServiceNameList = (getList) => {
  if (getList.length > 0 && getList.length === 1) {
    return Parser(getList[0]);
  }
  const newList = (
    <div
      style={{
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {getList.map((e) => (
        <div>{Parser(e)}</div>
      ))}
    </div>
  );
  return newList;
};

function Locationcomponent({ locationData, stateData }) {
  return (
    <Row className="location-outer-office-container location-outer-office-container-changed">
      {locationData &&
        locationData.sort(compareValues('StateName')).map((detail) => {
          const stateURLData = stateData.filter(
            (x) => x.StateName === detail.StateName
          );

          return (
            <Col xs={12} sm={6} md={4} className="office-details-container">
              <div className="office-card office-card-changed">
                <div className="office-box office-box-changed">
                  <Row>
                    <Col xs={12}>
                      <p className="city">
                        {detail.Title === 'Washington D.C.'
                          ? detail.Title
                          : `${detail.StateName} - ${detail.Title}`}
                      </p>
                    </Col>
                  </Row>
                  <Row className="content-container content-container-changed">
                    {/* KT  change column style 8 to 7 */}
                    <Col xs={12} sm={7} className="address-container">
                      <div className="address-card">
                        <p className="title">Address</p>
                        <p className="address1">
                          {detail.Address1}
                          {detail.Address2 != '' ? `, ${detail.Address2}` : ''}
                          <br />
                          {detail.City != '' ? detail.City : ''}
                          {detail.OfficeStateName != ''
                            ? `, ${detail.OfficeStateName}`
                            : ''}
                          <br />
                          {detail.Zip != '' ? detail.Zip : ''}
                        </p>
                      </div>
                      {/* KT add class get-direction-changed-for-mobile 10-12-2021 */}
                      <div className="get-direction-changed get-direction-changed-for-mobile">
                        <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                        <a
                          href={
                            detail.DirectionLink != undefined &&
                            detail.DirectionLink != null &&
                            detail.DirectionLink != ''
                              ? detail.DirectionLink
                              : `http://www.google.com/maps/place/${detail.Latitude},${detail.Longitude}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Directions
                        </a>
                      </div>
                    </Col>
                    {/* KT  change column style 8 to 5 */}
                    <Col
                      xs={12}
                      sm={5}
                      className="office-contact-container office-contact-container-changed"
                    >
                      <p className="title">Contact</p>
                      <div className="contact-info">
                        <div className="phone-card">
                          {/* KT  add class  common-flex-changed */}
                          <div className="phone common-flex-changed">
                            <i className="fa fa-phone" aria-hidden="true" />{' '}
                            <a
                              className="wrap-changed"
                              href={`tel:${detail.PhoneNumber}`}
                              title={detail.PhoneNumber}
                            >
                              {detail.PhoneNumber.length > 0
                                ? detail.PhoneNumber
                                : 'No contact available'}
                            </a>
                          </div>

                          {detail.FaxNumber && (
                            <div className="fax common-flex-changed">
                              {/* KT  add class  common-flex-changed */}
                              <i
                                className="fa fa-fax"
                                aria-hidden="true"
                              />{' '}
                              <a
                                className="wrap-changed"
                                href={`fax:${detail.FaxNumber}`}
                              >
                                {detail.FaxNumber}
                              </a>
                            </div>
                          )}
                        </div>
                        {/* KT add class visit-page-changed-for-mobile 10-12-2021 */}
                        <div className="visit-page-changed visit-page-changed-for-mobile">
                          <i className="fa fa-desktop" aria-hidden="true" />{' '}
                          <a href={detail.VisitOfficeLink}>Visit Office Page</a>
                          {stateURLData[0].isShowStateLink && (
                            <div style={{ paddingTop: '1rem' }}>
                              <i className="fa fa-desktop" aria-hidden="true" />{' '}
                              <a href={stateURLData[0].VisitStateLink}>
                                Visit State Page
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* KT add below row for large screen content 10-12-2021 */}
                  <Row className="card-bottom-section-for-big-screen">
                    <Col xs={12} sm={7} className="office-contact-container">
                      {/* KT add div get-direction-changed */}
                      <div className="get-direction-changed">
                        <i className="fa fa-map-marker" aria-hidden="true" />{' '}
                        <a
                          href={
                            detail.DirectionLink != undefined &&
                            detail.DirectionLink != null &&
                            detail.DirectionLink != ''
                              ? detail.DirectionLink
                              : `http://www.google.com/maps/place/${detail.Latitude},${detail.Longitude}`
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          Get Directions
                        </a>
                      </div>
                    </Col>
                    <Col xs={12} sm={5} className="office-contact-container">
                      {/* KT add div visit-page-changed */}
                      <div className="visit-page-changed">
                        <i className="fa fa-desktop" aria-hidden="true" />{' '}
                        <a href={detail.VisitOfficeLink}>Visit Office Page</a>
                        {stateURLData[0].isShowStateLink && (
                          <div style={{ paddingTop: '1rem' }}>
                            <i className="fa fa-desktop" aria-hidden="true" />{' '}
                            <a href={stateURLData[0].VisitStateLink}>
                              Visit State Page
                            </a>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                {detail.ServiceName && detail.ServiceName.length > 0 && (
                  <Row className="office-card-footer office-card-footer-changed">
                    {getServiceNameList(detail.ServiceName.split(','))}
                  </Row>
                )}
              </div>
            </Col>
          );
        })}
    </Row>
  );
}

const groupBy = (data, key) =>
  _(data)
    .sort((a, b) => (a.CountryName > b.CountryName ? 1 : -1))
    .groupBy((item) => item[key])
    .value();

// RP #Task 94 Location
export function USOfficeDetails({ locationData, stateData }) {
  // RP - SD 161 - Locations page : Title
  const groupdata = Object.values(groupBy(locationData, 'CountryName'));

  return (
    <div>
      {groupdata &&
        groupdata.map((element) => {
          const countryname =
            element && element.length > 0 && element[0].CountryName;
          if (
            countryname.toString().toLowerCase().replace(/ /g, '') ===
            'United States'.toString().toLowerCase().replace(/ /g, '')
          ) {
            return (
              <>
                <span style={{ fontWeight: 'bold', fontSize: '1.875rem' }}>
                  {countryname} {' Office Locations'}
                </span>
                <br />
                <br />
                <Locationcomponent
                  locationData={element}
                  stateData={stateData}
                />
              </>
            );
          }
        })}
    </div>
  );
}

// RP #Task 94 Location
function OfficeDetails({ locationData, stateData }) {
  // RP - SD 161 - Locations page : Title
  const groupdata = Object.values(groupBy(locationData, 'CountryName'));

  return (
    <div>
      {groupdata &&
        groupdata.map((element) => {
          const countryname =
            element && element.length > 0 && element[0].CountryName;
          if (
            countryname.toString().toLowerCase().replace(/ /g, '') !==
            'United States'.toString().toLowerCase().replace(/ /g, '')
          ) {
            return (
              <>
                <span style={{ fontWeight: 'bold', fontSize: '1.875rem' }}>
                  {countryname} {' Office Locations'}
                </span>
                <br />
                <br />
                <Locationcomponent
                  locationData={element}
                  stateData={stateData}
                />
              </>
            );
          }
        })}
    </div>
  );
}

export default OfficeDetails;
