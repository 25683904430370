export const UserActionTypes = {
  ADD_USER: 'ADD_USER',
  REMOVE_USER: 'REMOVE_USER',
  VERIFIED_EMAIL: 'VERIFIED_EMAIL',
  REDIRECT_ROUTER: 'REDIRECT_ROUTER',
  LOGGEDIN_USER: 'LOGGEDIN_USER',
  GET_IMPERSONATE_DETAILS: 'GET_IMPERSONATE_DETAILS',
  ISBACKEND_USER: 'ISBACKEND_USER',
  ISUSER_LOGIN_FIRSTTIME: 'ISUSER_LOGIN_FIRSTTIME',
  SUBSCRIBE_USER_DATA: 'SUBSCRIBE_USER_DATA',
};
