import React from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/about/policiesHero.svg';
import PrivacyPolicyForm from '../../../components/about/forms/privacy-policy/privacy-policy.component';

function PrivacyPolicy({ page: { modules } }) {
  return (
    <div className="privacy-policy-container">
      <Hero
        src={heroImageSrc}
        alt={modules.hero.hero_alt}
        title={modules.hero.hero_title}
        template="standard"
        colorCategory="policies"
      />
      <div className="trinity-container">
        <PrivacyPolicyForm />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
