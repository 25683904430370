import React from 'react';
import './image-left-with-content-well.styles.scss';

import ContentWell from '../../commons/content-well/content-well.component';

import image from '../../../images/consulting-ehs/consulting-data-table-image-left.png';

function ImageLeftWithContentWell({ content }) {
  return (
    <div className="image-left-with-content-well module-spacer-bottom">
      <div className="left">
        <img src={image} alt="" />
      </div>

      <div className="right">
        <ContentWell
          content={`
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum facilisis leo vel fringilla est. Viverra maecenas accumsan lacus vel facilisis volutpat. A diam maecenas sed enim ut sem. Velit ut tortor pretium viverra suspendisse potenti nullam ac. Risus sed vulputate odio ut enim blandit volutpat maecenas. Natoque penatibus et magnis dis parturient montes nascetur ridiculus. Dolor sit amet consectetur adipiscing elit.</p>
                `}
        />
      </div>
    </div>
  );
}

export default ImageLeftWithContentWell;
