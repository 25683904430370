// static version -- this is used for devlopment off local host

import homepage from '../../placeholder-json-api/homepage/homepage-pages.data';
import resources from '../../placeholder-json-api/resources-and-news/resources-and-news-pages.data';
import consulting from '../../placeholder-json-api/consulting/consulting-pages.data';
import about from '../../placeholder-json-api/about/about-pages.data';
import engineering from '../../placeholder-json-api/engineering/engineering-pages.data';
import science from '../../placeholder-json-api/science/science-pages.data';
import general from '../../placeholder-json-api/general/general-pages.data';
import industries from '../../placeholder-json-api/industries/industries-pages.data';
import software from '../../placeholder-json-api/software/software-pages.data';
import staffing from '../../placeholder-json-api/staffing/staffing-pages.data';
import technology from '../../placeholder-json-api/technology/technology-pages.data';
import training from '../../placeholder-json-api/training/training-pages.data';
import locations from '../../placeholder-json-api/locations/locations-pages.data';
import serviceBranded from '../../placeholder-json-api/serviceBranded/service-branded-pages.data';

export let BASE_URL;
export let AZURE_QUEUE_BASE_URL;
// staging url
export const STAGING_URL = 'https://trinitystage.azurewebsites.net';
export const PROD_URL = 'https://trinityprod.trinityconsultants.com';

export const DEV_URL = 'https://trinitysandboxcms.azurewebsites.net';
export const WEBSITE_URL = 'https://www.trinityconsultants.com';
export const AZURE_CDN_URL =
  'https://resources.trinityconsultants.com/tciwebsiteresources';
export const AZURE_QUEUE_DEV_URL =
  'https://devtcistorageservices.azurewebsites.net';
export const AZURE_QUEUE_STAGING_URL =
  'https://storageservices-prod.azurewebsites.net';

export const CUSTOM_SITE_MAP_BASE =
  'https://tciglobalstorage.blob.core.windows.net';
export const CUSTOM_SITE_MAP_JSON_URL = `${CUSTOM_SITE_MAP_BASE}/routes/cutomSiteMap.json`;

export const TRINITY_SITE_SEARCH =
  'https://trinityprod-search.search.windows.net';
export const SITE_SEARCH_API_KEY = '3B14A009F3781E05EA8B1F0352405C45';
export const SITE_SEARCH_AUTHORITY = 'trinityprod-search.search.windows.net';

export const LINKEDIN_PARTNER_ID = 5244020;
export const LINKEDIN_CONVERSION_ID = 13815332;

export const GA4_MEASUREMENT_ID = 'G-VB50X7KQHK';

export const GA4_EVENT_MAPPPING = {
  USER_LOGIN: {
    category: 'login',
    event_name: 'login',
    label: 'Login',
  },
  SELECT_ITEM: {
    category: 'select_item',
    event_name: 'select_item',
    label: 'Select Item',
  },
  ADD_TO_CART: {
    category: 'add_to_cart',
    event_name: 'add_to_cart',
    label: 'Add To Cart',
  },
  ADD_PAYMENT_INFO: {
    category: 'add_payment_info',
    event_name: 'add_payment_info',
    label: 'Add Payment Info',
  },
  PURCHASE: {
    category: 'purchase',
    event_name: 'purchase',
    label: 'Purchase',
  },
};

// proxy to avoid CORS errors on localhost
const proxy = ''; // "https://cors-anywhere.herokuapp.com/";
export const LOCAL_URL = `http://localhost:60876`;
BASE_URL = PROD_URL; //DEV_URL;// STAGING_URL;
AZURE_QUEUE_BASE_URL = AZURE_QUEUE_STAGING_URL;

// Must be in Milli Seconds + More than 50 Minutes
export const TOKEN_REFRESH_TIME = 187200;

export const REMOTE_LINKEDIN_TRACKING_SCRIPT =
  'https://tcilocalstorage.blob.core.windows.net/assets/LinkedInInsight-Code.js';

export const CAREER_WEBSITE_URL = 'https://www.trinity-careers.com';
export const BREEZE_EMAIL_ADDRESS = 'breeze@trinityconsultants.com';
export const SALES_TEAM = 'breeze@trinityconsultants.com';
export const DATA_TEAM = 'wgd@trinityconsultants.com';
export const SUPPORT_TEAM = 'password-help@trinityconsultants.com';
export const BREEZE_CONTACT_NUMBER = '+1 972-661-8881';

// export const RE_CAPTCHA_SITE_KEY = "6LefIOwZAAAAAOViVLh_vHZC1kFoI2K-1GSBznlE"
export const RE_CAPTCHA_SITE_KEY = '6LcqKMEZAAAAAJmqqSd_S4eog3zMrFbk6M7L6EDt';
export const LIBRARY_GU_ID = '031548C8-F93D-42B8-9D4D-0D87F8E7AB4C';
export const TORONTO_FOLDER_GUID = '5CF66AAD-FAF4-4535-91DC-21A1BAF0EFCA';
export const SUBSCRIBE_SECONDFORM_IMAGE = `${BASE_URL}/images/default-source/siteassets/ehs-quarterly/ehs-q-issue-5/ehs-quarterly-2021-issue-5_cover.jpg?sfvrsn=7d1ea333_2`;
export const SUBSCRIBE_SECONDFORM_IMAGELINK =
  'https://media.trinityconsultants.com/view/133247343/';

export const SANGEA_LINK = 'https://apisangea.org';

// Sentry Key
export const SENTRY_KEY =
  'https://0ab2a2cfea944f89ae1198087e89a99d@o1006744.ingest.sentry.io/5967072';

// Business Unit
export const TRAINING_BUSINESS_UNIT_TRAINING_CONSULTANTS_INC = 'EHS';
export const TRAINING_BUSINESS_UNIT_SAFEBRIDGE = 'SafeBridge';
export const TRAINING_BUSINESS_UNIT_BREEZE = 'Breeze';
export const TRAINING_BUSINESS_UNIT_PROVENANCE = 'Provenance';

export const ALLOWED_DIETORY_INSTRUCTIONS_CATEGORIES = ['Classroom'];

// This stores info coming from the placeholder static json files
const PLACEHOLDER_API = {
  HOMEPAGE: homepage,
  LOCATIONS: locations,
  RESOURCES: resources,
  ABOUT: about,
  CONSULTING: consulting,
  ENGINEERING: engineering,
  SCIENCE: science,
  GENERAL: general,
  INDUSTRIES: industries,
  SOFTWARE: software,
  STAFFING: staffing,
  TECHNOLOGY: technology,
  TRAINING: training,
  SERVICEBRANDED: serviceBranded,
};

export const ENDPOINTS = {
  ...PLACEHOLDER_API,
  PROXY: `${proxy}`,
  BASE_URL: `${BASE_URL}`,
  ANALYTICS_SEARCH: `${BASE_URL}/restapi/ucommerce/training/products/ga4`,
  SITEFINITYCONNECTION: `${BASE_URL}/restapi/customsitemap`, // this is the endpoint for the routes // restapi/customsitemap
  NAVIGATION: `${BASE_URL}/restapi/newcustommenu`, // restapi/custommenu
  FOOTER: `${BASE_URL}/restapi/customfooter`, // restapi/customfooter
  AUTHENTICATION: `${BASE_URL}/Sitefinity/Authenticate/OpenID/connect/token`,
  REGISTER_USER: `${BASE_URL}/restapi/user/register`,
  EMAIL_EXISTS: `${BASE_URL}/restapi/user/exists/`,
  GET_PRODUCTS: `${BASE_URL}/restapi/ucommerce/categories/{74}/products`,
  GET_PRODUCT: `${BASE_URL}/restapi/ucommerce/products/1518`,
  GET_SINGLE_PRODUCT: `${BASE_URL}/restapi/ucommerce/products/`,
  GET_SINGLE_TRAINING_PRODUCT: `${BASE_URL}/restapi/ucommerce/training/products/`,
  GET_CATEGORIES: `${BASE_URL}/restapi/ucommerce/categories`,
  GET_CATEGORIES_NAME: `${BASE_URL}/restapi/ucommerce/category/training`, // RK Task#99 added get training by category name
  CART: {
    GET_CART_CONTENTS: `${BASE_URL}/restapi/ucommerce/cart`,
    ADD_ITEM: `${BASE_URL}/restapi/ucommerce/cart/additem`,
    REMOVE_ITEM: `${BASE_URL}/restapi/ucommerce/cart/removeitem`,
    CHANGE_ITEM_QUANTITY: `${BASE_URL}/restapi/ucommerce/cart/changequantity`,
    ADD_ATTENDEE: `${BASE_URL}/restapi/ucommerce/attendee/createmultiple`,
    GET_CART_ATTENDEES: `${BASE_URL}/restapi/ucommerce/attendee`,
    UPDATE_CART_ATTENDEE: `${BASE_URL}/restapi/ucommerce/attendee/update`,
    REMOVE_CART_ATTENDEE: `${BASE_URL}/restapi/ucommerce/attendee/delete`,
    APPLY_DISCOUNT: `${BASE_URL}/restapi/ucommerce/discount/apply`,
    GET_PAYMENT_INTENT: `${BASE_URL}/restapi/ucommerce/checkout/paymentintent`, // NL Task#138 Implement stripe in shopping cart
    PAYMENT_SUCCESS_HOOK: `${BASE_URL}/restapi/ucommerce/checkout/stripepaymentsuccess`, // NL Task#138 Implement stripe in shopping cart
    FREE_PAYMENT_HOOK: `${BASE_URL}/restapi/ucommerce/checkout/freepaymentsuccess`,
    REMOVE_DISCOUNT: `${BASE_URL}/restapi/ucommerce/discount/removediscount`,
    GET_CUSTOMERS: `${BASE_URL}/restapi/ucommerce/customers`,
    PAYOFFLINE_PAYMENT: `${BASE_URL}/restapi/ucommerce/checkout/payofflinepaymentsuccess`,
  },
  // these are all the endpoints for dropdown content
  GET_USER_COUNTRIES: `${DEV_URL}/restapi/countries`,
  GET_USER_STATES: `${DEV_URL}/restapi/states`,
  GET_USER_INDUSTRIES: `${DEV_URL}/restapi/industries`,

  GET_LOCATIONS: `${BASE_URL}/restapi/customfilter/location`,
  GET_RESOURCE_TYPE: `${BASE_URL}/restapi/customfilter/resourcetype`,
  GET_SOFTWARES: `${BASE_URL}/restapi/customfilter/software`,
  GET_INDUSTRIES: `${BASE_URL}/restapi/customfilter/industry`,
  GET_NEWS_MEDIA: `${BASE_URL}/restapi/customnewsfilter/`,
  GET_SOFTWARE_LP: `${BASE_URL}/restapi/customcontent/software`,

  // JP #Task 41
  GET_SEARCH: `${BASE_URL}/restapi/ucommerce/searchcategory`,

  GET_GLOBAL_INDUSTRIES: `${BASE_URL}/restapi/customfilter/industries`,
  // customcontect
  GET_TRAINING_CONTENT: `${BASE_URL}/restapi/customcontent/training`,
  GET_TRAINING_SECONDARY_CONTENT: `${BASE_URL}/restapi/customcontent/training`,
  // get category and category wise product
  GET_CATEGORYPRODUCTS: `${BASE_URL}/restapi/ucommerce/categoryproducts`,

  GET_CONTACTUS: `${BASE_URL}/restapi/customcontent/contactus`,
  SUBMIT_FORM_AZURE_QUEUE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/sendmessage`,
  CREATE_AZURE_QUEUE_TOKEN: `${AZURE_QUEUE_BASE_URL}/api/v1/Token/create`,
  GET_SOFTWARE_REQUEST_KEY: `${BASE_URL}/restapi/customcontent/software-request-key`,
  GET_APPLY_ONLINE_FORM: `${BASE_URL}/restapi/customcontent/apply-online-form`,
  GET_REQUEST_A_QUOTE: `${BASE_URL}/restapi/customcontent/request-a-quote`,
  GET_WEBINAR_SIGNUP: `${BASE_URL}/restapi/customcontent/webinar-signup`,
  GET_SOFTWARE_SUPPORT_REQUEST: `${BASE_URL}/restapi/customcontent/software-support-request`,
  GET_SUBMIT_JOB_REQUEST: `${BASE_URL}/restapi/customcontent/submit-job-request`,
  GET_SOFTWARE_DEMO: `${BASE_URL}/restapi/customcontent/software-demo`,
  GET_SUBSCRIBE: `${BASE_URL}/restapi/customcontent/subscribe`,
  GET_REQUEST_TRAINING_QUOTE: `${BASE_URL}/restapi/customcontent/request-training-quote`,
  GET_WEBSITE_PROBLEM: `${BASE_URL}/restapi/customcontent/report-problem`,
  GET_REQUEST_DATA_QUOTE: `${BASE_URL}/restapi/customcontent/request-data-quote`,
  GET_REQUEST_SOFTWARE_QUOTE: `${BASE_URL}/restapi/customcontent/request-software-quote`,
  GET_SOFTWARE_REQUEST_TRIAL: `${BASE_URL}/restapi/customcontent/request-a-trial-license`,
  GET_SOFTWARE_A_KEY: `${BASE_URL}/restapi/customcontent/software-support-request`,
  GET_APPLY_FOR_JOB: `${BASE_URL}/restapi/customcontent/apply-for-job`,
  GET_REMOTE_MODELING_FORM: `${BASE_URL}/restapi/customcontent/remote-modeling-requirements`,
  GET_ACCOUNT_DETAIL: `${BASE_URL}/restapi/customcontent/create-account`,
  GET_EDITPROFILE_DETAIL: `${BASE_URL}/restapi/customcontent/edit-profile`,
  GET_ABOUTUS_DETAIL: `${BASE_URL}/restapi/customcontent/about-us`,
  GET_EXECUTIVE_COMMITEE: `${BASE_URL}/restapi/customcontent/about-us/executive-committee`,
  GET_OPERATIONAL_LEADERS: `${BASE_URL}/restapi/customcontent/about-us/operational-leaders`,
  GET_POLICIES: `${BASE_URL}/restapi/customcontent/about-us/policies`,
  GET_JOBALERT_SIGNUP: `${BASE_URL}/restapi/customcontent/job-alert-sign-up`,
  GET_REGULATORY_AGENCY_FORM: `${BASE_URL}/restapi/customcontent/regulatory-agency-program`,

  GET_PRODUCT_SKU: `${BASE_URL}/restapi/ucommerce/product`,
  GET_CATEGORY_NAME: `${BASE_URL}/restapi/ucommerce/category`,
  CHECK_USER_LOGGEDIN: `${BASE_URL}/restapi/user/loggedin`,
  GET_USER_PROFILE: `${BASE_URL}/restapi/user/profile`,

  CREATE_UPDATE_DOCUMENT: `${BASE_URL}/restapi/customcontentservice/createdocumentlibrary`,

  RELATED_ITEM: {
    GET_TRAINING_COURSE_SERVICE_DATA: `${BASE_URL}/restapi/ucommerce/course/service`,
    GET_TRAINING_COURSE_INDUSTRIES_DATA: `${BASE_URL}/restapi/ucommerce/course/Industries`,
    GET_TRAINING_COURSE_PRODUCT_DATA: `${BASE_URL}/restapi/ucommerce/course/product`,
    GET_TRAINING_COURSE_LOCATION_DATA: `${BASE_URL}/restapi/ucommerce/course/location/training`,
    GET_RESOURCES_DATA: `${BASE_URL}/restapi/customcontentservice/relateditem/resources`,
    GET_NEWS_DATA: `${BASE_URL}/restapi/customcontentservice/relateditem/news`,
    GET_PRODUCT_CONSULTING_DATA: `${BASE_URL}/restapi/customcontentservice/relateditem/consulting/product`,
    GET_PRODUCT_INDUSTRIES_DATA: `${BASE_URL}/restapi/customcontentservice/relateditem/industries/product`,
    GET_LATEST_TRAINING_COURSE: `${BASE_URL}/restapi/ucommerce/latest/courses`,
  },

  NEWS: {
    GET_ADMIN_NEWS: `${BASE_URL}/restapi/customcontentservice/adminnewssearch`,
    GET_USER_NEWS: `${BASE_URL}/restapi/customcontentservice/news/`,
    GET_NEWS_FILTER_DATA: `${BASE_URL}/restapi/customfilter/newsadvancefilterdata`,
    GET_NEWS_PAGE_DATA: `${BASE_URL}/restapi/customcontent/news/`,
  },
  CLIENT: {
    GET_SOFTWARE_DATA_DOWNLOADS: `${BASE_URL}/restapi/user/licenses`,
    GET_ORDER_HISTORY: `${BASE_URL}/restapi/user/orderhistory`,
    GET_MODEL_JOB_STATUS: `${BASE_URL}`,
    TRAINING_CERTIFICATES: `${BASE_URL}/restapi/user/trainingcertificate`,
    GET_WEBINAR: `${BASE_URL}/restapi/user/webinars`,
    GET_BRMS_KEY: `${BASE_URL}/restapi/user/remotemodeling/hasBRMSKey`,
    GET_QUEUED_DATA: `${BASE_URL}/restapi/user/remotemodeling/run/queuedrun`,
    GET_COMPLETED_DATA: `${BASE_URL}/restapi/user/remotemodeling/run/completedrun`,
    DOWNLOAD_TRAINING_CERTIFICATE: `${BASE_URL}/restapi/user/trainingcertificatedownload`,
    FEEDBACK_CERTIFICATE: `${BASE_URL}/restapi/user/survey/trainingcertificate`,
    DOWNLOAD_ORDER_RECEIPT: `${BASE_URL}/restapi/user/ordercertificatedownload`,
    OLD_ORDER_HISTORY: `${BASE_URL}/restapi/legacytrainingservice/getorderhistory`,
    SHOPPING_CARD_CERTIFICATE: `${BASE_URL}/restapi/legacytrainingservice/ordercertificatedownload`,
    SOFTWARE_AND_DATA_DOWNLOAD: `${BASE_URL}/restapi/user/license/file`,
    GET_ELEARNING_DATA: `${BASE_URL}/restapi/user/elearning`,
    VIDEO_STORAGE_SERVICES: `${AZURE_QUEUE_BASE_URL}/api/v1/media/webinarUrl`,
    BRMS_DOWNLOAD: `${AZURE_QUEUE_BASE_URL}/api/v1/brms/download`,
    MAINTENANCE_EXPIRED_ANALYTICS: `${BASE_URL}/restapi/user/softwaredownloads/analytics`,
  },
  MINNOW: {
    GET_MINNOW_DATA: `${BASE_URL}/restapi/customcontent/minnow/`,
    SERVICE_BRAND_TRAINING_URL: `${BASE_URL}/restapi/ucommerce/training/relateditem/`,
    SERVICE_BRAND_EVENTS_URL: `${BASE_URL}/restapi/customcontentservice/relateditem/tradeshow?TradeShowName=`,
    SERVICE_BRAND_NEWS: `${BASE_URL}/restapi/customcontentservice/relateditem/news?Newsname=`,
  },
  FEEDBACK: {
    GET_FEEDBACK_DATA: `${BASE_URL}/restapi/user/survey/`,
  },
  DOWNLOAD_COURSE_CATALOG: `${BASE_URL}/restapi/customcontentservice/DownloadCourseCatalog`,
  USER: {
    IMPERSONATE_USER: `${BASE_URL}/restapi/user/impersonate/`,
    FORGOTPASSWORD_REQUEST: `${BASE_URL}/restapi/user/forgotpassword`,
    RESETPASSWORD_VALIDTOKEN: `${BASE_URL}/restapi/user/resetpasswordvalidtoken/`,
    RESETPASSWORD_REQUEST: `${BASE_URL}/restapi/user/resetpassword`,
    PROFILE_UPDATE_USER: `${BASE_URL}/restapi/user/profile/update`,
    PROFILE_PASSWORD_UPDATE: `${BASE_URL}/restapi/user/profile/changePassword`,
    ISLOGIN_FIRSTTIME: `${BASE_URL}/restapi/user/isloginfirsttimeupdate`,
    UPDATE_LAST_ACTIVITY_DATE: `${BASE_URL}/restapi/user/updatelastactivitydate`,
  },
  TRAINING_BY_LOCATION: {
    GET_TRAINING_DATA: `${BASE_URL}/restapi/ucommerce/training/locationspecific`,
    GET_COUNTRY_STATE_WISE_DATA: `${BASE_URL}/restapi/ucommerce/training/locationspecific/list`,
  },
  ADMINUSER: {
    EMAIL_EXISTS: `${BASE_URL}/restapi/adminuser/exists/`,
  },
  NEW_HOME_PAGE: {
    HOME_PAGE_DATA: `${BASE_URL}/restapi/customhomecontentservice/home/`,
    HERO_PAGE_DATA: `${BASE_URL}/restapi/customlandingpagecontent/herocontent`,
    LANDING_PAGE_DATA: `${BASE_URL}/restapi/customlandingpagecontent/landingcontent`,

    NEW_SLIDER_DATA: `${BASE_URL}/restapi/customlandingpagecontent/bannercarousel`,
    CASE_STUDIES_DATA: `${BASE_URL}/restapi/customlandingpagecontent/casestudies`,
    BLOG_SERIES_DATA: `${BASE_URL}/restapi/customlandingpagecontent/blogseries`,
  },

  SUBMIT_AZURE_QUEUE: {
    CONTACTUS_RESPONSE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitcontactusresponse`,
    REQUEST_DATA_QUOTE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitrequestdataquoteresponse`,
    REQUEST_SOFTWARE_QUOTE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitrequestsoftwarequoteresponse`,
    GENERATE_SOFTWARE_KEY: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitgeneratesoftwarekeyresponse`,
    REQUEST_SERVICE_QUOTE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitrequestservicequoteresponse`,
    REQUEST_TRAINING_QUOTE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitrequesttrainingquoteresponse`,
    REQUEST_TRIAL: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitrequesttrialresponse`,
    SUBSCRIBE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitsubscriberesponse`,
    SOFTWARE_DEMO: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitsoftwaredemoresponse`,
    CREATE_USER_ACCOUNT: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitcreateuseraccountresponse`,
    SUBMIT_REMOTE_MODELING: `${AZURE_QUEUE_BASE_URL}/api/v1/file/UploadBrms`,
    SOFTWARE_REQUEST_KEY: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitsoftwarerequestkeyresponse`,
    APPLY_ONLINE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitapplyonlineresponse`,
    SUBMIT_JOB_REQUEST: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitjobrequestresponse`,
    WEBINAR_SIGNUP: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitwebinarsignupresponse`,
    REPORT_WEBSITE_PROBLEM: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitreportwebsiteproblemresponse`,
    APPLY_FOR_JOB: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitapplyforjobresponse`,
    JOB_ALERT_SIGNUP: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/submitjobalertsignupresponse`,
    REGULATORY_AGENCY_RESPONSE: `${AZURE_QUEUE_BASE_URL}/api/v1/queue/RegulatoryAgencyProgramMessageResponse`,
  },

  TRAINING_FILTER: {
    TRAINING_FORMAT_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/trainingformats`,
    TRAINING_CATEGORY_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/trainingcategories`,
    TRAINING_INDUSTRY_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/trainingindustries`,
    TRAINING_LOCATION_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/traininglocation`,
    TRAINING_REGULATORYAGENCY_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/trainingregulatoryagency`,
    TRAINING_DATE_FILTER_DATA: `${BASE_URL}/restapi/trainingservice/trainingdates`,
    GET_PAGE_WISE_TRAINING_DATA: `${BASE_URL}/restapi/trainingservice/trainingsearch`,
  },

  RESOURCE_FILTER: {
    RESOURCE_FILTER_DATA: `${BASE_URL}/restapi/customfilter/resourceadvancefilterdata`,
    GET_PAGE_WISE_RESOURCE_DATA: `${BASE_URL}/restapi/customcontentservice/resources`,
    GET_NEW_PAGE_WISE_RESOURCE_DATA: `${BASE_URL}/restapi/customresourcescontent/pagewisedata/resources`,
    NEW_RESOURCE_FILTER_DATA: `${BASE_URL}/restapi/customresourcescontent/resourceadvancefilterdata`,
    GET_TORONTO_CONTENT: `${BASE_URL}/restapi/customcontentservice/libraryfilesusingguid?LibraryGuid=${LIBRARY_GU_ID}`,
    GET_DOCUMENT_BY_FOLDER: `${BASE_URL}/restapi/customcontentservice/folderfilesusingguid?FolderGuid=${TORONTO_FOLDER_GUID}`,
  },

  SOFTWARE_RESOURCE_FILTER: {
    SOFTWARE_RESOURCE_FILTER_DATA: `${BASE_URL}/restapi/customfilter/softwareresourceadvancefilterdata`,
    GET_PAGE_WISE_SOFTWARE_RESOURCE_DATA: `${BASE_URL}/restapi/customcontentservice/softwareresources`,
    GET_NEW_PAGE_WISE_SOFTWARE_RESOURCE_DATA: `${BASE_URL}/restapi/customsoftwareresourcescontent/pagewisedata/software/resources`,
  },

  EHS_QUARTERLY: {
    GET_FILTER_DATA: `${BASE_URL}/restapi/customfilter/ehssearchfilterdata`,
    SEARCH_EHS_DATA: `${BASE_URL}/restapi/customcontentservice/ehssearch`,
  },
  EHS: {
    GET_EHS_PUBLICATION: `${BASE_URL}/restapi/customehspublication/ehs-publication`,
    EHS_PUBLICATION_SEARCH: `${TRINITY_SITE_SEARCH}/indexes/publication/docs/search?api-version=2016-09-01`,
  },
  URL_404: {
    NOT_FOUND_URL: `${BASE_URL}/api/RequestNotFound`,
  },

  CONSULTING_LANDING_CATEGORY: `${BASE_URL}/restapi/customconsultingcontent/consultingcategory`,
  ABOUTUS_LANDING_CATEGORY: `${BASE_URL}/restapi/customaboutuscontent/aboutuscategory`,
  SOFTWARE_LANDING_CATEGORY: `${BASE_URL}/restapi/customsoftwarecontent/softwarecategory`,
  TECHNOLOGY_LANDING_NEWS_RELATED_DATA: `${BASE_URL}/restapi/customtechnologycontentservice/relatedtechnologyitem/news`,
  TECHNOLOGY_LANDING_RESOURCE_RELATED_DATA: `${BASE_URL}/restapi/customtechnologycontentservice/relatedtechnologyitem/resources`,
  STAFFING_EMPLOYER_CATEGORY: `${BASE_URL}/restapi/customstaffingcontent/staffingesemployercategory`,
  STAFFING_JOB_CATEGORY: `${BASE_URL}/restapi/customstaffingcontent/staffingesjobcategory`,
  INDUSTRY_LANDING_CATEGORY: `${BASE_URL}/restapi/customindustrycontent/industriescategory`,
  CAREERS_LANDING_CATEGORY: `${BASE_URL}/restapi/customcareerscontent/careerscategory`,
  CAREERS_LANDING_INDIVIDUAL_POSITION: `${BASE_URL}/restapi/customcareerscontent/careersavailablepositions`,
  TRAINING_LANDING_CATEGORY: `${BASE_URL}/restapi/customtrainingcontent/trainingcategory`,
  LOCATION_LANDING_CONTENT: `${BASE_URL}/restapi/customlocationcontent/locationscontent`,
  STATE_CONTENT: `${BASE_URL}/restapi/customstatecontent/state`,
  COUNTRY_CONTENT: `${BASE_URL}/restapi/customcountrycontent/country`,
  INDUSTRY_CONTENT: `${BASE_URL}/restapi/customindustrycontent/industry`,
  TRINITY_SEARCH: {
    SEARCH_FOR_NEWS: `${TRINITY_SITE_SEARCH}/indexes/sitesearch/docs/search?api-version=2020-06-30`,
  },
  AREASOFINTEREST_CONTENT: `${BASE_URL}/restapi/customareasofinterestcontent/areasofinterest`,
  TYPEOFASSIGNMENT_CONTENT: `${BASE_URL}/restapi/customtypeofassignmentcontent/typeofassignment`,
  APPLY_FOR_JOB_EDUCATION_CONTENT: `${BASE_URL}/restapi/customapplyforjobeducationcontent/education`,
  APPLY_FOR_JOB_CAREER_LEVEL_CONTENT: `${BASE_URL}/restapi/customapplyforjobcareerlevelcontent/careerlevel`,
  APPLY_FOR_JOB_WORK_AUTHORIZATION_CONTENT: `${BASE_URL}/restapi/customapplyforjobworkauthorizationcontent/workauthorization`,
  HOWDIDYOUHEARABOUTUS_CONTENT: `${BASE_URL}/restapi/customhowdidyouhearaboutuscontent/howdidyouhearaboutus`,
  DATAFORMAT_CONTENT: `${BASE_URL}/restapi/customrequestdataquotedataformatcontent/requestdataquotedataformat`,
  COORDINATETYPE_CONTENT: `${BASE_URL}/restapi/customrequestdataquotecoordinatetypecontent/requestdataquotecoordinatetype`,
  YEAR_CONTENT: `${BASE_URL}/restapi/customyearcontent/year`,
  UTMDIRECTIONS_CONTENT: `${BASE_URL}/restapi/customrequestdataquoteutmdirectionscontent/requestdataquoteutmdirections`,
  DROP_DOWN_CONTENT: `${BASE_URL}/restapi/customdropdowncontent/dropdown`,

  CONSULTING_CATEGORY_CONTENT: `${BASE_URL}/restapi/customconsultinglvl2content/consultingcategory`,
  CONSULTING_INDIVIDUAL_CATEGORY_CONTENT: `${BASE_URL}/restapi/customconsultinglvl3content/consultingcategory`,
  CONSULTING_INDIVIDUAL_CATEGORY_DETAIL_CONTENT: `${BASE_URL}/restapi/customconsultinglvl4content/consultingcategory`,
  INDUSTRY_SECONDARY_CATEGORY_DETAIL_CONTENT: `${BASE_URL}/restapi/customindustrylvl2content/industriescategory`,
  INDUSTRY_SERVICE_CATEGORY_DETAIL_CONTENT: `${BASE_URL}/restapi/customindustrylvl3content/industriescategory`,
  ABOUTUS_MISSION_CATEGORY_DETAIL_CONTENT: `${BASE_URL}/restapi/customaboutuslvl2content/aboutuscategory/mission`,
  POLICIES_CATEGORY_DETAIL_CONTENT: `${BASE_URL}/restapi/customaboutuslvl2content/aboutuscategory/policies`,
  ABOUTUS_TRADE_SHOWS_DETAIL_CONTENT: `${BASE_URL}/restapi/customaboutuslvl2content/aboutuscategory/trade-shows`,
  ABOUTUS_EXECUTIVE_COMMITTEE_CONTENT: `${BASE_URL}/restapi/customaboutuslvl2content/aboutuscategory/about-us/executive-committee`,
  ABOUTUS_OPERATIONAL_LEADERS_CONTENT: `${BASE_URL}/restapi/customaboutuslvl2content/aboutuscategory/about-us/operational-leaders`,
  CAREERS_AVAILABLE_POSITIONS_CONTENT: `${BASE_URL}/restapi/customcareerslvl2content/careersavailablepositions`,
  TECHNOLOGY_CATEGORY_DATA: `${BASE_URL}/restapi//customtechnologylvl2content/technologycategory/our-approach`,
  STAFFING_AVAILABLE_POSITIONS_CONTENT: `${BASE_URL}/restapi/customstaffinglvl2content/available-positions`,
  STAFFING_CONTACTS_CONTENT: `${BASE_URL}/restapi/customstaffinglvl2content/staffingcontacts`,
  SOFTWARE_SUPPORT_MAINTENANCE_CATEGORY_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl2/softwaresupportmaintenancecategory`,
  SOFTWARE_SUPPORT_FAQ_CATEGORY_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl2/softwaresupportfaqcategory`,
  SOFTWARE_SUPPORT_RELEASENOTES_CATEGORY_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl2/softwaresupportreleasenotescategory`,
  SOFTWARE_GLOBAL_CATEGORY_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl2/globalsoftwaresupportcategory`,
  SOFTWARE_ADDITIONAL_CATEGORY_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl2/additionalsoftwareservicescategory`,
  SOFTWARE_SERVICE_CONTENT: `${BASE_URL}/restapi/customsoftwareservicecontentlvl2/softwarecategory`,
  GLOBAL_PARTNERS_CONTENT: `${BASE_URL}/restapi/customsoftwareglobalpartnerscontentlvl2/softwarecategory`,
  PRODUCT_IMAGE_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/productimage`,
  PRODUCT_SOFTWARE_RELATED_PROGRAM_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/softwareregulatoryagencyprogram`,
  PRODUCT_SOFTWARE_MODULES_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/softwaremodules`,
  PRODUCT_SOFTWARE_PRODUCT_TOUR_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/softwareproducttour`,
  PRODUCT_SOFTWARE_RESOURCES_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/softwareresources`,
  PRODUCT_VIDEO_CAROUSEL_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/videocarousel`,
  PRODUCT_SOFTWARE_REQUIREMENT_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/softwarerequirement`,
  PRODUCT_LIST_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/productlist`,
  PRODUCT_TRAINING_RELATED_CONTENT: `${BASE_URL}/restapi/customsoftwareproductcontent/productrelatedtrainingdata`,
  SOFTWARE_SUPPORT_RELEASE_NOTES_CONTENT: `${BASE_URL}/restapi/customsoftwaresupportcontentlvl3/softwaresupportreleasenotescategory`,
  TRAINING_CATEGORY_CONTENT: `${BASE_URL}/restapi/customtrainingcontentlvl2/trainingcategory`,
  INDIVIDUAL_STATE_LOCATION_NEWS_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl2/newscontent`,
  INDIVIDUAL_STATE_LOCATION_MAP_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl2/mapinformation`,
  INDIVIDUAL_STATE_LOCATION_LEADERSHIP_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl2/localleadership`,
  INDIVIDUAL_STATE_LOCATION_LOCALLINK_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl2/locallink`,
  INDIVIDUAL_OFFICE_LOCATION_NEWS_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl3/newscontent`,
  INDIVIDUAL_OFFICE_LOCATION_MAP_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl3/mapinformation`,
  INDIVIDUAL_OFFICE_LOCATION_LEADERSHIP_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl3/localleadership`,
  INDIVIDUAL_OFFICE_LOCATION_LOCALLINK_CONTENT: `${BASE_URL}/restapi/customlocationcontentlvl3/locallink`,
  SERVICE_BRAND_CATEGORY_CONTENT: `${BASE_URL}/restapi/customservicebrandcontent/servicebrandcategory`,
  SERVICE_BRAND_AUTHOR_CONTENT: `${BASE_URL}/restapi/customservicebrandcontent/servicebrandglobalauthor`,
  NEWS_ARTICLE_AUTHOR_CONTENT: `${BASE_URL}/restapi/customnewscontentlvl2/authorcontent`,
  NEWS_ARTICLE_TAG_CONTENT: `${BASE_URL}/restapi/customnewscontentlvl2/tagcontent`,
  RESOURCES_SEARCH: {
    GENERAL_RESOURCE: `${TRINITY_SITE_SEARCH}/indexes/resource/docs/search?api-version=2016-09-01`,
    SOFTWARE_RESOURCE: `${TRINITY_SITE_SEARCH}/indexes/softwareresource/docs/search?api-version=2016-09-01`,
  },
  NEWS_SEARCH_GENERAL: `${TRINITY_SITE_SEARCH}/indexes/news/docs/search?api-version=2016-09-01`,
  USER_CART_ACCOUNT: {
    SHOPPING_CART_URL: '/restapi/customcontent/shopping-cart',
    ATTENDEES_CART_URL: '/restapi/customcontent/shopping-cart/attendees',
    REVIEW_CART_URL:
      '/restapi/customcontent/shopping-cart/attendees/review-order',
    PAYMENT_CART_URL:
      '/restapi/customcontent/shopping-cart/attendees/review-order/payment',
    ORDER_CONFIRMATION:
      '/restapi/customcontent/shopping-cart/attendees/review-order/payment/order-confirmation',
  },
  IMPERSONATE_SEARCH_GENERAL: `${TRINITY_SITE_SEARCH}/indexes/impersonateuser/docs/search?api-version=2016-09-01`,
  TRAINING_NEW_SEARCH: {
    TRAINING_SEARCH_URL: `${TRINITY_SITE_SEARCH}/indexes/trainingsearch/docs/search?api-version=2016-09-01`,
  },
  SAFE_BRIDGE_SEARCH: {
    SAFE_BRIDGE_URL: `${TRINITY_SITE_SEARCH}/indexes/analyticalmethods/docs/search?api-version=2016-09-01`,
    SECOND_LEVEL: `${BASE_URL}/restapi/customlaboratorysubsubcategoriescontent/`,
    HEADER_CONTENT: `${BASE_URL}/restapi/customlaboratorysubcategoriescontent/`,
    SAFEBRIDGE_PDF_CONTENT: `${BASE_URL}/restapi/customsafebridgepdf/`,
  },
  CAREER_BUSINESS_AREA_CONTENT: `${BASE_URL}/restapi/customcareerscontent/careersbusinessareacontent`,
  CAREER_EXPERIENCE_LEVEL_CONTENT: `${BASE_URL}/restapi/customcareerscontent/careersexperiencelevelcontent`,
  CAREER_JOB_LOCATION_CONTENT: `${BASE_URL}/restapi/customcareerscontent/careersjoblocationcontent`,
};
