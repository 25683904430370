import React from 'react';
import ForgotPasswordForm from '../../../components/ecommerce/forms/password-forms/forgot-password/forgot-password.component';

function ForgotPassword() {
  return (
    <div className="forgot-password">
      <ForgotPasswordForm />
    </div>
  );
}

export default ForgotPassword;
