import testImage from '../../../../../images/about/testBioPic.svg';

export const title = 'Our Leadership Team';

export const grid_title_1 = 'Executive Committee';
export const grid_title_2 = 'Operational Leaders';
export const content =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut laboredolore magna aliqua maecenas accumsan lacus.';
export const link = 'Suspendisse poten ›';

export const name = 'Mike Remsberg';
export const person_title = 'Managing Director, Trinity Consultants';
export const person_content =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut laboredolore magna aliqua maecenas accumsan lacus.';
export const person_link = 'Suspendisse poten ›';
export const image = testImage;

export const boxCtaData = [
  {
    grid_title: 'Executive Committee',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut laboredolore magna aliqua maecenas accumsan lacus.',
    link: 'Suspendisse poten ›',
    color: 'green',
  },
  {
    grid_title: 'Operational Leaders',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut laboredolore magna aliqua maecenas accumsan lacus.',
    link: 'Suspendisse poten ›',
    color: 'green',
  },
];

export default boxCtaData;
