import React from 'react';
import './cta-grid-ServiceBrand-Author.styles.scss';
import { WEBSITE_URL } from '../../../../js/api/_config';

function AboutLPGrid({ data, mainData }) {
  const mainTitle = mainData != null ? mainData.Title : '';
  const commiteeData = data != null ? data : '';

  return (
    <div className="box-container">
      <p className="title">{mainTitle}</p>
      <div className="ctas">
        {commiteeData?.map((item, i) => (
          <div
            key={i}
            className="bkImg"
            style={{ backgroundImage: `url(${item.ImageUrl})` }}
          >
            <div className="content">
              {item.Name && (
                <p className="name">
                  <a href={WEBSITE_URL + item.AuthorUrl}>{item.Name}</a>
                </p>
              )}
              {item.Title && (
                <p className="job_title">
                  {item.Title}
                  {item != '' && item.Company != '' ? `, ${item.Company}` : ''}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutLPGrid;
