import React, { Suspense } from 'react';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function NewIndustrySecondaryLandingPage({
  page: { MainContent, breadCrumbList },
  section,
}) {
  const HeroComponent = React.lazy(() =>
    import('./new-industry-secondary-hero-component')
  );
  const ContentComponent = React.lazy(() =>
    import('./new-industry-secondary-content-component')
  );

  return (
    <div className="industrial-secondary-b">
      <Suspense fallback={<Loader />}>
        <HeroComponent
          MainContent={MainContent}
          section={section}
          breadCrumbList={breadCrumbList}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <ContentComponent MainContent={MainContent} section={section} />
      </Suspense>
    </div>
  );
}

export default NewIndustrySecondaryLandingPage;
