import React from 'react';
import ResetFailedCom from '../../../components/ecommerce/forms/password-forms/reset-failed/reset-failed.component';

function ResetFailed() {
  return (
    <div className="pswd-reset-failed">
      <ResetFailedCom />
    </div>
  );
}

export default ResetFailed;
