import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import { device } from '../../../components/new-home-page/components/misc/DeviceSize';

const PrimaryBackgroundContainer = styled.div((props) => [
  tw`py-20 lg:py-40 bg-primary-500 relative`,
  `
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url(${props.promoCodeDesktopImage});
        
    @media screen and ${device.laptop} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeDesktopImage});
    }
    
    @media screen and ${device.laptopL} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeDesktopImage});
    }
    
    @media screen and ${device.mobileL} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    
    @media screen and ${device.mobileM} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    
    @media screen and ${device.mobileS} {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-image: url(${props.promoCodeMobileImage});
    }
    `,
]);

const Container = styled.div`
  overflowy: 'hidden';
  ${tw`relative my-24 bg-center bg-cover`}
`;

function TrainingImageBar({
  heading = 'Leap Into Training',
  content = 'SAVE 29% on any single classroom training course when you register in February',
  disclaimerText = 'Any single classroom training course when you register in February',
  promoCodeDesktopImage = 'https://i.ibb.co/59QBcHR/Oct-Promo-BAN.jpg',
  promoCodeMobileImage = 'https://i.ibb.co/b2rdqDD/Microsoft-Teams-image.png',
}) {
  return (
    <Container style={{ zIndex: 100 }}>
      <PrimaryBackgroundContainer
        promoCodeDesktopImage={promoCodeDesktopImage}
        promoCodeMobileImage={promoCodeMobileImage}
      />
    </Container>
  );
}

export default TrainingImageBar;
