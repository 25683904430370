import React, { useEffect, useState } from 'react';
import WebinarForm from '../../../components/commons/forms/webinar-form/webinar-form.component';
import heroImageSrc from '../../../images/consulting-ehs/ehs-primary-background.png';
import Hero from '../../../components/commons/hero/hero.component';
import { bottomTitle, bottomBlurb } from './webinar-sign-up.data';
import ExistingAccountLogin from '../../../components/commons/widgets/existing-account-login-widget/existing-account-login.component';
import { getWebinarSignupPageData } from '../../../js/api/specialty-pages/_webinar-signup.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './webinar-sign-up.styles.scss';

// RP #Task 57 - Webinar Signup web form
function WebinarPage({ page: { modules } }) {
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getWebinarSignupPageData();
      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country != undefined)
  ) {
    return (
      <div className="webinar-container">
        <Hero
          src={
            status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status != noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status != noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="policies"
        />

        <div className="trinity-container">
          <ExistingAccountLogin
            data={
              status != noContent && responseMainContent
                ? responseMainContent
                : ''
            }
          />
          <WebinarForm
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
          <hr />
          <div className="bottom-content">
            <p className="title">
              {status != noContent && responseMainContent.FooterTitle
                ? responseMainContent.FooterTitle
                : ''}
            </p>
            <p className="blurb">
              {status != noContent && responseMainContent.FooterContent
                ? responseMainContent.FooterContent
                : ''}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default WebinarPage;
