import React from 'react';
import TwitterIcon from '../../../../images/about/twitter.svg';
import LinkedInIcon from '../../../../images/about/linkedin.svg';
import FBIcon from '../../../../images/about/fb.svg';
import MailIcon from '../../../../images/about/mail.svg';

import './social-links.styles.scss';

function SocialLinks(props) {
  return (
    <div className="social-links-container">
      <p className="share">Share: </p>
      <div className="links">
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => props.onClickSocialIcon('twitter', props)}
        >
          <img src={TwitterIcon} alt="" />
        </a>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => props.onClickSocialIcon('linkedin', props)}
        >
          <img src={LinkedInIcon} alt="" />
        </a>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => props.onClickSocialIcon('facebook', props)}
        >
          <img src={FBIcon} alt="" />
        </a>
        <a
          style={{ cursor: 'pointer' }}
          onClick={() => props.onClickSocialIcon('email', props)}
        >
          <img src={MailIcon} alt="" />
        </a>
      </div>
    </div>
  );
}

export default SocialLinks;
