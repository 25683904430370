import React, { useState, useEffect } from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/secondary-a-hero.jpg';
import RequestTrainingQuoteOuter from '../../../components/training/forms/custom-training/custom-training.component';
import { getRequestTrainingQuotePageData } from '../../../js/api/trainingForm/_request-training-quote.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './request-training-quote.styles.scss';

function RequestTrainingQuote({ page: { modules } }) {
  // #RP - Task 64 - Request training quote
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getRequestTrainingQuotePageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country != undefined)
  ) {
    return (
      <div className="custom-training-container">
        <Hero
          src={
            status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status != noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status != noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          description={responseMainContent.HeaderSubTitle}
          colorCategory="train"
        />
        <div className="trinity-container">
          <RequestTrainingQuoteOuter
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default RequestTrainingQuote;
