export default function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.jobTitle) {
    errors.jobTitle = 'Job Title is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone number field - ex: 1234567890';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.title) {
    errors.title = 'Title is required';
  }
  if (!values.startDate) {
    errors.startDate = 'Start Date is required';
  }
  if (!values.recaptchaValue) {
    errors.recaptchaValue = 'recaptcha is required';
  }

  return errors;
}
