import React from 'react';

import './training-policies-sidebar.styles.scss';

function TrainingPolices({ buttonText }) {
  return (
    <div className="cart-sidebar">
      <div
        dangerouslySetInnerHTML={{
          __html:
            '<p>Training Registration Policies</p><div><li>Course fees are payable upon registration.</li><li>Only one discount can be applied to your purchase.</li><li>The course fee is per attendee. Recording our courses is strictly prohibited.</li><li>Cancellations received more than 72 hours prior to a course are eligible for a full refund or transfer to another course. Cancellations received less than 72 hours prior to a course may be transferred to another course; however, no refund will be provided. If Trinity Consultants&nbsp;cancels a course, full refunds will be provided.</li><li>Trinity Consultants assumes no responsibility for participants&rsquo; travel expenses including, in the case of course cancellation, reimbursement for non-refundable reservations.</li><li>These courses are intended primarily for environmental professionals from industry and government agencies. Trinity Consultants&nbsp;reserves the right to decline registrations at its discretion to persons for whom its courses may not be intended.</li></div>',
        }}
      />
    </div>
  );
}

export default TrainingPolices;
