import React, { useEffect } from 'react';
import './widget-box.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import CommonWidgetBox from './common-widgetBox.component';
import {
  trainingcoursedata,
  resourcesdata,
  newsdata,
  productdata,
} from '../../../../redux/widget-box/widget-box.action';

function ResourcesConnectWidgetBox({ relatedResources }) {
  return (
    <div>
      {relatedResources && relatedResources.length > 0 && (
        <div className="widgetBox-container">
          <h5 className="widget-title">Related Resources</h5>
          {relatedResources &&
            relatedResources
              .sort((a, b) =>
                a.strPublishedDate < b.strPublishedDate ? 1 : -1
              )
              // .sort((a, b) => a.strPublishedDate < b.strPublishedDate ? 1 : -1)
              .map((widget) => {
                if (widget.Title != undefined) {
                  return (
                    <CommonWidgetBox
                      widgetImage={widget.GlobalIcon}
                      widgetContent={widget.Title}
                      widgetContentLink={widget.Url}
                      widgetDate={widget.strPublishedDate}
                      widgetSubContent=""
                      singleLinkContent=""
                      singleLink=""
                    />
                  );
                }
              })}
          <div className="widget-link">
            <a
              href="/resources"
              target="_blank"
              className="single-link"
              rel="noreferrer"
            >
              All Resources ›
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResourcesConnectWidgetBox;
