import React, { useEffect, useMemo, useState } from 'react';
import { widgetData, title, link } from './career-listing.data';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import './career-lisiting.styles.scss';
import { ENDPOINTS } from '../../../../js/api/_config';
import SelectList from '../../../commons/form-inputs/select-list/select-list.component';
import { sendToURL, groupBy } from '../../../../utilities/CommonUtilities';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';

// #RP - Task 124 Career
function CareerListing({ avadata, subTitle }) {
  // const data = widgetData;
  // RP - SD19: Adding Advanced Search Filters
  const [BusinessArea, setBusinessArea] = useState();
  const [ExperienceLevel, setExperienceLevel] = useState();
  const [JobLocation, setJobLocation] = useState();
  const [AvailablePositionData, setAvailablePositionData] = useState();
  const [FilterAvailablePositionData, setFilterAvailablePositionData] =
    useState();
  const [readMore, setReadMore] = useState(false);

  const [SelectBusinessArea, setSelectBusinessArea] =
    useState('- Business Area -');
  const [SelectExperienceLevel, setSelectExperienceLevel] = useState(
    '- Experience Level -'
  );
  const [SelectJobLocation, setSelectJobLocation] =
    useState('- Job Location -');

  const history = useHistory();

  useEffect(() => {
    let data = {};
    if (window.location.search.length > 0) {
      data = qs.parse(window.location.search.substring(1));

      if (data?.ba) setSelectBusinessArea(data?.ba);

      if (data?.el) setSelectExperienceLevel(data?.el);

      if (data?.jl) setSelectJobLocation(data?.jl);

      if (Object.keys(data).length > 0) {
        const barea = data?.ba;
        const elevel = data?.el;
        const jlocation = data?.jl;
        // getAvailablePositionDataFromAPIsOnPageLoad().then(e => {
        // var avadata = e && e.CareerContent && e.CareerContent.AvailablePositionsContent && e.CareerContent.AvailablePositionsContent.IndividualAvailablePositionsContent;
        setAvailablePositionData(avadata);
        setbusinessareadropdowndata(avadata);
        setexperienceleveldropdowndata(avadata);
        setjoblocationdropdowndata(avadata);
        filterdata(avadata, barea, elevel, jlocation);
        // });
      } else {
        // getAvailablePositionDataFromAPIsOnPageLoad().then(e => {
        // var avadata = e && e.CareerContent && e.CareerContent.AvailablePositionsContent && e.CareerContent.AvailablePositionsContent.IndividualAvailablePositionsContent;
        setAvailablePositionData(avadata);
        filterdata(
          avadata,
          SelectBusinessArea,
          SelectExperienceLevel,
          SelectJobLocation
        );
        setbusinessareadropdowndata(avadata);
        setexperienceleveldropdowndata(avadata);
        setjoblocationdropdowndata(avadata);
        // });
      }
    } else {
      // getAvailablePositionDataFromAPIsOnPageLoad().then(e => {
      // var avadata = e && e.CareerContent && e.CareerContent.AvailablePositionsContent && e.CareerContent.AvailablePositionsContent.IndividualAvailablePositionsContent;
      setAvailablePositionData(avadata);
      filterdata(
        avadata,
        SelectBusinessArea,
        SelectExperienceLevel,
        SelectJobLocation
      );
      setbusinessareadropdowndata(avadata);
      setexperienceleveldropdowndata(avadata);
      setjoblocationdropdowndata(avadata);
      // });
    }
  }, [window.location]);

  function setbusinessareadropdowndata(avadata) {
    let obj = [];
    let result = avadata.map((a) => a.BusinessArea);
    result = result.filter((x) => x.length > 0);
    result.map((item) => {
      if (item.length > 0) {
        item.map((data) => {
          const zzz = obj.find((y) => y.label === data.label);
          if (
            obj.length === 0 ||
            (obj.length > 0 && (zzz === undefined || zzz.length === 0))
          )
            obj.push(data);
        });
      }
    });

    const fdata = obj.filter(
      (x) => x.value !== '7e66d22a-1514-4898-972f-647aed3ab002'
    ); // Other
    const odata = obj.find(
      (x) => x.value === '7e66d22a-1514-4898-972f-647aed3ab002'
    ); // Other
    obj = fdata && fdata.sort((a, b) => (a.label > b.label ? 1 : -1));

    if (odata) obj.push(odata);

    setBusinessArea(obj);
  }

  function setexperienceleveldropdowndata(avadata) {
    let obj = [];
    let result = avadata.map((a) => a.ExperienceLevel);
    result = result.filter((x) => x.length > 0);
    result.map((item) => {
      if (item.length > 0) {
        item.map((data) => {
          const zzz = obj.find((y) => y.label === data.label);
          if (
            obj.length === 0 ||
            (obj.length > 0 && (zzz === undefined || zzz.length === 0))
          )
            obj.push(data);
        });
      }
    });

    obj = obj.sort((a, b) => (a.label > b.label ? 1 : -1));
    setExperienceLevel(obj);
  }
  // RK -- CareerPage Changes -- 03/11/2022
  function setjoblocationdropdowndata(avadata) {
    let USA = [];
    const Separater = [
      {
        Country: 'Other',
        city: '----------',
        countryid: '',
        joblocationtype: '',
        label: '--------',
        state: '---------------',
        stateid: '0000',
        value: '------',
      },
    ];
    const OtherCountries = [];

    let result = avadata.map((a) => a.LstJobLocation);
    result = result.filter((x) => x.length > 0);
    result = groupBy(result, 'Country');
    Object.keys(result).map((item) => {
      if (result[item].length > 0) {
        result[item].map((data) => {
          data.map((dat) => {
            const zzz = USA.find((y) => y.state === dat.state); // RP - SD19: Adding Advanced Search Filters
            const zzz2 = OtherCountries.find((y) => y.state === dat.state);
            if (
              dat.stateid != undefined &&
              dat.Country !== undefined &&
              dat.Country != null &&
              dat.Country == 'United States' &&
              dat.stateid &&
              (USA.length === 0 ||
                (USA.length > 0 && (zzz === undefined || zzz.length === 0)))
            )
              USA.push(dat);
            if (
              dat.stateid != undefined &&
              dat.Country !== undefined &&
              dat.Country != null &&
              dat.Country !== 'United States' &&
              dat.stateid &&
              (OtherCountries.length === 0 ||
                (OtherCountries.length > 0 &&
                  (zzz2 === undefined || zzz2.length === 0)))
            )
              OtherCountries.push(dat);
          });
        });
      }
    });
    USA = USA.sort((a, b) => (a.state > b.state ? 1 : -1));
    USA = USA.concat(
      Separater,
      OtherCountries.sort((a, b) => (a.state > b.state ? 1 : -1))
    );
    setJobLocation(USA);
  }

  function filterdata(
    record,
    stbusinessarea,
    stexperiencelevel,
    stjoblocation
  ) {
    let obj = record;
    if (stbusinessarea && stbusinessarea !== '- Business Area -') {
      var result = [];
      obj.map((item) => {
        item &&
          item.BusinessArea &&
          item.BusinessArea.map((bdata) => {
            if (bdata.value === stbusinessarea) {
              result.push(item);
            }
          });
      });

      obj = result;
    }
    if (stexperiencelevel && stexperiencelevel !== '- Experience Level -') {
      var result = [];
      obj.map((item) => {
        item &&
          item.ExperienceLevel &&
          item.ExperienceLevel.map((bdata) => {
            if (bdata.value === stexperiencelevel) {
              result.push(item);
            }
          });
      });

      obj = result;
    }
    if (stjoblocation && stjoblocation !== '- Job Location -') {
      var result = [];
      obj.map((item) => {
        item &&
          item.LstJobLocation &&
          item.LstJobLocation.map((bdata) => {
            if (bdata.stateid === stjoblocation) {
              result.push(item);
            }
          });
      });

      obj = result;
    }

    setFilterAvailablePositionData(obj);
  }

  // const getAvailablePositionDataFromAPIsOnPageLoad = async () => {
  //   return await fetch(ENDPOINTS.CAREERS_LANDING_INDIVIDUAL_POSITION).then((response) => {
  //     return response.json();
  //   });
  // }

  const handleApplyClick = (url) => {
    if (url) window.open(url);
  };

  const handleChange = (event) => {
    event.persist();

    if (event.target.name === 'businessarea') {
      setSelectBusinessArea(event.target.value);
    } else if (event.target.name === 'experiencelevel') {
      setSelectExperienceLevel(event.target.value);
    } else if (event.target.name === 'joblocation') {
      setSelectJobLocation(event.target.value);
    }
  };

  const handleSubmit = () => {
    const data = {
      ba: SelectBusinessArea,
      el: SelectExperienceLevel,
      jl: SelectJobLocation,
    };
    sendToURL(history, data);

    filterdata(
      AvailablePositionData,
      SelectBusinessArea,
      SelectExperienceLevel,
      SelectJobLocation
    );
  };

  const handleClear = () => {
    if (
      SelectBusinessArea === '- Business Area -' &&
      SelectExperienceLevel === '- Experience Level -' &&
      SelectJobLocation === '- Job Location -'
    ) {
      handleNotify('There are no filters applied.', ToasterTypes.Error);
    } else {
      setSelectBusinessArea('- Business Area -');
      setSelectExperienceLevel('- Experience Level -');
      setSelectJobLocation('- Job Location -');
      setFilterAvailablePositionData(AvailablePositionData);
    }

    window.history.replaceState(null, '', `${history?.location?.pathname}`);
  };

  const data = useMemo(() => {
    if (!FilterAvailablePositionData) {
      return [];
    }
    const temp = [...FilterAvailablePositionData];
    if (temp.length > 10 && !readMore) {
      return temp.slice(0, 9);
    }
    return temp;
  }, [FilterAvailablePositionData, readMore]);

  // RP - SD19: Adding Advanced Search Filters
  return (
    <>
      <div className="widget-container widger-search-bar">
        <div className="search-by-section">
          <div className="search-by-section1">
            <p>
              <strong>Search By :</strong>
            </p>
          </div>
          <div className="search-by-section1 col-md-3">
            {BusinessArea && (
              <SelectList
                onChange={handleChange}
                name="businessarea"
                value={SelectBusinessArea || '- Business Area -'}
                defaultText="- Business Area -"
                className="mb-2 sm-2"
              >
                {BusinessArea &&
                  BusinessArea.map((item) => (
                    <option className="filter" value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </SelectList>
            )}
          </div>
          <div className="search-by-section1 col-md-3">
            {ExperienceLevel && (
              <SelectList
                onChange={handleChange}
                name="experiencelevel"
                value={SelectExperienceLevel || '- Experience Level -'}
                defaultText="- Experience Level -"
                className="mb-2"
              >
                {ExperienceLevel &&
                  ExperienceLevel.map((item) => (
                    <option className="filter" value={item.value}>
                      {item.label}
                    </option>
                  ))}
              </SelectList>
            )}
          </div>
          <div className="search-by-section1 col-md-3">
            {JobLocation && (
              <SelectList
                onChange={handleChange}
                name="joblocation"
                value={SelectJobLocation || '- Job Location -'}
                defaultText="- Job Location -"
                className="mb-2"
              >
                {JobLocation &&
                  JobLocation.filter(
                    (x) => x.state !== '' && x.city !== ''
                  ).map((item) => (
                    <option className="filter" value={item.stateid}>
                      {item.state}
                    </option>
                  ))}
              </SelectList>
            )}
          </div>
          <div className="search-by-section1">
            <div>
              <CustomButton
                onClick={handleSubmit}
                style={{
                  height: 'fit-content',
                  width: '-webkit-fill-available',
                }}
                className="d-block button-spacer"
              >
                Search
              </CustomButton>
            </div>
          </div>
          <div className="search-by-section1">
            <div>
              <CustomButton
                onClick={handleClear}
                style={{
                  height: 'fit-content',
                  width: '-webkit-fill-available',
                }}
                className="d-block button-spacer"
              >
                Clear All
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="career-listing-no-pag-wrapper">
        <p className="title">{subTitle}</p>
        {data &&
          data.length > 0 &&
          data.map((position, i) => (
            <div key={i} className="widgetBox-wrapper">
              <div className="widget-wrapper">
                <div className="content">
                  <p className="department_title">{position.Company}</p>
                  <a href={position.RouteUrl} className="job_title">
                    {position.JobTitle}
                  </a>
                  <p className="location">{position.JobLocation}</p>
                  {/* <p className="location">{position.City}{position.State != "" ? ', '  + position.State : ""}</p> */}
                  {/* <p className="multiple-locations">
                {position.multiple_locations}
              </p> */}
                </div>
                <CustomButton
                  style={{ height: 'fit-content' }}
                  className="d-block button-spacer"
                  onClick={() => handleApplyClick(position.RouteUrl)}
                >
                  Apply Now
                </CustomButton>
              </div>
              <hr />
            </div>
          ))}
        {data && data.length == 0 && (
          <div>
            <p>No Positions Available</p>
          </div>
        )}
      </div>
      <div>
        {FilterAvailablePositionData &&
          FilterAvailablePositionData.length > 10 && (
            <a
              style={{ color: '#ef6008', cursor: 'pointer', float: 'left' }}
              onClick={() => setReadMore((e) => !e)}
            >
              {readMore ? 'View Less ' : 'View More '}
              <i
                className={readMore ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}
                style={{ fontSize: '15px', color: '#ef6008' }}
                aria-hidden="true"
              />
            </a>
          )}
        <a
          href="/careers/available-positions"
          className="link"
          style={{ color: '#ef6008', cursor: 'pointer', float: 'right' }}
        >
          {link}
        </a>
      </div>
    </>
  );
}

export default CareerListing;
