import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Hero from '../../../components/commons/hero/hero.component';
import ContactCta from '../../../components/commons/cta/contact-cta/contact-cta.component';
import WidgetBox from '../../../components/commons/widgets/widgetBox-component/WidgetBox.component';
import ContentWithSidebar from '../../../layouts/content-with-side-bar/content-with-sidebar.layout';
import ContentWell from '../../../components/commons/content-well/content-well.component';
import Breadcrumbs from '../../../components/commons/breadcrumbs/breadcrumbs.component';

import { getLinksFromCategoryContent } from '../../../js/helpers/_get-links-from-category-content';
import widgetData from '../../../components/commons/widgets/widgetBox-component/widgetBox-data';

function TechnologyIndividualCategory({
  page: { MainContent, CategoryContent },
  section,
}) {
  const breadcrumbLinks = CategoryContent
    ? getLinksFromCategoryContent(CategoryContent)
    : [];

  const data = widgetData;
  if (MainContent) {
    return (
      <div
        className={`technology technology-individual-category ${section || ''}`}
      >
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          title={MainContent.Title}
          description={MainContent.IntroText}
          template="featured"
          colorCategory="our-approach"
        />
        <div className="sm-up-trinity-container module-spacer">
          <ContactCta
            Title="Contact us to learn how we can help your business."
            subTitle="Contact Trinity Consulting"
            subTitleLink="#"
          />

          {breadcrumbLinks.length ? (
            <div className="xs-only-trinity-container">
              <Breadcrumbs links={breadcrumbLinks} />
            </div>
          ) : null}

          <ContentWithSidebar
            left={
              <div className="xs-only-trinity-container">
                <ContentWell content={MainContent.Content} />
              </div>
            }
            right={
              <Row>
                {data.map((widget, k) => (
                  <Col sm={6} md={12} lg={12} key={widget.title + k}>
                    <WidgetBox
                      widgetTitle={widget.widgetTitle}
                      widgetContent={widget.widgetContent}
                      widgetSubContent={widget.widgetSubContent}
                      singleLink={widget.singleLink}
                      widgetLink={widget.widgetLink}
                      widgetImage={widget.widgetImage}
                    />
                  </Col>
                ))}
              </Row>
            }
          />
        </div>
      </div>
    );
  }

  return null;
}

export default TechnologyIndividualCategory;
