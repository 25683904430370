import { TrinitySearchServices } from './trinity-search.services';
import { TrinitySearchTypes } from './trinity-search.types';

export const trinitySearchData = (requestBody) => (dispatch, getState) => {
  TrinitySearchServices.searchForNewsSiteSearch(requestBody).then(
    (response) => {
      const { trinitySearchData } = getState();

      dispatch({
        type: TrinitySearchTypes.LAST_SEARCH_DATA,
        payload: requestBody,
      });

      dispatch({
        type: TrinitySearchTypes.TRINITY_SEARCH_NEWS_DATA,
        payload: response,
        currentState: trinitySearchData,
      });
    }
  );
};
