import { ENDPOINTS } from './_config';

export const getFooterItems = async () => {
  try {
    const request = await fetch(ENDPOINTS.FOOTER);
    const response = await request.json();

    localStorage.setItem('footer', JSON.stringify(response));
    localStorage.setItem('footer-time-stamp', Date.now());

    return response;
  } catch (e) {
    return null;
  }
};
