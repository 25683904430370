import React from 'react';
import './contact-widget.styles.scss';

function ContactWidget(data) {
  return (
    <>
      <div className="contact-container">
        {/* #RP - Task 46 - Bind data to ContactUs Form in Front-End. */}
        <p className="title">{data.data.SideTitle}</p>
        <p className="content">{data.data.SideContent}</p>
      </div>
      <div className="contact-container">
        {/* #RP - Task 46 - Bind data to ContactUs Form in Front-End. */}
        <p className="title">{'ADD ME TO THE MAILING LIST '}</p>
        <p className="content">
          {
            'When checking the box "add me to the mailing list", you are agreeing to be adding to our mailing list and will receive our eNews, periodic publications on EHS, Engineering, and Science topics, and related training notifications.'
          }
        </p>
        <p className="content">
          {
            'You may unsubscribe at any time by selecting the Unsubscribe link in emails from Trinity Consultants or requesting removal at unsubscribe@trinityconsultants.com.'
          }
        </p>
      </div>
    </>
  );
}

export default ContactWidget;
