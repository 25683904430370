import React from 'react';
import './sidebar-contact-cta.styles.scss';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import { BREEZE_EMAIL_ADDRESS } from '../../../../js/api/_config';

function SidebarContactCta({ isFromSoftwareProduct }) {
  const history = useHistory();

  const onPressContactButton = () => {
    if (isFromSoftwareProduct) {
      window.open(`mailto:${BREEZE_EMAIL_ADDRESS}`, '_blank');
    } else {
      history.push('/contactus');
    }
  };

  return (
    <div className="xs-only-trinity-container mb-3 mb-sm-4 mb-md-0">
      <div className="sidebar-contact-cta">
        <h3 className="title">Contact us to learn more</h3>
        <div className="content">
          <CustomButton
            onClick={() => window.open('tel:97266-8308881')}
            rounded
            altColors
          >
            Speak to an Expert
          </CustomButton>
          <p className="phone-number">
            <span>Software Team</span>
            <span className="seperator"> | </span>
            <span className="number">
              <a style={{ color: '#000' }} href="phone:+1 972.661.8881">
                +1 972.661.8881
              </a>
            </span>
          </p>
          <CustomButton
            onClick={() => onPressContactButton()}
            rounded
            altColors
          >
            Contact Us
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default SidebarContactCta;
