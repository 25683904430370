import { ENDPOINTS } from '../_config';

export const getUserCountries = async () => {
  try {
    const request = await fetch(ENDPOINTS.GET_USER_COUNTRIES);
    const response = await request.json();

    return response.Countries;
  } catch (e) {
    return null;
  }
};
