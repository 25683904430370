export const confirmPassword = (password, confirmPassword) => {
  if (password.length < 7) {
    return false;
  }
  if (password.trim() !== confirmPassword.trim()) {
    return false;
  }

  return true;
};
