import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory, Link } from 'react-router-dom';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/software/software_lp_hero.png';
import RequestDemoWidget from '../../../components/software/widgets/request-demo-container/request-demo.component';
import ImageCarousel from '../../../components/software/image-carousel/image-carousel.component';

import './software-individual-product-detail.styles.scss';

function SoftwareIndividualProductDetail({
  page: { modules, MainContent, CategoryContent, SoftwareContent },
}) {
  const [secSoftwareProductTourContent, setsecSoftwareProductTourContent] =
    useState([]);

  /* NL: TASK 53 # Software Product Page */
  useEffect(() => {
    if (SoftwareContent) {
      const response = SoftwareContent;

      if (response.SoftwareProductTourContent) {
        let allsfproducttours = [];
        allsfproducttours = response.SoftwareProductTourContent;
        setsecSoftwareProductTourContent(allsfproducttours);
      }
    }
  }, []);

  const backLink = '< Go Back';
  const history = useHistory();

  return (
    <div className="software-individual-product-tour">
      <Hero
        src={heroImageSrc}
        alt={modules.hero.hero_alt}
        template="standard"
        title={modules.hero.hero_title}
        description={modules.hero.hero_description}
      />

      <div xs={12} className="trinity-container">
        {backLink && (
          <Link to="/software-product" className="back-link">
            {backLink}
          </Link>
        )}
        <RequestDemoWidget title="Request Demo" />
        <hr />
        <div className="xs-only-trinity-container">
          <ImageCarousel producttours={secSoftwareProductTourContent} />
          <div className="image-content">
            <p className="content">product content</p>
          </div>
        </div>
        {backLink && (
          <Link to="/" className="back-link">
            {backLink}
          </Link>
        )}
      </div>
    </div>
  );
}

export default SoftwareIndividualProductDetail;
