export const NewsActionTypes = {
  GET_PAGEWISE_NEWS: 'GET_PAGEWISE_NEWS',
  TOTAL_ITEM_COUNT: 'TOTAL_ITEM_COUNT',
  NEWS_LOADING: 'NEWS_LOADING',
  ACTIVE_PAGE_NUMBER: 'ACTIVE_PAGE_NUMBER',
  FILTER_LOADING: 'FILTER_LOADING',
  TAGS_FILTER: 'TAGS_FILTER',
  NEWS_CATEGORY_FILTER: 'NEWS_CATEGORY_FILTER',
  INDUSTRY_CATEGORY_FILTER: 'INDUSTRY_CATEGORY_FILTER',
  LOCATION_FILTER: 'LOCATION_FILTER',
  PUBLISHED_DATE_FILTER: 'PUBLISHED_DATE_FILTER',
  PUBLISHED_FROM_DATE_FILTER: 'PUBLISHED_FROM_DATE_FILTER',
  PUBLISHED_TO_DATE_FILTER: 'PUBLISHED_TO_DATE_FILTER',
  NEWS_PAGE_DATA: 'NEWS_PAGE_DATA',
};
