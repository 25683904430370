import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useHistory } from 'react-router-dom';
import useForm from '../../../js/custom-hooks/useForm';
import validate from './request-a-trial.validationRules';
import {
  backLink,
  companyBlurb,
  informationBlurb,
  licenseBlurb,
  link,
} from './request-a-trial.data';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import ReCaptcha from '../../commons/recaptcha/recaptcha.component';
import { submitRequestTrailResponse } from '../../../js/api/softwareForm/_softwareRequestTrail.data';
import './request-a-trial.styles.scss';
import { createToken } from '../../../js/api/_azure-queue.data';
import { ListOfFormUniqueData } from '../../commons/helpers/form-uniqueid/form-uniqueid';
import { ENDPOINTS } from '../../../js/api/_config';

function SoftwareDemoForm({ data, mainData, status }) {
  // #RP - Task 67 - Software Request Trial
  const [dropDownContent, setDropDownContent] = useState(data);
  const [mainContent, setmainContent] = useState(mainData);
  const [noContent] = useState(204);

  const [Country, setCountry] = useState();
  const [Industry] = useState(
    status != noContent && dropDownContent.Industry
      ? dropDownContent.Industry
      : null
  );
  const [State, setState] = useState();
  const [HowDidYouHearAboutUs] = useState(
    status != noContent && dropDownContent.HowDidYouHearAboutUs
      ? dropDownContent.HowDidYouHearAboutUs
      : null
  );
  const [Product] = useState(
    status != noContent && dropDownContent.Product
      ? dropDownContent.Product
      : null
  );
  const SubTitle = mainContent != null ? mainContent.SubTitle : '';
  const Content = mainContent != null ? mainContent.Content : '';

  const history = useHistory();
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitRequestTrailResponse(
        submissionObj,
        token
      );
      history.push('/software/thank-you-page');
    }
  };

  const getCountryDataFromAPIs = async () =>
    await fetch(ENDPOINTS.COUNTRY_CONTENT).then((response) => response.json());

  const getStateDataFromAPIs = async (item) => {
    if (
      item != undefined &&
      item != null &&
      item != '' &&
      item != '— Country —'
    ) {
      return await fetch(`${ENDPOINTS.STATE_CONTENT}?Countryname=${item}`).then(
        (response) => response.json()
      );
    }
    return await fetch(ENDPOINTS.STATE_CONTENT).then((response) =>
      response.json()
    );
  };

  useEffect(() => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  }, [values.Country]);

  useEffect(() => {
    getCountryDataFromAPIs().then((e) => {
      setCountry(e);
    });
  }, []);

  const stateclick = () => {
    getStateDataFromAPIs(values.Country).then((e) => {
      setState(e);
    });
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      Title: values.jobTitle.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      Company: values.companyName.trim(),
      Address: values.address.trim(),
      AddressTwo: values.address2 != null ? values.address2.trim() : 'None',
      City: values.city.trim(),
      State: values.State != null ? values.State.trim() : 'None',
      PostalCode: values.postalCode.trim(),
      Country: values.Country != null ? values.Country.trim() : 'None',
      Industry: values.Industry != null ? values.Industry.trim() : 'None',
      Product:
        values.softwareproduct != null ? values.softwareproduct.trim() : 'None',
      StartDate: values.startDate != null ? values.startDate.trim() : 'None',
      Information:
        values.information != null ? values.information.trim() : 'None',
      HowDidYouHearAboutUs:
        values.howdidyouhearaboutus != null
          ? values.howdidyouhearaboutus.trim()
          : 'None',
      FormUniqueId: ListOfFormUniqueData.request_trial.UniqueId,
      Catalog: ListOfFormUniqueData.request_trial.Catalog,
      Comments: values.comments != null ? values.comments.trim() : 'None',
    };

    submitData(submissionObj);
    // submitClientDetail(submissionObj,mainContent);
  }

  return (
    <div className="software-trial-form-container">
      <div className="back-wrapper xs-only-trinity-container">
        {backLink && (
          <a href="#" className="back-link">
            {backLink}
          </a>
        )}
      </div>
      <form className="trial-form" onSubmit={handleSubmit} noValidate>
        <p className="title" />
        <p className="title" dangerouslySetInnerHTML={{ __html: SubTitle }} />
        <p className="blurb" dangerouslySetInnerHTML={{ __html: Content }} />
        {/* <p className="blurb-2">{blurb_2}</p> */}
        <Row>
          <Col className="left" xs={12} sm={6}>
            <div className="information">
              {informationBlurb && (
                <p className="information-blurb form-title">
                  {informationBlurb}
                </p>
              )}
              {errors.firstName && (
                <span className="help">{errors.firstName}</span>
              )}
              <TextInput
                placeholder="First Name"
                required
                type="text"
                name="firstName"
                onChange={handleChange}
                value={values.firstName || ''}
                autoComplete="off"
                className={`${errors.firstName && 'error'}`}
                maxlength={100}
              />
              {errors.lastName && (
                <span className="help">{errors.lastName}</span>
              )}
              <TextInput
                placeholder="Last Name"
                required
                type="text"
                name="lastName"
                onChange={handleChange}
                value={values.lastName || ''}
                autoComplete="off"
                className={`${errors.lastName && 'error'}`}
                maxlength={100}
              />
              {errors.jobTitle && (
                <span className="help">{errors.jobTitle}</span>
              )}
              <TextInput
                placeholder="Job Title"
                required
                type="text"
                name="jobTitle"
                onChange={handleChange}
                value={values.jobTitle || ''}
                autoComplete="off"
                className={`${errors.jobTitle && 'error'}`}
                maxlength={200}
              />

              {errors.email && <span className="help">{errors.email}</span>}
              <TextInput
                placeholder="Business Email"
                required
                type="email"
                name="email"
                onChange={handleChange}
                value={values.email || ''}
                autoComplete="off"
                className={`${errors.email && 'error'}`}
                maxlength={200}
              />
              {errors.phone && <span className="help">{errors.phone}</span>}
              <TextInput
                placeholder="Phone"
                autoComplete="off"
                className={` ${errors.phone && 'error'}`}
                type="number"
                name="phone"
                onChange={handleChange}
                value={values.phone || ''}
                required
                maxlength={100}
              />
            </div>
            <div className="company-information">
              {companyBlurb && (
                <p className="company-blurb form-title">{companyBlurb}</p>
              )}
              {errors.companyName && (
                <span className="help">{errors.companyName}</span>
              )}
              <TextInput
                placeholder="Company Name"
                type="text"
                name="companyName"
                required
                onChange={handleChange}
                value={values.companyName || ''}
                autoComplete="off"
                className={`${errors.companyName && 'error'}`}
                maxlength={200}
              />
              {errors.address && <span className="help">{errors.address}</span>}
              <TextInput
                placeholder="Business Address 1"
                required
                type="text"
                name="address"
                onChange={handleChange}
                value={values.address || ''}
                autoComplete="off"
                className={`${errors.address && 'error'}`}
                maxlength={300}
              />

              <TextInput
                placeholder="Business Address 2"
                type="text"
                name="address2"
                onChange={handleChange}
                value={values.address2 || ''}
                autoComplete="off"
                maxlength={300}
              />
              {errors.city && <span className="help">{errors.city}</span>}
              <TextInput
                placeholder="City"
                required
                type="text"
                name="city"
                onChange={handleChange}
                value={values.city || ''}
                autoComplete="off"
                className={`${errors.city && 'error'}`}
                maxlength={100}
              />

              <SelectList
                defaultText="— Country —"
                className="mb-2"
                name="Country"
                required
                onChange={handleChange}
                value={values.Country}
              >
                {Country &&
                  Country.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>

              <SelectList
                defaultText="— State/Province —"
                className="mb-2"
                name="State"
                required
                onChange={stateclick}
                value={values.State}
              >
                {State &&
                  State.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
              {errors.postalCode && (
                <span className="help">{errors.postalCode}</span>
              )}
              <TextInput
                placeholder="Postal Code"
                required
                name="postalCode"
                onChange={handleChange}
                value={values.postalCode || ''}
                autoComplete="off"
                className={`${errors.postalCode && 'error'}`}
                maxlength={100}
              />

              <SelectList
                defaultText="— Industry —"
                name="Industry"
                required
                onChange={handleChange}
                value={values.Industry}
              >
                {status != noContent &&
                  Industry.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
            </div>
          </Col>
          <Col className="right" xs={12} sm={6}>
            <div className="license-information">
              {licenseBlurb && (
                <p className="product-blurb form-title">{licenseBlurb}</p>
              )}
              <SelectList
                className="mb-2"
                defaultText="— Select a software product —"
                name="softwareproduct"
                onChange={handleChange}
                value={values.softwareproduct}
              >
                {status != noContent &&
                  Product.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>

              {errors.startDate && (
                <span className="help">{errors.startDate}</span>
              )}

              <TextInput
                placeholder="Select a start date for your limited-time trial"
                required
                type="text"
                name="startDate"
                onChange={handleChange}
                value={values.startDate || ''}
                autoComplete="off"
                className={`${errors.startDate && 'error'}`}
                maxlength={100}
              />

              <textarea
                name="information"
                className="mb-3"
                placeholder="Please include any additional information"
                onChange={handleChange}
                value={values.information}
                maxLength={200}
              />
              <SelectList
                className="mb-2"
                defaultText="— How did you hear about us? —"
                name="howdidyouhearaboutus"
                onChange={handleChange}
                value={values.howdidyouhearaboutus}
              >
                {status != noContent &&
                  HowDidYouHearAboutUs.map(({ Name }) => (
                    <option key={Name} value={Name}>
                      {Name}
                    </option>
                  ))}
              </SelectList>
              <textarea
                name="comments"
                placeholder="comments"
                required
                onChange={handleChange}
                value={values.comments}
              />
            </div>
          </Col>
        </Row>

        <div className="recaptcha">
          <ReCaptcha />
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
      <div className="back-wrapper">
        {backLink && (
          <a href="/software-product" className="back-link">
            {backLink}
          </a>
        )}
      </div>
    </div>
  );
}

export default SoftwareDemoForm;
