import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h5>{children}</h5>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function PaymentInProgressModal(props) {
  const classes = paymentProgressStyle();

  return (
    <div>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={props.processing}
      >
        <DialogContent dividers>
          <Grid container>
            <Grid
              container
              item
              style={{ justifyContent: 'center', marginBottom: '1rem' }}
            >
              <CircularProgress
                variant="indeterminate"
                className={classes.bottom}
                size={40}
                thickness={4}
                {...props}
                value={100}
              />
            </Grid>
            <Grid container item xs={12} style={{ justifyContent: 'center' }}>
              <span style={{ fontSize: '20px' }}>
                Your Payment is processing...
              </span>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const paymentProgressStyle = makeStyles((theme) => ({
  bottom: {
    color: '#9f9f9f',
  },
}));

export default PaymentInProgressModal;
