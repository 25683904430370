import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/training_lp_hero.png';
import Map from '../../../components/locations/map/map.component';
import OfficeDetails from '../../../components/locations/locations-secondary/office-details/office-details.component';
import CountryDetails from '../../../components/locations/locations-secondary/country-details-cta/country-details-cta.component';
import SearchByFilter from '../../../components/locations/locations-secondary/search-by-filter/search-by-filter.component';
import RegionTabs from '../../../components/locations/locations-secondary/region-tabs/region-tabs.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import './locations-secondary.styles.scss';

// RP #Task 94 Location
function LocationsSecondary({
  page: { MainContent, CategoryContent, LocationContent },
}) {
  if (MainContent && MainContent.Title && LocationContent) {
    return (
      <div className="locations-secondary-container">
        <Hero
          src={MainContent.HeaderImage_src}
          mobileImgSrc={MainContent.HeaderMobileImage_src}
          alt={MainContent.HeaderImage_alt}
          template="standard"
          title={MainContent.Title}
          description={MainContent.HeaderContent}
          colorCategory="locations"
        />

        <div className="trinity-container">
          <RegionTabs ctas={CategoryContent} lcontent={LocationContent} />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default LocationsSecondary;
