import React, { useEffect, useState } from 'react';
import './widget-box.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getHomePageRelatedData } from '../../../pages/homepage/homepage.api';
import ConnectWidgetBox from './widget-box.connect';
import classroomImg from '../../../images/homepage/classroom.svg';
import WebinarsImg from '../../../images/homepage/Webinars.svg';
import SeminarsImg from '../../../images/homepage/Seminars.svg';

function WidgetBox() {
  const [response, setResponse] = useState(null);
  const [training, setTraining] = useState([]);
  const [webinars, setWebinars] = useState([]);
  const [seminars, setSeminars] = useState([]);
  const [ondemand, setOnDemand] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getHomePageRelatedData();

      const pushTraining = [];
      const pushWebinars = [];
      const pushSeminars = [];
      const pushOnDemand = [];

      if (getResponse) {
        setResponse(getResponse.data);
        getResponse.data !== undefined &&
          getResponse.data.relatedData !== undefined &&
          getResponse.data.relatedData.forEach((element) => {
            if (element.SessionType === 'Classroom') pushTraining.push(element);
            else if (element.SessionType === 'Webinar')
              pushWebinars.push(element);
            else if (element.SessionType === 'Seminar')
              pushSeminars.push(element);
            else if (element.SessionType === 'OnDemand')
              pushOnDemand.push(element);
          });
        setTraining(pushTraining);
        setWebinars(pushWebinars);
        setSeminars(pushSeminars);
        setOnDemand(pushOnDemand);
      }
    };
    fetchData();
  }, [response, training, webinars, seminars, ondemand]);

  if (response) {
    return (
      <div>
        <Row>
          <Col xs={12} sm={6} md={5} className="widgetBox-container">
            {training && (
              <div>
                <div className="image-title-container">
                  <img src={classroomImg} className="widget-icon" alt="" />
                  <h5 className="widget-title">Upcoming Classroom Training</h5>
                </div>
                {training &&
                  training.map((widget) => (
                    <ConnectWidgetBox
                      widgetContent={widget.CourseName}
                      widgetContentLink={widget.TrainingUrl}
                      widgetSubContent={`${widget.City}, ${widget.State}`}
                      widgetDate={widget.StartDate}
                    />
                  ))}
                <div className="widget-link">
                  <a href="/training" className="single-link">
                    All Training ›
                  </a>
                </div>
              </div>
            )}
          </Col>

          <Col xs={12} sm={6} md={5} className="widgetBox-container">
            {webinars && (
              <div>
                <div className="image-title-container">
                  <img src={WebinarsImg} className="widget-icon" alt="" />
                  <h5 className="widget-title">Upcoming Webinars</h5>
                </div>
                {webinars &&
                  webinars.map((widget) => (
                    <ConnectWidgetBox
                      widgetContent={widget.CourseName}
                      widgetContentLink={widget.TrainingUrl}
                      widgetSubContent={`${widget.City}, ${widget.State}`}
                      widgetDate={widget.StartDate}
                    />
                  ))}
                <div className="widget-link">
                  <a href="/training" className="single-link">
                    All Webinars ›
                  </a>
                </div>
              </div>
            )}
          </Col>

          <Col xs={12} sm={6} md={5} className="widgetBox-container">
            {seminars && (
              <div>
                <div className="image-title-container">
                  <img src={SeminarsImg} className="widget-icon" alt="" />
                  <h5 className="widget-title">Upcoming Seminars</h5>
                </div>
                {seminars &&
                  seminars.map((widget) => (
                    <ConnectWidgetBox
                      widgetContent={widget.CourseName}
                      widgetContentLink={widget.TrainingUrl}
                      widgetSubContent={`${widget.City}, ${widget.State}`}
                      widgetDate={widget.StartDate}
                    />
                  ))}
                <div className="widget-link">
                  <a href="/training" className="single-link">
                    All Seminars ›
                  </a>
                </div>
              </div>
            )}
          </Col>

          <Col xs={12} sm={6} md={5} className="widgetBox-container">
            {ondemand && (
              <div>
                <div className="image-title-container">
                  <img src={classroomImg} className="widget-icon" alt="" />
                  <h5 className="widget-title">Upcoming OnDemand</h5>
                </div>
                {ondemand &&
                  ondemand.map((widget) => (
                    <ConnectWidgetBox
                      widgetContent={widget.CourseName}
                      widgetContentLink={widget.TrainingUrl}
                      widgetSubContent={`${widget.City}, ${widget.State}`}
                      widgetDate={widget.StartDate}
                    />
                  ))}
                <div className="widget-link">
                  <a href="/training" className="single-link">
                    All OnDemand ›
                  </a>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
  return null;
}

export default WidgetBox;
