import * as Sentry from '@sentry/react';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { zeroPaymentSuccessRequest } from '../../../../js/api/shopping-cart/_payment-cart';
import {
  UpdateCartConfirmParams,
  cartCountUpdate,
  updateAllowCartStepsAction,
} from '../../../../redux/shopping-cart/shopping-cart.action';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import './shopping-cart-sidebar.styles.scss';

function ReviewShoppingCartSidebar({ buttonText }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const cartitemupdate = useSelector(
    ({ cart }) => cart.cart_update,
    shallowEqual
  );
  const cartitems = useSelector(({ cart }) => cart.cart, shallowEqual);
  const [submitting, setSubmitting] = useState(false);
  const cartitemimpersonate = useSelector(
    ({ cart }) => cart.impersonateMember,
    shallowEqual
  );
  const handlePayNow = async () => {
    try {
      setSubmitting(true);
      // history.push(location.pathname + '/payment');
      if (cartitems.OrderTotal > 0) {
        dispatch(updateAllowCartStepsAction(4));
        history.push(`${location.pathname}/payment`);
      } else {
        // skip payment and confirm order
        let memberId = null;
        if (cartitemimpersonate) {
          memberId = cartitemimpersonate.memberId;
        }
        const data = {
          memberid: memberId,
          isbothcategory: cartitems.IsBothCategory,
          isonlytraining: cartitems.IsOnlyTraining,
          isonlysoftware: cartitems.IsOnlySoftware,
        };
        const stripeResponse = await zeroPaymentSuccessRequest(data);

        if (
          stripeResponse &&
          stripeResponse.status &&
          stripeResponse.status === 1
        ) {
          const categoryparams = {
            isOnlySoftware: cartitems.IsOnlySoftware,
            isOnlyTraining: cartitems.IsOnlyTraining,
            bothCategory: cartitems.IsBothCategory,
            orderConfirmationNumber:
              stripeResponse.data.OrderConfirmationNumber,
          };
          dispatch(UpdateCartConfirmParams(categoryparams));
          dispatch(cartCountUpdate(1));
          dispatch(updateAllowCartStepsAction(5));
          const pageState = {
            paymentCompleted: stripeResponse.data.PaymentStatus === 'Completed',
          };
          history.push(
            `${location.pathname}/payment/order-confirmation`,
            pageState
          );
        }
      }
      setSubmitting(false);
    } catch (e) {
      {
        /* RP - Add Sentry Changes */
      }
      Sentry.setExtra('Shopping Cart', 'Error in Sidebar Paynow');
      Sentry.captureException(new Error(e), {
        tags: {
          section: 'Error in Sidebar Paynow',
        },
      });
    }
  };

  return (
    <div className="outer-container">
      <div className="shopping-cart-sidebar-container">
        <div className="top-container">
          <Row>
            <Col xs={12} lg={12} md={12}>
              <CustomButton loading={submitting} onClick={handlePayNow}>
                {buttonText}
              </CustomButton>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <p className="order-blurb">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      "By placing your order, you agree to Trinity's<br />privacy notice, and conditions of use",
                  }}
                />
              </p>
            </Col>
          </Row>
        </div>

        <hr />
        <div className="order-summary">
          <p className="title">Order Summary</p>
          <div className="summary-inner">
            <div className="left">
              <p className="items-title">
                Items ({cartitems.ItemsCount}
                ):
              </p>
              <p className="discount-title">Discount: </p>
              <p className="shipping-title">Shipping & handling:</p>
              <p className="tax-title">Total before tax: </p>
              <p className="estimated-tax-title">
                Estimated tax to be collected:
              </p>
            </div>
            <div className="right">
              <p className="items-total">
                <NumberFormat
                  value={cartitems.SubTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
              <p className="discount-total">
                <NumberFormat
                  value={cartitems.DiscountTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
              <p className="shipping-total">
                <NumberFormat
                  value={cartitems.ShippingTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
              <hr />
              <p className="total-before">
                <NumberFormat
                  value={cartitems.SubTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
              {/* Added for Discount Process */}
              <p className="total-before">
                <NumberFormat
                  value={cartitems.TotalBeforeTax}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
              <p className="estimated-total">
                <NumberFormat
                  value={cartitems.TaxTotal}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </p>
            </div>
          </div>
          <hr />
          <div className="order-total-container">
            <p className="total-title">Order total:</p>
            <p className="order-total-amount">
              <NumberFormat
                value={cartitems.OrderTotal}
                displayType="text"
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
              />
            </p>
          </div>
        </div>
      </div>

      <div className="shipping-information">
        <div className="shipping-code">
          {cartitems != undefined &&
            cartitems.DiscountCodes != undefined &&
            Object.entries(cartitems.DiscountCodes).map(([key, value]) => (
              <>
                <i className="fa fa-tag" aria-hidden="true" />{' '}
                <span>{value.toUpperCase()}</span> <span className="fa-times" />
              </>
            ))}
        </div>
        <p className="shipping-blurb">
          {cartitems.DiscountTotal === 0
            ? 'Discounts have not been applied to your order.'
            : 'Discounts has been applied to your order.'}
        </p>
      </div>
    </div>
  );
}

export default ReviewShoppingCartSidebar;
