import React, { useState } from 'react';
import CustomButton from '../../custom-button/custom-button.component';
import TextInput from '../../form-inputs/text-input/text-input.component';
import useForm from '../../../../js/custom-hooks/useForm';
import validate from './job-alert-side.validationRules';
import {
  title,
  first_content,
  second_content,
  link,
} from './job-alert-side-widget.data';
import ReCaptcha from '../../recaptcha/recaptcha.component';
import './job-alert-side-widget.styles.scss';
import {
  submitAzureQueueData,
  createToken,
} from '../../../../js/api/_azure-queue.data';
import { submitJobAlertSignUpResponse } from '../../../../js/api/staffing/_job-alert-signup.data';
import { ListOfFormUniqueData } from '../../helpers/form-uniqueid/form-uniqueid';
import { useHistory } from 'react-router-dom';

function JobAlertWidget({ data }) {
  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );
  const history = useHistory();

  const submitData = async (submissionObj) => {
    if (submissionObj != undefined && submissionObj != null) {
      const token = await createToken();
      const submitResponse = await submitJobAlertSignUpResponse(
        submissionObj,
        token
      );
      history.push('/thank-you-page');
    }
  };

  function submitSuccess() {
    const submissionObj = {
      FirstName: values.firstName.trim(),
      LastName: values.lastName.trim(),
      EmailAddress: values.email.trim(),
      PhoneNumber: values.phone.trim(),
      FormUniqueId: ListOfFormUniqueData.job_alert_sign_up.UniqueId,
      Catalog: ListOfFormUniqueData.job_alert_sign_up.Catalog,
    };

    submitData(submissionObj);
  }

  const onChangeRecaptchaValue = (changevalue) => {
    values.recaptchaValue = changevalue;
  };

  return (
    <div className="job-alert-container">
      <form className="job-alert-form" onSubmit={handleSubmit} noValidate>
        <p className="form-title">{data != undefined ? data.SubTitle : ''}</p>
        <div className="inner-container">
          <p
            className="first_content"
            dangerouslySetInnerHTML={{
              __html: data != undefined ? data.Content : '',
            }}
          />
          {/* <p className="second_content">{second_content}</p> */}
          <div className="inputs">
            <TextInput
              placeholder="First Name"
              required
              type="text"
              name="firstName"
              onChange={handleChange}
              value={values.firstName || ''}
              autoComplete="off"
              className={`${errors.firstName && 'error'}`}
              maxlength={100}
            />
            {errors.firstName && (
              <span className="help">{errors.firstName}</span>
            )}

            <TextInput
              placeholder="Last Name"
              required
              type="text"
              name="lastName"
              onChange={handleChange}
              value={values.lastName || ''}
              autoComplete="off"
              className={`${errors.lastName && 'error'}`}
              maxlength={100}
            />
            {errors.lastName && <span className="help">{errors.lastName}</span>}

            <TextInput
              placeholder="Email"
              type="email"
              name="email"
              required
              onChange={handleChange}
              value={values.email || ''}
              autoComplete="off"
              className={`${errors.email && 'error'}`}
              maxlength={100}
            />
            {errors.email && <span className="help">{errors.email}</span>}

            <TextInput
              placeholder="Phone"
              autoComplete="off"
              className={` ${errors.phone && 'error'}`}
              type="number"
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
              required
              maxlength={100}
            />
            {errors.phone && <span className="help">{errors.phone}</span>}
          </div>
          <div className="recaptcha">
            <div
              style={
                errors &&
                errors.recaptchaValue && {
                  border: '1px solid red',
                  width: 'fit-content',
                }
              }
            >
              <ReCaptcha onChangeRecaptchaValue={onChangeRecaptchaValue} />
            </div>
          </div>
        </div>
        <div className="link">
          <CustomButton className="heroLink">{link}</CustomButton>
        </div>
      </form>
    </div>
  );
}

export default JobAlertWidget;
