import React, { useCallback, useState } from 'react';
import './email-login-form.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import TextInput from '../../../commons/form-inputs/text-input/text-input.component';
import CustomButton from '../../../commons/custom-button/custom-button.component';
import useInputCollection from '../../../../js/custom-hooks/useInputCollection';
import { validateEmailSyntax } from '../../../../js/helpers/_validate-email-syntax';
import {
  userEmailExist,
  userEmailExistRequest,
} from '../../../../js/api/_user-email-exist';
import { UserActionTypes } from '../../../../redux/user/user.types';
import { addIsUserLoginFirstTime } from '../../../../redux/user/user.action';
// import * as Sentry from '@sentry/browser';
// import * as Sentry from '@sentry/tracing';

const INITIAL_STATE = {
  email: '',
};

function EmailLoginForm() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { state, updateInput } = useInputCollection(INITIAL_STATE);

  const [errorMessage, setErrorMessage] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const continueToCreateAccount = () => {
    history.push('create-account');
  };

  const addVerifiedEmail = useCallback(
    (email) =>
      dispatch({ type: UserActionTypes.VERIFIED_EMAIL, payload: email }),
    [dispatch]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const submissionEmail = state.email;

    const validEmailSyntax = validateEmailSyntax(submissionEmail);
    if (!validEmailSyntax) {
      setSubmitting(false);
      return setErrorMessage('Email must be in a valid format');
    }

    const responseemail = await userEmailExistRequest(submissionEmail);

    if (responseemail) {
      if (responseemail.Exists) {
        addVerifiedEmail(submissionEmail);
        dispatch(addIsUserLoginFirstTime(responseemail.IsLoginFirstTime));
        if (!responseemail.IsLoginFirstTime) {
          history.push('user-login');
        } else {
          history.push('forgot-password');
        }
      } else {
        setSubmitting(false);
        setErrorMessage("That email doesn't exist");
      }
    }
  };

  const onKeyUpEmail = (e) => {
    if (e.key === 'Enter' && !submitting) {
      handleSubmit(e);
    }
  };

  // RP - Add Sentry Changes
  // const handleError = () => {
  //   try {
  //     throw new Error('Caught');
  //   } catch (err) {
  //     Sentry.captureException(err);
  //   }
  // }

  return (
    <div className="email-login-form module-spacer">
      {errorMessage && <p className="text error">*{errorMessage}</p>}
      <div className="form-container background action-item">
        <h2>Sign In</h2>
        <Row>
          <Col xs={12} sm={6} className="left">
            <div className="create-account-cta background action-item">
              <p className="headline background action-item">New to Trinity?</p>
              <CustomButton onClick={continueToCreateAccount} className="w-100">
                Create your Trinity Account
              </CustomButton>
            </div>
          </Col>
          <Col xs={12} sm={6} className="right">
            <TextInput
              placeholder=""
              label="Email"
              type="email"
              name="email"
              onChange={updateInput}
              value={state.email}
              onKeyDown={onKeyUpEmail}
            />
            <CustomButton
              onClick={handleSubmit}
              type="submit"
              loading={submitting}
            >
              Continue
            </CustomButton>
            {/* RP - Add Sentry Changes */}
            {/* <CustomButton
              onClick={handleError}
              type={"submit"}
              loading={submitting}
            >
              Error
            </CustomButton> */}
            {/* <a onClick={() => Sentry.showReportDialog()}>Report feedback</a> */}
            <p>
              By creating an account, you agree to Trinity's Conditions of Use
              and Privacy Notice.
            </p>
          </Col>
        </Row>
      </div>
      <p>
        Need help? Email us at{' '}
        <a href="mailto:password-help@trinityconsultants.com">
          password-help@trinityconsultants.com
        </a>
      </p>
      {/* <p className="contact">
        Need help? Call support at <a href={'tel:+1 972-661-8881'}>+1 972-661-8881</a>
      </p> */}
    </div>
  );
}

export default EmailLoginForm;
