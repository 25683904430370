export const content_1 =
  'BREEZE offers software to federal and state environmental regulatory agencies at no charge as part of our efforts to monitor regulatory modeling needs and requirements for ongoing improvement of our products and services. In exchange, we ask for your feedback on our software products to continue providing scientifically sound products that are intuitive and easy to use.';

export const content_2 =
  'By participating in this program, you will receive complimentary licenses and technical support for:';

export const regulatoryData = [
  {
    name: 'BREEZE AERMOD Pro Plus',
  },
  {
    name: 'BREEZE 3D Analyst',
  },
  {
    name: 'BREEZE AERMET',
  },
  {
    name: 'BREEZE Downwash Analyst',
  },
  {
    name: 'BREEZE AERSCREEN',
  },
];

export const regulatoryData_content_1 =
  'Many state and federal agencies currently participate in this program and we encourage you to participate too!';

export const regulatoryData_content_2 =
  'BREEZE is committed to providing the highest level of customer satisfaction and we are anxious to receive your feedback. If you have a comment or suggestion for improving our products and services, please contact us at +1 (972) 661-8881 or breeze@trinityconsultants.com.';

export const expectations_title = 'Program Expectations';
export const expectations_content =
  'Employees of federal or state regulatory agencies that use BREEZE software under the BREEZE Regulatory Agency Program are requested to participate as follows:';

export const expectationsData = [
  {
    name: 'Provide feedback. We want to know what you like and dislike about BREEZE software. Our goal is to provide scientifically sound products that are intuitive and easy to use. Please send your feedback to breeze@trinityconsultants.com. For technical support, contact us at +1 (972) 661-8881 or support@trinityconsultants.com.',
  },
  {
    name: 'Restrict use of these free applications for solely non-profit purposes.',
  },
  {
    name: 'Do not distribute the software to anyone outside your regulatory agency.',
  },
  {
    name: 'If BREEZE products are used in modeling analyses that are part of an internal or external published report or paper, please include the appropriate credits to BREEZE Software and provide our website (www.breeze-software.com).',
  },
];
