import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { aermetData } from './single-release-notes.data';
import './single-release-notes.styles.scss';

function SingleReleaseNote({ data, releaseName }) {
  return (
    <div className="single-container">
      {/* {data && data.map((note, i) => { */}
      {/* return ( */}
      <Row>
        <Col xs={12} sm={3}>
          <p className="name">{releaseName}</p>
        </Col>
        <Col xs={12} sm={9} className="version-container">
          {data &&
            data
              .sort((a, b) => (a.Title > b.Title ? 1 : -1))
              .map((version, index) => (
                <ul key={index}>
                  <li>
                    <a href={version.RouteUrl}>{version.Title}</a>
                  </li>
                </ul>
              ))}
        </Col>
      </Row>
      {/* ); */}
      {/* })} */}
      <hr />
    </div>
  );
}

export default SingleReleaseNote;
