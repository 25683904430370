import React from 'react';
import Hero from '../../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../../images/training/secondary-a-hero.jpg';
import TrainingAzureSearchRootComponent from './TrainingAzureSearchRootComponent';

function NewTrainingSearchWrapper() {
  return (
    <div className="training training-secondary">
      <Hero
        src={heroImageSrc}
        alt="Training Result image"
        mobileImgSrc={heroImageSrc}
        title="Training Results"
        template="standard" // Changed from Landing to Standard
        colorCategory="train"
      />
      <div className="trinity-container">
        <TrainingAzureSearchRootComponent />
      </div>
    </div>
  );
}

export default NewTrainingSearchWrapper;
