import React from 'react';
import { useHistory } from 'react-router-dom';
import './admin-landing-page.scss';
import '../../components/commons/cta/box-cta/box-cta.styles.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../components/commons/hero/hero.component';
import adminLandingSrc from '../../images/homepage/adminLandingSrc.png';

function AdminLandingPage() {
  const history = useHistory();

  const goToNewsPage = () => {
    history.push('/admin/news');
  };
  const goToImpersonate = () => {
    history.push('/admin/impersonate');
  };

  return (
    <div className="software-lp software">
      <Hero
        src={adminLandingSrc}
        title="Admin Home"
        template="standard"
        altMobileTitle
      />

      <div className="sm-up-trinity-container">
        <div className="filtered-cta-grid  module-spacer only-small-ctas">
          <Row className="filter-row-custom">
            <Box title="News" onClick={goToNewsPage} />
            <Box title="Impersonate" onClick={goToImpersonate} />
          </Row>
        </div>
      </div>
    </div>
  );
}

export default AdminLandingPage;

function Box({ title, showArrow = true, onClick }) {
  return (
    <Col className="admin-grid-box-width pr-sm-1 pb-1 pb-sm-1 admin-box-color m-1">
      <button
        className="box-cta-full-height box-cta box-background xs-only-trinity-container small-box-cta admin-box-color"
        onClick={onClick}
      >
        {title && (
          <div className="title">
            <div dangerouslySetInnerHTML={{ __html: title }} />
            {showArrow && <span className="arrow d-none d-sm-inline">›</span>}
          </div>
        )}
      </button>
    </Col>
  );
}
