import React, { useEffect } from 'react';
import Hero from '../../../components/commons/hero/hero.component';
import heroImageSrc from '../../../images/training/training_lp_hero.png';
import TrainingRegionTabs from '../../../components/locations/training-by-location/region-tabs/region-tabs.component';

import './training-by-location.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getCountryAndStateData } from '../../../redux/course-training/course.action';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function TrainingByLocation() {
  const dispatch = useDispatch();
  const countryStateList = useSelector((state) => state.countryStateList);

  useEffect(() => {
    dispatch(getCountryAndStateData());
  }, []);

  if (typeof countryStateList === 'string') {
    return <Loader />;
  }

  return (
    <div className="training-location-container">
      <Hero
        src={heroImageSrc}
        alt=""
        template="standard"
        title="Training Location"
        description="Professional environmental training on location-specific requirements from excellent instructors with extensive real world experience."
        colorCategory="locations"
      />

      <div className="trinity-container">
        <TrainingRegionTabs tabData={countryStateList} />
      </div>
    </div>
  );
}

export default TrainingByLocation;
