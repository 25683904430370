const generalPages = [
  // {
  //     name: "General",
  //     path: "/general",
  //     meta: {
  //         title: 'this is a meta title',
  //         description: 'this is a meta description'
  //     },
  //     template: "GeneralContent"
  // }
];

export default JSON.stringify(generalPages);
