import React, { useEffect, useState } from 'react';
import './filtered-product-carousel.styles.scss';

import Carousel from '../carousel/carousel.component';
import SectionTitle from '../section-title/section-title.component';
import ProductListItem from '../product-list-item/product-list-item.component';
import ProductFilterControls from './product-filter-controls/product-filter-controls.component';

// This component contains similar functionality to '../../commons/filtered-cta-grid'
// => maybe I should merge the filter-controls component for both??
// => maybe just reuse functions?

function getCategories(products) {
  return products.reduce((allCategories, { categories }) => {
    categories.forEach((category) => {
      allCategories[category.title] = category.id;
    });

    return allCategories;
  }, {});
}

function FilteredProductCarousel({ products, title }) {
  const [activeContent, setActiveContent] = useState(products || null);
  const [categories, setCategories] = useState(
    products ? getCategories(products) : null
  );

  function filterContent(e) {
    const activeCategory = e.target.getAttribute('data-category');

    if (activeCategory === 'all') {
      return setActiveContent(products);
    }

    setActiveContent(() =>
      products.filter((product) => product.categories[0].id === activeCategory)
    );
  }

  if (activeContent) {
    return (
      <div className="product-carousel">
        <h2>{title}</h2>
        {activeContent.length > 0 && (
          <ProductFilterControls
            filter={filterContent}
            categories={categories}
          />
        )}
        <div className="carousel-container">
          <Carousel unslickOnMobile>
            {activeContent.map(
              ({ title, maxQty, retailPrice, salePrice, id }, i) => (
                <div
                  key={title + i}
                  className="slick-item-container mb-2 mb-sm-0"
                >
                  <ProductListItem
                    title={title}
                    maxQty={maxQty}
                    retailPrice={retailPrice}
                    salePrice={salePrice}
                    productId={id}
                    fromCarousel
                  />
                </div>
              )
            )}
          </Carousel>
        </div>
      </div>
    );
  }
  return null;
}

export default FilteredProductCarousel;
