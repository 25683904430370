export const title = 'Iowa Environmental News';

export const widgetData = [
  {
    date: 'January 3, 2020',
    link: 'Complimentary Luncheons - Iowa Regulatory Updates',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'IDNR Air Quality Contact Meeting',
    content:
      'The IDNR is soliciting agenda items for the next Air Quality Contact Meeting scheduled for Thursday, May 23, 2019 at 1:00 p.m. Agenda items should be submitted to Matthew Johnson by May 2 and include the name of the item, who will lead the…',
  },
  {
    date: 'January 3, 2020',
    link: 'Regional Haze and Prong 4 SIP Revisions',
    content:
      'The IDNR is holding a public comment period on the proposed Regional Haze and Prong 4 (State Implementation Plan) SIP Revisions until April 29th. Revisions include:IDNR requesting EPA fully approve Iowa’s regional haze SIP as meeting all applicable…',
  },
  {
    date: 'January 3, 2020',
    link: 'Reporting Reminders (April 2019 Update)',
    content:
      'Minor Source Emission Inventory Questionnaires (MSEIQs) are due May 15, 2019 for emission year 2018 for minor source facilities in the east section of Iowa (Field Offices 1 & 6) and portable plants. MSEIQ forms and instructions are available on the…',
  },
  {
    date: 'January 3, 2020',
    link: 'Reporting Reminders (March Update)',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'Complimentary Luncheons - Iowa Regulatory Updates',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'Complimentary Luncheons - Iowa Regulatory Updates',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'Complimentary Luncheons - Iowa Regulatory Updates',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'IDNR Easy Air Application System',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
  {
    date: 'January 3, 2020',
    link: 'Complimentary Luncheons - Iowa Regulatory Updates',
    content:
      'Please join Trinity Consultants for a complimentary lunch seminar on recent federal and Iowa environmental regulatory hot topics. Enjoy a free lunch while you learn about regulatory changes and how those changes could impact your…',
  },
];

export default widgetData;
