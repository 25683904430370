//  ********************************************************
//  ********************************************************
//  **
//  **  README
//  **
//  **  1. All pages should be imported here
//  **  2. this component controls what template is rendered (needed for dynamic routing)
//  **  3. in production this file should lazy load routes
//  **
//  ********************************************************
//  ********************************************************

import React from 'react';

// Components should be named the same as their respective file name
// names should not be shortened or differ from their file name
// ex: "ConSecB" => should be "ConsultingSecondaryB"
import ConsultingIndividualCategoryDetail from './consulting-ehs/consulting-individual-category-detail/consulting-individual-category-detail.page';
import ConsultingJobRequestIndividualCategoryDetail from './consulting-ehs/consulting-individual-category-detail/job-request/consulting-job-request-individual-category-detail.page';
import ConsultingIndividualCategory from './consulting-ehs/consulting-individual-category/consulting-individual-category.page';
import ConsultingCategory from './consulting-ehs/consulting-category/consulting-category.page';
import ConsultingLanding from './consulting-ehs/consulting-landing/consulting-landing.page';
import Industries from './industries/industry-landing/industries-landing.page';
import IndustrialB from './industries/industry-landing-secondary/industry-landing-secondary.page';
import IndustrialSecondaryA from './industries/industry-individual-service/industry-individual-service.page';
import TrainingLP from './training/training-lp/training-lp.page';
import TrainingSecondary from './training/training-secondary/training-secondary.page';
import TrainingSecondaryClassroom from './training/training-secondary/training-secondary-classroom.page';
import TrainingSecondaryWebinar from './training/training-secondary/training-secondary-webinar.page';
import TrainingSecondaryELearning from './training/training-secondary/training-secondary-elearning.page';
import TrainingSecondarySeminar from './training/training-secondary/training-secondary-seminar.page';
import TrainingTertiary from './training/training-tertiary/training-tertiary.page';
import TrainingLP2 from './training/training-lp-2/training-lp-2.page';
import TrainingSearch from './training/training-search/training-search.page';
import CustomTraining from './training/custom-training/custom-training.page';
import RequestTrainingQuote from './training/request-training-quote/request-training-quote.page';
import TechnologyLp from './technology/technology-lp/technology-lp.page';

import Software from './software/software-lp/software-lp.page';
import SoftwareCategory from './software/software-category/software-category.page';
import SoftwareDemo from './software/software-demo/software-demo.page';
import SoftwareTrial from './software/software-request-a-trial/request-a-trial.page';
import SoftwareRequestKey from './software/software-request-key/software-request-key.page';
import SoftwareSupport from './software/software-support/software-support.page';
import SofwareSupportRequestForm from './software/software-support/support-request-form.page';
import SoftwareProductDetail from './software/software-product-detail/software-product-detail.page';
import SoftwareProductIndivTour from './software/software-individual-product-detail/software-individual-product-detail.page';
import SoftwareSecondary from './software/software-secondary/software-secondary.page';
import SoftwareResources from './software/software-resources/software-resources.page';
import GeneralContent from './general-content/general-content/general-content.page';
import StaffingLp from './staffing/staffing-lp/staffing-lp.page';
import StaffingSubmitResume from './staffing/staffing-submit-resume/submit-resume.page';
import Homepage from './homepage/homepage.page';
import Resources from './resources-and-news/resources-lp/resources.page';
import NewsMedia from './resources-and-news/news-media/news-media.page';
import AboutLP from './about/about_lp.page';
import OpLeaders from './about/operational-leaders/operational-leaders.page';
import ExecCom from './about/exec committee/exec-committee.page';
import IndivBio from './about/operational-leaders/indivi-bio/indivi-bio.page';
import Policies from './about/policies/policies.page';
import PoliciesIndividual from './about/policies/policies-individual-category/policies-individual-category.page';
import TradeShows from './about/trade-shows/trade-shows.page';
import JobAlert from './about/careers/job-alert/job-alert.page';
import SubscribeForm from './about/subscribe/subscribe.page';
import WebinarForm from './specialty-pages/webinar-form-page/webinar-sign-up.page';
import RequestAQuoteForm from './specialty-pages/request-a-quote-form-page/request-a-quote.page';
import RequestServiceQuote from './specialty-pages/request-a-quote-form-page/request-a-quote.page';
import ContactUs from './about/contact-us/contact-us.page';
// import NewsMedia from "./news-media/news-media-lp/news-media.page";
import NewsIndividualArticle from './resources-and-news/news-individual-article/news-individual-article.page';
import CareersLP from './about/careers/careers-lp/careers-lp.page';
import AvaPositions from './about/careers/ava-positions/ava-positions.page';
import IndivPositions from './about/careers/ind-positions/ind-positions.page';
import ErrorDisplay from './specialty-pages/error-display/not-found.page';
import ConsultingDataTable from './consulting-ehs/consulting-data-table/consulting-data-table.page';
import SoftwareDownload from './ecommerce/software-download/software-download.page';
import TechnologyIndividualCategory from './technology/technology-individual-category/technology-individual-category.page';
import LocationsSecondary from './locations/locations-secondary/locations-secondary.page';
import TrainingByLocation from './locations/training-by-location/training-by-location.page';
import GlobalPartners from './locations/global-partners/global-partners.page';
import IndiviLocation from './about/individual-location/indivi-location.page';
import EhsLanding from './consulting-ehs/ehs-landing/ehs-landing.page';
import WebsiteProblem from './specialty-pages/website-problem/website-problem.page';
import PrivacyPolicy from './about/privacy-policy/privacy-policy.page';
import EducationProgram from './about/education-program/education-program.page';
import RegulatoryAgencyProgram from './about/regulatory-agency-program/regulatory-agency-program.page';
import Mission from './about/mission/mission.page';
import OurStory from './about/our-story/our-story.page';
import RequestAKey from './specialty-pages/request-a-key/request-a-key.page';
//import TrackVisiter from "./module-tracking.component";
import RequestDataQuote from './specialty-pages/request-data-quote/request-data-quote.page';
import RequestDataQuotePage from './specialty-pages/request-data-quote/request-data-quote.page';
import RequestSoftwareQuote from './software/request-software-quote/request-software-quote.page';
import RequestSoftwarePage from './software/request-software-quote/request-software-quote.page';
import ReleaseNotes from './specialty-pages/release-notes/release-notes.page';
import CreateAccount from './ecommerce/create-account/create-account.page';
// Service Branded pages
import MinnowPage from './service_branded/Minnow/minnow.page';
import ComCareer from './about/careers/com-career/com-career.page';
import ComStaffing from './staffing/staffing-common/staffing-common.page';
import ComSoftware from './software/software-secondary/software-common.page';
import SoftwareCategoryCommon from './software/software-secondary/software-category-common.page';
import RequestModelingDataPage from './specialty-pages/request-modeling-data/request-modeling-data.page';
import RequestSoftwareDemo from './software/request-software-demo/request-software-demo.page';
import FeedBackFormComponent from '../pages/feed-back/feed-back-form-page';
import { DocumentServices } from '../redux/document/document.services';
import ImpersonateUserListTableComponent from './impersonate-users-list/impersonate-user-list-table-component';
import TrainingIndustry from '../pages/general-content/training-by-industry/training-by-industry.page';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getUserIsLoggedin } from '../js/api/auth/_login.js';
import EditProfile from './ecommerce/edit-profile/edit-profile.page';
import { removeUser } from '../redux/user/user.action';
import { resetCartItem } from '../redux/shopping-cart/shopping-cart.action';
import TrainingSearchResult from './training/training-secondary/training-search.page';
import TrainingAllCourse from './training/training-secondary/training-all-course.page';
import TrainingSecondarySoftware from './training/training-secondary/training-secondary-software.page';
import IndiviState from './about/individual-state/indivi-state.page';
import SoftwareRegulatoryAgencyProgram from './software/software-secondary/software-regulatory-agency-program.page';
import EHSPublicationPage from '../components/ehs-quarterly/EHSPublicationPage';
import EHSSecondLevel from '../components/ehs-quarterly/EHSSecondLevel';
import NewConsultingLandingPage from './consulting-ehs/new-consulting-landing-page/new-consulting-landing-page';
import NewSoftwareLandingPage from './software/new-software-landing-page/new-software-landing-page';
import NewAboutusLandingPage from './about/new-aboutus-landing-page/new-aboutus-landing-page';
import NewTechnologyLandingPage from './technology/new-technology-landing-page/new-technology-landing-page';
import NewStaffingLandingPage from './staffing/new-staffing-landing-page/new-staffing-landing-page';
import NewIndustryLandingPage from './industries/new-industry-landing-page/new-industry-landing-page';
import NewCareersLandingPage from './about/careers/new-careers-landing-page/new-careers-landing-page';
import NewTrainingLandingPage from './training/new-training-landing-page/new-training-landing-page';
import NewResourcesLandingPage from './resources-and-news/new-resources-landing-page/new-resources-landing-page';
import NewLocationLandingPage from './locations/new-location-landing-page/new-location-landing-page';
import NewSoftwareResourcesLandingPage from './software/new-software-resources-landing-page/new-software-resources-landing-page';
import NewContactusLandingPage from './about/new-contactus-landing-page/new-contactus-landing-page';
import NewConsultingCategoryLandingPage from './consulting-ehs/new-consulting-category/new-consulting-category-landing-page';
import NewConsultingIndividualCategoryLandingPage from './consulting-ehs/new-consulting-individual-category/new-consulting-individual-category-landing-page';
import NewConsultingIndividualCategoryDetailLandingPage from './consulting-ehs/new-consulting-individual-category-detail/new-consulting-individual-category-detail-landing-page';
import NewConsultingIndividualCategoryDetailTypeLandingPage from './consulting-ehs/new-consulting-individual-category-detail-type/new-consulting-individual-category-detail-type-landing-page';
import NewIndustrySecondaryLandingPage from './industries/new-industry-landing-secondary-page/new-industry-secondary-landing-page';
import NewIndustryServicePage from './industries/new-industry-service-page/new-industry-service-page';
import NewPrivacyPolicyLandingPage from './about/new-privacy-policy/new-privacy-policy-landing-page';
import NewMissionLandingPage from './about/new-mission/new-mission-landing-page';
import NewPoliciesLandingPage from './about/policies/new-policies/new-policies-landing-page';
import NewTradeshowsLandingPage from './about/new-trade-shows/new-trade-shows-landing-page';
import NewPoliciesIndividualLandingPage from './about/policies/new-policies-individual-category/new-policies-individual-landing-page';
import NewExecCommitteeLandingPage from './about/new-exec-committee/new-exec-committee-landing-page';
import NewOperationalLeadersLandingPage from './about/new-operational-leaders/new-operational-leaders-landing-page';
import NewIndiviBioLandingPage from './about/operational-leaders/new-indivi-bio/new-indivi-bio-landing-page';
import NewAvaPositionsLandingPage from './about/careers/new-ava-positions/new-ava-positions-landing-page';
import NewComCareerLandingPage from './about/careers/new-com-career/new-com-career-landing-page';
import NewIndPositionsLandingPage from './about/careers/new-ind-positions/new-ind-positions-landing-page';
import NewTechnologyIndividualCategoryLandingPage from './technology/new-technology-individual-category/new-technology-individual-category-landing-page';
import NewTechnologyIndividualSubCategoryLandingPage from './technology/new-technology-individual-sub-category/new-technology-individual-sub-category-landing-page';
import NewStaffingCommonLandingPage from './staffing/new-staffing-common/new-staffing-Common-landing-page';
import NewStaffingAvaPositionsLandingPage from './about/careers/new-staffing-ava-positions/new-staffing-ava-positions-landing-page';
import NewJobAlertLandingPage from './about/careers/new-job-alert/new-job-alert-landing-page';
import NewStaffingIndPositionsLandingPage from './about/careers/new-staffing-ind-positions/new-staffing-ind-positions-landing-page';
import NewStaffingJobRequestLandingPage from './consulting-ehs/consulting-individual-category-detail/new-staffing-job-request/new-staffing-job-request-landing-page';
import NewSoftwareSupportLandingPage from './software/new-software-support/new-software-support-landing-page';
import NewGlobalPartnersLandingPage from './locations/new-global-partners/new-global-partners-landing-page';
import NewSoftwareServiceLandingPage from './software/new-software-service/new-software-service-landing-page';
import NewRequestDataQuoteLandingPage from './specialty-pages/new-request-data-quote/new-request-data-quote-landing-page';
import NewSoftwareProductDetailLandingPage from './software/new-software-product-detail/new-software-product-detail-landing-page';
import NewReleaseNotesLandingPage from './specialty-pages/new-release-notes/new-release-notes-landing-page';
import NewSoftwareCommonLandingPage from './software/new-software-common/new-software-common-landing-page';
import NewTrainingFormatLandingPage from './training/new-training-format/new-training-format-landing-page';
import NewTrainingCategoryLandingPage from './training/new-training-category/new-training-category-landing-page';
import NewTrainingAllCoursesLandingPage from './training/new-training-all-courses/new-training-all-courses-landing-page';
import NewTrainingLocationSpecificLandingPage from './training/new-training-location-specific/new-training-location-specific-landing-page';
import NewIndividualStateLandingPage from './about/new-individual-state/new-individual-state-landing-page';
import NewIndividualLocationLandingPage from './about/new-individual-location/new-individual-location-landing-page';
import NewMinnowLandingPage from './service_branded/new-minnow/new-minnow-landing-page';
import NewNewsLandingPage from './resources-and-news/new-news-landing-page/new-news-landing-page';
import NewRequestTrainingQuoteLandingPage from './training/new-request-training-quote/new-request-training-quote-landing-page';
import NewRequestSoftwareDemoLandingPage from './software/new-request-software-demo/new-request-software-demo-landing-page';
import NewRegulatoryAgencyProgramLandingPage from '../components/commons/forms/new-regulatory-agency-program-form/new-regulatory-agency-program-landing-page';
import NewCreateAccountLandingPage from './ecommerce/new-create-account/new-create-account-landing-page';
import NewSoftwareRequestKeyLandingPage from './software/new-software-request-key/new-software-request-key-landing-page';
import NewStaffingSubmitResumeLandingPage from './staffing/new-staffing-submit-resume/new-staffing-submit-resume-landing-page';
import NewRequestAQuoteLandingPage from './specialty-pages/new-request-a-quote-form-page/new-request-a-quote-landing-page';
import NewRequestAKeyLandingPage from './specialty-pages/new-request-a-key/new-request-a-key-landing-page';
import NewWebinarSignupLandingPage from './specialty-pages/new-webinar-sign-up/new-webinar-sign-up-landing-page';
import NewWebsiteProblemLandingPage from './specialty-pages/new-website-problem/new-website-problem-landing-page';
import NewRequestSoftwareQuoteLandingPage from './software/new-request-software-quote/new-request-software-quote-landing-page';
import NewRequestATrialLandingPage from './software/new-request-a-trial/new-request-a-trial-landing-page';
import NewTrainingSearchWrapper from './training/new-training-landing-page/new-training-azure-search/NewTrainingSearchWrapper';
import NewSubscribeLandingPageDesign from './about/new-subscribe-landing-page-design/new-subscribe-landing-page-design';
import SafeBridgeSearchWrapper from './safe-bridge-page/SafeBridgeSearchWrapper';
import SafeBridgeSecondLevel from './safe-bridge-page/safe-bridge-second-level/SafeBridgeSecondLevel';
import NewTrainingSearchLandingPage from './training/new-training-search/new-training-search-landing-page';
import NewSubscribeLandingPage from './about/new-subscribe-landing-page/new-subscribe-landing-page';

function ModuleController({ template, page, section }) {
  console.log('------_>>>');
  console.log(template);

  const user = useSelector(({ user }) => user.tokens.access_token);
  const isbackend_user = useSelector(({ user }) => user.isbackend_user);
  const dispatch = useDispatch();

  async function checkUserLoggedIn() {
    const getResponse = await getUserIsLoggedin(null);
    return getResponse;
  }

  const protectedShoppingCartUserRoute = (component) => {
    if (!user) {
      dispatch(removeUser(null));
      dispatch(resetCartItem(null));
      return <Redirect to="/login" />;
    } else if (user) {
      const userIsLoggedIn = checkUserLoggedIn();
      if (!userIsLoggedIn) {
        dispatch(removeUser(null));
        dispatch(resetCartItem(null));
        return <Redirect to="/login" />;
      }
    }

    return component;
  };
  const protectedAdminUserRoute = (component) => {
    if (!user) {
      return <Redirect to="/admin/login" />;
    } else if (user) {
      const userIsLoggedIn = checkUserLoggedIn();
      if (!userIsLoggedIn) {
        return <Redirect to="/admin/login" />;
      }
      if (!isbackend_user) {
        return <Redirect to="/admin/login" />;
      }
    }
    return component;
  };

  console.log('------>>');
  console.log(template);

  switch (template) {
    // service branded pages
    case 'new-safebridge-landing':
      return <SafeBridgeSearchWrapper page={page} />;

    case 'new-sub-safebridge-landing':
      return <SafeBridgeSecondLevel page={page} />;

    case 'trinity-service-brand':
      return <MinnowPage page={page} />;

    case 'new-trinity-service-brand':
      return <NewMinnowLandingPage page={page} />;

    case 'ehs-landing':
      return <EhsLanding page={page} section={section} />;
    // case "locations":
    //   return <Locations page={page} />;

    case 'locationsSecondary':
    case 'locations':
      return <LocationsSecondary page={page} />;

    case 'new-locations':
      return <NewLocationLandingPage page={page} />;

    case 'survey':
      return <FeedBackFormComponent page={page} />;
    case 'impersonate':
      return protectedAdminUserRoute(<ImpersonateUserListTableComponent />);

    case 'TrainingByLocation':
      return <TrainingByLocation page={page} />;

    case 'GlobalPartners':
    case 'software-global-partners':
      return <GlobalPartners page={page} />;

    case 'new-software-global-partners':
      return <NewGlobalPartnersLandingPage page={page} />;

    case 'new-individual-locations':
      return <NewIndividualLocationLandingPage page={page} />;

    case 'IndiviLocation':
    case 'individual':
    case 'individual-locations':
      return <IndiviLocation page={page} />;

    case 'new-individual-state':
      return <NewIndividualStateLandingPage page={page} />;

    case 'individual-state':
      return <IndiviState page={page} />;

    case 'technology-individual-category':
      return <TechnologyIndividualCategory section={section} page={page} />;

    case 'new-technology-individual-category':
      return (
        <NewTechnologyIndividualCategoryLandingPage
          section={section}
          page={page}
        />
      );

    case 'new-technology-individual-sub-category':
      return (
        <NewTechnologyIndividualSubCategoryLandingPage
          section={section}
          page={page}
        />
      );

    case 'software-download':
      return <SoftwareDownload />;

    case 'new-trinity-ehs-publication':
      return <EHSPublicationPage />;
    /*case "homepage":
          return <NewHomePage page={page} />;*/
    case 'homepage':
      return null;

    case 'webinar-sign-up':
      return <WebinarForm page={page} />;

    case 'new-webinar-sign-up':
      return <NewWebinarSignupLandingPage page={page} />;

    case 'request-a-key':
      return <RequestAKey page={page} />;

    case 'new-request-a-key':
      return <NewRequestAKeyLandingPage page={page} />;

    case 'request-a-quote':
      return <RequestAQuoteForm page={page} />;

    case 'request-modeling-data':
      return <RequestModelingDataPage page={page} />;

    case 'request-service-quote':
      return <RequestServiceQuote page={page} />;

    case 'new-request-service-quote':
      return <NewRequestAQuoteLandingPage page={page} />;

    case 'ConsultingDataTable':
      return <ConsultingDataTable section={section} page={page} />;

    case 'resources':
    case 'trinity-resource':
      return <Resources section={section} page={page} />;

    case 'new-trinity-resource':
      return <NewResourcesLandingPage section={section} page={page} />;

    case 'news-media':
    case 'news':
      return <NewsMedia />;

    case 'new-tri-news':
      return <NewNewsLandingPage page={page} />;

    case 'consulting-landing':
      return <ConsultingLanding section={section} page={page} />;

    case 'new-consulting-landing':
      return <NewConsultingLandingPage section={section} page={page} />;

    case 'consulting-individual-category-detail':
      return (
        <ConsultingIndividualCategoryDetail section={section} page={page} />
      );

    case 'new-consulting-individual-category-detail':
      return (
        <NewConsultingIndividualCategoryDetailLandingPage
          section={section}
          page={page}
        />
      );

    case 'new-consulting-individual-category-detail-type':
      return (
        <NewConsultingIndividualCategoryDetailTypeLandingPage
          section={section}
          page={page}
        />
      );

    case 'new-staffing-submit-a-job-request':
      return <NewStaffingJobRequestLandingPage section={section} page={page} />;

    case 'consulting-job-request-individual-category-detail':
    case 'staffing-submit-a-job-request':
      return (
        <ConsultingJobRequestIndividualCategoryDetail
          section={section}
          page={page}
        />
      );

    // case "staffing-submit-a-job-request":
    //     return <StaffingSubmitJobRequest section={section} page={page} />;

    case 'consulting-individual-category':
      return <ConsultingIndividualCategory section={section} page={page} />;

    case 'new-consulting-individual-category':
      return (
        <NewConsultingIndividualCategoryLandingPage
          section={section}
          page={page}
        />
      );

    case 'consulting-category':
      return <ConsultingCategory section={section} page={page} />;

    case 'new-consulting-category':
      return <NewConsultingCategoryLandingPage section={section} page={page} />;

    case 'industry-landing':
      return <Industries page={page} />;

    case 'new-industry-landing':
      return <NewIndustryLandingPage page={page} />;

    case 'industry-landing-secondary':
      return <IndustrialB section={section} page={page} />;

    case 'new-industry-landing-secondary':
      return <NewIndustrySecondaryLandingPage section={section} page={page} />;

    case 'industry-individual-service':
      return <IndustrialSecondaryA page={page} />;

    case 'new-industry-individual-service':
      return <NewIndustryServicePage page={page} section={section} />;

    //RK Task#99
    case 'Training-landing':
      return <TrainingLP section={section} page={page} />;

    case 'new-training-landing':
      return <NewTrainingLandingPage section={section} page={page} />;

    case 'new-training-individual-category':
      // For getting in old training
      // return <NewTrainingIndividualCategoryLandingPage page={page}/>;
      return <NewTrainingSearchWrapper />;

    //RK Task#104 Training Secondary Change
    case 'training-individual-category':
      return <TrainingSecondary page={page} />;
    case 'training-industry-specific':
      return <TrainingSecondary page={page} />;
    case 'training-software-course':
      return <TrainingSecondary page={page} />;
    case 'training-all-courses':
      return <TrainingAllCourse page={page} />;
    // case "training-schedule":
    //   return <TrainingSecondary page={page} />;
    case 'training-classroom':
      return <TrainingSecondaryClassroom page={page} />;
    case 'training-webinar':
      return <TrainingSecondaryWebinar page={page} />;
    case 'training-elearning':
      return <TrainingSecondaryELearning page={page} />;
    case 'training-free':
      return <TrainingSecondaryELearning page={page} />;
    case 'training-seminar':
      return <TrainingSecondarySeminar page={page} />;
    case 'TrainingTertiary':
      return <TrainingTertiary page={page} />;

    case 'new-training-format':
      return <NewTrainingFormatLandingPage page={page} />;

    case 'new-training-category':
      return <NewTrainingCategoryLandingPage page={page} />;

    case 'new-training-all-courses':
      return <NewTrainingAllCoursesLandingPage page={page} />;

    case 'new-training-location-specific':
      return <NewTrainingLocationSpecificLandingPage page={page} />;

    case 'TrainingLP2':
      return <TrainingLP2 page={page} />;

    case 'TrainingSearch':
      return <TrainingSearch page={page} />;

    case 'request-training-quote':
      return <RequestTrainingQuote page={page} />;

    case 'new-request-training-quote':
      return <NewRequestTrainingQuoteLandingPage page={page} />;

    case 'custom-training':
      return <CustomTraining page={page} />;

    case 'technology-landing':
      return <TechnologyLp page={page} />;

    case 'new-technology-landing':
      return <NewTechnologyLandingPage page={page} />;

    case 'SoftwareLP':
      return <Software page={page} />;

    case 'software-categories':
      return <SoftwareCategory page={page} section={section} />;

    case 'software-landing':
      return <Software page={page} />;

    case 'new-software-landing':
      return <NewSoftwareLandingPage page={page} />;

    case 'software-support':
    case 'SoftwareSupport':
      return <SoftwareSupport page={page} />;

    case 'new-software-support':
    case 'new-SoftwareSupport':
      return <NewSoftwareSupportLandingPage page={page} />;

    case 'software-demo':
      return <SoftwareDemo page={page} />;

    case 'request-software-demo':
      return <RequestSoftwareDemo page={page} />;

    case 'new-request-software-demo':
      return <NewRequestSoftwareDemoLandingPage page={page} />;

    case 'software-request-a-trial':
      return <SoftwareTrial page={page} />;

    case 'new-software-request-a-trial':
      return <NewRequestATrialLandingPage page={page} />;

    case 'software-request-key':
      return <SoftwareRequestKey page={page} />;

    case 'new-software-request-key':
      return <NewSoftwareRequestKeyLandingPage page={page} />;

    case 'SupportRequestForm':
      return <SofwareSupportRequestForm page={page} />;

    case 'SoftwareProductDetail':
    case 'software-product':
      return <SoftwareProductDetail page={page} />;

    case 'new-software-product':
      return <NewSoftwareProductDetailLandingPage page={page} />;

    case 'SoftwareProductIndivTour':
      return <SoftwareProductIndivTour page={page} />;

    case 'SoftwareSecondary':
    case 'software-modeling-ready-data':
      return <SoftwareSecondary page={page} />;

    case 'new-software-modeling-ready-data':
      return <NewSoftwareServiceLandingPage page={page} />;

    case 'regulatory-agency-program':
      return <SoftwareRegulatoryAgencyProgram page={page} />;

    case 'software-resources':
      return <SoftwareResources page={page} />;

    case 'new-software-resources':
      return <NewSoftwareResourcesLandingPage page={page} />;

    case 'general_content':
    case '':
      return <GeneralContent section={section} page={page} />;
    case 'training-by-industry':
      return <TrainingIndustry section={section} page={page} />;

    case 'stafﬁng-landing':
      return <StaffingLp page={page} />;

    case 'new-stafﬁng-landing':
      return <NewStaffingLandingPage page={page} />;

    case 'staffing-resume':
      return <StaffingSubmitResume page={page} />;

    case 'new-staffing-resume':
      return <NewStaffingSubmitResumeLandingPage page={page} />;

    case 'staffing-common':
      return <ComStaffing page={page} />;

    case 'new-staffing-common':
      return <NewStaffingCommonLandingPage page={page} />;

    case 'software-software-sub-categories':
      return <ComSoftware page={page} />;

    case 'new-software-software-sub-categories':
      return <NewSoftwareCommonLandingPage page={page} />;

    case 'software-common-categories':
      return <SoftwareCategoryCommon page={page} />;

    case 'NewsIndividualArticle':
    case 'news-article':
      return <NewsIndividualArticle page={page} />;

    // case "new-news-article":
    //   return <NewNewsIndividualArticleLandingPage page={page} />;

    case 'contact-us':
      return <ContactUs page={page} />;

    case 'new-contact-us':
      return <NewContactusLandingPage page={page} />;

    case 'new-regulatory-agency-program':
      return <NewRegulatoryAgencyProgramLandingPage page={page} />;

    case 'Careers':
    case 'trinity-career':
      return <CareersLP page={page} />;

    // case 'new-trinity-career':
    //   return <NewCareersLandingPage page={page} />;

    case 'JobAlert':
    case 'staffing-job-alert-sign-up':
      return <JobAlert page={page} />;

    case 'new-staffing-job-alert-sign-up':
      return <NewJobAlertLandingPage page={page} />;

    case 'SubscribeForm':
      return <SubscribeForm page={page} />;

    case 'new-SubscribeForm':
      return <NewSubscribeLandingPage page={page} />; // <NewSubscribeLandingPageDesign page={page} />

    case 'IndivPositions':
      return <IndivPositions page={page} name={''} />;

    case 'stafﬁng-individual-position':
      return <IndivPositions page={page} name={'Staffing'} />;

    case 'trinity-individual-position':
      return <IndivPositions page={page} name={'Careers'} />;

    case 'new-trinity-individual-position':
      return <NewIndPositionsLandingPage page={page} name={'Careers'} />;

    case 'new-stafﬁng-individual-position':
      return (
        <NewStaffingIndPositionsLandingPage page={page} name={'Staffing'} />
      );

    case 'AvaPositions':
    case 'trinity-available-positions':
    case 'staffing-available-positions':
      return <AvaPositions page={page} />;

    case 'new-staffing-available-positions':
      return <NewStaffingAvaPositionsLandingPage page={page} />;

    case 'new-trinity-available-positions':
      return <NewAvaPositionsLandingPage page={page} />;

    case 'trinity-company-career':
      return <ComCareer page={page} />;

    case 'new-trinity-company-career':
      return <NewComCareerLandingPage page={page} />;

    case 'AboutLP':
    case 'about-us':
      return <AboutLP page={page} />;

    case 'new-about-us':
      return <NewAboutusLandingPage page={page} />;

    case 'OpLeaders':
      return <OpLeaders page={page} />;

    case 'ExecCom':
      return <ExecCom page={page} />;

    case 'executive-committee':
      return <ExecCom page={page} />;

    case 'new-executive-committee':
      return <NewExecCommitteeLandingPage page={page} />;

    case 'operational-leaders':
      return <OpLeaders page={page} />;

    case 'new-operational-leaders':
      return <NewOperationalLeadersLandingPage page={page} />;

    case 'IndivBio':
      return <IndivBio page={page} />;

    case 'individual-bio':
      return <IndivBio page={page} />;

    case 'new-individual-bio':
      return <NewIndiviBioLandingPage page={page} />;

    case 'Policies':
    case 'policies':
      return <Policies page={page} />;

    case 'new-Policies':
    case 'new-policies':
      return <NewPoliciesLandingPage page={page} />;

    case 'individual-policy':
      return <PoliciesIndividual page={page} />;

    case 'new-individual-policy':
      return <NewPoliciesIndividualLandingPage page={page} />;

    case 'policies-individual-category':
      return <PoliciesIndividual page={page} />;

    case 'TradeShows':
    case 'trade-shows':
      return <TradeShows page={page} />;

    case 'new-TradeShows':
    case 'new-trade-shows':
      return <NewTradeshowsLandingPage page={page} />;

    case 'education-program':
      return <EducationProgram page={page} />;

    case 'regulatory-agency-program':
      return <RegulatoryAgencyProgram page={page} />;

    case 'mission':
      return <Mission page={page} />;

    case 'new-mission':
      return <NewMissionLandingPage page={page} />;

    case 'trinity-ehs-quarterly':
      return <EHSPublicationPage page={page} />;

    case 'trinity-ehs-quarterly-categories':
      return <EHSSecondLevel page={page} />;

    case 'our-story':
      return <OurStory page={page} />;

    case 'request-data-quote':
      return <RequestDataQuote page={page} />;

    case 'new-request-data-quote':
      return <NewRequestDataQuoteLandingPage page={page} />;

    case 'request-software-quote':
      return <RequestSoftwareQuote page={page} />;

    case 'new-request-software-quote':
      return <NewRequestSoftwareQuoteLandingPage page={page} />;

    case 'releasenotes-categroy':
    case 'release-notes':
      return <ReleaseNotes page={page} />;

    case 'new-releasenotes-categroy':
      return <NewReleaseNotesLandingPage page={page} />;

    case 'privacy-policy':
      return <PrivacyPolicy page={page} />;

    case 'new-privacy-policy':
      return <NewPrivacyPolicyLandingPage page={page} />;

    case 'report-problem':
      return <WebsiteProblem page={page} />;

    case 'new-report-problem':
      return <NewWebsiteProblemLandingPage page={page} />;

    //RP #Task 68 - Request Data Quote
    case 'request-data-quote':
      return <RequestDataQuotePage page={page} />;

    //RP #Task 69 - Request Software Quote
    case 'request-software-quote':
      return <RequestSoftwarePage page={page} />;
    // case "shopping-cart":
    //   return protectedShoppingCartUserRoute(<ShoppingCart page={page} />);
    // case "review-order":
    //   return protectedShoppingCartUserRoute(<ReviewOrderPage page={page} />);
    // case "payment":
    //   return protectedShoppingCartUserRoute(<PaymentConfirmationPage page={page} />);
    // case "attendees":
    //   return protectedShoppingCartUserRoute(<AttendeePage page={page} />);
    // case "order-confirmation":
    //   return protectedShoppingCartUserRoute(<OrderConfirmationPage page={page} />);
    case 'create-account':
      return <CreateAccount />;
    case 'new-create-account':
      return <NewCreateAccountLandingPage page={page} />;
    case 'edit-profile':
      return <EditProfile />;
    case 'download-course-catalog':
      DocumentServices.getDocument();
      return <Homepage page={page} />;

    case 'training-search':
      return <TrainingSearchResult page={page} />;

    case 'new-training-search':
      // For getting in old training
      // return <NewTrainingSearchLandingPage page={page}/>;
      return <NewTrainingSearchWrapper />;

    case 'training-software':
      return <TrainingSecondarySoftware />;

    case 'new-trinity-ehs-quarterly':
      return <EHSPublicationPage page={page} />;

    default: {
      return <ErrorDisplay page={page} />;
    }
    // this also needs to be tracked (error tracking from sitefinity?)
    // break;
  }
  //RP #Task 58 - Sitefinity Insight
  //var ModuleTracking = TrackVisiter;
}

export default ModuleController;
