import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SelectList from '../../commons/form-inputs/select-list/select-list.component';
import CustomButton from '../../commons/custom-button/custom-button.component';
import {
  selectTrainingActionFilter,
  trainingcategoryfilterdata,
  trainingdatefilterdata,
  trainingformatfilterdata,
  TrainingIgnoreDefaultSearch,
  trainingindustryfilterdata,
  traininglocationfilterdata,
  trainingregulatoryagencyfilterdata,
} from '../../../redux/training/training.action';
import TrainingActiveSearchFilter from './training-active-search-filter.component';
import './training-active-search-filter.styles.scss';
import { useHistory } from 'react-router-dom';
import TextInput from '../../commons/form-inputs/text-input/text-input.component';
import { createURLAndReturn } from '../../../utilities/CommonUtilities';
import {
  TRAINING_BUSINESS_UNIT_BREEZE,
  TRAINING_BUSINESS_UNIT_SAFEBRIDGE,
  TRAINING_BUSINESS_UNIT_TRAINING_CONSULTANTS_INC,
  TRAINING_BUSINESS_UNIT_PROVENANCE,
} from '../../../js/api/_config';

function TrainingLandingAdvanceSearchFilter({ istrainingladning }) {
  istrainingladning = istrainingladning = undefined ? false : istrainingladning;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(trainingformatfilterdata());
    dispatch(trainingcategoryfilterdata());
    dispatch(trainingindustryfilterdata());
    dispatch(traininglocationfilterdata());
    dispatch(trainingregulatoryagencyfilterdata());
    dispatch(trainingdatefilterdata());
  }, []);

  const dformat = useSelector(
    ({ training }) => training.TrainingFormatFilterData
  );
  const dcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterData
  );
  const dindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterData
  );
  const dlocation = useSelector(
    ({ training }) => training.TrainingLocationFilterData
  );
  const dregulatoryagency = useSelector(
    ({ training }) => training.TrainingRegulatoryAgencyFilterData
  );
  const ddate = useSelector(({ training }) => training.TrainingDateFilterData);
  const dbusinessunit = [
    {
      ID: TRAINING_BUSINESS_UNIT_TRAINING_CONSULTANTS_INC,
      Name: TRAINING_BUSINESS_UNIT_TRAINING_CONSULTANTS_INC,
    },
    {
      ID: TRAINING_BUSINESS_UNIT_SAFEBRIDGE,
      Name: TRAINING_BUSINESS_UNIT_SAFEBRIDGE,
    },
    { ID: TRAINING_BUSINESS_UNIT_BREEZE, Name: TRAINING_BUSINESS_UNIT_BREEZE },
    {
      ID: TRAINING_BUSINESS_UNIT_PROVENANCE,
      Name: TRAINING_BUSINESS_UNIT_PROVENANCE,
    },
  ];

  const pformat = useSelector(
    ({ training }) => training.TrainingFormatFilterSelected
  );
  const pcategory = useSelector(
    ({ training }) => training.TrainingCategoryFilterSelected
  );
  const pindustry = useSelector(
    ({ training }) => training.TrainingIndustryFilterSelected
  );
  const plocation = useSelector(
    ({ training }) => training.TrainingLocationFilterSelected
  );
  const pregulatoryagency = useSelector(
    ({ training }) => training.TrainingRegulatoryAgencyFilterSelected
  );
  const pdate = useSelector(
    ({ training }) => training.TrainingDateFilterSelected
  );
  const pkeyword = useSelector(
    ({ training }) => training.TrainingKeywordFilterSelected
  );
  const pbusinessunit = useSelector(
    ({ training }) => training.TrainingBusinessUnitFilterSelected
  );
  const trainingLoading = useSelector(
    ({ training }) => training.TrainingLoading
  );
  let IgnoreDefaultSearch = useSelector(
    ({ training }) => training.IgnoreDefaultSearch
  );

  const [format, setFormat] = useState([]);
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [location, setLocation] = useState([]);
  const [regulatoryagency, setRegulatoryAgency] = useState([]);
  const [date, setDate] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [businessunit, setBusinessunit] = useState([]);

  // var lstBusinessUnit = [];
  // lstBusinessUnit.push({ "ID": ENDPOINTS.TRAINING_BUSINESS_UNIT.TRAINING_CONSULTANTS_INC, "Name": ENDPOINTS.TRAINING_BUSINESS_UNIT.TRAINING_CONSULTANTS_INC });
  // lstBusinessUnit.push({ "ID": ENDPOINTS.TRAINING_BUSINESS_UNIT.SAFEBRIDGE, "Name": ENDPOINTS.TRAINING_BUSINESS_UNIT.SAFEBRIDGE });
  // lstBusinessUnit.push({ "ID": ENDPOINTS.TRAINING_BUSINESS_UNIT.BREEZE, "Name": ENDPOINTS.TRAINING_BUSINESS_UNIT.BREEZE });

  // lstBusinessUnit.push({ "ID": "Trinity Consultants Inc.", "Name": "Trinity Consultants Inc." });
  // lstBusinessUnit.push({ "ID": "SafeBridge", "Name": "SafeBridge" });
  // lstBusinessUnit.push({ "ID": "Breeze", "Name": "Breeze" });

  const handleChange = (event) => {
    event.persist();
    const name =
      event.nativeEvent.target[event.nativeEvent.target.selectedIndex];
    const { value } = event.target;
    if (event.target.name === 'Format') {
      if (name.text && name.text.includes('— By Format —')) {
        setFormat({});
      } else {
        setFormat((format) => ({
          ...format,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Category') {
      if (name.text && name.text.includes('— By Category —')) {
        setCategory({});
      } else {
        setCategory((category) => ({
          ...category,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Industry') {
      if (name.text && name.text.includes('— By Industry —')) {
        setIndustry({});
      } else {
        setIndustry((industry) => ({
          ...industry,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Location') {
      if (name.text && name.text.includes('— By Location —')) {
        setLocation({});
      } else {
        setLocation((location) => ({
          ...location,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'RegulatoryAgency') {
      if (name.text && name.text.includes('— By Regulatory Agency —')) {
        setRegulatoryAgency({});
      } else {
        setRegulatoryAgency((regulatoryagency) => ({
          ...regulatoryagency,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Date') {
      if (name.text && name.text.includes('— By Date —')) {
        setDate({});
      } else {
        setDate((date) => ({
          ...date,
          Name: name.text,
          ID: value,
        }));
      }
    } else if (event.target.name === 'Keyword') {
      setKeyword((keyword) => ({
        ...keyword,
        Name: event.target.value,
        ID: '0',
      }));
    } else if (event.target.name === 'BusinessUnit') {
      if (name.text && name.text.includes('— By Business Unit —')) {
        setBusinessunit({});
      } else {
        setBusinessunit((format) => ({
          ...format,
          Name: name.text,
          ID: value,
        }));
      }
    }

    if (
      pformat.length == 0 &&
      pcategory.length == 0 &&
      pindustry.length == 0 &&
      pdate.length == 0 &&
      pkeyword.length == 0 &&
      pbusinessunit.length == 0 &&
      pregulatoryagency.length == 0 &&
      plocation.length == 0
    )
      dispatch(TrainingIgnoreDefaultSearch(true));
    else dispatch(TrainingIgnoreDefaultSearch(false));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSuccess();
  };

  function submitSuccess() {
    if (
      format != null &&
      format.ID !== undefined &&
      format.ID !== '— Format —' &&
      format.Name !== '— Format —' &&
      format.ID !== 0 &&
      format.ID !== '0'
    )
      dispatch(selectTrainingActionFilter(format, pformat, 'Training_Format'));
    if (
      category != null &&
      category.ID !== undefined &&
      category.ID !== '— Category —' &&
      category.Name !== '— Category —' &&
      category.ID !== 0 &&
      category.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(category, pcategory, 'Training_Category')
      );
    }
    if (
      industry != null &&
      industry.ID !== undefined &&
      industry.ID !== '— Industry —' &&
      industry.Name !== '— Industry —' &&
      industry.ID !== 0 &&
      industry.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(industry, pindustry, 'Training_Industry')
      );
    }
    if (
      location != null &&
      location.ID !== undefined &&
      location.ID !== '— Location —' &&
      location.Name !== '— Location —' &&
      location.ID !== 0 &&
      location.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(location, plocation, 'Training_Location')
      );
    }
    if (
      regulatoryagency != null &&
      regulatoryagency.ID !== undefined &&
      regulatoryagency.ID !== '— RegulatoryAgency —' &&
      regulatoryagency.Name !== '— RegulatoryAgency —' &&
      regulatoryagency.ID !== 0 &&
      regulatoryagency.ID !== '0'
    ) {
      dispatch(
        selectTrainingActionFilter(
          regulatoryagency,
          pregulatoryagency,
          'Training_RegulatoryAgency'
        )
      );
    }
    if (
      date != null &&
      date.ID !== undefined &&
      date.ID !== '— Date —' &&
      date.Name !== '— Date —' &&
      date.ID !== 0 &&
      date.ID !== '0'
    )
      dispatch(selectTrainingActionFilter(date, pdate, 'Training_Date'));
    if (
      keyword !== null &&
      keyword !== undefined &&
      keyword.Name !== '' &&
      keyword.ID != null
    ) {
      dispatch(
        selectTrainingActionFilter(keyword, pkeyword, 'Training_Keyword')
      );
    }
    if (
      businessunit != null &&
      businessunit.ID !== undefined &&
      businessunit.ID !== '— By Business Unit —' &&
      businessunit.Name !== '— By Business Unit —'
    ) {
      dispatch(
        selectTrainingActionFilter(
          businessunit,
          pbusinessunit,
          'Training_Business_Unit'
        )
      );
    }

    IgnoreDefaultSearch = false;
    dispatch(TrainingIgnoreDefaultSearch(false));

    const selectformat = [];
    const selectcategory = [];
    const selectindustry = [];
    const selectlocation = [];
    const selectregulatoryagency = [];
    const selectdate = [];
    const selectkeyword = [];
    const selectbusinessunit = [];

    pformat.forEach((item) => {
      selectformat.push(item.Name);
    });
    pcategory.forEach((item) => {
      selectcategory.push(item.Name);
    });
    pindustry.forEach((item) => {
      selectindustry.push(item.Name);
    });
    plocation.forEach((item) => {
      selectlocation.push(item.Name);
    });
    pregulatoryagency.forEach((item) => {
      selectregulatoryagency.push(item.Name);
    });
    pdate.forEach((item) => {
      selectdate.push(item.Name);
    });
    pkeyword.forEach((item) => {
      selectkeyword.push(item.Name);
    });
    pbusinessunit.forEach((item) => {
      selectbusinessunit.push(item.Name);
    });

    const submissionObj = {
      PageNumber: 1,
      TrainingFormat: selectformat,
      TrainingCategory: selectcategory,
      TrainingIndustry: selectindustry,
      TrainingLocation: selectlocation,
      TrainingRegulatoryAgency: selectregulatoryagency,
      TrainingDate: selectdate,
      TrainingKeywords: selectkeyword,
      TrainingBusinessUnit: selectbusinessunit,
      IgnoreDefaultSearch,
    };

    let dynamicSearchDataArray = [];

    // console.log(selectdate)
    let rangeState = {};

    if (selectdate.length > 0) {
      if (selectdate.toString().includes('30')) {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(30, 'days').toDate().getTime(),
        };
      } else if (selectdate.toString().includes('60')) {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(60, 'days').toDate().getTime(),
        };
      } else if (selectdate.toString().includes('90')) {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(90, 'days').toDate().getTime(),
        };
      } else if (selectdate.toString().includes('6')) {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(6, 'M').toDate().getTime(),
        };
      } else if (selectdate.toString().includes('9')) {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(9, 'M').toDate().getTime(),
        };
      } else {
        rangeState = {
          min: moment().toDate().getTime(),
          max: moment().add(12, 'M').toDate().getTime(),
        };
      }
    }

    // console.log("-----------")
    // console.log(JSON.stringify(rangeState, null, 2))

    if (selectformat.length > 0) {
      selectformat.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `ByFormat|${element}`
        );
      });
    }

    if (selectcategory.length > 0) {
      selectcategory.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `ByCategory|${element}`
        );
      });
    }

    if (selectlocation.length > 0) {
      selectlocation.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `State|${element}`
        );
      });
    }

    if (selectregulatoryagency.length > 0) {
      selectregulatoryagency.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `RegulatoryAgency|${element}`
        );
      });
    }

    if (selectindustry.length > 0) {
      selectindustry.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `ByIndustry|${element}`
        );
      });
    }

    if (selectbusinessunit.length > 0) {
      selectbusinessunit.forEach((element) => {
        dynamicSearchDataArray = dynamicSearchDataArray.concat(
          `BusinessUnit|${element}`
        );
      });
    }

    // console.log(dynamicSearchDataArray)

    const data = {};
    if (dynamicSearchDataArray && dynamicSearchDataArray.length > 0)
      data.ds = dynamicSearchDataArray;
    if (selectkeyword && selectkeyword.length > 0)
      data.sbt = selectkeyword.toString().replace('[', '').replace(']', '');
    if (rangeState) data.rs = rangeState;
    if (
      (dynamicSearchDataArray && dynamicSearchDataArray.length > 0) ||
      (selectkeyword && selectkeyword.length > 0) ||
      (rangeState && rangeState.length > 0)
    )
      data.pg = 1;

    // data = {
    //   ds: dynamicSearchDataArray,
    //   sbt: selectkeyword.toString().replace("[", "").replace("]", ""),
    //   pg: 1,
    //   rs: rangeState
    // }

    // console.log(JSON.stringify(data, null, 2))
    // console.log(createURLAndReturn(data))

    // console.log("---> Getting here")
    // console.log(selectkeyword)
    // console.log(JSON.stringify(submissionObj, null, 2))

    // dispatch(loadTraining(true));
    // dispatch(getPageWiseTraining(submissionObj));
    // dispatch(activePageNumber(1));

    setFormat({ Name: '— Format —', ID: null });
    setCategory({ Name: '— Category —', ID: null });
    setIndustry({ Name: '— Industry —', ID: null });
    setLocation({ Name: '— Location —', ID: null });
    setRegulatoryAgency({ Name: '— RegulatoryAgency —', ID: null });
    setDate({ Name: '— Date —', ID: null });
    setKeyword({ Name: '', ID: null });
    setBusinessunit({ Name: '— By Business Unit —', ID: null });

    history.push(`/training/search?${createURLAndReturn(data)}`);

    // history.push({
    //     pathname: "/training/search",
    //     id: submissionObj
    // })
  }

  return (
    <div className="widget-container">
      <h2 className="search-title">FIND THE TRAINING YOU'RE LOOKING FOR:</h2>

      <div className="training-advanced-search">
        <TrainingActiveSearchFilter
          className="xs-sm-only-small-text mb-sm-2"
          istrainingladning={istrainingladning}
        />
      </div>

      <form className="form-inline">
        {/* <SearchInput className="xs-sm-only-small-text mb-sm-2" /> */}

        {/* <TextInput
          placeholder={"Keyword or phrase"}
          type="text"
          name={"Keyword"}
          onChange={handleChange}
          aria-label="Search"
          value={keyword.Name ? keyword.Name : ""}
          autoComplete="off"
          className="xs-sm-only-small-text mb-sm-2"
        /> */}

        <label className="text-input w-100">
          <input
            type="text"
            placeholder="Keyword or phrase"
            className="`trinity-text-input w-100 xs-sm-only-small-text mb-sm-2"
            autoComplete="off"
            aria-label="Search"
            name="Keyword"
            value={keyword.Name ? keyword.Name : ''}
            onKeyDown={(e) => e.key === 'Enter' && handleSubmit(e)}
            onChange={handleChange}
          />
        </label>

        {dformat && (
          <SelectList
            onChange={handleChange}
            name="Format"
            value={format.ID ? format.ID : '— By Format —'}
            defaultText="— By Format —"
            className="mb-2"
          >
            {dformat.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dcategory && (
          <SelectList
            onChange={handleChange}
            name="Category"
            value={category.ID ? category.ID : '— By Category —'}
            defaultText="— By Category —"
            className="mb-2"
          >
            {dcategory.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dindustry && (
          <SelectList
            onChange={handleChange}
            name="Industry"
            value={industry.ID ? industry.ID : '— By Industry —'}
            defaultText="— By Industry —"
            className="mb-2"
          >
            {dindustry.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {ddate && (
          <SelectList
            onChange={handleChange}
            name="Date"
            value={date.ID ? date.ID : '— By Date —'}
            defaultText="— By Date —"
            className="mb-2"
          >
            {ddate.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dlocation && (
          <SelectList
            onChange={handleChange}
            name="Location"
            value={location.ID ? location.ID : '— By Location —'}
            defaultText="— By Location —"
            className="mb-2"
          >
            {dlocation.map((item) => (
              <option className="filter" value={item.LocatioId}>
                {item.LocationName}
              </option>
            ))}
          </SelectList>
        )}

        {dregulatoryagency && (
          <SelectList
            onChange={handleChange}
            name="RegulatoryAgency"
            value={
              regulatoryagency.ID
                ? regulatoryagency.ID
                : '— By Regulatory Agency —'
            }
            defaultText="— By Regulatory Agency —"
            className="mb-2"
          >
            {dregulatoryagency.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}

        {dbusinessunit && (
          <SelectList
            onChange={handleChange}
            name="BusinessUnit"
            value={businessunit.ID ? businessunit.ID : '— By Business Unit —'}
            defaultText="— By Business Unit —"
            className="mb-2"
          >
            {dbusinessunit.map((item) => (
              <option className="filter" value={item.ID}>
                {item.Name}
              </option>
            ))}
          </SelectList>
        )}
      </form>
      <div className="button-container">
        <CustomButton
          onClick={handleSubmit}
          loading={trainingLoading}
          btndisabled={trainingLoading}
          className="btn"
          hideBorderOnHover
        >
          Search
        </CustomButton>
      </div>
    </div>
  );
}

export default TrainingLandingAdvanceSearchFilter;
