import React from 'react';
import { title, blurb, second_copy } from './cta.data';
import './cta.styles.scss';
import cta from '../../../images/homepage/homepage_cta.svg';

function CtaText() {
  return (
    <div className="cta-container">
      <img src={cta} alt="" />
      <div className="text-wrapper trinity-container">
        <p className="title">{title}</p>
        <p className="first-copy">{blurb}</p>
        <p className="second-copy">{second_copy}</p>
      </div>
    </div>
  );
}

export default CtaText;
