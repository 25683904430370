import { impersonateTypes } from './impersonate.types';

const IMPERSONATE_INITIAL_STATE = {
  userList: [],
  count: 0,
};

const impersonateUser = (state = IMPERSONATE_INITIAL_STATE, action) => {
  switch (action.type) {
    case impersonateTypes.GET_ALL_IMPERSONATE_USERS:
      if (action.payload) {
        return {
          ...state,
          userList: action.payload['value'],
          count: action.payload['@odata.count'],
        };
      }
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default impersonateUser;
