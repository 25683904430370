import { useDispatch, useSelector } from 'react-redux';
import { TrainingActionTypes } from './training.types';
import { TrainingServices } from './training.services';

export const trainingformatfilterdata = () => (dispatch) => {
  TrainingServices.getTrainingFormatFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_FORMAT_FILTER_DATA,
        payload: response.data.ResourceTypes,
      });
    }
  });
};
export const trainingcategoryfilterdata = () => (dispatch) => {
  TrainingServices.getTrainingCategoryFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_CATEGORY_FILTER_DATA,
        payload: response.data.Categories,
      });
    }
  });
};

export const trainingindustryfilterdata = () => (dispatch) => {
  TrainingServices.getTrainingIndustryFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_INDUSTRY_FILTER_DATA,
        payload: response.data.Industries,
      });
    }
  });
};

export const traininglocationfilterdata = () => (dispatch) => {
  TrainingServices.getTrainingLocationFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_LOCATION_FILTER_DATA,
        payload: response.data.Locations,
      });
    }
  });
};

export const trainingregulatoryagencyfilterdata = () => (dispatch) => {
  TrainingServices.getTrainingRegulatoryAgencyFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_REGULATORYAGENCY_FILTER_DATA,
        payload: response.data.TrainingRegulatoryAgency,
      });
    }
  });
};

export const trainingdatefilterdata = () => (dispatch) => {
  TrainingServices.getTrainingDateFilterData().then((response) => {
    if (response) {
      dispatch({
        type: TrainingActionTypes.TRAINING_DATE_FILTER_DATA,
        payload: response.data.TrainingDates,
      });
    }
  });
};

export const getPageWiseTraining = (training) => (dispatch) => {
  if (
    training.IgnoreDefaultSearch == true ||
    training.TrainingFormat.length > 0 ||
    training.TrainingCategory.length > 0 ||
    training.TrainingIndustry.length > 0 ||
    training.TrainingDate.length > 0 ||
    training.TrainingLocation.length > 0 ||
    training.TrainingKeywords.length > 0
  ) {
    TrainingServices.getPageWiseTraining(training).then((response) => {
      if (response) {
        const trainingData = response.data;
        dispatch({
          type: TrainingActionTypes.GET_PAGEWISE_TRAINING,
          payload: response.data,
        });

        dispatch({
          type: TrainingActionTypes.TRAINING_LOADING,
          payload: false,
        });

        if (trainingData !== null && trainingData !== undefined) {
          dispatch({
            type: TrainingActionTypes.TOTAL_ITEM_COUNT,
            payload: trainingData.TotalCount,
          });
          dispatch({
            type: TrainingActionTypes.PER_PAGE_COUNT,
            payload:
              trainingData.Product !== [] && trainingData.Product
                ? trainingData.Product.length
                : 0,
          });
        }
      } else if (response && response.data === '') {
        dispatch({
          type: TrainingActionTypes.GET_PAGEWISE_TRAINING,
          payload: [],
        });
      } else {
        dispatch({
          type: TrainingActionTypes.TRAINING_LOADING,
          payload: false,
        });
      }
    });
  }
};

export const loadTraining = (booleanParameter) => (dispatch) => {
  dispatch({
    type: TrainingActionTypes.TRAINING_LOADING,
    payload: booleanParameter,
  });
};

export const TrainingIgnoreDefaultSearch = (booleanParameter) => (dispatch) => {
  dispatch({
    type: TrainingActionTypes.TRAINING_IGNORE_DEFAULT_SEARCH,
    payload: booleanParameter,
  });
};

export const activePageNumber = (pagenumber) => (dispatch) => {
  dispatch({
    type: TrainingActionTypes.ACTIVE_PAGE_NUMBER,
    payload: pagenumber,
  });
};

export const selectTrainingActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername !== null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata;
      } else {
        pdata.push({ ID: filtername.ID, Name: filtername.Name });
      }

      if (method == 'Training_Format') {
        dispatch({
          type: TrainingActionTypes.TRAINING_FORMAT_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Category') {
        dispatch({
          type: TrainingActionTypes.TRAINING_CATEGORY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Industry') {
        dispatch({
          type: TrainingActionTypes.TRAINING_INDUSTRY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Date') {
        dispatch({
          type: TrainingActionTypes.TRAINING_DATE_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Location') {
        dispatch({
          type: TrainingActionTypes.TRAINING_LOCATION_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_RegulatoryAgency') {
        dispatch({
          type: TrainingActionTypes.TRAINING_REGULATORYAGENCY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Keyword') {
        dispatch({
          type: TrainingActionTypes.TRAINING_KEYWORD_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Business_Unit') {
        dispatch({
          type: TrainingActionTypes.TRAINING_BUSINESS_UNIT_FILTER_SELECTED,
          payload: pdata,
        });
      }
    }
  };

export const removeTrainingActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata.filter((x) => x.Name != filtername.Name);
      } else {
        pdata = pdata;
      }

      if (method == 'Training_Format') {
        dispatch({
          type: TrainingActionTypes.TRAINING_FORMAT_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Category') {
        dispatch({
          type: TrainingActionTypes.TRAINING_CATEGORY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Industry') {
        dispatch({
          type: TrainingActionTypes.TRAINING_INDUSTRY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Date') {
        dispatch({
          type: TrainingActionTypes.TRAINING_DATE_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Location') {
        dispatch({
          type: TrainingActionTypes.TRAINING_LOCATION_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_RegulatoryAgency') {
        dispatch({
          type: TrainingActionTypes.TRAINING_REGULATORYAGENCY_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Keyword') {
        dispatch({
          type: TrainingActionTypes.TRAINING_KEYWORD_FILTER_SELECTED,
          payload: pdata,
        });
      } else if (method == 'Training_Business_Unit') {
        dispatch({
          type: TrainingActionTypes.TRAINING_BUSINESS_UNIT_FILTER_SELECTED,
          payload: pdata,
        });
      }
    }
  };

export const resetTrainingFilterSet = () => (dispatch) => {
  dispatch({
    type: TrainingActionTypes.RESET_TRAINING_FILTER_SET,
  });
};
