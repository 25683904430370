import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TextInput from '../../../../components/commons/form-inputs/text-input/text-input.component';
import './change-password.styles.scss';
import { useDispatch } from 'react-redux';
import validate from './change-password.validationRule';
import useForm from '../../../../js/custom-hooks/useForm';
import CustomButton from '../../../../components/commons/custom-button/custom-button.component';
import { updateUserPassword } from '../../../../js/api/_register-user';
import { handleNotify } from '../../../../js/helpers/toaster/toaster-notify';
import { ToasterPositions } from '../../../../js/helpers/toaster/toaster-positions';
import { ToasterTypes } from '../../../../js/helpers/toaster/toaster-types';

function ChangePasswordForm({ userName }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { values, errors, handleChange, handleSubmit } = useForm(
    submitSuccess,
    validate
  );

  const [errorMessage, setErrorMesssage] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    values.NewPassword = '';
    values.ConfirmPassword = '';
    setIsLoaded(true);
  }, []);

  async function submitSuccess() {
    const createAccountSubmissionObj = {
      Username: userName,
      Password: values.NewPassword.trim(),
      ConfirmPassword: values.SetConfirmPassword.trim(),
    };

    // console.log(JSON.stringify(createAccountSubmissionObj, null, 2));
    const request = await updateUserPassword(createAccountSubmissionObj);
    const response = await request;

    if (response) {
      handleNotify(
        'You have successfully updated profile.',
        ToasterTypes.Success
      );
    }
    await handleCancel();
  }

  const handleCancel = async () => {
    history.push('/my-account');
  };

  if (values && isLoaded) {
    return (
      <div className="change-password-form-container">
        {errorMessage && <p className="text error">*{errorMessage}</p>}
        <form>
          <div className="edit-profile-form">
            <div>
              <h2>Update Password</h2>
              User Name: <h6>{userName}</h6>
              {errors.NewPassword && (
                <span className="help">{errors.NewPassword}</span>
              )}
              <TextInput
                placeholder="New Password"
                label="New Password"
                type="password"
                autoComplete="off"
                className="company-name"
                name="NewPassword"
                value={values ? values.NewPassword : ''}
                onChange={handleChange}
              />
              {errors.SetConfirmPassword && (
                <span className="help">{errors.SetConfirmPassword}</span>
              )}
              <TextInput
                placeholder="Confirm Password"
                label="Confirm Password"
                type="password"
                autoComplete="off"
                className="company-name"
                name="SetConfirmPassword"
                value={values ? values.SetConfirmPassword : ''}
                onChange={handleChange}
              />
            </div>
          </div>
        </form>
        <div className="profile-button-container">
          <Link to="/my-account">
            <CustomButton
              type="submit"
              loading={submitting}
              onClick={handleSubmit}
              className="changes"
            >
              Save Changes
            </CustomButton>
          </Link>

          <CustomButton
            type="button"
            onClick={handleCancel}
            altColors
            className="cancel"
          >
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  }
  return null;
}

export default ChangePasswordForm;
