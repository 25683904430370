export default function validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.title) {
    errors.title = 'Job Title is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone number field - ex: 1234567890';
  }
  if (!values.addressLine1) {
    errors.addressLine1 = 'Address Line1 is required';
  }
  if (
    !values.industryId ||
    values.industryId === '— Industry —' ||
    values.industryId < 1 ||
    values.industryId === 2
  ) {
    errors.industryId = 'Industry is required';
  }
  if (!values.stateId || values.stateId === '— State —' || values.stateId < 1) {
    errors.stateId = 'State is required';
  }
  if (
    !values.countryId ||
    values.countryId === '— Country —' ||
    values.countryId < 1
  ) {
    errors.countryId = 'Country is required';
  }

  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.postalCode) {
    errors.postalCode = 'Postal Code is required';
  }
  if (!values.companyName) {
    errors.companyName = 'Company Name is required';
  }

  if (values?.isDietary === 'y' && !values.dietaryRestrictions) {
    errors.dietaryRestrictions = 'Please select dietary restrictions';
  }

  if (
    values?.isDietary === 'y' &&
    Number(values.dietaryRestrictions) === 6 &&
    !values.otherDietInstructions
  ) {
    errors.otherDietInstructions = 'Please enter the diet instructions';
  }

  if (
    values?.isDietary === 'y' &&
    Number(values.dietaryRestrictions) === 6 &&
    values?.otherDietInstructions?.length > 140
  ) {
    errors.otherDietInstructions =
      'Please enter the diet instructions in 140 characters only';
  }
  return errors;
}
