import React, { useState, useEffect } from 'react';
import RequestDataQuote from '../../../components/commons/forms/request-data-quote/request-data-quote.component';
import heroImageSrc from '../../../images/consulting-ehs/ehs-primary-background.png';
import Hero from '../../../components/commons/hero/hero.component';
import { getRequestDataQuotePageData } from '../../../js/api/specialty-pages/_request-data-quote.data';
import Loader from '../../../components/commons/helpers/loader/loader.component';

function RequestDataQuotePage({ page: { modules } }) {
  // #RP - Task 68 - Request Data Quote
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getRequestDataQuotePageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
      }
    };
    fetchData();
  }, []);

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Country != undefined)
  ) {
    return (
      <div className="data-request-page-container">
        <Hero
          src={
            status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status != noContent && responseMainContent.MobileImage_src
              ? responseMainContent.MobileImage_src
              : ''
          }
          alt={
            status != noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          template="standard"
          colorCategory="policies"
        />
        <div className="trinity-container">
          <RequestDataQuote
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default RequestDataQuotePage;
