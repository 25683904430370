import React, { useEffect } from 'react';
import './resources.styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Hero from '../../../components/commons/hero/hero.component';
import ResourceListingWithFilters from '../../../components/resources-and-media/resource-listing-with-filters/resource-listing-with-filters.component';
import Loader from '../../../components/commons/helpers/loader/loader.component';
import {
  getPageWiseResource,
  loadFilter,
  loadResource,
} from '../../../redux/resource/resource.action';

// RP #Task 119 Resources
function ResourcesLp({ page: { MainContent, ResourceContent } }) {
  const dispatch = useDispatch();

  const resourceFilter = useSelector(({ resource }) => resource.filterList);

  const resourceData = {
    PageNumber: 1,
    KeywordorPhrase: [],
    ResourceType: [],
    Industry: [],
    Services: [],
    Location: [],
  };

  useEffect(() => {
    dispatch(loadResource(true));
    dispatch(getPageWiseResource(resourceData));
    dispatch(loadFilter());
  }, []);

  if (MainContent && resourceFilter) {
    return (
      <div className="resources resources-lp">
        <Hero
          src={MainContent.HeaderImage_src ? MainContent.HeaderImage_src : ''}
          mobileImgSrc={
            MainContent.MobileImage_src ? MainContent.MobileImage_src : ''
          }
          alt={MainContent.HeaderImage_alt ? MainContent.HeaderImage_alt : ''}
          template="standard"
          title={MainContent.Title ? MainContent.Title : ''}
        />
        <div className="sm-up-trinity-container">
          <ResourceListingWithFilters />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default ResourcesLp;
