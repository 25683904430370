import React, { useState } from 'react';
import './data-table.styles.scss';

import styles from '../../../../css/global.variables.scss';

function DataTable({ titleList, tableData }) {
  const [activeSectionIndex, setActiveSectionIndex] = useState(2);

  function viewNext(sectionIndex) {
    let nextIndex;

    if (window.innerWidth <= parseInt(styles.breakpointMd)) {
      nextIndex = sectionIndex >= titleList.length - 1 ? 2 : sectionIndex + 1;
    } else {
      nextIndex = sectionIndex >= titleList.length ? 2 : sectionIndex + 1;
    }

    setActiveSectionIndex(nextIndex);
  }

  function viewPrev(sectionIndex) {
    let prevIndex;

    if (window.width <= parseInt(styles.breakpointMd)) {
      prevIndex = sectionIndex <= 2 ? titleList.length - 1 : sectionIndex - 1;
    } else {
      prevIndex = sectionIndex <= 2 ? titleList.length : sectionIndex - 1;
    }

    setActiveSectionIndex(prevIndex);
  }

  if (tableData && titleList) {
    return (
      <div className="data-table">
        <div className="table-titles">
          {titleList.map((title, i) => (
            <div
              className={`
                            section-${i + 1} 
                            ${
                              activeSectionIndex === i + 1
                                ? 'primary-active-section'
                                : ''
                            }
                        `}
            >
              <div className="title-container relative">
                {i > 0 && (
                  <span
                    aria-label="View the previous data listing section"
                    className="filled-arrow prev"
                    onClick={() => viewPrev(activeSectionIndex)}
                  />
                )}

                <span key={title + i} className="title">
                  {title}
                </span>

                {i > 0 && (
                  <span
                    aria-label="View the next data listing section"
                    className="filled-arrow next"
                    onClick={() => viewNext(activeSectionIndex)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="table">
          {tableData.map(
            (
              {
                chemical,
                cas,
                sampling,
                method,
                sensitivity,
                activeSearch,
                activeSelect,
              },
              i
            ) => (
              <div
                className={`
                            table-item
                            ${activeSearch ? '' : 'hide-search'}
                            ${activeSelect ? '' : 'hide-select'}
                        `}
                key={chemical + i}
              >
                <div className="data-listing section-1">
                  <span className="mobile-listing-title d-sm-none">
                    Chemical
                  </span>
                  <span className="data-text">{chemical}</span>
                </div>
                <div
                  className={`data-listing section-2 ${
                    activeSectionIndex === 2 ? 'primary-active-section' : ''
                  }`}
                >
                  <span className="mobile-listing-title d-sm-none">CAS#</span>
                  <span className="data-text">{cas}</span>
                </div>
                <div
                  className={`data-listing section-3 ${
                    activeSectionIndex === 3 ? 'primary-active-section' : ''
                  }`}
                >
                  <span className="mobile-listing-title d-sm-none">
                    Sampling
                  </span>
                  <span className="data-text">{sampling}</span>
                </div>
                <div
                  className={`data-listing section-4 ${
                    activeSectionIndex === 4 ? 'primary-active-section' : ''
                  }`}
                >
                  <span className="mobile-listing-title d-sm-none">Method</span>
                  <span className="data-text">{method}</span>
                </div>
                <div
                  className={`data-listing section-5 ${
                    activeSectionIndex === 5 ? 'primary-active-section' : ''
                  }`}
                >
                  <span className="mobile-listing-title d-sm-none">
                    Analytical Sensitivity
                  </span>
                  <span className="data-text">{sensitivity}</span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default DataTable;
