import Axios from 'axios';
import { ENDPOINTS } from '../../js/api/_config';

export const getHomePageRelatedData = async () =>
  await Axios({
    method: 'GET',
    url: 'https://trinitysandboxcms.azurewebsites.net/restapi/ucommerce/latest/courses',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((e) => e)
    .catch((e) => e);
