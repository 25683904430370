function JobAlertModal({
  hero: { hero_src = '', hero_alt = '', hero_title = '' },
}) {
  return {
    hero: {
      hero_src,
      hero_alt,
      hero_title,
    },
  };
}

export default JobAlertModal;
