import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CustomButton from '../../custom-button/custom-button.component';
import { title, blurb, link } from './existing-account-login.data';

import './existing-account-login.styles.scss';

function ExistingAccountLogin(data) {
  return (
    <div className="existing-login-container">
      <div className="content">
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: data.data.SubTitle }}
        />
        <div
          className="blurb"
          dangerouslySetInnerHTML={{ __html: data.data.SubContent }}
        />
      </div>
      <CustomButton className="heroLink">{link}</CustomButton>
    </div>
  );
}

export default ExistingAccountLogin;
