import ConsultingTertiaryModel from '../consulting/consulting-models/consulting-tertiary.model';

const engineeringpages = [
  // {
  //   name: "engineering",
  //   path: "/engineering",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description"
  //   },
  //   template: "Engineering"
  // },
  // {
  //   name: "Bio-Pharma Services",
  //   path: "/bio-pharma-services",
  //   meta: {
  //     title: "this is a meta title",
  //     description: "this is a meta description"
  //   },
  //   template: "consulting-tertiary",
  //   modules: ConsultingTertiaryModel({
  //     hero: {
  //       hero_src: "",
  //       hero_title: "Industrial Hygiene Analytical Laboratory"
  //     },
  //     background_cta: {
  //       background_title: "Industrial Hygiene Analytical Laboratory",
  //       background_content:
  //         "The U.S. Environmental Protection Agency (EPA) widely recognizes and routinely utilizes Photochemical Grid Modeling (PGM) for regulatory analysis and attainment demonstrations. PGM simulates the impacts from single and multi-source studies by estimating pollutant concentrations and deposition for chemically reactive pollutants over large spatial scales. Similar regional scale modeling can be performed for inert pollutants as well. Trinity provides comprehensive modeling for both PGM and regional air quality studies by offering such modeling services to our clients nationally and internationally. For more than 10 years, experts at Trinity have been supporting clients with PGM and regional scale modeling services.",
  //       background_link: "Learn more about photochemical grid modeling › "
  //     },
  //     content_area: "<p>this is some content</p>"
  //   })
  // }
];

export default JSON.stringify(engineeringpages);
