import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Hero from '../../components/commons/hero/hero.component';
import heroImageSrc from '../../images/about/policiesHero.svg';
import AboutLPGrid from '../../components/about/cta/cta-grid/about_lp-grid/cta-grid.component';
import './about_lp.styles.scss';
import { getAboutUsPageData } from '../../js/api/about/_aboutus.data';
import Loader from '../../components/commons/helpers/loader/loader.component';

function AboutLP({ page: { MainContent, CategoryContent, modules } }) {
  // #RP - Task  - Bind data to AboutUs Form in Front-End.
  const [responseMainContent, setResponseMainContent] = useState({});
  const [responseDropDownContent, setResponseDropDownContent] = useState({});
  const [status, setStatus] = useState(null);
  const [noContent] = useState(204);
  const [heroCtas, setHeroCtas] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const getResponse = await getAboutUsPageData();

      if (getResponse) {
        setStatus(getResponse.status);
        setResponseMainContent(getResponse.MainContent);
        setResponseDropDownContent(getResponse.DropDownContent);
        if (
          getResponse.DropDownContent != undefined &&
          getResponse.DropDownContent.lstAboutUsCategoryIntroText != undefined
        )
          setHeroCtas(getResponse.DropDownContent.lstAboutUsCategoryIntroText);
      }
    };
    fetchData();
  }, []);

  const tempArray = heroCtas && heroCtas.reverse();

  if (
    status == noContent ||
    (responseMainContent &&
      responseDropDownContent &&
      responseDropDownContent.Author != undefined)
  ) {
    return (
      <div className="about about-lp">
        <Hero
          src={
            status != noContent && responseMainContent.HeaderImage_src
              ? responseMainContent.HeaderImage_src
              : ''
          }
          mobileImgSrc={
            status != noContent && responseMainContent.HeaderMobileImage_src
              ? responseMainContent.HeaderMobileImage_src
              : ''
          }
          alt={
            status != noContent && responseMainContent.HeaderImage_alt
              ? responseMainContent.HeaderImage_alt
              : ''
          }
          title={
            status != noContent && responseMainContent.Title
              ? responseMainContent.Title
              : 'Please contact admin for page content'
          }
          description={
            status != noContent && responseMainContent.HeaderContent
              ? responseMainContent.HeaderContent
              : ''
          }
          template="landing"
          colorCategory="default"
          downArrow
          headerIntroText={tempArray}
        />

        <div className="trinity-container grid-container">
          <AboutLPGrid
            onlySmallCtas
            data={responseDropDownContent}
            mainData={responseMainContent}
            status={status}
          />
        </div>
      </div>
    );
  }
  return <Loader />;
}

export default AboutLP;
