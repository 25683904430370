import { SoftwareResourceService } from './software-resource.service';
import { SoftwareResourceActionTypes } from './software-resource.type';

export const getPageWiseSoftwareResource = (resource) => (dispatch) => {
  SoftwareResourceService.getPageWiseSoftwareResource(resource).then(
    (response) => {
      if (response) {
        const resourceData = response.data.ResourceContent;
        dispatch({
          type: SoftwareResourceActionTypes.GET_PAGEWISE_SOFTWARE_RESOURCE,
          payload: resourceData,
        });

        if (resourceData.length > 0) {
          dispatch({
            type: SoftwareResourceActionTypes.TOTAL_ITEM_COUNT,
            payload: response.data.ResourceTotalCount,
          });
        }
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING,
          payload: false,
        });
      } else if (response && response.data == '') {
        dispatch({
          type: SoftwareResourceActionTypes.GET_PAGEWISE_RESOURCE,
          payload: [],
        });
      } else {
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING,
          payload: false,
        });
      }
    }
  );
};

export const getNewPageWiseSoftwareResource = (resource) => (dispatch) => {
  SoftwareResourceService.getNewPageWiseSoftwareResource(resource).then(
    (response) => {
      if (response) {
        const resourceData = response.data.ResourceContent;
        dispatch({
          type: SoftwareResourceActionTypes.GET_PAGEWISE_SOFTWARE_RESOURCE,
          payload: resourceData,
        });

        if (resourceData.length > 0) {
          dispatch({
            type: SoftwareResourceActionTypes.TOTAL_ITEM_COUNT,
            payload: response.data.ResourceTotalCount,
          });
        }
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING,
          payload: false,
        });
      } else if (response && response.data == '') {
        dispatch({
          type: SoftwareResourceActionTypes.GET_PAGEWISE_RESOURCE,
          payload: [],
        });
      } else {
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING,
          payload: false,
        });
      }
    }
  );
};

export const loadSoftwareResource = (booleanParameter) => (dispatch) => {
  dispatch({
    type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_LOADING,
    payload: booleanParameter,
  });
};

export const activePageNumber = (pagenumber) => (dispatch) => {
  dispatch({
    type: SoftwareResourceActionTypes.ACTIVE_PAGE_NUMBER,
    payload: pagenumber,
  });
};

export const loadFilter = () => (dispatch) => {
  SoftwareResourceService.getSoftwareResourceFilterData().then((response) => {
    if (response) {
      dispatch({
        type: SoftwareResourceActionTypes.FILTER_LOADING,
        payload: response.data,
        responseStatus: true,
      });
    }
  });
};

export const selectSoftwareResourceActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata;
      } else {
        pdata.push({ Id: filtername.Id, Name: filtername.Name });
      }

      if (method == 'Keyword') {
        dispatch({
          type: SoftwareResourceActionTypes.KEYWORD_FILTER,
          payload: pdata,
        });
      } else if (method == 'Modeling_Category') {
        dispatch({
          type: SoftwareResourceActionTypes.MODELING_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Product') {
        dispatch({
          type: SoftwareResourceActionTypes.PRODUCT_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({
          type: SoftwareResourceActionTypes.INDUSTRY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Service') {
        dispatch({
          type: SoftwareResourceActionTypes.SERVICE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Location') {
        dispatch({
          type: SoftwareResourceActionTypes.LOCATION_FILTER,
          payload: pdata,
        });
      } else if (method == 'SoftwareResourceType') {
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_TYPE,
          payload: pdata,
        });
      }
    }
  };

export const removeSoftwareResourceActionFilter =
  (filtername, pdata, method) => (dispatch) => {
    if (filtername && filtername != null) {
      const fdata = !!(
        pdata.length > 0 &&
        pdata.filter((x) => x.Name == filtername.Name).length > 0
      );

      if (fdata) {
        pdata = pdata.filter((x) => x.Name != filtername.Name);
      } else {
        pdata = pdata;
      }

      if (method == 'Keyword') {
        dispatch({
          type: SoftwareResourceActionTypes.KEYWORD_FILTER,
          payload: pdata,
        });
      } else if (method == 'Modeling_Category') {
        dispatch({
          type: SoftwareResourceActionTypes.MODELING_CATEGORY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Product') {
        dispatch({
          type: SoftwareResourceActionTypes.PRODUCT_FILTER,
          payload: pdata,
        });
      } else if (method == 'Industry') {
        dispatch({
          type: SoftwareResourceActionTypes.INDUSTRY_FILTER,
          payload: pdata,
        });
      } else if (method == 'Service') {
        dispatch({
          type: SoftwareResourceActionTypes.SERVICE_FILTER,
          payload: pdata,
        });
      } else if (method == 'Location') {
        dispatch({
          type: SoftwareResourceActionTypes.LOCATION_FILTER,
          payload: pdata,
        });
      } else if (method == 'SoftwareResourceType') {
        dispatch({
          type: SoftwareResourceActionTypes.SOFTWARE_RESOURCE_TYPE,
          payload: pdata,
        });
      }
    }
  };
